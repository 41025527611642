import React from 'react';
import Card from '../../../../components/Card/Card';
import AgeGraph from './AgeGraph';
import GenerationsGraph from './GenerationsGraph';


const AgeStats = (  )=>{

    return(
        <Card
            title='Reporte de edades'
            content={
                <>
                    <AgeGraph />
                    <hr/>
                    <GenerationsGraph />

                </>
            }
        />
    );

};

export default AgeStats;
