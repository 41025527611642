import {useContext, useMemo} from 'react';
import filterSearchIcon from '../../../assets/img/tgle-rewards/search-employees-filter.svg';
import searchIcon from '../../../assets/img/tgle-rewards/search-employees-icon.svg';
import {ApiContext} from "../../../api/Api";
import {useSearch, searchComponent} from "./search";
import {useSelector} from 'react-redux';

const searchConfigs = [
    {
        label: 'Nombre',
        filter: {
            'order[name]': 'ASC'
        }
    },
    {
        label: 'Apellido',
        filter: {
            'order[patLastName]': 'ASC'
        }
    },
    {
        label: 'Área',
        filter: {
            'order[area.name]': 'ASC'
        }
    }
];

const SearchEmployees = searchComponent({
    className: 'search-employees',
    searchIcon,
    filterSearchIcon,
    configTitle: 'Ordenar contactos por:',
    searchConfigs
});

const queryToRequestParams = query => ({filters: {search: query}});
const mergeExtraFilters = (filters, query) => ({...query, filters: {...(query.filters || {}), ...filters}});
export const useEmployeesSearch = (config) => {
    const extraFilters = useMemo(() => (config?config.extraFilters:{})||{}, [config]);
    const api = useContext(ApiContext);
    const me = useSelector(({api: {me}})=>me);
    const request = api.employees.simple;
    const nextPageRequest = api.employees.simpleNextPage;
    const {results, searchProps, requestNextPage, containerCSSClasses} = useSearch(queryToRequestParams, request, nextPageRequest, mergeExtraFilters, extraFilters, 'employees');
    return {
        results: (results||[]).filter(r=>me.employee?me.employee.id!==r.id:true),
        searchProps,
        requestNextPage,
        containerCSSClasses,
    };
};

export default SearchEmployees;
