
export default store => next => action => {

    if( typeof action.payload === "object" &&
        action.payload !== null &&
        typeof action.payload.json === "function" &&
        action.payload.headers.get("Content-Type").split(";")[0] === "application/json" &&
        action.payload.status !== 204 ){ //Prevent "no content" response from parsing

        action.payload.json().then( json =>{
            if( !action.meta )
                action.meta = {};
            action.meta.json = json;
            next(action);
        })
    }
    else{
        return next( action )
    }
}