import DefaultHeader from '../components/Header/Header';
import RewardsHeader from './RewardsHeader';

const headersForUser = user => {
    return [
        {
            path: '/rewards',
            component: RewardsHeader
        },
        {
            path: '/',
            component: DefaultHeader
        }
    ];
};

export default headersForUser;
