import React, {Component} from 'react';
import Justifications from './Justifications';

export default class IncidenceRequests extends Component {

    render(){

        return(
            <Justifications
                isFuture
            />
        );
    }
}