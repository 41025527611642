import React from 'react';
import Switch from "react-bootstrap-switch";
import _ from 'lodash/fp';




const types = [
    ['Aerolínea', 'airline'],
    ['Alimentos', 'food'],
    ['Bebidas', 'drinks'],
    ['Estancia', 'stay'],
    ['Felicidad', 'happiness'],
    ['Limpieza', 'cleanliness'],
    ['Mantenimiento', 'maintenance'],
    ['Método de acceso', 'loungeAccessMethod'],
    ['Motivo del viaje', 'purpose'],
    ['Recepción', 'reception'],
    ['Recomendación', 'recommendation'],
    ['Staff', 'staff'],
    ['Sala', 'lounge'],
    ['Turno', 'shift'],
].map(([name, type])=>({name, type}));

const getUsedQuestionTypes = _.flow(
    _.map('config.gcareType'),
    _.filter(_.identity)
);

const GcareQuestion = ({onChange, question, questionnaire}) => {
    const config = question.config;
    const isGcare = !!config.gcareType;
    const usedQuestionTypes = getUsedQuestionTypes(questionnaire.questions);
    const availableTypes = _.filter(
        ({type})=>(!_.includes(type, usedQuestionTypes))||type===config.gcareType,
        types
    );
    const hasAvailable = !!availableTypes[0];
    const onSwitchChange = (c, v)=>{
        if(!hasAvailable){
            onChange({...config, gcareType: undefined});
            return;
        }
        if(v){
            onChange({...config, gcareType: availableTypes[0].type});
            return;
        }
        onChange({...config, gcareType: undefined});
    };
    const onSelectChange = (e) => {
        onChange({...config, gcareType: e.target.value});
    };

    return (
        <div>
            <Switch
                value={isGcare}
                onChange={onSwitchChange}
            /> &nbsp; Pregunta para reporte GCare &nbsp;

            {isGcare && (
                <div className='configuration-box'>
                    <select value={config.gcareType} onChange={onSelectChange} className='form-control'>
                        {availableTypes.map(({name, type})=>(
                            <option key={type} value={type}>{name}</option>
                        ))}
                    </select>
                    <p className='text-muted small'>Selecciona el tipo de pregunta bajo el cuál de reportará.</p>
                </div>
            )} 
        </div>
    );
};

export default GcareQuestion;
