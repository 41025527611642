import React, {useContext, useEffect, useMemo, useCallback, useState} from 'react';
import {ApiContext} from "../../../api/Api";
import {connect} from "react-redux";
import useBoolean from "../../../hooks/useBoolean";
import Questionnaire from "../../../components/Questionnaire/Questionnaire";
import LoaderIcon from "../../../components/Helper/LoaderIcon";
import Notifications from "react-notification-system-redux";
import {style} from "../../../variables/Variables";
import {NotifierContext} from "../../../utils/Notifier";

const loadingId = '@GCareLoader.gcare.get';
const sendLoadingId = '@GCareLoader.gcare.create';
const GCareLoader = ({ notifications, loadingIds})=>{

    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);
    const [notFound, setNotFound] = useBoolean(!window.location.pathname || window.location.pathname === '/');
    const [alreadyFilled, setAlreadyFilledTrue] = useBoolean();
    const [gcare, setGcare] = useState(null);

    let token;
    const path = window.location.pathname.split('/');
    if( path.length > 1)
        token = path[1];

    useEffect(()=>{
        if( notFound )
            return;

        api.gcare.get({token, loadingId})
            .then((gcare)=>{
                if( !gcare )
                    return setNotFound();

                if( gcare.filled )
                    return setAlreadyFilledTrue();

                setGcare(gcare);
            })
            .catch(setNotFound);
    },[api, token, setAlreadyFilledTrue, setNotFound, notFound]);

    const publicQuestionnaire = useMemo( ()=>removeLoungeAndShiftQuestions(gcare), [gcare] );

    const sendQuestionnaire = useCallback( ( answers )=>{
        api.gcare.create({filledQuestionnaire:{ answers, token},loadingId:sendLoadingId })
            .then(setAlreadyFilledTrue)
            .catch(()=>{
                notifier.error('Lo sentimos, hubo un error al enviar la encuesta. Intenta de nuevo más tarde.');
            });

    }, [api, notifier, token, setAlreadyFilledTrue] );

    let message;
    if( notFound )
        message = <h2 className='text-center'>Lo sentimos, la encuesta no fue encontrada.</h2>;
    else if ( alreadyFilled )
        message = <h2 className='text-center'>Encuesta completada <br/>¡Gracias por tu participación!</h2>;

    return (
        <div className='GCareLoader'>
            <Notifications notifications={notifications} style={style}/>
            <div className='background'/>
            <h1 className='title' >Encuesta de satisfacción</h1>
            {loadingIds[loadingId] ?
                <><h2 className='text-center'>Cargando &nbsp;<LoaderIcon/></h2></>
                :
                (message?
                        message:
                <>
                    <p className='intro'>The Grand Lounge Elite agradece que nos hayas acompañado durante tu estancia en el aeropuerto internacional de la Ciudad de México.</p>
                    <p className='intro'>Para la mejora constante de nuestro servicio, nos gustaría tener tu ayuda llenando una rápida encuesta.</p>
                    <Questionnaire
                        questionnaire={publicQuestionnaire}
                        manualSend={sendQuestionnaire}
                        loading={!!loadingIds[sendLoadingId]}
                    />
                </>
                )
            }

        </div>);
};

const mapStateToProps = ({notifications, loadingIds})=>({notifications, loadingIds});

export default  connect(mapStateToProps)(GCareLoader);

const removeLoungeAndShiftQuestions = (questionnaire)=>{

    if( !questionnaire || !questionnaire.questions ) return  questionnaire;

    const questions = questionnaire.questions.reduce( (acc, question)=>{
        if(question.type === 'shift' || question.type === 'lounge')
            return acc;
        acc.push(question);
        return acc;
    },[] );
    return {...questionnaire, questions};
};
