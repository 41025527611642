
import React, {useState, useContext, useEffect, useCallback} from 'react'
import {Grid, Row, Col } from 'react-bootstrap';
import BigCalendar from 'react-big-calendar';
import Card from '../../../components/Card/Card.jsx';
import moment from 'moment';
import {TideApiContext} from "../../../api/tideApiConfig";
import { ModalEvent } from './components/ModalEvent.jsx';
import {SecurityContext} from "../../../utils/SecurityManager";
import useSingleDatePickerState from "../../../hooks/useSingleDatePickerState";

export const Calendar = () => {
    const messages = { 
        allDay: 'Todo el día',
        agenda: 'Agenda', 
        previous: '<<', 
        next: '>>',
        today: 'Hoy', 
        month: 'Mes', 
        week: 'Semana', 
        day: 'Día', 
        date: 'Fecha', 
        time: 'Hora', 
        event: 'Evento', 
        showMore: total => `+ ${total} más`
    };

    const tideApi = useContext(TideApiContext);
    const securityManager = useContext(SecurityContext);
    const [editModalOpen, setEditModalOpen] = useState();
    const [events, setEvents] = useState();
    const [currentEvent, setCurrentEvent] = useState();
    const [currentDate, setCurrentDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const {props:datePickerProps} = useSingleDatePickerState();

    const reloadEvents = useCallback( ()=>{
        let filters = {
            'eventDate[after]': moment(currentDate).format('YYYY-MM-DD'),
            'eventDate[before]': moment(currentDate).add(1, 'year').format('YYYY-MM-DD'),
        }

        tideApi.events.get({ params: {pagination: false, ...filters}}).then(events => {
            let eventsList = [];
            
            events.forEach((event) => {
                eventsList.push({
                    'id': event.id,
                    'title': event.name,
                    'allDay': true,
                    'start': event.eventDate,
                    'end': event.eventDate
                });
            });

            setEvents(eventsList);
        });
    }, [tideApi, currentDate]);

    const handleSelectSlot = (e) => {
        setCurrentEvent(null);
        setEditModalOpen(true);
        datePickerProps.onDateChange(moment(e.slots[0]));
    }

    const handleSelectEvent = (e) => {
        setCurrentEvent(e);
        setEditModalOpen(true);
        datePickerProps.onDateChange(moment(e.start));
    }

    useEffect(() => {
        reloadEvents();
    }, [reloadEvents]);

    
    const handleNavigate = (date) => {
        if(moment(date).get('year')!== moment(currentDate).get('year')) {
            setCurrentDate(new Date(date.getFullYear(), 0, 1));
        }
    }

    const onClose = (reload=false) => {
        setEditModalOpen(false);
        setCurrentEvent(null);
        if(reload) reloadEvents();
    }

    return (
        <div className="calendar">
            <Grid fluid>
                <Row>
                    <Col md={10} mdOffset={1}>
                        {events && 
                            <Card
                                calendar
                                content={
                                    <BigCalendar
                                        titleAccessor='title'
                                        startAccessor='start'
                                        endAccessor='end'
                                        messages={messages}
                                        selectable
                                        views={['month']}
                                        events={events}
                                        defaultView='month'
                                        onSelectEvent={handleSelectEvent}
                                        onNavigate={handleNavigate}
                                        onSelectSlot={handleSelectSlot}
                                        popup={true}
                                    />
                                }
                            />}
                    </Col>
                </Row>
            </Grid>
            
            {securityManager.ee.canEditCalendar() && 
                <ModalEvent 
                    editModalOpen={editModalOpen}
                    onClose={onClose}
                    currentEvent={currentEvent}
                    datePickerProps={datePickerProps}
                />}
        </div>
    );
}
