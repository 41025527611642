import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Card from '../../../../components/Card/Card';
import ReactEcharts from 'echarts-for-react';
import {connect} from 'react-redux';

class EmployeeRotationGraph extends Component {

    getEmployeesRotationOptions(){

        let count = Number( this.props.employeesMeta.totalItems );
        const movements = this.props.staffRotationReport.result;

        let minVal = count - 20;
        let labels = [];
        let base = [];
        let lastBase = count;
        let hires = [];
        let layoffs = [];

        for( let move in movements ){

            const hiresC = Number( movements[move].hires? movements[move].hires : 0 );
            const layoffsC = Number( movements[move].layoffs? movements[move].layoffs : 0 );
            const newBase = lastBase + layoffsC - hiresC;

            labels = labels.concat([move, move]);

            base = base.concat([ lastBase , newBase ]);

            if( newBase < lastBase )
                minVal = newBase - 20;
            lastBase = newBase;

            hires = hires.concat(['-', hiresC]);
            layoffs = layoffs.concat([layoffsC, '-']);


        }

        labels.reverse();
        base.reverse();
        hires.reverse();
        layoffs.reverse();

        return {
            backgroundColor:'#f5f5f5',

            color:["#28ce81","#c23531"],

            tooltip : {
                trigger: 'axis',
                axisPointer : {
                    type : 'shadow'
                },
                formatter: function (params) {
                    let tar;
                    if (params[1].value !== '-') {
                        tar = params[1];
                    }
                    else if (params[2].value !== '-') {
                        tar = params[2];
                    }
                    return tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value;
                }
            },
            legend: {
                data:['Altas','Bajas']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type : 'category',
                splitLine: {show:false},
                data :  labels
            },
            yAxis: {
                type : 'value',
                min: minVal,
            },
            series: [
                {
                    name: 'Base',
                    type: 'bar',
                    stack: 'ST',
                    itemStyle: {
                        normal: {
                            barBorderColor: 'rgba(0,0,0,0)',
                            color: 'rgba(0,0,0,0)'
                        },
                        emphasis: {
                            barBorderColor: 'rgba(0,0,0,0)',
                            color: 'rgba(0,0,0,0)'
                        }
                    },
                    data: base //[180, 187, 187, 191, 191, 193]
                },
                {
                    name: 'Altas',
                    type: 'bar',
                    stack: 'ST',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: hires //[10, '-', 5, '-', 4, '-']
                },
                {
                    name: 'Bajas',
                    type: 'bar',
                    stack: 'ST',
                    label: {
                        normal: {
                            show: true,
                            position: 'bottom'
                        }
                    },
                    data: layoffs //['-', 3, '-', 1, '-', 2]
                }
            ]
        };
    }

    render() {

        return (
            <Card
                title={<span>Rotación de personal</span>}
                category="Últimos 6 meses"
                content={
                    this.props.staffRotationReport && this.props.employeesMeta?
                        <ReactEcharts
                            option={this.getEmployeesRotationOptions()}
                        />:
                    null
                }
                stats={
                    <div>
                        <NavLink to="/alma/employees/fired"><i className="fa fa-clock-o" /> Ver despidos</NavLink>
                    </div>
                }
            />
        );
    }
}


function mapStateToProps( {api:{staffRotationReport, employeesMeta}} ){

    return {staffRotationReport, employeesMeta}
}

export default connect(mapStateToProps)(EmployeeRotationGraph);
