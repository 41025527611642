//Loading reducer
export const APP_LOADING_START = 'APP_LOADING_START';
export const APP_LOADING_END = 'APP_LOADING_END';

//Erase the whole state
export const CLEAR_STATE = 'CLEAR_STATE';

//User detail modal
export const USER_DETAIL = 'USER_DETAIL';
export const HIDE_USER_DETAIL = 'HIDE_USER_DETAIL';

//Move between systems
export const CHANGE_SYSTEM = 'CHANGE_SYSTEM';

//Save table state to redux
export const SAVE_REACT_TABLE_STATE = 'SAVE_REACT_TABLE_STATE';

//Saves the url to redirect after a successful login
export const REDIRECT_AFTER_LOGIN = 'REDIRECT_AFTER_LOGIN';
