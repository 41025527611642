import React, {useCallback, useContext} from 'react';
import "./_GolfCartScheduleEditControl.scss";
import Card from "../../../../../../components/Card/Card";
import Switch from "react-bootstrap-switch";
import switchTranslations from "../../../../../../utils/switchTranslations";
import DayOfTheWeekSelector from "../../../../../../components/DayOfTheWeekSelector/DayOfTheWeekSelector";
import {Button} from "react-bootstrap";
import TimeSelector from "../../../../../../components/TimeSelector/TimeSelector";
import TideEntitySelect from "../../../../../../components/TideEntitySelect/TideEntitySelect";
import {getEmptyGolfCartScheduledTrip} from "../../../../../../utils/modelUtils/ee/golfCartScheduledTripUtils";
import {
    prepareGolfCartScheduleForServer,
    cartStatus
} from "../../../../../../utils/modelUtils/ee/golfCartScheduleUtils";
import {NotifierContext} from "../../../../../../utils/Notifier";
import {dayOfTheWeekNotation} from "../../../../../../utils/timeUtils";
import {useHistory} from "react-router-dom";
import {paths} from "../../../../../../routes/ee";
import {TideApiContext} from "../../../../../../api/tideApiConfig";
import useCallbackCreator from "../../../../../../hooks/useCallbackCreator";
import {selectLoungeFilters} from "../../../../../../utils/modelUtils/ee/loungesUtils";

const GolfCartScheduleEditControl = ({golfCartSchedule, onChange, editingTripIndex, setEditingTripIndex}) => {

    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);
    const history = useHistory();

    // ---- Change handlers ----
    const changeHandler = useCallbackCreator((property, val) => {
        onChange({...golfCartSchedule, [property]: val?.target?.value !== undefined ? val.target.value : val})
    }, [golfCartSchedule]);

    const handleActiveChange = useCallback(
        (s, value) => onChange({...golfCartSchedule, isActive: value})
        , [golfCartSchedule, onChange]);


    const editTripValue = useCallback((field, value) => {
        const editingTrip = {...golfCartSchedule.golfCartScheduledTrips[editingTripIndex]};
        editingTrip[field] = value;
        const newTrips = [...golfCartSchedule.golfCartScheduledTrips];
        newTrips[editingTripIndex] = editingTrip;
        onChange({...golfCartSchedule, golfCartScheduledTrips: newTrips})
    }, [golfCartSchedule, onChange, editingTripIndex]);

    const handleStartTimeChange = useCallback((value) => editTripValue("startTime", value)
        , [editTripValue]);

    const handleEndTimeChange = useCallback((value) => {
            editTripValue("endTime", value)
        }
        , [editTripValue]);

    const handleGatesChange = useCallback((value) => editTripValue("airportGates", value)
        , [editTripValue]);

    const addNewTrip = useCallback(() => {
        const trip = getEmptyGolfCartScheduledTrip();
        setEditingTripIndex(golfCartSchedule.golfCartScheduledTrips.length);
        onChange({
            ...golfCartSchedule,
            golfCartScheduledTrips: [...golfCartSchedule.golfCartScheduledTrips, trip]
        })
    }, [setEditingTripIndex, onChange, golfCartSchedule]);

    const deleteTrip = useCallback(() => {
        const newTrips = [...golfCartSchedule.golfCartScheduledTrips];
        newTrips.splice(editingTripIndex, 1);
        onChange({...golfCartSchedule, golfCartScheduledTrips: newTrips});
        setEditingTripIndex(null);
    }, [golfCartSchedule, editingTripIndex, onChange, setEditingTripIndex]);

    // ---- Send to server ----
    const saveSchedule = useCallback(() => {

        let preparedSchedule;
        try {
            preparedSchedule = prepareGolfCartScheduleForServer(golfCartSchedule);
        } catch (e) {
            return notifier.error(e.message);
        }

        if (golfCartSchedule.id) {
            api.golfCartSchedules.update({id: golfCartSchedule.id, params: preparedSchedule})
                .then(() => notifier.success("Horario guardado"));
        } else {
            api.golfCartSchedules.create({id: golfCartSchedule.id, params: preparedSchedule})
                .then(newSchedule => {
                    history.replace(paths.golfCartScheduleDetail.replace(":id", newSchedule.id));
                    notifier.success("Horario guardado");
                })
        }

    }, [api, golfCartSchedule, notifier, history]);

    const editingTrip = golfCartSchedule.golfCartScheduledTrips[editingTripIndex];

    return (
        <div className={"GolfCartScheduleEditControl"}>
            <Card title={"Edición de horario"}>
                <div className="container-fluid m-xs-0">
                    <div className="row">
                        <div className="col-xs-8">
                            <label className="control-label">Nombre del horario</label>
                            <input className="form-control" value={golfCartSchedule.name}
                                   onChange={changeHandler("name")}/>
                        </div>
                        <div className="col-xs-4">
                            <label className="control-label">Horario activo</label><br/>
                            <Switch onChange={handleActiveChange}
                                    value={golfCartSchedule.isActive} {...switchTranslations}/>
                        </div>
                    </div>
                    <hr/>
                    <h4>Días disponible</h4>
                    <DayOfTheWeekSelector
                        values={golfCartSchedule.availableDays}
                        onChange={changeHandler("availableDays")}
                        notation={dayOfTheWeekNotation.ENGLISH_CAPS}
                    />
                    <hr/>
                    <div className="row">
                        <div className="col-sm-6">
                            <h4>Carrito asignado:</h4>
                            <TideEntitySelect
                                value={golfCartSchedule.golfCart}
                                entity={'golfCarts'}
                                onChange={changeHandler("golfCart")}
                                filterBy='search'
                                placeholder={'Selecciona un carrito...'}
                                labelCreator={cartStatus}
                                preload
                            />
                        </div>
                        <div className="col-sm-6">
                            <h4>Sala de partida:</h4>
                            <TideEntitySelect
                                value={golfCartSchedule.lounge}
                                entity={'lounges'}
                                onChange={changeHandler("lounge")}
                                placeholder={'Selecciona una sala...'}
                                additionalFilters={selectLoungeFilters}
                                preload
                            />
                        </div>
                    </div>
                    <hr/>
                    <h4>{golfCartSchedule.golfCartScheduledTrips?.length} viajes programados</h4>
                    <hr/>

                    {editingTrip &&
                    <div className='trip-editor'>
                        <button className="delete-button" onClick={deleteTrip}>
                            <i className="fa fa-trash red-icon"/>
                        </button>
                        <h5>Detalles del viaje</h5>
                        <div className="row">
                            <div className="col-xs-6">
                                <label className="control-label">Hora de inicio</label>
                                <TimeSelector value={editingTrip.startTime} onChange={handleStartTimeChange}/>
                            </div>
                            <div className="col-xs-6">
                                <label className="control-label">Hora de finn</label><br/>
                                <TimeSelector value={editingTrip.endTime} onChange={handleEndTimeChange}/>
                            </div>
                        </div>
                        <h5>Puertas a recorrer</h5>
                        <TideEntitySelect
                            entity={"airportGates"}
                            labelCreator={ag => ag.gateNumber}
                            multi
                            value={editingTrip.airportGates}
                            additionalFilters={airportGatesFilters}
                            onChange={handleGatesChange}
                            filterLocal
                        />
                    </div>}

                    <br/>
                    <Button className='add-button' onClick={addNewTrip}>+ Agregar viaje</Button>
                    <hr/>
                    <Button bsStyle="success" className='save-button' onClick={saveSchedule}>Guardar</Button>
                </div>
            </Card>
        </div>
    );
};

const airportGatesFilters = {
    sGroups: [
        "airport_gate_read",
        "airport_gate_read_airport_gate_lounges",
        "airport_gate_lounge_read_eta_minutes",
        "airport_gate_lounge_read_lounge",
        "place_read_name",
        "place_read_id"
    ]
};

export default GolfCartScheduleEditControl;
