import React, {useContext, useCallback, useEffect, useState} from 'react';
import {Col, Row, Collapse} from "react-bootstrap";
import {ApiContext} from "../../../api/Api";
import PurchaseProductsTable from "../components/PurchaseProductsTable";
import Card from "../../../components/Card/Card";
import moment from "moment";
import _ from "lodash";
import CommentsFeed from "../../../components/CommentsFeed/CommentsFeed";
import {useSelector} from "react-redux";
import {SecurityContext} from "../../../utils/SecurityManager";
import POStatusSelector from "./POStatusSelector";
import POPaymentMethodSelector from "./POPaymentMethodSelector";
import useInput from "../../../hooks/useInput";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import {NavLink} from "react-router-dom";
import useBoolean from "../../../hooks/useBoolean";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import {purchaseOrderDetailSGroups, addAdjustmentToPurchaseOrderAndPrepareForServer} from "../../../utils/modelUtils/jazz/purchaseOrderUtils";
import PurchaseInvoicesAndPayments from "../components/PurchaseInvoicesAndPayments";
import POExpectedDate from './components/POExpectedDate';


const transactionLoadingId = '@PurchaseOrderDetail.purchaseOrder.edit-transaction';
const customProp = '@PurchaseOrderDetail.purchaseOrder';
const sGroupParam={sGroups: purchaseOrderDetailSGroups};
const PurchaseOrderDetail = ({match}) => {

    const api = useContext(ApiContext);
    const security = useContext(SecurityContext);

    const purchaseOrders = useSelector(({api}) => api[customProp]) || [];
    const loadingIds = useSelector(({loadingIds}) => loadingIds);

    const id = match.params.id;
    const [notFound, setNotFound] = useState(false);
    const po = _.find(purchaseOrders, (po) => po.id === id);
    const [addingTransaction, startAddingTransaction, stopAddingTransaction] = useBoolean();
    const transactionInput = useInput(po ? po.transactionNumber : '');

    const reload = useCallback(() => {
        if (!po && !notFound)
            api.purchaseOrders.get(0, 1, undefined, {id, sGroups: purchaseOrderDetailSGroups}, customProp)
                .then(orders => {
                    const po = _.find(orders, (po) => po.id === id);
                    if (!orders || !orders.length || !po)
                        setNotFound(true);
                })
        // eslint-disable-next-line
    }, [id]);

    useEffect(reload, [reload]);

    const changeTransactionNumber = useCallback(() => {
        api.purchaseOrders.edit({
            id: po.id,
            purchaseOrder: {transactionNumber: transactionInput.value, sGroups: purchaseOrderDetailSGroups}, customProp,
            loadingId: transactionLoadingId
        })
            .then(stopAddingTransaction);
    }, [po, transactionInput.value, api, stopAddingTransaction]);

    const markAsReceived = useCallback(() => {
        api.purchaseOrders.edit({id: po.id, purchaseOrder: {receivedDate: moment().format('YYYY-MM-DDTHH:mm'), sGroups: purchaseOrderDetailSGroups}, customProp})
    }, [po, api]);

    const handleAddAdjustment = useCallback((adjustment) => {
        if (!Number(adjustment.value)) return;

        const newPO = addAdjustmentToPurchaseOrderAndPrepareForServer(adjustment, po);
        api.purchaseOrders.edit({id: newPO.id, purchaseOrder: {...newPO, sGroups: purchaseOrderDetailSGroups}, customProp})
    }, [po, api]);

    const handleEditExpectedDate = useCallback(date=>{
        const expectedDate = date.format('YYYY-MM-DD');
        api.purchaseOrders.edit({
            id: po.id,
            purchaseOrder: {expectedDate, sGroups: purchaseOrderDetailSGroups}, customProp});
    }, [api, po]);

    return (
        <div>
            {po ?
                <div className="container-fluid main-container PurchaseOrderDetail">
                    <Row>
                        <Col xs={12}>
                            <Card
                                title={
                                    <div>
                                        <div className='pull-left'>
                                            <span className='provider-name'>{po.provider.name}</span>
                                            <br/>
                                            <NavLink to={'/compras/requisitions/' + po.purchaseRequisition.id}
                                                     className='requisition-link'>
                                                <span
                                                    className="medium">Solicitud de compra <b>#{po.purchaseRequisition.folio}</b></span>
                                            </NavLink>
                                            <br/>
                                            <span>Orden de compra <b>#{po.folio}</b>

                                                <a className='export-link' href={api.purchaseOrders.downloadPdfUrl(po.id)}
                                                   rel="noopener noreferrer">
                                                    <button type="button" className="btn btn-default">Exportar a PDF
                                                    </button>
                                                </a>

                                            </span>
                                            <br/>
                                            <span
                                                className="small">Solicitado por: {employeeFullName(po.purchaseRequisition.requestedBy)}</span>
                                            <br/>
                                            <span
                                                className="small">Puesto: {po.purchaseRequisition.requestedBy.position ? po.purchaseRequisition.requestedBy.position.name : 'Sin puesto'}</span>
                                            <br/>
                                            <span
                                                className="small">Área: {po.purchaseRequisition.jazzArea?.name ? po.purchaseRequisition.jazzArea.name : 'Sin área asignada'}</span>
                                            <br/>
                                            <POExpectedDate
                                                onEditSave={handleEditExpectedDate}
                                                expectedDate={po.expectedDate}/>
                                            <br/>


                                        </div>
                                        <div className='pull-right'>
                                            <span className='pull-right'>
                                                Fecha de solicitud {moment(po.purchaseRequisition.requestedDate).format("DD/MM/YYYY HH:mm")}
                                            </span>
                                            <br/>
                                            <span className='pull-right'>
                                                Fecha de cotización {moment(po.purchaseRequisition.quotedDate).format("DD/MM/YYYY HH:mm")}
                                            </span>
                                            <br/>
                                            <span className='pull-right'>
                                                {po.receivedDate ?
                                                    `Fecha de recepción ${moment(po.receivedDate).format("DD/MM/YYYY HH:mm")}`
                                                    :
                                                    security.jazz.canMarkPOAsReceived(po) ?
                                                        <CustomButton onClick={markAsReceived}>Marcar como
                                                            recibida</CustomButton>
                                                        :
                                                        "La orden no ha sido recibida"
                                                }
                                            </span>
                                            <div className='clearfix'/>



                                            <div className='pull-right transaction-btn'>
                                                {addingTransaction ?
                                                    <div className='transaction-group'>
                                                        <input
                                                            className='transaction-input'
                                                            placeholder='Escribe número de transacción'
                                                            {...transactionInput.bind}
                                                            disabled={!!loadingIds[transactionLoadingId]}
                                                        />
                                                        <CustomButton
                                                            className='save-btn'
                                                            onClick={changeTransactionNumber}
                                                            disabled={!!loadingIds[transactionLoadingId]}
                                                        >Guardar</CustomButton>
                                                    </div>
                                                    :
                                                    <CustomButton
                                                        onClick={security.jazz.canUpdateOrderStatus() ? startAddingTransaction : undefined}>
                                                        {po.transactionNumber ?
                                                            ('Transacción: ' + po.transactionNumber) :
                                                            security.jazz.canUpdateOrderStatus() ?
                                                                'Agregar número de transacción'
                                                                :
                                                                'Sin transacción'
                                                        }
                                                    </CustomButton>
                                                }
                                            </div>

                                        </div>

                                        <div className='clearfix'/>
                                        <hr/>

                                        <POPaymentMethodSelector
                                            purchaseOrder={po}
                                            className='pull-left'
                                            customProp={customProp}
                                            requestParameters={sGroupParam}
                                        />
                                        {po.status !== 'canceled' ?
                                        <POStatusSelector
                                            purchaseOrder={po}
                                            className='pull-right'
                                            customProp={customProp}
                                            requestParameters={sGroupParam}
                                        />
                                        :<CustomButton bsStyle='danger' className='pull-right'>Cancelada</CustomButton>

                                        }

                                        <div className='clearfix'/>
                                    </div>
                                }
                                category=" "
                                content={
                                    <div>
                                        <PurchaseProductsTable
                                            purchaseOrder={po}
                                            onAddAdjustment={handleAddAdjustment}
                                        />
                                        <PurchaseInvoicesAndPayments
                                            purchaseOrder={po}
                                            onChange={reload}
                                        />
                                        <hr/>
                                        <CommentsFeed
                                            height={650}
                                            title="Comentarios y archivos de orden de compra"
                                            cassetteId={po.cassette ? po.cassette.id : null}
                                        />
                                        <Collapse>
                                            <CommentsFeed
                                                height={650}
                                                title="Comentarios y archivos de solicitud de compra"
                                                cassetteId={po.purchaseRequisition.cassette ? po.purchaseRequisition.cassette.id : null}
                                            />
                                        </Collapse>
                                        <hr/>
                                    </div>

                                }
                            >
                            </Card>
                        </Col>
                    </Row>
                </div> : null}
        </div>

    );


};

export default PurchaseOrderDetail;
