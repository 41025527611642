import React, {useContext} from 'react';
import {ApiContext} from "../../api/Api";
import optionsFilter from "../optionsFilter";

export const XFilesContext = React.createContext(null);

export const loadingId = '@UploadFileModal.employeeXFile.create';
export const useUploadXFile = () => {
    const api = useContext(ApiContext);
    return {
        uploadXFile: async (employee, classification) => {
            const [apiMethod, extraParams] = (() => {
                const xFile = employee.employeeXFile;
                if (!xFile) {
                    return ['create', {}];
                }
                return ['edit', {id: xFile.id}];
            })();
            const keyFor = type => type === 'other' ? 'otherFiles' : type;
            const fileEntry = (o, type, file) => {
                if (type !== 'other') {
                    return file;
                }
                if (!o.otherFiles) {
                    return [file];
                }
                return o.otherFiles.concat([file]);
            };
            const toUpload = classification.reduce((o, [file, type]) => ({
                ...o,
                [keyFor(type)]: fileEntry(o, type, file)
            }), {});
            await api.employeeXFiles[apiMethod]({
                employee: employee.id,
                ...extraParams
            }, toUpload, {loadingId})
        },
        removeXFile: async (employee, type) => {
            if (!employee.employeeXFile) {
                return;
            }
            await api.employeeXFiles.edit({
                employee: employee.id,
                id: employee.employeeXFile.id,
                [type]: null
            }, {}, {loadingId});
        },
        removeOtherFile: async (employee, currentFiles, toDelete) => {
            if (!employee.employeeXFile) {
                return;
            }
            const otherFiles = currentFiles.filter((o, k) => k !== toDelete)
                .map(o => o.id);
            await api.employeeXFiles.edit({
                employee: employee.id,
                id: employee.employeeXFile.id,
                otherFiles
            }, {}, {loadingId});
        }
    };
};

export const fileCell = fileType => ({original: employee}) => {
    const {showUpload, showPreview} = useContext(XFilesContext);

    const file = (() => {
        if (!employee.employeeXFile) {
            return null;
        }
        return employee.employeeXFile[fileType];
    })();

    const showUploadModal = showUpload(fileType, employee);
    const showPreviewModal = showPreview(file, fileType, employee);

    if (!file) {
        return <UploadFile
            showUploadModal={showUploadModal}
        />;
    }
    return <ShowFile
        showPreviewModal={showPreviewModal}
    />;
};

const ShowFile = ({showPreviewModal}) => {
    return (
        <span style={styles.uploadIcon}>
            <i onClick={showPreviewModal} className='fa fa-eye'/>
        </span>
    );
};

const UploadFile = ({showUploadModal}) => {
    return (
        <span style={styles.uploadIcon}>
            <i onClick={showUploadModal} className='fa fa-upload'/>
        </span>
    );
};

export const filter = optionsFilter;

const styles = {
    uploadIcon: {
        color: '#11ab96',
        textAlign: 'center',
        width: '100%',
        cursor: 'grab'
    }
};
