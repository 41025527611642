import React, {useCallback, useMemo} from 'react';
import ReactEcharts from "echarts-for-react";
import _ from 'lodash';

const woman = 'path://M421.184,384.96l-81.728-20.437l-4.715-18.88c56.384-9.493,85.397-26.389,86.677-27.136c3.029-1.771,4.907-4.992,5.163-8.491c0.235-3.499-1.323-6.933-4.075-9.131C422.123,300.587,384,269.099,384,160c0-92.011-21.525-138.667-64-138.667h-6.293C298.389,6.528,286.891,0,256,0c-40.085,0-128,40.299-128,160c0,109.099-38.123,140.587-38.4,140.8c-2.837,2.133-4.437,5.525-4.267,9.067c0.192,3.563,2.112,6.763,5.163,8.597c1.28,0.768,30.016,17.749,86.741,27.221l-4.715,18.837L90.795,384.96C37.333,398.336,0,446.165,0,501.33C0,507.221,4.779,512,10.667,512h490.667c5.888,0,10.667-4.821,10.667-10.709C512,446.165,474.667,398.336,421.184,384.96z';
const man = 'path://M421.184,384.96l-81.728-20.437l-6.955-27.819c21.867-21.653,36.843-51.392,41.28-81.899c12.117-3.413,21.504-13.803,23.125-26.837l5.333-42.667c1.131-9.003-1.643-18.112-7.616-24.939c-3.499-4.011-7.915-7.04-12.8-8.896l1.963-40.171l7.979-8c12.011-12.779,21.973-34.688,1.152-66.475C376.917,12.395,349.739,0,312.107,0c-14.848,0-49.024,0-80.853,21.376C140.373,23.275,128,65.493,128,106.667c0,9.579,2.325,31.147,3.861,44.16c-5.483,1.728-10.453,4.928-14.336,9.344c-6.08,6.891-8.896,16.043-7.765,25.152l5.333,42.667c1.749,13.909,12.309,24.853,25.664,27.456c4.416,29.333,18.624,58.112,39.232,79.403l-7.424,29.717l-81.728,20.437C37.333,398.336,0,446.165,0,501.333C0,507.221,4.779,512,10.667,512h490.667c5.888,0,10.667-4.821,10.667-10.709C512,446.165,474.667,398.336,421.184,384.96z';
const question = 'path://M1088 1256v240q0 16-12 28t-28 12h-240q-16 0-28-12t-12-28v-240q0-16 12-28t28-12h240q16 0 28 12t12 28zm316-600q0 54-15.5 101t-35 76.5-55 59.5-57.5 43.5-61 35.5q-41 23-68.5 65t-27.5 67q0 17-12 32.5t-28 15.5h-240q-15 0-25.5-18.5t-10.5-37.5v-45q0-83 65-156.5t143-108.5q59-27 84-56t25-76q0-42-46.5-74t-107.5-32q-65 0-108 29-35 25-107 115-13 16-31 16-12 0-25-8l-164-125q-13-10-15.5-25t5.5-28q160-266 464-266 80 0 161 31t146 83 106 127.5 41 158.5z';

const labelSetting = (total)=>({
    show: true,
    position: 'outside',
    offset: [0, -20],
    formatter: ({value})=>value===0?'':(100*value/total).toFixed(0)+'%',
    textStyle: {
        fontSize: 18,
        fontFamily: 'Arial'
    }
});

const getOption = (maleTotal, femaleTotal, unspecifiedTotal) =>
      ((total=>({
    tooltip: {
    },
    title: {
        text: 'Género',
    },
    xAxis: {
        data: ['Mujeres', 'Hombres', 'Sin especificar'],
        axisLine:{show:false},
        axisTick:{show:false}
    },
    yAxis: {
        max: total,
        splitLine: {show: false}
    },
    series: [{
        type: 'pictorialBar',
        symbolClip: true,
        symbolBoundingData: total,
        label: labelSetting(total),
        data: [{
            value: femaleTotal,
            symbol: woman,
            itemStyle:{color:'#EF7B45'}
        }, {
            value: maleTotal,
            symbol: man,
            itemStyle:{color:'#D84727'}
        }, {
            value: unspecifiedTotal,
            symbol: question
        }],
        itemStyle:{color:'#000000'},
        z: 10
    }, {
        type: 'pictorialBar',
        symbolBoundingData: total,
        animationDuration: 0,
        itemStyle: {
            color: '#ccc'
        },
        data: [{
            value: 1,
            symbol: woman
        }, {
            value: 1,
            symbol: man
        }, {
            value: 1,
            symbol: question
        }]
    }]}))(maleTotal+femaleTotal+unspecifiedTotal));




const ManWomanStats = ({generationReport}) => {
    const totalGetter = useCallback(f => (v=>v?v.genderTotal:0)(_.find(generationReport, {gender:f})), [generationReport]);
    const maleTotal = useMemo(()=>parseInt(totalGetter('M')), [totalGetter]);
    const femaleTotal = useMemo(()=>parseInt(totalGetter('F')), [totalGetter]);
    const unspecifiedTotal = useMemo(()=>parseInt(totalGetter(null)), [totalGetter]);

    const echartsOption = useMemo(
        ()=>getOption(maleTotal, femaleTotal, unspecifiedTotal),
        [maleTotal, femaleTotal, unspecifiedTotal]
    );

    return (
        <ReactEcharts option={echartsOption}/>
    );
};

export default ManWomanStats;
