import React, {useState, useContext, useRef} from 'react';
import {Modal, Table} from 'react-bootstrap';
import moment from 'moment';
import Button from "../../../elements/CustomButton/CustomButton";
import {ApiContext} from "../../../api/Api";


const ACReservationDetailModal = ({reservation, onHide}) => {

    const [status, setStatus] = useState(reservation.status);
    const api = useContext(ApiContext);

    const changedStatus = useRef(({target: {value}}) => {
        setStatus(value);
        api.airConciergeReservations.edit(reservation.id, {status: value})
    }).current;


    return (
        <Modal
            show={true}
            onHide={onHide}
        >
            <Modal.Header>
                <Modal.Title>Detalle de reservación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <tbody>
                    <tr>
                        <td>Método de reserva</td>
                        <td>{reservation.source}</td>
                    </tr>
                    <tr>
                        <td>Estado</td>
                        <td>
                            <select className='form-control' value={status} onChange={changedStatus}>
                                <option value='requested'>Solicitado</option>
                                <option value='finished'>Completado</option>
                                <option value='canceled'>Cancelado</option>
                            </select>
                        </td>
                    </tr>
                    </tbody>
                </Table>


                {reservation.outboundReservationDate ?
                    <div>
                        <h3>Viaje de ida</h3>
                        <hr/>

                        <h4>Personas</h4>
                        <Table>
                            <tbody>

                            {reservation.outboundAirConciergePassengers.map((passenger, i) =>
                                <React.Fragment key={passenger.id}>
                                    <tr>
                                        <td><strong>Persona {i + 1}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Nombre</td>
                                        <td>{passenger.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Apellidos</td>
                                        <td>{passenger.lastNames}</td>
                                    </tr>
                                    {passenger.email ?
                                        <tr>
                                            <td>Email</td>
                                            <td>{passenger.email}</td>
                                        </tr>
                                        : null}
                                    {passenger.phone ?
                                        <tr>
                                            <td>Teléfono</td>
                                            <td>{passenger.phone}</td>
                                        </tr>
                                        : null}
                                </React.Fragment>
                            )}

                            </tbody>
                        </Table>

                        <h4>Equipaje</h4>

                        <Table>
                            <tbody>
                            <tr>
                                <td>Número de piezas</td>
                                <td>{reservation.outboundLuggage.length}</td>
                            </tr>

                            {reservation.outboundLuggage.map((luggage, i) =>
                                <React.Fragment key={luggage.id}>
                                    <tr>
                                        <td><strong>Maleta {i + 1}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Peso</td>
                                        <td>{luggage.weight}</td>
                                    </tr>
                                    <tr>
                                        <td>Tamaño</td>
                                        <td>Alto {luggage.height}cm x Largo {luggage.width}cm x
                                            Ancho {luggage.depth}cm
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )}

                            </tbody>
                        </Table>

                        <h4>El viaje</h4>

                        <Table>
                            <tbody>
                            <tr>
                                <td>Fecha</td>
                                <td>{moment(reservation.outboundReservationDate).format('DD/MM/YYYY HH:mm')}</td>
                            </tr>
                            <tr>
                                <td>Dirección de recogida</td>
                                <td><a
                                    href={"https://www.google.com/maps/search/?api=1&query=" + reservation.outboundPickupAddress}
                                    target='_blank' rel="noopener noreferrer">{reservation.outboundPickupAddress}</a>
                                </td>
                            </tr>
                            <tr>
                                <td>Helipuerto</td>
                                <td>{reservation.outboundHeliport}</td>
                            </tr>
                            <tr>
                                <td>Terminal</td>
                                <td>{reservation.outboundTerminal}</td>
                            </tr>
                            <tr>
                                <td>Notas adicionales</td>
                                <td>{reservation.outboundNotes}</td>
                            </tr>

                            </tbody>
                        </Table>
                    </div> : null}


                {reservation.returnReservationDate ?
                    <div>
                        <h3>Viaje de regreso</h3>
                        <hr/>

                        <h4>Personas</h4>
                        <Table>
                            <tbody>

                            {reservation.returnAirConciergePassengers.map((passenger, i) =>
                                <React.Fragment key={passenger.id}>
                                    <tr>
                                        <td><strong>Persona {i + 1}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Nombre</td>
                                        <td>{passenger.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Apellidos</td>
                                        <td>{passenger.lastNames}</td>
                                    </tr>
                                    {passenger.email ?
                                        <tr>
                                            <td>Email</td>
                                            <td>{passenger.email}</td>
                                        </tr>
                                        : null}
                                    {passenger.phone ?
                                        <tr>
                                            <td>Teléfono</td>
                                            <td>{passenger.phone}</td>
                                        </tr>
                                        : null}
                                </React.Fragment>
                            )}

                            </tbody>
                        </Table>

                        <h4>Equipaje</h4>

                        <Table>
                            <tbody>
                            <tr>
                                <td>Número de piezas</td>
                                <td>{reservation.returnLuggage.length}</td>
                            </tr>

                            {reservation.returnLuggage.map((luggage, i) =>
                                <React.Fragment key={luggage.id}>
                                    <tr>
                                        <td><strong>Maleta {i + 1}</strong></td>
                                    </tr>
                                    <tr>
                                        <td>Peso</td>
                                        <td>{luggage.weight}</td>
                                    </tr>
                                    <tr>
                                        <td>Tamaño</td>
                                        <td>Alto {luggage.height}cm x Largo {luggage.width}cm x
                                            Ancho {luggage.depth}cm
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )}

                            </tbody>
                        </Table>

                        <h4>El viaje</h4>

                        <Table>
                            <tbody>
                            <tr>
                                <td>Fecha</td>
                                <td>{moment(reservation.returnReservationDate).format('DD/MM/YYYY HH:mm')}</td>
                            </tr>
                            <tr>
                                <td>Dirección destino</td>
                                <td><a
                                    href={"https://www.google.com/maps/search/?api=1&query=" + reservation.returnPickupAddress}
                                    target='_blank' rel="noopener noreferrer">{reservation.returnDestinationAddress}</a>
                                </td>
                            </tr>
                            <tr>
                                <td>Helipuerto</td>
                                <td>{reservation.returnHeliport}</td>
                            </tr>
                            <tr>
                                <td>Terminal</td>
                                <td>{reservation.returnTerminal}</td>
                            </tr>
                            <tr>
                                <td>Notas adicionales</td>
                                <td>{reservation.returnNotes}</td>
                            </tr>

                            </tbody>
                        </Table>
                    </div> : null}

                {reservation.discountCoupon && <h4>Cupón de descuento: { reservation.discountCoupon }</h4>}

            </Modal.Body>

            <Modal.Footer>
                <Button bsStyle='danger' onClick={onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );

};

export default ACReservationDetailModal;
