import React, {useEffect, useContext} from 'react';
import Card from '../../../components/Card/Card.jsx';
import SimpleBlockTable from '../../../components/Table/SimpleBlockTable';
import {connect} from 'react-redux';
import {ApiContext} from "../../../api/Api";

const MeasurementUnitsList = ({measurementUnits})=>{

    const api = useContext(  ApiContext );

    useEffect( ()=>{
        api.measurementUnits.get({page:0,pageSize:500});
    }, [api] );

    return(
        <Card
            title="Administración de unidades de medida"
            category={<span className='text-muted'>¡Advertencia! Modificar el nombre de una unidad de medida se modificará para todos los productos que la utilizan.</span>}
            content={
                <SimpleBlockTable
                    data = {measurementUnits}
                    accessor = "name"
                    idAccessor = "id"
                    text = {{
                        addModal:"Nueva unidad de medida:",
                        addPlaceholder:"Nombre",
                        deleteConfirm:"¿Estás seguro que deseas borrar esta unidad de medida?",
                        editModal:"Editar nombre de la unidad de medida:"
                    }}
                    onAdd = {(name)=>api.measurementUnits.create({measurementUnit:{name}})}
                    onDelete = {(id)=>api.measurementUnits.delete({id})}
                    onEdit = {(id, name)=>api.measurementUnits.edit({id, measurementUnit:{name}})}
                />
            }
        />
    );
};

const mapStateToProps = ( {api:{measurementUnits=[]}} )=>({measurementUnits});

export default connect(mapStateToProps)(MeasurementUnitsList);
