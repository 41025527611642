import {productUnitConversionSGroups} from "./productsUtils";
import React from "react";

export const stockMovementStatus={
    PENDING:'pending',
    CANCELED:'canceled',
    COMPLETED:'completed'
};

export const stockMovementInventoryListSGroups = [
    'stock_movement_read',
    'stock_movement_read_product_brand',
        'product_brand_read_product',
            ...productUnitConversionSGroups,
        'product_brand_read_brand',
            'brand_read_name',
    'stock_movement_read_batch',
        'stock_movement_batch_read_stock_request',
            'stock_request_read_folio',
            'stock_request_read_created_by',
                'user_read_employee',
                    'employee_read_name',
                    'employee_read_pat_last_name',
                    'employee_read_mat_last_name',
            "stock_request_read_to_place",
                'place_read_name',
            "stock_request_read_from_warehouse",
        'stock_movement_batch_read_purchase_order',
            'purchase_order_read_purchase_requisition',
                'purchase_requisition_read_folio',
                'purchase_requisition_read_requested_by',
            // 'purchase_order_read_provider',
            //     'provider_read_name',
    'stock_movement_read_completed_by',
    'stock_movement_read_completed_date',
    'stock_movement_read_orozco_order_product',
        'orozco_order_product_read_orozco_product',
            'orozco_product_read_name'
];

export const stockMovementProductDetailSGroups = [
    'stock_movement_read_id',
    'stock_movement_read_is_adjustment',
    'stock_movement_read_completed_by',
        'employee_read_full_name',
    'stock_movement_read_quantity',
    'stock_movement_read_product_brand',
        'product_brand_read_product',
            ...productUnitConversionSGroups,
        'product_brand_read_brand',
            'brand_read_name',
    'stock_movement_read_completed_date',
    'stock_movement_read_stock_amount_after_applied',
    'stock_movement_read_orozco_order_product',
        'orozco_order_product_read_orozco_product',
            'orozco_product_read_name'
];

export const getStockMovementTypeElement = (stockMovement)=>{
    if(stockMovement.quantity>0){
        return (
            <span><i className='fa fa-sign-in green'/> Entrada {stockMovement.isAdjustment?"(ajuste)":""}</span>
        )
    }
    else{
        return (
            <span><i className="fa fa-sign-out red"/> Salida {stockMovement.isAdjustment?"(ajuste)":""}</span>
        )
    }
}