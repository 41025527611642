import React, { Component } from 'react';
import ReactTable from 'react-table';
import moneyFormatter from '../../../utils/moneyFormatter';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Modal, Grid, Row, Col, Panel} from 'react-bootstrap';
import moment from 'moment';
import Button from '../../../elements/CustomButton/CustomButton';
import ReactTableTranslation from '../../../utils/ReactTableTranslation';
import {Link} from 'react-router-dom';
import PayrollDetailExplanation from "./components/PayrollDetailExplanation";
import AddDiscountModal from './components/AddDiscountModal';
import AddBonusModal from './components/AddBonusModal';
import LoaderIcon from '../../../components/Helper/LoaderIcon';
import {UserDetailApiProp, UserDetailLoadingId} from '../Employees/core/UserDetail';
import zipcelx from '../../../es6vendor/zipcelx/zipcelx';
import _ from 'lodash';
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";


class PayrollDetail extends Component{

    static contextTypes = { api:PropTypes.object, notifier: PropTypes.object };

    loadingId = "@Class.PayrollList.get";
    loadingIdJustifying = "@Class.PayrollList.justifying ";
    loadingIdcalculate = "@Class.PayrollList.calculate-payroll ";
    loadingIdaddBonus= "@Class.PayrollList.addBonus";
    loadingIdremoveBonus = "@Class.PayrollList.removeBonus";
    loadingIdaddDiscount = "@Class.PayrollList.addDiscount";
    loadingIdremoveDiscount = "@Class.PayrollList.removeDiscount";
    loadingIdDisapprove= "@Class.PayrollList.disapprovePayroll";

    constructor( props, context ){
        super(props);

        this.state = {
            isAdvancedView: false,
            detailOpen:false,
            justifying: false,
            isRealTime: false,
            justifyDetail: {
                incidenceType: 0,
                comment: ""
            },
            columns: this.availableColumns
        };

        this.justify = this.justify.bind(this);
        this.loadData = this.loadData.bind(this);
        this.justifyOpen = this.justifyOpen.bind(this);
        this.applyDiscount = this.applyDiscount.bind(this);
        this.blockPayrollClick = this.blockPayrollClick.bind(this);
        this.showEmployeeDetail = this.showEmployeeDetail.bind(this);
        this.disapprovePayroll = this.disapprovePayroll.bind(this);
        this.approvePayroll = this.approvePayroll.bind(this);
        this.removeDiscount = this.removeDiscount.bind(this);
        this.removeBonus = this.removeBonus.bind(this);
        this.applyBonus = this.applyBonus.bind(this);

        context.api.bonuses.get({isAutomatic: false});
        context.api.incidences.get();
    }

    componentDidMount(){
        //if( this.props.match.params.id === "actual" )
            this.loadData();
    }

    loadData( ){//tableState ){

        const payrollId = this.props.match.params.id;
        if( payrollId === "actual" )
            this.context.api.payrollDetailsRealTime.get( this.loadingId );
        else {

            /*
            const sort = tableState.sorted.reduce( (acc, val)=>{acc[`order[${val.id}]`] = val.desc?"DESC":"ASC"; return acc;}, {} );
            const filter = tableState.filtered.reduce( (acc, val)=>{acc[val.id] = val.value; return acc;}, {} );

            if( !tableState.sorted.length )
                sort['order[employee.name]'] = 'ASC';

            this.context.api.payrollDetails.get(tableState.page, tableState.pageSize, this.loadingId, {payroll: payrollId, ...sort, ...filter});
            */
            this.context.api.payrollDetails.get(0, 100000, this.loadingId, {payroll:payrollId});
        }
    }

    showInfo( info, index ){

        this.setState({
            detailOpen: true,
            payrollDetail: info,
            payrollDetailIndex:index
        });
    }

    justifyOpen(inc){
        this.setState({justifying:true, incidenceJustifying: inc});
    }

    onJustifyChange(field, value){

        let justifyDetail = this.state.justifyDetail;
        justifyDetail[field] = value;

        this.setState({justifyDetail});
    }

    justify(){

        const {incidenceType, comment} = this.state.justifyDetail;
        if( !incidenceType ) {
            this.context.notifier.error("Debes elegir un tipo de incidencia.");
            return;
        }

        const appInc = this.state.incidenceJustifying;
        this.context.api.appliedIncidences
            .singleEdit(appInc.id, { incidence:incidenceType, comment, approvedBy:this.props.me.id  }, this.loadingIdJustifying )
            .then(()=>this.context.api.payrollDetailsRealTime.refresh( this.state.payrollDetail.employee.id))
            .then(()=>this.setState({justifying:false, incidenceJustifying:null}));
    }

    UNSAFE_componentWillReceiveProps( nextProps ){

        let newState = {};

        const payrollId = nextProps.match.params.id;
        if( this.props.match.params.id !== nextProps.match.params.id )
            this.loadData();

        //When we receive a payroll list we check inside the first detail if it was calculated at the moment
        if( this.props.loadingIds[ this.loadingId ] && !nextProps.loadingIds[ this.loadingId ] && nextProps.list.length ){
            if( nextProps.list[0].calculatedRealTime !== undefined )
                this.setState({isRealTime:nextProps.list[0].calculatedRealTime});
        }

        //When the payroll is approved or disapproved we reload the table
        if( payrollId === "actual" && (
            (this.props.loadingIds[ this.loadingIdDisapprove ] === true && nextProps.loadingIds[ this.loadingIdDisapprove ] === false) ||
            (this.props.loadingIds[ this.loadingIdcalculate ] === true && nextProps.loadingIds[ this.loadingIdcalculate ] === false)
            )){

            this.setState({disapprovePayrollOpen:false});
            this.loadData();
        }

        //When an incidence changes, close justify modal and refresh the justified employee
        if( this.props.loadingIds[ this.loadingIdJustifying ] === true && nextProps.loadingIds[ this.loadingIdJustifying ] === false ){
            this.context.api.payrollDetailsRealTime.refresh( this.state.payrollDetail.employee.id );
            newState.justifying = false;
        }

        //When a change in an employee's payroll happens, it is replaced in the list by the api so we read it to show the changes on the detail's modal
        if( this.state.detailOpen && nextProps.list !== this.props.list ){
            newState.payrollDetail = nextProps.list[this.state.payrollDetailIndex];
        }

        this.setState( newState );
    }

    blockPayrollClick(){
        if( this.props.match.params.id !== "actual" )
            return;

        if( this.state.isRealTime )
            this.setState( {approvePayrollOpen:true} );
        else
            this.setState( {disapprovePayrollOpen:true} );
    }

    approvePayroll(){
        const date = new Date();
        this.context.api.calculatePayroll(date.getFullYear(), date.getMonth()+1, date.getDate()<16? 1:2, this.loadingIdcalculate )
    }
    disapprovePayroll(){

        if( !this.props.list || !this.props.list.length || !this.props.list[0].payroll || this.props.loadingIds[ this.loadingIdDisapprove ] )
            return;

        this.context.api.clearProperty('calculatePayroll');
        this.context.api.payrolls.delete(this.props.list[0].payroll.id, this.loadingIdDisapprove )

    }

    // Discount can only be applied to this.state.payrollDetail ( the payroll being examined )
    applyDiscount( amount, comment ){

        if( !this.state.payrollDetail )
            return;

        this.setState({addingDiscount:false});
        const employeeId = this.state.payrollDetail.employee.id;

        this.context.api.appliedDiscounts.create(moment().format("YYYY-MM-DD"), employeeId, amount, comment, this.loadingIdaddDiscount)
            .then( ()=> this.context.api.payrollDetailsRealTime.refresh( this.state.payrollDetail.employee.id ) );
    }

    removeDiscount( id ){
        this.context.api.appliedDiscounts.delete(id, this.loadingIdremoveDiscount)
            .then( ()=> this.context.api.payrollDetailsRealTime.refresh( this.state.payrollDetail.employee.id ) );

    }

    // only this.state.payrollDetail can be discounted
    applyBonus( amount, comment, type ){

        if( !this.state.payrollDetail )
            return;

        this.setState({addingBonus:false});
        this.context.api.appliedBonuses.create(type, moment().format("YYYY-MM-DD"), this.state.payrollDetail.employee.id, amount, comment, this.loadingIdaddBonus)
            .then( ()=> this.context.api.payrollDetailsRealTime.refresh( this.state.payrollDetail.employee.id ) );

    }

    removeBonus( id ){
        this.context.api.appliedBonuses.delete(id, this.loadingIdremoveBonus)
            .then( ()=> this.context.api.payrollDetailsRealTime.refresh( this.state.payrollDetail.employee.id ) );

    }

    showEmployeeDetail( employee ){
        this.context.api.users.get(0,1,UserDetailLoadingId,{employee},UserDetailApiProp );
    }

    availableColumns = [
        {
        Header: 'Nombre',
        enabled: true,
        accessor: (detail) => (detail.employee.name + " " + detail.employee.patLastName + " " + detail.employee.matLastName) ,
        id: "employee.name",
        sortable: true,
        filterable:true,
        filterMethod:(filter, row) =>row['employee.name'].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
    },{
        Header: 'Puesto',
        enabled: false,
        accessor: (detail) =>detail.employee.position? detail.employee.position.name:'',
        id: "employee.position.name",
        sortable: true,
        filterable:true,
        filterMethod:(filter, row) =>row['employee.position.name'].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
    },{
        Header: 'Sala',
        enabled: false,
        accessor: (detail) =>detail.employee.lounge? detail.employee.lounge.name:'',
        id: "employee.lounge.name",
        sortable: true,
        filterable:true,
        filterMethod:(filter, row) =>row['employee.lounge.name'].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
    },{
        Header: 'Turno',
        enabled: false,
        accessor: (detail) =>detail.employee.shift? `${detail.employee.shift.type} - ${detail.employee.shift.name}`:'',
        id: "employee.shift.type",
        sortable: true,
        filterable:true,
        filterMethod:(filter, row) =>row['employee.shift.type'].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
    },{
        Header: 'Fecha de ingreso',
        enabled: false,
        accessor: (detail) =>moment(detail.employee.hireDate).format('DD/MM/YYYY'),
        id: "employee.hireDate",
        sortable: true,
        sortMethod: (a, b) => moment(a,'DD/MM/YYYY').isBefore(moment(b,'DD/MM/YYYY'))? 1 : -1,
    },{
        Header: 'Outsourcing',
        enabled: false,
        accessor: (detail) =>detail.employee.hiredByOutsourcing?'Sí':'No',
        id: "employee.hiredByOutsourcing",
        sortable: true,
    }, {
        Header: 'Sueldo base',
        enabled: true,
        accessor: (detail) => moneyFormatter(detail.salary),
        id: "salary",
        sortMethod: (a, b) => (Number(a.replace(/(^\$|,)/g, '')) < Number(b.replace(/(^\$|,)/g, '')) ? 1 : -1),
        sortable: true,
    }, {
        Header: 'Deducciones',
        enabled: true,
        accessor: (detail) => moneyFormatter(detail.totalDiscount),
        id: "totalDiscount",
        sortMethod: (a, b) => (Number(a.replace(/(^\$|,)/g, '')) < Number(b.replace(/(^\$|,)/g, '')) ? 1 : -1),
        sortable: true,
    }, {
        Header: 'Percepciones',
        enabled: true,
        accessor: (detail) => moneyFormatter(detail.totalBonus),
        id: "totalBonus",
        sortMethod: (a, b) => (Number(a.replace(/(^\$|,)/g, '')) < Number(b.replace(/(^\$|,)/g, '')) ? 1 : -1),
        sortable: true,
    }, {
        Header: 'No. de Faltas',
        enabled: false,
        accessor: (detail) =>detail.appliedDiscounts.reduce((acc, disc)=>acc+disc.appliedIncidences.reduce((acc, appInc)=>appInc.incidence.code ==='F'?++acc:acc ,0),0),
        id: "faltas",
        sortable: true,
    }, {
        Header: 'No. de Retardos',
        enabled: false,
        accessor: (detail) =>detail.appliedDiscounts.reduce((acc, disc)=>acc+disc.appliedIncidences.reduce((acc, appInc)=>appInc.incidence.code ==='R'?++acc:acc ,0),0),
        id: "retardos",
        sortable: true,
    }, {
        Header: 'Horas extra',
        enabled: false,
        accessor: (detail) => {
            if( !detail.appliedBonuses || !detail.appliedBonuses.length )
                return '$ 0';
            const bonus = _.find(detail.appliedBonuses, (appBon)=> appBon.bonus.code === 'HE' );

            if( !bonus )
                return '$ 0';
            return moneyFormatter(bonus.amount);
        },
        id: "extraHours",
        sortMethod: (a, b) => (Number(a.replace(/(^\$|,)/g, '')) < Number(b.replace(/(^\$|,)/g, '')) ? 1 : -1),
        sortable: true,
    }, {
        Header: 'Puntualidad nocturno',
        enabled: false,
        accessor: (detail) => {
            if( !detail.appliedBonuses || !detail.appliedBonuses.length )
                return '$ 0';
            const bonus = _.find(detail.appliedBonuses, (appBon)=> appBon.bonus.code === 'PN' );

            if( !bonus )
                return '$ 0';
            return moneyFormatter(bonus.amount);
        },
        id: "nightPuntuality",
        sortMethod: (a, b) => (Number(a.replace(/(^\$|,)/g, '')) < Number(b.replace(/(^\$|,)/g, '')) ? 1 : -1),
        sortable: true,
    },{
        Header: 'Premio de puntualidad',
        enabled: false,
        accessor: (detail) => {
            if( !detail.appliedBonuses || !detail.appliedBonuses.length )
                return 'No';
            const bonus = _.find(detail.appliedBonuses, (appBon)=> appBon.bonus.code === 'P' );
            return bonus? 'Sí':'No';
        },
        id: "puntuality",
        sortable: true,
    }, {
        Header: 'Bono de comida',
        enabled: false,
        accessor: (detail) =>moneyFormatter(detail.totalFoodBonus),
        id: "totalFoodBonus",
        sortMethod: (a, b) => (Number(a.replace(/(^\$|,)/g, '')) < Number(b.replace(/(^\$|,)/g, '')) ? 1 : -1),
        sortable: true,
    }, {
        Header: 'Método de pago',
        enabled: false,
        accessor: (detail) =>paymentMethodString(detail.employee.paymentMethod),
        id: "employee.paymentMethod",
        sortable: true,
        filterable: true,
        filterMethod:(filter, row) =>row['employee.paymentMethod'].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
    }, {
        Header: 'Total',
        enabled: true,
        accessor: (detail) => moneyFormatter(detail.total),
        id: 'total',
        sortMethod: (a, b) => (Number(a.replace(/(^\$|,)/g, '')) < Number(b.replace(/(^\$|,)/g, '')) ? 1 : -1),
        sortable: true,
    }, {
        Header: 'Últimos cambios',
        enabled: true,
        accessor: (detail) => {
            return detail.updatedFields.join(', ');
        },
        id: 'updatedFields',
        sortable: false,
    }];

    toggleColumn = ( colId, show )=>{

        const newCols = this.state.columns.map( (col)=>{
            if( col.id === colId )
                col = {...col, enabled:show };
            return col;
        } );
        this.setState({columns:newCols});
    };

    export = ()=>{

        const rtState = this.refs.reactTable.state;
        let rows = rtState.sortedData;
        // if( rtState.manual )
        //     rows = rtState.sortedData;
        // else
        //     rows = rtState.sortedData.slice( rtState.page * rtState.pageSize, rtState.page * rtState.pageSize + rtState.pageSize );

        const headers = this.state.columns.reduce( (acc, colDef)=> {
            if( colDef.enabled )
                acc.push({type: 'string', value: colDef.Header});
            return acc;
        }, []);

        const data = rows.map( (row)=>
            this.state.columns.reduce( (acc, colDef)=> {
                if( colDef.enabled )
                    acc.push({type: 'string', value: row[colDef.id] });
                return acc;
            }, [])
        );

        data.unshift( headers );

        const config = {
            filename: `Detalle-de-nómina-${this.props.list.length? this.props.list[0].payroll.period : '' }`,
            sheet: {
                data
            }
        };

        zipcelx(config);
    };

    render(){

        const payrollId = this.props.match.params.id;


        let config = {manual:false};


        return(
            <div className="main-container payroll-detail">

                {this.state.isAdvancedView ?
                    <Panel className={'advanced-panel'}>
                        <Grid fluid>
                        <p>Selecciona las columnas a mostrar:</p>
                        <Row>
                            {this.state.columns.map( col=>
                            <Col lg={2} md={3} sm={2} className='advanced-check-container' key={col.id}>
                                <label htmlFor={col.id} >{col.Header}</label> &nbsp;
                                <input
                                    id={col.id}
                                    type='checkbox'
                                    checked={col.enabled}
                                    onChange={(e)=>this.toggleColumn(col.id, e.target.checked)}
                                />
                            </Col>
                            )}
                        </Row>
                        </Grid>
                    </Panel>
                    :
                    <div>
                        <Button className='pull-right btn-primary' onClick={()=>this.setState({isAdvancedView:true})}><i className='fa fa-cog'/> Abrir visor avanzado</Button>
                        <div className='clearfix'/>
                    </div>
                }

                <ReactTable
                    data={this.props.list}
                    columns = {this.state.columns.filter( col=>col.enabled )}
                    defaultPageSize= {10}
                    className="-striped -highlight"
                    loading={!!this.props.loadingIds[this.loadingId]}
                    getTrProps={(state, rowInfo)=>({onClick:()=>{this.showInfo(rowInfo.original, rowInfo.index)}}) }
                    pageSizeOptions={[10,20,50,100,1000]}
                    minRows={3}
                    {...config}
                    {...ReactTableTranslation}
                    ref='reactTable'
                />

                {payrollId === "actual" && this.props.list.length ?
                <Button className="col-xs-12 approve-payroll btn-danger" onClick={this.blockPayrollClick}>
                    {this.state.isRealTime? "Bloquear Nómina":"Desbloquear nómina" }
                </Button>
                    :null}

                <Button onClick={this.export} className='export-button' >Exportar a Excel</Button>


                {/* Modal to see one employee's payroll detail */}
                {this.state.detailOpen?
                <Modal show={this.state.detailOpen} bsSize="large" onHide={() => this.setState({detailOpen: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Detalle de <span className="span-but" onClick={()=>this.showEmployeeDetail(this.state.payrollDetail.employee.id)}>{employeeFullName(this.state.payrollDetail.employee)}</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <PayrollDetailExplanation
                            payrollDetail={this.state.payrollDetail}
                            onJustify={payrollId === "actual" && this.state.isRealTime? this.justifyOpen : undefined }
                            canModify={ payrollId === "actual" && this.state.isRealTime }
                            onAddDiscountClick={()=>this.setState({addingDiscount:true})}
                            onRemoveDiscount={this.removeDiscount}
                            onAddBonusClick={()=>this.setState({addingBonus:true})}
                            onRemoveBonus={this.removeBonus}
                        />
                    </Modal.Body>
                </Modal>
                    :null}

                {/* Justify incidence Modal */}
                {this.state.justifying?
                    <Modal show={this.state.detailOpen} onHide={() => this.setState({justifying: false})} className="justify-modal">
                        <Modal.Header closeButton>
                            <Modal.Title>Justificar incidencia</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h3>Justificar incidencia:</h3>
                            <p>Se va a justificar
                                {this.state.incidenceJustifying.incidence.code === "F"? " la falta ":null }
                                {this.state.incidenceJustifying.incidence.code === "R"? " el retardo ":null }
                                del {moment(this.state.incidenceJustifying.shiftDate).format("DD/MM/YYYY") }
                                , selecciona el tipo de incidencia por el cuál cambiará:</p>
                            <select
                                className="form-control"
                                onChange={(e)=>this.onJustifyChange("incidenceType",e.target.value)}
                                value={this.state.justifyDetail.incidenceType}
                            >
                                <option value=''>Selecciona un tipo de incidencia</option>
                                {this.props.incidences.map((inc)=>{
                                    if( (this.state.incidenceJustifying.incidence.code === "F" && inc.justifyAbsence) ||
                                        ( this.state.incidenceJustifying.incidence.code === "R" && inc.justifyRetardment)||
                                        ( this.state.incidenceJustifying.incidence.code === "MACO" && inc.justifyMoreMealTime )||
                                        ( this.state.incidenceJustifying.incidence.code === "MECO" && inc.justifyLessMealTime )||
                                        ( this.state.incidenceJustifying.incidence.code === "MHT" && inc.justifyLessWorkHours )||
                                        ( this.state.incidenceJustifying.incidence.code === "CHOT" && inc.justifyEarlyCheckout )||
                                        ( this.state.incidenceJustifying.incidence.code === "RIN" && inc.justifyIncompleteChecks )||
                                        ( this.state.incidenceJustifying.incidence.code === "UNREG" && inc.justifyOneCheck )||
                                        ( this.state.incidenceJustifying.incidence.code === "MHT5" && inc.justifyLess5WorkHours )||
                                        ( inc.code === 'RCHNF' ))
                                        return (<option value={inc.id} key={inc.id}>{inc.name}</option>);
                                    else
                                        return null;
                                        })}
                            </select>
                            <br /><br />
                            <p>Escribe un comentario:</p>
                            <textarea
                                className="form-control"
                                onChange={(e)=>this.onJustifyChange("comment",e.target.value)}
                                value={this.state.justifyDetail.comment}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                className={"btn-success"}
                                onClick={this.justify}
                                disabled = {!!this.props.loadingIds[ this.loadingIdJustifying ]}
                            >
                                Justificar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    :null}

                {/* Aprove payroll modal */}
                <Modal show={this.state.approvePayrollOpen} onHide={() => this.setState({approvePayrollOpen: false})} className="">
                    <Modal.Header closeButton>
                        <Modal.Title>Aprobar y archivar nómina</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { !this.props.calculatePayroll?
                            (!this.props.loadingIds[this.loadingIdcalculate] ?
                                    <div>
                                        <p>¿Estás seguro que la nómina está terminada?</p>
                                        <p>Una nómina archivada no puede ser modificada, los bonos e incidencias se congelaran
                                            en su estado actual.</p>
                                    </div>
                                    :
                                    <div>
                                        <p>Calculando nómina...</p>
                                    </div>
                            ):
                            (
                              <div>
                                  <p>Nómina archivada!</p>
                                  <p><span className="col-xs-7">Total de sueldos:</span> {moneyFormatter( this.props.calculatePayroll.salary )}</p>
                                  <p><span className="col-xs-7">Total de descuentos:</span> {moneyFormatter( this.props.calculatePayroll.totalDiscount)}</p>
                                  <p><span className="col-xs-7">Total de bonos:</span> {moneyFormatter( this.props.calculatePayroll.totalBonus)}</p>
                                  <p><span className="col-xs-7">Total a pagar:</span> {moneyFormatter( this.props.calculatePayroll.total)}</p>
                                  <br/>
                                  <Button><Link to="/payroll/list">Continuar</Link></Button>
                              </div>
                            )}
                    </Modal.Body>
                    <Modal.Footer>
                        {(!this.props.loadingIds[this.loadingIdcalculate] && !this.props.calculatePayroll) ?
                            <div>
                                <Button
                                    className={"btn-danger"}
                                    onClick={() => this.setState({approvePayrollOpen: false})}
                                >
                                    No, la seguiré revisando
                                </Button>
                                <Button
                                    className={"btn-success"}
                                    onClick={this.approvePayroll}
                                >
                                    Sí, aprobar
                                </Button>
                            </div>
                            :null}
                    </Modal.Footer>
                </Modal>

                {/* Disaprove payroll modal */}
                <Modal show={this.state.disapprovePayrollOpen} onHide={() => this.setState({disapprovePayrollOpen: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Desbloquear nómina</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Estás por desbloquear la nómina calculada actual. Se eliminará el archivo que se había generado y los bonos y descuentos serán editables de nuevo.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>
                            <Button
                                className={"btn-danger"}
                                onClick={() => this.setState({disapprovePayrollOpen: false})}
                            >
                                No hacer nada
                            </Button>
                            <Button
                                className={"btn-success"}
                                onClick={this.disapprovePayroll}
                                disabled={this.props.loadingIds[ this.loadingIdDisapprove ]}
                            >
                                {this.props.loadingIds[ this.loadingIdDisapprove ]?
                                    <LoaderIcon />:"Continuar con desbloqueo"
                                }
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>

                {/* Add discounts Modal */}
                {this.state.addingDiscount?
                    <AddDiscountModal
                        onHide={()=>this.setState({addingDiscount:false})}
                        employee={this.state.payrollDetail.employee}
                        applyDiscount={this.applyDiscount}
                    />:null
                    }

                {/* Add bonus Modal */}
                {this.state.addingBonus?
                    <AddBonusModal
                        onHide={()=>this.setState({addingBonus:false})}
                        employee={this.state.payrollDetail.employee}
                        applyBonus={this.applyBonus}
                        bonuses={this.props.bonuses}
                    />:null
                }
            </div>
        );
    }

}

function mapStateToProps( {api:{me, payrollDetails=[], payrollDetailsMeta={totalItems:0,itemsPerPage:0}, incidences=[], bonuses=[], calculatePayroll, appliedDiscounts}, loadingIds } ) {

    return { me, list:payrollDetails, listMeta:payrollDetailsMeta, incidences, bonuses,  loadingIds, calculatePayroll, appliedDiscounts }
}

export default connect(mapStateToProps )(PayrollDetail);


const paymentMethodString = ( str )=>{
    switch (str) {
        case 'transfer':
            return 'Transferencia electrónica';
        case 'cash':
            return 'Efectivo';
        case 'outsourcing':
            return 'Outsourcing';
        default:
            return str;
    }
};
