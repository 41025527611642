import moment from "moment";
import _ from 'lodash';
import moneyFormatter from "../../moneyFormatter";
import React from 'react';

export const listSGroups = [
    //'orozco_print_read',
    'orozco_print_read_folio_number',
    'orozco_print_read_orozco_payments',
    'orozco_payment_read',
    'orozco_payment_read_orozco_cash_register',
    'orozco_cash_register_read_lounge',
    'orozco_cash_register_read_name',
    'lounge_read_name',
    'orozco_payment_read_created_by',
    'orozco_print_read_created_by',
    'creation_date',
    'orozco_payment_read_type',
    'user_read_employee',
    'employee_read_full_name',
    'orozco_payment_read_orozco_payment_method',
    'orozco_payment_method_read_name'
];

export const orozcoPrintsListTable = [
    {
        Header: 'Concepto',
        accessor: (op)=>{
            if(op.orozcoPayments[0].type ==='visit_order'){
                return 'Pago de productos/servicios'
            }else
                return 'Walk In'
        },
        id: 'type',
        filterable: false
    },
    {
        Header: 'Folio',
        accessor: 'folioNumber',
    },
    {
        Header: 'Realizado por',
        accessor: 'createdBy.employee.fullName'
    },
    {
        Header: 'Sala',
        accessor: (op)=>{
            return op.orozcoPayments[0].orozcoCashRegister.lounge.name
        },
        id: 'orozcoPayments.orozcoCashRegister.lounge.name'
    },
    {
        Header: 'Caja',
        accessor: (op)=>{
            return op.orozcoPayments[0].orozcoCashRegister.name
        },
        id: 'orozcoPayments.orozcoCashRegister.name'
    },
    {
        Header: 'Pagos en MXN',
        accessor: (op)=>formatTicketPayments(op, 'MXN'),
        id: 'orozcoPayments.amount1',
        filterable:false
    },
    {
        Header: 'Pagos en USD',
        accessor: (op)=>formatTicketPayments(op, 'USD'),
        id: 'orozcoPayments.amount2',
        filterable:false
    },
    {
        Header: 'Pagos en EUR',
        accessor: (op)=>formatTicketPayments(op, 'EUR'),
        id: 'orozcoPayments.amount3',
        filterable:false
    },
    {
        Header: 'Fecha de pago',
        accessor: (op) => moment(op.createdDate).format('DD/MM/YYYY HH:mm'),
        id: 'createdDate'
    }

];


const formatTicketPayments = (op, currency)=>(
    _.map(op.orozcoPayments, (p)=>p.paymentCurrency===currency?<div key={p.id}>{moneyFormatter(p.amount)} - {p.orozcoPaymentMethod.name}</div>:null)
);

