import React, {useState, useContext} from 'react';
import {Modal, Table} from 'react-bootstrap';
import Button from "../../../elements/CustomButton/CustomButton";
import {ApiContext} from "../../../api/Api";
import {NotifierContext} from "../../../utils/Notifier";


const ServiceFormModal = ({onHide, service:newService}) => {
    const loadingId = '@Class.AddServiceModal.products.create';

    const [service, setService] = useState( {
        name: newService?newService.name:'',
        barcode: newService?newService.barcode:'',
        isService: true
    });

    const [creatingService, setCreatingService] = useState(false);

    const onPropertyChange = ({target}) => {
        const newService = {...service, [target.name]: target.value};
        setService(newService);
    };

    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);

    const saveService = ()=>{

        if( !service.name  )
            return notifier.error('No has llenado todos los campos');

        setCreatingService(true);

        if(newService)
            api.products.edit( {id: newService.id, product:service, loadingId} ).then(onHide);
        else
            api.products.create( {product:service, loadingId} ).then(onHide);
    };


    return (
        <Modal
            show={true}
            onHide={onHide}
        >
            <Modal.Header>
                <Modal.Title>Nuevo servicio</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <form autoComplete='off'>
                    <Table>
                        <tbody>
                        <tr>
                            <td>Nombre</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='name'
                                    required={true}
                                    onChange={onPropertyChange}
                                    value={service.name}
                                />
                            </td>
                        </tr>

                        </tbody>
                    </Table>
                </form>

            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle='danger' disabled={creatingService} className='pull-left' onClick={onHide}>Cancelar</Button>
                <Button bsStyle='success' disabled={creatingService} onClick={saveService}>Guardar</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ServiceFormModal;
