import React, {useContext, useState, useEffect, useRef, useCallback} from 'react';
import {Col, Row, Table} from "react-bootstrap";
import Card from '../../../components/Card/Card.jsx';
import {ApiContext} from "../../../api/Api";
import {connect, useSelector} from "react-redux";
import _ from 'lodash';
import {NotifierContext} from "../../../utils/Notifier";
import {getUnitsArray, pluralizeUnitName, unitConversion} from "../../../utils/modelUtils/almazen/unitsUtility";
import EntitySelect from "../../../components/EntitySelect/EntitySelect";
import noProductImg from "../../../assets/img/no-product.png";
import {
    batchLoadingId,
    createNewStockMovementBatch,
    getProductsDifferences,
    movementProductsToStockMovements,
    receiveForcedStockMovementBatch,
    receivePerfectStockMovementBatch,
    receiveStockMovementBatchAndCreatePending,
    stockMovementBatchToStockMovementWithUnit,
    stockMovementBatchType
} from "../../../utils/modelUtils/almazen/stockMovementBatchUtils";
import PromptStockMovementDifferencesModal from "./PromptStockMovementDifferencesModal";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import CommentsFeed from "../../../components/CommentsFeed/CommentsFeed";
import AddStockMovementTableRequestedCell from "./components/AddStockMovementTableRequestedCell";

const productLoadingId = '@AddStockMovement.products.get';

const productFilters ={'isActive':true, 'productBrand.product.isActive':true};

const AddStockMovement = ({productBrandUnits, movementType, currentWarehouse, stockMovementBatch, onBatchSaved, preventAddingProducts, cassetteId}) => {
    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);

    const [productBrandUnit, setProductBrandUnit] = useState(false);
    const [movementProducts, setMovementProducts] = useState([]);
    const [promptDifferences, setPromptDifferences] = useState(null);
    const [newShrinkageComment, setNewShrinkageComment] = useState('');

    const sendingBatch=useSelector(({loadingIds})=>!!loadingIds[batchLoadingId]);

    if(stockMovementBatch)
        movementType=stockMovementBatch.inOrOut;

    const barcodeInputRef = useRef('');

    const addMovementProduct = useCallback( (pbu) => {
        const newMovementProducts = [...movementProducts];
        const mpIndex = _.findIndex(newMovementProducts, (mp) => mp.productBrand.id === pbu.productBrand.id);

        if (mpIndex===-1)
            newMovementProducts.push({ quantity: 1, selectedUnit: pbu.unit, productBrand: pbu.productBrand });
        else {
            const newMovement = {...newMovementProducts[mpIndex]};
            if(newMovement.selectedUnit.id === pbu.unit.id )
                newMovement.quantity++;
            else
                newMovement.quantity=Number(newMovement.quantity)+ unitConversion(1, pbu.unit, newMovement.selectedUnit, pbu.productBrand.product );
            newMovementProducts[mpIndex]=newMovement;
        }
        setMovementProducts(newMovementProducts);
    }, [ movementProducts ]);

    // ---------------------  Barcode reader management

    const keyUpInterval=useRef(0);
    const onKeyUp = useCallback(({key}) => {

        if(key==='Shift' || key==='Ctrl')
            return;

        if (key !== 'Enter') {
            barcodeInputRef.current += key;
            if(keyUpInterval.current)
                clearInterval(keyUpInterval.current);
            keyUpInterval.current=setTimeout(()=>{
                barcodeInputRef.current='';
            }, 500);
            return;
        }

        if(!barcodeInputRef.current)
            return;

        const barcode=barcodeInputRef.current;
        const pbu = _.find(productBrandUnits, (pbu) => pbu.barcode === barcode);

        if (pbu) {
            setProductBrandUnit(pbu);
            addMovementProduct(pbu);
        } else {
            api.productBrandUnits.get({page:0, pageSize:10, loadingId:productLoadingId, filters:{search: barcode, isService: false}}).then((result) => {
                if (result && result[0]) {
                    const pbu = result[0];
                    setProductBrandUnit(pbu);
                    addMovementProduct(pbu);
                }
                else
                    notifier.error('Producto no encontrado. Código: '+barcode);
            });
        }
        barcodeInputRef.current = '';
    }, [ productBrandUnits, api, addMovementProduct, notifier ]);

    useEffect(() => {
        if(preventAddingProducts) return;
        //https://stackoverflow.com/questions/30677134/react-keyboard-events-not-firing Work around
        document.addEventListener("keyup", onKeyUp);
        return () => document.removeEventListener("keyup", onKeyUp);
    }, [ onKeyUp, preventAddingProducts ]);

    // -------    Set movements from old batch

    useEffect( ()=>{

        if( !stockMovementBatch ) {
            setMovementProducts([]);
            return;
        }
        const movements = stockMovementBatchToStockMovementWithUnit(stockMovementBatch);
        setMovementProducts( movements );

    }, [stockMovementBatch] );

    // ---------------   Api calls ---------

    const finishSendingBatch=useCallback(()=>{
        notifier.success( (movementType==='out'?'Salidas':movementType==='in'?'Entradas':'Mermas')+' registradas');
        setMovementProducts([]);
        setProductBrandUnit(null);
        onBatchSaved();
    },[notifier, movementType, onBatchSaved]);

    const sendBatch = useCallback(() => {
        if (movementType === 'shrinkage') {
            if (!newShrinkageComment)
                return notifier.error('Tienes que agregar un comentario para guardar.');
        }

        if (!currentWarehouse)
            return notifier.error('Debes seleccionar el almacén actual');

        const movementProductsQuantityOnZero = _.filter(movementProducts, (movement) => parseInt(movement.quantity) === 0);
        if (movementProductsQuantityOnZero && movementProductsQuantityOnZero.length !== 0) {
            const movementProductsWithoutQuantitiesOnZero = _.remove(movementProducts, (movement) => parseInt(movement.quantity) !== 0)
            return setMovementProducts(movementProductsWithoutQuantitiesOnZero);
        }


        const stockMovements=movementProductsToStockMovements(movementProducts, movementType);

        if(!stockMovementBatch)
            return createNewStockMovementBatch(api, stockMovements, movementType, currentWarehouse, newShrinkageComment)
                .then(finishSendingBatch);

        const differences=getProductsDifferences(stockMovements, stockMovementBatch.movements);

        if(!differences.length)
            return receivePerfectStockMovementBatch(api, stockMovementBatch)
                .then(finishSendingBatch);

        setPromptDifferences(differences);
        setNewShrinkageComment('');

    },[api, notifier, currentWarehouse,  movementProducts, movementType, stockMovementBatch, finishSendingBatch, setNewShrinkageComment, newShrinkageComment]);

    const closeBatchWithDifferences=useCallback(()=>{
        setPromptDifferences(null);
        return receiveForcedStockMovementBatch(api, stockMovementBatch, movementProducts)
            .then(finishSendingBatch);
    },[api, stockMovementBatch, finishSendingBatch, movementProducts]);

    const closeBatchAndCreatePending=useCallback(()=>{
        setPromptDifferences(null);
        return receiveStockMovementBatchAndCreatePending(api, stockMovementBatch, movementProducts, promptDifferences, currentWarehouse, movementType)
            .then(finishSendingBatch);
    },[api, stockMovementBatch, movementProducts, promptDifferences, currentWarehouse, movementType, finishSendingBatch]);

    // ---------------   Change handlers ---------

    const onProductBrandUnitChange = (productBrandUnit) => {
        setProductBrandUnit(productBrandUnit);
    };

    const handleAddProductButton=useCallback(()=>{
        if( productBrandUnit )
            addMovementProduct(productBrandUnit);
    },[productBrandUnit, addMovementProduct]);

    const onProductQuantityChange = useCallback( ( e )=>{

        const index = e.target.dataset.index;
        const movementProduct = movementProducts[ index ];

        const newMovementProducts = [ ...movementProducts ];
        newMovementProducts[ index ] = {...movementProduct, quantity: e.target.value};

        setMovementProducts( newMovementProducts );

    }, [ movementProducts ]);

    const onProductRemove = useCallback( ( e )=>{

        const index = e.currentTarget.dataset.index;
        const newMovementProducts = [ ...movementProducts ];
        newMovementProducts.splice( index, 1 );

        setMovementProducts( newMovementProducts );

    }, [ movementProducts ]);

    const onProductUnitChange = useCallback( ( e )=>{

        const index = e.target.dataset.index;
        const movementProduct = movementProducts[ index ];

        const newMovementProducts = [ ...movementProducts ];
        const selectedId=e.target.value;
        const selectedUnit = _.find(getUnitsArray(movementProduct.productBrand.product), unit=>unit.id+''===selectedId);

        newMovementProducts[ index ] = {...movementProduct, selectedUnit};
        setMovementProducts( newMovementProducts );

    }, [ movementProducts ]);

    const product = productBrandUnit && productBrandUnit.productBrand && productBrandUnit.productBrand.product;

    let productImg=noProductImg;
    if( product && product.photo)
        productImg=api.appFiles.getUrl( product.photo.id );
    else if(productBrandUnit && productBrandUnit.productBrand && productBrandUnit.productBrand.photo)
        productImg=api.appFiles.getUrl( productBrandUnit.productBrand.photo.id );

    //Create title
    let title;
    if(stockMovementBatch && stockMovementBatch.purchaseOrder)
        title=(
            <span>Recibiendo OC {stockMovementBatch.purchaseOrder.folio}<br/>
                {stockMovementBatch.purchaseOrder.provider?stockMovementBatch.purchaseOrder.provider.name:''}
            </span>
        );
    else if(stockMovementBatch && stockMovementBatch.stockRequest)
        title=(stockMovementBatch.inOrOut==='in'?'Recibiendo':'Entregando')+' requisición '+stockMovementBatch.stockRequest.folio;
    else
        title='Nueva '+(movementType===stockMovementBatchType.OUT?'salida':movementType===stockMovementBatchType.SHRINKAGE?'merma':'entrada');

    const handleShrinkageComment = useCallback((e) => {
        setNewShrinkageComment(e.currentTarget.value);
    }, [setNewShrinkageComment]);

    return (
        <div className='AddStockMovement'>

            <Row>

                <Col xs={12}>

                    <Card
                        content={
                            <>
                                <Row>

                                    <Col xs={12} md={6}>
                                        <h4>
                                        {title}
                                        </h4><br/>
                                        {movementType === 'shrinkage' ?
                                            <>
                                                <p>Agrega un comentario:</p>
                                                <textarea
                                                value={newShrinkageComment}
                                                onChange={handleShrinkageComment}
                                                placeholder='Comentario'
                                                style={{resize: 'none'}}
                                                className='form-control textarea'/>
                                            </>
                                            :
                                            <>
                                                <p>Nombre del solicitante: {
                                                    !stockMovementBatch?"":
                                                        (stockMovementBatch?.stockRequest?.createdBy?.employee.fullName||"Solicitud automática")
                                                }</p>
                                                <p>Destino: {stockMovementBatch?.stockRequest?.toPlace.name}</p>
                                            </>
                                        }
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <button onClick={sendBatch} disabled={sendingBatch || movementProducts.length === 0}
                                                className={'text-center btn btn-success big-button'}>Guardar
                                        </button>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    {!preventAddingProducts &&
                                    <Col xs={12} md={5}>
                                        <div className='text-center'>
                                            <EntitySelect
                                                entity='productBrandUnits'
                                                filterBy='search'
                                                labelCreator={pbu => `${pbu.productBrand.product.name} - ${pbu.productBrand.brand.name} - ${pbu.unit.name}`}
                                                onChange={onProductBrandUnitChange}
                                                apiCustomProp={'productBrandUnits'}
                                                placeholder="Escribe el nombre del producto o escanea un código de barras"
                                                additionalFilters={productFilters}

                                            />
                                            <Row>
                                                <Col xs={12}>

                                                    <div className='container-fluid product-detail'>
                                                        <Row>
                                                            <Col xs={12} sm={product ? 6 : 12}>
                                                                <div className='text-center'>
                                                                    <img
                                                                        src={productImg}
                                                                        alt='Producto'
                                                                        className='img-responsive product-img'
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col xs={12} sm={6}>
                                                                <div>
                                                                    <h4 className='text-center'>{product && product.name}</h4>
                                                                    <h6 className='text-center text-muted'>{product && product.line && ('Línea: ' + product.line.name)}</h6>
                                                                    {product &&
                                                                    <p className="prod-description">{product.description || 'Sin descripción'}</p>}
                                                                    {product && <CustomButton bsStyle='success'
                                                                                              onClick={handleAddProductButton}>Agregar
                                                                        producto</CustomButton>}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    }

                                    <Col xs={12} md={preventAddingProducts?12:7}>
                                        {movementProducts.length === 0 ?
                                            <>
                                                <h4 className='text-center'>Selecciona un producto para agregarlo al movimiento</h4>
                                            </>
                                            :
                                            <Table className='vertical-responsive-table'>
                                                <thead>
                                                <tr>
                                                    <th className='text-center'>Borrar</th>
                                                    <th>Nombre</th>
                                                    {stockMovementBatch &&
                                                    <th>Solicitado</th>}
                                                    <th className='quantity-col'>Cantidad</th>
                                                    <th>Unidad</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {movementProducts.map((movement, index) => (
                                                    <tr key={index}>
                                                        <td className='text-center'>
                                                            <button onClick={ onProductRemove } data-index={ index } className='remove-product-icon'><i className='fa fa-times'/></button>
                                                        </td>
                                                        <td data-label='Prod.'>{movement.productBrand.product.name} - {movement.productBrand.brand.name}</td>
                                                        {stockMovementBatch&&
                                                        <AddStockMovementTableRequestedCell
                                                            stockMovementBatch={stockMovementBatch}
                                                            movement={movement}
                                                        />}
                                                        <td data-label='Cant.' className='quantity-col'>
                                                            <input value={ movement.quantity }  data-index={ index } onChange={ onProductQuantityChange } type='number' className='form-control'/>
                                                        </td>
                                                        <td data-label='Unidad'>
                                                            <select className='form-control'  data-index={ index } value={movement.selectedUnit.id} onChange={onProductUnitChange}>
                                                                { getUnitsArray( movement.productBrand.product ).map( unit=>
                                                                    <option key={unit.id} value={unit.id}>{pluralizeUnitName(movement.quantity, unit.name)}</option>
                                                                 ) }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                ))}

                                                </tbody>
                                            </Table>
                                        }
                                    </Col>

                                </Row>
                            </>
                        }
                    >
                    </Card>
                </Col>
            </Row>

            {cassetteId&&
                <CommentsFeed
                    cassetteId={cassetteId}
                />}

            {promptDifferences &&
                <PromptStockMovementDifferencesModal
                    onHide={()=>setPromptDifferences(null)}
                    differences={promptDifferences}
                    stockMovementBatch={stockMovementBatch}
                    onForceClose={closeBatchWithDifferences}
                    onCloseWithPending={closeBatchAndCreatePending}

                    />
            }

        </div>


    );
};

const mapStateToProps = ({api: {productBrandUnits = []}}) => ({productBrandUnits});

export default connect(mapStateToProps)(AddStockMovement);


