import React, {useCallback, useContext, useState} from 'react';
import useTideTable from "../../../../hooks/table/useTideTable";
import ReactTable from "react-table";
import EntitySelect from "../../../../components/EntitySelect/EntitySelect";
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";
import {userPermissionAdminSGroups} from "../../../../utils/modelUtils/alma/userUtils";
import {positionPermissionsSGroups} from "../../../../utils/modelUtils/alma/positionUtils";
import Switch from "react-bootstrap-switch";
import _ from 'lodash';
import {NotifierContext} from "../../../../utils/Notifier";
import {TideApiContext} from "../../../../api/tideApiConfig";


const getUserName = u=>employeeFullName(u.employee);
const usersFilters = { hired: true, sGroups:userPermissionAdminSGroups };
const positionsFilters = { isActive: true, sGroups: positionPermissionsSGroups};
const tideTableFilters = {pagination: false};

const PermissionGroupList = () => {

    const notifier = useContext(NotifierContext);
    const api = useContext(TideApiContext);
    const [user, setUser] = useState(null);
    const [position, setPosition] = useState(null);

    const handleUserChange = useCallback((user)=>{
        setUser(user);
        setPosition(null)
    },[]);
    const handlePositionChange = useCallback((position)=>{
        setUser(null);
        setPosition(position);
    },[]);


    const isPermissionActive = (permission)=>{
        const permissions = user?.permissionGroups || position?.permissionGroups;
        if(!permissions?.length)
            return false;
        return !!_.find( permissions, p=>p.id===permission.id );
    };

    const togglePermission = (permission, value)=>{
        if(!user && !position){
            return notifier.error("Selecciona un usuario o un puesto");
        }
        const oldPermissions = user?.permissionGroups || position?.permissionGroups;
        let ids = oldPermissions.map( p=>p.id );

        if(value){
            ids.push(permission.id);
        }
        else {
            ids = _.filter(ids, id=>id!==permission.id);
        }
        const id = user?.id || position?.id;
        api[ user?"users":"positions" ].update( { id, params:{
            permissionGroups: ids,
            sGroups:user?userPermissionAdminSGroups:positionPermissionsSGroups
        } } )
            .then( resp=>{
                if(user) setUser(resp);
                else setPosition(resp);
            } )
    }

    const table = useTideTable({
        entity:'permissionGroups',
        newTideApi: true,
        requestFilters: tideTableFilters,
        columns: [
            {Header:'Nombre', accessor:'name'},
            {Header:'Descripción', accessor:'description'},
            {Header:'Sistema', accessor:'systemName'},
            {Header: 'Activo', id:'active',
                accessor: (permission)=><Switch
                    onChange={(s, value)=>{togglePermission(permission, value)}}
                    value={isPermissionActive(permission)}
                />
            }
        ]
    });


    return (
        <div className={"PermissionGroupList"}>
            <EntitySelect
                entity={"users"}
                value={user}
                onChange={handleUserChange}
                labelCreator={getUserName}
                placeholder="Ver permisos de usuario"
                additionalFilters={usersFilters}
                getMethod={"get2"}
                filterBy={"search"}

            />
            <EntitySelect
                entity={"positions"}
                value={position}
                onChange={handlePositionChange}
                placeholder="Ver permisos de puesto"
                additionalFilters={positionsFilters}
                getMethod={"get2"}
            />
            <h2>Lista de permisos</h2>
            <ReactTable
                {...table.tableProps}
                /*getTrProps={createTrProps}*/
            />

            {/*showingDetail&&<PermissionGroupsDetailModal permissionGroup={showingDetail} onHide={closeDetail} />*/}

        </div>
    );
};

export default PermissionGroupList;
