import React, {Component} from 'react';
import AppliedIncidences from '../../Manager/AppliedIncidences';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {UserDetailApiProp, UserDetailLoadingId} from "../core/UserDetail";
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";

class EmployeeIncidences extends Component {

    static contextTypes = { api: PropTypes.object };

    constructor(props, context){
        super(props);

        context.api.clearProperty( 'singleEmployee' );

        if( props.match.params.id ) {
            this.employeeId = props.match.params.id;
            this.isMe = false;
            context.api.employees.getSingle(this.employeeId);
        }
        else {
            this.employeeId = props.me.employee.id;
            this.isMe = true;
        }

        this.showUserDetail = this.showUserDetail.bind(this);
    }

    componentWillUnmount(){
        this.context.api.clearProperty( 'singleEmployee' );
    }

    showUserDetail( ){
        this.context.api.users.get(0,1,UserDetailLoadingId,{employee:this.props.singleEmployee.id},UserDetailApiProp );
    }

    render() {

        let employee;
        if( this.isMe )
            employee = this.props.me.employee;
        else
            employee = this.props.singleEmployee;

        return (
            <div className="EmployeeIncidences">
                {!this.isMe && this.props.singleEmployee?
                    <h4 className='name-title'>Incidencias de <span className="span-but" onClick={this.showUserDetail}>{employeeFullName(this.props.singleEmployee)}</span></h4>:null
                }
                {employee?
                    <AppliedIncidences
                        employee={employee}
                        showNames={false}
                    />:null}
            </div>
        );
    }
}

function mapStateToProps( {api:{me = {}, singleEmployee }} ) {

    return {me, singleEmployee}
}
export default connect(mapStateToProps)(EmployeeIncidences);
