import React, {useMemo, useCallback, useRef} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, OverlayTrigger, Tooltip} from "react-bootstrap";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import ReactTable from "react-table";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import {stockMovementBatchStatus, stockMovementBatchType} from "../../../utils/modelUtils/almazen/stockMovementBatchUtils";
import StockMovementBatchesNameAndTooltip from "../requisitions/components/StockMovementBatchesNameAndTooltip";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import useTideTable from "../../../hooks/table/useTideTable";
import DeleteStockMovementCell from "./components/DeleteStockMovementCell/DeleteStockMovementCell";


const PendingMovementsModal=({onHide, movementType, warehouse, onSelect})=>{

    const reloadRef = useRef();

    const tableOptions=useMemo(()=>({
            entity:'stockMovementBatches',
            columns:movementType===stockMovementBatchType.IN?inColumns(reloadRef):outColumns(reloadRef),
            requestOptions:{filters:{
                    inOrOut:movementType,
                    warehouse: warehouse.id,
                    status:stockMovementBatchStatus.PENDING,
                    "exists[purchaseOrder]": movementType===stockMovementBatchType.IN? true:undefined
            }}})
        , [movementType, warehouse.id]);

    const batchesTable=useTideTable(tableOptions);
    reloadRef.current = batchesTable.reload;

    const clickCreator=useCallbackCreator((index)=>{
        const movementsBatch= batchesTable.tableProps.data[index];
        if(movementsBatch)
            onSelect(movementsBatch);
        onHide();
    },[batchesTable.tableProps.data, onSelect, onHide]);

    const newMovement=useCallback(()=>{
        onSelect(null);
        onHide();
    },[onSelect, onHide]);

    return (
        <Modal show onHide={onHide} bsSize='lg'>
            <ModalHeader closeButton>
                <ModalTitle>{movementType===stockMovementBatchType.IN?'Entradas':'Salidas'} pendientes de {warehouse.name}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <ReactTable
                    {...batchesTable.tableProps}
                    getTrProps={(table, row)=>row?{onClick:clickCreator(row.index)}:{}}
                />
            </ModalBody>
            <ModalFooter>
                <CustomButton bsStyle='danger' className='pull-left' onClick={onHide}>Cerrar</CustomButton>
                <CustomButton bsStyle='primary' onClick={newMovement}>Nueva {movementType===stockMovementBatchType.IN?'entrada':'salida'}</CustomButton>
            </ModalFooter>
        </Modal>
    );
};

export default PendingMovementsModal;

const outColumns=(reloadRef)=>[
    {
        Header:'Origen',
        id:'toPlace',
        filterable:false,
        sortable:false,
        accessor:(stockMovementBatch)=>{
            if(stockMovementBatch.stockRequest)
                return 'Requisición '+stockMovementBatch.stockRequest.folio+' '+(stockMovementBatch.stockRequest.toPlace? ('de '+stockMovementBatch.stockRequest.toPlace.name):'');
            return 'No definido';
        }
    },
    {
        Header: 'Productos',
        accessor: (stockMovementBatch)=><StockMovementBatchesNameAndTooltip stockMovementBatches={[stockMovementBatch]} />,
        id: 'movements.productBrand.product.name'
    },{
        Header: 'Solicita',
        accessor: (stockMovementBatch)=>stockMovementBatch && stockMovementBatch.stockRequest && stockMovementBatch.stockRequest.createdBy?
        employeeFullName(stockMovementBatch.stockRequest.createdBy.employee):'Sin definir',
        id: 'stockRequest.createdBy.employee.name'
    },{
        Header: 'Acción',
        id: 'stockMovementBatches.delete',
        Cell: ({original})=><DeleteStockMovementCell reloadRef={reloadRef} original={original}/>,
        filterable:false,
        sortable:false,
    },

];

const inColumns=(reloadRef)=>[
    {
        Header:'Origen',
        id:'purchaseOrder',
        filterable:false,
        sortable:false,
        accessor:(stockMovementBatch)=>{
            if(stockMovementBatch.purchaseOrder)
                return 'Orden de compra '+stockMovementBatch.purchaseOrder.folio+' - Solicitud '+stockMovementBatch.purchaseOrder.purchaseRequisition.folio;
            if(stockMovementBatch.stockRequest)
                return 'Requisición '+stockMovementBatch.stockRequest.folio;
            return 'No definido';
        }},
    {
        Header:'Productos',
        id:'purchaseOrder.purchaseProducts',
        filterable:false,
        sortable:false,
        accessor:(stockMovementBatch)=>{
            const movements=stockMovementBatch.movements;
            if(!movements.length)
                return 'Sin productos';
            let str=movements[0].productBrand.product.name;
            if(movements.length > 1) {
                str += ' y ' + (movements.length - 1) + ' más';
                return <OverlayTrigger
                    overlay={<Tooltip id={1}>
                        <ul>
                            {movements.map((mov)=><li key={mov.id}>{mov.productBrand.product.name}</li>)}
                        </ul>
                    </Tooltip>}
                >
                    <span>{str}</span>
                </OverlayTrigger>
            }
            else
                return str;
        }},
    {
        Header:'Proveedor',
        id:'purchaseOrder.provider.name',
        filterable:false,
        sortable:false,
        accessor:(stockMovementBatch)=>{
            if(stockMovementBatch.purchaseOrder && stockMovementBatch.purchaseOrder.provider)
                return stockMovementBatch.purchaseOrder.provider.name;
            return 'No definido';
        }
    },
    {
        Header: 'Acción',
        id: 'stockMovementBatches.delete',
        Cell: ({original})=><DeleteStockMovementCell reloadRef={reloadRef} original={original}/>,
        filterable:false,
        sortable:false,
    },

];
