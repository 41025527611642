import React from 'react';


export default function MultipleChoiceRadioAnswer( props ){

    let answer = '';
    if( props.data && props.data.response )
        answer = props.data.response;

    const clickOnAnswer = ( e )=>{
        const index = e.currentTarget.dataset.index;
        props.onChange( {response: props.config.options[index] } );
    };

    let options = [];
    if( props.config && props.config.options && props.config.options.constructor === Array )
        options = props.config.options;

    return (
        <div className='answer-component MultipleChoiceRadioAnswer'>
            {options.map((opt,i)=>
                <button
                    key={i}
                    className={ answer === opt? 'active':''}
                    onClick={clickOnAnswer} data-index={i}>
                    {opt}
                </button>
            )}
        </div>
    );

}