import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Switch, Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {socketApi, SocketApiContext} from '../../api/socketApi';
import {API_URL} from '../../api/constants_api';
import Notifier from '../../utils/Notifier';
import {version} from '../../pckg';
import entries from 'object.entries';
import assign from 'es6-object-assign';
import find from '../../utils/polyfill/find';
import findIndex from '../../utils/polyfill/findIndex';
import isNaN from '../../utils/polyfill/isNaN';
import 'whatwg-fetch';//fetch polyfill
import appRoutes from '../../routes/app.jsx';
import {LOGIN_STATE} from "../../constants/LogIn";
import LoadingNotification from "../../components/Helper/LoadingNotification";
import moment from 'moment';
import 'moment/locale/es';
import {changeSystem} from '../../actions/action_changeSystem';
import loginRedirect from '../../actions/action_loginRedirect';
import Api, {ApiContext} from "../../api/Api";
import {NotifierContext} from "../../utils/Notifier";
import CrashView from '../../views/CrashView';
import DeviceDetector from 'device-detector-js';
import GCareLoader from "../../views/public/Questionnaires/GCareLoader";
import withReduxStore from "../../utils/withReduxStore";
import TideApi from 'tide-api';
import tideApiConfig, {TideApiContext} from '../../api/tideApiConfig';


moment.locale("es");

class App extends Component {

    constructor(props) {
        super(props);
        this.api = new Api(API_URL, props.store);
        this.tideApi = new TideApi({...tideApiConfig, reduxStore: props.store});
        this.api.onLogIn=()=>this.tideApi.token = this.api.token;

        if(this.api.token)
            this.tideApi.token = this.api.token;

        this.socketApi = socketApi(props.store);
        this.notifier = new Notifier(props.store);

        window.appVersion = version;

        /** Shims **/
        if (!Object.entries)
            entries.shim();
        if (!Object.assign)
            assign.polyfill();

        find.shim();
        isNaN.shim();
        findIndex.shim();
    }

    state = {sendingLogs: false};

    componentDidUpdate(e) {
        if (window.innerWidth < 993 && e.history.action === "PUSH" && document.documentElement.className.indexOf('nav-open') !== -1) {
            document.documentElement.classList.toggle('nav-open');
        }
    }

    static getDerivedStateFromError() {
        return {broken: true};
    }

    componentDidCatch(error, errorInfo) {

        const user = this.props.api && this.props.api.me;

        if (process.env.REACT_APP_BUILD === 'prod' && !this.errorSent) {

            let browser = "";
            try {
                browser = (new DeviceDetector()).parse(window.navigator.userAgent);
                browser.userAgent = window.navigator.userAgent;
            } catch (e) {
                browser = {browserReadError: e.message}
            }

            browser.appVersion = version;
            browser.userAgent = window.navigator.userAgent;

            const info = {
                location: window.location.href,
                browser,
            };

            if (this.api.requestLog && this.api.requestLog)
                info.requestLog = this.api.requestLog.slice(-5);

            this.api.sendError(error, errorInfo.componentStack, user, info)
                .then(() => {
                    this.setState({sendingLogs: false});
                });
        }
        this.errorSent = true;
    }

    getChildContext() {
        return {
            api: this.api,
            notifier: this.notifier,
            socketApi: this.socketApi
        };
    }

    render() {

        const isGCare = window.location.host === 'encuesta.thegrandloungeelite.com.mx';

        if (this.state.broken && !isGCare)
            return <CrashView sending={this.state.sendingLogs}/>;
        //Ensure logged in
        const path = this.props.history.location.pathname;
        if (this.props.loggedIn !== LOGIN_STATE.LOGGED_IN && path !== "/login" && !isGCare) {
            if(path!=='/logout')
                this.props.loginRedirect(path);
            return <Redirect from={path} to="/login"/>;
        }

        return (
            <SocketApiContext.Provider value={this.socketApi}>
                <ApiContext.Provider value={this.api}>
                    <TideApiContext.Provider value={this.tideApi}>
                        <NotifierContext.Provider value={this.notifier}>
                            {!isGCare ?
                                <div>
                                    <Switch>
                                        {
                                            appRoutes.map((prop, key) => {
                                                return (
                                                    <Route path={prop.path} component={prop.component} key={key}/>
                                                );
                                            })
                                        }
                                    </Switch>
                                    {this.props.loading ? <LoadingNotification/> : null}
                                </div>
                                :
                                <GCareLoader/>
                            }
                        </NotifierContext.Provider>
                    </TideApiContext.Provider>
                </ApiContext.Provider>
            </SocketApiContext.Provider>
        );
    }
}

App.childContextTypes = {
    api: PropTypes.object,
    notifier: PropTypes.object,
    socketApi: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return {
        changeSystem: (sys) => dispatch(changeSystem(sys)),
        loginRedirect: url=>dispatch(loginRedirect(url))
    }
}

function mapStateToProps(state) {
    return {
        loggedIn: state.api.loggedIn,
        loading: state.loading,
        api: state.api
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withReduxStore(App));
