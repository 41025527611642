import React, {useCallback} from 'react';
import CustomButton from "../../../../elements/CustomButton/CustomButton";

const PurchaseProductAddRowQuote = ( {onAdd, onHoverChange, isOpen, hasDiscount} )=>{

    const handleMouseEnter = useCallback( ()=>onHoverChange(true), [onHoverChange] );
    const handleMouseLeave = useCallback( ()=>onHoverChange(false), [onHoverChange] );

    return (
        <tr className={'PurchaseProductAddRowQuote quotation-row ' + (isOpen? 'q-open':'')}
            onMouseEnter={ handleMouseEnter }
            onMouseLeave={ handleMouseLeave }
        >
            <td colSpan={hasDiscount? 13 : 11 }>
                <div className='outer-div'><div className='inner-div'>
                    <CustomButton bsStyle={'primary'} onClick={ onAdd } >Agregar cotización</CustomButton>
                </div></div>
            </td>
        </tr>
    )
};

export default React.memo(PurchaseProductAddRowQuote);
