import React ,{useCallback} from 'react';
import {Modal, Table} from 'react-bootstrap';
import Button from '../../../../elements/CustomButton/CustomButton';
import CustomCheckbox from "../../../../elements/CustomCheckbox/CustomCheckbox";

const JazzAreaDetailModal = ({area, onHide, onDelete, onEdit}) => {

    const deleteClick=useCallback(()=>onDelete(area),[area, onDelete]);
    const editClick=useCallback(()=>onEdit(area),[area, onEdit]);

    return (
        <Modal
            show={true}
            onHide={onHide}
        >
            <Modal.Header>
                <Modal.Title>Detalles del área</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                    <Table>
                        <tbody>
                        <tr>
                            <td>Nombre</td>
                            <td>
                                {area.name}
                            </td>
                        </tr>
                        <tr>
                            <td>Presupuesto</td>
                            <td>
                                {area.monthlyAssignedBudget}
                            </td>
                        </tr>
                        <tr>
                            <td>Es Acumulable</td>
                            <td>
                                <CustomCheckbox
                                    isChecked={area.budgetIsAccumulated}
                                    input={{disabled: true}}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>P. Automático</td>
                            <td>
                                <CustomCheckbox
                                    isChecked={area.automaticBudget}
                                    input={{disabled: true}}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle='danger'  className='pull-left' onClick={onHide}>Cerrar</Button>
                <Button bsStyle='danger'  onClick={deleteClick}>Borrar</Button>
                <Button bsStyle='primary'  onClick={editClick}>Editar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default JazzAreaDetailModal;