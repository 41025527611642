import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import {displayAmount} from "../../../utils/modelUtils/almazen/unitsUtility";

const PromptStockMovementDifferencesModal=({onHide, stockMovementBatch, differences, onForceClose, onCloseWithPending})=>{

    let title;

    if(stockMovementBatch.purchaseOrder)
        title='Las siguientes cantidades especificadas en la orden de compra '+stockMovementBatch.purchaseOrder.id+' no coinciden.';

    if(stockMovementBatch.stockRequest)
        title='Las siguientes cantidades especificadas en la requisición '+stockMovementBatch.stockRequest.folio+' no coinciden.';

    const inOrOut=stockMovementBatch.inOrOut;
    const dir=inOrOut==='out'?-1:1;

    const plenty=differences.reduce((acc,diff)=>acc||(diff.quantity*dir)<0, false);
    const few=differences.reduce((acc,diff)=>acc||(diff.quantity*dir)>0, false);

    return (
        <Modal show onHide={onHide}>
            <ModalHeader closeButton><ModalTitle>
                Las cantidades no coinciden
            </ModalTitle></ModalHeader>
            <ModalBody>
                <h5>{title}</h5>
                <ul>
                    {differences.map((diff, index)=>
                        <li key={index}>
                            Hay <strong>{displayAmount(Math.abs(diff.quantity),diff.productBrand.product)}</strong> de&nbsp;
                            {diff.productBrand.product.name}
                            <strong>{(diff.quantity*dir)>0? ' faltantes.':' de sobra.'}</strong>
                        </li>
                    )}
                </ul>
                <hr/>
                <p>¿Qué deseas hacer?</p>

                {few&&<>
                    <p>
                        Los artículos faltantes {inOrOut==='out'?'no se enviarán':'ya no llegarán'}, cerrar&nbsp;
                        {stockMovementBatch.purchaseOrder?'la orden de compra':'el movimiento'}&nbsp;
                        con los artículos recibidos.
                    </p>
                    <CustomButton bsStyle='primary' onClick={onForceClose} className='pull-right'>
                        {inOrOut==='out'?'Enviar':'Recibir'} y cerrar movimiento
                    </CustomButton>
                    <div className='clearfix'/>
                    <hr/>
                </>}
                {plenty&&!few&&
                <>
                    <p>{inOrOut==='out'?'Enviar':'Ingresar'} todos los artículos incluyendo los sobrantes.</p>
                    <CustomButton bsStyle='primary' onClick={onForceClose} className='pull-right'>
                        {inOrOut==='out'?'Enviar':'Ingresar'} artículos
                    </CustomButton>
                    <div className='clearfix'/>
                    <hr/>
                </>
                }

                {few &&<>
                    <p>{inOrOut==='out'?'Se enviarán':'Entregarán'} los artículos en otro momento, {inOrOut==='out'?'enviar':'recibir'} estos artículos {plenty ?
                        <strong>incluyendo los sobrantes</strong> : ''} y
                        dejar una entrada pendiente con los artículos faltantes.
                    </p>
                    < CustomButton bsStyle='primary' onClick={onCloseWithPending} className='pull-right'>
                        {inOrOut==='out'?'Enviar':'Recibir'} y dejar el resto pendiente de {inOrOut==='out'?'envío':'recepción'}
                    </CustomButton>
                    <div className='clearfix'/>
                </>
                }

            </ModalBody>
            <ModalFooter>
                <CustomButton bsStyle='danger' onClick={onHide} className={'pull-left'}>Cancelar</CustomButton>
            </ModalFooter>
        </Modal>
    );
};

export default PromptStockMovementDifferencesModal;
