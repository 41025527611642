import React, {useCallback, useState} from 'react';
import {DateRangePicker} from "react-dates";
import CustomButton from "../../elements/CustomButton/CustomButton";
import {Modal, ModalBody, ModalHeader, ModalTitle} from "react-bootstrap";
import useBoolean from "../../hooks/useBoolean";


const falso = ()=>false;

const ReactTableDataRangeFilter = ( {filter,  onChange })=>{

    const [ open, openModal, closeModal ] = useBoolean();
    const [focused, setFocused] = useState(null);

    let text = '';
    if( filter && filter.value && filter.value.startDate )
        text = filter.value.startDate.format('DD/MM/YYYY') + ' -> ';

    if( filter && filter.value && filter.value.endDate )
        text += ( text? '':' -> ' ) + filter.value.endDate.format('DD/MM/YYYY');

    const handleChange = useCallback((dates)=>{
        onChange(dates);
    },[onChange]);


    return (
        <div className='ReactTableDataRangeFilter'>
            <CustomButton onClick={openModal} bsStyle='primary' >{text? text : 'Seleccionar fechas'}</CustomButton>

            <Modal
                show={open}
                onHide={closeModal}
                className='ReactTableDataRangeFilter'
            >
                <ModalHeader closeButton>
                    <ModalTitle>Rango de fechas</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <DateRangePicker
                        startDate={ filter && filter.value.startDate  } // momentPropTypes.momentObj or null,
                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                        endDate={filter && filter.value.endDate } // momentPropTypes.momentObj or null,
                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                        onDatesChange={handleChange} // PropTypes.func.isRequired,
                        focusedInput={focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={setFocused} // PropTypes.func.isRequired,
                        minimumNights={0}
                        isOutsideRange={falso}
                        hideKeyboardShortcutsPanel={true}
                    />
            </ModalBody>
            </Modal>
        </div>
    )
};

export default ReactTableDataRangeFilter;
