const _noInfoMessage = "Sin información";

export const columnsConfig = [
    {
        Header: 'Nombre',
        id: 'name',
        accessor: ({name}) => name || _noInfoMessage
    },
    {
        Header: 'Código',
        id: 'code',
        accessor: ({code}) => code || _noInfoMessage
    },
    {
        Header: 'Origen de Aerolínea',
        id: 'airlineOrigin',
        accessor: ({airlineOrigin}) => airlineOrigin?.spanishName||'Sin información'
    },
    {
        Header: 'Tipo de Aerolínea',
        id: 'airlineOrigin.name',
        accessor: ({airlineOrigin}) => {
            if(airlineOrigin?.id === 141 || airlineOrigin?.spanishName === 'México')
                return 'Doméstico';
            else if (airlineOrigin)
                return 'Extranjero';

            return _noInfoMessage;
        }
    }
];
