import {Component} from "react";
import PropTypes from "prop-types";
import {Modal, FormGroup, Radio, InputGroup, FormControl} from "react-bootstrap";
import Button from "../../../../elements/CustomButton/CustomButton";
import connect from "react-redux/es/connect/connect";
import React from "react";
import moneyFormatter from '../../../../utils/moneyFormatter';
import Loader from '../../../../components/Helper/LoaderIcon';
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";


class SettlementDetailModal extends Component {

    static contextTypes = {
        api: PropTypes.object,
        notifier: PropTypes.object
    };

    state = {
        isFiniquito: true,
        settlementAmount: '',
        settlementComment:''
    };

    loadingId = "@Class.SettlementDetailModal.layoffs";

    saveSettlement = ()=>{

        if( this.props.loadingIds[this.loadingId] ) return;

        let settlementAmount = parseFloat( this.state.settlementAmount );
        if( !settlementAmount ){
            this.context.notifier.error("Debes ingresar un monto válido");
            return;
        }

        settlementAmount = Math.floor( settlementAmount * 100 );

        const newLayoff = { ...this.state, settlementAmount};

        const layoff = this.props.user.employee.layoffs[ this.props.user.employee.layoffs.length - 1 ];
        this.context.api.layoffs.edit( layoff.id, newLayoff, this.loadingId )
            .then(()=>{
                this.context.api.users.update(this.props.user.id, {isActive:false});
                this.context.notifier.success( this.state.isFiniquito? "Finiquito creado correctamente":"Liquidación creada correctamente");
                this.props.onHide();
            });

    };

    editSettlement = ()=>{

        const layoff = this.props.user.employee.layoffs[ this.props.user.employee.layoffs.length - 1 ];

        this.setState({
            isFiniquito: layoff.isFiniquito,
            settlementAmount: layoff.settlementAmount/100.0,
            settlementComment: layoff.settlementComment,
            editing: true
        });
    };

    render() {

        const employee = this.props.user.employee;
        const layoff = employee.layoffs[ employee.layoffs.length - 1 ];
        const adding = !layoff.settlementAmount;

        const isFiniquito = (adding && this.state.isFiniquito) || ( !adding && layoff.isFiniquito ) ;

        return (
                <Modal
                    show={true}
                    onHide={this.props.onHide}
                >
                    <Modal.Header closeButton >
                        <Modal.Title>{isFiniquito? "Finiquito":"Liquidación"} de {employeeFullName(employee)}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        {adding || this.state.editing?
                        <div>
                            <p>¿Deseas agregar un finiquito o liquidación?</p>
                            <FormGroup>
                                <Radio
                                    name="radioGroup"
                                    inline
                                    checked={this.state.isFiniquito}
                                    onChange={()=>this.setState({isFiniquito:true})}
                                >
                                    Finiquito
                                </Radio>{' '}
                                <Radio
                                    name="radioGroup"
                                    inline
                                    checked={!this.state.isFiniquito}
                                    onChange={()=>this.setState({isFiniquito:false})}
                                >
                                    Liquidación
                                </Radio>{' '}
                            </FormGroup>
                            <p>Ingresa el monto de{isFiniquito? "l finiquito":" la liquidación"}</p>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroup.Addon>$</InputGroup.Addon>
                                    <FormControl type="text" value={this.state.settlementAmount} onChange={(e)=>this.setState({settlementAmount:e.target.value})} />
                                </InputGroup>
                            </FormGroup>
                            <p>¿Deseas agregar algún comentario ?</p>
                            <textarea className='form-control' value={this.state.settlementComment} onChange={(e)=>this.setState({settlementComment:e.target.value})} />
                        </div>
                            :
                        <div>
                            <p>Se generó un{isFiniquito? " finiquito": "a liquidación"} por la cantidad de <strong>{moneyFormatter(layoff.settlementAmount)}</strong></p>
                            <p>Comentarios:</p>
                            <pre>
                                {layoff.settlementComment? layoff.settlementComment: "Sin comentarios." }
                            </pre>

                        </div>
                        }



                    </Modal.Body>

                    <Modal.Footer>

                        <Button bsStyle='danger' className='pull-left' onClick={this.props.onHide} >Cerrar</Button>

                        {adding || this.state.editing?
                            <Button bsStyle='success' onClick={this.saveSettlement} >
                                {this.props.loadingIds[this.loadingId]?<Loader/>:"Guardar"}
                            </Button>
                            :
                            <Button bsStyle='primary' onClick={this.editSettlement} >
                                {this.props.loadingIds[this.loadingId]?<Loader/>:"Editar"}
                            </Button>
                        }

                    </Modal.Footer>
                </Modal>
        );
    }
}


function mapStateToProps( {api:{ me }, loadingIds={} } ) {

    return { me, loadingIds }
}

export default connect(mapStateToProps)(SettlementDetailModal);
