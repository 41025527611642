import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LoaderIcon from '../../Helper/LoaderIcon';
import _ from 'lodash';


class FileAnswer extends Component{

    static contextTypes = { api:PropTypes.object };

    state = {
        uploadingFiles: [],
        uploadedFiles: [],
        deletingFiles:[],
        inputKey: 0
    };

    onChange = ( {target} ) => {

        if( !target.files.length )
            return;

        const file = target.files[0];
        const name = file.name;

        const uploadingFiles = [ ...this.state.uploadingFiles, name ];
        const inputKey = Math.random(); //This will clear the file input

        this.setState( { uploadingFiles, inputKey } );

        this.context.api.appFiles.create( file )
            .then(( appFile )=>{
                const uploadedFiles = [ ...this.state.uploadedFiles, appFile];

                const index = this.state.uploadingFiles.indexOf( name );
                const newUploadingFiles = [ ...this.state.uploadingFiles ];
                newUploadingFiles.splice( index,1 );

                this.setState({uploadingFiles:newUploadingFiles, uploadedFiles});

                let response;
                if( this.props.data && this.props.data.response )
                    response = [ ...this.props.data.response, appFile.id ];
                else
                    response = [ appFile.id ];

                this.props.onChange( {response} );
            })
            .catch(()=>{
                const index = this.state.uploadingFiles.indexOf( name );
                const newUploadingFiles = [ ...this.state.uploadingFiles ];
                newUploadingFiles.splice( index,1 );
                this.setState({uploadingFiles:newUploadingFiles});
            })
    };

    deleteFile = ( {target} )=>{

        const id = target.dataset.id;
        this.setState( { deletingFiles: [ ...this.state.deletingFiles, id ] } );


        if( this.props.data && this.props.data.response ) {
            const index = this.props.data.response.indexOf( Number(id) ) ;
            if( index !== -1 ) {
                const response = [ ...this.props.data.response ];
                response.splice(index,1);
                this.props.onChange({response});
            }
        }

        this.context.api.appFiles.delete( id )
            .then( ()=>{
                const index = this.state.deletingFiles.indexOf( id );
                const newDeleting = [ ...this.state.deletingFiles ];
                newDeleting.splice( index, 1 );

                const upIndex = _.findIndex( this.state.uploadedFiles, (appFile)=>appFile.id+''===id+''  );
                const newUploaded = [ ...this.state.uploadedFiles ];
                newUploaded.splice( upIndex, 1 );

                this.setState( { deletingFiles:newDeleting, uploadedFiles: newUploaded } );
            } )
    };

    render() {

        const { minFiles, maxFiles } = this.props.config;

        return (
            <div className='answer-component FileAnswer'>
                {this.state.uploadingFiles.length?
                <div>
                    Subiendo archivos:
                    <ul>
                        { this.state.uploadingFiles.map((file,i)=>
                            <li key={file + i}><LoaderIcon/> {file}</li>
                        )}
                    </ul>
                </div>
                    :null}

                {this.state.uploadedFiles.length?
                <div>
                    Archivos subidos:
                    <ul>
                        {this.state.uploadedFiles.map(( appFile )=>
                            <li key={appFile.id}>{appFile.originalName}&nbsp;
                                {this.state.deletingFiles.indexOf(appFile.id) !== -1 ?
                                    <LoaderIcon/> :
                                    <i className='fa fa-times red'  data-id={appFile.id} onClick={this.deleteFile}/>
                                }<br/>
                                <img  alt='uploaded' src={this.context.api.appFiles.getUrl( appFile.id )} />
                            </li>
                        )}
                    </ul>
                </div>
                    :null}

                { !this.props.editable && this.props.data && this.props.data.response && this.props.data.response.length ?
                    <ul>
                        {this.props.data.response.map( (appFileId)=><li key={appFileId}><img  alt='uploaded' className='uploaded-image' src={this.context.api.appFiles.getUrl( appFileId )} /></li>   )}
                    </ul>
                    :null}

                { this.props.editable && (this.state.uploadedFiles.length + this.state.uploadingFiles.length < maxFiles) ?
                <input
                    key={ this.state.inputKey }
                    className='form-control'
                    onChange={this.onChange}
                    type='file'
                />
                    :null}

                { this.props.editable?
                <p className='text-muted small'>Debes subir por lo menos {minFiles} archivo{minFiles > 1? 's':''}</p>
                    :null}
            </div>
        );

    }
}

export default FileAnswer;
