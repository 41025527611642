import React, {useEffect, useContext, useState} from 'react';
import moment from "moment";
import {DateRangePicker} from "react-dates";
import useDateRangePickerState from "../../../hooks/useDateRangePickerState";
import {ApiContext} from "../../../api/Api";
import {TideApiContext} from "../../../api/tideApiConfig";
import _ from 'lodash';
import {useSelector} from "react-redux";
import moneyFormatter from "../../../utils/moneyFormatter";
import {Table} from "react-bootstrap";
import PurchaseProductsReport from "./PurchaseProductsReport";


const JazzDashboard=()=>{

    const { props:dateRangeProps, dateRange}=useDateRangePickerState(initialFilters);
    const api=useContext(ApiContext);
    const tideApi=useContext(TideApiContext);
    const [dateRangedRequisitions, setDateRangedRequisitions] = useState();
    const [filteredRequisitions, setFilteredRequisitions] = useState();


    useEffect(()=>{
        const filters={
            fromDate:dateRange.startDate? dateRange.startDate.format('YYYY-MM-DD'):undefined,
            toDate: dateRange.endDate?dateRange.endDate.format('YYYY-MM-DD'):undefined};
        api.purchaseRequisitions.getStats({filters});
    },[api, dateRange]);

    useEffect(() => {
        const params={
            'requestedDate[after]':dateRange.startDate? dateRange.startDate.add(1, 'days').format('YYYY-MM-DD'):undefined,
            'requestedDate[before]': dateRange.endDate?dateRange.endDate.add(1, 'days').format('YYYY-MM-DD'):undefined,
            pagination: false,
            sGroups: ["purchase_requisition_read_jazz_area", "purchase_requisition_read", "jazz_area_read"]
        };
        tideApi.purchaseRequisitions.get({customProp:'@OrderPurchaseList', params}).then((response) => {
            setDateRangedRequisitions(response);
        })
    }, [dateRange, tideApi]);

    useEffect(() => {
        const filteredRequisitions = _.filter(dateRangedRequisitions, function(req){ return req?.jazzArea !== null });
        let hash = {};
        let requisitionsOrderedByJazzArea = [];
        if (filteredRequisitions) {
            filteredRequisitions.forEach((req) => {
                var key = req.jazzArea.id;
                if (!hash[key]) {
                    hash[key] = [];
                    requisitionsOrderedByJazzArea.push(hash[key]);
                }
                hash[key].push(req);
            });
        }
        console.log(requisitionsOrderedByJazzArea);
        setFilteredRequisitions(requisitionsOrderedByJazzArea);
    }, [dateRangedRequisitions]);

    const stats = useSelector(({api})=>api.purchaseRequisitionsStats);

    const totalRequisitions=stats && stats.totalRequisitions.reduce((acc, total)=>({...acc, ...total[0]}),{});
    const totalAmounts=stats && stats.totalAmounts.reduce((acc, total)=>({...acc, ...total[0]}),{});


    return (
        <div className='JazzDashboard container-fluid main-container'>
            <div className='row'>
                <div className='col-xs-12 filters-panel'>
                    <h5>Rango de fechas de estadísticas</h5>
                    <DateRangePicker {...dateRangeProps} />
                </div>
            </div>

            {stats&&<>
            <div className='row'>
                {/*************   Total de requisiciones    *************/}
                <div className='col-xs-12 col-sm-6 col-md-4 quad requisitions-quad'>
                    <h2>{
                        Number(totalRequisitions.totalRequisitionsApproved)+
                        Number(totalRequisitions.totalRequisitionsDenied)+
                        Number(totalRequisitions.totalRequisitionsCancelled)+
                        Number(totalRequisitions.totalRequisitionsQuoted)
                    } requisiciones</h2>
                    <div className='quad-horizontal'>
                        <div className='quad-vertical tl'>
                            <span className='stat-name'>Aprobadas</span><br/>
                            <span className='stat-num'>{totalRequisitions.totalRequisitionsApproved}</span>
                        </div>
                        <div className='quad-vertical tr'>
                            <span className='stat-name'>Denegadas</span><br/>
                            <span className='stat-num'>{totalRequisitions.totalRequisitionsDenied}</span>
                        </div>
                    </div>
                    <div className='quad-horizontal'>
                        <div className='quad-vertical bl'>
                            <span className='stat-name'>Canceladas</span><br/>
                            <span className='stat-num'>{totalRequisitions.totalRequisitionsCancelled}</span>
                        </div>
                        <div className='quad-vertical br'>
                            <span className='stat-name'>Cotizadas</span><br/>
                            <span className='stat-num'>{totalRequisitions.totalRequisitionsQuoted}</span>
                        </div>
                    </div>
                </div>

                {/*************   Totales en pesos    *************/}
                <div className='col-xs-12 col-sm-6 col-md-4 total-amounts'>
                    <h2>Totales del periodo</h2>

                    <div className='amount-block'>
                        <h4>{moneyFormatter(totalAmounts.totalApproved,1,'MXN')}</h4>
                        <div className='stat'>Aprobados</div>
                    </div>
                    <div className='amount-block' >
                        <h4>{moneyFormatter(totalAmounts.totalPaid,1,'MXN')}</h4>
                        <div className='stat'>Pagados</div>
                    </div>
                    <div className='amount-block'>
                        <h4>{moneyFormatter(totalAmounts.totalUnpaid,1,'MXN')}</h4>
                        <div className='stat'>Por pagar</div>
                    </div>

                </div>

                {/*************   Top 3 proveedores    *************/}
                <div className='col-xs-12 col-sm-6 col-md-4 top-providers'>
                    <h2>Top 3 proveedores</h2>
                    {stats.topProviders.map((topProvider, i)=>
                    <div className='provider-block' key={i}>
                        <h4>{topProvider.providerName}</h4>
                        <div className='stat'>{topProvider.purchases} compras</div>
                        <div className='stat'>Total {moneyFormatter(topProvider.total,1)}</div>
                    </div>
                    )}
                </div>
            </div>
            <hr/>
            <div className='row'>
                <div className='col-xs-12 col-md-6 area-totals'>
                    <h2 className='text-center'>Totales por área</h2>
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th>Área</th>
                                <th>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                        {filteredRequisitions?.map((requisitionsByArea) => (
                            (_.some(requisitionsByArea, {'status':'approved'}) === true)
                                ?   (<tr key={requisitionsByArea[0].jazzArea.id}>
                                        <td>{requisitionsByArea[0].jazzArea.name}</td>
                                        <td>{moneyFormatter(_.filter(requisitionsByArea, function(requisition){return requisition.status === 'approved'})
                                        .reduce((start, requisition) => start + Number(requisition.total), 0),1, 'MXN')}</td>
                                    </tr>)
                                : null
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className='col-xs-12 col-md-6 area-totals'>
                    <h2 className='text-center'>Pendiente de autorizar</h2>
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th>Área</th>
                                <th>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                        {filteredRequisitions?.map((requisitionsByArea) => (
                            (_.some(requisitionsByArea, function(request){return request.status === 'quoted'}) === true)
                                ?   (<tr key={requisitionsByArea[0].jazzArea.id}>
                                        <td>{requisitionsByArea[0].jazzArea.name}</td>
                                        <td>{moneyFormatter(_.filter(requisitionsByArea, function(requisition){return requisition.status === 'quoted'})
                                        .reduce((start, requisition) => start + Number(requisition.total), 0),1, 'MXN')}</td>
                                    </tr>)
                                : null
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>



                <div className="row">
                   <div className="col-md-12">
                       {/*<PurchaseRequisitionsReport/>*/}
                   </div>
                    <div className="col-md-12">
                        <hr/>
                        <PurchaseProductsReport/>
                    </div>
                </div>
            </>}
        </div>
    );
};

export default JazzDashboard;

const initialFilters = {
    startDate: moment().date(1),
    endDate: moment()
};
