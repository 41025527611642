import React, {useContext} from 'react';
import defaultRewardImage from '../../../assets/img/tgle-rewards/default-reward.png';
import wishedImage from '../../../assets/img/tgle-rewards/store/wished.svg';
import notWishedImage from '../../../assets/img/tgle-rewards/store/not-wished.png';
import {ApiContext} from "../../../api/Api";

const Product = ({id, name, image, wished, pointsCost, toggleWish, navigation}) => {
    const api = useContext(ApiContext);

    const productImage = image ? api.appFiles.getUrl(image.id) : defaultRewardImage;
    return (
        <div className='product-container'>
            <div onClick={()=>navigation.push('/rewards/product/'+id)} className='image-container'>
                <img alt={'Product ' + name} src={productImage}/>
            </div>
            <div className='product-info'>
                <div>
                    <p>{name.toUpperCase()}</p>
                    <p className='bold'>Valor: {pointsCost}pts.</p>
                </div>
                <button className='wish-button' onClick={toggleWish}>
                    {wished ?
                        <img alt='Wished product' src={wishedImage}/> :
                        <img alt='Not wished product' src={notWishedImage}/>
                    }
                </button>
            </div>
        </div>
    );
};

export default Product;
