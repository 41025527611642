import React, {useState, useContext, useEffect, useRef, useLayoutEffect, useCallback} from 'react';
import Comment from "./Comment";
import {useSelector} from "react-redux";
import {ApiContext} from "../../api/Api";
import useStayScrolled from 'react-stay-scrolled';
import _ from 'lodash';
import ModalFile from "../ModalFile/ModalFile";
import AppFileThumb from "./AppFileThumb";
import Switch from "react-bootstrap-switch";

const createLoadingId = "@CommentsFeed.create";
const listLoadingId = "@CommentsFeed.list";

const CommentsFeed = ({
                          title,
                          height,
                          hideSubmit,
                          hideReload,
                          hideComments,
                          onMessageChange,
                          onFilesChange,
                          filesAsProp,
                          messageAsProp,
                          onCommentAdded,
                          onCommentRemoved,
                          cassetteId}) => {

    const me=useSelector(({api})=>api.me);
    const loadingIds=useSelector(({loadingIds})=>loadingIds);

    const [message, setMessage] = useState('');
    const [files, setFiles] = useState([]);

    const [comments, setComments] = useState([]);
    const [commentFiles, setCommentFiles] = useState([]);

    const [showFile, setShowFile] = useState(null);
    const [cassetteEmployee, setCassetteEmployee] = useState(null);

    const api = useContext(ApiContext);

    const listRef = useRef(null);
    const {stayScrolled} = useStayScrolled(listRef);

    // Typically you will want to use stayScrolled or scrollBottom inside
    // useLayoutEffect, because it measures and changes DOM attributes (scrollTop) directly
    useLayoutEffect(() => {
        stayScrolled();
    }, [stayScrolled, comments.length]);

    const fetchComments = useCallback( () => {
        if (cassetteId) {
            api.apiCall('/api/cassettes/'+cassetteId+'/comments', "comments"+cassetteId, {
                "order[createdDate]": "DESC",
                "itemsPerPage": 500
            }).then((comments) => {
                const newComments = [...comments];
                newComments.reverse();

                const newCommentFiles = [];
                for (let i = 0; i < newComments.length; i++) {
                    for (let j = 0; j < newComments[i].appFiles.length; j++) {
                        if(!newComments[i].isRemoved)
                            newCommentFiles.push(newComments[i].appFiles[j]);
                    }
                }
                setCommentFiles(newCommentFiles);
                setComments(newComments);
            });
            if(me.employee)
                api.cassetteEmployees.get(cassetteId, me.employee.id).then((cassetteEmployees)=>{
                    if(cassetteEmployees.length>0)
                        setCassetteEmployee(cassetteEmployees[0]);
                });
        }
    }, [ api, me, cassetteId ]);

    useEffect(() => {
        fetchComments();
    }, [fetchComments]);

    const onFilesInputChange = (e) => {
        const newFiles = [...(onFilesChange ? filesAsProp : files)];
        for (let i = 0; i < e.target.files.length; i++) {
            const foundFile = _.find(files, (f) => (e.target.files[i].name === f.name) && (e.target.files[i].type === f.type) && (e.target.files[i].size === f.size));
            if (!foundFile) {
                newFiles.push(e.target.files[i]);
            }
        }
        if (onFilesChange !== undefined)
            onFilesChange(newFiles);
        else
            setFiles(newFiles);
    };

    const onMessageInputChange = (e) => {
        if (onMessageChange !== undefined)
            onMessageChange(e.target.value);
        else
            setMessage(e.target.value);
    };

    const onToggleNotifications = ()=>{
          api.cassetteEmployees.edit(cassetteEmployee.id, !cassetteEmployee.notificationsEnabled).then((cassetteEmployee)=>{
                setCassetteEmployee(cassetteEmployee);
          });
    };

    const onMessageKeyUp = (e)=>{
        if (e.keyCode === 13) {
           onSubmit();
        }
    };

    const onRemoveFile = (e) => {
        const newFiles = [...(onFilesChange ? filesAsProp : files)];
        newFiles.splice(e.currentTarget.dataset.index, 1);
        if (onFilesChange !== undefined)
            onFilesChange(newFiles);
        else
            setFiles(newFiles);
    };

    const onCommentRemove = (e) => {
        const commentId = e.currentTarget.dataset.commentid;
        api.comments.edit(commentId, true).then(()=>{
            fetchComments();
            if(onCommentRemoved){
                onCommentRemoved(commentId);
            }
        });
    };

    const onShowFile = (e) => {
        const fileName = e.currentTarget.dataset.filename;
        const src = e.currentTarget.dataset.src;
        const newShowFile = {name: fileName, src: src};
        setShowFile(newShowFile);
    };

    const onHideFile = () => {
        setShowFile(null);
    };

    const onSubmit = () => {
        if (cassetteId && (message || files.length > 0)) {
            api.comments.create({
                text: message,
                cassette: cassetteId
            }, createLoadingId, files).then((comment) => {
                const newComments = [...comments, comment];
                const newCommentFiles = [...commentFiles];
                for (let i = 0; i < comment.appFiles.length; i++) {
                    newCommentFiles.push(comment.appFiles[i]);
                }
                setComments(newComments);
                setCommentFiles(newCommentFiles);
                if(onCommentAdded){
                    onCommentAdded(comment);
                }
            });
            if (onMessageChange !== undefined)
                onMessageChange('');
            else
                setMessage('');

            if (onFilesChange !== undefined)
                onFilesChange([]);
            else
                setFiles([]);
        }
    };


    return (
        <div className={"CommentsFeed card " + (hideSubmit?'submit-hidden':'')}>
            <div className="card-header">
                <h4 className="card-title">
                    <span>{title ? title : 'Comentarios'}</span>
                    {!hideReload ?
                    <button className='btn btn-fill btn-sm pull-right btn-primary btn-rotate' onClick={fetchComments}
                            disabled={loadingIds[listLoadingId]}>
                    <span className={'btn-label ' + (loadingIds[listLoadingId] ? 'fa fa-spin' : '')}><i className='fa fa-refresh'/></span>
                    </button> : null}

                    { (cassetteEmployee) ?
                    <div className='pull-right notifications-toggle hidden-xs small' onClick={onToggleNotifications}>
                         Recibir notificaciones &nbsp;
                        <Switch value={cassetteEmployee.notificationsEnabled} />
                    </div>: null
                    }
                </h4>


            </div>
            <div className="card-content">
                <ul className="chat" style={{height: height, display: hideComments ? "none" : "block"}} ref={listRef}>
                    {comments.map((comment) =>
                        <Comment
                            onFileClick={onShowFile}
                            key={comment.id}
                            comment={comment}
                            onCommentRemove={onCommentRemove}
                            self={comment.createdBy ? (comment.createdBy.id === me.id) : false}/>)
                    }
                </ul>

                <hr/>
                {commentFiles.length > 0 ?
                    <div>
                        {commentFiles.map((f) => (
                            <AppFileThumb key={f.id} onClick={onShowFile} appFile={f}/>
                        ))}
                        <div className="clearfix"/>
                    </div> : null

                }

                <div className="send-message">

                    <input
                        className="form-control textarea"
                        type="text" placeholder="Escribe aquí..."
                        onChange={onMessageInputChange}
                        onKeyUp={onMessageKeyUp}
                        value={onMessageChange ? messageAsProp : message}
                    />
                    &nbsp;

                    {!hideSubmit ?
                        <div className="send-button">
                            <button
                                className="btn btn-primary btn-fill"
                                disabled={loadingIds[createLoadingId]}
                                onClick={onSubmit}
                            >Enviar
                            </button>
                        </div> : null}

                    <div className="file-button">
                        <label htmlFor="file-input" className="btn btn-secondary btn-fill">
                            <i className="fa fa-file-archive-o"/>
                        </label>
                        <input key={Math.random()} id="file-input" accept="*" type="file" multiple={true}
                               onChange={onFilesInputChange}/>
                    </div>

                    {(onFilesChange ? filesAsProp : files).length > 0 ?
                        <div className="files">
                            {(onFilesChange ? filesAsProp : files).map((file, index) => (
                                <div key={index}>
                                    <i className="fa fa-file"/>
                                    {file.name}
                                    <i data-index={index}
                                       onClick={onRemoveFile}
                                       className="red fa fa-times"/>
                                </div>
                            ))
                            }
                        </div>
                        : null}

                </div>

            </div>

            {showFile ?
                <ModalFile
                    show={true}
                    onHide={onHideFile}
                    name={showFile.name}
                    src={showFile.src}
                /> : null}
        </div>
    );
};

export default CommentsFeed;
