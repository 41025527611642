import React, {useContext, useState} from 'react';
import {NotifierContext} from "../../../utils/Notifier";
import {TideApiContext} from "../../../api/tideApiConfig";

const ImportInventoryChecker = () => {

    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);

    const [response, setResponse] = useState('');

    const uploadFile = (e)=>{
        if(!e.target.files.length){
            notifier.error("Selecciona un archivo tipo XLSX");
            return;
        }
        console.log(e.target.files[0]);
        api.apiCall('/inventories/import-check',  '_', {}, {method:'post'},  {file:e.target.files[0]})
            .then(response=>response.text())
            .then(response=>{
                console.log(response);
                setResponse(response);
            })
    }


    return (
        <div className={"ImportInventoryChecker"}>
            <h2>Checar importación de inventario de cedis</h2>
            <p>Selecciona el archivo a subir</p>
            <input type='file' onChange={uploadFile}/>
            <div dangerouslySetInnerHTML={{__html:response}} />
            <hr/>
            <p>El archivo debe tener la siguientes columnas:</p>
            <ul>
                <li>Columna A: ID de producto</li>
                <li>Columna B: Nombre de producto</li>
                <li>Columna C: Marca</li>
                <li>Columna D: Línea</li>
                <li>Columna E: Ignorada</li>
                <li>Columna F: Unidad</li>
                <li>Columna G: Ignorada</li>
                <li>Columna H: Total</li>
            </ul>
        </div>
    );
};

export default ImportInventoryChecker;
