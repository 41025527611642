
import React, {useState} from 'react'
import {Modal, Table} from 'react-bootstrap';
import StatsCard from '../../../../components/Card/StatsCard';

export const GCareScoreWidget = ({employee}) => {
    const [gCareScoreOpen, setGCareScoreOpen] = useState();

    return (
        <>
            <StatsCard
                bigIcon={<i className="fa fa-check text-success" />}
                statsValue={employee?.autoScoreValue}
                statsText="Calificación GCare"
                statsIcon={<span className='link'  onClick={() => setGCareScoreOpen(true)}><i className="fa fa-eye" /></span>}
                statsIconText={<span className='link'  onClick={() => setGCareScoreOpen(true)}>Ver detalles</span>}
            />

            <Modal show={gCareScoreOpen} 
                bsSize="large" 
                onHide={()=>setGCareScoreOpen(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Detalle de preguntas GCare</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                        <tr>
                            <td className="text-center">
                                <div className="modal-header text-white">Pregunta</div>
                            </td>
                            <td className="px-3">
                                <div className="modal-header text-white">Promedio</div>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        {employee?.autoScoreValuesList?.map((stats, index)=>(
                            <tr key={index} className="p-5" style={{ backgroundColor: ((index%2)===0 ? 'rgba(0,0,0,0.03)' : '') }} >
                                <td className="p-4">{stats.question}</td>
                                <td className="p-4 text-center">{stats.value}</td>
                            </tr>
                        ))}
                        </tbody>                            
                    </Table>
                </Modal.Body>
            </Modal>
        </>
    );
}
