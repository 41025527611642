import React, {Component} from 'react';
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom';
import Notifications from 'react-notification-system-redux';
import Sidebar from '../../components/Sidebar/Sidebar';
//import Header from '../../components/Header/Header.jsx';
import routesForUser from '../../utils/routesForUser';
import PropTypes from 'prop-types';
import {style} from "../../variables/Variables.jsx";// style for notifications
import {connect} from "react-redux";
import avatar from '../../assets/img/faces/face-0.jpg';
import sysadminAvatar from '../../assets/img/faces/sysadmin.png';
import {Modal} from 'react-bootstrap';
import {showUserDetail, hideUserDetail} from "../../actions/action_userDetail";
import {bindActionCreators} from 'redux';
import UserDetail, {UserDetailLoadingId, UserDetailApiProp} from '../../views/alma/Employees/core/UserDetail';
import systemsForUser from '../../utils/systemsForUser';
import {changeSystem} from "../../actions/action_changeSystem";
//import CustomButton from "../../elements/CustomButton/CustomButton";
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import ProfileCompletionModal from '../../views/alma/HumanResources/ProfileCompletionModal';
import NewPasswordConfirmationModal from "../../views/alma/HumanResources/NewPasswordConfirmationModal";
//import profileCompletionImage from '../../assets/img/encuesta_elite.png';
//import initModalImage from '../../assets/img/core/initial-modal-img.png';
import SecurityManager, {SecurityContext} from "../../utils/SecurityManager";
import Footer from "../../components/Footer/Footer";
import ChatManager, {ChatManagerContext} from "../../utils/ChatManager/ChatManager";
import {HeaderFooterContextProvider} from '../../utils/header-footer-helper';
import GuestChatNotifier from "utils/ChatManager/GuestChatNotifier";
import footersForUser from "../../footers/footersForUser";
import headersForUser from "../../headers/headersForUser";

/**
 * IMPORTANT!
 * This is not a Dash, the name remains because of legacy reasons in the original theme
 * This is the main component which renders the app, here is the router with the main app routes
 */
class Dash extends Component {

    constructor(props, context) {
        super(props);
        this.chatManager = new ChatManager(context.socketApi);
    }

    componentDidMount() {

        this.context.api.me.get()
            .then(
                (me) => {
                    const system = this.props.location.pathname.split('/')[1];

                    if (system && system !== this.props.system && systemsForUser(this.props.me).indexOf(system) !== -1)
                        this.props.onSystemChange(system);

                    else if (!system || systemsForUser(this.props.me).indexOf(system) === -1) {
                        const availableSystems = systemsForUser(me);
                        this.props.onSystemChange(availableSystems[0]);
                    }

                    const securityManager = new SecurityManager(me);

                    this.chatManager.setCredentials(me, securityManager);
                    this.setState({securityManager});
                })
            .catch(response=>{
                console.log('me failed', response);
            });

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        /* When we load a new user to show details we set it to be displayed */
        if (this.props.loadingIds[UserDetailLoadingId] && !nextProps.loadingIds[UserDetailLoadingId] && nextProps.newUserDetail && nextProps.newUserDetail.length)
            this.props.showUserDetail(nextProps.newUserDetail[0]);
    }


    state = {
        popUpSeen: window.localStorage.popupSeen
    };

    hidePopUp = () => {
        this.setState({popUpSeen: popupVersion})
    };

    hidePopUpForever = () => {
        this.setState({popUpSeen: popupVersion});
        window.localStorage.popupSeen = popupVersion;
    };


    render() {

        const systemRoutes = routesForUser(this.props.me ? this.props.me : null, this.props.system);
        const allRoutes = routesForUser(this.props.me ? this.props.me : null, 'all');

        const footers = footersForUser(this.props.me ? this.props.me : null);
        const headers = headersForUser(this.props.me ? this.props.me : null);

        const deavatar = !this.props.me ?
            avatar :
            !this.props.me.employee ?
                sysadminAvatar :
                this.props.me.employee.photo ?
                    this.context.api.createFileUrl(this.props.me.employee.photo) :
                    avatar;

        const wrapperCSSClasses = [
            'wrapper',
            `system-${this.props.system}`
        ];

        return (
            <SecurityContext.Provider value={this.state.securityManager}>
                <HeaderFooterContextProvider>
                    <ChatManagerContext.Provider value={this.chatManager}>
                        {this.chatManager && this.chatManager.shouldSeeGuestChat() ?
                            <GuestChatNotifier/> : null}

                        <div className={wrapperCSSClasses.join(' ')}>
                            <Notifications notifications={this.props.notifications} style={style}/>
                            <Sidebar
                                {...this.props}
                                avatar={deavatar}
                                routes={systemRoutes}
                                onMeClick={() => this.props.showUserDetail(this.props.me)}
                                history={this.props.history}
                            />
                            <div className="main-panel" ref="mainPanel">

                                {/*<Header {...this.props} routes={systemRoutes}/>*/}

                                <Switch>
                                    {
                                        headers.map(h => (
                                            <Route path={h.path} render={props => React.createElement(h.component, {
                                                ...props, ...this.props,
                                                routes: systemRoutes
                                            })} key={h.path}/>
                                        ))
                                    }
                                </Switch>

                                <Switch>
                                    {
                                        !this.props.me || !this.state.securityManager ? null :
                                            allRoutes.map((prop, key) => {
                                                if (prop.collapse) {
                                                    return prop.views.map((prop, key) => {
                                                        if (prop.justMenu)
                                                            return null;
                                                        return (
                                                            <Route exact={!(prop.exact === false)} path={prop.path}
                                                                   component={prop.component} key={key}/>
                                                        );
                                                    })
                                                }
                                                if (prop.redirect)
                                                    return (
                                                        <Redirect from={prop.path} to={prop.pathTo} key={key}/>
                                                    );
                                                else if (prop.justMenu)
                                                    return null;
                                                else
                                                    return (
                                                        <Route exact={!(prop.exact === false)} path={prop.path}
                                                               component={prop.component} key={key}/>
                                                    );
                                            })
                                    }
                                    {this.props.me && this.state.securityManager ?
                                        <Redirect from='/'
                                                  to={systemRoutes.length ? systemRoutes[0].path : allRoutes[0].path}/>
                                        : null}
                                </Switch>

                                <Switch>
                                    {
                                        footers.map(f => (
                                            <Route path={f.path} render={props => React.createElement(f.component, {
                                                ...props,
                                                systemRoutes,
                                                location: this.props.location.pathname
                                            })} key={f.path}/>
                                        ))
                                    }
                                </Switch>

                                {this.state.securityManager && this.state.securityManager.core.canSeeGuestChat() ?
                                    <Footer/>
                                    : null}

                                {this.props.me && this.props.me.employee && !this.props.me.employee.employeeProfile && process.env.REACT_APP_BUILD === 'prod' ?
                                    <ProfileCompletionModal/>
                                    : null}

                                {this.props.me && this.props.me.changeMonthlyPassword && this.props.me.newPassword ?
                                    <NewPasswordConfirmationModal
                                        newPassword={this.props.me.newPassword}
                                    /> : null}

                                {/* User detail should be available to be opened system wide */}
                                {this.props.userDetail ?
                                    <Modal
                                        show={true}
                                        bsSize="lg"
                                        onHide={this.props.hideUserDetail}
                                    >
                                        <Modal.Header closeButton>
                                 <Modal.Title>{this.props.userDetail.employee?employeeFullname(this.props.userDetail.employee):'Admin'}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <UserDetail
                                                user={this.props.userDetail}
                                                onHide={this.props.hideUserDetail}
                                                canSeeWage={this.props.userDetail.id === this.props.me.id}
                                            />
                                        </Modal.Body>

                                    </Modal>
                                    : null}


                                {/* this.props.me && this.props.me.employee && !this.props.me.employee.employeeProfile && this.state.popUpSeen !== popupVersion ?
                                    <Modal
                                        show={true}
                                        onHide={this.hidePopUp}
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Encuesta de preferencias Elite</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <img className={"img-responsive profile-completion-image"}
                                                 src={profileCompletionImage} alt="Profile Completion"/>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <CustomButton bsStyle='primary' className='col-xs-12 col-lg-4 pull-right'
                                                          onClick={this.hidePopUpForever}>Cerrar</CustomButton>
                                        </Modal.Footer>

                                    </Modal>
                                    : null */}


                            {/*this.props.me && this.state.popUpSeen !== popupVersion ?
                                <Modal
                                    show={true}
                                    onHide={this.hidePopUp}
                                    bsSize='lg'
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Encuesta Elite</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <img className={"img-responsive profile-completion-image"}
                                             src={initModalImage} alt="Profile Completion"/>
                                             <br/>
                                             <div className='text-center'>
                                             <a href='https://forms.office.com/Pages/ResponsePage.aspx?id=4d30XLQF106WrysoJDW7zvkQgd_uWdtDqwRtJx17nppUN1NSN1VVODZCWk5HTVpXQlpaVk9IUUxNUS4u'>
                                                 <CustomButton bsStyle={'primary'}>Ir a encuesta</CustomButton>
                                             </a>
                                             </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Modal.Footer>
                                            <CustomButton bsStyle='warning' className='col-xs-12 col-lg-4' onClick={this.hidePopUp} >Ver después</CustomButton><span/>
                                            <CustomButton bsStyle='primary' className='col-xs-12 col-lg-4 pull-right' onClick={this.hidePopUpForever} >No volver a mostrar</CustomButton>
                                        </Modal.Footer>
                                    </Modal.Footer>

                                </Modal>
                                :null*/}


                            </div>
                        </div>
                    </ChatManagerContext.Provider>
                </HeaderFooterContextProvider>
            </SecurityContext.Provider>
        );
    }
}

//const profileCompletionUsers = ["168","120","219","318","217","469","358", "5"];

const popupVersion = 'v3';


/*
const canSeePopUp = ( user )=>{
    return true;
};
*/


Dash.contextTypes = {
    api: PropTypes.object,
    socketApi: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({showUserDetail, hideUserDetail, onSystemChange: changeSystem}, dispatch);
}

function mapStateToProps({notifications, api, userDetail, loadingIds, system}) {
    return {
        notifications,
        me: api.me,
        newUserDetail: api[UserDetailApiProp],
        userDetail,
        loadingIds,
        system
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dash);

const employeeFullname = (employee) => employee.name + " " + employee.patLastName + " " + employee.matLastName;
