import React, {useState, useContext} from 'react';
import {ApiContext} from "../../../api/Api";
import {Modal, ModalHeader, ModalBody, ModalTitle, FormGroup, FormControl, ControlLabel, Button} from "react-bootstrap";
import DropFile from "../utils/DropFile";

const AddMedalModal = ({onHide}) => {
    const [name, setName] = useState('');
    const [points, setPoints] = useState(1);
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const api = useContext(ApiContext);
    const onDrop = (files) => {
        if (files[0]) {
            setImage(files[0]);
        }
    };

    const saveMedal = () => {
        const params = {name, points, description};
        api.recognitions.create({
            params,
            ...(image ? {
                files: {
                    image
                }
            } : {})
        });
        onHide();
    };
    return (
        <Modal show onHide={onHide}>
            <div className='RewardsModal'>
                <ModalHeader closeButton>
                    <ModalTitle>Agregar medalla</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <ControlLabel>Nombre: </ControlLabel>
                        <FormControl type="text" value={name} onChange={e => setName(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Valor en puntos: </ControlLabel>
                        <FormControl min={1} type="number" value={points}
                                     onChange={e => setPoints(parseInt(e.target.value))}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Descripción</ControlLabel>
                        <FormControl componentClass="textarea" value={description}
                                     onChange={e => setDescription(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Imagen:</ControlLabel>
                        <DropFile onDrop={onDrop}
                                  text={image ? image.name : 'Da click aquí o arrastra la imagen'}
                                  textStyle={image ? {color: '#000000'} : {}}
                        />
                    </FormGroup>
                    <Button bsStyle='success' onClick={saveMedal}>
                        Guardar nueva medalla
                    </Button>
                </ModalBody>
            </div>
        </Modal>
    );
};

export default AddMedalModal;
