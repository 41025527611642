import React, { useContext, useState} from 'react';
import './_RecipeModifiers.scss';
import classNames from 'classnames';
import useCallbackCreator from "../../../../../../hooks/useCallbackCreator";
import {TideApiContext} from "../../../../../../api/tideApiConfig";
import _ from 'lodash';
import {emptyRecipeForModifier} from "../../../../../../utils/modelUtils/orozco/orozcoRecipeUtils";
import RecipeEditor from "../../RecipeEditor";

const emptyArr = [];
const RecipeModifiers = ({orozcoProduct, onChange}) => {

    const api = useContext(TideApiContext);
    const [openModifierId, setOpenModifierId] = useState();

    const mods = orozcoProduct?.orozcoProductModifiers||emptyArr;


    const handleModifierClick = useCallbackCreator((id)=>{
        const modifier = _.find(mods, m=>m.id===id)
        if(!modifier)
            return;

        if(!modifier.orozcoRecipe)
            api.orozcoRecipes.create({params:emptyRecipeForModifier(orozcoProduct, modifier) })
                .then(onChange);

        setOpenModifierId(modifier.id);
    },[mods]);

    if(!orozcoProduct?.orozcoProductModifiers?.length)
        return null;

    const openModifier = !!openModifierId && _.find(mods, m=>m.id===openModifierId);

    return (
        <div className={"RecipeModifiers"}>
            <h4 className={'title'}>Modificadores</h4>
            <ul className='modifiers-list'>
                {mods.map((modifier)=>
                    <li className={classNames('modifier', !modifier.orozcoRecipe&&'missing', modifier.id === openModifier?.id&&"active")}
                        onClick={handleModifierClick(modifier.id)}
                        key={modifier.id}>
                        <i className={classNames('fa',modifier.orozcoRecipe?'fa-clipboard':'fa-exclamation-triangle')}/>{' '}
                        <span className='name'>{modifier.name}</span>
                    </li>
                )}
            </ul>

            {!!openModifier?.orozcoRecipe &&
            <RecipeEditor recipeId={openModifier.orozcoRecipe.id} forceType={'modifier'} />}

        </div>
    );
};

export default RecipeModifiers;
