import React from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import HeaderLinks from "../components/Header/HeaderLinks";
import _ from "lodash/fp";
import {useHeaderFooterHelper} from "../utils/header-footer-helper";
import rightArrowIcon from '../assets/img/tgle-rewards/right-arrow.svg';
import leftArrowIcon from '../assets/img/tgle-rewards/left-arrow.svg';

const getSubRoutes = _.flow(
    _.filter('collapse'),
    _.map('views'),
    _.flatten
);

const getRoutes = routes => routes.filter(r => !r.collapse);

const RewardsHeader = ({routes, location, history}) => {
    const {action, actionIcon, headerTitle, backAction, headerClassName} = useHeaderFooterHelper();
    const path = location.pathname;
    const routeDetails = (() => {
        const paths = [
            ...getSubRoutes(routes),
            ...getRoutes(routes)
        ];
        const details = _.find(r => comparePaths(r.path, path), paths);
        if (!details) {
            return {name: ''};
        }
        return details;
    })();
    const brand = routeDetails.name;
    const fullBackAction = () => {
        if (!routeDetails.hidden) {
            return;
        }
        if (backAction === 'back') {
            return history.goBack();
        }
        if (typeof backAction === 'function') {
            return backAction();
        }
    };
    return (
        <Navbar fluid className={headerClassName}>
            <div className="navbar-minimize">
                <button id="minimizeSidebar" className="btn btn-default btn-fill btn-round btn-icon"
                        onClick={handleMinimizeSidebar}>
                    <i className="fa fa-ellipsis-v visible-on-sidebar-regular"/>
                    <i className="fa fa-navicon visible-on-sidebar-mini"/>
                </button>
            </div>
            <Navbar.Header>
                <div onClick={fullBackAction} className='header-button visible-xs visible-sm'>
                    <div className='back-icon'>
                        {routeDetails.hidden &&
                        <img alt='Go back' src={leftArrowIcon}/>
                        }
                    </div>
                </div>
                <Navbar.Brand>
                    {/* Here we create navbar brand, based on route name */}
                    <span>{headerTitle || brand}</span>

                </Navbar.Brand>

                {/*<Navbar.Toggle onClick={mobileSidebarToggle}/>*/}
                <div className='visible-xs visible-sm header-button' onClick={action}>
                    <div className='right-icon'>
                    {
                        actionIcon === 'menu' &&
                        <>
                            <span className='icon-bar'/>
                            <span className='icon-bar'/>
                            <span className='icon-bar'/>
                        </>
                    }
                    {
                        actionIcon === 'next' &&
                        <img alt='Next' src={rightArrowIcon}/>
                    }
                    {
                        actionIcon === 'ok' &&
                        <i className='fa fa-check'/>
                    }
                    </div>
                </div>
                {actionIcon!=='menu'&&
                    <div className='hidden-xs hidden-sm header-button' onClick={action}>
                        <div className='right-icon'>
                        {
                            actionIcon === 'next' &&
                            <img alt='Next' src={rightArrowIcon}/>
                        }
                        {
                            actionIcon === 'ok' &&
                            <i className='fa fa-check'/>
                        }
                        </div>
                    </div>
                }


                <div className='clearfix visible-xs visible-sm'/>

            </Navbar.Header>
            <Nav pullRight>

            </Nav>

            {/* Here we import the links that appear in navbar */}
            {(<Navbar.Collapse><HeaderLinks/></Navbar.Collapse>)}

        </Navbar>
    );
};

export default RewardsHeader;

const comparePaths = (pathTemplate, path) => {

    let paramIndex = pathTemplate.indexOf(":");
    if (paramIndex !== -1) {
        pathTemplate = pathTemplate.substring(0, paramIndex);
        path = path.substring(0, paramIndex);
    }

    return path === pathTemplate;

};

// WOOOOOOOOT
// function that makes the sidebar from normal to mini and vice-versa
const handleMinimizeSidebar = () => {
    document.body.classList.toggle('sidebar-mini');
};
/*
// function for responsive that hides/shows the sidebar
const mobileSidebarToggle = (e) => {
    document.documentElement.classList.toggle('nav-open');
};
 */
