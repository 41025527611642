import React, {useState, useContext, useEffect} from 'react';
import {useSelector} from 'react-redux';
import _ from "lodash/fp";
import {ApiContext} from "../../../api/Api";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import moment from 'moment';
import {useHeaderFooterHelper} from "../../../utils/header-footer-helper";
import Medal from "../utils/Medal";
import avatar from "../../../assets/img/default-avatar.png";

const getPast = _.filter('seen');
const getRecent = _.filter(m => !m.seen);
const customProp = 'forMeRecognitionMovements';
const Activity = () => {
    const {me, forMeRecognitionMovements} = useSelector(
        ({api: {me, forMeRecognitionMovements = []}}) => ({forMeRecognitionMovements, me})
    );
    const [showingOverlay, setShowingOverlay] = useState(null); // Maybe Int
    const api = useContext(ApiContext);
    const {setFooterClassName, setHeaderClassName} = useHeaderFooterHelper();
    const recent = getRecent(forMeRecognitionMovements);
    const past = getPast(forMeRecognitionMovements);
    useEffect(() => {
        if(!me.employee){
            return;
        }
        api.recognitionMovements.get({
            filter: {
                'toEmployee.id': me.employee.id
            },
            customProp
        });
    }, [api, me]);

    const showRecognitionOverlay = recentIndex => () => {
        setShowingOverlay(recentIndex);
        setFooterClassName('blurred');
        setHeaderClassName('blurred');
    };
    const closeOverlay = () => {
        api.recognitionMovements.edit({
            id: recent[showingOverlay].id,
            customProp,
            attrs: {seen: true}
        });
        setShowingOverlay(null);
        setFooterClassName('');
        setHeaderClassName('');
    };
    const toShow = showingOverlay === null ? null : recent[showingOverlay];
    return (
        <>
            <div className={'Rewards Activity ' + (showingOverlay === null ? '' : 'blurred')}>
                {(recent.length === 0 && past.length === 0) &&
                    <p className='info-text'>No tienes actividad reciente.</p>
                }
                {recent.length > 0 &&
                <div className='activity-group recent'>
                    <p className='activity-group-name'>Recientes ({recent.length})</p>
                    {
                        recent.map((r, k) => <ActivityEntry showRecognitionOverlay={showRecognitionOverlay(k)}
                                                            key={r.id} {...r}/>)
                    }
                </div>
                }
                {past.length > 0 &&
                <div className='activity-group'>
                    <p>Antiguas ({past.length})</p>
                    {
                        past.map(r => <ActivityEntry key={r.id}
                                                     {...r}/>)
                    }
                </div>
                }

            </div>

            {showingOverlay !== null &&
            <RecognitionOverlay closeOverlay={closeOverlay} recognition={toShow.recognition}/>
            }
        </>
    );
};

const RecognitionOverlay = ({recognition, closeOverlay}) => {
    return (
        <div className='Rewards Activity recognition-overlay'>
            <div className='close-button'>
                <i className='fa fa-close' onClick={closeOverlay}/>
            </div>
            <div className='overlay-content'>
                <Medal {...recognition}/>
                <div className='message'>
                    <p>
                        <span className='bold'>¡Felicidades!</span>
                        &nbsp;Acabas de obtener&nbsp;
                        <span className='red-info bold'>+{recognition.points} puntos</span>
                        &nbsp;por la medalla de&nbsp;
                        <span className='underline'>
                        {recognition.name.toLowerCase()}.
                    </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

const ActivityEntry = ({fromEmployee, recognition, showRecognitionOverlay}) => {
    const api = useContext(ApiContext);
    const fromImage = fromEmployee.photo?fromEmployee.photo.id:avatar;
    return (
        <div className='activity-row' onClick={showRecognitionOverlay}>
            <div className='activity-picture'>
                <div className='picture' style={{backgroundImage:'url('+api.appFiles.getUrl(fromImage)+')'}}/>
            </div>
            <div className='activity-label'>
                <p>
                    <span className='bold'>
                        {employeeFullName(fromEmployee)}
                    </span>
                        &nbsp; te otorgó la medalla de &nbsp;
                    <span className='link'>
                        {recognition.name} +{recognition.points}pts.
                    </span>
                    <span className='info'>
                        {moment(recognition.createdAt).format('DD.MM.YY, hh:mma')}
                    </span>
                </p>

            </div>
        </div>
    );
};

export default Activity;
