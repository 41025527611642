import React, {Component} from 'react';
import StatsCard from '../../../../components/Card/StatsCard';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {connect} from "react-redux";
import LoaderIcon from '../../../../components/Helper/LoaderIcon';

class MComplianceIndex extends Component {

    getInfoString(){

        if( this.props.appliedIncidenceReport && this.props.employeesMeta ){

            const incCount = this.props.appliedIncidenceReport.result.reduce((acc, cur)=>{

                switch (cur.attr){
                    case "Falta":
                        return acc + (cur.count * 3);
                    case "Retardo":
                        return acc + (cur.count * 2);
                    case "Incapacidad":
                        return acc + Number(cur.count);
                    case "Permiso para llegar tarde":
                        return acc + Number(cur.count);
                    default:
                        return acc;
                }
            }, 0);

            return Number(incCount/this.props.employeesMeta.totalItems).toFixed(4);

        }
        else{
            return <LoaderIcon/>
        }
    }

    render() {

        return (
            <StatsCard
                bigIcon={<i className={"fa fa-crosshairs text-warning"}/>}
                statsText="Índice de cumplimiento"
                statsValue={this.getInfoString()}
                statsIcon={<i className="fa fa-info" />}
                statsIconText={
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id={1}>
                            Se suman todas las incidencias de los últimos 30 días y se dividen entre el número de empleados.<br/>
                            Las incidencias tienen el siguiente valor:<br/>
                            Falta o retardo justificado <b>+1</b><br/>
                            Retardo <b>+2</b><br/>
                            Falta <b>+3</b><br/>

                        </Tooltip>}
                    >
                        <span>¿Qué es esto?</span>
                    </OverlayTrigger>
                }
            />
        );
    }
}

function mapStateToProps( {api:{appliedIncidenceReport, employeesMeta}} ){

    return {appliedIncidenceReport, employeesMeta}
}

export default connect(mapStateToProps)(MComplianceIndex);
