import React, {useCallback, useContext} from 'react';
import ClassicModal from "../../../components/Modals/ClassicModal";
import {getLoungeAccessMethodNameIncludingParents} from "../../../services/modelUtils/loungeAccessMethodsUtils";
import moment from "moment";
import useInput from "../../../hooks/useInput";
import {TideApiContext} from "../../../api/tideApiConfig";
import {NotifierContext} from "../../../utils/Notifier";
import {serverDateTimeFormat} from "../../../utils/timeUtils";
import {useSelector} from "react-redux";

const CancelCheckInModal = ({ visit, checkIn, onHide, onCancelSuccess }) => {

    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);
    const cancelCommentInput = useInput();

    const loadingId = '@CancelCheckInModal';
    const cancelComment = cancelCommentInput.value;

    //Make call to server
    const cancelCheckIn = useCallback(()=>{

        if(!cancelComment.length)
            return notifier.error("Debes agregar un comentario");

        // Since there's no PUT in check_in endpoint, we edit the check in through the visits endpoint,
        // for this we have to send the ids of all the check ins in the visit.
        const checkIns = visit.checkIns.map( oldCheckIn=>{
            if(oldCheckIn.id === checkIn.id){
                return {
                    id: checkIn.id,
                    cancellationReason: cancelComment,
                    canceledDate: moment().format(serverDateTimeFormat)
                };
            }
            return oldCheckIn.id
        });

        api.visits.update({ id: visit.id, params: {checkIns}, loadingId }).then(onCancelSuccess);
    },[api, notifier, onCancelSuccess, cancelComment, checkIn, visit]);

    const loading = useSelector( s=>!!s.loadingIds[loadingId] );

    return (
        <ClassicModal
            title={'Cancelar check in'}
            className={"CancelCheckInModal"}
            onHide={onHide}
            confirmStyle={'danger'}
            confirmText={'Cancelar check in'}
            cancelText={'No hacer nada'}
            onSave={cancelCheckIn}
            disabled={loading}
        >
            Método de acceso: {getLoungeAccessMethodNameIncludingParents(checkIn?.loungeAccessMethod)}<br/>
            Fecha de creación: {moment(checkIn.createdDate).format("DD/MM/YYYY HH:mm")}
            <hr/>
            Escribe un comentario indicando la razón de la cancelación:
            <textarea className='form-control' {...cancelCommentInput.bind} />
        </ClassicModal>
    );
};

export default CancelCheckInModal;
