import SecurityManager from "./SecurityManager";
import Systems from "../constants/Systems";

export default function( user ){
    const security = user && new SecurityManager(user);
    const role = user && user.role? user.role.name:"";

    let systems = [];

    switch (role) {
        case 'SUPER_ADMIN':
            systems = ['sysadmin', 'alma'];
            break;
        case 'NOMINAS':
            systems = ['alma'];
            break;
        case 'RECURSOS_HUMANOS':
            systems =  ['alma','ee'];
            break;
        case 'DIRECTOR':
            systems =  ['alma', 'ee', 'almazen', 'compras'];
            break;
        case 'GERENTE':
            systems =  ['alma', 'ee'];
            break;
        case 'RECLUTADOR':
            systems =  ['alma'];
            break;
        case 'ADMINISTRADOR_EE':
            systems =  ['alma', 'ee'];
            break;
        case 'GERENTE_ADMINISTRADOR_EE':
            systems =  ['alma', 'ee'];
            break;
        case 'NOMINAS_ADMINISTRADOR_EE':
            systems =  ['alma', 'ee'];
            break;
        default:
            systems = ['alma', 'ee'];
    }

    if(security && security.ee.canSeeSystem())
        systems = [...systems, 'ee'];

    if(security && security.almazen.canSeeSystem())
        systems = [...systems, 'almazen'];

    if(security && security.jazz.canSeeSystem())
        systems = [...systems, 'compras'];

    if (security && security.rewards.canSeeSystem())
        systems = [...systems, 'rewards'];

    if(security && security.capitalHumano.canSeeSystem())
        systems = [...systems, 'capitalHumano'];

    if(security && security.katharsis.canSeeSystem())
        systems = [...systems, Systems.KATHARSIS];

    return systems;
}
