import React, {Component} from 'react';
import {connect} from 'react-redux';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Button from '../../../../elements/CustomButton/CustomButton';
import {UserDetailApiProp, UserDetailLoadingId} from "./UserDetail";
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";

class EmployeeHistory extends Component {

    static contextTypes = {api: PropTypes.object};

    constructor(props, context) {
        super(props);

        this.state = {
            page: 0,
            pageSize: 10,
            journal: [],
            pageDate: moment()
        };

        this.changeDate = this.changeDate.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.showUserDetail = this.showUserDetail.bind(this);

        this.journalApi = context.api.journal.get;
        this.loadingId = "@Class.EmployeeHistory." + Math.random();

        if (props.match.params.id) {
            this.employeeId = props.match.params.id;
            this.isMe = false;
            context.api.employees.getSingle(this.employeeId);
        } else {
            this.employeeId = props.me.employee.id;
            this.isMe = true;
        }

    }

    UNSAFE_componentWillMount() {
        this.loadJournal();
    }

    loadJournal() {

        const date = moment(this.state.pageDate);

        if (this.props.me)
            this.journalApi(
                this.employeeId,
                0,
                500,
                this.loadingId,
                {
                    "shiftDate[before]": date.subtract(this.state.page * this.state.pageSize, "days").format("YYYY-MM-DD"),
                    "shiftDate[after]": moment(date).subtract(this.state.pageSize - 1, "days").format("YYYY-MM-DD")
                }
            );

    }

    //Create here the array for the table
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.journal !== this.props.journal) {
            let newJournal = [];
            let lastDateS = "";
            let lastDateO = {};

            for (let index in nextProps.journal) {
                const reg = nextProps.journal[index];
                if (reg.shiftDate !== lastDateS) {
                    lastDateS = reg.shiftDate;
                    lastDateO = {date: lastDateS, registers: [reg]};
                    newJournal.push(lastDateO);
                } else {
                    lastDateO.registers.push(reg);
                }
            }


            this.setState({journal: newJournal.reverse()});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.me || this.state.pageSize !== prevState.pageSize || !this.state.pageDate.isSame(prevState.pageDate))
            this.loadJournal();
    }

    changeDate(e, dateRangePicker) {

        if (!this.state.pageDate.isSame(dateRangePicker.startDate))
            this.setState({pageDate: dateRangePicker.startDate});
    }

    moveButtonPress(dir) {
        let date = moment(this.state.pageDate);
        date.add(this.state.pageSize * dir, "days");
        this.setState({pageDate: date});
    }

    changePageSize(e) {
        this.setState({pageSize: e.target.value})
    }

    showUserDetail() {
        this.context.api.users.get(0, 1, UserDetailLoadingId, {employee: this.props.singleEmployee.id}, UserDetailApiProp);
    }

    render() {

        return (
            <div className="main-container employee-history">
                {!this.isMe && this.props.singleEmployee ?
                    <h4>Historial de <span className="span-but"
                                           onClick={this.showUserDetail}>{employeeFullName(this.props.singleEmployee)}</span>
                    </h4> : null
                }
                <DateRangePicker
                    startDate={this.state.pageDate}
                    singleDatePicker
                    onApply={this.changeDate}>

                    <Button className="select-date-button">
                        <i className="fa fa-calendar"/>
                        {this.state.pageDate.format("dddd, D MMMM YYYY")}
                        <i className="fa fa-caret-down"/>
                    </Button>

                </DateRangePicker>

                <select
                    className="form-control input-sm days-select"
                    onChange={this.changePageSize}
                    value={this.state.pageSize}
                >
                    <option value={5}>Ver 5 días</option>
                    <option value={10}>Ver 10 días</option>
                    <option value={15}>Ver 15 días</option>
                    <option value={20}>Ver 20 días</option>
                    <option value={30}>Ver 30 días</option>
                </select>
                <ReactTable
                    data={this.state.journal}
                    columns={this.tableStructure}
                    loading={!!this.props.loadingIds[this.loadingId]}

                    manual
                    minRows={3}
                    showPagination={false}
                    noDataText='No se encontraron registros'
                />
                <div>
                    <Button
                        className="prev-page-but"
                        onClick={() => this.moveButtonPress(-1)}
                        disabled={!!this.props.loadingIds[this.loadingId]}
                    >
                        Página anterior</Button>
                    <Button
                        className="next-page-but"
                        onClick={() => this.moveButtonPress(1)}
                        disabled={!!this.props.loadingIds[this.loadingId]}
                    >
                        Página siguiente</Button>
                </div>
            </div>
        );
    }

    tableStructure = [{
        Header: 'Fecha',
        accessor: (shift) => <span className="cell-left">{moment(shift.date).format("dddd, D MMMM YYYY")}</span>,
        id: "date",
        sortable: false,
        minWidth: 175
    }, {
        Header: 'Registros',
        Cell: (props) => {
            return registerCell(props, this.props.me)
        },
        sortable: false,
        minWidth: 295
    }
    ];
}

function mapStateToProps({api: {journal, me, singleEmployee}, loadingIds}) {
    if (!journal) journal = [];
    return {journal, me, loadingIds, singleEmployee}
}

export default connect(mapStateToProps)(EmployeeHistory);

function typeToIcon(type) {
    switch (type) {
        case 'check_in':
            return <i className="green fa fa-caret-up"/>;
        case 'meal_check_in':
            return <i className="green fa fa-caret-up"/>;
        case 'check_out':
            return <i className="red fa fa-caret-down"/>;
        case 'meal_check_out':
            return <i className="red fa fa-caret-down"/>;
        case 'F':
            return <i className="yellow fa fa-exclamation-triangle"/>;
        case 'R':
            return <i className="red fa fa-clock-o"/>;
        case 'NCHO':
            return <i className="orange fa fa-sign-out"/>;
        case 'D':
            return <i className="fa fa-bed brown"/>;
        case 'I':
            return <i className="fa fa-wheelchair"/>;
        case 'RP':
            return <i className="fa fa-handshake-o"/>;
        case 'C':
            return <i className="fa fa-graduation-cap"/>;
        case 'CO':
            return <i className="fa fa-graduation-cap"/>;
        case 'Cer':
            return <i className="fa fa-graduation-cap"/>;
        case 'PP':
            return <i className="fa fa-clock-o green"/>;
        case 'V':
            return <i className="fa fa-paper-plane-o"/>;
        case 'PDT':
            return <i className="fa fa-suitcase"/>;
        case 'CD':
            return <i className="fa fa-bed green"/>;
        case 'CUMP':
            return <i className="fa fa-birthday-cake pink"/>;
        case 'CA':
            return <i className="fa fa-graduation-cap"/>;
        case 'OF':
            return <i className="fa fa-user-circle"/>;
        case 'OR':
            return <i className="fa fa-clock-o green"/>;
        case 'RCHNF':
            return <i className="fa fa-bell-slash"/>;
        case 'DF':
            return <i className="fa fa-flag"/>;
        case 'RIN':
            return <i className="fa fa-square-o"/>;
        case 'CHOT':
            return <i className="fa fa-hourglass-half"/>;
        case 'PRIN':
            return <i className="fa fa-square-o green"/>;
        case 'PCHOT':
            return <i className="fa fa-hourglass-half green"/>;
        case 'DA':
            return <i className="fa fa-bed sky-blue"/>;
        case 'invalid':
            return <i className="fa fa-times-circle red"/>;
        case 'E':
            return <i className="fa fa-wheelchair-alt sky-blue"/>;
        case 'MECO':
            return <i className="fa fa-cutlery red"/>;
        case 'MACO':
            return <i className="fa fa-cutlery red"/>;
        case 'PMECO':
            return <i className="fa fa-cutlery green"/>;
        case 'PMACO':
            return <i className="fa fa-cutlery green"/>;
        case 'min':
            return <i className="fa fa-grav sky-blue"/>;
        case 'MHT':
            return <i className="fa fa-hourglass-half red"/>;
        case 'PMHT':
            return <i className="fa fa-hourglass-half green"/>;
        case 'FDF':
            return <i className="fa fa-flag green"/>;
        case 'RB':
            return <i className="red fa fa-clock-o"/>;
        case 'EIMSS':
            return <i className="fa fa-wheelchair-alt sky-blue"/>;
        case 'EDOC':
            return <i className="fa fa-wheelchair-alt sky-blue"/>;
        case 'EINC':
            return <i className="fa fa-wheelchair-alt sky-blue"/>;
        case 'FCS':
            return <i className="blue fa fa-exclamation-triangle"/>;
        case 'COVID':
            return <i className="blue fa fa-medkit"/>;
        case 'FSS':
            return <i className="gray fa fa-exclamation-triangle"/>;
        case 'UNREG':
            return <i className="red fa fa-square-o"/>;
        case 'PUN':
            return <i className="green fa fa-square-o"/>;
        case 'MHT5':
            return <i className="fa fa-hourglass-half red"/>;
        case 'PMHT5':
            return <i className="fa fa-hourglass-half green"/>;
        case 'AUTHORIZED_NO_CHECK_DAY':
            return <i className="fa fa-square-o green"/>;
        default:
            return <i className="fa fa-calendar-times-o"/>;

    }
}

function typeToText(type) {
    switch (type) {
        case 'check_in':
            return "Entrada";
        case 'meal_check_in':
            return "Entrada de comer";
        case 'check_out':
            return "Salida";
        case 'meal_check_out':
            return "Salida a comer";
        case 'F':
            return "Falta";
        case 'R':
            return "Retardo";
        case 'NCHO':
            return "No marcó salida";
        case 'D':
            return "Descanso";
        case 'I':
            return "Incapacidad";
        case 'RP':
            return "Permiso para llegar tarde";
        case 'C':
            return "Curso";
        case 'CO':
            return "Curso";
        case 'PP':
            return "Uso de premio de puntualidad";
        case 'V':
            return "Día de vacaciones";
        case 'TD':
            return "Trabajo en día de descanso";
        case 'PDT':
            return "Primer día de trabajo";
        case 'CD':
            return "Cambio de día de descanso";
        case 'CUMP':
            return "Día de cumpleaños";
        case 'CA':
            return "Capacitación";
        case 'OF':
            return "Falta justificada";
        case 'OR':
            return "Retardo justificado";
        case 'RCHNF':
            return "Reloj checador no funciona";
        case 'DF':
            return "Día festivo";
        case 'RIN':
            return "Registros incompletos";
        case 'CHOT':
            return "Salida temprano";
        case 'PRIN':
            return "Permisos para tener registros incompletos";
        case 'PCHOT':
            return "Permiso para salir temprano";
        case 'DA':
            return "Descanso adicional";
        case 'E':
            return "Enfermedad";
        case 'MECO':
            return 'Tiempo de comida incompleto';
        case 'MACO':
            return 'Tiempo de comida excedido';
        case 'PMECO':
            return 'Permiso de tiempo de comida incompleto';
        case 'PMACO':
            return 'Permiso de tiempo de comida excedido';
        case 'min':
            return '';//This one is rendered in another function
        case 'MHT':
            return 'Menos horas trabajadas';
        case 'PMHT':
            return 'Permiso para trabajar menos horas';
        case 'FDF':
            return 'Falta por día festivo';
        case 'RB':
            return 'No llegó a briefing';
        case 'EIMSS':
            return "Enfermedad justificada por IMSS";
        case 'EDOC':
            return 'Enfermedad justificada por doctor particular';
        case 'EINC':
            return 'Enfermedad sin justificación completa';
        case 'FCS':
            return 'Permiso con goce de sueldo';
        case 'COVID':
            return 'Home office por COVID-19';
        case 'FSS':
            return 'Permiso sin goce de sueldo';
        case 'UNREG':
            return 'Un solo registro';
        case 'PUN':
            return 'Permiso para tener un solo registro';
        case 'MHT5':
            return 'Menos de cinco horas trabajadas';
        case 'PMHT5':
            return 'Permiso para tener menos de cinco horas trabajadas';
        case 'AUTHORIZED_NO_CHECK_DAY':
            return 'Autorización de no huelleo';

        case 'invalid':
            return (
                <span>
                    Registro inválido
                    <OverlayTrigger placement="top"
                                    overlay={<Tooltip id="tooltip">Los registros con menos de 5 minutos de diferencia
                                        entre ellos no serán tomados en cuenta.</Tooltip>}>
                        <i className="fa fa-question-circle sky-blue"/>
                    </OverlayTrigger>
                </span>
            );
        default:
            return type;
    }
}

function formatMinutes(minutes, max, me) {

    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    if (me.role && me.role.name === 'NOMINAS') {
        return `Tiempo trabajado ${hours < 10 ? `0${hours}` : hours}:${mins < 10 ? `0${mins}` : mins}`;//Display hours and minutes like 03:03, adding a leading zero if less than 10
    }

    if (hours >= max)
        return 'Tiempo cumplido';


    return `Tiempo trabajado ${hours < 10 ? `0${hours}` : hours}:${mins < 10 ? `0${mins}` : mins}`;//Display hours and minutes like 03:03, adding a leading zero if less than 10
}

function showWorkedMinutes(me) {

    if(me.role && me.role.name === 'NOMINAS')
        return true;

    if(me.employee && me.employee.shift && me.employee.shift.isUnscheduled)
        return true;

    return false;
}

function registerCell(props, me) {

    return (
        <ul className="registerList">
            {props.original.registers.map((reg) => {

                if (reg.type === 'min' && !showWorkedMinutes(me, reg))
                    return null;

                return <li key={Math.random()}>
                    {typeToIcon(reg.code)}{/* Render the register icon */}

                    {reg.type === "reg" ? moment(reg.date).format("HH:mm") : null}{/* If type is "reg" (from clock) render the time */}

                    - {typeToText(reg.code)} {/* Render descrition of register */}

                    {reg.clock ? ` - ${reg.clock}` : null} {/* If a clock was sent, render the name */}

                    {reg.type === "min" ? formatMinutes(reg.minutes, 8, me) : null} {/* If type is "min", it's an employee with X hours schedule and we display the amount of time he worked*/}
                </li>

            })}
        </ul>
    );
}
