import React from 'react';
import Switch from "react-bootstrap-switch";
import { Row, Col, FormControl} from 'react-bootstrap';

/**
 * Ranges are defined as an array of objects like: [ { min, max, value }, ... ]
 * When calculating the question score, the first range to match in the array will be the score
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const RangesValues = ( props )=>{

    if( props.question.type !== 'percentage' && props.question.type !== 'number' )
        return null;

    const onSwitchChange = ( comp, value )=>{

        const config = { ...props.question.config };
        config.rangedValues = value;

        if( value ) {
            config.rangeValues = [ {min:0, max:0, value: 0} ];
        }
        else{
            delete config.rangeValues;
        }

        props.onChange( config );
    };

    const onValueChange = ( {target} )=>{

        const { index, valtype } = target.dataset;
        const rangeValues = [ ...props.question.config.rangeValues ];


        rangeValues[ index ] = { ...rangeValues[ index ], [valtype]:target.value };

        props.onChange( {...props.question.config, rangeValues } );
    };

    const onEndEditing = ( {target} )=>{

        const { index, valtype } = target.dataset;
        const rangeValues = [ ...props.question.config.rangeValues ];

        if( target.value === '' || isNaN( Number(target.value) )  ) {
            rangeValues[ index ] = { ...rangeValues[ index ], [valtype]:0 };
        }
        else{
            rangeValues[ index ] = { ...rangeValues[ index ], [valtype]:Number(target.value) };
        }

        props.onChange( {...props.question.config, rangeValues } );
    };

    const onRangeAdd = ()=>{

        const rangeValues = [ ...props.question.config.rangeValues, {min:0, max:0, value: 0} ];
        props.onChange( {...props.question.config, rangeValues } );
    };

    const rangeValues= props.question.config? props.question.config.rangeValues : false;

    return (
        <div className='RangesValues'>
            <Switch
                value={ !!props.question.config.rangedValues }
                onChange={ onSwitchChange }
            />&nbsp;
            Agregar valores a rangos de respuestas
            { props.question.config.rangedValues && rangeValues?
                <div className='configuration-box'>
                    <ul>
                        {rangeValues.map( (range, i)=>
                            <li key={i} >
                                <Row>
                                    <Col xs={12} className='range-title'>
                                        Rango {i+1}<br/>
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <span>Mínimo</span><br/>
                                        <FormControl
                                            value={ rangeValues[i].min }
                                            data-index={i}
                                            data-valtype='min'
                                            onChange={onValueChange}
                                            onBlur={ onEndEditing }
                                            placeholder='Mínimo'
                                        />
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <span>Máximo </span><br/>
                                        <FormControl
                                            value={ rangeValues[i].max }
                                            data-index={i}
                                            data-valtype='max'
                                            onChange={onValueChange}
                                            onBlur={ onEndEditing }
                                            placeholder='Máximo'
                                        />
                                    </Col>
                                    <Col md={4} xs={12}>
                                        <span>Valor </span><br/>
                                        <FormControl
                                            value={ rangeValues[i].value }
                                            data-index={i}
                                            data-valtype='value'
                                            onChange={onValueChange}
                                            onBlur={ onEndEditing }
                                            placeholder='Valor'
                                        />
                                    </Col>
                                </Row>
                                <hr/>
                            </li>
                        )}
                    </ul>
                    <span className='link' onClick={onRangeAdd}>Agregar rango</span>
                    <p className='text-muted small'>Los valores de las respuestas se usarán para promediar una calificación de esta pregunta.</p>
                </div>
                :null}

        </div>
    );
};


export default RangesValues;
