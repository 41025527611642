import React,{useContext} from 'react';
import {Modal} from 'react-bootstrap';
import Button from "../../../elements/CustomButton/CustomButton";
import ProductDetail from './ProductDetail';
import {SecurityContext} from "../../../utils/SecurityManager";


const ProductDetailModal = ({product, onHide, onEdit, onDelete, onActivateToggle, editable, onChange}) => {

    const security=useContext(SecurityContext);

    return (
        <Modal
            show={true}
            onHide={onHide}
            bsSize='lg'
        >
            <Modal.Header closeButton>
                <Modal.Title>Detalles del producto</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <ProductDetail
                    product={product}
                    showConversionRules
                    editable={editable}
                    onChange={onChange}
                />

            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle='danger'  className='pull-left' onClick={onHide}>Cerrar</Button>
                {security.almazen.canEditProducts() &&
                <>
                    <Button bsStyle='success' className='pull-right' onClick={() => {onEdit(product)}}>Editar</Button>
                    <Button bsStyle={product.isActive?'danger':'success'} className='pull-right' onClick={() => {onActivateToggle(product)}}>{product.isActive?'Desactivar':'Activar'}</Button>
                    <Button bsStyle='danger' className='pull-right' onClick={() => {onDelete(product)}}>Eliminar</Button>
                </>
                }
            </Modal.Footer>
        </Modal>
    )
};

export default ProductDetailModal;
