import React, {useMemo} from 'react';
import ReactTable from "react-table";
import useTideTable from "../../../hooks/table/useTideTable";
import {purchaseProductListTable, purchaseProductListSGroups} from "../../../utils/modelUtils/jazz/purchaseProductUtils";

const PurchaseProductList = ({product})=>{

    const requestFilters=useMemo(()=>({
        'productBrand.product.id': product.id,
        sGroups: purchaseProductListSGroups,
        'purchaseOrder[exists]':true,
        'order[createdDate]':'DESC'
    }),[product]);

    const table=useTideTable({
        entity:'purchaseProducts',
        columns: purchaseProductListTable,
        requestFilters
    });

    return (
        <div className='PurchaseProductList'>
            <ReactTable
                {...table.tableProps}
                filterable={false}
                sortable={false}
            />
        </div>
    );
};

export default PurchaseProductList;
