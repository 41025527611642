import React, {useCallback, useState} from 'react';
import './_TideFormModal.scss';
import classNames from 'classnames';
import Field from "./Field";
import {validateForm} from "./utils/formModalUtils";
import ClassicModal from "../Modals/ClassicModal";

/**
 *
 * @param children React objects to be rendered under the form
 * @param className A class to add to the container Modal. It will always have the classes TideFormModal and Modal as well
 * @param fields Array of objects. See Field.js for the object schema
 * @param errors Array of strings describing the errors in the form
 * @param initialState Object used to initialized the form state
 * @param onClearErrors function Called when the errors displayed are hidden, the errors parameter should be cleaned in this function
 * @param onClose Function called to close the modal with the click event as parameter if it exists.
 * @param onSubmit Function called with the form state as parameter. Only called if all validations are passed
 * @param submitButtonText Text on the bottom submit button. Can a string or a component. Defaults to "Guardar"
 * @param title The Modal title
 * @param modalProps Props to pass down to Modal.jsx
 * @returns {*}
 * @constructor
 */
const TideFormModal = ({
                       children,
                       className,
                       fields=[],
                       errors=[],
                       header,
                       initialState,
                       onClearErrors,
                       onClose,
                       onSubmit,
                       submitButtonText='Guardar',
                       title,
                       ...modalProps

}) => {

    const [formState, setFormState] = useState(initialState||{});
    const [formErrors, setFormErrors] = useState(null);

    const handleChange = useCallback((value, field)=>{
        setFormState( form=>({...form, [field]:value}) );
    },[]);


    const handleSubmit = useCallback((e)=>{
        if(e) e.preventDefault();

        setFormErrors(null);
        onClearErrors && onClearErrors(null);

        const validation = validateForm( formState, fields );
        if(!validation.success)
            return setFormErrors(validation.errors);

        onSubmit(formState);
    },[formState, onSubmit, fields, onClearErrors]);

    const allErrors = [...(errors||[]), ...(formErrors||[])];

    return (
        <ClassicModal
            className={classNames("TideFormModal", className)}
            title={title}
            confirmText={submitButtonText}
            onHide={onClose}
            onSave={handleSubmit}
            {...modalProps}
        >
            {header}
            <form className='fields' onSubmit={handleSubmit}>
                {fields.map(field=>
                    <Field
                        key={field.name}
                        field={field}
                        onChange={handleChange}
                        value={formState[field.name]}
                        form={formState}
                    /> )}
            </form>
            {!!allErrors?.length &&
            <div className='errors-block'>
                {allErrors.map( (e, i)=>
                    <p className='error' key={e?.field?.name||i}>{e?.message || e}</p>)}
            </div>}

            {children}

        </ClassicModal>
    );
};

export default TideFormModal;
