import _ from 'lodash';
import { convertToSmallestUnit } from './unitsUtility';


export const stockMovementsArrayToBatchMovement = ( stockMovements, warehouseId, inOrOut, status='completed' )=>{

    const preparedMovements = _.map( stockMovements, ( sm )=>{

        let quantity = sm.quantity;
        if( sm.selectedUnit )
            quantity = convertToSmallestUnit( quantity, sm.selectedUnit, sm.productBrand.product );

        if( inOrOut === 'in' )
            quantity = Math.abs( quantity );
        else if( inOrOut === 'out' )
            quantity = - Math.abs( quantity );
        else
            throw ( new Error('On method \'stockMovementsArrayToBatchMovement\' param \'inOrOut\' must be either "in" or "out"') );

        return { productBrand: sm.productBrand.id, status, quantity: quantity+'' };
    });

    return {
        movements: preparedMovements,
        warehouse: warehouseId,
        inOrOut,
        status
    }

};
