import React, {useContext, useEffect, useState, useCallback} from 'react';
import {Modal, Table, Button} from "react-bootstrap";
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import EntitySelect from "../../../../components/EntitySelect/EntitySelect";
import {preparePositionForServer, operationConstants, positionPermissionsGroups} from "../../../../utils/modelUtils/alma/positionPermissionGroupsUtils";

const filters = {pagination: false, sGroups: positionPermissionsGroups};
const permissionGroupsLoadingId = "PositionPermissionGroups.get";
const positionLoadingId = "PositionPermissionGroups.put";

function PositionPermissionGorups() {
    const api = useContext(ApiContext);
    const positions = useSelector(({api}) => api['positionPermissionGroups']) || [];
    const permissionGroupsLoading = useSelector(({loadingIds}) => loadingIds[permissionGroupsLoadingId]);
    const positionsLoading = useSelector(({loadingIds}) => loadingIds[positionLoadingId]);
    const [editingPosition, setEditingPosition] = useState(null);
    const [group, setGroup] = useState(null);

    useEffect(() => {
        api.positions.get(filters, permissionGroupsLoadingId, 'positionPermissionGroups');
    }, [api]);

    const groupLabel = (entity) => {
        return entity.name + (entity.systemName ? ' - ' + entity.systemName.toUpperCase() : '');
    };

    const addGroup = useCallback(() => {
        const preparedData = preparePositionForServer(editingPosition, group, operationConstants.ADD_GROUP);
        api.positions.edit(editingPosition.id, preparedData, positionLoadingId, 'positionPermissionGroups').then((position) => {
            setEditingPosition(position)
        });
    }, [editingPosition, group, api]);

    const removeGroup = (position, group) => {
        const preparedData = preparePositionForServer(position, group, operationConstants.REMOVE_GROUP);
        api.positions.edit(position.id, preparedData, positionLoadingId, 'positionPermissionGroups');
    };

    return (
        <div className='UserPermissionGroups container-fluid'>
            {permissionGroupsLoading ? <h2>Cargando...</h2> : null}

            {positions.length > 0 &&
            <>
                <h2>Grupos de permisos de puestos</h2>

                <Table striped className={'vertical-responsive-table '}>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Grupos</th>
                    </tr>
                    </thead>
                    <tbody>
                    {positions.map((position, i) =>
                        <tr key={position.id} onClick={() => {
                            setEditingPosition(position)
                        }}>
                            <td data-label='id'>{position.id}</td>
                            <td data-label='Nombre'>{position.name}</td>
                            <td data-label='Grupos'>
                                {position.permissionGroups.map((permissionGroup) => (
                                    <span key={permissionGroup.id}
                                          title={permissionGroup.description}
                                          className={"label label-info"}>{permissionGroup.name}
                                        <i
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                removeGroup(position, permissionGroup)
                                            }}
                                            className={"delete fa fa-times"}
                                        />
                                    </span>
                                ))}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </>}

            {editingPosition?
                <Modal show={true} onHide={() => setEditingPosition(null)} bsSize='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>Agregar grupo de permisos a {editingPosition.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EntitySelect
                            entity={'permissionGroups'}
                            getMethod={'get'}
                            value={group}
                            onChange={setGroup}
                            labelCreator={groupLabel}
                            filterBy='search'
                            maxResults={1000}
                            placeholder={'Selecciona un grupo ...'}
                        />
                        <br/>

                        <p>{group?group.description:null}</p>
                        <Button bsStyle="success" disabled={positionsLoading} onClick={addGroup}>Agregar</Button>
                    </Modal.Body>
                </Modal> : null
            }
        </div>
    )
}


export default PositionPermissionGorups;
