import React, {useState, useRef, useMemo, useContext, useCallback} from 'react';
import {ApiContext} from "../../api/Api";
import _ from "lodash";
import {useSelector} from "react-redux";
import {defaultProps, tableStateToApiOptions} from "../../utils/reactTableUtils";
import CustomCheckbox from "../../elements/CustomCheckbox/CustomCheckbox";
import useUpdateEffect from "../useUpdateEffect";
import {TideApiContext} from "../../api/tideApiConfig";
import useCallbackCreator from '../useCallbackCreator';

/**
 *
 * @param entity string The name of the entity to fetch, in plural as defined in the Api
 * @param columns [] Array to send to ReactTable as columns property
 * @param debounced boolean Whether the load function should be debounced, handy when loading on each keystroke of a filter
 * @param requestFilters object Extra filters to send to the api in the request
 * @param requestOptions object Merged to the api call param
 * @param createApiOptions function Receives the params (tableState, addOptions, config, filters) and should return the object
 *                           to send to the api call see tableStateToApiOptions for the default implementation
 * @param getMethod string Method to call on the api endpoint
 * @param filterData function A function to filter the data received from the server before processing it
 * @param filterMappings object The default tableStateToApiOptions will take this in the config object and convert each
 *                           filter named as each key of the filterMappings object and convert it to its value
 * @param selectable boolean If true, a column will be prepended to the table with a checkbox, the selected row will be
 *                           returned in the "selected" key of the returned object
 * @param defaultPageSize
 * @param useTideApi Whether to use the new or the old api.
 * @param pause Stop api fetching while true
 * @returns {{
 *     reload: function,
 *     itemsFoundString: string,
 *     setSelected: function,
 *     selected: Array,
 *     lastUsedApiOptions: Object,
 *     tableProps:{
 *         filterable:boolean,
 *         minRows:Number,
 *         defaultPageSize:Number,
 *         data: Array,
 *         onFetchData: function,
 *         columns: Array,
 *         className: string,
 *         loading: boolean,
 *         manual: boolean,
 *         pages: Number,
 *         defaultFilterMethod: function,
 *         loadingText: string,
 *         nextText: string,
 *         noDataText: string,
 *         ofText: string,
 *         pageText: string,
 *         previousText:string,
 *         rowsText:string,
 *         },
 *     }}
 */
export default ({
                    entity,
                    columns,
                    debounced = true,
                    requestFilters,
                    requestOptions,
                    createApiOptions = tableStateToApiOptions,
                    getMethod = 'get',
                    filterData,
                    filterMappings,
                    selectable = false,
                    defaultPageSize = 10,
                    newTideApi=false,
                    pause= false
                }) => {

    if (!entity)
        throw new Error('The "entity" parameter is mandatory for the "useTideTable" hook.');
    if ((selectable && !columns))
        throw new Error('The "columns" parameter is mandatory for the "useTideTable" hook when the selectable parameter is activated.');

    const tableStateRef = useRef(null);
    //Generate a unique loading id for this component
    let loadingId = useMemo(() => `useTideTable.${entity}.${Math.random()}`, [entity]);

    if (requestOptions && requestOptions.loadingId)
        loadingId = requestOptions.loadingId;
    //Get the api object
    let api = useContext(ApiContext);
    const newApi = useContext(TideApiContext);
    if(newTideApi)
        api=newApi;

    const [lastUsedApiOptions, setLastUsedApiOptions] = useState(null);
    //Function to fetch data from server, debounced for searching while writing an input
    const loadData = useCallback(tableState => {
        tableStateRef.current = tableState;
        if(pause)
            return;

        let filters = requestFilters;
        
        //Convert the table state to an api options object to make a request
        const apiOptions = createApiOptions(tableState, {loadingId, ...requestOptions}, {filterMappings}, filters, newTideApi);

        //Make the request
        setLastUsedApiOptions(apiOptions);
        return api[entity][getMethod](apiOptions);

    }, [api, requestOptions, requestFilters, filterMappings, createApiOptions, entity, getMethod, loadingId, newTideApi, pause]);

    const loadDataDebounced = useCallback(_.debounce(loadData, 650), [loadData]);

    const reload = useCallback(() => {
        //if(!tableStateRef.current)
        //    return;
        return loadData(tableStateRef.current);
    }, [loadData]);

    //If the parameters for the request change, we reload the data
    useUpdateEffect(() => {
        loadData(tableStateRef.current);
    }, [loadData]);

    //Get the requested data from Redux
    const reduxProp = requestOptions && requestOptions.customProp ? requestOptions.customProp : entity;
    let data = useSelector(({api}) => api[reduxProp]) || [];
    //Get the request meta data from redux
    let {totalItems, itemsPerPage} = useSelector(({api}) => api[reduxProp + 'Meta']) || {
        totalItems: 0,
        itemsPerPage: 0
    };
    //Get the loading state of the request from redux
    const loading = !!useSelector(({loadingIds}) => loadingIds[loadingId]);

    //For the selectable version
    const [selected, setSelected] = useState([]);
    const addSelected = useCallbackCreator((entityId, entity, isSelected) => {
        const newSelected = [...selected];
        if (!isSelected)
            _.remove(newSelected, (s) => s.id === entity.id);
        else
            newSelected.push(entity);
        setSelected(newSelected);
    }, [selected]);

    const CheckboxCell = useMemo(() => ({original}) => {
        return <div className='text-center'><CustomCheckbox value={original} onChange={addSelected(original.id, original)}
                                                                isChecked={!!_.find(selected, (s) => s && original && s.id === original.id)}/>
            </div>
        }
        , [selected, addSelected]);

    const checkboxColumn = ({
        Header: 'Selección',
        Cell: CheckboxCell,
        id: 'selection',
        filterable: false,
        sortable: false,
        width: 80
    });

    const filterCaseInsensitive = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
    };

    //Apply external filter to data array
    data = useMemo(() => {
        if (filterData)
            return filterData(data);
        return data;
    }, [filterData, data]);


    if (selectable) {
        columns = [checkboxColumn, ...columns];
    }

    //Expose everything in an object
    //tableProps are the props meant to be sent to a ReactTable
    const pages = Math.ceil(totalItems / itemsPerPage);
    return useMemo(() => ({
        tableProps: {
            data,
            columns,
            pages: pages,
            onFetchData: debounced ? loadDataDebounced : loadData,
            loading,
            ...defaultProps,
            defaultPageSize,
            manual: true,
            defaultFilterMethod: filterCaseInsensitive
        },
        reload,
        itemsFoundString: `${totalItems} registro${totalItems !== 1 ? 's' : ''} encontrado${totalItems !== 1 ? 's' : ''}`,
        selected,
        setSelected,
        lastUsedApiOptions,
    }), [data, columns, debounced, loadDataDebounced, totalItems, pages, loadData, loading, reload, selected, lastUsedApiOptions, defaultPageSize]);

};
