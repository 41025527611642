//Created by Mark Ehrlich 18.01.2017
import React, {PureComponent} from 'react';
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import{
    Grid, Row, Col,
    FormGroup, ControlLabel,
    FormControl
} from 'react-bootstrap';
import Card from '../../../../components/Card/Card'
import Radio from '../../../../elements/CustomRadio/CustomRadio.jsx';
import Checkbox from '../../../../elements/CustomCheckbox/CustomCheckbox.jsx';
import Datetime from 'react-datetime';
import 'react-select/dist/react-select.min.css';
import Button from '../../../../elements/CustomButton/CustomButton';
import {required} from "../../../../utils/FieldValidations";
import Select from "react-select";
import Webcam from 'react-webcam';
import ImageCropper from '../../../../utils/ImageCropper';
import avatar from '../../../../assets/img/faces/face-0.jpg';
import _ from 'lodash';
import {showUserDetail} from "../../../../actions/action_userDetail";
import {bindActionCreators} from 'redux';


class EmployeeForm extends  PureComponent {
    static contextTypes = { api: PropTypes.object, notifier: PropTypes.object };
    static defaultProps = { canSeeWage:true };
    constructor(props, context){
        super(props);
        this.photoButton = this.photoButton.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBonusChange = this.handleBonusChange.bind(this);
        this.handleSuperiorChange = this.handleSuperiorChange.bind(this);

        let state = {
            isEditing: Boolean(props.isEditing),
            addedBonuses: [],
            removedBonuses:[]
        };

        if( props.initialValues && props.initialValues.photo )
            state.photoUrl = context.api.createFileUrl(props.initialValues.photo);
        else
            state.photoUrl = avatar;

        console.log(props.initialValues);

        if( props.initialValues?.original?.employee?.superior )
            state.selectedSuperior = {value:props.initialValues.superior, label:employeeFullname(props.initialValues.original.employee.superior)};

        if( props.initialValues && props.initialValues.assistanceIds )
            state.assistanceIds = props.initialValues.assistanceIds;
        else
            state.assistanceIds = [];

        this.state = state;
    }

    componentDidMount(){
        this.context.api.areas.get();
        this.context.api.lounges.get();
        this.context.api.shifts.get();
        this.context.api.groups.get();
        this.context.api.banks.get();
        this.context.api.bonuses.get({alwaysAvailable:false});
        this.context.api.positions.get();
        this.context.api.clocks.get();
        this.context.api.roles.get();
    }

    checkboxNormalizer( val ){
        return !!val;
    }

    handleBonusChange( bonus, selected){

       /* Not needed anymore but may be useful. This code saves in an array which bonus have been added and which removed from the original employeeBonus array

        const added = [...this.state.addedBonuses];
        const removed = [...this.state.removedBonuses];
        const indexA = this.state.addedBonuses.indexOf(bonus);
        const indexR = this.state.removedBonuses.indexOf(bonus);


        if( !this.props.initialValues ) { //Creating new employee
            if (selected && indexA === -1) {
                added.push(bonus);
            }
        }
        else{ //Editing employee
            if (selected && indexA === -1 && !this.props.initialValues.bonus[bonus] ) {
                added.push(bonus);
            }
            if (!selected && indexR === -1 && this.props.initialValues.bonus[bonus]) {
                removed.push(bonus);
            }
        }
        // Next two ifs apply if I was going to modify a bonus but then they changed it to the original state
        if (selected && indexR !== -1 ) {
            removed.splice(indexR, 1);
        }
        if (!selected && indexA !== -1) {
            added.splice(indexA, 1);
        }


        console.log("Added",added,"Removed",removed);
            this.setState({
            addedBonuses: added,
            removedBonuses: removed
        });
*/
    }

    reloadWindow = () => {
        window.location.reload( true );
    };

    static userCreatingLoadingId = "@Class.AddEmployee.creatingUser";
    static userEditingLoadingId = "@Class.AddEmployee.editingUser";

    handleSubmit( values ){
        values = Object.assign({}, values);

        values.salary = Math.round(Number(values.salary)*100);
        values.manualScoreValue = Number(values.manualScoreValue);

        if( values.daysOff )
            values.daysOff = values.daysOff.reduce( (acc, val, i)=>{ if(val) acc.push(i); return acc;}, [] );
        else
            values.daysOff = [];

        if( this.state.selectedSuperior )
            values.superior = this.state.selectedSuperior.value;
        else
            values.superior = null;

        if( values.bank === "" )
            values.bank = null;
        if( values.paymentMethod === "")
            values.paymentMethod = null;
        if( values.role === "")
            values.role = null;

        if( values.assistanceIds )
            values.assistanceIds = values.assistanceIds.reduce((acc, assId)=>{
                if( assId.clock && assId.internalId !== '' && assId.internalId !== null )
                    acc.push(assId);
                return acc;
            }, []);

        values.original = undefined;

        const newUser = {
            username: values.user,
            isClockAdmin: values.isClockAdmin,
            password: values.password,
            email: values.email,
            role: values.role,
            employee: values,
            changeMonthlyPassword: values.changeMonthlyPassword
        };

        let file;
        if( this.state.photo ) {
            file = this.state.photo;
            //If it was a photo take, we change the name, otherwise it was a file selected from the fs
            if( file.constructor !== File )
                file.name = "profile_photo.jpg";
        }

        const employeeBonuses = [];
        for(const bonusString in values.bonus ){

            const bonus = Number(bonusString);

            if( values.bonus[bonus] ) {
                const employeeBonus = {bonus: bonus};
                for (const emBonIndex in values.employeeBonuses) {
                    if (values.employeeBonuses[emBonIndex].bonus.id === bonus) {
                        employeeBonus.id = values.employeeBonuses[emBonIndex].id;
                        break;
                    }

                }
                employeeBonuses.push(employeeBonus);
            }
        }

        values.employeeBonuses= employeeBonuses;

        if( this.state.isEditing ){
            newUser.employee.id = this.props.initialValues.id;
            this.context.api.users.edit( this.props.initialValues.userId, newUser, EmployeeForm.userEditingLoadingId, file)
                .then( ()=>{
                    this.context.notifier.success("La información fue enviada.");
                    this.props.onEdit && this.props.onEdit();
                });
        }
        else{
            this.context.api.users.create(newUser,  EmployeeForm.userCreatingLoadingId, file)
                .then(( user )=>{

                    this.context.notifier.success('Agregado correctamente');
                    this.props.showUserDetail( user );
                    this.props.history.push('/employees/list')
                })
        }
        //this.reloadWindow();
    }

    superiorLoadingId = "@Class.AddEmployee.getSuperior";
    handleSuperiorChange( value ){
        this.setState( {selectedSuperior:value} );
    }

    loadSuperiorEmployees = _.debounce(

        (input)=>{
            if(input === "" ) return;
            this.context.api.employees.get(
                0,
                10,
                this.superiorLoadingId,
                {"search":input, isHired:true});
        }

        , 1000, {'leading': true,'trailing': true});

    addNewClock = ()=>{
        const newClocks = [...this.state.assistanceIds];
        newClocks.push({});
        this.setState({assistanceIds:newClocks});
    };

    static userToFormValues( user ){
         let values = Object.assign({},user.employee);

         values.userId = user.id;
         values.isClockAdmin = user.isClockAdmin;
         values.user = user.username;
         values.email = user.email;
         values.changeMonthlyPassword = user.changeMonthlyPassword;
         values.salary = values.salary? Number(values.salary)/100.0:undefined;
         values.area = values.area? values.area.id : undefined;
         values.lounge = values.lounge? values.lounge.id : undefined;
         values.shift = values.shift? values.shift.id : undefined;
         values.bank = values.bank? values.bank.id : undefined;
         values.position = values.position? values.position.id : undefined;
         values.superior = values.superior? values.superior.id : undefined;
         values.group = values.group? values.group.id : undefined;
         values.owningGroup = values.owningGroup? values.owningGroup.id : undefined;
         values.role = user.role? user.role.id : undefined;
         values.applicant= values.applicant? values.applicant.id : undefined;
         values.personnelRequisition= values.personnelRequisition? values.personnelRequisition.id : undefined;
         values.original = user;

         if( values.assistanceIds )
             values.assistanceIds = values.assistanceIds.map(({id,internalId,clock})=>({id,internalId, clock:clock.id}));

         let daysOff = [];
         if( values.daysOff ) {

             for (let i = 0; i < 7; i++) {
                 daysOff[i] = values.daysOff.indexOf(i) !== -1;
             }
         }
         values.daysOff = daysOff;

         if( values.employeeBonuses ) {
             values.bonus = values.employeeBonuses.reduce(
                 (acc, emBon) => {
                     acc[emBon.bonus.id] = true;
                     return acc;
                 }
                 , {});
         }

         return values;
    }

    UNSAFE_componentWillReceiveProps( nextProps ){

        if( !this.props.submitFailed && nextProps.submitFailed && !nextProps.invalid )
                this.context.notifier.error("Hubo un error al añadir al empleado.");


        if(
            nextProps.submitFailed && nextProps.invalid && //If the form is invalid and tryed to submit it
            ((nextProps.employeeForm && this.props.employeeForm && nextProps.employeeForm.syncErrors !== this.props.employeeForm.syncErrors) || //And the errors changed
            (!this.props.submitFailed && nextProps.submitFailed))){ // Or tryed to submit with errors
            const errors = nextProps.employeeForm.syncErrors;
            const msgs = [];
            for (let key in errors)
                msgs.push(<li key={key}>{key} : {errors[key]}</li>);

            this.context.notifier.error(<div><p>Parece que hay un error en los datos introducidos. Revisa los campos de arriba.</p><ul> {msgs} </ul></div>);
        }

    }

    renderField = ( field )=>{

        if( field.input.name === "password" )
            this.changePassword = field.input.onChange;

        return <FormGroup>
            {field.label?
            <ControlLabel>
                {field.label}<br />
            </ControlLabel>:null}
            <FormControl
                {...field.input}
                placeholder={field.placeholder}
                type={field.type}
            />
            {field.meta.touched && field.meta.error?
                <span className="alert alert-danger">{field.meta.error}</span> :null}
            {field.meta.touched && field.meta.warning?
                <span className="alert alert-warning">{field.meta.warning}</span> :null}
        </FormGroup>;
    };

    photoButton(){

        const imgCropper = new ImageCropper();
        imgCropper.setImage( this.webcam.getScreenshot() )
            .then((img)=>img.getSquare())
            .then( (square)=>{
                this.setState({
                    photo: square,
                    photoUrl: this.getPhotoUrl(square),
                    cameraActive:false
                });

            });

    }

    getPhotoUrl( photo ){

        if(! photo )
            return "";

        let urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(photo);
    }

    onPhotoSelect = (e)=>{
        const files = e.target.files;
        if( !files || !files.length )
            return;


        if (FileReader && files && files.length) {
            const fr = new FileReader();
            fr.onload = ()=>{
                this.setState({
                    photo:files[0],
                    phtoUrl:fr.result
                });
            };
            fr.readAsDataURL(files[0]);
        }
    };

    roleBlackList = [ 'NOMINAS', 'DIRECTOR', 'NOMINAS_ADMINISTRADOR_EE' ];
    canAssignRole( role ){

        if( this.props.me.role.name === 'NOMINAS' )
            return true;

        return this.roleBlackList.indexOf( role.name ) === -1;
    }

    getRoleNameFromId( roleId ){
        const role = this.props.roles.find( (role)=>role.id === roleId );
        if( role )
            return role.name;

        return undefined;
    }

    canEditRoleId( roleId ){

        if( this.props.me.role.name === 'NOMINAS' )
            return true;

        if( !this.props.roles.length )
            return false;

        const role = this.getRoleNameFromId(roleId);
        if( !role )
            return true;

        return this.roleBlackList.indexOf( role ) === -1;
    }

    randomPass = ()=>{

        if( !this.changePassword ) return;

        const pass = Math.floor(1000 + Math.random() * 9000) + "";
        this.changePassword(pass);
    };

    render(){
        return(
            <div className="container-fluid EmployeeForm">
                <Card
                    title="Datos generales"
                    content={
                        <Grid fluid>
                            <Row>
                                <Col md={4}>
                                    <Field
                                        name="name"
                                        label="Nombres"
                                        placeholder="Nombres"
                                        type="text"
                                        validate={[required]}
                                        component={this.renderField}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Field
                                        name="patLastName"
                                        label="Apellido paterno"
                                        placeholder="Apellido paterno"
                                        type="text"
                                        validate={[required]}
                                        component={this.renderField}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Field
                                        name="matLastName"
                                        label="Apellido materno"
                                        placeholder="Apellido materno"
                                        type="text"
                                        component={this.renderField}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={4}>
                                    <FormGroup>
                                        <label>Género</label>
                                        <Field
                                            name="sex"
                                            label="Masculino"
                                            type="radio"
                                            value="M"
                                            number={1}
                                            validate={[required]}
                                            component={Radio}
                                        />
                                        <Field
                                            name="sex"
                                            label="Femenino"
                                            type="radio"
                                            value="F"
                                            number={2}
                                            validate={[required]}
                                            component={Radio}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <Field
                                        name="birthday"
                                        type="text"
                                        component={(field)=>
                                            <FormGroup>
                                                <ControlLabel>
                                                    Fecha de nacimiento:
                                                </ControlLabel>
                                                <Datetime
                                                    timeFormat={false}
                                                    inputProps={{placeholder:"Seleccionar fecha"}}
                                                    {...field.input}
                                                    onFocus={undefined}
                                                />
                                            </FormGroup>}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Field
                                        name="curp"
                                        label="CURP"
                                        placeholder="CURP a 18 dígitos"
                                        type="text"
                                        validate={[required]}
                                        component={this.renderField}
                                    />
                                </Col>

                            </Row>
                        </Grid>
                    }>
                </Card>

                <Card
                    title="Calificaciones"
                    content={
                        <Grid fluid>
                            <Row>
                                <Col md={6}>
                                    <Field
                                            name="manualScoreName"
                                            label="Nombre de la calificación"
                                            placeholder="Nombre de la calificación"
                                            type="text"
                                            component={this.renderField}
                                        />
                                </Col>
                                <Col md={6}>
                                    <Field
                                            name="manualScoreValue"
                                            label="Calificación"
                                            placeholder="Calificación"
                                            type="text"
                                            component={this.renderField}
                                        />
                                </Col>
                            </Row>
                        </Grid>
                    }>
                </Card>

                <Card
                    title="Permisos"
                    content={
                        <Grid fluid>
                            <Row>
                                <Col md={6}>
                                    <Field
                                        name="isClockAdmin"
                                        label="Administrador de reloj checador"
                                        type="checkbox"
                                        inline
                                        props={{number:7}}
                                        component={Checkbox}
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    }>
                </Card>

                <Card
                    title="Datos del sistema"
                    content={
                        <Grid fluid>
                            <Row>
                                <Col md={6}>

                                    {!this.state.isEditing ?
                                        <Field
                                            name="user"
                                            label="Usuario"
                                            placeholder="usuario"
                                            type="text"
                                            validate={[required]}
                                            component={this.renderField}
                                        />
                                        :
                                        <FormGroup>
                                            <ControlLabel>Usuario</ControlLabel>
                                            <input className='form-control' value={this.props.initialValues.user} disabled/>
                                        </FormGroup>
                                    }
                                </Col>
                                <Col md={6}>
                                    {!this.state.isEditing || this.state.chagingPassword ?
                                        <div>
                                            <Field
                                            name="password"
                                            label={this.state.isEditing ? "Cambiar contraseña" : "Contraseña"}
                                            placeholder={this.state.isEditing ? "Dejar en blanco para mantener contraseña actual" : "Contraseña"}
                                            type="text"
                                            validate={!this.state.isEditing ? [required] : []}
                                            component={this.renderField}
                                            />
                                            <Button onClick={this.randomPass} bsStyle='primary' >Contraseña aleatoria</Button>
                                        </div>
                                            :
                                        <FormGroup>
                                            <ControlLabel>
                                                Cambiar contraseña
                                            </ControlLabel>
                                            <div>
                                            <Button onClick={() => this.setState({chagingPassword: true})}>
                                                Presiona para cambiar
                                            </Button>
                                            </div>
                                        </FormGroup>

                                    }
                                </Col>

                            </Row>

                            <Row>
                                <FormGroup>
                                    <Col md={6}>
                                            {!this.props.initialValues.role || this.canEditRoleId( this.props.initialValues.role ) ?
                                                <FormGroup>
                                                    <Field
                                                        name="role"
                                                        component="select"
                                                        className="form-control"
                                                        label="Tipo de usuario"
                                                    >
                                                        <option value=''>Usuario básico</option>
                                                        {this.props.roles.map((role)=>
                                                            role.assignable && this.canAssignRole(role)?
                                                                <option value={role.id} key={role.id}>{role.title}</option>:null
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                                :
                                                <FormGroup>
                                                    <ControlLabel>
                                                        Tipo de usuario<br />
                                                    </ControlLabel>
                                                    <ControlLabel>Tipo de usuario</ControlLabel>
                                                    <input className='form-control' value={this.getRoleNameFromId(this.props.initialValues.role) || ''} disabled/>
                                                </FormGroup>
                                            }
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Field
                                                name="changeMonthlyPassword"
                                                label="Actulización de contraseña mensual:"
                                                type="checkbox"
                                                component={Checkbox}
                                            />
                                        </FormGroup>
                                    </Col>
                                </FormGroup>
                            </Row>

                            {/* There's no need for editing ids as they ar now managed by or iot clocks
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <ControlLabel>
                                            Registros de reloj checador<br/>
                                        </ControlLabel>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {this.state.assistanceIds.map((assId, i)=>(
                                <Row key={i}>
                                    <Col md={6}>
                                        <Field
                                            name={`assistanceIds[${i}].clock`}
                                            component="select"
                                            className="form-control"
                                            normalize={Number}
                                        >
                                            <option>Selecciona un reloj</option>
                                            {this.props.clocks.map((clock)=>
                                                <option value={clock.id} key={clock.id}>{clock.name}</option>
                                            )}
                                        </Field>
                                    </Col>
                                    <Col md={6}>
                                        <Field
                                            name={`assistanceIds[${i}].internalId`}
                                            placeholder="ID en el reloj"
                                            type="text"
                                            normalize={(input)=>isNaN(input)?0:Number(input)}
                                            component={this.renderField}
                                        />
                                    </Col>
                                </Row>
                            ))}
                            <a onClick={this.addNewClock}>Añadir registro</a>
                            {/**/}

                        </Grid>
                    }>
                </Card>

                <Card
                    title="Datos de contacto"
                    content={
                        <Grid fluid>
                            <form>
                                <Row>
                                    <Col md={12}>
                                        <Field
                                            name="email"
                                            label="Correo electrónico"
                                            placeholder="Correo electrónico"
                                            type="text"
                                            component={this.renderField}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Field
                                            name="cellPhone"
                                            label="Teléfono celular"
                                            placeholder="Teléfono celular"
                                            type="text"
                                            component={this.renderField}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <Field
                                            name="homePhone"
                                            label="Teléfono de casa"
                                            placeholder="Teléfono de casa"
                                            type="text"
                                            component={this.renderField}
                                        />
                                    </Col>
                                </Row>
                            </form>
                        </Grid>
                    }>
                </Card>

                <Card
                    title="Datos del puesto"
                    content={
                        <Grid fluid>
                            <FormGroup className="form-horizontal">
                                <Row>
                                    <FormGroup>
                                        <ControlLabel className="col-sm-2">
                                            Días de descanso
                                        </ControlLabel>
                                        <Col sm={10}>
                                            <Field
                                                name="daysOff[1]"
                                                label="Lunes"
                                                type="checkbox"
                                                inline
                                                props={{number:6}}
                                                component={Checkbox}
                                            />
                                            <Field
                                                name="daysOff[2]"
                                                label="Martes"
                                                type="checkbox"
                                                inline
                                                props={{number:7}}
                                                component={Checkbox}
                                            />
                                            <Field
                                                name="daysOff[3]"
                                                label="Miércoles"
                                                type="checkbox"
                                                inline
                                                props={{number:8}}
                                                component={Checkbox}
                                            />
                                            <Field
                                                name="daysOff[4]"
                                                label="Jueves"
                                                type="checkbox"
                                                inline
                                                props={{number:9}}
                                                component={Checkbox}
                                            />
                                            <Field
                                                name="daysOff[5]"
                                                label="Viernes"
                                                type="checkbox"
                                                inline
                                                props={{number:10}}
                                                component={Checkbox}
                                            />
                                            <Field
                                                name="daysOff[6]"
                                                label="Sábado"
                                                type="checkbox"
                                                inline
                                                props={{number:11}}
                                                component={Checkbox}
                                            />
                                            <Field
                                                name="daysOff[0]"
                                                label="Domingo"
                                                type="checkbox"
                                                inline
                                                props={{number:12}}
                                                component={Checkbox}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup>
                                        <ControlLabel className="col-sm-2">Puesto</ControlLabel>
                                        <Col sm={10}>
                                            <Field
                                                name="position"
                                                component="select"
                                                className="form-control"
                                            >
                                                <option value="">Selecciona un puesto</option>
                                                {this.props.positions.map((position)=>
                                                    <option value={position.id} key={position.id}>{position.name}</option>
                                                )}
                                            </Field>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel className="col-sm-2">Área</ControlLabel>
                                        <Col sm={10}>
                                            <Field
                                                name="area"
                                                component="select"
                                                className="form-control"
                                            >
                                                <option value="">Selecciona un área</option>
                                                {this.props.areas.map((area)=>
                                                    <option value={area.id} key={area.id}>{area.name}</option>
                                                )}
                                            </Field>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel className="col-sm-2">Sala</ControlLabel>
                                        <Col sm={10}>
                                            <Field
                                                name="lounge"
                                                component="select"
                                                className="form-control"
                                            >
                                                <option value="">Selecciona una sucursal</option>
                                                {this.props.lounges.map((lounge)=>
                                                    <option value={lounge.id} key={lounge.id}>{lounge.name}</option>
                                                )}
                                            </Field>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel className="col-sm-2">Turno</ControlLabel>
                                        <Col sm={10}>
                                            <Field
                                                name="shift"
                                                component="select"
                                                className="form-control"
                                                validate={[required]}
                                            >
                                                <option value="">Selecciona un turno</option>
                                                {this.props.shifts.map((shift)=>
                                                    <option value={shift.id} key={shift.id}>{shift.name}</option>
                                                )}
                                            </Field>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel className="col-sm-2">Grupo</ControlLabel>
                                        <Col sm={10}>
                                            <Field
                                                name="group"
                                                component="select"
                                                className="form-control"
                                            >
                                                <option value="">Selecciona un grupo</option>
                                                {this.props.groups.map((group)=>
                                                    <option value={group.id} key={group.id}>{group.name}</option>
                                                )}
                                            </Field>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel className="col-sm-2">Fecha de contratación</ControlLabel>
                                        <Col sm={10}>
                                            <Field
                                                name="hireDate"
                                                type="text"
                                                component={(field) => {

                                                    return <Datetime
                                                        timeFormat={false}
                                                        inputProps={{placeholder: "Seleccionar fecha"}}
                                                        value={field.value}
                                                        {...field.input}
                                                        onFocus={undefined}
                                                    />
                                                }
                                                }/>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel className="col-sm-2">Superior</ControlLabel>
                                        <Col sm={10}>
                                            <Select
                                                name="superior"
                                                options={this.props.employees.map(
                                                    (employee)=>({value:employee.id, label:employee.name+" "+employee.patLastName+" "+employee.matLastName})
                                                )}
                                                onInputChange={(input)=>this.loadSuperiorEmployees(input)}
                                                onChange={this.handleSuperiorChange}
                                                value={this.state.selectedSuperior}

                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup>
                                        <ControlLabel className="col-sm-2">Puede faltar al trabajo en días festivos?</ControlLabel>
                                        <Col sm={10}>
                                            <Field
                                                name="canMissWorkOnHoliday"
                                                label=""
                                                type="checkbox"
                                                inline
                                                props={{number:1}}
                                                component={Checkbox}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Row>
                            </FormGroup>
                        </Grid>
                    }>
                </Card>

                <Card
                    title="Datos de nómina"
                    content={
                        <Grid fluid>
                            <form>

                                <Row>
                                    <FormGroup className="outsourcing">
                                            <ControlLabel >
                                                Outsourcing
                                            </ControlLabel>
                                            <Field
                                                name="hiredByOutsourcing"
                                                label=""
                                                type="checkbox"
                                                inline
                                                props={{number:20, className:"outsourcing-checkbox"}}
                                                component={Checkbox}
                                            />
                                    </FormGroup>
                                </Row>

                                {this.props.canSeeWage?
                                    <div>
                                        <Row>
                                            <Col md={4}>
                                                <Field
                                                    name="salary"
                                                    label="Sueldo mensual"
                                                    placeholder="$0.00"
                                                    type="text"
                                                    component={this.renderField}
                                                    validate={[required]}
                                                />
                                            </Col>

                                            <Col md={4}>
                                                <FormGroup>
                                                    <ControlLabel>
                                                        Método de pago
                                                    </ControlLabel>
                                                    <Field
                                                        name="paymentMethod"
                                                        component="select"
                                                        label="Método de pago"
                                                        className="form-control"
                                                    >
                                                        <option value="" >Selecciona un método</option>
                                                        <option value="cash" >Efectivo</option>
                                                        <option value="transfer" >Transferencia</option>
                                                        <option value="check" >Cheque</option>
                                                        <option value="outsourcing" >Outsourcing</option>
                                                    </Field>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <ControlLabel>
                                                        Banco
                                                    </ControlLabel>
                                                    <Field
                                                        name="bank"
                                                        component="select"
                                                        className="form-control"
                                                    >
                                                        <option value="">Selecciona el banco</option>
                                                        {this.props.banks.map((bank)=>
                                                            <option value={bank.id} key={bank.id}>{bank.name}</option>
                                                        )}
                                                    </Field>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <Field
                                                    name="bankAccount"
                                                    label="Cuenta de banco"
                                                    type="text"
                                                    component={this.renderField}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <Field
                                                    name="clabe"
                                                    label="Cuenta CLABE"
                                                    type="text"
                                                    component={this.renderField}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                    :null}


                                <h4>Bonos automáticos</h4>
                                <Row>

                                    { this.props.bonuses.map( (bonus, i)=>{


                                        return (
                                            <Col md={3} key={bonus.id} className={'bonus-field'}>
                                                <FormGroup>
                                                    <ControlLabel >
                                                        {bonus.name}
                                                    </ControlLabel> <br/>
                                                    <Field
                                                        name={"bonus["+bonus.id+"]"}
                                                        label=""
                                                        type="checkbox"
                                                        inline
                                                        normalize={this.checkboxNormalizer}
                                                        props={{number:30+i}}
                                                        component={Checkbox}
                                                        onChange={(val)=>this.handleBonusChange(bonus.id, val.target.checked)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                    )}

                                </Row>

                            </form>
                        </Grid>
                    }>
                </Card>

                <Card
                    title="Tomar fotografía"
                    content={
                        <div className={"form-video"}>
                            {this.state.cameraActive ?
                                <div>
                                    <Webcam
                                        audio={false}
                                        ref={(webcam) => {
                                            this.webcam = webcam
                                        }}
                                        height={600}
                                        width={600}
                                        screenshotFormat="image/jpeg"
                                    />
                                    <hr/>
                                    <Button onClick={()=>this.setState({cameraActive: false})}>Desactivar cámara</Button>{" "}
                                    <Button onClick={this.photoButton}>Tomar fotografía</Button>
                                </div> :
                                <div>
                                    <img src={this.state.photoUrl || avatar} alt="User"/>
                                    <hr/>
                                    <Button onClick={()=>this.setState({cameraActive: true})}>Activar cámara</Button> <br/>ó<br/>
                                    <input type='file' onChange={this.onPhotoSelect} />
                                </div>
                            }
                        </div>
                    }
                />
                <Button
                    className="btn-success"
                    onClick={this.props.handleSubmit(this.handleSubmit)}
                    disabled={this.props.loadingIds[this.userCreatingLoadingId]}
                >
                    {this.state.isEditing? "Guardar cambios": "Crear empleado"}
                </Button>

            </div>
        );
    }
}

const employeeFullname = (employee)=>(employee.name+" "+employee.patLastName+" "+employee.matLastName);

function mapStateToProps({api, form, loadingIds}){

    const roles = api.roles || [];
    const areas = api.areas || [];
    const banks = api.banks || [];
    const shifts = api.shifts || [];
    const groups = api.groups || [];
    const clocks = api.clocks || [];
    const lounges = api.lounges || [];
    const bonuses = api.bonuses || [];
    const employees = api.employees || [];
    const positions = api.positions || [];
    const me = api.me;

    return { me, areas,lounges,shifts,groups,banks,clocks,bonuses,employees,positions,roles, employeeForm:form.EmployeeForm , loadingIds }
}

const mapDispatchToProps = ( dispatch )=>{
    return ({ showUserDetail: bindActionCreators( showUserDetail, dispatch ) });
};

const ReduxAddEmployee = reduxForm({form:"EmployeeForm"})(EmployeeForm);
ReduxAddEmployee.defaultProps = {initialValues:
        {
            hireDate:moment().format("YYYY-MM-DD"),
            hiredByOutsourcing: false,
            paymentMethod: 'cash',
        }};
export default connect(mapStateToProps, mapDispatchToProps)(ReduxAddEmployee);
