import _ from 'lodash';
import {maxDecimals} from "../../MathUtils";
import {convertToSmallestUnit} from "../almazen/unitsUtility";
import moment from 'moment';
import {copyPropertyIdOrNull} from "../../../services/formUtils";

const canQuotePositions = [
    'COORDINADOR DE COMPRAS',
    'COTIZADOR',
    'AUXILIAR COTIZADOR'
];

export const groupByProductTotals = purchaseRequest => _.mapValues(
    _.groupBy(purchaseRequest.purchaseProducts, 'productBrand.product.id'),
    pps => _.reduce(pps, (s, pp)=>{
        if(!pp.productBrand){
            return null; // non line item
        }

        const unitBundle = pp.productBrand.product;

        try{
            return s + convertToSmallestUnit(pp.quantity, pp.measurementUnit, unitBundle);
        }
        catch (e){
            console.log(e);
            console.log(pp?.measurementUnit, unitBundle);
            return null;
        }
    }, 0)
);

/**
 * An empty purchaseProduct to use in a form
 *
 * @param {isInStock}
 * @param isExtraCharge boolean
 * @param taxes Array
 * @returns {{uid: number, quantity: string, name: string, isInStock: boolean, searchedProducts: Array, isService: boolean}}
 */
export const basePurchaseProduct = ( {isInStock=true}, isExtraCharge = false, taxes=[]) =>{
    const pp = {
        name: '',
        isInStock: isInStock,
        isService: isExtraCharge,
        quantity: '1',
        uid: Math.random(),
        searchedProducts: [],
        isExtraCharge
    };
    if( isExtraCharge  ) {
        pp.purchaseProductQuotes = [emptyPurchaseProductQuote(getBaseTaxes(taxes))];
        pp.quantity = '1';
    }
    return pp;
};

/**
 * Checks if a user can quote a requisition
 *
 * @param user
 * @returns {boolean}
 */
export const canQuote = (user) => {
    return !!(user && user.employee && user.employee.position && canQuotePositions.indexOf(user.employee.position.name) !== -1);
};

/**
 * Template for empty PurchaseProductQuotes
 *
 * @returns {{provider: null}}
 */
export const emptyPurchaseProductQuote = ( baseTaxes )=>({
    provider:null,
    unitPrice: 0,
    unitDiscount: 0,
    purchaseProductTaxes: baseTaxes || [],

    subtotal: '0',
    discountTotal: '0',
    subtotalWithDiscount: '0',
    taxesTotal: '0',
    total: '0',

});

/**
 * This finds if there's a discount in any product inside the requisition
 *
 * @param pr
 * @returns {boolean}
 */
export const purchaseRequisitionHasDiscount = ( pr )=>{

    if( !pr || !pr.purchaseProducts || !pr.purchaseProducts.length )
        return false;

    let hasDiscount = false;
    for( let i=0; i < pr.purchaseProducts.length; i++ ){

        const quotes = pr.purchaseProducts[i].purchaseProductQuotes;
        if( !quotes.length ) continue;

        for( let j=0; j< quotes.length; j++ ){

            if( Number(quotes[j].unitDiscount) ){
                hasDiscount = true;
                break;
            }
        }
        if( hasDiscount )
            break;
    }

    return hasDiscount;
};

/**
 *
 *
 * @param pr PurchaseRequisition
 * @param taxes All taxes array
 * @returns {{purchaseProducts: (T | {purchaseProductQuotes: {provider: null}[]})[]}}
 */
export const initializePurchaseRequisitionForQuoting = ( pr, taxes )=>{

    let baseTaxes = getBaseTaxes( taxes );

    const purchaseProducts = pr.purchaseProducts.map( pp=>({...pp, purchaseProductQuotes:createNewQuotesForPurchaseProduct(pp, baseTaxes) }) );

    return {...pr, purchaseProducts, isQuotation: true, currency: 'MXN', extraCharges: []};
};

/**
 *
 * @param purchaseProduct
 * @param baseTaxes
 * @returns {{provider: null}[]|*}
 */
const createNewQuotesForPurchaseProduct=(purchaseProduct, baseTaxes)=>{

    if(!purchaseProduct || !purchaseProduct.productBrand || !purchaseProduct.productBrand.productBrandProviders)
        return [emptyPurchaseProductQuote( baseTaxes )];

    //We can use the list price of a product if it's set as available, has a price set, and has not expired
    const availableProductBrandProviders = _.filter(purchaseProduct.productBrand.productBrandProviders, pbp=>
        pbp.isAvailable &&
        Number(pbp.listPrice) &&
        (!pbp.listPriceExpirationDate ||
                moment().isSameOrBefore(pbp.listPriceExpirationDate))
    );
    //If none has the above conditions we continue with a normal empty quote
    if(!availableProductBrandProviders.length)
        return [emptyPurchaseProductQuote( baseTaxes )];
    //Create all quotes from list price info
    const quotes= availableProductBrandProviders.map( pbp=>createNewQuoteForPurchaseProductWithProvider(purchaseProduct, pbp, baseTaxes) );

    return _.sortBy(quotes, 'total')
};

/**
 *
 * @param purchaseProduct
 * @param productBrandProvider
 * @param baseTaxes
 * @returns {{provider: null}}
 */
const createNewQuoteForPurchaseProductWithProvider=(purchaseProduct, productBrandProvider, baseTaxes)=>{
    const quote=emptyPurchaseProductQuote( baseTaxes );

    quote.provider = productBrandProvider.provider;

    if(Number(productBrandProvider.listPrice))
        quote.unitPrice = maxDecimals(convertToSmallestUnit(productBrandProvider.listPrice, purchaseProduct.measurementUnit, purchaseProduct.productBrand.product), 4);
    if(Number(productBrandProvider.listDiscount))
        quote.unitDiscount = maxDecimals(convertToSmallestUnit(productBrandProvider.listDiscount, purchaseProduct.measurementUnit, purchaseProduct.productBrand.product), 4);
    computeAllQuoteValues(purchaseProduct, quote, true);

    return quote;
};

/**
 * Extracts from the array of al the available taxes, the ones that we auto-select on a new quote
 *
 * @param taxes
 * @returns {Array|{total: number, tax: *}[]}
 */
export const getBaseTaxes = (taxes)=>{
    if( !taxes )
        return [];

    const IVA = _.find( taxes, tax=>tax.name==='IVA' );
    if( IVA )
        return [ {total:0, tax:IVA} ];
};

/**
 * This method computes the calculable prices from the unit prices ( i.e. total, subtotal, etc )
 * This will modify the purchaseProductQuote param
 *
 * @param purchaseProduct
 * @param purchaseProductQuote
 * @param forceTaxes
 */
export const computeAllQuoteValues = ( purchaseProduct, purchaseProductQuote, forceTaxes=false )=>{

    const ppq = purchaseProductQuote;
    const quantity = Number( purchaseProduct.quantity );
    const price = Number(ppq.unitPrice);
    const discount= Number(ppq.unitDiscount);
    const oldSubtotal = ppq.subtotalWithDiscount;

    if( !price ){
        ppq.subtotal= '0';
        ppq.discountTotal= '0';
        ppq.subtotalWithDiscount= '0';
        ppq.taxesTotal= '0';
        ppq.total= '0';
        return;
    }

    ppq.subtotal = maxDecimals(quantity * price);
    ppq.discountTotal = maxDecimals(discount? quantity * discount : 0);
    ppq.subtotalWithDiscount= maxDecimals(discount? (ppq.subtotal - ppq.discountTotal) : ppq.subtotal);

    ppq.purchaseProductTaxes = computeEachTax( ppq.purchaseProductTaxes, ppq.subtotalWithDiscount, Number( oldSubtotal )||0, forceTaxes );
    ppq.taxesTotal= maxDecimals(getTotalTaxes( ppq.purchaseProductTaxes ));

    ppq.total= maxDecimals((discount? (ppq.subtotalWithDiscount ) : ppq.subtotal) +  ppq.taxesTotal);
};

/**
 * Computes the taxes sum in a given purchase product
 *
 * @param purchaseProductTaxes
 * @returns {number|*}
 */
export const getTotalTaxes = ( purchaseProductTaxes )=>{
    if( !purchaseProductTaxes || !purchaseProductTaxes.length )
        return 0;

    return purchaseProductTaxes.reduce((acc, ppTax) => acc + (Number(ppTax.total) || 0), 0);
};

/**
 * This takes the purchase product subtotal ( that is quantity * price - discount ) and multiplies it for
 *
 * @param purchaseProductTaxes
 * @param subtotal
 * @param oldSubtotal
 * @param forceTaxes
 * @returns {{length}|{length}|*|...{length}|*[]}
 */
export const computeEachTax = ( purchaseProductTaxes, subtotal, oldSubtotal, forceTaxes )=>{

    if( !purchaseProductTaxes || !purchaseProductTaxes.length )
        return purchaseProductTaxes;


    let changed = false;
    const newPPTaxes = [ ...purchaseProductTaxes ];

    for( let i=0; i<purchaseProductTaxes.length; i++ ){
        const multi = Number(newPPTaxes[i].tax.multiplier );

        //If the value is the same we don't change it
        //Or if the old total wasn't a calculated value ( it was manually changed )
        if( !forceTaxes && (newPPTaxes[i].total === getTaxValue(subtotal, multi) || newPPTaxes[i].total !== getTaxValue(oldSubtotal, multi)) )
            continue;
        newPPTaxes[i] = { ...newPPTaxes[i], total: getTaxValue(subtotal, multi) };
        changed = true;

    }

    if( changed )
        return  newPPTaxes;
    return purchaseProductTaxes;
};

const getTaxValue=(amount, multiplier)=>
    maxDecimals(( Number( amount )||0 ) * multiplier);

/**
 * Calculate the requisition total values
 * This modified the sent purchaseRequisition
 *
 * @param purchaseRequisition
 */
export const computePurchaseRequisitionTotals = ( purchaseRequisition )=>{

    const pr = purchaseRequisition;
    if( !pr || !pr.purchaseProducts )
        return;

    pr.subtotal = 0;
    pr.discountTotal = 0;
    pr.subtotalWithDiscount = 0;
    pr.taxesTotal = 0;
    pr.total = 0;

    const extraCharges = pr.extraCharges ||[];
    const allProducts = [...pr.purchaseProducts, ...extraCharges];

    for( let i=0; i<allProducts.length; i++ ){

        if( !allProducts[i].purchaseProductQuotes || !allProducts[i].purchaseProductQuotes.length )
            continue;
        const quote = allProducts[i].purchaseProductQuotes[0];

        pr.subtotal += maxDecimals(Number( quote.subtotal )? Number( quote.subtotal ):0);
        pr.discountTotal += maxDecimals(Number( quote.discountTotal )? Number( quote.discountTotal ):0);
        pr.subtotalWithDiscount += maxDecimals(Number( quote.subtotalWithDiscount )? Number( quote.subtotalWithDiscount ):0);
        pr.taxesTotal += maxDecimals(Number( quote.taxesTotal )? Number( quote.taxesTotal ):0);
        pr.total += maxDecimals(Number( quote.total )? Number( quote.total ):0);
    }

};

export const prepareQuoteForServer = ( purchaseRequisition ) =>{

    const pr = {...purchaseRequisition };

    delete pr.requestedBy;
    delete pr.requestedDate;
    delete pr.quotedBy;
    delete pr.quotedDate;
    delete pr.stockRequests;

    if(pr.expectedDate && typeof pr.expectedDate === 'object')
        pr.expectedDate = pr.expectedDate.format('YYYY-MM-DD');

    const allProducts = [...pr.purchaseProducts, ...(pr.extraCharges||[])];
    pr.purchaseProducts = prepareQuotedPurchaseProductsForServer( allProducts, pr.currency );
    if( pr.purchaseProducts.error )
        return pr.purchaseProducts;

    copyPropertyIdOrNull(purchaseRequisition, pr, 'jazzArea');

    if( !pr.currency )
        return { error:1, message:'selecciona un tipo de moneda' };

    delete pr.lastStatusLog;
    delete pr.statusLog;
    delete pr.status;

    pr.subtotal = pr.subtotal + '';
    pr.discountTotal = pr.discountTotal + '';
    pr.subtotalWithDiscount = pr.subtotalWithDiscount + '';
    pr.taxesTotal = pr.taxesTotal + '';
    pr.total = pr.total + '';

    return pr;
};

export const prepareQuotedPurchaseProductsForServer = ( purchaseProducts, currency )=>{

    if( !purchaseProducts || !purchaseProducts.length )
        return { error:1, message:'no se encontró ningún producto' };

    const pps = [];

    for (let i = 0; i < purchaseProducts.length; i++) {

        const purchaseProduct = {...purchaseProducts[i]};

        purchaseProduct.purchaseProductQuotes = preparePurchaseProductQuotesForServer( purchaseProduct.purchaseProductQuotes, currency );

        if( purchaseProduct.purchaseProductQuotes.error )
            return { error:1, message: 'en '+
                    (purchaseProduct.isExtraCharge?
                        ('un cargo extra'):
                        ('el producto ' + (i+1))) +
                    ' - ' + purchaseProduct.purchaseProductQuotes.message };

        if( purchaseProduct.productBrand ) {
            purchaseProduct.productBrand = purchaseProduct.productBrand.id;
            purchaseProduct.measurementUnit = purchaseProduct.measurementUnit.id;
        }
        else if( !purchaseProduct.name )
            return { error:1, message: 'falta seleccionar el nombre del producto ' + (i+1) };
        purchaseProduct.quantity = purchaseProduct.quantity + '';
        pps.push(purchaseProduct);
    }

    return pps;
};

export const preparePurchaseProductQuotesForServer = ( purchaseProductQuotes, currency )=> {

    if( !purchaseProductQuotes || !purchaseProductQuotes.length )
        return { error:1, message:'no se encontró ninguna cotización' };

    const ppqs = [];

    for( let i=0; i<purchaseProductQuotes.length; i++ ) {

        const ppq = {...purchaseProductQuotes[i], currency};
        if (isNaN(Number(purchaseProductQuotes[i].unitPrice)))
            return {error: 1, message: 'precio unitario inválido en la cotización ' + (i+1)};
        if (isNaN(Number(purchaseProductQuotes[i].unitDiscount)))
            return {error: 1, message: 'descuento unitario inválido en la cotización ' + (i+1)};
        if (!purchaseProductQuotes[i].provider || !purchaseProductQuotes[i].provider.id)
            return {error: 1, message: 'proveedor inválido en la cotización ' + (i+1)};

        ppq.provider = ppq.provider.id;
        ppq.unitPrice = ppq.unitPrice + '';
        ppq.subtotal = ppq.subtotal + '';
        ppq.unitDiscount = (ppq.unitDiscount||'0') + '';
        ppq.discountTotal = ppq.discountTotal + '';
        ppq.subtotalWithDiscount = ppq.subtotalWithDiscount + '';
        ppq.taxesTotal = ppq.taxesTotal + '';
        ppq.total = ppq.total + '';

        if (ppq.purchaseProductTaxes && ppq.purchaseProductTaxes.length) {
            ppq.purchaseProductTaxes = preparePurchaseProductTaxesForServer(ppq.purchaseProductTaxes);
            if (ppq.purchaseProductTaxes.error)
                return { error:1, message: 'en la cotización ' + (i+1) + ' - ' + ppq.purchaseProductTaxes.error.message  }
        }

        ppqs.push( ppq );
    }

    return ppqs;
};

/**
 * Prepares a purchaseProductTax object to be sent and persisted in the server
 * @param purchaseProductTaxes
 * @returns {[]|{error: number, message: string}}
 */
export const preparePurchaseProductTaxesForServer = ( purchaseProductTaxes )=>{

    const ppts = [];
    for( let i=0; i< purchaseProductTaxes.length; i++ ){

        if( !purchaseProductTaxes[i].tax || isNaN( Number(purchaseProductTaxes[i].total) ) )
            return { error:1, message:'el impuesto número ' + (i+1) + ' es inválido' };

        const newPPT = {
            tax: purchaseProductTaxes[i].tax.id,
            total: purchaseProductTaxes[i].total + ''
        };
        ppts.push( newPPT );
    }

    return ppts;
};

/**
 * From a purchase request with stock request information, it extracts
 * the products that are on such requests.
 */
export const getProductsFromStockRequest = pr => {
    if(!pr) return [];
    const sr = pr.stockRequestTrigger;
    if(!sr) return [];
    const smbs = _.filter(sr.stockMovementBatches, {inOrOut: 'in'});
    if(smbs.length===0) return [];
    return _.map(_.flatMap(smbs, 'movements'), sm=>({
	quantity: parseInt(sm.quantity),
	...sm.productBrand.product
    }));
};

/**
 * From a given purchase product, it returns if it's from a given product.
 */
const isFromProduct = (purchaseProduct, product)=>{
    return purchaseProduct?.productBrand?.product?.id === product?.id;
}

/**
 * `stockRequestProducts` is like the output of `getProductsFromStockRequest`. It adds up the quantities for a given purchaseProduct.
 */
export const quantityFromStockRequest = (purchaseProduct, stockRequestProducts) => {
    return _.sumBy(_.filter(stockRequestProducts, product=>isFromProduct(purchaseProduct, product)), 'quantity');
}

/**
 * This merges the information from a new PurchaseRequisition with an old one saved before,
 * only the editable fields will be retrieved from the old one and the non-editable will be left as-is in the new one.
 *
 * It returns a new object
 *
 * @param serverPR
 * @param draftPR
 */
export const loadPurchaseRequisitionDraft=( serverPR, draftPR )=>{

    const newPR={...serverPR};

    newPR.currency=draftPR.currency;
    newPR.extraCharges=draftPR.extraCharges;

    newPR.purchaseProducts=newPR.purchaseProducts.map( purchaseProduct=>{
        const draftPurchaseProduct=_.find(draftPR.purchaseProducts, (dpp)=>dpp.id===purchaseProduct.id);
        if(!draftPurchaseProduct) return purchaseProduct;

        const newPP={
            ...purchaseProduct,
            purchaseProductQuotes: draftPurchaseProduct.purchaseProductQuotes||[]
        };

        _.forEach(newPP.purchaseProductQuotes, ppQuote=>computeAllQuoteValues(newPP, ppQuote));
        return newPP;
    });

    computePurchaseRequisitionTotals(newPR);
    return newPR;
};

export const purchaseRequisitionListSGroups=[
    'purchase_requisition_read_id',
    'purchase_requisition_read_is_urgent',
    'purchase_requisition_read_requested_by',
        'employee_read_name',
        'employee_read_pat_last_name',
        'employee_read_mat_last_name',
    'purchase_requisition_read_requested_date',
    'purchase_requisition_read_status',
    'purchase_requisition_read_stock_request_trigger',
    'purchase_requisition_read_purchase_products',
        'purchase_product_read_id',
        'purchase_product_read_name',
        'purchase_product_read_product_brand',
            'product_brand_read_product',
                'product_read_name',
        'purchase_product_read_selected_purchase_product_quote',
            'purchase_product_quote_read_provider',
                'provider_read_name',
    'purchase_requisition_read_total',
    'purchase_requisition_read_jazz_area',
        'jazz_area_read'
];

export const purchaseRequisitionProcessSGroups=[
    'purchase_requisition_read',
    'purchase_requisition_read_requested_by',
        'employee_read_id',
        'employee_read_name',
        'employee_read_pat_last_name',
        'employee_read_mat_last_name',
        'employee_read_full_name',
        'employee_read_area',
            'area_read',
        'employee_read_position',
            'position_read_id',
            'position_read_name',
    'purchase_requisition_read_requested_date',
    'purchase_requisition_read_status',
    'purchase_requisition_read_purchase_products',
        'purchase_product_read',
        'purchase_product_read_product_brand',
            'product_brand_read_id',
            'product_brand_read_product',
                'product_read_id',
                'product_read_name',
                'product_read_display_unit',
                'product_read_smallest_unit',
                    'measurement_unit_read',
                'product_read_conversion_rules',
                    'unit_conversion_rule_read',
            'product_brand_read_brand',
                'brand_read_name',
            'product_brand_read_product_brand_providers',
                'product_brand_provider_read',
                'product_brand_provider_read_provider',
        'purchase_product_read_measurement_unit',
        'purchase_product_read_selected_purchase_product_quote',
        'purchase_product_read_purchase_product_quotes',
            'tax_read',
            'purchase_product_quote_read_provider',
                'provider_read_id',
                'provider_read_name',
                'provider_read_commercial_name',
                'provider_read_registered',
    'purchase_requisition_read_total',
    'purchase_requisition_read_purchase_orders',
        'purchase_order_read',
    'cassette_read',
    'purchase_requisition_approval_read_co_approvers',
    'purchase_requisition_read_stock_requests',
        'stock_request_read_id',
        'stock_request_read_folio',
        'stock_request_read_created_date',
        'stock_request_read_stock_movement_batches',
            'stock_movement_batch_read_in_or_out',
            'stock_movement_batch_read_movements',
                'stock_movement_read_quantity',
                'stock_movement_read_product_brand',
                    'product_brand_read_product',
                        'product_read_id',
    'purchase_requisition_read_jazz_area',
        'jazz_area_read'
];
