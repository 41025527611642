import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import ClassicModal from "../../../../components/Modals/ClassicModal";
import useCallbackCreator from "../../../../hooks/useCallbackCreator";
import CustomCheckbox from "../../../../elements/CustomCheckbox/CustomCheckbox";
import {
    denormalizeConfig,
    normalizeConfig,
    prepareLoungeAccessMethodForServer
} from "../../../../utils/modelUtils/davinci/loungeAccessMethodUtils";
import {ApiContext} from "../../../../api/Api";
import {NotifierContext} from "../../../../utils/Notifier";
import {useSelector} from "react-redux";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import TagsInput from 'react-tagsinput';
import EntitySelect from 'components/EntitySelect/EntitySelect';
import _, {isEmpty} from 'lodash';

const loadingId='@LoungeAccessMethodFormModal';

const LoungeAccessMethodFormModal= React.memo(({onHide, onSave, loungeAccessMethod, parentLoungeAccessMethod})=>{

    const api=useContext(ApiContext);
    const notifier=useContext(NotifierContext);

    const parentLAM=parentLoungeAccessMethod;

    const [form, setForm]=useState(
        loungeAccessMethod||//If we are editing, load old entity values
        (parentLAM?{...parentLAM, id:undefined, name:''}:null)||//If it's a new sub-access method, pre-fill parent's values but without the id
        {});//If it's a brand new root access method, we start clean

    //Handle any change in the form except for the ones in the config section
    const handleInputChange=useCallbackCreator((name, val)=>{
        setForm({...form, [name]:val && val.target? val.target.value:val});
    },[form]);


    const [config, setConfig]=useState(loungeAccessMethod?
        denormalizeConfig(loungeAccessMethod.config):
        parentLoungeAccessMethod?
        denormalizeConfig(parentLoungeAccessMethod.config):
        {});
    //Handle config state change
    const handleConfigChange=useCallbackCreator((name, val)=>{
        const newConfig={...config, [name]:(val && val.target)? val.target.value:val};
        setConfig(newConfig);
    },[config]);

    // ------- Extra fields------
    const addExtraField=useCallback(()=>{
        const extraFields= config.extraFields||[];
        extraFields.push({name:''});
        const newConfig={...config, extraFields};
        setConfig(newConfig);
    },[config]);

    const onExtraFieldChange=useCallbackCreator((info, val)=>{
        const [index, name]=info.split('-');
        const extraField=config.extraFields[index];
        extraField[name] = (val && val.target)? val.target.value:val;
        const extraFields=[...config.extraFields];
        extraFields[index]=extraField;
        setConfig( {...config, extraFields } );

    },[config]);

    const removeExtraField=useCallbackCreator((index)=>{
        const extraFields = [...config.extraFields];
        extraFields.splice(index, 1);
        setConfig({...config, extraFields});
    },[config]);

    // ---- Flight Config ----
    //Initializers
    const arrivalsApiProp = "Flight.Arrivals";
    const departuresApiProp = "Flight.Departures";
    const loungeFlightsSaved = loungeAccessMethod?.config?.flights || [];

    //State
    const [arrivals, setArrivals] = useState([]);
    const [departures, setDepartures] = useState([]);

    //Setting existing flights:
    useEffect(() => {
        if(!isEmpty(loungeFlightsSaved)){
            const params = {id: loungeFlightsSaved};
            const payload = {params};
            api.flights.get(payload).then(res => {
                const newArrivals =
                    res.filter(({id, isArrival}) => loungeFlightsSaved.includes(id) && !!isArrival);
                const newDepartures =
                    res.filter(({id, isArrival}) => loungeFlightsSaved.includes(id) && !isArrival);

                setArrivals(newArrivals);
                setDepartures(newDepartures);
            });
        }
    }, [api, loungeFlightsSaved]);

    //Helpers
    const createFlightLabel = useCallback((data = {}) => {
        const stringParams = {
            airlineName: data?.airline?.name || 'Aerolínea desconocida',
            destination: data?.destination || 'Destino desconocido',
            number: data?.number || 'Número de vuelo desconocido'
        };

        const {airlineName, destination, number} = stringParams;
        return `${airlineName} - ${destination} - ${number}`;
    },[]);

    //Removing selected options from flight list
    const filterArrivals =useCallback((options)=>_.filter(options, opt=>!_.find(arrivals,d=>d.id===opt.id)), [arrivals]);
    const filterDepartures =useCallback((options)=>_.filter(options, opt=>!_.find(departures,d=>d.id===opt.id)), [departures]);
    //filters for requests
    const flightFilters = useMemo(()=>({arrival:{isArrival:true}, departure:{isArrival:false}}), []);


    //Send info to server
    const [canReCheckIn,setCanReCheckIn] = useState(loungeAccessMethod && loungeAccessMethod.canReCheckIn);
    const handleSave=useCallback(()=>{

        try {
            const preparedAccessMethod = prepareLoungeAccessMethodForServer(form);
            const flights = _.map([...arrivals, ...departures],'id');
            const normConfig = normalizeConfig(config);
            const configPayload = {...normConfig, flights};


            if (preparedAccessMethod.error)
                return notifier.error(preparedAccessMethod.error);

            if(parentLoungeAccessMethod)
                preparedAccessMethod.loungeParentAccessMethod=parentLoungeAccessMethod.id;

            preparedAccessMethod.config = configPayload;
            preparedAccessMethod.canReCheckIn = canReCheckIn;
            if (loungeAccessMethod)
                api.loungeAccessMethods.edit({
                    id: preparedAccessMethod.id,
                    loungeAccessMethod: preparedAccessMethod,
                    loadingId
                }).then(onSave);
            else
                api.loungeAccessMethods.create({loungeAccessMethod: preparedAccessMethod, loadingId}).then(onSave);
        }
        catch (e) {
            notifier.error(e.message);
        }

    },[form, config, api, notifier, loungeAccessMethod, parentLoungeAccessMethod, onSave,canReCheckIn, arrivals, departures]);

    const loading=useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    //handle activation
    const [isActive, setIsActive]=useState(loungeAccessMethod && loungeAccessMethod.isActive);
    const toggleIsActive=useCallback(()=>{
        if(!loungeAccessMethod) return;

        const oldActive=isActive;
        api.loungeAccessMethods.edit({id:loungeAccessMethod.id, loungeAccessMethod:{id:loungeAccessMethod.id, isActive: !isActive}})
            .catch(()=>{
                setIsActive(oldActive);
            });
        setIsActive(!isActive);
    },[isActive, api, loungeAccessMethod]);

    //Handle extra field regexp state
    const [testValues, setTestValues] = useState([]);
    const handleTestValueChange = useCallback((e)=>{
        const target = e.target;
        setTestValues( values=>{
            values[ target.dataset.index ] = target.value;
            return [...values];
        });
    },[]);
    const validateRegexp = ( value, regexp )=>{
        if(!value || !regexp) return '';
        return (new RegExp(regexp)).test(value)?"valid":"invalid";
    }


    return (
        <ClassicModal
            title={`${loungeAccessMethod?'Editar':'Nuevo'} ${parentLAM?'sub-':''}método de acceso`}
            onHide={onHide}
            className='LoungeAccessMethodFormModal'
            disabled={loading}
            onSave={handleSave}
        >
            <p className="in-label">Nombre:</p>
            <input className='form-control' value={form.name||''} onChange={handleInputChange('name')}/>
            <p className="in-label">Tiempo máximo de estancia en minutos: <span className='text-muted'>Coloca un cero para tiempo ilimitado</span></p>
            <input className='form-control' type='number' value={form.accessTime||''} onChange={handleInputChange('accessTime')}/>
            <p className="in-label">Número máximo de acompañantes:</p>
            <input className='form-control' type='number' value={form.maxAdults||''} onChange={handleInputChange('maxAdults')}/>
            <p className="in-label">Número máximo de niños menores de 12 años:</p>
            <input className='form-control' type='number' value={form.maxKids||''} onChange={handleInputChange('maxKids')}/>

            <p className='in-label'>Clasificación para reportes:</p>
            <select className='form-control' value={form.accessType} onChange={handleInputChange('accessType')}>
                <option value={null}>Sin asignar</option>
                <option value='airline'>Aerolínea</option>
                <option value='card'>Tarjeta</option>
            </select>

            <p className="in-label">Prefijo para folio de check in:</p>
            <input className='form-control' value={form.folioPrefix||''} onChange={handleInputChange('folioPrefix')}/>

            <hr/>
                <h4>Vuelos disponibles</h4>

                <div>
                    <p className='in-label'>Entrada:</p>
                    <EntitySelect
                        initialLoad
                        multi
                        entity="flights"
                        filterBy="search"
                        getMethod="getInEntitySelector"
                        value={arrivals}
                        onChange={setArrivals}
                        apiCustomProp={arrivalsApiProp}
                        filterEntities={filterArrivals}
                        labelCreator={createFlightLabel}
                        additionalFilters={flightFilters.arrival}
                    />
                </div>
                <div>
                    <p className='in-label'>Salida:</p>
                    <EntitySelect
                        initialLoad
                        multi
                        entity="flights"
                        filterBy="search"
                        getMethod="getInEntitySelector"
                        value={departures}
                        onChange={setDepartures}
                        labelCreator={createFlightLabel}
                        filterEntities={filterDepartures}
                        apiCustomProp={departuresApiProp}
                        additionalFilters={flightFilters.departure}
                    />
                </div>

            <hr/>
            <p className="in-label">Configuración adicional:</p>
            <ul className='config-list'>
                <li>
                    <span className='config-label'><CustomCheckbox inline isChecked={config.hasUSDPrice||false} onChange={handleConfigChange('hasUSDPrice')}/> Precio en dólares </span>
                    {config.hasUSDPrice&&
                        <div className='text-input price'>
                            <input className='form-control' value={config.USDPrice||''} onChange={handleConfigChange('USDPrice')} placeholder='Precio...'/>
                        </div>}
                </li>
                <li>
                    <span className='config-label'><CustomCheckbox inline isChecked={config.hasEURPrice||false} onChange={handleConfigChange('hasEURPrice')}/> Precio en euros </span>
                    {config.hasEURPrice&&
                    <div className='text-input price'>
                        <input className='form-control' value={config.EURPrice||''} onChange={handleConfigChange('EURPrice')} placeholder='Precio...'/>
                    </div>}
                </li>
                <li>
                    <span className='config-label'><CustomCheckbox inline isChecked={config.hasMXNPrice||false} onChange={handleConfigChange('hasMXNPrice')}/> Precio en pesos </span>
                    {config.hasMXNPrice&&
                    <div className='text-input price'>
                        <input className='form-control' value={config.MXNPrice||''} onChange={handleConfigChange('MXNPrice')} placeholder='Precio...'/>
                    </div>}
                </li>
                <li>
                    <span className='config-label'><CustomCheckbox inline isChecked={config.hasFreeMeals||false} onChange={handleConfigChange('hasFreeMeals')}/> Platillos de cortesía por persona</span>
                    {config.hasFreeMeals&&
                    <div className='text-input number'>
                        <input className='form-control' value={config.freeMeals||''} onChange={handleConfigChange('freeMeals')} placeholder='Cant...'/>
                    </div>}
                </li>
                <li>
                    <span className='config-label'><CustomCheckbox inline isChecked={canReCheckIn||false} onChange={setCanReCheckIn}/>Es posible hacer re-check in</span>
                </li>
            </ul>

            <p className="in-label">Campos extra:</p>
            {(config.extraFields||[]).map((extraField, index)=>
                <div className='extra-field' key={index}>
                    <input placeholder='Nombre...' className='form-control' value={extraField.name} onChange={onExtraFieldChange(index+'-name')} />
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-sm-6 col-xs-12'>
                                <CustomCheckbox inline isChecked={extraField.mandatory||false} onChange={onExtraFieldChange(index+'-mandatory')}/> Obligatorio
                            </div>
                            <div className='col-sm-6 col-xs-12'>
                                <CustomCheckbox inline isChecked={extraField.onePerCompanion||false} onChange={onExtraFieldChange(index+'-onePerCompanion')}/> Por cada acompañante
                            </div>
                            <div className='col-sm-6 col-xs-12'>
                                <CustomCheckbox inline isChecked={extraField.onePerChild||false} onChange={onExtraFieldChange(index+'-onePerChild')}/> Por cada niño
                            </div>
                            {(extraField.onePerCompanion || extraField.onePerChild)&&
                            <div className='col-sm-6 col-xs-12'>
                                <CustomCheckbox inline isChecked={extraField.includingGuest || false} onChange={onExtraFieldChange(index + '-includingGuest')}/> Solicitar al titular
                            </div>}
                            <div className='col-sm-6 col-xs-12'>
                                <CustomCheckbox inline isChecked={extraField.multipleChoice || false} onChange={onExtraFieldChange(index + '-multipleChoice')}/> De opción múltiple
                            </div>
                            <div className='col-sm-6 col-xs-12'>
                                <CustomCheckbox inline isChecked={extraField.validation || false} onChange={onExtraFieldChange(index + '-validation')}/> Validar texto
                            </div>
                            {!!extraField.validation &&
                            <div className="col-xs-12">
                                <p className="small">
                                    Se validará el campo de texto utilizando la expresión regular configurada. Si durante
                                    el check in la validación falla, se le mostrará el mensaje de error al recepcionista.
                                </p>
                                <p className="small extra-input-label">Expresión regular</p>
                                <input placeholder='Expresión regular...' className='form-control' value={extraField.regexp||''} onChange={onExtraFieldChange(index+'-regexp')} />
                                <p className="small extra-input-label">Mensaje de error</p>
                                <input placeholder='Mensaje de error...' className='form-control' value={extraField.regexpMessage||''} onChange={onExtraFieldChange(index+'-regexpMessage')} />
                                <p className={"small extra-input-label "}>Probar expresión (opcional)</p>
                                <input placeholder='Escribe texto para probar si es válido...'
                                       className={'form-control '+validateRegexp(testValues[index], config.extraFields[index]?.regexp )}
                                       value={testValues[index]||''} onChange={handleTestValueChange} data-index={index} />
                            </div>}
                        </div>
                        {!!extraField.multipleChoice &&
                        <div className='row'>
                            <p>Opciones para el campo:</p>
                            <TagsInput
                                inputProps={{placeholder: 'Escribe la opción y presiona enter'}}
                                value={extraField.multipleChoiceOptions||[]} onChange={onExtraFieldChange(index+'-multipleChoiceOptions')}
                         tagProps={{className: 'react-tagsinput-tag tag-fill tag-azure'}}
                            />
                        </div>
                        }
                    </div>
                    <button className='delete-btn' onClick={removeExtraField(index)}><i className='red-icon fa fa-trash'/></button>
                </div>
            )}
            <p className='text-center'><span className='link' onClick={addExtraField}>Agregar campo extra</span></p>

            <hr/>
            {loungeAccessMethod&&
                <div className='activation-container text-center'>
                    <CustomButton
                        bsStyle={isActive?'danger':'success'}
                        onClick={toggleIsActive}
                    >
                        {isActive?'Desactivar':'Activar'}
                    </CustomButton>
                </div>}

        </ClassicModal>
    );
});

export default LoungeAccessMethodFormModal;
