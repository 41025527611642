import React, {useCallback, useContext} from 'react';
import TideFormModal from "../../../../components/TideFormModal/TideFormModal";
import {TideApiContext} from "../../../../api/tideApiConfig";
import {useSelector} from "react-redux";
import {airportGateFormFields} from "../../../../utils/modelUtils/ee/airportGateUtils";

const AirportGateFormModal = ({ onHide, toEdit }) => {

    const api = useContext(TideApiContext);

    const loadingId = "@AirportGateFormModal";
    const saveAirportGate = useCallback(( airportGate )=>{
        api.airportGates[toEdit?"update":"create"]({ id:toEdit?.id, params:airportGate, loadingId })
            .then( onHide );
    },[api, onHide, toEdit]);

    const loading = useSelector( s=>!!s.loadingIds[loadingId] );

    return (
        <TideFormModal
            title={(toEdit?"Editar":"Nueva") + " puerta del aeropuerto"}
            initialState={toEdit}
            className={"AirportGateFormModal"}
            onHide={onHide}
            fields={airportGateFormFields}
            onSubmit={saveAirportGate}
            disabled={loading}
        />
    );
};

export default AirportGateFormModal;
