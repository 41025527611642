import React, {useContext, useEffect} from 'react';
import {Modal} from "react-bootstrap";
import {ApiContext, employeeFullname} from "../../../../../../api/Api";
import Button from "../../../../../../elements/CustomButton/CustomButton";
import {NotifierContext} from "../../../../../../utils/Notifier";
import {useSelector} from "react-redux";

const FireHireUserModal = ({ onHide, userFiring, userHiring, disabled }) => {

    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);

    useEffect(()=>{
        if(!userFiring) return;
        api.employeeWorkTools.get( 0, 200, "@FireHireUserModal.worktools", {employee:userFiring.employee.id} );
    },[api, userFiring]);

    const employeeWorkTools = useSelector((s)=>s.api.employeeWorkTools)||[];

    const confirmHireEmployee=()=>{
        api.employees.hire( userHiring.employee.id )
            .then( ()=>notifier.success("Empleado re-contratado") );
        onHide();
    }

    const confirmFireEmployee=()=>{
        api.employees.fire( userFiring.employee.id, '@FireHireUserModal.fire')
            .then(()=>notifier.success("Empleado despedido") );
        onHide();
    };

    return (
        <Modal
            onHide={onHide}
            show={true}>
            <Modal.Header closeButton>
                <Modal.Title>{userFiring? 'Dar de baja':'Contratar'} empleado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>¿Estás seguro que deseas {userFiring? 'dar de baja':'volver a contratar'} a {employeeFullname(userFiring? userFiring.employee : userHiring.employee)}?</p>
                <p>El empleado {userFiring? 'perderá':'obtendrá de nuevo'} acceso a su cuenta en el sistema</p>
                { userFiring && employeeWorkTools.length?
                    <div className='alert alert-danger'>
                        <p><strong>¡Advertencia!</strong> El empleado aún tiene el siguiente equipo de trabajo asignado:</p>
                        <ul>
                            {employeeWorkTools.map((ewt)=>(
                                <li key={ewt.id}>{ewt.workTool.name}</li>
                            ))}
                        </ul>
                    </div>:
                    null}
            </Modal.Body>

            <Modal.Footer>
                <Button
                    bsStyle={'danger'}
                    style={{float:'left'}}
                    onClick={onHide}
                >
                    Cancelar
                </Button>
                <Button
                    bsStyle={userFiring? 'danger':'success'}
                    onClick={ userFiring? confirmFireEmployee:confirmHireEmployee }
                    disabled={ disabled }
                >
                    Sí, {userFiring? 'despedir':'contratar'}
                </Button>
            </ Modal.Footer>
        </Modal>
    );
};

export default FireHireUserModal;
