import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import moment from "moment/moment";
import Button from "../../../elements/CustomButton/CustomButton";
import FileViewer from '../../../components/FileViewer/FileViewer';
import PropTypes from 'prop-types';
import {UserDetailLoadingId, UserDetailApiProp} from "../Employees/core/UserDetail";
import Rotable from '../../../components/Rotable/Rotable';
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";


export default class JustificationRequestDetail extends Component {

    static defaultProps = {canApprove:true};
    static contextTypes = {api:PropTypes.object};

    constructor(props){
        super(props);

        this.state = {
            supervisorComments: props.incidenceJustification.responseComment || "",
            rotations: {}
        };

        this.handleCommentsChange = this.handleCommentsChange.bind(this);
        this.showUserDetail = this.showUserDetail.bind(this);
        this.didApprove = this.didApprove.bind(this);
        this.didReject = this.didReject.bind(this);
        this.didDelete = this.didDelete.bind(this);
    }

    handleCommentsChange( e ){
        this.setState({supervisorComments:e.target.value});
    }

    didApprove(){

        const newIncJus = Object.assign( {},this.props.incidenceJustification );
        newIncJus.responseComment =  this.state.supervisorComments;
        newIncJus.approved = true;
        this.props.onChange(newIncJus);

    }

    didReject(){

        const newIncJus = Object.assign( {},this.props.incidenceJustification );
        newIncJus.responseComment =  this.state.supervisorComments;
        newIncJus.approved = false;
        this.props.onChange(newIncJus);
    }

    didDelete(){
        this.props.onDelete( this.props.incidenceJustification );
    }

    showUserDetail( ){
        this.context.api.users.get(0,1,UserDetailLoadingId,{employee:this.props.incidenceJustification.requestedBy.id},UserDetailApiProp );
    }

    rotateFile( index ){

        const rotations = { ...this.state.rotations };

        if( !rotations[index] ) {
            rotations[index] = Rotable.rotation90;
            this.setState({rotations})
        }
        else if( rotations[index] === Rotable.rotation90 ){
            rotations[index] = Rotable.rotation180;
            this.setState({rotations})
        }
        else if( rotations[index] === Rotable.rotation180 ){
            rotations[index] = Rotable.rotation270;
            this.setState({rotations})
        }
        else if( rotations[index] === Rotable.rotation270 ){
            rotations[index] = Rotable.rotation0;
            this.setState({rotations})
        }

    }

    render() {

        const {incidenceJustification: incJus, urlCreator, onHide} = this.props;

        return (
            <Modal
                show={true}
                bsSize="lg"
                onHide={onHide}
                className="JustificationRequestDetail"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Solicitud de <span className="span-but" onClick={this.showUserDetail}>{employeeFullName(incJus.requestedBy)}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>Tipo de solicitud: <b>{incJus.newIncidence.name}</b></p>
                    <p>Solicitud para {incJus.endDate?'los días':'el día'}: {moment(incJus.date).format("dddd, DD [de] MMMM")} {incJus.endDate?` al ${moment(incJus.endDate).format("dddd, DD [de] MMMM")}`:""}</p>
                    <p>Creación de solicitud: {moment(incJus.createdDate).format("dddd, DD [de] MMMM [a las] HH:mm")}</p>
                    <p>Comentarios de la solicitud: <br/></p>
                    <pre>{incJus.requestComment ? incJus.requestComment : "Sin comentarios."}</pre>

                    {incJus.files && incJus.files.length && this.props.urlCreator ?

                        incJus.files.map((file, i)=>
                            <div key={file.id}>
                                <Rotable rotation={this.state.rotations[i]}>
                                    <FileViewer
                                        name={file.originalName}
                                        src={urlCreator(file)}
                                    />
                                </Rotable><br/>
                                <Button onClick={()=>this.rotateFile(i)}>Rotar imagen <i className='fa fa-repeat'/></Button>
                            </div>) :
                        <p>No se adjunto ningún archivo.</p>
                    }
                    <p>Comentarios del supervisor:</p>
                    {this.props.canApprove && (incJus.approved === null || this.state.editingComments)?
                        <textarea
                            className="form-control"
                            placeholder="Agrega tus comentarios"
                            value={this.state.supervisorComments}
                            onChange={this.handleCommentsChange}
                        />:
                        incJus.responseComment?
                            <pre onDoubleClick={()=>this.setState({editingComments: true})} >{incJus.responseComment}</pre>:
                            <pre onDoubleClick={()=>this.setState({editingComments: true})} >Sin comentarios.</pre>
                    }
                    {incJus.reviewedBy?
                        <p>Revisado por {employeeFullName(incJus.reviewedBy)}</p>
                        :null}


                </Modal.Body>

                {this.props.canApprove?

                    <Modal.Footer>
                        { incJus.approved === null || incJus.approved?
                            <Button style={{float: "left"}} bsStyle="danger" onClick={this.didReject}>Rechazar</Button>:null}
                        { incJus.approved === null || !incJus.approved?
                            <Button bsStyle="success" onClick={this.didApprove}>Aprobar</Button>:null}
                    </Modal.Footer>
                    :null}

                {this.props.onDelete && incJus.approved === null?
                    <Modal.Footer>
                        <Button bsStyle="danger" onClick={this.didDelete}>Eliminar</Button>
                    </Modal.Footer>
                    :null}

            </Modal>
        );
    }
}
