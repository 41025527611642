import React, {Component} from 'react';
import StatsCard from '../../../../components/Card/StatsCard';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import LoaderIcon from '../../../../components/Helper/LoaderIcon';


class PPayrollClosed extends Component {

    getInfoObject(){

        const object = {
            isClosed: <LoaderIcon />,
            linkString: "Cerrar nómina"
        };

        if( this.props.payrollDetails && this.props.payrollDetails.constructor === Array ){

            if( !this.props.payrollDetails.length )
                object.isClosed = "No";

            object.isClosed = this.props.payrollDetails[0].calculatedRealTime? "No":"Sí";
            object.linkString = this.props.payrollDetails[0].calculatedRealTime? "Cerrar nómina":"Ver nómina";

        }

        return object;
    }

    render() {

        const stringsObject = this.getInfoObject();

        return (
            <StatsCard
                bigIcon={<i className={"fa fa-pencil-square-o"}/>}
                statsText={ "Nómina actual cerrada" }
                statsValue={stringsObject.isClosed}
                statsIcon={<Link to={"/alma/payroll/detail/actual"}><i className="fa fa-eye" /></Link>}
                statsIconText={<Link to={"/alma/payroll/detail/actual"}>{stringsObject.linkString}</Link>}
            />
        );
    }
}

function mapStateToProps( {api:{payrollDetails}} ){

    return {payrollDetails}
}

export default connect(mapStateToProps)(PPayrollClosed);