import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';

const smallBreakpoint = 660;


export default function YesNoAnswerStats( {stats} ){

    const [ isSmall, setIsSmall ] = useState( window.innerWidth < smallBreakpoint );

    useEffect( ()=>{

        const changeWidth = ()=>setIsSmall( window.innerWidth < smallBreakpoint );
        window.addEventListener('resize', changeWidth );

        return ()=>window.removeEventListener( 'resize', changeWidth );
    }, []);

    if( !stats.length )
        return <p>Sin información.</p>;

    let yesOption = 0;
    let noOption = 0;

    for( let i =0; i<stats.length; i++) {
        if ( stats[i].answer === 'true')
            yesOption = stats[i].count;
        else if ( stats[i].answer === 'false')
            noOption = stats[i].count;
    }

    const count = Number( yesOption ) + Number( noOption );

    const graphOtions = {
        tooltip : {
            trigger: 'item',
            formatter: "{b} <br/> {c} ({d}%)"
        },
        legend: {
            show: isSmall,
            left: 'left',
            orient: 'vertical',
            data: ['Sí','No'],
            formatter: ( opt )=> opt + ' - ' + ( opt==='Sí'? yesOption : noOption )
        },
        series : [
            {
                name: 's',
                type: 'pie',
                radius : '60%',
                center: ['50%', '50%'],
                label: isSmall?
                    false :
                    { formatter:'{b} - {d}%'},
                data:[
                    {value:yesOption, name:'Sí', itemStyle:{color:"#3cb868"}},
                    {value:noOption, name:'No', itemStyle:{color:"#b9465d"}},
                ],
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };

    return (
        <div>
            <ReactEcharts option={graphOtions} />
            <span className='text-muted small'>{count} respuesta{Number(count)!==1?'s':''}</span>
        </div>
    );
}