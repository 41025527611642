import React from 'react';
import Switch from "react-bootstrap-switch";


const InactiveQuestion = ( props )=>{

    return (
        <div>
            <Switch
                value={ !!props.question.config.inactive }
                onChange={ (component,value)=>{ props.onChange( { ...props.question.config, inactive: value } ) } }
            />&nbsp;
            Desactivar pregunta
        </div>
    );
};


export default InactiveQuestion;
