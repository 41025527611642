import React, { useState, useCallback, useContext, useMemo } from 'react';
import {Creatable} from "react-select";
import _ from "lodash";
import {ApiContext} from "../../../api/Api";
import {useSelector} from "react-redux";


const NEW_OPTION_PREFIX = 'Nuevo - ';
const customProp='ProviderSelect.providers';
const loadingId='ProviderSelect.providers.get';
const ProviderSelect = (  { provider, onChange, creatable=false } )=>{

    const api = useContext( ApiContext );
    const [inputValue, setInputValue] = useState('');
    let searchedProviders=useSelector(({api})=>api[customProp]||[]);
    const isLoading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    const loadProviders = useCallback( _.debounce(( input )=>{

        const trimmedInput = input.trim();
        if( !trimmedInput ) return;

        api.providers.get({page:0, pageSize:10, filters:{search: trimmedInput}, customProp, loadingId});

    }, 650), [api]);

    //If there are no results and it is creatable, add the "Create new" option
    searchedProviders=useMemo(()=> !creatable || searchedProviders.length || isLoading || !inputValue.trim()?
            searchedProviders:
            [{id:'new', name: inputValue.trim()}]
    ,[searchedProviders, isLoading, inputValue, creatable]);

    const inputChangeHandler=useCallback((input)=>{
        setInputValue(input);
        loadProviders(input);
    },[loadProviders]);

    const selected = useMemo( ()=>provider? selectValue(provider):null, [provider] );

    const onSelectChange = useCallback( ( option )=>{
        if( !option )
            return onChange(null);

        if(option.value.id === 'new'){
            api.providers.create({provider:{name: option.value.name, registered: false}, customProp, loadingId})
                .then((newProvider)=>{
                    onChange(newProvider);
                });
        }
        else {
            const selectedProvider = _.find(searchedProviders, (oneProv) => oneProv.id === option.value.id);
            if (!selectedProvider)
                return onChange(null);
            return onChange(selectedProvider);
        }
    }, [api, searchedProviders, onChange] );

    const providerOptions = useMemo( ()=>searchedProviders.map(selectValue), [searchedProviders]);

    return <Creatable
        name="provider"
        options={providerOptions}
        onInputChange={inputChangeHandler}
        inputValue={inputValue}
        onChange={ onSelectChange }
        value={ selected }
        isLoading={ !!isLoading }
        placeholder='Escribe el nombre del proveedor...'
        valueRenderer={renderOption}
        optionRenderer={renderOption}
    />;
};

const selectValue = ( provider )=>( { value: provider, label: createLabel( provider ) } );
const createLabel = ( provider )=>{
    if(provider.id !== 'new' )
        return provider.name + (provider.commercialName ? (' (' + provider.commercialName + ')') : '');
    return NEW_OPTION_PREFIX + provider.name;
};
const renderOption = (option)=><span><span className={'dot ' + (option.value.registered?'green':'red')}/>{option.label}</span>;

export default ProviderSelect;
