import React, { useContext, useState, useCallback } from 'react';
import Button from '../../../elements/CustomButton/CustomButton';
import useBoolean from '../../../hooks/useBoolean';
import JazzAreaFormModal from './components/JazzAreaFormModal';
import JazzAreaDetailModal from './components/JazzAreaDetailModal';
import useTideTable from "../../../hooks/table/useTideTable";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { TideApiContext } from '../../../api/tideApiConfig';
import moneyFormatter from '../../../utils/moneyFormatter';
import ReactTable from 'react-table';
import {Link} from "react-router-dom";

export const columnsConfig = [
    {
        Header: 'Área',
        id: 'name',
        accessor: (jazzArea)=>jazzArea.name?`${jazzArea.name}`:'Sin definir'
    },
    {
        Header: 'Presupuesto mensual',
        id: 'budget',
        filterable: false,
        accessor: (jazzArea)=>jazzArea.budget?moneyFormatter(jazzArea.budget, 1):'Sin definir'
    },
    {
        Header: 'Presupuesto utilizado',
        id: 'monthlyUsedBudget',
        filterable: false,
        accessor: (jazzArea)=>moneyFormatter(jazzArea.monthlyUsedBudget, 1)
    },
    {
        Header: 'Presupuesto restante',
        id: 'remainingBudget',
        filterable: false,
        accessor: (jazzArea)=>moneyFormatter(jazzArea.budget - jazzArea.monthlyUsedBudget, 1)
    },
    {
        Header: 'Es Acumulable',
        id: 'isAccumulated',
        filterable: false,
        accessor: (jazzArea) => jazzArea.budgetIsAccumulated ? 'Si' : 'No'
    },
    {
        Header: 'P. Automático',
        id: 'automaticBudget',
        filterable: false,
        accessor: (jazzArea) => jazzArea.automaticBudget ? 'Si' : 'No'
    },
    {
        Header: 'Historial',
        id:'history',
        filterable: false,
        accessor: (jazzArea) => <Link to={"/compras/area_catalogues/history/" + jazzArea.id}><Button>Ver Historial</Button></Link>
    }
];

const tableConfig = {
    entity:'jazzAreas',
    columns: columnsConfig,
    newTideApi: true,
    requestOptions: {
        customProp: '@JazzAreaCataloguesList.jazzAreas',
        filters: {
            sGroups: ["jazz_area_read","jazz_area_read_monthly_used_budget"]
        }
    }
};

const JazzAreaCataloguesList = () => {
    const [addingArea, addArea, hideAddAreaModal] = useBoolean(false);
    const [showingDetail, setShowingDetail] = useState(null);
    const [editingArea, setEditingArea]=useState(null);
    const stopEditing=useCallback(()=>setEditingArea(null),[]);
    const [deletingArea, setDeletingArea]=useState(null);
    const stopDeleting=useCallback(()=>setDeletingArea(null),[]);

    //---detail
    const hideDetailModal = () => {
        setShowingDetail(null);
    };

    const jazzAreaTable = useTideTable(tableConfig);

    const reloadTableJazzAreas = useCallback(() => {
        jazzAreaTable.reload();
    }, [jazzAreaTable]);

    const tideApi = useContext(TideApiContext);

    // .------- deleting ----------
    const promptDeleteArea=useCallback((area)=>{
        hideDetailModal();
        setDeletingArea(area);
    },[]);

    const deleteArea=useCallback(()=>{
        stopDeleting();
        tideApi.jazzAreas.delete({id:deletingArea.id});
        reloadTableJazzAreas();
    },[tideApi, deletingArea, stopDeleting, reloadTableJazzAreas]);

    // .------- editing ----------
    const startEditingArea=useCallback((area)=>{
        hideDetailModal();
        setEditingArea(area);
    },[]);

    return (
        <div className={'JazzAreaCatalogues'}>
            <div className="container-fluid main-container">
                <Button bsStyle='primary' className='pull-right' onClick={addArea}>Nueva área</Button>
            </div>
            <div className='clearfix'/>
            <ReactTable
                {...jazzAreaTable.tableProps}
                getTdProps={(state, row) => ({onClick: () => row ? setShowingDetail(row.original) : null})}
            />
            <p className="total-count">{jazzAreaTable.itemsFoundString}</p>
            {addingArea && <JazzAreaFormModal onHide={hideAddAreaModal} reload={reloadTableJazzAreas}/>}
            {editingArea && <JazzAreaFormModal onHide={stopEditing} area={editingArea} reload={reloadTableJazzAreas}/>}
            {showingDetail && <JazzAreaDetailModal area={showingDetail} onHide={hideDetailModal} onEdit={startEditingArea} onDelete={promptDeleteArea} />}
            {deletingArea && <ConfirmModal onCancel={stopDeleting} onConfirm={deleteArea} title={'Eliminar área'} message={"¿Estás seguro que deseas borrar esta área?"} />}
        </div>
    );
}

export default JazzAreaCataloguesList;
