import React, {useContext, useCallback, useState, useEffect} from 'react'
import {Modal, FormGroup, FormControl, ControlLabel, Button} from 'react-bootstrap';
import {SingleDatePicker} from 'react-dates';
import {Row, Col } from 'react-bootstrap';
import {TideApiContext} from "../../../../api/tideApiConfig";
import {NotifierContext} from "../../../../utils/Notifier";
import ConfirmModal from "../../../../components/Modals/ConfirmModal";

export const ModalEvent = ({onClose, editModalOpen, currentEvent, datePickerProps}) => {
    const tideApi = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);
    const [name, setName] = useState('');
    const [deletingEvent, setDeletingEvent] = useState();

    useEffect(() => {
        setName('');
        
        if(currentEvent?.title)
            setName(currentEvent?.title);

    }, [currentEvent])

    const handleSaveEvent = () => {
        if(!name)
            return notifier.error('El nombre del evento es un campo requerido');            

        const event = {
            name,
            eventDate: datePickerProps.date
        };

        if(currentEvent?.id){
            tideApi.events.update({params: event, id: currentEvent.id}).then(eventSuccess);
        } else {
            tideApi.events.create({params: event}).then(eventSuccess);
        }
    };
    
    const handleNameChange = useCallback((e) => setName(e.target.value),[setName]);
    const cancelDeleting = useCallback(() => setDeletingEvent(null), [setDeletingEvent]);
    
    const eventSuccess = () => { 
        clearEvent();
        notifier.success(currentEvent ? 'El evento ha sido actualizado' : 'El evento ha sido registrado');
    };

    const handleDeleteEvent = useCallback(() => setDeletingEvent(true), []);
    const clearEvent = useCallback(() => {
        setDeletingEvent(null);
        setName('');
        onClose(true);
    }, [setDeletingEvent, onClose, setName]);

    const deleteEvent = useCallback(() => {
        if(!currentEvent?.id) return;
        
        tideApi.events.delete({id: Number(currentEvent.id)})
            .then(()=>notifier.warning('El evento ha sido eliminado'))
            .then(()=>clearEvent());        
    }, [tideApi, currentEvent, clearEvent, notifier]);

    const handleClose = () => {
        onClose();
    }
    return (
        <>
            <Modal
                onHide={handleClose}
                show={editModalOpen} >
                <Modal.Header closeButton>
                    <Modal.Title>{!currentEvent ? 'Crear evento' : 'Editar evento' }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <FormGroup>                                
                                <ControlLabel>Nombre del evento</ControlLabel>
                                <FormControl type="text" onChange={handleNameChange} value={name}/>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <ControlLabel>Fecha del evento</ControlLabel>
                                
                                <div className="w-100">
                                    <SingleDatePicker
                                        {...datePickerProps}
                                        placeholder='Selecciona...'
                                    />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>    
                    {currentEvent && 
                        <Button bsStyle="danger" onClick={handleDeleteEvent}>Borrar</Button>}
                    <Button bsStyle="primary" onClick={handleSaveEvent}>Enviar</Button>
                </Modal.Footer>
            </Modal>

            <ConfirmModal
                show={!!deletingEvent}
                onCancel={cancelDeleting}
                onConfirm={deleteEvent}
                title={'Borrar el evento'}
            />
        </>
    );
}
