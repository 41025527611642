import React, {useState, useEffect, useContext, useMemo, useCallback} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, OverlayTrigger, Tooltip} from "react-bootstrap";
import {TideApiContext} from "../../../api/tideApiConfig";
import {useSelector} from 'react-redux';
import useInput from '../../../hooks/useInput';
import CustomCheckbox from '../../../elements/CustomCheckbox/CustomCheckbox';
import TideEntitySelect from '../../../components/TideEntitySelect/TideEntitySelect';
import _ from 'lodash';
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import {listSGroups} from "../../../utils/modelUtils/almazen/periodicStockRequestUtils";
import Switch from "react-bootstrap-switch";
import DayOfTheWeekSelector from "../../../components/DayOfTheWeekSelector/DayOfTheWeekSelector";
import {dayOfTheWeekNotation} from "../../../utils/timeUtils";


export const hourToTime = i => (i<10?'0':'')+(i.toString())+':00';
const employeeAdditionalFilters = {sGroups:["user_search"]};
const emptyArr = [];

const PeriodicStockFormModal = ({periodicStockRequest, onHide}) => {

    const tideApi = useContext( TideApiContext);
    const warehouses =useSelector(({api})=>api.warehouses||emptyArr);
    const lounges =useSelector(({api})=>api.lounges||emptyArr);

    const [selectedLounges, setSelectedLounges] = useState(periodicStockRequest?.loungesForAverage||emptyArr);
    const [approvers, setApprovers] = useState(periodicStockRequest?.approvers||emptyArr);
    const [daysOfTheWeekIn, setDaysOfTheWeekIn] = useState(periodicStockRequest?.daysOfTheWeek?.split(',')||[1]);
    const daysForAverageIn = useInput(periodicStockRequest?.daysForAverage||30);
    const daysToAskIn = useInput(periodicStockRequest?.daysToAsk||7);
    const fromIn = useInput(periodicStockRequest?.fromWarehouse?.id);
    const toIn = useInput(periodicStockRequest?.toWarehouse?.id);
    const timeIn = useInput((periodicStockRequest?.time/60)||0);
    const multiplier = useInput(periodicStockRequest?.multiplier? Math.round(((periodicStockRequest?.multiplier-1)*100)) :0);
    const [isActive, setIsActive] = useState(!!periodicStockRequest?.isActive);
    const [productsForMaxStock, setProductsForMaxStock] = useState( !!periodicStockRequest?.getProductsForMaxStock );
    const [productsFromRecipes, setProductsFromRecipes] = useState( !!periodicStockRequest?.getProductsFromRecipes );
    const [productsFromIncomeMovements, setProductsFromIncomeMovements] = useState( !!periodicStockRequest?.getProductsFromIncomeMovements );

    useEffect( ()=>{
        tideApi.warehouses.get({params:{pagination:false, isActive: true}});
        tideApi.lounges.get({params:{pagination:false, isActive: true, isLounge: true}});
    }, [tideApi] );

    const isLoungeChecked = (id) => {
        return _.some(selectedLounges, {'id': id});
    }

    const toggleLoungeCheckbox = (lounge) => {
        if (!isLoungeChecked(lounge.id)) {
            setSelectedLounges(ls =>  [...ls, lounge]);
        }
        else {
            setSelectedLounges(ls => _.filter(ls, l => lounge.id !== l.id));
        }
    };

    const params = useMemo(()=>({
        fromWarehouse: fromIn.value,
        toWarehouse: toIn.value,
        daysOfTheWeek: daysOfTheWeekIn.join(","),
        isActive,
        time: parseInt(timeIn.value)*60,
        daysForAverage: parseInt(daysForAverageIn.value),
        loungesForAverage: selectedLounges.map(l=>l.id),
        daysToAsk: parseInt(daysToAskIn.value),
        approvers: approvers.map(e=>e.id),
        multiplier: (parseFloat(multiplier.value)||0)/100+1,
        sGroups: listSGroups,
        getProductsFromRecipes:productsFromRecipes,
        getProductsFromIncomeMovements: productsFromIncomeMovements,
        getProductsForMaxStock: productsForMaxStock
    }), [fromIn, toIn, daysOfTheWeekIn, timeIn, daysForAverageIn, selectedLounges, daysToAskIn, approvers,
        multiplier.value, isActive, productsFromRecipes, productsFromIncomeMovements, productsForMaxStock]);

    const submitRequest = useCallback(()=>{
        tideApi.periodicStockRequests[periodicStockRequest? "update":"create" ]({id:periodicStockRequest?.id ,params})
            .then(onHide);
    }, [params, onHide, tideApi, periodicStockRequest]);

    const deleteRequest = ()=>{
        tideApi.periodicStockRequests.delete({ id:periodicStockRequest.id }).then(onHide);
    };

    return (
        <Modal show className="PeriodicStockModal" onHide={onHide}>
            <ModalHeader closeButton onHide={onHide}>
                <ModalTitle>{periodicStockRequest?'Editar':'Crear'} requisición automática</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <h5>Solicitar al almacén:</h5>
                <select className='form-control' {...fromIn.bind}>
                    <option value='' disabled>Selecciona un almacén</option>
                    { warehouses.map( ( wh )=>
                        <option key={wh.id} value={wh.id}>{wh.name}</option>
                    ) }
                </select>
                <h5>Lugar de entrega:</h5>
                <select className='form-control' {...toIn.bind}>
                    <option value='' disabled>Selecciona un lugar</option>
                    { warehouses.map( ( wh )=>
                        <option key={wh.id} value={wh.id}>{wh.name}</option>
                    ) }
                </select>
                <h5>Días de la semana:</h5>
                <DayOfTheWeekSelector values={daysOfTheWeekIn} onChange={setDaysOfTheWeekIn}
                                      notation={dayOfTheWeekNotation.SUNDAY_ZERO} />

                <h5>Hora:</h5>
                <select {...timeIn.bind} className="form-control">
                    {[...new Array(24)].map((v, i)=>(
                        <option key={i} value={i}>
                            {hourToTime(i)}
                        </option>
                    ))}
                </select>

                <h5>Días a promediar</h5>
                <input {...daysForAverageIn.bind} className="form-control" type="number"/>

                <h5>Días a solicitar</h5>
                <input {...daysToAskIn.bind} className="form-control" type="number"/>

                <h5>Porcentaje extra a solicitar{' '}
                    <OverlayTrigger placement="bottom"
                                    overlay={<Tooltip id="percentage-info">
                                        Si esperas que la demanda incremente, selecciona ingresa el porcentaje extra
                                        de personas que se espera para los próximos {daysToAskIn.value} días.<br/>
                                        Por ejemplo si recibiste 1,000 personas en los últimos {daysToAskIn.value} días y
                                        esperas 1,500 en los próximos {daysToAskIn.value} días, ingresa aquí <b>50</b>, ya que se espera un
                                        incremento de 50% en la demanda.
                                    </Tooltip>}
                    ><i className='fa fa-question-circle' />
                    </OverlayTrigger>
                </h5>
                <input {...multiplier.bind} className="form-control" type="number"/>

                <h5>Salas a promediar</h5>
                <div className="row">
                    <div className='col-sm-12'>
                        {lounges.map(lounge=><CustomCheckbox
                            key={lounge.id}
                            label={lounge.name}
                            inline
                            isChecked={isLoungeChecked(lounge.id)}
                            onChange={() => toggleLoungeCheckbox(lounge)}
                        />)}
                    </div>
                </div>

                <h5>Aprobaciones de la requisición</h5>
                <TideEntitySelect
                    entity="employees"
                    multi={true}
                    value={approvers}
                    onChange={setApprovers}
                    additionalFilters={employeeAdditionalFilters}
                    labelCreator={employeeFullName}
                />
                <h5>Solicitar lo necesario llenar el máximo de almacén</h5>
                <Switch value={productsForMaxStock} onChange={(_, val)=>setProductsForMaxStock(val)}/>
                <h5>Usar consumos de recetas para calcular la requisición</h5>
                <Switch value={productsFromRecipes} onChange={(_, val)=>setProductsFromRecipes(val)}/>
                <h5>Usar las entradas anteriores para calcular la requisición</h5>
                <Switch value={productsFromIncomeMovements} onChange={(_, val)=>setProductsFromIncomeMovements(val)}/>
                <h5>Habilitada</h5>
                <Switch value={isActive} onChange={(_, val)=>setIsActive(val)}/>

                <ModalFooter>
                    <button className="btn btn-danger pull-left" onClick={deleteRequest}>Eliminar requisición</button>
                    <button className="btn btn-success" onClick={submitRequest}>Guardar requisición</button>
                </ModalFooter>
            </ModalBody>
        </Modal>
    );
};

export default PeriodicStockFormModal;
