import React from 'react';
import Card from '../../../../components/Card/Card';
import AgeGraph from './AgeGraph';
import GenderGraph from './GenderGraph';


const DemographicsStats = (  )=>{

    return(
        <Card
            title='Reporte demográfico'
            content={
                <>
                    <GenderGraph />

                    <hr/>

                    <AgeGraph />

                </>
            }
            />
    );

};

export default DemographicsStats;
