import React from 'react';
import "./_GolfCartScheduleControlPanel.scss";
import Card from "../../../../../components/Card/Card";
import GolfCartTripForm from "../GolfCartTripForm/GolfCartTripForm";
import CommentsFeed from "../../../../../components/CommentsFeed/CommentsFeed";

const GolfCartScheduleControlPanel = ({
                                          showingCassetteId,
                                          addingTrip,
                                          onCancel,
                                          reloadTrips,
                                          reloadAddingTrip,
                                          addingTripOnChange,
                                          onCommentAdded,
                                      }) => {

    return (
        <Card className={"GolfCartScheduleControlPanel"}
              title={
                  <div>Panel de control
                      {(!!addingTrip || !!showingCassetteId) &&
                      <span onClick={onCancel} className={"close"}><i className={'fa fa-times'}/></span>}
                  </div>}>


            {!addingTrip && !showingCassetteId &&
            <div className={"not-selected"}>
                <p>Selecciona un viaje programado o una hora</p>
            </div>}

            {showingCassetteId &&
            <CommentsFeed
                onCommentAdded={onCommentAdded}
                cassetteId={showingCassetteId}
            />}

            {addingTrip && <GolfCartTripForm addingTrip={addingTrip}
                                             onCancel={onCancel}
                                             reloadTrips={reloadTrips}
                                             reloadAddingTrip={reloadAddingTrip}
                                             addingTripOnChange={addingTripOnChange}
            />}

        </Card>
    );
};

export default GolfCartScheduleControlPanel;
