import React from 'react';
import moment from "moment";
import PurchaseRequisitionStatus from "./PurchaseRequisitionStatus";
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";
import {Link} from "react-router-dom";
import {paths} from "../../../../routes/almazen";

const PurchaseRequisitionHeader = ( {purchaseRequisition} )=>{

    if( !purchaseRequisition )
        return null;
    const pr = purchaseRequisition;

    return (
        <>
            <div className='pull-left'>
                {!pr.stockRequests?.length &&
                <div className='bg-danger text-center rounded' style={{padding: '1em 0', marginBottom: '0.5em'}}>
                    <h6 className='text-uppercase' style={{margin: '0.2em 0'}}><strong>No se asoció una requisición de almacén</strong></h6>
                </div>
                }
                <span>Solicitud <b>#{pr.folio}</b></span>
                <br/>
                <span>{employeeFullName(pr.requestedBy)}</span>
                <br/>
                <span className="small">Área: {pr.jazzArea ? pr.jazzArea.name : 'Sin área asignada'}</span>
                <br/>
                <span className="small">Puesto: {pr.requestedBy.position ? pr.requestedBy.position.name : 'Sin puesto'}</span>
                <br/>
                {!!pr.stockRequests?.length &&
                <span className="small">
                    Requisiciones de almacén asociadas:
                    {pr.stockRequests.map( (stockRequest, index)=>
                        <React.Fragment key={index}>{' '}
                            <Link to={paths.stockRequestDetail.replace(":id", stockRequest.id)}>
                                {stockRequest.folio}
                            </Link>{ index!==(pr.stockRequests.length-1)&&", "}
                        </React.Fragment>
                    )}
                </span>}
            </div>
            <div className='pull-right'>
                <span className='pull-right'>Fecha de solicitud {moment(pr.requestedDate).format("DD/MM/YYYY HH:mm")}</span>
                <br/>
                {pr.quotedDate ?
                    <span className='pull-right'>Fecha de cotización {moment(pr.quotedDate).format("DD/MM/YYYY HH:mm")}</span>
                    : null
                }
            </div>

            <div className='clearfix' />
            <PurchaseRequisitionStatus purchaseRequisition={pr} className='pull-right'/>
            <div className='clearfix' />
        </>
    );
};

export default PurchaseRequisitionHeader;
