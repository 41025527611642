import React, { useState, useCallback, useContext } from 'react';
import _ from 'lodash';
import { Button } from "react-bootstrap";
import {
    convertSmallestUnitToDisplayUnit,
    convertToSmallestUnit,
    getUnitsArray
} from "../../../../utils/modelUtils/almazen/unitsUtility";
import {NotifierContext} from "../../../../utils/Notifier";
import '../../../../assets/sass/gle/_InventoryMinMax.scss';
import useBoolean from '../../../../hooks/useBoolean';


const InventoryMinMax = ({api, inventory, info}) => {
    const notifier = useContext(NotifierContext);
    const [edit,,, toggleEdit] = useBoolean(info);
    const [minQuantity, setMinQuantity] = useState(()=>{
        return inventory.minStockAmount?
            convertSmallestUnitToDisplayUnit(inventory.minStockAmount, inventory.productBrand.product): null
    });
    const [minUnit, setMinUnit] = useState(inventory.productBrand?.product?.displayUnit?.id);
    const [maxQuantity, setMaxQuantity] = useState(()=>{
        return inventory.minStockAmount?
            convertSmallestUnitToDisplayUnit(inventory.maxStockAmount, inventory.productBrand.product): null
    });
    const [maxUnit, setMaxUnit] = useState(inventory.productBrand?.product?.displayUnit?.id);

    const saveMaxMin = useCallback(() => {
        if(!minQuantity || !maxQuantity || !minUnit || !maxUnit || !Number(minQuantity) || !Number(maxQuantity))
            return notifier.error("Llena todos los campos para guardar la información");

        const minUnitObject = _.find(getUnitsArray(inventory.productBrand.product), obj => obj.id === minUnit);
        const maxUnitObject = _.find(getUnitsArray(inventory.productBrand.product), obj => obj.id === maxUnit);
        const minStockAmount = convertToSmallestUnit(minQuantity, minUnitObject, inventory.productBrand.product);
        const maxStockAmount = convertToSmallestUnit(maxQuantity, maxUnitObject, inventory.productBrand.product);

        if(minStockAmount >= maxStockAmount)
            return notifier.error("El mínimo de inventario debe ser menor que el máximo.");

        const data = {minStockAmount: minStockAmount+'', maxStockAmount: maxStockAmount+''};
        api.inventories.update({id: inventory.id, params: data})
            .then(()=>notifier.success("Máximo y mínimo guardado correctamente"));
        toggleEdit()
    },[api, notifier, inventory, minQuantity, minUnit, maxQuantity, maxUnit, toggleEdit]);

    const displayMinQuantity = () => {
        if (minUnit !== inventory.productBrand.product.displayUnit.id) {
            if (maxQuantity !== null && inventory) {
                return Number(convertSmallestUnitToDisplayUnit(minQuantity, inventory.productBrand.product)).toFixed(1);
            }
        } else {
            return Number(minQuantity).toFixed(1);
        }
    };

    const displayMaxQuantity = () => {
        if (maxUnit !== inventory.productBrand.product.displayUnit.id) {
            if (maxQuantity !== null && inventory) {
                return Number(convertSmallestUnitToDisplayUnit(maxQuantity, inventory.productBrand.product)).toFixed(1);
            }
        } else {
            return Number(maxQuantity).toFixed(1);
        }
    };

    const quantitySetter = (e, func) => {
        if (e.target.value !== '')
            return func(e.target.value);
    };
    return (
        <div className={"InventoryMinMax"}>
            <div className="container-box">
                <div className="min-box">
                    <h5>Cantidad mínima del producto</h5>
                    <div className="input-box">
                        <div className={"quantity-show info-box " + (!edit && 'display-none')}>
                            <p>{displayMinQuantity()}</p>
                        </div>
                        <div className={(edit && 'display-none') + ' unit-show-input'}>
                            <label htmlFor="number">Cantidad</label>
                            <input onChange={event => quantitySetter(event, setMinQuantity)}
                                className='input-number'
                                placeholder='Ingresa una cantidad'
                                value={minQuantity||''}
                                type="number"
                                min='0'
                                name="number"
                                id="number"/>
                        </div>
                        <div className={"unit-show info-box " + (!edit && 'display-none')}>
                            <p>{inventory.productBrand.product.displayUnit.name}</p>
                        </div>
                        <div className={(edit && 'display-none')}>
                            <label htmlFor="select">Unidad:</label>
                            <select id="select" value={minUnit} className='form-control select-box' onChange={e=> setMinUnit(e.target.value)}>
                                <option value="default">Selecciona una opción</option>
                                { getUnitsArray(inventory.productBrand.product).map( unit =>
                                    <option key={unit.id} value={unit.id}>{unit.name}</option>
                                ) }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="min-box">
                    <h5>Cantidad máxima del producto</h5>
                    <div className="input-box">
                        <div className={"quantity-show info-box " + (!edit && 'display-none')}>
                            <p>{displayMaxQuantity()}</p>
                        </div>
                        <div className={(edit && 'display-none') + ' unit-show-input'}>
                            <label htmlFor="number">Cantidad</label>
                            <input onChange={event => quantitySetter(event, setMaxQuantity)}
                                className='input-number' placeholder='Ingresa una cantidad'
                                type="number"
                                value={maxQuantity||''}
                                name="number"
                                min='0'
                                id="number"/>
                        </div>
                        <div className={"unit-show info-box " + (!edit && 'display-none')}>
                            <p>{inventory.productBrand.product.displayUnit.name}</p>
                        </div>
                        <div className={(edit && 'display-none')}>
                            <label htmlFor="select">Unidad:</label>
                            <select id="select" value={maxUnit} className='form-control select-box' onChange={e=> setMaxUnit(e.target.value)}>
                                <option value="default">Selecciona una opción</option>
                                { getUnitsArray(inventory.productBrand.product).map( unit =>
                                    <option key={unit.id} value={unit.id}>{unit.name}</option>
                                ) }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="min-box button-box">
                    {edit && <Button type="submit" className='edit-button' onClick={() => {toggleEdit()}}><i className="fa fa-pencil"/>Editar</Button>}
                    {!edit && <Button type="submit" className='save-button' onClick={saveMaxMin}><i className="fa fa-save"/>Guardar</Button>}
                </div>
            </div>
        </div>
    );
}

export default InventoryMinMax;
