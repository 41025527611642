//Image Cropper by Mark Ehrlich

export default class ImageCropper {

    imageLoaded = false;

    setImage( image ){

        const that = this;
        return new Promise((resolve, reject)=>{
                that.image = new Image();
                that.image.src = image;
                that.image.onload = ()=>{ that.imageLoaded=true; resolve(that); };
                that.image.onerror = reject;
            }
        );

    }

    getSquare( ){

         if( !this.imageLoaded )
             throw new Error("Tried to crop image before loading it.");

         return new Promise( (resolve)=> {

             const {naturalWidth: width, naturalHeight: height} = this.image;

             const smallSide = width < height ? width : height;
             const canvas = window.document.createElement('canvas');
             canvas.width = smallSide;
             canvas.height = smallSide;

             const context = canvas.getContext("2d");
             context.drawImage(this.image, (width - smallSide) / 2, (height - smallSide) / 2, smallSide, smallSide, 0, 0, smallSide, smallSide);


             canvas.toBlob(( blob )=>{
                 resolve(blob);
             },'image/jpeg', 0.9);

         });



    }
}