import React, {useState, useRef, useEffect} from 'react';

const steps = 50;
const animateScrollTo = async (scroll, position) => {
    const currentPosition = scroll.scrollLeft;
    const stepSize = (position - currentPosition) / steps;
    for (let i = 0; i < steps; i++) {
        scroll.scrollLeft += stepSize;
        await new Promise(res => setTimeout(res, 3));
    }
    scroll.scrollLeft = position;
};

const ScrollBoxes = ({elements, visible, info, picked, setPicked}) => {
    const [scrolling, setScrolling] = useState(false);
    const [reload, setReload] = useState(true);
    const timeoutId = useRef(null);

    const size = elements.length;
    const ghostBoxes = Math.floor(size / 2);
    const boxStyle = {width: (100 / visible) + '%'};

    const scrollContainer = useRef(null);
    const scrollElement = useRef(null);
    const firstScroll = useRef(true);
    useEffect(() => {
        animateScrollTo(scrollContainer.current, scrollElement.current.offsetWidth * picked);
    }, [picked, reload]);

    useEffect(()=>{
        if(!scrolling && !firstScroll.current) {
            const boxWidth = scrollElement.current.offsetWidth;
            const toPick = Math.floor((scrollContainer.current.scrollLeft + (boxWidth / 2)) / boxWidth);
            setPicked(toPick);
            setReload(r=>!r);
        }
        else if(!scrolling){
            firstScroll.current = false;
        }
    }, [scrolling, setPicked]);
    const onScroll = () => {
        setScrolling(true);
        if(timeoutId.current){
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(() => {
            setScrolling(false);
            timeoutId.current = null;
        }, 250);
    };
    return (
        <div onScroll={onScroll} ref={scrollContainer} className='stats-container'>
            {[...new Array(ghostBoxes)].map((v, k) => <div style={boxStyle} key={k} className='stat-container'/>)}
            {elements.map((e, k) => (
                <div key={k} ref={scrollElement} onClick={() => setPicked(k)}
                     className={'stat-container ' + (picked === k ? 'selected' : '')} style={boxStyle}>
                    <h3>{e.getNumber(info)}</h3>
                    <p>{e.headerTitle || e.title}</p>
                </div>
            ))}
            {[...new Array(ghostBoxes)].map((v, k) => <div style={boxStyle} key={k} className='stat-container'/>)}
        </div>
    );
};

export default ScrollBoxes;
