import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const StockMovementBatchesNameAndTooltip=({products, stockMovementBatches, movementType})=>{

    if(stockMovementBatches){
        products = stockMovementBatches.reduce( (acc, batch)=>{
            if(!movementType || !batch.inOrOut || movementType===batch.inOrOut)
                return [...acc, ...batch.movements.map(mov=>mov.productBrand?.product?.name||'')];
            return acc;
        },[] );
    }

    if(!products || !products.length )
        return <span>Sin productos</span>;

    const firstName = products.length>0?products[0]:'Sin productos';

    if(products.length < 2)
        return <span>{firstName}</span>;

    return (
        <OverlayTrigger placement="top" overlay={
            <Tooltip id='purchase-requisition-products-tooltip'>
                <ul>
                    {products.map((m, i) => <li key={i}>{m}</li>)}
                </ul>
            </Tooltip>
        }>
            <span>{firstName} y {products.length-1} más</span>
        </OverlayTrigger>
    );
};

export default StockMovementBatchesNameAndTooltip;
