import React from 'react';
import {Tooltip} from "react-bootstrap";


const PurchaseProductProvidersTooltip = (purchaseProducts) => {


    const providers = [];
    purchaseProducts.forEach((pp)=>{
        if (!providers.includes(pp.selectedPurchaseProductQuote.provider.name))
            providers.push(pp.selectedPurchaseProductQuote.provider.name);
    });

    return (
        <Tooltip id='purchase-requisition-providers-tooltip'>
            <ul>
                {providers.map((p,i) => (
                    <li key={i}>{p}</li>
                ))}
            </ul>
        </Tooltip>);
};

export default PurchaseProductProvidersTooltip;
