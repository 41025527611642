import React, {useState} from 'react';
import SingleChatBar from "./components/SingleChatBar";
import {connect} from 'react-redux';

const Footer=({assignedChats, style})=>{
    return (
        <footer className="Footer" style={style}>
            {assignedChats.map(chat =>
                <SingleChatBar key={chat.id} chat={chat}/>
            )}
        </footer>
    );
};

const mapStateToProps = ({api: {assignedChats = []}}) => ({assignedChats});

const ConnectedFooter = connect(mapStateToProps)(Footer);

export default () => {
    const [marginLeft, setMarginLeft] = useState(0);
    const move = direction => () => {
        const step = 10;
        setMarginLeft(step*direction + marginLeft);
    };
    const moveLeft = move(1);
    const moveRight = move(-1);
    return (
        <div className="FooterContainer footer footer-transparent">
            <button className="chat-nav-button back" onClick={moveLeft}><i className="fa fa-arrow-left"/></button>
            <ConnectedFooter style={{marginLeft: marginLeft+"px"}}/>
            <button className="chat-nav-button forward" onClick={moveRight}><i className="fa fa-arrow-right"/></button>
        </div>
    );
}
