import React, {useContext, useMemo} from 'react';
import './_TableConfigurator.scss';
import {getColumnId, getSelectableColumns} from "./tableConfiguratorUtils";
import Switch from "react-bootstrap-switch";
import _ from 'lodash';
import {SecurityContext} from "../../utils/SecurityManager";

const TableConfigurator = ({ onClose, availableColumns, activeColumns, onActiveColumnsChange }) => {

    const securityManager = useContext(SecurityContext);
    const columns = useMemo(()=>getSelectableColumns({availableColumns, securityManager}),
        [availableColumns, securityManager]);

    const isColumnActive = ( column )=>{
        return !!_.find(activeColumns, col=>getColumnId(col)===getColumnId(column));
    };

    const handleColumnChange = (column, isActive)=>{
        if( isColumnActive(column) === isActive )
            return;
        let newColumns = [...activeColumns];
        if( !isActive ){
            newColumns = _.filter( newColumns, activeCol=>getColumnId(activeCol)!==getColumnId(column) );
        }
        else {
            newColumns.push(column);
        }
        //Make a filter of available columns to keep column order
        const newActive = _.filter( availableColumns, (available)=>{
            return _.find( newColumns, newCol=>getColumnId(available)===getColumnId(newCol) );
        });

        onActiveColumnsChange(newActive);
    }

    return (
        <div className={"TableConfigurator"}>
            <button onClick={onClose} className='button-clear-def pull-right'>X</button>
            <div className='clearfix' />
            <div className='container-fluid'>
                {_.chunk(columns, 6).map((chunk, i)=>
                    <div className='row' key={i}>
                        {chunk.map(column=>
                            <div className={'col-md-2 col-sm-4 col-xs-6 column-block'} key={getColumnId(column)}>
                                <div className='column-block-inner'>
                                    <Switch
                                        onChange={(s, value)=>handleColumnChange(column, value)}
                                        value={isColumnActive(column)} />{' '}
                                    {column.Header}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TableConfigurator;
