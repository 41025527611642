import React, {useState, useContext} from 'react';
import {Modal, ModalTitle, ModalBody, ModalHeader, FormControl, FormGroup, ControlLabel, Button} from "react-bootstrap";
import {ApiContext} from "../../../api/Api";
import DropFile from "../utils/DropFile";

const EditMedalModal = ({medal, onHide}) => {
    const {name, image, points, description} = medal;
    const [editedName, setEditedName] = useState(name);
    const [editedImage, setEditedImage] = useState(null);
    const [editedPoints, setEditedPoints] = useState(points);
    const [editedDescription, setEditedDescription] = useState(description);
    const api = useContext(ApiContext);

    const onDrop = (files) => {
        if (files[0]) {
            setEditedImage(files[0]);
        }
    };
    const editProduct = () => {
        api.recognitions.edit({
            id: medal.id,
            params: {
                name: editedName,
                points: editedPoints,
                description: editedDescription
            },
            ...(editedImage ? {files: {image: editedImage}} : {})
        });
        onHide();
    };
    return (
        <Modal onHide={onHide} show>
            <div className='RewardsModal'>
                <ModalHeader closeButton>
                    <ModalTitle>Edición de medalla: {name}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <ControlLabel>Nombre: </ControlLabel>
                        <FormControl type='text' value={editedName} onChange={e => setEditedName(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Costo en puntos: </ControlLabel>
                        <FormControl min={1} type="number" value={editedPoints}
                                     onChange={e => setEditedPoints(parseInt(e.target.value))}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Descripción: </ControlLabel>
                        <FormControl componentClass="textarea" value={editedDescription}
                                     onChange={e => setEditedDescription(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Imagen:</ControlLabel>
                        {image ? <img src={api.appFiles.getUrl(image.id)} alt='Imagen a editar'/> :
                            <p>No hay imagen para esta medalla</p>}
                        <DropFile onDrop={onDrop}
                                  text={editedImage ? editedImage.name : 'Da click aquí o arrastra la nueva imagen'}
                                  textStyle={image ? {color: '#000000'} : {}}
                        />
                    </FormGroup>
                    <Button bsStyle='success' onClick={editProduct}>
                        Guardar edición de medalla
                    </Button>
                </ModalBody>
            </div>
        </Modal>
    );
};
export default EditMedalModal;
