import React, {Component} from 'react';
import LoaderIcon from './LoaderIcon';

export default class LoadingNotification extends Component{

    render(){
        return (
            <div className="loading-notification">
                <LoaderIcon/> Cargando...
            </div>
        );
    }
}