import React, {useContext, useCallback} from 'react';
import {DropdownButton, MenuItem} from "react-bootstrap";
import {SecurityContext} from "../../../utils/SecurityManager";
import {ApiContext} from "../../../api/Api";
import {connect} from 'react-redux';
import {paymentConditions, paymentMethods, poTrans} from "../../../utils/modelUtils/jazz/purchaseOrderUtils";

const loadingMethodId='@POPaymentMethodSelector.purchaseOrder.edit-method';
const loadingWayId='@POPaymentMethodSelector.purchaseOrder.edit-way';

const POPaymentMethodSelector = ({purchaseOrder, loadingIds, className="",customProp='purchaseOrders', requestParameters={}})=>{

    const security = useContext(SecurityContext);
    const api = useContext(ApiContext);
    const po = purchaseOrder;

    const onChangeMethod = useCallback((paymentMethod) => {
        if(paymentMethod!==po.paymentMethod)
            api.purchaseOrders.edit({id: po.id, purchaseOrder: { paymentMethod, ...requestParameters}, loadingId:loadingMethodId, customProp});

    }, [api, po, customProp, requestParameters]);

    const onChangeWay = useCallback((paymentWay) => {
        if(paymentWay!==po.paymentWay)
            api.purchaseOrders.edit({id: po.id, purchaseOrder: {paymentWay, ...requestParameters}, loadingId:loadingWayId, customProp});

    }, [api, po, customProp, requestParameters]);

    return (
        <div className={"POPaymentMethodSelector "+ className}>
            {security.jazz.canUpdateOrderPaymentMethod() ?
                <>
                    <DropdownButton
                        id='method'
                        title={loadingIds[loadingMethodId] ?
                            'Cambiando...' :
                            (poTrans(po.paymentMethod)||'Sin método de pago')
                        }
                        bsStyle='primary'
                        className='btn-fill payment-method-btn'
                        onSelect={onChangeMethod}
                    >
                        {paymentMethods.map((method)=>
                            <MenuItem key={method} eventKey={method} >{poTrans(method)}</MenuItem>
                        )}
                    </DropdownButton>

                    <DropdownButton
                        id='way'
                        title={loadingIds[loadingWayId] ?
                            'Cambiando...' :
                            (poTrans(po.paymentWay)||'Sin forma de pago')
                        }
                        bsStyle='primary'
                        className='btn-fill payment-way-btn'
                        onSelect={onChangeWay}
                    >
                        {paymentConditions.map((condition)=>
                            <MenuItem key={condition} eventKey={condition} >{poTrans(condition)}</MenuItem>
                        )}
                    </DropdownButton>
                </>
                :
                <>
                <span className={'alert alert-primary payment-method-btn'}>{poTrans(po.paymentMethod)||'Sin método de pago'}</span>
                <span className={'alert alert-primary payment-way-btn'}>{poTrans(po.paymentWay)||'Sin forma de pago'}</span>
                </>
            }
        </div>
    );
};

const mapStateToProps = ({loadingIds})=>({loadingIds});

export default connect(mapStateToProps)(POPaymentMethodSelector);
