import React, { useContext, useState } from 'react';
import {ApiContext} from "../../../api/Api";
import ReactTable from 'react-table';
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import {connect} from "react-redux";
import {employeeFullname} from "../../../api/Api";
import moment from 'moment';
import {Modal} from "react-bootstrap";
import IncidenceJustificationDetail from './IncidenceJustificationDetail';
import {NotifierContext} from "../../../utils/Notifier";


const tableStructure = [
    {
        Header: 'Employee',
        accessor: (appliedIncidence) => employeeFullname(appliedIncidence.employee),
        id: 'employee.name'
    },
    {
        Header: 'Incidencia',
        accessor: (appliedIncidence) => appliedIncidence.incidence.name,
        filterable: false,
        id: 'incidence.name'
    },
    {
        Header: 'Fecha de incidencia',
        accessor: (appliedIncidence) => moment(appliedIncidence.shiftDate).format("DD/MM/YYYY"),
        filterable: false,
        id: 'shiftDate'
    }
];

const loadingId = "@AppliedIncidences.get";

const AppliedIncidencesListModal = ({appliedIncidences, appliedIncidencesMeta, loadingIds, filters, onHide }) => {

    const {totalItems, itemsPerPage} = appliedIncidencesMeta;
    const plural = totalItems > 1 ? "s" : "";

    const [justificationDetail, setJustificationDetail] = useState(false);
    const api = useContext(ApiContext);
    const notifier = useContext( NotifierContext );

    const loadData = (tableState) => {

        const sort = tableState.sorted.reduce((acc, val) => {
            acc[`order[${val.id}]`] = val.desc ? "DESC" : "ASC";
            return acc;
        }, {});

        const newFilters = tableState.filtered.reduce( (acc, val)=>{acc[val.id] = val.value; return acc;}, {} );

        if( newFilters['employee.name'] ) {
            newFilters.search = newFilters['employee.name'];
            delete filters['employee.name'];
        }

        api.appliedIncidences.get(tableState.page, tableState.pageSize, loadingId, {...filters, ...newFilters, ...sort});
    };

    return (
        <>
            <Modal show={true} onHide={onHide} bsSize='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Lista de incidencias</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <ReactTable
                    data={appliedIncidences}
                    columns={tableStructure}
                    defaultPageSize={10}
                    pages={Math.ceil(totalItems / itemsPerPage)}
                    className="-striped -highlight"
                    loading={loadingIds[loadingId]}
                    manual
                    filterable
                    onFetchData={loadData}
                    getTdProps={(state, row) => ({onClick: () =>{
                        const justification = row.original.justification;
                        if( !justification)
                            return notifier.info("La incidencia no fue justificada");

                        justification.appliedIncidences = [ row.original ];
                        setJustificationDetail( justification );
                        }
                    })}
                    minRows={3}
                    {...ReactTableTranslations}
                />
                <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>
                </Modal.Body>
            </Modal>

            {justificationDetail &&
                <IncidenceJustificationDetail
                    incidenceJustification={justificationDetail}
                    onHide={()=>setJustificationDetail(false)}
                    urlCreator={ ( appFile )=>api.appFiles.getUrl(appFile.id) }
                />
            }

        </>
    );
};

const mapStateToProps = ({
                             api: {
                                 appliedIncidences = [], appliedIncidencesMeta = {
                                     totalItems: 0,
                                     itemsPerPage: 0
                                 }
                             }, loadingIds
                         }) => ({appliedIncidences, appliedIncidencesMeta, loadingIds});

export default connect(mapStateToProps)(AppliedIncidencesListModal);
