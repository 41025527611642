import React, {useContext} from 'react';
import {NotifierContext} from '../../../utils/Notifier';
import {Button, Modal, ModalHeader, ModalTitle, ModalBody} from 'react-bootstrap';

const CUModal = ({validation, files, onDelete, initialValues, onSubmit, state, children, onHide, title, submitText}) => {
    const notifier = useContext(NotifierContext);
    const addAction = () => {
        if(validation){
            const errors = validation(state);
            if(errors.length!==0){
                notifier.error(<ul>{errors.map((e, k)=><li key={k}>{e}</li>)}</ul>);
                return;
            }
        }
        onSubmit(state, files);
        onHide();
    };
    const deleteAction = () => {
        onDelete(initialValues&&initialValues.id);
        onHide();
    };

    return (
        <Modal show onHide={onHide}>
            <ModalHeader closeButton>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                {children}
                <div>
                    <Button onClick={addAction}>
                        {submitText}
                    </Button>
                    {onDelete&&
                     <Button onClick={deleteAction} bsClass='btn btn-danger pull-right'>
                         Eliminar
                     </Button>
                    }
                </div>
            </ModalBody>
        </Modal>
    );
};

export default CUModal;
