import React, {useCallback, useContext} from 'react';
import EntitySelect from "../../../../components/EntitySelect/EntitySelect";
import _ from "lodash";
import {NotifierContext} from "../../../../utils/Notifier";
import {emptyProductBrand, emptyProductBrandUnit} from "../../../../utils/modelUtils/almazen/productsUtils";
import useCallbackCreator from "../../../../hooks/useCallbackCreator";


const ProductBrandsForm = ({productBrands, onChange})=>{

    const notifier=useContext(NotifierContext);

    const handleBrandAdd=useCallback((brand)=>{

        const addedBrand = _.find(productBrands, pb=>pb.brand.id===brand.id);

        if(addedBrand)
            return notifier.error('La marca ya ha sido agregada');
        onChange([...productBrands, emptyProductBrand(brand)]);

    },[productBrands, notifier, onChange]);

    const removeProductBrandCreator=useCallbackCreator((index)=>{

        const newProductBrands=[...productBrands];
        newProductBrands.splice(index, 1);
        onChange(newProductBrands);

    },[productBrands, onChange]);

    const handleAddProductBrandUnitCreator=useCallbackCreator((index)=>{

        const newProductBrands=[...productBrands];
        newProductBrands[index].productBrandUnits = [...(newProductBrands[index].productBrandUnits||[]), emptyProductBrandUnit()];
        onChange(newProductBrands);

    },[productBrands, onChange]);

    const handleRemoveProductBrandUnitCreator=useCallbackCreator((id)=>{

        const [pbIndex, pbuIndex] = id.split('-');
        const newProductBrands=[...productBrands];
        newProductBrands[pbIndex].productBrandUnits = [...newProductBrands[pbIndex].productBrandUnits];
        newProductBrands[pbIndex].productBrandUnits.splice(pbuIndex, 1);
        onChange(newProductBrands);

    },[productBrands, onChange]);

    const handleEditProductBrandUnitCreator=useCallbackCreator((id, val)=>{

        const [pbIndex, pbuIndex, prop] = id.split('-');
        const newProductBrands=[...productBrands];
        newProductBrands[pbIndex].productBrandUnits = [...newProductBrands[pbIndex].productBrandUnits];

        const newPBU = {...newProductBrands[pbIndex].productBrandUnits[pbuIndex]};
        newPBU[prop] = val && val.target? val.target.value:val;
        newProductBrands[pbIndex].productBrandUnits[pbuIndex] = newPBU;
        onChange(newProductBrands);

    },[productBrands, onChange]);

    return (
        <div>
            {productBrands && productBrands.map( (productBrand, index)=>
                <div className='brand-box' key={productBrand.id||productBrand.uid}>
                    <h5>{productBrand.brand.name}</h5>
                    <p>Presentaciones:</p>
                    {productBrand.productBrandUnits && productBrand.productBrandUnits.map( (pbu, pbuIndex)=>
                        <React.Fragment key={pbu.id||pbu.uid}>
                            <div className='row'>
                                <div className='col-xs-12 col-md-5'>
                                    <p>Unidad</p>
                                    <EntitySelect
                                        entity='measurementUnits'
                                        value={pbu.unit}
                                        onChange={handleEditProductBrandUnitCreator(index+'-'+pbuIndex+'-unit')}
                                        creatable
                                    />
                                </div>
                                <div className='col-xs-12 col-md-5'>
                                    <p>Código de barras</p>
                                    <input
                                        value={pbu.barcode||''}
                                        onChange={handleEditProductBrandUnitCreator(index+'-'+pbuIndex+'-barcode')}
                                        className='form-control'
                                        placeholder='Escanea o escribe el código'/>
                                </div>
                                <div className='col-xs-12 col-md-2 remove-unit-cont'>
                                    <span className='link red remove-unit' onClick={handleRemoveProductBrandUnitCreator(index+'-'+pbuIndex)}>Quitar</span>
                                </div>
                            </div>
                            <hr/>
                        </React.Fragment>
                    )}
                    <div className='add-unit link'>
                        <span className='link' onClick={handleAddProductBrandUnitCreator(index)}>+ Añadir presentación</span>
                    </div>
                    <span className='remove-brand red-icon' onClick={removeProductBrandCreator(index)} >
                                <i className='fa fa-trash'/>
                            </span>
                </div>
            )}

            <div className='brand-box add-brand'>
                <EntitySelect
                    entity='brands'
                    onChange={handleBrandAdd}
                    placeholder='Agregar marca...'
                    creatable={true}
                />
            </div>
        </div>
    )
};

export default ProductBrandsForm;
