import React, {useState, useContext, useCallback} from 'react';
import ReactTable from 'react-table';
import Button from '../../../elements/CustomButton/CustomButton';
import useTideTable from '../../../hooks/table/useTideTable';
import {listSGroups, periodicStockRequestsListColumns} from '../../../utils/modelUtils/almazen/periodicStockRequestUtils';
import {SecurityContext} from "../../../utils/SecurityManager";
import PeriodicStockFormModal from './PeriodicStockFormModal';
import useBoolean from "../../../hooks/useBoolean";

const requestFilters = {sGroups: listSGroups};

const PeriodicStockRequestsAdmin = () => {
	const security = useContext(SecurityContext);

	const periodicReqTable = useTideTable({
		entity: 'periodicStockRequests',
		columns: periodicStockRequestsListColumns,
		requestFilters,
		newTideApi: true
	});

	const [creating, startCreating, stopCreating] = useBoolean();
	const [editing, setEditing] = useState(null);

	const closeModal = useCallback(()=>{
		setEditing(null);
		stopCreating();
	},[stopCreating]);

	return (
		<div className="PeriodicStockRequestsAdmin main-content">
			{security.almazen.canCreatePeriodicStockRequest()&&(
				<Button bsStyle="success" onClick={startCreating}>Crear nueva requisición automática</Button>
			)}
			<ReactTable
				{...periodicReqTable.tableProps}
				getTrProps={(_,{original}={})=>({onClick:()=>setEditing(original)})}
			/>

			{(creating || editing)&&
			<PeriodicStockFormModal
				onHide={closeModal}
				periodicStockRequest={editing}
			/>}

		</div>
	);
};

export default PeriodicStockRequestsAdmin;
