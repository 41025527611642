import React, {useCallback, useContext} from 'react';
import './_RecipeEditorImage.scss';
import {TideApiContext} from "../../../../../../api/tideApiConfig";
import placeholder from '../../assets/egg-placeholder.png';
import classNames from 'classnames';
import {isImage} from "../../../../../../utils/fileUtils";
import {NotifierContext} from "../../../../../../utils/Notifier";
import {orozcoProductRecipeEditorSGroups} from "../../../../../../utils/modelUtils/orozco/orozcoProductUtils";
import {orozcoRecipeEditorSGroups} from "../../../../../../utils/modelUtils/orozco/orozcoRecipeUtils";
import {SecurityContext} from "../../../../../../utils/SecurityManager";


const RecipeEditorImage = ({orozcoProduct, recipe, onChange}) => {

    const api = useContext(TideApiContext);
    const security = useContext(SecurityContext);
    const notifier = useContext(NotifierContext);

    let image;
    if(orozcoProduct?.productPhoto)
        image = api.appFiles.getUrl(orozcoProduct.productPhoto.id)
    else if(recipe?.photo)
        image = api.appFiles.getUrl(recipe.photo.id)

    const handleImageChange = useCallback((e)=>{
        if(!e?.target?.files?.length)
            return;

        const file = e.target.files[0];
        if(!isImage(file.name))
            return notifier.error('Debes seleccionar un archivo de tipo imagen');

        if(orozcoProduct)
            api.orozcoProducts.update({id:orozcoProduct.id, params:{sGroups: orozcoProductRecipeEditorSGroups}, files:[file]})
                .then(onChange);
        else if(recipe)
            api.orozcoRecipes.update({id:recipe.id, params:{sGroups: orozcoRecipeEditorSGroups}, files:[file]})
                .then(onChange);

    },[api, orozcoProduct, recipe, notifier, onChange]);

    return (
        <div className={classNames("RecipeEditorImage", !image&&'no-image')}>
            <div className='image-container' style={{backgroundImage:`url("${image||placeholder}")`}}>
                <img src={image||placeholder} alt='Platillo' className='print-only print-photo' />
                {security.katharsis.canEditRecipes() &&
                    <>
                        <div className='click-overlay'>
                            Click para cambiar
                        </div>
                        <input type='file' className='file-input' onChange={handleImageChange} key={Math.random()}/>
                    </>}
            </div>
        </div>
    );
};

export default RecipeEditorImage;
