import React, {Component} from 'react';
import ReactTable from 'react-table';
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import _ from 'lodash';
import { employeeFullname } from '../../../api/Api';
import moment from 'moment';
import CustomButton from "../../../elements/CustomButton/CustomButton";
import NewTicketModal from "./components/NewTicketModal";
import TicketDetailModal from "./components/TicketDetailModal";


class TicketList extends Component{

    static contextTypes = { api:PropTypes.object, notifier:PropTypes.object };

    loadingId = "@Class.TicketList.tickets.get";

    constructor( props, context ){
        super(props);
        this.state = {};

        this.loadTickets = _.debounce( this.loadTickets.bind(this), 700 );

        this.dataApi = context.api.tickets;
        context.api.ticketTypes.get(0, 200);
    }

    loadTickets( tableState ){

        this.tableState = tableState;

        const sort = tableState.sorted.reduce( (acc, val)=>{acc[`order[${val.id}]`] = val.desc?"DESC":"ASC"; return acc;}, {} );
        const filter = tableState.filtered.reduce( (acc, val)=>{acc[val.id] = val.value; return acc;}, {} );

        this.dataApi.get(tableState.page , tableState.pageSize, this.loadingId, {...sort, ...filter});
    }

    closeDetail = ()=>{
        this.setState({ticketDetail:false})
    };

    createTicket = ()=>{
        this.setState( {creatingTicket:true} );
    };

    closeCreatingTicket = ()=>{
        this.setState( {creatingTicket:false} );
    };

    tableStructure = [{
        Header: 'Tipo',
        accessor: 'ticketType.name'
    }, {
        Header: 'Creado por',
        accessor: (ticket)=> ticket.createdBy? employeeFullname( ticket.createdBy.employee ):'',
        id: 'createdBy.employee.name',
    },  {
        Header: 'Fecha de creación',
        accessor: (ticket)=> moment(ticket.createdDate).format( 'DD/MM/YYYY HH:mm' ),
        id: 'createdDate',
        filterable: false,
    }, {
        Header: 'Fecha límite',
        accessor: (ticket)=> moment(ticket.limitResolutionDate).format( 'DD/MM/YYYY HH:mm' ),
        id: 'limitResolutionDate',
        filterable: false,
    }, {
        Header: 'Status',
        accessor: (ticket)=>ticketStatusStrings(ticket.status),
        id:'status'
    }];

    render() {

        const { totalItems, itemsPerPage } = this.props.listMeta;
        const plural = totalItems>1?"s":"";

        return (
            <div className="container-fluid main-container">

                <CustomButton bsStyle={'primary'} className={'col-xs-12'} onClick={this.createTicket}> Crear nuevo ticket </CustomButton>
                <div className='clearfix'/>
                <ReactTable
                    data={this.props.list}
                    columns = {this.tableStructure}
                    defaultPageSize= {10}
                    pages= { Math.ceil( totalItems / itemsPerPage ) }
                    className="-striped -highlight"
                    loading={!!this.props.loadingIds[this.loadingId]}
                    manual
                    filterable
                    onFetchData={this.loadTickets}
                    getTdProps={(state, rowInfo)=>({onClick:()=>this.setState({ticketDetail:rowInfo.original})})}
                    minRows={3}
                    {...ReactTableTranslations}
                />
                <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>

                {this.state.creatingTicket?
                    <NewTicketModal
                        onHide={this.closeCreatingTicket}
                    />
                    :null}

                {this.state.ticketDetail?
                    <TicketDetailModal ticket={this.state.ticketDetail} onHide={this.closeDetail} />
                    :null}

            </div>
        );
    }
}


const ticketStatusStrings = ( status )=>{
    switch (status) {
        case 'open':
            return 'Abierto';
        case 'canceled':
            return 'Cancelado';
        case 'closed':
            return 'Cerrado';
        default:
            return status;
    }
};

function mapStateToProps( {api:{ tickets=[], ticketsMeta={totalItems:0,itemsPerPage:0} }, loadingIds } ) {

    return { list:tickets, listMeta:ticketsMeta, loadingIds }
}

export default connect(mapStateToProps)(TicketList);