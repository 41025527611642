import React, {Component} from 'react';
import {Modal, Table} from 'react-bootstrap';
import moment from 'moment';
import Button from "../../../../elements/CustomButton/CustomButton";

export default class PayrollFileModal extends Component {

    constructor(props){
        super(props);

        this.state = {};
    }

    render() {

        const assRep = this.props.assistanceReport;

        return (
            <div>

                <Modal
                    show={true}
                    onHide={this.props.onHide}
                >
                    <Modal.Header closeButton >
                        <Modal.Title>Archivo {assRep.file.originalName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table striped>
                            <tbody>
                            <tr>
                                <td>Archivo subido el:</td>
                                <td>{moment(assRep.createdDate).format("DD/MM/YYYY")}</td>
                            </tr>
                            <tr>
                                <td>Inicio de registros:</td>
                                <td>{moment(assRep.fromDate).format("DD/MM/YYYY")}</td>
                            </tr>
                            <tr>
                                <td>Fin de registros:</td>
                                <td>{moment(assRep.toDate).format("DD/MM/YYYY")}</td>
                            </tr>
                            <tr>
                                <td>Subido por:</td>
                                <td>{getFullName(assRep.createdBy.employee)}</td>
                            </tr>
                            <tr>
                                <td>Registros encontrados:</td>
                                <td>{assRep.registersNum}</td>
                            </tr>
                            <tr>
                                <td>Registros insertados al sistema:</td>
                                <td>{assRep.insertedRegistersNum}</td>
                            </tr>

                            </tbody>
                        </Table>
                        <a href={this.props.urlCreator(assRep.file, true)}>Descargar archivo</a>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="danger" onClick={()=>this.setState({wannaDelete:true})} >Eliminar registros</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.wannaDelete}
                    onHide={()=>this.setState({wannaDelete:false})}
                >
                    <Modal.Header closeButton >
                        <Modal.Title>Elimina registros</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <p>Vas a eliminar los registros de asistencia generados por el reloj checador de {assRep.clock.name}.<br/>
                            Para hacerlo no debe haber incidencias generadas a partir de estos registros.
                        </p>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="danger" onClick={this.props.onAssistanceReportDelete} >Sí, eliminarlos todos</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const getFullName = (employee)=>(employee.name+" "+employee.patLastName+" "+employee.matLastName);