import React from 'react';
import "./_GolfCartPassengerInput.scss";
import {FormControl} from "react-bootstrap";
import TideEntitySelect from "../../../../../../components/TideEntitySelect/TideEntitySelect";

const visitFilters = {
    sGroups: ["visit_read", "visit_read_guest", "guest_read", "guest_read_full_name"],
    'activePersons[gt]': 0
}

const GolfCartTripPassengerInput = ({passenger, onVisitChange, onPersonsNumberChange, index, onRemove}) => {
    return (
        <div className={"GolfCartPassengerInput"}>

            {index!==0 &&
            <button className='remove-button' onClick={()=>onRemove(index)}>
                <i className={"fa fa-trash"}/>
            </button>}

            <label  className="control-label">Huésped <span className="small text-muted">opcional</span></label>
            <TideEntitySelect
                entity={"visits"}
                filterBy='search'
                value={passenger.visit}
                onChange={(value)=>onVisitChange(index, value)}
                additionalFilters={visitFilters}
                labelCreator={visit => visit?.guest?.fullName}
                placeholder={'Escribe para buscar un huésped...'}
            />
            <br/>
            <label className="control-label">Personas <span className="small text-muted">Titular más acompañantes</span></label>
            <FormControl
                min={1}
                type="number"
                value={passenger.personsNumber ? passenger.personsNumber : 1}
                placeholder={"Total de personas"}
                onChange={e => onPersonsNumberChange(index, parseInt(e.target.value))}
            />
            <br/>
        </div>
    );
};

export default GolfCartTripPassengerInput;
