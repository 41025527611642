import React, {useState, useMemo} from 'react';
import CustomCheckbox from '../../../../elements/CustomCheckbox/CustomCheckbox';

export const useShiftFilter = () => {
    const [shift, setShift] = useState(undefined);
    const filterProps = {
        shift,
        setShift
    };
    const requestParams = useMemo(()=>({
        shift
    }), [shift]);
    return {
        requestParams,
        filterProps
    };
};

const ShiftFilter = ({shift, setShift, label}) => {
    return (
        <div className='row'>
            <div className='col-md-3'>
                <label>{label||'Turno:'}</label>
            </div>
            <div className='col-md-8 pull-right'>
                <CustomCheckbox
                    label='Matutino'
                    inline
                    isChecked={shift==='morning'}
                    onChange={()=>shift==='morning'?setShift(undefined):setShift('morning')}
                />
                <CustomCheckbox
                    label='Vespertino'
                    inline
                    isChecked={shift==='afternoon'}
                    onChange={()=>shift==='afternoon'?setShift(undefined):setShift('afternoon')}
                />
                <CustomCheckbox
                    label='Nocturno'
                    inline
                    isChecked={shift==='night'}
                    onChange={()=>shift==='night'?setShift(undefined):setShift('night')}
                />
            </div>
        </div>
    );
};

export default ShiftFilter;
