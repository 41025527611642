import React from 'react';
import moment from "moment";
import {reportHeaders} from '../../xlsxReports';
import {guestFullName} from "./guestUtils";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import {Link} from "react-router-dom";

const statusSGroups = [
    'creation_date',
    'visit_read_guest',
    'visit_read_folio',
    'guest_read_full_name',
    'visit_read_lounge',
    'place_read_name',
    'visit_read_check_ins',
    'check_in_read_lounge_access_method',
    'lounge_access_method_read_name'
];

export const vipSGroups = [
    ...statusSGroups,
    'guest_read_vip_reason'
];

const vipProperties = [
    {
        header: 'Fecha de visita',
        property: 'createdDate'
    },
    {
        header: 'Folio de visita',
        property: 'folio'
    },
    {
        header: 'Huésped',
        property: 'guest.fullName'
    },
    {
        header: 'Sala',
        property: 'lounge.name'
    },
    {
        header: 'Método de acceso',
        property: 'checkIns.0.loungeAccessMethod.name'
    }
];

const vipP = [
    ...vipProperties,
    {
        header: 'Razón',
        property: 'guest.vipReason'
    }
];

const vipHeaders = reportHeaders(vipP);

export const xlsVipConfig = {
    name: 'vip.xlsx',
    headers: vipHeaders
};

/////////////////////////

export const nonGrataSGroups = [
    ...statusSGroups,
    'guest_read_non_grata_reason'
];

const nonGrataProperties = [
    ...vipProperties,
    {
        header: 'Razón',
        property: 'guest.nonGrataReason'
    }
];

const nonGrataHeaders = reportHeaders(nonGrataProperties);

export const xlsNonGrataConfig = {
    name: 'nongrata.xlsx',
    headers: nonGrataHeaders
};

/////////////////////////

export const guestSGroups = [
    ...vipSGroups,
    'guest_read_age',
    'visit_read_flight',
    'flight_read_number',
    'flight_read_destination',
    'flight_read_airline',
    'airline_read_name',
    'visit_read_created_date_shift'
];

const guestProperties = [
    {
        header: 'Fecha',
        property: 'createdDate'
    },
    {
        header: 'Huésped',
        property: 'guest.fullName'
    },
    {
        header: 'Acceso',
        property: 'checkIns.0.loungeAccessMethod.name'
    },
    {
        header: 'Número de vuelo',
        property: 'flight.number'
    },
    {
        header: 'Destino',
        property: 'flight.destination'
    },
    {
        header: 'Aerolínea',
        property: 'flight.airline.name'
    },
    {
        header: 'Edad',
        property: 'guest.age'
    },
    {
        header: 'Sala',
        property: 'lounge.name'
    },
    {
        header: 'Turno',
        property: 'createdDateShift'
    }
];

const guestHeaders = reportHeaders(guestProperties);

export const xlsGuestConfig = {
    name: 'huespedes.xlsx',
    headers: guestHeaders
};


export const reCheckInsSGroups = [
    'creation_date',
    'visit_read_guest',
    'guest_read_full_name',
    'visit_read_re_check_ins_count',
    'visit_read_re_check_ins_access_methods_string'
];

const reCheckInsProperties = [
    {
        header: 'Fecha de visita',
        property: 'createdDate'
    },
    {
        header: 'Huésped',
        property: 'guest.fullName'
    },
    {
        header: 'Re-CheckIns en la visita',
        property: 'reCheckInsCount'
    },
    {
        header: 'Tipo de método de acceso',
        property: 'reCheckInsAccessMethodsString'
    }
];

const reCheckInsHeaders = reportHeaders(reCheckInsProperties);

export const xlsReCheckInsConfig = {
    name: 'recheckins.xlsx',
    headers: reCheckInsHeaders
};


export const activeVisitsSGroups=[
    'visit_read',
    'creation_date',
    'visit_read_guest',
        'guest_read_name',
        'guest_read_pat_last_name',
        'guest_read_mat_last_name',
    'visit_read_lounge',
        'lounge_read_id',
        'lounge_read_name',
    'visit_read_filled_questionnaire'
];

export const activeVisitsTableColumns=[
    {
        Header:'Huésped',
        id:'guest.name',
        accessor:(v)=>guestFullName(v && v.guest)
    },
    {
        Header:'Entrada',
        id:'createdDate',
        filterable: false,
        accessor:(v)=>v && v.createdDate? moment(v.createdDate).format('HH:mm DD/MM/YYYY'):'-'
    },
    {
        Header:'Sala',
        accessor:'lounge.name'
    },
    {
        Header:'G-Care',
        filterable: false,
        id:'filledQuestionnaire',
        accessor:(v)=>v.filledQuestionnaire?
            'Realizado':
            <Link to={'/ee/questionnaires/gcare/'+v.id}><CustomButton bsStyle='primary'>Realizar G-Care</CustomButton></Link>
    }
];

export const visitListSGroups = [
    'visit_read_id',
    'visit_read_created_date',
    'visit_read_folio',
    'visit_read_lounge',
        'lounge_read_name',
    'visit_read_guest',
        'guest_read_full_name',

];

export const visitDetailSGroups = [
    'visit_read',
    'visit_read_created_by',
    'visit_read_service_visits',
        'service_visit_read_id',
        'service_visit_read_service',
        'service_read_name',
    'creation_date',
    'visit_read_lounge',
        'lounge_read',
    'visit_read_guest',
        'guest_read',
    'visit_read_check_ins',
        'check_in_read',
        'check_in_read_lounge_access_method',
            'lounge_access_method_read',
            'lounge_access_method_read_lounge_parent_access_methods',
    'visit_read_flight',
        'flight_read',
    'visit_read_orozco_visit_order',
        'orozco_visit_read',
        'orozco_visit_order_read_orozco_visit_order_products',
        'orozco_visit_order_product_read',
        'orozco_visit_order_product_read_orozco_product',
            'orozco_product_read',
        'orozco_visit_order_read_scheduled_service',
        'orozco_visit_order_read_orozco_visit_order_payments',
            'orozco_payment_read',
                'orozco_visit_order_payment_read',
                'orozco_payment_read_orozco_payment_method' ,
                'orozco_payment_method_read',
    'visit_read_employees_attending_visit',
        'employee_attending_visit_read_employee',
            'employee_read_full_name',
        'user_read_employee',
    'visit_read_cassette',
    'cassette_read_id',
];
