import React from 'react';
import './_KatharsisReports.scss';
import OrozcoProductCostReport from "./reports/OrozcoProductCostReport";
import InventoryInputReport from "./reports/InventoryInputReport";
import OrozcoProductCategoriesCostReport from "./reports/OrozcoProductCategoriesCostReport";
import RequisitionsReport from "./reports/RequisitionsReport";
import OrozcoProductGuestsReport from "./reports/OrozcoProductGuestsReport";
import OrozcoProductDecreaseReport from "./reports/OrozcoProductDecreaseReport";
import OrozcoProductTotalCostReport from "./reports/OrozcoProductTotalCostReport";

const KatharsisReports = () => {
    return (
        <div className={"KatharsisReports"}>
            <h2 className='text-center'>Reportes</h2>
            <div className='card container-fluid'>
                <div className='row'>
                    <div className='col-12 col-sm-6'>
                        <OrozcoProductCostReport />
                    </div>
                    <div className='col-12 col-sm-6'>
                        <InventoryInputReport />
                    </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-12 col-sm-6'>
                        <OrozcoProductCategoriesCostReport />
                    </div>
                    <div className='col-12 col-sm-6'>
                        <OrozcoProductGuestsReport/>
                    </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-12 col-sm-6'>
                        <OrozcoProductDecreaseReport />
                    </div>
                    <div className='col-12 col-sm-6'>
                        <OrozcoProductTotalCostReport/>
                    </div>

                </div>
		<br/>
                <div className='row'>
                    <div className='col-12 col-sm-6'>
                        <RequisitionsReport />
                    </div>
		</div>
                <br/>
            </div>
        </div>
    );
};

export default KatharsisReports;
