import React, {useCallback, useContext, useEffect, useState} from 'react';
import './_WarehousesConfiguration.scss';
import {TideApiContext} from "../../../api/tideApiConfig";
import {useSelector} from "react-redux";
import SingleWarehouseConfig from "./components/SingleWarehouseConfig/SingleWarehouseConfig";
import {warehouseConfigurationSGroups} from "../../../utils/modelUtils/almazen/warehouseUtils";
import Switch from "react-bootstrap-switch";
import {ApiContext} from "../../../api/Api";
import {SecurityContext} from "../../../utils/SecurityManager";
import {NotifierContext} from "../../../utils/Notifier";

const CREATE_REQUISITIONS_WHEN_EXISTS_UNCLOSED_REQUISITIONS = 'CREATE_REQUISITIONS_WHEN_EXISTS_UNCLOSED_REQUISITIONS'

const WarehousesConfiguration = () => {
    const api = useContext(TideApiContext);
    const oldApi = useContext(ApiContext);
    const security = useContext(SecurityContext);
    const notifier = useContext(NotifierContext);
    const [requisitionsCreationConfig, setRequisitionsCreationConfig] = useState();

    const loadConfiguration = useCallback(()=>{
        if(!security.almazen.canEditConfigurationOfCreateNewRequest())
            return;
        oldApi.configurations.getSingle({id: CREATE_REQUISITIONS_WHEN_EXISTS_UNCLOSED_REQUISITIONS})
            .then((configuration) => setRequisitionsCreationConfig(configuration.value === '1'));
    },[oldApi, security]);

    useEffect(() => {
        api.warehouses.get({params: {pagination: false, sGroups: warehouseConfigurationSGroups}});
        loadConfiguration();
    }, [api, loadConfiguration]);

    const changeRequisitionsCreationConfig = (_, value) => {
        setRequisitionsCreationConfig(value);
        oldApi.configurations.batchEdit({configurations: [
                {
                    id: CREATE_REQUISITIONS_WHEN_EXISTS_UNCLOSED_REQUISITIONS,
                    value: value ? "1" : "0"
                }
            ]}).then(()=>notifier.success('Configuración guardada'));
    };

    const warehouses = useSelector(s => s.api.warehouses) || [];

    return (
        <div className={"WarehousesConfiguration container-fluid main-container card"}>
            {warehouses.map(wh => <SingleWarehouseConfig key={wh.id} warehouse={wh}/>)}
            {security.almazen.canEditConfigurationOfCreateNewRequest() ?
                <div className='config-editor'>
                    <table className='table table-responsive'>
                        <thead>
                        <td>Configuración</td>
                        <td>Valor</td>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Bloquear la creación de nuevas requisiciones si hay alguna requisición sin cerrar</td>
                            <td><Switch value={requisitionsCreationConfig} onChange={changeRequisitionsCreationConfig}/></td>
                        </tr>
                        </tbody>
                    </table>

                </div> : null}
        </div>
    );
};

export default WarehousesConfiguration;
