import {useState, useMemo} from 'react';
import moment from "moment";


const useDatePickerState = (initialValue=moment())=>{

    const [date, setDate]=useState(initialValue);
    const [focused, onFocusChange]=useState({focused: false});

    const id = useMemo(()=>Math.random()+'', []);

    return useMemo(()=>({
        props:{
            date,
            onDateChange:setDate,
            focused: focused.focused,
            onFocusChange,
            id
        },
        date
    }),[date, focused, id]);
};

export default useDatePickerState;
