import React from 'react';

const PurchaseRequisitionTableHeader = ( {hasDiscount} )=>{

    return (
        <thead>
        <tr>
            <th className='text-center'>!</th>
            <th className='text-center'>Tipo</th>
            <th className='text-center' style={{maxWidth:38}}>De línea</th>
            <th className='text-left'>Producto / Servicio</th>
            <th className='text-center col-xs-1'>Cantidad</th>
            <th className='text-center col-xs-2'>Proveedor</th>
            <th className='text-center col-xs-1'>Precio unitario</th>
            <th className='text-center'>Subtotal</th>
            <th className='text-center col-xs-1'>Descuento unitario</th>
            {hasDiscount? <th className='text-center'>Descuento total</th> :null}
            {hasDiscount? <th className='text-center'>Subtotal con descuento</th> :null}
            <th className='text-center'>Impuestos</th>
            <th className='text-center'>Total</th>
        </tr>
        </thead>
    );
};

export default React.memo(PurchaseRequisitionTableHeader);
