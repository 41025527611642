import React,{useState,useCallback,useContext} from 'react';
import ClassicModal from "../../../../components/Modals/ClassicModal";
import {useSelector} from "react-redux";
import _ from 'lodash';
import DateTime from 'react-datetime';
import {ApiContext} from "../../../../api/Api";
import {NotifierContext} from "../../../../utils/Notifier";
import moment from 'moment';


const EmployeeWorkToolsAssignerModal=({onHide, employees, onSave})=>{

    const workTools=useSelector(({api})=>api.workTools||[]);
    const [expiration, setExpiration]=useState(null);

    const [selectedWorkTool, setSelectedWorkTool]=useState('');
    const handleSelectedWorkToolChange=useCallback(({target})=>{
        const workTool=_.find(workTools, wt=>wt.id===Number(target.value));
        setSelectedWorkTool(workTool);
        if(workTool.lifeDays)
            setExpiration(moment().add(workTool.lifeDays,'days'));
    },[workTools]);

    const api=useContext(ApiContext);
    const notifier=useContext(NotifierContext);

    const saveAssignation=useCallback(()=>{
        if(!employees || !employees.length)
            return notifier.error('Ningún colaborador seleccionado');
        if(!selectedWorkTool)
            return notifier.error('Debes seleccionar una herramienta');

        api.employeeWorkTools.batchAssignation({
            params: {
                employees: employees.map(e => e.id),
                workTool: selectedWorkTool.id,
                expirationDate: expiration? expiration.format('YYYY-MM-DD'):null
            }
        })
            .then(()=>{
                if(onSave)
                    onSave();
            });

    },[api,notifier, expiration, selectedWorkTool, employees, onSave]);

    return (
        <ClassicModal
            onHide={onHide}
            title={'Asignación de herramientas de trabajo'}
            onSave={saveAssignation}
        >
            Selecciona la herramienta a asignar:
            <select
                className='form-control'
                value={selectedWorkTool && selectedWorkTool.id}
                onChange={handleSelectedWorkToolChange}
            >
                <option value=''>Selecciona una herramienta</option>
                {workTools.map(wt=>
                    <option key={wt.id} value={wt.id}>{wt.name}</option>
                )}
            </select>
            <hr/>
            Selecciona la fecha de expiración de las herramientas asignadas:
            <DateTime
                timeFormat={false}
                dateFormat={'DD/MM/YYYY'}
                inputProps={{placeholder:'Sin expiración'}}
                value={expiration}
                onChange={setExpiration}
            />
            <hr/>
            Se asignará a las siguientes personas:
            <ul>
                {employees.map(e=><li key={e.id}>{e.fullName}</li>)}
            </ul>

        </ClassicModal>
    )
};

export default EmployeeWorkToolsAssignerModal;
