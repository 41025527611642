import React, {useContext, useState} from 'react';
import {NotifierContext} from "../../../utils/Notifier";

const ReportBox = ({title, description, valid, reportUrl, children}) => {
    const notifier = useContext(NotifierContext);
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = ()=>setIsOpen(o=>!o);
    const handleClick = e=>{
	if(!valid){
	    e.preventDefault();
	    notifier.error("Llena todos los filtros");
	}
    };
    return (
	<div className={"report-block "+(isOpen?'block-open':'')}>
            <p className='title' onClick={toggleOpen}>{title}<i className='fa fa-caret-down caret-icon'/></p>
	    {isOpen&&(
		<div className="body">
		    <hr/>
		    <div className="container-fluid">
			<div className="row">
			    <div className="col-md-6">
				{children}
			    </div>
			    <div className="col-md-6">
				<p className='filter-label'>Descripción:</p>
				<p className='description'>{description}</p>
			    </div>
			</div>
		    </div>
		    <br/>
                    <a onClick={handleClick} href={reportUrl} rel='noopener noreferrer'>
			<button className='btn btn-info'>Descargar</button>
                    </a>
		</div>
	    )}
	</div>
    );
};

export default ReportBox;
