import React, {useState, useMemo, useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import {ApiContext} from '../../../api/Api';
import CrudPage from './CrudPage';
import AddDishModal from './AddDishModal';
import EditDishModal from './EditDishModal';
import defaultDishImage from '../../../assets/img/default-orozco-product.png';
import {Button} from 'react-bootstrap';
import {listSGroups as sGroups} from '../../../utils/modelUtils/orozco/orozcoProductUtils';
import moneyFormatter from "../../../utils/moneyFormatter";
import useBoolean from "../../../hooks/useBoolean";

const PhotoCell = ({original}) => {
    const api = useContext(ApiContext);
    const productPhoto = original.productPhoto?
          api.appFiles.getUrl(original.productPhoto.id):
          defaultDishImage;
    const style={backgroundImage: `url(${productPhoto})`};
    return (
        <div className='product-picture' style={style}/>
    );
};

const List = ({items}) => (
    <ul>
        {items.map(i=><li key={i.id}>{i.name}</li>)}
    </ul>
);

const AvailableCell = ({original}) => {
    const available = original.isAvailable;
    const api = useContext(ApiContext);
    const [loading, setLoading] = useState(false);
    const toggleAvailability = async e => {
        e.stopPropagation();
        setLoading(true);
        try{
            await api.orozcoProducts.edit({
                id: original.id,
                properties: {isAvailable: !available, sGroups}
            });
        } finally {
            setLoading(false);
        }

    };
    if(loading){
        return null;
    }
    return (
         <Button onClick={toggleAvailability} bsClass={available?'btn btn-success':'btn btn-error'}>{available?'Habilitado':'Deshabilitado'}</Button>
    );
};

const priceToItem = ({amount, currency})=>
      ({id: currency, name: `${moneyFormatter(amount)} ${currency}`});

const tideTableConfig = {
    entity: 'orozcoProducts',
    requestFilters: {
        sGroups
    },
    columns: [
        {
            Header: 'Platillo',
            accessor: 'name'
        },
        {
            Header: 'Foto',
            filterable: false,
            Cell: PhotoCell
        },
        {
            Header: 'Precio',
            filterable: false,
            Cell: ({original})=><List items={
                (original.prices||[]).map(priceToItem)}/>
        },
        {
            Header: 'Modificadores default',
            filterable: false,
            Cell: ({original})=><List items={
                original.orozcoProductModifiers||[]
            }/>
        },
        {
            Header: 'Categorías',
            Cell: ({original})=><List items={original.orozcoProductCategories||[]}/>,
            id: 'orozcoProductCategories.name'
        },
        {
            Header: 'Salas',
            Cell: ({original})=><List items={original.lounges||[]}/>,
            id: 'lounges.name'
        },
        {
            Header: 'Disponible',
            id: 'isAvailable',
            Cell: AvailableCell,
            filterable: false
        },

    ]
};

const DishAdmin = () => {
    const api = useContext(ApiContext);

    useEffect(()=>{
        api.lounges.get2({filters:{isLounge: true}});
        api.orozcoProductCategories.get();
    }, [api]);

    const lounges = useSelector(({api: {lounges=[]}})=>lounges);
    const dishCategories = useSelector(({api: {orozcoProductCategories=[]}})=>orozcoProductCategories);

    const [showDisabled,,,toggleShowDisabled] = useBoolean(true);

    const tableConfig = useMemo(()=>({...tideTableConfig, requestFilters: {...tideTableConfig.requestFilters, 'isAvailable': showDisabled || null}}), [showDisabled]);

    const serverData = useMemo(()=>({
        lounges,
        dishCategories
    }), [lounges, dishCategories]);

    return (
        <div className='DishAdmin'>
            <CrudPage
                addText='Agregar platillo'
                header = {<Button className='pull-right' onClick={toggleShowDisabled}>{showDisabled ? "Mostrar deshabilitados" : "Ocultar deshabilitados"}</Button>}
                title='Platillos'
                tideTableConfig={tableConfig}
                AddModal={AddDishModal}
                EditModal={EditDishModal}
                propsForModals={serverData}
            />
        </div>
    );
};

export default DishAdmin;
