import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import {getTotalTaxes} from "../../../../utils/modelUtils/jazz/purchaseRequisitionUtils";
import moneyFormatter from "../../../../utils/moneyFormatter";
import ProductTaxesDetail from "../../../almazen/components/ProductTaxesDetail";
import useBoolean from '../../../../hooks/useBoolean';


const PurchaseProductTaxes = ( {purchaseProductTaxes, subtotal, currency, editable, onChange} )=>{

    //[ value, setTrue, setFalse, toggleValue, setValue ]
    const [ detailVisible, showDetail, hideDetail ] = useBoolean();

    if( !purchaseProductTaxes )
        return null;

    const tooltip = (
        <Tooltip id='taxes'>
            { purchaseProductTaxes.length ?
                purchaseProductTaxes.map((pTax, i) =>
                    <React.Fragment key={pTax.id || i}>
                        <span>{pTax.tax.name} ${Number(pTax.total)}</span><br/>
                    </React.Fragment>
                )
                :
                "Sin impuestos"
            }
        </Tooltip>);

    const totalTaxes = getTotalTaxes( purchaseProductTaxes, subtotal );

    return (
        <>
            <OverlayTrigger placement="top" overlay={tooltip}>
                <CustomButton bsStyle='primary'
                        onClick={showDetail }>{ moneyFormatter( totalTaxes, 1, currency ) }</CustomButton>
            </OverlayTrigger>

            {detailVisible?
                <ProductTaxesDetail
                    onHide={hideDetail}
                    editable={ editable }
                    productTaxes={ purchaseProductTaxes }
                    onChange={ onChange }
                    subtotal={subtotal}
                />
                :null}

        </>
    );
};

export default PurchaseProductTaxes;
