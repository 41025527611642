import React, {useCallback, useContext, useEffect, useState} from 'react';
import './_RecipeHeader.scss';
import RecipeEditorImage from "../RecipeEditorImage/RecipeEditorImage";
import useBoolean from "../../../../../../hooks/useBoolean";
import RecipeFormModal from "../RecipeFormModal/RecipeFormModal";
import {useParams, useHistory} from "react-router-dom";
import {displayAmount} from "../../../../../../utils/modelUtils/almazen/unitsUtility";
import moneyFormatter from "../../../../../../utils/moneyFormatter";
import classNames from 'classnames';
import {SecurityContext} from "../../../../../../utils/SecurityManager";
import ConfirmModal from "../../../../../../components/Modals/ConfirmModal";
import {TideApiContext} from "../../../../../../api/tideApiConfig";
import {NotifierContext} from "../../../../../../utils/Notifier";
import moment from "moment";

const RecipeHeader = ({recipe, orozcoProduct, onChange, showImage=true, showPrices}) => {
    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);
    const {type, id} = useParams();
    const history = useHistory();
    const [approvingRecipe, setApprovingRecipe] = useState(false);
    const security = useContext(SecurityContext);
    const loadingId = 'RecipeHeader.RecipeHeader';

    const afterApprove = useCallback(() => {
        setApprovingRecipe(false);
        notifier.success('La receta ha sido aprobada.');
        onChange(recipe);
    }, [notifier, onChange, recipe]);

    const saveApproval = useCallback(()=>{
        const params = {
            approvedBy: security.me.id,
            approvedDate: moment().toDate()
        };
        api.orozcoRecipes['update']({ id:recipe?.id, params: params, loadingId })
            .then(afterApprove);

    },[api, afterApprove, recipe, security]);

    // Edition modal state
    const [editingHeader, startEditing, stopEditing] = useBoolean();
    const closeEditionModal = useCallback(()=>{
        if(recipe)
            stopEditing();
        if(!recipe)
            history.push('/katharsis/products');
    },[recipe, history, stopEditing]);

    const handleChange = useCallback((recipe)=>{
        stopEditing();
        onChange(recipe);
    },[stopEditing, onChange]);

    const handleApproveRecipe = useCallback((recipe) => {
        setApprovingRecipe(false);
        saveApproval();
    }, [saveApproval]);

    useEffect(()=>{
        if(type==="product" && orozcoProduct && !orozcoProduct.orozcoRecipe )
            startEditing();
        if(id==='new')
            startEditing();
    },[startEditing, orozcoProduct, id, type]);

    const recipeType = (orozcoProduct || recipe?.orozcoProduct)?'Platillo':'Sub receta';

    return (
        <div className={classNames("RecipeHeader", !showImage&&'no-image')}>
            <div className='left'>
                <table className='table'>
                    <tbody>
                    <tr><td>Nombre:</td><td>{recipe?.name}</td></tr>
                    <tr><td>Rendimiento:</td><td>{recipeType==='Platillo'?'1 Platillo':displayAmount(recipe?.quantity, recipe)||'No configurado'}</td></tr>
                    <tr><td>Tipo:</td><td>{recipeType}</td></tr>
                    <tr><td>Clasificación:</td><td>{recipe?.classification || 'Sin clasificación'}</td></tr>
                    <tr><td>Tiempo de preparación:</td>
                        <td>{recipe?.elaborationMinutes?`${recipe.elaborationMinutes} minutos`:`Sin definir`}</td>
                    </tr>
                    {showPrices &&
                    <tr><td>Costo:</td><td><b>{moneyFormatter(recipe?.estimatedCost||0, 1)}</b></td></tr>}

                    {recipeType!=='Platillo' && recipe?.smallestUnit && <tr>
                        <td>Costo unidad mínima:</td>
                        <td>
                            <b>{moneyFormatter((recipe?.estimatedCost||0)/(recipe?.quantity||1), 1)} x {recipe?.smallestUnit?.name}</b>
                        </td>
                    </tr>}
                    {orozcoProduct&&<tr><td>Consumo por cada 100 huéspedes:</td><td><b>{Math.ceil(orozcoProduct.consumedAverageByGuestInPeriod*100)}</b></td></tr>}
                    {orozcoProduct&&<tr><td>Modificadores requeridos del platillo:</td><td><b>{Math.ceil(orozcoProduct.requiredModifier)||'-'}</b></td></tr>}

                    <tr>
                        <td>
                            Aprobado por:
                        </td>
                        <td>
                            { recipe?.approvedBy &&
                                recipe?.approvedBy?.employee?.name} -
                            {recipe?.approvedBy &&
                                moment(recipe?.approvedDate).format('DD/MM/YYYY HH:mm')}
                        </td>
                    </tr>

                    </tbody>
                </table>
                { security.katharsis.canApproveRecipes() && recipe &&
                    <button className='approve-btn' onClick={() => setApprovingRecipe(true)}>
                        <i className='fa fa-check link'/>
                    </button>}
                {security.katharsis.canEditRecipes() &&
                    <button className='edit-btn' onClick={startEditing}>
                        <i className='fa fa-pencil link'/>
                    </button>}
            </div>
            {showImage &&
            <div className='right'>
                <RecipeEditorImage orozcoProduct={orozcoProduct} recipe={recipe} onChange={handleChange}/>
            </div>}

            {editingHeader && <RecipeFormModal
                onHide={closeEditionModal}
                orozcoProduct={orozcoProduct}
                recipe={recipe}
                afterSave={handleChange}
            />}

            {approvingRecipe && <ConfirmModal
                    className={"RecipeFormModal"}
                    title={'Aprobación de receta'}
                    message={'¿Está seguro de que desea aprobar la receta?'}
                    onCancel={() => setApprovingRecipe(false)}
                    onConfirm={handleApproveRecipe}
                />}
        </div>
    );
};

export default RecipeHeader;
