import React, {useContext, useEffect, useCallback} from 'react';
import {Col, Row} from "react-bootstrap";
import {ApiContext} from "../../../api/Api";
import {useSelector} from "react-redux";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import LoaderIcon from "../../../components/Helper/LoaderIcon";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";

const pageSize=24;
const loadingId='@NotificationsList.notifications.get';
const NotificationsList = ({history})=>{

    const api=useContext(ApiContext);

    const loadNotifications = useCallback((page)=>{
        api.notifications.getHeaderNotifications({page, pageSize, append: page!==0, loadingId});
    }, [api]);

    useEffect(()=>{
        loadNotifications(0);
    },[loadNotifications]);

    const notifications = useSelector((state)=>state.api.notifications)||[];
    const {totalItems, currentPage} = useSelector(({api})=>api.notificationsMeta)||{totalItems:0, currentPage:0};
    const loading = useSelector(({loadingIds})=>loadingIds[loadingId]);

    const clickNotificationHandler = useCallbackCreator((index)=>{
        const notification = notifications[index];
        history.push(notification.link);

        api.notifications.markAsRead({id:notification.id, customProp: index<11? 'notificationsHeader':'notifications'});
        //notificationsHeader is the custom prop used by the badge in the app header
        //This header only shows 11 notifications, so if we want to mark it as read there without making a new request
        //we should use the notificationsHeader custom prop to modify it's redux state

    },[notifications, history]);

    return (
        <div className='container-fluid NotificationsList'>
            <Row>
                <Col md={8} mdPush={2} className='notifications-list-container'>
                    <InfiniteScroll
                        loadMore={loadNotifications}
                        hasMore={ currentPage*pageSize<totalItems && !loading }
                        loader={<div className="loader" key={0}> <LoaderIcon /> Cargando más ...</div>}
                        initialLoad={false}
                    >
                        <ul className='list'>
                            {notifications.map((notification, i)=>
                                <li
                                    key={notification.id}
                                    className={'list-item '+(notification.readDate?'':'unread')}
                                    onClick={clickNotificationHandler(i)}
                                >
                                    <i className={getNotificationIcon(notification)+' notification-icon'}/>
                                    <div dangerouslySetInnerHTML={{__html:notification.html}}/>
                                    <p className='notification-time'>
                                        <i className='fa fa-clock-o'/>
                                        <span className='time-string'>{moment(notification.createdDate).format("dddd D [de] MMMM [del] YYYY [a las] HH:mm")}</span>
                                    </p>
                                </li>
                            )}
                        </ul>
                    </InfiniteScroll>
                    {!notifications.length && !loading?
                        <p>Aún no tienes ninguna notificación</p>
                        :null}
                </Col>
            </Row>
        </div>
    );
};

export default NotificationsList;

const getNotificationIcon = (notification)=>{
    if(!notification || !notification.notificationTopic)
        return 'fa fa-bell-o';

    return ({

        'ALMA_REPOSITORY_FILE_UPLOADED':'fa fa-file-o',

        'JAZZ_PURCHASE_REQUISITION_COMMENT_CREATED':'fa fa-commenting-o',
        'JAZZ_PURCHASE_REQUISITION_APPROVED':'fa fa-check',
        'JAZZ_PURCHASE_REQUISITION_DENIED':'fa fa-times',
        'JAZZ_PURCHASE_REQUISITION_APPROVAL_PENDING':'fa fa-question-circle-o',

    })[notification.notificationTopic.name]||'fa fa-bell-o';
};
