import React, { useContext, useEffect, useState } from 'react';
import {connect} from 'react-redux';
import Card from '../../../components/Card/Card';
import ReactTable from 'react-table';
import Button from "../../../elements/CustomButton/CustomButton";
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import {ApiContext} from "../../../api/Api";
import PositionFormModal from './components/PositionFormModal';


const loadingId = "@StaffTable.positions.get";

const StaffTable  = ( { positions, positionsMeta, loadingIds } )=>{


    const api = useContext( ApiContext );
    const [ creating, setCreating ] = useState( false );
    const [ editing, setEditing ] = useState( false );

    useEffect( ()=>{
        api.positions.get({ isActive: 1 }, loadingId);
    }, [api] );


    const onSave = ( position )=>{

        if( editing ) {
            api.positions.edit(editing.id, position);
            setEditing( false );
        }
        else {
            api.positions.create(position);
            setCreating( false );
        }

    };

    const onDelete = ()=>{
        if( !editing )
            return;

        api.positions.delete( editing.id );
        setEditing( false );
    };

    const { totalItems } = positionsMeta || { totalItems:0 } ;
    const plural = totalItems>1?"s":"";

    return (
        <div className="container-fluid main-container StaffTable">

            <Card
                content={(
                    <div>

                        <ReactTable
                            columns={ tableDefinition }
                            data = { positions }
                            minRows={3}
                            pageSizeOptions={ [10,20,50,100,200] }
                            sortable
                            className="-striped -highlight"
                            loading={!!loadingIds[loadingId]}
                            getTdProps={(state, rowInfo)=>({onClick:()=> setEditing(rowInfo.original)})}
                            {...ReactTableTranslations}

                        />

                        <p className="total-count pull-left">{totalItems} registro{plural} encontrado{plural}</p>
                        <Button bsStyle='primary' className='pull-right new-requisition' onClick={()=>setCreating({creating:true})}>Crear nuevo puesto</Button>
                        <div className='clearfix' />
                    </div>
                )} />

            { creating || editing?
                <PositionFormModal
                    onHide={()=> editing? setEditing(false): setCreating(false)}
                    onSave={onSave}
                    editing={ !!editing }
                    prefill={ editing? editing : undefined }
                    onDelete={onDelete}
                />
                :null
            }

        </div>
    );
};

const mapStateToProps = ( {api: { positions = [], positionsMeta }, loadingIds} )=>({ positions, positionsMeta, loadingIds});


export default connect(mapStateToProps)(StaffTable);

const tableDefinition = [ {
    Header: 'Puesto',
    accessor: 'name',
},{
    Header: 'Autorizados',
    accessor: (pos)=>pos.vacancies === null? 'Sin información':pos.vacancies ,
    id: 'vacancies',
}, {
    Header: 'Contratados',
    accessor: 'occupiedVacancies',
}, {
    Header: 'Vacantes',
    accessor: ( pos ) => pos.vacancies === null? '-' : (pos.vacancies - pos.occupiedVacancies),
    id: 'vacantes',
}];
