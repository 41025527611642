
export const emptyWorkTool=()=>({
    name:'',
    employeePrice:0,
    providerPrice:0,
    lifeDays:0
});

export const prepareWorkToolForServer=(workTool)=>{

    const prepared={...workTool};

    if(!workTool.name || !workTool.name.trim())
        return {error:true, message:'Ingresa un nombre válido'};

    if(isNaN(prepared.employeePrice))
        prepared.employeePrice=0;
    else
        prepared.employeePrice=Number(prepared.employeePrice);
    if(isNaN(prepared.providerPrice))
        prepared.providerPrice=0;
    else
        prepared.providerPrice=Number(prepared.providerPrice);
    if(isNaN(prepared.lifeDays))
        prepared.lifeDays=0;
    else
        prepared.lifeDays=Number(prepared.lifeDays);

    return prepared;
};
