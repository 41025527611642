import React, {useCallback, useContext, useState} from 'react';
import './_LoungeWarehousesTable.scss';
import EntitySelect from "../../../../../components/EntitySelect/EntitySelect";
import {employeeFullName} from "../../../../../utils/modelUtils/alma/employeeUtils";
import moment from "moment";
import {warehouseConfigurationSGroups} from "../../../../../utils/modelUtils/almazen/warehouseUtils";
import useCallbackCreator from "../../../../../hooks/useCallbackCreator";
import _ from "lodash";
import {TideApiContext} from "../../../../../api/tideApiConfig";
import {NotifierContext} from "../../../../../utils/Notifier";

const filters = {isActive: true, isLounge: true};

const LoungeWarehousesTable = ({warehouse: wh}) => {

    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);

    const [addingLounge, setAddingLounge] = useState();
    const handleAddLounge = useCallback(()=>{
        if(!addingLounge)
            return;

        const lounges = wh.loungeWarehouses.map( lw=>lw.lounge.id );
        if(lounges.indexOf(addingLounge.id) !== -1){
            notifier.info("Esta sala ya está asignada a este almacén");
            return;
        }
        const loungeWarehouses = wh.loungeWarehouses.map( lw=>lw.id );
        loungeWarehouses.push({lounge:addingLounge.id});
        api.warehouses.update({id:wh.id, params:{ loungeWarehouses, sGroups: warehouseConfigurationSGroups}});
        setAddingLounge(null);
    },[api, wh, notifier, addingLounge]);

    const handleRemoveLounge = useCallbackCreator((id)=>{
        const loungeWarehouses = wh.loungeWarehouses.map( lw=>lw.id );
        _.pull(loungeWarehouses, id);
        api.warehouses.update({id:wh.id, params:{ loungeWarehouses, sGroups: warehouseConfigurationSGroups}});
    },[wh]);


    return (
        <div className={"LoungeWarehousesTable"}>
            <div className='container-fluid p-header'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <p className='perm-title'>Salas asignadas</p>
                    </div>
                    <div className='col-sm-6'>
                        <div className='select-cont'>
                            <EntitySelect
                                className='new-employee-select'
                                placeholder='Agregar sala a almacén'
                                entity='lounges'
                                getMethod='get2'
                                labelCreator={employeeFullName}
                                value={addingLounge}
                                onChange={setAddingLounge}
                                additionalFilters={filters}
                                initialLoad
                            />
                            <button
                                className='btn btn-info'
                                onClick={handleAddLounge}
                                disabled={!addingLounge}>
                                Agregar sala
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <table className='table table-striped'>
                <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Asignado por</th>
                    <th>Desde</th>
                    <th>Acción</th>
                </tr>
                </thead>
                <tbody>
                {wh.loungeWarehouses?.map(loungeWarehouse=>
                    <tr key={loungeWarehouse.id}>
                        <td>{loungeWarehouse.lounge.name}</td>
                        <td>{employeeFullName(loungeWarehouse.createdBy.employee)}</td>
                        <td>{moment(loungeWarehouse.createdDate).format('DD/MM/YYYY')}</td>
                        <td><i className='fa fa-trash red-icon' onClick={handleRemoveLounge(loungeWarehouse.id)}/></td>
                    </tr>)}
                </tbody>
            </table>
            {!wh.loungeWarehouses?.length &&
            <p className='empty-msg'>Aún no se asigna ninguna sala a este almacén.</p>}
        </div>
    );
};

export default LoungeWarehousesTable;
