import React, {useCallback, useContext, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {ApiContext} from "../../../api/Api";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import useTideEntity from "../../../hooks/useTideEntity";
import {useHeaderFooterHelper} from "../../../utils/header-footer-helper";
import Medal from "../utils/Medal";
import _ from 'lodash';

const ChooseAwardForEmployee = ({match: {params: {id: employeeId}}, pointsWallets, recognitions, history: navigate}) => {
    const api = useContext(ApiContext);
    const [employee, loading, notFound] = useTideEntity('employees', employeeId, {
        getMethod: 'simple'
    });
    const [picked, setPicked] = useState(null);
    const [writingMessage, setWritingMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [showingNotEnoughPoints, setShowingNotEnoughPoints] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const {setFooterClassName, setBackAction, setHeaderTitle, fullResetAction} = useHeaderFooterHelper();

    const blurFooter = useCallback(() => setFooterClassName('blurred'), [setFooterClassName]);

    const recognitionsRequest = api.recognitions.get;
    const walletRequest = api.pointsWallets.get;
    const giveAwardRequest = api.recognitionMovements.create;
    useEffect(() => {
        recognitionsRequest();
        walletRequest();
    }, [recognitionsRequest, walletRequest]);

    const closeOverlay = useCallback(() => {
        walletRequest();
        fullResetAction();
        setWritingMessage(false);
        setMessage('');
        setShowConfirmation(false);
        setPicked(null);
    }, [fullResetAction, walletRequest]);

    const giveAwardToEmployee = async ()=>{
        if(disabled){
            return;
        }
        setDisabled(true);
        try{
            await giveAwardRequest({
                toEmployee: employeeId,
                recognition: picked,
                comment: message
            });
            setShowConfirmation(true);
        } catch(e){
        }
        setDisabled(false);
    };

    useEffect(()=>{
        if(!showConfirmation){
            return;
        }
        setTimeout(()=>{
            closeOverlay();
            setTimeout(()=>navigate.push(`/rewards/feed`), 100);
        }, 1400);
    }, [showConfirmation, closeOverlay, navigate]);

    const writeToEmployee = (medal, message)=>{
        setHeaderTitle('Escribe un mensaje');
        blurFooter();
        window.scroll(0,0);
        setBackAction(() => closeOverlay);
        setWritingMessage(true);
    };


    if (notFound && !loading) {
        navigate.push(`/rewards/gifts`);
    }

    const pointsWallet = (pointsWallets || []).length > 0 ? pointsWallets[0] : null;


    const availablePointsMessage = pw => (toGive =>
        toGive <= 0 ?
            'Ya no tienes puntos para otorgar este mes.' :
            `Te resta${toGive!==1?'n':''} ${toGive}pt${(toGive !== 1) ? 's':''} para dar este mes`)(pw.availableRecognitionPointsToGive);

    const medalAffordable = m => {
        if (!pointsWallet) {
            return false;
        }
        return pointsWallet.availableRecognitionPointsToGive >= m.points;
    };

    const pickMedal = medal => {
        if(!medalAffordable(medal)){
            setShowingNotEnoughPoints(true);
            return;
        }
        writeToEmployee(_.find(recognitions, {id: medal.id}), message);
        setPicked(medal.id);
    };
    const addPicked = m => ({...m, picked: picked === m.id});
    const medals = _.map(recognitions, addPicked);

    const cssClasses = [
        'Rewards',
        'ChooseAwardForEmployee',
        ...(writingMessage ? ['blurred'] : [])
    ];
    const medalClasses = m=>[
        ...(m.picked?['picked']:['']),
        ...(medalAffordable(m)?['']:['disabled']),
    ];

    return (
        <>
            <div className={cssClasses.join(' ')}>
                <div className='info-box'>
                    {(!loading && !!employee) && <p>¿Qué medalla le darás a {employeeFullName(employee)}?</p>}
                    {pointsWallet && <h3>{availablePointsMessage(pointsWallet)}</h3>}
                </div>
                {showingNotEnoughPoints&&
                 <div className='error-message'>
                     No tienes suficientes puntos para esta medalla
                     <div onClick={()=>setShowingNotEnoughPoints(false)}>
                         <i className='fa fa-close'/>
                     </div>
                 </div>
                }
                <div className='title-text'>
                    Utilizadas recientemente
                </div>
                <div className='medal-list'>
                    {
                        medals.map(m =>
                                   <Medal className={medalClasses(m).join(' ')} key={m.id} disabled={!medalAffordable(m)}
                                   onMedalClick={pickMedal} {...m}/>
                        )
                    }
                </div>
            </div>
            {writingMessage &&
            <div className='ChooseAwardForEmployee overlay'>
                {!showConfirmation&&(
                    <div className='overlay-title'>
                        Otorgarás la medalla de {_.find(medals, 'picked').name} (+{_.find(medals, 'picked').points}pts).
                    </div>
                )}
                <div className='message-bubble'>
                    <p>Escríbele un mensaje a <span className='bold'>{employeeFullName(employee)}</span></p>
                    <textarea value={message} onChange={e => setMessage(e.target.value)}/>
                </div>
                {!showConfirmation&&(
                    <div className='action-buttons'>
                        <button className='cancel-button' onClick={closeOverlay}><i className='fa fa-times'/>Cancelar</button>
                        <button className='ok-button' onClick={giveAwardToEmployee}><i className='fa fa-check'/>Continuar</button>
                    </div>
                )}
                {showConfirmation &&
                <div className='answer-bubble'>
                    <p>¡Tu mensaje se ha <span className='bold'>publicado exitosamente</span>! Gracias.</p>
                </div>
                }
            </div>
            }
        </>
    );
};

const mapStateToProps = ({api: {recognitions, pointsWallets}}) => ({
    recognitions,
    pointsWallets
});

export default connect(mapStateToProps)(ChooseAwardForEmployee);
