import React, {useState, useEffect, useContext, useMemo} from 'react';
import ReactTable from 'react-table';
import {Button} from 'react-bootstrap';
import AddCategoryModal from './AddCategoryModal';
import EditCategoryModal from './EditCategoryModal';
import useTideTable from '../../../hooks/table/useTideTable';
import {timeString} from './MinuteTimePicker';
import {TideApiContext} from "../../../api/tideApiConfig";
import {useSelector} from "react-redux";

const AvailableCell = ({original}) => {
    const active = original.isActive;
    const api = useContext(TideApiContext);
    const loading = useSelector(({loadingIds}) => loadingIds['editingProductCategoryAvailability' + original.id]);
    const toggleAvailability = e => {
        e.stopPropagation();
        if (loading) return;
        api.orozcoProductCategories.update({
            id: original.id,
            loadingId: 'editingProductCategoryAvailability' + original.id,
            params: {isActive: !active}
        });

    };

    return (
        <Button onClick={toggleAvailability} bsClass={active ? 'btn btn-success' : 'btn btn-error'}>
            {loading ? 'Cargando...' : active ? 'Habilitado' : 'Deshabilitado'}
        </Button>
    );
};


const columns = [
    {
        Header: 'Categoría',
        accessor: 'name'
    },
    {
        Header: 'Subcategoría de...',
        id: 'parentCategory.name',
        accessor: c => c.parentCategory ? c.parentCategory.name : ''
    },
    {
        Header: 'Descripción',
        accessor: 'description'
    },
    {
        Header: 'Horario',
        id: 'availableAfter',
        filterable: false,
        accessor: c =>
            `${timeString(c.availableAfter)} - ${timeString(c.availableBefore)}`
    },
    {
        Header: 'Disponible',
        id: 'isActive',
        Cell: AvailableCell,
        filterable: false
    },
];

const DishesCategories = () => {
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [toEdit, setToEdit] = useState(null);

    const [showDisabled, setShowDisabled] = useState(true);

    const showCategoriesDisabled = () => {
        if (showDisabled) {
            setShowDisabled(null);

        } else {
            setShowDisabled(true);
        }
    };

    const filter = useMemo(()=> ({"isActive":showDisabled}),[showDisabled]);

    const {
        tableProps,
    } = useTideTable({
        entity: 'orozcoProductCategories',
        columns,
        requestFilters: filter
    });
    const trProps = (state, rowInfo) => ({
        style: (rowInfo && rowInfo.original) ? {cursor: 'pointer'} : {},
        onClick: () =>
            setToEdit(rowInfo.original)
    });
    useEffect(() => {
        if (!!toEdit) {
            setAddModalOpen(false);
        }
    }, [toEdit]);

    return (
        <>
            <div className='container-fluid main-container'>
                <Button onClick={() => setAddModalOpen(true)}>
                    Agregar categoría
                </Button>
                <Button
                    className='pull-right'
                    onClick={showCategoriesDisabled}>
                    {showDisabled ? "Mostrar deshabilitados" : "Ocultar deshabilitados"}
                </Button>
                <h3>Categorías</h3>
                <ReactTable
                    {...tableProps}
                    getTrProps={trProps}
                />
            </div>
            {addModalOpen && (
                <AddCategoryModal onHide={() => setAddModalOpen(false)}/>
            )}
            {!!toEdit && (
                <EditCategoryModal toEdit={toEdit} onHide={() => setToEdit(null)}/>
            )}
        </>
    );
};

export default DishesCategories;
