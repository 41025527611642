import React, {useMemo, useContext, useState, useCallback} from 'react';
import moment from 'moment';
import Switch from "react-bootstrap-switch";
import {Table} from "react-bootstrap";
import PurchaseRequisitionTableHeader from "./PurchaseRequisitionTableHeader";
import PurchaseProductRow from "./PurchaseProductRow";
import PurchaseRequisitionTotalRow from "./PurchaseRequisitionTotalRow";
import useBoolean from "../../../../hooks/useBoolean";
import {purchaseRequisitionHasDiscount, purchaseRequisitionProcessSGroups, quantityFromStockRequest, groupByProductTotals} from "../../../../utils/modelUtils/jazz/purchaseRequisitionUtils";
import {ApiContext} from "../../../../api/Api";
import {SecurityContext} from "../../../../utils/SecurityManager";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import {NavLink} from "react-router-dom";
import ConfirmModal from "../../../../components/Modals/ConfirmModal";


const editLoadingId = '@PurchaseRequisitionDetail.purchaseRequisitions.edit';
const PurchaseRequisitionDetail = ( {purchaseRequisition, stockRequestProducts} )=>{

    const api = useContext( ApiContext );
    const security = useContext( SecurityContext );

    const [ forceOpen, , , toggleForceOpen ] = useBoolean( );
    const [ promptUnquote, setPromptUnquoteTrue, setPromptUnquoteFalse] = useBoolean();
    const [ hoveredRow, setHoveredRow] = useState( -1 );

    const pr = purchaseRequisition;
    const hasDiscount = purchaseRequisitionHasDiscount( pr );

    const handleHoverChange = ( index )=> !forceOpen? setHoveredRow(index):null;

    const onPurchaseProductChange = ()=>{
        api.clearProperty('purchaseRequisitions');
    };


    const unquoteRequisition=useCallback(()=>{
        setPromptUnquoteFalse();
        api.purchaseRequisitions.unquote({id:pr.id, filters: {sGroups: purchaseRequisitionProcessSGroups}, loadingId:editLoadingId} );
    },[api, pr, setPromptUnquoteFalse]);

    const stockRequestAmount = useCallback(purchaseProduct=>quantityFromStockRequest(purchaseProduct, stockRequestProducts), [stockRequestProducts]);
    const productTotals = useMemo(()=>groupByProductTotals(pr), [pr]);
    const getTotalInRequest = useCallback(
	(pp)=>pp.productBrand ?
	    productTotals[pp.productBrand.product.id]:
	    pp.quantity, [productTotals]);

    return (
        <div className='PurchaseRequisitionDetail'>
            <div className='row'>

                <div className='col-md-6'>
                    {pr.expectedDate?'Fecha estimada de entrega: '+moment(pr.expectedDate).format('DD/MM/YYYY'):'La solicitud no tiene fecha estimada de entrega'}
                </div>
                <div className='col-md-6'>
                    <div className='pull-right force-open-box' onClick={toggleForceOpen}>
                        { forceOpen? 'Ocultar':'Mostrar' } todas las cotizaciones &nbsp;
                        <Switch value={forceOpen} />
                    </div>
                </div>
            </div>

            <div className='scrollable-table-container'>
                <Table className='text-center vertical-responsive-table'>

                    <PurchaseRequisitionTableHeader hasDiscount={hasDiscount} />
                    <tbody>

                    { pr.purchaseProducts.map( (purchaseProduct, i)=>
                        <PurchaseProductRow
			    productTotal={getTotalInRequest(purchaseProduct)}
			    stockRequestAmount={purchaseProduct.productBrand?stockRequestAmount(purchaseProduct):null}
                            purchaseRequisition={purchaseRequisition}
                            purchaseProduct={purchaseProduct}
                            onChange={ onPurchaseProductChange }
                            onHoverChange={ handleHoverChange }
                            isOpen={ forceOpen || hoveredRow === i }
                            hasDiscount={ hasDiscount }
                            currency={ pr.currency }
                            index={ i }
                            key={ i }
                        />
                    ) }
                    <PurchaseRequisitionTotalRow purchaseRequisition={pr} hasDiscount={ hasDiscount } />

                    </tbody>
                </Table>
            </div>

            <div className='bottom-btn-wrapper'>
                { pr.status === 'requested' && security.jazz.canEditRequisition(pr) &&
                <NavLink to={'/compras/requisitions/'+ purchaseRequisition.id +'/edit'}><CustomButton bsStyle='primary' className='bottom-btn'> Editar requisición</CustomButton></NavLink>}
                { pr.status === 'quoted' && security.jazz.canUnquotePurchaseRequisitions(pr) &&
                <CustomButton bsStyle='danger' className=' bottom-btn right-btn' onClick={setPromptUnquoteTrue}>Des-cotizar requisición</CustomButton>}
            </div>


            {promptUnquote&&
                <ConfirmModal
                    title={'Des-cotizar requisición'}
                    message={`¿Estás seguro que deseas cancelar la cotización ${pr.folio}? Se eliminarán los precios y las aprobaciones y regresará al estado de solicitada`}
                    onCancel={setPromptUnquoteFalse}
                    onConfirm={unquoteRequisition}
                />
            }

        </div>
    );
};

export default PurchaseRequisitionDetail;
