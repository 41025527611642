import React from 'react';

const Changelog = ()=>{

    return(
        <div className="main-content">
            <div className='changelog-entry'>
                <hr/>
                <h4>4.3.30 11/10/2020</h4>
                <ul>
                    <li>Almazen. Se agrega funcionalidad para clonar requisiciones.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.2.6 11/02/2020</h4>
                <ul>
                    <li>Core. Se agrega permisos administrable para crear documentos del DID</li>
                    <li>CH. Bug fix. Horarios extraordinarios tenían problemas en móvil y dejaba eliminar a cualquiera</li>
                    <li>Jazz. Nuevo switch para activar la aprobación únicamente por montos en productos de línea</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.2.5 10/02/2020</h4>
                <ul>
                    <li>CH. Se checan permisos para poder eliminar citas. Solo los que pueden crearlas pueden eliminarlas</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.2.4 06/02/2020</h4>
                <ul>
                    <li>EE. Mejora el tiempo de carga de catálogo de cuestionarios.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.2.3 06/02/2020</h4>
                <ul>
                    <li>Alma. Bug fix. Creador de citas se rompía al modificar la hora manualmente en el input.</li>
                    <li>EE. Se añade permiso de ver sistema de EE a rol de CH.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.2.2 06/02/2020</h4>
                <ul>
                    <li>Alma. Bug fix. Personas que pueden asignar citas solo a sus subordinados no podían buscar.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.2.0 04/02/2020</h4>
                <ul>
                    <li>Alma. Nuevo sistema de citas para horarios extraordinarios.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.0.17 21/01/2020</h4>
                <ul>
                    <li>Jazz. Bug fix. No se podían editar requisiciones de compra e línea.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.0.12 17/01/2020</h4>
                <ul>
                    <li>Davinci. Nueva opción multiple en campos extra de la configuración el método de acceso</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.0.10 16/01/2020</h4>
                <ul>
                    <li>Orozco. Se agrega costo teórico y precios pre-llenados a los platillos.</li>
                    <li>Alma. Al iniciar sesión abre con el logo de ALMA en vez del dashboard</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.0.6 15/01/2020</h4>
                <ul>
                    <li>Jazz. Bug fix. No se auto-cargaba la info de las facturas en la OC cuando la extensión del xml estaba en mayúsculas.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.0.3 13/01/2020</h4>
                <ul>
                    <li>Jazz. Bug fix. No se veía el cambio cuando se editaba método de pago y estado de OC.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.0.2 13/01/2020</h4>
                <ul>
                    <li>Da Vinci. Nuevo configurador de métodos de acceso.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>4.0.0 11/01/2020</h4>
                <ul>
                    <li>Llega davinci a producción.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.15.8 10/01/2020</h4>
                <ul>
                    <li>Jazz. Bug fix. A veces se rompía al quitar el límite de crédito de un proveedor.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.15.5 06/01/2020</h4>
                <ul>
                    <li>Jazz. Bug fix. No se podían hacer requisiciones cargando la lista de productos de un proveedor.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.15.4 06/01/2020</h4>
                <ul>
                    <li>Jazz. Nuevo botón de "Agregar producto" en las listas de precios.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.15.3 06/01/2020</h4>
                <ul>
                    <li>Jazz. Bug fix. No se podía cargar la lista de productos a crear una nueva requisición.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.15.2 06/01/2020</h4>
                <ul>
                    <li>Jazz. Bug fix. No se veían comentarios de requisiciones ni salía botón para aprobar.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.15.1 05/01/2020</h4>
                <ul>
                    <li>Jazz. Al cotizar se cargan automáticamente los precios y productos desde las listas de precios.</li>
                    <li>Jazz. Se agrega método de pago "Pre-pago".</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.15.0 03/01/2020</h4>
                <ul>
                    <li>Jazz. Se libera funcionalidad de listas de precios, con precios de lista, descuento de lista, expiración de ambos y check de disponibilidad.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.14.9 18/12/2019</h4>
                <ul>
                    <li>Jazz. Bug fix. Editar el precio de una OC dándole "guardar" sin hacer ningún cambio al precio daba 500.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.14.8 12/12/2019</h4>
                <ul>
                    <li>Alma. Mejora del ~2000% de tiempo de carga de carpetas del DID.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.14.7 07/12/2019</h4>
                <ul>
                    <li>Se re-activa opción de justificar registros incompletos.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.14.6 07/12/2019</h4>
                <ul>
                    <li>Alma. DID permite carga de archivos masiva.</li>
                    <li>Core. Se cambia order de notificaciones, primero se muestran no leídas, luego se ordenen por fecha.</li>
                    <li>Almazen. Se Agrega la fecha en que se requiere la entrega al las requisiciones de almazen.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.14.5 29/11/2019</h4>
                <ul>
                    <li>Almazen. Servicios de línea pueden ser editados y borrados.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.14.4 29/11/2019</h4>
                <ul>
                    <li>Jazz. Bug fix. Se rompía al intentar agregar un ajuste a una orden de compra.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.14.2 23/11/2019</h4>
                <ul>
                    <li>Almazen. Mejora en el algoritmo que decide qué unidad usar al mostrar la cantidad de un producto.</li>
                    <li>Almazen. Los nombres de las unidades se pluralizan automáticamente.</li>
                    <li>Almazen. Cambio en la forma de cargar la lista de requisiciones de almacén. Aumenta 4x la velocidad de carga.</li>
                    <li>Almazen. Se restringe hacer requisiciones que salen y se entregan en el mismo lugar.</li>
                    <li>Almazen. Bug fix. Pantalla de detalles de requisiciones siempre decía "No encontrada"</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.14.1 22/11/2019</h4>
                <ul>
                    <li>Core. Se mejora el menú de notificaciones, te aparecen primero las no leídas. Esto evita que se vayan hacia abajo y se pierdan.</li>
                    <li>Core. Bug fix. Al seleccionar una notificación se cierra solo el menú de notificaciones.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.14.0 22/11/2019</h4>
                <ul>
                    <li>Almazen. Se agrega nuevo sistema de conteos aleatorios</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.33 21/11/2019</h4>
                <ul>
                    <li>Jazz. Se agrega permiso para cancelar cualquier requisición a Alma y Yas</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.32 20/11/2019</h4>
                <ul>
                    <li>EE. Se agrega hora a las reservaciones de airconcierge</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.30 20/11/2019</h4>
                <ul>
                    <li>Alma. Cambio en premio de extensión de tiempo de comida. Ahora se obtiene con base en la calificación de cumplimiento.</li>
                    <li>Alma. Se agrega gráfica de historial de cumplimiento en la pantalla de "Mis Incidencias"</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.29 19/11/2019</h4>
                <ul>
                    <li>Almazen. Cambio de texto de menú "Requisición de salida de insumo y/o producto".</li>
                    <li>Alma. Bug fix. Se rompía al crear un nuevo horario y borrar la hora del input.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.28 15/11/2019</h4>
                <ul>
                    <li>Almazen. Modal de nueva requisición de almazen ya no se cierra al dar click afuera de él.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.26 14/11/2019</h4>
                <ul>
                    <li>Core. Cuando entras a una ruta y no tenías una sesión iniciada, al iniciar sesión te redirige a donde habías intentado entrar</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.25 14/11/2019</h4>
                <ul>
                    <li>Jazz. Bug fix. Al cotizar productos con un subtotal no representable en binario los impuestos dejaban de calcularse solos.</li>
                    <li>Jazz. Mejora de estilos, ya no se anima la separación entre productos si no hay más de una cotización.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.24 13/11/2019</h4>
                <ul>
                    <li>Jazz. Se quitan permisos de hacer requisiciones de compra a todos excepto las personas que se encuentran
                        en la matriz de compra sin incluir a los que aprueban..</li>
                    <li>Core. Se cambia el mensaje de error de todos los requests para especificar cuando fue por error de conexión</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.21 05/11/2019</h4>
                <ul>
                    <li>Jazz. Mejora a vista de celular en requisiciones de compra.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.19 04/11/2019</h4>
                <ul>
                    <li>Jazz. Bug fix. No se podía editar método ni forma de pago en órdenes de compra</li>
                </ul>
            </div>
                    <div className='changelog-entry'>
                <hr/>
                <h4>3.13.18 04/11/2019</h4>
                <ul>
                    <li>EE. Posibilidad de desactivar preguntas y cuestionarios.</li>
                    <li>EE. Al borrar una pregunta, confirma si estás seguro y explica las consecuencias de tus actos.</li>
                    <li>Almazen. Se pueden editar requisiciones antes de que se haya recibido o sacado algún producto.
                        (La edición cancela la requisición actual y genera una nueva)</li>
                    <li>Almazen. Al hacer entradas o salidas de almacén muestra la cantidad que debería ser junto al campo editable de cantidad.</li>
                    <li>Jazz. Se pueden cancelar requisiciones aún cuando hayan sido aceptadas y las OC generadas. Solo no se puede si los productos ya fueron recibidos.</li>
                    <li>Jazz. Bug fix, en los detalles de una solicitud de compra, al dar click en la tabla recargaba la página. Ya no más.</li>
                    <li>Jazz. Nuevos ajustes en órdenes de compra. Se puede cambiar precio y cantidad.</li>
                    <li>CH. Bug fix. Tabla de expiración de herramientas de trabajo se veía en blanco.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.17 31/10/2019</h4>
                <ul>
                    <li>Jazz. Nueva pantalla de Dashboard. Primeras estadísticas de Jaz, aunque aún en construcción.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.16 29/10/2019</h4>
                <ul>
                    <li>Alma. Menú de permisos de manuales y boletines ya no cierra el modal al escoger un puesto.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.15</h4>
                <ul>
                    <li>Almazen. Flujo de aprobación de requisiciones terminado.</li>
                    <li>Almazen. Se agrega bloque de comentarios y archivos a las requisiciones.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.14</h4>
                <ul>
                    <li>EE. Se agrega la posibilidad de filtrar estadísticas basado en respuestas de algunas preguntas.
                        Actualmente solo se puede con preguntas de sí/no y preguntas de opción multiple con una respuesta.</li>
                    <li>Almazen. Confirmación de recepción de pedidos a medias. Se genera la entrada pendiente a la sala pero aún no está listo
                    el botón para confirmar la recepción (por lo que está oculto)</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.13</h4>
                <ul>
                    <li>Almazen. Posibilidad de borrar movimientos del inventario, restaurando automáticamente lo que cambiaron.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.12</h4>
                <ul>
                    <li>Rutas de almazen se controlan con permisos</li>
                    <li>Configurador de matriz de requisiciones de Almazen</li>
                    <li>Atributo "Línea" se muestra en la exportación del excel de productos</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.11 24/10/2019</h4>
                <ul>
                    <li>Jazz. Bug fix/mejora. Ya se pueden cargar borradores de cotizaciones aunque haya sido editada la
                        requisición, sin importar si se agregaron o quitaron productos o si cambió la cantidad o unidad
                        de alguno.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.10 23/10/2019</h4>
                <ul>
                    <li>Jazz. Al agregar a una persona a la matriz de autorizaciones le da permisos para ver el sistema, al quitarla se los quita.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.9 17/10/2019</h4>
                <ul>
                    <li>Jazz. Se agrega "prioridad" a las aprobaciones por montos para poder configurar multiples reglas para una misma compra.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.8 17/10/2019</h4>
                <ul>
                    <li>Íconos de movimientos pendientes en listado de almacenes y en la pantalla de entradas y salidas</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.7 17/10/2019</h4>
                <ul>
                    <li>Almazen. Los selectores de unidades de la forma de producto muestran hasta 110 coincidencias en vez de 10.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.6 16/10/2019</h4>
                <ul>
                    <li>Se añadió pestaña de colaboradores a sistema de CH.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.4 16/10/2019</h4>
                <ul>
                    <li>Bug fix. Entradas y salidas que se quedaban a medias programaban mal el complemento restante del movimiento.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.3 16/10/2019</h4>
                <ul>
                    <li>Mejora en mensajes del modal de movimientos de almacén que no coinciden con lo requerido.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.2 16/10/2019</h4>
                <ul>
                    <li>Bug fix. En el modal de nuevo producto, al crear una unidad se puede elegir en los demás selectores.</li>
                    <li>Bug fix. Ya se pueden crear entidades en los selectores cuando el nombre es un substring de otra entidad</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.1 15/10/2019</h4>
                <ul>
                    <li>Bug fix. Crasheaba al buscar un producto en la pantalla de movimientos de almazen.</li>
                    <li>Movimientos de almazen. Seleccionar un producto ya no lo agrega al movimiento automáticamente</li>
                    <li>Selector de productos en nueva requisición de almacén muestra hasta 100 productos y en orden alfabético.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.13.0 14/10/2019</h4>
                <ul>
                    <li>Nuevo sistema de Capital Humano</li>
                    <li>Se libera sección de herramientas de trabajo</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.12.13 11/10/2019</h4>
                <ul>
                    <li>Requisiciones de almacén y salidas pendientes terminadas.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.12.12 10/10/2019</h4>
                <ul>
                    <li>Se agrega filtro de productos inactivos en la lista.</li>
                    <li>Se pueden desactivar presentaciones en los productos.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.12.11 10/10/2019</h4>
                <ul>
                    <li>Bug fix. Al editar un producto se debía configurar primero smallestUnit o crasheaba.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.12.10 10/10/2019</h4>
                <ul>
                    <li>Se pueden borrar y desactivar productos de almazen.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.12.9 10/10/2019</h4>
                <ul>
                    <li>Las personas que pueden cotizar requisiciones de compra ahora pueden también descotizarlas.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.12.8 07/10/2019</h4>
                <ul>
                    <li>Bug fix. No se podían editar requisiciones que no fueran de línea.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.12.7 04/10/2019</h4>
                <ul>
                    <li>Mejora al mostrar responsables de movimientos de inventarios.</li>
                    <li>Al cargar entradas pendientes de una orden de compra, se copia la unidad de medida utilizada para pedir.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.12.6 04/10/2019</h4>
                <ul>
                    <li>Nuevo control de entradas pendientes en almacén.</li>
                    <li>Se agregan opciones de cotizaciones en los cargos extra en compras.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.12.4 03/10/2019</h4>
                <ul>
                    <li>Nueva pantalla de administrador de la matriz de aprobaciones de compras.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.12.0 01/10/2019</h4>
                <ul>
                    <li>Nuevas notificaciones push.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.11.8 25/09/2019</h4>
                <ul>
                    <li>Permiso a finanzas para ver proveedores.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.11.5 23/09/2019</h4>
                <ul>
                    <li>Bug fix. Crash al enviar requisición de compra con un string vacío como descuento.</li>
                    <li>Validación para no agregar dos veces el mismo producto a una misma requisición de compra.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.11.4 20/09/2019</h4>
                <ul>
                    <li>Bug fix. Al intentar editar una requisición de compra se borraban los productos.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.11.0 18/09/2019</h4>
                <ul>
                    <li>Se agrega sección de expedientes de empleados a Alma</li>
                    <li>Se agrega el modo inventario al Almazen</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.10.15 09/09/2019</h4>
                <ul>
                    <li>Bug fix. Revisar un cuestionario llenado de que no tenía ninguna configuración general rompía la app.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.10.14 04/09/2019</h4>
                <ul>
                    <li>Bug fix. Forma para editar productos de Almazen se rompía a veces.</li>
                    <li>Arreglos de estilos varios de Almazen</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.10.13 03/09/2019</h4>
                <ul>
                    <li>Nueva pantalla de todas las notificaciones</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.10.11 03/09/2019</h4>
                <ul>
                    <li>Tablas de solicitudes de compra y órdenes de compra guardan su estado de paginación, filtrado y ordenado cuando te vas y regresas a la pantalla.</li>
                    <li>Cuando hay más de 10 notificaciones la campana dice 10+.</li>
                    <li>Cambio de logo a Jazz 2.0</li>
                    <li>Al cambiar de productos de no línea a línea en la forma de requisición de compra se borran los productos agregados.</li>
                    <li>Nuevo botón en forma de requisición de productos para agregar todos los productos de línea de un proveedor.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.10.9 01/09/2019</h4>
                <ul>
                    <li>Nuevos filtros de cuestionarios, por colaborador que lo hizo y enviados por correo.</li>
                    <li>Estadísticas de G-Care en cuestionario G-Care.</li>
                    <li>Estadísticas de cuestionarios aparecen ahora en el mismo orden que las preguntas del cuestionario.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.10.8 30/08/2019</h4>
                <ul>
                    <li>Ae agrega forma de pago y fecha de recepción a órdenes de compra.
                        Solo el creado puede marcarla como recibida.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.10.6 29/08/2019</h4>
                <ul>
                    <li>Se agregan nuevas opciones de estado y método de pago a las órdenes de compra.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.10.5 28/08/2019</h4>
                <ul>
                    <li>Bug fix. Algunos creadores de requisiciones de compra no podían editarlas ni eliminarlas</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.10.3 24/08/2019</h4>
                <ul>
                    <li>Bug fix. No se podía ver sita previa de PFDs</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.10.2 24/08/2019</h4>
                <ul>
                    <li>Se agrega permisos a DIRECCION_DE_FINANZAS_Y_ADMINISTRACION</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.10.1 24/08/2019</h4>
                <ul>
                    <li>Se integra el chat de la aplicación.</li>
                    <li>Se crea el manejador de apis por sockets</li>
                    <li>Se agregan columnas a la tabla de órdenes de compra</li>
                    <li>Se agregan los campos "método de pago" y "número de transacción" a las órdenes de compra</li>
                    <li>Bug Fix. Algunas personas no podían editar o cancelar sus propias requisiciones de compra</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.9.4 23/08/2019</h4>
                <ul>
                    <li>Nueva estructura de productos y almacén se une a las requisiciones de compra, ya se pueden hacer solicitudes de línea.</li>
                    <li>Se elimina menú de "Tickets" del perfil Admin EE</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.9.2 22/08/2019</h4>
                <ul>
                    <li>Permisos de órdenes de compra para SILVIA.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.9.0 22/08/2019</h4>
                <ul>
                    <li>Nueva estructura de base de datos para productos en almacén.</li>
                    <li>Se agregan marcas a los productos.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.8.16 22/08/2019</h4>
                <ul>
                    <li>Permiso para ver solicitudes de compra a GERENTE DE TESORERÍA (Franco).</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.8.15 21/08/2019</h4>
                <ul>
                    <li>Bug fix. Botón de editar requisición no funcionaba para personas con todos los permisos de compras.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.8.14 21/08/2019</h4>
                <ul>
                    <li>Nómina actual no se actualizaba en el front al justificar a alguien.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.8.11 20/08/2019</h4>
                <ul>
                    <li>Se agrega menú de órdenes de compra al puesto de GERENTE DE TESORERÍA.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.8.10 20/08/2019</h4>
                <ul>
                    <li>Las requisiciones de compra y el cotizador son ahora más responsive.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.8.8 19/08/2019</h4>
                <ul>
                    <li>Al cotizar en compras se pueden usar proveedores no registrados.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.8.7 16/08/2019</h4>
                <ul>
                    <li>Se agrega posibilidad de editar y cancelar requisiciones de compra.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.8.1 10/08/2019</h4>
                <ul>
                    <li>Al crear nuevas requisiciones de compras la forma se inicia con cantidad en 1.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.8.0 10/08/2019</h4>
                <ul>
                    <li>Nuevo SecurityContext para permisos en el front.</li>
                    <li>Solo el coordinador de almacén (Chef Sandy) puede hacer pedidos de línea.</li>
                    <li>Bug fix. No se podía editar una reservación que tuviera una sala asignada.</li>
                    <li>Mejora de seguridad. Se previene inyección de javascript desde un HTML subido al nuevo sistema de archivos.</li>
                    <li>Bug fix. Redimensionar la pantalla no aparecía el menú móvil para cerrar sesión y ver la versión</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.7.3 06/08/2019</h4>
                <ul>
                    <li>Typo en GCare público.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.7.2f 06/08/2019</h4>
                <ul>
                    <li>Bug fix. Cuestionarios hechos desde perfil de EE no se guardaban.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.7.1 05/08/2019</h4>
                <ul>
                    <li>Optimización de carga de la lista de cuestionarios resueltos de 18 segundos a 1 segundo</li>
                    <li>Se agregan las rutas de EE al sysadmin</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.7.0 05/08/2019</h4>
                <ul>
                    <li>Se añade ruta pública para cuestionario de gcare.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.6.2 05/08/2019</h4>
                <ul>
                    <li>Permisos del nuevo repositorio de archivos se heredan en el front.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.6.1 2/08/2019</h4>
                <ul>
                    <li>Bug fix. Página de reservaciones apuntaba a localhost.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.6.0 01/08/2019</h4>
                <ul>
                    <li>
                        Se añade sistema de manuales, boletines y políticas
                        <ul>
                            <li>Alta de archivos y carpetas por CH y EE</li>
                            <li>Notificaciones a usuarios que pueden ver los archivos</li>
                            <li>Visor de archivos compatibles dentro de la aplicación</li>
                        </ul>
                    </li>
                    <li>Se permite de nuevo justificar la incidencia "Registros incompletos"</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.5.3 01/08/2019</h4>
                <ul>
                    <li>Bug fix. Crear una reservación de sala con campos faltantes enviaba un json vacío al servidor.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.5.2 31/07/2019</h4>
                <ul>
                    <li>Se pueden editar reservaciones a salas.</li>
                    <li>Se agrega vuelo y aerolínea a las reservaciones.</li>
                    <li>Se da permiso a usuario 359 (Karen) de ver el changelog.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.5.1 30/07/2019</h4>
                <ul>
                    <li>Se agregan permisos para crear solicitudes de compra. Permisos específicos por usuario con su id.</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.5.0 30/07/2019</h4>
                <ul>
                    <li>Se agrega sistema de Almazen a gerentes</li>
                    <li>Bug fix. Requisiciones de productos se ponen al día con la nueva api de stockRequests</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.4.13 29/07/2019</h4>
                <ul>
                    <li>Bug fix. Las reservaciones cambiaban siempre la hora a AM</li>
                    <li>Se elimina librería perfect scroll</li>
                </ul>
            </div> <div className='changelog-entry'>
                <hr/>
                <h4>3.4.12 29/07/2019</h4>
                <ul>
                    <li>Bug fix. Crash en visor de solicitud de compra cuando no existe ningún borrador</li>
                </ul>
            </div>
            <div className='changelog-entry'>
                <hr/>
                <h4>3.4.11 27/07/2019</h4>
                <ul>
                    <li>El log de logins guarda de nuevo el cliente</li>
                    <li>Bug fix. El logger de crashes podría crashear en algunos dispositivos viejos</li>
                </ul>
            </div>

            <div className='changelog-entry'>
                <hr/>
                <h4>3.4.10</h4>
                <ul>
                    <li>Se añadió el aviso de privacidad a la forma de reservaciones</li>
                </ul>
            </div>

            <div className='changelog-entry'>
                <hr/>
                <h4>3.4.9</h4>
                <ul>
                    <li>Se añadió el filtro de "Premios de puntualidad" en el visor avanzado de la nómina</li>
                    <li>Se creó el change log</li>
                    <li>Bug fix. A veces se rompía la librería "Perfect scroll"</li>
                </ul>
            </div>



        </div>
    );
};

export default Changelog;
