import {getUnitsArray, unitBundleToIds} from "./unitsUtility";
import _ from 'lodash';


export const emptyProductBrandUnit = ()=>({
    uid: Math.random(),
    unit: null,
    barcode: ''
});

export const emptyProductBrand=(brand=null)=>({
    uid: Math.random(),
    brand,
    productBrandUnits:[emptyProductBrandUnit()]
});

export const prepareProductForServer=(product)=>{

    let serverProduct = {...product};

    delete serverProduct.approvalEvidenceFiles;

    if(!serverProduct.name || !serverProduct.name.trim())
        return {error:1, message:'Debes introducir un nombre para el producto'};
    serverProduct.name = serverProduct.name.trim();

    if(!serverProduct.smallestUnit )
        return {error:1, message:'Debes introducir la unida mínima del producto'};

    const result=testConversionRules(serverProduct);
    if(result.error)
        return result;

    serverProduct = unitBundleToIds(serverProduct);

    if( serverProduct.line )
        serverProduct.line = serverProduct.line.id;

    if( serverProduct.approvedBy )
        serverProduct.approvedBy = serverProduct.approvedBy.id;

    if(!serverProduct.productBrands || !serverProduct.productBrands.length)
        return {error:1, message:'Se debe agregar por lo menos una marca con una presentación.'};

    const newProductBrands = [];
    for( let i=0; i<serverProduct.productBrands.length; i++) {
        newProductBrands.push(prepareProductBrandForServer(serverProduct.productBrands[i]));
        if(newProductBrands[i].error)
            return newProductBrands[i];
    }
    serverProduct.productBrands=newProductBrands;

    if(serverProduct.productBrands.error)
        return serverProduct.productBrands;

    return serverProduct;
};

export const prepareProductBrandForServer= productBrand=> {
    const newProductBrand = {...productBrand};
    if (newProductBrand.brand)
        newProductBrand.brand = newProductBrand.brand.id;
    else
        return {error: 1, message: 'No se encontró la marca'};

    if(!newProductBrand.productBrandUnits || !newProductBrand.productBrandUnits.length)
        return {error: 1, message: 'Todas las marcas deben tener al menos una presentación.'};

    newProductBrand.productBrandUnits = [];

    for(let i=0; i<productBrand.productBrandUnits.length; i++){
        const pbu=productBrand.productBrandUnits[i];
        if(!pbu.unit) {
            return {error: 1, message: 'Todas las presentaciones deben tener una unidad seleccionada.'};
        }
        const newPbu={unit:pbu.unit.id};
        if(pbu.id)
            newPbu.id=pbu.id;
        if(pbu.barcode)
            newPbu.barcode = pbu.barcode.trim();

        newProductBrand.productBrandUnits.push(newPbu);
    }

    return newProductBrand;
};

export const getUsedUnitsFromProductBrands=(productBrands)=>{

    if( !productBrands || !productBrands.length)
        return[];

    return  productBrands.reduce( (acc, pb)=>{
        if(!pb.productBrandUnits || !pb.productBrandUnits.length)
            return acc;
        return pb.productBrandUnits.reduce( (acc2, pbu)=>{
            if( pbu.unit &&
                !_.find(acc, (u)=>u.id===pbu.unit.id) &&
                !_.find(acc2, (u)=>u.id===pbu.unit.id) )
                acc2.push(pbu.unit);

            return acc2;
        }, acc);

    }, []);

};

export const testConversionRules = (unitBundle)=>{

    if(!unitBundle || !unitBundle.productBrands || !unitBundle.productBrands.length)
        return {success:1};

    const productUnits=getUnitsArray(unitBundle);

    if(unitBundle.displayUnit && !_.find(productUnits, unit=>unit.id===unitBundle.displayUnit.id))
        return {error:1, message:'Debes agregar una regla de conversión para la unidad '+unitBundle.displayUnit.name};

    for(let i=0; i<unitBundle.productBrands.length; i++){
        const pb=unitBundle.productBrands[i];
        if(!pb.productBrandUnits || !pb.productBrandUnits.length)
            continue;
        for(let j=0; j<pb.productBrandUnits.length; j++){
            const pbu=pb.productBrandUnits[j];
            if(!pbu.unit)
                continue;
            const rule=_.find(productUnits, unit=>unit.id===pbu.unit.id);
            if(!rule)
                return {error:1, message:'Debes agregar una regla de conversión para la unidad '+pbu.unit.name};
        }
    }

    for(let i=0; i<unitBundle.conversionRules.length; i++){
        const cr = unitBundle.conversionRules[i];
        if(!cr.unit)
            return {error:1, message:'Debes agregar una unidad en la regla de conversión '+(i+1)};
        if(!Number(cr.multiplier) || Number(cr.multiplier) < 0 )
            return {error:1, message:'Debes agregar un multiplicador válido en la regla de conversión '+(i+1)};
    }

    return {success:1};
};

export const productUnitConversionSGroups = [
    'product_read_id',
    'product_read_name',
    'product_read_smallest_unit',
    'product_read_display_unit',
    'measurement_unit_read',
    'product_read_conversion_rules',
    'unit_conversion_rule_read_id',
    'unit_conversion_rule_read_unit',
    'unit_conversion_rule_read_multiplier'
];
