import React, {useContext, useCallback, useMemo, useState} from 'react';
import CustomCheckbox from '../../../elements/CustomCheckbox/CustomCheckbox';
import {Creatable} from 'react-select';
import {FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import defaultDishImage from '../../../assets/img/default-orozco-product.png';
import {useDropzone} from 'react-dropzone';
import {ApiContext} from '../../../api/Api';
import CUModal from './CUModal';
import PricesCheckboxes from './PricesCheckboxes';
import {printAreas, productsGroups, productsTypes} from "../../../utils/modelUtils/orozco/orozcoProductUtils";
import EntitySelect from "../../../components/EntitySelect/EntitySelect";
import _ from 'lodash';

const setTextFromEvent = setter => e => setter(e.target.value);
const parseAmount = v => {
    const parsed = parseInt(v);
    if (Number.isNaN(parsed)) {
        return 0;
    }
    return Math.abs(parsed);
};

const useDishState = ({initialValues = {}} = {}) => {
    const [name, setName] = useState(initialValues.name || '');
    const [description, setDescription] = useState(initialValues.description || '');
    const [isExtraCharge, setIsExtraCharge] = useState(initialValues.isExtraCharge ||false)
    const [prices, setPrices] = useState(initialValues.prices || []);
    const [canBeFreeMeal, setCanBeFreeMeal] = useState(initialValues.canBeFreeMeal || false);
    const initialProductCategories = initialValues.orozcoProductCategories ?
        initialValues.orozcoProductCategories.map(({id}) => id) : [];
    const [orozcoProductCategories, setOrozcoProductCategories] = useState(initialProductCategories);
    const [printArea, setPrintArea] = useState(initialValues.printArea ? initialValues.printArea : printAreas.cocina);
    const initialProductModifiers = initialValues.orozcoProductModifiers ?
        initialValues.orozcoProductModifiers : [];
    const [orozcoProductModifiers, setOrozcoProductModifiers] = useState(initialProductModifiers);
    const initialLounges = initialValues.lounges ?
        initialValues.lounges.map(({id}) => id) : [];
    const [lounges, setLounges] = useState(initialLounges);
    const [realCost, setRealCost] = useState(initialValues.realCost || 0);
    const [requiredModifier, setRequiredModifier] = useState(initialValues.requiredModifier || 0);
    const [productGroup, setProductGroup] = useState(initialValues.productGroup ? initialValues.productGroup : productsGroups.INGRESO_EXTRA_GROUP);
    const [productType, setProductType] = useState(initialValues.productType ? initialValues.productType : productsTypes.BEBIDA_TYPE);

    const setPriceFor = useCallback(currency => e => {
        if (e === null) {
            setPrices(ps => _.filter(ps, p => p.currency !== currency));
            return;
        }
        const value = e.target ? (e.target.value || '') : '';
        const amount = value === '' ? '' : parseAmount(value * 100);
        const previous = _.findIndex(prices, {currency});
        if (previous === -1) {
            setPrices(ps => [...ps, {amount, currency}]);
            return;
        }
        setPrices(ps => [
            ...ps.slice(0, previous),
            {amount, currency},
            ...ps.slice(previous + 1)
        ]);
    }, [prices, setPrices]);


    const setCost = useCallback((e) => {
        const value = e.target ? (e.target.value || '') : '';
        const amount = value === '' ? '' : parseAmount(value * 100);
        setRealCost(amount);
    }, []);

    const setModifier = useCallback((e) => {
        const value = e.target ? (e.target.value || '') : '';
        const amount = value === '' ? '' : parseAmount(value);
        setRequiredModifier(amount);
    }, []);

    const valueFor = useMemo(() => ({
        name,
        description,
        canBeFreeMeal,
        prices,
        orozcoProductCategories,
        orozcoProductModifiers,
        lounges,
        printArea,
        isExtraCharge,
        isAvailable: true,
        realCost,
        requiredModifier,
        productGroup,
        productType
    }), [name, description, realCost,requiredModifier, prices, orozcoProductModifiers, orozcoProductCategories, lounges, canBeFreeMeal, printArea, productGroup, productType, isExtraCharge]);

    const onChangeFor = useMemo(() => ({
        name: setTextFromEvent(setName),
        description: setTextFromEvent(setDescription),
        canBeFreeMeal: setCanBeFreeMeal,
        isExtraCharge: setIsExtraCharge,
        prices: setPriceFor,
        orozcoProductCategories: setOrozcoProductCategories,
        orozcoProductModifiers: setOrozcoProductModifiers,
        printArea: setTextFromEvent(setPrintArea),
        lounges: setLounges,
        realCost: setCost,
        requiredModifier: setModifier,
        productGroup: setTextFromEvent(setProductGroup),
        productType: setTextFromEvent(setProductType)
    }), [setName, setDescription, setCost, setPriceFor, setModifier, setOrozcoProductModifiers, setOrozcoProductCategories, setLounges, setPrintArea, setProductGroup, setProductType]);

    const propsFor = useCallback(v => ({
        value: valueFor[v],
        onChange: onChangeFor[v],
    }), [valueFor, onChangeFor]);

    return {
        propsFor,
        state: valueFor
    };
};

const validation = state => {
    const {name, orozcoProductCategories, lounges, requiredModifier, orozcoProductModifiers} = state;
    let errors = [];
    if (!name) {
        errors.push('El nombre del producto no puede estar vacío.');
    }
    if (requiredModifier && requiredModifier > orozcoProductModifiers.length) {
        errors.push(`El producto debe tener los mismos o más modificadores de los requeridos en el formulario: ${requiredModifier}`);
    }
    if (!name) {
        errors.push('El nombre del producto no puede estar vacío.');
    }
    if (orozcoProductCategories.length === 0) {
        errors.push('El producto debe pertenecer al menos a una categoría.');
    }
    if (lounges.length === 0) {
        errors.push('El producto debe pertenecer al menos a una sala.');
    }
    return errors;
};

const DishModal = ({onDelete, onSubmit, submitText, title, onHide, initialValues = undefined, lounges = [], dishCategories = []}) => {
    const api = useContext(ApiContext);
    const {propsFor, state} = useDishState({initialValues});
    const [productPhoto, setProductPhoto] = useState(null);

    // Load dish categories and set it to State
    const loadCategoriesArray = [];
    const categoriesSelectedID = propsFor('orozcoProductCategories').value;
    dishCategories.map(c => {
        const isSelected = id => _.includes(categoriesSelectedID, id);
        if (isSelected(c.id)) {
            loadCategoriesArray.push(c);
        }

        return loadCategoriesArray;
    });
    //State with the dish categories
    const [categoryDishes, setCategoryDishes] = useState(loadCategoriesArray);

    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragActive,
        isDragReject
    } = useDropzone({onDrop: f => setProductPhoto(f[0])});

    const style = useMemo(() => ({
        ...dropzoneStyle,
        ...(isDragReject ? {borderColor: '#ff1744'} : {}),
        ...(isDragAccept ? {borderColor: '#00e676'} : {}),
        ...(isDragActive ? {borderColor: '#2196f3'} : {}),
    }), [isDragReject, isDragActive, isDragAccept]);

    const image = initialValues && initialValues.productPhoto ?
        api.appFiles.getUrl(initialValues.productPhoto.id) :
        defaultDishImage;

    // Edit EntitySelect Categories
    const editCategoryDishes = (element) => {
        const categoriesSelected = propsFor('orozcoProductCategories');
        const newEditCategoriesArray = [];
        element.map(value => {
            newEditCategoriesArray.push(value.id);

            return newEditCategoriesArray;
        });

        categoriesSelected.onChange(newEditCategoriesArray);
        setCategoryDishes([...element]);
    };

    return (
        <CUModal
            onDelete={onDelete}
            initialValues={initialValues}
            onSubmit={onSubmit}
            state={state}
            onHide={onHide}
            title={title}
            submitText={submitText}
            files={{productPhoto}}
            validation={validation}
        >
            <FormGroup>
                <ControlLabel>Nombre: </ControlLabel>
                <FormControl type='text' {...propsFor('name')}/>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Descripción: </ControlLabel>
                <FormControl type='text' {...propsFor('description')}/>
            </FormGroup>
            <FormGroup>
                <CustomCheckbox
                    onChange={propsFor('isExtraCharge').onChange}
                    isChecked={propsFor('isExtraCharge').value}
                    label='Es cargo extra'
                />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Precios: </ControlLabel>
                <PricesCheckboxes {...propsFor('prices')}/>
                <CustomCheckbox
                    onChange={propsFor('canBeFreeMeal').onChange}
                    isChecked={propsFor('canBeFreeMeal').value}
                    label='Puede ser cortesía'
                />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Costo teórico: </ControlLabel>
                <FormControl type='number'
                             {...{
                                 ...propsFor('realCost'),
                                 value: propsFor('realCost').value === '' ? '' : propsFor('realCost').value / 100
                             }}/>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Se imprime en:</ControlLabel>
                <select {...propsFor('printArea')} className='form-control'>
                    <option value={printAreas.cocina}>Cocina</option>
                    <option value={printAreas.bar}>Bar</option>
                    <option value={printAreas.dotcom}>Dotcom</option>
                </select>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Imagen: </ControlLabel>
                <div {...getRootProps({style})}>
                    <h4>Selecciona o arrastra el archivo</h4>
                    <img className='DishAdmin modal-product-picture' alt='Producto' src={image}/>
                    <input {...getInputProps()}/>
                    {productPhoto && (
                        <h4 style={{color: 'black'}}>{productPhoto.name}</h4>
                    )}
                </div>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Modificadores obligatorios del pedido: </ControlLabel>
                <FormControl
                    type='number'
                    min={0}
                    step={1}
                    {...{
                                 ...propsFor('requiredModifier'),
                                 value: propsFor('requiredModifier').value === '' ? '' : propsFor('requiredModifier').value
                    }}
                />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Modificaciones del platillo:</ControlLabel>
                <DishModifiers {...propsFor('orozcoProductModifiers')}/>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Categorías del platillo:</ControlLabel>
                <EntitySelect
                    value={categoryDishes}
                    onChange={editCategoryDishes}
                    entity='orozcoProductCategories'
                    placeholder='Escribe tu categoría...'
                    multi
                    initialLoad
                />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Grupo:</ControlLabel>
                <select {...propsFor('productGroup')} className='form-control'>
                    {_.map(productsGroups, (group, key) => (
                        <option key={key} value={group}>{group}</option>
                    ))}
                </select>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Tipo:</ControlLabel>
                <select {...propsFor('productType')} className='form-control'>
                    {_.map(productsTypes, (type, key) => (
                        <option key={key} value={type}>{type}</option>
                    ))}
                </select>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Salas en las que está disponible:</ControlLabel>
                <LoungesCheckboxes lounges={lounges} {...propsFor('lounges')}/>
            </FormGroup>
        </CUModal>
    );
};


const modifierToCreatable = ms => ms.map(({name}) => ({value: name, label: name}));
const DishModifiers = ({value, onChange}) => {
    const onCreatableChange = vs => {
        const currentNames = value.map(({name}) => name);
        const namesToAppear = vs.map(({value}) => value);
        const filtered = value.filter(v => _.includes(namesToAppear, v.name));
        const added = namesToAppear
            .filter(n => !_.includes(currentNames, n))
            .map(name => ({name}));
        onChange([
            ...filtered,
            ...added
        ]);
    };
    return (
        <Creatable
            multi
            onChange={onCreatableChange}
            value={modifierToCreatable(value)}
            options={[{label: 'Agrega el modificador'}]}
            placeholder='Escribe el nombre del modificador'
        />
    );
};

const LoungesCheckboxes = ({lounges, value, onChange}) => {
    const isChecked = id => _.includes(value, id);
    const changed = id => () => onChange(
        isChecked(id) ? _.filter(value, v => v !== id) :
            [...value, id]
    );
    return (
        <div>
            {lounges.map(l => (
                <CustomCheckbox onChange={changed(l.id)} isChecked={isChecked(l.id)} key={l.id} label={l.name} inline/>
            ))}
        </div>
    );
};

const dropzoneStyle = {
    cursor: 'grab',
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: '#eeeeee',
    backgroundColor: '#fafafa',
    padding: '60px',
    color: '#bdbdbd',
    outline: 'none',
    borderRadius: 2,
    alignItems: 'center',
    textAlign: 'center'
};

export default DishModal;
