import React, {useState, useCallback, useRef, useEffect} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import useInput from "../../../hooks/useInput";
import EmployeePositionPermissions from "../../../components/Permissions/EmployeePositionPermissions";
import Switch from "react-bootstrap-switch";
import switchTranslations from '../../../utils/switchTranslations';

const RepositoryFileFormModal = ({onHide, loading, onSave, isFolder, parent, repositoryFile})=>{

    const nameIn = useInput(repositoryFile?repositoryFile.name:'');
    const [ permissions, setPermissions ] = useState(repositoryFile?repositoryFile.permissions:[]);
    const [ notifyEmployees, setNotifyEmployees] = useState(false);

    const file = useRef(null);

    useEffect(()=>{//Inherit permissions from parent
        if( !parent || repositoryFile)//If it's not in the root folder and we are not editing, set initial permissions
            return;

        let newPermissions = [];
        if(parent.permissions)
            newPermissions = parent.permissions.map((perm)=>{
                const newPerm = {...perm};
                delete newPerm.id;
                return newPerm;
            });
        setPermissions( newPermissions );

    },[parent, repositoryFile]);

    const handleSave = useCallback(()=>{

        const formattedPermissions = permissions.map( perm=>
            perm.id?
                perm.id:
                {employee: perm.employee? perm.employee.id:null, position: perm.position? perm.position.id: null}
                );

        const repFile = {
            permissions:formattedPermissions,
            notifyEmployees
        };
        if( isFolder )
            repFile.name = nameIn.value.trim();
        if(repositoryFile)
            repFile.id = repositoryFile.id;

        onSave(repFile , file.current);

    },[nameIn, permissions, onSave, isFolder, notifyEmployees, repositoryFile]);

    const handleFileChange = useCallback(({target})=>{
        if(!target || !target.files || !target.files.length )
            file.current = null;
        else
            file.current = target.files;
    },[]);

    const folder = repositoryFile? repositoryFile.isFolder: isFolder;

    return (
        <Modal
            show={true}
            onHide={onHide}
            bsSize='lg'
            backdrop='static'
        >
            <ModalHeader closeButton>
                <ModalTitle>
                    {folder?"Carpeta":"Archivo"}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                {folder ?
                    <>
                        <p>Nombre de la carpeta:</p>
                        <input className='form-control'
                               placeholder='Nombre'
                               {...nameIn.bind}
                        />
                    </> :
                      repositoryFile?
                          <p>Archivo: {repositoryFile.name}</p>
                          :
                          <>
                            <p>Selecciona el archivo:</p>
                            <input className='form-control'
                                   type='file'
                                   onChange={handleFileChange}
                                   multiple
                            />
                        </>
                }

                <br/><br/>
                <p>Permisos:</p>
                <p className='small text-muted'>Deja vacíos los permisos para permitir que toda la compañía tenga acceso.</p>
                <EmployeePositionPermissions
                    permissions={permissions}
                    onPermissionsChange={setPermissions}
                    />

                { !repositoryFile && !isFolder?
                    <div>
                        <p>Enviar notificación a empleados:</p>
                        <Switch
                            {...switchTranslations}
                            value={notifyEmployees}
                            onChange={(s, value)=>setNotifyEmployees(value)}
                        />
                    </div>
                    :null}

            </ModalBody>

            <ModalFooter>
                <CustomButton
                    onClick={onHide}
                    bsStyle='danger'
                    className='pull-left'
                    disabled={!!loading}
                >
                    Cancelar
                </CustomButton>
                <CustomButton
                    bsStyle='success'
                    onClick={handleSave}
                    disabled={!!loading}
                >
                    Guardar
                </CustomButton>
            </ModalFooter>

        </Modal>
    );
};

export default RepositoryFileFormModal;
