import React, {Component} from 'react';
import StatsCard from '../../../../components/Card/StatsCard';
import moneyFormatter from "../../../../utils/moneyFormatter";
import {connect} from 'react-redux';
import LoaderIcon from '../../../../components/Helper/LoaderIcon';
import {NavLink} from 'react-router-dom';


//Requires api.payrollDetailsRealTime.get();

class PPayrollTotal extends Component {

    getInfoString(){

        if( this.props.payrollDetails && this.props.payrollDetails.constructor === Array ){

            const amount = this.props.payrollDetails.reduce(
                (acc, cur)=>{return acc + cur.total},
                0
            );
            return moneyFormatter(amount);

        }
        else{
            return <LoaderIcon/>
        }
    }

    render() {

        return (
            <StatsCard
                bigIcon={<i className="fa fa-dollar text-success" />}
                statsText="Nómina quincenal"
                statsValue={ this.getInfoString() }
                statsIcon={<NavLink to="/alma/payroll/detail/actual"><i className="fa fa-eye" /></NavLink>}
                statsIconText={<NavLink to="/alma/payroll/detail/actual">Ver detalles</NavLink>}
            />
        );
    }
}

function mapStateToProps( {api:{payrollDetails}} ){

    return {payrollDetails}
}

export default connect(mapStateToProps)(PPayrollTotal);
