import React, {Component} from 'react';
import StatsCard from '../../../../components/Card/StatsCard';
import {connect} from 'react-redux';
import LoaderIcon from '../../../../components/Helper/LoaderIcon';
import moneyFormatter from "../../../../utils/moneyFormatter";
import {NavLink} from 'react-router-dom';


//Requires api.payrollDetailsRealTime.get();

class PAverageWage extends Component {

    getAverageAmountString(){

        if( this.props.payrollDetails && this.props.payrollDetails.constructor === Array ){

            const sum = this.props.payrollDetails.reduce(
                (acc, cur)=>{
                    if( cur.salary ){
                        acc.total += cur.total;
                        acc.employees ++;
                        return acc;
                    }
                    else{
                        return acc;
                    }
                },
                {total:0,employees:0}
            );

            return moneyFormatter(sum.total/sum.employees);

        }
        else{
            return <LoaderIcon/>
        }
    }

    render() {

        return (
            <StatsCard
                bigIcon={<i className="fa fa-user text-primary" />}
                statsText="Pago promedio quincenal"
                statsValue={ this.getAverageAmountString() }
                statsIcon={<NavLink to="/alma/payroll/detail/actual"><i className="fa fa-eye" /></NavLink>}
                statsIconText={<NavLink to="/alma/payroll/detail/actual">Ver detalles</NavLink>}
            />
        );
    }
}

function mapStateToProps( {api:{payrollDetails}} ){

    return {payrollDetails}
}

export default connect(mapStateToProps)(PAverageWage);