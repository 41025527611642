import {useState, useRef, useCallback} from 'react';


export default (initialState={})=>{

    const [formState, setFormState]=useState(initialState);

    const formRef = useRef(initialState);
    formRef.current=formState;

    const handleChange=  e=>setFormState({...formRef.current, [e.target.name]:e.target.value});

    const reset=useCallback(()=>setFormState(initialState),[initialState]);

    return {
        field: (name)=>({
            name,
            onChange:handleChange,
            value:typeof formState[name]==="undefined" || formState[name]===null?'':formState[name],
            }),
        values:formState,
        reset
        }
};
