import React,{useCallback,useContext} from 'react';
import useTideTable from "../../../hooks/table/useTideTable";
import moment from 'moment';
import ReactTable from "react-table";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import useBoolean from "../../../hooks/useBoolean";
import NewWarehouseCountModal from "./NewWarehouseCountModal";
import {maxDecimals} from "../../../utils/MathUtils";
import {SecurityContext} from "../../../utils/SecurityManager";
import { shiftsList } from '../../../utils/shiftsUtils';

const WarehouseCountList=({history})=>{

    const warehouseCountsTable = useTideTable({
        entity:'warehouseCounts',
        columns: tableStructure,
    });

    const security=useContext(SecurityContext);

    const [creatingCount, startCreatingCount, stopCreatingCount]=useBoolean();

    const goToDetail=useCallbackCreator((id)=>{
        history.push('/almazen/warehouse-count/'+id)
    },[history]);

    const createClickHandler=useCallback((table, rowInfo)=>rowInfo?
        ({onClick:goToDetail(rowInfo.original.id)}): {}
        ,[goToDetail]);

    return (
        <div className='WarehouseCountList container-fluid'>
            <br/>
            {security.almazen.canCreateWarehouseCounts() &&
            <CustomButton bsStyle='primary' className='pull-right' onClick={startCreatingCount}>Crear nuevo conteo</CustomButton>}
            <div className='clearfix'/>

            <ReactTable
                {...warehouseCountsTable.tableProps}
                getTrProps={createClickHandler}
                />

            {creatingCount&&
                <NewWarehouseCountModal
                    onHide={stopCreatingCount}
                    />
            }
        </div>
    );
};

export default WarehouseCountList;

const tableStructure=[
    {
        Header:'# de conteo',
        accessor:'id',
    },
    {
        Header:'Realizó',
        accessor:'completedBy.name',
    },
    {
        Header:'Almacén',
        accessor:'warehouse.name',
    },
    {
        Header:'Recibió',
        accessor:'receivedBy.name',
    },
    {
        Header:'Creación',
        accessor:(warehouseCount)=>moment(warehouseCount.createdDate).format('DD/MM/YYYY HH:mm'),
        id:'createdDate',
        filterable: false,
    },
    {
        Header:'Terminada',
        accessor:(warehouseCount)=>warehouseCount.completedDate?
            moment(warehouseCount.completedDate).format('DD/MM/YYYY HH:mm'):
            'Sin terminar',
        id:'completedDate',
        filterable: false,
    },
    {
        Header:'Confiabilidad',
        accessor:(warehouseCount)=>warehouseCount.completedDate?
            `${maxDecimals(warehouseCount.reliability,2)}%`:
            'Sin terminar',
        id:'reliability',
        filterable: false,
    },
    {
        Header:'Tipo',
        accessor:(warehouseCount)=>warehouseCount.isRandom?'Aleatorio':'Fijo',
        id:'isRandom',
        filterable: false,
    },
    {
        Header:'Turno',
        accessor: (warehouseCount)=> shiftsList.find(shift => shift.key===warehouseCount?.shiftType)?.value,
        id:'shiftType'
    },
];
