import {useEffect, useRef} from 'react';

const usePageTitle = (title) => {

    const initialTitle = useRef(document.title);

    useEffect(() => {
        document.title = title;
        const restoreTitle = initialTitle.current;
        return () => {
            document.title = restoreTitle;
        }
    }, [title]);
}

export default usePageTitle;
