import React, {useContext, useState} from 'react';
import {SecurityContext} from '../../../../utils/SecurityManager';
import {SingleDatePicker} from 'react-dates';
import moment from 'moment';
import useSingleDatePickerState from "../../../../hooks/useSingleDatePickerState";

const POExpectedDate = ({expectedDate, onEditSave}) => {

    const datePicker = useSingleDatePickerState(expectedDate? moment(expectedDate):undefined);
    const [editing, setEditing] = useState(false);
    const security = useContext(SecurityContext);
    const canEdit = security.jazz.canAdjustPurchaseOrders();

    return (
        <span className="POExpectedDate small">
            {expectedDate?'Fecha estimada de llegada: '+moment(expectedDate).format('DD/MM/YYYY'):'La orden no tiene fecha de llegada estimada'}{' '}
            {(canEdit&&!editing)&&<button onClick={()=>setEditing(true)} className='edit-btn'><i className='fa fa-edit'/></button>}
            {editing&&(
                <>
                    <SingleDatePicker
                        {...datePicker.props}
                        placeholder='Selecciona...'
                    />
                    <button className='edit-btn' onClick={()=>setEditing(false)}>
                        <i className='fa fa-times'/>
                    </button>
                    <button className='edit-btn' onClick={()=>{
                        setEditing(false);
                        onEditSave(datePicker.date);
                    }}>
                        <i className='fa fa-check'/>
                    </button>
                </>
            )}
        </span>
    );
};

export default POExpectedDate;
