import React, {useContext, useState} from 'react';
import moment from 'moment';
import useBoolean from "../../../../hooks/useBoolean";
import classNames from 'classnames';
import {DateRangePicker} from "react-dates";
import useDateRangePickerState from "../../../../hooks/useDateRangePickerState";
import TideEntitySelect from "../../../../components/TideEntitySelect/TideEntitySelect";
import {querySerialize} from "../../../../api/Api";
import {TideApiContext} from "../../../../api/tideApiConfig";
import {NotifierContext} from "../../../../utils/Notifier";

const productLinesFilters = {isActive: true};

const RequisitionsReport = () => {

    const notifier = useContext(NotifierContext);
    const api = useContext(TideApiContext);

    const [isOpen, , , toggleOpen] = useBoolean();

    const {props, dateRange} = useDateRangePickerState({
	startDate: moment().subtract(1, 'month'),
	endDate: moment()
    });
    const [productLine, setProductLine] = useState();
    const [warehouse, setWarehouse] = useState();

    const valid = warehouse&&productLine&&dateRange.startDate&&dateRange.endDate;
    const query = valid&&querySerialize({
	warehouse: warehouse.id,
	productLine: productLine.id,
	from: dateRange.startDate.format('YYYY-MM-DD'),
	to: dateRange.endDate.format('YYYY-MM-DD'),
	token: api.token
    });
    const handleClick = (e)=>{
        if(!valid){
            e.preventDefault();
            notifier.error('Llena todos los filtros');
        }
    };
    const reportUrl = valid?`${api.host}/api/inventories/input-requisitions-report?${query}`:'#';

    return (
        <div className={classNames("report-block", isOpen && 'open')}>
            <p className='title' onClick={toggleOpen}>Traza de requisiciones por categoría<i className='fa fa-caret-down caret-icon'/>
            </p>
            {isOpen &&
            <div className='body'>
                <hr/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <p className='filter-label'>Almacén:</p>
                            <TideEntitySelect
                                entity='warehouses'
                                value={warehouse}
                                onChange={setWarehouse}
                                filterLocal
                            />
                            <p className='filter-label'>Categoría:</p>
                            <TideEntitySelect
                                entity='productLines'
                                value={productLine}
                                onChange={setProductLine}
                                additionalFilters={productLinesFilters}
                                filterLocal
                            />
                            <p className='filter-label'>Rango de fechas:</p>
                            <DateRangePicker {...props} />
                        </div>
                        <div className='col-md-6'>
                            <p className='filter-label'>Descripción:</p>
                            <p className='description'>Nos permite conocer el costo, la cantidad y el inventario de la categoría.</p>
                        </div>
                    </div>
                </div>
                <br/>
                <a onClick={handleClick} href={reportUrl} rel='noopener noreferrer'>
                    <button className='btn btn-info'>Descargar</button>
                </a>
            </div>}
        </div>
    );
}

export default RequisitionsReport;
