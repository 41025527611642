import React, {useContext, useState} from 'react';
import useBoolean from "../../../../hooks/useBoolean";
import classNames from 'classnames';
import TideEntitySelect from "../../../../components/TideEntitySelect/TideEntitySelect";
import {TideApiContext} from "../../../../api/tideApiConfig";
import {getInventoryValueDownloadUrl} from "../../../../utils/modelUtils/almazen/inventoryUtils";

const InventoryValueReport = () => {

    const api = useContext(TideApiContext);
    const [isOpen,,,toggleOpen] = useBoolean();
    const [warehouse, setWarehouse] = useState();

    const link = getInventoryValueDownloadUrl(api, warehouse);

    return (
        <div className={classNames("report-block", isOpen&&'open')}>
            <p className='title' onClick={toggleOpen}>Valor del inventario <i className='fa fa-caret-down caret-icon'/></p>
            {isOpen&&
            <div className='body'>
                <hr/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <p className='filter-label'>Almacén:</p>
                            <TideEntitySelect
                                entity='warehouses'
                                value={warehouse}
                                onChange={setWarehouse}
                            />
                        </div>
                        <div className='col-md-6'>
                            <p className='filter-label'>Descripción:</p>
                            <p className='description'>
                                Exporta el inventario actual de los productos en cada almacén incluyendo el valor monetario de cada artículo.<br/>
                                Selecciona un almacén o deja el campo vacío para exportar el conteo de todos los almacenes.
                            </p>
                        </div>
                    </div>
                </div>
                <br/>
                <a href={link} target='_blank' rel='noopener noreferrer'>
                    <button className='btn btn-info'>Descargar</button>
                </a>

            </div>}
        </div>
    );
};

export default InventoryValueReport;
