import React, {useContext, useState} from 'react';
import EntitySelect from "../../../../components/TideEntitySelect/TideEntitySelect";
import Button from "../../../../elements/CustomButton/CustomButton";
import ClassicModal from "../../../../components/Modals/ClassicModal";
import {TideApiContext} from "../../../../api/tideApiConfig";
import {NotifierContext} from "../../../../utils/Notifier";
import {useSelector} from "react-redux";
import {productLineListSGroups} from "../../../../utils/modelUtils/almazen/productLineUtils";

const ProductLineFormModal = ({onClose, productLine, onActivateToggle, isActive}) => {

    const [parent, setParent] = useState(productLine?.parentLine);
    const [name, setName] = useState(productLine?.name||'');
    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);

    const loadingId = "@ProductLineFormModal.productLines";
    const handleSave = ()=>{
        if(!name.trim())
            return notifier.error("Debes ingresar un nombre");
        const params = { name, parentLine: parent?.id, sGroups: productLineListSGroups };
        api.productLines[ productLine?"update":"create" ]( { id:productLine?.id, params, loadingId } )
            .then(()=>{
                notifier.success("Línea guardada.")
                onClose();
            });
    }

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    return (
        <ClassicModal
            title={(productLine? "Editar":"Nueva")+" línea"}
            className={"ProductLineFormModal"}
            onHide={onClose}
            onSave={handleSave}
            disabled={loading}
        >
            <label className='control-label'>Nombre:</label>
                <input className='form-control' value={name} onChange={e=>setName(e.target.value)}/>
            <br/>
            <label>Línea padre:</label>
                <EntitySelect
                    entity='productLines'
                    value={parent}
                    onChange={setParent}
                />
            <br/>
            <Button bsStyle={productLine.isActive?'danger':'success'} className="mt-5" onClick={() => {onActivateToggle(productLine)}}>{productLine.isActive?'Desactivar':'Activar'}</Button>



        </ClassicModal>
    );
};

export default ProductLineFormModal;
