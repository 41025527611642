import React, {useContext} from 'react';
import useTideTable from "../../../../hooks/table/useTideTable";
import {Link, useHistory} from "react-router-dom";
import {paths} from "../../../../routes/katharsis";
import ReactTable from "react-table";
import {orozcoRecipeListColumns, orozcoRecipeListSGroups} from "../../../../utils/modelUtils/orozco/orozcoRecipeUtils";
import Button from "../../../../elements/CustomButton/CustomButton";
import {ApiContext} from "../../../../api/Api";
import useReduxTableState from "../../../../hooks/table/useReduxTableState";

const filters = {sGroups:orozcoRecipeListSGroups};

const OrozcoRecipeList = () => {

    const api = useContext(ApiContext);

    const reduxTableState = useReduxTableState('@OrozcoRecipeList');
    const table = useTideTable({
        entity:'orozcoRecipes',
        columns: orozcoRecipeListColumns,
        requestFilters:filters
    });

    const history = useHistory();

    const createRowProps = (_, {original}={})=>{
        if(!original)
            return {};
        return ({
            onClick: ()=>history.push(
                paths.recipesEditor
                    .replace(':type', original.orozcoProduct?'product':'recipe')
                    .replace(':id', original?.orozcoProduct?.id||original?.id)
            )
        });
    }

    return (
        <div className={"OrozcoProductList main-container"}>
            <div className='card'>
                <Link to={paths.recipesEditor.replace(':type', 'recipe').replace(':id', 'new')}>
                    <button className='btn btn-info'>Nueva receta</button>
                </Link>
                <ReactTable
                    {...table.tableProps}
                    getTrProps={createRowProps}
                    {...reduxTableState.tableProps}
                />
                <a
                    href={ api.orozcoRecipes.exportUrl({...(table?.lastUsedApiOptions?.filters||filters), pagination:false})}
                    rel="noopener noreferrer"
                >
                    <Button>Exportar a Excel</Button>
                </a>
            </div>
        </div>
    );

};

export default OrozcoRecipeList;
