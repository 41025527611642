import React, {useContext, useMemo, useEffect} from 'react';
import {ApiContext} from '../../../../../api/Api';
import ReactEcharts from "echarts-for-react";
import {useSelector} from 'react-redux';
import _ from 'lodash/fp';

const loadingId='@DestinationsStats.report';
const colors = [
    '#003d5b',
    '#30638e',
    '#edae49',
    '#ee6352',
    '#00798c',
    '#102542',
    '#06a77d',
    '#5a0001',
    '#f3dfa2',
    '#bb4430'
];
const dataTitle = v=>v.destination;
const dataItem = (v, k)=>({
    value: v.totalOfVisits,
    itemStyle: {color: colors[k]}
});
const getOption = data => ({
    title:{
        text:'Top 10 destinos'
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    xAxis: {
        type: 'category',
        data: data.map(dataTitle),
        axisTick: {
            alignWithLabel: true,
        },
        axisLabel: {
            interval: 0
        }
    },
    yAxis: {
        type: 'value'
    },
    series: [{
        data: data.map(dataItem),
        type: 'bar'
    }]
});
const DestinationStats = ({loungeAccessMethod: lam, dateRequest}) => {
    const api=useContext(ApiContext);
    const params=useMemo(()=>({
        ...dateRequest,
        ...(lam?{loungeAccessMethods: [lam]}:{}),
    }), [dateRequest, lam]);
    useEffect(()=>{
        api.flights.visitDestinationsReport({
            loadingId,
            params
        });
    },[api, params]);
    const report = useSelector(({api})=>api.visitDestinationsReport)||[];
    const values = useMemo(
        ()=>_.sortBy(r=>-r.totalOfVisits, report).slice(0,colors.length),
        [report]
    );
    const option = useMemo(
        ()=>getOption(_.reverse(values)),
        [values]
    );
    return (
        <ReactEcharts option={option}/>
    );
};

export default DestinationStats;
