import React, {useContext, useEffect, useMemo, useState} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Row} from "react-bootstrap";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import _ from "lodash";
import ReactEcharts from "echarts-for-react";


const FlightsGraphReport = () => {
    const api = useContext(ApiContext);
    const [dates, setDates] = useState({from: moment().date(1), to: moment()});
    const onDateChange = (e, {startDate, endDate}) => {
        setDates({from: moment(startDate), to: moment(endDate)});
    };

    const filters = useMemo(() => {
        const from = dates.from.format('YYYY-MM-DD');
        const to = dates.to.format('YYYY-MM-DD');
        return {from, to}
    }, [dates])

    useEffect(() => {
        api.flights.destinationTypesReport({params: filters});
    }, [api, dates, filters]);


    const report = useSelector(({api}) => api['destinationTypesReport'] || null);

    const localVsInternationalReportConfig = useMemo(() => {
            if (!report)
                return null;
            const seriesLabel = {
                normal: {
                    show: true,
                    textBorderColor: '#333',
                    textBorderWidth: 2
                }
            }
            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: ['Nacionales', 'Internacionales', 'Sin clasificar']
                },
                grid: {
                    left: 100
                },

                xAxis: {
                    type: 'value',
                    name: 'Vuelos',
                    axisLabel: {
                        formatter: '{value}'
                    }
                },
                yAxis: {
                    type: 'category',
                    inverse: true,
                    data: [''],

                },
                series: [
                    {
                        name: 'Nacionales',
                        type: 'bar',
                        data: [report.localVsInternationalCount.localFlights],
                        label: seriesLabel
                    },
                    {
                        name: 'Internacionales',
                        type: 'bar',
                        label: seriesLabel,
                        data: [report.localVsInternationalCount.internationalFlights]
                    },
                    {
                        name: 'Sin clasificar',
                        type: 'bar',
                        label: seriesLabel,
                        data: [report.localVsInternationalCount.unknownFlights]
                    }
                ]
            }
        },
        [report]
        )
    ;

    const destinationTypesConfig = useMemo(() => {
            if (!report)
                return null;

            const labels = _.map(report.countByDestinationTypes, (entry) => entry.destinationTypeName ? entry.destinationTypeName : 'Sin clasificar');

            const data = _.map(report.countByDestinationTypes, (entry) => ({
                value: entry.flightsCount,
                name: entry.destinationTypeName ? entry.destinationTypeName : 'Sin clasificar'
            }));
            return {
                label: {
                    formatter: '{b}： {d}%'
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    data: labels
                },
                series: [
                    {
                        name: 'Tipo de destino',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data
                    }
                ]
            }
        },
        [report]
        )
    ;


    const airlinesConfig = useMemo(() => {
            if (!report)
                return null;
            const reportByAirlines = _.groupBy(report.countByAirlineDestinationTypes, 'airlineName');
            const destinationTypes = _.uniq(_.map(report.countByAirlineDestinationTypes, 'destinationTypeName'));
            const destinationTypesLabels = _.map(destinationTypes,(dt)=>dt!==null?dt:'Sin clasificar');
            const labels = _.keys(reportByAirlines);


            const series = _.map(destinationTypes, (destinationType) => {
                const data = _.map(reportByAirlines, (entry)=>{
                    const result = _.find(entry, (en)=>{
                        return en.destinationTypeName===destinationType;
                    });
                    if(!result)
                        return 0;

                    return result.flightsCount;
                })

                return {
                    name:destinationType?destinationType:'Sin clasificar',
                    type: 'bar',
                    stack: 'P',
                    label: {
                        show: false
                    },
                    data
                }
            });

            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: destinationTypesLabels
                },
                grid: {
                    left: '10%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: {
                    type: 'category',
                    data: labels
                },
                series
            }
        },
        [report]
        )
    ;

    return (
        <div className='FlightsGraphsReport'>

            <Row>
                <DateRangePicker
                    startDate={dates.from}
                    endDate={dates.to}
                    onApply={onDateChange}
                    containerStyles={{}}
                >

                    <CustomButton
                        bsStyle={'primary'}
                        className='col-xs-12 range-button'
                    >
                        Rango de fechas &nbsp;
                        <strong>{dates.from.format('YYYY-MM-DD')}</strong> al <strong>{dates.to.format('YYYY-MM-DD')}</strong>
                    </CustomButton>

                </DateRangePicker>
            </Row>

            {report &&
            <>
                <h3>Alcance de vuelos </h3>
                <ReactEcharts option={localVsInternationalReportConfig}/>

                <h3>Tipos de destinos</h3>
                <ReactEcharts option={destinationTypesConfig}/>

                <h3>Tipos de destinos por aerolinea</h3>
                <ReactEcharts style={{height: '1000px'}} option={airlinesConfig}/>

            </>}


        </div>
    );
};

export default FlightsGraphReport;
