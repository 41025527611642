import {reportHeaders as headersCreator} from '../../xlsxReports';

export const reportSGroups = [
    'creation_date',
    'reservation_read_reservation_date',
    'reservation_read_guest_full_name',
    'reservation_read_requested_services_string'
];

const reportProperties = [
    {
        header: 'Reservación creada el',
        property: 'createdDate'
    },
    {
        header: 'Fecha de reservación',
        property: 'reservationDate'
    },
    {
        header: 'Huésped',
        property: 'guestFullName'
    },
    {
        header: 'Servicios reservados',
        property: 'requestedServicesString'
    }
];

const reportHeaders = headersCreator(reportProperties);


export const sourceStrings = (str) => {
    switch (str) {

        case 'web':
            return 'Formulario web';
        case 'app':
            return 'App TGLE';
        case 'phone':
            return 'Teléfono';
        case 'facebook':
            return 'Facebook';
        case 'whatsapp':
            return 'Whatsapp';
        case 'personal':
            return 'En persona';
        case 'other':
            return 'Otro';
        default:
            return str;

    }
};

export const xlsReportConfig = {
    name: 'Reservaciones.xlsx',
    headers: reportHeaders
};
