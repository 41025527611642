import React, {Component} from 'react';
import ListEmployees from './ListEmployees';

export default class ListFiredEmployees extends Component {

    render() {

        return (
            <ListEmployees
                areHired={false}
            />
        );
    }
}