import _ from "lodash";

export const SEPARATOR = '|';

/**
 * Construct a header object from one given entry
 * After getting all the headers they can be merged (_.merge) to have one object presenting the header structure
 * Example:
 * Given
 *   entry = {
 *       "layoffs": "2",
 *       "area": "SISTEMA DE GESTION Y CALIDAD",
 *       "shift": "M",
 *       "position": "AUXILIAR EN TECNOLOGÍA "
 *   }
 *   xFilters = [
 *      { name:"Área", value:"area" },
 *      { name:"Turno", value:"shift" },
 *   ]
 *
 *   The returned value would be:
 *   {
 *       "SISTEMA DE GESTION Y CALIDAD":{
 *           size:1,
 *           subHeaders: {
 *               "M":{
 *                   size: 1
 *               }
 *           }
 *       }
 *   }
 *
 *   Because "position" was not in "xFilters" it was ignored
 *   The tree hierarchy will be given by the "xFilters" array order
 *
 * @param entry An object with each xFilter value as a property, this property will be the heade name
 * @param xFilters An array with the properties from which the headers are obtained
 * @param yFilter An object describing the filter for rows
 * @returns {{}}
 */
export const getHeaders = ( entry, xFilters, yFilter)=>{

    if( xFilters.length > 1 ) {
        const restFilters = [ ...xFilters ];
        restFilters.splice(0,1);

        const subHeaders = getHeaders(entry, restFilters, yFilter);
        return { [ entry[ xFilters[0].value ]||"" ]: { name:entry[ xFilters[0].value ]||"", size: 1, subHeaders } };
    }

    const name = xFilters[0]? (entry[ xFilters[0].value ]||""):"";
    if( yFilter )
        return { [name]: { name, size: 1, [entry[yFilter.value]]: {count: entry.count} }  };
    else
        return { [name]: { name, size: 1, count: entry.count }  };
};


/**
 *
 * Computes the size of each header recursively
 * This means counting all the final leaves in the tree structure under the given headers
 * It will set a "size" property inside every "header" object which contains the count of final leaves underneath
 *
 *
 * @param headers
 */
export const setHeadersSize = ( headers )=>{

    _.forEach( headers, ( header )=>{

        if( header.subHeaders && Object.keys(header.subHeaders).length ) {
            setHeadersSize(header.subHeaders);
        }
        else
            return;

        let size = 0;
        _.forEach( header.subHeaders, ( subheader )=>size += subheader.size );
        header.size = size;
    });

};

/**
 * Converts a tree like headers object to an Array of arrays recursively.
 * The outter array has one entry for each tree level, the inner arrays have one entry for each node in that level.
 * Example:
 * headers = {
 *     "A":{
 *         name: "A",
 *         subHeaders:{
 *             "B":{name: "B"},
 *             "C":{name: "C"},
 *         }
 *     },
 *     "D":{
 *         name: "D",
 *         subHeaders:{
 *             "E":{name: "E"},
 *             "F":{name: "F"},
 *         }
 *     }
 * }
 *
 * returns [
 *     [ {name: "A" }, {name: "D"} ],
 *     [ {name: "B" }, {name: "C"}, {name: "E" }, {name: "F"} ],
 * ]
 *
 * @param headers
 * @returns {*[]}
 */
export const headersObjectToArrays = ( headers )=>{

    const newLevel = [];
    const subLevels = [];

    _.forEach( headers, ( header )=>{
        newLevel.push( header );
        if( header.subHeaders ) {
            const subRows = headersObjectToArrays(header.subHeaders);
            _.mergeWith(subLevels, subRows, (a, b) => _.concat(a||[], b||[]));
        }
    } );

    return [ newLevel, ...subLevels ];
};

/**
 * Extract all the available codes from a headers object.
 * There will be one code for each final leaf in the tree.
 * A code is the concatanation of all the names from the first leaf to each last one.
 * Joined with SEPARATOR in between.
 *
 * Each code is a column in the table.
 *
 * Example:
 * SEPARATOR = '|'
 * headers = {
 *     "A":{
 *         name: "A",
 *         subHeaders:{
 *             "B":{name: "B"},
 *             "C":{name: "C"},
 *         }
 *     },
 *     "D":{
 *         name: "D",
 *         subHeaders:{
 *             "E":{name: "E"},
 *             "F":{name: "F"},
 *         }
 *     }
 * }
 *
 * returns [ 'A|B','A|C','D|E','D|F' ]
 *
 * The codes are then used as coordenates in the table to extract data of each cell.
 *
 * @param headers
 * @param separator
 * @returns {Array}
 */
export const getColumnsCodes = ( headers, separator )=>{

    let columns = [];
    _.forEach( headers, ( header )=>{

        if( header.subHeaders && Object.keys(header.subHeaders).length ){

            const subColumns = getColumnsCodes( header.subHeaders, separator );
            const newColumns = subColumns.map( (col)=>(header.name + separator + col) );
            columns = columns.concat( newColumns );
        }
        else{
            columns.push( header.name );
        }
    });

    return columns;
};

/**
 * Gets all different values found in the report array under the yFilter key
 *
 * @param report
 * @param yFilter
 * @returns {Array}
 */
export const getRowHeaders = ( report, yFilter )=>{

    if( !yFilter )
        return [''];

    let rowHeaders = [];

    _.forEach( report, ( entry )=>{
        rowHeaders = _.union( rowHeaders, [ entry[ yFilter.value ] ] );
    });

    return rowHeaders;
};

/**
 *
 * @param report Array
 * @param xFilters
 * @param yFilter
 * @param codeSeparator
 * @returns {{rowHeaders: (Array|string[]), columnsCodes: Array, data, headerRows: *[]}}
 */
const buildTableData = ( report, xFilters, yFilter, codeSeparator= SEPARATOR )=>{

    const headers = {};

    _.forEach( report, ( entry )=>{

        const newHeaders = getHeaders( entry, xFilters, yFilter );
        _.merge( headers, newHeaders );
    });

    setHeadersSize( headers );

    const headerRows = headersObjectToArrays( headers );
    const columnsCodes = getColumnsCodes( headers, codeSeparator );
    const rowHeaders = getRowHeaders( report, yFilter );

    return { headerRows, columnsCodes, rowHeaders, data: headers };
};

export default buildTableData;