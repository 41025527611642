import React from 'react';


export default function YesNoAnswer( props ){

    const clickYes = ()=>{
        props.onChange( {response:true} );
    };

    const clickNo = ()=>{
        props.onChange( {response:false} );
    };

    let answer;
    if( props.data && props.data.response === true )
        answer = 'yes';
    else if( props.data && props.data.response === false )
        answer = 'no';

    return (
        <div className='answer-component YesNoAnswer'>
            <button className={'left yes ' + (answer==='yes'? 'active':'')} onClick={clickYes}>
                Sí
            </button>
            <button className={'right no ' + (answer==='no'? 'active':'')} onClick={clickNo}>
                No
            </button>
        </div>
    );

}