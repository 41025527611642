import React,{useContext} from 'react';
import _ from 'lodash';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {SecurityContext} from "../../../../utils/SecurityManager";


const AdjustablePOCell=({
                            adjusting,
                            adjustment,
                            inputValue,
                            onInputChange,
                            unitName='',
                            onSave,
                            onCancel,
                            value,
                            valueFormatter=_.identity,onAdjustStart
})=>{

    const security=useContext(SecurityContext);

    return (
        <td>
            {adjusting?
                <div className='adjust-wrapper'>
                    <input className='form-control' value={inputValue} onChange={onInputChange}/>
                    {unitName}<br/>
                    <button className='save-adjustment-btn' onClick={onSave}><i className='fa fa-check'/></button>
                    <button className='cancel-adjustment-btn' onClick={onCancel}><i className='fa fa-times'/></button>
                </div>:
                adjustment?
                    <OverlayTrigger placement="top" overlay={
                        <Tooltip id='adjustable-po-cell'>
                            Original:<br/>
                            {valueFormatter(value-adjustment)}
                        </Tooltip>
                    }>
                        <div className='text-warning'>
                        {valueFormatter(value)}
                        <button className='edit-btn' onClick={onAdjustStart}><i className='fa fa-edit'/></button>
                        </div>
                    </OverlayTrigger>
                    :
                <>
                    {valueFormatter(value)}
                    {security.jazz.canAdjustPurchaseOrders()&&
                    <button className='edit-btn' onClick={onAdjustStart}><i className='fa fa-edit'/></button>}
                </>}
        </td>
    );
};

export default AdjustablePOCell;
