import React, {useEffect, useContext, useState, useCallback, useRef} from 'react';
import './_InventoryDetailModal.scss';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import ProductDetail from "../../products/ProductDetail";
import {TideApiContext} from "../../../../api/tideApiConfig";
import ReactTable from 'react-table';
import {connect, useSelector} from "react-redux";
import {ApiContext} from "../../../../api/Api";
import moment from 'moment';
import {convertToSmallestUnit, displayAmount, getUnitsArray, convertSmallestUnitToDisplayUnit} from "../../../../utils/modelUtils/almazen/unitsUtility";
import {defaultProps as reactTableDefaultProps} from "../../../../utils/reactTableUtils";
import {stockMovementBatchStatus} from "../../../../utils/modelUtils/almazen/stockMovementBatchUtils";
import {SecurityContext} from "../../../../utils/SecurityManager";
import ConfirmModal from "../../../../components/Modals/ConfirmModal";
import InventoryMinMax from '../components/InventoryMinMax';
import Switch from "react-bootstrap-switch";
import switchTranslations from "../../../../utils/switchTranslations";
import {NotifierContext} from "../../../../utils/Notifier";
import _ from "lodash";
import {
    getStockMovementTypeElement,
    stockMovementProductDetailSGroups
} from "../../../../utils/modelUtils/almazen/stockMovementUtils";


const loadingId = '@InventoryDetailModal.stockMovements.get';
const InventoryDetailModal = ( {inventory, onHide, onChange, stockMovements, stockMovementsMeta, loadingIds} )=>{
    const api = useContext( ApiContext );
    const tideApi = useContext(TideApiContext);
    const security = useContext( SecurityContext );
    const notifier = useContext(NotifierContext);

    let stockMovementPromise = useRef();

    useEffect( ()=>{
        api.clearProperty('stockMovements');
    },[api]);

    useEffect(()=> ()=> { if (stockMovementPromise.current) stockMovementPromise.current.abort(); }, []);

    const loadData = (tableState) => {
        if (stockMovementPromise.current) { stockMovementPromise.current.abort() };

        const sort = tableState.sorted.reduce((acc, val) => {
            acc[`order[${val.id}]`] = val.desc ? "DESC" : "ASC";
            return acc;
        }, {});

        const filter = tableState.filtered.reduce((acc, val) => {
            acc[val.id] = val.value;
            return acc;
        }, {});
        if(!tableState.sorted || !tableState.sorted.length)
            sort['order[completedDate]']='DESC';

        const promise = api.stockMovements.getOptimized({
            page: tableState.page,
            pageSize: tableState.pageSize,
            loadingId,
            filters: {
                ...sort,
                ...filter,
                productBrand: inventory.productBrand.id,
                'batch.warehouse': inventory.warehouse.id,
                status:stockMovementBatchStatus.COMPLETED,
                sGroups: stockMovementProductDetailSGroups
            }
        });

        promise.catch(() => {});

        stockMovementPromise.current = promise;

        return promise;
    };

    //const {totalItems, itemsPerPage} = stockMovementsMeta;

    const [removingStockMovement, setRemovingStockMovement]=useState(null);
    const [removingInventory, setRemovingInventory]=useState(null);
    const [editReOrderPoint, setEditReOrderPoint] = useState(true);
    const [reorderPoint, setReOrderPoint] = useState(() => {return inventory.preorderPoint?
        convertSmallestUnitToDisplayUnit(inventory?.preorderPoint, inventory?.productBrand?.product): null});
    const [reorderPointUnit, setReorderPointUnit] = useState(inventory.productBrand?.product?.displayUnit?.id);

    const saveReOrderPoint = useCallback(() => {
        if (!reorderPoint)
            return notifier.error('Punto de re-orden necesita un valor');

        const reorderPointUnitObject = _.find(getUnitsArray(inventory.productBrand.product), obj => obj.id === reorderPointUnit);
        const reorderPointAmount = convertToSmallestUnit(reorderPoint, reorderPointUnitObject, inventory.productBrand.product);
        const data = {preorderPoint: reorderPointAmount + ''};
        tideApi.inventories.update({id: inventory.id, params: data})
            .then(()=> {
                notifier.success("Punto de re-orden guardado correctamente");
                setEditReOrderPoint((editReOrderPoint) => !editReOrderPoint);
            });
    }, [tideApi, notifier, reorderPoint, inventory, reorderPointUnit]);

    const quantitySetter = (e, func) => {
        if (e.target.value !== '')
            return func(e.target.value);
    };

    const removeStockMovement=()=> {
        setRemovingStockMovement(null);
        api.stockMovements.delete({id:removingStockMovement.id})
            .then(() => {
                if (onChange)
                    onChange();
                onHide();
            });
    };

    const toggleLoadingId = 'InventoryDetailModal.toggleShowInWarehouseCount';
    const loading = useSelector(s=>!!s.loadingIds[toggleLoadingId]);
    const toggleShowInWarehouseCount = useCallback(()=>{
        if(loading) return;
        tideApi.inventories.update({
            id:inventory.id,
            params: { showInWarehouseCount: !inventory.showInWarehouseCount },
            loadingId:toggleLoadingId });
    },[tideApi, inventory, loading]);

    const toggleAutoMaxMin = useCallback(()=>{
        if(loading) return;
        tideApi.inventories.update({
            id:inventory.id,
            params: { automaticMinMax: !inventory.automaticMinMax },
            loadingId:toggleLoadingId });
    },[tideApi, inventory, loading]);

    const deleteInventory = useCallback(() => {
        tideApi.inventories.delete({id: inventory.id}).then(() => {
            notifier.success('Se ha eliminado el inventorio');
        }).catch(() => notifier.error('No se pudo eliminar el inventorio'));
    }, [tideApi, inventory, notifier]);

    const tableStructure =[
        {
            Header: 'Responsable',
            accessor: 'completedBy.fullName',
        },
        {
            Header: 'Tipo',
            accessor: ( move )=>
                <div className='text-center'>
                    {getStockMovementTypeElement(move)}
                </div>,
            id: 'type'
        },
        {
            Header: 'Cantidad',
            accessor: (move)=>displayAmount( move.quantity, inventory.productBrand.product ),
            id: "quantity"
        },
        {
            Header: 'Fecha',
            accessor: (move)=>move.completedDate?moment( move.completedDate ).format( "DD/MM/YYYY HH:mm" ):'No definido',
            id: "completedDate"
        },
        {
            Header: 'Inventario',
            accessor: (move)=>displayAmount(move.stockAmountAfterApplied, inventory.productBrand.product),
            id: "stockAmountAfterApplied"
        },
        {
            Header: 'Receta',
            accessor: 'orozcoOrderProduct.orozcoProduct.name'
        }
        ];

    if(security.almazen.canDeleteStockMovements())
        tableStructure.push({
            Header:'Borrar',
            id:'Borrar',
            accessor:(move)=><div className='text-center'><i onClick={()=>setRemovingStockMovement(move)} className='fa fa-trash red-icon'/></div>
        });

    return (
        <>
        <Modal
            show={true}
            onHide={onHide}
            bsSize='lg'
            className={'InventoryDetailModal'}
        >
            <ModalHeader closeButton>
                <ModalTitle>{inventory.productBrand.product.name} en {inventory.warehouse.name}</ModalTitle>
            </ModalHeader>
            <ModalBody>

                <ProductDetail
                    productBrand={inventory.productBrand}
                />
                <div className='showInWarehouseCount-block'>
                    <span className='b-label'>Mostrar en conteo de inventarios </span>
                    <Switch
                        onChange={toggleShowInWarehouseCount}
                        value={!!inventory?.showInWarehouseCount}
                        wrapperClass={'pull-right'}
                        {...switchTranslations}
                    />
                </div>
                <div className='showInWarehouseCount-block'>
                    <span className='b-label'>Calcular máximo y mínimo automático </span>
                    <Switch
                        onChange={toggleAutoMaxMin}
                        value={!!inventory?.automaticMinMax}
                        wrapperClass={'pull-right'}
                        {...switchTranslations}
                    />
                </div>
                <InventoryMinMax api={tideApi} info={inventory.minStockAmount} inventory={inventory} />
                <div className="text-center">
                    <h5>Punto de Re-orden</h5>
                    <div className="reorderUnitsContainer">
                        <div className="reorderInput">
                            {!editReOrderPoint && <label className='reorderPointLabel' htmlFor="reorderPoint">Cantidad:</label>}
                            <input disabled={editReOrderPoint}
                                   id="reorderPoint"
                                   onChange={event => quantitySetter(event, setReOrderPoint)}
                                   className='reorderPoint'
                                   type="number"
                                   value={reorderPoint||''}
                            />
                        </div>
                        <div>
                            {editReOrderPoint && <div className='reorderPointUnit'>
                                {inventory?.productBrand?.product?.displayUnit?.name}
                            </div>}
                            {!editReOrderPoint && <>
                            <label className='reorderPointLabel' htmlFor="select">Unidad:</label>
                                <select disabled={editReOrderPoint}
                                value={reorderPointUnit}
                                id="select"
                                className='form-control select-box-reorderpoint'
                                onChange={e=> setReorderPointUnit(e.target.value)}
                                >
                                <option value="default">Selecciona una opción</option>
                            {getUnitsArray(inventory.productBrand.product).map( unit =>
                                <option key={unit.id} value={unit.id}>{unit.name}</option>
                                )}
                                </select>
                            </>
                            }
                        </div>
                        <div style={{alignSelf: 'flex-end'}}>
                        {editReOrderPoint &&
                            <Button type="submit" className='edit-button-rop' onClick={() => setEditReOrderPoint(!editReOrderPoint)}>
                                <i className="fa fa-pencil"/>Editar
                            </Button>}
                            {!editReOrderPoint &&
                            <Button type="submit" className='save-button-rop' onClick={saveReOrderPoint}>
                                <i className="fa fa-save"/>Guardar
                            </Button>}
                        </div>
                    </div>
                </div>
                <div>
                    <div className="title-container">
                    <h5 className='text-center' >Movimientos en { inventory.warehouse.name }</h5>
                    <button className="delete-button" onClick={() => setRemovingInventory(true)}> <i className='fa fa-eraser'/> Eliminar inventario</button>
                    </div>
                    <ReactTable
                        data={ stockMovements }
                        columns={ tableStructure }
                        loading={!!loadingIds[ loadingId ] }
                        pages={1000000}
                        onFetchData={loadData}
                        {...reactTableDefaultProps}
                        ofText={''}
                        filterable={false}
                    />
                </div>

            </ModalBody>
            <ModalFooter>
                <CustomButton bsStyle='danger' onClick={onHide}>Cerrar</CustomButton>
            </ModalFooter>
        </Modal>
            {removingInventory &&
            <ConfirmModal
                title='Eliminar inventario'
                message={
                    '¿Estás seguro que deseas eliminar el inventario?'
                }
                onConfirm={deleteInventory}
                onCancel={() => setRemovingInventory(null)}
            />}
            {removingStockMovement&&
            <ConfirmModal
                title='Borrar movimiento'
                message={
                    '¿Estás seguro que deseas borrar este movimiento? Se '+
                    (removingStockMovement.inOrOut==='in'?
                        'restará del inventario el producto que entró.':
                        'sumará al inventario el producto que salió.')
                }
                onConfirm={removeStockMovement}
                onCancel={()=>setRemovingStockMovement(null)}
                />
            }
        </>
    );
};

const mapStateToProps = ({api:{ stockMovements, stockMovementsMeta={ totalItems:0, itemsPerPage:1 } }, loadingIds})=>({ stockMovements, stockMovementsMeta, loadingIds });

export default connect( mapStateToProps )(InventoryDetailModal);
