import React, {useCallback} from 'react';
import TextField from "../TextField/TextField";
import useBoolean from "../../../../hooks/useBoolean";

const NumberField = ({onChange, value, ...props}) => {

    const handleChange = useCallback((value, name)=>{
        const val = !isNaN(Number(value)) && value !== ''? Number(value): null;
        onChange&&onChange(val, name)
    }, [onChange]);

    //This will remove zeros on the left and zeros after the decimal point
    const [isFocused, setFocused, setBlur] = useBoolean();

    return <TextField
        {...props}
        inputProps={{
            type:'number',
            onFocus: setFocused,
            onBlur:setBlur}}
        value={isFocused? (value??''):(value??'')+''}
        onChange={handleChange}/>;
};

export default React.memo(NumberField);
