import React, {useRef, useContext, useState, useCallback} from 'react';
import {ApiContext} from "../../../api/Api";
import ReactTable from 'react-table';
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import {connect} from "react-redux";
import Button from '../../../elements/CustomButton/CustomButton';
import ProviderFormModal from './ProviderFormModal';
import ProviderDetailModal from "./ProviderDetailModal";
import EvaluateProviderModal from "./EvaluateProviderModal";
import ProviderEvaluationsModal from "./ProviderEvaluationsModal";
import _ from "lodash";
import useBoolean from "../../../hooks/useBoolean";
import {Link} from "react-router-dom";
import CustomButton from "../../../elements/CustomButton/CustomButton";

const tableStructure = [
    {
        Header: 'Razón social',
        accessor: 'name'
    },
    {
        Header: 'Nombre comercial',
        accessor: 'commercialName'
    },
    {
        Header: 'RFC',
        accessor: 'rfc'
    },
    {
        Header: 'Nombre de contacto principal',
        accessor: 'contactName1'
    },
    {
        Header: 'Correos',
        id: 'emails',
        accessor: (provider)=>provider.emails?provider.emails.join(', '):'Sin correos'
    },
    {
        Header: 'Acción',
        id:'link',
        sortable:false,
        filterable:false,
        accessor:(provider)=><Link to={'/compras/providers/list/'+provider.id}><CustomButton bsStyle='primary'>Lista de precios</CustomButton></Link>
    }
];

const loadingId = "@ProviderList.get";


const ProviderList = ({providers, providersMeta, loadingIds}) => {

    const [addingProvider, startAddingProvider, stopAddingProvider] = useBoolean();
    const [editingProvider, setEditingProvider] = useState(false);
    const [showingDetail, setShowingDetail] = useState(null);
    const [evaluatingProvider, setEvaluatingProvider] = useState( false );
    const [showProviderEvaluations, setShowProviderEvaluations] = useState( false );

    const {totalItems, itemsPerPage} = providersMeta;
    const plural = totalItems > 1 ? "s" : "";

    const tableStateRef = useRef(null);
    const api = useContext(ApiContext);

    const loadData = _.debounce((tableState) => {

        tableStateRef.current = tableState;

        const sort = tableState.sorted.reduce((acc, val) => {
            acc[`order[${val.id}]`] = val.desc ? "DESC" : "ASC";
            return acc;
        }, {});

        const filter = tableState.filtered.reduce((acc, val) => {
            acc[val.id] = val.value;
            return acc;
        }, {});

        filter.registered=true;

        api.providers.get({page:tableState.page, pageSize:tableState.pageSize, loadingId, filters:{...sort, ...filter}});
    }, 400, {'leading': false,'trailing': true} );

    const editProvider = useCallback(() => {
        setEditingProvider(showingDetail);
        setShowingDetail(false);
    },[showingDetail]);

    const stopEditingProvider = useCallback(()=>{
        setEditingProvider(null);
    },[]);

    const hideDetailModal = useCallback(() => {
        setShowingDetail(null);
    },[]);

    const evaluateProvider = useCallback( ()=>{
        setEvaluatingProvider( showingDetail );
        setShowingDetail( false );
    }, [showingDetail] );

    const showEvaluations = useCallback( ()=>{
        setShowProviderEvaluations( showingDetail );
        setShowingDetail( false );
    }, [showingDetail] );

    return (
        <div className="container-fluid main-container">

            <Button bsStyle='primary' className='pull-right' onClick={startAddingProvider}>Nuevo proveedor</Button>
            <div className='clearfix'/>

            <ReactTable
                data={providers}
                columns={tableStructure}
                defaultPageSize={10}
                pages={Math.ceil(totalItems / itemsPerPage)}
                className="-striped -highlight"
                loading={!!loadingIds[loadingId]}
                manual
                filterable
                onFetchData={loadData}
                getTdProps={(state, row) => ({onClick: () => row ? setShowingDetail(row.original) : null})}
                minRows={3}
                {...ReactTableTranslations}
            />
            <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>

            {addingProvider ? <ProviderFormModal onHide={stopAddingProvider}/> : null}

            {editingProvider ? <ProviderFormModal editProvider={editingProvider} onHide={stopEditingProvider}/> : null}

            {showingDetail ?
                <ProviderDetailModal
                    provider={showingDetail}
                    onHide={hideDetailModal}
                    onEvaluateProvider={evaluateProvider}
                    onShowEvaluations={showEvaluations}
                    onEdit={editProvider}
                />
                    : null}

            {evaluatingProvider ? <EvaluateProviderModal provider={evaluatingProvider} onHide={()=>setEvaluatingProvider(false)}/> : null}
            {showProviderEvaluations ? <ProviderEvaluationsModal provider={showProviderEvaluations} onHide={()=>setShowProviderEvaluations(false)}/> : null}

        </div>
    );

};

const mapStateToProps = ({
                             api: {
                                 providers = [], providersMeta = {
                                     totalItems: 0,
                                     itemsPerPage: 0
                                 }
                             }, loadingIds
                         }) => ({providers, providersMeta, loadingIds});

export default connect(mapStateToProps)(ProviderList);
