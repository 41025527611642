import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './style/_LoungeMapEditor.scss';
import LoungeMap from 'tgle-core/components/LoungeMap';
import LoungeMapEditorTools from "./LoungeMapEditorTools";
//import {createMapItem} from "../utils/loungeMapItemUtils";
import {createMapItem} from 'tgle-core/utils/loungeMapItemUtils';
import _ from 'lodash';
import useTideEntity from "../../../hooks/useTideEntity2";
import {useParams} from 'react-router-dom';
import {loungeMapEditorSGroups, prepareLoungeMapForRender} from "tgle-core/utils/loungeMapUtils";
import {initialViewPort} from "tgle-core/utils/konvaUtils";

const tideEntityConfig = {requestParams: {sGroups: loungeMapEditorSGroups}, customProp:'LoungeMapEditor.loungeMap'};
const LoungeMapEditor = ()=>{

    const id = useParams().id;
    const [remoteLoungeMap, loadingEntity]= useTideEntity('loungeMaps', id, tideEntityConfig);
    const [loungeMap, setLoungeMap]=useState({loungeMapItems:[], config:{width: 1000, height: 1000}});

    useEffect(()=>{
        if(remoteLoungeMap)
            setLoungeMap( prepareLoungeMapForRender(remoteLoungeMap));
    },[remoteLoungeMap]);

    const [viewPort, setViewPort]=useState(initialViewPort);
    const handleViewPortChange = useCallback((viewPort)=>setViewPort(viewPort), []);//Callback used to prevent warning

    const [activeTool, setActiveTool]= useState(null);
    const [selectedItemId, setSelectedItemId]=useState(null);

    // ----- Stage click can place a new item or just deselect the selected one
    const handleStageClick = useCallback((e)=>{

        if(selectedItemId)
            setSelectedItemId(null);

        if(!activeTool)
            return;

        const newItem = createMapItem(activeTool, e.stagePosition, loungeMap.loungeMapItems);
        newItem.config.order = loungeMap.loungeMapItems.length;
        const loungeMapItems = [
            ...loungeMap.loungeMapItems,
            newItem
        ];
        setLoungeMap({...loungeMap, loungeMapItems});
        //setActiveTool(null);
    },[activeTool, loungeMap, selectedItemId]);


    // -------  Edition callbacks --------
    const handleItemChange = useCallback((item)=>{
        const index = _.findIndex(loungeMap.loungeMapItems, oldItem=>item.id === oldItem.id);
        if(index===-1)
            return;

        const loungeMapItems = [...loungeMap.loungeMapItems];
        loungeMapItems[index] = item;
        setLoungeMap({...loungeMap, loungeMapItems})

    },[loungeMap]);

    // ----------  Selection handling
    const selectedItem = useMemo(()=>{
        if(!selectedItemId)
            return null;
        return _.find(loungeMap.loungeMapItems, item=>item.id===selectedItemId)||null;
    },[selectedItemId, loungeMap]);

    const setSelectedItem = useCallback((loungeMapItem)=>{
        if(!loungeMapItem)
            return setSelectedItemId(null);
        setSelectedItemId(loungeMapItem.id);
    },[]);

    return (
        <div className='LoungeMapEditor'>
            {loadingEntity && <h3>Cargando...</h3>}
            {remoteLoungeMap && <h3>{remoteLoungeMap.lounge.name}</h3>}
            <LoungeMap
                loungeMap={loungeMap}
                viewPort={viewPort}
                onViewPortChange={handleViewPortChange}
                onStageClick={handleStageClick}
                onItemChange={handleItemChange}
                onItemSelect={setSelectedItem}
                selectedItem={selectedItem}
                editable
            />
            <LoungeMapEditorTools
                loungeMap={loungeMap}
                onLoungeMapChange={setLoungeMap}
                viewPort={viewPort}
                onViewPortChange={setViewPort}
                activeTool={activeTool}
                onActiveToolChange={setActiveTool}
                selectedItem={selectedItem}
                onItemSelect={setSelectedItem}
            />
        </div>
    );
};

export default LoungeMapEditor;
