import React,{useCallback, useContext, useRef, useState} from 'react';
import ClassicModal from "../../../components/Modals/ClassicModal";
import {ControlLabel} from "react-bootstrap";
import {SingleDatePicker} from "react-dates";
import useSingleDatePickerState from "../../../hooks/useSingleDatePickerState";
import useInput from "../../../hooks/useInput";
import useNumberInput from "../../../hooks/useNumberInput";
import {NotifierContext} from "../../../utils/Notifier";
import {TideApiContext} from "../../../api/tideApiConfig";
import _ from 'lodash';
import moment from 'moment';
import useCallbackCreator from "../../../hooks/useCallbackCreator";


const loadingId='@PurchaseInvoiceAndPaymentFormModal.purchaseInvoice/Payment';

const PurchaseInvoiceAndPaymentFormModal = ({isInvoice, editEntity, onHide, purchaseOrder, onChange})=>{

    const notifier=useContext(NotifierContext);
    const tideApi=useContext(TideApiContext);

    const {date, props:datePickerProps} = useSingleDatePickerState( moment(editEntity && (isInvoice?editEntity.generatedDate:editEntity.paymentDate)) );
    const numberIn = useInput(editEntity? isInvoice? editEntity.invoiceNumber : editEntity.transactionNumber : '');
    const amountIn = useNumberInput(editEntity? editEntity.amount :'');

    const filesRef = useRef(null);


    //  --------- Files deletion ---------
    const [deletedFiles, setDeletedFiles]=useState([]);
    const deleteFile=useCallbackCreator((id)=>{
        setDeletedFiles([...deletedFiles, id+'']);
    },[deletedFiles]);

    // ----- Send to server ----------
    const save=useCallback(()=>{

        if(!amountIn.value )
            return notifier.error('Debes agregar la cantidad');
        if(!date)
            return notifier.error('Debes agregar la fecha');

        const newEntity={
            purchaseOrder: purchaseOrder.id,
            amount: amountIn.value+'',
        };
        if(isInvoice){
            newEntity.generatedDate=date.format('YYYY-MM-DD');
            newEntity.invoiceNumber=numberIn.value;
        }
        else{
            newEntity.paymentDate=date.format('YYYY-MM-DD');
            newEntity.transactionNumber=numberIn.value;
        }
        const apiOptions={params:newEntity, loadingId, files:[...filesRef.current.files]};
        if(editEntity) {
            apiOptions.id = editEntity.id;
            //Removed deleted files
            newEntity.appFiles = _.filter(editEntity.appFiles.map(f=>f.id),
                    id=>_.indexOf(deletedFiles, id+'') === -1);
        }

        onHide();
        tideApi[isInvoice?'purchaseInvoices':'purchasePayments'][editEntity?'update':'create']( apiOptions )
            .then(onChange);

    },[numberIn.value, amountIn.value, notifier, date, editEntity, isInvoice, onChange, onHide, purchaseOrder.id, tideApi, deletedFiles]);

    // -------- Load data from xml invoices -------------

    const changedFiles=useCallback((e)=>{
        if(!e.target.files.length || editEntity)
            return;

        let xml;
        _.forEach(e.target.files, file=>{
            const extension=(file.name.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1];
            if((extension||'').toUpperCase()==='XML')
                xml=file;
        });
        if(!xml)
            return;

        const reader = new FileReader();
        reader.readAsText(xml);
        reader.onload=()=>{
            const parser=new DOMParser();
            const xmlDoc=parser.parseFromString(reader.result, 'text/xml');
            const factura=xmlDoc.getElementsByTagName('cfdi:Comprobante')[0];
            if(!factura)
                return;

            numberIn.setValue(factura.getAttribute('Serie') +' '+ factura.getAttribute('Folio'));
            datePickerProps.onDateChange( moment(factura.getAttribute('Fecha')) );
            amountIn.setValue(factura.getAttribute('Total'));
        }

    },[amountIn, datePickerProps, numberIn, editEntity]);

    return (
        <ClassicModal
            title={(editEntity?'Editar ':'Agregar ') + (isInvoice?'factura':'pago')}
            confirmText={'Guardar'}
            onHide={onHide}
            onSave={save}
        >
            <ControlLabel>{isInvoice?'Folio de factura':'Número de transacción'}</ControlLabel>
            <input className='form-control' {...numberIn.bind}/>
            <br/>
            <ControlLabel>Fecha de{isInvoice?' la factura':'l pago'}</ControlLabel>
            <div className='SingleDatePicker-form-control' >
                <SingleDatePicker {...datePickerProps} />
            </div>
            <br/>
            <ControlLabel>Cantidad</ControlLabel>
            <input className='form-control' type='number' {...amountIn.bind}/>
            <br/>
            <ControlLabel>Archivos</ControlLabel>
            <input className='form-control' type='file' multiple ref={filesRef} onChange={changedFiles}/>

            {editEntity && editEntity.appFiles && !!editEntity.appFiles.length &&
                <>
                    <br/>
                    <ul>
                        {editEntity.appFiles.map( file=> {
                            if(_.indexOf(deletedFiles, file.id+'') !== -1)
                                return null;
                            return <li key={file.id}>{file.originalName} <i className='fa fa-trash red-icon' onClick={deleteFile(file.id)}/></li>;
                            }
                        )}
                    </ul>
                </>}

        </ClassicModal>
    );
};

export default PurchaseInvoiceAndPaymentFormModal;
