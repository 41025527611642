import React, {useCallback} from 'react';
import './_SelectField.scss';

const SelectField = ({value = '', onChange, field, options=[], inputProps}) => {

    const {name, label, hideLabel} = field;
    const handleChange = useCallback((e) => onChange && onChange(e.target.value, name), [onChange, name]);

    const labelVisible = !!value && !hideLabel;

    return <div className='SelectField'>
        {labelVisible && <span className='label'>{label}</span>}
        <select
            className='input slim'
            value={value}
            onChange={handleChange}
            {...inputProps}
        >
            <option value='' disabled>{label}</option>
            {options.map(o=><option key={o.value} value={o.value}>{o.label}</option>)}
        </select>
    </div>;
};

export default React.memo(SelectField);
