import React, {useContext, useMemo} from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import useTideTable from '../../../hooks/table/useTideTable';
import {exportCheckInsSGroups, listSGroups, xlsExportConfig} from '../../../utils/modelUtils/davinci/checkInUtils';
import ReactTableDataRangeFilter from '../../../components/Helper/ReactTableDateRangeFilter';
import {ApiContext} from '../../../api/Api';
import {accessMethodNameFromParents} from "../../../utils/modelUtils/davinci/loungeAccessMethodUtils";

const columns = [
    {
        Header: 'Checkin con Scanner',
        id: 'visit.isScanned',
        accessor: (c=>c.isScanned ? 'Sí' : 'No')
    },
    {
        Header: 'Huésped',
        id: 'visit.guest.name',
        accessor: c=>c.visit.guest.fullName
    },
    {
        Header: 'Sala',
        id: 'visit.lounge.name',
        accessor: c=>c.visit.lounge.name
    },
    {
        Header: 'Método de acceso',
        id: 'loungeAccessMethod.name',
        accessor: c=>accessMethodNameFromParents(c.loungeAccessMethod)
    },
    {
        Header: 'Fecha de check in',
        id: 'createdDate',
        accessor: c=>moment(c.createdDate).format('HH:mm DD/MM/YYYY'),
        Filter: ReactTableDataRangeFilter
    },
    {
        Header: 'Fecha de check out',
        id: 'checkOutDate',
        accessor: c=>c.checkOutDate?moment(c.checkOutDate).format('HH:mm DD/MM/YYYY'):'-',
        Filter: ReactTableDataRangeFilter
    },
    {
        Header: 'Tiempo de captura',
        id: 'captureTime',
        accessor: c=>c.captureTime? (c.captureTime+' segundos'):'-',
        filterable: false
    },
    {
        Header: 'Folio',
        accessor: 'folio',
    }
];

const requestFilters = {
    sGroups: listSGroups,
    "exists[canceledDate]": false
};

const initialFilter = [{ id:'createdDate',
    value:{ startDate:moment().subtract(1, 'month'), endDate: moment() } }
]

const CheckIns = () => {
    const api = useContext(ApiContext);
    const checkInsTable = useTideTable({
        entity: 'checkIns',
        columns,
        requestFilters
    });

    const filters = useMemo(
        ()=>(checkInsTable.lastUsedApiOptions||{}).filters,
        [checkInsTable]
    );

    const downloadTableURL = useMemo(
        ()=>api.checkIns.exportUrl({
            reportConfig: xlsExportConfig,
            filters:{...filters, sGroups: exportCheckInsSGroups, pagination: false}
        }),
        [api, filters]
    );

    return (
        <div className='container-fluid main-container'>
            <div className='pull-right'>
                <a className='btn btn-default' href={downloadTableURL} rel='noopener noreferrer'>
                    Exportar a Excel
                </a>
            </div>
            <div className='col-xs-12'>
                <ReactTable defaultFiltered={initialFilter} {...checkInsTable.tableProps}/>
            </div>
        </div>
    );
};

export default CheckIns;
