
import React, {useContext, useMemo, useEffect} from 'react'
import ReactTable from 'react-table';
import useTideTable from '../../../hooks/table/useTideTable';
import {ApiContext} from '../../../api/Api';

const params = {};

export const ReceptionistsReport = () => {
    const api = useContext(ApiContext);

    useEffect(()=>{
        api.receptionistsCheckinReport.get(params);
    }, [api]);

    const table = useTideTable({
        entity:'receptionistsCheckinReport',
        newTideApi: true,
        columns: [
            {Header:'Nombre', accessor:'name', filterable: false,},
            {Header:'Apellidos', accessor:'pat_last_name', filterable: false,},
            {Header:'Tiempo promedio (segundos)', accessor:'avg_capture_time', filterable: false,}
        ],
        requestFilters: params
    });

    const receptionistsCheckinReportURL = useMemo(
        ()=>api.receptionistsCheckinReport.recepcionistsCheckinReportURL({
            params:{
                excelReport: true
            }
        }),
        [api]
    );

    return (
        <div className="container-fluid main-container">
            <div className='pull-right'>
                <a className='btn btn-default' href={receptionistsCheckinReportURL} rel='noopener noreferrer'>
                Exportar a Excel
                </a>
            </div>
            <div className='col-xs-12'>
                <ReactTable
                    {...table.tableProps}
                />
            </div>
        </div>
    )
}
