import { ACTION_QUEUE_SHIFT, ACTION_QUEUE_PUSH, initialQueue, QUEUE_INTERVAL} from "./constants_api";
import LocalStorageState from "../utils/LocalStorage";


/**
 * A queue object would be an array of objects with all the parameters of an apiCall as properties
 */

export const startQueueInterval = ( api )=>{

    return setInterval( ()=>{

        const queue = api.store.getState().apiQueue;
        if( queue && queue.length )
            executeQueue( queue, api );

    }, QUEUE_INTERVAL );

};

export const reducer = (state = initialQueue, action)=> {

    switch (action.type) {

        case ACTION_QUEUE_PUSH: {

            const item = action.payload;
            const newQueue = [...state];

            newQueue.push(item);

            let loc = new LocalStorageState();
            loc.store({apiQueue: newQueue});

            return newQueue;
        }

        case ACTION_QUEUE_SHIFT: {

            const newQueue = [...state];
            newQueue.shift();

            let loc = new LocalStorageState();
            loc.store({apiQueue: newQueue});

            return newQueue;
        }
        default:
            return state;
    }
};

export const executeQueue = ( queue, api)=>{

    if( !queue || queue.constructor !== Array || !queue.length ) return;

    const item = reconstructStoredItem( queue[0] );
    const { path, property, params, config, files } = item;

    api.apiCall( path, property, params, config, files )
        .then(()=>{

            api.store.dispatch({
                type: ACTION_QUEUE_SHIFT,
            });
            const newQueue = api.store.getState().apiQueue;
            if( newQueue && newQueue.length )
                executeQueue( newQueue, api );
        });

};

export const createQueueItem = ( path, property, params, config, files )=>{

    const newConfig = { ...config, queueable:false, preventNotifier: true };
    return prepareItemForStore( { path, property, params, config:newConfig, files } );
};

const prepareItemForStore = ( item )=>{
    const prepared = { ...item };
    if( prepared.files )
        delete prepared.files;
    return prepared;
};

const reconstructStoredItem = ( item )=>{
    return item;
};