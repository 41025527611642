import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import moment from 'moment';
import ReactTable from 'react-table';
import _ from 'lodash';
import IncidenceJustificationDetail from './IncidenceJustificationDetail';
import JustificationRequestDetail from "./JustificationRequestDetail";
import Button from "../../../elements/CustomButton/CustomButton";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import withSecurityManager from "../../../utils/SecurityManager/withSecurityManager";

class Justifications extends Component{

    static contextTypes = { api:PropTypes.object };

    static defaultProps = {
        showNames: true,
        showGroups: true,
        paginated: true,
        canApprove: true,
        canDelete: false,
        isFuture: false,
        records: 100//If not paginated show this number of records
    };

    loadingId = "@Class.Justifications.get";
    deleteLoadingId = "@Class.Justifications.delete";

    constructor( props, context ){
        super(props);
        this.state = {};

        this.incJusApi = context.api.incidenceJustifications;
        this.configApi = context.api.configurations;
        this.incJusApi.clear();

        this.loadApproveJustificationLimitHours();
        this.setTableStructure();

        this.loadIncidences = _.debounce( this.loadIncidences.bind(this), 700, {'leading': true,'trailing': true} );
        this.changeJustificationIncidence = this.changeJustificationIncidence.bind(this);
        this.deleteIncidenceJustification = this.deleteIncidenceJustification.bind(this);
        this.isExpired = this.isExpired.bind(this);
    }

    loadApproveJustificationLimitHours(){
        this.configApi.getSingle({id: 'JUSTIFICATION_APPROVAL_LIMIT_HOURS'}).then( config => {
            this.approveJustificationLimitHours = config.value;
        });
    }

    loadIncidences( tableState ){

        const sort = tableState.sorted.reduce( (acc, val)=>{acc[`order[${val.id}]`] = val.desc?"DESC":"ASC"; return acc;}, {} );
        const filter = tableState.filtered.reduce( (acc, val)=>{acc[val.id] = val.value; return acc;}, {} );

        if( !tableState.sorted.length )
            filter['order[date]'] = 'DESC';

        filter.isFuture = this.props.isFuture;


        if( this.props.dates ) {
            filter["date[after]"] = this.props.dates.from.format("YYYY/MM/DD");
            filter["date[before]"] = this.props.dates.to.format("YYYY/MM/DD");
        }

        if( this.props.employee )
            filter.requestedBy = this.props.employee.id;

        this.filter = filter;
        this.sort = sort;

        this.incJusApi.get(tableState.page , tableState.pageSize, this.loadingId, {...sort, ...filter});
    }

    changeJustificationIncidence( newAppInc ){
        this.incJusApi.edit( newAppInc.id, newAppInc.responseComment, newAppInc.approved);
        this.setState({showDetail:null});

    }

    deleteIncidenceJustification( incJus ){

        this.context.api.incidenceJustifications.delete(incJus.id, this.deleteLoadingId);
        this.setState({showDetail:null});

    }

    UNSAFE_componentWillReceiveProps( nextProps ){

        if( this.props.loadingIds[this.deleteLoadingId] && !nextProps.loadingIds[this.deleteLoadingId] && this.props.onDelete )
            this.props.onDelete();

    }

    isExpired( incJus ){
        if ( !this.approveJustificationLimitHours ) return false;
        if ( this.approveJustificationLimitHours === '0' ) return false;

        return moment(incJus.createdDate).add( this.approveJustificationLimitHours, 'hours' ).isBefore( moment() );
    }

    setTableStructure = ()=> {

        let tableStructure = [];

        if( this.props.showNames )
            tableStructure.push({
                Header: 'Nombre',
                accessor: (incJus) => employeeFullName(incJus.requestedBy),
                id: 'requestedBy.name',
                filterable: true,
                sortable:true

            });

        tableStructure = tableStructure.concat([{
            Header: 'Tipo',
            accessor: (incJus) => incJus.newIncidence.name,
            id: 'newIncidence.name',
            filterable: true,
            sortable:true
        }, {
            Header: 'Fecha',
            accessor: (incJus) => moment(incJus.date).format("DD/MM/YYYY"),
            id: 'date',
            sortable:true
        }, {
            Header: 'Estado',
            accessor: (incJus) => {
                if (incJus.approved === true)
                    return <span><i className="fa fa-check green"/> Aprobado</span>;
                else if (incJus.approved === false)
                    return <span><i className="fa fa-times red"/> Denegado</span>;
                else if (this.isExpired( incJus ))
                    return <span><i className="fa fa-times red"/> Expirado</span>;
                else
                    return <span><i className="fa fa-clock-o"/> Por revisar</span>;
            },
            id: 'approved',
            sortable:true
        }, {
            Header: 'Revisó',
            accessor: (incJus) => incJus.reviewedBy? employeeFullName(incJus.reviewedBy):'Sin revisar',
            id: 'reviewedBy.name',
            filterable: true,
            sortable:true

        }]);

        if( this.props.showGroups )
            tableStructure.push({
                Header: 'Grupo',
                accessor: 'requestedBy.group.name',
                filterable: true,
                sortable:true

            });

        this.tableStructure = tableStructure;
    };

    render(){

        const { totalItems, itemsPerPage } = this.props.listMeta;
        const plural = totalItems !== 1?"s":"";

        const DetailModal = this.state.showDetail? this.state.showDetail.isFuture? JustificationRequestDetail:IncidenceJustificationDetail : null ;

        return(
            <div className="container-fluid main-container">
                <ReactTable
                    data={this.props.list}
                    columns = {this.tableStructure}
                    defaultPageSize= {this.props.paginated?10:this.props.records}
                    pages= { Math.ceil( totalItems / itemsPerPage ) }
                    className="-striped -highlight"
                    loading={!!this.props.loadingIds[this.loadingId]}
                    manual
                    showPagination={this.props.paginated}
                    sortable={false}
                    onFetchData={this.loadIncidences}
                    minRows={3}
                    getTdProps={(state, rowInfo)=>(
                        {onClick:(e)=>{
                                e.preventDefault();
                                if( rowInfo )
                                    this.setState({showDetail:rowInfo.original});
                            }
                        }
                    )}
                    {...ReactTableTranslations}
                />
                <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>

                <a
                    href={ this.context.api.incidenceJustifications.exportUrl({...this.sort, ...this.filter, pagination:false})}
                    rel="noopener noreferrer"
                   >
                    <Button>Exportar a Excel</Button>
                </a>

                {this.state.showDetail?
                    <div>
                        <DetailModal
                            incidenceJustification={this.state.showDetail}
                            urlCreator={this.context.api.createFileUrl}
                            onHide={()=>this.setState({showDetail:null})}
                            onChange={this.changeJustificationIncidence}
                            //canApprove={!!(this.props.me && this.props.me.role && this.props.me.role.name === "NOMINAS")}//this.props.canApprove} //Cambio para que gerentes no puedan justificar
                            //canApprove={this.props.canApprove}
                            canApprove={this.props.securityManager.alma.canApproveEmployeeJustification(this.state.showDetail.requestedBy)}
                            onDelete={this.props.canDelete? this.deleteIncidenceJustification:undefined}
                        />
                    </div>:null}

            </div>
        )}
}

function mapStateToProps( {api:{me, incidenceJustifications=[], incidenceJustificationsMeta={totalItems:0,itemsPerPage:0}}, loadingIds } ) {

    return {me, list:incidenceJustifications, listMeta:incidenceJustificationsMeta, loadingIds }
}

export default connect(mapStateToProps )(withSecurityManager(Justifications));
