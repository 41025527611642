import React, {useCallback} from 'react';
import useBoolean from "../../../../hooks/useBoolean";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import '../style/_ElementOrder.scss';
import arrayMove from "array-move";
import classNames from 'classnames';
import useCallbackCreator from "../../../../hooks/useCallbackCreator";

const SortableContainerUl = SortableContainer(( props )=><ul className={props.className}>{props.children}</ul> );
const SortableLi = SortableElement((props)=><li {...props} />);

const ElementOrder = ({loungeMap, onLoungeMapChange, selectedItem, onItemSelect})=>{

    const loungeMapItems = (loungeMap && loungeMap.loungeMapItems)||[];
    const [isOpen,,, toggleOpen] = useBoolean();

    const handleSortEnd= useCallback(( {oldIndex, newIndex} )=>{
        const items = arrayMove( loungeMapItems, oldIndex, newIndex);

        for( let i=0; i<items.length; i++)
            items[i].config.order = i;

        onLoungeMapChange({...loungeMap, loungeMapItems: items});
    },[loungeMapItems, onLoungeMapChange, loungeMap]);

    const handleItemSelect = useCallbackCreator((id, item)=>{
        onItemSelect(item);
    },[onItemSelect]);

    return (
        <div className='ElementOrder'>
            <h5 onClick={toggleOpen} className='toolbox-title'>Ordenar elementos <i className={'fa fa-caret-'+(isOpen?'up':'down')}/></h5>

            {isOpen &&
            <SortableContainerUl
                className='toolbox'
                distance={5}
                lockAxis={'y'}
                onSortEnd={handleSortEnd}
            >
                {loungeMapItems.map((item, i)=>
                    <SortableLi
                        key={item.id}
                        index={i}
                        className={classNames('ElementOrder-element', selectedItem && selectedItem.id===item.id && 'selected')}
                        onClick={handleItemSelect(item.id, item)}
                    >
                        {item.name}
                    </SortableLi>
                )}
            </SortableContainerUl>}
        </div>
    );
};

export default ElementOrder;
