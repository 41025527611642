import React from 'react';
import moment from 'moment';

class ChatManager {

    constructor(socketApi) {
        this.socketApi = socketApi;
    }

    setCredentials(user, securityManager) {
        this.user = user;
        this.securityManager = securityManager;

        if (!this.shouldSeeGuestChat())
            return;

        this.socketApi.configure();

        this.subscribe(this.socketApi.apiCall);
        this.socketApi.socket().on('reconnect', ()=>
            this.subscribe(this.socketApi.apiCall));
    }

    subscribe(apiCall) { // apiCall as argument cause `this` was misbehaving and `bind` won't cut it.
        apiCall('chat', 'find');
        apiCall('chat', 'find', {creator: 'fromUser'});
    }

    /**
     * This is different from the securityManager method because even though an employee may have permission to see guest chats,
     * we could prevent him from watching them outside of his working hours, or if he did not arrive to work.
     * @returns {boolean}
     */
    shouldSeeGuestChat() {
        if (!this.securityManager)
            return false;
        return this.securityManager.core.canSeeGuestChat();
    }

    takeGuestChat(chat) {
        this.socketApi.apiCall('chat', 'update', {
            parameters: {
                assignTo: this.user.id,
                id: chat.id
            }
        })
    }

    markChatAsSolved(chat){
        this.socketApi.apiCall('chat', 'update', {
            parameters: {
                id: chat.id,
                finishedAt: moment()
            }
        })
    }

    getChatMessages(chat) {
        //this.socketApi.apiCall('chatMessages', 'get', { chat: chat.id})
    }

}

export default ChatManager;

export const ChatManagerContext = React.createContext(null);
