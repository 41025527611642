import React, {Component} from 'react';
import ReactTable from 'react-table';
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import _ from 'lodash';
import moment from 'moment';
import {Modal} from 'react-bootstrap';
import CustomButton from "../../../elements/CustomButton/CustomButton";
import Questionnaire from "../../../components/Questionnaire/Questionnaire";
import questionnaireHelper from "../../../components/Questionnaire/helper";
import ReactTableDataRangeFilter from "../../../components/Helper/ReactTableDateRangeFilter";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import {filledQuestionnaireListSGroups} from "../../../utils/modelUtils/ee/filledQuestionnaireUtils";


class FilledQuestionnaireList extends Component{

    static contextTypes = { api:PropTypes.object, notifier:PropTypes.object };

    loadingId = "@Class.FilledQuestionnaireList.filledQuestionnaires.get";
    singleLoadingId = "@Class.FilledQuestionnaireList.filledQuestionnaires.getSingle";

    constructor( props, context ){
        super(props);
        this.state = {
            showFilters: false,
            showOnlyApproved: false
        };

        this.loadFilledQuestionnaires = _.debounce( this.loadFilledQuestionnaires.bind(this), 700 );

        this.dataApi = context.api.filledQuestionnaires;
    }

    loadFilledQuestionnaires( tableState ){

        this.tableState = tableState;

        const sort = tableState.sorted.length?
            tableState.sorted.reduce( (acc, val)=>{acc[`order[${val.id}]`] = val.desc?"DESC":"ASC"; return acc;}, {} )
            :
            {'order[createdDate]':'DESC' };

        const filter = tableState.filtered.reduce( (acc, val)=>{acc[val.id] = val.value; return acc;}, {} );

        if(filter.shiftType)
            filter.shiftType = filter.shiftType.trim().substring(0, 1);


        if( filter.search && filter.search.indexOf(',') !== -1  )
            filter.search = filter.search.split(',').map( (str)=>str.trim() );

        if( filter.createdDate && filter.createdDate.startDate )
            filter['createdDate[after]'] = filter.createdDate.startDate.format('YYYY-MM-DDT[00:00]');

        if( filter.createdDate && filter.createdDate.endDate )
            filter['createdDate[before]'] = filter.createdDate.endDate.format('YYYY-MM-DDT[23:59:59]');

        delete filter.createdDate;

        if(this.state.showOnlyApproved)
            filter['status'] = 'approved';

        filter.sGroups = filledQuestionnaireListSGroups;

        this.dataApi.get(tableState.page , tableState.pageSize, this.loadingId, {...sort, ...filter});
    }

    toggleFilter = () => {
        this.setState({showFilters: !this.state.showFilters});
    };

    toggleOnlyApproved = (c, value) => {
        this.setState({showOnlyApproved: value}, () => {
            this.loadFilledQuestionnaires(this.tableState);
        })
    };

    loadFilledQuestionnaireDetail = (filledQuestionnaire)=>{
        this.context.api.filledQuestionnaires.get(0,1,this.singleLoadingId, {id:filledQuestionnaire.id, sGroups:['filled_questionnaire_full_read', 'answer_read','question_read']}, 'singleFilledQuestionnaire' )
            .then((response)=>{
                if( !response || !response.length )
                    return this.context.notifier.error('No se encontró el cuestionario');
                this.setState( {filledQuestionnaireDetail:response[0]} );
            });
    };

    closeDetail = ()=>{
        this.setState({filledQuestionnaireDetail:false})
    };

    getQuestionnaireScore = ( filledQuestionnaire )=>{

        const scoreObj = questionnaireHelper.getQuestionnaireScore( filledQuestionnaire );
        if( !scoreObj )
            return 'NA';

        let score = scoreObj.score;
        if( typeof score === 'number' )
            score = Math.round( score * 1000 ) / 1000;

        return score;

    };

    tableStructure = [{
        Header: 'Folio',
        accessor: 'id'

    },{
        Header: 'Cuestionario',
        accessor: 'questionnaire.name'
    }, {
        Header: 'Realizado por',
        accessor: (filled)=> employeeFullName(filled.filledBy),
        id: 'search'
    }, {
        Header: 'Fecha',
        accessor: (filled)=> moment(filled.createdDate).format( 'DD/MM/YYYY HH:mm' ),
        id: 'createdDate',
        Filter: ReactTableDataRangeFilter
    }, {
        Header: 'Sala',
        id:'lounge.name',
        accessor: ( filled )=>{
            if(filled.lounge )
                return filled.lounge.name;
            if(filled.visit && filled.visit.lounge)
                return filled.visit.lounge.name;
            return 'Sin sala';
        },
        sortable: true,
        filterable: true
    }, {
        Header: 'Turno',
        id: 'shiftType',
        accessor: ( filled )=>{
            if(filled.shiftType) {
                if (filled.shiftType === 'M')
                    return 'Matutino';
                if (filled.shiftType === 'V')
                    return 'Vespertino';
                if (filled.shiftType === 'N')
                    return 'Nocturno';
            }
            if(filled.visit && filled.visit.createdDateShift)
                return filled.visit.createdDateShift;
            return 'Sin turno';
        },
        sortable: true,
        filterable: true
    }
    ];

    render() {

        const { totalItems, itemsPerPage } = this.props.listMeta;
        const plural = totalItems>1?"s":"";

        const filledDetail = this.state.filledQuestionnaireDetail;

        return (
            <div className="container-fluid main-container">

                <ReactTable
                    data={this.props.list}
                    columns = {this.tableStructure}
                    defaultPageSize= {10}
                    pages= { Math.ceil( totalItems / itemsPerPage ) }
                    className="-striped -highlight"
                    loading={!!this.props.loadingIds[this.loadingId]}
                    manual
                    filterable
                    onFetchData={this.loadFilledQuestionnaires}
                    getTdProps={(state, rowInfo)=>({ onClick:()=>this.loadFilledQuestionnaireDetail(rowInfo.original) })}
                    minRows={3}
                    {...ReactTableTranslations}
                />
                <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>

                {filledDetail?
                <Modal
                    show={true}
                    onHide={this.closeDetail}
                    bsSize="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{filledDetail.id} - {filledDetail.questionnaire.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Questionnaire
                            filledQuestionnaire={filledDetail}
                            displayName={false}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <CustomButton bsStyle='danger' onClick={this.closeDetail}>Cerrar</CustomButton>
                    </Modal.Footer>
                </Modal>
                    :null}

            </div>
        );
    }
}

function mapStateToProps( {api:{ filledQuestionnaires=[], filledQuestionnairesMeta={totalItems:0,itemsPerPage:0} }, loadingIds } ) {

    return { list:filledQuestionnaires, listMeta:filledQuestionnairesMeta, loadingIds }
}

export default connect(mapStateToProps)(FilledQuestionnaireList);
