import React, {Component} from 'react';
import { Modal, Table } from 'react-bootstrap';
import moment from 'moment';
import Button from "../../../elements/CustomButton/CustomButton";
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import TideEntitySelect from "../../../components/TideEntitySelect/TideEntitySelect";
import {flattenLoungeAccessMethodsTree} from "../../../services/modelUtils/loungeAccessMethodsUtils";

const loungeAccessMethodFilters={'order[name]':'ASC', isActive:true, 'loungeParentAccessMethod[exists]':false};

class ReservationFormModal extends Component{

    static contextTypes = {
        api: PropTypes.object,
        notifier: PropTypes.object
    };

    constructor( props ){
        super(props);

        if( !props.reservation ) {
            this.state = {
                form: {
                    name: '',
                    lastNames: '',
                    accessMethod: '',
                    personsNumber: 1,
                    terminal: 'T1',
                    reservationDate: moment().add(5, 'hours'),
                    notes: '',
                    loungeAccessMethods: ''
                },
                objectAccessMethod: ''
            };
        }
        else{
            this.state = { form: {
                ...props.reservation,
                    reservationDate: moment( props.reservation.reservationDate )
            } };
        }
    }

    getFormValue = ( name )=>{
        if( this.state.form[name] )
            return this.state.form[name];
        return '';
    };

    changeFormValue = ( e )=>{
        this.setState( { form:{...this.state.form, [e.target.name]:e.target.value} } );
    };

    changeMethodAccess = (value) => {
        this.setState({ form:{...this.state.form, 'loungeAccessMethods' : [value.id], 'accessMethod' : value.name} });
        this.setState({'objectAccessMethod' : value});
    }

    getReservationForServer = () =>{
        const reservation = { ...this.state.form };

        delete reservation.flight;

        reservation.personsNumber = Number(reservation.personsNumber);

        reservation.name = reservation.name.trim();
        reservation.lastNames = reservation.lastNames.trim();
        reservation.notes = reservation.notes.trim();
        reservation.reservationDate = reservation.reservationDate.format('YYYY-MM-DDTHH:mm');

        if( !reservation.name || !reservation.lastNames || !reservation.accessMethod || !reservation.source )
            return this.context.notifier.error('No has llenado todos los campos');

        if( reservation.lounge && reservation.lounge.id )
            reservation.lounge = reservation.lounge.id;

        return reservation;
    };

    saveReservation = () =>{
        const reservation = this.getReservationForServer();

        if(!reservation)
            return;

        this.setState( {loading: true} );

        if( this.props.reservation )
            this.context.api.reservations.edit( this.props.reservation.id, reservation )
                .then(this.props.onHide).catch( this.stopLoading );
        else
            this.context.api.reservations.create( reservation )
                .then(this.props.onHide).catch( this.stopLoading );

    };

    stopLoading = () =>
        this.setState({loading:false});

    render(){
        return (
            <Modal
                show={true}
                onHide={this.props.onHide}
            >
                <Modal.Header>
                    <Modal.Title>Detalle de reservación</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form autoComplete='off'>
                    <Table>
                        <tbody>
                        <tr>
                            <td>Nombre</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='name'
                                    value={this.getFormValue('name')}
                                    onChange={this.changeFormValue}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Apellidos</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='lastNames'
                                    value={this.getFormValue('lastNames')}
                                    onChange={this.changeFormValue}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='email'
                                    value={this.getFormValue('email')}
                                    onChange={this.changeFormValue}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Teléfono</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='phone'
                                    value={this.getFormValue('phone')}
                                    onChange={this.changeFormValue}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Método de acceso</td>
                            <td>
                                <TideEntitySelect
                                    value={this.state.objectAccessMethod}
                                    onChange={this.changeMethodAccess}
                                    entity='loungeAccessMethods'
                                    placeholder='Escribe tu método de acceso...'
                                    additionalFilters={loungeAccessMethodFilters}
                                    apiCustomProp={'SingleCheckInLoungeAccessMethods'}
                                    filterEntities={flattenLoungeAccessMethodsTree}
                                    filterLocal
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Fecha</td>
                            <td>
                                <Datetime
                                    value={this.getFormValue('reservationDate')}
                                    onChange={(date)=>this.changeFormValue({target:{name:'reservationDate', value:date}})}
                                    isValidDate={(current)=> moment().isSameOrBefore(current, 'day') }

                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Personas</td>
                            <td>
                                <select
                                    className='form-control'
                                    name='personsNumber'
                                    value={this.getFormValue('personsNumber')}
                                    onChange={this.changeFormValue}
                                >
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                    <option value='4'>4</option>
                                    <option value='5'>5</option>
                                    <option value='6'>6</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Terminal</td>
                            <td>
                                <select
                                    className='form-control'
                                    name='terminal'
                                    value={this.getFormValue('terminal')}
                                    onChange={this.changeFormValue}
                                >
                                    <option value='T1'>Terminal 1</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Aerolínea</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='airline'
                                    value={this.getFormValue('airline')}
                                    onChange={this.changeFormValue}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Número de vuelo</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='flightNumber'
                                    value={this.getFormValue('flightNumber')}
                                    onChange={this.changeFormValue}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Método de reserva</td>
                            <td>
                                <select
                                    className='form-control'
                                    name='source'
                                    value={this.getFormValue('source')}
                                    onChange={this.changeFormValue}
                                >
                                    <option disabled value="">Selecciona una opción</option>
                                    <option value='phone'>Teléfono</option>
                                    <option value='facebook'>Facebook</option>
                                    <option value='whatsapp'>Whatsapp</option>
                                    <option value='personal'>En persona</option>
                                    <option value='other'>Otro</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Notas</td>
                            <td>
                                <textarea
                                    className='form-control'
                                    name='notes'
                                    value={this.getFormValue('notes')}
                                    onChange={this.changeFormValue}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                    </form>

                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle='danger' disabled={this.state.loading} className='pull-left' onClick={this.props.onHide}>Cancelar</Button>
                    <Button bsStyle='success' disabled={this.state.loading} onClick={this.saveReservation}>{this.state.loading? 'Cargando...':'Guardar'}</Button>
                </Modal.Footer>
            </Modal>
        );

    }

}

export default ReservationFormModal;
