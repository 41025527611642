
//Start with sunday because that's the PHP way :(
export const daysOfTheWeek  = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
export const daysOfTheWeekEngCaps  = [ 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY' ];

export const dayOfTheWeekNotation = {
    SUNDAY_ZERO:"SUNDAY_ZERO",
    MONDAY_ZERO:"MONDAY_ZERO",
    ENGLISH_CAPS:"ENGLISH_CAPS"
}

export const dayOfTheWeekNotationConvert = ( from, to, value )=>{
    if(from === to){
        return value;
    }
    if( from === dayOfTheWeekNotation.MONDAY_ZERO && to === dayOfTheWeekNotation.ENGLISH_CAPS ){
        return daysOfTheWeekEngCaps[ value ];
    }
    if( from === dayOfTheWeekNotation.ENGLISH_CAPS && to === dayOfTheWeekNotation.MONDAY_ZERO ){
        return daysOfTheWeekEngCaps.indexOf( value );
    }
    if( from === dayOfTheWeekNotation.SUNDAY_ZERO && to === dayOfTheWeekNotation.ENGLISH_CAPS ){
        if( Number(value) === 0 ){
            return daysOfTheWeekEngCaps[6];
        }
        return daysOfTheWeekEngCaps[ value - 1 ];
    }
    if( from === dayOfTheWeekNotation.ENGLISH_CAPS && to === dayOfTheWeekNotation.SUNDAY_ZERO ){
        if( value === daysOfTheWeekEngCaps[6] ){
            return 0;
        }
        return daysOfTheWeekEngCaps.indexOf( value ) + 1;
    }
    if( from === dayOfTheWeekNotation.MONDAY_ZERO && to === dayOfTheWeekNotation.SUNDAY_ZERO ){
        if( Number(value) === 6 ){
            return 0;
        }
        return Number(value) + 1;
    }
    if( from === dayOfTheWeekNotation.SUNDAY_ZERO && to === dayOfTheWeekNotation.MONDAY_ZERO ){
        if( Number(value) === 0 ){
            return 6;
        }
        return Number(value) - 1;
    }
}

export const dayOfTheWeekNotationBatchConvert = ( from, to, values )=>{
    return values.map( val=>dayOfTheWeekNotationConvert(from, to, val) );
}

export const displayDateFormat = "DD/MM/YYYY";
export const displayDateTimeFormat = "DD/MM/YYYY HH:mm";
export const serverDateTimeFormat = "YYYY-MM-DD HH:mm";
