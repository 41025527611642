import React,{useState,useEffect,useCallback,useContext} from 'react';
import useTideEntity from "../../../hooks/useTideEntity";
import {Table} from "react-bootstrap";
import {productBrandName} from "../../../utils/modelUtils/almazen/productBrandUtils";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import {maxDecimals} from "../../../utils/MathUtils";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import {
    computeReliability, createEmptyRealCounts,
    detailSerializationGroups,
    prepareWarehouseCountForServer
} from "../../../utils/modelUtils/almazen/warehouseCountUtils";
import {ApiContext} from "../../../api/Api";
import {NotifierContext} from "../../../utils/Notifier";
import {useSelector} from "react-redux";
import moment from 'moment';
import {convertToSmallestUnit, displayAmount, getUnitsArray} from "../../../utils/modelUtils/almazen/unitsUtility";
import EntitySelect from "../../../components/EntitySelect/EntitySelect";
import {employeeFullName, userEmployeeNameSearchSGroups} from "../../../utils/modelUtils/alma/employeeUtils";
import CommentsFeed from "../../../components/CommentsFeed/CommentsFeed";
import { shiftsList } from '../../../utils/shiftsUtils';

const saveLoadingId='@WarehouseCountDetail.save';

const tideEntityConfig={customProp:'WarehouseCountDetail', requestFilters: {sGroups: detailSerializationGroups}};

const userEmployeeNameSearchSGroupsSimple={
    sGroups: userEmployeeNameSearchSGroups,
    isHired: 1
};

const WarehouseCountDetail=({match})=>{

    const id=match.params.id;
    const api=useContext(ApiContext);
    const notifier=useContext(NotifierContext);

    const [warehouseCount, loading, notFound, {reload}]=useTideEntity('warehouseCounts', id, tideEntityConfig);
    const [realCounts, setRealCounts]=useState([]);
    const [completedBy, setCompletedBy]=useState(null);
    const [receivedBy, setReceivedBy]=useState(null);
    const [shiftType, setShifType]=useState('M');

    useEffect(()=>{
        if(!warehouseCount)
            return;
        setRealCounts(createEmptyRealCounts(warehouseCount));
    },[warehouseCount]);

    const handleRealCountChange=useCallbackCreator((id, {target})=>{
        const [prop, index]=id.split('-');

        const newRealCounts=[...realCounts];
        newRealCounts[index]={...newRealCounts[index], [prop]:target.value};

        const product=warehouseCount.warehouseCountProductBrands[index].productBrand.product;
        newRealCounts[index].valueInSmallestUnit=convertToSmallestUnit(newRealCounts[index].value||0, {id:newRealCounts[index].unit}, product);

        setRealCounts(newRealCounts);
    },[realCounts]);

    const getDifference=(index)=>{
        const wCPB=warehouseCount.warehouseCountProductBrands[index];
        const product=wCPB.productBrand.product;

        if(warehouseCount.completedDate)
            return displayAmount(wCPB.realCount  - wCPB.theoreticalCount, product );

        if(!realCounts[index] || !warehouseCount.warehouseCountProductBrands[index])
            return '-';

        return displayAmount( (realCounts[index].valueInSmallestUnit||0) - wCPB.theoreticalCount, product );
    };

    const handleSave=useCallback(()=>{
        const warehouseC = prepareWarehouseCountForServer({...warehouseCount, completedBy, receivedBy, shiftType}, realCounts);
        if(warehouseC.error)
            return notifier.error(warehouseC.error);

        api.warehouseCounts.edit({id:warehouseC.id, warehouseCount:warehouseC, loadingId:saveLoadingId }).then(reload);
    },[warehouseCount, api, notifier,realCounts, reload, completedBy, receivedBy, shiftType]);

    const saving=useSelector(({loadingIds})=>!!loadingIds[saveLoadingId]);

    const getRowColor = ( index )=>{
        const wCPB=warehouseCount.warehouseCountProductBrands[index];
        const diff = wCPB.realCount  - wCPB.theoreticalCount;

        if(diff<0){
            return 'red';
        }else if(diff>0){
            return 'sky-blue';
        }
        return 'x';
    };

    return (
      <div className='WarehouseCountDetail container-fluid'>
          {loading && <h2>Cargando...</h2>}
          {notFound && <h2>Conteo no encontrado</h2>}

          {warehouseCount&&
              <>
                  <h4 className='pull-right'>{moment(warehouseCount.createdDate).format('DD/MM/YYYY HH:mm')}</h4>
                  <h2>Conteo {warehouseCount.id} - {warehouseCount.warehouse&&warehouseCount.warehouse.name}</h2>
                  <div className={warehouseCount.completedDate?'':'hide-print-optional'}>

                    {!warehouseCount.completedBy ?
                            <div className="row">
                                <div className="col-md-4">
                                    <EntitySelect
                                        entity={'employees'}
                                        getMethod={'getSimple'}
                                        filterBy={'search'}
                                        value={completedBy}
                                        onChange={setCompletedBy}
                                        placeholder={'Seleccionar encargado de conteo...'}
                                        labelCreator={employeeFullName}
                                        className={'print-optional'}
                                        additionalFilters={userEmployeeNameSearchSGroupsSimple}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <EntitySelect
                                        entity={'employees'}
                                        getMethod={'getSimple'}
                                        filterBy={'search'}
                                        value={receivedBy}
                                        onChange={setReceivedBy}
                                        placeholder={'Seleccionar quién recibe el conteo...'}
                                        labelCreator={employeeFullName}
                                        className={'print-optional'}
                                        additionalFilters={userEmployeeNameSearchSGroupsSimple}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <select onChange={(e) => setShifType(e.target.value)} value={shiftType || ''} className="form-control">
                                        { shiftsList.map((shift) => <option value={shift.key} key={shift.key}>{shift.value}</option>)}
                                    </select>
                                </div>
                            </div>

                        :
                            <div>
                                <div className='pull-left'>
                                    <h4 style={{ padding: 0, margin: 0 }}><b>Realizado por</b> {employeeFullName(warehouseCount.completedBy)}</h4>
                                    <h4 style={{ padding: 0, margin: 0 }}><b>Recibido por</b> {employeeFullName(warehouseCount?.receivedBy)}</h4>
                                    <h4 style={{ padding: 0, margin: 0 }}><b>Turno</b> { shiftsList.find(shift => shift.key===warehouseCount?.shiftType)?.value } </h4>
                                </div>

                                <div><h4 className='pull-right'>Confiabilidad <strong>{maxDecimals(warehouseCount.reliability,2)}%</strong></h4></div>
                            </div>
                      }

                      <Table striped className={'vertical-responsive-table '}>
                          <thead>
                          <tr>
                              <th>#</th>
                              <th>Producto</th>
                              <th>Línea</th>
                              <th>Cantidad</th>
                              {warehouseCount.completedBy && <th className='print-optional'>Teórico</th>}
                              {warehouseCount.completedBy && <th className='print-optional'>Diferencias</th>}
                              {warehouseCount.completedBy && <th className='print-optional'>Confiabilidad</th>}
                          </tr>
                          </thead>
                          <tbody>
                            {warehouseCount.warehouseCountProductBrands.map((wCPB, i)=>
                                <tr key={wCPB.id} className={getRowColor(i)}>
                                    <td data-label='#'>{i+1}</td>
                                    <td data-label='Producto'>{productBrandName(wCPB.productBrand)}</td>
                                    <td data-label='Línea'>{wCPB.productBrand && wCPB.productBrand.product && wCPB.productBrand.product.line &&
                                        wCPB.productBrand.product.line.name}</td>
                                    <td data-label='Cantidad'>{warehouseCount.completedDate?
                                        displayAmount(wCPB.realCount, wCPB.productBrand.product):
                                        <>
                                            <input className='form-control count-input' value={realCounts[i]?realCounts[i].value:''} onChange={handleRealCountChange('value-'+i)}/>
                                            <select className='form-control  count-input print-optional' value={realCounts[i]?realCounts[i].unit:''} onChange={handleRealCountChange('unit-'+i)}>
                                                {getUnitsArray(wCPB.productBrand.product).map(unit=>
                                                    <option value={unit.id} key={unit.id}>{unit.name}</option>
                                                )}
                                            </select>
                                        </>
                                    }</td>

                                    {warehouseCount.completedBy && <td data-label='Teórico' className='print-optional'>{displayAmount(wCPB.theoreticalCount, wCPB.productBrand.product)}</td>}
                                    {warehouseCount.completedBy && <td data-label='Diferencia' className='print-optional'>{getDifference(i)}</td>}
                                    {warehouseCount.completedBy && <td data-label='Confiabilidad' className='print-optional'>{warehouseCount.completedDate?
                                        maxDecimals(wCPB.reliability,2):
                                        maxDecimals(computeReliability(realCounts[i]?realCounts[i].valueInSmallestUnit:0, wCPB.theoreticalCount), 2)||0}%</td>}
                                </tr>
                            )}
                          </tbody>
                      </Table>
                      {!warehouseCount.completedDate &&
                      <CustomButton className='print-optional' bsStyle='success' disabled={saving} onClick={handleSave}>Guardar conteo</CustomButton>}
                  </div>
                  {warehouseCount.cassette&&
                  <CommentsFeed
                      cassetteId={warehouseCount.cassette.id}
                  />}
              </>}
      </div>
    );
};

export default WarehouseCountDetail;
