import React,{useContext,useEffect,useMemo} from 'react';
import {ApiContext} from "../../../../api/Api";
import moment from "moment";
import {useSelector} from "react-redux";
import ReactEcharts from 'echarts-for-react';
import {getFulfillmentPoints} from "../../../../utils/modelUtils/alma/incidenceUtils";


const compName='@EmployeeFulfillmentPointsGraph';
const EmployeeFulfillmentPointsGraph=({employee})=>{

    const api=useContext(ApiContext);

    useEffect(()=>{
        const sixtyDaysAgo=moment().subtract(60,'days').format('YYYY-MM-DD 00:00');
        api.appliedIncidences.get(1,1,compName, {pagination:false, 'shiftDate[after]':sixtyDaysAgo, 'order[shiftDate]':'DESC', employee:employee.id}, compName);
    },[api, employee]);

    const appIncidences = useSelector(({api})=>api[compName]||[]);
    const loading = useSelector(({loadingIds})=>!!loadingIds[compName]);


    const graphOptions=useMemo(()=>{

        if(!appIncidences.length) return {};

        const xLabels=[];
        const yValues=[];

        for(let i=0; i<30; i++){
            xLabels.push(moment().subtract(i,'days').format('DD/MM/YY'));
            const limitBefore=moment().subtract(i+15, 'days');
            const limitAfter=moment().subtract(i, 'days');
            yValues[i]=100;
            for(let j=0; j<appIncidences.length; j++){

                if(limitBefore.isAfter( appIncidences[j].shiftDate, 'day') )
                    break;
                if(limitAfter.isBefore( appIncidences[j].shiftDate, 'day') )
                    continue;

                //if(getFulfillmentPoints(appIncidences[j].incidence) < 0) console.log(appIncidences[j]);
                yValues[i]+=getFulfillmentPoints(appIncidences[j].incidence);
            }
        }
        xLabels.reverse();
        yValues.reverse();


        return {
            backgroundColor: '#f5f5f5',
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['Calificación']
            },
            grid: {
                left: '3%',
                right: '6%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: xLabels
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: 'Cumplimiento',
                    type: 'line',
                    data: yValues,
                    lineStyle: {color: '#61eb63'}
                }
            ],
            textStyle: {color:"rgba(20, 20, 20, 1)"}
        };
    },[appIncidences]);

    return (
        <div className='EmployeeFulfillmentPointsGraph'>
            {loading&&<h3>Cargando...</h3>}
            {!!appIncidences.length &&
            <>
                <h4>Historial de cumplimiento</h4>
                <ReactEcharts
                    option={graphOptions}
                    style={{height:"460px"}}
                />
            </>}
        </div>
    )
};

export default EmployeeFulfillmentPointsGraph;
