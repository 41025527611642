import React, {Component} from 'react';
import {Row, Col, Panel} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import CustomButton from "../../../elements/CustomButton/CustomButton";
import _ from 'lodash';


class QuestionnaireList extends  Component{

    static contextTypes = {api:PropTypes.object};

    componentDidMount(){
        this.context.api.questionnaires.get(
            0,
            200,
            undefined,
            {"order[createdDate]":'DESC',sGroups:['questionnaire_read_id','questionnaire_read_name'], isActive:true},
            'QuestionnaireList.questionnaires');
    }

    render() {

        const questionnaireBlocks = _.chunk( this.props.questionnaires, 4 );

        return (
            <div className="container-fluid main-container">
                <h4>Cuestionarios disponibles</h4>

                {questionnaireBlocks.map(( questionnaires, index )=>
                    <Row key={index}>
                        {questionnaires.map((quest)=>
                        <Col lg={3} md={4} sm={6} xs={12} key={quest.id}>
                            <Panel>

                                <h3>{quest.name}</h3>
                                <NavLink to={"/ee/questionnaires/" + quest.id }>
                                    <CustomButton bsStyle='primary'>Realizar</CustomButton>
                                </NavLink>
                                <NavLink to={"/ee/questionnaire-report/" + quest.id }>
                                    <CustomButton bsStyle='success' className='pull-right'>Estadísticas</CustomButton>
                                </NavLink>
                            </Panel>
                        </Col>
                        )}
                    </Row>
                )}

            </div>
        )
    }
}

const mapStateToProps = ({ api })=> ({questionnaires:api['QuestionnaireList.questionnaires']||[]});

export default connect(mapStateToProps)(QuestionnaireList);
