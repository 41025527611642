import React, {useContext, useEffect, useMemo, useState} from 'react';
import Button from "../../../../elements/CustomButton/CustomButton";
import {DateRangePicker} from "react-dates";
import useDateRangePickerState from "../../../../hooks/useDateRangePickerState";
import moment from "moment/moment";
import {TideApiContext} from "../../../../api/tideApiConfig";

const styles = {
    table: {
        width: '100%',
        borderSpacing: '7px 3px',
        borderCollapse: 'separate'
    },
    thead: {
        color: "#fff",
        fontSize: '14px',
    },
    th: {
        background: "linear-gradient(60deg, #2496d8, #62c1ef)",
        borderRadius: "5px",
        padding: '10px',
        fontWeight: 'initial',
        boxShadow: '0 3px 9px 0 #000000b3'
    },
    tr: {
        fontSize: '14px',
    },
    grayTr: {
        fontSize: '14px',
        backgroundColor: '#00000008'
    },
    td: {
        padding: '10px 5px',
        fontSize: 1.5 + 'rem',
    },
    btnReport: {
        marginTop: 2+'rem',
        marginLeft: 15+'px'
    }
};

const ListEmployeeQualifications = () => {
    const tideApi = useContext(TideApiContext);
    const [columnsHeaders, setColumnsHeader] = useState();
    const [tableRows, setTableRows] = useState();
    const {props, dateRange} = useDateRangePickerState({
        startDate: moment().subtract(3, 'months'),
        endDate: moment()
    });

    useEffect(() => {
        tideApi.scoresGenerationsBiweekly.getEmployeesQualifications({
            params: {
                from: dateRange?.startDate?.format('YYYY-MM-DD'),
                to: dateRange?.endDate?.format('YYYY-MM-DD'),
                token: window.localStorage.googlead
            }
        }).then((res) => {
            setColumnsHeader(res.headers);
            setTableRows(res.employees);
        })
    }, [tideApi, dateRange]);


    const downloadReport = useMemo(
        () => tideApi.scoresGenerationsBiweekly.downloadReport({
            params: {
                from: dateRange?.startDate?.format('YYYY-MM-DD'),
                to: dateRange?.endDate?.format('YYYY-MM-DD'),
                token: window.localStorage.googlead
            }
        }), [tideApi, dateRange]);

    return (
        <div className='container-fluid main-container'>
            <div className='col-xs-12 text-center' style={{marginBottom: '15px'}}>
                <p>Rango de Fechas:</p>
                <DateRangePicker {...props}/>
            </div>
            <div className='col-xs-12'>
                <table style={styles.table}>
                    <thead style={styles.thead}>
                    <tr>
                        {columnsHeaders ? columnsHeaders?.map((header, key) => <th key={key} style={styles.th}>{header}</th>) : null}
                    </tr>
                    </thead>
                    <tbody style={styles.tbody}>
                    {tableRows?.map((employeeData, key) => {
                        return (
                            <tr key={key} style={key % 2 === 0 ? styles.tr : styles.grayTr}>
                                {employeeData?.map((data, key) => {
                                   return <td style={styles.td} key={key}>{data}</td>
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
            <a rel="noopener noreferrer" href={downloadReport}>
                <Button pullRight={true}>Exportar a Excel</Button>
            </a>
        </div>
    )
}

export default ListEmployeeQualifications;