import React, {Component} from 'react';
import { Row, Col } from 'react-bootstrap';
import Card from '../../../components/Card/Card.jsx';


class Help extends Component{

    render() {
        return (
            <div className="container-fluid main-container Help">
                <Row>
                    <Col xs={12}>
                        <Card
                            title="Manual del sistema Jazz"
                            content={<div className='text-center'>

                                <a href={'/manuales/Manual_de_sistema_JAZZ.pdf'} target='_blank' rel="noopener noreferrer" >
                                    <button className='btn btn-info'>
                                        Ver manual de Jazz
                                    </button>
                                </a>
                            </div>
                            }/>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Help;
