import React, {useMemo,useCallback, useContext, useState} from 'react';
import {ApiContext} from "../../../api/Api";
import ReactTable from 'react-table';
import Button from '../../../elements/CustomButton/CustomButton';
import ProductFormModal from './ProductFormModal';
import ProductDetailModal from "./ProductDetailModal";
import _ from 'lodash';
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import useBoolean from "../../../hooks/useBoolean";
import useTideTable from "../../../hooks/table/useTideTable";
import moment from "moment";


const tableStructure = [
    {
        Header: 'Nombre',
        accessor: 'name'
    },
    {
        Header: 'Línea',
        accessor: (product) => product.line ? product.line.name : 'Sin línea',
        id: 'line.name'
    },
    {
        Header: 'Unidad más pequeña',
        accessor: (product) => product.smallestUnit? product.smallestUnit.name: 'Sin unidad de medida',
        id: 'smallestUnit.name'
    },
    {
        Header: 'Unidad para mostrar',
        accessor: (product) => product.displayUnit? product.displayUnit.name: 'Sin unidad de medida',
        id: 'displayUnit.name'
    },
];

const reportConfig = {
    name: `Productos de almacén ${moment().format("YYYY-MM-DD HH_mm")}.xlsx`,
    headers: {
        'name': 'Nombre',
        'isActive':'Activo',
        'description': 'Descripción',
        'line.name': 'Línea',
        'smallestUnit.name': 'Unidad más pequeña',
        'displayUnit.name': 'Unidad para mostrar',
        'id':'ID',
    }
};

const ProductList = () => {

    const [form, setForm] = useState({isOpen:false, product: null});
    const [showingDetails, setShowingDetails ] = useState(null);

    const [deletingProduct, setDeletingProduct]=useState(null);
    const [hideInactive,,,toggleHideInactive]=useBoolean(true);

    const api = useContext(ApiContext);

    const requestFilters=useMemo(()=>({
        isService:false,
        isActive:hideInactive?true:undefined
    }),[hideInactive]);

    const productTable=useTideTable({
        entity:'products',
        columns:tableStructure,
        requestFilters
    });

    const hideForm = ()=>{
        setForm({isOpen:false, product: null});
    };

    const showForm = (product)=>{
        setForm({isOpen: true, product: product});
    };

    const hideDetails = () =>{
        setShowingDetails(null);
    };

    const onEdit = useCallback(product =>{
        setShowingDetails(null);
        setForm({isOpen: true, product})
    },[]);

    const onDelete=useCallback(product=>{
        setShowingDetails(null);
        setDeletingProduct(product);
    },[]);

    const onActivateToggle=useCallback(product=>{
        setShowingDetails(null);
        api.products.edit({id: product.id, product:{isActive: !product.isActive }});
    },[api]);

    const deleteProduct=useCallback(()=>{
        api.products.delete({id:deletingProduct.id});
        setDeletingProduct(null);
    },[api, deletingProduct]);


    const cancelDelete=useCallback(()=>{
        setDeletingProduct(null);
    },[]);

    const didChangeProduct=useCallback(()=>{
        productTable.reload()
            .then( products=>{
                if(!showingDetails) return;
                const newDetail = _.find(products, p=>p.id===showingDetails.id);
                setShowingDetails(newDetail||null);
            });
    },[productTable, showingDetails]);


    return (
        <div className="container-fluid main-container">

            <Button bsStyle='primary' onClick={toggleHideInactive}>{hideInactive?'Mostrar':'Ocultar'} inactivos</Button>

            <Button bsStyle='primary' className='pull-right' onClick={()=>showForm(null)}>Nuevo producto</Button>
            <div className='clearfix'/>

            <ReactTable
                {...productTable.tableProps}
                getTdProps={(state, row) => ({onClick: () => row ? setShowingDetails(row.original) : null})}
                getTrProps={(state, row)=>({className: row && !row.original.isActive?'red-table-row':'' })}
            />
            <p className="total-count">{productTable.itemsFoundString}</p>
            <a href={api.products.exportUrl({reportConfig, filters:requestFilters })}>
                <button className='btn btn-primary'>Exportar a Excel</button>
            </a>

            {/*<AddProductModal onHide={hideAddProductModal}/>*/}
            {form.isOpen?<ProductFormModal editProduct={form.product} onHide={hideForm} />:null}

            {showingDetails&&
            <ProductDetailModal
                product={showingDetails}
                onHide={hideDetails}
                onEdit={onEdit}
                onDelete={onDelete}
                onActivateToggle={onActivateToggle}
                editable
                onChange={didChangeProduct}
            />}

            <ConfirmModal
                show={!!deletingProduct}
                message={`¿Estás seguro que deseas borrar el producto ${deletingProduct?deletingProduct.name:''}?`}
                onCancel={cancelDelete}
                onConfirm={deleteProduct}
                />

        </div>
    );

};

export default ProductList;
