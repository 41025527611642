import React, { useContext, useEffect, useState, useCallback } from 'react';
import "./WidgetUsedBudget.scss";
import { TideApiContext } from "../../../../../api/tideApiConfig";
import moneyFormatter from "../../../../../utils/moneyFormatter";
import classNames from 'classnames';

const WidgetUsedBudget = () => {
    const tideApi = useContext(TideApiContext);
    const [jazzAreas, setJazzAreas] = useState();

    useEffect(() => {
        tideApi.jazzAreas.get({pagination: false, params: {sGroups: ["jazz_area_read_monthly_used_budget", "jazz_area_read"]}}).then((response) => {
            setJazzAreas(response);
        })
    }, [tideApi]);

    const getPorcentageOutOfUsedBudget = useCallback((usedBudget, totalBudget) => {
        const expenses = Number(usedBudget);
        const budget = Number(totalBudget);
        const percentage = (expenses * 100) / budget
        if (percentage === null) return 0;
        return (percentage <= 100) ? percentage.toFixed(3) : 100;
    }, []);

    const getColorOutOfUsedBudget = useCallback((usedBudget, totalBudget) => {
        const expenses = Number(usedBudget);
        const budget = Number(totalBudget);
        const percentage = (expenses * 100) / budget
        switch (true) {
            case percentage <= 33:
                return 'bg-blue';
            case percentage <= 66 && percentage > 33:
                return 'bg-orange';
            case percentage > 66:
                return 'bg-red';
            default:
                return null;
        }
    },[])

    return (
        <div className={"WidgetUsedBudget"}>
            <h5 className='title text-center'>Resumen de <strong>presupuesto</strong> por área</h5>
            <ul className="bar-graph">
                {jazzAreas && jazzAreas.map((area) => (
                    <li key={area.id}>
                        <div className='graph-box'>
                            <p className='text-name'>{area.name}</p>
                            <div className="bar-wrap">
                                <p className='text-usedwidget'>{moneyFormatter(area.monthlyUsedBudget, 1, "mxn")} - {`${getPorcentageOutOfUsedBudget(area.monthlyUsedBudget, area.budget)}%`}</p>
                                <span className={classNames('bar-fill', getColorOutOfUsedBudget(area.monthlyUsedBudget, area.budget))} style={{width:`${getPorcentageOutOfUsedBudget(area.monthlyUsedBudget, area.budget)}%`}}></span>
                            </div>
                            <p className='text-budget'>{moneyFormatter(area.budget, 1, "mxn")}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default WidgetUsedBudget;
