import React, {useContext, useState} from 'react';
import {fileClassification, fileTypeName} from "../../../../utils/xFilesUtils";
import {ApiContext} from "../../../../api/Api";
import PreviewXFileModal from "./PreviewXFileModal";

const XFileAdmin= ({employee, xFile, onDelete, deleteOtherFile}) => {
    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [showingPreview, setShowingPreview] = useState(false);
    const api = useContext(ApiContext);
    const arrayXFile = fileClassification.map(f => ({
        ...f,
        value: xFile[f.key]
    })).filter(({value}) => value);
    const otherFiles = xFile.otherFiles || [];
    if (arrayXFile.length === 0 && otherFiles.length === 0) {
        return (
            <p>El empleado no tiene archivos en su expediente.</p>
        );
    }
    const downloadUrl = fileId => {
        const url = api.appFiles.getDownloadUrl(fileId);
        window.open(url);
    };
    const downloadFile = fileType => () => {
        downloadUrl(xFile[fileType].id);
    };
    const showPreview = fileType => ()=>{
        setShowingPreview(true);
        setFile(xFile[fileType]);
        setFileType(fileType);
    };
    return (
        <>
            {showingPreview &&
            <PreviewXFileModal file={file} employee={employee} fileType={fileType}
                               onHide={() => setShowingPreview(false)}/>
            }
            <div className='row'>
                {arrayXFile.map(f => (
                    <div key={f.key} className='col-md-3' style={fileBoxStyle}>
                        <h2>
                        <span className='open-modal' onClick={showPreview(f.key)}>
                            {fileTypeName(f.key)}
                        </span>
                            <span style={iconContainerStyle}>
                            <i style={iconStyle} className='fa fa-download' onClick={downloadFile(f.key)}/>
                            <i style={iconStyle} className='fa fa-trash' onClick={onDelete(f.key)}/>
                        </span>
                        </h2>
                    </div>
                ))
                }
                {otherFiles.length > 0 &&
                <div className='col-md-3' style={fileBoxStyle}>
                    <h2>
                        Otros archivos
                    </h2>
                    <ul>
                        {otherFiles.map((o, k) => (
                            <li key={k}>
                                <span style={downloadLinkStyle}
                                      onClick={() => downloadUrl(o.id)}>{o.originalName}</span>
                                <span>
                                     <i onClick={deleteOtherFile(otherFiles, k)} style={iconStyle}
                                        className='fa fa-trash'/>
                                 </span>
                            </li>
                        ))}
                    </ul>
                </div>
                }
            </div>
        </>
    )
};

const fileBoxStyle = {
    border: '2px solid',
    borderRadius: '10px',
    backgroundColor: 'white',
    margin: '20px'
};

const iconStyle = {
    cursor: 'grab',
    display: 'inline'
};

const iconContainerStyle = {
    right: '20px',
    position: 'absolute',
};

const downloadLinkStyle = {
    cursor: 'grab'
};

export default XFileAdmin;
