import React from 'react';


export default function TextAnswer( props ){

    let answer = '';
    if( props.data && props.data.response )
        answer = props.data.response;

    const onChange = (e)=>{

        if( e.target.value === '' )
            return props.onChange( undefined );
        props.onChange( {response:e.target.value} );
    };

    return (
        <div className='answer-component TextAnswer'>
            <input
                className='form-control'
                value={answer}
                onChange={onChange}
                disabled={!props.editable}
            />
        </div>
    );

}