import React, {useMemo} from 'react';
import ReactTable from "react-table";
import useTideTable from "../../../../hooks/table/useTideTable";
import {listSGroups, orozcoPrintsListTable} from '../../../../utils/modelUtils/orozco/orozcoPrintsUtils';

const PrintsList = () => {

    const requestFilters = useMemo(() => ({
        sGroups: listSGroups,
        'order[createdDate]': 'DESC',
        'orozcoPayments[exists]': true
    }), []);

    const table = useTideTable({
        entity: 'orozcoPrints',
        columns: orozcoPrintsListTable,
        requestFilters
    });

    return (
        <div className='PrintsList container-fluid'>
            <ReactTable
                {...table.tableProps}
            />
        </div>
    );
};

export default PrintsList;
