import React, {useCallback, useContext, useState} from 'react';
import './_RecipeFormModal.scss';
import ClassicModal from "../../../../../../components/Modals/ClassicModal";
import {Table} from "react-bootstrap";
import {TideApiContext} from "../../../../../../api/tideApiConfig";
import useFormState from "../../../../../../hooks/useFormState";
import {prepareRecipeHeaderFormForServer} from "../../../../../../utils/modelUtils/orozco/orozcoRecipeUtils";
import {NotifierContext} from "../../../../../../utils/Notifier";
import {useSelector} from "react-redux";
import UnitBundleCreator from "../../../../../../components/UnitBundleCreator/UnitBundleCreator";
import {
    convertFromSmallestUnit,
    emptyUnitBundle,
    extractUnitBundleProperties,
    pluralizeUnitName
} from "../../../../../../utils/modelUtils/almazen/unitsUtility";

const cleanRecipeForHeader = (recipe)=>{
    const clean =  {...recipe};
    delete clean.orozcoRecipeIngredients;
    if(clean.smallestUnit)
        clean.quantity = convertFromSmallestUnit(clean.quantity, clean.displayUnit, clean);
    return clean;
};

const RecipeFormModal = ({ orozcoProduct:op, recipe, onHide, afterSave }) => {

    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);
    const {form, handleInputChange} = useFormState(cleanRecipeForHeader(recipe));
    const [unitBundle, setUnitBundle] = useState(recipe?.smallestUnit? extractUnitBundleProperties(recipe): emptyUnitBundle() );

    // .--- Save to server ---
    const loadingId = 'RecipeFormModal.OrozcoRecipe';

    const save = useCallback(()=>{
        let preparedRecipe;
        if(op) {
            form.name = op.name;
            form.orozcoProduct = op;
        }
        try {
            preparedRecipe = prepareRecipeHeaderFormForServer({...form, ...unitBundle});
        }
        catch (e){
            return notifier.error(e.message);
        }

        api.orozcoRecipes[recipe?'update':'create']({ id:recipe?.id, params: preparedRecipe, loadingId })
            .then(afterSave);

    },[api, op, notifier, form, afterSave, recipe, unitBundle]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);
    const canCancel = !!op?.orozcoRecipe;

    const recipeType = (op || recipe?.orozcoProduct)?'Platillo':'Sub receta';

    return (
        <ClassicModal
            className={"RecipeFormModal"}
            title={`Información de receta - ${recipe?.name||op?.name||''}`}
            onHide={onHide}
            showCancel={canCancel}
            onSave={save}
            disabled={loading}
            bsSize={'lg'}
        >
            <Table>
                <tbody>
                <tr>
                    <td>Nombre:</td>
                    <td>{!!op?op.name:<input className='form-control' value={form.name||''} onChange={handleInputChange('name')}/>}</td>
                </tr>
                <tr>
                    <td>Clasificación:</td>
                    <td><input className='form-control' value={form.classification||''} onChange={handleInputChange('classification')}/></td>
                </tr>
                <tr>
                    <td>Tiempo de preparación:</td>
                    <td><input className='form-control' value={form.elaborationMinutes||''}
                               onChange={handleInputChange('elaborationMinutes')}
                               placeholder={'Minutos'}
                    /></td>
                </tr>
                {recipeType !== 'Platillo' &&
                <tr>
                    <td>Rendimiento:</td>
                    <td className='p-relative'><input className='form-control' value={form.quantity||''}
                               onChange={handleInputChange('quantity')}/>
                               <span className='unit-label'>{pluralizeUnitName(form.quantity, unitBundle?.displayUnit?.name)}</span>
                    </td>
                </tr>}
                </tbody>
            </Table>
            <hr/>
            {recipeType === 'Platillo' ?
                <p>
                    <b>Receta de platillo</b><br/>
                    Las recetas asignadas directamente a un platillo siempre deben estar calculadas para una
                    porción.<br/>
                    El nombre de la receta será siempre el mismo que el del platillo.
                </p> :
                <div>
                    <h4>Configuración de unidades</h4>
                    <UnitBundleCreator
                        unitBundle={unitBundle}
                        onChange={setUnitBundle}
                        showTemplates={false}
                    />
                </div>
            }

        </ClassicModal>
    );
};

export default RecipeFormModal;
