import React, {Component} from 'react';
import {Col, Panel, Row, Table} from 'react-bootstrap';
import Question from './Question';
import CustomButton from "../../elements/CustomButton/CustomButton";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { QUEUED_RESPONSE } from "../../api/constants_api";
import _ from 'lodash';
import questionnaireHelper from './helper';
import moment from "moment/moment";
import {employeeFullName} from "../../utils/modelUtils/alma/employeeUtils";


class Questionnaire extends Component{

    static contextTypes = { api: PropTypes.object, notifier: PropTypes.object };

    static defaultProps = { displayName:true };

    state = { answers:{} };

    loadingId = '@Class.Questionnaire.filledQuestionnaires.create';

    constructor( props ){
        super(props);


        if( props.filledQuestionnaire ) {
            const answers = props.filledQuestionnaire.answers.reduce(
                (acc, answer)=>{
                    acc[ answer.question.id ] = answer.data;
                    return acc;
            }, {} );

            this.state = {answers}
        }
    }

    handleQuestionAnswered = ( question, data )=>{

        const answers = {...this.state.answers };
        answers[ question.id ] = data;

        this.setState( {answers} );
    };

    sendQuestionnaire = ()=>{

        const questions = this.props.questionnaire.questions;
        const answers = this.state.answers;
        const answersArray =[];

        for( let i=0; i<questions.length; i++ ){

            const data = answers[questions[i].id];

            if( typeof data === 'undefined' || data === '' ) {//The question hasn't been answered
                if( questions[i].config && questions[i].config.required && this.shouldRenderQuestion( questions[i] ) ) {//If it's required we stop and send an error

                    const elem = document.querySelector('[data-id="'+questions[i].id+'"]');
                    if( elem ) elem.scrollIntoView();

                    this.context.notifier.error(<span>No se han respondido todas las preguntas obligatorias, falta:<br/>{questions[i].text}</span>);
                    return;
                }
                else{//If it's not required we just ignore it.
                    continue;
                }
            }
            answersArray.push( { question: questions[i].id, data } );
        }

        if( this.props.manualSend )
            return this.props.manualSend(answersArray);

        this.context.api.filledQuestionnaires.create( {
            questionnaire:this.props.questionnaire.id,
            answers:answersArray,
            visit: this.props.visit,
            loadingId:this.loadingId} )
            .then(( response )=>{

                if( response === QUEUED_RESPONSE )
                    this.context.notifier.warning( 'El cuestionario será enviado cuando se recupere la conexión.', undefined, { autoDismiss:10 } );
                else
                    this.context.notifier.success( 'Cuestionario enviado correctamente.', undefined, { autoDismiss:10 } );

                if( this.props.onSend )
                    this.props.onSend();
            });

    };

    shouldRenderQuestion = ( question )=>{

        if( !question.config)
            return true;
        if(question.config.inactive && this.props.questionnaire)//If it's inactive and it's not a filledQuestion, hide it
            return false;
        if(!question.config.isConditional ||
            !question.config.conditionalQuestion ||
            !question.config.conditionalAnswer ||
            !this.props.questionnaire
        )//If is not a conditional question or we are looking at a filledQuestionnaire, then always render
            return true;

        const conditionalQuestion = _.find( this.props.questionnaire.questions,
            ( quest )=> quest.config && quest.config.uid ? (quest.config.uid === question.config.conditionalQuestion) :false );

        if( !conditionalQuestion ) //This means that there is a condition on a non existent question. Someone created the condition and the deleted the question it depended upon.
            return false;

        if( !this.state.answers[ conditionalQuestion.id ] ) //The question has not yet been answered
            return false;

        let conditionalAnswer = question.config.conditionalAnswer;

        if( conditionalQuestion.type === 'yesno' ){
            const cond = conditionalAnswer.toLowerCase().trim();
            if(cond === 'si' || cond === 'sí' )
                conditionalAnswer = true;
            else if( cond === 'no' )
                conditionalAnswer = false;

        }
        else if( conditionalQuestion.type === 'number' ){
            conditionalAnswer = Number( conditionalAnswer );
        }
        else if( conditionalQuestion.type === 'MultipleChoiceSelectAnswer' ){
            return this.state.answers[conditionalQuestion.id].response.indexOf( conditionalAnswer ) !== -1;
        }

        if( typeof this.state.answers[conditionalQuestion.id].response === 'string' && typeof conditionalAnswer === 'string' ) {
            const answers = conditionalAnswer.toLowerCase().split('|').map( (str)=>str.trim() );
            const actualAnswer = this.state.answers[conditionalQuestion.id].response.toLowerCase();
            return answers.indexOf( actualAnswer ) !== -1;
        }

        return this.state.answers[conditionalQuestion.id].response === conditionalAnswer;
    };

    renderScoreTable( scores ) {
        if (!scores || !scores.groupScores || !scores.groupScores.length)
            return null;

        const groups = _.filter(scores.groupScores, gScore => gScore.score !== false );

        if ( groups.length <= 1 )
            return null;

        return <Table className='text-center'>
            <tbody>
            <tr>
                <th className='text-center'>Grupo</th>
                <th className='text-center'>Preguntas</th>
                <th className='text-center'>Calificación</th>
            </tr>
            {groups.map( (gScore, i) =>
                <tr key={i}>
                    <td>{gScore.group? gScore.group : 'Sin grupo'}</td>
                    <td>{gScore.count}</td>
                    <td>{Math.round(gScore.score * 1000) / 1000 }</td>
                </tr>
            )}
            </tbody>
        </Table>
    }

    transStatus = (status) =>{
        switch (status) {
            case 'pending_review':
            case 'pending_approval':
                return 'Sin revisar';
            case 'approved':
                return 'Aprobado';
            case 'rejected':
                return 'Rechazado';
            default:
                return '';
        }
    };


    render(){

        let questionnaire, questions;
        if( this.props.questionnaire) {
            questionnaire = this.props.questionnaire;
            questions = questionnaire.questions;
        }
        else if( this.props.filledQuestionnaire) {
            questionnaire = this.props.filledQuestionnaire.questionnaire;
            questions = this.props.filledQuestionnaire.answers.map( (answer)=>answer.question );
        }

        questions = questionnaireHelper.sortQuestionnaire( questions );

        if( !questionnaire ) return null;

        const filteredQuestions = questions.filter(this.shouldRenderQuestion);
        const splittedInGroups = questionnaireHelper.splitInGroups( filteredQuestions );
        const scores = questionnaireHelper.getQuestionnaireScore( this.props.filledQuestionnaire );

        return(
            <Panel className='Questionnaire' >
                {this.props.displayName?
                    <h3>{questionnaire.name}</h3>
                    :null}

                {!questionnaire.isGcare&&(scores && scores.score !== false)?
                <h4 className='whole-score'>Calificación: {Math.round( scores.score* 1000) / 1000 }</h4>
                    :null}

                {questionnaire.isGcare?this.renderScoreTable( scores ):null}

                {this.props.filledQuestionnaire && questionnaire.config && questionnaire.config.requireApproval && this.props.filledQuestionnaire.reviewer?
                    <div>
                        Estado: {this.transStatus(this.props.filledQuestionnaire.status)}
                        <br/>
                        {this.props.filledQuestionnaire.status==='approved' || this.props.filledQuestionnaire.status==='rejected'?
                            <>
                               Fecha de revisión: {moment(this.props.filledQuestionnaire.reviewedDate).format("DD/MM/YYYY HH:mm")}
                                <br/>
                                Revisado por: {employeeFullName(this.props.filledQuestionnaire.reviewer)}
                            </>:null
                        }
                    </div>:null
                }


                { splittedInGroups.map( (group, index)=>

                    <div className={ group[0].config && group[0].config.group? 'block-with-group':'just-block-no-group' } key={index}>
                        {group[0].config && group[0].config.group?
                            <h4>{group[0].config.group}
                                {(()=>{
                                    if( !scores || !scores.groupScores ) return null;

                                    const groupScore = _.find( scores.groupScores, gScore=>gScore.group === group[0].config.group );
                                    if( !groupScore || groupScore.score === false ) return null;

                                    return <span className='text-muted small pull-right'>
                                        Puntaje: {Math.round( groupScore.score* 1000) / 1000 }
                                    </span>
                                })()}
                            </h4>
                            :null}

                        <hr/>

                        {group.map((question, index)=> {

                            return <Row key={question.id} data-id={question.id}>
                                <Col xs={12} >
                                    <Question
                                        question={question}
                                        index={index + 1}
                                        data={this.state.answers[question.id]}
                                        onAnswer={this.handleQuestionAnswered}
                                        editable={!!this.props.questionnaire}
                                        questionnaire={questionnaire}
                                    />
                                </Col>
                            </Row>
                        })}
                    </div>

                ) }

                {this.props.questionnaire?
                <CustomButton
                    bsStyle='success'
                    onClick={this.sendQuestionnaire}
                    disabled={!!(this.props.loadingIds[this.loadingId] || this.props.loading) }
                >
                    {this.props.loadingIds[this.loadingId] || this.props.loading?
                        'Enviando...':
                        'Enviar cuestionario'
                    }
                </CustomButton>
                    :null}
            </Panel>
        )
    }
}



const mapStateToProps = ({loadingIds})=>({loadingIds});

export default connect(mapStateToProps)(Questionnaire);
