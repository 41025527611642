import React, {useMemo, useState, useCallback} from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";
import {
    stockMovementBatchStatus,
    stockMovementBatchType,
    stockMovementBatchOrigin
} from "../../../../utils/modelUtils/almazen/stockMovementBatchUtils";
import useTideTable from "../../../../hooks/table/useTideTable";
import useTideEntity from "../../../../hooks/useTideEntity";
import {displayAmount} from "../../../../utils/modelUtils/almazen/unitsUtility";
import ReactTableDataRangeFilter from "../../../../components/Helper/ReactTableDateRangeFilter";
import {tableStateToApiOptions} from "../../../../utils/reactTableUtils";
import {Col, OverlayTrigger, Row} from "react-bootstrap";
import Switch from "react-bootstrap-switch";
import Card from "../../../../components/Card/Card";
import productUnitsTooltip from "../../components/ProductUnitsTooltip";
import {
    getStockMovementTypeElement,
    stockMovementInventoryListSGroups
} from "../../../../utils/modelUtils/almazen/stockMovementUtils";

const InventoryMovementsHistory = ({match}) => {

    const {warehouse: warehouseId} = match.params;
    const [warehouse, loading, notFound] = useTideEntity('warehouses', warehouseId);
    const [movementOrigin, setMovementOrigin] = useState('');
    const [showPendingMovements, setShowPendingMovements] = useState(false);

    const handleOriginChange = useCallback(({target}) => {
        setMovementOrigin(target.value);
    }, []);

    const handleShowPendingMovementsChange = useCallback((s, val) => {
        setShowPendingMovements(val)
    }, []);

    const tableOptions = useMemo(() => {

            const filters = {
                sGroups: stockMovementInventoryListSGroups,
                'batch_warehouse': warehouseId,
                'order[createdDate]': 'DESC'
            };

            if (!showPendingMovements) {
                filters.status = stockMovementBatchStatus.COMPLETED;
            }

            if (movementOrigin !== '') {
                if (movementOrigin === stockMovementBatchOrigin.PROVIDER_DELIVERY) {
                    filters['batch.purchaseOrder[exists]'] = true;
                }
                if (movementOrigin === stockMovementBatchOrigin.REQUISITION) {
                    filters['batch.stockRequest[exists]'] = true;
                }
                if (movementOrigin === stockMovementBatchOrigin.MANUAL) {
                    filters['batch.stockRequest[exists]'] = false;
                    filters['batch.purchaseOrder[exists]'] = false;
                }
            }

            return {
                entity: 'stockMovements',
                getMethod: 'getOptimized',
                pause: !warehouse,
                columns: tableStructure(movementOrigin, warehouse),
                requestOptions: {
                    customProp: 'InventoryMovementsHistory.stockMovements',
                    filters
                },
                createApiOptions: (tableState, addOptions, config, filters) => {
                    const apiOptions = tableStateToApiOptions(tableState, addOptions, config, filters);

                    const apiFilters = apiOptions.filters;
                    if (apiFilters.createdDate && apiFilters.createdDate.startDate)
                        apiFilters['createdDate[after]'] = apiFilters.createdDate.startDate.format('YYYY-MM-DDT[00:00]');

                    if (apiFilters.createdDate && apiFilters.createdDate.endDate)
                        apiFilters['createdDate[before]'] = apiFilters.createdDate.endDate.format('YYYY-MM-DDT[23:59:59]');

                    if(apiFilters['batch.inOrOut']){
                        const type = apiFilters['batch.inOrOut'].toLowerCase();
                        if("entrada".search(type)!==-1)
                            apiFilters['batch.inOrOut'] = stockMovementBatchType.IN;
                        else if("salida".search(type)!==-1)
                            apiFilters['batch.inOrOut'] = stockMovementBatchType.OUT;
                    }

                    delete apiFilters.createdDate;

                    return apiOptions;
                }
            }
        }
        , [warehouseId, movementOrigin, showPendingMovements, warehouse]);

    const movementsTable = useTideTable(tableOptions);

    return (
        <div className="InventoryMovementsHistory container-fluid main-container">
            <Card
                title={<span>
                    {loading && 'Cargando...'}
                    {notFound && 'Almacén no encontrado'}
                    {warehouse && 'Almacén - '+warehouse.name}
                </span>}
                content={
                    <div>

                        <div className='filters-wrapper container-fluid'>
                            <Row>
                                <Col xs={12} md={6} lg={4} className='text-center'>
                                    <p>Origen del movimiento:</p>
                                    <select
                                        className='form-control'
                                        value={movementOrigin}
                                        onChange={handleOriginChange}
                                    >
                                        <option value=''>Todos los movimientos</option>
                                        <option value={stockMovementBatchOrigin.PROVIDER_DELIVERY}>
                                            Entrega de proveedor
                                        </option>
                                        <option value={stockMovementBatchOrigin.REQUISITION}>Requisición</option>
                                        <option value={stockMovementBatchOrigin.MANUAL}>Sin origen</option>

                                    </select>
                                </Col>
                                <Col xs={12} md={6} lg={4} className='text-center'>
                                    <p>Mostrar movimientos pendientes:</p>
                                    <Switch value={showPendingMovements} onChange={handleShowPendingMovementsChange}/>
                                </Col>
                            </Row>
                        </div>


                        <ReactTable
                            {...movementsTable.tableProps}
                            ofText={''}
                            pages={10000000}
                            getTrProps={(state, row) => ({className: row && row.original.status === stockMovementBatchStatus.PENDING ? 'pending-movement' : ''})}
                            minRows={10}
                        />
                    </div>
                }/>
        </div>

    )
};

export default InventoryMovementsHistory;

const tableStructure = (movementOrigin, warehouse) => {
    let structure = [
        {
            Header: 'Producto',
            accessor: (move) => (`${move.productBrand.product.name} - ${move.productBrand.brand.name}`),
            id: "productBrand.product.name"
        },
        {
            Header: 'Solicitante',
            accessor: (move) => {
                if(move.batch.purchaseOrder){
                    return employeeFullName(move.batch.purchaseOrder.purchaseRequisition.requestedBy);
                }
                if(move.batch.stockRequest)
                    return employeeFullName(move.batch.stockRequest.createdBy.employee);

                return  move.completedBy? employeeFullName(move.completedBy.employee):'Sin información'
            },
            id: 'search'
        },
        {
            Header: 'Realizado por',
            accessor: (move) => move.completedBy?employeeFullName(move.completedBy):'Sin realizar',
            id: 'completedBy.name'
        },
        {
            Header: 'Tipo',
            accessor: (move) =>
                <div className='text-center'>
                    {getStockMovementTypeElement(move)}
                </div>,
            id: 'batch.inOrOut'
        },
        {
            Header: 'Cantidad',
            accessor: (move) =>(
                <OverlayTrigger placement="top" overlay={productUnitsTooltip(move.quantity, move.productBrand.product)}>
                    <span>{displayAmount(move.quantity, move.productBrand.product)}</span>
                </OverlayTrigger>
            ),
            id: "quantity",
            filterable: false
        },
        {
            Header: 'Receta',
            accessor: 'orozcoOrderProduct.orozcoProduct.name'
        }
    ];

    if (movementOrigin === '' || movementOrigin === stockMovementBatchOrigin.PROVIDER_DELIVERY) {
        structure = [...structure, {
            Header: 'Requisición de Compra/Almacén',
            accessor: (move) =>
                move?.batch?.purchaseOrder ?
                    move?.batch?.purchaseOrder?.folio:
                    (move?.batch?.stockRequest?.folio||"Sin información")
            ,
            id: "batch.purchaseOrder",
            filterable: false,
            sortable: false
        }];
    }
    if (movementOrigin === '' || movementOrigin === stockMovementBatchOrigin.REQUISITION) {

        structure = [...structure,
            {
                Header: 'Lugar de origen',
                accessor: (move) => {
                    if(move.batch.purchaseOrder)
                        return 'Proveedor';

                    return (move.batch.stockRequest && move.batch.stockRequest.fromWarehouse)?move.batch.stockRequest.fromWarehouse.name: 'Sin lugar de origen'
                },
                id: "batch.stockRequest.fromWarehouse.name"
            },
            {
                Header: 'Lugar de destino',
                accessor: (move) => {
                    if(move.batch.purchaseOrder)
                        return warehouse.name;

                    return move.batch.stockRequest?move.batch.stockRequest.toPlace.name: 'Sin lugar de destino'

                },
                id: "batch.stockRequest.toPlace.name"
            }
        ];
    }

    structure = [...structure,
        {
            Header: 'Fecha de solicitud',
            accessor: (move) => moment(move.createdDate).format("DD/MM/YYYY HH:mm"),
            id: "createdDate",
            Filter: ReactTableDataRangeFilter
        },
        {
            Header: 'Fecha de movimiento',
            accessor: (move) => move.completedDate ? moment(move.completedDate).format("DD/MM/YYYY HH:mm") : 'Sin realizar',
            id: "completedDate",
            Filter: ReactTableDataRangeFilter
        }
    ];

    return structure;
};




