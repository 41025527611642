import React from 'react';

import {
    ACTION_CACHE_SAVE,
    ACTION_LOG,
    ACTION_PREFIX,
    ACTION_QUEUE_PUSH,
    API_URL,
    LOGIN_STATE,
    QUEUED_RESPONSE,
    STATE_ACTION_PUSH,
    STATE_ACTION_APPEND,
    STATE_ACTION_CLEAR,
    STATE_ACTION_SEARCH_N_DELETE,
    STATE_ACTION_SEARCH_N_REPLACE,
    STATE_ACTION_SET,
    STATE_ACTION_SET_SINGLE
} from "./constants_api";
import {APP_LOADING_END, APP_LOADING_START, CLEAR_STATE} from "../actions/types";
import Notifications from 'react-notification-system-redux';
import moment from 'moment';
import timekeeper from 'timekeeper'; //For TimeTravel
import {version} from '../pckg';
import {searchCache} from './CacheManager';
import {createQueueItem, startQueueInterval} from './QueueManager';
import qs from 'qs';
import sysadminAvatar from '../assets/img/faces/sysadmin.png';
import avatar from '../assets/img/faces/face-0.jpg';
import DeviceDetector from "device-detector-js";


export const ApiPaths =
{
    airlines: '/api/airlines',
    airConciergeReservations: "/api/air_concierge_reservations",
    appFiles: "/api/app_files",
    appFileUrl: "/file",
    appFileDownload: "/file/download",
    applicants: "/api/applicants",
    appliedBonuses: "/api/applied_bonuses",
    appliedDiscounts: "/api/applied_discounts",
    appliedIncidences: "/api/applied_incidences",
    appliedIncidenceReport: "/api/reports/applied-incidences",
    appointments: "/api/appointments",
    answers:"/api/answers",
    areas:"/api/areas",
    assistanceReportUploads: "/api/assistance_report_uploads",
    banks:"/api/banks",
    bonuses:"/api/bonuses",
    bonusExtraHour: "/api/extra_hour_applied_bonuses",
    bonusExtraordinary: "/api/extraordinary_applied_bonuses",
    bonusPreviousPeriodJustification: "/api/previous_period_justification_applied_bonuses",
    brands: "/api/brands",
    calculatePayroll: "/api/payroll/calculate",
    cassetteEmployees:"/api/cassette_employees",
    checkIns: "/api/check_ins",
    clocks:"/api/clocks",
    comments:"/api/comments",
    countries: "/api/countries",
    configurations:"/api/configurations",
    employeeApprovalRules:"/api/employee_approval_rules",
    employeeProfiles:"/api/employee_profiles",
    employees:"/api/employees",
    employeeFire:"/api/employees/fire",
    employeesFollowedEmployees: "/api/employees/followed_employees",
    employeeHire:"/api/employees/rehire",
    employeeWorkTools:"/api/employee_work_tools",
    employeeXFiles: "/api/employee_x_files",
    employeesSimple: "/api/employees_simple",
    employeesWishedRewards: "/api/employees_wished_rewards",
    employeesEditWishedRewards: "/api/employees/employees_wished_rewards",
    //filePath: "/api/file",
    //fileDownloadPath: "/api/file/download",
    filePath: "/file",
    fileDownloadPath: "/file/download",
    filledQuestionnaires: "/api/filled_questionnaires",
    flights: "/api/flights",
    destinationTypes: "/api/destination_types",
    followedEmployees: id=>`/api/employees/${id}/followed_employees`,
    frontendError: "/frontend_error",
    gcare:"/gcare",
    gcareReport: "/api/gcare/report",
    golfCartTrips: "/api/golf_cart_trips",
    groups:"/api/groups",
    guestsVipNonGrataReport: "/api/guests/vip_non_grata_report",
    guestsGenerationReport: "/api/guests/generation_report",
    happinessLogs: '/api/happiness_logs',
    happinessLogsReport: '/api/happiness_logs/report',
    happinessLogsExcelReport: '/api/happiness_logs/excel_export',
    incidenceGenerations: "/api/incidence_generations",
    incidenceJustifications: "/api/incidence_justifications",
    incidences:"/api/incidences",
    inventories:"/api/inventories",
    journals:"/api/journals",
    layoffRequests: "/api/layoff_requests",
    layoffs: "/api/layoffs",
    logIn: "/api/login_check",
    loginRecords: "/api/xlogin_records",
    logOut: "/logout",
    loungeAccessMethods: "/api/lounge_access_methods",
    lounges:"/api/lounges",
    loungeSubAccessMethods: "/api/lounge_sub_access_methods",
    me:"/api/me",
    measurementUnits:"/api/measurement_units",
    measurementConversionTemplates:"/api/measurement_conversion_templates",
    notifications:"/api/notifications",
    notificationsHeader:"/api/notifications/get_header_notifications",
    notificationTopics:"/api/notification_topics",
    haveExtraMealTime:"/api/me/have_extra_meal_time",
    orozcoCashRegisters:"/api/orozco_cash_registers",
    orozcoCashRegistersCurrentCash: (cashRegister)=>(`/api/orozco_cash_registers/${cashRegister.id}/current_money`),
    orozcoCashRegistersCashToPickUp: (cashRegister)=>(`/api/orozco_cash_registers/${cashRegister.id}/to_pick_up`),
    orozcoCashClosings:"/api/orozco_cash_closings",
    orozcoCashPickUps:"/api/orozco_cash_pick_ups",
    orozcoPayments: "/api/orozco_payments",
    orozcoProducts: "/api/orozco_products",
    orozcoPrints: "/api/orozco_prints",
    orozcoProductCategories: "/api/orozco_product_categories",
    orozcoRecipes: "/api/orozco_recipes",
    orozcoVisitOrderProducts: '/api/orozco_visit_order_products',
    payrollDetails: "/api/payroll_details",
    payrollDetailsDownload: "/api/payroll/export",
    payrollDetailsEmployee: (employeeId)=>(`/api/employees/${employeeId}/payroll_details`),
    payrollDetailsRealTime: "/api/payroll/calculate-real-time",
    payrollDetailsReport: '/api/reports/payroll-details',
    payrolls: "/api/payrolls",
    permissionGroups: "/api/permission_groups",
    personnelRequisitions:"/api/personnel_requisitions",
    places:"/api/places",
    pointsWallets: "/api/points_wallets",
    positions:"/api/positions",
    productLines: "/api/product_lines",
    products: "/api/products",
    productBrands: "/api/product_brands",
    productBrandProviders: "/api/product_brand_providers",
    productBrandUnits: "/api/product_brand_units",
    providerEvaluations: "/api/provider_evaluations",
    providers: "/api/providers",
    purchaseApprovalRules:"/api/purchase_approval_rules",
    purchaseRequisitionApprovals:"/api/purchase_requisition_approvals",
    purchaseRequisitions: "/api/purchase_requisitions",
    purchaseRequisitionsComments: (purchaseId) => (`/api/purchase_requisitions/${purchaseId}/comments`),
    purchaseProducts: "/api/purchase_products",
    purchaseOrders: "/api/purchase_orders",
    questionnaires: "/api/questionnaires",
    questionnaireReport: "/api/reports/questionnaires",
    recognitions: "/api/recognitions",
    recognitionMovements: "/api/recognition_movements",
    refillingPointsRules: "/api/refilling_points_rules",
    repositoryFiles: "/api/repository_files",
    reservations: "/api/reservations",
    rewardExchanges: "/api/reward_exchanges",
    rewards: "/api/rewards",
    roles: "/api/roles",
    scoresGenerationsBiweekly: "/api/scores_generations/biweekly",
    scoresGenerationsBiweeklyReport: "/api/scores_generations/report",
    shiftCategories: "/api/shift_categories",
    stockMovementBatches: "/api/stock_movement_batches",
    shifts: "/api/shifts",
    staffRotationReport: '/api/reports/staff_rotation',
    uploadTimeReport: "/api/upload/time-report",
    services: "/api/services",
    serviceRequests: "/api/service_requests",
    shrinkages: "/api/shrinkages",
    stockMovements: "/api/stock_movements",
    stockRequestApprovals: "/api/stock_request_approvals",
    stockRequests: "/api/stock_requests",
    system: "/api/system",
    tables:"/api/tables",
    taxes:"/api/taxes",
    tickets:"/api/tickets",
    ticketTypes:"/api/ticket_types",
    tgleCompanies:"/api/tgle_companies",
    users:"/api/users",
    usersSimple:"/api/users_simple",
    usersSubordinates:"/api/users_subordinates",
    visits: "/api/visits",
    warehouseCounts:"/api/warehouse_counts",
    warehouses:"/api/warehouses",
    warehousesPendingMovements: (warehouseId)=> (`/api/warehouses/${warehouseId}/pending_movements`),
    workTools:"/api/work_tools",
    webPush:"/api/webpush/",
    webPushVapidPublicKey: "/vapid_public_key",
    gcareByHourReport: "/api/gcare_by_hour_report",
    receptionistsCheckinReport: "/api/receptionists_checkin_report"
};
export default class Api {

    constructor( baseUrl, store )
    {
        this.baseUrl = baseUrl;
        this.store = store;
        //this.isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
        this.isSafari = true; //Force authorization header instead of cookie

        if( this.isSafari && window.localStorage && window.localStorage.googlead )
            this.token = window.localStorage.googlead;

        //TimeTravel hack
        if( process.env.REACT_APP_BUILD === 'dev' ) {
            if (window.localStorage.timeTravel)
                timekeeper.travel(new Date(window.localStorage.timeTravel));
        }

        startQueueInterval( this );

        if( window.navigator.serviceWorker ) {
            window.navigator.serviceWorker.addEventListener('message', event => {
                if (event.data === 'refresh')
                    window.location.reload();
            });
        }

        this.requestLog = [];

    }
    stateActions = {
        create: STATE_ACTION_PUSH,
        update: STATE_ACTION_SEARCH_N_REPLACE,
        delete: STATE_ACTION_SEARCH_N_DELETE
    }

    createEntityCall = (endpoint, {data, config, files}) => {
        const {id, params = {}, customProp} = data || {};
        const method = config?.method;

        if(method === 'POST')
            config.stateAction = this.stateActions.create;
        if(method === 'PUT')
            config.stateAction = this.stateActions.update;
        if(method === 'DELETE'){
            config.stateAction = this.stateActions.delete;
            params.id = id;
        }

        return this.apiCall(
            ((method === "PUT" || method === "DELETE") && (id))
            ? `${endpoint}/${id}`
            : endpoint,
            customProp,
            params,
            config,
            files
        );
    }

    airlines = {
        get: (data = {}) => this.createEntityCall(ApiPaths.airlines, {data, config: {loadingId: data?.loadingId}}),
        update: (data = {}) => {
            const config = {method: 'PUT'};
            return this.createEntityCall(ApiPaths.airlines, {data, config});
        }
    }

    airConciergeReservations =
        {
            get: ( page=0, pageSize=200, loadingId, filters )=> this.apiCall(ApiPaths.airConciergeReservations, "airConciergeReservations", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId}  ),
            create: ( reservation, loadingId )=> this.apiCall( ApiPaths.airConciergeReservations, "airConciergeReservations", reservation, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( id, reservation, loadingId  )=> this.apiCall( `${ApiPaths.airConciergeReservations}/${id}`, "airConciergeReservations", reservation, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( id )=> this.apiCall( `${ApiPaths.airConciergeReservations}/${id}`, "airConciergeReservations", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    appFiles =
        {
            get: ( page=0, pageSize=10, loadingId, filters, customProp )=> this.apiCall(ApiPaths.appFiles, customProp? customProp:"appFiles" , {page:page+1,itemsPerPage:pageSize, ...filters }, {loadingId} ),
            create: ( file, loadingId)=>this.apiCall( ApiPaths.appFiles, "appFiles", { name: file.name}, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId}, file? {file}:undefined ),
            delete: ( id, loadingId )=> this.apiCall( `${ApiPaths.appFiles}/${id}`, "appFiles", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} ),
            getUrl: (id)=>`${this.baseUrl}${ApiPaths.appFileUrl}/${id}`,
            getDownloadUrl: (id)=>`${this.baseUrl}${ApiPaths.appFileDownload}/${id}`,
        };

    applicants =
        {
            get: ( page=0, pageSize=10, loadingId, filters )=> this.apiCall(ApiPaths.applicants, "applicants" , {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
        };

    appliedBonuses =
        {
            create: ( type, date, employee, amount, comment, loadingId )=> this.apiCall( ApiPaths.appliedBonuses, "appliedBonuses", {type, date, employee, amount, comment}, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            delete: ( id, loadingId )=> this.apiCall( `${ApiPaths.appliedBonuses}/${id}`, "appliedBonuses", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} ),
        };

    appliedIncidences =
        {
            get: ( page=0, pageSize=100, loadingId, filters, customProperty = 'appliedIncidences' )=> this.apiCall(ApiPaths.appliedIncidences, customProperty, {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            singleEdit: (id, properties, loadingId)=>this.apiCall( `${ApiPaths.appliedIncidences}/${id}`, "singleAppliedIncidences", {id, ...properties}, {loadingId, method:"PUT", stateAtion:STATE_ACTION_SET_SINGLE } ),
            getReport: ( options, loadingId )=> this.apiCall(ApiPaths.appliedIncidences+'/report', "appliedIncidencesReport", options, {method: 'POST', loadingId} ),
        };

    appliedIncidenceReport =
        {
            get: ( options, loadingId, customProp )=> this.apiCall(ApiPaths.appliedIncidenceReport, customProp? customProp:"appliedIncidenceReport", options, {method:"POST",loadingId} ),
        };

    appliedDiscounts =
        {
            create: ( date, employee, amount, comment, loadingId )=> this.apiCall( ApiPaths.appliedDiscounts, "appliedDiscounts", {date, employee, amount, comment}, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            delete: ( id, loadingId )=> this.apiCall( `${ApiPaths.appliedDiscounts}/${id}`, "appliedDiscounts", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} ),
        };

    appointments =
        {
            get: ({page=0, pageSize=50, filters={}, customProp='appointments'}={})=>
                this.apiCall(ApiPaths.appointments, customProp, {...filters, page:page+1, itemsPerPage: pageSize}),
            create: ({appointment, customProp='appointments', loadingId}={})=>
                this.apiCall(ApiPaths.appointments, customProp, appointment, {method: 'POST', stateAction: STATE_ACTION_PUSH, loadingId}),
            edit: ({customProp='appointments', properties={}, id, loadingId})=>
                this.apiCall(`${ApiPaths.appointments}/${id}`, customProp, properties, {stateAction: STATE_ACTION_SEARCH_N_REPLACE, method: 'PUT'}, loadingId),
            delete: ({id, customProp='appointments', loadingId})=>
                this.apiCall(`${ApiPaths.appointments}/${id}`, customProp, {id}, {method: 'DELETE', stateAction: STATE_ACTION_SEARCH_N_DELETE, loadingId}),
        };

    answers =
        {
            get: ( page=0, pageSize=100, filters, loadingId, customProp )=> this.apiCall(ApiPaths.answers, customProp? customProp:"answers", {page: page+1, itemsPerPage:pageSize, ...filters}, {loadingId} ),
            getWithQuestionsFilter: ( page=0, pageSize=100, filters, loadingId, customProp )=> this.apiCall(ApiPaths.answers+'/by_filtered_answers', customProp? customProp:"answers", {page: page+1, itemsPerPage:pageSize, ...filters}, {loadingId} ),
            countWithQuestionsFilter: ( filters, loadingId, customProp )=> this.apiCall(ApiPaths.answers+'/count_responses', customProp? customProp:"countResponses", filters, {loadingId, method: 'PUT'} ),
            editWithQuestionsFilter: ( filters, loadingId, customProp )=> this.apiCall(ApiPaths.answers+'/batch_edit_response', customProp? customProp:"countResponses", filters, {loadingId, method: 'PUT'} ),
            gcareEvolutionReport: ({customProp='gcareEvolutionReport', params, loadingId='gcareEvolutionReport'}={})=>
                this.apiCall(ApiPaths.answers+'/gcare_evolution_report', customProp, params, {loadingId}),
            gcareNpsAverageInRange: ({customProp='gcareNpsAverageInRange', params, loadingId='gcareNpsAverageInRange'}) =>
                this.apiCall(ApiPaths.answers+'/gcare_nps_average_in_range', customProp, params, {loadingId}),
            gcareNpsReport: ({customProp='gcareNpsReport', params, loadingId='gcareNpsReport'})=>
                this.apiCall(ApiPaths.answers+'/gcare_nps_report', customProp, params, {loadingId})
        };

    areas =
        {
            get: ()=> this.apiCall(ApiPaths.areas, "areas", {itemsPerPage:200} ),
            create: ( name )=> this.apiCall( ApiPaths.areas, "areas", {name}, {method:"POST", stateAction:STATE_ACTION_PUSH} ),
            edit: ( id, name )=> this.apiCall( `${ApiPaths.areas}/${id}`, "areas", {id, name}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
            delete: ( id )=> this.apiCall( `${ApiPaths.areas}/${id}`, "areas", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    assistanceReportUploads = {
        get: ( page=0, pageSize=100, loadingId )=> this.apiCall(ApiPaths.assistanceReportUploads, "assistanceReportUploads", {page:page+1,itemsPerPage:pageSize}, {loadingId} ),
        create: ( clock, loadingId, file)=> this.apiCall( ApiPaths.assistanceReportUploads, "assistanceReportUploads", {clock}, {method:"POST", stateAction:STATE_ACTION_PUSH, useFormData:true, loadingId}, [file] ),
        delete: ( id, loadingId )=> this.apiCall( `${ApiPaths.assistanceReportUploads}/${id}`, "assistanceReportUploads", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} ),
        clear: ()=>this.clearProperty("assistanceReportUploads")
    };

    banks =
        {
            get: ()=> this.apiCall(ApiPaths.banks, "banks", {itemsPerPage:200} ),
            create: ( name )=> this.apiCall( ApiPaths.banks, "banks", {name}, {method:"POST", stateAction:STATE_ACTION_PUSH} ),
            edit: ( id, name )=> this.apiCall( `${ApiPaths.banks}/${id}`, "banks", {id, name}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
            delete: ( id )=> this.apiCall( `${ApiPaths.banks}/${id}`, "banks", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    bonuses =
        {
            get: (filters, loadingId) => this.apiCall(ApiPaths.bonuses, "bonuses", filters, {loadingId}),
        };

    brands =
        {
            get: ( {page=0, pageSize=300, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.brands, customProp || "brands", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( {brand, loadingId, customProp} )=> this.apiCall( ApiPaths.brands, customProp || "brands", brand, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
        };

    calculatePayroll = ( year, month, period, loadingId)=> this.apiCall(ApiPaths.calculatePayroll, "calculatePayroll", {year,month,number:period}, {method:"POST",loadingId});

    checkIns = {
        get: ({page=0, pageSize=25, loadingId, customProp='checkIns', filters={}} = {}) => {
            return this.apiCall(ApiPaths.checkIns, customProp, {
                ...filters,
                itemsPerPage: pageSize,
                page: page+1
            }, {loadingId})
        },
        getStats: ({filters={}, customProp='checkInStats'}={}) =>
            this.apiCall(ApiPaths.checkIns+'/stats', customProp, filters),
        exportBillingUrl: ({params})=>API_URL+ApiPaths.checkIns+'/billing_report?'+querySerialize({
            ...params,
            token: this.token
        }),
        exportBreakdownBillingUrl: ({params})=>API_URL+ApiPaths.checkIns+'/breakdown_billing_report?'+querySerialize({
            ...params,
            token: this.token
        }),
        exportUrl: ({filters, reportConfig}) => `${API_URL}${ApiPaths.checkIns}.xlsx?`+querySerialize({...filters, token: this.token, reportConfig: JSON.stringify(reportConfig)})
    };

    clocks =
        {
            get: ()=> this.apiCall(ApiPaths.clocks, "clocks", {itemsPerPage:200} ),
            create: ( name )=> this.apiCall( ApiPaths.clocks, "clocks", {name}, {method:"POST", stateAction:STATE_ACTION_PUSH} ),
            edit: ( id, name )=> this.apiCall( `${ApiPaths.clocks}/${id}`, "clocks", {id, name}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
            delete: ( id )=> this.apiCall( `${ApiPaths.clocks}/${id}`, "clocks", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    createFileUrl = (file, download = false)=>
        {
            //TimeTravel hack
            if( process.env.REACT_APP_BUILD === 'dev' )
                return `${this.baseUrl}${download? ApiPaths.fileDownloadPath:ApiPaths.filePath}/${file.id}${window.localStorage.timeTravel?`?${querySerialize({fakeTime:window.localStorage.timeTravel})}`:""}`;
            else
                return `${this.baseUrl}${download? ApiPaths.fileDownloadPath:ApiPaths.filePath}/${file.id}`;
        };

    comments =
        {
            get: ( options, loadingId, customProp )=> this.apiCall(ApiPaths.comments, customProp? customProp:"comments", options, {loadingId}),
            create: ( comment, loadingId, files)=> this.apiCall( ApiPaths.comments, "comments", comment, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId}, files ),
            edit: ( id, isRemoved )=> this.apiCall( `${ApiPaths.comments}/${id}`, "comments", {id, isRemoved}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
        };

    countries = {
        get: (data = {}) => this.createEntityCall(ApiPaths.countries, {data, config: {loadingId: data?.loadingId}})
    }

    cassetteEmployees =
        {
            get: (cassette, employee, loadingId) => this.apiCall(ApiPaths.cassetteEmployees, "cassetteEmployees", {cassette, employee},{loadingId}),
            edit: (id, notificationsEnabled) =>  this.apiCall( `${ApiPaths.cassetteEmployees}/${id}`, "cassetteEmployees", {id, notificationsEnabled}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
        };

    configurations =
        {
            getSingle: ({id, loadingId}) => this.apiCall(`${ApiPaths.configurations}/${id}`, "configurations", {}, {loadingId, stateAction:STATE_ACTION_SET}),
            batchEdit: ({configurations, loadingId}) => this.apiCall(`${ApiPaths.configurations}/batch_update`, 'configurations', configurations, {method: "PUT", loadingId})
        };

    downloadPayrollDetailUrl = (id)=>
    {
        //TimeTravel hack
        if( process.env.REACT_APP_BUILD === 'dev' )
            return `${API_URL}${ApiPaths.payrollDetailsDownload}/${id}${window.localStorage.timeTravel?`?${querySerialize({fakeTime:window.localStorage.timeTravel})}&`:"?"}`+querySerialize({token: this.token});
        else
            return `${API_URL}${ApiPaths.payrollDetailsDownload}/${id}?`+querySerialize({token: this.token});
    };

    employeeApprovalRules={
        get: ( {page=0, pageSize=10, loadingId, filters, customProp="employeeApprovalRules"})=> this.apiCall(ApiPaths.employeeApprovalRules, customProp, {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
        edit: ({id, employeeApprovalRule, loadingId,  customProp="employeeApprovalRules"})=>this.apiCall(`${ApiPaths.employeeApprovalRules}/${id}`, customProp, employeeApprovalRule, {method: "PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
        create: ( { employeeApprovalRule, loadingId,  customProp="employeeApprovalRules"})=> this.apiCall( ApiPaths.employeeApprovalRules, customProp, employeeApprovalRule, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
        delete: ( {id, loadingId,  customProp="employeeApprovalRules"} )=> this.apiCall( `${ApiPaths.employeeApprovalRules}/${id}`, customProp , {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} )
    };

    employeeProfiles =
        {
            getSingle: ( id,loadingId ) =>this.apiCall(`${ApiPaths.employeeProfiles}/${id}`, "singleEmployeeProfile", {}, {loadingId, stateAction:STATE_ACTION_SET}),
        };

    employees =
        {
            get: ( page=0, pageSize=10, loadingId, filters )=> this.apiCall(ApiPaths.employees, "employees", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            get2: ( {page=0, pageSize=10, loadingId, filters, customProp="employees"} )=> this.apiCall(ApiPaths.employees, customProp, {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            getSimple: ( {page=0, pageSize=10, loadingId, filters, customProp="employees"} )=> this.apiCall(ApiPaths.employeesSimple, customProp, {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            getSingle: ( employeeId,loadingId ) =>this.apiCall(`${ApiPaths.employees}/${employeeId}`, "singleEmployee", {}, {loadingId, stateAction:STATE_ACTION_SET}),
            edit: ( id, employee, loadingId )=> this.apiCall( `${ApiPaths.employees}/${id}`, "singleEmployee", employee, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            editProfile: ( id, employee, loadingId )=> this.apiCall( `${ApiPaths.employees}/${id}/profile`, "singleEmployee", employee, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            fire: (id, loadingId) =>this.apiCall( `${ApiPaths.employeeFire}/${id}`, 'users', {id}, {loadingId, method:'PUT', stateAction:STATE_ACTION_SEARCH_N_DELETE}),
            hire: (id, loadingId) =>this.apiCall( `${ApiPaths.employeeHire}/${id}`, 'users', {id}, {loadingId, method:'PUT', stateAction:STATE_ACTION_SEARCH_N_DELETE}),

            generalReport: () => this.apiCall(ApiPaths.employees + '/general_report', "employeesGeneralReport"),
            ageReport: () => this.apiCall(ApiPaths.employees + '/age_report', "employeesAgeReport"),
            genderReport: () => this.apiCall(ApiPaths.employees + '/gender_report', "employeesGenderReport"),
            seniorityReport: (intervals) => this.apiCall(ApiPaths.employees + '/seniority_report', "employeesSeniorityReport", {intervals}),
            generationsReport: (onlyHired) => this.apiCall(ApiPaths.employees + '/generations_report', "employeesGenerationsReport", {onlyHired}),
            simple: ({page = 1, pageSize = 18, filters, loadingId}) => this.apiCall(ApiPaths.employeesSimple, "employees", {
                page,
                itemsPerPage: pageSize,
                ...filters
            }, {
                loadingId,
                stateAction: STATE_ACTION_SET
            }),
            simpleNextPage: ({page = 1, pageSize = 18, filters, loadingId}) => this.apiCall(ApiPaths.employeesSimple, "employees", {
                page,
                itemsPerPage: pageSize,
                ...filters
            }, {
                loadingId,
                stateAction: STATE_ACTION_APPEND
            }),
            editFollowedEmployees: ( id, employee, loadingId )=> this.apiCall( `${ApiPaths.employeesFollowedEmployees}/${id}`, "singleEmployee", employee, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            editWishedRewards: ( id, employee, loadingId )=> this.apiCall( `${ApiPaths.employeesEditWishedRewards}/${id}`, "singleEmployee", employee, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),


            followedEmployees: ({id})=>this.apiCall(ApiPaths.followedEmployees(id), 'followedEmployees'),

            wishedRewards: () => this.apiCall(ApiPaths.employeesWishedRewards, 'rewardWishes'),

            guestsAttendedReport: ({params, loadingId, customProp}={})=> this.apiCall(ApiPaths.employees+ '/guests_attended_report', customProp||'guestsAttendedReport', params, {loadingId}),
            guestsAttendedReportURL: ({params})=>API_URL+ApiPaths.employees+'/guests_attended_report?'+querySerialize({...params, token: this.token})

        };

    employeeWorkTools =
        {
            get: ( page=0, pageSize=200, loadingId, filters )=> this.apiCall(ApiPaths.employeeWorkTools, "employeeWorkTools", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( workTool, employee, comments, loadingId )=> this.apiCall( ApiPaths.employeeWorkTools, "employeeWorkTools", {workTool, employee, comments}, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( id, workTool, comments, returnDate, loadingId )=> this.apiCall( `${ApiPaths.employeeWorkTools}/${id}`, "employeeWorkTools", {id, workTool, comments, returnDate}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( id, loadingId )=> this.apiCall( `${ApiPaths.employeeWorkTools}/${id}`, "employeeWorkTools", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} ),
            getMonthlyReport: ({loadingId, options})=> this.apiCall(ApiPaths.employeeWorkTools+'/report_by_moth', "employeeWorkToolsMonthlyReport", options, {method: 'POST', loadingId} ),
            batchAssignation:( { params, loadingId, customProp} )=> this.apiCall( `${ApiPaths.employeeWorkTools}/batch_assignation`, customProp||"batchAssignation", params, {method:"POST", stateAction:STATE_ACTION_SET, loadingId} ),
        };

    employeeXFiles =
        {
            create: (params, files) => this.apiCall(ApiPaths.employeeXFiles, 'employeeXFiles', params, {method: 'POST'}, files),
            edit: (params, files, {loadingId}) => this.apiCall(`${ApiPaths.employeeXFiles}/${params.id}`, 'employeeXFiles', params, {method: 'PUT', loadingId}, files)
        };

    filledQuestionnaires =
        {
            get: ( page=0, pageSize=10, loadingId, filters, customProp )=> this.apiCall(ApiPaths.filledQuestionnaires, customProp||"filledQuestionnaires", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( {questionnaire, answers, visit, loadingId} )=> this.apiCall( ApiPaths.filledQuestionnaires, "filledQuestionnaires", {questionnaire, answers, visit}, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId, queueable:true} ),
            edit:  ( id, filledQuestionnaire, loadingId )=> this.apiCall( `${ApiPaths.filledQuestionnaires}/${id}`, "filledQuestionnaires", filledQuestionnaire, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
        };

    flights =
        {
            get: (data = {}) => {
                const {params = {}, customProp = "flights", loadingId, filters={}} = data;
                const config = {loadingId};

                return this.apiCall(
                    ApiPaths.flights,
                    customProp,
                    {...params, ...filters},
                    config
                );
            },
            destinationTypesReport: ({params, customProp, loadingId}={})=>this.apiCall(ApiPaths.flights+'/destination_types_report', customProp || "destinationTypesReport", params, {loadingId} ),
            create: (data = {}) => {
                const config = {method: "POST"};
                return this.createEntityCall(ApiPaths.flights, {data, config});
            },
            update: (data = {}) => {
                const config = {method: "PUT"};
                return this.createEntityCall(ApiPaths.flights, {data, config});
            },
            getInEntitySelector: (data = {}) => {
                const {customProp, loadingId, filters, pageSize} = data;
                const params = {...filters, pageSize};
                const config = {loadingId};

                return this.apiCall(
                    ApiPaths.flights,
                    customProp || 'flightsInEntitySelector',
                    params || {},
                    config || {}
                );
            },
            exportGoalsReportUrl: ( filters )=>
            {
                const reportConfig = {
                    name: 'Reporte de meta de vuelos.xlsx',
                    headers: {
                        'number': 'Número de vuelo',
                        'airline.name': 'Aerolínea',
                        'monthlyGoal': 'Meta mensual',
                        'monthFlights': `Vuelos del ${filters.countFlightsFrom} a ${filters.countFlightsTo}`,
                        'goalPercentage': `Porcentaje de meta`,
                    }
                };


                const query = querySerialize({...filters,  token: window.localStorage.googlead, reportConfig: JSON.stringify(reportConfig)});
                return `${API_URL}${ApiPaths.flights}.xlsx?${query}`;
            },
            visitDestinationsReport: ({params, customProp, loadingId}={})=>this.apiCall(ApiPaths.flights+'/visit_destinations_report', customProp || "visitDestinationsReport", params, {loadingId} ),
        };

    destinationTypes = {
        get: (data = {}) =>
            this.createEntityCall(ApiPaths.destinationTypes, {data, config: {loadingId: data?.loadingId}}),
        create: (data = {}) => {
            const config = {method: 'POST'};
            return this.createEntityCall(ApiPaths.destinationTypes, {data, config});
        },
        update: (data = {}) => {
            const config = {method: "PUT"};
            return this.createEntityCall(ApiPaths.destinationTypes, {data, config});
        },
        delete: (data = {}) => {
            const config = {method: "DELETE"};
            return this.createEntityCall(ApiPaths.destinationTypes, {data, config});
        }
    }

    groups =
        {
            get: ()=> this.apiCall(ApiPaths.groups, "groups", {itemsPerPage:200} ),
            create: ( group, loadingId )=> this.apiCall( ApiPaths.groups, "groups", group, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( id, group, loadingId )=> this.apiCall( `${ApiPaths.groups}/${id}`, "groups", group, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( id, loadingId )=> this.apiCall( `${ApiPaths.groups}/${id}`, "groups", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} )
        };

    gcare =
        {
            get: ({token, loadingId})=> this.apiCall(ApiPaths.gcare+'/'+token, "gcare", undefined, {loadingId} ),
            create: ( {filledQuestionnaire, customProp, loadingId} )=> this.apiCall( ApiPaths.gcare, customProp||"gcare", filledQuestionnaire, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId, preventNotifier:true} ),
            downloadGCareReport: ({params}) => {
                const query = querySerialize(params);
                return API_URL + ApiPaths.gcareReport + '?' + query;
            }
        };

    guests = {
        vipNonGrataReport: ({loadingId, customProp}={})=>this.apiCall(ApiPaths.guestsVipNonGrataReport, customProp||'vipNonGrataReport', {}, {loadingId}),
        generationReport: ({loadingId, customProp, params={}}={})=>this.apiCall(ApiPaths.guestsGenerationReport, customProp||'generationReport', params, {loadingId})
    };

    happinessLogs =
        {
            get: ( page=0, pageSize=200, loadingId, filters )=> this.apiCall(ApiPaths.happinessLogs, "happinessLogs", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
        };

    happinessLogsReport =
        {
            get: ( fromDate, intervalSizeInDays, numberOfBlocks, loadingId, customProp = "happinessLogsReport", filters = {} )=>this.apiCall( ApiPaths.happinessLogsReport, customProp, {fromDate, intervalSizeInDays, numberOfBlocks, ...filters}, {loadingId, method:'POST'} ),
            excelUrl: ()=>`${API_URL}/api/happiness_logs/excel_export`
        };

    incidenceGenerations =
        {
            get: ( page=0, pageSize=100, loadingId, filters )=> this.apiCall(ApiPaths.incidenceGenerations, "incidenceGenerations", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: (toDate, loadingId)=>this.apiCall( ApiPaths.incidenceGenerations, "incidenceGenerations", {toDate}, {method:"POST", stateAction:STATE_ACTION_PUSH,loadingId} ),
            delete: ( id )=> this.apiCall( `${ApiPaths.incidenceGenerations}/${id}`, "incidenceGenerations", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    incidenceJustifications =
        {
            get: ( page=0, pageSize=100, loadingId, filters, customProperty='incidenceJustifications' )=> this.apiCall(ApiPaths.incidenceJustifications, customProperty, {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            edit: ( id,responseComment, approved, loadingId )=> this.apiCall( `${ApiPaths.incidenceJustifications}/${id}`, "incidenceJustifications", {responseComment, approved, reviewDate:moment().format("YYYY-MM-DD")}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            create: (date, endDate, newIncidence, appliedIncidences, requestComment, files, loadingId)=>this.apiCall( ApiPaths.incidenceJustifications, "incidenceJustifications", {date, endDate, newIncidence, appliedIncidences, requestComment}, {method:"POST",useFormData:true, stateAction:STATE_ACTION_PUSH,loadingId}, files),
            delete: ( id, loadingId )=> this.apiCall( `${ApiPaths.incidenceJustifications}/${id}`, "incidenceJustifications", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} ),
            clear: ()=>this.clearProperty("incidenceJustifications"),
            exportUrl: ( filters )=>
            {
                const reportConfig = {
                    name: 'Justificaciones.xlsx',
                    headers: {
                        'requestedBy.name': 'Nombre',
                        'requestedBy.patLastName': 'Apellido paterno',
                        'requestedBy.matLastName': 'Apellido materno',
                        'requestedBy.group.name': 'Grupo',
                        'newIncidence.name': 'Tipo de justificación',
                        'createdDate': 'Fecha de creación de jsutificación',
                        'date': 'Fecha de inicio de justificación',
                        'endDate': 'Fecha de fin de justificación',
                        'approved': 'Aprobada',
                        'reviewedBy.name': 'Revisada por (Nombre)',
                        'reviewedBy.patLastName': 'Revisada por (Apellido paterno)',
                        'reviewedBy.matLastName': 'Revisada por (Apellido materno)',
                        'responseComment': 'Comentarios'
                    }
                };

                const query = querySerialize({...filters,  token: window.localStorage.googlead, reportConfig: JSON.stringify(reportConfig)});
                return `${API_URL}${ApiPaths.incidenceJustifications}.xlsx?${query}`;
            }
        };

    incidences =
        {
            get: (loadingId, pageSize = 200) => this.apiCall(ApiPaths.incidences, "incidences", {itemsPerPage: pageSize}, {loadingId}),
        };

    inventories =
        {
            get: ( {page=0, pageSize=100, loadingId, filters, customProp})=>this.apiCall(ApiPaths.inventories, customProp?customProp:'inventories', {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} )
        };

    journal =
        {
            get: (id, page=0, pageSize=10, loadingId, filters)=>this.apiCall( ApiPaths.journals, "journal", {employee:id, page:page+1, itemsPerPage:pageSize, "order[shiftDate]":"DESC","order[date]":"ASC", ...filters}, {loadingId}  ),
            exportUrl: ( year, month, filters )=>
                {
                    const query = querySerialize({year, month: month+1, ...filters, token: window.localStorage.googlead});
                    //TimeTravel hack
                    if( process.env.REACT_APP_BUILD === 'dev' )
                        return `${API_URL}${ApiPaths.journals}/export?${ query }${ window.localStorage.timeTravel? `&${querySerialize({fakeTime:window.localStorage.timeTravel})}` : "" }`;
                    else
                        return `${API_URL}${ApiPaths.journals}/export?${query}`;
                }
        };

    layoffs =
        {
            get: ( filters = {}, loadingId )=> this.apiCall(ApiPaths.layoffs, "layoffs", { ...filters }, {loadingId} ),
            edit: ( id, layoff, loadingId )=> this.apiCall( `${ApiPaths.layoffs}/${id}`, "layoffs", layoff, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            getReport: ( options, loadingId )=> this.apiCall(ApiPaths.layoffs+'/report', "layoffsReport", options, {method: 'POST', loadingId} ),
        };

    layoffRequests =
        {
            get: ( page=0, pageSize=10, loadingId, filters, customProp )=> this.apiCall(ApiPaths.layoffRequests, customProp || "layoffRequests", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( layoffRequest, loadingId, customProp )=> this.apiCall( ApiPaths.layoffRequests, customProp || "layoffRequests", layoffRequest, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( id, layoffRequest, customProp )=> this.apiCall( `${ApiPaths.layoffRequests}/${id}`, customProp || "layoffRequests", {id, ...layoffRequest}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
            delete: ( id, customProp )=> this.apiCall( `${ApiPaths.layoffRequests}/${id}`, customProp || "layoffRequests", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    logIn = (username, password)=>
    {
        let credentials = new FormData();
        credentials.append("_username", username );
        credentials.append("_password", password);

        let client;
        try{
            client = (new DeviceDetector()).parse( window.navigator.userAgent );
        }
        catch(e){
            client = { browserReadError: e.message }
        }

        client.appVersion = version;
        client.userAgent= window.navigator.userAgent;

        try{
            client.screen = {width: window.screen.width, height: window.screen.height, pixelRatio:window.devicePixelRatio };
            client.window = {width: window.innerWidth, height: window.innerHeight };
        }
        catch(e){
            client.screen = "Couldn't get screen size - "+e.message;
        }

        credentials.append("client_data", JSON.stringify(client) );

        let path = API_URL + ApiPaths.logIn;

        //TimeTravel hack
        if( process.env.REACT_APP_BUILD === 'dev' ) {
            if (window.localStorage.timeTravel)
                path += "?" + querySerialize({fakeTime: window.localStorage.timeTravel});
        }

        this.store.dispatch({ type:APP_LOADING_START });
        fetch(
            path,
            {
                method: "POST",
                body: credentials,
                credentials: 'include'
            })
            .then(( response)=>{

                if( response.status === 200 ){

                    if( this.isSafari ){

                        response.json().then((json)=>{

                            this.token = json.token;
                            if( window.localStorage ) window.localStorage.googlead = this.token;
                            this.store.dispatch({
                                type: ACTION_PREFIX + ACTION_LOG,
                                payload: LOGIN_STATE.LOGGED_IN
                            });
                            if(this.onLogIn)
                                this.onLogIn(this.token);
                            this.store.dispatch({type: APP_LOADING_END});
                        })

                    }
                    else {
                        this.store.dispatch({
                            type: ACTION_PREFIX + ACTION_LOG,
                            payload: LOGIN_STATE.LOGGED_IN
                        });
                        this.store.dispatch({type: APP_LOADING_END});
                    }
                }
                else if( response.status === 401 ){
                    this.store.dispatch({
                        type:ACTION_PREFIX+ACTION_LOG,
                        payload: LOGIN_STATE.BAD_CREDENTIALS
                    });
                    this.store.dispatch({ type:APP_LOADING_END });
                }
                else {
                    loginError();
                }

                return response;
            }, loginError);

        const that= this;
        function loginError(){
            that.store.dispatch({
                type:ACTION_PREFIX+ACTION_LOG,
                payload: LOGIN_STATE.LOGIN_ERROR
            });
            that.store.dispatch({ type:APP_LOADING_END });
        }
    };

    loginRecords =
    {
        get: ( {page=0, pageSize=10, loadingId, filters}) => this.apiCall(ApiPaths.loginRecords,  "loginRecords", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId})
    };

    logOut = ()=>
    {

        const done = ()=> {
            this.store.dispatch({
                type: ACTION_PREFIX + ACTION_LOG,
                payload: LOGIN_STATE.NOT_LOGGED
            });
            this.store.dispatch({
                type: CLEAR_STATE
            })
        };
        fetch( API_URL + ApiPaths.logOut,{credentials: 'include'}).then(done,done);
    };

    loungeAccessMethods =  {
        get: ( {page=0, pageSize=10, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.loungeAccessMethods, customProp || "loungeAccessMethods", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
        create: ( {loungeAccessMethod, loadingId, customProp} )=> this.apiCall( ApiPaths.loungeAccessMethods, customProp || "loungeAccessMethods", loungeAccessMethod, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
        edit: ( {id, loungeAccessMethod, customProp, loadingId} )=> this.apiCall( `${ApiPaths.loungeAccessMethods}/${id}`, customProp || "loungeAccessMethods", loungeAccessMethod, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
        delete: ( {id, customProp, loadingId} )=> this.apiCall( `${ApiPaths.loungeAccessMethods}/${id}`, customProp || "loungeAccessMethods", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} )
    };

    loungeSubAccessMethods =  {
        get: ( {page=0, pageSize=10, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.loungeSubAccessMethods, customProp || "loungeSubAccessMethods", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
        create: ( {loungeSubAccessMethod, loadingId, customProp} )=> this.apiCall( ApiPaths.loungeSubAccessMethods, customProp || "loungeSubAccessMethods", loungeSubAccessMethod, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
        edit: ( {id, loungeSubAccessMethod, customProp, loadingId} )=> this.apiCall( `${ApiPaths.loungeSubAccessMethods}/${id}`, customProp || "loungeSubAccessMethods", loungeSubAccessMethod, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
        delete: ( {id, customProp, loadingId} )=> this.apiCall( `${ApiPaths.loungeSubAccessMethods}/${id}`, customProp || "loungeSubAccessMethods", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} )
    };

    lounges =
        {
            get: ( loadingId, filters, property )=> this.apiCall(ApiPaths.lounges, property?property:"lounges", {itemsPerPage:200, ...filters}, {loadingId, cacheable:true} ),
            get2: ({customProp='lounges', filters={}}={})=>
                this.apiCall(ApiPaths.lounges, customProp, {itemsPerPage:200, ...filters}),
            create: ( name, isWarehouse, property )=> this.apiCall( ApiPaths.lounges, property?property:"lounges", {name, isWarehouse}, {method:"POST", stateAction:STATE_ACTION_PUSH} ),
            edit: ( id, name, property )=> this.apiCall( `${ApiPaths.lounges}/${id}`, property?property:"lounges", {id, name}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
            delete: ( id, property )=> this.apiCall( `${ApiPaths.lounges}/${id}`, property?property:"lounges", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} ),
            reservationVisitsReport: ({params, customProp, loadingId}={})=>this.apiCall(ApiPaths.lounges+'/reservation_visits_report', customProp || "loungesReservationVisitsReport", params, {loadingId} ),
            vipNonGrataReport: ({params, loadingId})=>this.apiCall(ApiPaths.lounges+'/vip_non_grata_report', 'loungesVipNonGrataReport', params, {loadingId}),
            realDailyCostSumReport: ({params, loadingId})=>this.apiCall(ApiPaths.lounges+'/real_daily_cost_sum_report', 'loungesRealDailyCostSumReport', params, {loadingId}),
            shiftOccupancyReport: ({params, loadingId})=>this.apiCall(ApiPaths.lounges+'/shift_occupancy_report', 'shiftOccupancyReport', params, {loadingId}),
            accessMethodReport: ({params, loadingId})=>this.apiCall(ApiPaths.lounges+'/access_method_report', 'loungesAccessMethodReport', params, {loadingId}),
        };
    me =
    {
        get: ()=> this.apiCall(ApiPaths.me, "me", undefined, { cacheable:true} ).then( ( me )=>{
            if( me && me.debugWebClient )
                this.turnOnWebDebugger();
            return me;
        } ),
        edit: ({userNotificationTopics, loadingId})=>this.apiCall(ApiPaths.me, 'me',{userNotificationTopics}, {method:"PUT", loadingId}),
        haveExtraMealTime: ()=> this.apiCall(ApiPaths.haveExtraMealTime, "haveExtraMealTime"),
    };

    measurementConversionTemplates =  {
        get: ( page=0, pageSize=10, loadingId, filters )=> this.apiCall(ApiPaths.measurementConversionTemplates, "measurementConversionTemplates", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
        create: ( measurementUnit, loadingId )=> this.apiCall( ApiPaths.measurementConversionTemplates, "measurementConversionTemplates", measurementUnit, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
        edit: ( id, measurementUnit, loadingId)=> this.apiCall( `${ApiPaths.measurementConversionTemplates}/${id}`, "measurementConversionTemplates", measurementUnit, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
        delete: ( id )=> this.apiCall( `${ApiPaths.measurementConversionTemplates}/${id}`, "measurementConversionTemplates", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
    };

    measurementUnits =  {
        get: ( {page=0, pageSize=10, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.measurementUnits, customProp || "measurementUnits", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
        create: ( {measurementUnit, loadingId, customProp} )=> this.apiCall( ApiPaths.measurementUnits, customProp || "measurementUnits", measurementUnit, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
        edit: ( {id, measurementUnit, customProp, loadingId} )=> this.apiCall( `${ApiPaths.measurementUnits}/${id}`, customProp || "measurementUnits", measurementUnit, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
        delete: ( {id, customProp, loadingId} )=> this.apiCall( `${ApiPaths.measurementUnits}/${id}`, customProp || "measurementUnits", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} )
    };

    notifications =
        {
            get: ( {page=0, pageSize=10, filters, loadingId, customProp='notifications', append=false} )=>this.apiCall(ApiPaths.notifications, customProp, {page:page+1,itemsPerPage:pageSize, ...filters} , {loadingId, preventNotifier:true, stateAction:append?STATE_ACTION_APPEND:STATE_ACTION_SET} ),
            getHeaderNotifications: ( {page=0, pageSize=10, filters, loadingId, customProp='notifications', append=false} )=>this.apiCall( ApiPaths.notificationsHeader, customProp, {page:page+1,itemsPerPage:pageSize, ...filters} , {loadingId, preventNotifier:true, stateAction:append?STATE_ACTION_APPEND:STATE_ACTION_SET} ),
            markAsRead: ( {id, customProp='notifications'} )=> this.apiCall( `${ApiPaths.notifications}/${id}/mark_as_read`, customProp, {id}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
            markAllAsRead: ()=> this.apiCall( `${ApiPaths.notifications}/mark_all_as_read`, "notifications_ignore", {}, {method:"PUT"} ),
        };

    notificationTopics =
        {
           getUserCanReceive: ({loadingId})=> this.apiCall(`${ApiPaths.notificationTopics}/user_can_receive`, 'notificationTopics', {}, {loadingId})
        };

    orozcoCashRegisters = {
        get: ({page=0, pageSize=50, filters={}, customProp='orozcoCashRegisters'}={})=>
            this.apiCall(ApiPaths.orozcoCashRegisters, customProp, {...filters, page:page+1, itemsPerPage: pageSize}),
        getCurrentCash: (cashRegister)=>
            this.apiCall(ApiPaths.orozcoCashRegistersCurrentCash(cashRegister), 'orozcoCashRegisterCash'),
        getCashToPickUp: cashRegister =>
            this.apiCall(ApiPaths.orozcoCashRegistersCashToPickUp(cashRegister), 'orozcoCashRegisterToPickUp'),
        create: ({customProp='orozcoCashRegisters', orozcoCashRegister={}}={})=>
            this.apiCall(ApiPaths.orozcoCashRegisters, customProp, orozcoCashRegister, {method: 'POST', stateAction: STATE_ACTION_PUSH}),
        edit: ({customProp='orozcoCashRegisters', orozcoCashRegister={}, id})=>
            this.apiCall(`${ApiPaths.orozcoCashRegisters}/${id}`, customProp, orozcoCashRegister, {stateAction: STATE_ACTION_SEARCH_N_REPLACE, method: 'PUT'}),
        delete: ({id, customProp='orozcoCashRegisters'})=>this.apiCall(`${ApiPaths.orozcoCashRegisters}/${id}`, customProp, {id}, {method: 'DELETE', stateAction: STATE_ACTION_SEARCH_N_DELETE})
    };

    orozcoCashClosings = {
        get: ({page=0, pageSize=50, filters={}, customProp='orozcoCashClosings', loadingId} = {})=>
            this.apiCall(ApiPaths.orozcoCashClosings, customProp, {...filters, page: page+1, itemsPerPage: pageSize}, {loadingId}),
        create: ({customProp='orozcoCashClosings', orozcoCashClosing={}}={})=>
            this.apiCall(ApiPaths.orozcoCashClosings, customProp, orozcoCashClosing, {method: 'POST', stateAction: STATE_ACTION_PUSH})
    };

    orozcoCashPickUps = {
        get: ({page=0, pageSize=50, filters={}, customProp='orozcoCashPickUps', loadingId} = {})=>
            this.apiCall(ApiPaths.orozcoCashPickUps, customProp, {...filters, page: page+1, itemsPerPage: pageSize}, {loadingId}),
        create: ({loadingId, customProp='orozcoCashPickUps', params={}}={})=>
            this.apiCall(ApiPaths.orozcoCashPickUps, customProp, params, {method: 'POST', stateAction: STATE_ACTION_PUSH, loadingId}),
        exportUrl: ({params, reportConfig}) => `${API_URL}${ApiPaths.orozcoCashPickUps}.xlsx?`+querySerialize({...params, token: this.token, reportConfig: JSON.stringify(reportConfig)})
    };

    orozcoProducts = {
        get: ({page=0, pageSize=50, filters={}, customProp='orozcoProducts'}={})=>
            this.apiCall(ApiPaths.orozcoProducts, customProp, {...filters, page:page+1, itemsPerPage: pageSize}),
        create: ({files, customProp='orozcoProducts', properties={}}={})=>
            this.apiCall(ApiPaths.orozcoProducts, customProp, properties, {method: 'POST', stateAction: STATE_ACTION_PUSH}, files),
        edit: ({customProp='orozcoProducts', properties={}, id, files})=>
            this.apiCall(`${ApiPaths.orozcoProducts}/${id}`, customProp, properties, {stateAction: STATE_ACTION_SEARCH_N_REPLACE, method: 'PUT'}, files),
        delete: ({id, customProp='orozcoProducts'})=>this.apiCall(`${ApiPaths.orozcoProducts}/${id}`, customProp, {id}, {method: 'DELETE', stateAction: STATE_ACTION_SEARCH_N_DELETE}),
        rangeCountReport: ({params, customProp, loadingId}={})=>this.apiCall(ApiPaths.orozcoProducts+'/range_count_report', customProp || "orozcoProductRangeCountReport", params, {loadingId} ),
        averageRealCostReport: ({loadingId})=>this.apiCall(ApiPaths.orozcoProducts+'/average_real_cost_report', 'averageRealCostReport', {}, {loadingId}),
        exportOrdersReport: ({params})=>API_URL+ApiPaths.orozcoProducts+'/ordered_products_report?'+querySerialize({
            ...params,
            token: this.token
        }),
    };

    orozcoProductCategories = {
        get: ({page=0, pageSize=200, filters={}, customProp='orozcoProductCategories'}={})=>
            this.apiCall(ApiPaths.orozcoProductCategories, customProp, {...filters, page: page+1, itemsPerPage: pageSize}),
        create: ({customProp='orozcoProductCategories', properties={}}={})=>
            this.apiCall(ApiPaths.orozcoProductCategories, customProp, properties, {method: 'POST', stateAction: STATE_ACTION_PUSH}),
        edit: ({customProp='orozcoProductCategories', properties={}, id})=>
            this.apiCall(`${ApiPaths.orozcoProductCategories}/${id}`, customProp, properties, {stateAction: STATE_ACTION_SEARCH_N_REPLACE, method: 'PUT'}),
        delete: ({id, customProp='orozcoProductCategories'})=>this.apiCall(`${ApiPaths.orozcoProductCategories}/${id}`, customProp, {id}, {method: 'DELETE', stateAction: STATE_ACTION_SEARCH_N_DELETE})
    };
    orozcoPayments= {
        get: ({page=0, pageSize=200, filters={}, customProp='orozcoPayments'}={})=>
            this.apiCall(ApiPaths.orozcoPayments, customProp, {...filters, page: page+1, itemsPerPage: pageSize}),
        exportUrl: ( filters )=>
        {
            const reportConfig = {
                name: 'OrozcoPayments.xlsx',
                headers: {
                    'formattedType': 'Concepto',
                    'guestName': 'Huésped',
                    'orozcoPrint.folioNumber': 'Folio',
                    'createdBy.employee.fullName': 'Empleado',
                    'dateOnly': 'Fecha',
                    'timeOnly': 'Hora',
                    'orozcoCashRegister.lounge.name': 'Sala',
                    'orozcoCashRegister.name': 'Caja',
                    'voucherNumber': 'Número de Operación',
                    'creditCardNumber': 'Número de tarjeta',
                    'formattedAmount': 'Monto',
                    'paymentCurrency': 'Moneda',
                    'orozcoPaymentMethod.name': 'Método de Pago',
                    'formattedProducts': 'Productos',
                    'visitComments': 'Comentarios'
                }
            };

            const query = querySerialize({...filters, token: window.localStorage.googlead, reportConfig: JSON.stringify(reportConfig)});
            return `${API_URL}${ApiPaths.orozcoPayments}.xlsx?${query}`;
        }
    };

    orozcoPrints= {
        get: ({page=0, pageSize=200, filters={}, customProp='orozcoPrints'}={})=>
            this.apiCall(ApiPaths.orozcoPrints, customProp, {...filters, page: page+1, itemsPerPage: pageSize}),
    };


    orozcoRecipes = {
        get: ({page=0, pageSize=50, filters={}, customProp='orozcoRecipes'}={})=>
            this.apiCall(ApiPaths.orozcoRecipes, customProp, {...filters, page:page+1, itemsPerPage: pageSize}),
        exportUrl: ( filters )=>
        {
            const reportConfig = {
                name: 'Reporte de recetas.xlsx',
                headers: {
                    'name': 'Nombre',
                    'type': 'Tipo',
                    'estimatedCost': 'Costo'
                }
            };

            const query = querySerialize({...filters, token: window.localStorage.googlead , reportConfig: JSON.stringify(reportConfig)});
            return `${API_URL}${ApiPaths.orozcoRecipes}.xlsx?${query}`;
        }
    };

    orozcoVisitOrderProducts = {
        popularityURL: ({params})=>API_URL+ApiPaths.orozcoVisitOrderProducts+'/popularity_report?'+querySerialize({
            ...params,
            token: this.token
        })
    };

    payrollDetails =
        {
            get: ( page=0, pageSize=10, loadingId, filters )=> this.apiCall(ApiPaths.payrollDetails, "payrollDetails", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            getEmployee: ( employeeId, activePeriod, loadingId)=>this.apiCall(ApiPaths.payrollDetailsEmployee(employeeId), "employeePayrollDetails", {activePeriod}, {loadingId} )
        };

    payrollDetailsRealTime =
        {
            get: (loadingId) => this.apiCall(ApiPaths.payrollDetailsRealTime, "payrollDetails", {}, {loadingId}),
            refresh: ( employeeId,loadingId ) => { if(!employeeId) return; return this.apiCall(`${ApiPaths.payrollDetailsRealTime}/${employeeId}`, "payrollDetails", {}, {loadingId, stateAction:STATE_ACTION_SEARCH_N_REPLACE}) },
            getSingle: ( employeeId,loadingId ) => { if(!employeeId) return; return this.apiCall(`${ApiPaths.payrollDetailsRealTime}/${employeeId}`, "singlePayrollDetail", {}, {loadingId, stateAction:STATE_ACTION_SET}) },

        };

    payrollDetailsReport =
        {
            get: ( options, loadingId, customProp='payrollDetailsReport' )=> this.apiCall(ApiPaths.payrollDetailsReport, customProp, options, {method:"POST",loadingId} ),
        };

    payrolls =
        {
            get: ( page=0, pageSize=10, loadingId, filters )=> this.apiCall(ApiPaths.payrolls, "payrolls", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            delete: ( id, loadingId )=> this.apiCall( `${ApiPaths.payrolls}/${id}`, "payrolls", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} )
        };

    permissionGroups = {
        get: ( {page=0, pageSize=10, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.permissionGroups, customProp || "permissionGroups", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
    };

    personnelRequisitions =
        {
            get: ( page=0, pageSize=10, loadingId, filters, customProp )=> this.apiCall(ApiPaths.personnelRequisitions, customProp || "personnelRequisitions", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( requisition, loadingId, customProp )=> this.apiCall( ApiPaths.personnelRequisitions, customProp || "personnelRequisitions", requisition, {method:"POST", stateAction:STATE_ACTION_PUSH}, {loadingId} ),
            edit: ( id, isApproved, rejectionComment, customProp )=> this.apiCall( `${ApiPaths.personnelRequisitions}/${id}`, customProp || "personnelRequisitions", {id, isApproved, rejectionComment}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
            delete: ( id, customProp )=> this.apiCall( `${ApiPaths.personnelRequisitions}/${id}`, customProp || "personnelRequisitions", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    places =
        {
            get: ( filters = {}, loadingId )=> this.apiCall(ApiPaths.places, "places", {itemsPerPage:200, ...filters}, {loadingId} ),
        };

    purchaseApprovalRules={
        get: ( {page=0, pageSize=10, loadingId, filters, customProp="purchaseApprovalRules"} )=> this.apiCall(ApiPaths.purchaseApprovalRules, customProp, {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
        edit: ({id, purchaseApprovalRule, loadingId,  customProp="purchaseApprovalRules"})=>this.apiCall(`${ApiPaths.purchaseApprovalRules}/${id}`, customProp, purchaseApprovalRule, {method: "PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
        create: ( { purchaseApprovalRule, loadingId,  customProp="purchaseApprovalRules"})=> this.apiCall( ApiPaths.purchaseApprovalRules, customProp, purchaseApprovalRule, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
        delete: ( {id, loadingId,  customProp="purchaseApprovalRules"} )=> this.apiCall( `${ApiPaths.purchaseApprovalRules}/${id}`, customProp , {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} )
    };

    purchaseRequisitionApprovals =
        {
            edit: ( id, requisitionApproval, loadingId )=> this.apiCall( `${ApiPaths.purchaseRequisitionApprovals}/${id}`, "purchaseRequisitionApprovals", requisitionApproval, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
        };

    purchaseRequisitions =
        {
            get: ( page=0, pageSize=10, loadingId, filters, customProp )=> this.apiCall(ApiPaths.purchaseRequisitions, customProp || "purchaseRequisitions", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( requisition, loadingId, customProp, files )=> this.apiCall( ApiPaths.purchaseRequisitions, customProp || "purchaseRequisitions", requisition, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId}, files ),
            edit: ( id, requisition, customProp, loadingId )=> this.apiCall( `${ApiPaths.purchaseRequisitions}/${id}`, customProp || "purchaseRequisitions", requisition, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( id, customProp )=> this.apiCall( `${ApiPaths.purchaseRequisitions}/${id}`, customProp || "purchaseRequisitions", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} ),
            cancel: ( {id, customProp, loadingId} )=> this.apiCall( `${ApiPaths.purchaseRequisitions}/${id}/cancel`, customProp || "purchaseRequisitions", {id}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            unquote: ( {id, customProp, loadingId, filters={}} )=> this.apiCall( `${ApiPaths.purchaseRequisitions}/${id}/unquote`, customProp || "purchaseRequisitions", {id, ...filters}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            getStats: ({filters, customProp, loadingId})=> this.apiCall( '/api/requisitions_by_dates', customProp||'purchaseRequisitionsStats', filters, {method:"GET", stateAction:STATE_ACTION_SET, loadingId} ),
            getReport: ( {options, loadingId} )=> this.apiCall(`${ApiPaths.purchaseRequisitions}/report`, "purchaseRequisitionsReport", options, {method: 'POST', loadingId} ),
            exportUrl: ( filters )=>
            {
                const reportConfig = {
                    name: 'Solicitudes de compra.xlsx',
                    headers: {
                        'folio': 'Número',
                        'providersRfcAsString': 'RFC',
                        'requestedBy.fullName': 'Solicitante',
                        'requestedBy.area.name': 'Área',
                        'requestedDate': 'Fecha de solicitud',
                        'status': 'Estado',
                        'formattedProducts': 'Productos',
                        'isUrgent': 'Es urgente',
                        'currency': 'Moneda',
                        'subtotalWithDiscount': 'Subtotal',
                        'taxesTotal': 'Impuestos',
                        'total': 'Total'
                    },
                    transStrings: {
                        'requested': 'Solicitada',
                        'quoted': 'Cotizada',
                        'approved': 'Aprobada',
                        'denied': 'Rechazada',
                        'canceled': 'Cancelada'
                    }
                };
                filters.sGroups = [
                    'purchase_requisition_read_id',
                    'purchase_requisition_read_requested_by',
                    'employee_read_full_name',
                    'employee_read_area',
                    'area_read_name',
                    'purchase_requisition_read_requested_date',
                    'purchase_requisition_read_status',
                    'purchase_requisition_read_is_urgent',
                    'purchase_requisition_read_currency',
                    'purchase_requisition_read_subtotal_with_discount',
                    'purchase_requisition_read_taxes_total',
                    'purchase_requisition_read_total',
                    'purchase_requisition_read_selectedQuote',
                    'purchase_requisition_read_providers_rfc_as_string',
                    'purchase_requisition_read_formatted_products'
                ];
                const query = querySerialize({...filters, token: window.localStorage.googlead , reportConfig: JSON.stringify(reportConfig)});
                return `${API_URL}${ApiPaths.purchaseRequisitions}.xlsx?${query}`;
            }
        };

    purchaseProducts = {
        get: ( {page=0, pageSize=10, loadingId, filters, customProp="purchaseProducts"} )=> this.apiCall(ApiPaths.purchaseProducts, customProp, {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
        getReport: ( {options, loadingId} )=> this.apiCall(`${ApiPaths.purchaseProducts}/report`, "purchaseProductsReport", options, {method: 'POST', loadingId} )
    };


    purchaseOrders=
        {
            get: ( page=0, pageSize=10, loadingId, filters, customProp )=> this.apiCall(ApiPaths.purchaseOrders, customProp || "purchaseOrders", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            edit: ({id, purchaseOrder, loadingId, customProp})=>this.apiCall(`${ApiPaths.purchaseOrders}/${id}`, customProp||"purchaseOrders", purchaseOrder, {method: "PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            downloadPdfUrl: (id)=>{
                const query = querySerialize({token: window.localStorage.googlead});
                return `${API_URL}${ApiPaths.purchaseOrders}/${id}/generate_pdf?${query}`;
            },
            exportUrl: ( filters )=>
            {
                const reportConfig = {
                    name: 'Órdenes de compra.xlsx',
                    headers: {
                        'folio': 'No. de orden',
                        'purchaseRequisition.folio': 'No. de solicitud',
                        'purchaseRequisition.requestedBy.fullName': 'Solicitante',
                        'purchaseRequisition.jazzArea.name': 'Área',
                        'purchaseRequisition.requestedDate': 'Fecha de solicitud',
                        'provider.name': 'Proveedor',
                        'provider.rfc': 'RFC',
                        'createdDate': 'Fecha de creación',
                        'status': 'Estado',
                        'paymentMethod': 'Método de pago',
                        'paymentWay': 'Forma de pago',
                        'transactionNumber': 'No. de transacción',
                        'subtotal': 'Subtotal',
                        'taxes': 'Impuestos',
                        'total': 'Total'
                    },
                    transStrings: {
                        'requested': 'Solicitada',
                        'quoted': 'Cotizada',
                        'approved': 'Aprobada',
                        'denied': 'Rechazada',
                        'canceled': 'Cancelada',
                        'paying': 'Pagando',
                        'payed': 'Pagada',
                        'in_clarification': 'En aclaración',
                        'bank_transfer': 'Transferencia bancaria',
                        'full_payment': 'Liquidación',
                        'fixed_fund': 'Fondo fijo',
                        'online': 'En línea',
                        'initial_and_settlement': 'Anticipo y finiquito',
                        'credit': 'Crédito'
                    }
                };

                filters.sGroups = [
                    'purchase_order_read_id',
                    'purchase_order_read_purchase_requisition',
                    'purchase_requisition_read_id',
                    'purchase_requisition_read_requested_by' ,
                    'employee_read_full_name',
                    'employee_read_area',
                    'area_read_name',
                    'purchase_requisition_read_requested_date',
                    'purchase_order_read_provider',
                    'provider_read_name',
                    'provider_read_rfc',
                    'creation_date',
                    'purchase_order_read_status',
                    'purchase_order_read_payment_method',
                    'purchase_order_read_payment_way',
                    'purchase_order_read_transaction_number',
                    'purchase_order_read_subtotal',
                    'purchase_order_read_taxes',
                    'purchase_order_read_total'


                ];

                const query = querySerialize({...filters, token: window.localStorage.googlead , reportConfig: JSON.stringify(reportConfig)});
                return `${API_URL}${ApiPaths.purchaseOrders}.xlsx?${query}`;
            }
        };


    productLines =
        {
            get: ( {page=0, pageSize=10, loadingId, filters, customProp="productLines"})=> this.apiCall(ApiPaths.productLines, customProp, {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( productLine, loadingId )=> this.apiCall( ApiPaths.productLines, "productLines", productLine, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId}),
            edit: ( id, productLine  )=> this.apiCall( `${ApiPaths.productLines}/${id}`, "productLines", productLine, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
            delete: ( id )=> this.apiCall( `${ApiPaths.productLines}/${id}`, "productLines", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    products =
        {
            get: ( {page=0, pageSize=10, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.products, customProp || "products", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( {product, files, loadingId, customProp}  )=> this.apiCall( ApiPaths.products, customProp || "products", product, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId}, files ),
            edit: ( {id, product, files, loadingId, customProp} )=> this.apiCall( `${ApiPaths.products}/${id}`, customProp || "products", product, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId}, files ),
            delete: ( {id, customProp, loadingId})=> this.apiCall( `${ApiPaths.products}/${id}`, customProp || "products", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} ),
            getReport: ( {id, customProp, loadingId})=> this.apiCall( `${ApiPaths.products}/${id}/report`, customProp || "productsReport", {id}, {loadingId} ),
            exportUrl: ({reportConfig, filters}) =>
                (query=>`${API_URL}${ApiPaths.products}.xlsx?${querySerialize(query)}`)({
                    ...filters,
                    pagination: false,
                    token: this.token,
                    reportConfig: JSON.stringify(reportConfig)
                }),


        };

    productBrands =
        {
            get: ({page = 0, pageSize = 10, loadingId, filters, customProp}) => this.apiCall(ApiPaths.productBrands, customProp || "productBrands", {page: page + 1, itemsPerPage: pageSize, ...filters}, {loadingId}),
            edit: ( {id, productBrand, files, loadingId, customProp} )=> this.apiCall( `${ApiPaths.productBrands}/${id}`, customProp || "productBrand", productBrand, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId}, files ),
        };

    productBrandProviders =
        {
            get: ({page = 0, pageSize = 10, loadingId, filters, customProp}) => this.apiCall(ApiPaths.productBrandProviders, customProp || "productBrandProviders", {page: page + 1, itemsPerPage: pageSize, ...filters}, {loadingId}),
            create: ( {productBrandProvider, loadingId, customProp}  )=> this.apiCall( ApiPaths.productBrandProviders, customProp || "productBrandProviders", productBrandProvider, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId}),
            edit: ( {id, productBrandProvider, loadingId, customProp} )=> this.apiCall( `${ApiPaths.productBrandProviders}/${id}`, customProp || "productBrandProviders", productBrandProvider, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId}),
        };

    productBrandUnits =
        {
            get: ( {page=0, pageSize=10, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.productBrandUnits, customProp || "productBrandUnits", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            edit: ( {id, productBrandUnit, loadingId, customProp} )=> this.apiCall( `${ApiPaths.productBrandUnits}/${id}`, customProp || "productBrandUnits", {id, ...productBrandUnit}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId}),
            delete: ( {id, customProp} )=> this.apiCall( `${ApiPaths.productBrandUnits}/${id}`, customProp || "productBrandUnits", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    providerEvaluations =
        {
            get: ( page=0, pageSize=10, loadingId, filters, customProp )=> this.apiCall(ApiPaths.providerEvaluations, customProp || "providerEvaluations", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( providerEvaluation, loadingId, customProp )=> this.apiCall( ApiPaths.providerEvaluations, customProp || "providerEvaluations", providerEvaluation, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( id, providerEvaluation, customProp )=> this.apiCall( `${ApiPaths.providerEvaluations}/${id}`, customProp || "providerEvaluations", providerEvaluation, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
            delete: ( id, customProp )=> this.apiCall( `${ApiPaths.providerEvaluations}/${id}`, customProp || "providerEvaluations", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    providers =
        {
            get: ( {page=0, pageSize=10, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.providers, customProp || "providers", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( {provider, loadingId, customProp, files} )=> this.apiCall( ApiPaths.providers, customProp || "providers", provider, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId}, files ),
            edit: ( {id, provider, loadingId, customProp, files} )=> this.apiCall( `${ApiPaths.providers}/${id}`, customProp || "providers", provider, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId}, files ),
            delete: ( id, customProp )=> this.apiCall( `${ApiPaths.providers}/${id}`, customProp || "providers", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    pointsWallets = {
        get: ({page=0, pageSize=50, customProp='pointsWallets', filters={}, loadingId}={}) => this.apiCall(ApiPaths.pointsWallets,customProp, {...filters, itemsPerPage: pageSize, page: page+1}, {loadingId})
    };

    positions =
        {
            get: ( filters, loadingId, customProp )=> this.apiCall(ApiPaths.positions, customProp||"positions", {itemsPerPage:200, ...filters }, {loadingId} ),
            get2: ( {page=0, pageSize=10, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.positions, customProp || "positions", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( position )=> this.apiCall( ApiPaths.positions, "positions", position, {method:"POST", stateAction:STATE_ACTION_PUSH} ),
            edit: ( id, position, loadingId, customProp )=> this.apiCall( `${ApiPaths.positions}/${id}`, customProp||"positions", {id, ...position}, {loadingId, method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
            delete: ( id )=> this.apiCall( `${ApiPaths.positions}/${id}`, "positions", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    questionnaires =
        {
            get: ( page=0, pageSize=200, loadingId, filters, customProp='questionnaires' )=> this.apiCall(ApiPaths.questionnaires, customProp, {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId, cacheable: true}  ),
            create: ( questionnaire, loadingId )=> this.apiCall( ApiPaths.questionnaires, "questionnaires", questionnaire, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( id, questionnaire, loadingId  )=> this.apiCall( `${ApiPaths.questionnaires}/${id}`, "questionnaires", questionnaire, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( id )=> this.apiCall( `${ApiPaths.questionnaires}/${id}`, "questionnaires", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    questionnaireReport =
        {
            get: ( options, loadingId )=> this.apiCall(ApiPaths.questionnaireReport, 'questionnaireReport', options, {method:"POST",loadingId } ),
        };

    recognitions = {
        get: ({page=0, pageSize = 50, filters = {}, customProp = 'recognitions'}={}) => this.apiCall(ApiPaths.recognitions, customProp, {
            ...filters,
            itemsPerPage: pageSize,
            page: page+1,
        }),
        create: ({params, files})=>
            this.apiCall(ApiPaths.recognitions, 'recognitions', params, {
                method: 'POST',
                stateAction: STATE_ACTION_PUSH
            }, files),
        edit: ({id, params, files}) =>
            this.apiCall(`${ApiPaths.recognitions}/${id}`, 'recognitions', params, {method: 'PUT', stateAction: STATE_ACTION_SEARCH_N_REPLACE},files)
    };

    recognitionMovements = {
        create: ({toEmployee, recognition, comment}) => this.apiCall(ApiPaths.recognitionMovements, 'recognitionMovementCreated', {
            toEmployee,
            recognition,
            comment
        }, {method: 'POST'}),
        get: ({filter = {'order[createdDate]':'DESC'}, customProp = 'recognitionMovements'} = {}) => this.apiCall(ApiPaths.recognitionMovements, customProp, filter),
        edit: ({id, customProp = 'recognitionMovements', attrs})=>this.apiCall(`${ApiPaths.recognitionMovements}/${id}`, customProp, attrs,
            {method: 'PUT', stateAction: STATE_ACTION_SEARCH_N_REPLACE})
    };

    refillingPointsRules = {
        get: ()=>this.apiCall(ApiPaths.refillingPointsRules, 'refillingPointsRules'),
        create: ({data})=>this.apiCall(ApiPaths.refillingPointsRules, 'refillingPointsRules', data , {
            method: 'POST',
            stateAction: STATE_ACTION_PUSH
        }),
        edit: ({data, id})=>this.apiCall(`${ApiPaths.refillingPointsRules}/${id}`, 'refillingPointsRules', data, {
            method: 'PUT',
            stateAction: STATE_ACTION_SEARCH_N_REPLACE
        })
    };

    repositoryFiles =
        {
            get: ( {page=0, pageSize=300, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.repositoryFiles, customProp || "repositoryFiles", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( {params, loadingId, customProp, files} )=> this.apiCall( ApiPaths.repositoryFiles, customProp || "repositoryFiles", params, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId}, files ),
            createMultiple: ( {params, loadingId, customProp, files} )=> this.apiCall( ApiPaths.repositoryFiles+'/multiple', customProp || "repositoryFiles", params, {method:"POST", stateAction:STATE_ACTION_APPEND, loadingId}, files ),
            edit: (  {params, loadingId, customProp, files, id} )=> this.apiCall( `${ApiPaths.repositoryFiles}/${id}`, customProp || "repositoryFiles", params, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId}, files ),
            delete: ( {id, customProp} )=> this.apiCall( `${ApiPaths.repositoryFiles}/${id}`, customProp || "repositoryFiles", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    reservations =
        {
            get: ( page=0, pageSize=200, loadingId, filters )=> this.apiCall(ApiPaths.reservations, "reservations", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId}  ),
            create: ( reservation, loadingId )=> this.apiCall( ApiPaths.reservations, "reservations", reservation, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( id, reservation, loadingId  )=> this.apiCall( `${ApiPaths.reservations}/${id}`, "reservations", reservation, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( id )=> this.apiCall( `${ApiPaths.reservations}/${id}`, "reservations", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} ),
            exportUrl: ({reportConfig, filters}) =>
            (query=>`${API_URL}${ApiPaths.reservations}.xlsx?${querySerialize(query)}`)({
                ...filters,
                pagination: false,
                token: this.token,
                reportConfig: JSON.stringify(reportConfig)
            }),
            statsReport: ({loadingId, params})=>this.apiCall(ApiPaths.reservations+'/stats_report', 'reservationStatsReport', params, {loadingId})

        };

    rewardExchanges = {
        create: (reward)=>this.apiCall(ApiPaths.rewardExchanges, 'createdRewardExchange', {reward}, {method: 'POST'}),
        get: ({page=0, pageSize=50, filters={}})=>
            this.apiCall(ApiPaths.rewardExchanges, 'rewardExchanges', {
                ...filters,
                itemsPerPage: pageSize,
                page: page+1
            }),
        edit: ({id, params})=>
            this.apiCall(`${ApiPaths.rewardExchanges}/${id}`, 'rewardExchanges', params, {method: 'PUT', stateAction: STATE_ACTION_SEARCH_N_REPLACE})
    };

    rewards = {
        get: ({page=0, pageSize = 50, filters = {}, customProp = 'rewards', loadingId}={}) =>
            this.apiCall(ApiPaths.rewards, customProp, {
                ...filters,
                itemsPerPage: pageSize,
                page: page+1,
            }, {
                loadingId,
                stateAction: STATE_ACTION_SET
            }),
        getNextPage: ({page=0, pageSize = 50, filters = {}, customProp = 'rewards', loadingId}) =>
            this.apiCall(ApiPaths.rewards, customProp, {
                ...filters,
                itemsPerPage: pageSize,
                page: page+1,
            }, {
                loadingId,
                stateAction: STATE_ACTION_APPEND
            }),
        create: ({params, files})=>
            this.apiCall(ApiPaths.rewards, 'rewards', params, {
                method: 'POST',
                stateAction: STATE_ACTION_PUSH
            }, files),
        edit: ({id, params, files}) =>
            this.apiCall(`${ApiPaths.rewards}/${id}`, 'rewards', params, {method: 'PUT', stateAction: STATE_ACTION_SEARCH_N_REPLACE},files)
    };

    roles =
        {
            get: ()=> this.apiCall(ApiPaths.roles, "roles", {itemsPerPage:200} ),
        };

    services = {
        get: ({page=0, pageSize=200, filters={}, customProp='services'}={})=>
            this.apiCall(ApiPaths.services, customProp, {...filters, page: page+1, itemsPerPage: pageSize}),
        create: ({customProp='services', properties={}}={})=>
            this.apiCall(ApiPaths.services, customProp, properties, {method: 'POST', stateAction: STATE_ACTION_PUSH}),
        edit: ({customProp='services', properties={}, id})=>
            this.apiCall(`${ApiPaths.services}/${id}`, customProp, properties, {stateAction: STATE_ACTION_SEARCH_N_REPLACE, method: 'PUT'}),
        delete: ({id, customProp='services'})=>this.apiCall(`${ApiPaths.services}/${id}`, customProp, {id}, {method: 'DELETE', stateAction: STATE_ACTION_SEARCH_N_DELETE})
    };

    serviceRequests = {
        get: ({page=0, pageSize=200, filters={}, customProp='serviceRequests'}={})=>
            this.apiCall(ApiPaths.serviceRequests, customProp, {...filters, page: page+1, itemsPerPage: pageSize}),
        create: ({customProp='serviceRequests', properties={}}={})=>
            this.apiCall(ApiPaths.serviceRequests, customProp, properties, {method: 'POST', stateAction: STATE_ACTION_PUSH}),
        edit: ({customProp='serviceRequests', properties={}, id})=>
            this.apiCall(`${ApiPaths.serviceRequests}/${id}`, customProp, properties, {stateAction: STATE_ACTION_SEARCH_N_REPLACE, method: 'PUT'}),
        delete: ({id, customProp='serviceRequests'})=>this.apiCall(`${ApiPaths.serviceRequests}/${id}`, customProp, {id}, {method: 'DELETE', stateAction: STATE_ACTION_SEARCH_N_DELETE})
    };

    shrinkages = {
        get: ({page=0, pageSize=50, filters={}, customProp='shrinkages'}={})=>
            this.apiCall(ApiPaths.shrinkages, customProp, {...filters, page:page+1, itemsPerPage: pageSize}),
        exportUrl: ( filters )=>
        {
            const reportConfig = {
                name: `Reporte de mermas ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`,
                headers: {
                    'stockMovement.productBrand.product.name': 'Producto',
                    'stockMovement.productBrand.brand.name': 'Marca',
                    'stockMovement.batch.warehouse.name': 'Almacén',
                    'stockMovement.quantity': 'Cantidad',
                    'stockMovement.productBrand.product.smallestUnit.name': 'Unidad',
                    'formattedCreatedDate': 'Fecha',
                    'type': 'Tipo',
                    'createdBy.employee.fullName': 'Registrada por',
                    'stockMovement.batch.shrinkageComment': 'Comentario'
                },
                transStrings: {
                    'expected': 'Automática',
                    'surprise': 'Manual'
                }
            };

            const query = querySerialize({...filters, token: window.localStorage.googlead , reportConfig: JSON.stringify(reportConfig)});
            return `${API_URL}${ApiPaths.shrinkages}.xlsx?${query}`;
        }
    };

    system =
        {
            getFilesStorage: ()=> this.apiCall(ApiPaths.system + '/files_storage', "systemFilesStorage"),
        };

    staffRotationReport =
        {
            get: ( options, loadingId, customProp='staffRotationReport' )=> this.apiCall(ApiPaths.staffRotationReport, customProp, options, {method:"POST",loadingId} ),
        };

    shifts =
        {
            get: ()=> this.apiCall(ApiPaths.shifts, "shifts", {itemsPerPage:200} ),
            create: ( shift, loadingId )=> this.apiCall( ApiPaths.shifts, "shifts", shift, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( id, shift, loadingId )=> this.apiCall( `${ApiPaths.shifts}/${id}`, "shifts", shift, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( id, loadingId )=> this.apiCall( `${ApiPaths.shifts}/${id}`, "shifts", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} )
        };

    stockMovementBatches =
        {
            get: ( {page=0, pageSize=150, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.stockMovementBatches, customProp || "stockMovementBatches", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( {stockMovementBatch, loadingId, customProp} )=> this.apiCall( ApiPaths.stockMovementBatches, customProp || "stockMovementBatches", stockMovementBatch, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( {id, stockMovementBatch, loadingId, customProp} )=> this.apiCall( `${ApiPaths.stockMovementBatches}/${id}`, customProp || "stockMovementBatches", stockMovementBatch, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( id, customProp )=> this.apiCall( `${ApiPaths.stockMovementBatches}/${id}`, customProp || "stockMovementBatches", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    stockMovements =
        {
            get: ( {page=0, pageSize=10, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.stockMovements, customProp || "stockMovements", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            getOptimized: ( {page=0, pageSize=10, loadingId, filters, customProp} )=> this.apiCall(`${ApiPaths.stockMovements}/optimized`, customProp || "stockMovements", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            edit: ( id, stockMovement, customProp )=> this.apiCall( `${ApiPaths.stockMovements}/${id}`, customProp || "stockMovements", {id, ...stockMovement}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE} ),
            delete: ( {id, customProp, loadingId} )=> this.apiCall( `${ApiPaths.stockMovements}/${id}`, customProp || "stockMovements", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE,loadingId} )
            //Don't create stockMovements directly, they should be inside a stockMovementBatches
        };

    stockRequestApprovals=
        {
            get: ( {page=0, pageSize=150, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.stockRequestApprovals, customProp || "stockRequests", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            edit: ( {id, stockRequestApproval, customProp, loadingId} )=> this.apiCall( `${ApiPaths.stockRequestApprovals}/${id}`, customProp || "stockRequests", stockRequestApproval, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
        };

    stockRequests=
        {
            getMyPendingRequests: ({page=0, pageSize=150, loadingId, filters, customProp = 'MyPendingRequestsList'})=> this.apiCall(ApiPaths.stockRequests + '/pending_approved_by_me', customProp,{page:page+1,itemsPerPage:pageSize, ...filters}, {method: 'GET'}),
            get: ( {page=0, pageSize=150, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.stockRequests, customProp || "stockRequests", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( stockRequest, loadingId, customProp )=> this.apiCall( ApiPaths.stockRequests, customProp || "stockRequests", stockRequest, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( {id, stockRequest, customProp, loadingId} )=> this.apiCall( `${ApiPaths.stockRequests}/${id}`, customProp || "stockRequests", stockRequest, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( id, customProp )=> this.apiCall( `${ApiPaths.stockRequests}/${id}`, customProp || "stockRequests", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    shiftCategories =
        {
            get: ()=> this.apiCall(ApiPaths.shiftCategories, "shiftCategories", {itemsPerPage:200} ),
            create: ( name, loadingId )=> this.apiCall( ApiPaths.shiftCategories, "shiftCategories", {name}, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( id, name, loadingId )=> this.apiCall( `${ApiPaths.shiftCategories}/${id}`, "shiftCategories", {name}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( id, loadingId )=> this.apiCall( `${ApiPaths.shiftCategories}/${id}`, "shiftCategories", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} )
        };

    tables=
        {
            get: ( {page=0, pageSize=150, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.tables, customProp || "tables", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( {table, loadingId, customProp} )=> this.apiCall( ApiPaths.tables, customProp || "tables", table, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( {id, table, customProp, loadingId} )=> this.apiCall( `${ApiPaths.tables}/${id}`, customProp || "tables", table, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( {id, customProp })=> this.apiCall( `${ApiPaths.tables}/${id}`, customProp || "tables", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    uploadTimeReport =
        {
            upload: (file, clockId, loadingId) => this.apiCall(`${ApiPaths.uploadTimeReport}/${clockId}`, "uploadTimeReport", {}, {method: "POST", loadingId, useFormData: true }, {excel: file}),
            clear: ()=>this.clearProperty("uploadTimeReport")
        };

    taxes =
        {
            get: ( page=0, pageSize=200, loadingId, filters )=> this.apiCall(ApiPaths.taxes, "taxes", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId}  ),
            create: ( tax, loadingId )=> this.apiCall( ApiPaths.taxes, "taxes", tax, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( id, tax, loadingId  )=> this.apiCall( `${ApiPaths.taxes}/${id}`, "taxes", tax, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( id )=> this.apiCall( `${ApiPaths.taxes}/${id}`, "taxes", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE} )
        };

    tickets =
        {
            get: ( page=0, pageSize=10, loadingId, filters )=> this.apiCall(ApiPaths.tickets, "tickets" , {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( ticket, loadingId )=> this.apiCall( ApiPaths.tickets, "tickets", ticket, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( id, properties, loadingId )=> this.apiCall( `${ApiPaths.tickets}/${id}`, "tickets", properties, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
        };

    ticketTypes =
        {
            get: ( page=0, pageSize=10, loadingId, filters )=> this.apiCall(ApiPaths.ticketTypes, "ticketTypes" , {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
        };

    tgleCompanies =
        {
            get: ( loadingId, filters, property )=> this.apiCall(ApiPaths.tgleCompanies, property?property:"tgleCompanies", {itemsPerPage:200, ...filters}, {loadingId} ),
        };
    users =
        {
            get: ( page=0, pageSize=10, loadingId, filters, customProp, isActive = true )=> this.apiCall(ApiPaths.users, customProp? customProp:"users" , {page:page+1,itemsPerPage:pageSize, ...filters, isActive}, {loadingId} ),
            get2: ( {page=0, pageSize=10, loadingId, filters, customProp, isActive = true} )=> this.apiCall(ApiPaths.users, customProp? customProp:"users" , {page:page+1,itemsPerPage:pageSize, ...filters, isActive}, {loadingId} ),
            getSubordinates: ( {page=0, pageSize=10, loadingId, filters, customProp, isActive = true} )=> this.apiCall(ApiPaths.usersSubordinates, customProp? customProp:"users" , {page:page+1,itemsPerPage:pageSize, ...filters, isActive}, {loadingId} ),
            unswtichedGet: ( {page=0, pageSize=10, loadingId, filters, customProp, isActive = true} )=> this.apiCall(ApiPaths.users, customProp? customProp:"users" , {page:page+1,itemsPerPage:pageSize, ...filters, isActive}, {loadingId, preventSwitch:true} ),
            getSimple: ( page=0, pageSize=10, loadingId, filters, customProp, isActive = true )=> this.apiCall(ApiPaths.usersSimple, customProp? customProp:"usersSimple" , {page:page+1,itemsPerPage:pageSize, ...filters, isActive}, {loadingId} ),
            create: ( user, loadingId, file )=> this.apiCall( ApiPaths.users, "newuser", user, {method:"POST", stateAction:STATE_ACTION_SET, loadingId}, file?[file]:undefined ),
            edit: ( id, properties, loadingId, file, customProp )=> this.apiCall( `${ApiPaths.users}/${id}`, customProp||"users", properties, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId, useFormData:true}, file?[file]:undefined ),
            update: ( id, filters, loadingId, customProp)=> this.apiCall( `${ApiPaths.users}/${id}`, customProp||"users", {id, ...filters}, {method:"GET", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId } ),
            avatarUrl: (user) => {
                if(!user)
                    return avatar;
                if(!user.employee)
                    return  sysadminAvatar;
                if(user.employee && user.employee.photo)
                    return this.createFileUrl(user.employee.photo);
                return avatar;
            }

        };

    visits = {
        get: ( {page=0, pageSize=100, loadingId, filters, customProp}={} )=> this.apiCall(ApiPaths.visits, customProp || "visits", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
        airlinesReport: ({params, customProp, loadingId}={})=>this.apiCall(ApiPaths.visits+'/count-by-airline', customProp || "visitsAirlinesReport", params, {loadingId} ),
        flightsReport: ({params, customProp, loadingId}={})=>this.apiCall(ApiPaths.visits+'/persons_by_flight_report', customProp || "visitsFlightsReport", params, {loadingId} ),
        exportUrl: ({reportConfig, filters}) =>
            (query=>`${API_URL}${ApiPaths.visits}.xlsx?${querySerialize(query)}`)({
                ...filters,
                pagination: false,
                token: this.token,
                reportConfig: JSON.stringify(reportConfig)
            }),
    };

    workTools =
        {
            get: ( {page=0, pageSize=100, loadingId, filters, customProp}={} )=> this.apiCall(ApiPaths.workTools, customProp || "workTools", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( {workTool,customProp, loadingId} )=> this.apiCall( ApiPaths.workTools,customProp||"workTools", workTool, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( {id, workTool, loadingId, customProp} )=> this.apiCall( `${ApiPaths.workTools}/${id}`, customProp||"workTools", {id, ...workTool}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( {id, loadingId, customProp} )=> this.apiCall( `${ApiPaths.workTools}/${id}`, customProp||"workTools", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} ),
        };

    warehouseCounts =
        {
            get: ( {page=0, pageSize=100, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.warehouseCounts, customProp || "warehouseCounts", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            create: ( {warehouseCount, loadingId} )=> this.apiCall( ApiPaths.warehouseCounts, "warehouseCounts", warehouseCount, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( {id, warehouseCount, loadingId, customProp} )=> this.apiCall( `${ApiPaths.warehouseCounts}/${id}`, customProp||"warehouseCounts", {id, ...warehouseCount}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( {id, loadingId} )=> this.apiCall( `${ApiPaths.warehouseCounts}/${id}`, "warehouseCounts", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} )
        };

    warehouses =
        {
            get: ( {page=0, pageSize=100, loadingId, filters, customProp} )=> this.apiCall(ApiPaths.warehouses, customProp || "warehouses", {page:page+1,itemsPerPage:pageSize, ...filters}, {loadingId} ),
            getPendingMovements: ( {loadingId, warehouseId, property})=> this.apiCall(ApiPaths.warehousesPendingMovements(warehouseId), property?property:"warehousePendingMovements", {}, {loadingId} ),
            create: ( {warehouse, loadingId} )=> this.apiCall( ApiPaths.warehouses, "warehouses", warehouse, {method:"POST", stateAction:STATE_ACTION_PUSH, loadingId} ),
            edit: ( {id, warehouse, loadingId} )=> this.apiCall( `${ApiPaths.warehouses}/${id}`, "warehouses", {id, ...warehouse}, {method:"PUT", stateAction:STATE_ACTION_SEARCH_N_REPLACE, loadingId} ),
            delete: ( {id, loadingId} )=> this.apiCall( `${ApiPaths.warehouses}/${id}`, "warehouses", {id}, {method:"DELETE", stateAction:STATE_ACTION_SEARCH_N_DELETE, loadingId} )
        };

    webPush = {
        getVapidPublicKey: ()=>this.apiCall(ApiPaths.webPushVapidPublicKey),
        subscribe: ({subscription, loadingId})=>this.apiCall(ApiPaths.webPush, "subscription", {subscription}, {method:"POST", loadingId}),
        unsubscribe: ({subscription, loadingId})=>this.apiCall(ApiPaths.webPush, "subscription", {subscription}, {method:"DELETE", loadingId})
    };

    gcareByHourReport =
    {
        get: ( options, loadingId )=> this.apiCall(ApiPaths.gcareByHourReport, 'gcareByHourReport', options, {method:"GET",loadingId } ),
        gcareByHourReportURL: ({params})=>API_URL+ApiPaths.gcareByHourReport+'?'+querySerialize({...params, token: this.token})
    };

    receptionistsCheckinReport = 
    {
        get: ( options, loadingId )=> this.apiCall(ApiPaths.receptionistsCheckinReport, 'recepcionistsCheckinReport', options, {method:"GET",loadingId } ),
        recepcionistsCheckinReportURL: ({params})=>API_URL+ApiPaths.receptionistsCheckinReport+'?'+querySerialize({...params, token: this.token})
    };

    apiCall = ( path, property, params, config, files)=>
    {

        if( !config )
            config = {};
        let method = config.method || "GET";
        const filesPropName = "files[]";

        const stateAction = config.stateAction || STATE_ACTION_SET;
        let useFormData = config.useFormData || false;
        if( files )
            useFormData = true;

        // Prepare request
        let query = "?";
        let body = undefined;

        if( params ) {

            if (method === "GET" ) {
                query += querySerialize(params);
            }
            else if ( useFormData ){
                const form_data = new FormData();
                form_data.append("_method",method);
                form_data.append("data",JSON.stringify(params));
                if( files && files.constructor === Array ) {
                    files.forEach((file) => {
                        if( file ) {
                            if (file.name)
                                form_data.append(filesPropName, file, file.name);
                            else
                                form_data.append(filesPropName, file);
                        }
                    });
                }
                else if(files && typeof files === "object"){
                    for( const name in files ) {
                        if(files[name] instanceof Array){
                            for(let i=0; i<files[name].length; i++){
                                form_data.append(name+"["+i+"]", files[name][i]);
                            }
                        }else{
                            form_data.append(name, files[name]);
                        }
                    }
                }

                method = "POST";
                body = form_data;
            }
            else if( method === "POST" || method === "PUT" || method === 'DELETE'){
                body = JSON.stringify(params);
            }
        }

        //TimeTravel hack
        if( process.env.REACT_APP_BUILD === 'dev' ) {
            if (window.localStorage.timeTravel)
                query += "&" + querySerialize({fakeTime: window.localStorage.timeTravel});
        }

        //Make request
        let loadingStartAction = {type:APP_LOADING_START};
        if( config.loadingId ) loadingStartAction.payload = {id:config.loadingId};

        this.store.dispatch(loadingStartAction);

        const successHandler = ( data )=>{

            let loadingEndAction = {type:APP_LOADING_END};
            if( config.loadingId ) loadingEndAction.payload = {id:config.loadingId};

            if( data === QUEUED_RESPONSE ) {
                this.store.dispatch(loadingEndAction);
                return data;
            }

            if( !data )
                return errorHandler("Empty response");

            this.store.dispatch({

                type: ACTION_PREFIX + stateAction,
                payload: {
                    success:true,
                    method,
                    property,
                    params,
                    ...data
                }

            });

            this.store.dispatch(loadingEndAction);

            if( config.cacheable ){
                this.store.dispatch({
                    type: ACTION_CACHE_SAVE,
                    payload: {
                        url:path,
                        params,
                        data
                    }
                });
            }

            return data.data;
        };

        const errorHandler = ( response )=>{

            let loadingEndAction = {type:APP_LOADING_END};
            if( config.loadingId ) loadingEndAction.payload = {id:config.loadingId};
            this.store.dispatch(loadingEndAction);

            if (response && response.name === 'AbortError')
                throw response;

            if( response && response.status === 401 )
                throw new Error('Not logged in');

            let message = response instanceof Error?
                "Error de red. Revisa tu conexión a internet":
                "Parece que hubo un error.";

            // Symfony profiler
            if(response && response.headers && response.headers.get('X-Debug-Token-Link')) {
                const debugLink = response.headers.get('X-Debug-Token-Link');
                message = <span>{message} <a target="_blank" rel="noopener noreferrer" href={debugLink+'?panel=exception'}>{debugLink}</a></span>;
            }

            if( config.preventNotifier )
                throw(response);

            if( response && response.headers &&
                response.headers.get("Content-Type") && (
                response.headers.get("Content-Type").split(";")[0] === "application/problem+json" ||
                response.headers.get("Content-Type").split(";")[0] === "application/json" )
            ){
                response.json().then((json)=>{
                    this.store.dispatch( Notifications.error({
                        title: <i className="fa fa-exclamation-triangle"/>,
                        message: <span> {message} <br/> {json.detail?json.detail:null}</span>,
                        autoDismiss: 8
                    }));
                })
            }
            else{
                this.store.dispatch( Notifications.error({
                    title: <i className="fa fa-exclamation-triangle"/>,
                    message: message,
                    autoDismiss: 8,
                    uid:  response instanceof Error?'network-error':'api-error'
                }));
            }

            throw( response );

        };

        const requestFinished = (response)=>{

            if( !response.status ) {//Inside this if means the fetch failed

                if( config.cacheable ) {//If it was a cacheable request, look for it in the cache and return it as if nothing has happened
                    const cache = this.store.getState().apiCache;
                    const cached = searchCache(path, params, cache);

                    if (cached)
                        return cached;
                }

                if( config.queueable ){//If it was a queueable request, save it to the queue to try again later.

                    console.log('Request to ' + path + ' queued.');
                    const item = createQueueItem( path, property, params, config, files );
                    this.store.dispatch({
                        type: ACTION_QUEUE_PUSH,
                        payload: item
                    });

                    return QUEUED_RESPONSE;
                }

                throw(response);
            }

            if( response && response.headers )
                this.responseHeadersHandler( response.headers );

            if (response.status === 401) {
                this.store.dispatch({
                    type:ACTION_PREFIX+ACTION_LOG,
                    payload: LOGIN_STATE.NOT_LOGGED

                });
                throw (response);
            }
            else if (response.status >= 400) {

                return Promise.reject(response);
            }
            else if( response.status === 204 ){
                return response;
            }
            else if(response.json){
                return response.json()
            }

        };

        let headers= {"Accept": "application/json" };

        if( this.isSafari && this.token )
            headers["Authorization"] = "Bearer "+this.token;
        if(! useFormData )
            headers["Content-Type"]= "application/json";
        if( window.localStorage.switchMe && !config.preventSwitch )
            headers["x-view-analytics"]= window.localStorage.switchMe;

        const url = this.baseUrl + path + query;
        this.logRequest( url, params );

        const fetchParams={
            credentials: 'include',
            headers,
            method,
            body
        };

        //Aborting requests feature
        let abort=()=>{};//Abort is a noop if it's not supported
        if(window.AbortController) {
            const controller = new window.AbortController();
            fetchParams.signal = controller.signal;
            abort=controller.abort.bind(controller);
        }

        const fetchPromise=fetch(url, fetchParams)
        .then(requestFinished,requestFinished)
        .then( successHandler, errorHandler );

        //Make abort accesible through the returned promise. This is weird. And it will be lost after a ".then" is used
        //Looking rn for a more obvious way of making it accesible without breaking all the actual code that expects a
        //promise to be returned from apiCall. ¿Any suggestion, dear code reader?
        fetchPromise.abort=abort;

        return fetchPromise;
    };

    logRequest = ( url, params) =>{

        this.requestLog.push( { url, params } );

        if(  this.requestLog.length >= 50 )
            this.requestLog.shift();

    };

    clearProperty = (property)=>
    {
        this.store.dispatch({
            type: ACTION_PREFIX+STATE_ACTION_CLEAR,
            payload: {property}
        })
    };

    responseHeadersHandler = ( headers )=>{

        if( headers.get("X-App-Version") )
            this.handleVersionHeader(headers.get("X-App-Version") );

        //if( process.env.REACT_APP_BUILD === 'prod' )
        //    this.turnOnWebDebugger();

    };

    handleVersionHeader = ( versionHeader )=>{

        if( typeof  versionHeader !== 'string' )
            return;

        const size = versionHeader.length;
        let forceReload = false;

        if( versionHeader[ size-1 ] === 'f' ) {
            forceReload = true;
            versionHeader = versionHeader.slice( 0, size-1 );
        }

        if( versionHeader === version )
            return;

        const headerParts = versionHeader.split('.');
        const localVersion =  version[ version.length-1 ] === 'f'?version.slice( 0, version.length-1 ):version;
        const appParts = localVersion.split('.');

        if( Number(headerParts[0]) > Number(appParts[0]) )
            return this.handleNewerVersion( versionHeader, forceReload );

        if( Number(headerParts[0]) < Number(appParts[0]) )
            return;

        if( Number(headerParts[1]) > Number(appParts[1]) )
            return this.handleNewerVersion( versionHeader, forceReload );

        if( Number(headerParts[1]) < Number(appParts[1]) )
            return;

        if( Number(headerParts[2]) > Number(appParts[2]) )
            return this.handleNewerVersion( versionHeader, forceReload );

    };

    handleNewerVersion = ( version, force)=>{


        console.log("Newer version detected");

        if( !this.newerVersion && window.swRegistration )
            window.swRegistration.update()
                .then(()=>{
                    //This will broadcast the refresh message to all active tabs of the app through the sw
                    if( force )
                        window.navigator.serviceWorker.controller.postMessage('force_refresh');
                });

        this.newerVersion = version;
        if( force && !window.swRegistration )
            window.location.reload( true );
    };

    turnOnWebDebugger = ()=>{

        if( debugEnabled ) return;
        console.log("Web debugger");

        debugEnabled = 1;
        window.store = this.store;

        const script = document.createElement("script");
        script.setAttribute("src","https://gledev.tide.mx:1337/vorlon.production.js");
        document.body.append(script);

        let vorInterval = setInterval(()=> {//Wait for the vorlon library to be downloaded
            if (!window.VORLON)
                return;
            clearInterval(vorInterval);
            vorlonProd = new window.VORLON.Production("https://gledev.tide.mx:1337", 'tgle');
            vorlonProd.activate(false);

            const state = window.store.getState();

            vorlonProd.setIdentity( employeeFullname( state.api.me.employee ) + '( '+ state.api.me.username +' )'  );
        },1000);

    };

    /* DEVELOPMENT STUFF  */
    switchUser = ( username )=>{
        window.localStorage.switchMe = username;
        window.location.reload();
    };

    unswitchUser = ()=>{
        delete window.localStorage.switchMe;
        window.location.reload();
    };

    isUserSwitched = ()=>!!window.localStorage.switchMe;

    switchHost = ( host )=>{
        window.localStorage.host = host;
        window.location.reload();
    };

    sendError = ( error, stack, user, additionalInfo )=>{

        let params = { stack };
        if( error && error.message )
            params.error = error.message;

        if( user ){
            params.user = {
                id: user.id,
                username: user.username
            };

            if( user.employee ) {
               params.employee = {
                   id: user.employee.id,
                   name: employeeFullname( user.employee )
               }
            }
        }

        if( additionalInfo )
            params = { ...params, ...additionalInfo };

        return this.apiCall( ApiPaths.frontendError, "error_sent", params, { method: "POST", queueable: true }  )
    }

}

//Remote debugging
let vorlonProd;
let debugEnabled = 0;


export const querySerialize = (obj) => qs.stringify(obj, { arrayFormat: 'brackets' });

export const AbsenceCodes =
    [
        "F",
        "I"
    ];

export const ApiContext = React.createContext(null);

export const employeeFullname = (employee)=>{
    console.warn('Legacy method "employeeFullname". Use "employeeFullName" from src/utils/employeeUtils.js');
    return employee? (employee.name+" "+employee.patLastName+" "+employee.matLastName):"";
};
