import React, {Component} from 'react';
import Card from '../../../../components/Card/Card';
import ReactEcharts from 'echarts-for-react';
import PropTypes from 'prop-types';
import LoaderIcon from "../../../../components/Helper/LoaderIcon";
import moment from 'moment';
import _ from 'lodash';
import {connect} from 'react-redux';
import DateTime from 'react-datetime';


import scale1 from '../../../../assets/img/happiness-scale/angry.png';
import scale2 from '../../../../assets/img/happiness-scale/uncomfortable.png';
import scale3 from '../../../../assets/img/happiness-scale/indifferent.png';
import scale4 from '../../../../assets/img/happiness-scale/amused.png';
import scale5 from '../../../../assets/img/happiness-scale/happy.png';


class HappinessOverTimeGraph extends Component {

    static contextTypes = { api: PropTypes.object };

    state = {
        intervalSize: 2,
        numberOfBlocks: 14,
        fromDate: moment('2019-02-03'),
        checkTypes: false
    };

    componentDidMount(){

        if( this.props.filterGroup && !this.props.me )
            return;

        this.loadReport();
    }

    loadReport = ()=>{

        const filters = {};

        if( this.props.filterGroup ){
            if( !this.props.me.employee.owningGroup )
                return;
            filters.group = this.props.me.employee.owningGroup.id;
        }

        this.context.api.happinessLogsReport.get(
            this.state.fromDate.format('YYYY-MM-DDTHH:mm'),
            this.state.intervalSize, this.state.numberOfBlocks,
            'HappinessOverTimeGraph',
            'HappinessOverTimeGraph',
            filters )
            .then( (happinessLogsReport)=>{
                this.happinessLogsReport = happinessLogsReport;
                const options = this.createHappinessOptions( happinessLogsReport );
                this.setState( {options} );
            } )
    };

    createHappinessOptions = ( happinessLogsReport ) => {

        const report = happinessLogsReport;
        const lounges = [];
        const series = [];
        let periods = [];
        const type = 'line';
        let timeshift = 0;

        //This will be the average of all lounges, will be calculated by adding up all stats
        const generalSeries = {};
        const generalSeriesIn = {};
        const generalSeriesOut = {};

        //First iterate everything to obtain all the values un x axis i.e. the periods to graph

        // Method to be called in the "each" function of the next iteration,
        // we define it here to prevent defining a methos inside a loop ( two loops actually )
        const statFormatter = ( stat )=>{
            if( periods.indexOf( stat.timeblock ) === -1 )
                periods.push(stat.timeblock);
            if( !timeshift )
                timeshift = stat.timeshift;
        };

        for( let lounge in report ) {
            for (let shiftType in report[lounge]) {
                if (report[lounge][shiftType].length) {
                    _.each(report[lounge][shiftType], statFormatter);
                }
            }
        }

        //Sort the periods from the x axis
        periods.sort();

        let counter = 0;
        //Now we generate the series, for each lounge and shift, we extract an array of happiness averages in time
        //In the mean time, adding up every hapinness average to make one general average
        for( let lounge in report ) {
            for( let shiftType in report[lounge] ){
                if( report[lounge][shiftType].length ) {

                    const name = `${lounge} - ${shiftType}`;
                    lounges.push(name);

                    const stats = report[lounge][shiftType];

                    if( !this.state.checkTypes ) {
                        //Generate data for one lounge average graph
                        const data = periods.map((period) => {
                            const stat = _.find(stats, (stat) => period === stat.timeblock);
                            if (stat) {

                                const count = Number(stat.count);
                                if (generalSeries[period]) {
                                    generalSeries[period].sum += Number(stat.average) * count;
                                    generalSeries[period].count += count;
                                } else {
                                    generalSeries[period] = {
                                        sum: Number(stat.average) * count,
                                        count: Number(stat.count)
                                    };
                                }

                                return stat.average;
                            } else
                                return null;
                        });

                        series.push({name, type, data});
                    }
                    else{
                        //Generate data for one lounge chenk in average graph
                        const dataIn = periods.map((period) => {
                            const stat = _.find(stats, (stat) => period === stat.timeblock);
                            if (stat) {

                                const count = Number(stat.checkInCount);
                                if (generalSeriesIn[period]) {
                                    generalSeriesIn[period].sum += Number(stat.checkInAverage) * count;
                                    generalSeriesIn[period].count += count;
                                } else {
                                    generalSeriesIn[period] = {
                                        sum: Number(stat.checkInAverage) * count,
                                        count: Number(stat.checkInCount)
                                    };
                                }

                                return stat.checkInAverage;
                            } else
                                return null;
                        });

                        series.push({name, type, data: dataIn, itemStyle: { color: greenPalette[ counter % greenPalette.length ] }});

                        //Generate data for one lounge chenk out average graph
                        const dataOut = periods.map((period) => {
                            const stat = _.find(stats, (stat) => period === stat.timeblock);
                            if (stat) {

                                const count = Number(stat.checkOutCount);
                                if (generalSeriesOut[period]) {
                                    generalSeriesOut[period].sum += Number(stat.checkOutAverage) * count;
                                    generalSeriesOut[period].count += count;
                                } else {
                                    generalSeriesOut[period] = {
                                        sum: Number(stat.checkOutAverage) * count,
                                        count: Number(stat.checkOutCount)
                                    };
                                }

                                return stat.checkOutAverage;
                            } else
                                return null;
                        });

                        series.push({name, type, data: dataOut, itemStyle: { color: redPalette[ counter % redPalette.length ] }});
                    }
                    counter ++;
                }
            }
        }

        lounges.push('GENERAL');

        if( !this.state.checkTypes ) {
            const generalData = periods.map((period) => {
                if (generalSeries[period])
                    return Math.round((generalSeries[period].sum / generalSeries[period].count) * 10000) / 10000;
                else
                    return null
            });
            series.push({name: 'GENERAL', type, data: generalData });
        }
        else{
            const generalInData = periods.map((period) => {
                if (generalSeriesIn[period])
                    return Math.round((generalSeriesIn[period].sum / generalSeriesIn[period].count) * 10000) / 10000;
                else
                    return null
            });
            series.push({name: 'GENERAL', type, data: generalInData, itemStyle: {color: greenPalette[0]}});

            const generalOutData = periods.map((period) => {
                if (generalSeriesOut[period])
                    return Math.round((generalSeriesOut[period].sum / generalSeriesOut[period].count) * 10000) / 10000;
                else
                    return null
            });
            series.push({name: 'GENERAL', type, data: generalOutData, itemStyle: {color: redPalette[0]}});
        }

        periods = periods.map( (timeblock)=>moment( (( timeblock * this.state.intervalSize ) - timeshift)* 1000 ).format( 'DD-MM-YY' ) );

        let selectedLegends = {};

        if( this.state.selectedLegends ){
            selectedLegends = this.state.selectedLegends;
        }
        else {
            selectedLegends = lounges.reduce((acc, lounge) => {
                acc[lounge] = false;
                return acc;
            }, {});
            selectedLegends['GENERAL'] = true;
        }

        return {
            backgroundColor: '#f5f5f5',
            tooltip: {
                trigger: 'axis',

            },
            legend: {
                data: lounges,
                selected: selectedLegends
            }
            ,
            grid: {
                left: '6%',
                right: '6%',
                bottom: '3%',
                top: '25%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: periods
            },
            yAxis: {
                type: 'value',
                axisLabel : {
                    formatter:' '
                },
                min:0,
                max:5
            },
            series: series,
            textStyle: {color:"rgba(20, 20, 20, 1)"}
        }
    };

    loadReportDebounced = _.debounce( this.loadReport, 600 );

    decreaseInterval = ()=>{
        if( this.state.intervalSize > 1 )
            this.setState( { intervalSize: this.state.intervalSize - 1 }, this.loadReportDebounced )
    };

    increaseInterval = ()=>{
        this.setState( { intervalSize: this.state.intervalSize + 1 }, this.loadReportDebounced )
    };

    decreaseBlocks = ()=>{
        if( this.state.numberOfBlocks > 1 )
            this.setState( { numberOfBlocks: this.state.numberOfBlocks - 1 }, this.loadReportDebounced )
    };


    increaseBlocks= ()=>{
        this.setState( { numberOfBlocks: this.state.numberOfBlocks + 1 }, this.loadReportDebounced )
    };

    legendSelectChanged = ( e )=>{
        this.setState( {selectedLegends: e.selected } );
    };

    toggleCheckTypes = ()=>{
        this.setState( {checkTypes: !this.state.checkTypes}, ()=>{
            const options = this.createHappinessOptions( this.happinessLogsReport );
            this.setState( {options} );
        } )
    };

    render() {

        return (
            <Card
                title={<span>Felicidad en el tiempo</span>}
                category="Tendencia por sala y turno"
                className={'HappinessOverTimeGraph'}
                content={
                    <div>
                        {this.state.options ?
                            <div className='chart-container'>
                                <ReactEcharts
                                    option={this.state.options}
                                    notMerge={true}
                                    style={{height: "520px"}}
                                    onEvents={{
                                        legendselectchanged: this.legendSelectChanged
                                    }}
                                />

                                <img className='happiness-face scale5' src={scale5} alt={'Happiness scale 5'}/>
                                <img className='happiness-face scale4' src={scale4} alt={'Happiness scale 4'}/>
                                <img className='happiness-face scale3' src={scale3} alt={'Happiness scale 3'}/>
                                <img className='happiness-face scale2' src={scale2} alt={'Happiness scale 2'}/>
                                <img className='happiness-face scale1' src={scale1} alt={'Happiness scale 1'}/>

                            </div>
                            :
                            <p style={{textAlign:'center'}}><LoaderIcon/></p>
                        }
                        <div className='intervals-wrapper'>
                            <div>
                                <DateTime
                                    dateFormat={'YYYY-MM-DD'}
                                    timeFormat={false}
                                    value={this.state.fromDate}
                                    onChange={(fromDate)=>this.setState({fromDate}, this.loadReport)}
                                />
                                Fecha de inicio
                            </div>
                            <p>
                                <button className='left' onClick={this.decreaseInterval}>-</button>
                                <span className='number-picker'>{this.state.intervalSize}</span>
                                <button className='right'  onClick={this.increaseInterval}>+</button>&nbsp;
                                Días por intervalo
                            </p>
                            <p><button className='left' onClick={this.decreaseBlocks}>-</button>
                                <span className='number-picker'>{this.state.numberOfBlocks}</span>
                                <button className='right'  onClick={this.increaseBlocks}>+</button>&nbsp;
                                Número de intervalos</p>
                        </div>
                    </div>
                    }
                stats={
                    <span className='link'
                          onClick={this.toggleCheckTypes}
                    >
                        <i className={'fa '+ ( this.state.checkTypes?'fa-eye-slash':'fa-eye') } />
                        Entrada y salida
                    </span>
                }
            />

        );
    }
}

const mapStateToProps = ( {api:{ me }} )=>({ me });

export default connect(mapStateToProps)(HappinessOverTimeGraph);


const greenPalette = [
    '#00FF00',
    '#7CFC00',
    '#7FFF00',
    '#32CD32',
    '#228B22',
    '#008000',
    '#006400',
    '#ADFF2F',
    '#9ACD32',
    '#00FF7F',
    '#00FA9A',
    '#90EE90',
    '#98FB98',
    '#8FBC8F',
    '#3CB371',
    '#20B2AA',
    '#2E8B57',
    '#808000',
    '#556B2F',
    '#6B8E23',
    ];

const redPalette = [
    '#FF0000',
    '#FFA07A',
    '#FA8072',
    '#E9967A',
    '#F08080',
    '#CD5C5C',
    '#DC143C',
    '#B22222',
    '#8B0000',
    '#800000',
    '#FF6347',
    '#FF4500',
    '#db697e',

]