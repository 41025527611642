import _ from 'lodash';
import {stockMovementBatchStatus} from "./stockMovementBatchUtils";
import join from 'url-join';
import moment from "moment";
import qs from "qs";

export const stockRequestStatus={
    PENDING_APPROVAL:'pending_approval',
    PENDING_RECEPTION:'pending_reception',
    PENDING_SHIPPING:'pending_shipping',
    PENDING:'pending',
    COMPLETED:'completed',
    CANCELED:'canceled',
    IN_TRANSIT:'in_transit',
    ERRORED:'errored',
    RECEIVED_BUT_NOT_SENT:'received_but_not_sent',
};

export const stockRequestStatusTrans={
    'pending':'Pendiente',
    [stockRequestStatus.PENDING_APPROVAL]:'Pendiente de aprobación',
    [stockRequestStatus.PENDING_RECEPTION]:'Pendiente de recepción',
    [stockRequestStatus.PENDING_SHIPPING]:'Pendiente de envío',
    [stockRequestStatus.COMPLETED]:'Completado',
    [stockRequestStatus.CANCELED]:'Cancelado',
    [stockRequestStatus.IN_TRANSIT]:'En tránsito',
    [stockRequestStatus.ERRORED]:'Con error',
    [stockRequestStatus.RECEIVED_BUT_NOT_SENT]:'Recibida sin salida',
};

/**
 *
 * @param stockRequest
 * @returns {*[]|{inMovements: *[], outMovements: *[]}}
 */
export const splitMovementsByDirectionFromStockRequest = ( stockRequest )=>{

    if( !stockRequest?.stockMovementBatches?.length )
        return [];

    const [inMovements, outMovements]=stockRequest.stockMovementBatches.reduce( (acc, batch)=>
            batch.inOrOut==='in'?
                [[...acc[0], ...batch.movements], acc[1]]:
                [ acc[0], [...acc[1], ...batch.movements]]
        ,[[],[]]);

    return { inMovements, outMovements };
}

/**
 * It gets a stockRequest and returns an array of stockMovements.
 * It merges the in and out movements found in all the related stockMovementBatches
 * It takes the "out" movements as a base and modifies their status property depending on
 * the corresponding "in" movement's status (the one with the same ProductBrand)
 *
 * The rules are as follows:
 * Out: pending In: pending Then: pending
 * Out: completed In: pending Then: in_transit
 * Out: completed In: completed Then: completed
 * Out: pending In: completed Then: errored
 * Out: canceled In: pending Then: canceled
 * Out: canceled In: completed Then: errored
 * Out: completed In: canceled Then: canceled
 *
 * @param stockRequest
 * @returns {Array|*}
 */
export const getMovementsSummaryFromStockRequest=(stockRequest)=>{
    if(!stockRequest || !stockRequest.stockMovementBatches || !stockRequest.stockMovementBatches.length)
        return [];

    //Get all the movements of all the batches, joined in an out and in arrays correspondingly
    const [inMovements, outMovements]=stockRequest.stockMovementBatches.reduce( (acc, batch)=>
        batch.inOrOut==='in'?
             [[...acc[0], ...batch.movements], acc[1]]:
             [ acc[0], [...acc[1], ...batch.movements]]
    ,[[],[]]);

    const foundProductBrands={};
    let movements = outMovements.map( move=>{
        const outMovement={...move};
        const previousIndex=foundProductBrands[outMovement.productBrand.id];
        //Previous index was saved in case we have 2 different movement pairs with the same productBrand (e.g. a batch was split to be sent at two moments)
        //In that case, we want to match the second out movement of this product with the second in movement
        //So we start searching from the index it was found the first appearance
        const inMovementIndex=_.findIndex(
            inMovements,
            inMove=>inMove.productBrand.id===outMovement.productBrand.id,
            typeof previousIndex!=='undefined'?previousIndex+1:0);

        if(inMovementIndex===-1)
            return outMovement;

        const inMovement=inMovements[inMovementIndex];
        foundProductBrands[inMovement.productBrand.id]=inMovementIndex;

        if(outMovement.status===inMovement.status)
            return outMovement;
        else if(outMovement.status===stockRequestStatus.COMPLETED && inMovement.status===stockRequestStatus.PENDING)
            outMovement.status=stockRequestStatus.IN_TRANSIT;
        else if(outMovement.status===stockRequestStatus.PENDING && inMovement.status===stockRequestStatus.COMPLETED)
            outMovement.status=stockRequestStatus.RECEIVED_BUT_NOT_SENT;
        else if(outMovement.status===stockRequestStatus.CANCELED && inMovement.status===stockRequestStatus.PENDING)
            outMovement.status=stockRequestStatus.CANCELED;
        else if(outMovement.status===stockRequestStatus.CANCELED && inMovement.status===stockRequestStatus.COMPLETED)
            outMovement.status=stockRequestStatus.ERRORED;
        else if(outMovement.status===stockRequestStatus.COMPLETED && inMovement.status===stockRequestStatus.CANCELED)
            outMovement.status=stockRequestStatus.CANCELED;
        return outMovement;
    });

    movements = _.sortBy(movements, 'productBrand.product.name');

    return movements;
};

export const isStockRequestReceivable = (stockRequest) => {
    if (!stockRequest)
        return false;

    if (!stockRequest.stockMovementBatches || !stockRequest.stockMovementBatches.length)
        return false;

    const stockMovementsIn = _.filter(stockRequest.stockMovementBatches, (stockMovement) => stockMovement.inOrOut === 'in' && stockMovement.status === 'pending');
    if (!stockMovementsIn || !stockMovementsIn.length)
        return false;

    return true;
}

export const isStockRequestCancellable = (stockRequest)=>{
    if(!stockRequest)
        return false;
    if(stockRequest.status === stockRequestStatus.PENDING_APPROVAL)
        return true;
    if(!stockRequest.stockMovementBatches || !stockRequest.stockMovementBatches.length)
        return false;
    const batches  = stockRequest.stockMovementBatches;
    for( let i=0; i<batches.length; i++ ){
        if(batches[i].status !== stockMovementBatchStatus.PENDING && batches[i].status !== stockMovementBatchStatus.CANCELED )
            return false;
    }

    return true;
}

export const getAllBatchesStatusIfTheyAreTheSame=(stockRequest)=>{
    if(!stockRequest || !stockRequest.stockMovementBatches || !stockRequest.stockMovementBatches.length)
        return null;

    let status=stockRequest.stockMovementBatches[0].status;
    _.forEach(stockRequest.stockMovementBatches, smb=>{
        if(smb.status!==status)
            status=null;
    });
    return status;
};

export const isAnyMovementCompleted=(stockRequest)=>{
    if(!stockRequest || !stockRequest.stockMovementBatches || !stockRequest.stockMovementBatches.length)
        return false;

    for( let i=0;i<stockRequest.stockMovementBatches.length;i++)
        if(stockRequest.stockMovementBatches[i].status === stockMovementBatchStatus.COMPLETED)
            return true;

    return false;
};

export const createExportStockRequestsUrl = ( api, filters )=>{

    if(!api.host) return '#';

    const reportConfig = {
        name: `Requisiciones de almacén ${moment().format('YYYY-MM-DD HH:mm')}.xlsx`,
        headers: {
            'folio': 'No. de orden',
            'createdBy.employee.fullName': 'Solicitante',
            'toPlace.name': 'Entregar a',
            'status':'Estado',
            'createdDate': 'Fecha de creación',
            'requestedForDate': 'Fecha requerida de entrega',
            'productsAsString': 'Productos',

        },
        transStrings: stockRequestStatusTrans
    };

    const sGroups = filters?.sGroups || [];
    sGroups.push("stock_request_read_products_as_string");

    const query = qs.stringify({
            sGroups,
            ...filters,
            token: api.token,
            pagination: false,
            reportConfig: JSON.stringify(reportConfig)
        },
        { arrayFormat: 'brackets' });

    return join(api.host, 'api','stock_requests.xlsx')+`?${query}`;
}

export const detailSerializationGroups=[
    "stock_request_read",
    "stock_request_read_smb",
    "stock_movement_batch_read_movements",
    "stock_movement_batch_read_in_or_out",
    "stock_movement_read_product_brand",
    "warehouse_read",
    "user_read_employee",
    "employee_simplified",
    "product_brand_read_brand",
    "product_brand_read_product",
    "brand_read_name",
    "product_read_name",
    "place_read",
    "user_read_id",
    "uc_rule_read",
    "mu_read",
    "cassette_read",
    "product_brand_read_id",
    "stock_request_read_stock_request_approval",
    "stock_request_approval_read",
    "stock_request_approval_read_employee",
    "product_simple_read",
    "stock_request_read_from_warehouse",
    "sm_read",
    "smb_read",
];
