import React, {useState, useContext} from 'react';
import {Modal, Table} from 'react-bootstrap';
import Button from "../../../../elements/CustomButton/CustomButton";
import {TideApiContext} from "../../../../api/tideApiConfig";
import {NotifierContext} from "../../../../utils/Notifier";
import CustomCheckbox from "../../../../elements/CustomCheckbox/CustomCheckbox";
import {useSelector} from "react-redux";

const JazzAreaFormModal = ({ onHide, area:newArea, reload}) => {
    const loadingId = '@Class.AddJazzAreaModal.jazzAreas.create';
    const loading = useSelector(s => !!s.loadingIds[loadingId]);

    const [checkboxValue, setCheckboxValue] = useState(newArea ? newArea.budgetIsAccumulated : false);
    const [automaticBudget, setAutomaticBudget] = useState(newArea ? newArea.automaticBudget : false);

    const [area, setArea] = useState( {
        name: newArea?newArea.name:'',
        monthlyAssignedBudget: newArea?newArea.monthlyAssignedBudget:''
    });

    const onPropertyChange = ({target}) => {
        const newArea = {...area, [target.name]: target.value};
        setArea(newArea);
    };

    const handleCheckboxChange = () => {
        setCheckboxValue(!checkboxValue);
    };

    const automaticBudgetRequestChange = () => {
        setAutomaticBudget(!automaticBudget);
    }

    const tideApi = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);

    const saveArea = ()=>{

        if( !area.name  )
            return notifier.error('No has llenado todos los campos');

        if(newArea)
            tideApi.jazzAreas.update( {
                id: newArea.id, params: {
                    name:area.name, monthlyAssignedBudget: Number(area.monthlyAssignedBudget), budgetIsAccumulated: checkboxValue, automaticBudget
                }, loadingId} ).then(() => {
                onHide();
                reload();
            });
        else
            tideApi.jazzAreas.create({params: {
                    name: area.name, monthlyAssignedBudget: Number(area.monthlyAssignedBudget), budgetIsAccumulated: checkboxValue, automaticBudget
                }, loadingId}).then(() => {
                onHide();
                reload();
            });
    };

    return (
        <Modal show={true} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Nueva área</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form autoComplete='off'>
                    <Table>
                        <tbody>
                        <tr>
                            <td>Nombre</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='name'
                                    required={true}
                                    value={area.name}
                                    onChange={onPropertyChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Presupuesto</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='monthlyAssignedBudget'
                                    type='number'
                                    required={true}
                                    value={Number(area.monthlyAssignedBudget)}
                                    onChange={onPropertyChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Es Acumulable</td>
                            <td>
                                <CustomCheckbox
                                    isChecked={checkboxValue}
                                    onChange={handleCheckboxChange}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Presupuesto automático</td>
                            <td>
                                <CustomCheckbox
                                    isChecked={automaticBudget}
                                    onChange={automaticBudgetRequestChange}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </form>

            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle='danger' disbled={loading} className='pull-left' onClick={onHide}>Cancelar</Button>
                <Button bsStyle='success' disabled={loading} onClick={saveArea}>Guardar</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default JazzAreaFormModal;