import React,{useCallback} from 'react';
import Slider from 'rc-slider';
import {maxDecimals} from "../../../../utils/MathUtils";
import useBoolean from "../../../../hooks/useBoolean";
import InputConfigTool from "./elementToolboxes/InputConfigTool";

const MIN_ZOOM=0;
const MAX_ZOOM=5;
const StageTools = ({loungeMap, onLoungeMapChange, viewPort, onViewPortChange})=>{

    const handleZoomChange = useCallback((zoom)=>{
        onViewPortChange({...viewPort, zoom});
    },[viewPort, onViewPortChange]);

    const [isOpen,,, toggleOpen] = useBoolean();

    return (
        <div className='StageTools'>
            <h5 onClick={toggleOpen} className='toolbox-title'>Mapa <i className={'fa fa-caret-'+(isOpen?'up':'down')}/></h5>

            {isOpen &&
            <div className='toolbox'>
                <InputConfigTool loungeMapItem={loungeMap} onChange={onLoungeMapChange} field='width' displayName='Ancho' type='number'/>
                <InputConfigTool loungeMapItem={loungeMap} onChange={onLoungeMapChange} field='height' displayName='Alto' type='number'/>
                <span className='toolbox-label'>Posición:</span>
                <span className='toolbox-value'>{maxDecimals(viewPort.x,2)}, {maxDecimals(viewPort.y,2)}</span> <br/>
                <span className='toolbox-label'>Zoom:</span> <span className='toolbox-value'>{viewPort.zoom}</span>
                <Slider min={MIN_ZOOM} max={MAX_ZOOM} value={viewPort.zoom} onChange={handleZoomChange} step={0.1}/>
            </div>}
        </div>
    );
};

export default StageTools;
