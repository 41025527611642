import React, {useContext, useEffect, useState, useCallback} from 'react';
import {Modal, Table, Button} from "react-bootstrap";
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import EntitySelect from "../../../../components/EntitySelect/EntitySelect";
import {prepareUserForServer, operationConstants} from "../../../../utils/modelUtils/alma/userPermissionGroupsUtils";


const filters = {pagination: false, sGroups: ['user_minimal', 'employee_simple', 'permission_group_read', 'permission_group_read_permissions','permission_read']};
const permissionGroupsLoadingId = "UserPermissionGroups.get";
const userLoadingId = "UserPermissionGroups.put";

const groupLabel = (entity) => {
    return entity.name + (entity.systemName ? ' - ' + entity.systemName.toUpperCase() : '');
};

function UserPermissionGroups() {
    const api = useContext(ApiContext);
    const users = useSelector(({api}) => api['userPermissionGroups']) || [];
    const permissionGroupsLoading = useSelector(({loadingIds}) => loadingIds[permissionGroupsLoadingId]);
    const usersLoading = useSelector(({loadingIds}) => loadingIds[userLoadingId]);
    const [editingUser, setEditingUser] = useState(null);
    const [group, setGroup] = useState(null);

    useEffect(() => {
        api.users.get(0, 0, permissionGroupsLoadingId, filters, 'userPermissionGroups', true);
    }, [api]);

    const addGroup = useCallback(() => {
        const preparedData = prepareUserForServer(editingUser, group, operationConstants.ADD_GROUP);
        api.users.edit(editingUser.id, preparedData, userLoadingId,null, 'userPermissionGroups').then((user) => {
            setEditingUser(user)
        });
    }, [editingUser, group, api]);

    const removeGroup = (user, group) => {
        const preparedData = prepareUserForServer(user, group, operationConstants.REMOVE_GROUP);
        api.users.edit(user.id, preparedData, userLoadingId, null,'userPermissionGroups');
    };

    return (
        <div className='UserPermissionGroups container-fluid'>
            {permissionGroupsLoading ? <h2>Cargando...</h2> : null}

            {users.length > 0 &&
            <>
                <h2>Grupos de permisos de usuarios</h2>

                <Table striped className={'vertical-responsive-table '}>
                    <thead>
                    <tr>
                        <th>Id</th>
                        <th>Usuario</th>
                        <th>Nombre</th>
                        <th>Grupos</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user, i) =>
                        <tr key={user.id} onClick={() => {
                            setEditingUser(user)
                        }}>
                            <td data-label='id'>{user.id}</td>
                            <td data-label='username'>{user.username}</td>
                            <td data-label='Nombre'>{user.employee ? user.employee.fullName : ""}</td>
                            <td data-label='Grupos'>
                                {user.permissionGroups.map((permissionGroup) => (
                                    <span key={permissionGroup.id}
                                          title={permissionGroup.description}
                                          className={"label label-info"}>{permissionGroup.name}
                                        <i
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                removeGroup(user, permissionGroup)
                                            }}
                                            className={"delete fa fa-times"}
                                        />
                                    </span>
                                ))}
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </>}

            {editingUser ?
                <Modal show={true} onHide={() => setEditingUser(null)} bsSize='lg'>
                    <Modal.Header closeButton>
                        <Modal.Title>Agregar grupo de permisos
                            a {editingUser.employee ? editingUser.employee.fullName : editingUser.username}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EntitySelect
                            entity={'permissionGroups'}
                            getMethod={'get'}
                            value={group}
                            onChange={setGroup}
                            maxResults={1000}
                            labelCreator={groupLabel}
                            filterBy='search'
                            placeholder={'Selecciona un grupo ...'}
                        />
                        <br/>

                        <p>{group?group.description:null}</p>
                        <Button bsStyle="success" disabled={usersLoading} onClick={addGroup}>Agregar</Button>
                    </Modal.Body>
                </Modal> : null
            }
        </div>
    )
}


export default UserPermissionGroups;
