import React from 'react';

const colors = [
    "#050708",
    "#0f1b21",
    "#0f2c3b",
    "#2c4552"
];

const bootstrapClass = n => `col-md-${parseInt(12/n)}`;

const LoungeCurrentOcupancy = ({lounge, nLounge, totalLounges}) => {
    const {name, loungeMap} = lounge;
    const color = colors[nLounge%colors.length];
    const style={backgroundColor: color};
    const className =
          bootstrapClass(totalLounges);
    return (
        <div className={className} >
            <div className='LoungeCurrentOcupancy' style={style}>
            <h4 className='text-center'>
                {name}
            </h4>
            {loungeMap&&(
                <h1 className='text-center'>
                    {Math.max(0, loungeMap.occupiedSeats)}/{loungeMap.totalSeats}
                </h1>
            )}
            </div>
        </div>
    );
};

export default LoungeCurrentOcupancy;
