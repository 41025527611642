import React, {useContext, useMemo, useState, useEffect, useCallback} from 'react';
import {ApiContext} from "../../../api/Api";
import _ from "lodash";
import {connect} from "react-redux";
import {Col, Row, Tab, Tabs} from "react-bootstrap";
import Card from "../../../components/Card/Card";
import PurchaseRequisitionHeader from "./purchaseRequisitionComponents/PurchaseRequisitionHeader";
import PurchaseRequisitionQuoter from "./purchaseRequisitionComponents/PurchaseRequisitionQuoter";
import PurchaseRequisitionDetail from "./purchaseRequisitionComponents/PurchaseRequisitionDetail";
import PurchaseRequisitionApprovals from "./purchaseRequisitionComponents/PurchaseRequisitionApprovals";
import CommentsFeed from "../../../components/CommentsFeed/CommentsFeed";
import {SecurityContext} from "../../../utils/SecurityManager";
import moment from "moment";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import {
    getProductsFromStockRequest,
    purchaseRequisitionProcessSGroups,
} from "../../../utils/modelUtils/jazz/purchaseRequisitionUtils";
import useBoolean from "../../../hooks/useBoolean";
import ConfirmModal from "../../../components/Modals/ConfirmModal";

const PurchaseRequisitionProcess = ( {match, purchaseRequisitions} ) => {
    const editLoadingId = '@PurchaseRequisitionDetail.purchaseRequisitions.edit';
    const api = useContext(ApiContext);
    const security = useContext(SecurityContext);
    const id = match.params.id;

    const [notFound, setNotFound] = useState(false);
    const [ promptCancel, setPromptCancelTrue, setPromptCancelFalse] = useBoolean();

    const purchaseRequisition = _.find(purchaseRequisitions, (pr) => pr.id === id);

    const loadPR = useCallback(()=>{
        api.purchaseRequisitions.get(0, 10, undefined, {id, sGroups:purchaseRequisitionProcessSGroups})
            .then(reqs => {
                if (!reqs || !reqs.length || !_.find(reqs, (pr) => pr.id === id))
                    setNotFound(true);
            })
    },[id, api]);

    useEffect(() => {
        loadPR();
    }, [loadPR]);

    const markAsReceivedCreator = useCallbackCreator((index)=>{
        const po = purchaseRequisition.purchaseOrders[index];
        api.purchaseOrders.edit({id:po.id, purchaseOrder:{receivedDate:moment().format('YYYY-MM-DDTHH:mm')}})
            .then(loadPR);

    },[api, purchaseRequisition, loadPR]);

    const pr = purchaseRequisition;

    const cancelRequisition = useCallback(()=>{
        setPromptCancelFalse();
        api.purchaseRequisitions.cancel( {id:pr.id, loadingId:editLoadingId} )
    },[api, pr, setPromptCancelFalse]);


    const stockRequestProducts = useMemo(()=>getProductsFromStockRequest(pr), [pr]);

    return (
        <div className="container-fluid main-container PurchaseRequisitionProcess">
            <Row>
                <Col xs={12}>
                    <Card
                        title={ <PurchaseRequisitionHeader purchaseRequisition={pr} /> }
                        category=" "
                        content={
                            !pr ?
                                (notFound ?
                                    <h3 className='text-center'>Requisición no encontrada</h3> :
                                    <h3 className='text-center'>Cargando...</h3>
                                ):

                                <div>

                                    <Tabs defaultActiveKey="request" id="uncontrolled-tab-example">
                                        <Tab eventKey="request" title="Solicitud">
                                            { pr.status !== 'canceled' && security.jazz.canCancelRequisition(pr) &&
                                            <div>
                                            <CustomButton bsStyle='danger' className='bottom-btn right-btn' onClick={setPromptCancelTrue}>Cancelar requisición</CustomButton>
                                            <hr/>
                                            </div>
                                            }
                                            {pr.status === 'requested' && security.jazz.canQuote()?
                                                <PurchaseRequisitionQuoter
                                                    purchaseRequisition={ pr }
                                                />
                                                :
                                                <PurchaseRequisitionDetail
                                                    purchaseRequisition={ pr }
						    stockRequestProducts={stockRequestProducts}
                                                />
                                            }
                                            { pr.status !== 'requested'?
                                                <PurchaseRequisitionApprovals
                                                    purchaseRequisition={pr}
                                                    />
                                                :null}

                                            <CommentsFeed
                                                title="Comentarios y archivos"
                                                cassetteId={pr.cassette?pr.cassette.id:null}
                                            />

                                        </Tab>

                                        {/* Show PurchaseOrders if the requisition is already approved */}
                                        { pr.purchaseOrders && pr.purchaseOrders.length ?
                                            <Tab eventKey="orders" title="Órdenes de compra">
                                                <>
                                                    {pr.purchaseOrders.map((po, index) =>
                                                        <div className='panel' key={po.id} >
                                                            <h5>Orden de compra <b>#{po.folio}</b></h5>
                                                            {po.receivedDate?
                                                                <span>Fecha de recepción <strong>
                                                                    {moment(po.receivedDate).format("DD/MM/YYYY HH:mm")}</strong>
                                                                </span>
                                                                :
                                                                security.jazz.canMarkPOAsReceived({...po, purchaseRequisition: pr})
                                                                    &&po.status!=='canceled'?
                                                                    <CustomButton onClick={markAsReceivedCreator(index)}>Marcar como recibida</CustomButton>
                                                                    :
                                                                    <span>La orden no ha sido recibida</span>
                                                            }
                                                            <PurchaseRequisitionDetail
								purchaseRequisition={ pr }
								stockRequestProducts={stockRequestProducts}
							    />
                                                        </div>
                                                    )}
                                                    <hr/>
                                                </>

                                            </Tab> : null
                                        }

                                    </Tabs>


                                </div>
                        }
                    />
                </Col>
            </Row>
            {promptCancel&&
            <ConfirmModal
                title='Cancelar requisición de comprar'
                message={<span>¿Estás seguro que deseas cancelar la requisición de compra número <strong>{pr.id}</strong> ?</span>}
                onCancel={setPromptCancelFalse}
                onConfirm={cancelRequisition}
                cancelButtonText='No hacer nada'
                continueButtonText='Sí, cancelar requisición'

            />}
        </div>
    );

};

const mapStateToProps = ({api: {me, purchaseRequisitions = []}}) => ({me, purchaseRequisitions});

export default connect(mapStateToProps)(PurchaseRequisitionProcess);
