import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Card from '../../../../components/Card/Card';
import ReactEcharts from 'echarts-for-react';
import {connect} from 'react-redux';


class CPayrollDetailGraph extends Component {

    getPayrollStatsOptions = () => {

        const payrolls = [ ...this.props.payrolls ];
        payrolls.reverse();

        const data = payrolls.reduce( (acc, cur)=>{

            acc.labels.push( cur.period );
            acc.totals.push( cur.total/100 );
            acc.salaries.push( cur.salary/100 );
            acc.bonuses.push( cur.totalBonus/100 );
            acc.discounts.push( cur.totalDiscount/100 );

            return acc;

        },{labels:[],totals:[],salaries:[],bonuses:[],discounts:[]});

        return {
            backgroundColor: '#f5f5f5',
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['Sueldos','Bonos','Descuentos','Total']
            },
            grid: {
                left: '3%',
                right: '6%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: data.labels
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: 'Total',
                    type: 'line',
                    data: data.totals
                },
                {
                    name: 'Sueldos',
                    type: 'line',
                    data: data.salaries
                },
                {
                    name: 'Bonos',
                    type: 'line',
                    data: data.bonuses
                },
                {
                    name: 'Descuentos',
                    type: 'line',
                    data: data.discounts
                },
            ],
            textStyle: {color:"rgba(20, 20, 20, 1)"}
        }
    };

    render() {

        return (
            <Card
                title={<span>Nómina quincenal</span>}
                category="Últimas 6 quincenas"
                content={
                    <ReactEcharts
                        option={this.getPayrollStatsOptions()}
                        style={{height:"460px"}}
                    />
                }
                stats={
                    <div>
                        <NavLink to="/alma/payroll/list"><i className="fa fa-clock-o" /> Ir a historial</NavLink>
                    </div>
                }
            />

        );
    }
}

const mapStateToProps = ( { api:{ payrolls=[] } } )=>{
    return {payrolls}
};

export default connect(mapStateToProps)(CPayrollDetailGraph);