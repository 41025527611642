import _ from 'lodash';
import React from 'react';
import { stockRequestStatus, stockRequestStatusTrans } from 'utils/modelUtils/almazen/stockRequestUtils';

const ReactTableStatusFilter = ({ filter, onChange }) => {

  const options = [
    { value: stockRequestStatus.PENDING_APPROVAL, option: stockRequestStatusTrans[stockRequestStatus.PENDING_APPROVAL] },
    { value: stockRequestStatus.PENDING_RECEPTION, option: stockRequestStatusTrans[stockRequestStatus.PENDING_RECEPTION] },
    { value: stockRequestStatus.PENDING_SHIPPING, option: stockRequestStatusTrans[stockRequestStatus.PENDING_SHIPPING] },
    { value: stockRequestStatus.PENDING, option: stockRequestStatusTrans[stockRequestStatus.PENDING] },
    { value: stockRequestStatus.COMPLETED, option: stockRequestStatusTrans[stockRequestStatus.COMPLETED] },
    { value: stockRequestStatus.CANCELED, option: stockRequestStatusTrans[stockRequestStatus.CANCELED] },
    { value: stockRequestStatus.IN_TRANSIT, option: stockRequestStatusTrans[stockRequestStatus.IN_TRANSIT] },
    { value: stockRequestStatus.ERRORED, option: stockRequestStatusTrans[stockRequestStatus.ERRORED] },
    { value: stockRequestStatus.RECEIVED_BUT_NOT_SENT, option: stockRequestStatusTrans[stockRequestStatus.RECEIVED_BUT_NOT_SENT] },
  ];

  return (
    <div className="ReactTableStatusFilter">
      <select className='form-control border border-info' value={filter?.value || ''} onChange={(e) => onChange(e.target.value)}>
          <option value='' disabled>Filtrar</option>
          { _.map(options, ( option, index ) =>
              <option key={index} value={option.value}>{option.option}</option>
          ) }
      </select>
    </div>
  );
}
 
export default ReactTableStatusFilter;