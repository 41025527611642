import React, {Component} from 'react';
import Justifications from '../../Manager/Justifications';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {employeeFullname} from '../../../../api/Api';
import {UserDetailApiProp, UserDetailLoadingId} from "../core/UserDetail";
import withSecurityManager from "../../../../utils/SecurityManager/withSecurityManager";

class EmployeeJustifications extends Component {

    static contextTypes = { api: PropTypes.object };

    constructor(props, context){
        super(props);

        context.api.clearProperty( 'singleEmployee' );

        if( props.match.params.id ) {
            this.employeeId = props.match.params.id;
            this.isMe = false;
            context.api.employees.getSingle(this.employeeId);
        }
        else {
            this.employeeId = props.me.employee.id;
            this.isMe = true;
        }

        this.showUserDetail = this.showUserDetail.bind(this);
    }

    componentWillUnmount(){
        this.context.api.clearProperty( 'singleEmployee' );
    }

    showUserDetail( ){
        this.context.api.users.get(0,1,UserDetailLoadingId,{employee:this.props.singleEmployee.id},UserDetailApiProp );
    }

    render(){

        let employee;
        if( this.isMe )
            employee = this.props.me.employee;
        else
            employee = this.props.singleEmployee;

        return(
            <div className="EmployeeJustifications">
                {!this.isMe && this.props.singleEmployee?
                    <h4>Justificaciones de <span className="span-but" onClick={this.showUserDetail}>{employeeFullname(this.props.singleEmployee)}</span></h4>:null
                }
                {employee?
                <Justifications
                    employee={employee}
                    canApprove={this.props.securityManager.alma.canApproveJustifications(this.isMe)}
                    showNames={false}
                    showGroups={false}
                    canDelete={ employee.id === this.props.me.employee.id }
                />:null}
            </div>
        );

    }
}

function mapStateToProps( {api:{me = {}, singleEmployee }} ) {

    return {me, singleEmployee}
}
export default connect(mapStateToProps)(withSecurityManager(EmployeeJustifications));
