import moment from "moment";
import {displayDateTimeFormat} from "../../timeUtils";

export const airportGateListColumns = [
    {
        Header: "Número de puerta",
        accessor: "gateNumber"
    },
    {
        Header: "Fecha de creación",
        accessor: ({createdDate})=> createdDate? moment(createdDate).format(displayDateTimeFormat):"Sin información",
        id: "createdDate",
        filterable: false
    }
];


export const airportGateFormFields = [
    { label: "Número de puerta", name: "gateNumber" }
];

export const airportGateWithLoungesSGroups = [
    "airport_gate_read",
    "airport_gate_read_airport_gate_lounges",
        "airport_gate_lounge_read",
        "airport_gate_lounge_read_lounge",
            "place_read_id",
];
