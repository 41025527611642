import React from 'react';
import useTideTable from '../../../hooks/table/useTideTable';
import ReactTable from 'react-table';

const columns = [
    {
        Header: 'Empleado',
        accessor: 'employee.name'
    },
    {
        Header: 'Puntos dados',
        accessor: 'givenRecognitionPoints'
    },
    {
        Header: 'Puntos recibidos',
        accessor: 'earnedRecognitionPoints',
    },
    {
        Header: 'Puntos usados',
        accessor: 'exchangePoints'
    }
];

const RewardsStats = ()=>{
    const {
        tableProps
    } = useTideTable({
        entity: 'pointsWallets',
        columns
    });
    return (
        <div className='Rewards admin'>
            <h2>Colaboradores</h2>
            <ReactTable {...tableProps}/>
        </div>
    );
};

export default RewardsStats;
