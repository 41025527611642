import React, {useCallback, useMemo} from 'react';
import ReactEcharts from "echarts-for-react";
import _ from 'lodash/fp';

const ranges = [
    {name: 'Bueno', lowerLimit: 9, color: '#4FD948'},
    {name: 'Regular', lowerLimit: 7, color: '#FFB13D'},
    {name: 'Malo', color: '#A10702'}
];

const getSeriesData = groupedData => k => 
      _.map(gs=>!gs[k]?0:_.sumBy('count', gs[k])
      , groupedData);

const getSeries = groupedData => {
    const getData = getSeriesData(groupedData);
    const indexedRanges = _.zip(_.range(0, ranges.length),ranges);
    return _.map(([k,r])=>({
        name: r.name,
        type: 'bar',
        stack: 'x',
        itemStyle:{color: r.color},
        data: getData(k)
    }), indexedRanges);
};

const getOption = ({groupedData, title}) => ({
    tooltip: {
        trigger: 'axis',
        axisPointer: {type: 'shadow'}
    },
    title:{text:title},
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: [
        {
            type: 'category',
            data: _.keys(groupedData)
        }
    ],
    yAxis: [{type: 'value'}],
    series: getSeries(groupedData)
});



const getQuestionValue = ({response, options, optionsValues})=>{
    if(!optionsValues[response]){
        return null;
    }
    return optionsValues[response];
};
const mapQuestionValue = v=>({
    ...v,
    value:getQuestionValue(v),
    count: parseInt(v.count)
});
const hasValue = v=>getQuestionValue(v)!==null;
const parseOptions = v=>({
    ...v,
    options:JSON.parse(v.options),
    optionsValues:JSON.parse(v.optionsValues)
});
const getReportForType = gcareType=>_.filter({gcareType});
const getValues = _.flow(
    _.map(parseOptions),
    _.filter(hasValue),
    _.map(mapQuestionValue)
);

const getRange = ({value, count}) => 
    _.findIndex(({lowerLimit})=>{
        if(!lowerLimit){
            return true;
        }
        return value>=lowerLimit;
    }, ranges);

const TimeGroupedGcareStat = ({report, gcareType, title}) => {
    const getTypeValues = useCallback(_.flow(
        getReportForType(gcareType),
        getValues,
        _.groupBy('date'),
        _.mapValues(_.groupBy(getRange))
    ), [gcareType]);

    const valuesForType = useMemo(
        ()=>getTypeValues(report), [getTypeValues, report]);

    const option = useMemo(
        ()=>getOption({groupedData: valuesForType, title}),
        [valuesForType, title]);
    return <ReactEcharts option={option}/>;
};

export default TimeGroupedGcareStat;
