import React, { Component } from 'react';
import ReactTable from 'react-table';
import moneyFormatter from '../../../../utils/moneyFormatter';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Modal} from 'react-bootstrap';
import ReactTableTranslation from '../../../../utils/ReactTableTranslation';
import PayrollDetailExplanation from "../../Payroll/components/PayrollDetailExplanation";
import {UserDetailApiProp, UserDetailLoadingId} from "../core/UserDetail";
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";


class EmployeePayrolls extends Component{

    static contextTypes = { api:PropTypes.object, notifier: PropTypes.object };

    loadingId = "@Class.PayrollList.get";

    constructor( props, context ){
        super(props);

        this.state = {
            detailOpen:false,
        };

        context.api.clearProperty( 'singleEmployee' );

        if( props.match.params.id ) {
            this.employeeId = props.match.params.id;
            this.isMe = false;
            context.api.employees.getSingle(this.employeeId);
        }
        else {
            this.employeeId = props.me.employee.id;
            this.isMe = true;
        }


        this.setTableStructure( this.payrollId === "actual");

        this.loadData = this.loadData.bind(this);
    }

    componentDidMount(){
        if( this.props.match.params.id === "actual" )
            this.loadData();
    }

    componentWillUnmount(){
        this.context.api.clearProperty( 'singleEmployee' );
    }

    showUserDetail = ( )=>{
        this.context.api.users.get(0,1,UserDetailLoadingId,{employee:this.props.singleEmployee.id},UserDetailApiProp );
    };

    loadData( tableState ){

        const employeeId = this.isMe? this.props.me.employee.id : this.props.match.params.id;
        this.context.api.payrollDetails.get(tableState.page , tableState.pageSize, this.loadingId, {employee: employeeId});
    }

    showInfo( info, index ){

        this.setState({
            detailOpen: true,
            payrollDetail: info,
            payrollDetailIndex:index
        });
    }

    UNSAFE_componentWillReceiveProps( nextProps ){

        if( this.props.match.params.id !== nextProps.match.params.id )
            this.loadData();

    }


    setTableStructure( sortable ) {
        this.tableStructure = [ {
            Header: 'Quincena',
            accessor: 'payroll.period',
            sortable
        },{
            Header: 'Sueldo base',
            accessor: (detail) => moneyFormatter(detail.salary),
            id: "salary",
            sortMethod: (a, b) => (Number(a.replace(/(^\$|,)/g, '')) < Number(b.replace(/(^\$|,)/g, '')) ? 1 : -1),
            sortable
        }, {
            Header: 'Deducciones',
            accessor: (detail) => moneyFormatter(detail.totalDiscount),
            id: "discounts",
            sortMethod: (a, b) => (Number(a.replace(/(^\$|,)/g, '')) < Number(b.replace(/(^\$|,)/g, '')) ? 1 : -1),
            sortable
        }, {
            Header: 'Percepciones',
            accessor: (detail) => moneyFormatter(detail.totalBonus),
            id: "bonus",
            sortMethod: (a, b) => (Number(a.replace(/(^\$|,)/g, '')) < Number(b.replace(/(^\$|,)/g, '')) ? 1 : -1),
            sortable
        }, {
            Header: 'Total',
            accessor: (detail) => moneyFormatter(detail.total),
            id: 'total',
            sortMethod: (a, b) => (Number(a.replace(/(^\$|,)/g, '')) < Number(b.replace(/(^\$|,)/g, '')) ? 1 : -1),
            sortable
        }];
    }

    render(){

        this.payrollId = this.props.match.params.id;

        const {totalItems, itemsPerPage} = this.props.listMeta;
        const config = {
            pages: Math.ceil(totalItems / itemsPerPage),
            onFetchData:this.loadData,
            manual:true
        };

        return(
            <div className="main-container payroll-detail">

                {!this.isMe && this.props.singleEmployee?
                    <h4 className='name-title'>Pagos de <span className="span-but" onClick={this.showUserDetail}>{employeeFullName(this.props.singleEmployee)}</span></h4>:null
                }

                <ReactTable
                    data={this.props.list}
                    columns = {this.tableStructure}
                    defaultPageSize= {10}
                    className="-striped -highlight"
                    loading={!!this.props.loadingIds[this.loadingId]}
                    getTrProps={(state, rowInfo)=>({onClick:()=>{this.showInfo(rowInfo.original, rowInfo.index)}})}
                    {...config}
                    {...ReactTableTranslation}
                />

                {/* Modal to see one employee's payroll detail */}
                {this.state.detailOpen?
                    <Modal show={this.state.detailOpen} bsSize="large" onHide={() => this.setState({detailOpen: false})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Detalle de quincena {this.state.payrollDetail.period}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PayrollDetailExplanation
                                payrollDetail={this.state.payrollDetail}
                                canModify={ false }
                            />
                        </Modal.Body>
                    </Modal>
                    :null}

            </div>
        );
    }

}

function mapStateToProps( {api:{me, payrollDetails=[], payrollDetailsMeta={totalItems:0,itemsPerPage:0}, incidences, calculatePayroll, appliedDiscounts, singleEmployee}, loadingIds } ) {

    return { me, list:payrollDetails, listMeta:payrollDetailsMeta, incidences, loadingIds, calculatePayroll, appliedDiscounts, singleEmployee }
}

export default connect(mapStateToProps )(EmployeePayrolls);
