import React, {useCallback, useContext} from 'react';
import EntitySelect from "../../../../components/EntitySelect/EntitySelect";
import useFormState from "../../../../hooks/useFormState";
import DateTime from "react-datetime";
import {
    employeeFullName,
    userEmployeeNameSearchSGroups
} from "../../../../utils/modelUtils/alma/employeeUtils";
import Switch from "react-bootstrap-switch";
import switchTranslations from "../../../../utils/switchTranslations";
import useCallbackCreator from "../../../../hooks/useCallbackCreator";
import {
    appointmentListSGroups, appointmentToForm,
    emptyAppointment, moveCheckOutHourToNextDay,
    prepareAppointmentForServer
} from "../../../../utils/modelUtils/alma/appointmentUtils";
import {NotifierContext} from "../../../../utils/Notifier";
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import moment from 'moment';
import _ from 'lodash';
import useBoolean from "../../../../hooks/useBoolean";
import ConfirmModal from "../../../../components/Modals/ConfirmModal";
import {SecurityContext} from "../../../../utils/SecurityManager";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import CustomButton from "../../../../elements/CustomButton/CustomButton";


const todayOrAfter = (d) => moment().isSameOrBefore(d, 'day');
const requestSGroups = {sGroups: userEmployeeNameSearchSGroups, isHired: true};
const loadingId = '@AppointmentFormModal.appointments';
const userFullName = (u) => (employeeFullName(u && u.employee) || '');

const AppointmentFormModal = ({onHide, disabled, appointment}) => {

    const {
        form,
        setForm,
        handleInputChange,
        handleSimpleChange
    }
        = useFormState(appointment ? appointmentToForm(appointment) : emptyAppointment());

    const notifier = useContext(NotifierContext);
    const api = useContext(ApiContext);
    const security = useContext(SecurityContext);

    const saveAppointment = useCallback(() => {
        let appointment;
        try {
            appointment = prepareAppointmentForServer(form);
        } catch (e) {
            return notifier.error(e.message);
        }
        api.appointments.create({appointment: {...appointment, sGroups: appointmentListSGroups}, loadingId})
            .then(onHide);
    }, [form, api, notifier, onHide]);

    const [promptDeleteAppointment, showPromptDeleteAppointment, hidePromptDeleteAppointment] = useBoolean();
    const [promptDeactivateAppointment, showPromptDeactivateAppointment, hidePromptDeactivateAppointment] = useBoolean();


    const deleteAppointment = useCallback(() => {
        hidePromptDeleteAppointment();
        api.appointments.delete({id: appointment.id, loadingId})
            .then(onHide);
    }, [api, appointment, onHide, hidePromptDeleteAppointment]);

    const deactivateAppointment = useCallback(() => {
        hidePromptDeactivateAppointment();
        api.appointments.edit({
            id: appointment.id,
            loadingId,
            properties: {sGroups: appointmentListSGroups, deactivationDate: moment().format('YYYY-MM-DDTHH:mm')}
        }).then(onHide);
    }, [api, appointment, onHide, hidePromptDeactivateAppointment]);

    const handleAddDay = useCallback((day, property) => {
        if (_.find(form[property], d => day.isSame(d, 'day')))
            return;
        setForm(form => ({...form, [property]: form[property] ? [...form[property], day] : [day]}));
    }, [setForm, form]);

    const handleAddDayOff = useCallback((day) => {
        handleAddDay(day, 'daysOff');
    }, [handleAddDay]);

    const handleAddDayWithoutChecks = useCallback((day) => {
        handleAddDay(day, 'daysWithoutChecks');
    }, [handleAddDay]);

    const handleRemoveDayOff = useCallbackCreator((index) => {
        const days = [...form.daysOff];
        days.splice(index, 1);
        const newForm = {...form, daysOff: days};
        setForm(newForm);
    }, [form, setForm]);

    const handleRemoveDayWithoutCheck = useCallbackCreator((index) => {
        const days = [...form.daysWithoutChecks];
        days.splice(index, 1);
        const newForm = {...form, daysWithoutChecks: days};
        setForm(newForm);
    }, [form, setForm]);


    const loading = useSelector(({loadingIds}) => !!loadingIds[loadingId]);
    const handleSwitch = useCallbackCreator((name, sw, value) => setForm(form => ({...form, [name]: value})), []);

    return (

        <Modal show onHide={onHide} className={'AppointmentFormModal'} bsSize='lg'>
            <ModalHeader closeButton>
                <ModalTitle>Horario extraordinario</ModalTitle>
            </ModalHeader>

            <ModalBody>
                <div className='container-fluid'>
                    <div className='row'>
                        {appointment && appointment.deactivationDate &&
                        <div className='col-xs-12'>
                            <p className="in-label error-message">Horario desactivado el {moment(appointment.deactivationDate).format('YYYY-MM-DD HH:mm')}</p>
                        </div>
                        }
                        <div className='col-xs-12'>
                            <p className="in-label">Colaboradores a los que aplica</p>
                            <EntitySelect
                                entity={'users'}
                                getMethod={security.alma.canCreateAppointmentsForAll() ? 'get2' : 'getSubordinates'}
                                labelCreator={userFullName}
                                filterBy={'search'}
                                value={form.employees}
                                onChange={handleSimpleChange('employees')}
                                additionalFilters={requestSGroups}
                                multi
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xs-12'>
                            <p className="in-label">Motivo del horario extraordinario</p>
                            <input
                                className='form-control'
                                value={form.reason || ''}
                                placeholder={'Escribe el motivo del cambio de horario...'}
                                onChange={handleInputChange('reason')}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <hr/>
                        <div className='col-xs-12 col-md-6'>
                            <p className="in-label">Requiere registros de comida</p>
                            <Switch
                                {...switchTranslations}
                                value={!!form.hasMealTime}
                                onChange={handleSwitch('hasMealTime')}
                                disabled={disabled}
                            />
                        </div>
                        {form.hasMealTime &&
                        <div className='col-xs-12 col-md-6'>
                            <p className="in-label">Tiempo de comida en minutos</p>
                            <input
                                className='form-control'
                                value={form.mealMinutes || ''}
                                onChange={handleInputChange('mealMinutes')}
                                type='number'
                                disabled={disabled}
                            />
                        </div>}
                    </div>
                    <div className='row'>
                        <hr/>
                        <div className='col-xs-12 col-md-6'>
                            <p className="in-label">Entrada</p>
                            <DateTime
                                dateFormat={false}
                                timeFormat={'HH:mm'}
                                value={form.checkIn}
                                onChange={handleSimpleChange('checkIn')}
                                inputProps={{disabled}}
                            />
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <p className="in-label">Salida {moveCheckOutHourToNextDay(form.checkIn, form.checkOut) && '( día siguiente )'}</p>
                            <DateTime
                                dateFormat={false}
                                timeFormat='HH:mm'
                                value={form.checkOut}
                                onChange={handleSimpleChange('checkOut')}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <hr/>
                        <div className='col-xs-12 col-md-6'>
                            <p className="in-label">Aplicar desde la fecha</p>
                            <DateTime
                                dateFormat={'DD/MM/YYYY'}
                                timeFormat={false}
                                isValidDate={todayOrAfter}
                                value={form.fromDate}
                                onChange={handleSimpleChange('fromDate')}
                                inputProps={{disabled}}
                            />
                        </div>
                        <div className='col-xs-12 col-md-6'>
                            <p className="in-label">Hasta la fecha</p>
                            <DateTime
                                dateFormat={'DD/MM/YYYY'}
                                timeFormat={false}
                                isValidDate={todayOrAfter}
                                value={form.toDate}
                                onChange={handleSimpleChange('toDate')}
                                inputProps={{disabled}}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <hr/>
                        <div className='col-xs-12'>
                            <p className="in-label">Días de descanso</p>
                            <div>{(form.daysOff || []).map((day, i) =>
                                <div key={i} className='day-off' data-property='daysOff'
                                     onClick={disabled ? null : handleRemoveDayOff(i)}>
                                    {moment(day).format('dddd DD/MM/YYYY')} {!disabled && <i className='fa fa-times'/>}
                                </div>
                            )}
                            </div>
                            {disabled && (!form.daysOff || !form.daysOff.length) &&
                            <span className='text-muted'>Sin días de descanso</span>}
                            {!disabled &&
                            <DateTime
                                dateFormat={'DD/MM/YYYY'}
                                timeFormat={false}
                                isValidDate={todayOrAfter}
                                value={''}
                                inputProps={{placeholder: 'Agrega un día de descanso'}}
                                onChange={handleAddDayOff}
                            />}
                        </div>
                    </div>
                    <div className='row'>
                        <hr/>
                        <div className='col-xs-12'>
                            <p className="in-label">Días sin huelleo autorizados</p>
                            <div>{(form.daysWithoutChecks || []).map((day, i) =>
                                <div key={i} className='day-off'
                                     onClick={disabled ? null : handleRemoveDayWithoutCheck(i)}>
                                    {moment(day).format('dddd DD/MM/YYYY')} {!disabled && <i className='fa fa-times'/>}
                                </div>
                            )}
                            </div>
                            {disabled && (!form.daysWithoutChecks || !form.daysWithoutChecks.length) &&
                            <span className='text-muted'>Sin días con autorización de no huelleo</span>}
                            {!disabled &&
                            <DateTime
                                dateFormat={'DD/MM/YYYY'}
                                timeFormat={false}
                                isValidDate={todayOrAfter}
                                value={''}
                                inputProps={{placeholder: 'Agrega un día sin huelleo autorizado'}}
                                onChange={handleAddDayWithoutChecks}
                            />}
                        </div>
                    </div>
                    {promptDeleteAppointment &&
                    <ConfirmModal
                        onCancel={hidePromptDeleteAppointment}
                        onConfirm={deleteAppointment}
                        title={'Eliminar horario extraordinario'}
                        message={'¿Estás seguro que deseas eliminar este horario?'}
                    />}
                    {promptDeactivateAppointment &&
                    <ConfirmModal
                        onCancel={hidePromptDeactivateAppointment}
                        onConfirm={deactivateAppointment}
                        title={'Desactivar horario extraordinario'}
                        message={'¿Estás seguro que deseas desactivar este horario?'}
                    />}
                </div>
            </ModalBody>

            <ModalFooter>
                <CustomButton bsStyle='danger' className='pull-left' onClick={onHide}
                              disabled={loading}>Cerrar</CustomButton>

                {appointment && !appointment.deactivationDate &&
                <CustomButton bsStyle={'danger'} onClick={showPromptDeactivateAppointment} disabled={loading}>
                    Desactivar
                </CustomButton>
                }

                {(security.alma.canCreateAppointments() && (!appointment || moment().isBefore(appointment.fromDate))) &&
                <CustomButton bsStyle={appointment ? 'danger' : 'success'}
                              onClick={appointment ? showPromptDeleteAppointment : saveAppointment} disabled={loading}>
                    {appointment ? 'Eliminar' : 'Guardar'}
                </CustomButton>
                }

            </ModalFooter>
        </Modal>

    );
};

export default AppointmentFormModal;
