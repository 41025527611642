import React, {useContext, createContext, useState, useCallback} from 'react';

const HeaderFooterContext = createContext(null);

export const HeaderFooterContextProvider = ({children}) => {
    const [action, setAction] = useState(() => mobileSidebarToggle);
    const [actionIcon, setActionIcon] = useState('menu');
    const [backAction, setBackAction] = useState('back');
    const [headerTitle, setHeaderTitle] = useState('');
    const [footerClassName, setFooterClassName] = useState('');
    const [headerClassName, setHeaderClassName] = useState('');
    const resetBackAction = useCallback(() => {
        setBackAction('back');
    }, [setBackAction]);
    const resetAction = useCallback(() => {
        setAction(() => mobileSidebarToggle);
    }, [setAction]);
    const resetActionIcon = useCallback(() => {
        setActionIcon('menu');
    }, [setActionIcon]);
    const resetHeaderTitle = useCallback(() => {
        setHeaderTitle('');
    }, [setHeaderTitle]);
    const resetFooterClassName = useCallback(() => {
        setFooterClassName('');
    }, [setFooterClassName]);
    const resetHeaderClassName = useCallback(() => {
        setHeaderClassName('');
    }, [setHeaderClassName]);
    const fullResetAction = useCallback(() => {
        resetAction();
        resetActionIcon();
        resetBackAction();
        resetHeaderTitle();
        resetFooterClassName();
        resetHeaderClassName();
    }, [resetAction, resetHeaderTitle, resetFooterClassName, resetHeaderClassName, resetActionIcon, resetBackAction]);
    const header = {
        action,
        setAction,
        resetAction,
        actionIcon,
        setActionIcon,
        resetActionIcon,
        fullResetAction,
        headerTitle,
        setHeaderTitle,
        footerClassName,
        setFooterClassName,
        headerClassName,
        setHeaderClassName,
        backAction,
        setBackAction,
        resetBackAction
    };
    return (
        <HeaderFooterContext.Provider value={header}>
            {children}
        </HeaderFooterContext.Provider>
    );
};

export const useHeaderFooterHelper = () => {
    const stored = useContext(HeaderFooterContext);
    return stored || {};
};

const mobileSidebarToggle = (e) => {
    document.documentElement.classList.toggle('nav-open');
};
