import React, {useState, useEffect, useCallback, useContext, useMemo} from 'react';
import _ from 'lodash/fp';
import {useSelector} from 'react-redux';
import {ApiContext} from '../../../api/Api';
import ReactEcharts from 'echarts-for-react';
import './EntriesStatsTable.scss';

const turnsDefinition = [
    {
        key: 'morning',
        label: 'Mañana'
    },
    {
        key: 'afternoon',
        label: 'Tarde'
    },
    {
        key: 'night',
        label: 'Noche'
    }
];

const useEntriesStats = (dateRange) => {
    const api = useContext(ApiContext);
    const from = useMemo(
        ()=>dateRange.startDate?
            dateRange.startDate.clone().startOf('day').format('YYYY-MM-DD HH:mm:ss'): undefined, [dateRange]);
    const to = useMemo(
        ()=>dateRange.endDate?
            dateRange.endDate.clone().endOf('day').format('YYYY-MM-DD HH:mm:ss'): undefined, [dateRange]);
    useEffect(()=>{
        api.checkIns.getStats({
            filters: {from, to}
        });
    }, [api, from, to]);
    return useSelector(({api:{checkInStats=[]}})=>checkInStats);
};

const entryFilter = ([name, allEntries, getFilter, getEntryName]) => ({name, allEntries, getFilter, getEntryName});

const EntriesStatsTable = ({lounges: allLounges, loungeAccessMethods: allLoungeAccessMethods, dateRange, statsConfig}) => {
    const stats = useEntriesStats(dateRange);
    const [hideEchart, setHideEchart] = useState(false);
    const filters = useMemo(()=>[
        [
            'lounge',
            allLounges,
            l=>({loungeId: l.id}),
            ({name})=>name,
        ],
        [
            'loungeAccessMethod',
            allLoungeAccessMethods,
            l=>({loungeAccessMethodId: l.id}),
            ({name})=>name,
        ],
        [
            'turn',
            turnsDefinition,
            t=>({turn: t.key}),
            ({label})=>label,
        ]
    ].map(entryFilter), [allLounges, allLoungeAccessMethods]);
    const configuredFilters = useMemo(()=>_.filter(
        ({name})=>_.includes(name, statsConfig),
        filters
    ), [statsConfig, filters]);
    const statFilter = useCallback(config => {
        const namesToApply = _.map('name', config);
        const filterInNames = ({name}) => _.includes(name, namesToApply);
        const filtersToApply = _.filter(filterInNames, configuredFilters);
        const getFilterFn = ({getFilter, name})=>
              _.filter(getFilter((_.find({name}, config)||{}).value));
        const filters = _.map(getFilterFn, filtersToApply);
        return _.reduce(_.flow, _.identity, filters)(stats);
    }, [configuredFilters, stats]);

    const loungeAccessMethods = _.includes('loungeAccessMethod', statsConfig)?
          allLoungeAccessMethods:[{id: 1, name: 'Entradas'}];
    const lounges = _.includes('lounge', statsConfig)?
          allLounges:[{id: 1, name: ''}];
    const turns = _.includes('turn', statsConfig)?
          turnsDefinition:[{id: 1, name: ''}];

    const chartOptions = useMemo(()=>getChartOptions(
        configuredFilters,
        statFilter
    ), [configuredFilters, statFilter]);

    // to prevent an echarts bug when setting option from state
    useEffect(()=>{
        if(chartOptions){
            setHideEchart(true);
            setTimeout(()=>setHideEchart(false), 100);
        }
    }, [chartOptions]);

    const showingTurns = turns.length > 1;
    const total = stats.reduce((x, {adults})=>x+adults, 0);

    const showingLAMTotal = _.includes('loungeAccessMethod', statsConfig);
    const showingLoungeTotal = _.includes('lounge', statsConfig) || _.includes('turn', statsConfig);

    return (
        <>
            <div className='EntriesStatsTable'>
                <table className='table table-hover'>
                    <StatsHeader
                        loungeAccessMethods={loungeAccessMethods}
                        turns={turns}
                        showingLAMTotal={showingLAMTotal}
                    />
                    <tbody>
                        {lounges.map(l=><LoungeRowGroup key={l.id} showingLAMTotal={showingLAMTotal} statFilter={statFilter} lounge={l} loungeAccessMethods={loungeAccessMethods} lounges={lounges} turns={turns}/>)}
                    </tbody>
            {showingLoungeTotal&&
                    <tfoot>
                        <tr>
                            <th colSpan={showingTurns?2:1}>Totales</th>
                            {loungeAccessMethods.map(lam=><LAMTotals key={lam.id} loungeAccessMethod={lam} statFilter={statFilter}/>)}
                            {showingLAMTotal&&<td className={'text-right '+(total===0?'empty':'')}><b>{total}</b></td>}
                        </tr>
                    </tfoot>
            }
                </table>
            </div>
            {(chartOptions&&!hideEchart)&&<ReactEcharts option={chartOptions}/>}
        </>
    );
};

const LAMTotals = ({loungeAccessMethod: lam, statFilter})=>{
    const total = statFilter([{
        name: 'loungeAccessMethod',
        value: lam
    }]).reduce((x, {adults})=>x+adults, 0);
    return (
        <td className={'text-right '+(total===0?'empty':'')}>
            <b>{total}</b>
        </td>
    );
};

const pieChart = (filter, statFilter)=>({
    series: {
        type: 'pie',
        radius: '55%',
        data: filter.allEntries.map(e=>({
            name: filter.getEntryName(e),
            value: statFilter([
                {
                    name: filter.name,
                    value: e
                }
            ]).reduce((x, {adults})=>x+adults, 0)
        }))
    }
});

const lineChart = (filters, statFilter) => ((([turnsFilter, otherFilter])=>({
    xAxis: {
        type: 'category',
        data: _.map('label', turnsFilter.allEntries)
    },
    yAxis: {
        type: 'value'
    },
    series: _.map(e=>({
        name: otherFilter.getEntryName(e),
        label: {show: true},
        type: 'line',
        data: turnsFilter.allEntries.map(t=>statFilter([
            {
                name: 'turn',
                value: t
            },
            {
                name: otherFilter.name,
                value: e
            }
        ]).reduce((s, {adults})=>s+adults, 0))
    }),otherFilter.allEntries)
}))(filters.sort(x=>x.name==='turn'?-1:1)));

const getChartOptions = (configuredFilters, statFilter)=>{
    if(configuredFilters.length===1){
        const filter = configuredFilters[0];
        return pieChart(filter, statFilter);
    }
    const configuredNames = _.map('name', configuredFilters);
    if(configuredFilters.length===2){
        if(_.includes('turn', configuredNames)){
            return lineChart(configuredFilters, statFilter);
        }
    }
    return null;
};

const AccessMethodsCells = ({statFilter, showingTurns, loungeAccessMethods, showingLAMTotal, lounge, turn})=>{
    const value = a => statFilter([
        {name: 'lounge',  value: lounge},
        {name: 'loungeAccessMethod', value: a},
        {name: 'turn', value:turn}
    ]).reduce((x, {adults})=>x+adults, 0);
    const total = statFilter([
        {name: 'lounge',  value: lounge},
        {name: 'turn', value:turn}
    ]).reduce((x, {adults})=>x+adults, 0);
    return (
        <>
            {showingTurns &&
            <th>
                {turn.label}
            </th>
            }
            {loungeAccessMethods.map(a=>(
                <td key={a.id} className={'text-right '+(value(a)===0?'empty':'')}>
                    {value(a)}
                </td>
            ))
            }
            {showingLAMTotal&&
            <td className={'text-right '+(total===0?'empty':'')}>
                <b>{total}</b>
            </td>
            }
        </>
    );
};

const LoungeRowGroup = ({loungeAccessMethods, turns, lounge, statFilter, showingLAMTotal})=>{
    const showingTurns = turns.length>1;
    return (
        <>
            <tr>
                <th rowSpan={turns.length}>
                    {lounge.name}
                </th>
                <AccessMethodsCells showingLAMTotal={showingLAMTotal} statFilter={statFilter} showingTurns={showingTurns} loungeAccessMethods={loungeAccessMethods} lounge={lounge} turn={turns[0]}/>
            </tr>
            {turns.slice(1).map(t=>(
                <tr key={t.key}>
                    <AccessMethodsCells showingLAMTotal={showingLAMTotal} statFilter={statFilter} showingTurns={showingTurns} loungeAccessMethods={loungeAccessMethods} lounge={lounge} turn={t}/>
                </tr>
            ))}
        </>
    );
};

const StatsHeader = ({turns, loungeAccessMethods, showingLAMTotal}) => {
    const showingTurns = turns.length>1;
    return (
        <thead>
            <tr>
                <th colSpan={showingTurns?2:1}/>
                {loungeAccessMethods.map(l=><th key={l.id}>{l.name}</th>)}
                {showingLAMTotal && <th><b>Totales</b></th>}
            </tr>
        </thead>
    );
};

export default EntriesStatsTable;
