import React,{useContext, useEffect, useMemo} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import DateRangeFilter, {useDateRangeFilter} from '../Filters/DateRangeFilter';
import _ from 'lodash';

const loadingId='@ReservationVisitsReport.report';
const ReservationVisitsReport=({defaultDateRange})=>{
    const dateConfig = useMemo(()=>({initial: defaultDateRange}), [defaultDateRange]);
    const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);
    const api=useContext(ApiContext);
    useEffect(()=>{
        api.lounges.reservationVisitsReport({loadingId, params:dateRequest});
    },[api, dateRequest]);

    const report = useSelector(({api})=>api.loungesReservationVisitsReport)||[];
    const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    //See info example at the end of this document
    const tableRows = useMemo(()=>{
        const reportTable = [];
        _.forEach(report||[], (info)=>{
            let loungeInfo = _.find(reportTable, r=>r.lounge===info.loungeName);
            if(!loungeInfo){
                loungeInfo = { lounge: info.loungeName, shifts: {} };
                reportTable.push(loungeInfo);
            }

            loungeInfo.shifts[info.shift]= {
                withReservation:info.withReservation,
                withoutReservation:info.withoutReservation
            }
        });
        return reportTable;
    },[report]);

    return (
        <div className='VisitsAirlinesReport'>
            <DateRangeFilter {...dateFilterProps}/>
            {loading && <h3 className='text-center'>Cargando...</h3>}

            <p className='text-center'>
                <span className='green'>Con reservación</span>{' / '}
                <span className='red'>Sin reservación</span>
            </p>
            <Table striped>
                <thead>
                <tr>
                    <th>Sala</th>
                    <th>Matutino</th>
                    <th>Vespertino</th>
                    <th>Nocturno</th>
                </tr>
                </thead>
                <tbody>
                {tableRows.map((data, i)=>
                    <tr key={i}>
                        <th>{data.lounge}</th>
                        <ShiftCountCell shifts={data.shifts} type={'morning'}/>
                        <ShiftCountCell shifts={data.shifts} type={'afternoon'}/>
                        <ShiftCountCell shifts={data.shifts} type={'night'}/>
                    </tr>
                )}
                </tbody>
            </Table>

        </div>
    );
};

export default ReservationVisitsReport;

const ShiftCountCell=({shifts, type})=>{

    return <td>
        {
            shifts && shifts[type]?
                <>
                    <span className='green'>{shifts[type].withReservation||'0'}</span>{' / '}
                    <span className='red'>{shifts[type].withoutReservation||'0'}</span>
                </>:
                '-'
        }
    </td>;
};

/*
[
{
"lounge": 1,
"name": "TGLE",
"turn": "afternoon",
"withoutReservation": "7",
"withReservation": "3"
},
{
"lounge": 1,
"name": "TGLE",
"turn": "morning",
"withoutReservation": "52",
"withReservation": "2"
},
{
"lounge": 12,
"name": "TERRAZA",
"turn": "morning",
"withoutReservation": "1",
"withReservation": "0"
}
]
 */
