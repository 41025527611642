import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Col, Modal, OverlayTrigger, Row, Table, Tooltip} from 'react-bootstrap';
import moment from 'moment';
import Button from "../../../../elements/CustomButton/CustomButton";
import UserCard from "../../../../components/Card/UserCard";
import Card from "../../../../components/Card/Card";
import {NavLink} from 'react-router-dom';
//import moneyFormatter from '../../../../utils/moneyFormatter';
import avatar from '../../../../assets/img/faces/face-0.jpg';
import sala from '../../../../assets/img/sala.jpg';
import WeekSchedule from "../../Catalogues/Shifts/WeekSchedule";
import EmployeeProfileModal from "./EmployeeProfileModal";
import userFulfillmentColor from "../../../../utils/userFulfillmentColor";
import {SecurityContext} from "../../../../utils/SecurityManager";
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";

export const UserDetailApiProp = "userForDetail";
export const UserDetailLoadingId = "@Class.UserDetail.externalLoad";

export default class UserDetail extends Component {

    static propTypes = {user: PropTypes.object.isRequired};
    static contextTypes = {api: PropTypes.object};
    //static contextType = SecurityContext;

    state = {};

    renderNameSubtitle = () => {
        const employee = this.props.user.employee;
        const str = employee && ((employee.lounge ? employee.lounge.name : "") + " / " + (employee.shift ? employee.shift.name : ""));

        return (
            <span>
            {str} <br/>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={1}>
                    Todos los colaboradores inician con 100 puntos, a estos se les restan puntos por cada incidencia
                    encontrada en los últimos 15 días.<br/>
                    Los puntos se restan de la siguiente manera:<br/>
                    Más tiempo de comida: -3<br/>
                    Registros incompletos: -3<br/>
                    Retardo a briefing: -3<br/>
                    Salida temprana: -5<br/>
                    Menos de 8 horas trabajadas: -5<br/>
                    Retardo: -5<br/>
                    Falta: -10<br/>
                    Suspensión: -20<br/><br/>

                    El color alrededor de tu foto irá cambiando con tu calificación. El 100 es verde y se va
                    convirtiendo en rojo al acercarse al 80.
                </Tooltip>}
            >
              <span>Calificación de cumplimiento: {employee?employee.fulfillmentScore:100}</span>
            </OverlayTrigger>
        </span>
        )

    };

    render() {
        const user = this.props.user;
        const employee = user.employee;
        const displayedClockSensors = [];
        return (
            <SecurityContext.Consumer>
                {security => (

                    <div className="UserDetail container-fluid">
                        <Row>
                            <Col md={6}>
                                <UserCard
                                    bgImage={sala}
                                    avatar={employee&&employee.photo ? this.context.api.createFileUrl(employee.photo) : avatar}
                                    name={employeeFullName(employee)}
                                    userName={this.renderNameSubtitle()}
                                    color={userFulfillmentColor(employee?employee.fulfillmentScore:100)}
                                    manualScoreName={employee?employee.manualScoreName:'Calificación'}
                                    manualScoreValue={employee?employee.manualScoreValue:100}
                                    autoScoreValue={employee?employee.autoScoreValue:0}
                                    socials={
                                        <div>
                                            {this.props.editEmployee ?
                                                <Button simple onClick={() => this.props.editEmployee(user)}>
                                                    <i className="fa fa-pencil"/> Editar información
                                                </Button> :
                                                <Button simple>
                                                    {employee&&employee.position ? employee.position.name : null}
                                                </Button>}
                                        </div>
                                    }
                                >

                                </UserCard>
                            </Col>

                            <Col md={6}>
                                <Card
                                    title="Información personal"
                                    content={
                                        <div>
                                            <Table responsive>
                                                <tbody>
                                                <tr>
                                                    <td className="">Nombre:</td>
                                                    <td>{employee.name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Apellido paterno:</td>
                                                    <td>{employee.patLastName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Apellido materno:</td>
                                                    <td>{employee.matLastName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Género:</td>
                                                    <td>{employee.sex === "F" ? "Mujer" : "Hombre"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Fecha de nacimiento:</td>
                                                    <td>{moment(employee.birthday).format("DD/MM/YYYY")}</td>
                                                </tr>
                                                <tr>
                                                    <td className="">CURP:</td>
                                                    <td>{employee.curp}</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <Card
                                    title="Información de contacto"
                                    content={
                                        <div>
                                            <Table responsive>
                                                <tbody>
                                                <tr>
                                                    <td className="">Correo:</td>
                                                    <td>{user.email}</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Teléfono de casa:</td>
                                                    <td>{employee.homePhone}</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Teléfono celular:</td>
                                                    <td>{employee.cellPhone}</td>
                                                </tr>

                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                />
                            </Col>

                            <Col md={6}>
                                <Card
                                    title="Información del sistema"
                                    content={
                                        <div>
                                            <Table responsive>
                                                <tbody>
                                                <tr>
                                                    <td>Usuario:</td>
                                                    <td>{user.username}</td>
                                                </tr>
                                                <tr>
                                                    <td>Tipo:</td>
                                                    <td>{user.role ? user.role.title : "Usuario básico"}</td>
                                                </tr>
                                                {employee.assistanceIds.map((assId) => {

                                                    if (!assId.characteristic || !assId.characteristic.sensorType)
                                                        return (
                                                            <tr key={assId.id}>
                                                                <td className="">ID de reloj checador:</td>
                                                                <td>{assId.clock.name} - {assId.internalId}</td>
                                                            </tr>
                                                        );

                                                    const sensor = assId.characteristic.sensorType;

                                                    if (displayedClockSensors.indexOf(sensor) !== -1)
                                                        return null;

                                                    displayedClockSensors.push(sensor);

                                                    return (
                                                        <tr key={assId.id}>
                                                            <td className="">En reloj inteligente:</td>
                                                            <td>{sensorTypesNames[sensor] || sensor}</td>
                                                        </tr>
                                                    );

                                                })}
                                                <tr>
                                                    <td>Actualización de contraseña mensual:</td>
                                                    <td>{user.changeMonthlyPassword ? 'Activado' : 'Desactivado'}</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                />
                            </Col>

                        </Row>
                        <Row>

                            <Col md={6}>
                                <Card
                                    title="Información del puesto"
                                    content={
                                        <div>
                                            <Table>
                                                <tbody>
                                                <tr>
                                                    <td className="">Puesto:</td>
                                                    <td>{employee.position ? employee.position.name : null}</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Días de descanso:</td>
                                                    <td>{employee.daysOff.map((day) => daysNumbers[day]).join(", ")}</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Puede faltar al trabajo en días festivos:</td>
                                                    <td>{employee.canMissWorkOnHoliday ? 'Sí' : 'No' }</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Área:</td>
                                                    <td>{employee.area ? employee.area.name : null}</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Sala:</td>
                                                    <td>{employee.lounge ? employee.lounge.name : null}</td>
                                                </tr>
                                                <tr>
                                                    <td className="">Turno:</td>
                                                    <td><span className='link'
                                                              onClick={() => this.setState({shiftDetail: true})}>{employee.shift ? employee.shift.name : null}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="">Fecha de contratación:</td>
                                                    <td>{moment(employee.hireDate).format("DD/MM/YYYY")}</td>
                                                </tr>
                                                {employee.owningGroup ?
                                                    <tr>
                                                        <td className="">Supervisando:</td>
                                                        <td>{employee.owningGroup.name}</td>
                                                    </tr>
                                                    : null}
                                                {employee.group ?
                                                    <tr>
                                                        <td className="">Supervisor:</td>
                                                        <td>{employeeFullName(employee.group.owner)}</td>
                                                    </tr>
                                                    : null}
                                                {/* Medida para ocultar sueldos
                                                {this.props.canSeeWage ?
                                                    <tr>
                                                        <td className="">Salario:</td>
                                                        <td>{moneyFormatter(employee.salary)}</td>
                                                    </tr>
                                                    : null}
                                                    */}
                                                {this.props.canSeeWage && employee.employeeBonuses ?
                                                    <tr>
                                                        <td className="">Bonos:</td>
                                                        <td>{employee.employeeBonuses.reduce((acc, cur) => `${acc}${cur.bonus.name}, `, '')}</td>
                                                    </tr>
                                                    : null}
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                />
                            </Col>

                            <Col md={6}>
                                <Card
                                    title="Ver registros"
                                    content={
                                        <div>
                                            <Table>
                                                <tbody>
                                                <tr>
                                                    <td><NavLink to={"/alma/employee/history/" + employee.id}
                                                                 onClick={this.props.onHide}>Registro de
                                                        asistencia</NavLink></td>
                                                </tr>

                                                <tr>
                                                    <td><NavLink to={"/alma/employee/justifications/" + employee.id}
                                                                 onClick={this.props.onHide}>Registro de
                                                        justificaciones</NavLink></td>
                                                </tr>

                                                <tr>
                                                    <td><NavLink to={"/alma/employee/requests/" + employee.id}
                                                                 onClick={this.props.onHide}>Registro de
                                                        solicitudes</NavLink></td>
                                                </tr>

                                                <tr>
                                                    <td><NavLink to={"/alma/employee/incidences/" + employee.id}
                                                                 onClick={this.props.onHide}>Registro de
                                                        incidencias</NavLink></td>
                                                </tr>
                                                {this.props.canSeeWage ?
                                                    <tr>
                                                        <td><NavLink to={"/alma/employee/payroll/" + employee.id}
                                                                     onClick={this.props.onHide}>Registro de
                                                            pagos</NavLink>
                                                        </td>
                                                    </tr>
                                                    : null}

                                                <tr>
                                                    <td><NavLink to={"/alma/employee/worktools/" + employee.id}
                                                                 onClick={this.props.onHide}>Equipo de trabajo</NavLink>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><span className={'link'}
                                                              onClick={() => this.setState({showEmployeeProfile: employee})}>Perfil personal</span>
                                                    </td>
                                                </tr>
                                                {security.alma.canSeeXFiles() &&
                                                    <tr>
                                                        <td>
                                                            <NavLink to={'/alma/employees/xfiles/'+employee.id}>Ver expediente</NavLink>
                                                        </td>
                                                    </tr>
                                                }

                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                />
                            </Col>

                        </Row>

                        {this.props.onFire && this.props.user.employee.isHired ?
                            <Row>
                                <Button
                                    className='col-md-6'
                                    bsStyle={'danger'}
                                    onClick={() => this.props.onFire(this.props.user)}>
                                    Dar de baja
                                </Button>
                            </Row>
                            : null}

                        {this.props.onHire && !this.props.user.employee.isHired ?
                            <Row>

                                {(() => {
                                    //If there was a layoffRequest, display it
                                    const lenLayoffs = this.props.user.employee.layoffs.length;
                                    if (lenLayoffs && this.props.user.employee.layoffs[lenLayoffs - 1].layoffRequest) {
                                        const req = this.props.user.employee.layoffs[lenLayoffs - 1].layoffRequest;
                                        return (
                                            <Card
                                                title="Requisición de baja"
                                                content={
                                                    <Table>
                                                        <tbody>
                                                        <tr>
                                                            <td>Solicitante:</td>
                                                            <td>{employeeFullName(req.requestedBy)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fecha de solicitud:</td>
                                                            <td>{moment(req.createdDate).format('DD/MM/YYYY')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Fecha de terminación:</td>
                                                            <td>{moment(req.layoffRequestDate).format('DD/MM/YYYY')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Motivo:</td>
                                                            <td>
                                                                <pre>{req.requestComment}</pre>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </Table>
                                                }/>);
                                    } else
                                        return null;
                                })()}
                                <Button
                                    className='col-md-6'
                                    bsStyle={'success'}
                                    onClick={() => this.props.onHire(this.props.user)}>
                                    Contratar empleado
                                </Button>
                            </Row>
                            : null}

                        {this.state.shiftDetail ?
                            <Modal show={true} onHide={() => this.setState({shiftDetail: false})} bsSize='lg'>
                                <Modal.Header>
                                    <Modal.Title>Turno {employee.shift.name} - {employee.shift && employee.shift.category && employee.shift.category.name}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    {employee.shift.hasMealTime ?
                                        <p>Tiempo de comida: {employee.shift.mealMinutes} minutos</p>
                                        :
                                        <p>Sin horario de comida</p>
                                    }

                                    <hr/>
                                    {employee.shift.weekSchedule ?
                                        <WeekSchedule weekSchedule={employee.shift.weekSchedule}/>
                                        :
                                        <p>Sin horario definido</p>
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button bsStyle='danger'
                                            onClick={() => this.setState({shiftDetail: false})}>Cerrar</Button>
                                </Modal.Footer>
                            </Modal> : null}

                        {this.state.showEmployeeProfile ?
                            <EmployeeProfileModal
                                employee={this.state.showEmployeeProfile}
                                onHide={() => this.setState({showEmployeeProfile: false})}
                            /> : null
                        }
                    </div>
                )}
            </SecurityContext.Consumer>
        )
    }
}

const daysNumbers = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

const sensorTypesNames = {
    'R307': 'Óptico',
    'R303': 'Plano',
};
