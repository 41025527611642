import avi from '../assets/img/file-thumbs/avi.svg';
import doc from '../assets/img/file-thumbs/doc.svg';
import file from '../assets/img/file-thumbs/file.svg';
import jpg from '../assets/img/file-thumbs/jpg.svg';
import midi from '../assets/img/file-thumbs/midi.svg';
import mov from '../assets/img/file-thumbs/mov.svg';
import mp3 from '../assets/img/file-thumbs/mp3.svg';
import pdf from '../assets/img/file-thumbs/pdf.svg';
import png from '../assets/img/file-thumbs/png.svg';
import ppt from '../assets/img/file-thumbs/ppt.svg';
import psd from '../assets/img/file-thumbs/psd.svg';
import txt from '../assets/img/file-thumbs/txt.svg';
import xls from '../assets/img/file-thumbs/xls.svg';
import xml from '../assets/img/file-thumbs/xml.svg';
import zip from '../assets/img/file-thumbs/zip.svg';

export const extensionImages = { avi, doc, file, jpg, midi, mov, mp3, pdf, png, ppt, psd, txt, xls, xml, zip,
    docx:doc, jpeg:jpg, pptx:ppt, xlsx:xls
};

export const imageForExtension = ( ext )=>{

    if( !ext )
        return file;

    const normalized = ext.trim().toLowerCase();

    if( extensionImages[normalized] )
        return extensionImages[normalized];

    return file;

};

export const supportedImageExtensions = ['jpg', 'png', 'jpeg', 'gif'];

export const isImage = (fileName)=>{
    if(!fileName)
        return false;
    return supportedImageExtensions.indexOf( fileName.split('.').pop().toLowerCase() ) !== -1;
};
