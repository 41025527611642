import React, {useContext, useCallback, useState} from 'react';
import {ApiContext} from "../../../api/Api";
import ReactTable from 'react-table';
import {
    defaultProps as ReactTableDefaultProps,
    filtersToObject,
} from "../../../utils/reactTableUtils";
import {connect} from "react-redux";
import _ from 'lodash';
import Button from '../../../elements/CustomButton/CustomButton';
import useReduxTableState from "../../../hooks/table/useReduxTableState";
import {
    POListTableStructure,
    purchaseOrderListSGroups,
    transSearch
} from "../../../utils/modelUtils/jazz/purchaseOrderUtils";

const loadingId = "@PurchaseOrdersList.get";


const PurchaseOrderList = ({purchaseOrders, purchaseOrdersMeta, loadingIds, history}) => {
    const [filters, setFilters] = useState([]);


    const {totalItems, itemsPerPage} = purchaseOrdersMeta;
    const plural = totalItems > 1 ? "s" : "";

    const api = useContext(ApiContext);

    const reduxTableState = useReduxTableState('@PurchaseOrderList');
    const loadData = useCallback(_.debounce((tableState) => {

        const filters = filtersToObject(tableState.filtered, tableState.sorted);

        if (filters.createdDate && filters.createdDate.startDate)
            filters['createdDate[after]'] = filters.createdDate.startDate.format('YYYY-MM-DDT[00:00]');

        if (filters.createdDate && filters.createdDate.endDate)
            filters['createdDate[before]'] = filters.createdDate.endDate.format('YYYY-MM-DDT[23:59:59]');


        delete filters.createdDate;

        if(filters.status){
            filters.status = transSearch(filters.status);
        }

        setFilters(filters);

        api.purchaseOrders.get(tableState.page, tableState.pageSize, loadingId, {...filters, sGroups: purchaseOrderListSGroups});
    },1000),[]);

    const goToDetail = (id) => history.push('/compras/orders/' + id);

    return (
        <div className="container-fluid main-container PurchaseOrderList">
            <ReactTable
                data={purchaseOrders}
                columns={POListTableStructure}
                pages={Math.ceil(totalItems / itemsPerPage)}
                loading={!!loadingIds[loadingId]}
                onFetchData={loadData}
                getTdProps={(state, row) => ({onClick: () => row ? goToDetail(row.original.id) : null})}
                {...ReactTableDefaultProps}
                {...reduxTableState.tableProps}
            />
            <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>
            <a
                href={ api.purchaseOrders.exportUrl({...filters, pagination:false})}
                rel="noopener noreferrer"
            >
                <Button>Exportar a Excel</Button>
            </a>

        </div>

);

};

const mapStateToProps = ({
                             api: {
                                 purchaseOrders = [], purchaseOrdersMeta = {
                                     totalItems: 0,
                                     itemsPerPage: 0
                                 }
                             }, loadingIds
                         }) => ({purchaseOrders, purchaseOrdersMeta, loadingIds});

export default connect(mapStateToProps)(PurchaseOrderList);
