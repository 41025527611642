import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';


const loadingId = "@Function.LoungeAnswer.Lounges.get";

class LoungeAnswer extends Component {

    state = {};

    componentDidMount = ()=>{

        if (!this.props.lounges.length && !this.props.loadingIds[loadingId])
            this.context.api.lounges.get(loadingId);

    };

    render() {

        let answer = '';
        if (this.props.data && this.props.data.response)
            answer = this.props.data.response;

        const onLoungeSelect = (e) => {
            this.props.onChange({response: e.target.value});
        };

        return (
            <div className='answer-component LoungeAnswer'>
                <select
                    className='form-control'
                    value={answer}
                    onChange={onLoungeSelect}
                    disabled={!this.props.editable}
                >
                    <option disabled value=''>Selecciona una sala</option>
                    {this.props.lounges.map((lounge) => {
                            if (!lounge.isLounge)
                                return null;
                            return (
                                <option
                                    key={lounge.id}>
                                    {lounge.name}
                                </option>
                            );
                        }
                    )}
                </select>
            </div>
        );

    }
}

LoungeAnswer.contextTypes = {api:PropTypes.object};

const mapStateToProps = ( {api:{lounges=[]}, loadingIds} )=>({lounges, loadingIds});

export default connect(mapStateToProps)(LoungeAnswer);
