import React, {Component} from 'react';
import EmployeeForm from './EmployeeForm';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

class EditEmployee extends Component{


    constructor( props ){
        super(props);

        if(props.user)
            this.state = {
                formValues: EmployeeForm.userToFormValues(props.user)
            }
    }

    render(){
        return (
            <div>
                {!this.props.user ?
                    <Redirect to={"/employees/list"}/>
                    :
                    <EmployeeForm
                        isEditing={true}
                        initialValues={this.state.formValues}
                    />
                }
            </div>
        );
    }
}

function mapStateToProps( {editEmployee} ){

    return {user:editEmployee}
}
export default connect(mapStateToProps)(EditEmployee);