import React from 'react';

const rotation90 = 90;
const rotation180 = 180;
const rotation270 = 270;

const Rotable =  ( props )=>{

    let RotableStyle = {};
    let outterStyle = {};
    let innerStyle = {};

    if( props.rotation === rotation90 || props.rotation === rotation270 ){
        RotableStyle = {display:'table'};
        outterStyle = {padding:'50% 0', height:'0'};
        innerStyle = {
            display: 'block',
            transformOrigin: 'top left',
            transform:  `rotate(${props.rotation}deg) ${(props.rotation === rotation90? 'translate(0, -100%)':'translate(-100%)')}`,//rotate(${270}deg) ${props.rotation === rotation90? 'translate(-100%)':'translate(0, -100%)}),
            marginTop: '-50%',
            whiteSpace: 'nowrap'
        };
    }

    else if( props.rotation === rotation180 ){
        innerStyle = { transform: 'rotate(180deg)' }
    }

    return (
        <div className='Rotable' style={RotableStyle}>
            <div className='outter-wrapper' style={outterStyle}>
                <div className='inner-wrapper' style={innerStyle}>
                    {props.children}
                </div>
            </div>
        </div>
    )
};

Rotable.rotation90 = rotation90;
Rotable.rotation180 = rotation180;
Rotable.rotation270 = rotation270;

export default Rotable;