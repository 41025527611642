import moment from 'moment';

const enumerateDates = ( start, end)=>{

    if( start.isAfter(end) )
        return [];

    const changing = moment(start);
    const dates = [];
    while( changing.isSameOrBefore(end) ){

        dates.push( moment(changing) );
        changing.add(1,'day');
    }

    return dates;
};

export default enumerateDates;