
export const warehouseConfigurationSGroups = [
    "place_read",
    "warehouse_read",
    "warehouse_read_employee_warehouses",
        "employee_warehouse_read",
        "employee_warehouse_read_employee",
            "employee_simple",
        "employee_warehouse_read_created_by",
            "user_read_employee",
    "warehouse_read_lounge_warehouses",
        "lounge_warehouse_read",
        "lounge_warehouse_read_lounge",
        "lounge_warehouse_read_created_by",
        "lounge_warehouse_read_created_date",
    "warehouse_read_stock_notification_users",
    "user_read",
];

export const warehouseForStockRequestFormSGroups = [
    "place_read",
    "warehouse_read",
    "warehouse_read_lounge_warehouses",
        "lounge_warehouse_read",
        "lounge_warehouse_read_lounge",
];
