
export const positionPermissionsSGroups = [
    "position_read_id",
    "position_read_name",
    "position_read_permission_groups",
        "permission_group_read"
];

export const positionReducedPermissionsSGroups = [
    "position_read_id",
    "position_read_name"
];