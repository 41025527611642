import React, {useContext} from 'react';
import {DateRangePicker} from "react-dates";
import moment from 'moment';
import ReportBox from './ReportBox';
import useDateRangePickerState from "../../../hooks/useDateRangePickerState";
import {TideApiContext} from "../../../api/tideApiConfig";
import {querySerialize} from "../../../api/Api";

const PurchasedLineProductsReport = () => {
    const api = useContext(TideApiContext);
    const {props, dateRange} = useDateRangePickerState({
	startDate: moment().subtract(1, 'month'),
	endDate: moment()
    });
    const valid = dateRange.startDate&&dateRange.endDate;
    const query = valid&&querySerialize({
	from: dateRange.startDate.format('YYYY-MM-DD'),
	to: dateRange.endDate.format('YYYY-MM-DD'),
	token: api.token
    });
    const reportUrl = valid?`${api.host}/api/purchase_products/purchased_report?${query}`:'#';

    return (
	<ReportBox
	    title="Resumen de productos comprados"
	    description="Muestra los productos que costaron más en un rango de tiempo, junto con las cantidades compradas"
	    valid={valid}
	    reportUrl={reportUrl}
	>
	    <p className='filter-label'>Rango de fechas:</p>
	    <DateRangePicker {...props} />
	</ReportBox>
    );
};

export default PurchasedLineProductsReport;
