import {prepareGolfCartSchedulesTripForServer} from "./golfCartScheduledTripUtils";
import {minutesToString} from "../../TimeConverter";
import {copyPropertyIdOrThrow} from "../../../services/formUtils";

export const prepareGolfCartScheduleForServer = ( form )=>{
    const schedule = {};

    if(!form.name) {
        throw new Error("Debes agregar un nombre");
    }
    schedule.name = form.name;
    schedule.isActive = !!form.isActive;

    copyPropertyIdOrThrow(form, schedule, "lounge", "Debes seleccionar la sala de partida");

    if(!form.availableDays?.length){
        throw new Error("Debes seleccionar al menos un día de la semana");
    }
    schedule.availableDays = form.availableDays;
    if(!form.golfCartScheduledTrips?.length){
        throw new Error("Debes agregar al menos un viaje");
    }

    if (!form.golfCart) {
        throw new Error("Debes seleccionar un carrito");
    }
    schedule.golfCart = form.golfCart.id;

    schedule.golfCartScheduledTrips = form.golfCartScheduledTrips.map( ( trip )=>{
        try{
            return prepareGolfCartSchedulesTripForServer(trip);
        }
        catch (e){
            throw new Error(`Viaje de las ${minutesToString(trip.startTime)} - ${e.message}` );
        }
    } );

    return schedule;
};

export const cartStatus = (cart) => cart.isActive ? cart.name + " - (Activo)" : cart.name + " - (Inactivo)";

export const getEmptyGolfCartSchedule = ()=>({
    name:"",
    isActive: true,
    availableDays: [],
    golfCart: "",
    golfCartScheduledTrips:[]
});

export const golfCartScheduleColumns = [
    {
        Header: "Carrito",
        accessor: "golfCart.name"
    },
    {
        Header: "Nombre",
        accessor: "name"
    },
    {
        Header: "Días",
        accessor: schedule=>{
            if(!schedule?.availableDays?.length) return "Sin información";
            return schedule.availableDays.map(day=>day.dayName).join(', ');
        },
        id: "availableDays.dayName",
        sortable: false,
    },
    {
        Header: "Número de viajes",
        accessor: schedule=>schedule?.golfCartScheduledTrips?.length||0,
        id: "golfCartScheduledTrips",
        filterable: false,
        sortable: false,
    },
    /*
        --- CODE COMMENTED MIGHT USE IT SOME OTHER TIME ---
    {
        Header: "Primer viaje",
        accessor: schedule=>{
            if(!schedule?.golfCartScheduledTrips?.length) return "Sin viajes";
            return `${minutesToString( schedule.golfCartScheduledTrips[0].startTime )}`;
        },
        id: "golfCartScheduledTrips.startTime",
        filterable: false,
        sortable: false,
    },
    {
        Header: "Último viaje",
        accessor: schedule=>{
            if(!schedule?.golfCartScheduledTrips?.length) return "Sin viajes";
            return `${minutesToString( schedule.golfCartScheduledTrips[schedule.golfCartScheduledTrips.length-1].startTime )}`;
        },
        id: "golfCartScheduledTrips[length].startTime",
        filterable: false,
        sortable: false,
    },*/
    {
        Header: "Estado",
        accessor: schedule => schedule.isActive? "Activo" : "Inactivo",
        id: "isActive",
        filterable: false,
    },
];

export const golfCartScheduleListSGroups = [
    "golf_cart_schedule_read",
    "golf_cart_schedule_read_golf_cart",
    "golf_cart_read",
    "golf_cart_schedule_read_available_days",
        "day_of_the_week_read",
    "golf_cart_schedule_read_golf_cart_scheduled_trips",
        "golf_cart_scheduled_trip_read",
        "golf_cart_scheduled_trip_read_airport_gates",
            "airport_gate_read",
            "airport_gate_read_airport_gate_lounges",
            "airport_gate_lounge_read_eta_minutes",
            "airport_gate_lounge_read_lounge",
    "golf_cart_schedule_read_golf_cart",
    "golf_cart_schedule_read_lounge",
        "place_read_id",
        "place_read_name",
]

export const golfCartScheduleDetailSGroups = [
    ...golfCartScheduleListSGroups
];
