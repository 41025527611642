import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Questionnaire from "../../../components/Questionnaire/Questionnaire";
import Button from '../../../elements/CustomButton/CustomButton';
import ThankYou from '../../../components/Questionnaire/ThankYou';

const hotCornerSize = 200;
const hotCornerPattern = [0,1,2,3];


class QuestionnaireView extends Component{

    static contextTypes = { api:PropTypes.object };

    loadingId = '@Class.QuestionnaireView.questionnaires.get';

    state = {
        locked: false,
        hotCornerStep:0,
        sent:false
    };

    constructor(props, context) {
        super(props, context);
        this.verifyQuestionnaireIsLoaded();
    }

    componentDidUpdate = ()=>{
        this.verifyQuestionnaireIsLoaded();
    };

    verifyQuestionnaireIsLoaded = ()=>{

        const idInUrl = this.props.match.params.id;
        const gCareForVisit = this.props.match.params.visit;

        const questionnaire = _.find( this.props.questionnaires, (quest)=> gCareForVisit?
            quest.isGcare : quest.id+'' === idInUrl );

        if( !questionnaire && !this.props.loadingIds[this.loadingId] && !this.state.failedToFetch) {

            const filters = gCareForVisit? {isGcare:true}:{id: idInUrl};

            this.context.api.questionnaires.get(0, 1, this.loadingId, filters)
                .then((questionnaires) => {
                    const questionnaire = _.find(questionnaires, (quest) => gCareForVisit ?
                        quest.isGcare : quest.id+'' === idInUrl);
                    if (!questionnaire)
                        this.setState({failedToFetch: true});
                })
                .catch(() => this.setState({failedToFetch: true}));
        }

    };

    onSend = ()=>{

        const idInUrl = this.props.match.params.id;
        const gCareForVisit = this.props.match.params.visit;
        const questionnaire = _.find( this.props.questionnaires, (quest)=>  gCareForVisit ?
            quest.isGcare : quest.id+'' === idInUrl);

        if( questionnaire && questionnaire.config && questionnaire.config.fullscreen )
            this.setState({sent:true});
        else
            this.props.history.push('/ee/questionnaires');
    };

    /*
    About hot corners
    You can lock the screen to make this view fill the window, as there is no way to change the view when it's locked
    we designed this method to unlock it.
    The hot corners are the corners of the view, which can be clicked in a pattern to unlock the screen.
    The corners are squares with size hotCornerSize and the password is defined as an array of indexes, the indexes
    of the hot corners are counted from the top left corner and clock-wise.

     */
    containerClick = (e)=>{

        if( !this.state.locked )
            return;

        const cornerIndex = QuestionnaireView.isHotCorner( e.clientX, e.clientY );

        if( cornerIndex === -1 )
            return this.setState( {hotCornerStep:0} );

        this.hotCornerTouch(cornerIndex);
    };

    hotCornerTouch = ( corner )=>{

        if( corner === hotCornerPattern[this.state.hotCornerStep] ) {
            console.log(corner, (this.state.hotCornerStep+1) + '/' + hotCornerPattern.length);
            if( this.state.hotCornerStep + 1 === hotCornerPattern.length ) {
                window.document.exitFullscreen();
                this.setState({hotCornerStep: 0, locked: false});
            }
            else
                this.setState({hotCornerStep: this.state.hotCornerStep + 1});
        }
        else{
            this.setState({hotCornerStep: 0});
        }
    };

    static isHotCorner = (x, y)=>{

        if( x < hotCornerSize && y < hotCornerSize)
            return 0;
        if( x > window.innerWidth - hotCornerSize && y < hotCornerSize )
            return 1;
        if( x > window.innerWidth - hotCornerSize && y > window.innerHeight - hotCornerSize )
            return 2;
        if( x < hotCornerSize && y > window.innerHeight - hotCornerSize )
            return 3;
        return -1;
    };

    lockScreen = ()=>{
        this.container && this.container.requestFullscreen();
        this.setState({locked:true});
    };

    doAgain = ()=>{
        this.setState({sent:false});
    };

    render() {

        if( this.state.failedToFetch)
            return <div className='text-center'><p className='alert alert-danger'>Hubo un error al cargar la información.</p></div>;

        const idInUrl = this.props.match.params.id;
        const gCareForVisit = this.props.match.params.visit;
        const questionnaire = _.find( this.props.questionnaires, (quest)=>  gCareForVisit ?
            quest.isGcare : quest.id+'' === idInUrl);

        if( !questionnaire )
            return <h4>Cargando cuestionario...</h4>;

        return (
            <div className={"QuestionnaireView container-fluid main-container " + (this.state.locked? 'fullscreen':'')} ref={(r)=>this.container=r} onClick={this.containerClick}>
                {!this.state.locked && questionnaire.config && questionnaire.config.fullscreen?
                    <Button bsStyle='primary' className='pull-right lock-button' onClick={this.lockScreen}><i className='fa fa-arrows-alt'/></Button>
                    :null}
                {!this.state.sent ?
                    <Questionnaire
                        questionnaire={questionnaire}
                        onSend={this.onSend}
                        visit={this.props.match.params.visit}
                    />
                    :
                    <ThankYou
                        onRepeat={this.doAgain}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = ({api:{questionnaires=[]}, loadingIds})=>({questionnaires, loadingIds});

export default  connect(mapStateToProps)(QuestionnaireView);
