import React, {useContext, useState} from 'react';
import useBoolean from "../../../../hooks/useBoolean";
import classNames from 'classnames';
import {DateRangePicker} from "react-dates";
import useDateRangePickerState from "../../../../hooks/useDateRangePickerState";
import TideEntitySelect from "../../../../components/TideEntitySelect/TideEntitySelect";
import {NotifierContext} from "../../../../utils/Notifier";
import {TideApiContext} from "../../../../api/tideApiConfig";
import {querySerialize} from "../../../../api/Api";

const InventoryPeriodResume = () => {

    const notifier = useContext(NotifierContext);
    const api = useContext(TideApiContext);

    const [isOpen,,,toggleOpen] = useBoolean();

    const {props, dateRange} = useDateRangePickerState();
    const [warehouse, setWarehouse] = useState();

    const handleClick = (e)=>{
        if(!warehouse || !dateRange.endDate || !dateRange.startDate){
            e.preventDefault();
            notifier.error('Llena todos los filtros');
        }
    };

    let link = "#";
    window.api = api;
    if(warehouse && dateRange.startDate && dateRange.endDate){
        const query = querySerialize({
            warehouse: warehouse.id,
            from: dateRange.startDate.format('YYYY-MM-DD 00:00'),
            to:dateRange.endDate.format('YYYY-MM-DD 23:59'),
            token: api.token
        });
        link = `${api.host}/api/inventories/period-report?${query}`;
    }


    return (
        <div className={classNames("StockMovementsResume report-block", isOpen&&'open')}>
            <p className='title' onClick={toggleOpen}>Consumos de almacén <i className='fa fa-caret-down caret-icon'/></p>
            {isOpen&&
            <div className='body'>
                <hr/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <p className='filter-label'>Almacén:</p>
                            <TideEntitySelect
                                entity='warehouses'
                                value={warehouse}
                                onChange={setWarehouse}
                            />
                            <p className='filter-label'>Rango de fechas:</p>
                            <DateRangePicker {...props} />
                        </div>
                        <div className='col-md-6'>
                            <p className='filter-label'>Descripción:</p>
                            <p className='description'>Lista el consumo de todos los productos del almacén seleccionado. Muestra el inventario al inicio y final del periodo y la cantidad consumida.</p>
                        </div>
                    </div>
                </div>
                <br/>
                <a href={link} target='_blank' rel='noopener noreferrer' onClick={handleClick}>
                    <button className='btn btn-info'>Descargar</button>
                </a>

            </div>}
        </div>
    );
};

export default InventoryPeriodResume;
