import React, {useContext} from 'react';
import {Modal} from "react-bootstrap";
import {ApiContext} from "../../../../api/Api";
import {fileTypeName} from "../../../../utils/xFilesUtils";
import {employeeFullname} from "../../../../api/Api";
import FileViewer from "../../../../components/FileViewer/FileViewer";

export default ({onHide, file, fileType, employee}) => {
    const api = useContext(ApiContext);
    const title = fileTypeName(fileType) + ' de ' + employeeFullname(employee);
    return (
        <Modal show={true} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FileViewer name={file.originalName} src={api.createFileUrl(file)}/>
                <button className='btn btn-success' onClick={()=>window.open(api.createFileUrl(file, true))}>Descargar</button>
            </Modal.Body>
        </Modal>
    );
};
