
export const productLineListColumns = [
    {
        Header:"Nombre",
        accessor:"name"
    },
    {
        Header:"Línea padre",
        accessor:"parentLine.name"
    }
];

export const productLineListSGroups = [
    "product_line_read",
    "product_line_read_parent_line"
];
