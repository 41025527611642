import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, useContext} from 'react';
import "./_GolfCartSchedule.scss";
import {minutesToString, momentToMinutes} from "../../utils/TimeConverter";
import classNames from 'classnames';
import moment from "moment";
import {statusIndicator} from "../../utils/modelUtils/ee/golfCartTripUtils";
import _ from 'lodash';
import {SecurityContext} from "../../utils/SecurityManager";
import { colorHEX } from 'utils/colorUtils';

const defaultHourHeight = 400;
const mobileHeight = 150; 
const mobileBreakpoint = 650;

const GolfCartSchedule = ({
                              isSelected,
                              golfCart,
                              addingTrip,
                              golfCartSchedule,
                              golfCartTrips,
                              editingTripIndex,
                              setEditingTripIndex,
                              onScheduleSquareClick,
                              onCommentsButtonClick,
                              lounges
                          }) => {
    const [tripEnded, setTripEnded] = useState(false);
    const securityManager = useContext(SecurityContext);
    const isReadonlyUser = securityManager.ee.canSeeGolfCartTripListReadonly();
    const [showHours, setShowHours] = useState();
    const [size, setSize] = useState({x: window.innerWidth, y: window.innerHeight});
    const updateSize = () => setSize({ x: window.innerWidth, y: window.innerHeight});
    const [hourHeight, setHourHeight] = useState(defaultHourHeight);
    
    useEffect(() => {
        if (addingTrip?.scheduledTrip && (addingTrip?.scheduledTrip?.realEndDate || addingTrip?.scheduledTrip?.realStartDate)) {
            return setTripEnded(true);
        }
        setTripEnded(false);
    }, [addingTrip, setTripEnded]);

    useEffect(() => (window.onresize = updateSize), []);
  
    useEffect(() => {
        if(size.x<mobileBreakpoint){
            setHourHeight(mobileHeight);
        } else {
            setHourHeight(defaultHourHeight);
        }
    }, [setHourHeight, size]);

    // ---- Rendering hour blocks helpers ----
    const timeBlocks = useMemo(() => {
        const timeBlocks = [];
        for (let i = 0; i < 24; i++) {
            timeBlocks.push({minute: i * 60});
        }
        return timeBlocks;
    }, []);

    const timeBlockStyle = useMemo(() => ({height: hourHeight}), [hourHeight]);

    const getStyleForScheduledTrip = (trip) => {
        let startTime, endTime;
        if (trip.scheduledStartDate && trip.scheduledEndDate) {
            startTime = momentToMinutes(moment(trip.scheduledStartDate));
            endTime = momentToMinutes(moment(trip.scheduledEndDate));
        } else {
            startTime = trip.startTime;
            endTime = trip.endTime;
        }

        const loungeId = (trip.lounge?.id ? trip.lounge.id : golfCartSchedule?.lounge?.id);

        return {
            top: (hourHeight / 60 * startTime),
            height: (hourHeight / 60 * (endTime - startTime)),
            backgroundColor: (loungesColors ? loungesColors?.find( lc => lc?.id===loungeId )?.color : 'rgba(31, 141, 255, 1)')
        }
    };

    const getStyleForPreviewTrip = (trip) => {
        let startTime, endTime;
        if (trip.scheduledStartTime && trip.scheduledEndTime) {
            startTime = trip.scheduledStartTime;
            endTime = trip.scheduledEndTime;
        } else {
            startTime = trip.startTime;
            endTime = trip.endTime;
        }
        return {
            top: (hourHeight / 60 * startTime),
            height: (hourHeight / 60 * (endTime - startTime)),
        }
    };

    const getStylesForTimeIndicator = (minutes) => {
        const topValue = ((hourHeight / 60) * minutes);
        return {top: topValue + 'px'};
    }

    const handleTripClick = useCallback((e, scheduledTrip) => {
        const scheduledStartTime = !scheduledTrip.scheduledStartDate ? scheduledTrip.startTime : momentToMinutes(moment(scheduledTrip.scheduledStartDate));
        const scheduledEndTime = !scheduledTrip.scheduledEndDate ? scheduledTrip.endTime : momentToMinutes(moment(scheduledTrip.scheduledEndDate));
        const operator = scheduledTrip.operator ? scheduledTrip?.operator : golfCart?.operator;
        const airportGates = scheduledTrip.airportGates ? scheduledTrip.airportGates : [];
        const passengers = scheduledTrip.golfCartPassengers ? _.cloneDeep(scheduledTrip.golfCartPassengers) : [];
        const lounge = scheduledTrip.lounge ? scheduledTrip.lounge : null;
        if (onScheduleSquareClick) {
            onScheduleSquareClick({
                cassette: scheduledTrip.cassette,
                scheduledTrip,
                golfCart,
                scheduledStartTime,
                scheduledEndTime,
                operator,
                airportGates,
                passengers,
                lounge
            });
        }

        if(onCommentsButtonClick)
            onCommentsButtonClick(null);

        if (!setEditingTripIndex)
            return;

        setEditingTripIndex(Number(e.target.dataset.index));
    }, [setEditingTripIndex, onScheduleSquareClick, golfCart, onCommentsButtonClick]);

    const handleOutsideClick = useCallback((minute) => {
        const startTime = minute;
        const endTime = (minute + 15);
        const operator = golfCart?.operator ? golfCart.operator : null;
        const passengers = [{visit: null, personsNumber: 1}];
        if (onScheduleSquareClick) {
            onScheduleSquareClick({minute, golfCart, startTime, endTime, operator, passengers});
        }

        if (setEditingTripIndex)
            setEditingTripIndex(null);

        if(onCommentsButtonClick)
            onCommentsButtonClick(null);
    }, [setEditingTripIndex, onScheduleSquareClick, golfCart, onCommentsButtonClick]);

    // Auto scroll to current time
    const timeSpaceRef = useRef();
    useLayoutEffect(() => {
        const startTime = momentToMinutes(moment());
        /*
        if (!golfCartSchedule?.golfCartScheduledTrips?.length || !timeSpaceRef.current)
            return;
        const startTime = golfCartSchedule.golfCartScheduledTrips.reduce((minTime, trip) => trip.startTime < minTime ? trip.startTime : minTime, 1000);
         */

        timeSpaceRef.current.scroll(0, (startTime - 60) * (hourHeight / 60));
    }, [golfCartSchedule, hourHeight]);

    const reduceNumberOfPassengers = useCallback((golfCartPassengersArray) => {
        return _.reduce(golfCartPassengersArray, (acc, current) => {
            return acc + current.personsNumber
        }, 0);
    }, []);

    const handleCommentsClick = useCallback((e) => {
        e.stopPropagation();
        if (onCommentsButtonClick) {
            onCommentsButtonClick(e.currentTarget.dataset.cassetteId);
        }
        if(onScheduleSquareClick)
            onScheduleSquareClick(null)
    }, [onCommentsButtonClick, onScheduleSquareClick]);

    const loungesColors = useMemo(() => {
        return colorHEX(lounges);
    }, [lounges]);

    const timeSpaceClass = useMemo(() => { return (!showHours ? 'time-space--hidden' : '') }, [showHours]);
    const toggleHours = () => {setShowHours(!showHours)};

    return (
        <div className={classNames("GolfCartSchedule", isSelected && "selected")}>
            <div className="header"
                onClick={toggleHours}
            >
                {golfCart?.name || golfCartSchedule?.name || '-'}

                {!showHours ? 
                    <div className={'btn-command'}>+</div> : 
                    <div className={'btn-command'}>-</div>}
            </div>
            <div className={classNames("time-space", timeSpaceClass)} ref={timeSpaceRef}>
                {timeBlocks.map(timeBlock => {
                        const actualHour = (moment().hour() * 60);
                        const actualMinutes = (moment().minutes());
                        return (
                            <div key={timeBlock.minute} style={timeBlockStyle} className='time-block'
                                 onClick={() => handleOutsideClick(timeBlock.minute)}>
                                <span className='time-label'>
                                    {minutesToString(timeBlock.minute)}
                                </span>
                                <div className={actualHour === timeBlock.minute ? 'time-indicator' : null}
                                     style={getStylesForTimeIndicator(actualMinutes)}/>
                            </div>
                        )
                    }
                )}

                {golfCartSchedule?.golfCartScheduledTrips && golfCartSchedule.golfCartScheduledTrips.map((scheduledTrip, index) => {

                        const loungeId = (scheduledTrip.lounge?.id ? scheduledTrip.lounge.id : golfCartSchedule.lounge?.id);

                        return <div className={classNames("scheduledTrip", index === editingTripIndex && "editing")}
                                    key={scheduledTrip.id || scheduledTrip.tempId}
                                    style={getStyleForScheduledTrip(scheduledTrip)}
                                    data-index={index}
                                    onClick={(e) => handleTripClick(e, scheduledTrip)}
                        >
                            <span
                                className="scheduledTrip-time">{minutesToString(scheduledTrip.startTime)} - {minutesToString(scheduledTrip.endTime)}</span> |{' '}
                            <span
                                className="gates"
                                style={{ backgroundColor: (loungesColors ?
                                        loungesColors?.find( lc => lc?.id===loungeId )?.color :
                                        'rgba(31, 141, 255, 1)') }}
                            >
                                Puertas: {scheduledTrip.airportGates && scheduledTrip.airportGates.map(ag => ag.gateNumber).join(', ')}
                            </span>
                        </div>
                    }
                )}

                {golfCartTrips && golfCartTrips.map((trip, index) =>
                    <div
                        className={classNames("trip", "scheduledTrip", addingTrip?.scheduledTrip?.id === trip.id && "editing", trip?.createdFromScheduledTrip !== true && "manually-created")}
                        key={trip.id}
                        style={getStyleForScheduledTrip(trip)}
                        data-index={index}
                        onClick={(e) => handleTripClick(e, trip)}
                    >
                        {statusIndicator(trip)}
                        {trip.cassette && <span onClick={handleCommentsClick} data-cassette-id={trip.cassette?.id}
                                                className={"comments-button"}>{trip.cassette.commentsCount||<i className={"fa fa-comment"}/>}</span>}
                        <span
                            className="scheduledTrip-time">{minutesToString(momentToMinutes(moment(trip.scheduledStartDate)))} - {minutesToString(momentToMinutes(moment(trip.scheduledEndDate)))}</span> |{' '}
                        <span className="gates">Puertas: {trip.airportGates.map(ag => ag.gateNumber).join(', ')}</span>

                        <div className="scheduledTrip-info-container">
                            <div className='current-passengers'>
                                <i className={classNames("fa fa-user", trip.golfCart.maxPassengersNumber <= reduceNumberOfPassengers(trip.golfCartPassengers) && "red")}/>
                                {reduceNumberOfPassengers(trip.golfCartPassengers)}
                            </div>
                            <div className="scheduledTrip-lounge">
                                Sala: {trip?.lounge?.name}
                            </div>
                        </div>
                    </div>
                )}

                {addingTrip && isSelected && !tripEnded && !isReadonlyUser &&
                <div className='trip-preview' style={getStyleForPreviewTrip(addingTrip)}>
                        <span className="scheduledTrip-time">
                            {!addingTrip?.scheduledTrip ? Math.floor(addingTrip?.startTime / 60) + ':' + (addingTrip.startTime % 60) :
                                Math.floor(addingTrip?.scheduledStartTime / 60) + ':' + (addingTrip?.scheduledStartTime % 60)} -
                            {!addingTrip?.scheduledTrip ? Math.floor(addingTrip?.endTime / 60) + ':' + (addingTrip.endTime % 60) :
                                Math.floor(addingTrip?.scheduledEndTime / 60) + ':' + (addingTrip?.scheduledEndTime % 60)}
                        </span> |{' '}
                    <span className="gates">
                        Puertas: {addingTrip?.airportGates?.map(ag => ag.gateNumber).join(', ')}
                    </span>
                    <div className='current-passengers-preview'>
                        <i className={classNames("fa fa-user")}/>
                        {reduceNumberOfPassengers(addingTrip.passengers)}
                    </div>
                </div>}

            </div>
        </div>
    );
};

export default GolfCartSchedule;
