import React, {useState, useContext, useEffect, useRef, useCallback} from 'react';
import {Table, Dropdown, MenuItem, DropdownButton} from 'react-bootstrap';
import {ApiContext} from "../../../api/Api";
import {NotifierContext} from "../../../utils/Notifier";
import TagsInput from "react-tagsinput";
import {connect} from 'react-redux';
import ClassicModal from "../../../components/Modals/ClassicModal";
import {paymentConditions, paymentMethods, poTrans} from "../../../utils/modelUtils/jazz/purchaseOrderUtils";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import useBoolean from "../../../hooks/useBoolean";
import Switch from "react-bootstrap-switch";


const tgleCompaniesLoadingId = '@AddProviderModal.tgleCompanies.get';
const providerLoadingId = '@AddProviderModal.provider.create';
const ProviderFormModal = ({onHide, tgleCompanies, loadingIds, editProvider}) => {

    const [provider, setProvider] = useState({
        name: '',
        commercialName: null,
        rfc: null,
        contactName1: null,
        contactPhone1: null,
        contactName2: null,
        contactPhone2: null,
        emails: [],
        emailsToSendPurchaseOrders: [],
        creditLimit: null,
        paymentMethod: null,
        paymentCondition: null,
        paymentCurrency: 'MXN',
        street: null,
        exteriorNumber: null,
        interiorNumber: null,
        zipCode: null,
        neighborhood: null,
        cityCode: null,
        daysToSupply: null,
        tgleCompany: null,
        notes: null
    });

    const [enableMails, setEnableEmailsTrue, setEnableEmailsFalse, toggleEnableMails] = useBoolean();

    const [fileInputs, setFileInputs] = useState([]);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (editProvider) {
            setProvider({
                    ...editProvider,
                    creditLimit: editProvider.creditLimit ? Number(editProvider.creditLimit) : null,
                    files: undefined
                }
            );
            editProvider.purchaseOrderEmailEnabled ? setEnableEmailsTrue() : setEnableEmailsFalse();
        }
    }, [editProvider, setEnableEmailsFalse, setEnableEmailsTrue]);

    const filesRef = useRef([]);
    filesRef.current = files;

    const onFileChange = (e, index) => {
        const newFiles = [...filesRef.current];
        newFiles[index] = {...e.target.files}[0];
        setFiles(newFiles);
    };

    const addFileInput = () => {
        const index = fileInputs.length;
        const newFileInputs = [...fileInputs];
        newFileInputs.push(<input type="file" onChange={(e) => onFileChange(e, index)} key={index}/>);
        setFileInputs(newFileInputs);
    };


    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);

    useEffect(() => {
        api.tgleCompanies.get(tgleCompaniesLoadingId);
    }, [api]);

    const onPropertyChange = ({target}) => {
        const newProvider = {...provider, [target.name]: target.value};
        setProvider(newProvider);
    };

    const onSimplePropertyChange = useCallbackCreator((prop, value) => {
        const newProvider = {...provider, [prop]: value};
        setProvider(newProvider);
    }, [provider]);

    const saveProvider = useCallback(() => {

        const sendProvider = {...provider};
        sendProvider.tgleCompany = provider.tgleCompany ? provider.tgleCompany.id : null;

        if (!sendProvider.commercialName)
            sendProvider.commercialName = null;
        if (!sendProvider.rfc)
            sendProvider.rfc = null;
        if (!Number(sendProvider.creditLimit))
            sendProvider.creditLimit = null;

        sendProvider.daysToSupply = Number(sendProvider.daysToSupply);

        sendProvider.purchaseOrderEmailEnabled = enableMails;

        if (!sendProvider.name)
            return notifier.error('Debes agregar un nombre al proveedor');

        if (sendProvider.id)
            api.providers.edit({
                id: sendProvider.id,
                provider: sendProvider,
                loadingId: providerLoadingId,
                files: files || undefined
            })
                .then(() => {
                    onHide();
                });
        else
            api.providers.create({provider: sendProvider, loadingId: providerLoadingId, files: files || undefined})
                .then(() => {
                    onHide();
                });

    }, [api, provider, files, notifier, onHide, enableMails]);


    return (
        <ClassicModal
            title='Forma de proveedor'
            onHide={onHide}
            disabled={!!loadingIds[providerLoadingId]}
            onSave={saveProvider}
        >

            <form autoComplete='off'>
                <Table>
                    <tbody>
                    <tr>
                        <td>Razón social</td>
                        <td>
                            <input
                                className='form-control'
                                name='name'
                                required={true}
                                onChange={onPropertyChange}
                                value={provider.name}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Nombre comercial</td>
                        <td>
                            <input
                                className='form-control'
                                name='commercialName'
                                required={true}
                                onChange={onPropertyChange}
                                value={provider.commercialName || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>RFC</td>
                        <td>
                            <input
                                className='form-control'
                                name='rfc'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.rfc || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Nombre de contacto 1</td>
                        <td>
                            <input
                                className='form-control'
                                name='contactName1'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.contactName1 || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Teléfono de contacto 1</td>
                        <td>
                            <input
                                className='form-control'
                                name='contactPhone1'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.contactPhone1 || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Nombre de contacto 2</td>
                        <td>
                            <input
                                className='form-control'
                                name='contactName2'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.contactName2 || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Teléfono de contacto 2</td>
                        <td>
                            <input
                                className='form-control'
                                name='contactPhone2'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.contactPhone2 || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Emails</td>
                        <td>
                            <TagsInput
                                value={provider.emails || []}
                                onChange={onSimplePropertyChange('emails')}
                                tagProps={{className: 'react-tagsinput-tag tag-fill tag-azure'}}
                                inputProps={{placeholder: 'Agregar correo'}}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Envío de correos de orden de compra automáticos</td>
                        <td onClick={toggleEnableMails}>
                            <Switch value={enableMails}/>
                        </td>
                    </tr>
                    <tr>
                        <td>Emails que reciben correo de orden de compra</td>
                        <td>
                            <TagsInput
                                value={provider.emailsToSendPurchaseOrders || []}
                                onChange={onSimplePropertyChange('emailsToSendPurchaseOrders')}
                                tagProps={{className: 'react-tagsinput-tag tag-fill tag-azure'}}
                                inputProps={{placeholder: 'Agregar correo'}}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Límite de crédito</td>
                        <td>
                            <input
                                className='form-control'
                                name='creditLimit'
                                type='number'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.creditLimit || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Método de pago</td>
                        <td>
                            <DropdownButton
                                id='method'
                                title={poTrans(provider.paymentMethod) || 'Sin método de pago'}
                                bsStyle='primary'
                                className='btn-fill payment-method-btn'
                                onSelect={onSimplePropertyChange('paymentMethod')}
                            >
                                {paymentMethods.map((method) =>
                                    <MenuItem key={method} eventKey={method}>{poTrans(method)}</MenuItem>
                                )}
                            </DropdownButton>
                        </td>
                    </tr>
                    <tr>
                        <td>Condición de pago</td>
                        <td>
                            <DropdownButton
                                id='condition'
                                title={poTrans(provider.paymentCondition) || 'Sin condiciones de pago'}
                                bsStyle='primary'
                                className='btn-fill payment-method-btn'
                                onSelect={onSimplePropertyChange('paymentCondition')}
                            >
                                {paymentConditions.map((condition) =>
                                    <MenuItem key={condition} eventKey={condition}>{poTrans(condition)}</MenuItem>
                                )}
                            </DropdownButton>
                        </td>
                    </tr>
                    <tr>
                        <td>Moneda de pago</td>
                        <td>
                            <Dropdown id='paymentCurrency' onSelect={onSimplePropertyChange('paymentCurrency')}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {provider.paymentCurrency ? provider.paymentCurrency : "Seleccionar"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <MenuItem eventKey='MXN'>MXN</MenuItem>
                                    <MenuItem eventKey='USD'>USD</MenuItem>
                                    <MenuItem eventKey='EUR'>EUR</MenuItem>
                                </Dropdown.Menu>
                            </Dropdown>

                        </td>
                    </tr>
                    <tr>
                        <td>Calle</td>
                        <td>
                            <input
                                className='form-control'
                                name='street'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.street || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Número exterior</td>
                        <td>
                            <input
                                className='form-control'
                                name='exteriorNumber'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.exteriorNumber || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Número interior</td>
                        <td>
                            <input
                                className='form-control'
                                name='interiorNumber'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.interiorNumber || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Código postal</td>
                        <td>
                            <input
                                className='form-control'
                                name='zipCode'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.zipCode || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Colonia</td>
                        <td>
                            <input
                                className='form-control'
                                name='neighborhood'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.neighborhood || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Código de ciudad</td>
                        <td>
                            <input
                                className='form-control'
                                name='cityCode'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.cityCode || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Días que tarda en surtir</td>
                        <td>
                            <input
                                type='number'
                                className='form-control'
                                name='daysToSupply'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.daysToSupply || ''}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Razón social con la que se le paga</td>
                        <td>
                            <Dropdown id='tgleCompany' onSelect={onSimplePropertyChange('tgleCompany')}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {provider.tgleCompany ? provider.tgleCompany.name : "Seleccionar empresa"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {tgleCompanies.map((company) =>
                                        <MenuItem eventKey={company} key={company.id}>{company.name}</MenuItem>)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                    <tr>
                        <td>Archivos</td>
                        <td>
                            <span className='link add-text' onClick={addFileInput}>+ Agregar archivo</span>
                            {fileInputs}
                        </td>
                    </tr>
                    <tr>
                        <td>Notas</td>
                        <td>
                            <textarea
                                className='form-control'
                                name='notes'
                                required={false}
                                onChange={onPropertyChange}
                                value={provider.notes || ''}
                            />
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </form>
        </ClassicModal>
    );
};

const mapStateToMaps = ({api: {tgleCompanies = []}, loadingIds}) => ({
    tgleCompanies,
    loadingIds
});

export default connect(mapStateToMaps)(ProviderFormModal);
