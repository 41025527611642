import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';

const smallBreakpoint = 660;


export default function OptionsAnswerStats( {stats, result} ){

    const [ isSmall, setIsSmall ] = useState( window.innerWidth < smallBreakpoint );

    useEffect( ()=>{

        const changeWidth = ()=>setIsSmall( window.innerWidth < smallBreakpoint );
        window.addEventListener('resize', changeWidth );

        return ()=>window.removeEventListener( 'resize', changeWidth );
    }, []);

    if( !stats.length )
        return <p>Sin información.</p>;

    const options = [];
    let values = [];
    let count = 0;

    for( let i =0; i<stats.length; i++) {
        options.push( stats[i].answer );
        values.push({
            value: stats[i].count,
            name: stats[i].answer
        });
        count += Number(stats[i].count);
    }

    if( result.questionConfig && result.questionConfig.options ){

        values = _.sortBy( values, stat=>result.questionConfig.options.indexOf( stat.name ) );
    }

    const graphOtions = {
        tooltip : {
            trigger: 'item',
            formatter: "{b} <br/> {c} ({d}%)"
        },
        legend: {
            show: isSmall,
            left: 'left',
            orient: 'vertical',
            data: options,
            formatter: ( opt )=> opt + ' - ' + _.find( values, ( val)=>val.name===opt ).value
        },
        series : [
            {
                name: 's',
                type: 'pie',
                radius : '50%',
                center: ['50%', isSmall?'60%' : '50%' ],
                label: isSmall?
                    false :
                    { formatter:'{b} - {d}%'},
                data:values,
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ],
        color:[
            '#23da4a',
            '#97d400',
            '#ffc522',
            '#ff443b',
            '#DD0000',
            '#990000',
            '#660000',
            '#220000',
            '#000000']
    };

    return (
        <div>
            <ReactEcharts option={graphOtions} />
            <span className='text-muted small'>{count} respuesta{Number(count)!==1?'s':''}</span>
        </div>
    );
}