import React, {Component} from 'react';
import ReactTable from 'react-table';
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import _ from 'lodash';
import ApplicantModal from './components/ApplicantModal';
import EmployeeForm from "../Employees/core/EmployeeForm";
import {Modal} from 'react-bootstrap';
import moment from 'moment';
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";

class Applicants extends Component{

    static contextTypes = { api:PropTypes.object, notifier:PropTypes.object };

    loadingId = "@Class.Applicants.get";

    constructor( props, context ){
        super(props);
        this.state = {};

        this.loadApplicants = _.debounce( this.loadApplicants.bind(this), 700, {'leading': false,'trailing': true} );
        this.hireApplicant = this.hireApplicant.bind(this);

        this.dataApi = context.api.applicants;
    }

    UNSAFE_componentWillReceiveProps( nextProps ){

        if( this.props.newuser !== nextProps.newuser ) {

            this.loadApplicants(this.tableState);
            this.setState({creatingUser:false});

        }

    }

    loadApplicants( tableState ){

        this.tableState = tableState;

        const sort = tableState.sorted.reduce( (acc, val)=>{acc[`order[${val.id}]`] = val.desc?"DESC":"ASC"; return acc;}, {} );
        const filter = tableState.filtered.reduce( (acc, val)=>{acc[val.id] = val.value; return acc;}, {} );

        if( !tableState.sorted.length )
            sort['order[createdDate]'] = 'DESC';

        this.dataApi.get(tableState.page , tableState.pageSize, this.loadingId, {...sort, ...filter}, undefined, this.props.areHired);
    }


    hireApplicant( applicant ){

        applicant.hireDate = moment().format('YYYY-MM-DD');
        const {email, id, ...employee} = applicant;
        employee.applicant = {id};
        const user = {
            email,
            employee,
        };

        this.setState( {
            creatingUser: user,
            showAppDetail:false} );
    }

    tableStructure = [{
        Header: 'Nombre',
        accessor: employeeFullName,
        id: 'search'
    }, {
        Header: 'Puesto',
        accessor: 'position.name'
    }, {
        Header: 'Contratado',
        accessor: ( app )=><div className='text-center'><i className={'fa '+(app.employee?'fa-check green':'fa-times red')}/></div>,
        id:'employee.id',
        filterable: false
    }, {
        Header: 'Creación',
        accessor: ( app )=>moment( app.createdDate ).format('DD/MM/YYYY'),
        id:'createdDate',
        filterable: false
    }
    ];

    render() {

        const { totalItems, itemsPerPage } = this.props.listMeta;
        const plural = totalItems>1?"s":"";

        return (
            <div className="container-fluid main-container">

                <ReactTable
                    data={this.props.list}
                    columns = {this.tableStructure}
                    defaultPageSize= {10}
                    pages= { Math.ceil( totalItems / itemsPerPage ) }
                    className="-striped -highlight"
                    loading={!!this.props.loadingIds[this.loadingId]}
                    manual
                    filterable
                    onFetchData={this.loadApplicants}
                    getTdProps={(state, rowInfo)=>({onClick:()=>this.setState({showAppDetail:rowInfo.original})})}
                    minRows={3}
                    {...ReactTableTranslations}
                />
                <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>

                {this.state.showAppDetail?
                <ApplicantModal
                    onHide={()=>this.setState({showAppDetail:false})}
                    applicant={this.state.showAppDetail}
                    didHire={this.hireApplicant}
                />:null}

                {this.state.creatingUser?
                    <Modal
                        onHide={()=>this.setState({creatingUser:false})}
                        show={true}
                        bsSize="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Agregar empleado</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EmployeeForm
                                isEditing={false}
                                initialValues={EmployeeForm.userToFormValues(this.state.creatingUser) }
                                history={this.props.history}
                            />

                        </Modal.Body>

                        <Modal.Footer/>
                    </Modal>:null}

            </div>
                );
    }
}

function mapStateToProps( {api:{ applicants=[], applicantsMeta={totalItems:0,itemsPerPage:0}, newuser}, loadingIds } ) {

    return { list:applicants, listMeta:applicantsMeta, newuser, loadingIds }
}

export default connect(mapStateToProps)(Applicants);
