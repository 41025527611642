import './FlightForm.scss';
import React, {useContext, useEffect, useState, useMemo} from 'react';
import Selector from 'react-select';
import { ApiContext } from 'api/Api';
import { useSelector } from 'react-redux';
import CustomButton from 'elements/CustomButton/CustomButton';

const customPropAirlines = "@FlightForm.Store.Airlines";
const customPropDestinationTypes = "@FlightForm.Store.DestinationType";

const FlightForm = React.memo(({flight, onSubmit}) => {
    //Initializers
    const api = useContext(ApiContext);
    const {id = null} = flight || {};
    const editMode = id;
    const selectorOptionsSchema = {value: null, label: null};

    //State
    const [form, setForm] = useState({});
    const [airlineSelected, setAirlineSelected] = useState(selectorOptionsSchema);
    const [destinationTypeSelected, setDestinationTypeSelected] = useState(selectorOptionsSchema);


    //Retrieving destinationTypes
    useEffect(() => {
        const payload = {customProp: customPropDestinationTypes};
        api.destinationTypes.get(payload);
    }, [api]);

    //Create Mode: Retrieving airlines
    useEffect(() => {
        if(!editMode){
            const payload = {customProp: customPropAirlines};
            api.airlines.get(payload);
        }
    }, [editMode, api]);

    //Create Mode: Setting airline code in flight number input
    useEffect(() => {
        if(!editMode){
            if(airlineSelected?.code)
                setForm(f => ({...f, number: airlineSelected.code}));
            else
                setForm( f => ({...f, number: ''}))
        }
    }, [airlineSelected, editMode]);

    //Edit Mode: Setting current flight values
    useEffect(() => {
        if(editMode && flight?.airline && flight?.number){
            //Setting airline
            const {id, name} = flight.airline;
            setAirlineSelected({value: id, label: name});

            //Setting form values
            const {number, isArrival, flightType, monthlyGoal, isActive} = flight;
            setForm(f => ({...f, number, isArrival: String(isArrival), flightType, monthlyGoal, isActive:isActive?'true':'false' }));

            //Destination types
            if(flight?.destinationType){
                const {id, name} = flight.destinationType;
                setDestinationTypeSelected({value: id, label: name});
            }
        }
    }, [flight, editMode]);

    //Redux Store
    const airlines = useSelector(({api}) => api?.[customPropAirlines] || []);
    const destinationTypes = useSelector(({api}) => api?.[customPropDestinationTypes] || []);

    //Formatting airlines/destinationTypes data for Selector
    const airlinesOptions = useMemo(() =>
        airlines.map( ({id, name, code}) => ({value: id, label: name, code})
    ), [airlines]);

    const destinationTypesOptions = useMemo(() =>
        destinationTypes.map( ({id, name}) => ({value: id, label: name}))
    , [destinationTypes]);

    //Handlers
    const handleInputChange = ({target: {value, name}}) => setForm({...form, [name] : value});

    const onFormSubmit = async (event) => {
        event.preventDefault();
        const {isArrival, flightType, number, monthlyGoal, isActive} = form;
        const params = {
            airline: airlineSelected?.value,
            number,
            flightType,
            monthlyGoal: Number(monthlyGoal),
            isArrival: isArrival === 'true',
            destinationType: destinationTypeSelected?.value,
            isActive: isActive==='true'
        }
        onSubmit({id, params});
    }

    return (
        <form className="FlightForm" onSubmit={onFormSubmit}>
            <label className="full-width">
                Aerolínea:
                {
                    (editMode)
                    ? (
                        <input
                            name="airline"
                            className="form-control"
                            defaultValue={airlineSelected?.label || ''}
                            disabled={true}
                        />
                    )
                    : (
                        <Selector
                            value={airlineSelected}
                            onChange={setAirlineSelected}
                            options={airlinesOptions}
                        />
                    )
                }
            </label>
            <label className="form-group full-width">
                Número de vuelo:
                <input
                    name="number"
                    className="form-control"
                    value={form?.number || ''}
                    onChange={handleInputChange}
                    disabled={(editMode) ? true : !airlineSelected}
                />
            </label>

            <fieldset>
                <legend>Tipo de vuelo:</legend>
                <div className="label-container">
                <label>
                    <input
                        disabled={(editMode) ? true : false}
                        name="isArrival"
                        type="radio"
                        onChange={handleInputChange}
                        value={false}
                        checked={form?.isArrival === "false"}
                    />
                    Salida
                </label>
                <label>
                    <input
                        disabled={(editMode) ? true : false}
                        name="isArrival"
                        type="radio"
                        onChange={handleInputChange}
                        value={true}
                        checked={form?.isArrival === "true"}
                    />
                    Llegada
                </label>
                </div>
            </fieldset>

            <fieldset>
                <legend>Alcance del vuelo:</legend>
                <div className="label-container">
                <label>
                    <input
                        name="flightType"
                        type="radio"
                        onChange={handleInputChange}
                        value="domestic"
                        checked={form?.flightType === "domestic"}
                    />
                    Doméstico
                </label>
                <label>
                    <input
                        name="flightType"
                        type="radio"
                        onChange={handleInputChange}
                        value="international"
                        checked={form?.flightType === "international"}
                    />
                    Internacional
                </label>
                </div>
            </fieldset>
            <fieldset>
                <legend>Vuelo activo:</legend>
                <div className="label-container">
                <label>
                    <input
                        name="isActive"
                        type="radio"
                        onChange={handleInputChange}
                        value="true"
                        checked={form?.isActive === "true"}
                    />
                    Activo
                </label>
                <label>
                    <input
                        name="isActive"
                        type="radio"
                        onChange={handleInputChange}
                        value="false"
                        checked={form?.isActive === "false"}
                    />
                    Inactivo
                </label>
                </div>
            </fieldset>

            <label className="form-group full-width destination-type">
                Tipo de destino:
                <Selector
                    value={destinationTypeSelected}
                    onChange={setDestinationTypeSelected}
                    options={destinationTypesOptions}
                />
            </label>

            <label className="form-group full-width">
                Meta de número de huéspedes mensual:
                <input
                    min={0}
                    type="number"
                    name="monthlyGoal"
                    className="form-control"
                    value={form?.monthlyGoal||''}
                    onChange={handleInputChange}
                />
            </label>

            <CustomButton
                type="submit"
                fill
                block
            >
                Guardar
            </CustomButton>
        </form>
    );
});
export default FlightForm;
