import React, { useContext, useState, useEffect, useCallback } from 'react';
import {MenuItem, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import {connect} from 'react-redux';
import HeaderLinks from './HeaderLinks.jsx'; // links that appear in navbar - they are separated from this component (navbar) so that we can render them on responsive in sidebar as well
import moment from "moment";
import {ApiContext} from "../../api/Api";
import useCallbackCreator from "../../hooks/useCallbackCreator";


const Header = ( { me, routes, notifications, location, history, apiQueue } )=>{

    const api = useContext(ApiContext);

    const [notificationsOpen, setNotificationsOpen] = useState( false );

    useEffect( ()=>{

        if( !me )
            return;

        const filters={ 'user.id':me.id,'exists[readDate]':true,'order[createdDate]':'DESC' };
        api.notifications.getHeaderNotifications( {page:0, pageSize:11, filters, customProp:'notificationsHeader'} );

        const interval =  setInterval(()=>{
            api.notifications.getHeaderNotifications( {page:0, pageSize:11, filters, customProp:'notificationsHeader'} );
        },350000);

        return ()=>clearInterval( interval );

    },[ me, api ]);



    const makeBrand = ()=>{

        let name = "";
        const path = location.pathname;

        routes.forEach((route) => {

            if(route.collapse){
                route.views.forEach((subRoute) => {

                    if( comparePaths(subRoute.path, path) ){
                        name = subRoute.name;
                    }
                })
            } else {
                if( comparePaths(route.path, path) ){
                    name = route.name;
                }
            }
        });

        return name;
    };

    const notificationClick = useCallbackCreator((index)=>{

        const notification=notifications[index];

        if(!notification.readDate)
            api.notifications.markAsRead( {id:notification.id, customProp:'notificationsHeader'} );

        if(notification.link)
            history.push(notification.link);

        setNotificationsOpen(false);

    },[notifications]);

    const notificationsReadAll = ()=>{
        api.notifications.markAllAsRead()
            .then( ()=>api.notifications.get( {page:0, pageSize:11, filters:{ 'user.id':me.id }, customProp:'notificationsHeader'} ) );
    };


    const unreadCount = notifications.reduce( (acc, not)=>not.readDate? acc : ++acc , 0 );
    const queueSize = apiQueue.length;
    const badgeNumber = unreadCount + queueSize;

    const openAllNotifications =useCallback(()=>{
        setNotificationsOpen(false);
        history.push('/alma/notifications')
    },[history]);
    const openNotificationsConfiguration =useCallback(()=>{
        setNotificationsOpen(false);
        history.push('/alma/notifications-configuration')
    },[history]);

    return (
        <Navbar fluid>
            <div className="navbar-minimize">
                <button id="minimizeSidebar" className="btn btn-default btn-fill btn-round btn-icon" onClick={handleMinimizeSidebar}>
                    <i className="fa fa-ellipsis-v visible-on-sidebar-regular" />
                    <i className="fa fa-navicon visible-on-sidebar-mini" />
                </button>
            </div>
            <Navbar.Header>
                <Navbar.Brand>
                    {/* Here we create navbar brand, based on route name */}
                    <span >{makeBrand()}</span>
                </Navbar.Brand>

                <Navbar.Toggle onClick={mobileSidebarToggle} />

                <div
                    onClick={()=>setNotificationsOpen( !notificationsOpen )}
                    className='mobile-notifications-icon visible-xs visible-sm'
                >
                    <i className='fa fa-bell-o'/>
                    {unreadCount?
                        <span className="notification">{unreadCount}</span>
                        :null}
                </div>

                <div className='clearfix visible-xs visible-sm' />

            </Navbar.Header>
            <Nav pullRight>
                <NavDropdown
                    eventKey={3}
                    noCaret
                    id="basic-nav-dropdown-2"
                    title={
                        <div>
                            <i className="fa fa-bell-o" />
                            {badgeNumber?
                                <span className="notification">{badgeNumber}</span>
                                :null}
                        </div>
                    }
                    className={ notificationsOpen? 'open':''}
                >
                    {apiQueue.length?
                        <MenuItem >
                            <div className='notification-desc'>
                                Queda{queueSize>1?'n':''} {queueSize} formulario{queueSize>1?'s':''} pendiente{queueSize>1?'s':''} por subir.
                            </div>
                            <p className='notification-time'><i className='fa fa-clock-o'/></p>
                        </MenuItem>
                    :null}

                    {notifications.map((noti, index)=>
                        <MenuItem className={noti.readDate? '':'unread'}
                                  onClick={ notificationClick( index )}
                                  key={noti.id}
                        >
                            <div className='notification-desc' dangerouslySetInnerHTML={{__html:noti.html}} />
                            <p className='notification-time'><i className='fa fa-clock-o'/><span className='time-string'>{moment(noti.createdDate).format("dddd D [de] MMMM [del] YYYY [a las] HH:mm")}</span></p>
                        </MenuItem>
                    )}

                    {notifications.length && unreadCount?
                        <MenuItem onClick={ notificationsReadAll} >
                            <i className="fa fa-envelope-open-o" /> Marcar todo como leído
                        </MenuItem>
                        :null}

                    {notifications.length?
                        <MenuItem onClick={openAllNotifications}>
                                <i className="fa fa-database" /> Ver todas las notificaciones
                        </MenuItem>
                        :null}
                    <MenuItem onClick={openNotificationsConfiguration}>
                        <i className="fa fa-cog" /> Configuración de notificaciones
                    </MenuItem>

                    {!notifications.length && !apiQueue.length?
                        <MenuItem >
                            Aún no tienes notificaciones
                        </MenuItem>
                        :null}
                </NavDropdown>
            </Nav>

                {/* Here we import the links that appear in navbar */}
                {  (<Navbar.Collapse><HeaderLinks /></Navbar.Collapse>) }

        </Navbar>
    );

}

const mapStateToProps = ( { api:{notificationsHeader = [], me}, apiQueue } )=>{
    return {notifications:notificationsHeader, apiQueue, me};
};

export default connect( mapStateToProps )(Header);


const comparePaths = ( pathTemplate, path)=>{

    let paramIndex = pathTemplate.indexOf(":");
    if( paramIndex !== -1 ){
        pathTemplate = pathTemplate.substring(0, paramIndex);
        path = path.substring(0, paramIndex);
    }

    return path === pathTemplate;

};
// function that makes the sidebar from normal to mini and vice-versa
const handleMinimizeSidebar = ()=>{
    document.body.classList.toggle('sidebar-mini');
};
// function for responsive that hides/shows the sidebar
const mobileSidebarToggle = (e)=>{
    document.documentElement.classList.toggle('nav-open');
};

