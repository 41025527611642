import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import ReduxPromise from 'redux-promise';
import "react-table/react-table.css";
import 'font-awesome/css/font-awesome.min.css'

import jsonResponseMiddleware from 'middleware/middleware_jsonResponse';

import App from './containers/App/App';
import registerServiceWorker from './registerServiceWorker';
import rootReducer from './reducers/index.js';
import LocalStorageState from './utils/LocalStorage';

import 'rc-slider/assets/index.css';
import './assets/css/bootstrap.min.css';
import './assets/sass/light-bootstrap-dashboard.css';
import './assets/css/demo.css';
import './assets/css/pe-icon-7-stroke.css';
import './assets/sass/gle.css';


let local = new LocalStorageState();

const store = applyMiddleware( ReduxPromise, jsonResponseMiddleware )( createStore )( rootReducer, local.getState(),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render((
    <Provider store={store}>
        <BrowserRouter>
           <Route path="/" component={App} />
        </BrowserRouter>
    </Provider>
), document.getElementById('root'));

registerServiceWorker();
