import moment from "moment";
import {displayDateTimeFormat} from "../../timeUtils";
import {employeeFullName} from "../alma/employeeUtils";
import React from 'react';
import ReactTableDataRangeFilter from '../../../components/Helper/ReactTableDateRangeFilter';

// Returns a string with the name of the gates visited in the trip  //
export const getAirportGates = (airportGatesArray) => {
    return airportGatesArray.map((airportGate) => airportGate?.gateNumber).join(', ');
};

// Returns a string with the name of guest and number of companions //
export const getCompanionsPerVisit = (passengersArray) => {
    return passengersArray.map((passenger) => {
        if (!passenger?.visit)
            return 'Anónimo';

        const companions = passenger?.personsNumber > 1 ? ' - (' + (passenger?.personsNumber - 1) + ' Acompañantes) ': '';
        return passenger?.visit?.guest?.fullName + companions;
    }).join(', ');
};

// Returns a integer with a total of passengers in the trip //
export const getNumberOfPassengers = (passengersArray) => {
    let numberOfPassengers = 0;
    passengersArray.map((passenger) => {
        numberOfPassengers += passenger?.personsNumber;
        return null;
    });
    return numberOfPassengers;
};

// Returns a string with the name of all passengers //
export const getNameOfPassengers = (passengersArray) => {
    let passengersNameString = '';
    passengersArray.map((passenger) => {
        passengersNameString += passenger?.visit?.guest?.fullName + ', ';
        return null;
    });
    return passengersNameString;
}

export const statusIndicator = (golfCartTrip)=>{

    if(golfCartTrip?.realStartDate && !golfCartTrip?.realEndDate)
        return <span className='indicator ongoing'/>;

    if(golfCartTrip?.realStartDate && golfCartTrip?.realEndDate)
        return <span className='indicator ended'/>;

    return <span className='indicator waiting'/>;
}

// Table configuration for component GolfCartTripList //
export const golfCartTripsTableConfig = [
    {
        Header: "Inicio programado de viaje",
        accessor: ({scheduledStartDate})=> scheduledStartDate? moment(scheduledStartDate).format(displayDateTimeFormat):"Sin fecha de inicio",
        id: "scheduledStartDate",
        filterable: true,
        Filter: ReactTableDataRangeFilter
    },
    {
        Header: "Estatus",
        accessor: 'status',
        id: "status",
        filterable: false
    },
    {
        Header: "Fin programado de viaje",
        accessor: ({scheduledEndDate})=> scheduledEndDate? moment(scheduledEndDate).format(displayDateTimeFormat):"Sin fecha de fin",
        id: "scheduledEndDate",
        filterable: false
    },
    {
        Header: "Fin real de viaje",
        accessor: ({realEndDate})=> realEndDate? moment(realEndDate).format(displayDateTimeFormat):"Sin terminar viaje",
        id: "realEndDate",
        filterable: false
    },
    {
        Header: "Puertas",
        accessor: ({airportGates}) => airportGates?.length > 0 ? getAirportGates(airportGates) : 'Sin puertas',
        id: "airportGates.gateNumber",
    },
    {
        Header: "Huéspedes",
        accessor: ({golfCartPassengers}) => golfCartPassengers?.length > 0 ? getCompanionsPerVisit(golfCartPassengers) : 'Sin puertas',
        id: "golfCartPassengers.visit.guest.name",
    },
    {
        Header: "Número de Pasajeros",
        accessor: ({golfCartPassengers}) => golfCartPassengers?.length > 0 ? getNumberOfPassengers(golfCartPassengers) : 'Sin pasajeros definidos',
        id: "golfCartPassengers.personsNumber",
        filterable: false
    },
    {
        Header: "Carrito",
        accessor: ({golfCart})=> golfCart? golfCart.name : "Sin carrito",
        id: "golfCart.name",
    },
    {
        Header: "Operador",
        accessor: ({operator}) => employeeFullName(operator),
        id: "operator.name",
    },
    {
        Header: "TerminadoPor",
        accessor: ({endedBy}) => employeeFullName(endedBy?.employee),
        id: "endedBy",
    },
];

export const golfCartDayTripsTable = [
    {
        Header: "Huéspedes",
        accessor: ({golfCartPassengers}) => golfCartPassengers?.length > 0 ? getCompanionsPerVisit(golfCartPassengers) : 'Sin puertas',
        id: "golfCartPassengers.visit.guest.name",
    },
    {
        Header: "Número de Pasajeros",
        accessor: ({golfCartPassengers}) => golfCartPassengers?.length > 0 ? getNumberOfPassengers(golfCartPassengers) : 'Sin pasajeros definidos',
        id: "golfCartPassengers.personsNumber",
        filterable: false
    },
    {
        Header: "Asientos",
        accessor: ({golfCartPassengers}) => golfCartPassengers?.map( p=>p.visit?.seats?.map(s=>s.name).join(', ')||'' ).join(', ')||'Sin información',
        id: "golfCartPassengers.visit.seats",
        filterable: false
    },
    {
        Header: "Conductor",
        accessor: ({operator}) => employeeFullName(operator),
        id: "operator.name",
    },
    {
        Header: "Inicio programado",
        accessor: ({scheduledStartDate})=> scheduledStartDate? moment(scheduledStartDate).format('HH:mm'):"Sin fecha de inicio",
        id: "scheduledStartDate",
        filterable: false,
    },
    {
        Header: "Sala",
        accessor: 'lounge.name',
        filterable: false
    },
    {
        Header: "Destino",
        accessor: ({airportGates}) => airportGates?.length > 0 ? getAirportGates(airportGates) : 'Sin puertas',
        id: "airportGates.gateNumber",
    },
    {
        Header: "Vuelos",
        accessor: ({golfCartPassengers}) => golfCartPassengers?.map( p=>p.visit?.flight?.number||'' ).join(', ')||'Sin información',
        id: "golfCartPassengers.visit.flight.number",
    },
    {
        Header: "Puerta de vuelo",
        accessor: ({golfCartPassengers}) => golfCartPassengers?.map( p=>p.visit?.flight?.gate||'' ).join(', ')||'Sin información',
        id: "golfCartPassengers.visit.flight.gate",
    },
    {
        Header: "Estatus",
        accessor: 'status',
        id: "status",
        filterable: false
    },
];

// SGROUPS TO SHOW DATA //
export const golfCartTripSGroups = [
    "golf_cart_trip_read",
    "golf_cart_trip_read_golf_cart",
    "golf_cart_read",
    "golf_cart_trip_read_operator",
    "employee_simple",
    "golf_cart_trip_read_airport_gates",
    "airport_gate_read",
    "golf_cart_trip_read_golf_cart_passengers",
    "lounge_read",
    "golf_cart_trip_read_lounge",
    "golf_cart_passenger_read",
    "golf_cart_passenger_read_visit",
        "visit_read",
        "visit_read_guest",
            "guest_read",
            "guest_read_full_name",
        "visit_read_seats",
            "lounge_map_item_read_name",
        "visit_read_flight",
            "flight_read_number",
            "flight_read_gate",
    "golf_cart_schedule_read_lounge",
        "place_read_id",
        "place_read_name",
    "golf_cart_scheduled_trip_read_airport_gates",
        "airport_gate_read",
        "airport_gate_read_airport_gate_lounges",
        "airport_gate_lounge_read_eta_minutes",
        "airport_gate_lounge_read_lounge",
    "cassette_read",
    "cassette_read_comments_count",
    "user_read_employee",
    "golf_cart_trip_read_status"
];

// SGROUPS TO DOWNLOAD EXCEL //
export const golfCartTripSGroupsReport = [
    "golf_cart_trip_read",
    "golf_cart_trip_read_golf_cart",
    "golf_cart_read",
    "golf_cart_trip_read_operator",
    "employee_simple",
    "golf_cart_trip_read_airport_gates",
    "airport_gate_read",
    "golf_cart_trip_read_golf_cart_passengers",
    "golf_cart_trip_read_airport_gates_string",
    "golf_cart_trip_read_start_date_formatted",
    "golf_cart_trip_read_end_date_formatted",
    "golf_cart_trip_read_persons_number",
    "golf_cart_passenger_read",
    "golf_cart_passenger_read_visit",
    "visit_read",
    "visit_read_guest",
    "guest_read",
    "guest_read_full_name",
];

// CONFIG TO DOWNLOAD EXCEL //
export const reportConfigDownload = {
    name: "Historial de Viajes.xls",
    headers: {
        "id": "ID de Viaje",
        "scheduledStartDate": "Inicio de viaje programado",
        "scheduledEndDate": "Fin de viaje programado",
        "realStartDate": "Inicio real de viaje",
        "realEndDate": "Fin real de viaje",
        "airportGatesString": "Puertas",
        "personsNumberString": "Número de pasajeros",
        "golfCart.name": "Carrito de golf",
        "operator.name": "Operador",
    }
};
