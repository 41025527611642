import {daysOfTheWeek} from "../../timeUtils";

export const periodicStockRequestsListColumns = [
    {
        Header: 'Habilitada',
        id: 'isActive',
        accessor: r=>r.isActive?'Habilitada':'Deshabilitada'
    },
    {
        Header: 'De almacén',
        id: 'fromWarehouse',
        accessor: r=>r?.fromWarehouse?.name
    },
    {
        Header: 'Para almacén',
        id: 'toWarehouse',
        accessor: r=>r?.toWarehouse?.name
    },
    {
        Header: 'Días de la semana',
        id: 'daysOfTheWeek',
        accessor: r=>!r?.daysOfTheWeek? "" : r.daysOfTheWeek.split(",")
            .sort()
            .map( day=>daysOfTheWeek[day].substr(0,3) )
            .join(", ")
    },
    {
        Header: 'Hora',
        id: 'time',
        accessor: r=>Math.floor(r.time/60)+":00"
    },
    {
        Header: 'Días promediados',
        id: 'daysForAverage',
        accessor: r=>r.daysForAverage
    },
    {
        Header: 'Salas a promediar',
        id: 'loungesForAverage',
        accessor: r=>r.loungesForAverage?.map(l=>l.name).join(', ')
    },
    {
        Header: 'Días a solicitar',
        id: 'daysToAsk',
        accessor: r=>r.daysToAsk
    }
];

export const listSGroups = [
    'periodic_stock_request_read',
    'periodic_stock_request_read_from_warehouse',
    'periodic_stock_request_read_to_warehouse',
    'place_read', 'warehouse_read',
    'periodic_stock_request_read_lounges_for_average',
    'periodic_stock_request_read_approvers',
    'employee_read_id',
    'employee_read_name',
    'employee_read_pat_last_name',
    'employee_read_mat_last_name',
    'employee_read_full_name',
];
