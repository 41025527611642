import React from 'react';
//import {mapItemsDefinition} from "../../utils/loungeMapItemUtils";
import {mapItemsDefinition} from "tgle-core/utils/loungeMapItemUtils";
import classNames from 'classnames';
import useCallbackCreator from "../../../../hooks/useCallbackCreator";
import useBoolean from "../../../../hooks/useBoolean";


const ElementsPicker = ({activeTool, onActiveToolChange})=>{

    const handleToolClick = useCallbackCreator((type)=>{
        if( activeTool === type )
            onActiveToolChange(null);
        else
            onActiveToolChange(type);
    },[onActiveToolChange, activeTool]);

    const [isOpen,,, toggleOpen] = useBoolean();

    return (
        <div className='ElementsPicker'>
            <h5 onClick={toggleOpen} className='toolbox-title'>Nuevo elemento <i className={'fa fa-caret-'+(isOpen?'up':'down')}/></h5>

            {isOpen &&
            <div className='toolbox'>
                {mapItemsDefinition().map(item=>
                    <button
                        className={classNames('toolbox-button', activeTool === item.type && 'active')}
                        key={item.type}
                        onClick={handleToolClick(item.type)}
                    >
                        {item.name}
                    </button>
                )}
            </div>}
        </div>
    )
};

export default ElementsPicker;
