import React, {useContext, useEffect, useMemo, useState} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import moment from 'moment';
import DateRangeFilter, {useDateRangeFilter} from '../Filters/DateRangeFilter';
import './Reports.scss';
import _ from 'lodash';


const getDaysArrayBetweenDates = (from, to) => {
    const dates = []
    const currDate = moment(from);
    const lastDate = moment(to);
    do {
        dates.push(currDate.format('Y-MM-DD'));
    } while (currDate.add(1, "days").diff(lastDate) < 0);

    return dates;
}

const getReportRows = (report, dateRequest, searchTerm) => {
    if (report.length === 0)
        return [];
    const daysArray = getDaysArrayBetweenDates(dateRequest.from, dateRequest.to);

    const rows = _.filter(_.groupBy(report, function (flight) {
        return flight.flightNumber;
    }), (row, key) => {
        if (searchTerm) {
            return key.toUpperCase().search(searchTerm.toUpperCase()) !== -1
        } else {
            return true;
        }
    });


    const newRows = [];

    _.forEach(rows, (flightReport, key) => {
        const airline = flightReport[0].airline;
        const flightNumber = flightReport[0].flightNumber;
        _.each(daysArray, (day) => {
            const dayExists = _.find(flightReport, (dayReport) => {
                return dayReport.day === day
            })

            if (!newRows[key])
                newRows[key] = [];

            if (dayExists) {
                newRows[key].push(dayExists);
            } else {
                newRows[key].push({
                    airline,
                    flightNumber,
                    persons: 0,
                    day
                })
            }
        })
    })
    return _.values(newRows);
};

const loadingId = '@VisitsFlightsReport.report';
const VisitsFlightsReport = ({defaultDateRange}) => {
    const dateConfig = useMemo(() => ({initial: defaultDateRange}), [defaultDateRange]);
    const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);
    const api = useContext(ApiContext);
    const [searchTerm, setSearchTerm] = useState("");
    const onSearch = ({target: {value}}) => setSearchTerm(value);

    useEffect(() => {
        api.visits.flightsReport({
            params: dateRequest,
            loadingId
        });
    }, [api, dateRequest]);

    const report = useSelector(({api}) => api.visitsFlightsReport) || [];
    const loading = useSelector(({loadingIds}) => !!loadingIds[loadingId]);

    const reportRows = useMemo(() => getReportRows(report, dateRequest, searchTerm), [report, dateRequest, searchTerm]);

    //const [filter, setFilter] = useState();

    return (
        <div className='VisitsFlightsReport'>
            <DateRangeFilter {...dateFilterProps}/>
            <br/>
            <div className="row">
                <div className="col-md-4"><label>No. de vuelo:</label></div>

                <div className="col-md-5 text-right">
                    <div className="current-flights">
                        <div className="flight-filters">
                            <input className="form-control" value={searchTerm} onChange={onSearch} placeholder="Todos"/>
                        </div>
                    </div>
                </div>
            </div>


            {loading && <h3 className='text-center'>Cargando...</h3>}

            <div className='overflow-table'>
                <Table striped>
                    <thead>
                    <tr>
                        <th></th>
                        {getDaysArrayBetweenDates(dateRequest.from, dateRequest.to).map((date) => (
                            <th>
                                {date}
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {reportRows.map((flightStats, key) =>
                        <tr key={key}>
                            <td>{flightStats[0].airline} - {flightStats[0].flightNumber}</td>
                            {flightStats.map((dayStat) => (

                                    <td>{dayStat.persons}</td>
                                )
                            )}
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>

        </div>
    );
};

export default VisitsFlightsReport;
