import React, {useCallback, useContext, useState } from 'react';
import './_WarehouseNotifyTable.scss';
import EntitySelect from "../../../../../components/EntitySelect/EntitySelect";
import {employeeFullName} from "../../../../../utils/modelUtils/alma/employeeUtils";
import {warehouseConfigurationSGroups} from "../../../../../utils/modelUtils/almazen/warehouseUtils";
import useCallbackCreator from "../../../../../hooks/useCallbackCreator";
import _ from "lodash";
import {TideApiContext} from "../../../../../api/tideApiConfig";
import {NotifierContext} from "../../../../../utils/Notifier";


const NotifyFilters = {isHired: true, sGroups: ["employee_read_id", "employee_read_name", "employee_read_user", "employee_user_read_id"]};
const WarehouseNotifyTable = ({warehouse: wh}) => {

    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);

    const [addingUser, setAddingUser] = useState();
    const handleAddUser = useCallback(()=>{
        if(!addingUser)
            return;

            const usersIds = wh.stockNotificationUsers.map( e=>e.id );
            const userId = addingUser.user.split('/').pop();
            if(usersIds && usersIds.indexOf(Number(userId)) !== -1){
                notifier.info("A este usuario ya se le envían notificaciones");
                return;
            }
            usersIds.push(userId);
            api.warehouses.update({id:wh.id, params:{ stockNotificationUsers: usersIds, sGroups: warehouseConfigurationSGroups }});
            setAddingUser(null);
    },[api, wh, notifier, addingUser]);

    const handleRemoveUserPermission = useCallbackCreator((id)=>{
            const permissions = wh.stockNotificationUsers.map( e=>e.id );
            _.pull(permissions, id);
            api.warehouses.update({id:wh.id, params:{ stockNotificationUsers: permissions, sGroups: warehouseConfigurationSGroups}});
    },[wh]);


    return (
        <div className={"WarehouseNotifyTable"}>
            <div className='container-fluid p-header'>
                <div className='row'>
                    <div className='col-sm-6'>
                            <p className='perm-title'>Las notificaciones se envían a los siguientes usuarios</p> :
                    </div>
                    <div className='col-sm-6'>
                        <div className='select-cont'>
                            <EntitySelect
                                className='new-employee-select'
                                placeholder={'Agregar usuario a notificar'}
                                entity='employees'
                                getMethod='get2'
                                labelCreator={employeeFullName}
                                value={addingUser}
                                onChange={setAddingUser}
                                additionalFilters={NotifyFilters}
                            />
                            <button
                                className='btn btn-info'
                                onClick={handleAddUser}
                                disabled={!addingUser}>
                                Agregar usuario
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <table className='table table-striped'>
                <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Acción</th>
                </tr>
                </thead>
                <tbody>
                {wh.stockNotificationUsers?.map(user=>
                    <tr key={user.employee?.id}>
                        <td>{employeeFullName(user.employee)}</td>
                        <td><i className='fa fa-trash red-icon' onClick={handleRemoveUserPermission(user.employee?.id)}/></td>
                    </tr>)}
                </tbody>
            </table>
            {!wh.employeeWarehouses?.length &&
            <p className='empty-msg'>Aún no se asigna ningún usuario a este almacén. Solo los administradores podrán verlo.</p>}
        </div>
    );
};

export default WarehouseNotifyTable;
