import React, {useContext, useEffect, useState, useCallback} from 'react';
import "./_GolfCartScheduleDetail.scss";
import GolfCartSchedule from "../../../../../components/GolfCartSchedule/GolfCartSchedule";
import {SecurityContext} from "../../../../../utils/SecurityManager";
import GolfCartScheduleEditControl from "../components/GolfCartScheduleEditControl/GolfCartScheduleEditControl";
import {
    getEmptyGolfCartSchedule,
    golfCartScheduleDetailSGroups
} from "../../../../../utils/modelUtils/ee/golfCartScheduleUtils";
import {TideApiContext} from "../../../../../api/tideApiConfig";
import _  from 'lodash';
import {selectLoungeFilters} from "../../../../../utils/modelUtils/ee/loungesUtils";

const GolfCartScheduleDetail = ({ match }) => {

    const api = useContext(TideApiContext);
    const security = useContext(SecurityContext);
    const [golfCartSchedule, setGolfCartSchedule] = useState( getEmptyGolfCartSchedule() );
    const [editingTripIndex, setEditingTripIndex] = useState();
    const id = match?.params?.id;
    const [lounges, setLounges] = useState([]);

    useEffect(()=>{
        if(!id || id==="new")
            return;
        api.golfCartSchedules.get( {id, params:{sGroups:golfCartScheduleDetailSGroups, pagination: false}, customProp:'_'} )
            .then(schedule=>{
                setGolfCartSchedule({...schedule, availableDays: _.map(schedule.availableDays, 'id')})
            });
    },[api, id]);

    const {golfCartScheduledTrips, lounge} = golfCartSchedule;
    const currentTrip = golfCartScheduledTrips[editingTripIndex];
    const currentGates = currentTrip?.airportGates;

    useEffect(()=>{
        if(currentGates){
            let maxTime = 0;

            // calculate max time
            for(let i=0; i<=currentGates.length-1; i++){
                let gateLoungeTime = currentGates[i].airportGateLounges.filter((gateLounge) => gateLounge.lounge.id===lounge.id)[0]?.etaMinutes;

                if(maxTime<gateLoungeTime){
                    maxTime=gateLoungeTime;
                }
            }

            setGolfCartSchedule((golfCartSchedule) => ({...golfCartSchedule,
                golfCartScheduledTrips: [
                    ...golfCartSchedule.golfCartScheduledTrips.slice(0, editingTripIndex),
                    {
                        ...golfCartSchedule.golfCartScheduledTrips[editingTripIndex],
                        endTime: (maxTime + golfCartSchedule.golfCartScheduledTrips[editingTripIndex]["startTime"])
                    },
                    ...golfCartSchedule.golfCartScheduledTrips.slice(editingTripIndex+1)
                ]
            }));
        }
    },[lounge, editingTripIndex, currentGates]);

    const loadLounges = useCallback(() => {
        api.lounges.get({params: selectLoungeFilters})
            .then(lounges => {
                setLounges(lounges);
            });
    }, [api]);

    useEffect(() => {
        loadLounges();
    }, [loadLounges])

    return (
        <div className={"GolfCartScheduleDetail"}>
            {security.ee.canEditGolfCartSchedule() &&
            <GolfCartScheduleEditControl
                golfCartSchedule={golfCartSchedule}
                onChange={setGolfCartSchedule}
                editingTripIndex={editingTripIndex}
                setEditingTripIndex={setEditingTripIndex}
            />}
            <GolfCartSchedule
                golfCartSchedule={golfCartSchedule}
                editingTripIndex={security.ee.canEditGolfCartSchedule() && editingTripIndex}
                setEditingTripIndex={security.ee.canEditGolfCartSchedule() && setEditingTripIndex}
                lounges={lounges}
            />
        </div>
    );
};

export default GolfCartScheduleDetail;
