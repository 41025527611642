export const guestFullName=guest=>guest?`${guest.name||''} ${guest.patLastName||''} ${guest.matLastName||''}`:'';

export const searchSGroups=['guest_read_id', 'guest_read_name', 'guest_read_pat_last_name', 'guest_read_mat_last_name' ];

export const listSGroups = [
    'guest_read_id',
    'guest_read_full_name',
    'guest_read_city',
    'guest_read_country', 'country_read_spanish_name',
    'guest_read_email',
    'guest_read_is_homologated'
];

export const showSGroups = [
    'guest_read_id',
    'guest_read_full_name',
    'guest_read_city',
    'guest_read_gender',
    'guest_read_country', 'country_read_spanish_name',
    'guest_read_email',
    'guest_read_phone',
    'guest_read_non_grata_since',
    'guest_read_vip_since',
    'guest_read_visits', 'visit_read_id', 'visit_read_first_check_in_date', 'visit_read_lounge', 'lounge_read_name'
];
