import React, {useState, useEffect} from 'react';
import {Button} from 'react-bootstrap';
import ReactTable from 'react-table';
import useTideTable from '../../../hooks/table/useTideTable';

/**
 *
 * @param propsForModals
 * @param addText
 * @param header
 * @param title
 * @param tideTableConfig
 * @param AddModal
 * @param EditModal
 * @returns {JSX.Element}
 * @constructor
 */
const CrudPage = ({propsForModals = {}, addText, header, title, tideTableConfig, AddModal, EditModal}) => {
        const [addModalOpen, setAddModalOpen] = useState(false);
        const [toEdit, setToEdit] = useState(null);

        const {
            tableProps,
        } = useTideTable(tideTableConfig);
        const trProps = (state, rowInfo) => ({
            style: (rowInfo && rowInfo.original) ? {cursor: 'pointer'} : {},
            onClick: () => EditModal && setToEdit(rowInfo.original)
        });
        useEffect(() => {
            if (!!toEdit) {
                setAddModalOpen(false);
            }
        }, [toEdit]);

        return (
            <>
                <div className='container-fluid main-container CrudPage'>
                    {header}
                    {!!AddModal && <Button onClick={() =>setAddModalOpen(true)}>
                        {addText}
                    </Button>}
                    <h3>{title}</h3>
                    <ReactTable
                        {...tableProps}
                        getTrProps={trProps}
                    />
                </div>
                {addModalOpen && (
                    <AddModal {...propsForModals} onHide={() => setAddModalOpen(false)}/>
                )}
                {!!toEdit && (
                    <EditModal {...propsForModals} toEdit={toEdit} onHide={() => setToEdit(null)}/>
                )}

            </>
        );
    };

export default CrudPage;
