import React,{useEffect, useContext,useCallback} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Panel} from 'react-bootstrap';
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import useBoolean from "../../../../hooks/useBoolean";
import LoungeAccessMethodFormModal from "./LoungeAccessMethodFormModal";
import AccessMethodAdditionalConfigList from "./AccessMethodAdditionalConfigList";
import useSelectObjectFromArray from "../../../../hooks/useSelectObjectFromArray";
import ConfirmModal from "../../../../components/Modals/ConfirmModal";

const emptyArr=[];
const loadingId='@LoungeAccessMethodsList';
const LoungeAccessMethodsList=({parentLoungeAccessMethod})=>{

    //No manches, está muy largo ese nombre
    const parentLAM=parentLoungeAccessMethod;

    //  --------    Carga inicial   --------
    const api=useContext(ApiContext);
    const loadData=useCallback(()=>
        api.loungeAccessMethods.get({filters:{pagination:false, 'order[name]':'ASC', 'loungeParentAccessMethod[exists]':false}, loadingId})
    ,[api]);
    useEffect(()=>{
        if(!parentLAM)
            loadData();
    },[loadData, parentLAM]);
    const rootAccessMethods = useSelector(({api})=>api.loungeAccessMethods||emptyArr);
    const accessMethods= parentLAM?
        (parentLAM.loungeSubAccessMethods || []):
        rootAccessMethods;


    //  --------       Creación     --------
    const [addingAccessMethod, startAddingAccessMethod, stopAddingAccessMethod]=useBoolean();

    //  --------       Edición     --------
    const [editingAccessMethod, editAccessMethodAtIndex, stopEditingAccessMethod]=useSelectObjectFromArray(accessMethods);

    const afterSave=useCallback(()=>{
        stopAddingAccessMethod();
        stopEditingAccessMethod();
        loadData();
    },[stopAddingAccessMethod, stopEditingAccessMethod, loadData]);

    //  --------       Borrado     --------
    const [deletingAccessMethod, deleteAccessMethodAtIndex, stopDeletingAccessMethod]=useSelectObjectFromArray(accessMethods);
    const handleDeleteAccessMethod=useCallback(()=>{
        if(!deletingAccessMethod)
            return;
        api.loungeAccessMethods.delete({id:deletingAccessMethod.id, loadingId}).then(stopDeletingAccessMethod);
    },[deletingAccessMethod, api, stopDeletingAccessMethod]);

    const loading=useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    return (
        <div className='LoungeAccessMethodsList'>
            <CustomButton bsStyle='primary' className='pull-right' onClick={startAddingAccessMethod}>Nuevo {parentLAM&&'sub-'}método de acceso</CustomButton>
            {parentLAM&&
                <h3>Sub-métodos de acceso</h3>}
            <div className='clearfix'/>
            <br/>
            {!accessMethods.length && !loading &&
                <h4 className='text-center text-muted'>Ningún método de acceso registrado</h4>}
            {accessMethods.map( (accessMethod, i)=>
                <Panel
                    collapsible
                    key={accessMethod.id}
                    header={<span>{accessMethod.name} {accessMethod.isActive?'':<span className='text-danger small'>(Inactivo)</span>}</span>}
                    eventKey={accessMethod.id}
                    className={'access-method-block'}
                >
                    <div className='container-fluid'>

                        <div className='actions'>
                            <button className='delete-button' onClick={deleteAccessMethodAtIndex(i)}><i className='fa fa-trash'/></button>
                            <button className='edit-button' onClick={editAccessMethodAtIndex(i)}><i className='fa fa-pencil'/></button>
                        </div>

                        <div className='row'>
                            <div className='col-xs-12 col-md-4 data-block'>
                                <h4>Tiempo en sala</h4>
                                <span>{accessMethod.accessTime!==0? accessMethod.accessTime+ ' minutos' : 'Ilimitado'}</span>
                            </div>
                            <div className='col-xs-12 col-md-4 data-block'>
                                <h4>Máximo de acompañantes</h4>
                                <span>{accessMethod.maxAdults}</span>
                            </div>
                            <div className='col-xs-12 col-md-4 data-block'>
                                <h4>Menores de 12 años</h4>
                                <span>{accessMethod.maxKids}</span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-12 under-box'>
                                <div className='sub-data-container'>
                                    <h4>Configuración adicional:</h4>
                                    <AccessMethodAdditionalConfigList loungeAccessMethod={accessMethod} />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <hr/>
                            <LoungeAccessMethodsList
                                parentLoungeAccessMethod={accessMethod}
                            />
                        </div>
                    </div>
                </Panel>
            )}

            {addingAccessMethod&&
                <LoungeAccessMethodFormModal
                    onHide={stopAddingAccessMethod}
                    onSave={afterSave}
                    parentLoungeAccessMethod={parentLAM}
                />}

            {editingAccessMethod&&
                <LoungeAccessMethodFormModal
                    onHide={stopEditingAccessMethod}
                    onSave={afterSave}
                    loungeAccessMethod={editingAccessMethod}
                />}

            {deletingAccessMethod&&
                <ConfirmModal
                    message={`¿Estás seguro que deseas borrar el método de acceso ${deletingAccessMethod.name}?`}
                    onCancel={stopDeletingAccessMethod}
                    onConfirm={handleDeleteAccessMethod}
                    disabled={loading}
                    />
            }
        </div>
    );
};

export default LoungeAccessMethodsList;
