import React, {useState} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import {SingleDatePicker} from 'react-dates';
import moment from "moment";

const ExpectedDateSelector = ({expectedDate, onChange}) => {
    const [focused, setFocused] = useState(false);
    return (
        <FormGroup>
            <ControlLabel>Fecha estimada de llegada: </ControlLabel>
            <SingleDatePicker
                date={typeof expectedDate === "string"? moment(expectedDate):expectedDate}
                onDateChange={onChange}
                focused={focused}
                onFocusChange={({focused})=>setFocused(focused)}
                id='expected-date-selector'
                placeholder='Selecciona...'
            />
        </FormGroup>
    );
};

export default ExpectedDateSelector;
