import React,{useContext, useEffect, useMemo} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import DateRangeFilter, {useDateRangeFilter} from '../Filters/DateRangeFilter';
import {dataView} from '../utils';

const loadingId = '@ReservationStatsReport.report';

const dataAxis = [
    {
        getKey: 'shift',
        getDisplayName: ({shift})=>{
            switch(shift){
            case 'morning': return 'Matutino';
            case 'afternoon': return 'Vespertino';
            case 'night': return 'Nocturno';
            default: return 'Desconocido';
            }
        }
    },
    {
        getKey: 'loungeId',
        getDisplayName: 'loungeName'
    }
];
const prepareDataView = dataView(dataAxis);

const ReservationStatsReport = ({defaultDateRange}) => {
    const dateConfig = useMemo(()=>({initial: defaultDateRange}), [defaultDateRange]);
    const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);
    const params = useMemo(()=>({...dateRequest}), [dateRequest]);
    const api=useContext(ApiContext);
    useEffect(()=>{
        api.reservations.statsReport({loadingId, params});
    },[api, params]);
    const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);
    const report  = useSelector(({api})=>api.reservationStatsReport);
    const {axisData, foldDataValues} = useMemo(()=>prepareDataView(report, 'totalReservations'), [report]);
    const shifts = axisData[0];
    const lounges = axisData[1];
    const getValue = foldDataValues((x,y)=>parseInt(x)+parseInt(y), 0);
    return (
        <div className='ReservationStatsReport'>
            <DateRangeFilter {...dateFilterProps}/>
            {loading && <h3 className='text-center'>Cargando...</h3>}
            <Table>
                <thead>
                    <tr>
                        <th>Sala asignada</th>
                        {shifts.map(({key, displayName})=><th key={key}>{displayName}</th>)}
                        <th>Total sala</th>
                    </tr>
                </thead>
                <tbody>
                    {lounges.map(({key:loungeId, displayName})=>(
                        <tr key={loungeId}>
                            <td>
                                {displayName||'Sin asignar'}
                            </td>
                            {shifts.map(({key:shift})=>(
                                <td key={shift}>{getValue([shift, loungeId])}</td>
                            ))}
                            <td><strong>{getValue([undefined, loungeId])}</strong></td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <strong>Total</strong>
                        </td>
                        {shifts.map(({key:shift})=>(
                            <td key={shift}><strong>{getValue([shift, undefined])}</strong></td>
                        ))}
                        <td><strong>{getValue([])}</strong></td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    );
};

export default ReservationStatsReport;

