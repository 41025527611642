import React, {useCallback, useState} from 'react';
import ContentEditable from 'react-contenteditable';
import {connect} from 'react-redux';
import moment from 'moment';
import {useSocketApi} from "../../api/socketApi";
import _ from "lodash";


const Chat = ({chat, assignedChats})=>{
    const [newMsg, setNewMsg] = useState('');

    const socket = useSocketApi('chatMessage');

    const handleMessageChange = useCallback(({target})=>{
        setNewMsg(target.value);
    },[]);

    //const messages = api['chatMessages-'+chat.id] || [];
    const assignedChat = _.find(assignedChats, c=>c.id === chat.id);
    if(!assignedChat){
        throw new Error('Requested chat is not assigned');
    }
    const messages = assignedChat.messages;
    const sendMessage = async ()=>{
        setNewMsg('');
        await socket.apiCall('create', {
            parameters: {
                content: newMsg,
                chatId: chat.id
            },
            creator: 'sendFrom'
        });
    };


    return(
        <div className='Chat'>
            <div className='messages-container'>
                {messages.map((message)=>(
                    <div key={message.id}>
                        <div className={'message ' + (message.senderType!=='guest'? 'self':'')}>
                            {message.content}
                            <div className='time'>{moment(message.createdAt).format('HH:mm A')}</div>
                        </div>
                        <div className="clearfix" />
                    </div>
                ))}

            </div>
            <div className='writer-container'>
                <ContentEditable className='input' html={newMsg} onChange={handleMessageChange}/>
                <button onClick={sendMessage}><i className='fa fa-send'/></button>
            </div>
        </div>
    );
};

const mapStateToProps = ({api: {assignedChats}})=>({assignedChats});

export default connect(mapStateToProps)(Chat);
