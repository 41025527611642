import './AirlineForm.scss';
import React, {useState, useContext, useEffect, useMemo} from 'react';
import Selector from 'react-select';
import {ApiContext} from 'api/Api';
import {useSelector} from 'react-redux';
import CustomButton from 'elements/CustomButton/CustomButton';

const customProp = "@AirlineForm.Store";
const loadingId = "@AirlineForm.Loader";
const AirlineForm = React.memo(({airline, onSubmit}) => {
    //Initializers
    const api = useContext(ApiContext);
    const { id, name, code, airlineOrigin:currentAirlineOrigin } = airline || {};

    //State
    const [airlineOrigin, setAirlineOrigin] = useState(null);

    //Setting airlineOrigin if exists
    useEffect(() => {
        if(currentAirlineOrigin?.id && currentAirlineOrigin?.spanishName){
            const {id, spanishName} = currentAirlineOrigin;
            setAirlineOrigin({value: id, label: spanishName});
        }
    }, [currentAirlineOrigin]);

    //Retrieving countries:
    useEffect(() => {
        const payload = {params: {pagination: false}, customProp, loadingId};
        api.countries.get(payload);
    }, [api]);

    //Redux Store
    const countries = useSelector(({api}) => api?.[customProp] || []);
    const loading = useSelector(({loadingIds}) => !!loadingIds[loadingId]);

    //Formatting countries for selector (component) options:
    const countriesOptions = useMemo(() => {
        return countries.map( ({id, spanishName}) => ({value: id, label: spanishName}) );
    }, [countries]);

    //Handlers
    const handleFormSubmit = event => {
        event.preventDefault();
        const params = { name, code, airlineOrigin: airlineOrigin?.value || null };
        const payload = {id, params};
        (onSubmit) && onSubmit(payload);
    };

    return (
        <form className="AirlineForm" onSubmit={handleFormSubmit}>
            <label className="form-group">
                Nombre:
                <input
                    type="text"
                    className="form-control"
                    disabled={true}
                    defaultValue={name}
                />
            </label>
            <label className="form-group">
                Código:
                <input
                    type="text"
                    className="form-control"
                    disabled={true}
                    defaultValue={code}
                />
            </label>
            <label className="form-group">
                Orígen de aerolínea:
                <Selector
                    value={airlineOrigin}
                    onChange={setAirlineOrigin}
                    options={countriesOptions}
                    isLoading={loading}
                />
            </label>
            <CustomButton fill block type="submit">
                Guardar
            </CustomButton>
        </form>
    );
});
export default AirlineForm;