import React, {useCallback} from 'react';
import TideEntitySelect from "../../../TideEntitySelect/TideEntitySelect";

const EntityField = ({value, onChange, field}) => {

    const {name, label, filterLocal, ...tideEntityProps} = field;

    const handleChange = useCallback((value)=>onChange && onChange(value, name),[onChange, name]);

    return (
        <div className={"EntityField"}>
            <span>{label}</span>
            <TideEntitySelect
                entity={field.entity}
                value={value}
                onChange={handleChange}
                placeholder={label}
                filterLocal={filterLocal!==false}
                {...tideEntityProps}
            />
        </div>
    );
};

export default EntityField;
