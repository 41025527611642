import React, {useContext, useMemo, useState} from 'react';
import ReportBox from "./ReportBox";
import moment from 'moment';
import {TideApiContext} from "../../../api/tideApiConfig";
import join from 'url-join';
import qs from 'qs';
import {purchaseProductsMonthlyReportFilters} from "../../../utils/modelUtils/jazz/purchaseProductUtils";

const PurchaseProductsMonthlyReport = () => {

    const api = useContext(TideApiContext);

    const monthList = useMemo(()=>{
        const monthCount = moment().diff( '2019-08-01', "months" );
        const monthList=[];
        for ( let i=0; i<monthCount; i++ )
            monthList.push( moment().subtract( i, "months" ) );
        return monthList;
    },[]);

    const [month, setMonth] = useState( monthList[0].format("YYYY-MM")+"-01" );

    const url = useMemo(()=>{
        const filters = { ...purchaseProductsMonthlyReportFilters,
            "purchaseOrder.createdDate[after]":moment(month).startOf("month").format("YYYY-MM-DD HH:mm"),
            "purchaseOrder.createdDate[before]":moment(month).endOf("month").format("YYYY-MM-DD HH:mm"),
            token: api.token
        };
        const query  = qs.stringify(filters);
        return join(api.host, "api/purchase_products.xlsx?" + query);
    },[api, month]);

    return (
        <ReportBox className={"PurchaseProductsMonthlyReport"}
                   title="Listado de compras mensuales"
                   description="Muestra todas las compras realizadas en un mes. El reporte en excel contiene una
                   fila por cada producto de cada orden de compra realizada."
                   valid
                   reportUrl={url}
        >
            <p className='filter-label'>Fecha del reporte:</p>
            <select className='input form-control' value={month} onChange={e=>setMonth(e.target.value)}>
                {monthList.map( (month, index)=>
                    <option value={month.format("YYYY-MM")+"-01"} key={index}>{month.format("YYYY - MMMM")}</option>
                )}
            </select>
        </ReportBox>
    );
};

export default PurchaseProductsMonthlyReport;
