import React, {useContext, useState} from 'react';
import './_RecipeFooter.scss';
import useBoolean from "../../../../../../hooks/useBoolean";
import {TideApiContext} from "../../../../../../api/tideApiConfig";
import {useSelector} from "react-redux";
import RecipeGallery from "../RecipeGallery/RecipeGallery";
import {SecurityContext} from "../../../../../../utils/SecurityManager";

const RecipeFooter = ({recipe, onChange}) => {

    const api = useContext(TideApiContext);
    const security = useContext(SecurityContext);

    const [editingDescription, startEditingDescription, stopEditingDescription] = useBoolean();
    const [editingTechnicalDescription, startEditingTechnicalDescription, stopEditingTechnicalDescription] = useBoolean();
    const [description, setDescription] = useState()
    const [technicalDescription, setTechnicalDescription] = useState()

    const editDescription = ()=>{
        setDescription(recipe?.description||'');
        startEditingDescription();
    };
    const editTechnicalDescription = ()=>{
        setTechnicalDescription(recipe?.technicalDescription||'');
        startEditingTechnicalDescription();
    };

    const loadingDirId = 'RecipeFooter.recipe.description'
    const saveDescription = ()=>{
        api.orozcoRecipes.update({ id:recipe.id, params:{description}, loadingId:loadingDirId })
            .then(()=>{
                onChange();
                stopEditingDescription();
            });
    };
    const saveTechnicalDescription = ()=>{
        api.orozcoRecipes.update({ id:recipe.id, params:{technicalDescription}, loadingId:loadingDirId })
            .then(()=>{
                onChange();
                stopEditingTechnicalDescription();
            });
    };
    const loadingDescription = useSelector(s=>!!s.loadingIds[loadingDirId]);


    return (
        <div className={"RecipeFooter"}>
            <div className='instructions-container'>
                <h3 className='recipe-subtitle'>Preparación</h3>
                <div className='instructions'>
                    {editingDescription &&
                    <>
                        <textarea className='textarea' value={description} onChange={e=>setDescription(e.target.value)}/>
                        <button className='edit-btn cancel' onClick={stopEditingDescription} disabled={loadingDescription}>
                            <i className='fa fa-times link'/>
                        </button>
                        <button className='edit-btn' onClick={saveDescription} disabled={loadingDescription}>
                            <i className='fa fa-check link'/>
                        </button>
                    </>}
                    {!editingDescription &&
                    <>
                        <pre className='text'>{recipe?.description||'Aún no hay indicaciones de preparación'}</pre>
                        {security.katharsis.canEditRecipes() &&
                        <button className='edit-btn' onClick={editDescription}>
                            <i className='fa fa-pencil link'/>
                        </button>}
                    </>}
                </div>
            </div>

            <div className='instructions-container'>
                <h3 className='recipe-subtitle'>Ficha técnica</h3>
                <div className='instructions'>
                    {editingTechnicalDescription &&
                    <>
                        <textarea className='textarea' value={technicalDescription} onChange={e=>setTechnicalDescription(e.target.value)}/>
                        <button className='edit-btn cancel' onClick={stopEditingTechnicalDescription} disabled={loadingDescription}>
                            <i className='fa fa-times link'/>
                        </button>
                        <button className='edit-btn' onClick={saveTechnicalDescription} disabled={loadingDescription}>
                            <i className='fa fa-check link'/>
                        </button>
                    </>}
                    {!editingTechnicalDescription &&
                    <>
                        <pre className='text'>{recipe?.technicalDescription||'Aún no hay una ficha técnica'}</pre>
                        {security.katharsis.canEditRecipes() &&
                        <button className='edit-btn' onClick={editTechnicalDescription}>
                            <i className='fa fa-pencil link'/>
                        </button>}
                    </>}
                </div>
                <RecipeGallery recipe={recipe} onChange={onChange} />
            </div>
        </div>
    );
};

export default RecipeFooter;
