import React, {useCallback} from 'react';
import './_TextField.scss';

const TextField = ({value = '', onChange, field, inputProps}) => {

    const {name, label, hideLabel} = field;
    const handleChange = useCallback((e) => onChange && onChange(e.target.value, name), [onChange, name]);

    const labelVisible = !!value && !hideLabel;

    return <div className='TextField'>
        {labelVisible && <span className='label'>{label}</span>}
        <input
            className='input slim'
            type={field.fieldType||'text'}
            placeholder={label}
            value={value}
            onChange={handleChange}
            {...inputProps}
        />
    </div>;
};

export default React.memo(TextField);
