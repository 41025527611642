import React, {useContext, useMemo, useState, useCallback, useEffect} from 'react';
import ReactEcharts from "echarts-for-react";
import {Table} from "react-bootstrap";
import useTideEntity from "../../../hooks/useTideEntity";
import {getProperty} from "../../../utils/jsUtils";
import {ApiContext} from "../../../api/Api";
import defaultProductPhoto from "../../../assets/img/no-product.png";
import {DateRangePicker} from "react-dates";
import useDateRangePickerState from "../../../hooks/useDateRangePickerState";
import moment from 'moment';
import moneyFormatter from "../../../utils/moneyFormatter";
import {convertFromSmallestUnit, convertToSmallestUnit, displayAmount} from "../../../utils/modelUtils/almazen/unitsUtility";
import _ from 'lodash';
import {maxDecimals} from "../../../utils/MathUtils";
import PurchaseProductList from "./PurchaseProductList";


const ProductReport=({productId})=>{

    const [product, loading, notFound]=useTideEntity('products', productId);
    const api=useContext(ApiContext);

    const periodState = useDateRangePickerState({startDate: moment().subtract(6,'months'), endDate:moment()});
    const [report, setReport] = useState(null);

    const {startDate, endDate}=periodState.dateRange;
    useEffect(()=>{
        api.products.getReport({id:productId, fromDate:startDate, toDate:endDate}).then(setReport);
    },[api, productId, startDate, endDate]);

    //Convert the quantity from the report to the display unit
    const displayUnitMonthlyReport = useMemo(()=>
        ((product && getProperty(report,'byMonth'))||[]).map((month)=>({
            ...month,
            quantitySum: convertFromSmallestUnit(month.quantitySum, product.displayUnit?product.displayUnit:product.smallestUnit, product),
        }))
    ,[report, product]);

    const monthlyAverages=useMemo(()=>{
        const zero={spent:0, quantity:0, count:0.00000000000001};
        if(!report || !report.byMonth.length)
            return zero;

        return report.byMonth.reduce((acc, month)=>{
            return {
                spent: acc.spent+Number(month.spentSum),
                quantity: acc.quantity + Number(month.quantitySum),
                count: acc.count+1,
            }
        },zero);
    },[report]);

    const [activeGraph, setActiveGraph]=useState('spentSum');
    const toggleGraph=useCallback(()=>setActiveGraph( (oldActive)=>oldActive==='spentSum'?'quantitySum':'spentSum'),[]);

    const chartOptions = !report?{}:{
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        xAxis: {
            type: 'category',
            data: _.map(report.byMonth,'month')
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: _.map(displayUnitMonthlyReport, activeGraph).map((num)=>maxDecimals(num,2)),
            type: 'line',
        }]
    };

    if(loading)
        return <h3>Cargando...</h3>;
    if(notFound || !product)
        return <h3>Producto no encontrado</h3>;

    const image=product.photo? api.appFiles.getUrl(product.photo.id):defaultProductPhoto;

    return (
        <div className='ProductReport'>
            <div className='header'>
                <div className='image-container'>
                    <img className='product-image' src={image} alt='Producto' />
                </div>
                <div className='title-container'>
                    <h1>{product.name}</h1>
                    <h4>{getProperty(product,'line','name')||' '}</h4>
                </div>
                <div className='clearfix' />
            </div>

            <div className='date-picker'>
                <p>Periodo del reporte</p>
                <DateRangePicker {...periodState.props} />
            </div>

            <div className='graphs-block'>
                <div className='left-handles'>
                    <div className={'handle '+(activeGraph==='spentSum'?'selected':'')} onClick={toggleGraph}>
                        <p className='name'>Gasto promedio mensual</p>
                        <p className='data'>{moneyFormatter(monthlyAverages.spent/monthlyAverages.count, 1)}</p>
                        <p className='daily'>{moneyFormatter(monthlyAverages.spent/monthlyAverages.count/30, 1)} diarios</p>
                    </div>
                    <div className={'handle '+(activeGraph==='quantitySum'?'selected':'')} onClick={toggleGraph}>
                        <p className='name'>Consumo promedio mensual</p>
                        <p className='data'>{displayAmount(monthlyAverages.quantity/monthlyAverages.count, product)}</p>
                        <p className='daily'>{displayAmount(monthlyAverages.quantity/monthlyAverages.count/30, product)} diarios</p>
                    </div>
                </div>
                <div className='graph-container'>
                    <ReactEcharts
                        option={chartOptions}
                        style={{height:"460px"}}
                    />
                </div>
            </div>

            <div className='providers-block'>
                <h3>Proveedores</h3>
                <Table striped>
                    <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Compras</th>
                        <th>Cantidad comprada</th>
                        <th>Precio promedio por {product.displayUnit.name}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {(getProperty(report,'byProvider')||[]).map((providerReport, i)=>
                        <tr key={i}>
                            <td>{providerReport.provider}</td>
                            <td>{providerReport.purchaseRequisitionsCount}</td>
                            <td>{displayAmount(providerReport.quantitySum, product)}</td>
                            <td>{moneyFormatter(convertToSmallestUnit(providerReport.priceAverage, product.displayUnit, product),1)}</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>

            {product &&
            <div className='purchase-history-block'>
                <h3>Historial de compras</h3>
                <p className='small text-muted'>Precios por {product.displayUnit.name}</p>
                <PurchaseProductList
                    product={product}
                />
            </div>
            }

        </div>
    );
};

export default ProductReport;
