import React, {useContext} from "react";
import {ApiContext} from "../../api/Api";
import aviImage from "../../assets/img/file-thumbs/avi.svg";
import docThumb from "../../assets/img/file-thumbs/doc.svg";
import fileThumb from "../../assets/img/file-thumbs/file.svg";
import jpgThumb from "../../assets/img/file-thumbs/jpg.svg";
import pngThumb from "../../assets/img/file-thumbs/png.svg";
import pdfThumb from "../../assets/img/file-thumbs/pdf.svg";
import pptThumb from "../../assets/img/file-thumbs/ppt.svg";
import textThumb from "../../assets/img/file-thumbs/txt.svg";
import xlsThumb from "../../assets/img/file-thumbs/xls.svg";
import zipThumb from "../../assets/img/file-thumbs/zip.svg";
import mp3Thumb from "../../assets/img/file-thumbs/mp3.svg";



const AppFileThumb = ({appFile, onClick}) => {
    const api = useContext(ApiContext);
    const downloadUrl = api.createFileUrl(appFile, true);
    const embedUrl = api.createFileUrl(appFile, false);

    return (
        <div className="app-file-container">
            <div className="app-file-thumb" title={appFile.originalName} data-src={embedUrl} data-filename={appFile.originalName} onClick={onClick}>
                <img className="img-responsive" src={getFileType(appFile)} alt=""/>
                <br/>
                <span className="name">{appFile.originalName}</span>
            </div>
            <a href={downloadUrl} className="download"><i className="fa fa-download"/></a>
        </div>
    );

};

const getFileType = (appFile) => {
    const mimeType = appFile.mimeType;

    if (mimeType.indexOf('text') !== -1)
        return textThumb;

    if (mimeType.indexOf('excel') !== -1 || mimeType.indexOf('sheet') !== -1)
        return xlsThumb;

    if (mimeType.indexOf('zip') !== -1)
        return zipThumb;

    if (mimeType.indexOf('pdf') !== -1)
        return pdfThumb;

    if (mimeType.indexOf('png') !== -1)
        return pngThumb;

    if (mimeType.indexOf('jpeg') !== -1)
        return jpgThumb;

    if (mimeType.indexOf('word') !== -1)
        return docThumb;

    if (mimeType.indexOf('xml') !== -1)
        return fileThumb;

    if (mimeType.indexOf('mp3') !== -1)
        return mp3Thumb;

    if (mimeType.indexOf('ppt') !== -1)
        return pptThumb;

    if (mimeType.indexOf('avi') !== -1)
        return aviImage;

    return fileThumb;

};

export default AppFileThumb;

