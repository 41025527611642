import React, {useContext, useEffect, useState} from 'react';
import useBoolean from "hooks/useBoolean";
import GuestChat from "../../GuestChat/GuestChat";
import {ChatManagerContext} from "../../../utils/ChatManager/ChatManager";


const SingleChatBar = ({chat}) => {
    const chatManager = useContext(ChatManagerContext);
    const [active, , , toggleActive] = useBoolean(null);
    const [unattended, setUnattended] = useState(false);

    if(unattended) console.log('Removing var not used warning');

    const {messages} = chat;
    const {name, lastName} = chat.attending;

    useEffect(() => {
        // When 'messages' changes, is because a message is received (or sent)
        // if the chat is not active we'll set the unattended flag to true.
        if (!active) {
            setUnattended(true);
            return;
        }
        setUnattended(false);
    }, [messages, active]);//Added active to prevent warning

    useEffect(()=>{
        // An active chat is never unattended
        if(active){
            setUnattended(false);
        }
    },[active]);

    const markAsSolved = () => {
        chatManager.markChatAsSolved(chat);
    };

    let className = 'SingleChatBar ';
    if(active){
        className+='active '
    }
    else if(active !== null){
        className+='closed ';
    }
    /*
    if(unattended){
        className+='bounce ';
    }
  overflow-x: auto;
     */

    return (
        <div className={className}>
            <div className='top-bar' onClick={toggleActive}>
                <span className={'status-point'}/>
                {name} {lastName}
            </div>
            {
                active && (
                    <div className='control-bar'>
                        <button onClick={markAsSolved}>
                            Marcar chat como resuelto
                        </button>
                    </div>
                )
            }

            <div className='chat-container'>
                <GuestChat chat={chat}/>
            </div>
        </div>
    );
};

export default SingleChatBar;
