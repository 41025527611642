import React, {Component} from 'react';
import { Modal, Table} from 'react-bootstrap';
import Button from "../../../../elements/CustomButton/CustomButton";
import moment from 'moment';
import 'react-select/dist/react-select.min.css';
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";


export default class LayoffRequestModal extends Component {

    state = {};

    render() {

        const req = this.props.request;

        const canApprove = req.supervisor && this.props.me.employee.id === req.supervisor.id;

        return (
            <div>
                <Modal
                    show={true}
                    onHide={this.props.onHide}
                >
                    <Modal.Header closeButton >
                        <Modal.Title>Detalle de solicitud {req.isApproved === false?"rechazada":""}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <Table>
                            <tbody>
                            <tr>
                                <td >Solicitante</td>
                                <td >{employeeFullName(req.requestedBy)}</td>
                            </tr>
                            <tr>
                                <td >Fecha de solicitud</td>
                                <td >{moment(req.createdDate).format('DD/MM/YYYY')}</td>
                            </tr>
                            <tr>
                                <td >Persona a dar de baja</td>
                                <td >{employeeFullName(req.employee)}</td>
                            </tr>
                            <tr>
                                <td >Fecha de terminación</td>
                                <td >{moment(req.layoffRequestDate).format('DD/MM/YYYY')}</td>
                            </tr>
                            </tbody>
                        </Table>

                        <p>Motivo de baja</p>
                        <pre>
                            {req.requestComment}
                        </pre>

                        <hr/>
                        <h4>Aprobación</h4>

                        <Table>
                            <tbody>
                            <tr>
                                <td >Debe aprobar</td>
                                <td >{employeeFullName(req.supervisor)}</td>
                            </tr>
                            <tr>
                                <td >Estado</td>
                                <td >{ req.isApproved === null? 'Pendiente':
                                    ( req.isApproved? 'Aprobada':'Rechazada' )
                                }</td>
                            </tr>
                            </tbody>
                        </Table>

                        { req.supervisorComment?
                            <div>
                                <p>Motivo del rechazo:</p>
                                <pre>
                                    {req.supervisorComment}
                                </pre>
                            </div>
                            :null}

                    </Modal.Body>

                    <Modal.Footer>

                        <Button bsStyle='danger' className='pull-left' onClick={this.props.onHide} >Cerrar</Button>

                        {/* Buttons for pending requisitions */}
                        {req.isApproved === true && this.props.canFire?
                        <span>
                            <Button bsStyle='danger' onClick={()=>this.setState({rejecting:true})}>Rechazar</Button>
                            {req.isReviewed ?
                                <Button bsStyle='danger' onClick={() => this.props.onFire(req)}>Despedir ahora</Button> :
                                <Button bsStyle='success' onClick={() => this.props.onReview(req)}>Acuse de recibo</Button>
                            }

                        </span>:null}&nbsp;

                        {req.isApproved === null && canApprove?
                        <span>
                            <Button bsStyle='danger' onClick={()=>this.setState({rejecting:true})}>Rechazar</Button>
                            <Button bsStyle='success' onClick={ ()=>this.props.onApprove( req ) }>Aprobar</Button>
                        </span>:null}&nbsp;

                        {/* I can delete requisition that I own and haven't been approved */}
                        {req.isApproved === null && req.requestedBy && req.requestedBy.id === this.props.me.employee.id?
                            <Button bsStyle='danger' onClick={()=>this.props.onDelete(req)}>Borrar</Button>
                            :null}

                    </Modal.Footer>
                </Modal>


                {/* Reject requisition confirmation Modal */}

                <Modal
                    onHide={()=>this.setState({rejecting:false})}
                    show={this.state.rejecting}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Motivo de rechazo
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Escribe el motivo por el que rechazas esta solicitud:</p>
                        <textarea
                            className='form-control'
                            value={this.state.supervisorComment}
                            onChange={(e)=>this.setState({supervisorComment:e.target.value})}
                        />
                    </Modal.Body>
                    <Modal.Footer>

                        <Button bsStyle='danger' className='pull-left' onClick={()=>this.setState({rejecting:false})} >Cancelar</Button>

                        <Button bsStyle='danger' onClick={ ()=>this.props.onReject( req, this.state.supervisorComment) }>Rechazar</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}
