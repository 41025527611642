
export const listSGroups = [
    'budget_request_read',
    'budget_request_read_id',
    'budget_request_read_approved_1',
    'budget_request_read_approved_2',
    'budget_request_read_status',
    'budget_request_read_approved_date_1',
    'budget_request_read_approved_date_2',
    'budget_request_read_details',
    'creation_date',
        'user_read',
        'employee_read_full_name',
        'cassette_read'
];

export const listSGroupsDetails = [
    'budget_request_read_id',
    'budget_request_read_approved_1',
    'budget_request_read_approved_2',
    'budget_request_read_status',
    'budget_request_read_approved_date_1',
    'budget_request_read_approved_date_2',
    'budget_request_read_details',
    'budget_request_read_real_budget_last_month',
    'budget_request_read_real_budget_before_previous',
        'budget_request_detail_read_jazz_area',
            'jazz_area_read',
            'jazz_area_name',
                'jazz_area_read_monthly_used_budget',
        'user_read',
        'employee_read_full_name',
        'cassette_read',
        'budget_request_detail_read_calculated_budget_last_month',
        'budget_request_detail_read_calculated_budget_before_previous',
        'budget_request_detail_read_real_budget_last_month',
        'budget_request_detail_read_real_budget_before_previous',
        'budget_request_detail_read_budget',
        'budget_request_detail_read_id'
];

export const budgetRequestStatus={
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
};

export const budgetRequestStatusTrans={
    [budgetRequestStatus.PENDING]:'Pendiente',
    [budgetRequestStatus.APPROVED]:'Aprobado',
    [budgetRequestStatus.REJECTED]:'Rechazado'
};

export const budgetDetailToForm=(budgetDetail)=>{
    let values = {};

    budgetDetail.forEach((detail) => {
        values[detail.id] = detail.budget;
    });
    
    return {
        ...values    
    };
};