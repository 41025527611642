import React, {useState, useEffect, useCallback, useContext, useMemo} from 'react';
import {Col, Row} from "react-bootstrap";
import {DateRangePicker} from "react-dates";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import Switch from "react-bootstrap-switch";
import {SecurityContext} from "../../../../utils/SecurityManager";
import TideEntitySelect from "../../../../components/TideEntitySelect/TideEntitySelect";
import {employeeFullName, userEmployeeNameSearchAndShiftSGroups} from "../../../../utils/modelUtils/alma/employeeUtils";
import {selectLoungeFilters} from "../../../../utils/modelUtils/ee/loungesUtils";
import { orozcoProductRecipeListSGroups } from '../../../../utils/modelUtils/orozco/orozcoProductUtils';

const loungeAccessMethodLabel = ( lam )=>lam.fullName;
const employeeAdditionalFilters = { sGroups: userEmployeeNameSearchAndShiftSGroups, isHired: true };

const shifts = [
    {
        id: 'M',
        name: 'Matutino'
    },
    {
        id: 'V',
        name: 'Vespertino'
    },
    {
        id: 'N',
        name: 'Nocturno'
    },
];

const QuestionnaireStatsFilters = ({questionnaireReport, onFilterUse,loading})=>{

    const security = useContext(SecurityContext);

    const [filtersOptions, setFiltersOptions] = useState({});
    const [dateFilters, setDateFilters] = useState({startDate:moment().subtract(1,'month'), endDate:moment()});
    const [filledByFilter, setFilledByFilter] = useState([]);
    const [visitAttendingByFilter, setVisitAttendingByFilter] = useState([]);
    const [visitCreatedByUsersFilter, setVisitCreatedByUsersFilter] = useState([]);
    const [loungeAccessMethodFilter, setLoungeAccessMethodFilter] = useState([]);
    const [airlineFilter, setAirlineFilter] = useState([]);
    const [loungeFilter, setLoungeFilter] = useState([]);
    const [shiftFilter, setShiftFilter] = useState([]);
    const [filterByLoungeAndShift, setFilterByLoungeAndShift] = useState([]);
    const [receipeFilter, setReceipeFilter] = useState([]);

    const [isMailedFilter, setIsMailedFilter] = useState(false);

    const [focusedDatePicker, setFocusedDatePicker ] = useState(null);

    /**
     * Every time we get a new report, we extract all the different answers that come in it.
     * Then we make this answers the options to filter with.
     * Note that we preserve the old options because they will obviously disappear when we filter with any option of
     * the same question.
     */
    useEffect(()=>{

        if (!questionnaireReport || !questionnaireReport.result) return;

        const filterableQuestions = getFilterableQuestionStats( questionnaireReport);
        if (!filterableQuestions.length) return;

        const newFiltersOptions = filterableQuestions.reduce((acc, questionStat) => {

            const oldValues = filtersOptions[ questionStat.questionId ] || [];
            const newValues = questionStat.stats.map( stat=>stat.answer );
            acc[ questionStat.questionId ] = _.union(oldValues, newValues);

            return acc;

        }, {});
        setFiltersOptions(newFiltersOptions);
        // eslint-disable-next-line
    }, [questionnaireReport]);

    const setFilterAndFillLoungeAndShift = useCallback((e) => {
        const selectedLounges = e.reduce((acc, val) => {
            return acc.filter(lounge => lounge.id === val.lounge.id) ? [...acc, val?.lounge] : [...acc];
        }, []);

        const selectedShifts = e.reduce((acc, val) => {
            const shiftName = shifts.find(shift => shift.id===val?.shift.type)?.name;
            return acc.filter(shift => shift.id === val.shift.id) ? [...acc,
                {id: val?.shift.id, value: val?.shift.id, label: shiftName}
            ] : [...acc];
        }, []);

        setLoungeFilter(selectedLounges);
        setShiftFilter(selectedShifts);
        setFilterByLoungeAndShift(e);
    }, []);

    const useFilters = useCallback(()=>{
        const filters = {};

        if(dateFilters.startDate) filters.fromDate = dateFilters.startDate.format('YYYY-MM-DDT00:00');
        if(dateFilters.endDate) filters.toDate = dateFilters.endDate.format('YYYY-MM-DDT23:59');

        let filtersCount = 0;
        const filterAnswers={};

        if(filtersCount) filters.filterAnswers = filterAnswers;

        if( filledByFilter?.length)
            filters.filledBy=filledByFilter;

        if ( visitCreatedByUsersFilter?.length)
            filters.visitCreatedByUsers = visitCreatedByUsersFilter.map( employee=>({id: employee.user.id }) );

        if ( visitAttendingByFilter?.length)
            filters.visitAttendingBy = visitAttendingByFilter;

        if ( loungeAccessMethodFilter?.length)
            filters.loungeAccessMethods = loungeAccessMethodFilter;

        if ( airlineFilter?.length)
            filters.airlines = airlineFilter;

        if (loungeFilter?.length)
            filters.visitLounges = loungeFilter;

        if (shiftFilter?.length)
            filters.visitShiftTypes = shiftFilter;

        if (receipeFilter.length)
            filters.orozcoProducts = receipeFilter;

        if(isMailedFilter)
            filters.isMailed=true;

        onFilterUse(filters);

    },[dateFilters, onFilterUse, filledByFilter, visitAttendingByFilter, visitCreatedByUsersFilter, isMailedFilter,
             loungeAccessMethodFilter, airlineFilter, loungeFilter, shiftFilter, receipeFilter]);

    const mailedChangeHandler = useCallback((s, val)=>setIsMailedFilter(val), []);

    const shiftsOptions = useMemo(() => {
        return shifts.map((shift) => ({id: shift.id, value: shift.id, label: shift.name}));
    }, []);

    return (
        <div className='container-fluid QuestionnaireStatsFilters'>

            <Row>
                <Col lg={3} md={4} sm={6}>
                    <div>
                        <h5 className='filter-title'>Rango de fechas</h5>
                        <DateRangePicker
                            startDate={dateFilters.startDate} // momentPropTypes.momentObj or null,
                            endDate={dateFilters.endDate} // momentPropTypes.momentObj or null,
                            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                            onDatesChange={setDateFilters} // PropTypes.func.isRequired,
                            focusedInput={focusedDatePicker} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={setFocusedDatePicker} // PropTypes.func.isRequired,
                            isOutsideRange={isDateOutOfRange}
                            minimumNights={0}
                        />
                    </div>
                </Col>

            </Row>

            {questionnaireReport.questionnaire.isGcare && security.ee.canSeeGCareStats() &&
            <>
                <Row>
                    <hr/>
                    <h5>Filtros de G-Care</h5>

                    <Col lg={3} md={4} sm={6}>
                        <div>
                            <h5 className='filter-title'>Creado por usuarios</h5>
                            <TideEntitySelect
                                entity={'employees'}
                                getMethod={'getSimple'}
                                filterBy='search'
                                multi
                                labelCreator={employeeFullName}
                                additionalFilters={employeeAdditionalFilters}
                                onChange={setFilledByFilter}
                                value={filledByFilter}
                            />

                        </div>
                    </Col>

                    <Col lg={3} md={4} sm={6}>
                        <div>
                            <h5 className='filter-title'>Anfitrión:</h5>
                            <TideEntitySelect
                                entity={'employees'}
                                getMethod={'getSimple'}
                                filterBy='search'
                                multi
                                labelCreator={employeeFullName}
                                additionalFilters={employeeAdditionalFilters}
                                onChange={setVisitAttendingByFilter}
                                value={visitAttendingByFilter}
                            />
                        </div>
                    </Col>

                    <Col lg={3} md={4} sm={6}>
                        <div>
                            <h5 className='filter-title'>Recepcionista:</h5>
                            <TideEntitySelect
                                entity={'employees'}
                                getMethod={'getSimple'}
                                filterBy='search'
                                multi
                                labelCreator={employeeFullName}
                                additionalFilters={employeeAdditionalFilters}
                                onChange={setVisitCreatedByUsersFilter}
                                value={visitCreatedByUsersFilter}
                            />
                        </div>
                    </Col>

                    <Col lg={3} md={4} sm={6}>
                        <div>
                            <h5 className='filter-title'>Método de acceso:</h5>
                            <TideEntitySelect
                                entity={'loungeAccessMethods'}
                                filterBy={"fullName"}
                                labelCreator={loungeAccessMethodLabel}
                                multi
                                onChange={setLoungeAccessMethodFilter}
                                value={loungeAccessMethodFilter}
                            />
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col lg={3} md={4} sm={6}>
                        <div>
                            <h5 className='filter-title'>Por turno y sala de usuario:</h5>
                            <TideEntitySelect
                                entity={'employees'}
                                filterBy='search'
                                multi
                                labelCreator={employeeFullName}
                                additionalFilters={employeeAdditionalFilters}
                                onChange={setFilterAndFillLoungeAndShift}
                                value={filterByLoungeAndShift}
                            />

                        </div>
                    </Col>

                    <Col lg={3} md={4} sm={6}>
                        <div>
                            <h5 className='filter-title'>Aerolínea:</h5>
                            <TideEntitySelect
                                entity={'airlines'}
                                multi
                                onChange={setAirlineFilter}
                                value={airlineFilter}
                            />
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div>
                            <h5 className='filter-title'>Sala:</h5>
                            <TideEntitySelect
                                entity={'lounges'}
                                multi
                                preload
                                additionalFilters={selectLoungeFilters}
                                onChange={setLoungeFilter}
                                value={loungeFilter}
                            />
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={6}>
                        <div>
                            <h5 className='filter-title'>Turno:</h5>
                            <Select
                                placeholder="Escribe para buscar..."
                                closeOnSelect={false}
                                multi
                                value={shiftFilter}
                                options={shiftsOptions}
                                onChange={setShiftFilter}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} md={4} sm={6}>
                        <div>
                            <h5 className='filter-title'>Por platillo:</h5>
                            <TideEntitySelect
                                entity={'orozcoProducts'}
                                multi
                                filterBy='name'
                                value={receipeFilter}
                                additionalFilters={orozcoProductRecipeListSGroups}
                                onChange={setReceipeFilter}
                            />

                        </div>
                    </Col>
                </Row>
                <Row>
                    <hr/>
                    <h5>G-Care</h5>
                    <Col lg={3} md={4} sm={6}>
                        <div className='g-care-stat'>
                            <span className='desc'>Cuestionarios enviados por correo</span><br/>
                            <span className='data'>{questionnaireReport? questionnaireReport.sentQuestionnaires:''}</span>
                        </div>
                    </Col>

                    <Col lg={3} md={4} sm={6}>
                        <div className='g-care-stat'>
                            <span className='desc'>Cuestionarios contestados por correo</span><br/>
                            <span className='data'>{questionnaireReport? questionnaireReport.sentFilledQuestionnaires:''}</span>
                        </div>
                    </Col>

                    <Col lg={3} md={4} sm={6}>
                        <div className='g-care-stat with-switch'>
                            <span className='desc'>Mostrar solo cuestionarios enviados</span><br/>
                            <Switch value={isMailedFilter} onChange={mailedChangeHandler}/>
                        </div>
                    </Col>
                </Row>
            </>}

            <Row>
                <Col md={6} sm={12}>
                    <CustomButton
                        bsStyle='primary'
                        onClick={useFilters}
                        className='use-filter-but'
                        disabled={loading}
                    >
                        Utilizar filtros
                    </CustomButton>
                </Col>
            </Row>

        </div>
    );
};

export default QuestionnaireStatsFilters;

const getFilterableQuestionStats = ( questionnaireReport )=> {
    if( !questionnaireReport?.result?.length)
        return [];
    //Filter inactive questions
    const results = _.filter( questionnaireReport.result, res=>res.questionConfig?.inactive!==true );

    return results.filter((res) => res.type === 'shift' || res.type === 'lounge' || (res.questionConfig && res.questionConfig.filterable));
}

const  isDateOutOfRange = ( date )=>{
    return date.isBefore('2018-12-01') || date.isAfter( moment().add(1,'day') );
};
