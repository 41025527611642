import React, {useCallback} from 'react';
import TextField from "./fields/TextField/TextField";
import fieldTypes from "./fields/fieldTypes";
import DateField from "./fields/DateField/DateField";
import NumberField from "./fields/NumberField/NumberField";
import EntityField from "./fields/EntityField/EntityField";
import BooleanField from "./fields/BooleanField/BooleanField";
import SelectField from "./fields/SelectField/SelectField";

/*
------------  field object schema ---------------
{
    //  REQUIRED
    value: *,                       //Field value, type depends on fieldType
    onChange: function(value, name), //Function to handle the field change called with the parameters value and name
    name

    // OPTIONAL
    type: fieldTypes.*,             //One of the strings defined in the fieldTypes object. Defaults to "text"
    required: boolean,              //If the field is required, this will be validated by the parent element before submit
                                    // usually the TideFormModal.jsx. Default to true
    validation: function(value, {field, fields, form}) //A function used to validate the input, it should return true if
                                    // it's valid or a string describing the error if it is not

}

 */

const availableFields = {
    default:               TextField,
    [fieldTypes.text]:     TextField,
    [fieldTypes.date]:     DateField,
    [fieldTypes.number]:   NumberField,
    [fieldTypes.boolean]:   BooleanField,
    [fieldTypes.entity]:   EntityField,
    [fieldTypes.select]:   SelectField,
};

/**
 *
 * @param onChange
 * @param field
 * @param props
 * @returns {*}
 * @constructor
 */
const Field = ({onChange, field, ...props}) => {

    const handleChange = useCallback((value, fieldName)=>{
        if(field.processValue)
            onChange( field.processValue(value, field), fieldName );
        else
            onChange(value, fieldName);
    },[field, onChange]);

    const FieldComponent = availableFields[field.type] || field.component || availableFields.default;

    return <FieldComponent onChange={handleChange} field={field} {...props} />;
};

export default Field;
