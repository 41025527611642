import React, {useContext, useMemo} from 'react';
import CrudPage from "../../Dishes/CrudPage";
import {SecurityContext} from "../../../../utils/SecurityManager";
import {airportGateListColumns} from "../../../../utils/modelUtils/ee/airportGateUtils";
import AirportGateFormModal from "./AirportGateFormModal";
import AirportGateLoungesModal from "./AirportGateLoungesModal";

const AirportGateList = () => {

    const securityManager = useContext(SecurityContext);

    const tideTableConfig = useMemo(()=>({
        newTideApi: true,
        entity: "airportGates",
        columns: airportGateListColumns
    }),[]);

    const canEdit = securityManager.ee.canEditAirportGate();

    return (
        <div className={"AirportGateList"}>
            <CrudPage
                addText="Agregar puerta"
                tideTableConfig={tideTableConfig}
                AddModal={canEdit && AirportGateFormModal}
                EditModal={canEdit && AirportGateLoungesModal}
            />
        </div>
    );
};

export default AirportGateList;
