import { combineReducers } from 'redux';

import { reducer as formReducer } from 'redux-form';
import loadingReducer from './reducer_loading';
import loadingByIDReducer from './reducer_loading_by_id';
import ApiReducer from '../api/reducer_api';
import { reducer as apiCache} from '../api/CacheManager';
import { reducer as apiQueue} from '../api/QueueManager';
import {reducer as notifications} from 'react-notification-system-redux'
import userDetail from './reducer_userDetail';
import system from './reducer_system';
import reactTableState from "./reducer_reactTableState";
import loginRedirect from "./reducer_login_redirect";


const rootReducer = combineReducers({

    loading: loadingReducer,
    loadingIds: loadingByIDReducer,
    form: formReducer,
    notifications,
    api: ApiReducer,
    apiCache,
    apiQueue,
    userDetail,
    system,
    reactTableState,
    loginRedirect,
});

export default rootReducer;
