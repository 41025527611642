import React, {Component} from 'react';
import Justifications from '../../Manager/Justifications';
import {connect} from 'react-redux';
import Button from '../../../../elements/CustomButton/CustomButton';
import {Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import {employeeFullname} from "../../../../api/Api";
import {UserDetailApiProp, UserDetailLoadingId} from "../core/UserDetail";
import withSecurityManager from "../../../../utils/SecurityManager/withSecurityManager";
import _ from 'lodash';


class EmployeeIncidenceRequests extends Component {

    static contextTypes = {
        api: PropTypes.object,
        notifier: PropTypes.object
    };

    loadingId = "@Class.EmployeeIncidenceRequests.create";

    constructor( props, context ){
        super(props);

        this.state = {
            requesting:false,
            fileInputs: [],
            ...this.defaultRequestState
        };

        context.api.clearProperty( 'singleEmployee' );

        this.handleRequestStartDateChange = this.handleRequestStartDateChange.bind(this);
        this.handleRequestEndDateChange = this.handleRequestEndDateChange.bind(this);
        this.handleRequestCommentChange = this.handleRequestCommentChange.bind(this);
        this.handleRequestRangeChange = this.handleRequestRangeChange.bind(this);
        this.handleRequestTypeChange = this.handleRequestTypeChange.bind(this);
        this.handleRequestFileChange = this.handleRequestFileChange.bind(this);
        this.sendNewRequest = this.sendNewRequest.bind(this);
        this.showUserDetail = this.showUserDetail.bind(this);
        this.addFileInput = this.addFileInput.bind(this);

        if( props.match.params.id ) {
            this.employeeId = props.match.params.id;
            this.isMe = false;
            context.api.employees.getSingle(this.employeeId);
        }
        else {
            this.employeeId = props.me.employee.id;
            this.isMe = true;
        }

        context.api.incidences.get();
    }

    componentWillUnmount(){
        this.context.api.clearProperty( 'singleEmployee' );
    }

    defaultRequestState = {
        requestRange: "one",
        requestStartDate: '',
        requestEndDate: '',
        requestComment: '',
        requestType: '',
        requestFiles: []
    };

    handleRequestStartDateChange = (date)=>{
        this.setState({requestStartDate: date})
    };

    handleRequestEndDateChange = (date)=>{
        this.setState({requestEndDate: date})
    };

    handleRequestCommentChange = (e)=>{
        this.setState({requestComment: e.target.value})
    };

    handleRequestTypeChange = (e)=>{
        this.setState({requestType: e.target.value})
    };

    handleRequestRangeChange = (e)=>{
        this.setState({requestRange: e.target.value})
    };

    handleRequestFileChange = (e, index)=>{
        const requestFiles = [ ...this.state.requestFiles ];
        requestFiles[index] = e.target.files[0];
        this.setState({requestFiles})
    };

    addFileInput = ()=>{
        const fileInputs = [...this.state.fileInputs];
        const index = fileInputs.length;
        fileInputs.push(<input type="file" onChange={(e)=>this.handleRequestFileChange(e, index)} key={index}/>);
        this.setState({fileInputs});
    };

    sendNewRequest = ()=>{

        if( !this.state.requestType ){
            this.context.notifier.error("Debes seleccionar el tipo de solicitud.");
            return;
        }

        if( !this.state.requestStartDate ){
            this.context.notifier.error("Debes seleccionar la fecha de inicio.");
            return;
        }

        if( this.state.requestRange !== "one" && !this.state.requestEndDate ){
            this.context.notifier.error("Debes seleccionar la fecha de fin.");
            return;
        }

        if( !this.state.requestComment ){
            this.context.notifier.error("Debes escribir algún comentario.");
            return;
        }

        if( !this.state.requestStartDate || !this.state.requestStartDate.format ){
            this.context.notifier.error("Parace que la fecha está incorrecta. El formato debe ser dd/mm/yyyy");
            return;
        }

        this.context.api.incidenceJustifications.create(
            this.state.requestStartDate.format("YYYY-MM-DD"),
            this.state.requestEndDate? this.state.requestEndDate.format("YYYY-MM-DD"): undefined,
            this.state.requestType,
            undefined,
            this.state.requestComment,
            this.state.requestFiles,
            this.loadingId
        );

        this.setState( {requesting:false, ...this.defaultRequestState } );
    };

    UNSAFE_componentWillReceiveProps( nextProps ){
        if( this.props.loadingIds[this.loadingId] && !nextProps.loadingIds[this.loadingId] )
            this.setState({
                requestType: undefined,
                requestStartDate: undefined,
                requestEndDate: undefined,
                requestComment: undefined,
            });
    }

    showUserDetail( ){
        this.context.api.users.get(0,1,UserDetailLoadingId,{employee:this.props.singleEmployee.id},UserDetailApiProp );
    }

    render(){

        let employee;
        if( this.isMe )
            employee = this.props.me.employee;
        else
            employee = this.props.singleEmployee;

        return(
            <div className="EmployeeIncidenceRequests">

                    {this.isMe ?
                        <Button className="col-md-6 col-xs-10 col-md-push-3 col-xs-push-1 top-button" bsStyle="primary"
                                onClick={() => this.setState({requesting: true})}>
                            Nueva solicitud
                        </Button>
                        :

                        <h4>Solicitudes de <span className="span-but" onClick={this.showUserDetail}>{employeeFullname(this.props.singleEmployee)}</span></h4>
                    }
                    <div className='clearfix'/>
                {employee?
                <Justifications
                    employee={employee}
                    canApprove={this.props.securityManager.alma.canApproveJustifications(this.isMe)}
                    showNames={false}
                    showGroups={false}
                    canDelete={employee.id === this.props.me.employee.id }
                    isFuture
                />:null}

                {this.state.requesting ?
                    <Modal show={true} onHide={() => this.setState({requesting: false})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Nueva solicitud</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <p>Selecciona el tipo de solicitud</p>
                            <select  onChange={this.handleRequestTypeChange} value={this.state.requestType}>
                                <option>Selecciona una opción</option>
                                {this.props.incidences.map((inc)=> {
                                    if(  requestableIncidences.indexOf( inc.code ) !== -1 )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    return null;
                                })}
                            </select>
                            <br/><br/>
                            {(()=>{
                                //Generates a string indicating which kind of incidences justifies the incidence selected up here
                                if( !this.state.requestType ) return null;
                                const incidence = _.find(this.props.incidences, (inc)=>Number(inc.id)===Number(this.state.requestType) );
                                if( !incidence ) return null;
                                const justifies = [];
                                if( incidence.justifyAbsence) justifies.push(" Falta");
                                if( incidence.justifyRetardment) justifies.push(" Retardo");
                                if( incidence.justifyEarlyCheckout) justifies.push(" Salida temprano");
                                if( incidence.justifyIncompleteChecks) justifies.push(" Registros incompletos");
                                if( incidence.justifyLessMealTime) justifies.push(" Menos tiempo de comida");
                                if( incidence.justifyMoreMealTime) justifies.push(" Más tiempo de comida");
                                if( incidence.justifyLessWorkHours) justifies.push(" Menos tiempo trabajado");
                                if( incidence.justifyOneCheck) justifies.push(" Un solo registro");
                                if( incidence.justifyLess5WorkHours) justifies.push(" Menos de 5 horas trabajadas");

                                return <p>Esta solicitud justifica: {justifies.join(',')}</p>
                            })()}
                            <p>¿Tu solicitud es para un día o varios días?</p>
                            <select  onChange={this.handleRequestRangeChange} value={this.state.requestRange}>
                                <option value="one" >Un día</option>
                                <option value="several" >Varios días</option>
                            </select>
                            <br/><br/>
                            <p>¿Para qué fecha{this.state.requestRange!=="one"?"s":""}?</p>
                            <Datetime
                                timeFormat={false}
                                closeOnSelect={true}
                                onChange={this.handleRequestStartDateChange}
                                value={this.state.requestStartDate}
                                disableOnClickOutside={true}
                            />
                            {this.state.requestRange!=="one"?
                                <div>
                                    <p>Al:</p>
                                    <Datetime
                                        timeFormat={false}
                                        closeOnSelect={true}
                                        onChange={this.handleRequestEndDateChange}
                                        value={this.state.requestEndDate}
                                        isValidDate={(date)=>date.isAfter(this.state.requestStartDate)}
                                        disableOnClickOutside={true}
                                    />
                                </div>
                                :null}

                            <br/>
                            <p>Escribe algunos comentarios sobre tu solicitud.</p>
                            <textarea className={"form-control"} value={this.state.requestComment} onChange={this.handleRequestCommentChange} />
                            <br/>
                            <p>¿Deseas anexar alguna foto o archivo a tu solicitud?</p>

                            {this.state.fileInputs}

                            <br />
                            <Button bsStyle='primary' onClick={this.addFileInput}>Agregar archivo</Button>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.setState({requesting: false})}>Cancelar</Button>{" "}
                            <Button bsStyle="primary" onClick={this.sendNewRequest}>Enviar</Button>
                        </Modal.Footer>
                    </Modal>
                    :null}

            </div>
        );

    }
}

const requestableIncidences = ["CD", "I", "RP", "PP", "V", "CUMP", "OF", "OR", "PCHOT","C","CO","CA","E","DA","EIMSS","EDOC","EINC","FCS","FSS","COVID","PUN","PMHT5"];
function mapStateToProps( {api:{me, incidences = [], singleEmployee }, loadingIds} ) {

    return {me, incidences, singleEmployee, loadingIds}
}
export default connect(mapStateToProps)(withSecurityManager(EmployeeIncidenceRequests));
