import React, {useCallback, useContext} from 'react';
import './DeleteStockMovementCell.scss';
import {TideApiContext} from "../../../../../api/tideApiConfig";
import {useSelector} from "react-redux";
import useBoolean from "../../../../../hooks/useBoolean";
import ConfirmModal from "../../../../../components/Modals/ConfirmModal";

const DeleteStockMovementCell = ({ original, reloadRef }) => {

    const api = useContext(TideApiContext);

    const [deleting, startDeleting, stopDeleting] = useBoolean();
    const promptDelete = useCallback((e)=>{
        e.stopPropagation();
        startDeleting();
    },[startDeleting]);

    const id = original?.id;
    const loadingId = 'DeleteStockMovementCell-'+id;
    const reload = reloadRef.current;
    const deleteStockMovement = useCallback((e)=>{
        e.stopPropagation();
        stopDeleting();
        api.stockMovementBatches.update( {id, params:{status: 'canceled'}, loadingId})
            .then(()=>{
                console.log(reload);
                if(reload) {
                    reload();
                }
            });
    },[api, id, loadingId, stopDeleting, reload]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    if(!original)
        return null;

    return (
        <div className={"DeleteStockMovementCell"}>
            <button className='btn btn-danger' disabled={loading} onClick={promptDelete}>Cancelar</button>

            {deleting &&<ConfirmModal
                title='Cancelar movimiento pendiente'
                message='¿Estás seguro que deseas cancelarlo?'
                onCancel={stopDeleting}
                onConfirm={deleteStockMovement} />}
        </div>
    );
};

export default DeleteStockMovementCell;
