import React, {useState, useEffect, useContext}  from 'react';
import {Modal, Table} from "react-bootstrap";
import Button from '../../../elements/CustomButton/CustomButton';
import TaxManagerModal from './TaxManagerModal';
import {connect} from "react-redux";
import {ApiContext} from "../../../api/Api";
import _ from 'lodash';


const ProductTaxesDetail = ( { productTaxes, taxes, onHide, editable, onChange, subtotal } )=>{

    const api = useContext( ApiContext );
    const [managingTaxes, setManagingTaxes] = useState( false );

    useEffect( ()=>{
        if( !taxes.length )
            api.taxes.get( 0,200 );
    }, [api, taxes.length ] );

    const addTax = ( {target} )=>{

        const id = target.value;
        const tax = _.find( taxes, ( oneTax )=>id+''===oneTax.id+'' );

        onChange( [ ...productTaxes, { tax, total: tax.multiplier * ( Number( subtotal )||0 ) } ] );
    };

    const onInputChange = ( value, index )=>{

        const newProductTaxes = [ ...productTaxes ];
        const newProductTax = { ...productTaxes[index] };

        newProductTax.total = value;

        newProductTaxes[ index ] = newProductTax;

        onChange( newProductTaxes );
    };

    const deleteTax = ( index )=>{

        const newProductTaxes = [ ...productTaxes ];
        newProductTaxes.splice( index, 1 );
        onChange( newProductTaxes );
    };

    return<>
        <Modal
            show={true}
            onHide={onHide}
        >
            <Modal.Header>
                <Modal.Title>Impuestos</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Table>
                    <thead>
                    <tr>
                        <th>Impuesto</th>
                        <th>Multiplicador</th>
                        <th>Valor</th>
                        {editable?
                            <th>Eliminar</th>
                            :null}

                    </tr>
                    </thead>
                    <tbody>

                    {productTaxes.map( (pTax, i)=><tr key={i}>
                        <td>{pTax.tax.name}</td>
                        <td>{Number(pTax.tax.multiplier)}</td>
                        <td>{ editable?
                            <input className='form-control' value={ pTax.total } onChange={ ( {target} )=>onInputChange( target.value, i ) } />
                            : pTax.total }
                        </td>

                        {editable?
                            <td><i className='fa fa-trash red-icon' onClick={ ()=>deleteTax( i ) }/></td>
                            :null}
                    </tr> )}

                    </tbody>
                </Table>

                {editable?
                    <div>
                        <select value='' onChange={addTax} className='form-control'>
                            <option value='' disabled>Agregar impuesto</option>
                            { taxes.map( ( tax )=>{
                                return <option value={ tax.id } key={ tax.id }>{tax.name}</option>
                            }) }
                        </select>
                    </div>
                    :null}

            </Modal.Body>

            <Modal.Footer>

                {editable?
                    <Button className='pull-left' bsStyle='primary' onClick={ ()=>setManagingTaxes(true) }>Administrar tipos de impuestos</Button>
                    :null}

                <Button bsStyle='primary' onClick={onHide} className='pull-right'>Cerrar</Button>
            </Modal.Footer>
        </Modal>

        { managingTaxes?
            <TaxManagerModal
                onHide={ ()=>setManagingTaxes(false) }
            />
            :null
        }

        </>
};


const mapStateToProps = ( {api:{taxes=[]}} )=>({taxes});

export default connect(mapStateToProps)(ProductTaxesDetail);
