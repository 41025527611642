import {reportHeaders} from '../../xlsxReports';

export const exportCheckInsSGroups = [
    'check_in_read_id',
    'check_in_read_folio',
    'check_in_read_adults_number',
    'check_in_read_kids_number',
    'check_in_read_companions_number',
    'check_in_read_visit',
        'visit_read_lounge',
            'place_read_name',
        'visit_read_guest',
            'guest_read_full_name',
    'check_in_read_lounge_access_method',
        'lounge_access_method_read_name',
        'lounge_access_method_read_lounge_parent_access_methods',
    'creation_date',
    'check_in_read_cancellation_reason',
    'check_in_read_capture_time'
];

export const listSGroups = [
    'check_in_read_id',
    'check_in_read_folio',
    'check_in_read_is_scanned',
    'check_in_read_visit',
        'visit_read_lounge',
            'place_read_name',
        'visit_read_guest',
            'guest_read_full_name',
    'check_in_read_lounge_access_method',
        'lounge_access_method_read_name',
        'lounge_access_method_read_lounge_parent_access_methods',
    'check_in_read_created_date',
    'check_in_read_check_out_date',
    'check_in_read_cancellation_reason',
    'check_in_read_capture_time'
];

export const listProperties = [
    {
        header: 'Huésped',
        property: 'visit.guest.fullName'
    },
    {
        header: 'Sala',
        property: 'visit.lounge.name'
    },
    {
        header: 'Método de acceso',
        property: 'loungeAccessMethod.name'
    },
    {
        header: 'Fecha de visita',
        property: 'createdDate'
    },
    {
        header: 'Número de acompañantes',
        property: 'companionsNumber'
    },
    {
        header: 'Tiempo de captura',
        property: 'captureTime'
    },
    {
        header: 'Folio',
        property: 'folio'
    }
];

export const canceledProperties = [
    ...listProperties,
    {
        header: 'Razón',
        property: 'cancellationReason'
    }
];

export const billingSGroups = [
    'check_in_read_id',
    'check_in_read_adults_number',
    'check_in_read_kids_number',
    'check_in_read_companions_number',
    'check_in_read_companions_string',
    'check_in_read_visit',
    'visit_read_lounge',
    'place_read_name',
    'visit_read_guest',
    'guest_read_full_name',
    'check_in_read_lounge_access_method',
    'lounge_access_method_read_name',
    'creation_date',
    'check_in_read_companions',
    'companion_read_name',
];

const billingProperties = [
    {
        header: 'Fecha',
        property: 'createdDate'
    },
    {
        header: 'Huésped',
        property: 'visit.guest.fullName'
    },
    {
        header: 'Cliente',
        property: 'loungeAccessMethod.name'
    },
    {
        header: 'Número de acompañantes',
        property: 'companionsNumber'
    },
    {
        header: 'Acompañantes',
        property: 'companionsString'
    }
];

const exportHeaders = reportHeaders(listProperties);
const exportCanceledHeaders = reportHeaders(canceledProperties);

export const xlsExportConfig = {
    name: 'CheckIns.xlsx',
    headers: exportHeaders
};

export const xlsCanceledExportConfig = {
    name: 'CheckIns.xlsx',
    headers: exportCanceledHeaders
};

const billingHeaders = reportHeaders(billingProperties);

export const xlsBillingConfig = {
    name: 'Facturación.xlsx',
    headers: billingHeaders
};

const courtesyProperties = [
    {
        header: 'Fecha',
        property: 'createdDate'
    },
    {
        header: 'Folio de check in',
        property: 'folio'
    },
    {
        header: 'Folio de visita',
        property: 'visit.folio'
    },
    {
        header: 'Huésped',
        property: 'visit.guest.fullName'
    },
    {
        header: 'Quién otorga la cortesía',
        property: 'extraFields.Autoriza.guest'
    },
    {
        header: 'Tiempo de captura',
        property: 'captureTime'
    },
];

const courtesyHeaders = reportHeaders(courtesyProperties);

export const courtesySGroups = [
    'creation_date',
    'check_in_read_visit',
    'check_in_read_folio',
    'visit_read_guest',
    'visit_read_folio',
    'guest_read_full_name',
    'check_in_read_lounge_access_method',
    'lounge_access_method_read_lounge_sub_access_methods',
    'lounge_access_method_read',
    'check_in_read_extra_fields',
    'check_in_read_capture_time'
];

export const xlsCourtesyConfig = {
    name: 'Cortesías.xlsx',
    headers: courtesyHeaders
};
