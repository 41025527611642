import React, {useMemo, useEffect, useState, useCallback} from 'react';
import _ from 'lodash/fp';

/*
  Component to chunk its children on rows,
  meant to be used when wrapping on the row breaks 
  when having different heights.
*/

/*Bootstrap 3 breakpoints:
    - lg >= 992px
    - md >= 768px
    - sm >= 576px
    - xs < 576px
*/
const bsBreakpoints = [1200, 992, 768, -Infinity];
const bsBreakpointsNames = ['lg','md','sm','xs'];

const getBreakpointCols = (cols, width)=>{
    if(!cols){
        throw new Error('breakpointCols prop is required');
    }
    if(!_.isArray(cols)){
        throw new Error('breakpointCols prop must be an array');
    }
    if(cols.length!==4){
        throw new Error('breakpointCols prop must be an array with 4 elements');
    }

    return _.flow(
        ()=>_.zip(cols, bsBreakpoints),
        _.filter(([x])=>!!x),
        _.find(([col, minWidth])=>width>=minWidth),
        _.get(0)
    )();
};

const ColLayout = ({breakpointCols=[], colClass='', rowClass='', children}) => {
    const [nCols, setNCols] = useState(undefined);
    const adjustNCols = useCallback(()=>{
        const width = document.documentElement.clientWidth;
        const gridCols = getBreakpointCols(breakpointCols, width);
        setNCols(Math.floor(12/gridCols));
    }, [breakpointCols]);
    useEffect(()=>{
        adjustNCols();
        window.addEventListener('resize', adjustNCols);
    }, [adjustNCols]);

    const bsClasses = useMemo(
        _.flow(
            ()=>_.zip(breakpointCols, bsBreakpointsNames),
            _.filter(([n])=>!!n),
            _.map(([x,y])=>`col-${y}-${x}`),
            _.join(' ')
        ),
        [breakpointCols]);

    if(!nCols){
        return null;
    }
    return _.chunk(nCols, children).map((r, i)=>(
        <div key={i} className={`row ${rowClass}`}>
            {r.map((x, j)=><div key={j} className={`${bsClasses} ${colClass}`}>{x}</div>)}
        </div>
    ));
};

export default ColLayout;
