import React,{useContext, useEffect, useMemo, useState} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import {dataView} from '../utils';
import DateRangeFilter, {useDateRangeFilter} from '../Filters/DateRangeFilter';
import CustomCheckbox from '../../../../elements/CustomCheckbox/CustomCheckbox';
import moneyFormatter from '../../../../utils/moneyFormatter';

const loadingId = '@OrozcoProductAverageRealCost.report';

const dataAxis = [
    {
        getKey: 'shift',
        getDisplayName: ({shift})=>{
            switch(shift){
            case 'morning': return 'Matutino';
            case 'afternoon': return 'Vespertino';
            case 'night': return 'Nocturno';
            default: return 'Desconocido';
            }
        }
    },
    {
        getKey: 'loungeId',
        getDisplayName: 'loungeName'
    },
];

const prepareDataView = dataView(dataAxis);

const orozcoProductAverageRealCostHOC = (categories=undefined) => {
    const OrozcoProductAverageRealCost = ({defaultDateRange}) => {
        const dateConfig = useMemo(()=>({initial: defaultDateRange}), [defaultDateRange]);
        const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);
        const [showing, setShowing] = useState('totalOccupation');
        const api = useContext(ApiContext);
        useEffect(()=>{
            api.lounges.realDailyCostSumReport({loadingId,
                                                params: {
                                                    ...dateRequest,
                                                    categories
                                                }
                                               });
        }, [api, dateRequest]);
        const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);
        const report = useSelector(({api})=>api.loungesRealDailyCostSumReport||[]);

        const {axisData, foldDataValues} = useMemo(
            ()=> prepareDataView(report, v=>{
                if(showing==='averageRealCost'){
                    return v.totalRealCost;
                }
                return v[showing];
            }),
            [showing, report]);

        const getValue = foldDataValues((x,y)=>parseInt(x)+parseInt(y), 0);
        const getNonNullValue = foldDataValues((x,y)=>y!==null?parseInt(x)+parseInt(y):parseInt(x), 0);
        const getCount = foldDataValues(x=>x+1, 0);
        const getNonNullCount = foldDataValues((x, y)=>y!==null?x+1:x, 0);
        const shifts = axisData[0];
        const lounges = axisData[1];
        const value = filters=>{
            if(showing === 'totalOccupation'){
                if(getCount(filters) === 0){
                    return (0).toFixed(2);
                }
                return (getValue(filters)/getCount(filters)).toFixed(2);
            }
            if(showing === 'averageRealCost'){
                if(getCount(filters) === 0){
                    return moneyFormatter(0);
                }
                return moneyFormatter(getValue(filters)/getCount(filters));
            }
            if(showing === 'totalRealCost'){
                return moneyFormatter(getValue(filters));
            }
            if(showing === 'averagePersonRealCost'){
                if(getNonNullCount(filters) === 0){
                    return moneyFormatter(0);
                }
                return moneyFormatter(getNonNullValue(filters)/getNonNullCount(filters));
            }
            return 0;
        };

        return (
            <div className='OrozcoProductAverageRealCost'>
                <DateRangeFilter {...dateFilterProps}/>
                <div className='row'>
                    <div className='col-md-2'>
                        <label>Mostrar: </label>
                    </div>
                    <div className='col-md-10 text-right'>
                        <CustomCheckbox onChange={()=>setShowing('totalOccupation')} isChecked={showing==='totalOccupation'} inline label='Ocupación promedio'/>
                        <CustomCheckbox onChange={()=>setShowing('averageRealCost')} isChecked={showing==='averageRealCost'} inline label='Costo real promedio'/>
                        <CustomCheckbox onChange={()=>setShowing('totalRealCost')} isChecked={showing==='totalRealCost'} inline label='Costo real total'/>
                        <CustomCheckbox onChange={()=>setShowing('averagePersonRealCost')} isChecked={showing==='averagePersonRealCost'} inline label='Costo por persona promedio'/>
                    </div>
                </div>

                {loading && <h3 className='text-center'>Cargando...</h3>}
                <Table striped>
                    <thead>
                        <tr>
                            <th>Sala</th>
                            {shifts.map(({key, displayName})=><th key={key}>{displayName}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {lounges.map(({key: loungeId, displayName})=>(
                            <tr key={loungeId}>
                                <td>
                                    {displayName}
                                </td>
                                {shifts.map(({key: shift})=><td key={shift}>{value([shift, loungeId])}</td>)}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    };
    return OrozcoProductAverageRealCost;
};

export default orozcoProductAverageRealCostHOC;
