export const detailTableSGroups = [
    'orozco_cash_pick_up_read',
    'orozco_cash_pick_up_read_orozco_cash_pick_up_details',
        'orozco_cash_pick_up_detail_read',
        'orozco_cash_pick_up_detail_read_orozco_payment_method',
            'orozco_payment_method_read',
    'creation_date',
    'orozco_cash_pick_up_read_created_by',
        'user_read_employee',
            'employee_read_full_name'
];

export const xlsReportSGroups = [
    'orozco_cash_pick_up_read',
    'orozco_cash_pick_up_read_created_date_string',
    'orozco_cash_pick_up_read_cash_mxn_amount',
    'orozco_cash_pick_up_read_cash_usd_amount',
    'orozco_cash_pick_up_read_cash_eur_amount',
    'orozco_cash_pick_up_read_card_mxn_amount',
    'orozco_cash_pick_up_read_formatted_mxn_amount',
    'orozco_cash_pick_up_read_formatted_usd_amount',
    'orozco_cash_pick_up_read_formatted_eur_amount',
    'orozco_cash_pick_up_read_created_by',
        'user_read_employee',
            'employee_read_full_name'
];

export const xlsReportConfig = ({cashRegister})=>({
    name: 'Recolecciones_de_caja_'+(cashRegister?cashRegister.name:'')+'.xlsx',
    headers: {
        'createdBy.employee.fullName': 'Recolectado por',
        'createdDateString': 'Fecha de recolección',
        checkInsCount: 'Total Huéspedes',
        visitOccupancy: 'Total personas atendidas',
        'mxnAmountFormatted': 'MXN Efectivo/Tarjeta',
        'usdAmountFormatted': 'USD Efectivo',
        'eurAmountFormatted': 'EUR Efectivo'
    }
});
