import React,{useContext, useEffect, useMemo} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import DateRangeFilter, {useDateRangeFilter} from '../Filters/DateRangeFilter';
import moneyFormatter from '../../../../utils/moneyFormatter';
import _ from 'lodash/fp';

const loadingId='@OrozcoProductsCountReport.report';

const groupValues = _.flow(
    _.groupBy('productId'),
    _.mapValues(arr=>({...arr[0], quantity: _.sumBy(x=>parseInt(x.quantity), arr)})),
    _.values
);

const OrozcoProductsCountReport=({drinksId, coffeeId, defaultDateRange})=>{
    const dateConfig = useMemo(()=>({initial: defaultDateRange}), [defaultDateRange]);
    const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);
    const api=useContext(ApiContext);
    useEffect(()=>{
        api.orozcoProducts.rangeCountReport({
            loadingId,
            params: {...dateRequest, categoriesExcept: [drinksId, coffeeId]}
        });
    },[api, dateRequest, coffeeId, drinksId]);

    const report = useSelector(({api})=>api.orozcoProductRangeCountReport)||[];
    const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    const table = useMemo(()=>{
        const noZero=_.sortBy([data=>-data.quantity])((groupValues(report)||[]).filter( data=>Number(data.quantity) ));
        const total = noZero.reduce( (sum, data)=>sum+Number(data.quantity), 0);

        return {dishes: noZero, total};
    },[report]);

    return (
        <div className='OrozcoProductsCountReport'>
            <DateRangeFilter {...dateFilterProps}/>
            {loading && <h3 className='text-center'>Cargando...</h3>}

            {table && !!table.total &&
            <h3>{table.total} platillos vendidos</h3>}

            <Table striped>
                <thead>
                <tr>
                    <th>Platillo</th>
                    <th className='text-center'>Servidos</th>
                    <th>Costo de receta</th>
                </tr>
                </thead>
                <tbody>
                {table.dishes.map((dish, i)=>
                    <tr key={i}>
                        <td>{dish.productName}</td>
                        <td className='text-center'>{dish.quantity}</td>
                        <td>{moneyFormatter(dish.recipeCost, 1)}</td>
                    </tr>
                )}
                </tbody>
            </Table>

        </div>
    );
};

export default OrozcoProductsCountReport;
