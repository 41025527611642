import React, {useContext, useEffect, useCallback, useState} from 'react';

import EntitySelect from "../../../components/EntitySelect/EntitySelect";
import useInput from "../../../hooks/useInput";
import {ApiContext} from "../../../api/Api";
import {SecurityContext} from "../../../utils/SecurityManager";
import {NotifierContext} from "../../../utils/Notifier";
import Switch from "react-bootstrap-switch";
import switchTranslations from "../../../utils/switchTranslations";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";

const MINS_TO_ADD_APPROVAL_TO_SUPERIOR='MINS_TO_ADD_APPROVAL_TO_SUPERIOR';
const MINS_TO_AUTO_APPROVE_REQUISITION = 'MINS_TO_AUTO_APPROVE_REQUISITION';
const ONLY_ONE_APPROVAL_FOR_LINE_PRODUCTS = 'ONLY_ONE_APPROVAL_FOR_LINE_PRODUCTS';
const URL_TO_EXTERNAL_FORM = 'URL_TO_EXTERNAL_FORM';

const BUDGET_REQUEST_APPROVAL_1 = 'BUDGET_REQUEST_APPROVAL_1';
const BUDGET_REQUEST_APPROVAL_2 = 'BUDGET_REQUEST_APPROVAL_2';
const BUDGET_REQUEST_MULTIPLIER = 'BUDGET_REQUEST_MULTIPLIER';
const BUDGET_REQUEST_MONTHS = 'BUDGET_REQUEST_MONTHS';

const JazzConfiguration = () => {

    const superiorApprovalTimeInput = useInput('');
    const autoApprovalTimeInput = useInput('');
    const [onlyAmountApproval, setOnlyAmountApproval] = useState(false);
    const handleAmountApprovalChange=useCallback((s, value)=>{
        setOnlyAmountApproval(value);
    },[]);
    
    const notifier = useContext(NotifierContext);
    const urlForExternalForm = useInput('');
    const multiplier = useInput(0);
    const months = useInput(0);

    const api = useContext(ApiContext);
    const security = useContext(SecurityContext);

    const [selectedEmployee1, setSelectedEmployee1] = useState(null);
    const [selectedEmployee2, setSelectedEmployee2] = useState(null);

    useEffect(() => {
            api.configurations.getSingle({id: MINS_TO_ADD_APPROVAL_TO_SUPERIOR}).then((configuration) => superiorApprovalTimeInput.setValue(configuration.value));
            api.configurations.getSingle({id: MINS_TO_AUTO_APPROVE_REQUISITION}).then((configuration) => autoApprovalTimeInput.setValue(configuration.value));
            api.configurations.getSingle({id: ONLY_ONE_APPROVAL_FOR_LINE_PRODUCTS}).then((configuration) => setOnlyAmountApproval(configuration.value==='1'));
            api.configurations.getSingle({id: URL_TO_EXTERNAL_FORM}).then((configuration) => urlForExternalForm.setValue(configuration.value));
            api.configurations.getSingle({id: BUDGET_REQUEST_APPROVAL_1}).then((configuration) => updateApproval1(configuration));
            api.configurations.getSingle({id: BUDGET_REQUEST_APPROVAL_2}).then((configuration) => updateApproval2(configuration));
            api.configurations.getSingle({id: BUDGET_REQUEST_MULTIPLIER}).then((configuration) => multiplier.setValue(configuration.value));
            api.configurations.getSingle({id: BUDGET_REQUEST_MONTHS}).then((configuration) => months.setValue(configuration.value));
        //eslint-disable-next-line
    }, [api]);

    const updateApproval1 = (configuration) => {
        api.employees.getSimple({
            filters: {...employeeFilters, id: configuration.value}
        }).then(employees=>setSelectedEmployee1(employees[0]));
    }

    const updateApproval2 = (configuration) => {
        api.employees.getSimple({
            filters: {...employeeFilters, id: configuration.value}
        }).then(employees=>setSelectedEmployee2(employees[0]));
    }
    
    const onSave = () => {
        if (!selectedEmployee1)
            return notifier.error('Selecciona la primer persona que debe aprobar');

        if (!selectedEmployee2)
            return notifier.error('Selecciona la segunda persona que debe aprobar');

        if(!Number(months?.value))
            return notifier.error('Escribe los meses que se deben tomar en cuenta en el cálculo del presupuesto');

        if(Number(months?.value)<1)
            return notifier.error('La cantidad de meses debe ser mayor o igual a 1');

        if(!Number(multiplier?.value))
            return notifier.error('Escribe el multiplicador para el cálculo del presupuesto');

        if(Number(multiplier?.value)<=0)
            return notifier.error('El multiplicador debe ser mayor que 0');
            
        api.configurations.batchEdit({configurations: [
                {
                    id: MINS_TO_ADD_APPROVAL_TO_SUPERIOR,
                    value: superiorApprovalTimeInput.value
                },
                {
                    id: MINS_TO_AUTO_APPROVE_REQUISITION,
                    value: autoApprovalTimeInput.value
                },
                {
                    id: ONLY_ONE_APPROVAL_FOR_LINE_PRODUCTS,
                    value: onlyAmountApproval?'1':'0'
                },
                {
                    id: URL_TO_EXTERNAL_FORM,
                    value: urlForExternalForm.value
                },
                {
                    id: BUDGET_REQUEST_APPROVAL_1,
                    value: selectedEmployee1.id
                },
                {
                    id: BUDGET_REQUEST_APPROVAL_2,
                    value: selectedEmployee2.id
                },
                {
                    id: BUDGET_REQUEST_MULTIPLIER,
                    value: multiplier.value
                },
                {
                    id: BUDGET_REQUEST_MONTHS,
                    value: months.value
                }
            ]}).then(()=>notifier.success('Configuración guardada'));
    };

    

    return (
        <div className="container-fluid Configuration">
            <div className='configuration-container col-md-8 col-md-push-2'>

                {security.jazz.canEditMinsToChangeApprovalToSuperior() ?
                    <div className='config-editor row'>
                        <div className='col-md-10 description'>
                            Minutos para habilitar aprobaciones a superior. Si una solicitud de compra no es
                            aprobada en el tiempo definido, el siguiente colaborador en la lista de
                            aprobaciones será notificado y podrá aprobar la solicitud.<br/>
                            El valor 0 desactiva esta funcionalidad.
                        </div>
                        <div className='col-md-2 value'>
                            <input className="form-control" type="text" {...superiorApprovalTimeInput.bind}/>
                        </div>
                    </div> : null}
                {security.jazz.canEditMinsToAutoApproveRequisitions() ?
                    <div className='config-editor row'>
                        <div className='col-md-10 description'>
                            Minutos para aprobar automáticamente requisiciones de productos de línea.<br/>
                            El valor 0 desactiva esta funcionalidad.
                        </div>
                        <div className='col-md-2 value'>
                            <input className="form-control" type="text" {...autoApprovalTimeInput.bind}/>
                        </div>
                    </div> : null}

                    {security.jazz.canEditOnlyAmountApprovalForLineProducts() ?
                    <div className='config-editor row'>
                        <div className='col-md-10 description'>
                            Las requisiciones de productos de línea solo requieren de la aprobación por montos.
                        </div>
                        <div className='col-md-2 value'>
                            <Switch value={onlyAmountApproval} onChange={handleAmountApprovalChange} {...switchTranslations}/>
                        </div>
                    </div> : null}

                {security.jazz.canEditUrlToExternalFormPurchaseRequisitionsLimit() ?
                    <div className='config-editor row'>
                        <div className='col-md-10 description'>
                            URL cuando se excede el límite de requisiciones de compra en el área seleccionada.
                        </div>
                        <div className='col-md-2 value'>
                            <input className="form-control" type="text" {...urlForExternalForm.bind}/>
                        </div>
                    </div> : null}

                    {security.jazz.canConfigureApprovalRequest() && 
                        <fieldset>
                            <legend><b>Configuración de Solicitudes automáticas de presupuesto</b></legend>
                            <div className='config-editor row'>
                                <div className='col-md-4 description'>
                                    Primer persona que debe aprobar las solicitudes de presupuesto:
                                </div>
                                <div className='col-xs-12 col-md-8 description'>
                                    <EntitySelect
                                        entity='employees'
                                        getMethod='getSimple'
                                        filterBy='search'
                                        labelCreator={employeeFullName}
                                        onChange={setSelectedEmployee1}
                                        value={selectedEmployee1}
                                        additionalFilters={employeeFilters}
                                    />
                                </div>
                            </div>
                            <div className='config-editor row'>
                                <div className='col-md-4 description'>
                                    Segunda persona debe aprobar las solicitudes de presupuesto:
                                </div>
                                <div className='col-xs-12 col-md-8 description'>
                                    <EntitySelect
                                        entity='employees'
                                        getMethod='getSimple'
                                        filterBy='search'
                                        labelCreator={employeeFullName}
                                        onChange={setSelectedEmployee2}
                                        value={selectedEmployee2}
                                        additionalFilters={employeeFilters}
                                    />
                                </div>
                            </div>

                            <div className='config-editor row'>
                                <div className='col-md-8 description'>
                                    Meses que se deben tomar en cuenta en el cálculo del presupuesto automático
                                </div>
                                <div className='col-md-4 description'>
                                    <input className="form-control" type="text" {...months.bind}/>
                                </div>
                            </div>

                            <div className='config-editor row'>
                                <div className='col-md-8 description'>
                                    Multiplicador para cálculo del presupuesto automático
                                </div>
                                <div className='col-md-4 description'>
                                    <input className="form-control" type="text" {...multiplier.bind}/>
                                </div>
                            </div>
                        </fieldset>}

                <button className='btn btn-success' onClick={onSave}>
                    Guardar
                </button>
                <div className='clearfix' />
            </div>
        </div>
    );
};

const employeeFilters = {
    isHired: true, 
    sGroups:[
        'employee_read_full_name',
        'employee_read_name',
        'employee_read_pat_last_name',
        'employee_read_area',
        'employee_read_user',
        'user_read',
        'user_read_id',
        'user_read_employee'
    ]
};

export default JazzConfiguration;