import React, {useCallback, useContext, useRef, useState, useEffect} from 'react';
import {Col, Dropdown, MenuItem, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import Card from '../../../components/Card/Card.jsx';
import moment from 'moment/moment';
import Button from '../../../elements/CustomButton/CustomButton';
import {ApiContext} from "../../../api/Api";
import {TideApiContext} from '../../../api/tideApiConfig';
import {connect, useSelector} from 'react-redux';
import _ from 'lodash';
import {NotifierContext} from "../../../utils/Notifier";
import Switch from "react-bootstrap-switch";
import switchTranslations from "../../../utils/switchTranslations";
import TideEntitySelect from "../../../components/TideEntitySelect/TideEntitySelect";
import CommentsFeed from "../../../components/CommentsFeed/CommentsFeed";
import {
    basePurchaseProduct,
    purchaseRequisitionProcessSGroups
} from "../../../utils/modelUtils/jazz/purchaseRequisitionUtils";
import {SecurityContext} from "../../../utils/SecurityManager";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import EntitySelect from "../../../components/EntitySelect/EntitySelect";
import { convertFromSmallestUnit, convertToSmallestUnit } from '../../../utils/modelUtils/almazen/unitsUtility';
import CustomButton from "../../../elements/CustomButton/CustomButton";
import useBoolean from "../../../hooks/useBoolean";
import {itemsListForRequisitionSGroups} from "../../../utils/modelUtils/almazen/productBrandProviderUtils";
import {purchaseRequisitionListSGroups} from "../../../utils/modelUtils/jazz/purchaseRequisitionUtils";

const loadingId = "@PurchaseRequisitionForm.create";
const stockRequestFilters = {sGroups:["stock_request_read_created_date", "stock_request_read_folio", "stock_request_read_id"]};
const jazzAreaFilters = {sGroups: ["jazz_area_read", "jazz_area_read_monthly_used_budget"]};
const stockRequestLabelCreator = sr=>`Folio: ${sr.folio} - Fecha: ${moment(sr.createdDate).format("DD/MM/YYYY")}`;

const PurchaseRequisitionForm = ({ purchaseRequisitions, loadingIds, history, match}) => {

    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState('');
    const [isUrgent, setIsUrgent] = useState(false);
    const [isInStock, setIsInStock] = useState(false);
    const [budgetExceeded, setBudgetExceeded] = useState(false);
    const [purchaseProducts, setPurchaseProducts] = useState([basePurchaseProduct({isInStock})]);
    const [providerLoaderOpen,,,toggleProviderLoader] = useBoolean();
    const [inventories, setInventories] = useState();
    const [overFlowingProducts, setOverFlowingProducts] = useState( [] );
    const [totalPrice, setTotalPrice] = useState(0);
    const [defaultWarehouse, setDefaultWarehouse] = useState();
    const [stockRequests, setStockRequests] = useState();
    const [jazzArea, setJazzArea] = useState();
    const [requestedProductBrandIds, setRequestedProductBrandIds] = useState([]);
    const [requestedProductBrands, setRequestedProductBrands] = useState([]);
    const [urlExternalForm, setUrlExternalForm] = useState('');

    const api = useContext(ApiContext);
    const tideApi = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);
    const security = useContext(SecurityContext);
    const me=useSelector(({api})=>api.me);

    const purchaseProductsRef = useRef( {} );
    purchaseProductsRef.current = purchaseProducts;

    //Warning function if a product exceeds its maxStockAmount
    const diffAmountChecker = useCallback((inventory, newAmount, stockMovement) => {
        const num = convertToSmallestUnit(newAmount, stockMovement.measurementUnit, inventory.productBrand.product);
        const max = Number(inventory?.maxStockAmount).toFixed(2);
        const stock = Number(inventory?.stockAmount).toFixed(2);
        if (inventory.maxStockAmount && num > (max - stock)) {
            const arrayOfOverflowProductsId = overFlowingProducts.map(inv => inv.productBrand.id);
            if (!_.includes(arrayOfOverflowProductsId, inventory.productBrand.id)) {
                setOverFlowingProducts([...overFlowingProducts, inventory]);
            }
        } else {
            if (overFlowingProducts.length) {
                const newArrayOfOverflow = overFlowingProducts.filter(inv => inv.productBrand.id !== inventory.productBrand.id);
                setOverFlowingProducts(newArrayOfOverflow);
            }
        }
    }, [overFlowingProducts, setOverFlowingProducts]);
    //End of warning checker

    const onPropertyChange = (index, prop, value) => {
        const newPurchaseProducts = [...purchaseProductsRef.current];
        newPurchaseProducts[index] = {...newPurchaseProducts[index], [prop]: value};
        setPurchaseProducts(newPurchaseProducts);
    };

    const onIsInStockChange = useCallback((component,value) => {
        setPurchaseProducts([basePurchaseProduct({isInStock:value})]);
        setIsInStock(value);
    }, []);

    //------------------------------------------------------
    const onTypeChange = useCallback(({target}) => {
        const newPurchaseProducts = [...purchaseProductsRef.current];
        const index = target.dataset.index;
        newPurchaseProducts[index] = {...newPurchaseProducts[index], isService: target.value === 'service', searchedProducts: [], product: null, measurementUnit: null, name: ''};
        setPurchaseProducts(newPurchaseProducts);
    }, []);
    //------------------------------------------------------

    const onNameChange = useCallback(({target}) => {
        onPropertyChange(target.dataset.index, 'name', target.value);
    }, []);

    const fulFillWarehouse = useCallback(() => {
        const newPurchaseProducts = [...purchaseProducts];
        for (let i = 0; i < newPurchaseProducts.length; i++) {
            const inventory = _.filter(inventories, function(inv) { return inv.productBrand?.id === newPurchaseProducts[i].productBrand?.id })[0];
            const max = Number(inventory?.maxStockAmount).toFixed(2);
            const stock = Number(inventory?.stockAmount).toFixed(2);
            //const num = convertToSmallestUnit(item.quantity, item.measurementUnit, inventory.productBrand?.product);
            newPurchaseProducts[i].quantity = Math.max(0, convertFromSmallestUnit(max-stock, newPurchaseProducts[i].measurementUnit, newPurchaseProducts[i].productBrand?.product).toFixed(2));
        }
    }, [purchaseProducts, inventories]);

    const onQuantityChange = useCallback(({target}) => {
        onPropertyChange(target.dataset.index, 'quantity', target.value);
        if (isInStock === true) {
            const inventory = _.filter(inventories, function(inv) { return inv.productBrand?.id === purchaseProducts[target.dataset.index].productBrand?.id });
            if (inventory.length > 0 && inventory?.maxStockAmount !== null && target.value.length) {
                diffAmountChecker(...inventory, target.value, purchaseProducts[target.dataset.index]);
            }
        }
    }, [purchaseProducts, inventories, diffAmountChecker, isInStock]);

    const onStockProductChange = useCallbackCreator((index, productBrand) => {
        const newPurchaseProducts = [...purchaseProducts];

        if(productBrand) {
            newPurchaseProducts[index] = {...newPurchaseProducts[index], productBrand, measurementUnit: productBrand.product.displayUnit?productBrand.product.displayUnit:productBrand.product.smallestUnit};
        } else
            newPurchaseProducts[index] = {...newPurchaseProducts[index], productBrand:null, measurementUnit: null};
        setPurchaseProducts(newPurchaseProducts);
    }, [purchaseProducts]);

    const onIsUrgentChange = useCallback( ( c, value )=>setIsUrgent(value), [] );

    const onRemovePurchaseProduct = useCallback((e) => {
        const productPurchaseId = purchaseProducts[e.target.dataset.index].productBrand?.id;
        if (isInStock === true) {
            const inventory = _.filter(inventories, function(inv) { return inv.productBrand.id === productPurchaseId });
            if (overFlowingProducts.length && inventory) {
                const newArrayOfOverflow = overFlowingProducts.filter(inv => inv?.productBrand.id !== inventory[0]?.productBrand.id);
                setOverFlowingProducts(newArrayOfOverflow);
            }
        }
        const newProducts = [...purchaseProductsRef.current];
        newProducts.splice(e.target.dataset.index, 1);
        setPurchaseProducts(newProducts);
    }, [overFlowingProducts, inventories, purchaseProducts, isInStock]);

    const onMeasurementUnitSelect = useCallbackCreator((index, id) => {
        let measurementUnit = null;
        const product = purchaseProducts[index].productBrand.product;
        if(product){
            if(product.smallestUnit && product.smallestUnit.id === id){
              measurementUnit = {...product.smallestUnit};
            }else if (product.conversionRules){
                for(let i=0; product.conversionRules.length; i++){
                   if(product.conversionRules[i].unit.id === id){
                       measurementUnit = product.conversionRules[i].unit;
                       break;
                   }
                }
            }
        }
        if (measurementUnit)
            onPropertyChange(index, 'measurementUnit', measurementUnit);
            const inventory = _.filter(inventories, function(inv) { return inv.productBrand.id === purchaseProducts[index].productBrand.id });
            if (inventory.length > 0 && inventory?.maxStockAmount !== null && purchaseProducts[index].quantity.length) {
                diffAmountChecker(...inventory, purchaseProducts[index].quantity, purchaseProducts[index]);
            }
    }, [purchaseProducts, inventories, diffAmountChecker]);

    const onAddProduct = useCallback(() => {
        setPurchaseProducts([...purchaseProducts, basePurchaseProduct({isInStock})]);
    },[purchaseProducts, isInStock]);

    const onFilesChange = useCallback((files) => {
        setFiles([...files]);
    },[]);

    // -------------- For requisitions edition

    const id = match.params.id;

    const [notFound, setNotFound] = useState(false);
    const purchaseRequisition = _.find(purchaseRequisitions, (pr) => pr.id === id);

    useEffect(() => {
        const purchaseRequisition = _.find(purchaseRequisitions, (pr) => pr.id === id);
        if (purchaseRequisition && purchaseRequisition.jazzArea) {
            setJazzArea(purchaseRequisition.jazzArea);
        }
    }, [purchaseRequisitions, id])

    //Initial load of requisition
    useEffect(() => {

        if( !id ) return;

        if (!purchaseRequisition && !notFound)
            api.purchaseRequisitions.get(0, 10, undefined, {id, sGroups: purchaseRequisitionProcessSGroups})
                .then(reqs => {
                    if (!reqs || !reqs.length || !_.find(reqs, (pr) => pr.id+'' === id+''))
                        setNotFound(true);
                })
    }, [api, id, purchaseRequisition, notFound]);


    //If the inStock flag changes, we need to edit the inStock property of each PurchaseProduct
    useEffect( ()=>{
        if(!purchaseRequisition) return;

        setIsUrgent( purchaseRequisition.isUrgent );
        setStockRequests( purchaseRequisition.stockRequests )
        setPurchaseProducts( purchaseRequisition.purchaseProducts.map(pp=>({
            ...pp,
            isInStock:!!pp.productBrand,
            quantity:Number(pp.quantity)
        })) );
        let isInStock = false;
        if( purchaseRequisition.purchaseProducts)
            isInStock = purchaseRequisition.purchaseProducts.reduce( (acc, pp)=>(!!pp.productBrand||acc), false );

        setIsInStock( isInStock );
    }, [purchaseRequisition]);
    // ---------- END edition only code

    // ---------- API Call for warning display and amount check
    useEffect(() => {
        if (defaultWarehouse?.id) {
            tideApi.inventories.get({ params: {productBrand: requestedProductBrandIds, warehouse: defaultWarehouse.id}}).then((resp) => {
                setInventories(resp);
            });
        }
    }, [tideApi, defaultWarehouse, requestedProductBrandIds]);

    useEffect(() => {
        tideApi.warehouses.get({params: {defaultDeliveryWarehouse:true}}).then((response)=>{
            setDefaultWarehouse(...response);
        });
    }, [tideApi])
    // ---------- END of API Call for warning display and amount check
    //------ START JazzArea Budget Exceed Checker
    const budgetJazzAreaExceedChecker = useCallback(() => {
        let total = 0
        purchaseProducts.forEach((x) => {
            if (x.productBrand && x.quantity) {
                const keyProductBrand = _.filter(requestedProductBrands, function(brand) { return brand.id === x.productBrand.id });
                const exactQuantity =  convertToSmallestUnit(x.quantity, x.measurementUnit, x.productBrand.product);
                const totalPrice = exactQuantity * (keyProductBrand[0]?.lastPrice||0);
                total += totalPrice;
            }
        });
        setTotalPrice(total);
    }, [requestedProductBrands, purchaseProducts]);

    //--------------------------------------------------------------
    const setIdOfProductRequested = useCallback(() => {
        const newProductBrandId = _.filter(purchaseProducts, function(pr){
            return !_.includes(requestedProductBrandIds, pr.productBrand?.id)
        }).map((pr) => pr.productBrand?.id);

            if (newProductBrandId[0] && newProductBrandId) {
                setRequestedProductBrandIds([...requestedProductBrandIds, ...newProductBrandId]);
                return newProductBrandId;
            }

    }, [requestedProductBrandIds, purchaseProducts]);
    //----------------------------------------------------------------------

    //-----------------------------------------------------------------------
    useEffect(() => {
        const newProductBrandId = setIdOfProductRequested();
        budgetJazzAreaExceedChecker();
        if (newProductBrandId){
            tideApi.productBrands.get({ id:newProductBrandId[0],
                params: {
                    sGroups: ["product_brand_read", "product_brand_read_last_price"] }})
                .then((response) => {
                    setRequestedProductBrands([...requestedProductBrands, response]);
                });
        }
    }, [purchaseProducts, tideApi, requestedProductBrands, setIdOfProductRequested, budgetJazzAreaExceedChecker, setIsInStock]);
    //---------------------------------------------------------------------------------

    useEffect(() => {
        if (jazzArea) {
            if (totalPrice > (jazzArea.budget - (jazzArea.monthlyUsedBudget == null ? 0 : jazzArea.monthlyUsedBudget))) {
                api.configurations.getSingle({id: 'URL_TO_EXTERNAL_FORM'})
                    .then((configuration) => setUrlExternalForm(configuration.value));
                setBudgetExceeded(true);
            } else {
                setBudgetExceeded(false);
            }
        }
    }, [totalPrice, jazzArea, api])
    //------ END JazzArea Budget Exceed Checker
    const getPercentageOutOfUsedBudget = useCallback((usedBudget, totalBudget) => {
        const expenses = Number(usedBudget);
        const budget = Number(totalBudget);
        const percentage = (expenses * 100) / budget
        if (!percentage) return 0;
        return (percentage <= 100) ? percentage.toFixed(3) : 100;
    }, []);
    //--- Budget percentage used for JazzArea


    // Upload new requisition to server
    const onSubmit = () => {

        if(budgetExceeded)
            return notifier.error("No es posible generar una requisición que exceda el presupuesto.");
        if(!jazzArea)
            return notifier.error("Debes seleccionar a qué área pertenece la requisición.");
        if(isInStock && !stockRequests?.length && !message.trim() && !purchaseRequisition?.cassette?.id)
            return notifier.error("Las requisiciones de línea sin una requisición de almacén asociada deben incluir un comentario describiendo la razón.");

        const newPurchaseProducts = [...purchaseProducts];

        if (!purchaseProducts.length)
            return notifier.error('Debes agregar al menos un producto.');

        for (let i = 0; i < newPurchaseProducts.length; i++) {

            if (!newPurchaseProducts[i].productBrand && (!newPurchaseProducts[i].name.trim() || !newPurchaseProducts[i].quantity))
                return notifier.error('El producto ' + (i + 1) + ' no esta completo, debes agregar nombre y cantidad.');


            const purchaseProduct = {...newPurchaseProducts[i]};

            const repeated=_.find(purchaseProducts, (pp, index)=>index!==i && pp.productBrand && purchaseProduct.productBrand && pp.productBrand.id===purchaseProduct.productBrand.id);
            if(repeated) return notifier.error(`El producto ${i+1} está repetido, elimina una entrada.`);

            const quant = Number(purchaseProduct.quantity);
            if (isNaN(quant) || quant <= 0)
                return notifier.error('El producto ' + (i + 1) + ' tiene una cantidad inválida, debe ser un número mayor a cero.');

            if (!purchaseProduct.isService && purchaseProduct.isInStock) {
                if (!purchaseProduct.measurementUnit) {
                    return notifier.error('El producto ' + (i + 1) + ' no esta completo, debes especificar la unidad de medida');
                } else {
                    purchaseProduct.measurementUnit = purchaseProduct.measurementUnit.id;
                }
            }

            if( purchaseProduct.productBrand){
                purchaseProduct.productBrand = purchaseProduct.productBrand.id;
            }

            purchaseProduct.quantity = Number(quant) + '';
            newPurchaseProducts[i]=purchaseProduct;
        }

        const comment = {text: ''};
        if(message || files.length>0){
            comment.text = message;
        }

        const stockRequestsIds = stockRequests?.map((sr)=>sr.id)||[];

        const newPurchaseRequisition = {
            purchaseProducts: newPurchaseProducts,
            stockRequests: stockRequestsIds,
            isUrgent,
            jazzArea: jazzArea?.id,
            sGroups:purchaseRequisitionListSGroups,
        };

        if( !purchaseRequisition )
            newPurchaseRequisition.cassette= {
                comments: comment.text?[comment]:[]
            };

        if( !purchaseRequisition )
        api.purchaseRequisitions.create(newPurchaseRequisition, loadingId, 'purchaseRequisitions',files)
            .then(() =>history.push('/compras/requisitions') );
        else
            api.purchaseRequisitions.edit(id, newPurchaseRequisition, 'purchaseRequisitions', loadingId )
                .then(() =>history.push('/compras/requisitions') );
    };


    const productBrandLabelCreator = useCallback((productBrand)=>{
        return productBrand.product.name + ' - ' + productBrand.brand.name;
    },[]);

    const providerLabelCreator = useCallback((provider)=>{
        return provider.name + (provider.commercialName?` (${provider.commercialName})`:'');
    },[]);

    // --------  Load all productBrandProviders for provider

    const loadProductsFromProvider = useCallback((provider)=>{
        api.productBrandProviders.get({page:0, pageSize:500, filters:{provider:provider.id, 'productBrand.product.isActive':true, sGroups:itemsListForRequisitionSGroups}})
            .then((productBrandProviders)=>{
                if(!productBrandProviders || !productBrandProviders.length)
                    return notifier.error('No se encontraron productos del proveedor '+provider.name);

                const newPurchaseProducts = productBrandProviders.map(({productBrand})=>{
                    let purchaseProduct=basePurchaseProduct({isInStock:true});
                    return {...purchaseProduct, productBrand, measurementUnit: productBrand.product.displayUnit?productBrand.product.displayUnit:productBrand.product.smallestUnit};
                });
                setPurchaseProducts(newPurchaseProducts);
                toggleProviderLoader();
            })
    },[api, notifier, toggleProviderLoader]);

    return (
        <div className="container-fluid main-container PurchaseRequisitionForm">
            {notFound ?
                <h2>No se encontró la requisición de compra</h2>
                :
                <Row>
                    <Col xs={12}>
                        <Card
                            title={ (purchaseRequisition? 'Editar':'Nueva') + " solicitud de compra"}
                            category=" "
                            content={
                                <div>
                                    <hr/>
                                    <div className='stock-request-block'>

                                        <p>Requisiciones de almacén{" "}
                                            <OverlayTrigger placement="bottom"
                                                overlay={<Tooltip id="stock-request-info">
                                                    Si esta requisición de compra fue generada para surtir una
                                                    requisición de almacén. Escribe el número de la requisición de
                                                    almacén y seleccionala de la lista.
                                                </Tooltip>}
                                            ><i className='fa fa-info-circle' />
                                            </OverlayTrigger>
                                        </p>

                                        <EntitySelect
                                            entity={"stockRequests"}
                                            value={stockRequests}
                                            onChange={setStockRequests}
                                            filterBy={"folio"}
                                            labelCreator={stockRequestLabelCreator}
                                            placeholder={"Escribe el folio"}
                                            additionalFilters={stockRequestFilters}
                                            initialLoad={true}
                                            multi
                                        />
                                    </div>
                                    <p className='text-muted small'>Todos los productos serán recibidos en {defaultWarehouse?.name}.</p>

                                    <div className='clearfix'/>
                                    <br/>
                                    <div className='pull-left'>
                                        {security.jazz.canRequestLineProducts() &&
                                            <div>
                                                <Switch
                                                    {...switchTranslations}
                                                    onChange={onIsInStockChange}
                                                    value={isInStock}
                                                />&nbsp;
                                                Productos de línea
                                            </div>
                                        }
                                        <br/>
                                        <br/>
                                        <p className='text-center'>Área a la que se dirige:</p>
                                        <div className='d-flex'>
                                            <TideEntitySelect
                                                entity='jazzAreas'
                                                value={jazzArea}
                                                onChange={setJazzArea}
                                                placeholder={'Selecciona área'}
                                                additionalFilters={jazzAreaFilters}
                                                filterLocal
                                            />
                                            {jazzArea && <p  style={{marginTop: '1em'}}>Presupuesto utilizado: {getPercentageOutOfUsedBudget(jazzArea.monthlyUsedBudget, jazzArea.budget)}%</p>}
                                        </div>
                                    </div>

                                    <h3 className='main-date'>{moment().format("DD/MM/YYYY")}</h3>
                                    <div className='clearfix'/>

                                    {budgetExceeded && jazzArea &&
                                        <div className='bg-danger text-center' style={{padding: '1em 0', marginTop: '1em'}}>
                                            <h5 className='text-uppercase' style={{margin: '0.5em 0'}}><strong>El presupuesto del área "{jazzArea?.name}" está siendo excedido.</strong></h5>
                                            <br/>
                                            <h6 className='m-0'>Cálculo hecho a partir del precio ofertado por el último proveedor de cada producto.</h6>
                                            <h6 className='m-0'>Ingresa al siguiente formulario para solicitar una extensión del presupuesto.</h6>
                                            <br/>
                                            <a href={urlExternalForm} target="blank" rel="noreferrer noopener">
                                                <CustomButton bsStyle='primary'>
                                                    Solicitar una extensión
                                                </CustomButton>
                                            </a>
                                        </div>
                                    }
                                    {!stockRequests?.length && isInStock &&
                                        <div className='bg-danger text-center'  style={{padding: '1em 0', marginTop: '1em'}}>
                                            <h6 className='text-uppercase' style={{margin: '0.2em 0'}}><strong>No se asoció una requisición de almacén</strong></h6>
                                        </div>
                                    }
                                    { overFlowingProducts.length > 0 &&
                                        <div className='bg-danger text-center'>
                                            <br/>
                                            <p>Estás rebasando la capacidad máxima que tiene el almacén {defaultWarehouse?.name} en los siguientes artículos:</p>
                                            <div className='w-100 d-flex flex-column'>
                                                {overFlowingProducts.map((inv) => (
                                                    <p key={inv.productBrand.product.id} className='text-danger'>-- {inv.productBrand.product.name} --</p>
                                                ))}
                                                <br/>
                                            </div>
                                        </div>
                                    }
                                    <br/>
                                    <h4 className='text-center table-title'>Productos y Servicios</h4>
                                    <Table className={'vertical-responsive-table'}>

                                        <thead>
                                        <tr>
                                            <th className='text-center col-xs-1'>#</th>
                                            <th className='col-xs-2'>Tipo</th>
                                            <th className='col-xs-4'>Producto o Servicio</th>
                                            <th className='col-xs-4'>Cantidad</th>
                                            {isInStock &&
                                                <th className='col-xs-4'>Unidad</th>}
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {purchaseProducts.map((purchaseProduct, i) => (
                                            <tr className='product-row' key={purchaseProduct.uid || purchaseProduct.id}>
                                                <td data-label='#' className='text-center'>
                                                    <i className='fa fa-trash remove-row'
                                                       onClick={onRemovePurchaseProduct}
                                                       data-index={i}/>
                                                       {i + 1}
                                                </td>
                                                <td data-label='Tipo'>
                                                    {isInStock ?
                                                        (
                                                        <select className='form-control'
                                                            value={'product'}
                                                            onChange={onTypeChange} data-index={i}>
                                                            <option value='product'>Producto</option>
                                                        </select>
                                                        ) :
                                                        (
                                                        <select className='form-control'
                                                            value={purchaseProduct.isService ? 'service' : 'product'}
                                                            onChange={onTypeChange} data-index={i}>
                                                            <option value='product'>Producto</option>
                                                            <option value="service">Servicio</option>
                                                        </select>
                                                        )
                                                    }
                                                </td>
                                                <td data-label='Producto'>
                                                    {purchaseProduct.isInStock ?
                                                        <EntitySelect
                                                            entity='productBrands'
                                                            value={purchaseProduct.productBrand}
                                                            onChange={onStockProductChange(i)}
                                                            additionalFilters={{'product.isActive':true, 'productBrandUnits.isActive':true}}
                                                            filterBy={'search'}
                                                            labelCreator={productBrandLabelCreator}
                                                        />
                                                        : <input className='form-control' value={purchaseProduct.name}
                                                                 onChange={onNameChange} data-index={i}/>
                                                    }
                                                </td>

                                                <td data-label='Cant.'>
                                                            <input className='form-control quantity-input' type='number'
                                                                   value={purchaseProduct.quantity}
                                                                   onChange={onQuantityChange}
                                                                   data-index={i}/>
                                                </td>
                                                {purchaseProduct.productBrand && !purchaseProduct.isService && purchaseProduct.isInStock ?
                                                <td data-label='Unidad'>
                                                    <div>
                                                        <Dropdown id='measurementUnits' onSelect={onMeasurementUnitSelect(i)}>
                                                            <Dropdown.Toggle variant="success"
                                                                             id="dropdown-basic">
                                                                {purchaseProduct.measurementUnit ? purchaseProduct.measurementUnit.name : "Selecciona la unidad"}
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                {
                                                                    purchaseProduct.productBrand.product.smallestUnit ?
                                                                        <MenuItem
                                                                            eventKey={purchaseProduct.productBrand.product.smallestUnit.id}
                                                                            key={purchaseProduct.productBrand.product.smallestUnit.id}>
                                                                            {purchaseProduct.productBrand.product.smallestUnit.name}
                                                                        </MenuItem>
                                                                        : null
                                                                }
                                                                {purchaseProduct.productBrand ?
                                                                    purchaseProduct.productBrand.product.conversionRules.map((cr) =>
                                                                        <MenuItem eventKey={cr.unit.id}
                                                                                  key={cr.unit.id}>{cr.unit.name}</MenuItem>)
                                                                    : null
                                                                }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </td>
                                                :null}
                                            </tr>
                                        ))}

                                        <tr className='add-product-row text-center' onClick={onAddProduct}>
                                            <td colSpan="10"><span className='link add-text'>+ Agregar producto</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    <div className={'bottom-container'}>
                                        <div className='urgent-container'>
                                            <span className='urgent-label'>¿Es un pedido urgente?&nbsp;</span>
                                            <Switch
                                                {...switchTranslations}
                                                value={isUrgent}
                                                onChange={onIsUrgentChange}
                                            />
                                        </div>
                                        <div className='clearfix'/>
                                        {isUrgent&&
                                            <div className='alert alert-warning'>
                                                <h5><strong>{me.employee.name}</strong> ¿Estás seguro que es un pedido urgente?</h5>
                                                Los pedidos urgentes son los cuales podrían detener la operación en caso de no ser obtenidos a tiempo.
                                            </div>}

                                        {security.jazz.canRequestLineProducts()?
                                        <div className='load-provider-wrap'>
                                            {isInStock &&
                                                <CustomButton className='primary mr-5' onClick={fulFillWarehouse}>Llenar inventario al máximo</CustomButton>
                                            }
                                            {providerLoaderOpen ?
                                                <>
                                                    <EntitySelect
                                                        entity='providers'
                                                        onChange={loadProductsFromProvider}
                                                        labelCreator={providerLabelCreator}
                                                        className='provider-input'
                                                        filterBy='search'
                                                    />
                                                    <CustomButton bsStyle='danger' onClick={toggleProviderLoader}>Cancelar</CustomButton>
                                                </> :
                                                <CustomButton bsStyle='primary' onClick={toggleProviderLoader}>Cargar productos de proveedor</CustomButton>
                                            }
                                        </div>
                                            :null}
                                        <div className='clearfix'/>
                                    </div>
                                    <hr/>

                                    {purchaseRequisition && purchaseRequisition.cassette ?
                                        <CommentsFeed
                                            title="Comentarios y archivos"
                                            cassetteId={purchaseRequisition.cassette.id}
                                        />
                                        :
                                        <CommentsFeed
                                            title="Comentarios y archivos"
                                            hideComments={true}
                                            hideSubmit={true}
                                            hideReload={true}
                                            onMessageChange={setMessage}
                                            onFilesChange={onFilesChange}
                                            messageAsProp={message}
                                            filesAsProp={files}
                                        />
                                    }

                                    <Button bsStyle='success' className='pull-right' onClick={onSubmit}
                                            disabled={!!loadingIds[loadingId]}>{loadingIds[loadingId] ? 'Enviando...' : 'Enviar solicitud'}</Button>
                                    <div className='clearfix'/>

                                </div>
                            }
                        />
                    </Col>
                </Row>
            }
        </div>

    );
};

const mapStateToMaps = ({api: {purchaseRequisitions=[], places = [], measurementUnits = []}, loadingIds}) => ({
    places,
    measurementUnits,
    loadingIds,
    purchaseRequisitions
});

export default connect(mapStateToMaps)(PurchaseRequisitionForm);
