import React from 'react';
import Switch from "react-bootstrap-switch";
import { FormControl } from 'react-bootstrap';


const OptionsValues = ( props )=>{

    if( props.question.type !== 'multiple-choice-radio' && props.question.type !== 'yesno' )
        return null;

    const onSwitchChange = ( comp, value )=>{

        const config = { ...props.question.config };
        config.valuatedOptions = value;

        if( value ) {
            config.optionsValues = {};
        }
        else{
            delete config.optionsValues;
        }

        props.onChange( config );
    };

    const onValueChange = ( {target} )=>{

        const option = target.dataset.option;
        const optionsValues = { ...props.question.config.optionsValues };

        if( target.value === '' ) {
            delete optionsValues[option];
        }
        else {
            optionsValues[option] = target.value;
            if( isNaN( Number(target.value) ) ) return;
        }

        props.onChange( {...props.question.config, optionsValues } );
    };

    const onEndEditing = ( {target} )=>{
        const option = target.dataset.option;
        const optionsValues = { ...props.question.config.optionsValues };

        if( target.value === '' || isNaN( Number(target.value) )  ) {
            delete optionsValues[option];
        }
        else
            optionsValues[option] = Number(target.value);

        props.onChange( {...props.question.config, optionsValues } );
    };

    const optionsValues= props.question.config.optionsValues;
    const options = props.question.type === 'yesno'? ['Si','No'] : props.question.config.options;

    return (
        <div className='OptionsValues'>
            <Switch
                value={ !!props.question.config.valuatedOptions }
                onChange={ onSwitchChange }
            />&nbsp;
            Agregar ponderación a respuestas
            { props.question.config.valuatedOptions && props.question.config.optionsValues?
            <div className='configuration-box'>
                <ul>
                    {options.map( (option, i)=>
                    <li key={i} >
                        <span>{option}</span>
                        <FormControl
                            value={ typeof optionsValues[option] === 'undefined'? '' : optionsValues[option]}
                            data-option={option}
                            onChange={onValueChange}
                            onBlur={ onEndEditing }
                            placeholder='Valor #'
                        />
                    </li>
                    )}
                </ul>
                <p className='text-muted small'>Los valores de las respuestas se usarán para promediar una calificación de esta pregunta.</p>
            </div>
                :null}

        </div>
    );
};


export default OptionsValues;
