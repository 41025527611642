import React, {useContext, useEffect, useState, useCallback} from 'react';
import FileIcon from '../../../components/FileIcon/FileIcon';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import {connect} from 'react-redux';
import LoadingIcon from "../../../components/Helper/LoaderIcon";
import FileViewer from "../../../components/FileViewer/FileViewer";
import {ApiContext} from "../../../api/Api";
import RepositoryFileFormModal from "./RepositoryFileFormModal";
import useBoolean from "../../../hooks/useBoolean";
import ConfirmDeleteFileModal from "./ConfirmDeleteFileModal";
import _ from 'lodash';
import {Redirect} from 'react-router-dom';
import {NotifierContext} from "../../../utils/Notifier";
import {SecurityContext} from "../../../utils/SecurityManager";


const loadingId = "@FileRepository.repositoryFiles.get";
const loadingNewFolderId = "@FileRepository.appFiles.create.folder";

const FileRepository = ( props )=>{

    const {history, repositoryFiles, actualFolder, loadingIds, match} = props;

    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);
    const security= useContext(SecurityContext);

    const actualFolderId = match.params.folder || null;
    const openFileId = match.params.openFile || null;

    const [creatingFolder, startCreatingFolder, stopCreatingFolder ] = useBoolean();
    const [creatingFile, startCreatingFile, stopCreatingFile ] = useBoolean();
    const [deletingFile, setDeletingFile] = useState(null);
    const [editingFile, setEditingFile] = useState(null);

    const closeEditingFile = useCallback(()=>setEditingFile(null), []);
    const closeDeletingFile = useCallback(()=>setDeletingFile(null),[]);

    //Load actual folder info
    useEffect( ()=>{
        api.clearProperty( "repositoryFiles" );
        api.clearProperty( "actualFolder" );

        if( actualFolderId && !isNaN(Number(actualFolderId)) )
            api.repositoryFiles.get({ filters:{id:actualFolderId, pagination: false}, loadingId, customProp:'actualFolder' })
                .then((files)=>{
                    //If the folder id was the id of a file instead of a folder, we open the parent folder
                    if(files && files.length && !files[0].isFolder)
                        history.replace('/alma/file-repository/' + (files[0].parent? files[0].parent.id : 'root'));
                });

        const filters = actualFolderId && actualFolderId!== 'root'? {parent:actualFolderId} : {'parent[exists]':false};
        filters.pagination= false;
        api.repositoryFiles.get({ filters, loadingId });
    }, [api, actualFolderId, history]);


    //Open a file modal
    const openId = Number(openFileId);
    let viewFile = null;
    if( openId ){
        viewFile = _.find(repositoryFiles, rf=>rf.id===openId);
    }

    const closeViewFile = useCallback(()=>{
        history.push('/alma/file-repository/' + (actualFolder? actualFolder.id:'root'));
    },[history, actualFolder]);

    const onSelect = useCallback(( repositoryFile )=>{

        if( repositoryFile.isFolder )
            history.push('/alma/file-repository/' + repositoryFile.id);
        else
            history.push('/alma/file-repository/' + (actualFolder? actualFolder.id:'root') +'/' + repositoryFile.id);

    },[history, actualFolder]);

    const goToParent = useCallback(()=>{

        if( !actualFolder ) return;
        api.clearProperty('actualFolder');
        history.push('/alma/file-repository/' +( actualFolder && actualFolder.parent? (actualFolder.parent.id) : 'root'));

    }, [api, actualFolder, history]);

    const createFolder = useCallback((folder)=>{

        if( !folder || !folder.name )
            return notifier.error("Selecciona un nombre para la carpeta");

        api.repositoryFiles.create({params:{
                parent: actualFolder? actualFolder.id:null,
                isFolder: true,
                ...folder,
            },
            loadingId:loadingNewFolderId
        })
            .then(stopCreatingFolder);
    },[api, notifier, actualFolder, stopCreatingFolder]);

    const createFile = useCallback((repositoryFile, files)=>{

        if(!repositoryFile || !files || !files.length )
            return notifier.error('Debes seleccionar un archivo');

        const parent = actualFolder? actualFolder.id : null;
        api.repositoryFiles[repositoryFile.isFolder?'create':'createMultiple'](
            {params:{parent, ...repositoryFile}, files:Array.from(files), loadingId:loadingNewFolderId })
            .then(stopCreatingFile)
    },[api, notifier, actualFolder, stopCreatingFile]);

    const editFile = useCallback((repositoryFile)=>{

        if(!repositoryFile )
            return notifier.error('No se encontró el archivo a editar');

        api.repositoryFiles.edit(
            {id:repositoryFile.id, params:{ ...repositoryFile}, loadingId:loadingNewFolderId })
            .then(closeEditingFile)
    },[api, notifier, closeEditingFile]);

    const confirmDeleteFile = useCallback(()=>{
        api.repositoryFiles.delete({id:deletingFile.id});
        setDeletingFile(null);
    },[api, deletingFile]);


    if( !actualFolderId )
        return <Redirect to={'/alma/file-repository/root'} />;
    let canEdit = security.alma.canCreateRepositoryFiles();//editRoles.indexOf(me.role && me.role.name) !== -1;

    return(
        <div className="container-fluid FileRepository">

            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">
                                { !actualFolder ? '/' : actualFolder.name } &nbsp;
                                { actualFolder ? <i className='fa fa-level-up' onClick={goToParent} />:null }
                            </h4>
                            {canEdit?
                                <div className='actions-container'>
                                    <span className='action-but' onClick={startCreatingFolder}> <i className='fa fa-folder-open-o' /> Nueva carpeta</span>
                                    <span className='action-but' onClick={startCreatingFile}> <i className='fa fa-files-o' />  Subir archivo </span>
                                </div>
                                :null}
                        </div>
                        <div className="card-content">

                            <div className='files-container'>

                                {repositoryFiles.map( (file)=>
                                    <FileIcon
                                        repositoryFile={file}
                                        key={file.id}
                                        onSelect={onSelect}
                                        onDelete={canEdit? setDeletingFile : null}
                                        onEdit={canEdit? setEditingFile : null}
                                    />
                                )}

                                {loadingIds[loadingId]?
                                    <h5><LoadingIcon/> Cargando...</h5>:
                                    null}
                                {repositoryFiles.length === 0 && !loadingIds[loadingId]?
                                    <h5>Parece que no hay ningún archivo aquí</h5>:
                                    null}
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {creatingFolder?
                <RepositoryFileFormModal
                    loading={loadingIds[loadingNewFolderId]}
                    onHide={stopCreatingFolder}
                    onSave={createFolder}
                    parent={actualFolder}
                    isFolder
                    />
                :null}

            {creatingFile?
                <RepositoryFileFormModal
                    loading={loadingIds[loadingNewFolderId]}
                    onHide={stopCreatingFile}
                    onSave={createFile}
                    parent={actualFolder}
                    isFolder={false}
                    />
                :null}

            {editingFile?
                <RepositoryFileFormModal
                    loading={loadingIds[loadingNewFolderId]}
                    onHide={closeEditingFile }
                    onSave={editFile}
                    repositoryFile={editingFile}
                    />
                :null}

            {deletingFile?
                <ConfirmDeleteFileModal
                    repositoryFile={deletingFile}
                    onHide={closeDeletingFile}
                    onDelete={confirmDeleteFile}
                    />
                :null}

            {/* File viewer modal */}
            {viewFile?
                <Modal
                    show={true}
                    onHide={closeViewFile}
                    bsSize='lg'
                >
                    <ModalHeader closeButton>
                        <ModalTitle>
                            {viewFile.name}
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <FileViewer
                            src={api.appFiles.getUrl( viewFile.appFile && viewFile.appFile.id )}
                            name={viewFile.name}
                        />
                    </ModalBody>

                    <ModalFooter>
                        <button
                            type='button'
                            onClick={closeViewFile}
                            className='btn btn-danger pull-left'
                        >
                            Cerrar
                        </button>
                        <a href={api.appFiles.getDownloadUrl( viewFile.appFile && viewFile.appFile.id )} target='_blank' rel="noopener noreferrer">
                            <button className='btn btn-success'>
                                Descargar
                            </button>
                        </a>
                    </ModalFooter>

                </Modal>
                :null}
            {/* END  File viewer modal */}

        </div>
    );

};

const mapStateToProps = ( {api:{me, actualFolder=[], repositoryFiles = []}, loadingIds=[]} )=>{
    return { me, actualFolder:actualFolder[0], repositoryFiles, loadingIds };
};

export default connect( mapStateToProps )( FileRepository );

/*
const editRoles = [
    'SUPER_ADMIN',
    'RECURSOS_HUMANOS',
    'GERENTE_ADMINISTRADOR_EE',
    'ADMINISTRADOR_EE',
    'JURIDICO'
];
*/
