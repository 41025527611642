import React, {useCallback} from 'react';
const PurchaseProductSpacer = ( { onHoverChange, isOpen, hasDiscount} )=>{

    const handleMouseEnter = useCallback( ()=>onHoverChange(true), [onHoverChange] );
    const handleMouseLeave = useCallback( ()=>onHoverChange(false), [onHoverChange] );

    return (
        <tr className={'PurchaseProductSpacerRow quotation-row ' + (isOpen? 'q-open':'')}
            onMouseEnter={ handleMouseEnter }
            onMouseLeave={ handleMouseLeave }
        >
            <td colSpan={hasDiscount? 13 : 11 }>
                <div className='outer-div'><div className='inner-div'>

                </div></div>
            </td>
        </tr>
    )
};

export default React.memo(PurchaseProductSpacer);
