import React, {useState, useContext} from 'react';
import ReactTable from 'react-table';
import useTideTable from '../../../hooks/table/useTideTable';
import {querySerialize, ApiContext} from '../../../api/Api';
import {listSGroups as sGroups} from '../../../utils/modelUtils/davinci/guestUtils';
import GuestDetailModal from './GuestDetailModal';
import _ from "lodash";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import {TideApiContext} from "../../../api/tideApiConfig";
import {NotifierContext} from "../../../utils/Notifier";
import {SecurityContext} from "../../../utils/SecurityManager";


const columns = [
    {
	Header: 'Nombre completo',
	id: 'name',
	accessor: g=>g.fullName
    },
    {
	Header: 'Ciudad/País',
	id: 'city',
	accessor: g=>[g.city, g.country?.spanishName].filter(n=>!!n).join('/')
    },
    {
	Header: 'Correo',
	id: 'email',
	accessor: g=>g.email
    }
];

const requestFilters = {sGroups, 'exists[originalGuest]': false, 'order[name]': 'ASC'};

const Guests = () => {
    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);
    const security = useContext(SecurityContext);
	const tideApi = useContext(TideApiContext);
    const [selectedGuestId, setSelectedGuestId] = useState(null);
    const [guestsSelectedToHomologate, setGuestsSelectedToHomologate] = useState([]);
    const [guestsId, setGuestsId] = useState([]);
    const [confirmHomologate, setConfirmHomologate] = useState(false);
    const guestsTable = useTideTable({
        entity: 'guests',
        columns,
        requestFilters,
		newTideApi: true,
		selectable: !!security?.ee?.canHomologateGuests()
    });
    const headers = {
	'fullName': 'Nombre completo',
	'city': 'Ciudad',
	'country.spanishName': 'Pais',
	'email': 'Email'
    };
    const reportConfig = JSON.stringify({name: 'Lista de huéspedes.xls', headers});
    const downloadTableURL = api.baseUrl+'/api/guests.xlsx?'+querySerialize({token: api.token, ...requestFilters, pagination: false, reportConfig});

    const getTrProps = (state, rowInfo)=>({
	onClick: (e)=>{
	    // Can't prevent bubble from checkbox because of `label`
	    if(e.target.type === "checkbox"){
	    	if (e.target.checked) {
	    		setGuestsSelectedToHomologate([...guestsSelectedToHomologate, {id: rowInfo.original.id, name: rowInfo.original.fullName}]);
	    		setGuestsId([...guestsId, rowInfo.original.id]);
			} else {
	    		const removedGuestArray = _.remove(guestsSelectedToHomologate, (guestId) => guestId.id !== rowInfo.original.id);
				setGuestsSelectedToHomologate(removedGuestArray);
				const removedGuestIdArray = _.remove(guestsId, (guestId) => guestId !== rowInfo.original.id);
				setGuestsId(removedGuestIdArray);
			}
		return;
	    }
	    if(e.target.nodeName.toLowerCase()==="label"){
		return;
	    }
	    setSelectedGuestId(rowInfo.original.id);
	},
	style: (rowInfo&&rowInfo.original)?{cursor: 'pointer'}:{},
    });

    const confirmHomologateGuests = () => {
		tideApi.guests.homologateGuests({params: {guests: guestsId}, method:"POST"})
			.then(() => {
				notifier.success('Huéspedes homologados');
				window.location.reload();
			})
	};

    return (
	<>
	    <div className="container-fluid main-container">
		<div className='pull-right'>
		    {guestsSelectedToHomologate.length > 1 && (
			<button className='btn btn-success'
					style={{marginRight: '10px'}}
					onClick={() => setConfirmHomologate(!confirmHomologate)}>
			    Homologar
			</button>
		    )}
		    <a className='btn btn-default' href={downloadTableURL} rel='noopener noreferrer'>
			Exportar a Excel
		    </a>
		</div>
		<div className='col-xs-12'>
		    <ReactTable
			{...guestsTable.tableProps}
			getTrProps={getTrProps}
		    />
		</div>
	    </div>
	    {!!selectedGuestId&&(
		<GuestDetailModal
		    guestId={selectedGuestId}
		    onHide={()=>setSelectedGuestId(null)}
		/>
	    )}
		<ConfirmModal
			show={confirmHomologate}
			onConfirm={confirmHomologateGuests}
			onCancel={() => setConfirmHomologate(false)}
			title='Homologar Huéspedes'
			message={
				<>
					¿Estás seguro que quieres homologar huéspedes seleccionados?
						{guestsSelectedToHomologate.map((guests) => {
							return <li key={guests.id}>{guests.name}</li>
						})}
				</>
			}
			/>
	</>
    );
};

export default Guests;
