import React, {useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import {ApiContext} from '../../../../api/Api';
import {Table} from 'react-bootstrap';
import _ from 'lodash';
import {percentageString} from "../../../../utils/MathUtils";

const loadingId1 = '@GuestsStatisticsReport.vipNonGrataReport';
const loadingId2 = '@GuestsStatisticsReport.generationReport';

const generations = ['silent', 'bb', 'x', 'millenials', 'z', 'other', 'unspecified'];
const genders = [{key: 'M', value: 'men'}, {key: 'F', value: 'women'}, {key:null, value: 'unspecified'}];

const generationStats = report => generation => {
    const buildEntry = (genderKey, count)=>({generation, [genderKey]: count});
    const genderInReport = gender => _.find(report, {gender});
    const builder = (v, k)=>v?buildEntry(k, v[generation]):buildEntry(k, 0);
    const genderEntries = _.map(genders, ({key, value})=>builder(genderInReport(key), value));
    const total = _.sumBy(report, e=>parseInt(e[generation]));
    return _.merge(...[...genderEntries, {generation, total}]);
};

const reportify = report=>
      _.keyBy(_.map(generations, generationStats(report)), 'generation');

const GuestsStatisticsReport = () => {
    const api = useContext(ApiContext);
    useEffect(()=>{
        api.guests.vipNonGrataReport({loadingId: loadingId1});
        api.guests.generationReport({loadingId: loadingId2});
    }, [api]);
    const vipNonGrataReport = useSelector(({api})=>api.vipNonGrataReport||[]);
    const generationReport = useSelector(({api})=>api.generationReport||[]);
    const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId1]||!!loadingIds[loadingId2]);

    const gs = reportify(generationReport);
    const total = _.sumBy(generationReport, v=>parseInt(v.genderTotal));

    const totalGetter = f => (v=>v?v.genderTotal:0)(_.find(generationReport, {gender:f}));
    const maleTotal = totalGetter('M');
    const femaleTotal = totalGetter('F');
    const unspecifiedTotal = totalGetter(null);

    return (
        <div className='GuestsStatisticsReport'>
            {loading && <h3 className='text-center'>Cargando...</h3>}
            <div className='row'>
                <div className='col-md-6 text-center'>
                    <label>VIP:</label>&nbsp;{vipNonGrataReport[0]&&vipNonGrataReport[0].vipCount}
                </div>
                <div className='col-md-6 text-center'>
                    <label>No gratos:</label>&nbsp;{vipNonGrataReport[0]&&vipNonGrataReport[0].nonGrataCount}
                </div>
            </div>
            <h2 className='text-center'>Generaciones</h2>
            <Table striped>
                <thead>
                    <tr>
                        <th></th>
                        <th>Hombres</th>
                        <th>Mujeres</th>
                        <th>No especificado</th>
                        <th><label>Total generación</label></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><label>Generación Z </label></td>
                        <td className='text-center'>{gs.z.men}</td>
                        <td className='text-center'>{gs.z.women}</td>
                        <td className='text-center'>{gs.z.unspecified}</td>
                        <td className='text-center'><strong>{gs.z.total}</strong></td>
                    </tr>
                    <tr>
                        <td><label>Generación Millenial</label></td>
                        <td className='text-center'>{gs.millenials.men}</td>
                        <td className='text-center'>{gs.millenials.women}</td>
                        <td className='text-center'>{gs.millenials.unspecified}</td>
                        <td className='text-center'><strong>{gs.millenials.total}</strong></td>
                    </tr>
                    <tr>
                        <td><label>Generación X</label></td>
                        <td className='text-center'>{gs.x.men}</td>
                        <td className='text-center'>{gs.x.women}</td>
                        <td className='text-center'>{gs.x.unspecified}</td>
                        <td className='text-center'><strong>{gs.x.total}</strong></td>
                    </tr>
                    <tr>
                        <td><label>Generación Baby Boomers</label></td>
                        <td className='text-center'>{gs.bb.men}</td>
                        <td className='text-center'>{gs.bb.women}</td>
                        <td className='text-center'>{gs.bb.unspecified}</td>
                        <td className='text-center'><strong>{gs.bb.total}</strong></td>
                    </tr>
                    <tr>
                        <td><label>Generación del Silencio</label></td>
                        <td className='text-center'>{gs.silent.men}</td>
                        <td className='text-center'>{gs.silent.women}</td>
                        <td className='text-center'>{gs.silent.unspecified}</td>
                        <td className='text-center'><strong>{gs.silent.total}</strong></td>
                    </tr>
                    <tr>
                        <td><label>Otra generación</label></td>
                        <td className='text-center'>{gs.other.men}</td>
                        <td className='text-center'>{gs.other.women}</td>
                        <td className='text-center'>{gs.other.unspecified}</td>
                        <td className='text-center'><strong>{gs.other.total}</strong></td>
                    </tr>
                    <tr>
                        <td><label>Sin fecha de nacimiento</label></td>
                        <td className='text-center'>{gs.unspecified.men}</td>
                        <td className='text-center'>{gs.unspecified.women}</td>
                        <td className='text-center'>{gs.unspecified.unspecified}</td>
                        <td className='text-center'><strong>{gs.unspecified.total}</strong></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td><label>Total género</label></td>
                        <td className='text-center'><strong>{maleTotal}/{percentageString(maleTotal/total)}</strong></td>
                        <td className='text-center'><strong>{femaleTotal}/{percentageString(femaleTotal/total)}</strong></td>
                        <td className='text-center'><strong>{unspecifiedTotal}/{percentageString(unspecifiedTotal/total)}</strong></td>
                        <td className='text-center'><strong>{total}</strong></td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    );
};

export default GuestsStatisticsReport;
