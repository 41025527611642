import React from 'react';
import PurchasedLineProductsReport from './PurchasedLineProductsReport';
import "./_JazzReports.scss";
import PurchaseProductsMonthlyReport from "./PurchaseProductsMonthlyReport";

const JazzReports = () => {
	return (
		<div className="JazzReports">
			<h2 className="text-center">Reportes</h2>
			<div className="card container-fluid">
				<div className="row">
					<div className="col-12 col-sm-6">
						<PurchasedLineProductsReport/>
					</div>
					<div className="col-12 col-sm-6">
						<PurchaseProductsMonthlyReport />
					</div>
				</div>
			</div>
		</div>
	);
};

export default JazzReports;
