import React, {Component} from 'react';
import {Modal, Table, FormGroup, ControlLabel, Col, Row} from 'react-bootstrap';
import Button from "../../../../elements/CustomButton/CustomButton";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Datetime from "react-datetime";
import moment from 'moment';
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";


class PersonnelRequisitionForm extends Component {

    static contextTypes = {api: PropTypes.object, notifier: PropTypes.object};

    constructor( props ){
        super(props);

        if( !props.canApprove )
            this.state = { form:{
                area: props.me.employee.area.id,
                lounge: props.me.employee.lounge.id,
                } };
        else
            this.state = { form:{} };

    }

    getFormValue = ( name )=>{
        return this.state.form[name] || '' ;
    };

    setFormValue = ( e )=>{
        this.setState( {form: {...this.state.form, [e.target.name]:e.target.value } } );
    };

    create = ()=>{
        const required = [ 'lounge', 'area', 'position', 'fulfillDate', 'requestComment'];

        for( let i=0; i<required.length; i++){
            if( !this.state.form[required[i]] ){
                this.context.notifier.error('No has llenado todos los campos');
                return;
            }
        }

        const daysOff = [];
        for( let i=0; i<7; i++){
            if( this.state['daysOff'+i] )
                daysOff.push(i);
        }

        const req = { ...this.state.form, daysOff };
        req.fulfillDate = req.fulfillDate.format('YYYY-MM-DD');

        this.props.onCreate( req );
    };

    render() {

        return (
            <Modal
                show={true}
                onHide={this.props.onHide}
            >
                <Modal.Header closeButton >
                    <Modal.Title>Detalle de solicitud</Modal.Title>
                </Modal.Header>

                <Modal.Body className='PersonnelRequisitionsForm'>

                    <Table>
                        <tbody>
                        <tr>
                            <td >Solicitante:</td>
                            <td >{employeeFullName(this.props.me.employee)}</td>
                        </tr>
                        <tr>
                            <td >Fecha de solicitud:</td>
                            <td >{moment().format('DD/MM/YYYY')}</td>
                        </tr>
                        </tbody>
                    </Table>

                    <h5>Datos generales</h5>

                    <div className='form-horizontal container-fluid'>

                        <Row>
                            <FormGroup>
                                <ControlLabel className="col-sm-4">ÁREA</ControlLabel>
                                <Col sm={8}>
                                    <select
                                        name="area"
                                        className="form-control"
                                        value={this.getFormValue('area')}
                                        onChange={this.setFormValue}
                                        disabled={!this.props.canApprove}
                                    >
                                        <option>Selecciona un área</option>
                                        {this.props.areas.map((area)=>
                                            <option value={area.id} key={area.id}>{area.name}</option>
                                        )}
                                    </select>
                                </Col>
                            </FormGroup>
                        </Row>

                        <Row>
                            <FormGroup>
                                <ControlLabel className="col-sm-4">SALA</ControlLabel>
                                <Col sm={8}>
                                    <select
                                        name="lounge"
                                        className="form-control"
                                        value={this.getFormValue('lounge')}
                                        onChange={this.setFormValue}
                                        disabled={!this.props.canApprove}
                                    >
                                        <option>Selecciona una sala</option>
                                        {this.props.lounges.map((lounge)=>
                                            <option value={lounge.id} key={lounge.id}>{lounge.name}</option>
                                        )}
                                    </select>
                                </Col>
                            </FormGroup>
                        </Row>

                        <Row>
                            <FormGroup>
                                <ControlLabel className="col-sm-4">PUESTO</ControlLabel>
                                <Col sm={8}>
                                    <select
                                        name="position"
                                        className="form-control"
                                        value={this.getFormValue('position')}
                                        onChange={this.setFormValue}
                                    >
                                        <option>Selecciona una puesto</option>
                                        {this.props.positions.map((position)=>
                                            <option value={position.id} key={position.id}>{position.name}</option>
                                        )}
                                    </select>
                                </Col>
                            </FormGroup>
                        </Row>

                        <Row>
                            <FormGroup>
                                <ControlLabel className="col-sm-4">FECHA PARA CUBRIR VACANTE</ControlLabel>
                                <Col sm={8}>
                                    <Datetime
                                        timeFormat={false}
                                        inputProps={{placeholder: "Seleccionar fecha"}}
                                        closeOnSelect={true}
                                        value={this.getFormValue('fulfillDate')}
                                        onChange={(date)=>this.setFormValue({target:{value:date, name:'fulfillDate'}})}
                                    />
                                </Col>
                            </FormGroup>
                        </Row>

                        <Row>
                            <FormGroup>
                                <ControlLabel className="col-sm-3">DÍA DE DESCANSO</ControlLabel>
                                <Col sm={3}>
                                    <div className='checkbox'  onClick={()=>this.setState( {daysOff1:!this.state.daysOff1 } )}>
                                        <input type='checkbox' checked={this.state.daysOff1||false} readOnly/>
                                        <label>Lunes</label>
                                    </div>
                                    <div className='checkbox'  onClick={()=>this.setState( {daysOff2:!this.state.daysOff2 } )}>
                                        <input type='checkbox' checked={this.state.daysOff2||false} readOnly/>
                                        <label>Martes</label>
                                    </div>
                                    <div className='checkbox'  onClick={()=>this.setState( {daysOff3:!this.state.daysOff3 } )}>
                                        <input type='checkbox' checked={this.state.daysOff3||false} readOnly/>
                                        <label>Miércoles</label>
                                    </div>                                </Col>
                                <Col sm={3}>
                                    <div className='checkbox'  onClick={()=>this.setState( {daysOff4:!this.state.daysOff4 } )}>
                                        <input type='checkbox' checked={this.state.daysOff4||false} readOnly/>
                                        <label>Jueves</label>
                                    </div>
                                    <div className='checkbox'  onClick={()=>this.setState( {daysOff5:!this.state.daysOff5 } )}>
                                        <input type='checkbox' checked={this.state.daysOff5||false} readOnly/>
                                        <label>Viernes</label>
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    <div className='checkbox'  onClick={()=>this.setState( {daysOff6:!this.state.daysOff6 } )}>
                                        <input type='checkbox' checked={this.state.daysOff6||false} readOnly/>
                                        <label>Sábado</label>
                                    </div>
                                    <div className='checkbox'  onClick={()=>this.setState( {daysOff7:!this.state.daysOff7 } )}>
                                        <input type='checkbox' checked={this.state.daysOff7||false} readOnly/>
                                        <label>Domingo</label>
                                    </div>
                                </Col>
                            </FormGroup>
                        </Row>

                        <p>Comentarios de la solicitud:</p>
                        <textarea name='requestComment' className='form-control' value={this.getFormValue('requestComment')} onChange={this.setFormValue}/>
                    </div>



                </Modal.Body>

                <Modal.Footer>

                    <Button bsStyle='danger' className='pull-left' onClick={this.props.onHide} >Cerrar</Button>

                    <Button bsStyle='success' onClick={ this.create }>Crear</Button>

                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = ( {api:{ me, areas=[], lounges=[], positions=[] }} )=>{
    return {me, areas, lounges, positions};
};

export default connect( mapStateToProps )(PersonnelRequisitionForm);
