import React, { useState, useContext } from 'react';
import {ControlLabel, Modal} from 'react-bootstrap';
import Button from "../../../../elements/CustomButton/CustomButton";
import useInput from '../../../../hooks/useInput';
import useNumberInput from "../../../../hooks/useNumberInput";
import Switch from 'react-bootstrap-switch';
import {NotifierContext} from "../../../../utils/Notifier";


const PositionFormModal = ( {onSave, onHide, onDelete, prefill = { name:'', vacancies:'', canFire: false }, editing = false } ) =>{

    const name = useInput( prefill.name );
    const vacancies = useNumberInput( prefill.vacancies||'' );
    const [canFire, setCanFire] = useState( prefill.canFire||false );

    const notifier = useContext( NotifierContext);

    const saveForm = ()=>{

        if( !name.value )
            return notifier.error( 'El nombre no puede estar vacío' );

        if( vacancies.value === '' )
            return notifier.error( 'Proporciona un número de vacantes autorizadas' );

        onSave( { name:name.value, vacancies: vacancies.value, canFire } );
    };

    return (
        <Modal
            show={true}
            onHide={onHide}
        >
            <Modal.Header closeButton >
                <Modal.Title>{editing? 'Edición de':'Nuevo'} puesto</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <ControlLabel>Nombre:</ControlLabel>
                <input className='form-control' {...name.bind} />

                <ControlLabel>Vacantes autorizadas:</ControlLabel>
                <input className='form-control' {...vacancies.bind} />

                <ControlLabel>Puede despedir gente sin autorización de un superior:</ControlLabel><br/>
                <Switch
                    value={ canFire }
                    onChange={ (s,value)=>setCanFire(value) }
                    onText={ 'Sí' }
                    offText={ 'No' }
                />

                <br/>
                { editing? <span className='link red pull-right' onClick={onDelete}>Eliminar puesto</span>: null }
                <br/>

            </Modal.Body>

            <Modal.Footer>

                <Button bsStyle='danger' className='pull-left' onClick={onHide} >Cerrar</Button>


                <Button bsStyle='success' onClick={saveForm}>Guardar</Button>

            </Modal.Footer>
        </Modal>
    );
};

export default PositionFormModal;
