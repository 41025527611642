import React,{useState, useCallback, useContext} from 'react';
import {imageForExtension} from "../../../utils/fileUtils";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import PurchaseInvoiceAndPaymentFormModal from "./PurchaseInvoiceAndPaymentFormModal";
import moneyFormatter from "../../../utils/moneyFormatter";
import moment from 'moment';
import ModalFile from "../../../components/ModalFile/ModalFile";
import {ApiContext} from "../../../api/Api";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import {TideApiContext} from "../../../api/tideApiConfig";

const PurchaseInvoicesAndPayments = ({purchaseOrder, onChange})=>{

    const api=useContext(ApiContext);
    const tideApi=useContext(TideApiContext);

    const [adding, setAdding]=useState(null);
    const setAddingCreator=useCallbackCreator(type=>setAdding(type),[]);
    const stopAdding=useCallback(()=>setAdding(null), []);

    const invoices= (purchaseOrder && purchaseOrder.purchaseInvoices)||[];
    const payments= (purchaseOrder && purchaseOrder.purchasePayments)||[];

    const invoicesSum=invoices.reduce( (acc, invoice)=>acc+Number(invoice.amount), 0);
    const paymentsSum=payments.reduce( (acc, invoice)=>acc+Number(invoice.amount), 0);

    const [showFile, setShowFile]=useState(null);
    const hideFile=useCallback(()=>setShowFile(null),[]);

    // ----------- delete stuff ----------
    const [deletingExtra, setDeletingExtra]=useState(null);
    const stopDeleting=useCallback(()=>setDeletingExtra(null),[]);

    const promptDeleteExtra=useCallbackCreator((which)=>{
        const [type, id]=which.split('-');
        setDeletingExtra({type, id});
    },[]);

    const deleteExtra=useCallback(()=>{
        stopDeleting();
        tideApi[deletingExtra.type].delete({id:deletingExtra.id})
            .then(onChange);
    },[deletingExtra, onChange, stopDeleting, tideApi]);

    // ------------ edit stuff ----------
    const [editing, setEditing]=useState(null);
    const stopEditing=useCallback(()=>setEditing(null),[]);

    return (
        <div className='PurchaseInvoicesAndPayments container-fluid'>
            <div className='row'>
                <div className='col-xs-12 col-md-6 side-block'>
                    <p className='total'>Total - {moneyFormatter(invoicesSum, 1)}</p>
                    <h5>Facturas</h5>
                    <table className='table table-striped'>
                        <tbody>
                        <tr>
                            <th>No.</th>
                            <th>Fecha</th>
                            <th>Cantidad</th>
                            <th className='text-center'>Archivos</th>
                            <th/>
                        </tr>
                        {invoices.map((invoice)=>
                            <tr key={invoice.id}>
                                <td>{invoice.invoiceNumber || 'na'}</td>
                                <td>{invoice.generatedDate? moment(invoice.generatedDate).format('DD/MM/YYYY'):'na'}</td>
                                <td className='amount-cell'>{moneyFormatter(invoice.amount, 1)}</td>
                                <td className='text-center'>
                                    {(invoice.appFiles||[]).map(appFile=>
                                        <button className='file-btn' key={appFile.id} onClick={()=>setShowFile(appFile)} title={appFile.originalName}>
                                            <img src={imageForExtension((appFile.name.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1] )} alt='file' />
                                        </button>
                                    )}
                                </td>
                                <td>
                                    <button className='edit-btn' onClick={()=>setEditing({type:'purchaseInvoices', entity:invoice})}>
                                        <i className='fa fa-pencil sky-blue'/>
                                    </button>
                                    <button className='delete-btn' onClick={promptDeleteExtra('purchaseInvoices-'+ invoice.id)}>
                                        <i className='fa fa-trash red'/>
                                    </button>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan={10} className='text-center'>
                                <CustomButton bsStyle='primary' onClick={setAddingCreator('invoice')}>Agregar</CustomButton>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className='col-xs-12 col-md-6 side-block'>
                    <p className='total'>Total - {moneyFormatter(paymentsSum, 1)}</p>
                    <h5>Pagos</h5>
                    <table className='table table-striped'>
                        <tbody>
                        <tr>
                            <th>No.</th>
                            <th>Fecha</th>
                            <th>Cantidad</th>
                            <th className='text-center'>Archivos</th>
                            <th/>
                        </tr>
                        {payments.map(payment=>
                            <tr key={payment.id}>
                                <td>{payment.transactionNumber}</td>
                                <td>{payment.paymentDate? moment(payment.paymentDate).format('DD/MM/YYYY'):'na'}</td>
                                <td className='amount-cell'>{moneyFormatter(payment.amount, 1)}</td>
                                <td className='text-center'>
                                    {(payment.appFiles||[]).map(appFile=>
                                        <button className='file-btn' key={appFile.id} onClick={()=>setShowFile(appFile)} title={appFile.originalName}>
                                            <img src={imageForExtension((appFile.originalName.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1] )} alt='file' />
                                        </button>
                                    )}
                                </td>
                                <td>
                                    <button className='edit-btn' onClick={()=>setEditing({type:'purchasePayments', entity:payment})}>
                                        <i className='fa fa-pencil sky-blue'/>
                                    </button>
                                    <button className='delete-btn' onClick={promptDeleteExtra('purchasePayments-'+ payment.id)}>
                                        <i className='fa fa-trash red'/>
                                    </button>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan={10} className='text-center'>
                                <CustomButton bsStyle='primary' onClick={setAddingCreator('payment')}>Agregar</CustomButton>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {adding&&
                <PurchaseInvoiceAndPaymentFormModal
                    onHide={stopAdding}
                    isInvoice={adding==='invoice'}
                    purchaseOrder={purchaseOrder}
                    onChange={onChange}
                />}

            {editing&&
                <PurchaseInvoiceAndPaymentFormModal
                    onHide={stopEditing}
                    editEntity={editing.entity}
                    isInvoice={editing.type==='purchaseInvoices'}
                    purchaseOrder={purchaseOrder}
                    onChange={onChange}
                />}

            {showFile&&
                <ModalFile
                    name={showFile.originalName}
                    src={api.appFiles.getUrl(showFile.id)}
                    onHide={hideFile}
                    downloadLink={api.appFiles.getDownloadUrl(showFile.id)}
                />}

            {deletingExtra&&
                <ConfirmModal
                    message={'¿Estas seguro que deseas eliminar '+ (deletingExtra.type==='purchaseInvoices'?'esta factura?':'este pago?')}
                    onConfirm={deleteExtra}
                    onCancel={stopDeleting}
                />
            }

        </div>
    );
};

export default PurchaseInvoicesAndPayments;
