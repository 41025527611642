import {convertFromSmallestUnit, convertToSmallestUnit, getSuggestedUnitToShow, unitConversion} from "./unitsUtility";
import _ from 'lodash';

export const stockMovementBatchStatus={
    PENDING_APPROVAL:'pending_approval',
    PENDING:'pending',
    CANCELED:'canceled',
    COMPLETED:'completed'
};

export const stockMovementBatchType={
    IN:'in',
    OUT:'out',
    SHRINKAGE:'shrinkage'
};

export const stockMovementBatchOrigin={
    PROVIDER_DELIVERY:'provider_delivery',
    REQUISITION: 'requisition',
    MANUAL: 'manual'
};

export const batchLoadingId = '@AddStockMovement.stockMovementBatches.create';

/**
 * movementProduct is the object created by react to easily keep the state of the form
 * StockMovement is the object the server receives and persists
 *
 * @param movementProducts
 * @param movementType
 */
export const movementProductsToStockMovements=(movementProducts, movementType)=>{

    if(!movementProducts)
        return [];

    const stockMovements=[];

    for (let i = 0; i < movementProducts.length; i++) {

        let quantity =  convertToSmallestUnit( movementProducts[i].quantity, movementProducts[i].selectedUnit, movementProducts[i].productBrand.product);

        if (movementType === stockMovementBatchType.OUT || movementType === stockMovementBatchType.SHRINKAGE)
            quantity = quantity * -1;

        const mp = {
            quantity,
            productBrand: movementProducts[i].productBrand,
            status: stockMovementBatchStatus.COMPLETED,
        };

        stockMovements.push(mp);
    }

    return stockMovements;

};

export const prepareStockMovementsForServer=(stockMovements)=>{
    return stockMovements.map( stockMovement=>({
        ...stockMovement,
        quantity:stockMovement.quantity+'',
        productBrand: stockMovement.productBrand.id,
    }) )
};

/**
 * Brand new stockMovementBatch without any purchase order or requisition attached
 *
 * @param api
 * @param stockMovements as created from the method movementProductsToStockMovements
 * @param movementType 'in' or 'out'
 * @param warehouse The warehouse entity to which the movements will be attached
 * @returns Promise
 */
export const createNewStockMovementBatch=(api, stockMovements, movementType, warehouse, shrinkageComment, recipeIngredients = null)=>{
    const stockMovementBatch = {
        warehouse: warehouse.id||warehouse,
        movements: {...prepareStockMovementsForServer(stockMovements), ...recipeIngredients},
        status: stockMovementBatchStatus.COMPLETED,
        inOrOut: movementType===stockMovementBatchType.SHRINKAGE?stockMovementBatchType.OUT:movementType,
        isShrinkage:movementType===stockMovementBatchType.SHRINKAGE,
        shrinkageComment: shrinkageComment
    };

    return api.stockMovementBatches.create({stockMovementBatch, loadingId:batchLoadingId});
};

export const getProductsDifferences=(newStockMovements, oldStockMovements)=>{

    const newMovements=[...newStockMovements];
    const differences=[];

    _.each(oldStockMovements, oldMovement=>{
        const indexInNew=_.findIndex(newMovements, mov=>mov.productBrand.id===oldMovement.productBrand.id);

        if(indexInNew===-1){
            differences.push({
                productBrand:oldMovement.productBrand,
                quantity: Number(oldMovement.quantity)
            });
            return;
        }

        const newMovement=newMovements[indexInNew];
        newMovements.splice(indexInNew, 1);

        const difference= oldMovement.quantity-newMovement.quantity;
        if(difference)
            differences.push({
                productBrand:oldMovement.productBrand,
                quantity:difference
            });
    });

    const remaining=newMovements.map( mov=>({productBrand: mov.productBrand, quantity:-mov.quantity}) );

    return differences.concat(remaining);
};

/**
 * We received exactly the products we were expecting, so we just have to close the movements
 *
 * @param api
 * @param stockMovementBatch
 * @returns {*}
 */
export const receivePerfectStockMovementBatch=(api, stockMovementBatch)=>{
    const serverSMB={id:stockMovementBatch.id};
    serverSMB.status=stockMovementBatchStatus.COMPLETED;

    if(stockMovementBatch.purchaseOrder)
        serverSMB.closePurchaseOrder=true;

    serverSMB.movements =
        prepareStockMovementsForServer(stockMovementBatch.movements)
        .map( mov=>({...mov, status: stockMovementBatchStatus.COMPLETED}));

    return api.stockMovementBatches.edit({id:serverSMB.id, stockMovementBatch:serverSMB, loadingId:batchLoadingId});
};

/**
 * When we receive the products, we mark the purchaseOrder as complete and all the movements with the values from the form
 * even though it's incomplete, it will never be completed so just close everything with what we have
 *
 * @param api
 * @param stockMovementBatch
 * @param movementProducts
 * @returns {*}
 */
export const receiveForcedStockMovementBatch=(api, stockMovementBatch, movementProducts)=>{

    const stockMovements=movementProductsToStockMovements(movementProducts);

    const dir=stockMovementBatch.inOrOut==='out'?-1:1;
    //Add ids to update the entities
    _.each(stockMovements, newMov=>{
        const oldMov=_.find(stockMovementBatch.movements, oldMov=>oldMov.productBrand.id===newMov.productBrand.id);
        newMov.quantity=dir*Math.abs(newMov.quantity);//Force the direction
        if(oldMov)
            newMov.id=oldMov.id;
    });

    const serverSMB={id:stockMovementBatch.id};
    serverSMB.status=stockMovementBatchStatus.COMPLETED;
    serverSMB.movements =
        prepareStockMovementsForServer(stockMovements)
        .map( mov=>({...mov, status: stockMovementBatchStatus.COMPLETED}));

    if(stockMovementBatch.purchaseOrder)
        serverSMB.closePurchaseOrder=true;

    return api.stockMovementBatches.edit({id:serverSMB.id, stockMovementBatch:serverSMB, loadingId:batchLoadingId});
};

/**
 * Recipes have ingredients which could be either products or other recipes. This function fetchs all the products from a recipe,
 * including the products inside other recipe which if it is an ingredient of the principal recipe.
 *
 * @param recipe // A recipe object from orozcoRecipes entity
 * @param fetcher // An accumulator such as an empty array: []
 * @returns [] // Fetcher param will include all the products after this function
 */

export const productGetterFromRecipeObject = (recipe, fetcher, currentRecipeYield, yieldFactor, api, warehouse) => {

    recipe.orozcoRecipeIngredients.forEach((ingredient) => {
        const quantity = (Number(ingredient.quantity) * yieldFactor) +(Number(ingredient.quantity) * yieldFactor)*ingredient.expectedShrinkage;
        if(ingredient.product) {
            if (_.includes(_.map(fetcher, 'productBrand'), ingredient.product.id)) {
                const index = _.indexOf(_.map(fetcher, 'productBrand'), ingredient.product.id);
                fetcher[index].quantity = (Number(fetcher[index].quantity)+quantity)+''
            } else {
                fetcher.push({ quantity: quantity+'', productBrand: ingredient.product.id });
            }
        } else if (ingredient.orozcoRecipe) {
            const newCurrentRecipeYield = ingredient.quantity * yieldFactor;
            const newYieldFactor = ingredient.orozcoRecipe.displayUnit?(newCurrentRecipeYield/ingredient.orozcoRecipe.quantity):1;
            productGetterFromRecipeObject(ingredient.orozcoRecipe, fetcher, newCurrentRecipeYield, newYieldFactor, api, warehouse);
        } else {
            return;
        }
    });

    return fetcher;
}

/**
 * When we receive the products and they were incomplete, but we expect to receive them in the future we split the stockMovementBatch
 * We close the actual one modifying the quantities to the ones received and we create a pending new one with the remaining products
 *
 * @param api
 * @param stockMovementBatch
 * @param movementProducts
 * @param differences
 * @param warehouse
 * @param movementType
 * @returns {*}
 */
export const receiveStockMovementBatchAndCreatePending=(api, stockMovementBatch, movementProducts, differences, warehouse, movementType)=>{

    const dir=stockMovementBatch.inOrOut==='out'?-1:1;
    //Quitamos los productos que tienen sobrantes, esos no queremos que queden como entradas pendientes
    //Luego cambiamos el signo de los faltantes, para que representen el movimiento pendientes
    const filteredDifferences=_.filter(differences, diff=>(diff.quantity*dir)>0);

    const pendingStockMovementBatch = {
        warehouse: warehouse.id,
        movements: prepareStockMovementsForServer(filteredDifferences)
            .map( mov=>({...mov, status: stockMovementBatchStatus.PENDING})),
        status: stockMovementBatchStatus.PENDING,
        inOrOut: movementType
    };

    if(stockMovementBatch.purchaseOrder)
        pendingStockMovementBatch.purchaseOrder=stockMovementBatch.purchaseOrder.id;
    if(stockMovementBatch.stockRequest)
        pendingStockMovementBatch.stockRequest=stockMovementBatch.stockRequest.id;

    return api.stockMovementBatches.create({stockMovementBatch:pendingStockMovementBatch, loadingId:batchLoadingId})
        .then(()=>receiveForcedStockMovementBatch(api, stockMovementBatch, movementProducts));
};

export const stockMovementBatchToStockMovementWithUnit=(stockMovementBatch)=>{

        return stockMovementBatch.movements.map( movement=>{

            let purchaseProduct;
            if(stockMovementBatch.purchaseOrder)
                purchaseProduct=_.find(stockMovementBatch.purchaseOrder.purchaseProducts, pp=>pp.productBrand?.id===movement.productBrand.id);

            const newMovement={productBrand: movement.productBrand};

            try{
                if(purchaseProduct) {
                    newMovement.quantity = unitConversion(movement.quantity, movement.productBrand.product.smallestUnit, purchaseProduct.measurementUnit, purchaseProduct.productBrand.product);
                    newMovement.selectedUnit=purchaseProduct.measurementUnit;
                }
                else {
                    const unit=getSuggestedUnitToShow(movement.quantity, movement.productBrand.product)||movement.productBrand.product.smallestUnit;
                    newMovement.quantity = convertFromSmallestUnit(Math.abs(movement.quantity), unit, movement.productBrand.product);
                    newMovement.selectedUnit=unit;
                }
                return newMovement
            }
            catch(e){
                console.error(e.message);
                return stockMovementForForm(movement);
            }


        });

};

const stockMovementForForm= stockMovement=>({
    productBrand: stockMovement.productBrand,
    quantity: Math.abs(stockMovement.quantity),
    selectedUnit: stockMovement.productBrand.product.smallestUnit
});
