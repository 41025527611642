import React, {useState, useContext, useEffect, useMemo, useCallback} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import moment from 'moment';
import {Grid, Row, Col, Table} from 'react-bootstrap';
import {ApiContext} from "../../../../api/Api";
import _ from 'lodash';
import {connect} from 'react-redux';
import tableDataBuilder, {SEPARATOR} from "../../../../utils/tableDataBuilder";
import AppliedIncidencesListModal from "../../Manager/AppliedIncidencesListModal";
import Switch from "react-bootstrap-switch";


const AppliedIncidencesReport = ({appliedIncidencesReport}) => {

    const api = useContext(ApiContext);

    const [dates, setDates] = useState({from: moment().date(1).month(0), to: moment()});
    const [hidePositives, setHidePositives] = useState(true);

    //const [yFilter, setYFilter] = useState({name: 'Meses', value: 'month',paths: {appliedIncidences: 'month'}});
    const [yFilter, setYFilter] = useState( {name: 'Incidencia', value: 'type', paths: {appliedIncidences: 'incidence.name'}});

    const [xFilters, setXFilters] = useState([
        {name: 'Sala', value: 'lounge', paths: {appliedIncidences: 'employee.lounge.name'}}
    ]);

    const [appIncidencesFilters, setAppIncidencesFilters] = useState(null);

    /**
     * Load Data
     */
    useEffect(() => {
        const options = {groupBy: []};

        if (yFilter)
            options.groupBy.push(yFilter.value);

        setHiddenNames( [] );

        _.forEach(xFilters, ({value}) => options.groupBy.push(value));

        options.fromShiftDate = dates.from.format('YYYY-MM-DD');
        options.toShiftDate = dates.to.format('YYYY-MM-DD');

        options.hidePositives = hidePositives;

        api.appliedIncidences.getReport(options);

    }, [api, xFilters, yFilter, dates, hidePositives]);

    const tableData = useMemo(() => {

        return tableDataBuilder(appliedIncidencesReport, xFilters, yFilter);

        // eslint-disable-next-line
    }, [appliedIncidencesReport]);

    const onDateChange = (e, {startDate, endDate}) => {
        setDates({from: moment(startDate), to: moment(endDate)});
    };

    const removeYFilter = () => setYFilter(null);

    const addYFilter = ({target}) => setYFilter(availableFilters[target.value]);

    const removeXFilter = ({target}) => {
        const newFilters = [...xFilters];
        newFilters.splice(target.dataset.index, 1);
        setXFilters(newFilters);
    };

    const addXFilter = ({target}) => {
        const newFilters = [...xFilters];
        newFilters.push(availableFilters[target.value]);
        setXFilters(newFilters);
    };

    const showAppliedIncidencesTable = (xFilterValues, yFilterValue = '') => {
        const appliedIncidencesPaths = _.map(_.map(xFilters, 'paths'), 'appliedIncidences');

        if(yFilterValue)
            appliedIncidencesPaths.push(yFilter.paths.appliedIncidences);

        const apiFilters = _.zipObject(appliedIncidencesPaths, (xFilterValues+'|'+yFilterValue).split('|'));
        apiFilters['shiftDate[after]']= dates.from.format('YYYY-MM-DD');
        apiFilters['shiftDate[before]']= dates.to.format('YYYY-MM-DD');
        //apiFilters['incidence.isPositive'] = false;

        setAppIncidencesFilters(apiFilters);
    };

    const hideIncidencesDetails = ()=>{
        setAppIncidencesFilters(null);
    };

    const [hiddenNames, setHiddenNames] = useState( [] );

    const addHiddenName = useCallback(( {currentTarget} )=>{

        if( xFilters.length > 1 )
            return;

        const name = currentTarget.dataset.name;
        if( hiddenNames.indexOf(name) !== -1 ) return;
        setHiddenNames( [...hiddenNames, name] );
    },[hiddenNames, xFilters]);

    const removeHiddenName = useCallback( ( {target} )=>{

        const index = hiddenNames.indexOf( target.innerText );
        if( index!== -1 ){
            const newNames = [...hiddenNames];
            newNames.splice( index, 1 );
            setHiddenNames( newNames );
        }

    }, [hiddenNames] );

    const xTotals = [];
    const yTotals = [];

    let columnsCodes = tableData.columnsCodes;

    const doesOneNameHasCode = ( names, code )=>
        _.find(names, hn =>
            (new RegExp("(^|\\|)" +
            hn.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&') // Escape for regexp
            + "(\\||$)")).exec(code));

    if( hiddenNames.length )
        columnsCodes = columnsCodes.filter((code) => !doesOneNameHasCode( hiddenNames, code ) );

    return (
        <div className='LayoffsReport'>

            <div>
                Ocultar incidencias positivas&nbsp;
                <Switch
                    offText='No'
                    onText='Sí'
                    onChange={(sw, value)=>setHidePositives(value)}

                />
                <br/>
                <span className='text-muted small'>Incidencias positivas son descansos, vacaciones, cursos, etc.</span>
                <br/><br/>
            </div>

            <Grid fluid>

                <Row>
                    <DateRangePicker
                        startDate={dates.from}
                        endDate={dates.to}
                        onApply={onDateChange}
                        containerStyles={{}}
                    >

                        <CustomButton
                            bsStyle={'primary'}
                            className='col-xs-12 range-button'
                        >
                            Rango de fechas &nbsp;
                            <strong>{dates.from.format('YYYY-MM-DD')}</strong> al <strong>{dates.to.format('YYYY-MM-DD')}</strong>
                        </CustomButton>

                    </DateRangePicker>
                </Row>

                <Row>
                    <Col md={6} xs={12}>
                        <p className='filter-title'>
                            Selecciona filtros para el eje X
                            <select value='' onChange={addXFilter} className='form-control'>
                                <option value='' disabled>Agrega un filtro</option>
                                {availableFilters.map((filter, index) => {
                                        if ((yFilter && yFilter.value === filter.value) || _.find(xFilters, (fil) => fil.value === filter.value))
                                            return null;
                                        else
                                            return <option value={index} key={filter.value}>{filter.name}</option>;
                                    }
                                )}
                            </select>
                        </p>
                        <ul className='filter-list'>
                            {!xFilters || !xFilters.length ?
                                <li className='filter-item'>Sin filtro</li>
                                :
                                xFilters.map((filter, index) =>
                                    <li className='filter-item' key={filter.value}>{filter.name}
                                        <i className='fa fa-close' onClick={removeXFilter} data-index={index}/>
                                    </li>
                                )
                            }
                        </ul>
                    </Col>

                    <Col md={6} xs={12}>
                        <p className='filter-title'>
                            Selecciona filtros para el eje Y
                            <select value='' onChange={addYFilter} className='form-control'>
                                <option value='' disabled>Agrega un filtro</option>
                                {yFilter ? null : availableFilters.map((filter, index) => {
                                        if (_.find(xFilters, (fil) => fil.value === filter.value))
                                            return null;
                                        else
                                            return <option value={index} key={filter.value}>{filter.name}</option>;
                                    }
                                )}
                            </select>
                        </p>
                        <ul className='filter-list'>
                            {yFilter ?
                                <li className='filter-item'>
                                    {yFilter.name}
                                    <i className='fa fa-close' onClick={removeYFilter}/>
                                </li>
                                :
                                <li className='filter-item'>
                                    Sin filtro
                                </li>
                            }
                        </ul>
                    </Col>

                </Row>

                {hiddenNames.length?
                <Row>
                    <Col>
                        <div className='hidden-names-container'>
                            <p>Excluir resultados:</p>
                            {hiddenNames.map( name=>
                                <span key={name} className='hidden-name' onClick={removeHiddenName}>{name} </span>
                            )}
                        </div>
                    </Col>
                </Row>
                    :null}

            </Grid>

            {/*  ----------   Render table -----------  */}

            <div className='table-container'>
                <Table>

                    <thead>
                    {tableData.headerRows.map((row, i) =>
                        <tr key={i}>
                            {yFilter ?
                                <th>~</th>
                                : null}
                            {row.map((col, j) => {

                                    if( doesOneNameHasCode( hiddenNames, col.name ) )
                                        return null;

                                    return <th
                                        colSpan={col.size}
                                        key={`${i}-${j}`}
                                        data-name={col.name}
                                        onClick={addHiddenName}
                                    >
                                        {col.name}</th>
                                }
                            )}

                            { xFilters && xFilters.length && (tableData.headerRows.length-1) === i?
                                <th>Total</th>
                            :null}
                        </tr>
                    )}
                    </thead>

                    <tbody>

                    {tableData.rowHeaders.map((rHeader, i) =>// i is y axis coordinate

                        <tr key={rHeader}>
                            {yFilter ?
                                <th style={{width:100}}>{rHeader ? rHeader : ('Sin ' + yFilter.name)}</th>
                                :
                                null
                            }

                            {columnsCodes.map((code, j) => {// j is x axis coordinate
                                const path = code.split(SEPARATOR);
                                let entries = tableData.data[path[0]];

                                if( yTotals.length < (j+1) )
                                    yTotals.push(0);

                                if( xTotals.length < (i+1) )
                                    xTotals.push(0);

                                for (let i = 1; i < path.length; i++) {
                                    if (entries && entries.subHeaders && entries.subHeaders[path[i]]) {
                                        entries = entries.subHeaders[path[i]];
                                    }
                                    else {
                                        return <td className='empty' key={code}>0</td>;
                                    }
                                }
                                if (entries && entries.count) {

                                    xTotals[i] += Number(entries.count);
                                    yTotals[j] += Number(entries.count);
                                    return <td key={code}
                                               className='pointer'
                                               onClick={() => showAppliedIncidencesTable(code)}>{entries.count}</td>;
                                }
                                if (entries && entries[rHeader] && entries[rHeader].count) {

                                    xTotals[i] += Number(entries[rHeader].count);
                                    yTotals[j] += Number(entries[rHeader].count);
                                    return <td key={code}
                                               className='pointer'
                                               onClick={() => showAppliedIncidencesTable(code, rHeader)}>{entries[rHeader].count}</td>;
                                }
                                else
                                    return <td className='empty' key={code}>0</td>;
                            })}

                            { xFilters && xFilters.length?
                                <td>{xTotals[i]}</td>
                                :null}
                        </tr>
                    )}

                    { yFilter?
                        <tr>
                            <th style={{width:240}}>Total</th>
                            {yTotals.map( (total, i)=><td key={i}>{total}</td> )}

                            { xFilters && xFilters.length?
                            <td><strong> { yTotals.reduce( (acc, t)=>acc+t, 0 ) || xTotals.reduce( (acc, t)=>acc+t, 0 ) } </strong> </td>
                                :null}
                        </tr>
                        :null}

                    </tbody>

                </Table>
            </div>
            {appIncidencesFilters?
                <AppliedIncidencesListModal filters={appIncidencesFilters} onHide={hideIncidencesDetails} />: null
            }

        </div>
    );
};

const mapStateToProps = ({api: {appliedIncidencesReport}}) => ({appliedIncidencesReport});

export default connect(mapStateToProps)(AppliedIncidencesReport);

const availableFilters = [
    {name: 'Área', value: 'area', paths: {appliedIncidences: 'employee.area.name'}},
    {name: 'Grupo', value: 'group', paths: {appliedIncidences: 'employee.group.name'}},
    {name: 'Meses', value: 'month', paths: {appliedIncidences: 'month'}},
    {name: 'Puesto', value: 'position', paths: {appliedIncidences: 'employee.position.name'}},
    {name: 'Sala', value: 'lounge', paths: {appliedIncidences: 'employee.lounge.name'}},
    {name: 'Turnos', value: 'shift', paths: {appliedIncidences: 'employee.shift.type'}},
    {name: 'Incidencia', value: 'type', paths: {appliedIncidences: 'incidence.name'}},
    {name: 'Día de la semana', value: 'weekday', paths: {appliedIncidences: 'weekday'}},
    {name: 'Colaborador', value: 'employee', paths: {appliedIncidences: 'search'}}
];
