import React, {useMemo, useState, useCallback} from 'react';
import {Table, OverlayTrigger, Tooltip} from 'react-bootstrap';
import moneyFormatter from "../../../utils/moneyFormatter";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import AdjustablePOCell from "../orders/components/AdjustablePOCell";
import _ from 'lodash';
import {getProperty} from "../../../utils/jsUtils";
import {displayAmount} from "../../../utils/modelUtils/almazen/unitsUtility";

const PurchaseProductsTable = ({purchaseOrder, onAddAdjustment}) =>{
    const po=purchaseOrder;
    const purchaseProducts=po.purchaseProducts;
    const currency=po.purchaseRequisition && po.purchaseRequisition.currency;

    const hasDiscount= purchaseProducts.reduce( (acc, pp)=>acc+Number(pp.selectedPurchaseProductQuote.unitDiscount), 0);

    const [adjustingValue, setAdjustingValue]=useState(null);
    const startAdjusting=useCallbackCreator((id)=>{
        const [index, type]=id.split('-');
        let value=type==='quantity'?
            purchaseProducts[index].quantity:
            purchaseProducts[index].selectedPurchaseProductQuote.unitPrice;
        value=Number(value)+'';//Casting to number and back to string for removing extra zeros
        setAdjustingValue({index:Number(index), type, value});
    },[purchaseProducts]);

    const handleAdjustmentChange=useCallback(({target})=>{
        setAdjustingValue({...adjustingValue, value:target.value});
    },[adjustingValue]);

    const handleAdjustmentSave=useCallbackCreator((id)=>{
        const [index]=id.split('-');
        const purchaseProduct=purchaseProducts[index];

        onAddAdjustment({...adjustingValue, purchaseProduct});
        setAdjustingValue(null);
    },[purchaseProducts, adjustingValue, onAddAdjustment]);

    const cancelAdjustment=useCallback(()=>setAdjustingValue(null),[]);

    const pendingBatch = useMemo(
        ()=>
            _.find(po.stockMovementBatches, ({movements})=>!!_.find(movements, ({status})=>status==='pending')),
        [po]);
    const getPurchaseProductStatus = useCallback(purchaseProduct => {
        const productBrandId = getProperty(purchaseProduct,'productBrand','id');
        if(!productBrandId){
            return null;
        }
        let movement;
        if(pendingBatch)
            movement= _.find(pendingBatch.movements, ({productBrand})=>
                   (productBrand&&productBrand.id)===productBrandId);
        // if not found on pending batch, it's already fulfilled
        if(!movement){
            return {
                status: 'fulfilled'
            };
        }
        return {
            status: 'unfulfilled',
            missing: displayAmount(movement.quantity, purchaseProduct.productBrand.product),
        };
    }, [pendingBatch]);

    return(
        <Table className='text-center PurchaseProductsTable' responsive>

            <thead>
            <tr>
                <th className='text-center'>#</th>
                <th className='text-center'>Tipo</th>
                <th className='text-center'>De línea</th>
                <th className='text-left'>Producto / Servicio</th>
                <th className='text-center col-xs-1'>Cantidad</th>
                <th className='text-center col-xs-1'>Status</th>
                <th className='text-center col-xs-2'>Proveedor</th>
                <th className='text-center col-xs-1'>Precio unitario</th>
                {hasDiscount?<th className='text-center col-xs-1'>Descuento unitario</th>:null}
                {hasDiscount?<th className='text-center col-xs-1'>Precio con descuento</th>:null}
                <th className='text-center'>Subtotal</th>
                {hasDiscount?<th className='text-center'>Descuento total</th>:null}
                {hasDiscount?<th className='text-center'>Subtotal con descuento</th>:null}
                <th className='text-center'>Impuestos</th>
                <th className='text-center'>Total</th>
            </tr>
            </thead>

            <tbody>
            {purchaseProducts && purchaseProducts.map((purchaseProduct, i) => (

                <tr className='product-row' key={purchaseProduct.id}>
                    <td className='text-center'> {i + 1}</td>
                    <td className='text-center'>{purchaseProduct.isService ? 'Servicio' : 'Producto'}</td>
                    <td className='text-center'>{purchaseProduct.productBrand ? 'Si' : 'No'}</td>
                    <td className='text-left'>{purchaseProduct.productBrand ?
                        ( purchaseProduct.productBrand.product.name + ' - ' + purchaseProduct.productBrand.brand.name)
                        : purchaseProduct.name}</td>
                    <AdjustablePOCell
                        adjusting={adjustingValue && adjustingValue.type==='quantity' && adjustingValue.index===i}
                        adjustment={purchaseProduct.purchaseProductAdjustment?purchaseProduct.purchaseProductAdjustment.quantity:0}
                        inputValue={adjustingValue?adjustingValue.value:''}
                        onInputChange={handleAdjustmentChange}
                        unitName={purchaseProduct.measurementUnit ? purchaseProduct.measurementUnit.name : null}
                        onSave={handleAdjustmentSave(i+'-quantity')}
                        onCancel={cancelAdjustment}
                        value={purchaseProduct.quantity}
                        valueFormatter={(v)=>`${Number(v)} ${purchaseProduct.measurementUnit ? purchaseProduct.measurementUnit.name : ''}`}
                        onAdjustStart={startAdjusting(i+'-quantity')}
                    />
                    <td>
                        {(status=>status?(
                            <OverlayTrigger placement='top' overlay={
                                status.missing?<Tooltip id={'product-status-'+i}>{status.missing}</Tooltip>:<></>
                            }>
                                <span className={status.status==='unfulfilled'?'red':'green'}>
                                    {status.status==='unfulfilled'?'Pendiente de entrega':'Entregado'}
                                </span>
                            </OverlayTrigger>
                        ):'NA')(getPurchaseProductStatus(purchaseProduct))}
                    </td>
                    <td> {purchaseProduct.selectedPurchaseProductQuote.provider.name} </td>
                    <AdjustablePOCell
                        adjusting={adjustingValue && adjustingValue.type==='unitPrice' && adjustingValue.index===i}
                        adjustment={purchaseProduct.purchaseProductAdjustment?purchaseProduct.purchaseProductAdjustment.unitPrice:0}
                        inputValue={adjustingValue?adjustingValue.value:''}
                        onInputChange={handleAdjustmentChange}
                        onSave={handleAdjustmentSave(i+'-unitPrice')}
                        onCancel={cancelAdjustment}
                        value={purchaseProduct.selectedPurchaseProductQuote.unitPrice}
                        valueFormatter={(v)=>moneyFormatter(v, 1, currency)}
                        onAdjustStart={startAdjusting(i+'-unitPrice')}
                    />
                    {hasDiscount?<td> {moneyFormatter(purchaseProduct.selectedPurchaseProductQuote.unitDiscount, 1, currency)}</td>: null}
                    {hasDiscount?<td> {moneyFormatter(purchaseProduct.selectedPurchaseProductQuote.unitPrice - purchaseProduct.selectedPurchaseProductQuote.unitDiscount, 1, currency)} </td>: null}
                    <td> {moneyFormatter(purchaseProduct.selectedPurchaseProductQuote.subtotal, 1, currency)} </td>
                    {hasDiscount?<td> {moneyFormatter(purchaseProduct.selectedPurchaseProductQuote.discountTotal, 1, currency)}</td>: null}
                    {hasDiscount?<td> {moneyFormatter(purchaseProduct.selectedPurchaseProductQuote.subtotalWithDiscount, 1, currency)}</td>: null}
                    <td> {moneyFormatter(purchaseProduct.selectedPurchaseProductQuote.taxesTotal, 1, currency)}</td>
                    <td> {moneyFormatter(purchaseProduct.selectedPurchaseProductQuote.total, 1, currency)} </td>
                </tr>
            ))}

            <tr>
                <td colSpan={hasDiscount?10:8}/>
                <td><strong>{moneyFormatter(po.subtotal,1,currency)}</strong></td>
                {hasDiscount?<td colSpan={2}/>:null}
                <td><strong>{moneyFormatter(po.taxes,1,currency)}</strong></td>
                <td><strong>{moneyFormatter(po.total,1,currency)}</strong></td>
            </tr>

            </tbody>
        </Table>
    );

};

export default PurchaseProductsTable;

