import React, {Component} from 'react';
import {Col, Modal, Nav, NavItem, Row, Tab} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Card from '../../../components/Card/Card';
import ReactTable from 'react-table';
import LayoffRequestModal from './components/LayoffRequestModal';
import LayoffRequestForm from './components/LayoffRequestForm';
import Button from "../../../elements/CustomButton/CustomButton";
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import moment from 'moment';
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";


export const types = {
    PENDING:'PENDING',
    APPROVED:'APPROVED',
    REVIEWED:'REVIEWED',
    DENIED:'DENIED',
};

const descriptions = {
    [types.PENDING]:'Las solicitudes de baja pendientes deben de ser aprobadas por un superior.',
    [types.APPROVED]:'Las solicitudes aprobadas, están en espera de obtener el acuse de recibo de Capital Humano.',
    [types.REVIEWED]:'Las solicitudes revisadas están listas para ser ejecutadas en la fecha de terminación.',
    [types.DENIED]:'Revisa los comentarios del supervisor para saber porque fue rechazada la solicitud.',
};

class LayoffRequests extends Component{

    static contextTypes = { api:PropTypes.object, notifier:PropTypes.object };

    loadingId = "@Class.LayoffRequests.get";

    constructor( props ){
        super(props);

        const hash = props.location.hash.replace( '#', '' );
        this.state = {
            type : types[ hash ]? types[ hash ] : types.PENDING
        };
    }

    loadRequis = ( tableState )=>{

        this.tableState = tableState;

        this.sort = tableState.sorted.reduce( (acc, val)=>{acc[`order[${val.id}]`] = val.desc?"DESC":"ASC"; return acc;}, {} );
        this.filter = tableState.filtered.reduce( (acc, val)=>{acc[val.id] = val.value; return acc;}, {} );

        if (this.state.type === types.DENIED)
            this.filter.isApproved = false;

        else if (this.state.type === types.PENDING)
            this.filter['isApproved[exists]'] = false;

        else if (this.state.type === types.APPROVED) {
            this.filter.isApproved = true;
            this.filter.isReviewed = false;
        }
        else if (this.state.type === types.REVIEWED) {
            this.filter.isApproved = true;
            this.filter.isReviewed = true;
        }

        this.filter['layoff[exists]'] = false;

        this.context.api.layoffRequests.get(tableState.page , tableState.pageSize, this.loadingId, {...this.sort, ...this.filter}, 'layoffRequests'+this.state.type );
    };

    getColumnsDefinition = ()=>{
        return [
                {
                    Header:'Solicitante',
                    accessor: ( req )=>employeeFullName( req.requestedBy ),
                    id: 'requestedBy.name'
                },
                {
                    Header:'A dar de baja',
                    accessor: ( req )=>employeeFullName( req.employee ),
                    id:'employee.name',
                },
                {
                    Header:'Fecha de creación',
                    accessor:( req )=>moment(req.createdDate).format('DD/MM/YYYY'),
                    id:'createdDate'
                },{
                    Header:'Fecha de baja',
                    accessor:( req )=>moment(req.layoffRequestDate).format('DD/MM/YYYY'),
                    id:'layoffRequestDate'
                }
            ];
    };

    onCreate = ( request )=>{
        this.setState({creating:false});
        this.context.api.layoffRequests.create( request, undefined, 'layoffRequests'+types.PENDING );
    };

    showRequestDetail = ( request )=>{
        this.setState( {showDetail: request })
    };

    onTypeChange = (type)=>{
        this.setState({type},
            ()=>this.loadRequis( this.tableState ) );
    };

    onReject = ( request, supervisorComment )=>{
        this.setState( {showDetail:false} );
        this.context.api.layoffRequests.edit( request.id, {isApproved:false, supervisorComment}, 'layoffRequests'+this.state.type )
            .then( ()=>this.loadRequis( this.tableState ) );
    };

    onApprove = ( request )=>{
        this.setState( {showDetail:false} );
        this.context.api.layoffRequests.edit( request.id, {isApproved:true} )
            .then( ()=>this.loadRequis( this.tableState ) );
    };

    onReview = ( request )=>{
        this.setState( {showDetail:false} );
        this.context.api.layoffRequests.edit( request.id, {isReviewed:true} )
            .then( ()=>this.loadRequis( this.tableState ) );
    };

    onDelete = ( request )=>{
        this.setState( {showDetail: false });
        this.context.api.layoffRequests.delete( request.id, 'layoffRequests'+this.state.type );
    };

    onFire = ( request )=>{
        this.setState( {firingEmployee:request.employee, showDetail:false} );
    };

    confirmFireEmployee = ()=>{
        const employee = this.state.firingEmployee;

        this.setState( {firingEmployee:false} );
        this.context.api.employees.fire( employee.id )
            .then( ()=>this.loadRequis( this.tableState ) )
    };

    render() {

        const { totalItems, itemsPerPage } = this.props.api['layoffRequests'+this.state.type+'Meta'] || { totalItems:0, itemsPerPage:0 } ;
        const layoffRequests = this.props.api['layoffRequests'+this.state.type] || [];
        const plural = totalItems>1?"s":"";

        return (
            <div className="container-fluid main-container LayoffRequests">

                <Card
                    content={(
                        <div>
                            <Tab.Container id="tabs-with-dropdown" activeKey={this.state.type} onSelect={this.onTypeChange}>
                                <Row className="clearfix">
                                    <Col sm={12}>
                                        <Nav bsStyle="tabs">
                                            <NavItem eventKey={types.PENDING}>
                                                <i className="fa fa-question-circle-o" /> Solicitudes pendientes
                                            </NavItem>
                                            <NavItem eventKey={types.APPROVED}>
                                                <i className="fa fa-check-circle-o" /> Solicitudes aprobadas
                                            </NavItem>
                                            <NavItem eventKey={types.REVIEWED}>
                                                <i className="fa fa-eye" /> Solicitudes revisadas
                                            </NavItem>
                                            <NavItem eventKey={types.DENIED}>
                                                <i className="fa fa-window-close-o" /> Solicitudes rechazadas
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                </Row>
                            </Tab.Container>

                            <p className='layoff-request-description'>{ descriptions[this.state.type] }</p>

                            <ReactTable
                                columns={ this.getColumnsDefinition() }
                                data = { layoffRequests }
                                minRows={3}
                                pages= { Math.ceil( totalItems / itemsPerPage ) }
                                manual
                                sortable
                                onFetchData={this.loadRequis}
                                className="-striped -highlight"
                                loading={!!this.props.loadingIds[this.loadingId]}
                                getTdProps={(state, rowInfo)=>({onClick:()=> rowInfo && this.showRequestDetail(rowInfo.original)})}
                                {...ReactTableTranslations}

                            />

                            <p className="total-count pull-left">{totalItems} registro{plural} encontrado{plural}</p>
                            <Button bsStyle='primary' className='pull-right new-requisition' onClick={()=>this.setState({creating:true})}>Crear nueva solicitud</Button>
                            <div className='clearfix' />
                        </div>
                    )} />

                {this.state.showDetail?
                    <LayoffRequestModal
                        request={ this.state.showDetail }
                        onHide={()=>this.setState( {showDetail:false} ) }
                        canFire={ canFireRoles.indexOf( this.props.me.role.name ) !== -1 }
                        onFire={ this.onFire }
                        onApprove={ this.onApprove }
                        onReject={this.onReject}
                        onReview={this.onReview}
                        onDelete={ this.onDelete }
                        me = {this.props.me}
                    />
                    :null}

                {this.state.creating?
                    <LayoffRequestForm
                        onHide={()=>this.setState({creating:false})}
                        onCreate={ this.onCreate }
                    />
                    :null}

                { this.state.firingEmployee?
                    <Modal
                        onHide={()=>this.setState({firingEmployee:undefined})}
                        show={true}>
                        <Modal.Header closeButton>
                            <Modal.Title>Despedir empleado</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>¿Estás seguro que deseas despedir a {employeeFullName(this.state.firingEmployee)}?</p>
                            <p>El empleado perderá acceso a su cuenta en el sistema</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button
                                bsStyle={'danger'}
                                style={{float:'left'}}
                                onClick={()=>this.setState({firingEmployee:undefined})}
                            >
                                Cancelar
                            </Button>
                            <Button
                                bsStyle='danger'
                                onClick={this.confirmFireEmployee}
                            >
                                Sí, despedir
                            </Button>
                        </ Modal.Footer>
                    </Modal>
                    :null}

            </div>
        );
    }
}

function mapStateToProps( {api, loadingIds} ){//{api:{ me, personnelRequisitions=[], personnelRequisitionsMeta={totalItems:0,itemsPerPage:0} }, loadingIds} ) {

    return { me:api.me, api, loadingIds };
}

export default connect(mapStateToProps)(LayoffRequests);

const canFireRoles = [ 'RECURSOS_HUMANOS' ];
