import React, {Component} from 'react';
import {Row, Col, FormControl} from 'react-bootstrap';
import Card from "../../../components/Card/Card";
import questionTypes from '../../../components/Questionnaire/questionTypes';
import _ from 'lodash';
import Button from "../../../elements/CustomButton/CustomButton";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import GeneralConfig from '../../../components/Questionnaire/GeneralConfig';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import ConfirmModal from "../../../components/Modals/ConfirmModal";


const SortableContainerDiv = SortableContainer(( props )=><div className={props.className}>{props.children}</div> );


class QuestionnaireEditor extends Component{

    static contextTypes = {
        api:PropTypes.object,
        notifier:PropTypes.object,
    };

    loadingId = '@Class.QuestionnaireEditor.questionnaires.create&edit';
    getLoadingId = '@Class.QuestionnaireEditor.questionnaires.get';

    constructor(props, context){
        super(props);

        this.state = {
            name:'',
            config: GeneralConfig.initialConfig,
            permissions:[],
            questions:[],
            configOpen:false,
            isActive: true
        };

        this.context = context;

        this.loadQuestionnaire( true );
    }

    componentDidUpdate = ( prevProps)=>{

        //If the component changed from editing to creating
        if( this.props.match.params.id === 'new' && prevProps.match.params.id !== 'new') {
            this.setState({
                name: '',
                config: GeneralConfig.initialConfig,
                permissions:[],
                questions: []
            });
        }
        else
            this.loadQuestionnaire();

    };

    loadQuestionnaire = ( inConstructor=false )=>{

        let idInUrl = this.props.match.params.id;

        if( idInUrl === 'new' )
            return;

        if( this.state.id+'' === idInUrl )
            return;

        const questionnaire = _.find( this.props.questionnaires, (quest)=> quest.id+'' === idInUrl );

        if( questionnaire ){

            QuestionnaireEditor.addOrderConfig( questionnaire );

            if( inConstructor )
            // eslint-disable-next-line
                this.state = {...this.state, questionnaire};
            else
                this.setState(questionnaire);
        }
        else if( !this.props.loadingIds[this.getLoadingId] && !this.state.failedToFetch) {

            this.context.api.questionnaires.get(0, 1, this.getLoadingId, {id: idInUrl})
                .then((questionnaires) => {
                    const questionnaire = _.find(questionnaires, (quest) => quest.id+'' === this.props.match.params.id);

                    if (!questionnaire)
                        this.setState({failedToFetch: true});
                    else
                        this.setState(questionnaire);

                })
                .catch(() => this.setState({failedToFetch: true}));
        }
    };

    static addOrderConfig = ( questionnaire )=>{

        if( !questionnaire.questions || !questionnaire.questions.length )
            return questionnaire;

        _.forEach( questionnaire.questions, (question, index)=>{

            if( !question.config )
                question.config = { order:index };
            else if( typeof question.config.order === 'undefined' )
                question.config.order = index;
            });

        questionnaire.questions = _.sortBy( questionnaire.questions, question=>question.config.order )

    };

    handleNameChange = (e)=>{
        this.setState({name:e.target.value});
    };

    addQuestion = ( e )=>{

        const type = e.currentTarget.dataset.qtype;
        const definition = questionForType(type);

        if( !definition ) return;

        const question = {
            text: definition.editor.initialText? definition.editor.initialText(type) : '',
            type,
            config: definition.editor.initialConfig( this.state.questions.length )
        };

        this.setState( {questions: [...this.state.questions, question ]} );

    };

    onQuestionChange = (question)=>{
        const questions = [ ...this.state.questions];
        questions[question.config.order] = question;
        this.setState({questions});
    };

    saveQuestionnaire = ()=>{

        if( !this.state.name)
            return this.context.notifier.error('Debes escribir un título para el cuestionario');

        if( this.state.questions.length === 0)
            return this.context.notifier.error('Agrega un pregunta para continuar');

        const ok = this.state.questions.reduce( (valid, question, index)=>{
            const def = questionForType( question.type );

            if( !def ){
                this.context.notifier.error(`La pregunta número ${index+1} es de tipo desconocido.`);
                return false;
            }

            const validation = def.editor.validate( question );
            if( validation.error ){
                this.context.notifier.error(<span>Hubo un error en la pregunta {index+1}.<br/>{validation.message}</span>);
                return false;
            }

            return valid;

        }, true );

        if( !ok )
            return;

        const permissions = this.state.permissions.map( (permission)=>({...permission, position: permission.position?permission.position.id:undefined, employee: permission.employee?permission.employee.id:undefined}) );
        const questionnaire = {
            name: this.state.name,
            questions: this.state.questions,
            isActive: this.state.isActive,
            permissions,
        };

        if(this.state.config)
            questionnaire.config = this.state.config;

        const returnToList = ()=>this.props.history.push('/ee/questionnaire-editor');

        if( this.state.id )
            this.context.api.questionnaires.edit( this.state.id, questionnaire, this.loadingId).then(returnToList);
        else
            this.context.api.questionnaires.create( questionnaire, this.loadingId).then(returnToList);

    };

    promptQuestionRemoval=(question)=>{
        this.setState({removing:question});
    };

    handleQuestionRemoval = ()=>{
        const questions = [...this.state.questions];
        questions.splice(this.state.removing.config.order,1);

        for( let i=0; i<questions.length; i++)
            questions[i].config.order = i;

        this.setState({questions, removing:undefined});
    };

    toggleGeneralConfig = ()=>{
        this.setState( {generalConfigVisible:  !this.state.generalConfigVisible});
    };

    onPermissionsChange = ( newPermissions )=>{
        this.setState({permissions:newPermissions});
    };

    onConfigChange= ( newConfig )=>{
        this.setState({config:newConfig});
    };

    onSortQuestionsEnd= ( {oldIndex, newIndex} )=>{
        const newQuestions = arrayMove( this.state.questions, oldIndex, newIndex);

        for( let i=0; i<newQuestions.length; i++)
            newQuestions[i].config.order = i;

        this.setState( {questions: newQuestions} );
    };

    render(){

        if( this.state.failedToFetch)
            return <div className='text-center'><p className='alert alert-danger'>Hubo un error al cargar la información.</p></div>;

        return (
            <>
            <SortableContainerDiv
                className="container-fluid main-container QuestionnaireEditor"
                distance={5}
                onSortEnd ={ this.onSortQuestionsEnd }
                lockAxis={'y'}
                useWindowAsScrollContainer={true}
            >
                <Row className='main-row'>
                    <Col lgPush={8} lg={4} md={6} mdPush={6} className='row-eq-height'>
                        <Card
                            title='Agregar pregunta'
                            className='options-card'
                              content={
                                  <div>
                                      <hr className='title-separator'/>
                                      { questionTypes.map( (questionDefinition)=>
                                          <button
                                              onClick={this.addQuestion}
                                              key={questionDefinition.type}
                                              data-qtype={questionDefinition.type}>
                                              {questionDefinition.title}
                                          </button>
                                      )}
                                  </div>
                              }/>
                    </Col>

                    <Col lgPull={4} lg={8} md={6} mdPull={6} className='row-eq-height'>
                        <Card

                            className='questions-card'
                            content={
                                <div>

                                    <FormControl
                                        className='questionnaire-name'
                                        placeholder='Nombre del cuestionario'
                                        value={this.state.name}
                                        onChange={this.handleNameChange}
                                    />
                                    <span className='link config-opener' onClick={this.toggleGeneralConfig}><i className={'fa fa-eye' + (this.state.generalConfigVisible?'-slash':'')}/> Configuración general</span>
                                    <div className='general-config-wrapper' style={this.state.generalConfigVisible?{maxHeight: 600, overflow:'initial'}: {maxHeight:0, overflow:'hidden'}} >
                                        <GeneralConfig
                                            permissions={this.state.permissions||[]}
                                            config={this.state.config||{}}
                                            onPermissionsChange={this.onPermissionsChange}
                                            onConfigChange={this.onConfigChange}
                                            isActive={this.state.isActive}
                                            onActiveChange={isActive=>this.setState({isActive})}
                                        />
                                    </div>

                                    <hr className='title-separator'/>
                                    {this.state.questions.map( (question, index)=>{
                                        const definition = questionForType( question.type );
                                        if( definition ) {
                                            const Editor = definition.editor;
                                            return <Editor
                                                key={question.config && question.config.uid? question.config.uid : index}
                                                question={question}
                                                definition={definition}
                                                questionnaire={ this.state }
                                                index={index}
                                                onChange={this.onQuestionChange}
                                                onRemove={this.promptQuestionRemoval}
                                            />;
                                        }
                                        else{
                                            return <p key={index}>Tipo de pregunta desconocido</p>
                                        }
                                    })}
                                    <Button
                                        bsStyle='success'
                                        onClick={this.saveQuestionnaire}
                                        disabled={!!this.props.loadingIds[this.loadingId]}
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            }/>
                    </Col>
                </Row>
            </SortableContainerDiv>

            {this.state.removing&&
            <ConfirmModal
                title={'Eliminar pregunta'}
                message={'¿Estás seguro que deseas eliminar esta pregunta? Esto eliminará el historial de respuestas del sistema. Si quieres mantener el historial solo desactiva la pregunta'}
                onCancel={()=>this.setState({removing:undefined})}
                onConfirm={this.handleQuestionRemoval}
                />}
            </>
        );
    }
}

const questionForType = ( type )=>{

    return _.find( questionTypes, (question)=>question.type === type );
};

const mapStateToProps = ({api:{questionnaires}, loadingIds}) =>({questionnaires, loadingIds});

export default connect(mapStateToProps)( QuestionnaireEditor );
