import React, {Component} from 'react';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import TagsInput from 'react-tag-autocomplete';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-tag-autocomplete/example/styles.css';
import _ from 'lodash';
import Switch from 'react-bootstrap-switch';
import {employeeFullName} from "../../utils/modelUtils/alma/employeeUtils";
import switchTranslations from "../../utils/switchTranslations";


class GeneralConfig extends Component{

    static contextTypes = { api:PropTypes.object };

    constructor( props, context ){
        super(props);
        context.api.positions.get();
    }

    formattedPositionPermissions = ()=>{

        return this.props.permissions
            .filter((per)=>(!!per.position))//Select only position permissions
            .map( (per)=>({
                id:per.position.id,
                name:per.position.name
            }))
    };

    formattedEmployeePermissions = ()=>{

        return this.props.permissions
            .filter((per)=>(!!per.employee))//Select only employee permissions
            .map( (per)=>({
                id:per.employee.id,
                name:employeeFullName(per.employee)
            }))
    };

    getPositionSuggestions = ()=>{

        //Remove already added positions
        return _.filter(
            this.props.positions,
            (pos)=>
                !( _.find(
                    this.formattedPositionPermissions(),
                    (per)=>per.id === pos.id )
                )
        )
    };

    handlePositionPermissionAddition = ( position )=>{
        const permission = { position, permission:'do' };
        this.props.onPermissionsChange( [ ...this.props.permissions, permission ] );
    };

    handlePositionPermissionDelete = ( index )=>{
        const permissions = [...this.props.permissions];
        const position = this.formattedPositionPermissions()[index];

        const permIndex = _.findIndex( permissions, ( per )=>{
            if( !per.position )
                return false;
            return per.position.id === position.id;
        });

        if( permIndex === -1 ){
            console.log('Error, permission with this position not found');
            return;
        }

        permissions.splice(permIndex,1);
        this.props.onPermissionsChange( permissions );
    };

    getEmployeeSuggestions = ()=>{

        if( this.props.loadingIds[ this.employeesLoadingId ] )
            return [ { name:'Cargando...', id:0, disabled: true } ];
        //Remove already added positions
        return _.filter(
            this.props.usersSimple,
            (user)=>
                !( _.find(
                        this.formattedEmployeePermissions(),
                        (per)=>per.id === user.employee.id )
                ))
            .map( (user)=>({
                id: user.employee.id,
                name: employeeFullName( user.employee ),
                justName: user.employee.name,
                patLastName: user.employee.patLastName,
                matLastName: user.employee.matLastName
            }) )
    };

    handleEmployeePermissionAddition = ( employee )=>{
        const permission = { employee, permission:'do' };
        permission.employee.name = permission.employee.justName;
        this.props.onPermissionsChange( [ ...this.props.permissions, permission ] );
    };

    handleEmployeePermissionDelete = ( index )=>{
        const permissions = [...this.props.permissions];
        const employee = this.formattedEmployeePermissions()[index];

        const permIndex = _.findIndex( permissions, ( per )=>{
            if( !per.employee )
                return false;
            return per.employee.id === employee.id;
        });

        if( permIndex === -1 ){
            console.log('Error, permission with this employee not found');
            return;
        }

        permissions.splice(permIndex,1);
        this.props.onPermissionsChange( permissions );
    };

    handleEmployeePermissionChange = ( input )=>{
        this.loadSimpleEmployees( input );
    };

    employeesLoadingId = '@Class.GeneralConfig.users.getSimple';

    loadSimpleEmployees = _.debounce(( filter )=>{

        this.context.api.users.getSimple( 0, 10, this.employeesLoadingId, { search: filter } );
    }, 600);

    onFullscreenChange = (s, value)=>{
        const config = {...this.props.config};
        config.fullscreen = value;
        this.props.onConfigChange( config );
    };

    onRequireApprovalChange= (s, value)=>{
        const config = {...this.props.config};
        config.requireApproval = value;
        this.props.onConfigChange( config );
    };

    onActiveChange= (s, value)=>
        this.props.onActiveChange(value);

    render() {

        return (

            <div className='GeneralConfig'>

                <FormGroup className='first-tagger'>
                    <ControlLabel>Puestos que pueden verlo:</ControlLabel>
                    <TagsInput
                        handleAddition={this.handlePositionPermissionAddition}
                        handleDelete={this.handlePositionPermissionDelete}
                        suggestions={this.getPositionSuggestions()}
                        tags={this.formattedPositionPermissions()}
                        placeholder={'Añade un puesto'}
                    />
                </FormGroup>

                <FormGroup className='second-tagger'>
                    <ControlLabel>Personas que pueden verlo:</ControlLabel>
                    <TagsInput
                        handleAddition={this.handleEmployeePermissionAddition}
                        handleDelete={this.handleEmployeePermissionDelete}
                        handleInputChange={ this.handleEmployeePermissionChange }
                        suggestions={this.getEmployeeSuggestions()}
                        tags={this.formattedEmployeePermissions()}
                        placeholder={'Añade una persona'}
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Abrir en pantalla completa:</ControlLabel><br/>
                    <Switch
                        onChange={this.onFullscreenChange}
                        value={this.props.config.fullscreen||false}
                        {...switchTranslations}
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Requiere aprobación del gerente:</ControlLabel><br/>
                    <Switch
                        onChange={this.onRequireApprovalChange}
                        value={this.props.config.requireApproval||false}
                        {...switchTranslations}
                    />
                </FormGroup>

                <FormGroup>
                    <ControlLabel>Cuestionario activo:</ControlLabel><br/>
                    <Switch
                        onChange={this.onActiveChange}
                        value={this.props.isActive||false}
                        {...switchTranslations}
                    />
                </FormGroup>


            </div>
        );
    }
}

GeneralConfig.initialConfig = {};

const mapStateToProps = ( {api:{ positions=[], usersSimple=[] }, loadingIds } )=>({positions, usersSimple, loadingIds});

export default connect( mapStateToProps )( GeneralConfig );
