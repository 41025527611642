import React, { Component } from 'react';
import {
    Nav, NavDropdown, MenuItem
} from 'react-bootstrap';

import { LinkContainer } from 'react-router-bootstrap';
import {version} from '../../pckg';
import PropTypes from 'prop-types';
import CustomButton from "../../elements/CustomButton/CustomButton";
import {connect} from "react-redux";
import EntitySelect from "../EntitySelect/EntitySelect";


class HeaderLinks extends Component{

    static contextTypes = { api: PropTypes.object };

    state = {switch:''};

    refresh = ()=>{
        window.location.reload(true);
    };

    onSwitchChange = ({target})=>this.setState({switch:target.value});

    onSwitchSelectorChange = (user)=>this.context.api.switchUser( user.username );

    changeUser = ()=>this.context.api.switchUser( this.state.switch );

    render(){

        if( window.broken === 5 ) {
            //throw new Error("Roto");
        }

        window.broken = window.broken?  window.broken +1: 1;

        return(
            <div>

                <Nav pullRight>

                    <NavDropdown
                        eventKey={4}
                        title={(
                            <div>
                                <i className="fa fa-list" />
                                <p className="hidden-md hidden-lg">
                                    Opciones
                                    <b className="caret" />
                                </p>
                            </div>
                        )} noCaret id="basic-nav-dropdown-3" bsClass="dropdown-with-icons dropdown">

                        <LinkContainer to="/help">
                            <MenuItem eventKey={4.5}>
                                <div className="text-primary"><i className="pe-7s-info"/>Ayuda</div>
                            </MenuItem>
                        </LinkContainer>

                        <LinkContainer to="/logout">
                            <MenuItem eventKey={4.5}>
                                <div className="text-danger"><i className="pe-7s-close-circle"/>Cerrar sesión</div>
                            </MenuItem>
                        </LinkContainer>

                        {this.context.api.isUserSwitched() || (this.props.me && this.props.me.role && this.props.me.role.name === 'SUPER_ADMIN' )? <>
                            <MenuItem eventKey={4.7}>
                                <EntitySelect
                                    entity='users'
                                    apiCustomProp='headerUserSwitch'
                                    getMethod='unswtichedGet'
                                    filterBy='search'
                                    placeholder='Buscar usuario...'
                                    labelCreator={u=> u?.employee?.fullName || 'Sin información'}
                                    additionalFilters={ {'employee.exists': true}}
                                    onChange={this.onSwitchSelectorChange}
                                />
                                <input placeholder='Change user' value={this.state.switch} onChange={this.onSwitchChange} className='form-control'/>
                                <CustomButton onClick={this.changeUser}>¡Do it!</CustomButton>
                            </MenuItem>
                            <MenuItem eventKey={4.8} onClick={this.context.api.unswitchUser}>
                                <div className="text-primary"><i className="pe-7s-info"/>Stop switching</div>
                            </MenuItem>
                        </>:null}

                        <MenuItem className="version-header-link" onClick={this.refresh}>
                            <i className="fa fa-code-fork"/> Version {version}
                        </MenuItem>

                        {this.context.api.newerVersion?
                            <MenuItem className="new-version-header-link" onClick={this.refresh}>
                                <i className="fa fa-arrow-circle-o-up"/> Actualizar versión!<br/>
                                <i className="fa fa-circle" />{this.context.api.newerVersion}
                            </MenuItem>
                            :null}
                    </NavDropdown>
                </Nav>
            </div>
        );
    }
}

const mapStateToProps = ({api:{me}})=>({me});

export default connect(mapStateToProps)(HeaderLinks);
