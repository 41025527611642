import _ from 'lodash/fp';

const getAxisValue = ({getKey, getDisplayName}) => v => ({
    key: _.iteratee(getKey)(v),
    displayName: _.iteratee(getDisplayName)(v)
});
const axisInfo = data => axisSpec =>
      _.uniqBy('key', _.map(getAxisValue(axisSpec), data));

const applyFilter = (filterInstance, key)=>
      _.isArray(filterInstance)?_.includes(key, filterInstance):
      key===filterInstance;

const getFilter = ([{getKey, getDisplayName}, filterInstance])=>
      d => applyFilter(filterInstance, _.iteratee(getKey)(d));

const getFilters = _.flow(
    _.filter(([definition, filterInstance])=>filterInstance!==undefined),
    _.map(getFilter)
);

const dataFolder = (dataAxis, data, getValue) => (operation, initialValue) => axisFilters => {
    const filters = _.map(
        _.filter,
        getFilters(_.zip(dataAxis, axisFilters)));

    const fn = _.flow(...filters);
    const filtered = fn(data);
    return _.reduce((result, d)=>operation(result, _.iteratee(getValue)(d)), initialValue, filtered);
};
      
export const dataView = dataAxis => (data, getValue) => {
    const axisData = _.map(axisInfo(data), dataAxis);
    const foldDataValues = dataFolder(dataAxis, data, getValue);
    return {
        axisData,
        foldDataValues
    };
};
