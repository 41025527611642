import React, {useCallback, useContext, useState} from 'react';
import './_RecipeIngredientsTable.scss';
import RecipeIngredientFormModal from "../RecipeIngredientForm/RecipeIngredientFormModal";
import Lottie from 'react-lottie';
import animation from '../../assets/4762-food-carousel.json';
import {
    convertToSmallestUnit,
    displayAmount,
    getSuggestedUnitToShow
} from "../../../../../../utils/modelUtils/almazen/unitsUtility";
import moneyFormatter from "../../../../../../utils/moneyFormatter";
import {SecurityContext} from "../../../../../../utils/SecurityManager";

const RecipeIngredientsTable = ({recipe, onChange, showPrices}) => {

    const security = useContext(SecurityContext);
    const [editing, setEditing] = useState();

    const startEditing = useCallback((ingredient)=>{
        if(!security.katharsis.canEditRecipes())
            return;
        setEditing(ingredient);
    },[security]);

    const stopEditing = useCallback(()=>setEditing(null), []);

    const handleChange = useCallback((ingredient)=>{
        setEditing(null);
        onChange(ingredient);
    },[onChange]);

    if(!recipe){
        return (
            <div className='RecipeIngredientsTable loader'>
                <Lottie options={animation} />
            </div>
        );
    }

    return (
        <div className={"RecipeIngredientsTable"}>
            <div className='ingredients-container'>
                <h3 className='recipe-subtitle'>Ingredientes</h3>
                <table className='table ingredients-table'>
                    <thead>
                    <tr>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Merma</th>
                        {!!showPrices && <>
                            <th>Precio unitario</th>
                            <th>Total</th>
                        </>}
                    </tr>
                    </thead>
                    <tbody>
                    {recipe.orozcoRecipeIngredients?.map(ingredient=> {
                        let base,smallestUnitCost;
                        if(ingredient.product) {
                            base = ingredient.product;
                            smallestUnitCost = base.weightedCostAverage||base.lastCostAverage||0;
                        }
                        else {
                            base = ingredient.orozcoRecipe;
                            smallestUnitCost = (base.estimatedCost||0)/base.quantity;
                        }
                        const suggestedUnit = getSuggestedUnitToShow(ingredient.quantity, base);
                        const unitCost = convertToSmallestUnit(smallestUnitCost, suggestedUnit, base);
                        const total = smallestUnitCost*ingredient.quantity;
                        return <tr key={ingredient.id} onClick={()=>startEditing(ingredient)}>
                            <td>{base?.name}</td>
                            <td>{displayAmount(ingredient.quantity, base)}</td>
                            <td>{Number(ingredient.expectedShrinkage)*100}%</td>
                            {!!showPrices && <>
                                <td>{moneyFormatter(unitCost, 1)}</td>
                                <td>{moneyFormatter(total, 1)}</td>
                            </>}
                        </tr>
                    })}
                    </tbody>
                </table>
                {!recipe.orozcoRecipeIngredients?.length &&
                <p className='no-ingredients'>No se encontró ningún ingrediente</p>}

                {security.katharsis.canEditRecipes() &&
                <button className='btn add-button' onClick={()=>setEditing({})}>+ Agregar ingrediente</button>}
            </div>

            {editing && <RecipeIngredientFormModal
                recipe={recipe}
                onSave={handleChange}
                onHide={stopEditing}
                ingredient={editing}
            />}

        </div>
    );
};

export default RecipeIngredientsTable;
