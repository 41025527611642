import React, {Component} from "react";
import DaySchedule from './DaySchedule';


export default class WeekSchedule extends Component{

    static defaultProps = { editable: false };

    constructor(props) {
        super(props);

        this.onDayChange = this.onDayChange.bind(this);

        this.state = {
            allEqual:true
        }
    }

    onDayChange(e,day){

        let newSchedule;
        if( day === 'all' ) {
            const sch = arrayToSchedule(e);
            newSchedule = arrayToWeekSchedule( [sch,sch,sch,sch,sch,sch,sch] );
        }
        else
            newSchedule = Object.assign( this.props.weekSchedule, { [weekDaysKeys[day]]:arrayToSchedule(e) } );

        if( this.props.onChange )
            this.props.onChange( newSchedule );
    }

    showAllWeek(){

        if( this.props.editable)
            return !this.state.allEqual;

        const weekArray = weekScheduleToArray(this.props.weekSchedule);

        let lastDay = weekArray[0];
        for( let i=1; i<weekArray.length; i++ ){

            const sch = weekArray[i];
            if( sch.checkIn !== lastDay.checkIn ||
                sch.mealCheckOut !== lastDay.mealCheckOut ||
                sch.mealCheckIn !== lastDay.mealCheckIn ||
                sch.checkOut !== lastDay.checkOut ){

                return true;
            }
        }

        return false;

    }

    render() {

        const weekArray = weekScheduleToArray(this.props.weekSchedule);

        return (
            <div>
                {this.props.editable?
                    <div>
                        <p>¿Tienen todos los días el mismo horario? <input
                                type='checkbox'
                                checked={this.state.allEqual}
                                onChange={(e)=>{this.setState({allEqual:e.target.checked})}}
                            />
                        </p>
                        <br/><br/>
                    </div>
                    :null}
                {this.showAllWeek() ?
                    weekArray.map((schedule, day) => {

                            return <DaySchedule
                                key={day}
                                day={weekDays[day]}
                                schedule={scheduleToArray(schedule)}
                                editable={this.props.editable}
                                onChange={(e) => this.onDayChange(e, day)}
                            />
                        }
                    ) :
                    <DaySchedule
                        day={'Todos los días'}
                        schedule={scheduleToArray(weekArray[0])}
                        editable={this.props.editable}
                        onChange={(e) => this.onDayChange(e, 'all')}
                    />
                }
            </div>
        );
    }
}

const weekScheduleToArray = (wk)=>[wk.monday, wk.tuesday, wk.wednesday, wk.thursday, wk.friday, wk.saturday, wk.sunday];
const arrayToWeekSchedule = (arr)=>({monday: arr[0], tuesday: arr[1], wednesday: arr[2], thursday: arr[3], friday: arr[4], saturday: arr[5], sunday: arr[6]});
const scheduleToArray = (sch)=>[sch.checkIn,sch.checkOut];
const arrayToSchedule = (arr)=>{return {checkIn:arr[0] ,checkOut:arr[3]}};

const weekDays = ["Lunes","Martes","Miércoles","Jueves","Viernes","Sábado","Domingo"];
const weekDaysKeys = ["monday","tuesday","wednesday","thursday","friday","saturday","sunday"];
