import React, { useCallback, useContext, useMemo } from 'react';
import moment from "moment/moment";
import ReactTable from 'react-table';
import Button from '../../../elements/CustomButton/CustomButton';
import ProductRequisitionFormModal from './ProductRequisitionFormModal';
import useBoolean from '../../../hooks/useBoolean';
import { employeeFullName } from "../../../utils/modelUtils/alma/employeeUtils";
import StockMovementBatchesNameAndTooltip from "./components/StockMovementBatchesNameAndTooltip";
import useTideTable from "../../../hooks/table/useTideTable";
import {
    createExportStockRequestsUrl,
    stockRequestStatus,
    stockRequestStatusTrans
} from "../../../utils/modelUtils/almazen/stockRequestUtils";
import { SecurityContext } from "../../../utils/SecurityManager";
import useReduxTableState from "../../../hooks/table/useReduxTableState";
import ReactTableDataRangeFilter from "../../../components/Helper/ReactTableDateRangeFilter";
import { TideApiContext } from "../../../api/tideApiConfig";
import Switch from "react-bootstrap-switch";
import switchTranslations from "../../../utils/switchTranslations";
import ReactTableStatusFilter from 'components/Helper/ReactTableStatusFilter';


const ProductRequisitionList = ({ history }) => {

    const api = useContext(TideApiContext);
    const [creating, setCreatingTrue, setCreatingFalse] = useBoolean();
    const security = useContext(SecurityContext);

    const reduxTableState = useReduxTableState('@ProductRequisitionList');
    const getMyPendingRequests = !!reduxTableState.tableProps.genericState;

    const handlePendingRequestsChange = useCallback((s, value) => {
        reduxTableState.tableProps.onGenericStateChange(value);
    }, [reduxTableState.tableProps]);

    const tableOptions=useMemo(()=>({
        entity:'stockRequests',
        columns,
        getMethod: getMyPendingRequests ? 'getMyPendingRequests' : 'get',
        requestOptions:{customProp:'MyPendingRequestsList'}
        })
    , [getMyPendingRequests]);

    const table=useTideTable(tableOptions);

    const goToDetail = (stockRequest) => {
        if (stockRequest)
            history.push('/almazen/product-requisition/' + stockRequest.id);
    };

    return (
        <div className="ProductRequisitionList container-fluid main-container">

            {security.almazen.canRequestStock() ?
                <Button bsStyle='primary' className='pull-right' onClick={setCreatingTrue}>Nueva requisición</Button>
                : null}
            <div className="d-flex flex-column">
                <p>Mis req. pendientes por aprobar.</p>
                <Switch value={getMyPendingRequests} onChange={handlePendingRequestsChange} {...switchTranslations} />
            </div>
            <div className='clearfix' />
            <ReactTable
                {...table.tableProps}
                {...reduxTableState.tableProps}
                getTdProps={(state, row) => ({ onClick: () => goToDetail(row && row.original) })}
                getTrProps={(state, row) => ({ className: row && row.original && row.original.status === stockRequestStatus.CANCELED && 'red-table-row' })}
            />
            <p className="total-count">{table.itemsFoundString}</p>
            <a href={createExportStockRequestsUrl(api, table.lastUsedApiOptions?.filters)} target='_blank'
                rel='noopener noreferrer'>
                <Button bsStyle='primary'>Descargar a excel</Button>
            </a>

            {creating ?
                <ProductRequisitionFormModal
                    onHide={setCreatingFalse}
                />
                : null}

        </div>
    );

};


const trans = (string) => {
    return stockRequestStatusTrans[string] || string;
};

const columns = [
    {
        Header: 'No.',
        accessor: 'folio'
    },
    {
        Header: 'Solicitante',
        accessor: (req) => req.createdBy ? employeeFullName(req.createdBy.employee) : "Generada por el sistema",
        id: 'search',
    },
    {
        Header: 'Origen',
        accessor: (req) => req.fromWarehouse.name,
        id: 'fromWarehouse.name',
        filterable: true
    },
    {
        Header: 'Entregar a',
        accessor: (req) => req.toPlace ? req.toPlace.name : 'Sin información',
        id: 'toPlace.name',
    }, {
        Header: 'Entrega requerida el',
        accessor: (req) => req.requestedForDate ? moment(req.requestedForDate).format('DD/MM/YYYY HH:mm') : 'Sin fecha',
        id: 'requestedForDate',
        filterable: false,
    }, {
        Header: 'Productos',
        accessor: (req) => <StockMovementBatchesNameAndTooltip products={req.products} movementType='out' />,
        id: 'products'
    }, {
        Header: 'Fecha de solicitud',
        accessor: (req) => moment(req.createdDate).format('DD/MM/YYYY HH:mm'),
        id: 'createdDate',
        Filter: ReactTableDataRangeFilter
    }, {
        Header: 'Estado',
        accessor: (pr) => trans(pr.status),
        id: 'status',
        Filter: ReactTableStatusFilter
    },];


export default ProductRequisitionList;
