import React, {useContext, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import Medal from "../utils/Medal";
import {ApiContext} from "../../../api/Api";
import searchFilterImage from '../../../assets/img/tgle-rewards/search-recognitions-filter.svg';
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import {useHeaderFooterHelper} from '../../../utils/header-footer-helper';
import {FilterMenu} from '../utils/search';

const awardsFilters = [
    {
        label: 'Valor (menor a mayor)',
        filter: {
            'order[points]': 'ASC'
        }
    },
    {
        label: 'Valor (mayor a menor)',
        filter: {
            'order[points]': 'DESC'
        }
    },
    {
        label: 'Alfabético',
        filter: {
            'order[recognition.name]': 'ASC'
        }
    }
];

const AwardHistory = () => {
    const [page, setPage] = useState('for');
    const [showingMenu, setShowingMenu] = useState(false);
    const {fullResetAction, setFooterClassName, setHeaderClassName} = useHeaderFooterHelper();
    const api = useContext(ApiContext);
    const me = useSelector(({api: {me}}) => me);
    const fromMe = useSelector(({api: {recognitionMovementsFromMe}}) => recognitionMovementsFromMe) || [];
    const forMe = useSelector(({api: {recognitionMovementsForMe}}) => recognitionMovementsForMe) || [];
    const [filter, setFilter] = useState(null);
    useEffect(() => {
        api.recognitionMovements.get({
            filter: {
                'fromEmployee.id': me.id
            },
            customProp: 'recognitionMovementsFromMe'
        });
        api.recognitionMovements.get({
            filter: {
                toEmployee: me.id
            },
            customProp: 'recognitionMovementsForMe'
        });
    }, [api, me.id]);
    const showing = page === 'for' ? forMe : fromMe;
    useEffect(()=>{
        if(showingMenu){
            setFooterClassName('blurred');
            setHeaderClassName('blurred');
            return;
        }
        fullResetAction();
    }, [showingMenu, fullResetAction, setFooterClassName, setHeaderClassName]);
    useEffect(()=>{
        if(!filter){
            return;
        }
        if(page === 'for'){
            api.recognitionMovements.get({
                filter: {
                    toEmployee: me.id,
                    ...filter
                },
                customProp: 'recognitionMovementsForMe'
            });
            return;
        }
        api.recognitionMovements.get({
            filter: {
                'fromEmployee.id': me.id,
                ...filter
            },
            customProp: 'recognitionMovementsFromMe'
        });
    }, [filter, page, api.recognitionMovements, me.id]);
    const showFilterMenu = ()=>{
        setShowingMenu(true);
    };
    const cssClasses = [
        'Rewards',
        'AwardHistory',
        ...(showingMenu?['blurred']:[])
    ];
    return (
        <>
            <div className={cssClasses.join(' ')}>
                <div className='top-sub-nav'>
                    <div className='page-title' onClick={() => setPage('for')}>
                        Obtenidas
                    </div>
                    <div className='page-title' onClick={() => setPage('from')}>
                        Otorgadas
                    </div>
                    <div className='filter'>
                        <button onClick={showFilterMenu}>
                            <img alt='Filtro de búsqueda' src={searchFilterImage}/>
                        </button>
                    </div>
                </div>
                <hr/>
                <div className={'selector-bar '+page}/>
                {showing.length === 0 &&
                <div className='text-content'>
                    {page === 'for' ? 'No has obtenido ninguna medalla aún.' : 'No has otorgado ninguna medalla aún.'}
                </div>
                }
                <div className='medal-list'>
                    {showing.map(({id, recognition, fromEmployee, toEmployee}) =>
                        <div key={id} className='recognition-movement-container'>
                            <Medal {...recognition}/>
                            {page==='for'&&<p className='by-text'>Por: {employeeFullName(fromEmployee)}</p>}
                            {page==='from'&&<p className='by-text'>Para: {employeeFullName(toEmployee)}</p>}
                        </div>
                    )}
                </div>
            </div>
            <div onClick={()=>setShowingMenu(false)} className={'overlay '+(showingMenu?'blurred':'')}/>
        {showingMenu && <FilterMenu
                            onClose={()=>setShowingMenu(false)}
                            searchConfigs={awardsFilters}
                            configTitle='Ordenar medallas por:'
                            className='filter-awards'
                            filter={filter}
                            setFilter={setFilter}
                            />
            }
        </>
    );
};

export default AwardHistory;
