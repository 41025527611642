import React, {useContext, useEffect, useState, useCallback} from 'react';
import {Col, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row} from "react-bootstrap";
import {ApiContext} from "../../api/Api";
import {useSelector} from "react-redux";
import {avatarUrl, employeeFullName} from "../../utils/modelUtils/alma/employeeUtils";
import _ from 'lodash';
import Modal from "react-bootstrap/lib/Modal";
import EntitySelect from "../EntitySelect/EntitySelect";
import CustomButton from "../../elements/CustomButton/CustomButton";
import useCallbackCreator from "../../hooks/useCallbackCreator";
import {NotifierContext} from "../../utils/Notifier";
import ConfirmModal from "../Modals/ConfirmModal";

const ApprovalMatrixConfigurator = ({approvalType}) => {

    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);

    const [selecting, setSelecting] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [deletingRule, setDeletingRule] = useState(null);

    useEffect(() => {
        api.employeeApprovalRules.get({filters: {pagination: false, approvalType}});
    }, [api, approvalType]);

    const employeeApprovalRules = useSelector(({api}) => api.employeeApprovalRules || []);

    const rows = _.chunk(employeeApprovalRules, 3);

    const addSubordinate = useCallbackCreator((ruleId) => {
        const rule = _.find(employeeApprovalRules, rule => rule.id === ruleId);

        if (!rule) console.error('Rule ' + ruleId + ' not found', employeeApprovalRules, typeof ruleId);

        setSelecting({type: 'subordinate', rule});
    }, [employeeApprovalRules]);

    const cancelSelecting = useCallback(() => setSelecting(null), []);

    const saveSelected = useCallback(() => {
        if (!selectedEmployee)
            return notifier.error('Selecciona a una persona');

        const newRule = {...selecting.rule};
        if (newRule.approvesTo) newRule.approvesTo = _.map(selecting.rule.approvesTo, 'id');
        if (newRule.employee) newRule.employee = newRule.employee.id;

        if (selecting.type === 'superior' && !selecting.rule) {
            newRule.employee = selectedEmployee.id;
            newRule.approvalType = approvalType;
            api.employeeApprovalRules.create({employeeApprovalRule: newRule});
        } else if (selecting.type === 'superior') {
            newRule.employee = selectedEmployee.id;
            api.employeeApprovalRules.edit({id: newRule.id, employeeApprovalRule: newRule});
        } else if (selecting.type === 'subordinate' && selecting.rule) {
            newRule.approvesTo.push(selectedEmployee.id);
            api.employeeApprovalRules.edit({id: newRule.id, employeeApprovalRule: newRule});
        } else {
            console.error(selecting);
            notifier.error('Error de edición. Tipo de regla no encontrada');
        }

        setSelecting(null);
        setSelectedEmployee(null);

    }, [api, notifier, selectedEmployee, selecting, approvalType]);

    const removeSubordinate = useCallbackCreator((ids) => {
        const [ruleID, subID] = ids.split('-').map(Number);
        const rule = _.find(employeeApprovalRules, rule => rule.id === ruleID);

        if (!rule) return notifier.error('Error, regla no encontrada');

        const newRule = {approvesTo: rule.approvesTo};
        newRule.approvesTo = _.map(newRule.approvesTo, 'id');
        newRule.approvesTo = _.filter(newRule.approvesTo, employeeId => employeeId !== subID);
        api.employeeApprovalRules.edit({id: rule.id, employeeApprovalRule: newRule});

    }, [employeeApprovalRules]);

    const promptDeleteRule = useCallbackCreator((id) => {
        setDeletingRule(id);
    }, []);

    const cancelDeleting = useCallback(() => setDeletingRule(null), []);

    const deleteRule = useCallback(() => {
        api.employeeApprovalRules.delete({id: Number(deletingRule)});
        setDeletingRule(null);

    }, [api, deletingRule]);


    return (
        <div className='container-fluid ApprovalMatrixConfigurator'>
            <Row>
                <Col xs={12}>
                    <h3>Aprobaciones por superior</h3>
                    <hr/>
                </Col>
            </Row>

            {rows.map((rules, i) =>
                <Row key={i}>
                    {rules.map((rule) =>
                        <Col xs={12} sm={6} md={4} key={rule.id}>
                            <div className='approval-card'>
                                <div className={'header ' + approvalType}>
                                    <div className={"face " + approvalType}
                                         style={{backgroundImage: `url('${avatarUrl(rule.employee, api)}')`}}/>
                                    {employeeFullName(rule.employee)}

                                    <button className='edit-rule-btn'
                                            onClick={() => setSelecting({type: 'superior', rule})}>
                                        <i className='fa fa-pencil'/>
                                    </button>
                                    <button className='remove-rule-btn' onClick={promptDeleteRule(rule.id)}>
                                        <i className='fa fa-trash'/>
                                    </button>

                                </div>

                                <div className='body'>
                                    {rule.approvesTo && rule.approvesTo.map(subordinate =>
                                        <div className='subordinate' key={subordinate.id}>
                                            <div className={"face " + approvalType}
                                                 style={{backgroundImage: `url('${avatarUrl(subordinate, api)}')`}}/>
                                            <br/>
                                            <span>{employeeFullName(subordinate)}</span>
                                            <button className='remove-sub-btn'
                                                    onClick={removeSubordinate(`${rule.id}-${subordinate.id}`)}>
                                                <i className='fa fa-trash red-icon'/>
                                            </button>
                                        </div>
                                    )}
                                    <button className='add-sub-btn' onClick={addSubordinate(rule.id)}>
                                        <i className='fa fa-plus-circle'/>
                                    </button>
                                </div>

                            </div>
                        </Col>
                    )}
                </Row>
            )}
            <Row>
                <Col xs={12} sm={6} md={4}>
                    <div className='approval-card add'>
                        <button className='add-card-btn' onClick={() => setSelecting({type: 'superior'})}>
                            <i className='fa fa-plus-circle'/>
                            Añadir regla
                        </button>
                    </div>
                </Col>
            </Row>

            <Modal show={!!selecting} onHide={cancelSelecting}>
                <ModalHeader closeButton>
                    <ModalTitle>Selecciona una persona</ModalTitle>
                </ModalHeader>
                <ModalBody>

                    <p>
                        {selecting ?
                            selecting.type === 'superior' ?
                                'Selecciona la persona que aprobará estas solicitudes' :
                                selecting.type === 'subordinate' ?
                                    'Selecciona la persona que solicitará aprobación' :
                                    '' : ''
                        }
                    </p>

                    <EntitySelect
                        entity='employees'
                        getMethod='get2'
                        filterBy='search'
                        labelCreator={employeeFullName}
                        onChange={setSelectedEmployee}
                        value={selectedEmployee}
                        additionalFilters={employeeFilters}
                    />

                </ModalBody>
                <ModalFooter>
                    <CustomButton bsStyle='danger' className='pull-left'
                                  onClick={cancelSelecting}>Cancelar</CustomButton>
                    <CustomButton bsStyle='success' onClick={saveSelected}>Guardar</CustomButton>
                </ModalFooter>
            </Modal>

            <ConfirmModal
                show={!!deletingRule}
                onCancel={cancelDeleting}
                onConfirm={deleteRule}
                title={'Borrar regla de aprobación'}
            />

        </div>
    )
};

export default ApprovalMatrixConfigurator;

const employeeFilters = {isHired: true};
