import React, {useMemo} from 'react';
import {DateRangePicker} from "react-dates";
import useDateRangePickerState from "../../../../hooks/useDateRangePickerState";
import moment from 'moment';

const dateRangeFilter = ({startDate, endDate}, [fromAttrib, toAttrib])=>({
    [fromAttrib]: startDate?startDate.format('YYYY-MM-DD'):undefined,
    [toAttrib]: endDate?endDate.format('YYYY-MM-DD'):undefined,
});

export const useDateRangeFilter = (configuration) => {
    const properties = useMemo(
        ()=>(configuration&&configuration.properties)||['from', 'to'], [configuration]);
    const initialRange = useMemo(
        ()=>{
            const defaultInitial = {
                startDate: moment().startOf('month').startOf('day'),
                endDate: moment().add(1, 'day').endOf('day')
            };
            if(!configuration){
                return defaultInitial;
            }
            if(!configuration.initial){
                return defaultInitial;
            }
            return configuration.initial;
        }, [configuration]
    );
    
    const {props: dateRangeProps, dateRange} = useDateRangePickerState(initialRange);
    const filterProps = useMemo(()=>({dateRangeProps}), [dateRangeProps]);
    const requestParams = useMemo(()=>dateRangeFilter(dateRange, properties), [dateRange, properties]);
    return useMemo(()=>({
        requestParams,
        filterProps
    }),[requestParams, filterProps]);
};

const DateRangeFilter = ({dateRangeProps, label})=>{
    return (
        <div className='row'>
            <div className='col-md-4'>
                <label>{label||'Rango de fechas para las estadísticas:'}</label>
            </div>
            <div className='col-md-6'>
                <DateRangePicker
                    {...dateRangeProps}
                    startDatePlaceholderText='Inicio...'
                    endDatePlaceholderText='Fin...'
                />
            </div>
        </div>
    );
};

export default DateRangeFilter;
