import React, {useContext, useState, useEffect} from 'react';
import {Col, Grid, Row} from "react-bootstrap";
import Card from "../../components/Card/Card";
import CustomButton from "../../elements/CustomButton/CustomButton";
import {ApiContext} from "../../api/Api";
import ListEmployees from "../alma/Employees/core/ListEmployees";
import {connect} from "react-redux";
import {maxDecimals} from "../../utils/MathUtils";


const SysadminDash = ( {systemFilesStorage} )=>{

    const api = useContext(  ApiContext );
    const [elSwitch, setElSwitch] = useState('');
    const [back, setBack] = useState('');
    const [showEmp, setShowEmp] = useState(false);

    const goToProd = ()=>{

        if( window.confirm( "Achis achis los mariachis! ¿Estás seguro de lo que haces?" ) )
            api.switchHost('https://back.tgle.mx');
    };

    useEffect( ()=>{
        api.system.getFilesStorage();
    }, [api]);

    return <Grid fluid className='SysadminDash'>
        <Row>
            <Col md={9}>
                <Card
                    title={'Cambios rápidos'}
                    content={
                        <div className='switch-buts-cont'>
                            <CustomButton bsStyle='primary' onClick={ ()=>api.switchUser(40) }>40 - NOM</CustomButton>
                            <CustomButton bsStyle='primary' onClick={ ()=>api.switchUser(85) }>85 - COM</CustomButton>
                            <CustomButton bsStyle='primary' onClick={ ()=>api.switchUser(98) }>98 - EE</CustomButton>
                            <CustomButton bsStyle='primary' onClick={ ()=>api.switchUser(120) }>120 - CH</CustomButton>
                            <CustomButton bsStyle='primary' onClick={ ()=>api.switchUser(163) }>163 - ALMZ</CustomButton>
                            <hr/>
                            <input className='form-control switch-in' value={elSwitch} onChange={({target})=>setElSwitch(target.value)} />
                            <CustomButton onClick={()=>api.switchUser(elSwitch)}>Custom change</CustomButton>
                        </div>
                    }
                    />
            </Col>

            <Col xs={12} md={3}>
                <Card
                    title={'Almacenamiento de archivos'}
                    content={
                        <div className='switch-buts-cont'>
                           <h3>{maxDecimals( systemFilesStorage/1048576, 2) } MB</h3>
                        </div>
                    }
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12}>

                <Card
                    title={'Employees'}
                    content={
                        showEmp?
                            <ListEmployees onSwitchUser={api.switchUser} />:
                            <CustomButton onClick={()=>setShowEmp(true)}>Ver</CustomButton>
                    }
                />

            </Col>
        </Row>

        { process.env.REACT_APP_BUILD === 'dev'?
            <Row>
                <Col xs={12}>


                    <Card
                        title={'Cambio de sistema'}
                        content={
                            <div className='switch-buts-cont'>
                                <CustomButton bsStyle='primary' onClick={ ()=>api.switchHost('http://localhost:8000') }>local ocho mil</CustomButton>
                                <CustomButton bsStyle='primary' onClick={ ()=>api.switchHost('https://gledev.tide.mx') }>gledev</CustomButton>
                                <CustomButton bsStyle='primary' onClick={ ()=>api.switchHost('https://nom-back.tide.mx') }>nom</CustomButton>
                                <CustomButton bsStyle='danger' onClick={ goToProd }>PROD</CustomButton>
                                <hr/>
                                <input className='form-control switch-in' value={back} onChange={({target})=>setBack(target.value)} />
                                <CustomButton onClick={()=>api.switchHost(back)}>Custom change</CustomButton>
                            </div>
                        }
                    />


                </Col>
            </Row>
            :<div className='alert alert-warning'>PRODUCCIÓN</div>}
    </Grid>
};

const mapStateToProps = ( { api:{ systemFilesStorage=0 } } )=>({systemFilesStorage});

export default connect(mapStateToProps)(SysadminDash);
