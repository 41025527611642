import React, {useCallback, useContext, useEffect, useState} from 'react';
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import useBoolean from "../../../../hooks/useBoolean";
import CashRegisterFormModal from "./CashRegisterFormModal";
import CashRegisterDetailModal from "./CashRegistersDetailModal";
import _ from 'lodash';
import {useSelector} from "react-redux";
import {ApiContext} from "../../../../api/Api";
import {withLoungeSGroups} from "../../../../utils/modelUtils/orozco/orozcoCashRegisterUtils";
import {SecurityContext} from "../../../../utils/SecurityManager";
import Switch from "react-bootstrap-switch";

const AUTOMATIC_CASH_CLOSING_CONFIGURATION_ID = "AUTOMATIC_CASH_REGISTER_CLOSING";

const CashRegistersList=()=>{

    const [addingCash, startAddingCash, stopAddingCash]=useBoolean();

    const [selectedCashRegister, setSelectedCasRegister] = useState(null);
    const [automaticCashClosing, setAutomaticCashClosing] = useState(false);

    const api=useContext(ApiContext);
    const security = useContext(SecurityContext);

    const onHideDetail = ()=>{
        setSelectedCasRegister(null);
    };

    useEffect(()=>{
        api.orozcoCashRegisters.get({filters:{pagination:false, sGroups:withLoungeSGroups}});
        if(security.ee.canChangeAutomaticCashClosingConfiguration()) {
            api.configurations.getSingle({id: AUTOMATIC_CASH_CLOSING_CONFIGURATION_ID})
                .then(config => {
                    setAutomaticCashClosing(config.value === "1");
                });
        }
    },[api, security]);

    const cashRegisters=useSelector(({api})=>api.orozcoCashRegisters||[]);

    const splitByLounge=cashRegisters.reduce((acc, cashRegister)=>{

        if(acc[cashRegister.lounge.name])
            acc[cashRegister.lounge.name].push(cashRegister);
        else
            acc[cashRegister.lounge.name]=[cashRegister];
        return acc;
    },{});

    const changeAutomaticClosingConfiguration = useCallback((_, value)=>{
        setAutomaticCashClosing(value);
        api.configurations.batchEdit({configurations:[{
            id: AUTOMATIC_CASH_CLOSING_CONFIGURATION_ID,
            value: value?"1":"0"
            }]})
            .catch(()=>{
                setAutomaticCashClosing(!value);
            })
    },[api]);

    return (
        <div className='CashRegistersList main-content'>
            {security.ee.canChangeAutomaticCashClosingConfiguration() &&
                <div className='cash-closing-block'>
                    <p>Cerrar caja automáticamente cada cambio de turno:</p>
                    <Switch onChange={changeAutomaticClosingConfiguration} value={automaticCashClosing}/>
                </div>
            }
            <CustomButton bsStyle='primary' className='pull-right' onClick={startAddingCash}>Nueva caja registradora</CustomButton>
            <div className='clearfix'/>
            <div>
                {_.map(splitByLounge, (cashRegisters, lounge)=>
                    <div key={lounge}>
                        <h3 className='lounge-name'>Sala - {lounge}</h3>
                        {cashRegisters.map((cashRegister)=>
                            <div key={cashRegister.id} className='single-register' onClick={()=>setSelectedCasRegister(cashRegister)}>
                                <i className='fa fa-cube' /><br/>
                                {cashRegister.name}
                            </div>
                        )}
                    </div>
                )}
            </div>

            {addingCash&&
                <CashRegisterFormModal
                    onHide={stopAddingCash}
                />}

            {selectedCashRegister &&
                <CashRegisterDetailModal
                    cashRegister={selectedCashRegister}
                    onHide={onHideDetail}
                />
            }

        </div>
    );
};

export default CashRegistersList;
