
import ListEmployees from '../../views/alma/Employees/core/ListEmployees';
import CataloguesIndex from "../../views/alma/Catalogues/CataloguesIndex";
import PayrollDetail from "../../views/alma/Payroll/PayrollDetail";
import PayrollList from "../../views/alma/Payroll/PayrollList";
import EmployeeHistory from "../../views/alma/Employees/core/EmployeeHistory";
import Justifications from "../../views/alma/Manager/Justifications";
import EmployeeJustifications from '../../views/alma/Employees/payrollManagement/EmployeeJustifications';
import IncidenceRequests from "../../views/alma/Manager/IncidenceRequests";
import EmployeeIncidenceRequests from "../../views/alma/Employees/payrollManagement/EmployeeIncidenceRequests";
import CapiDashboard from "../../views/alma/Dashboard/CapiDashboard";
import EmployeeIncidences from "../../views/alma/Employees/payrollManagement/EmployeeIncidences";
import ListFiredEmployees from "../../views/alma/Employees/core/ListFiredEmployees";
import EmployeeWorkTools from "../../views/alma/Employees/workTools/EmployeeWorkTools";
import FileRepository from "../../views/alma/FileRepository/FileRepository";
import NotificationsList from "../../views/alma/Employees/NotificationsList";
import NotificationsConfiguration from "../../views/alma/Employees/NotificationsConfiguration";
import Alma from "../../views/alma/Alma";
import PersonnelRequisitions from "../../views/alma/HumanResources/PersonnelRequisitions";

const dashRoutes = [
    { path: "/alma", name: "ALMA 2.0", component: Alma, hidden: true },
    { path: "/alma/dashboard", name: "Dashboard", icon: "pe-7s-graph", component: CapiDashboard },

    { path: "/alma/employee/justifications/:id", name: "Historial de justificaciones", component: EmployeeJustifications, hidden: true },
    { path: "/alma/employee/requests/:id", name: "Historial de solicitudes", component: EmployeeIncidenceRequests, hidden: true },
    { path: "/alma/employee/incidences/:id", name: "Historial de incidencias", component: EmployeeIncidences, hidden: true },
    { path: "/alma/employee/history/:id", name: "Historial de asistencia", component: EmployeeHistory, hidden: true },
    { path: "/alma/employees/fired", name: "Empleados dados de baja", component: ListFiredEmployees,  hidden: true},
    { path: "/alma/payroll/detail/:id", name: "Detalle de nómina", component: PayrollDetail, hidden: true },
    { path: "/alma/employee/worktools/:id", name: "Equipo de trabajo", component: EmployeeWorkTools, hidden: true },
    { path: "/alma/employee/worktools", name: "Equipo de trabajo", component: EmployeeWorkTools, hidden: true },

    { path: "/alma/file-repository/:folder?/:openFile?", smallName: "DID TGLE", name: "DID TGLE", icon: "fa fa-archive", component: FileRepository, exact: false},

    { path: "/alma/employees/list", name: "Lista de colaboradores", icon: "fa fa-users", component: ListEmployees},

    { path: "/alma/justifying", smallName: "Justificantes", name: "Revisión de justificantes", icon: "fa fa-file-text-o", component: Justifications },
    { path: "/alma/requests", smallName: "Solicitudes", name: "Revisión de solicitudes", icon: "fa fa-comments-o", component: IncidenceRequests },

    { collapse: true, name: "Nóminas", state: "openPayroll", icon: "pe-7s-plugin", views:[
            { path: "/alma/payroll/list", name: "Lista de nóminas", mini: "N", component: PayrollList },
            { path: "/alma/payroll/detail/actual", name: "Nómina en curso", mini: "NC", component: PayrollDetail, justMenu:true },
        ]
    },


    { collapse: true, name: "Colaboradores", state: "openEmployees", icon: "fa fa-users", views: [
        {path: "/alma/employees/list", name: "Lista de colaboradores", mini:"LE", component: ListEmployees},
        {path: "/alma/personnel-requisitions", name: "Requisición de personal", mini: "RP", component: PersonnelRequisitions},
    ]
},

    { path: "/alma/catalogues", smallName: "Admon. de catálogos", name: "Administración de catálogos", icon: "fa fa-cogs", component: CataloguesIndex },

    { path: "/alma/notifications", name: "Notificaciones", component: NotificationsList, hidden:true },
    { path: "/alma/notifications-configuration", name: "Configuración de notificaciones", component: NotificationsConfiguration, hidden:true },


];
export default dashRoutes;
