import React from 'react';


export default function MultipleChoiceSelectAnswer( props ){

    let answer = [];
    if( props.data && props.data.response )
        answer = props.data.response;

    const clickOnAnswer = ( e )=>{
        const index = e.currentTarget.dataset.index;
        const option = props.config.options[index];

        const oldIndex = answer.indexOf(option);

        const newAnswer = [...answer];

        if( oldIndex === -1 )
            newAnswer.push(option);
        else
            newAnswer.splice(oldIndex, 1);

        props.onChange( {response:newAnswer} );
    };

    let options = [];
    if( props.config && props.config.options && props.config.options.constructor === Array )
        options = props.config.options;

    return (
        <div className='answer-component MultipleChoiceRadioAnswer'>
            {options.map((opt,i)=>
                <button
                    key={i}
                    className={ answer.indexOf(opt) !== -1? 'active':''}
                    onClick={clickOnAnswer} data-index={i}>
                    {opt}
                </button>
            )}
        </div>
    );

}