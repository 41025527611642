import moment from 'moment';

export const minutesToString = ( minutes )=> `${("0" + Math.floor((minutes%1440)/60)).slice(-2)}:${("0" + minutes%60).slice(-2)}`;

export const momentToMinutes = m => parseInt(m.format('H'))*60+parseInt(m.format('m'));

export const minutesToMoment = ( minutes, date )=> moment(date).hour(Math.floor(minutes/60)).minute(minutes%60);

export const getTimeElapsedString = (fromDate, toDate) => {
    const elapsedDuration = moment.duration(toDate.diff(fromDate)).asMinutes();
    return Math.round(elapsedDuration / 60) + ' horas y ' + Math.round(elapsedDuration % 60) + ' minutos';
};

export const getMinutesAndSecondsElapsedString = (fromDate, toDate) => {
    const elapsedDuration = moment.duration(toDate.diff(fromDate)).asSeconds();
    return Math.round(elapsedDuration / 60) + ' minutos y ' + Math.round(elapsedDuration % 60) + ' segundos';
};