import {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import useCallbackCreator from "../useCallbackCreator";
import saveReactTableState from "../../actions/action_saveReactTableState";

const defaultInitialTableState = {page:0, pageSize: 10, sorted:[], filtered:[]};
export default ( tableId, initialState=defaultInitialTableState )=>{

    const tableState = useSelector((state)=>state.reactTableState[tableId]) || initialState;
    const dispatch = useCallback(useDispatch(),[]);

    const changeHandlerCreator = useCallbackCreator((prop, value)=>{

        const newState = {...tableState, [prop]:value};
        //If page size changes we need to re-calculate the actual page
        if(prop==='pageSize')
            newState.page = Math.floor((tableState.pageSize*tableState.page+1)/value);

        const action = saveReactTableState(tableId, newState);
        dispatch(action);
    }, [tableState, dispatch]);

    return {
        tableProps:{
            onPageChange: changeHandlerCreator('page'),
            onPageSizeChange: changeHandlerCreator('pageSize'),
            onSortedChange: changeHandlerCreator('sorted'),
            onFilteredChange: changeHandlerCreator('filtered'),
            //Used to save variables or filters that are not handled by ReactTable
            onGenericStateChange: changeHandlerCreator('genericState'),
            ...tableState
        }
    };
};
