import React from 'react';
import Card from '../../../../components/Card/Card';
import RotationGraph from './RotationGraph';
import LayoffsReport from './LayoffsReport';


const LayoffsStats = (  )=>{

    return(
        <Card
            className='LayoffsStats'
            title='Reporte de bajas'
            content={
                <>
                    <LayoffsReport />

                    <hr/>

                    <RotationGraph />
                </>
            }
        />
    );

};

export default LayoffsStats;
