import React,{useContext, useEffect, useMemo} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import {maxDecimals} from "../../../../utils/MathUtils";
import DateRangeFilter, {useDateRangeFilter} from '../Filters/DateRangeFilter';
import ShiftFilter, {useShiftFilter} from '../Filters/ShiftFilter';

const loadingId='@GuestsAttendedReport.report';
const GuestsAttendedReport=({defaultDateRange})=>{
    const dateConfig = useMemo(()=>({initial: defaultDateRange}), [defaultDateRange]);
    const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);
    const {requestParams: shiftRequest, filterProps: shiftFilterProps} = useShiftFilter();
    const params = useMemo(()=>({...dateRequest, ...shiftRequest}), [dateRequest, shiftRequest]);
    const api=useContext(ApiContext);
    useEffect(()=>{
        api.employees.guestsAttendedReport({loadingId, params});
    },[api, params]);

    const report = useSelector(({api})=>api.guestsAttendedReport)||[];
    const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    const table = useMemo(()=>{
        const noZero=(report||[]).filter( data=>Number(data.visitsAttended) );
        const total = noZero.reduce( (sum, data)=>sum+Number(data.visitsAttended), 0);

        return {employees: noZero, total};
    },[report]);

    return (
        <div className='VisitsAirlinesReport'>
            <DateRangeFilter {...dateFilterProps}/>
            <ShiftFilter {...shiftFilterProps}/>
            {loading && <h3 className='text-center'>Cargando...</h3>}

            {table && !!table.total &&
                <h3>{maxDecimals(table.total/table.employees.length, 2)} huéspedes por anfitrión</h3>}

            <Table striped>
                <thead>
                <tr>
                    <th>Anfitrión</th>
                    <th className='text-center'>Huéspedes atendidos</th>
                    <th className='text-center'>Diferencia al promedio</th>
                </tr>
                </thead>
                <tbody>
                {table.employees.map((employeeData, i)=>
                    <tr key={i}>
                        <td>{employeeData.name}</td>
                        <td className='text-center'>{employeeData.visitsAttended}</td>
                        <td className='text-center'>
                        {(()=>{
                            const prom= table.total/table.employees.length;
                            const attended = Number(employeeData.visitsAttended);
                            return <span className={attended>=prom?'green':'red'}>{attended>prom?'+ ':''}{maxDecimals(Number(employeeData.visitsAttended) - prom, 2)}</span>;
                        })()}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>

        </div>
    );
};

export default GuestsAttendedReport;
