import React from 'react';
import logo from "../../assets/img/katharsis/tgle-Katharsis-dark.png";

const Katharsis = () => {

    return <div style={{ textAlign:'center', paddingTop:'50px' }}>
        <span>
            <img alt='logo' src={logo } style={{maxWidth:'50%'}} />
        </span>
    </div>;
};

export default Katharsis;
