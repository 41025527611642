import defaultProductPhoto from "../../../assets/img/no-product.png";

export const productBrandName=(productBrand)=>
    productBrand?
        `${productBrand.product && productBrand.product.name} - ${productBrand.brand && productBrand.brand.name}`
        :'undefined'
;

/**
 *
 * @param productBrand
 * @param api
 */
export const getProductBrandPhotoUrl=(productBrand, api)=>{
    let photo = productBrand.photo;

    if(!photo)
        photo = productBrand.product.photo;

    if(!photo){
        return defaultProductPhoto;
    }
    return api.createFileUrl(photo);
};

