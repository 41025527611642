import React, {useEffect, useMemo, useContext} from 'react';
import {useSelector} from 'react-redux';
import ReactEcharts from "echarts-for-react";
import {ApiContext} from '../../../../../api/Api';
import {getGcareQuestionType} from '../../utils';
const getOption = purposeStats=>({
    tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
    },
    title: {
        text: 'Motivo',
    },
    series: [
        {
            name:'Motivo',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            data: purposeStats.map(v=>({
                name: v.answer,
                value: parseInt(v.count)
            })),
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
});
const dateReq = ({from, to})=>({fromDate: from, toDate: to});
const PurposeStats = ({dateRequest, loungeAccessMethod: lam})=>{
    const api = useContext(ApiContext);
    const dateParams = useMemo(()=>dateReq(dateRequest), [dateRequest]);
    useEffect(()=>{
        api.questionnaireReport.get({
            isGcare: true,
            ...dateParams,
            ...(lam?{loungeAccessMethods: [lam]}:{})
        });
    }, [api, dateParams, lam]);
    const report = useSelector(({api})=>api.questionnaireReport||{});
    const purposeData = useMemo(()=>getGcareQuestionType('purpose', report), [report]);
    const option = useMemo(()=>getOption(purposeData?purposeData.stats:[]), [purposeData]);

    return (
        <ReactEcharts option={option}/>
    );
};

export default PurposeStats;
