import React, {useContext} from 'react';
import Button from "../../../elements/CustomButton/CustomButton";
import {ApiContext} from "../../../api/Api";
import ClassicModal from "../../../components/Modals/ClassicModal";
import moneyFormatter from "../../../utils/moneyFormatter";
import {poTrans} from "../../../utils/modelUtils/jazz/purchaseOrderUtils";


const ProviderDetailModal = ({provider, onHide, onEvaluateProvider, onShowEvaluations, onEdit}) => {
    const api = useContext(ApiContext);

    return (
        <ClassicModal
            onHide={onHide}
            title='Detalles del proveedor'
            bsSize='lg'
            onSave={onEdit}
            confirmText={'Editar'}
            confirmStyle={'primary'}
        >
            <div className='container-fluid'>
                <h2>{provider.commercialName || provider.name}</h2>
                <h4>Información fiscal</h4>
                <hr/>
                <div className='row'>
                    <p className='col-xs-12 col-md-6'>
                        <span className='text-muted small'>Razón social:</span><br/>
                        {provider.name}
                    </p>
                    <p className='col-xs-12 col-md-6'>
                        <span className='text-muted small'>RFC:</span><br/>
                        {provider.rfc}
                    </p>
                </div>
                <div className='row'>
                    <p className='col-xs-12'>
                        <span className='text-muted small'>Dirección:</span><br/>
                        {provider.street||'Sin calle'} {provider.exteriorNumber} {provider.interiorNumber?'Int. '+provider.interiorNumber:''},{' '}
                        {provider.neighborhood||'Sin colonia'}. {provider.zipCode?'CP '+provider.zipCode:''}<br/>
                        {provider.cityCode?'Ciudad: '+provider.cityCode:''}
                    </p>
                </div>
                <h4>Contacto</h4>
                <hr/>
                <div className='row'>
                    <p className='col-xs-12 col-md-6'>
                        <span className='text-muted small'>Contacto 1:</span><br/>
                        {provider.contactName1 || 'Sin contacto'}
                        {!!provider.contactPhone1&&<>
                            <br/>{provider.contactPhone1}
                        </>}
                    </p>
                    {!!provider.contactName2&&
                    <p className='col-xs-12 col-md-6'>
                        <span className='text-muted small'>Contacto 2:</span><br/>
                        {provider.contactName2 || 'Sin contacto'}
                        {!!provider.contactPhone2&&<>
                            <br/>{provider.contactPhone2}
                        </>}
                    </p>}
                    <p className='col-xs-12  col-md-6'>
                        <span className='text-muted small'>Emails:</span><br/>
                        {provider.emails?provider.emails.join(', '):'Sin correos'}
                    </p>
                </div>
                <h4>Información de pago</h4>
                <hr/>
                <div className='row'>
                    <p className='col-xs-12 col-md-6'>
                        <span className='text-muted small'>Método de pago:</span><br/>
                        {provider.paymentMethod? poTrans(provider.paymentMethod): 'No definido'}
                    </p>
                    <p className='col-xs-12 col-md-6'>
                        <span className='text-muted small'>Condiciones de pago:</span><br/>
                        {provider.paymentCondition? poTrans(provider.paymentCondition): 'No definido'}
                    </p>
                </div>
                <div className='row'>
                    <p className='col-xs-12 col-md-6'>
                        <span className='text-muted small'>Moneda de pago:</span><br/>
                        {provider.paymentCurrency || 'No definido'}
                    </p>
                    {provider.tgleCompany&&
                    <p className='col-xs-12 col-md-6'>
                        <span className='text-muted small'>Razón social que paga:</span><br/>
                        { provider.tgleCompany.name}
                    </p>}
                    {provider.creditLimit&&
                    <p className='col-xs-12 col-md-6'>
                        <span className='text-muted small'>Límite de crédito:</span><br/>
                        {moneyFormatter(provider.creditLimit, 1)}
                    </p>}

                </div>
                {provider.notes&&
                <div className='row'>
                    <div className='col-xs-12'>
                        <span className='text-muted small'>Notas:</span><br/>
                        <pre>{provider.notes}</pre>
                    </div>
                </div>}
                {provider.files && !!provider.files.length &&
                <div className='row'>
                    <div className='col-xs-12'>
                    {provider.files.map((file) =>
                        <div key={file.id}>
                            <a href={api.createFileUrl(file)} target='_blank' rel="noopener noreferrer">{file.originalName}</a>
                        </div>
                    )}
                    </div>
                </div>}

                <hr/>
                <Button bsStyle='success' onClick={onShowEvaluations}>Ver evaluaciones</Button>
                <Button bsStyle='success' className='pull-right' onClick={onEvaluateProvider}>Evaluar proveedor</Button>
            </div>
        </ClassicModal>
    );
};

export default ProviderDetailModal;
