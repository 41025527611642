import React from 'react';
import ConditionalQuestion from './advancedConfigurator/ConditionalQuestion';
import RequiredQuestion from './advancedConfigurator/RequiredQuestion';
import OptionsValues from './advancedConfigurator/OptionsValues';
import GroupQuestions from './advancedConfigurator/GroupQuestions';
import RangesValues from "./advancedConfigurator/RangesValues";
import HistoryEdition from "./advancedConfigurator/HistoryEdition";
import FilterableQuestion from "./advancedConfigurator/FilterableQuestions";
import InactiveQuestion from "./advancedConfigurator/InactiveQuestion";
import GcareQuestion from './advancedConfigurator/GcareQuestion';
import PositionSelector from './advancedConfigurator/PositionSelector';

const AdvancedQuestionConfigurator = ( props )=>{
    const isGcare = props.questionnaire.isGcare;
    return (
        <div className='AdvancedQuestionConfigurator'>
            {isGcare&&
                <GcareQuestion
                    {...props}
                />
            }
            <InactiveQuestion
                { ...props }
                />
            <RequiredQuestion
                { ...props }
                />
            <FilterableQuestion
                { ...props }
                />
            <GroupQuestions
                { ...props }
                />
            <ConditionalQuestion
                { ...props }
                />
            <OptionsValues
                { ...props }
                />
            <RangesValues
                { ...props }
                />
            
            <PositionSelector {...props} />

            {/*HistoryEdition must be the last one*/}
            <HistoryEdition
                { ...props }
                />

        </div>
    )
};

export default AdvancedQuestionConfigurator;
