import {orozcoRecipeEditorSGroups} from "./orozcoRecipeUtils";

export const printAreas = {
    cocina: 'cocina',
    bar: 'bar',
    dotcom: 'dotcom'
};

export const listSGroups = [
    'orozco_product_read',
    'orozco_product_read_product_photo',
    'app_file_read',
    'orozco_product_read_orozco_product_categories',
    'orozco_product_category_read',
    'orozco_product_read_place',
    'place_read',
    'orozco_product_read_prices',
    'orozco_product_read_orozco_product_modifiers',
    'orozco_product_modifier_read'
];

export const orozcoProductRecipeListSGroups = [
    'orozco_product_read',
    'orozco_product_read_lounge',
        'place_read',
    'orozco_product_read_orozco_recipe',
    'orozco_product_read_orozco_product_categories',
        'orozco_product_category_read',
];

export const orozcoProductRecipeListColumns = [
    {
        Header:'Platillo',
        accessor: 'name',
    },
    {
        Header: 'Salas',
        accessor: (op)=>op?.lounges?.map(l=>l.name).join(', '),
        id: 'lounges.name'
    },
    {
        Header: 'Categorías',
        accessor: (op)=>op?.orozcoProductCategories?.map(c=>c.name).join(', '),
        id: 'orozcoProductCategories.name'
    },
    {
        Header: 'Tiene Receta',
        accessor: (op)=>op?.orozcoRecipe?'Si':'No',
        id: 'orozcoRecipe.name',
        filterable: false
    },
    {
        Header:'Consumo por cada 100 huéspedes',
        id: 'consumedAverageByGuestInPeriod',
        accessor: (op)=>Math.ceil(op.consumedAverageByGuestInPeriod*100),
    }
];

export const orozcoProductRecipeEditorSGroups = [
    'orozco_product_read',
    'orozco_product_read_product_photo',
        'app_file_read',
    'orozco_product_read_orozco_product_modifiers',
        'orozco_product_modifier_read',
        'orozco_product_modifier_read_orozco_recipe',
    'orozco_product_read_orozco_recipe',
        'orozco_recipe_read_id',
    ...orozcoRecipeEditorSGroups
];

export const productsGroups = {
    INGRESO_EXTRA_GROUP: 'INGRESO_EXTRA',
    PLATILLO_EXTRA_GROUP: 'PLATILLO_EXTRA',
    DOTCOM_CAFE_GROUP: 'DOTCOM_CAFE'
};

export const productsTypes = {
    BEBIDA_TYPE: 'BEBIDA',
    ALIMENTO_TYPE: 'ALIMENTO',
    SERVICIO_TYPE: 'SERVICIO',
    OTRO_TYPE: 'OTRO'
};
