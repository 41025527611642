import React, {useContext} from 'react';
import {ApiContext} from '../../../api/Api';
import CategoryModal from './CategoryModal';

const EditCategoryModal = ({onHide, toEdit}) => {
    const api = useContext(ApiContext);
    const editOrozcoProductCategory = state=>{
        api.orozcoProductCategories.edit({
            id: toEdit.id,
            properties: {
                ...state,
                parentCategory: toEdit.parentCategory?(state.parentCategory || null):(state.parentCategory || undefined)
            }
        });
    };
    const deleteOrozcoProductCategory = id=>{
        api.orozcoProductCategories.delete({id});
    };
    return (
        <CategoryModal
            onHide={onHide}
            onSubmit={editOrozcoProductCategory}
            submitText='Editar categoría'
            title='Editar categoría'
            initialValues={toEdit}
            onDelete={deleteOrozcoProductCategory}
        />
    );
};

export default EditCategoryModal;
