export const itemsListForRequisitionSGroups=[
    'product_brand_provider_read',
    'product_brand_provider_read_product_brand',
        'product_brand_read_id',
        'product_brand_read_product',
            'product_read_id',
            'product_read_name',
                'measurement_unit_read',
            'product_read_smallest_unit',
            'product_read_display_unit',
            'product_read_conversion_rules',
                'unit_conversion_rule_read_id',
                'unit_conversion_rule_read_unit',
                'unit_conversion_rule_read_multiplier',
    'product_brand_read_brand',
        'brand_read',
];