import React, { Component } from 'react';

import PropTypes from "prop-types";


export default class LogoutPage extends Component{

    componentDidMount(){
        this.context.api.logOut();
    }

    render(){


        return (
            <div className="logout-wrap">
                <h3>Cerrando sessión...</h3>
            </div>
        );
    }
}

LogoutPage.contextTypes = {api: PropTypes.object};
