import React, {useContext} from 'react';
import ReactTable from "react-table";
import useTideTable from "../../../hooks/table/useTideTable";
import {shrinkageListColumns, shrinkageListSGroups} from "../../../utils/modelUtils/almazen/shrinkageUtils";
import Button from "../../../elements/CustomButton/CustomButton";
import {ApiContext} from "../../../api/Api";
import moment from 'moment';

const DEFAULT_DAYS_REPORT_XLS = 2;

const filters = { sGroups:shrinkageListSGroups };

const tableDefaultFilters = [{
    id:'createdDate',
    value:{ startDate:moment().subtract(DEFAULT_DAYS_REPORT_XLS, 'days'), endDate:moment()}
}];

const ShrinkageList = () => {
    const api = useContext(ApiContext);

    const table = useTideTable({
        entity:'shrinkages',
        columns: shrinkageListColumns,
        requestFilters:filters
    });

    const lastFilters = table?.lastUsedApiOptions?.filters || filters;

    return (
        <div className={"ShrinkageList main-container"}>
            <div className='card'>

                <ReactTable
                    {...table.tableProps}
                    defaultFiltered={tableDefaultFilters}
                />
                <a
                    href={ api.shrinkages.exportUrl({...lastFilters, pagination:false})}
                    rel="noopener noreferrer"
                >
                    <Button>Exportar a Excel</Button>
                </a>

            </div>
        </div>
    );

};

export default ShrinkageList;
