import Dashboard from '../../views/alma/Dashboard/ManagerDashboard';

import EmployeeHistory from "../../views/alma/Employees/core/EmployeeHistory";
import EmployeeJustifications from "../../views/alma/Employees/payrollManagement/EmployeeJustifications";
import EmployeeIncidenceRequests from "../../views/alma/Employees/payrollManagement/EmployeeIncidenceRequests";
import ListEmployees from "../../views/alma/Employees/core/ListEmployees";
import Justifications from "../../views/alma/Manager/Justifications";
import IncidenceRequests from "../../views/alma/Manager/IncidenceRequests";
import EmployeeIncidences from "../../views/alma/Employees/payrollManagement/EmployeeIncidences";
//import EmployeePayrolls from "../../views/alma/Employees/payrollManagement/EmployeePayrolls";
import EmployeeWorkTools from "../../views/alma/Employees/workTools/EmployeeWorkTools";
import PersonnelRequisitions from "../../views/alma/HumanResources/PersonnelRequisitions";
import LayoffRequests from "../../views/alma/HumanResources/LayoffRequests";
import FileRepository from "../../views/alma/FileRepository/FileRepository";
import NotificationsList from "../../views/alma/Employees/NotificationsList";
import NotificationsConfiguration from "../../views/alma/Employees/NotificationsConfiguration";
import Alma from "../../views/alma/Alma";


const dashRoutes = [
    { path: "/alma", name: "ALMA 2.0", component: Alma, hidden: true },

    { path: "/alma/dashboard", name: "Dashboard", icon: "pe-7s-graph", component: Dashboard },
    { path: "/alma/file-repository/:folder?/:openFile?", smallName: "DID TGLE", name: "DID TGLE", icon: "fa fa-archive", component: FileRepository, exact: false},

    { path: "/alma/employee/history/:id", name: "Historial de asistencia", component: EmployeeHistory, hidden: true },
    { path: "/alma/employee/justifications/:id", name: "Historial de justificaciones", component: EmployeeJustifications, hidden: true },
    { path: "/alma/employee/requests/:id", name: "Historial de solicitudes", component: EmployeeIncidenceRequests, hidden: true },
    { path: "/alma/employee/incidences/:id", name: "Historial de incidencias", component: EmployeeIncidences, hidden: true },
    { path: "/alma/employee/worktools/:id", name: "Equipo de trabajo", component: EmployeeWorkTools, hidden: true },
    { path: "/alma/employee/worktools", name: "Equipo de trabajo", component: EmployeeWorkTools, hidden: true },

    { collapse: true, name: "Mi información", state: "openPersonal", icon: "fa fa-user-o", views:[
            { path: "/alma/employee/history", name: "Mis asistencias", icon: "fa fa-clock-o", mini:"MA", component: EmployeeHistory },
            //{ path: "/alma/employee/payroll", name: "Mis pagos", icon: "fa fa-money", mini:"MP", component: EmployeePayrolls },
            { path: "/alma/employee/justifications", name: "Mis Justificantes", icon: "fa fa-file-text-o", mini:"MJ", component: EmployeeJustifications },
            { path: "/alma/employee/requests", name: "Mis Solicitudes", icon: "fa fa-comments-o", mini:"MS", component: EmployeeIncidenceRequests },
            { path: "/alma/employee/incidences", name: "Mis Incidencias", icon: "fa fa-exclamation-circle",  mini:"MI", component: EmployeeIncidences},
        ]
    },

    { path: "/alma/employees/list", name: "Lista de colaboradores", icon: "fa fa-users", component: ListEmployees},
    { path: "/alma/justifying", smallName: "Justificantes", name: "Revisión de justificantes", icon: "fa fa-file-text-o", component: Justifications },
    { path: "/alma/requests", smallName: "Solicitudes", name: "Revisión de solicitudes", icon: "fa fa-comments-o", component: IncidenceRequests },
    { path: "/alma/personnel-requisitions", name: "Requisición de personal", mini: "RP", icon: "fa fa-id-badge", component: PersonnelRequisitions},
    {path: "/alma/layoff-requests", name: "Requisición de baja", mini: "RB", icon: "fa fa-user-times", component: LayoffRequests},

    { path: "/alma/notifications", name: "Notificaciones", component: NotificationsList, hidden:true, },
    { path: "/alma/notifications-configuration", name: "Configuración de notificaciones", component: NotificationsConfiguration, hidden:true, },

];
export default dashRoutes;
