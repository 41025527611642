import React, {useContext, useCallback} from 'react';
import {connect} from 'react-redux';
import {ChatManagerContext} from "./ChatManager";

/*
Displays a fullscreen notification when there's a new unattended chat.
 */
const GuestChatNotifier = ({chats})=>{

    const chatManager = useContext(ChatManagerContext);

    const takeGuestChat = useCallback(()=>{
        chatManager.takeGuestChat(chats[0]);
    },[chatManager, chats]);
    if(!chats.length)
        return null;

    return (
        <div className='GuestChatNotifier' onClick={takeGuestChat}>
            <i className='fa fa-comment'/>
            <span>{chats.length}</span>
        </div>
    );
};

const mapStateToProps = ({api:{chats=[]}})=>({chats});

export default connect(mapStateToProps)(GuestChatNotifier);
