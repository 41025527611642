import React, {PureComponent} from 'react';
import moneyFormatter from "../../../../utils/moneyFormatter";
import moment from "moment/moment";
import {Table} from 'react-bootstrap';
import Button from "../../../../elements/CustomButton/CustomButton";

export default class PayrollDetailExplanation extends PureComponent{

    render(){

        //20/03/2022 Nos solicitan que ya no se pueda justificar sin que el empleado mande una solicitud
        //const onJustify = this.props.onJustify;// (Des-comentar esta línea para reactivar)
        const onJustify = null;

        const payrollDetail = this.props.payrollDetail;
        if( !payrollDetail)
            return <div />;

        return <div>

            <h4>Total a pagar - {moneyFormatter(payrollDetail.total)}</h4>
            <h4>Sueldo quincenal - {moneyFormatter(payrollDetail.salary)}</h4>
            {!payrollDetail.appliedDiscounts.length? null :
                <div>
                    <h4>Descuentos - {moneyFormatter(payrollDetail.totalDiscount)}</h4>
                    <Table>
                        <thead>
                        <tr>
                            <th className="col-md-4">Concepto</th>
                            <th className="col-md-4">Monto</th>
                            <th className="col-md-4">Fechas</th>
                        </tr>
                        </thead>
                        <tbody>

                        {payrollDetail.appliedDiscounts.map((appDis)=>(
                            <tr key={appDis.id?appDis.id:Math.random()}>
                                <td className="col-md-4">
                                    {appDis.appliedIncidences.length?
                                        appDis.appliedIncidences[0].incidence.name:
                                        <div>Descuento<br/>
                                            {appDis.comment}<br/>
                                            {this.props.canModify?
                                            <span className="link red" onClick={()=>this.props.onRemoveDiscount(appDis.id)}>Eliminar descuento</span>
                                                :null}
                                        </div>
                                    }
                                </td>
                                <td className="col-md-4">{moneyFormatter(appDis.amount)}</td>
                                <td className="col-md-4">
                                    {appDis.appliedIncidences.length?

                                        appDis.appliedIncidences.map(
                                        (appInc)=><div key={appInc.id}>
                                            {moment(appInc.shiftDate).format("DD/MM/YYYY")}{" "}
                                            { onJustify? <span className="justify-but" onClick={()=>onJustify(appInc)}>Justificar</span> :null}
                                        </div>):

                                        <div>{moment(appDis.date).format("DD/MM/YYYY")}</div>
                                            }
                                </td>
                            </tr>
                        ))}

                        </tbody>
                    </Table>
                </div>
            }
            {!payrollDetail.appliedBonuses.length? null :
                <div>
                    <h4>Bonos - {moneyFormatter(payrollDetail.totalBonus)}</h4>
                    <Table>
                        <thead>
                        <tr>
                            <th className="col-md-4">Concepto</th>
                            <th className="col-md-4">Monto</th>
                            <th className="col-md-4">Fechas</th>
                        </tr>
                        </thead>
                        <tbody>
                        {payrollDetail.appliedBonuses.map(
                            (appBon)=>(
                                <tr key={appBon.id?appBon.id:Math.random()}>
                                    <td className="col-md-4">
                                        {appBon.bonus.name} <br/>
                                        {appBon.comment? <div>{appBon.comment}
                                        </div>:null}
                                        {appBon.bonus.code === "TDF"?
                                            <div>{moment(appBon.publicHolidayDate).format("DD/MM/YYYY")}</div>:null}

                                        {!appBon.bonus.isAutomatic && this.props.canModify?
                                        <span className="link red" onClick={()=>this.props.onRemoveBonus(appBon.id)}>Eliminar bono</span>
                                        :null} </td>

                                    <td className="col-md-4">{moneyFormatter(appBon.amount)}</td>
                                    <td className="col-md-4"><div>{moment(appBon.date).format("DD/MM/YYYY")}</div></td>
                                </tr>
                            )
                        )}
                        </tbody>
                    </Table>
                </div>
            }
            {this.props.canModify?
                <div>
                    <hr/>
                    <Button
                        bsStyle="danger"
                        onClick={this.props.onAddDiscountClick}
                    > Agregar descuento</Button>
                    <Button
                        bsStyle="success"
                        style={{float:"right"}}
                        onClick={this.props.onAddBonusClick}
                    > Agregar Bono</Button>
                </div>
                :null}

        </div>
    }}
