import React, {useMemo, useState} from 'react';
import ReactTable from 'react-table';
import useTideTable from '../../../hooks/table/useTideTable';
import moment from 'moment';
import VisitDetailModal from './VisitDetailModal';
import useBoolean from "../../../hooks/useBoolean";
import {Button} from "react-bootstrap";
import {visitListSGroups} from "../../../utils/modelUtils/davinci/visitUtils";

const columns = [
    {
        Header: 'Fecha',
        id: 'createdDate',
        accessor: x=>moment(x.createdDate).format('DD/MM/YYYY HH:mm'),
        filterable: false
    },
    {
        Header: 'Sala',
        id: 'lounge.name',
        accessor: x=>x.lounge.name
    },
    {
        Header: 'Huésped',
        id: 'search',
        accessor:'guest.fullName'
    },
    {
        Header: 'Folio',
        accessor:'folio'
    }
];

const requestFilters = {
    sGroups: visitListSGroups,
    'order[createdDate]': 'DESC'
};

const VisitsList = ()=>{
    const [visitDetail, setVisitDetail] = useState(null);
    const [showCanceled,,,toggleShowCanceled] = useBoolean();

    const filter = useMemo(()=>({...requestFilters, "exists[checkIns.canceledDate]":showCanceled}), [showCanceled]);

    const visitsTable = useTideTable({
        entity: 'visits',
        columns,
        requestFilters:filter
    });
    const getTrProps = (state, rowInfo)=>({
        style: (rowInfo&&rowInfo.original)?{cursor: 'pointer'}:{},
        onClick: ()=>setVisitDetail(rowInfo.original.id)
    });

    return (
        <>
            <div className='container-fluid'>
                <Button onClick={toggleShowCanceled}>Mostrar {showCanceled?"sin cancelar":"solo canceladas"}</Button>
                <div className='col-xs-12'>
                    <ReactTable
                        getTrProps={getTrProps}
                        {...visitsTable.tableProps}
                        />
                </div>
            </div>
            {!!visitDetail&&
            <VisitDetailModal
                onHide={()=>setVisitDetail(null)}
                visitId={visitDetail}
            />}
        </>
    );

};

export default VisitsList;
