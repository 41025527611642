import React from 'react';
import "./KatharsisDashboard.scss";
import WidgetUsedBudget from "./components/WidgetUsedBudget/WidgetUsedBudget";

const KatharsisDashboard = () => {
    return (
        <div className={"KatharsisDashboard"}>
            <WidgetUsedBudget/>
        </div>
    );
}
 
export default KatharsisDashboard;