import React, {Component} from 'react';
import {Grid, Row, Col} from 'react-bootstrap';
import PayrollDetailGraph from "./Widgets/CPayrollDetailGraph";
import LoungeCostGraph from "./Widgets/CLoungeCostGraph";
import PPayrollClosed from "./Widgets/PPayrollClosed";
import PEmployeeCount from "./Widgets/PEmployeeCount";
import PPayrollTotal from "./Widgets/PPayrollTotal";
import PAverageWage from "./Widgets/PAverageWage";
import PropTypes from "prop-types";




export default class PayrollDashboard extends Component {

    static contextTypes = {api: PropTypes.object };

    constructor(props, context){
        super(props);

        context.api.payrollDetailsRealTime.get();// For PPayrollTotal, PAverageWage, PPayrollClosed
        context.api.employees.get(0,1,undefined,{isHired:true});// For PEmployeeCount
        context.api.payrolls.get(0,6, undefined,{"order[createdDate]":"DESC"});// For CPayrollDetailGraph
    }

    render() {

        return (
            <div className="main-content Dashboard">
                <Grid fluid>

                    <Row>
                        <Col lg={3} sm={6}>
                            <PPayrollTotal />
                        </Col>

                        <Col lg={3} sm={6}>
                            <PAverageWage />
                        </Col>

                        <Col lg={3} sm={6}>
                            <PPayrollClosed />
                        </Col>

                        <Col lg={3} sm={6}>
                            <PEmployeeCount />
                        </Col>

                    </Row>
                    <Row>
                        <Col md={6}>
                            <PayrollDetailGraph/>
                        </Col>

                        <Col md={6}>
                            <LoungeCostGraph/>
                        </Col>
                    </Row>

                </Grid>
            </div>
        );
    }
}