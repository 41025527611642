import React, {Component} from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import moneyFormatter from '../../../utils/moneyFormatter';
import {Link} from 'react-router-dom';
import ReactTableTranslations from "../../../utils/ReactTableTranslation";

class PayrollList extends Component{

    static contextTypes = { api:PropTypes.object };

    loadingId = "@Class.PayrollList.get";

    constructor( props, context ){
        super(props);

        this.loadData = this.loadData.bind(this);
        this.dataApi = context.api.payrolls;
    }

    loadData( tableState ){

        const sort =  tableState.sorted.reduce( (acc, val)=>{acc[`order[${val.id}]`] = val.desc?"DESC":"ASC"; return acc;}, {} );

        if( !tableState.sorted || !tableState.sorted.length )
            sort["order[createdDate]"] = 'DESC';

        this.dataApi.get(tableState.page , tableState.pageSize, this.loadingId, {...sort} );
    }

    tableStructure = [
       {
            Header: 'Inicio',
            accessor: (payroll)=>moment(payroll.startDate).format("DD/MM/YYYY"),
            id: 'startDate'
        }, {
            Header: 'Fin',
            accessor: (payroll)=>moment(payroll.endDate).format("DD/MM/YYYY"),
            id: 'endDate',
            sortable: false
        }, {
            Header: 'Sueldo base',
            accessor: (payroll)=>moneyFormatter(payroll.salary),
            id: 'salary'
        },{
            Header: 'Descuentos',
            accessor: (payroll)=>moneyFormatter(payroll.totalDiscount),
            id: 'totalDiscount'
        }, {
            Header: 'Bonos',
            accessor: (payroll)=>moneyFormatter(payroll.totalBonus),
            id: 'totalBonus'
        }, {
            Header: 'Total',
            accessor: (payroll)=>moneyFormatter(payroll.total),
            id: 'total'
        }, {
            Header: 'Detalles',
            accessor: 'id',
            Cell: ({value}) => (
                <div>
                    <Link to={`/alma/payroll/detail/${value}`}><i className="fa fa-eye pointer" /> </Link>
                    <a href={this.context.api.downloadPayrollDetailUrl(value)} target="_blank" rel="noopener noreferrer" ><i className="fa fa-download pointer" /></a>
                </div>
            )
        }
    ];

    render(){

        const { totalItems, itemsPerPage } = this.props.listMeta;

        return(
            <div className="container-fluid main-container">

                <ReactTable
                    data={this.props.list}
                    columns = {this.tableStructure}
                    defaultPageSize= {10}
                    pages= { Math.ceil( totalItems / itemsPerPage ) }
                    className="-striped -highlight"
                    loading={!!this.props.loadingIds[this.loadingId]}
                    manual
                    onFetchData={this.loadData}
                    {...ReactTableTranslations}
                />
            </div>
        )}
}

function mapStateToProps( {api:{payrolls=[], payrollsMeta={totalItems:0,itemsPerPage:0}}, loadingIds } ) {

    return { list:payrolls, listMeta:payrollsMeta, loadingIds }
}

export default connect(mapStateToProps )(PayrollList);
