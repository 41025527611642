import './DestinationTypeForm.scss';
import React, {useState} from 'react';
import CustomButton from 'elements/CustomButton/CustomButton';

const DestinationTypeForm = ({destinationType = null, onSubmit = () => {}}) => {
    //State
    const [form, setForm] = useState(destinationType);

    //Handlers
    const handleInputChange = ({target: {name, value}}) => setForm({...form, [name] : value});

    const handleFormSubmit = event => {
        event.preventDefault();
        onSubmit(form);
    };

    return (
        <form className="DestinationTypeForm" onSubmit={handleFormSubmit}>
            <div className="form-group">
                <label>
                    Nombre:
                    <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={form?.name || ''}
                        placeholder="Nombra el nuevo tipo de destino"
                        onChange={handleInputChange}
                    />
                </label>
                <CustomButton type="submit" fill block>Guardar</CustomButton>
            </div>
        </form>
    );
};
export default DestinationTypeForm;