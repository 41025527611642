import React, {useCallback} from 'react';
import moneyFormatter from "../../../../utils/moneyFormatter";
import {displayAmount} from "../../../../utils/modelUtils/almazen/unitsUtility";
import PurchaseProductTaxes from "./PurchaseProductTaxes";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import useBoolean from "../../../../hooks/useBoolean";
import PurchaseProductAnalysis from "./PurchaseProductAnalysis/PurchaseProductAnalysis";

const PurchaseProductQuoteRow = (props) => {

    const {
        purchaseProduct,
        purchaseRequisition,
        purchaseProductQuote,
        ppIndex,
        quoteIndex,
        onHoverChange,
        isOpen,
        hasDiscount,
        currency,
        //onMakeFirst
	stockRequestAmount,
	productTotal
    } = props;

    const handleMouseEnter = useCallback(() => onHoverChange(true), [onHoverChange]);
    const handleMouseLeave = useCallback(() => onHoverChange(false), [onHoverChange]);

    const [showingAnalysis,showAnalysis, hideAnalysis] = useBoolean();

    const handleClick = useCallback(() => {
        if(purchaseRequisition && purchaseProduct.productBrand)
            showAnalysis();
    }, [purchaseRequisition, showAnalysis, purchaseProduct]);
    /*const handleClick = useCallback( ()=>{
        onMakeFirst( quoteIndex );
    }, [quoteIndex, onMakeFirst] );*/

    let classes = quoteIndex !== 0 && !isOpen ? 'quotation-row' : '';
    if (quoteIndex === 0) classes += ' first';

    if (purchaseProduct.selectedPurchaseProductQuote) {

        const total = Number(purchaseProductQuote.total);
        const selectedTotal = Number(purchaseProduct.selectedPurchaseProductQuote.total);
        if (total && selectedTotal) {
            if (total > selectedTotal)
                classes += ' up';
            else if (total < selectedTotal)
                classes += ' down';
        }
    }
    const stockDiff = stockRequestAmount?stockRequestAmount-productTotal:0;
    const stockDiffClass = stockRequestAmount?(stockDiff>=0?'green':'red'):'';

    return (
        <tr
            className={'PurchaseProductQuoteRow product-row ' + classes}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <td className='text-center' onClick={handleClick} data-label='#'>
                <div className='outer-div'>
                    <div className='inner-div'>
                        {ppIndex + 1}
                    </div>
                </div>
            </td>

            <td className='text-center' onClick={handleClick} data-label='Tipo'>
                <div className='outer-div'>
                    <div className='inner-div'>
                        {purchaseProduct.isService ? 'Servicio' : 'Producto'}
                    </div>
                </div>
            </td>
            {/* De línea o no */}
            <td className='text-center' onClick={handleClick} data-label='De línea'>
                <div className='outer-div'>
                    <div className='inner-div'>
                        {purchaseProduct.productBrand ? 'Si' : 'No'}
                    </div>
                </div>
            </td>

            <td className='text-left' onClick={handleClick} data-label='Nombre'>
                <div className='outer-div'>
                    <div className='inner-div'>
                        {purchaseProduct.productBrand ?
                            (purchaseProduct.productBrand.product.name + ' - ' + purchaseProduct.productBrand.brand.name)
                            : purchaseProduct.name}
                    </div>
                </div>
            </td>

            <td onClick={handleClick} data-label='Cantidad' className={stockDiffClass}>
                <div className='outer-div'>
                    <div className='inner-div'>
			{!!stockRequestAmount&&
			 <OverlayTrigger placement="top"
					 overlay={<Tooltip id='purchase-product-prices-difference-tooltip'>
						      <div>Este producto corresponde a una requisición de almacén</div>
						      {displayAmount(stockRequestAmount, purchaseProduct?.productBrand?.product)}{' '}
                              requeridos en almacén.<br/>

                         {displayAmount(productTotal, purchaseProduct?.productBrand?.product)}{' '} en esta orden
						  </Tooltip>}
					 >
			     <i className="fa fa-archive"/>
			 </OverlayTrigger>
			}
                        {Number(purchaseProduct.quantity)} {purchaseProduct.measurementUnit ? purchaseProduct.measurementUnit.name : null}
                    </div>
                </div>
            </td>

            <td data-label='Proveedor'  onClick={handleClick}>
                <div className='outer-div'>
                    <div className='inner-div'>
                        {purchaseProductQuote.provider.name}
                    </div>
                </div>
            </td>

            <td data-label='Precio unitario' onClick={handleClick}>
                <OverlayTrigger placement="top"
                                overlay={<Tooltip id='purchase-product-prices-difference-tooltip'>
                                    <div>
                                        <div>Último precio de
                                            compra: {purchaseProduct.previousPurchaseSmallestUnitPrice ? moneyFormatter((purchaseProduct.previousPurchaseSmallestUnitPrice * purchaseProduct.smallestUnitQuantity) / purchaseProduct.quantity, 1, purchaseProduct.currency) : 'Sin compras previas'}</div>
                                        <div>Precio de
                                            lista: {purchaseProduct.providerListUnitPrice ? moneyFormatter((purchaseProduct.providerListUnitPrice * purchaseProduct.smallestUnitQuantity) / purchaseProduct.quantity, 1, purchaseProduct.currency) : 'Sin precio de lista'}</div>
                                    </div>
                                </Tooltip>}>
                    <div className='outer-div'>
                        <div className='inner-div'>
                            <span>
                                {moneyFormatter(purchaseProductQuote.unitPrice, 1, purchaseProductQuote.currency)}
                                </span>
                        </div>
                    </div>
                </OverlayTrigger>
            </td>

            <td onClick={handleClick} data-label='Subtotal' >
                <div className='outer-div'>
                    <div className='inner-div'>{moneyFormatter(purchaseProductQuote.subtotal, 1, currency)}</div>
                </div>
            </td>

            <td data-label='Descuento unitario' onClick={handleClick}>
                <div className='outer-div'>
                    <div className='inner-div'>
                        {moneyFormatter(purchaseProductQuote.unitDiscount, 1, purchaseProductQuote.currency)}
                    </div>
                </div>
            </td>

            {hasDiscount ? <td onClick={handleClick} data-label='Descuento total'>
                <div className='outer-div'>
                    <div className='inner-div'>{moneyFormatter(purchaseProductQuote.discountTotal, 1, currency)}</div>
                </div>
            </td> : null}

            {hasDiscount ? <td onClick={handleClick} data-label='Subtotal con descuento'>
                <div className='outer-div'>
                    <div
                        className='inner-div'>{moneyFormatter(purchaseProductQuote.subtotalWithDiscount, 1, currency)}</div>
                </div>
            </td> : null}

            <td data-label='Impuestos'>
                <div className='outer-div'>
                    <div className='inner-div'>
                        <PurchaseProductTaxes
                            purchaseProductTaxes={purchaseProductQuote.purchaseProductTaxes}
                            currency={currency}
                            subtotal={purchaseProductQuote.subtotalWithDiscount}
                        />
                    </div>
                </div>
            </td>

            <td onClick={handleClick} data-label='Total'>
                <div className='outer-div'>
                    <div className='inner-div'>{moneyFormatter(purchaseProductQuote.total, 1, currency)}</div>
                </div>
            </td>
            {showingAnalysis &&
            <PurchaseProductAnalysis
                purchaseProduct={purchaseProduct}
                purchaseRequisition={purchaseRequisition}
                quote={purchaseProductQuote}
                stockRequest={purchaseRequisition?.stockRequestTrigger}
                onHide={hideAnalysis}
            />}
        </tr>
    );
};

export default PurchaseProductQuoteRow;
