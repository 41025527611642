//Created by Mark Ehrlich 18.01.2017
import React, {Component} from 'react';
import {
    Nav, NavItem,
    Tab
} from 'react-bootstrap';

import Lounges from './Lounges';
import Areas from './Areas';
import Shifts from './Shifts/Shifts';
import Positions from './Positions';
import Groups from './Groups';
import Clocks from './Clocks';
//import Banks from './Banks';
import Holidays from './Holidays';
import withSecurityManager from "../../../utils/SecurityManager/withSecurityManager";
import {connect} from 'react-redux';
class CataloguesIndex extends  Component {

    constructor(props){
        super(props);

        this.onSelect = this.onSelect.bind(this);
        this.state = {selected:""};
    }

    onSelect( key ){
        this.setState({selected:key});
    }

    render(){
        return(
            <div className="container-fluid main-container">

                <Tab.Container id="nav-with-icons" defaultActiveKey="description">
                    <div>
                        <div className="nav-container">
                            <Nav bsStyle="tabs" bsClass="nav nav-icons" onSelect={this.onSelect}>
                                <NavItem eventKey="salas">
                                    <i className="fa fa-cube" /><br /> Salas
                                </NavItem>
                                <NavItem eventKey="areas">
                                    <i className="fa fa-bars" /><br /> Áreas
                                </NavItem>
                                <NavItem eventKey="puestos">
                                    <i className="fa fa-sitemap" /><br /> Puestos
                                </NavItem>
                                <NavItem eventKey="turnos">
                                    <i className="fa fa-clock-o" /><br /> Turnos
                                </NavItem>
                                <NavItem eventKey="grupos">
                                    <i className="fa fa-users" /><br /> Grupos
                                </NavItem>
                                {/*
                                <NavItem eventKey="bancos">
                                    <i className="fa fa-bank" /><br /> Bancos
                                </NavItem>
                                */}
                                <NavItem eventKey="relojes">
                                    <i className="fa fa-hand-o-up" /><br /> Relojes
                                </NavItem>

                                {this.props.securityManager?.alma.canAdminHolidays() &&
                                    <NavItem eventKey="holidays">
                                        <i className="fa fa-gift" /><br /> Días festivos
                                    </NavItem>}
                            </Nav>
                        </div>
                        <Tab.Content>
                            <Tab.Pane eventKey="description">
                                <p className="main-description"><span>Aquí puedes configurar la estructura de la empresa. <b>No</b> es para el uso cotidiano; únicamente para cambios en la organización de la empresa.</span></p>
                            </Tab.Pane>
                            <Tab.Pane eventKey="salas">
                                { this.state.selected === "salas"?<Lounges/>:null}
                            </Tab.Pane>
                            <Tab.Pane eventKey="areas">
                                { this.state.selected === "areas"?<Areas/>:null}
                            </Tab.Pane>
                            <Tab.Pane eventKey="puestos">
                                { this.state.selected === "puestos"?<Positions/>:null}
                            </Tab.Pane>
                            <Tab.Pane eventKey="turnos">
                                { this.state.selected === "turnos"?<Shifts />:null}
                            </Tab.Pane>
                            <Tab.Pane eventKey="grupos">
                                { this.state.selected === "grupos"?<Groups />:null}
                            </Tab.Pane>
                            {/*
                            <Tab.Pane eventKey="bancos">
                                { this.state.selected === "bancos"?<Banks />:null}
                            </Tab.Pane>
                            */}
                            <Tab.Pane eventKey="relojes">
                                { this.state.selected === "relojes"?<Clocks />:null}
                            </Tab.Pane>

                            {this.props.securityManager?.alma.canAdminHolidays() &&
                                <Tab.Pane eventKey="holidays">
                                    { this.state.selected === "holidays"?<Holidays />:null}
                                </Tab.Pane>}
                        </Tab.Content>
                    </div>
                </Tab.Container>

            </div>
        );
    }
}

export default connect()(withSecurityManager(CataloguesIndex));