import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import Button from "../../../../elements/CustomButton/CustomButton";
import PropTypes from 'prop-types';

export default class AddDiscountModal extends Component {

    static contextTypes = {notifier:PropTypes.object};

    constructor(props){
        super(props);

        this.state = {
            discount:"",
            comment:""
        };

        this.discountChange = this.discountChange.bind(this);
        this.discountBlur = this.discountBlur.bind(this);
        this.applyDiscount = this.applyDiscount.bind(this);
        this.commentChange = this.commentChange.bind(this);
    }

    discountChange(e){
        this.setState({discount:e.target.value});
    }

    discountBlur(e){
        let dis = Number(e.target.value);
        if( isNaN(dis) )
            dis=0;
        this.setState({discount:dis});
    }

    commentChange(e){
        this.setState({comment:e.target.value});
    }

    applyDiscount(){

        if( !this.state.discount || this.state.discount <= 0 ) {
            this.context.notifier.error("Es necesario seleccionar un descuento mayor a cero.");
            return;
        }

        if( !this.state.comment ){
            this.context.notifier.error("Es necesario indicar la razón del descuento");
            return;
        }

        this.props.applyDiscount( this.state.discount * 100, this.state.comment );
    }

    render() {

        return (
            <div>

                <Modal
                    show={true}
                    onHide={this.props.onHide}
                >
                    <Modal.Header closeButton >
                        <Modal.Title>Añadir descuento</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Se añadirá un descuento extrordinario a {getFullName( this.props.employee )}.</p>
                        <p>Indica el monto a descontar:</p>

                        <div className="input-group">
                            <span className="input-group-addon">$</span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Monto"
                                value={this.state.discount}
                                onChange={this.discountChange}
                                onBlur={this.discountBlur}
                            />
                        </div>
                        <br/>
                        <p>Describe la razón del descuento:</p>
                        <textarea
                            className="form-control"
                            value={this.state.comment}
                            onChange={this.commentChange} />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="danger" onClick={this.applyDiscount} >Descontar</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

const getFullName = (employee)=>(employee.name+" "+employee.patLastName+" "+employee.matLastName);