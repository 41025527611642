import _ from 'lodash';
import almaLogo from '../assets/img/tgle-alma.png';
import eeLogo from '../assets/img/tgle-ee.png';
import almazenLogo from '../assets/img/tgle-almazen.png';
import comprasLogo from '../assets/img/logo-compras-inverted.png';
import sysadmin from '../assets/img/sysadmin_logo.png';
import cHLogo from '../assets/img/tgle-ch.png';
import rewardsLogo from 'assets/img/tgle-rewards.png';
import katharsisLogo from '../assets/img/katharsis/tgle-Katharsis.png';
import Systems from "./Systems";

/*
the sidebarColor string is a class defined in
src/assets/sass/gle/_general.scss
 */
const all = [

    {
        name: Systems.SYSADMIN,
        logo: sysadmin,
        sidebarColor: 'whitesmoke',
    }, {
        name: Systems.ALMA,
        logo: almaLogo,
        sidebarColor: 'azure',
    },{
        name: Systems.EXPERIENCIA_ELITE,
        logo: eeLogo,
        sidebarColor: 'black',
    },{
        name: Systems.ALMAZEN,
        logo: almazenLogo,
        sidebarColor: 'red',
    },{
        name: Systems.COMPRAS,
        logo: comprasLogo,
        sidebarColor: 'greenyellow',
    },
    {
        name: Systems.CAPITAL_HUMANO,
        logo: cHLogo,
        sidebarColor: 'purple',
    },
    {
        name: Systems.KATHARSIS,
        logo: katharsisLogo,
        sidebarColor: 'lightBrown',
    },
    {
        name: Systems.REWARDS,
        logo: rewardsLogo
    }
];

export const getTheme = ( systemName )=>{

    const system = _.find( all, ( sys )=>sys.name === systemName );

    return system? system : all[0];
};

export default all;
