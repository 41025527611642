import React, {useCallback, useState} from 'react';
import Nps from  './Reports/Nps/Nps';
import CustomButton from "../../../elements/CustomButton/CustomButton";
import ClassicModal from "../../../components/Modals/ClassicModal";
import useDateRangePickerState from "../../../hooks/useDateRangePickerState";
import moment from "moment";
import {DateRangePicker} from "react-dates";
import ColLayout from "../../../components/ColLayout/ColLayout";
import HappinessReport from "./Reports/HappinessReport/HappinessReport";
import OperationalExcellence from "./Reports/OperationalExcellence/OperationalExcellence";
import loungeAccessMethodGcareStats from "./Reports/LoungeAccessMethodGcareStats/LoungeAccessMethodGcareStats";


const davinciNpsReports = [
    {
        name:'Reporte NPS',
        component: Nps
    },
    {
        name:'Reporte de Felicidad',
        component: HappinessReport
    },
    {
        name:'Excelencia operativa',
        component: OperationalExcellence
    },
    {
        name:'Método de acceso por Tarjeta',
        component: loungeAccessMethodGcareStats('card')
    },
    {
        name:'Método de acceso por Aerolínea',
        component: loungeAccessMethodGcareStats('airline')
    },
];

const DavinciReportsNps = () => {

    const [showingReport, setShowingReport]=useState(null);
    const hideReport=useCallback(()=>setShowingReport(null),[]);

    const showReport = report => setShowingReport(report);

    const {props: dateRangeProps, dateRange} = useDateRangePickerState({
        startDate: moment().startOf('month').startOf('day'),
        endDate: moment().add(1, 'day').endOf('day')
    });

    return (
        <div className="DavinciReports container-fluid">
            <h2 className='text-center'>Selecciona un reporte</h2>
            <div className='row'>
                <div className="col-xs-offset-3 col-xs-6 text-center">
                    <label>Rango de fechas:&nbsp;</label>
                    <DateRangePicker {...dateRangeProps}/>
                </div>
            </div>
            <div className='container-fluid'>

                <ColLayout
                    breakpointCols={[3, undefined, 4, 12]}
                    colClass='report-cont'
                >
                    {
                        davinciNpsReports.map((report ,i)=>{
                            return(
                                <div key={i} className='report-block'>
                                    <h4>{report.name}</h4>
                                    <CustomButton bsStyle='primary' onClick={()=>showReport(report)}>Ver ahora</CustomButton>
                                </div>

                            )
                        })
                    }
                </ColLayout>
            </div>

            {showingReport &&
            <ClassicModal
                onHide={hideReport}
                title={showingReport.name}
                showConfirm={false}
                bsSize='lg'
            >
                <showingReport.component defaultDateRange={dateRange}/>
            </ClassicModal>}

        </div>
    )
};
export default DavinciReportsNps
