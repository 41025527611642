const colors = [
	'#ff3497',
	'#ff8b4c',
	'#ffd045',
	'#dfff8a',
	'#afffda',
	'#0000FF',
	'#4682B4',
	'#F0F8FF',
	'#0047AB',
	'#1034A6',
	'#120A8F',
	'#73C2FB',
	'#002366',
	'#005588',
	'#082567'
];

export const colorHEX = (lounges) => {
	let lstOfLoungesColors = [];

	lstOfLoungesColors = lounges.sort((a, b) => ((a.name > b.name && 1) || -1)).map((lounge, index) => {
		return {
			id: lounge.id,
			color: colors[index]
		}
	});

	return lstOfLoungesColors;
}
