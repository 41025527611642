import React, {useEffect, useContext} from 'react';
import {Col, Row, Table} from "react-bootstrap";
import {ApiContext} from "../../../api/Api";
import {useSelector} from "react-redux";
import noProductImg from '../../../assets/img/no-product.png';
import {displayAmount} from "../../../utils/modelUtils/almazen/unitsUtility";
import {SecurityContext} from "../../../utils/SecurityManager";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import {NotifierContext} from "../../../utils/Notifier";
import _ from 'lodash';
import {getProductBrandPhotoUrl} from "../../../utils/modelUtils/almazen/productBrandUtils";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import {Link} from "react-router-dom";
import {paths} from "../../../routes/jazz";

const loadingId = '@ProductDetail.products.get';

/*
It can receive either product or productBrand
 */
const ProductDetail = ({product, productBrand, showConversionRules, showSmallestUnit=true, simple, editable=false, onChange}) => {

    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);
    const security = useContext(SecurityContext);

    const inventories = useSelector(s=>s.api.inventoriesInProductDetail)||[];
    const loadingIds = useSelector(s=>s.loadingIds);

    let showBrands = true;

    if(productBrand) {
        product = productBrand.product;
        showBrands=false;
    }

    useEffect(() => {

        if(simple) return;

        api.clearProperty( 'inventoriesInProductDetail' );

        const filters={};
        if(productBrand)
            filters.productBrand=productBrand.id;
        else if(product)
            filters['productBrand.product']=product.id;

        if(security.almazen.canListInventories()){
            api.inventories.get({page:0, pageSize:100, loadingId, filters, customProp:'inventoriesInProductDetail'});
        }
    }, [api, security, productBrand, product, simple]);

    const togglePBUActive=useCallbackCreator((ids)=>{
        const [pbId, pbuId]=ids.split('|');
        const productBrand = _.find(product.productBrands, pb=>pb.id+''===pbId);
        if(!productBrand)
            return notifier.error('Hubo un error al editar la presentación.');
        const productBrandUnit = _.find(productBrand.productBrandUnits, pbu=>pbu.id+''===pbuId);
        if(!productBrandUnit)
            return notifier.error('Hubo un error al editar la presentación');
        api.productBrandUnits.edit({id:productBrandUnit.id, productBrandUnit:{isActive: !productBrandUnit.isActive}})
            .then(()=>{
                if(onChange)
                    onChange();
                })

    },[api, notifier, product, productBrand]);

    return (
        <div className='ProductDetail'>
            <Row>
                <Col xs={12} sm={4}>
                    <div className='text-center'>
                        {
                            productBrand?
                                <img src={getProductBrandPhotoUrl(productBrand, api)} alt='Producto' className='img-responsive'/>:
                                <img src={ product.photo? api.appFiles.getUrl( product.photo.id ) : noProductImg} alt='Producto' className='img-responsive'/>

                        }
                        {product&&
                        <Link to={paths.productReport.replace(':id',product.id)}><CustomButton bsStyle='primary' className='report-link'>
                            Ver reporte
                        </CustomButton></Link>}
                    </div>
                </Col>
                <Col xs={12} sm={8}>
                    <div className='text-left'>
                        <Table>
                            <tbody>
                            <tr>
                                <td>ID</td>
                                <td>
                                    {product.id}
                                </td>
                            </tr>
                            <tr>
                                <td>Nombre</td>
                                <td>
                                    {product.name}
                                </td>
                            </tr>
                            <tr>
                                <td>Línea</td>
                                <td>
                                    {product.line? product.line.name:'Sin línea'}
                                </td>
                            </tr>
                            {showSmallestUnit&&
                                <>
                            <tr>
                                <td>Unidad más pequeña</td>
                                <td>
                                    {product.smallestUnit ? product.smallestUnit.name : 'Sin unidad más pequeña'}
                                </td>
                            </tr>
                            <tr>
                                <td>Unidad para mostrar</td>
                                <td>
                                    {product.displayUnit ? product.displayUnit.name : 'Sin unidad'}
                                </td>
                            </tr>
                            </>}
                            {!simple &&
                            <>
                                <tr>
                                    <td>Aprobado por</td>
                                    <td>
                                        {product.approvedBy ? employeeFullName(product.approvedBy) : 'Sin aprobación registrada'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Archivos de aprobación</td>
                                    <td>
                                        {product.approvalEvidenceFiles && product.approvalEvidenceFiles.length ?
                                            product.approvalEvidenceFiles.map((file) =>
                                                <div key={file.id}>
                                                    <a href={api.createFileUrl(file)} target='_blank'
                                                       rel="noopener noreferrer">{file.originalName}</a>
                                                </div>)
                                            :
                                            'Sin archivos'
                                        }
                                    </td>
                                </tr>
                            </>
                            }
                            </tbody>
                        </Table>

                        { showConversionRules && product.conversionRules && product.conversionRules.length ?
                            <>
                                <h5>Unidades de medida</h5>
                                <Table>
                                    <tbody>
                                    { product.conversionRules.map( cr =>
                                        <tr key={cr.id}>
                                            <td>{cr.unit.name}</td>
                                            <td>= {Number(cr.multiplier)} {product.smallestUnit ? product.smallestUnit.name : 'Unidades'}</td>
                                        </tr>
                                    ) }
                                    </tbody>
                                </Table>
                            </>
                            :null
                        }

                    </div>
                </Col>
            </Row>
            {showBrands?
            <Row>
                <hr/>
                <Col xs={12}>
                    <h5>Marcas y presentaciones</h5>

                    <div className='container-fluid'>
                        <Row>
                            {product.productBrands && product.productBrands.length?
                                product.productBrands.map(pb=>pb.productBrandUnits.map(pbu=>
                                    <Col xs={12} md={6} key={pbu.id} >
                                        <div className={'product-brand-unit '+(!pbu.isActive?'inactive':'')}>
                                            {pb.brand.name} - {pbu.unit.name}<br/>
                                            <span className='barcode'>{pbu.barcode?('Código - '+pbu.barcode):'Sin código'}</span>

                                            { editable && security.almazen.canEditProducts()&&
                                            <button
                                                onClick={togglePBUActive(pb.id+'|'+pbu.id)}
                                                className={'act-btn ' + (pbu.isActive ? 'deactivate' : '')}>
                                                {pbu.isActive ? 'Desactivar' : 'Activar'}
                                            </button>}

                                        </div>
                                    </Col>
                                ))
                                :
                                'Sin marca asignada'
                            }
                        </Row>
                    </div>
                </Col>
            </Row>
                :null}
            <Row>
                <Col xs={12}>
                    <hr/>
                    <h5>Descripción</h5>
                    <div className='description-box'>
                        <p>{product.description? product.description:'Sin descripción'}</p>
                    </div>
                </Col>
            </Row>
            <hr/>
            {!simple && security.almazen.canListInventories() &&
            <Row>
                <Col xs={12}>
                    {inventories.length > 0 ?
                        <>
                            <h5>Inventarios</h5>
                            <Table>
                                <tbody>
                                <tr>
                                    <th>Almacén/Sala</th>
                                    <th>Marca</th>
                                    <th>Cantidad</th>
                                </tr>
                                {inventories.map((inventory) => (
                                    <tr key={inventory.id}>
                                        <td>{inventory.warehouse.name}</td>
                                        <td>{inventory.productBrand?.brand?.name}</td>
                                        <td>{displayAmount(inventory.stockAmount, productBrand? productBrand.product : product)}</td>
                                    </tr>
                                ))}

                                </tbody>
                            </Table>
                        </> :
                        (
                            security.almazen.canListInventories() && loadingIds[loadingId] ?
                                <h5>Cargando...</h5> :
                                <h5>Sin inventarios</h5>
                        )
                    }
                </Col>
            </Row>
            }

        </div>

    );
};

export default ProductDetail;
