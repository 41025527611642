import React,{useRef, useCallback, useState} from 'react';
import {createPortal} from 'react-dom';
import {DayPicker} from "react-dates";
import classNames from 'classnames';
import {getCoords} from "../../utils/domUtils";
import useBoolean from "../../hooks/useBoolean";


const CalendarPopUp=({children, className, onChange})=>{

    const [isOpen,open, close]=useBoolean();
    const [position,setPosition]=useState({left:0, top:0});
    const originalNodeRef=useRef(null);

    const toggle=useCallback(()=>{

        if(isOpen)
            return close;

        const node=originalNodeRef.current;
        setPosition(getCoords(node, 'br'));

        open();

    },[isOpen, close, open]);

    const handleSelect=useCallback((data)=>{
        close();
        onChange(data);
    }, [onChange, close]);

    return (
        <>
            <div onClick={toggle} className={classNames('CalendarPopUp', className)} ref={originalNodeRef}>
                {children}
            </div>
            {isOpen&&createPortal(
                <>
                    <div className='CalendarPopUp-close-overlay' onClick={close}/>
                    <div className='CalendarPopUp-container' style={position}>
                        <DayPicker
                            numberOfMonths={1}
                            onDayClick={handleSelect}
                        />
                    </div>
                </>
                , document.body)}
        </>
    );
};

export default CalendarPopUp;