import './FlightsTable.scss';
import React, {useContext, useEffect, useMemo, useState, useRef, useCallback} from 'react';
import { isArray } from 'lodash';
import ReactTable from 'react-table';
import { ApiContext } from 'api/Api';
import { useSelector } from 'react-redux';
import useDebounce from 'hooks/useDebounce';
import ReactTableTranslation from 'utils/ReactTableTranslation';
import {columnsConfig, sGroups} from './utils/table.config';
import {Button} from "react-bootstrap";

const FlightsTable = React.memo(({unshiftColumns, pushColumns, onlyColumns, topLeftContent = null, customProp = "@FlightsTable.Store", requestFilters, disableTimeFilters = false, onContentLoad}) => {
    //Initializers
    const api = useContext(ApiContext);
    const requestFiltersRef = useRef(requestFilters);
    const columns = useMemo(() => {
        let result = [...columnsConfig];

        if(onlyColumns)
            //Header prop is in file "./utils/table.config.js" and means the title of the table column:
            result = result.filter( ({Header}) => onlyColumns.includes(Header) );

        if(isArray(unshiftColumns))
            result = [...unshiftColumns, ...result];
        else if(typeof unshiftColumns === 'object')
            result = [unshiftColumns, ...result];

        if(isArray(pushColumns))
            result = [...result, ...pushColumns];
        else if(typeof pushColumns === 'object')
            result = [...result, pushColumns];

        return result;
    }, [unshiftColumns, pushColumns, onlyColumns]);

    //State
    const [searchTerm, setSearchTerm] = useState("");
    const [showInactiveFlights, setShowInactiveFlights] = useState(true);

    //Helpers
    const searchTermDebounced = useDebounce(searchTerm, 1000);

    //Retrieving current flights
    useEffect(() => {
        const {current:requestFilters} = requestFiltersRef;

        let params = {
            sGroups,
            pagination: false,
            'isActive': showInactiveFlights
        };

        if(!disableTimeFilters) {
            params = {
                ...params
            }
        }

        if(requestFilters) params = {...params, ...requestFilters};

        if(searchTermDebounced)
            params.search = searchTermDebounced;
        else if (params?.search)
            delete params.search;

        const payload = {params, customProp};
        api.flights.get(payload).then( data => (data) && onContentLoad && onContentLoad(data) );
    }, [api, showInactiveFlights, searchTermDebounced, customProp, disableTimeFilters, onContentLoad]);

    //Redux Store
    const currentFlights = useSelector(({api}) => api[customProp] || []);

    //Events
    const onSearch = ({target: {value}}) => setSearchTerm(value);

    //Display helper
    const isInactiveClass=useCallback((table, row)=>
            ({className:row?.original?.isActive ===false?'inactive':''})
    ,[]);

    //Filter isActive Handler
    const flightsInactiveHandler = () => {
        if (showInactiveFlights === true) {
            setShowInactiveFlights(null);
        } else {
            setShowInactiveFlights(true);
        }
    };

    return (
        <div className="FlightsTable">
            <div className="current-flights">
                {topLeftContent}
                <div className="flight-filters">
                    <input className="searchInput" value={searchTerm} onChange={onSearch} placeholder="Buscar por: Vuelo, aerolínea, destino, puerta, terminal."/>
                </div>
                <Button onClick={flightsInactiveHandler}>{showInactiveFlights ? 'Mostrar Inactivos' : 'Ocultar Inactivos'}</Button>
            </div>
            <ReactTable
                defaultPageSize={10}
                columns={columns}
                data={currentFlights}
                getTrProps={isInactiveClass}
                {...ReactTableTranslation}
            />
        </div>
    );
});
export default FlightsTable;
