import React, {useCallback, useState, useLayoutEffect, useRef, useMemo} from 'react';
import './_RadioSelector.scss';
import _ from 'lodash';
import classNames from 'classnames';


const RadioSelector = ({options=[], value, onChange})=>{

    const handleChange = useCallback(e=>onChange(e.target.value), [onChange]);

    const id=useMemo(()=>Math.random(),[]);

    // -------   Make all the buttons the same width so they look pretty
    const containerRef = useRef(null);
    const [minWidth, setMinWidth] = useState({});
    useLayoutEffect(()=>{
        if(!containerRef.current) return;
        const largerButton = _.chain(containerRef.current.childNodes).map('offsetWidth').max().value();
        if(largerButton)
            setMinWidth( {minWidth:largerButton} );

    },[options]);

    return (
        <div className='RadioSelector' ref={containerRef}>
            {options.map((opt, i)=>
                <div key={i} className={classNames('option-container', opt.value === value && 'selected')} style={minWidth}>
                    <input
                    type='radio'
                    checked={opt.value === value}
                    onChange={handleChange}
                    id={'RadioSelector'+id+opt.value}
                    value={opt.value}
                    />
                    <label htmlFor={'RadioSelector'+id+opt.value}>{opt.label}</label>
                </div>
            )}
        </div>
    );
};

export default RadioSelector;
