import React, {Component} from 'react';
import ReactTable from 'react-table';
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import PropTypes from "prop-types";
import moment from 'moment';
import {connect} from 'react-redux';
import _ from 'lodash';
import Switch from 'react-bootstrap-switch';
import ReservationDetailModal from './ReservationDetailModal';
import ReservationFormModal from './ReservationFormModal';
import Button from "../../../elements/CustomButton/CustomButton";
import ReservationStatusDropdown from './ReservationStatusDropdown';
import ReservationLoungeDropdown from "./ReservationLoungeDropdown";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import {sourceStrings} from "../../../utils/modelUtils/davinci/reservationUtils";


class ReservationsList extends Component {

    static contextTypes = {api: PropTypes.object};

    state = {
        showFilters: false,
        showOlder: false,
    };

    loadingId = '@Class.ReservationsList.reservations.get';

    componentDidMount() {

        this.interval = setInterval(() => {
            this.loadReservations(this.tableState);
        }, 300000);

        this.context.api.lounges.get(undefined, {isLounge: true}, 'reservationDropdownLounges');
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    loadReservations = (tableState) => {

        this.tableState = tableState;

        let sort = {};
        if (tableState.sorted.length)
            sort = tableState.sorted.reduce((acc, val) => {
                acc[`order[${val.id}]`] = val.desc ? "DESC" : "ASC";
                return acc;
            }, {});
        else
            sort = {'order[reservationDate]': this.state.showOlder ? 'DESC' : 'ASC'};

        const filter = tableState.filtered.reduce((acc, val) => {
            acc[val.id] = val.value;
            return acc;
        }, {});

        if (!this.state.showOlder)
            filter['reservationDate[after]'] = moment().subtract(2, 'hours').format('YYYY-MM-DDTHH:mm');

        this.context.api.reservations.get(tableState.page, tableState.pageSize, this.loadingId, {...sort, ...filter});
    };

    toggleOlder = (c, value) => {

        this.setState({showOlder: value}, () => {
            this.loadReservations(this.tableState);
        })
    };

    toggleFilter = () => {
        this.setState({showFilters: !this.state.showFilters});
    };

    showDetail = (state, rowInfo) => {
        return {onClick: () => this.setState({showingDetail: rowInfo?.original})}
    };

    editDetail = () => {
        this.setState({showingDetail: false, editingReservation: this.state.showingDetail})
    };

    hideDetail = () => {
        this.setState({showingDetail: false})
    };

    newReservation = () => {
        this.setState({addingReservation: true})
    };

    hideReservationForm = () => {
        this.setState({addingReservation: false, editingReservation: false})
    };

    render() {

        const {totalItems, itemsPerPage} = this.props.reservationsMeta;
        const plural = totalItems > 1 ? "s" : "";

        return (
            <div className="container-fluid main-container ReservationsList">

                <Button onClick={this.newReservation} bsStyle='primary' className='col-xs-12'>Agregar
                    reservación</Button>

                <div className={'col-xs-12 ' + (this.state.showFilters ? 'configuration' : '')}>
                    <h5><span className='link' onClick={this.toggleFilter}><i className='fa fa-cogs'/> Filtros</span>
                    </h5>
                    {this.state.showFilters ?
                        <div className='row'>
                            <div className='col-md-3 filter-wrapper'>
                                <div className='filter-block'>
                                    <Switch value={this.state.showOlder} onChange={this.toggleOlder}/>&nbsp;
                                    Mostrar reservaciones anteriores
                                </div>
                            </div>
                        </div>
                        : null}
                </div>

                <div className='clearfix'/>

                <ReactTable
                    data={this.props.reservations}
                    columns={tableStructure}
                    defaultPageSize={10}
                    pages={Math.ceil(totalItems / itemsPerPage)}
                    className="-striped -highlight"
                    loading={!!this.props.loadingIds[this.loadingId]}
                    manual
                    onFetchData={_.debounce(this.loadReservations, 600)}
                    getTdProps={this.showDetail}
                    minRows={3}
                    filterable
                    {...ReactTableTranslations}
                />
                <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>


                {this.state.showingDetail ?
                    <ReservationDetailModal
                        reservation={this.state.showingDetail}
                        onHide={this.hideDetail}
                        onEdit={this.editDetail}
                    />
                    : null
                }

                {this.state.addingReservation || this.state.editingReservation ?
                    <ReservationFormModal
                        onHide={this.hideReservationForm}
                        reservation={this.state.editingReservation}
                    />
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = ({
                             api: {
                                 reservations = [], reservationsMeta = {
                                     totalItems: 0,
                                     itemsPerPage: 0
                                 }
                             }, loadingIds
                         }) => ({reservations, reservationsMeta, loadingIds});

export default connect(mapStateToProps)(ReservationsList);


export const statusStrings = (str) => {
    switch (str) {

        case 'requested':
            return 'Solicitada';
        case 'completed':
            return 'Completada';
        case 'canceled':
            return 'Cancelada';
        default:
            return str;

    }
};


const tableStructure = [{
    Header: 'Nombre',
    id: 'search',
    accessor: (reservation) => reservation.guest ? employeeFullName(reservation.guest) : `${reservation.name} ${reservation.lastNames}`
},
    {

        Header: 'Tipo de acceso',
        id: 'loungeAccessMethods.name',
        accessor: (reservation) => {
            if (reservation.loungeAccessMethods.length) {
                const names = _.map(reservation.loungeAccessMethods, 'name');
                return names.join(',');
            } else {
                return reservation.accessMethod;
            }
        }
    },
    {
        Header: 'Fecha',
        id: 'reservationDate',
        accessor: (reservation) => moment(reservation.reservationDate).format('DD/MM/YYYY HH:mm'),
        filterable: false
    }, {
        Header: 'Personas',
        accessor: 'personsNumber',
        filterable: false,
        width: 90
    }, {
        Header: 'Terminal',
        accessor: 'terminal',
        filterable: false,
        width: 90
    }, {
        Header: 'Sala',
        id: 'lounge',
        accessor: (res) => <ReservationLoungeDropdown reservation={res}/>
    }, {
        Header: 'Estado',
        id: 'status',
        accessor: (res) => <ReservationStatusDropdown reservation={res}/>
    }, {
        Header: 'Notas',
        accessor: 'notes'
    }, {
        Header: 'Forma de reserva',
        id: 'source',
        accessor: (res) => sourceStrings(res.source)
    },];
