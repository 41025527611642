import React from 'react';
import _ from "lodash";
import {fileClassification} from "../../../../utils/xFilesUtils";

export default ({files, classification, removeFile, userPickedClassification}) => {
    const otherOption = {
        key: 'other',
        name: 'Otro'
    };
    const keyToOption = key => _.find(fileClassification, {key}) || otherOption;
    const categorized = _.zip(files, classification);
    const optionsFor = current => {
        return fileClassification.filter(
            c => !_.includes(classification, c.key))
            .concat([keyToOption(current)])
            .concat(current !== 'other' ? [keyToOption('other')]:[]);
    };

    if (files.length !== classification.length) {
        return null;
    }
    return (
        <table className='table table-striped'>
            <thead>
            <tr>
                <th>Nombre del archivo</th>
                <th>Tipo de archivo</th>
            </tr>
            </thead>
            <tbody>
            {categorized.map(([file, classification], k) => (
                    <tr key={k}>
                        <td> {file.name} </td>
                        <td>
                            <select className='form form-control' onChange={userPickedClassification(k)} value={classification}>
                                {optionsFor(classification).map(o =>
                                    <option value={o.key} key={o.key}>{o.name}</option>
                                )}
                            </select>
                        </td>
                        <td>
                            <p className='open-modal' onClick={removeFile(k)}>&times; </p>
                        </td>
                    </tr>
                )
            )}
            </tbody>
        </table>
    )
};
