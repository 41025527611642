
const colors = [
"#FF0000",
"#FF2300",
"#FF4600",
"#FF6900",
"#FF8C00",
"#FFAF00",
"#FFD300",
"#FFF600",
"#F7FF00",
"#E5FF00",
"#C2FF00",
"#B0FF00",
"#8DFF00",
"#7CFF00",
"#58FF00",
"#47FF00",
"#35FF00",
"#24FF00",
"#12FF00",
"#00FF00"
    ];

export default function ( score ) {

    if( score >= 100 )
        return colors[ colors.length - 1 ];

    if( score <= 80 )
        return colors[0];


    return colors[ score - 80 ];
}
