import React, {useEffect, useState, useMemo} from 'react';
import {connect} from 'react-redux';
import useTideEntity from "../../../../hooks/useTideEntity";
import {useDropzone} from 'react-dropzone';
import ClassifyNewXFiles from "./ClassifyNewXFiles";
import _ from 'lodash';
import {fileClassification} from "../../../../utils/xFilesUtils";
import XFileAdmin from "./XFileAdmin";
import {useUploadXFile} from "../../../../utils/xFilesUtils/xFilesReact";
import {NavLink} from "react-router-dom";
import {loadingId} from "../../../../utils/xFilesUtils/xFilesReact";
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";

const matchTagsWithFile = (previouslyClassified, file) => {
    const notClassified = ({key}) => !_.includes(previouslyClassified, key);
    const classificationCandidates = fileClassification.filter(notClassified);
    const hasTag = tag => _.includes(file.name.toLowerCase(), tag.toLowerCase());
    const hasAnyTag = ({tags}) => tags.reduce((included, tag) => included || hasTag(tag), false);
    const match = _.find(classificationCandidates, hasAnyTag);

    return match ? match.key : 'other';
};

const classifyFiles = (files, setClassification, userClassification) => () => {
    const newClassification = files.reduce((classified, f, index) => {
        const userClassified = _.find(userClassification,
            ({index: uIndex}) => uIndex === index
        );
        if (userClassified) {
            return [...classified, userClassified.value];
        }
        const matched = matchTagsWithFile(classified, f);
        return [...classified, matched];
    }, []);
    setClassification(newClassification);
};

const EmployeeXFiles = ({match: {params: {id}}, loadingIds}) => {
    const apiConfig = {
        getMethod: 'get2',
        requestFilters: {
            sGroups: ['employee_simplified', 'employee_x_file_read', 'app_file_read']
        }
    };
    const [employee, loading, notFound, {reload}] = useTideEntity('employees', id, apiConfig);
    const [files, setFiles] = useState([]);
    const [classification, setClassification] = useState([]);
    const [userClassification, setUserClassification] = useState([]);
    useEffect(classifyFiles(files, setClassification, userClassification), [files, userClassification]);
    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragActive,
        isDragReject
    } = useDropzone({onDrop: f => setFiles(files.concat(f))});
    const style = useMemo(() => ({
        ...dropzoneStyle,
        ...(isDragReject ? {borderColor: '#ff1744'} : {}),
        ...(isDragAccept ? {borderColor: '#00e676'} : {}),
        ...(isDragActive ? {borderColor: '#2196f3'} : {}),
    }), [isDragReject, isDragActive, isDragAccept]);
    const {uploadXFile, removeXFile, removeOtherFile} = useUploadXFile();

    if (loading || !employee) {
        if(notFound){
            return <p>Empleado no encontrado <NavLink to={'/alma/employees/xfiles'}>Volver a la lista de expedientes</NavLink></p>;
        }
        return null;
    }

    const resetFiles = () => {
        setFiles([]);
    };

    const uploadFiles = async () => {
        await uploadXFile(employee, _.zip(files, classification));
        resetFiles();
        reload();
    };

    const removeFile = k => () => {
        setFiles(files.filter((f, i) => i !== k));
        setClassification(classification.filter((f, i) => i !== k));
        setUserClassification(userClassification.filter(({index}) => index !== k));
    };
    const userPickedClassification = index => evt => {
        setUserClassification(userClassification
            .filter(({index: uIndex}) => uIndex !== index)
            .concat([{
                index,
                value: evt.target.value
            }]));
    };

    const onDelete = classification => async ()=>{
        await removeXFile(employee, classification);
        reload();
    };

    const deleteOtherFile = (other, toDelete) => async () => {
        await removeOtherFile(employee, other, toDelete);
        const otherFiles = other
            .filter((o, k)=>k!==toDelete)
            .map(o=>o.id);
        await uploadXFile(employee, [[otherFiles, 'other']]);
        reload();
    };

    return (
        <div className='XFile container-fluid'>
            <h4>Expediente de {`${employeeFullName(employee)}`}</h4>
            <XFileAdmin employee={employee} deleteOtherFile={deleteOtherFile} xFile={employee.employeeXFile || {}} onDelete={onDelete}/>
            {
                files.length > 0 &&
                <ClassifyNewXFiles userPickedClassification={userPickedClassification} removeFile={removeFile}
                                   classification={classification} files={files}/>
            }
            <div {...getRootProps({style})}>
                <input {...getInputProps()}/>
                {files.length === 0 ?
                    <p> Da click o arrastra los archivos de {employeeFullName(employee)} </p> :
                    <p> Da click o arrastra más archivos </p>
                }
            </div>
            {(files.length > 0  && !loadingIds[loadingId]) &&
                <div>
                    <button className='btn btn-success' onClick={uploadFiles}>Guardar cambios</button>
                </div>
            }

        </div>
    );
};

export default connect(({loadingIds})=>({loadingIds}))(EmployeeXFiles);

const dropzoneStyle = {
    cursor: 'grab',
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: '#eeeeee',
    backgroundColor: '#fafafa',
    padding: '60px',
    color: '#bdbdbd',
    outline: 'none',
    borderRadius: 2,
    alignItems: 'center',
    textAlign: 'center'
};

