import React from 'react';
import Chat from '../Chat/Chat';


const GuestChat = ({chat})=>{

    return <Chat chat={chat}/>;
};

export default GuestChat;
