
import React, { useContext, useEffect, useMemo } from 'react'
import {ApiContext} from "../../../../api/Api";
import ReactTable from "react-table";
import useTideTable from "../../../../hooks/table/useTideTable";

export const EeGcareByHourReport = ({dateRange}) => {
    const api = useContext(ApiContext);
    
    const params=useMemo(()=>({
        from: dateRange?.startDate?.format('YYYY-MM-DD'),
        to: dateRange?.endDate?.format('YYYY-MM-DD'),
    }), [dateRange]);

    useEffect(()=>{
        api.gcareByHourReport.get(params);
    }, [api, params]);

    const table = useTideTable({
        entity:'gcareByHourReport',
        newTideApi: true,
        columns: [
            {Header:'Día', accessor:'fq_day', filterable: false,},
            {Header:'Hora', accessor:'fq_hour', filterable: false,},
            {Header:'Contestados', accessor:'fq_count', filterable: false,}
        ],
        requestFilters: params
    });

    return (
        <div className="EeGcareByHourReport">
            <ReactTable
                {...table.tableProps}
            />
        </div>
    )
}
