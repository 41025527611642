import React from 'react';

const AccessMethodStatsTable = ({data, title}) => {
    return (
        <>
            <h3>{title||'Entradas por método de acceso'}</h3>
            <table>
                <thead>
                    <tr>
                        <th>Método de acceso</th>
                        <th>Entradas</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(({loungeAccessMethod: {id, name}, entries})=>
                              <tr key={id}>
                                  <th className='text-center'>
                                      {name}
                                  </th>
                                  <td className='text-right'>
                                      {entries}
                                  </td>
                              </tr>
                             )}
                </tbody>
            </table>
        </>
    );
};

export default AccessMethodStatsTable;
