import React, {useContext, useState, useMemo} from 'react';
import useBoolean from "../../../../hooks/useBoolean";
import classNames from 'classnames';
import {DateRangePicker} from "react-dates";
import useDateRangePickerState from "../../../../hooks/useDateRangePickerState";
import TideEntitySelect from "../../../../components/TideEntitySelect/TideEntitySelect";
import {NotifierContext} from "../../../../utils/Notifier";
import {ApiContext} from "../../../../api/Api";

const OrozcoProductCostReport = () => {

    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);

    const [isOpen, , , toggleOpen] = useBoolean();

    const {props, dateRange} = useDateRangePickerState();
    const [lounge, setLounge] = useState();

    const handleClick = (e) => {
        if (!lounge || !dateRange.endDate || !dateRange.startDate) {
            e.preventDefault();
            notifier.error('Llena todos los filtros');
        }
    };

    const reportUrl = useMemo(() => {
        return api.orozcoProducts.exportOrdersReport({params:{
            from: dateRange?.startDate?.format('YYYY-MM-DD'),
            to: dateRange?.endDate?.format('YYYY-MM-DD'),
            lounge: lounge?.id
        }});
    }, [dateRange, lounge, api]);

    return (
        <div className={classNames("report-block", isOpen && 'open')}>
            <p className='title' onClick={toggleOpen}>Consumos de platillos<i className='fa fa-caret-down caret-icon'/>
            </p>
            {isOpen &&
            <div className='body'>
                <hr/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <p className='filter-label'>Sala:</p>
                            <TideEntitySelect
                                entity='lounges'
                                value={lounge}
                                onChange={setLounge}
                                additionalFilters={{isLounge: true}}
                            />
                            <p className='filter-label'>Rango de fechas:</p>
                            <DateRangePicker {...props} />
                        </div>
                        <div className='col-md-6'>
                            <p className='filter-label'>Descripción:</p>
                            <p className='description'>Lista el consumo de platillos en la sala seleccionada. Muestra el
                                costo de cada platillo..</p>
                        </div>
                    </div>
                </div>
                <br/>
                <a href={reportUrl} rel='noopener noreferrer' onClick={handleClick}>
                    <button className='btn btn-info'>Descargar</button>
                </a>

            </div>}
        </div>
    );
};

export default OrozcoProductCostReport;
