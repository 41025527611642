import React, { Component, createRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoaderIcon from "../../../components/Helper/LoaderIcon";
import moment from 'moment';
import _ from 'lodash';


class ScrollableStats extends Component{

    static contextTypes = { api: PropTypes.object };

    constructor( props ) {
        super(props);

        this.state = {
            answers: [],
            hasMoreAnswers: true,
            filters: props.filters,
            page: 0,
            onScreen: false
        };

        this.loadingId = '@Func.ScrollableStats.answers.get.' + Math.random();
        this.domContainer = createRef();

    }

    componentDidMount() {
        window.addEventListener( 'scroll', this.scrollHandler );
    }

    componentWillUnmount() {
        window.removeEventListener( 'scroll', this.scrollHandler );
    }

    scrollHandler = _.throttle( ()=>{

        if( !this.domContainer.current ) return;

        const offset = this.domContainer.current.getBoundingClientRect().top;

        if( offset > -300 && offset < window.innerHeight + 300 )
            this.setState( { onScreen: true } );
        else if( this.state.onScreen )
            this.setState( { onScreen: false } );

    }, 650);

    loadMore = ()=>{

        this.setState( { hasMoreAnswers: false } );

        let pageSize = 15;
        if( this.props.result.type === 'files' )
            pageSize = 5;

        //The promises may resolve in any order if we trigger more than one. So we want to know which one is the latest
        //to know if the pagination information i.e. is the last page, should be persisted
        this.latestLoadGlobal = Math.random();
        const latestLoadLocal = this.latestLoadGlobal;
        this.context.api.answers.getWithQuestionsFilter(this.state.page , pageSize, { question: this.props.result.questionId, 'order[createdDate]':'DESC', ...this.props.filters }, this.loadingId )
            .then( ( answers )=>{
                if( answers.length && this.latestLoadGlobal === latestLoadLocal )
                    this.setState( { answers: [ ...this.state.answers, ...answers ],  hasMoreAnswers: answers.length === pageSize, page: this.state.page+1 } );
            });
    };

    static getDerivedStateFromProps = (nextProps, prevState)=>{

        if( nextProps.filters !== prevState.filters )
            return { filters:nextProps.filters, answers:[], hasMoreAnswers: true, page:0 };
        return null;
    };

    render() {

        return (
            <div className='ScrollableStats' ref={this.domContainer}>

                { !this.props.loadingIds[ this.loadingId ] && !this.state.answers.length?
                    <p>Sin información.</p>
                    :null
                }

                <InfiniteScroll
                    loadMore={this.loadMore}
                    hasMore={ this.state.hasMoreAnswers && this.state.onScreen }
                    loader={<div className="loader" key={0}> <LoaderIcon /> Cargando más ...</div>}
                    useWindow={false}
                    initialLoad={true}
                >
                    { this.state.answers.map((answer) =>{
                        switch ( this.props.result.type ) {
                            case 'file':
                                return renderFileElement( answer, this.context.api.appFiles.getUrl );
                            case 'text':
                                return renderTextElement( answer );
                            default:
                                return renderLongTextElement( answer );
                        }
                    })}

                </InfiniteScroll>
            </div>
        );
    }
}

const mapStateToProps = ({api, loadingIds})=>({api, loadingIds});

export default connect(mapStateToProps)(ScrollableStats);

const renderFileElement = ( answer, urlCreator )=>(
    <div className='file-element' key={answer.id}>
        <p className='text-muted small'>{ moment(answer.createdDate).format('DD/MM/YYYY HH:mm') } </p>

        { answer && answer.data && answer.data.response?
            answer.data.response.map( ( fileId )=> <img key={fileId} src={ urlCreator(fileId) } alt='Foto' /> )
            :'Información inválida'
        }

    </div>
);

const renderTextElement = ( answer )=>(
    <p className='text-element' key={answer.id}>
        <span className='text-muted small'>{ moment(answer.createdDate).format('DD/MM/YYYY HH:mm') }: </span>
        { answer && answer.data && answer.data.response?
            answer.data.response:
            'Información inválida'
        }
    </p>
    );

const renderLongTextElement = ( answer )=>(
    <p className='text-element' key={answer.id}>
        <span className='text-muted small'>{ moment(answer.createdDate).format('DD/MM/YYYY HH:mm') }</span><br/>
        { answer && answer.data && answer.data.response?
            answer.data.response:
            'Información inválida'
        }
    </p>
    );
