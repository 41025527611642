import React, {useEffect, useContext, useState} from 'react';
import {ApiContext} from '../../api/Api';
import {useSelector} from 'react-redux';
import ReactTable from 'react-table';
import _ from 'lodash/fp';
import {defaultProps as defaultTableProps} from '../../utils/reactTableUtils';

const matchToPositions = (positions, refillingRules)=>{
    const ruleFor = p=>_.find(r=>r.position.id===p.id, refillingRules);
    const addRule = p => ({...p, refillingPointsRule: ruleFor(p)});
    return _.map(addRule, positions);
};

const PointsCell = ({original})=>{
    const api = useContext(ApiContext);
    const {refillingPointsRule, id} = original;
    const hasError = !refillingPointsRule;
    const [value, setValue] = useState(refillingPointsRule?refillingPointsRule.points:0);
    useEffect(()=>{
        if(refillingPointsRule){
            setValue(refillingPointsRule.points);
            return;
        }
        setValue(0);
    }, [refillingPointsRule]);
    const saveRule = ()=>{
        const data = {
            position: id,
            points: value||0
        };
        if(hasError){
            api.refillingPointsRules.create({
                data
            });
        }
        else{
            api.refillingPointsRules.edit({
                data,
                id: refillingPointsRule.id
            });
        }
    };
    return (
        <div className={"form-group"+(hasError?" has-error":"")}>
            <input onBlur={saveRule}
                   className="form-control"
                   type="number"
                   min={0}
                   onChange={e=>setValue(parseInt(e.target.value)||"")}
                value={value} />
        </div>
    );
};

const likeFilter = (filter, row, column)=>{
    const toFind = filter.value.toLowerCase();
    const candidate = row.name.toLowerCase();
    return _.includes(toFind, candidate);
};

const columns = [
    {
        Header: 'Puesto',
        accessor: 'name',
        filterMethod: likeFilter
    },
    {
        Header: 'Puntos',
        Cell: PointsCell,
        accessor: 'refillingPointsRule.points',
        filterable: false
    }
];

const PointsAssignment = ()=>{
    const api = useContext(ApiContext);
    const [loading, setLoading] = useState(false);
    const positions = useSelector(({api: {positions=[]}})=>positions);
    const refillingRules = useSelector(({api:{refillingPointsRules}})=>refillingPointsRules);
    useEffect(()=>{
        setLoading(true);
        Promise.all([
            api.positions.get({}, '@PointsAssignment'),
            api.refillingPointsRules.get()
        ]).then(()=>setLoading(false));
    }, [api]);
    const data = matchToPositions(positions, refillingRules);

    return (
        <div className='Rewards admin'>
            <ReactTable
                loading={loading}
                data={data}
                columns={columns}
                {...defaultTableProps}
                defaultPageSize={20}
                manual={false}
                />
        </div>
    );
};

export default PointsAssignment;
