import Almazen from "../views/almazen/Almazen";
import ProductList from "../views/almazen/products/ProductList";
import ServiceList from "../views/almazen/services/ServiceList";
import WarehousesList from "../views/almazen/warehouses/WarehousesList";
import InventoriesList from "../views/almazen/warehouses/InventoriesList";
import InventoryMovementsHistory from "../views/almazen/warehouses/components/InventoryMovementsHistory";
import InventoryMode from "../views/almazen/warehouses/InventoryMode";
import StockMovements from "../views/almazen/movements/StockMovements";
import ShrinkageMovements from "../views/almazen/movements/AddShrinkageMovement";
import ProductRequisitionList from "../views/almazen/requisitions/ProductRequisitionList";
import ProductLineList from "../views/almazen/catalogues/ProductLineList";
import MeasurementUnitsList from "../views/almazen/catalogues/MeasurementUnitsList";
import MeasurementConversionTemplatesList from "../views/almazen/catalogues/MeasurementConversionTemplatesList";
import StockRequestApprovalMatrixConfigurator from "../views/almazen/admin/StockRequestApprovalMatrixConfigurator";
import ReceiveProductRequisition from "../views/almazen/requisitions/ReceiveProductRequisition";
import ProductRequisitionDetail from "../views/almazen/requisitions/ProductRequisitionDetail";
import WarehouseCountList from "../views/almazen/warehouseCount/WarehouseCountList";
import WarehouseCountDetail from "../views/almazen/warehouseCount/WarehouseCountDetail";
import WarehousesConfiguration from "../views/almazen/WarehousesConfiguration/WarehousesConfiguration";
import ShrinkageList from "../views/almazen/ShrinkageList/ShrinkageList";
import ImportInventoryChecker from "../views/almazen/warehouses/ImportInventoryChecker";
import WarehouseReports from "../views/almazen/WarehouseReports/WarehouseReports";
import PeriodicStockRequestsAdmin from "../views/almazen/PeriodicStockRequestsAdmin/PeriodicStockRequestsAdmin";
import Help from "../views/almazen/Help/Help";

export const paths = {
    inventory:"/almazen/inventory/:id",
    inventoryMovements:"/almazen/inventory-movements-history/:warehouse",
    configuration: "/almazen/configuration",
    reports: "/almazen/reports",
    stockRequestDetail: "/almazen/product-requisition/:id",
    almazenHelp: '/almazen/help'
};

export default (securityManager)=>{

    const routes=[];
    routes.push({ path: "/almazen/almazen", name: "ALMA-ZEN", icon: "pe-7s-note2", component: Almazen, hidden: true });

    if(!securityManager) return routes;

    if(securityManager.almazen.canEditProducts()){
        routes.push( { path: "/almazen/products", name: "Productos", icon: "fa fa-cubes", component: ProductList });
        routes.push( { path: "/almazen/services", name: "Servicios", icon: "fa fa-suitcase", component: ServiceList });
    }

    if(securityManager.almazen.canCreateOutMovements() || securityManager.almazen.canCreateInMovements() || securityManager.almazen.canListInventories() || securityManager.almazen.canShowInventoryMode()){
        routes.push({ path: "/almazen/warehouses", name: "Almacenes", icon: "pe-7s-culture", component: WarehousesList });
        routes.push({ path: "/almazen/movements/:warehouse/shrinkage", name: "Movimientos de almacén", hidden:true, component: ShrinkageMovements});
        routes.push({ path: "/almazen/movements/:warehouse/:movementType", name: "Movimientos de almacén", hidden:true, component: StockMovements});
    }

    if(securityManager.almazen.canListInventories() || securityManager.almazen.canShowInventoryMode()){
        routes.push({ path: paths.inventory, name: "Inventario", component: InventoriesList, hidden: true});
        routes.push({ path: paths.inventoryMovements, name: "Movimientos de inventario", component: InventoryMovementsHistory, hidden: true});
        routes.push({ path: "/almazen/inventory-mode/:id", name: "Carga de inventario", component: InventoryMode, hidden: true});
    }

    if(securityManager.almazen.canListRequestStock() || securityManager.almazen.canRequestStock()){
        routes.push({ path: "/almazen/product-requisition", name:"Requisición de salida de insumo y/o producto", smallName: "Req. insumos y productos", icon:"fa fa-lemon-o", component:ProductRequisitionList });
        routes.push({ path: paths.stockRequestDetail, name: "Detalle de requisición", hidden:true, component:ProductRequisitionDetail });
        routes.push({ path: "/almazen/product-requisition/receive/:id", name: "Recibir requisición", hidden:true, component:ReceiveProductRequisition });
    }

    if(securityManager.almazen.canCreateMeasurementUnits()){
        routes.push( { collapse: true, name: "Catálogos", state: "openCata", icon: "fa fa-list", views:[
                { path: "/almazen/lines", smallName:'Líneas', name: "Líneas de productos", mini:"LI", component: ProductLineList },
                { path: "/almazen/units", smallName:'Unidades', name: "Unidades de medida", mini:"UM", component: MeasurementUnitsList },
                { path: "/almazen/measurement-conversion-templates", smallName:'Conversiones', name: "Plantillas de conversión de unidades", mini:"CV", component: MeasurementConversionTemplatesList },
            ]
        });
    }

    if(securityManager.almazen.canEditStockRequestApprovalMatrix())
        routes.push({ path: "/almazen/approval-matrix", icon: "fa fa-chain", smallName: "Aprobaciones", name:"Matriz de aprobaciones", component: StockRequestApprovalMatrixConfigurator});

    if(securityManager.almazen.canEditWarehousePermissions())
        routes.push({ path: paths.configuration, icon: "fa fa-cog", smallName: "Configuración", name:"Configuración de almacenes", component: WarehousesConfiguration});

    if(securityManager.almazen.canListPeriodicStockRequest())
	routes.push({ path: '/almazen/periodic-stock-requests', icon: 'fa fa-refresh', name: 'Requisiciones automáticas', smallName: 'Req. automáticas', component: PeriodicStockRequestsAdmin})

    if(securityManager.almazen.canListWarehouseCounts()){
        routes.push({path:'/almazen/warehouse-count/:id', name:'Conteo de inventario', component: WarehouseCountDetail, hidden: true});
        routes.push({path:'/almazen/warehouse-count', icon: 'fa fa-check-square-o', name:'Conteo de inventario', component: WarehouseCountList});
    }
    if(securityManager.almazen.canListShrinkages()){
        routes.push({path:'/almazen/shrinkages', icon: 'fa fa-trash-o', name:'Historial de mermas', component: ShrinkageList});
    }
    if(securityManager.almazen.canSeeReports()){
        routes.push({path:paths.reports, icon: 'fa fa-area-chart', name:'Reportes', component: WarehouseReports});
    }

    routes.push({path:'/almazen/checador', name:'Checar archivo de importación', component: ImportInventoryChecker, hidden: true});
    routes.push({ path: paths.almazenHelp, name: "Ayuda", icon: "pe-7s-info", component: Help });

    return routes;
};
