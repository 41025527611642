import React from 'react';


export default function NumberAnswer( props ){

    let answer = '';
    if( props.data && typeof props.data.response === 'number' )
        answer = props.data.response;

    const clickPlus = ()=>{
        props.onChange( {response: answer === ''? 1:(answer+1) } );
    };

    const clickMinus = ()=>{
        props.onChange( {response: answer === ''? 0:(answer-1) } );
    };

    const onNumberChange = (e)=>{

        if( e.target.value === '' )
            return props.onChange( undefined );

        const val = parseInt( e.target.value, 10 );
        if( isNaN( val ) ) return;

        props.onChange( {response:val} );
    };

    return (
        <div className='answer-component NumberAnswer'>
            {props.editable?
            <button className={'left'} onClick={clickMinus}>
                -
            </button>
                :null}

            <input
                type='number'
                value={answer}
                onChange={onNumberChange}
                disabled={!props.editable}
            />

            {props.editable?
            <button className={'right'} onClick={clickPlus}>
                +
            </button>
                :null}
        </div>
    );

}