import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Card from '../../../components/Card/Card.jsx';
import SimpleBlockTable from '../../../components/Table/SimpleBlockTable';
import {connect} from 'react-redux';

class Lounges extends Component{

    constructor(props, context){
        super(props);
        this.loungesApi = context.api.lounges;
    }

    UNSAFE_componentWillMount(){
        this.loungesApi.get();
    }

    render(){

        return(
            <Card
                title="Administración de salas"
                content={
                    <SimpleBlockTable
                        data = {this.props.lounges}
                        accessor = "name"
                        idAccessor = "id"
                        text = {{
                            addModal:"Nueva sala:",
                            addPlaceholder:"Nombre",
                            deleteConfirm:"¿Estás seguro que deseas borrar esta sala?",
                            editModal:"Editar nombre de sala:"
                        }}
                        onAdd = {this.loungesApi.create}
                        onDelete = {this.loungesApi.delete}
                        onEdit = {this.loungesApi.edit}
                    />
                }
            />
        );
    }
}

Lounges.contextTypes = { api: PropTypes.object.isRequired };

function mapStateToProps( state ) {
    const { api } = state;
    const lounges = api.lounges? api.lounges:[];

    return { lounges };
}

export default connect(mapStateToProps)(Lounges);