import React,{useCallback, useContext} from 'react';
import ClassicModal from "../../../../components/Modals/ClassicModal";
import {Col, Row} from "react-bootstrap";
import {emptyWorkTool, prepareWorkToolForServer} from "../../../../utils/modelUtils/alma/workToolUtils";
import useForm from "../../../../hooks/useForm";
import {ApiContext} from "../../../../api/Api";
import {NotifierContext} from "../../../../utils/Notifier";


const WorkToolFormModal=({workTool, onHide, ...props})=>{

    const form=useForm(workTool||emptyWorkTool());
    const api=useContext(ApiContext);
    const notifier=useContext(NotifierContext);

    const save=useCallback(()=>{
        onHide();

        const prepared=prepareWorkToolForServer( form.values);

        if(prepared.error)
            return notifier.error(prepared.message);

        if(!workTool)
            api.workTools.create({workTool: prepared});
        else
            api.workTools.edit({id:workTool.id, workTool: prepared});
    },[api, notifier, workTool, onHide, form.values]);

    return (
        <ClassicModal
            title={(workTool?'Editar':'Crear') + ' herramienta de trabajo'}
            className={'WorkToolFormModal'}
            onSave={save}
            onHide={onHide}
            {...props}
        >
            <div className='container-fluid'>
                <Row>
                    <Col xs={12} sm={3}>
                        <p className='wt-label'>Nombre</p>
                    </Col>
                    <Col xs={12} sm={9}>
                        <input className='form-control' placeholder='Escribe el nombre...' {...form.field('name')}/>
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={3}>
                        <p className='wt-label'>Precio a empleados</p>
                    </Col>
                    <Col xs={12} sm={9}>
                        <input className='form-control' type='number' {...form.field('employeePrice')} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={3}>
                        <p className='wt-label'>Precio de proveedor</p>
                    </Col>
                    <Col xs={12} sm={9}>
                        <input className='form-control' type='number' {...form.field('providerPrice')} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={3}>
                        <p className='wt-label'>Días de vida</p>
                    </Col>
                    <Col xs={12} sm={9}>
                        <input className='form-control' type='number' {...form.field('lifeDays')} />
                    </Col>
                </Row>
            </div>
        </ClassicModal>
    )
};

export default WorkToolFormModal;
