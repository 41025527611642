import React,{useContext, useEffect, useMemo} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import DateRangeFilter, {useDateRangeFilter} from '../Filters/DateRangeFilter';
import LoungesFilter, {useLoungesFilter} from '../Filters/LoungesFilter';
import ShiftFilter, {useShiftFilter} from '../Filters/ShiftFilter';
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash/fp';

const loadingId = '@ShiftOccupancyReport.report';
const ShiftOccupancyReport = ({defaultDateRange}) => {
    const dateConfig = useMemo(()=>({initial: defaultDateRange}), [defaultDateRange]);
    const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);
    const {requestParams: loungesRequest, filterProps: loungesFilterProps} = useLoungesFilter();
    const {requestParams: shiftRequest, filterProps: shiftFilterProps} = useShiftFilter();
    const api=useContext(ApiContext);
    const params=useMemo(()=>({
        ...dateRequest,
        ...loungesRequest,
        ...shiftRequest
    }), [dateRequest, loungesRequest, shiftRequest]);
    useEffect(()=>{
        api.lounges.shiftOccupancyReport({
            loadingId,
            params
        });
    },[api, params]);
    const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);
    const report = useSelector(({api})=>api.shiftOccupancyReport);
    const echartsOption = useMemo(()=>({
        color: ['#3398DB'],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                data: _.map('hour', report),
                axisTick: {
                    alignWithLabel: true
                }
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: 'Ocupación por hora',
                type: 'bar',
                barWidth: '60%',
                data: _.map('averageMaxOccupation', report)
            }
        ]
    }),[report]);

    return (
        <div className='ShiftOccupancyReport'>
            <DateRangeFilter {...dateFilterProps}/>
            <LoungesFilter {...loungesFilterProps}/>
            <ShiftFilter {...shiftFilterProps}/>
            {loading && <h3 className='text-center'>Cargando...</h3>}

            <ReactEcharts option={echartsOption}/>
        </div>
    );

};

export default ShiftOccupancyReport;
