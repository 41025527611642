import React from 'react';
import Switch from "react-bootstrap-switch";
import _ from 'lodash';


const ConditionalQuestion = ( props )=>{

    return (
        <div className='ConditionalQuestion'>
            <Switch
                value={ !!props.question.config.isConditional }
                onChange={ (component,value)=>{ props.onChange( { ...props.question.config, isConditional: value } ) } }
            />&nbsp;
            Pregunta condicional
            {props.question.config.isConditional?
                <div className='configuration-box'>
                    <p>Selecciona la pregunta de la cual dependerá la condición:</p>
                    <select
                        className='form-control'
                        value={ getSelectedValue( props.question.config.conditionalQuestion, props.questionnaire.questions) }
                        onChange={ ( {target} )=>{ props.onChange( { ...props.question.config, conditionalQuestion: target.value } ) } }
                    >

                        <option value='' disabled >Selecciona una pregunta</option>
                        { props.questionnaire.questions.map( (question, index)=>{
                            const uid = question.config.uid;
                            if( uid === props.question.config.uid )
                                return null;

                            return <option key={uid} value={uid}>{index + 1}.- {question.text}</option>
                        })}

                    </select>
                    <p>Escribe la respuesta de la cual dependerá la condición:</p>
                    <input
                        className='form-control'
                        value={ props.question.config.conditionalAnswer || '' }
                        onChange={ ( {target} )=>{ props.onChange( { ...props.question.config, conditionalAnswer: target.value } ) } }
                    />

                </div>
                :null}
        </div>
    );
};

//Check that the selected question exists to set it as selected
const getSelectedValue = ( selectedQuestionUID, questions )=>{

    if( !selectedQuestionUID )
        return '';

    if( _.findIndex(questions,( question )=>question.config.uid === selectedQuestionUID ) === -1 )
        return '';

    return selectedQuestionUID;
};

export default ConditionalQuestion;
