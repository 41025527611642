import React, {useMemo, useEffect, useContext, useState, useCallback} from 'react';
import CustomButton from "../../../elements/CustomButton/CustomButton";
import ColLayout from '../../../components/ColLayout/ColLayout';
import ClassicModal from "../../../components/Modals/ClassicModal";
import VisitsAirlinesReport from "./Reports/VisitsAirlinesReport";
import ReservationVisitsReport from "./Reports/ReservationVisitsReport";
import GuestsAttendedReport from "./Reports/GuestsAttendedReport";
import OrozcoProductsCountReport from "./Reports/OrozcoProductsCountReport";
import OrozcoProductsCoffeeCountReport from "./Reports/OrozcoProductsCoffeeCountReport";
import OrozcoProductsDrinksCountReport from "./Reports/OrozcoProductDrinksCountReport";
import DestinationsReport from "./Reports/DestinationsReport";
import GuestsStatisticsReport from "./Reports/GuestsStatisticsReport";
import vipNonGrataVisitsHOC from "./Reports/VipNonGrataVisitsReport";
import ShiftOccupancyReport from './Reports/ShiftOccupancyReport';
import ReservationStatsReport from './Reports/ReservationStatsReport';
import LoungeAccessMethodStatsReport from './Reports/LoungeAccessMethodStatsReport';
//import GCareHappinessReport from './Reports/GCareHappinessReport';
import orozcoProductAverageRealCostHOC from './Reports/OrozcoProductAverageRealCost';
import OrozcoProductAverageRealCostReport from './Reports/OrozcoProductAverageRealCostReport';
import {DateRangePicker} from "react-dates";
import useDateRangePickerState from "../../../hooks/useDateRangePickerState";
import {ApiContext} from '../../../api/Api';
import moment from 'moment';
import VisitsFlightsReport from "./Reports/VisitsFlightsReport";
import GuestsFlightsGoalReport from "./Reports/GuestsFlightsGoalReport";
import FlightsGraphsReport from "./Reports/FlightsGraphsReport";


const davinciReports = [
    {
        name: 'Top de aerolíneas',
        component: VisitsAirlinesReport
    },
    {
        name: 'Vuelos por día',
        component: VisitsFlightsReport
    },
    {
        name: 'Meta de huéspedes por vuelo',
        component: GuestsFlightsGoalReport
    },
    {
        name: 'Tipos de vuelos',
        component: FlightsGraphsReport
    },
    {
        name: 'Visitas con reservación',
        component: ReservationVisitsReport
    },
    {
        name: 'Número de reservaciones',
        component: ReservationStatsReport
    },
    {
        name: 'Huéspedes por anfitrión',
        component: GuestsAttendedReport
    },

    {
        name: 'Destinos principales',
        component: DestinationsReport
    },
    {
        name: 'Estadísticas de huéspedes',
        component: GuestsStatisticsReport
    },
    {
        name: 'Visitas de VIP',
        component: vipNonGrataVisitsHOC('vipCount')
    },
    {
        name: 'Visitas de no gratos',
        component: vipNonGrataVisitsHOC('nonGrataCount')
    },
    {
        name: 'Ocupación de salas',
        component: ShiftOccupancyReport
    },
    {
        name: 'Estadísticas por método de acceso',
        component: LoungeAccessMethodStatsReport
    },
    /*{
        name: 'GCare',
        component: GCareHappinessReport
    }*/
];

const getOrozcoReports = drinksId => [
    {
        name: 'Platillos más pedidos',
        component: OrozcoProductsCountReport
    },
    {
        name: 'Bebidas más pedidas',
        component: OrozcoProductsDrinksCountReport
    },
    {
        name: 'Métodos de café más pedidos',
        component: OrozcoProductsCoffeeCountReport
    },
    {
        name: 'Costo promedio de platillos',
        component: OrozcoProductAverageRealCostReport,
    },
    {
        name: 'Costo promedio de platillos por pasajero',
        component: orozcoProductAverageRealCostHOC()
    },
    {
        name: 'Costo promedio de bebidas por pasajero',
        component: drinksId ? orozcoProductAverageRealCostHOC(drinksId) : () => null
    }
];

const DavinciReports = () => {
    const [showingReport, setShowingReport] = useState(null);
    const hideReport = useCallback(() => setShowingReport(null), []);
    const {props: dateRangeProps, dateRange} = useDateRangePickerState({
        startDate: moment().startOf('month').startOf('day'),
        endDate: moment().add(1, 'day').endOf('day')
    });
    const [drinksId, setDrinksId] = useState(null);
    const [coffeeId, setCoffeeId] = useState(null);
    const api = useContext(ApiContext);
    useEffect(() => {
        api.configurations.getSingle({
            id: 'DRINKS_CATEGORY_ID'
        }).then(v => setDrinksId(v.value));
        api.configurations.getSingle({
            id: 'COFFEE_CATEGORY_ID'
        }).then(v => setCoffeeId(v.value));

    }, [api]);

    const orozcoReports = useMemo(() => getOrozcoReports(drinksId), [drinksId]);

    const showReport = report => setShowingReport(report);

    return (
        <div className='DavinciReports container-fluid'>
            <h2 className='text-center'>Selecciona un reporte</h2>
            <div className='row'>
                <div className="col-xs-offset-3 col-xs-6 text-center">
                    <label>Rango de fechas:&nbsp;</label>
                    <DateRangePicker {...dateRangeProps}/>
                </div>
            </div>
            <div className='container-fluid'>
                <h3>Da Vinci</h3>
                <ColLayout
                    breakpointCols={[3, undefined, 4, 12]}
                    colClass='report-cont'
                >
                    {davinciReports.map((report, i) => (
                        <div key={i} className='report-block'>
                            <h4>{report.name}</h4>
                            <CustomButton bsStyle='primary' onClick={() => showReport(report)}>Ver ahora</CustomButton>
                        </div>
                    ))}
                </ColLayout>
                <h3>Orozco</h3>
                <ColLayout
                    breakpointCols={[3, undefined, 4, 12]}
                    colClass='report-cont'
                >
                    {orozcoReports.map((report, i) => (
                        <div key={i} className='report-block'>
                            <h4>{report.name}</h4>
                            <CustomButton bsStyle='primary' onClick={() => showReport(report)}>Ver ahora</CustomButton>
                        </div>
                    ))}
                </ColLayout>
            </div>

            {showingReport &&
            <ClassicModal
                onHide={hideReport}
                title={showingReport.name}
                showConfirm={false}
                bsSize='lg'
            >
                <showingReport.component drinksId={drinksId} coffeeId={coffeeId} defaultDateRange={dateRange}/>
            </ClassicModal>}

        </div>
    );
};

export default DavinciReports;
