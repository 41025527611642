import React, {useMemo, useState, useContext} from 'react';
import {connect} from 'react-redux';
import {useUploadXFile, XFilesContext} from "../../../../utils/xFilesUtils/xFilesReact";
import {fileTypeName} from "../../../../utils/xFilesUtils";
import {useDropzone} from "react-dropzone";
import {Modal, Button, Row, Col} from "react-bootstrap";
import {loadingId} from "../../../../utils/xFilesUtils/xFilesReact";
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";

const UploadXFileModal = ({onHide, loadingIds, onReloadRequest}) => {
    const [file, setFile] = useState(null);
    const onDrop = files => {
        if (files[0]) {
            setFile(files[0]);
        }
    };
    const {fileType, employee} = useContext(XFilesContext);
    const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragActive,
        isDragReject
    } = useDropzone({onDrop});
    const title = fileTypeName(fileType) + ' de ' + employeeFullName(employee);
    const style = useMemo(() => ({
        ...dropzoneStyle,
        ...(isDragReject ? {borderColor: '#ff1744'} : {}),
        ...(isDragAccept ? {borderColor: '#00e676'} : {}),
        ...(isDragActive ? {borderColor: '#2196f3'} : {}),
    }), [isDragReject, isDragActive, isDragAccept]);
    const {uploadXFile} = useUploadXFile();
    const uploadFile = async ()=>{
        await uploadXFile(employee, [[file, fileType]]);
        onReloadRequest();
    };

    return (
        <Modal show={true} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div {...getRootProps({style})}>
                    <input {...getInputProps()}/>
                    {!file ?
                        <p> Da click aquí o arrastra el {title} </p> :
                        <p style={{color: '#000000'}}> {file.name} </p>
                    }
                </div>
                {file &&
                <Row>
                    <Col xs={6}>
                        <Button onClick={() => setFile(null)} bsStyle='danger'>Cancelar</Button>
                    </Col>
                    <Col xs={6}>
                        <Button
                            disabled={!!loadingIds[loadingId]}
                            onClick={uploadFile}
                            bsStyle='success'>Guardar</Button>
                    </Col>
                </Row>
                }
            </Modal.Body>
        </Modal>
    );
};

const mapStateToProps = ({loadingIds})=>({loadingIds});
export default connect(mapStateToProps)(UploadXFileModal);

const dropzoneStyle = {
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: '#eeeeee',
    backgroundColor: '#fafafa',
    padding: '30px',
    color: '#bdbdbd',
    outline: 'none',
    borderRadius: 2,
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'grab'
};
