import React, {useMemo, useContext} from 'react';
import {ApiContext} from "../../../../api/Api";
import ReactTable from "react-table";
import useTideTable from "../../../../hooks/table/useTideTable";
import Button from "../../../../elements/CustomButton/CustomButton";
import {listSGroups, orozcoPaymentsListTable} from '../../../../utils/modelUtils/orozco/orozcoPaymentUtils';

const PaymentsList = () => {

    const api = useContext(ApiContext);

    const requestFilters = useMemo(() => ({
        sGroups: listSGroups,
        'order[createdDate]': 'DESC'
    }), []);

    const table = useTideTable({
        entity: 'orozcoPayments',
        columns: orozcoPaymentsListTable,
        requestFilters
    });

    const apiFilters = table.lastUsedApiOptions?.filters||{};

    return (
        <div className='PaymentsList container-fluid'>
            <ReactTable
                {...table.tableProps}
            />

            <a href={api.orozcoPayments.exportUrl({...apiFilters, pagination: false})}
               rel="noopener noreferrer"
            >
                <Button>Exportar a Excel</Button>
            </a>
        </div>
    );
};

export default PaymentsList;
