import React, { useCallback } from 'react';
import { Table} from "react-bootstrap";
import {getUnitsArray} from "../../../utils/modelUtils/almazen/unitsUtility";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import { convertToSmallestUnit } from '../../../utils/modelUtils/almazen/unitsUtility';
import _ from 'lodash';

const EditableStockMovementsTable = ({stockMovements=[], onChange, setOverflowing, inventories, overFlowingProductBrand } )=>{

    //Warning function if a product exceeds its maxStockAmount
    const diffAmountChecker = useCallback((inventory, newAmount, stockMovement) => {
        const num = convertToSmallestUnit(newAmount, stockMovement.selectedUnit, inventory.productBrand.product);
        const max = Number(inventory?.maxStockAmount).toFixed(2);
        const stock = Number(inventory?.stockAmount).toFixed(2);
        if (inventory.maxStockAmount && num > (max - stock)) {
            const arrayOfOverflowProductsId = overFlowingProductBrand.map(inv => inv.productBrand.id);
            if (!_.includes(arrayOfOverflowProductsId, inventory.productBrand.id)) {
                setOverflowing([...overFlowingProductBrand, inventory]);
            }
        } else {
            if (overFlowingProductBrand.length) {
                const newArrayOfOverflow = overFlowingProductBrand.filter(inv => inv.productBrand.id !== inventory.productBrand.id);
                setOverflowing(newArrayOfOverflow);
            }
        }
    }, [overFlowingProductBrand, setOverflowing]);
    //End of warning checker

    const onStockMovementRemove = useCallbackCreator( ( index )=>{

        const stockMovement = stockMovements[ index ];
        const newMovements  = [ ...stockMovements  ];
        newMovements.splice( index, 1 );
        onChange( newMovements );

        // Remove if warning is showing
        let inventory = null;
        inventories.forEach((inv) => {
            if (inv.productBrand.id === stockMovement.productBrand.id)
                inventory = inv;
        });
        if (overFlowingProductBrand.length) {
            const newArrayOfOverflow = overFlowingProductBrand.filter(inv => inv?.productBrand.id !== inventory?.productBrand.id);
            setOverflowing(newArrayOfOverflow);
        }
    }, [ stockMovements, onChange, overFlowingProductBrand ]);

    const onMovementQuantityChange = useCallbackCreator( ( index, e )=>{

        const stockMovement = stockMovements[ index ];
        // Check value to throw warning Warning maxStockAmount implementation
        let inventory = null;
        inventories.forEach((inv) => {
            if (inv.productBrand.id === stockMovement.productBrand.id)
                inventory = inv;
        });
        if (inventory !== null && inventory?.maxStockAmount !== null && e.target.value.length)
            diffAmountChecker(inventory, e.target.value, stockMovement);
        // Create new Stock Movement
        const newStockMovements = [ ...stockMovements ];
        newStockMovements[ index ] = {...stockMovement, quantity: e.target.value};

        onChange( newStockMovements );

    }, [ stockMovements, onChange, inventories ]);

    const onMovementUnitChange = useCallbackCreator( ( index, e )=>{

        const stockMovement = {...stockMovements[ index ]};
        const newStockMovements= [ ...stockMovements ];

        stockMovement.selectedUnit = getUnitsArray( stockMovement.productBrand.product ).find( ( uni )=>uni.id+''===e.target.value );
        newStockMovements[index] = stockMovement;
        // Check value to throw warning Warning maxStockAmount implementation
        let inventory = null;
        inventories.forEach((inv) => {
            if (inv.productBrand.id === stockMovement.productBrand.id)
                inventory = inv;
        });
        if (inventory !== null && inventory?.maxStockAmount !== null && stockMovement.quantity.length)
            diffAmountChecker(inventory, stockMovement.quantity, stockMovement);

        //Send information
        onChange( newStockMovements);

    }, [ stockMovements, onChange, inventories ]);


    return (
        <Table>
            <tbody>
            <tr>
                <th>Nombre</th>
                <th>Cantidad</th>
                <th>Unidad</th>
            </tr>
            {stockMovements.map((sm, index) => (
                <tr key={sm.productBrand.id}>
                    <td><span onClick={ onStockMovementRemove(index) } className='red-icon'><i className='fa fa-times'/></span> {sm.productBrand.product.name} - {sm.productBrand.brand.name}</td>
                    <td><input value={ sm.quantity}  onChange={ onMovementQuantityChange(index) } id='quantity-input' type='number' className='form-control'/></td>
                    <td>
                        <select className='form-control' value={sm.selectedUnit?sm.selectedUnit.id:''} onChange={onMovementUnitChange(index)}>
                            { getUnitsArray( sm.productBrand.product ).map( unit=>
                                <option key={unit.id} value={unit.id}>{unit.name}</option>
                            ) }
                        </select>
                    </td>
                </tr>
            ))}

            </tbody>
        </Table>
    )

};


export default EditableStockMovementsTable;
