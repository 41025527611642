import React, {useCallback} from 'react';
import Switch from "react-bootstrap-switch";


const BooleanField = ({value, onChange, field}) => {

    const {name, label, onText, offText} = field;
    const handleChange = useCallback((s, value)=>{
        onChange && onChange(value, name)
    },[onChange, name]);

    return (
        <div className={"BooleanField"}>
            <p className='label'>{label}</p>
            <Switch
                value={ !!value }
                onChange={handleChange}
                onText={ onText||'Si' }
                offText={ offText||'No' }
            />
        </div>
    );
};

export default React.memo(BooleanField);
