import React from 'react';
import Notifications from 'react-notification-system-redux';

export default class Notifier {

    constructor( store ){

        if( !store )
            throw new Error("Notifier requires the store to work");
        this.store = store;
    }

    error= ( message, icon = "fa fa-exclamation-triangle", config )=>{
        this.store.dispatch( Notifications.error({
            title: <i className={icon}/>,
            message: message,
            ...config,
        }));
    };

    success= ( message, icon = "fa fa-check", config )=>{
        this.store.dispatch( Notifications.success({
            title: <i className={icon}/>,
            message: message,
            ...config,
        }));
    };

    warning= ( message, icon = "fa fa-exclamation-triangle", config )=>{
        this.store.dispatch( Notifications.warning({
            title: <i className={icon}/>,
            message: message,
            ...config,
        }));
    };

    info= ( message, icon = "fa fa-info-circle", config )=>{
        this.store.dispatch( Notifications.info({
            title: <i className={icon}/>,
            message: message,
            ...config,
        }));
    };
}

export const NotifierContext = React.createContext(null);