import React, {Component} from 'react';
import ReactTable from 'react-table';
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import _ from 'lodash';
import moment from 'moment';
import Button from "../../../elements/CustomButton/CustomButton";
import {NavLink} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";


class QuestionnaireEditorList extends Component{

    static contextTypes = { api:PropTypes.object, notifier:PropTypes.object };

    loadingId = "@Class.QuestionnaireEditorList.questionnaires.get";

    constructor( props, context ){
        super(props);
        this.state = {
            deletingQuestionnaire: false
        };

        this.loadQuestionnaires = _.debounce( this.loadQuestionnaires.bind(this), 700 );

        this.dataApi = context.api.questionnaires;
    }

    loadQuestionnaires( tableState ){

        this.tableState = tableState;

        const sort = tableState.sorted.reduce( (acc, val)=>{acc[`order[${val.id}]`] = val.desc?"DESC":"ASC"; return acc;}, {} );
        const filter = tableState.filtered.reduce( (acc, val)=>{acc[val.id] = val.value; return acc;}, {} );

        if( !tableState.sorted || !tableState.sorted.length )
            sort["order[createdDate]"] = 'DESC';

        this.dataApi.get(tableState.page , tableState.pageSize, this.loadingId, {...sort, ...filter});
    }

    handleDeleteQuestionnaire = (e)=>{
        this.setState( {deletingQuestionnaire: e.currentTarget.dataset.index} );
    };

    stopDeleting = ()=>{
        this.setState( {deletingQuestionnaire: false} );
    };

    confirmDelteQuestionnaire = ()=>{

        const questionnaire = this.props.list[ this.state.deletingQuestionnaire ];
        if( !questionnaire ) return;

        this.context.api.questionnaires.delete( questionnaire.id );
        this.stopDeleting();
    };

    tableStructure = [{
        Header: 'Cuestionario',
        accessor: 'name',
        filterable: true
    }, {
        Header: 'Creado por',
        accessor: (quest)=> quest.createdBy?employeeFullName(quest.createdBy.employee):'',
        id: 'createdBy.employee.name',
        filterable: true
    }, {
        Header: 'Fecha',
        accessor: (quest)=> moment(quest.createdDate).format( 'DD/MM/YYYY' ),
        id: 'createdDate',
    }, {
        Header: 'Permisos',
        accessor: (quest) => {
            const employees = quest.permissions.reduce( (acc, perm)=>perm.employee? acc+1:acc  , 0 );

            return quest.permissions.length ?
                <span>
                    {employees?<span>{employees} persona{employees>=1?'s':''} y <br/></span>:null}

                    {quest.permissions.map((perm, i) =>
                        perm.position?
                            <span key={i}>{perm.position.name}<br/></span>:
                            null
                    )}
                </span>
                :
                <span>Sin permisos</span>
        }
        ,
        id: 'permissions',
        sortable: false
    }, {
        Header: 'Acciones',
        accessor:'id',
        Cell: (row) =>
            <span>
                <NavLink to={'/ee/questionnaire-editor/'+row.value}>
                    <Button bsStyle='primary'>Editar</Button>
                </NavLink>&nbsp;&nbsp;&nbsp;
                <Button bsStyle='danger' onClick={this.handleDeleteQuestionnaire} data-index={row.index}>Eliminar</Button>
            </span>,
        id: 'actions',
        sortable: false
    }

    ];

    render() {

        const { totalItems, itemsPerPage } = this.props.listMeta;
        const plural = totalItems>1?"s":"";

        return (
            <div className="container-fluid main-container">

                <NavLink to={'/ee/questionnaire-editor/new'}>
                    <Button bsStyle={'primary'} className={'col-xs-12'}> Crear nuevo cuestionario </Button>
                </NavLink>
                <div className='clearfix'/>

                <ReactTable
                    data={this.props.list}
                    columns = {this.tableStructure}
                    defaultPageSize= {10}
                    pages= { Math.ceil( totalItems / itemsPerPage ) }
                    className="-striped -highlight"
                    loading={!!this.props.loadingIds[this.loadingId]}
                    getTrProps={(table, row)=>row && !row.original.isActive?{className:'red-table-row'}:{}}
                    manual
                    onFetchData={this.loadQuestionnaires}
                    minRows={3}
                    {...ReactTableTranslations}
                />
                <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>

                <Modal
                    show={this.state.deletingQuestionnaire !== false }
                    onHide={this.stopDeleting}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Eliminar cuestionario</Modal.Title>
                    </Modal.Header>
                    {this.state.deletingQuestionnaire !== false?
                    <Modal.Body>

                        <p>
                            ¿ Estás seguro que deseas eliminar el cuestionario <strong>{this.props.list[this.state.deletingQuestionnaire].name}</strong>?<br/><br/>
                            Toda la información asociada a él, incluyendo los cuestionarios respondidos, serán eliminados.
                        </p>
                    </Modal.Body>
                        :null}
                    <Modal.Footer>
                        <Button bsStyle='danger' className='pull-left' onClick={this.stopDeleting}>Cancelar</Button>
                        <Button bsStyle='danger' onClick={this.confirmDelteQuestionnaire}>Eliminar</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

function mapStateToProps( {api:{ questionnaires=[], questionnairesMeta={totalItems:0,itemsPerPage:0} }, loadingIds } ) {

    return { list:questionnaires, listMeta:questionnairesMeta, loadingIds }
}

export default connect(mapStateToProps)(QuestionnaireEditorList);
