import React, {useContext, useEffect} from 'react';
import {TideApiContext} from "../../../../api/tideApiConfig";
import {useSelector} from "react-redux";
import {loungeMapListSGroups} from "tgle-core/utils/loungeMapUtils";
import {paths} from "../../../../routes/ee";
import {Link} from "react-router-dom";

const loadingId = '@LoungeMapList';
const LoungeMapList = ()=>{

    const api = useContext(TideApiContext);
    useEffect(()=>{
        api.loungeMaps.get({params:{pagination:false, sGroups: loungeMapListSGroups}, loadingId});
    },[api]);

    const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);
    const loungeMaps = useSelector(({api})=>api.loungeMaps)||[];

    return (
        <div className='LoungeMapList main-content'>
            {loading&&<h4>Cargando...</h4>}
            <div className='container-fluid'>
                <div className='row'>
                    {loungeMaps.map(loungeMap=>
                        <Link  key={loungeMap.id} to={paths.loungeMapEditor.replace(':id', loungeMap.id)}>
                            <div className='col-xs-12 col-md-4 map-block'>
                                <h2 className='text-center'>{loungeMap.lounge.name}</h2>
                            </div>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LoungeMapList;
