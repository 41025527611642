import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Card from '../../../../components/Card/Card';
import ReactEcharts from 'echarts-for-react';
import {connect} from 'react-redux';

class AvergageWageAndEmployeesGraph extends Component {

    getPayrollAvergageStatsOptions = () => {

        const values = this.props.payrollDetailsReport.result.reduce( (acc, cur)=>{

            acc.periods.push( cur.attr );
            acc.salaries.push( cur.employeeTotalAverage/100 );
            acc.employees.push( cur.totalEmployees );

            return acc;

        }, {periods:[], salaries:[], employees:[]} );

        return {
            backgroundColor: '#f5f5f5',
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['Sueldo promedio','Número de empleados']
            },
            grid:
                {
                    left: '3%',
                    right: '6%',
                    bottom: '3%',
                    containLabel: true
                }
            ,
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: values.periods
            }],
            yAxis: [
                {
                    type: 'value',
                },
                {
                    type: 'value',
                    position: 'right'
                }
            ],

            series: [
                {
                    name: 'Sueldo promedio',
                    type: 'line',
                    stack: 's4',
                    data: values.salaries
                },
                {
                    name: 'Número de empleados',
                    type: 'line',
                    stack: 's3',
                    yAxisIndex:1,
                    data: values.employees
                },
            ],
            textStyle: {color:"rgba(20, 20, 20, 1)"}
        }
    };

    render() {

        return (
            <Card
                title={<span>Sueldo promedio y número de empleados</span>}
                category="Últimas 6 quincenas"
                content={
                    <ReactEcharts
                        option={this.getPayrollAvergageStatsOptions()}
                    />
                }
                stats={
                    <div>
                        <NavLink to="/alma/payroll/list"><i className="fa fa-clock-o" /> Ir a historial</NavLink>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = ({api:{ payrollDetailsReport = {result:[]} }})=>{

    return {payrollDetailsReport};
};

export default connect(mapStateToProps)(AvergageWageAndEmployeesGraph);