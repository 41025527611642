import React, {Component} from 'react';
import Card from '../../../../components/Card/Card';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import _ from 'lodash';

import scale1 from '../../../../assets/img/happiness-scale/angry.png';
import scale2 from '../../../../assets/img/happiness-scale/uncomfortable.png';
import scale3 from '../../../../assets/img/happiness-scale/indifferent.png';
import scale4 from '../../../../assets/img/happiness-scale/amused.png';
import scale5 from '../../../../assets/img/happiness-scale/happy.png';
import LoaderIcon from "../../../../components/Helper/LoaderIcon";
import CustomButton from "../../../../elements/CustomButton/CustomButton";


class HappinessLastWeek extends Component {

    static contextTypes = { api: PropTypes.object };

    state = {
        showShifts: false,
        initialDate: moment('2019-02-01'),
        finalDate: moment('2019-03-31'),
        checkTypes: false
    };

    componentDidMount(){
        this.loadReport();
    };

    loadReport = ()=>{

        if( this.props.filterGroup && !this.props.me )
            return;

        const filters = {};

        if( this.props.filterGroup ){
            if( !this.props.me.employee.owningGroup )
                return;
            filters.group = this.props.me.employee.owningGroup.id;
        }

        const fromDate = this.state.initialDate.format('YYYY-MM-DDTHH:mm');
        const intervalSize = this.state.finalDate.diff( this.state.initialDate, "days" );

        this.context.api.happinessLogsReport.get( fromDate, intervalSize, 1,undefined,  'happinessLastWeekReport', filters );
    };

    onDateChange = ( e , picker)=>{
        this.setState( {initialDate: picker.startDate, finalDate: picker.endDate },
            this.loadReport);
    };


    toggleShifts = ()=>{
        this.setState({showShifts: !this.state.showShifts});
    };

    toggleCheckTypes = ()=>{
        this.setState({checkTypes: !this.state.checkTypes});
    };

    static isValidReport = ( loungeReport )=>{
        if( !loungeReport )
            return false;

        //If it's an empty report it's not valid
        return !((!loungeReport.M || !loungeReport.M.length) &&
            (!loungeReport.V || !loungeReport.V.length) &&
            (!loungeReport.N || !loungeReport.N.length));


    };

    static reportAverage = ( loungeReport, checkType )=>{

        if( !HappinessLastWeek.isValidReport(loungeReport) )
            return 0;

        let sum = 0;
        let count = 0;

        if( !checkType ) {
            if (loungeReport.M && loungeReport.M.length) {
                sum += Number(loungeReport.M[0].average) * Number(loungeReport.M[0].count);
                count += Number(loungeReport.M[0].count);
            }
            if (loungeReport.V && loungeReport.V.length) {
                sum += Number(loungeReport.V[0].average) * Number(loungeReport.V[0].count);
                count += Number(loungeReport.V[0].count);
            }
            if (loungeReport.N && loungeReport.N.length) {
                sum += Number(loungeReport.N[0].average) * Number(loungeReport.N[0].count);
                count += Number(loungeReport.N[0].count);
            }

            return Math.round((sum / count) * 100) / 100;
        }

        else if(  checkType==='check_in' ){
            if (loungeReport.M && loungeReport.M.length) {
                sum += Number(loungeReport.M[0].checkInAverage) * Number(loungeReport.M[0].checkInCount);
                count += Number(loungeReport.M[0].checkInCount);
            }
            if (loungeReport.V && loungeReport.V.length) {
                sum += Number(loungeReport.V[0].checkInAverage) * Number(loungeReport.V[0].checkInCount);
                count += Number(loungeReport.V[0].checkInCount);
            }
            if (loungeReport.N && loungeReport.N.length) {
                sum += Number(loungeReport.N[0].checkInAverage) * Number(loungeReport.N[0].checkInCount);
                count += Number(loungeReport.N[0].checkInCount);
            }

            return Math.round((sum / count) * 100) / 100;
        }

        else if(  checkType==='check_out' ){
            if (loungeReport.M && loungeReport.M.length) {
                sum += Number(loungeReport.M[0].checkOutAverage) * Number(loungeReport.M[0].checkOutCount);
                count += Number(loungeReport.M[0].checkOutCount);
            }
            if (loungeReport.V && loungeReport.V.length) {
                sum += Number(loungeReport.V[0].checkOutAverage) * Number(loungeReport.V[0].checkOutCount);
                count += Number(loungeReport.V[0].checkOutCount);
            }
            if (loungeReport.N && loungeReport.N.length) {
                sum += Number(loungeReport.N[0].checkOutAverage) * Number(loungeReport.N[0].checkOutCount);
                count += Number(loungeReport.N[0].checkOutCount);
            }

            return Math.round((sum / count) * 100) / 100;
        }

        return 0;

    };

    static faceForAverage = ( average )=>{

        if( average <= 1 )
            return scale1;
        if( average <= 2 )
            return scale2;
        if( average <= 3 )
            return scale3;
        if( average <= 4 )
            return scale4;
        else
            return scale5;
    };

    renderLoungeReports = ( loungeReports )=>{

        let lounges = Object.keys( loungeReports );

        lounges = _.sortBy( lounges, ( lounge)=>{
            if( !HappinessLastWeek.isValidReport( loungeReports[lounge] ))
                return 0;
            else
                return HappinessLastWeek.reportAverage( loungeReports[lounge]);
        });

        const rendered = [];

        lounges.forEach( (lounge)=> {

            if( !HappinessLastWeek.isValidReport( loungeReports[lounge] ))
                return;

            const loungeReport = loungeReports[lounge];
            const average = HappinessLastWeek.reportAverage(loungeReport);

            rendered.push(
                <div className='lounge-container' key={lounge}>
                    <div className={'inner-container'}>
                        <h3>{lounge}</h3>
                        <img src={HappinessLastWeek.faceForAverage(average)} alt='Happiness scale face'/><br/>
                        {this.state.showShifts ?
                            <div className='shifts'>
                                    {this.state.checkTypes ?
                                        <div className='score check-types'>
                                        <table >
                                            <tbody>
                                            <tr>
                                                <td>M</td>
                                                {loungeReport.M && loungeReport.M.length ?
                                                    <>
                                                        <td>
                                                            <i className='fa fa-sign-in check-in'/>
                                                            {loungeReport.M[0].checkInAverage? loungeReport.M[0].checkInAverage.substr(0, 4):'-'}
                                                            <span className='small'> ({loungeReport.M[0].checkInCount})</span>
                                                        </td>
                                                        <td>
                                                            <i className='fa fa-sign-out check-out'/>
                                                            {loungeReport.M[0].checkOutAverage? loungeReport.M[0].checkOutAverage.substr(0, 4): '-'}
                                                            <span className='small'> ({loungeReport.M[0].checkOutCount})</span>
                                                        </td>
                                                    </>
                                                    : <td>-</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>V</td>
                                                {loungeReport.V && loungeReport.V.length ?
                                                    <>
                                                        <td>
                                                            <i className='fa fa-sign-in check-in'/>
                                                            {loungeReport.V[0].checkInAverage? loungeReport.V[0].checkInAverage.substr(0, 4):'-'}
                                                            <span className='small'> ({loungeReport.V[0].checkInCount})</span>
                                                        </td>
                                                        <td>
                                                            <i className='fa fa-sign-out check-out'/>
                                                            {loungeReport.V[0].checkOutAverage? loungeReport.V[0].checkOutAverage.substr(0, 4):'-'}
                                                            <span className='small'> ({loungeReport.V[0].checkOutCount})</span>
                                                        </td>
                                                    </>
                                                    : <td>-</td>
                                                }
                                            </tr>
                                            <tr>
                                                <td>N</td>
                                                {loungeReport.N && loungeReport.N.length ?
                                                    <>
                                                        <td>
                                                            <i className='fa fa-sign-in check-in'/>
                                                            {loungeReport.N[0].checkInAverage? loungeReport.N[0].checkInAverage.substr(0, 4):'-'}
                                                            <span className='small'> ({loungeReport.N[0].checkInCount})</span>
                                                        </td>
                                                        <td>
                                                            <i className='fa fa-sign-out check-out'/>
                                                            {loungeReport.N[0].checkOutAverage? loungeReport.N[0].checkOutAverage.substr(0, 4): '-'}
                                                            <span className='small'> ({loungeReport.N[0].checkOutCount})</span>
                                                        </td>
                                                    </>
                                                    : <td>-</td>
                                                }
                                            </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                        :
                                        <div>
                                            <span className='score'>M {loungeReport.M && loungeReport.M.length ? `${loungeReport.M[0].average.substr(0, 4)} (${loungeReport.M[0].count})` : '-'}<br/></span>
                                            <span className='score'>V {loungeReport.V && loungeReport.V.length ? `${loungeReport.V[0].average.substr(0,4)} (${loungeReport.V[0].count})` : '-'}<br/></span>
                                            <span className='score'>N {loungeReport.N && loungeReport.N.length ? `${loungeReport.N[0].average.substr(0,4)} (${loungeReport.N[0].count})` : '-'}<br/></span>
                                        </div>

                                    }
                            </div>
                            :
                            <span className='score'>
                                {this.state.checkTypes ?
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td><i className='fa fa-sign-in check-in' /> {HappinessLastWeek.reportAverage( loungeReport, 'check_in' )}</td>
                                            <td><i className='fa fa-sign-in check-out' /> {HappinessLastWeek.reportAverage( loungeReport, 'check_out' )}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    :
                                    average
                                }
                            </span>}
                    </div>
                </div>
            );
        });

        return rendered;
    };

    render() {

        return (
            <Card
                title={<span>Felicidad promedio</span>}
                category="Cambia el rango de fechas con el botón inferior"
                className='HappinessLastWeek'
                content={
                    <>
                        <div className='happiness-wrapper'>
                            {this.props.happinessLastWeekReport ?
                                this.renderLoungeReports(this.props.happinessLastWeekReport)
                                :
                                <p style={{textAlign:'center'}}><LoaderIcon/></p>
                            }
                        </div>
                        <DateRangePicker startDate={this.state.initialDate} endDate={this.state.finalDate} onApply={this.onDateChange}>
                            <CustomButton bsStyle={'primary'}>{ this.state.initialDate.format('YYYY-MM-DD') } - { this.state.finalDate.format('YYYY-MM-DD') }</CustomButton>
                        </DateRangePicker>
                    </>
                }
                stats={
                    <>
                        <span className='link' onClick={this.toggleShifts}>
                            <i className='fa fa-eye'/> {this.state.showShifts ? 'Ocultar' : 'Ver'} turnos
                        </span>

                        <span className='link pull-right' onClick={this.toggleCheckTypes}>
                            <i className='fa fa-eye'/> {this.state.checkTypes ? 'Ocultar' : 'Ver'} entradas y salidas
                        </span>
                    </>
                }
            />

        );
    }
}

const mapStateToProps = ( {api:{happinessLastWeekReport, me}} )=>({happinessLastWeekReport, me});

export default connect( mapStateToProps )(HappinessLastWeek);
