import React, {useState, useRef, useContext} from 'react';
import useInput from '../../../hooks/useInput';
import {ControlLabel, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import UnitBundleCreator from '../../../components/UnitBundleCreator/UnitBundleCreator';
import {emptyUnitBundle, extractUnitBundleProperties} from "../../../utils/modelUtils/almazen/unitsUtility";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import _ from 'lodash';
import {NotifierContext} from "../../../utils/Notifier";
import {ApiContext} from "../../../api/Api";
import {connect} from "react-redux";


const loadingId = '@MeasurementConversionTemplateForm.measurementConversionTemplate.create/edit';

const MeasurementConversionTemplateForm = ( {measurementConversionTemplate, onHide, loadingIds} )=>{

    const api = useContext( ApiContext );
    const notifier = useContext( NotifierContext );

    const mct = measurementConversionTemplate;

    const [nameTouched, setNameTouched] = useState( false );
    const nameTouchedRef = useRef( nameTouched );
    nameTouchedRef.current = nameTouched;

    const nameIn = useInput( mct? mct.name : '', ()=>{
        if( !nameTouchedRef.current )
            setNameTouched( true );
    } );
    const [unitBundle, setUnitBundle ]= useState( mct? extractUnitBundleProperties(mct) : emptyUnitBundle() );

    const onUnitBundleChange = ( unitBundle )=>{

        if( !nameTouched ){
            let name = '';
            if( unitBundle.smallestUnit )
                name = unitBundle.smallestUnit.name;

            if( unitBundle.displayUnit )
                name += ' - ' + unitBundle.displayUnit.name;

            if( unitBundle.conversionRules && unitBundle.conversionRules.length ){

                const rulesUnits = [];
                _.forEach( unitBundle.conversionRules, ( cr )=>{
                    if( cr.unit && ( !unitBundle.displayUnit || ( unitBundle.displayUnit.id !== cr.unit.id ) ) )
                        rulesUnits.push( cr.unit.name );
                } );

                if( rulesUnits.length )
                    name += ' [ ' + rulesUnits.join(' - ') + ' ]';
            }

            nameIn.setValue( name );
        }

        setUnitBundle( unitBundle )
    };

    const onTemplateSave = ()=>{

        const name = nameIn.value.trim();
        if( !name )
            return notifier.error("Debes agregar un nombre a la plantilla.");

        const fixedBundle = {...unitBundle};

        if( !unitBundle.smallestUnit )
            return notifier.error("Debes agregar la unidad mínima de medida.");
        else
            fixedBundle.smallestUnit = fixedBundle.smallestUnit.id;


        if( !fixedBundle.displayUnit )
            delete fixedBundle.displayUnit;
        else
            fixedBundle.displayUnit = fixedBundle.displayUnit.id;

        fixedBundle.conversionRules = fixedBundle.conversionRules.reduce( (acc,cr)=>{
            if( cr.unit && cr.multiplier && Number( cr.multiplier ))
                acc.push( { unit: cr.unit.id, multiplier: cr.multiplier+''} );
            return acc;
        }, [] );

        const mct = {
            name,
            ...fixedBundle
        };

        if( measurementConversionTemplate )
            api.measurementConversionTemplates.edit( measurementConversionTemplate.id, mct, loadingId ).then( onHide );
        else
            api.measurementConversionTemplates.create( mct, loadingId ).then(onHide);


    };

    return (
        <Modal
            show={true}
            bsSize={'lg'}
            onHide={onHide}
        >
            <ModalHeader closeButton>
                <ModalTitle> {mct? 'Editar':'Crear' } plantilla de unidades</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <ControlLabel>Nombre:</ControlLabel>
                <input className='form-control' {...nameIn.bind} />
                <hr/>
                <UnitBundleCreator
                    unitBundle={unitBundle}
                    onChange={ onUnitBundleChange }
                />
            </ModalBody>
            <ModalFooter>
                <CustomButton bsStyle={'danger'} className='pull-left' onClick={onHide} disabled={!!loadingIds[loadingId]}>Cerrar</CustomButton>
                <CustomButton bsStyle={'success'} onClick={onTemplateSave} disabled={!!loadingIds[loadingId]}>Guardar</CustomButton>
            </ModalFooter>
        </Modal>
    );

};

const mapStateToProps = ({loadingIds})=>({loadingIds});

export default connect(mapStateToProps)(MeasurementConversionTemplateForm);
