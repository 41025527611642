import _ from 'lodash';
import {fileCell, filter} from "./xFilesReact";

export const fileClassification = [
    {
        key: 'cv',
        name: 'CV / Solicitud',
        smallName: 'CV',
        tags: ['cv', 'curriculum']
    },
    {
        key: 'ine',
        name: 'Identificación Oficial',
        tags: ['ine', 'ife', 'pasaporte', 'cedula']
    },
    {
        key: 'curp',
        name: 'CURP',
        tags: ['curp']
    },
    {
        key: 'rfc',
        name: 'RFC',
        tags: ['rfc']
    },
    {
        key: 'birthCertificate',
        name: 'Acta de nacimiento',
        smallName: 'Nacimiento',
        tags: ['nacimiento']
    },
    {
        key: 'imssCertificate',
        name: 'Alta del IMSS',
        smallName: 'IMSS',
        tags: ['imss']
    },
    {
        key: 'studiesCertificate',
        name: 'Comprobante de estudios',
        smallName: 'Estudios',
        tags: ['estudios']
    },
    {
        key: 'proofOfAddress',
        name: 'Comprobante de domicilio',
        smallName: 'Domicilio',
        tags: ['domicilio']
    },
    {
        key: 'recommendationLetters',
        name: 'Cartas de recomendación / Constancias Laborales',
        smallName: 'Recomendación',
        tags: ['recomendacion']
    },
    {
        key: 'medicalCertificate',
        name: 'Certificado Médico',
        smallName: 'Médico',
        tags: ['medico', 'medica']
    },
    {
        key: 'criminalRecord',
        name: 'No antecedentes penales',
        smallName: 'Penales',
        tags: ['antecedentes', 'penales']
    },
    {
        key: 'employeePicture',
        name: 'Fotografía tamaño infantil',
        tags: ['foto', 'infantil']
    },
    {
        key: 'antidoping',
        name: 'Antidoping',
        tags: ['antidoping']
    },
    {
        key: 'account',
        name: 'Cuenta',
        tags: ['cuenta']
    },
    {
        key: 'infonavit',
        name: 'Retención INFONAVIT',
        tags: ['infonavit']
    },
    {
        key: 'fonacot',
        name: 'Descuento FONACOT',
        tags: ['fonacot']
    },
    {
        key: 'alimony',
        name: 'Pensión Alimenticia',
        tags: ['alimony']
    },
    {
        key: 'covidTest',
        name: 'PRUEBA covid-19',
        tags: ['covidTest']
    },
    {
        key: 'firstVaccine',
        name: 'Primera vacuna',
        tags: ['firstVaccine']
    },
    {
        key: 'secondVaccine',
        name: 'Segunda vacuna',
        tags: ['secondVaccine']
    },
    {
        key: 'boosterVaccine',
        name: 'Vacuna refuerzo',
        tags: ['boosterVaccine']
    },
    {
        key: 'vaccinationCertificate',
        name: 'Certificado de vacunación',
        tags: ['vaccinationCertificate']
    },
    {
        key: 'letterOfRecommendation',
        name: 'Carta recomendación personal',
        tags: ['letterOfRecommendation']
    },
    {
        key: 'file1',
        name: 'Archivo1',
        tags: ['file1']
    },
    {
        key: 'file2',
        name: 'Archivo2',
        tags: ['file2']
    },
    {
        key: 'file3',
        name: 'Archivo3',
        tags: ['file3']
    },
    {
        key: 'file4',
        name: 'Archivo4',
        tags: ['file4']
    },
    {
        key: 'file5',
        name: 'Archivo5',
        tags: ['file5']
    },
    {
        key: 'file6',
        name: 'Archivo6',
        tags: ['file6']
    },
    {
        key: 'file7',
        name: 'Archivo7',
        tags: ['file7']
    },
    {
        key: 'file8',
        name: 'Archivo8',
        tags: ['file8']
    },
    {
        key: 'file9',
        name: 'Archivo9',
        tags: ['file9']
    },
    {
        key: 'file10',
        name: 'Archivo10',
        tags: ['file10']
    }
];

export const fileTypeName = key =>
    _.find(fileClassification, {key}).name;


export const fileColumns = fileClassification.map(({key, name, smallName}) => ({
    Header: smallName || name,
    id: 'employeeXFile.'+key,
    Cell: fileCell(key),
    Filter: filter([
        {
            value: false,
            text: 'Sin archivo'
        },
        {
            value: true,
            text: 'Con archivo'
        }
    ]),
}));


