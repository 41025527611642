import React, {useEffect,useContext,useCallback, useRef, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import {productBrandName} from "../../../../utils/modelUtils/almazen/productBrandUtils";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {convertToSmallestUnit, displayAmount, getUnitsArray} from "../../../../utils/modelUtils/almazen/unitsUtility";
import useBoolean from "../../../../hooks/useBoolean";
import useInput from "../../../../hooks/useInput";
import _ from 'lodash';
import {NotifierContext} from "../../../../utils/Notifier";
import {stockMovementStatus} from "../../../../utils/modelUtils/almazen/stockMovementUtils";
import {stockMovementBatchStatus, stockMovementBatchType} from "../../../../utils/modelUtils/almazen/stockMovementBatchUtils";
import noProductImg from "../../../../assets/img/no-product.png";
import useCallbackCreator from "../../../../hooks/useCallbackCreator";


const loadingInventoryId='@InventoryModeFormModal.inventories.get';
const InventoryModeFormModal = ({productBrand, warehouse, onHide, onChange})=>{

    const api=useContext(ApiContext);
    const notifier=useContext(NotifierContext);

    //Save the product brand to the state, so in case of editing we will show the info returned from the server.
    const productBrandState=useState(productBrand);
    const setProductBrand=productBrandState[1];

    useEffect(()=>setProductBrand(productBrand), [productBrand, setProductBrand] );
    productBrand=productBrandState[0];

    const customProp = `InventoryModeFormModal.warehouse-${warehouse.id}.productBrand-${productBrand.id}`;

    const inventories = useSelector(({api})=>api[customProp]);
    const inventory = !!inventories && !!inventories.length && inventories[0];
    const loadingInventory=useSelector(({loadingIds})=>loadingIds[loadingInventoryId]);

    const loadInventory = useCallback(()=>
        api.inventories.get({pageSize:1, filters:{warehouse:warehouse.id, productBrand:productBrand.id}, customProp})
    ,[api, warehouse.id, productBrand.id, customProp]);

    useEffect(()=>{
        if(!inventory)
            loadInventory();

    },[inventory, loadInventory]);

    const [editingAmount, startEditingAmount, stopEditingAmount]=useBoolean();

    const amountInput = useInput('');
    const amountUnitInput = useInput(
        productBrand.product.displayUnit?
            productBrand.product.displayUnit.id:
            productBrand.product.smallestUnit.id
    );

    const units = getUnitsArray(productBrand.product);

    const saveNewInventory=useCallback(()=>{

        const newAmount=amountInput.value;
        if(newAmount.trim()==='' || isNaN(Number(newAmount)))
            return notifier.error('Ingresa una cantidad válida');

        const unit = _.find(units, (unit)=>unit.id+''===amountUnitInput.value);
        const smallestAmount = convertToSmallestUnit(newAmount, unit, productBrand.product);
        const changeAmount=inventory? smallestAmount - inventory.stockAmount  : smallestAmount;

        if(Number(changeAmount)===0) {
            notifier.info('La cantidad ingresada es igual a la existencia actual');
            stopEditingAmount();
            return;
        }

        const stockMovementBatch={
            movements: [{
                productBrand: productBrand.id,
                quantity: changeAmount + '',
                status: stockMovementStatus.COMPLETED
            }],
            warehouse: warehouse.id,
            inOrOut: changeAmount < 0? stockMovementBatchType.OUT:stockMovementBatchType.IN,
            status: stockMovementBatchStatus.COMPLETED
        };

        api.stockMovementBatches.create({stockMovementBatch})
            .then(()=>{
                onChange();
                api.clearProperty(customProp);
                loadInventory();
            });

        stopEditingAmount();
    },[api, customProp, inventory, notifier, loadInventory, onChange, warehouse.id, stopEditingAmount, units,amountUnitInput.value, amountInput.value, productBrand]);

    const [editingBarcodes, startEditingBarcodes, stopEditingBarcodes]=useBoolean();
    const [productBrandUnits, setProductBrandUnits]=useState([...productBrand.productBrandUnits]);
    const handleBarcodeChange=useCallbackCreator((index, {target})=>{
        const newPBUs= [...productBrandUnits];
        newPBUs[index]={...newPBUs[index]};
        newPBUs[index].barcode=target.value;
        setProductBrandUnits(newPBUs);
    },[productBrandUnits]);

    const saveNewBarcodes=useCallback(()=>{

        const id=productBrand.id;

        const serverPBUs=productBrandUnits.map(pbu=>({...pbu, unit: pbu.unit.id}));
        const newProductBrand ={id,productBrandUnits:serverPBUs};
        api.productBrands.edit({id, productBrand: newProductBrand})
            .then(()=>{
                onChange();
                setProductBrand({...productBrand, productBrandUnits});
                stopEditingBarcodes();
            });

    },[api, productBrandUnits, onChange, productBrand, setProductBrand, stopEditingBarcodes]);

    const newPhotoRef = useRef(null);
    const [previewImage, setPreviewImage]=useState(null);
    const onPhotoSelect = ( {target} )=>{

        if( !target.files.length ) {
            newPhotoRef.current = null;
            setPreviewImage( null );
            return;
        }

        const ext = _.last( target.files[0].name.split('.') ).toLowerCase();
        if( ext !== 'jpg' && ext !== 'jpeg' && ext !== 'png' ) {
            newPhotoRef.current = null;
            setPreviewImage(null);
            return notifier.error('Debes seleccionar una imagen de tipo jpg o png');
        }

        newPhotoRef.current = target.files[0];

        var reader = new FileReader();
        reader.onload = (e)=> setPreviewImage( e.target.result );
        reader.readAsDataURL(target.files[0]);
    };

    let photoSrc;
    if(previewImage)
        photoSrc=previewImage;
    else if(productBrand.photo)
        photoSrc=api.appFiles.getUrl( productBrand.photo.id );
    else if (productBrand.product.photo)
        photoSrc=api.appFiles.getUrl( productBrand.product.photo.id );
    else
        photoSrc=noProductImg;

    const saveImage=useCallback(()=>{
        setPreviewImage(null);
        api.productBrands.edit({id:productBrand.id, productBrand:{}, files:{photo: newPhotoRef.current}})
            .then(editedProductBrand=>{
                setProductBrand({...productBrand, photo: editedProductBrand.photo});
            });
    },[api, setPreviewImage, setProductBrand, productBrand ]);

    return (
        <Modal show={true} onHide={onHide} className='InventoryModeFormModal'>
            <ModalHeader closeButton>
                <ModalTitle>Inventario de {productBrandName(productBrand)}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className=''>
                    {loadingInventory&&<p>Cargando...</p>}
                    {inventory?
                        <p className='actual-inventory'>Inventario actual: {displayAmount(inventory.stockAmount, productBrand.product)}</p>
                        :!loadingInventory?
                            <p>Actualmente sin existencia</p>:
                            null}
                    {editingAmount ?
                        <div>
                            <p>Introduce conteo real:</p>
                            <div className='amount-input-block'>
                                <input className='form-control amount-input' type='number' {...amountInput.bind}/>
                                <select className='dropdown' {...amountUnitInput.bind}>
                                    {units.map(unit=><option value={unit.id} key={unit.id}>{unit.name}</option>)}
                                </select>
                            </div><br/>
                            <CustomButton bsStyle='success' className='pull-right' onClick={saveNewInventory}>Guardar inventario</CustomButton>
                            <div className='clearfix'/>
                        </div>
                        :
                        <CustomButton bsStyle='primary' onClick={startEditingAmount}>Editar inventario</CustomButton>
                    }
                    <hr/>
                    <h4>Códigos de barra:</h4>
                    {(editingBarcodes? productBrandUnits:productBrand.productBrandUnits).map((pbu, index)=>
                        <div key={pbu.id}>
                            <p>{pbu.unit.name}: {editingBarcodes?'':(pbu.barcode? pbu.barcode:'Sin código')}</p>
                            {editingBarcodes&&
                            <input className='form-control' placeholder='Código de barras...' value={pbu.barcode||''} onChange={handleBarcodeChange(index)} />}
                        </div>
                    )}
                    <br/>
                    {editingBarcodes?
                    <CustomButton bsStyle='success' className='pull-right' onClick={saveNewBarcodes}>Guardar códigos</CustomButton>:
                    <CustomButton bsStyle='primary' onClick={startEditingBarcodes}>Editar códigos</CustomButton>}

                    <div className='clearfix'/>
                    <hr/>
                    <div className='text-center'>
                        <img src={ photoSrc}
                             alt='Producto'
                             className='product-photo'/>
                    </div><br/>

                    {previewImage ?
                        <CustomButton bsStyle='success' className='pull-right' onClick={saveImage}>Guardar foto</CustomButton> :
                        <CustomButton bsStyle='primary' className='edit-photo-btn'>Editar foto <input type='file' onChange={onPhotoSelect}/></CustomButton>
                    }
                    <div className='clearfix'/>

                </div>
            </ModalBody>
            <ModalFooter>
                <CustomButton bsStyle='danger' onClick={onHide}>Cerrar</CustomButton>
            </ModalFooter>
        </Modal>
    );
};

export default InventoryModeFormModal;
