import React, {Component} from 'react';
import Card from '../../../../components/Card/Card';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Table} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import HappinessFace from '../../../../components/Helper/HapinnessFace';
import LoaderIcon from "../../../../components/Helper/LoaderIcon";
import moment from 'moment';
import _ from 'lodash';
import {UserDetailApiProp, UserDetailLoadingId} from "../../Employees/core/UserDetail";
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";


class LastHappinessLogs extends Component {

    static contextTypes = { api: PropTypes.object };

    loadingId = "@Class.LastHappinessLogs.happinessLogs.get";

    state = { happinessLogs: [], hasMoreLogs:true };

    componentDidMount(){
        this.loadMoreLogs(0);
    }

    loadMoreLogs = ( page, reset )=>{

        const filters = {'order[createdDate]':'DESC'};
        if( this.state.nameFilter )
            filters['employee.name'] = this.state.nameFilter;

        this.context.api.happinessLogs.get( page, 20, this.loadingId, filters )
            .then( ( newLogs )=>{
                if( newLogs.length ) {
                    let happinessLogs;
                    if( reset )
                        happinessLogs = [ ...newLogs];
                    else
                        happinessLogs = [...this.state.happinessLogs, ...newLogs];
                    this.setState({happinessLogs});
                }
                else
                    this.setState( {hasMoreLogs:false} );
            })
    };

    loadMoreLogsDebounced = _.debounce( this.loadMoreLogs, 600 );

    changedFilter = ( {target:{value}} )=>{

        this.setState( {nameFilter: value, happinessLogs:[], hasMoreLogs:true } );

        this.loadMoreLogsDebounced( 0, true );

    };

    showUserDetail = ( e )=>{
        const id = e.target.dataset.employee;
        if( id)
            this.context.api.users.get(0,1,UserDetailLoadingId,{employee:id},UserDetailApiProp );
    };

    render() {

        return (
            <Card
                title={<span>Últimos registros de felicidad</span>}
                category={<input className='form-control' placeholder='Filtra por nombre' onChange={this.changedFilter} value={this.state.nameFilter} />}
                className='LastHappinessLogs'
                content={
                    <div style={{height:400,overflow:'auto'}}>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadMoreLogs}
                            hasMore={!!this.state.happinessLogs.length && this.state.hasMoreLogs }
                            loader={<div className="loader" key={0}> <LoaderIcon /> Cargando más ...</div>}
                            useWindow={false}
                        >
                            <Table>
                                <tbody>
                                { this.state.happinessLogs.map((hl)=>
                                        <tr key={hl.id}>
                                            <td><span className='employee-link' onClick={this.showUserDetail} data-employee={hl.employee? hl.employee.id:''}>{employeeFullName(hl.employee)}</span></td>
                                            <td>{hl.employee && hl.employee.lounge? hl.employee.lounge.name: ''}</td>
                                            <td>{moment(hl.createdDate).format('DD/MM/YYYY hh:mm')}</td>
                                            <td><HappinessFace level={hl.happinessLevel} style={{height:30}}/></td>
                                        </tr>
                                )}
                                </tbody>
                            </Table>
                        </InfiniteScroll>
                    </div>
                }
            />

        );
    }
}

const mapStateToProps = ( {api:{happinessLogs=[], happinessLogsMeta}} )=>({happinessLogs, happinessLogsMeta});

export default connect( mapStateToProps )(LastHappinessLogs);
