import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Card from '../../../components/Card/Card.jsx';
import SimpleBlockTable from '../../../components/Table/SimpleBlockTable';
import {connect} from 'react-redux';

class Areas extends Component{

    constructor(props, context){
        super(props);
        this.areasApi = context.api.areas;
        context.api.areas.get();
    }

    render(){

        return(
            <Card
                title="Administración de áreas"
                content={
                    <SimpleBlockTable
                        data = {this.props.areas}
                        accessor = "name"
                        idAccessor = "id"
                        text = {{
                            addModal:"Nueva área:",
                            addPlaceholder:"Nombre",
                            deleteConfirm:"¿Estás seguro que deseas borrar esta área?",
                            editModal:"Editar nombre del área:"
                        }}
                        onAdd = {this.areasApi.create}
                        onDelete = {this.areasApi.delete}
                        onEdit = {this.areasApi.edit}
                    />
                }
            />
        );
    }
}

Areas.contextTypes = { api: PropTypes.object.isRequired };

function mapStateToProps( state ) {
    const { api } = state;
    const areas = api.areas? api.areas:[];

    return { areas };
}

export default connect(mapStateToProps)(Areas);
