/**
 * Multiple helper functions to extract data from questionnaires
 */
import _ from 'lodash';


/**
 * getQuestionnaireScore
 * Get the score for a filledQuestionnaire by doing an average of each question's score
 *
 *
 * @param filledQuestionnaire
 * @returns {boolean|object} Return the filledQuestionnaire score
 */
const getQuestionnaireScore =
    ( filledQuestionnaire )=>{

        if( !filledQuestionnaire || !filledQuestionnaire.answers || !filledQuestionnaire.answers.length )
            return false;

        const answers = sortQuestionnaire( filledQuestionnaire.answers );
        const groups = splitInGroups( answers );
        const groupScores = [];

        _.forEach( groups, ( group )=>{

            let scorableGroupCount = 0;
            let scoreGroupSum = 0;

            _.forEach( group, (answer)=>{

                //Not scorable questions are ignored for this means, haha means, if you know what I mean
                if( !isQuestionScorable( answer.question ) )
                    return;

                scorableGroupCount++;
                scoreGroupSum += getAnswerScore( answer );
            });

            //if( group[0].question.config && group[0].question.config.group )
                groupScores.push({
                    group: group[0].question.config && group[0].question.config.group? group[0].question.config.group:false,
                    count: scorableGroupCount,
                    score: scorableGroupCount? scoreGroupSum / scorableGroupCount : false
                });

        });

        let scorableQuestionCount = 0;
        let scoreSum = 0;

        _.forEach( groupScores, ( groupScore )=>{

            if( groupScore.score === false ) return;

            /*
            // IMPORTANT
            // This is another way to calculate the score, it's different only if there are groups in the questionnaire
            // This will calculate the average of every single question, instead of the average of the groups scores
            scorableQuestionCount += groupScore.count;
            scoreSum += groupScore.score * groupScore.count;
            */

            scorableQuestionCount ++;
            scoreSum += groupScore.score;
        });

        if( !scorableQuestionCount )
            return false;

        return {
            count: scorableQuestionCount,
            score: scoreSum / scorableQuestionCount,
            groupScores
        };
    };

/**
 * Gets the score of a question's answer, how to get it depends on the question type
 *
 * @param answer
 * @returns {number}
 */
const getAnswerScore =
    ( answer )=>{

        const response = answer.data.response;
        const question = answer.question;
        const config = question.config;

        if( question.type === 'percentage' || question.type === 'number' ) {

            const amount = question.type === 'number' ? response : (response.real / response.goal);

            if( !config || !config.rangedValues || !config.rangeValues || !config.rangeValues.length )
                return amount;

            for( let i=0; i<config.rangeValues.length; i++ ){
                const range = config.rangeValues[i];

                if( amount >= range.min && amount <= range.max )
                    return range.value;
            }

        }

        if( question.config.optionsValues && question.type === 'yesno' ){
            if( response && question.config.optionsValues['Si'] )
                return question.config.optionsValues['Si'];
            if( response && question.config.optionsValues['No'] )
                return question.config.optionsValues['No'];
        }

        if( question.config.optionsValues && question.config.optionsValues[ response ] )
            return question.config.optionsValues[ response ];

        return 0;
    };

/**
 * Says wether we can get a score of a question or not ( e.g. It's imposible to get a value from a free text answer )
 *
 * @param question
 * @returns {boolean}
 */
const isQuestionScorable =
    ( question )=> {

        return !!(question.type === 'number' ||
            question.type === 'percentage' ||
            (question.config && question.config.optionsValues)
        );
    };

/**
 * Says wether we can get a score of a questionnaire by searching any scorable question in it
 *
 * @param questionnaire
 * @returns {boolean}
 */
const isQuestionnaireScorable =
    ( questionnaire )=> {

        for( let i=0; i<questionnaire.questions; i++ )
            if( isQuestionScorable( questionnaire.questions[i] ) ) return true;

        return false;
    };

/**
 * This will make an array of arrays, each inner array containing questions with the same 'group' property.
 * Note that they should be contiguous to be linked in the same array
 *
 * @param questionsOrAnswers
 * @returns {Array}
 */
const splitInGroups = ( questionsOrAnswers )=>{

    if( !questionsOrAnswers || !questionsOrAnswers.length )
        return [];

    let questions = questionsOrAnswers ;
    if( questions[0].question ) // We received an array of answers
        questions = questionsOrAnswers.map( answer=>answer.question );


    const splitted = [];
    let actualGroupName = questions[0].config && questions[0].config.group? questions[0].config.group:false;
    let actualGroupQuestions = [ questionsOrAnswers[0] ];

    for( let i=1; i< questions.length; i++ ){

        const groupName = questions[i].config && questions[i].config.group? questions[i].config.group:false;

        if( actualGroupName === groupName )
            actualGroupQuestions.push( questionsOrAnswers[i] );
        else{
            splitted.push( actualGroupQuestions );
            actualGroupQuestions = [ questionsOrAnswers[i] ];
            actualGroupName = groupName;
        }
    }

    splitted.push( actualGroupQuestions );
    return splitted;

};

const sortQuestionnaire = ( questionsOrAnswers )=>{
    return _.sortBy( questionsOrAnswers, qOrA=> qOrA.question?
        ( qOrA.question && qOrA.question.config ? ( qOrA.question.config.order||0 ):0  )//It's an answer
        :
        ( qOrA.config? ( qOrA.config.order||0 ):0  ) );//It's a question ( or an answer without order)
};

export default {
    getQuestionnaireScore,
    getAnswerScore,
    isQuestionScorable,
    isQuestionnaireScorable,
    splitInGroups,
    sortQuestionnaire,
};