import React, {Component} from 'react';
import Card from "../Card/Card";
import questionTypes from './questionTypes';
import _ from 'lodash';


class QuestionStat extends Component{

    render() {

        const {question, type, stats, questionConfig} = this.props.result;
        const AnswerStatComponent = answerStatComponent( type );

        return (
            <Card
                title={<span>{question} {questionConfig && questionConfig.inactive?<span className='text-danger'>(inactiva)</span>:''}</span>}
                content={
                    <AnswerStatComponent
                        stats={stats}
                        result={ this.props.result }
                        filters={ this.props.filters }
                    />
                }/>
        )
    }
}

function answerStatComponent(  questionType ){

    const definition = _.find(questionTypes, (def)=>def.type === questionType );

    if( !definition )
        return ()=><span>{questionType}</span>;

    return definition.statComponent;

}

export default QuestionStat;
