import avatar from "../../../assets/img/faces/face-0.jpg";
import userFulfillmentColor from "../../userFulfillmentColor";
import defaultFace from "../../../assets/img/faces/face-0.jpg";
import moment from "moment";
import moneyFormatter from "../../moneyFormatter";
import React from "react";
import {displayDateFormat} from "../../timeUtils";
import {getColumnId} from "../../../components/TableConfigurator/tableConfiguratorUtils";
import {querySerialize} from "../../../api/Api";


export const employeeFullName = (employee)=>{
    return employee? (employee.name+" "+(employee.patLastName||'')+" "+(employee.matLastName||'')):""
};

export const avatarUrl = (employee, api)=>employee && employee.photo? api.createFileUrl(employee.photo) : avatar;

export const getUserExportUrl = (api, columns, filters)=>{
    const headers = columns.reduce( (headers, column)=>({...headers, [getColumnId(column)]:column.exportHeader||column.Header}), {});

    const reportConfig = {name: 'Usuarios de Alma 2.0.xlsx', headers};

    const query = querySerialize({
        ...filters,
        pagination:false,
        token: api.token,
        reportConfig: JSON.stringify(reportConfig)
    });

    return `${api.host}/api/users.xlsx?${query}`;
}

export const availableEmployeeColumns = ({ tableContext })=>[
    {
        Header: 'ID de usuario',
        accessor: 'id',
        width: 120,
        className: 'text-center',
        isDefault: false,
        sGroups:['user_read_id'],
    },{
        Header: 'ID de empleado',
        accessor: 'employee.id',
        width: 120,
        className: 'text-center',
        isDefault: false,
        sGroups:['user_read_employee', 'employee_read_id'],
    },
    {
        Header: 'No. empleado',
        accessor: 'username',
        width: 120,
        className: 'text-center',
        isDefault: true,
        sGroups:['user_read_username'],
    },
    {
        Header: 'Calificación GCare',
        id: 'employee.autoScoreValue',
        accessor: (user)=>getAutoScoreText(user.employee),
        isDefault: true,
        filterable: false,
        sGroups:['user_read_employee', 'employee_read_auto_score_value'],
    },
    {
        Header: 'Foto',
        exportHeader: 'Cumplimiento',
        accessor: (user)=>
            <div className='list-photo-container' style={{borderColor: userFulfillmentColor( user?.employee?.fulfillmentScore ) }}>
                <img alt='Profile' src={ user.employee?.photo? tableContext.api.appFiles.getUrl( user.employee.photo.id ) : defaultFace } />
            </div>
        ,
        id: 'employee.fulfillmentScore',
        filterable: false,
        width: 70,
        isDefault: true,
        sGroups:['user_read_employee', 'employee_read_photo', 'app_file_read', 'employee_read_fulfillment_score'],
    },
    {
        Header: 'Nombre',
        accessor: 'employee.name',
        isDefault: true,
        sGroups:['user_read_employee', 'employee_read_name'],
    },
    {
        Header: 'Apellidos',
        accessor: (user)=>user.employee?.patLastName+" "+user.employee?.matLastName,
        id: 'employee.patLastName',
        isDefault: true,
        sGroups:['user_read_employee', 'employee_read_pat_last_name', 'employee_read_mat_last_name'],
    },
    {
        Header: 'Género',
        accessor: 'employee.sex',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_sex'],
    },
    {
        Header: 'F. de nacimiento',
        accessor: u=>u.employee.birthday? moment(u.employee.birthday).format(displayDateFormat):'Sin información' ,
        id: 'employee.birthday',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_birthday'],
    },
    {
        Header: 'Teléfono',
        accessor: 'employee.homePhone',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_home_phone'],
    },
    {
        Header: 'Celular',
        accessor: 'employee.cellPhone',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_cell_phone'],
    },
    {
        Header: 'CURP',
        accessor: 'employee.curp',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_curp'],
    },
    {
        Header: 'RFC',
        accessor: 'employee.rfc',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_rfc'],
    },
    {
        Header: 'Fecha contratación',
        accessor: u=>u.employee.hireDate? moment(u.employee.hireDate).format(displayDateFormat):'Sin información' ,
        id: 'employee.hireDate',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_hire_date'],
    },
    {
        Header: 'Tipo de usuario',
        accessor: user=>user?.role?.title||"Básico",
        id: 'role.title',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_role', 'role_read_title'],
    },
    {
        Header: 'Sala',
        accessor: 'employee.lounge.name',
        isDefault: true,
        sGroups:['user_read_employee', 'employee_read_lounge', 'lounge_read_name'],
    },
    {
        Header: 'Puesto',
        accessor: 'employee.position.name',
        isDefault: true,
        sGroups:['user_read_employee', 'employee_read_position', 'position_read_name'],
    },
    {
        availabilityCheck: ({securityManager})=>securityManager.alma.canSeeEmployeesWage(),
        Header: 'Salario',
        accessor: user=>moneyFormatter(user.employee.salary),
        id: 'employee.displaySalary',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_salary', 'employee_read_display_salary'],
    },
    {
        availabilityCheck: ({securityManager})=>securityManager.alma.canSeeEmployeesWage(),
        Header: 'Método de pago',
        accessor: 'employee.paymentMethod',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_payment_method'],
    },
    {
        availabilityCheck: ({securityManager})=>securityManager.alma.canSeeEmployeesWage(),
        Header: 'Outsourcing',
        accessor: u=>u.employee.hiredByOutsourcing?"Si":"No",
        id: 'employee.hiredByOutsourcing',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_hired_by_outsourcing'],
    },
    {
        availabilityCheck: ({securityManager})=>securityManager.alma.canSeeEmployeesWage(),
        Header: 'Banco',
        accessor: 'employee.bank.name',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_bank', 'bank_read_name'],
    },
    {
        availabilityCheck: ({securityManager})=>securityManager.alma.canSeeEmployeesWage(),
        Header: 'Cuenta banco',
        accessor: 'employee.bankAccount',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_bank_account'],
    },
    {
        availabilityCheck: ({securityManager})=>securityManager.alma.canSeeEmployeesWage(),
        Header: 'Cuenta CLABE',
        accessor: 'employee.clabe',
        isDefault: false,
        filterable: false,
        sortable: false,
        sGroups:['user_read_employee', 'employee_read_clabe'],
    },
    {
        Header: 'Turno',
        accessor: 'employee.shift.name',
        isDefault: tableContext.areHired,
        sGroups:['user_read_employee', 'employee_read_shift', 'shift_read_name'],
    },{
        Header: 'Área',
        accessor: 'employee.area.name',
        isDefault: false,
        sGroups:['user_read_employee', 'employee_read_area', 'area_read_name'],
    }, {
        Header: 'Grupo',
        accessor: 'employee.group.name',
        isDefault: tableContext.areHired,
        sGroups:['user_read_employee', 'employee_read_group', 'group_read_name'],
    }, {
        Header: 'Supervisando grupo',
        accessor: 'employee.owningGroup.name',
        isDefault: false,
        sGroups:['user_read_employee', 'employee_read_owning_group', 'group_read_name'],
    },
    //For fired users list
    {
        availabilityCheck: ()=>!tableContext.areHired,
        Header: 'Baja',
        accessor: (user)=>moment(user.employee.layoffDate).format("DD/MM/YYYY"),
        id: 'employee.layoffDate',
        isDefault: !tableContext.areHired,
        sGroups:['user_read_employee', 'employee_read_layoff_date'],
    },
    {
        availabilityCheck: ()=>!tableContext.areHired,
        Header: 'Terminación',
        accessor: (user)=>{
            if( !user?.employee?.layoffs?.length )
                return '-';
            const layoff = user.employee.layoffs[ user.employee.layoffs.length-1 ];

            return layoff.layoffRequest?.layoffRequestDate?
                moment(layoff.layoffRequest.layoffRequestDate).format("DD/MM/YYYY"):'-';
        },
        id: 'employee.layoffs.layoffRequest.layoffRequestDate',
        isDefault: !tableContext.areHired,
        sGroups:['user_read_employee', 'employee_read_layoffs', 'layoff_read_layoff_request', 'layoff_request_read_layoff_request_date'],
    },
    {
        availabilityCheck: ({securityManager})=>!tableContext.areHired && securityManager.alma.canUpdateLayoffs(),
        Header: 'Finiquito/Liquidación',
        accessor: (user)=>{
            if( !user?.employee?.layoffs?.length )
                return '';
            const layoff = user.employee.layoffs[user.employee.layoffs.length - 1];
            return <span className='link' onClick={(e) => tableContext.settlementDetail(user, e)}>
                {layoff.settlementAmount? moneyFormatter(layoff.settlementAmount)+" "+(layoff.isFiniquito?"F":"L"):"Añadir"}
            </span>;
        },
        id: 'employee.layoffs.settlementAmount',
        isDefault: !tableContext.areHired,
        sGroups:['user_read_employee', 'employee_read_layoffs', 'layoff_read'],
    },
    {
        Header: 'Calificación',
        id: 'employee.manualScoreValue',
        accessor: (user)=>getManualScoreText(user.employee),
        isDefault: true,
        sGroups:['user_read_employee', 'employee_read_manual_score_value', 'employee_read_manual_score_name'],
    }
];

const getManualScoreText = (employee) => {
    return (employee?.manualScoreValue ? `${employee?.manualScoreName}: ${employee?.manualScoreValue}` : '');
}

const getAutoScoreText = (employee) => {
    return (`Calificación: ${employee?.autoScoreValue}`);
}

export const userEmployeeNameSearchSGroups=[
    'user_read_id',
    'user_read_employee',
    'employee_read_id',
    'employee_read_name',
    'employee_read_pat_last_name',
    'employee_read_mat_last_name',
];

export const userEmployeeNameSearchAndShiftSGroups=[
    'user_read_id',
    'user_read_employee',
    'employee_read_id',
    'employee_read_name',
    'employee_read_pat_last_name',
    'employee_read_mat_last_name',
    'employee_read_shift',
        'shift_read',
        'lounge_read'
];

export const userEmployeeExcelSGroups=[
    'user_read_employee',
    'employee_read_id',
    'employee_read_name',
    'employee_read_pat_last_name',
    'employee_read_mat_last_name',
    'employee_read_fulfillment_score',
    'employee_read_lounge',
        'lounge_read',
    'employee_read_position',
        'position_read',
    'employee_read_shift',
        'shift_read',
    'employee_read_group',
        'group_read_name',
        'group_read_owner',
    'employee_read_hire_date',
    'employee_read_layoff_date',
];

export const employeeFiredListSGroups=[
    'user_read_id',
    'user_read_username',
    'user_read_employee',
        'employee_read_id',
        'employee_read_name',
        'employee_read_pat_last_name',
        'employee_read_mat_last_name',
        'employee_read_fulfillment_score',
        'employee_read_layoff_date',
        'employee_read_lounge',
            'lounge_read',
        'employee_read_area',
            'area_read',
        'employee_read_photo',
            'app_file_read',
        'employee_read_position',
            'position_read_id',
            'position_read_name',
        'employee_read_layoffs',
            'layoff_read_id',
            'layoff_read_layoff_date',
            'layoff_read_settlement_amount',
            'layoff_read_is_finiquito',
            'layoff_read_settlement_comment',
            'layoff_read_layoff_request',
                'layoff_request_read_id',
                'layoff_request_read_layoff_request_date',
];
