import React,{useMemo, useCallback} from 'react';
import useTideEntity from "../../../hooks/useTideEntity";
import AddStockMovement from "../movements/AddStockMovement";
import _ from 'lodash';
import {detailSerializationGroups, stockRequestStatus} from "../../../utils/modelUtils/almazen/stockRequestUtils";
import {stockMovementBatchStatus} from "../../../utils/modelUtils/almazen/stockMovementBatchUtils";


const ReceiveProductRequisition=({match, history})=>{

    const [stockRequest, loading, notFound]=useTideEntity(
        'stockRequests',
        match.params.id,
        {customProp:'ProductRequisitionDetail', requestFilters:{sGroups:detailSerializationGroups}});

    let stockMovementBatch=useMemo(()=> {
        if (!stockRequest)
            return null;
        //Filter the place reception stockMovementBatches
        const stockMovementBatches = _.filter(stockRequest.stockMovementBatches,
                smb =>
                    smb.warehouse.id === stockRequest.toPlace.id &&
                    smb.inOrOut === 'in' &&
                    smb.status === stockMovementBatchStatus.PENDING
        );
        const stockMovementBatch = stockMovementBatches[0];

        if(stockMovementBatch)
            stockMovementBatch.stockRequest=stockRequest;
        return  stockMovementBatch;
    }, [stockRequest]);

    const handleBatchSaved=useCallback(()=>{
        history.push('/almazen/product-requisition');
    },[history]);

    if(stockRequest && stockRequest.status===stockRequestStatus.COMPLETED)
        history.replace('/almazen/product-requisition');

    return(
        <div className='ReceiveRequisition container-fluid main-container '>
            <h2>
                {stockRequest&&!stockMovementBatch&&(`La requisición ${stockRequest.folio} no tiene entradas pendientes`)}
                {notFound&&'Requisición no encontrada'}
                {loading&&'Cargando...'}
            </h2>
            {stockRequest && stockMovementBatch &&
                <AddStockMovement
                    currentWarehouse={stockRequest.toPlace}
                    stockMovementBatch={stockMovementBatch}
                    preventAddingProducts
                    onBatchSaved={handleBatchSaved}
                    cassetteId={stockRequest.cassette.id}
                />
            }
        </div>
    );
};

export default ReceiveProductRequisition;
