import React, {Component} from 'react';

export default class FileViewer extends Component{

    constructor(props) {
        super(props);

        this.isImage = false;
        this.isIframe = false;

        let isImageLoading = false;


        if( props.name ) {
            const extension = props.name.split(".").splice(-1, 1);
            if( extension.length && inlineImgExtensions.indexOf(extension[0].toLowerCase()) !== -1 ) {
                this.isImage = true;
                isImageLoading = true;
            }
            else if( extension.length && inlineIframeExtensions.indexOf(extension[0].toLowerCase()) !== -1 )
                this.isIframe = true;
        }

        this.state = {
            isImageLoading
        };
    }

    render() {
        return (
            <div className={"FileViewer" + (this.state.isImageLoading ? " imageLoading" : "")}>
                {this.isImage ?
                    <img src={this.props.src}
                         alt="File"
                         onLoad={() => {
                             this.setState({isImageLoading:false})
                         }}
                    /> :
                    (this.isIframe?
                    <iframe src={this.props.src} title={this.props.name ? this.props.name : Math.random()}/>
                    :<p>Archivo sin vista previa.</p>
                    )
                }
            </div>
        );
    }
}

const inlineImgExtensions = ["png","jpg","jpeg","bmp","gif"];
const inlineIframeExtensions = ["pdf","html","txt","mp4","mp3"];
