import React, {Component} from 'react';
import questionTypes from './questionTypes';
import _ from 'lodash';
import questionnaireHelper from './helper';


class Question extends Component{

    handleAnswerChange = ( data )=>{

        if( !this.props.editable )
            return;

        this.props.onAnswer(
            this.props.question,
            data
        )
    };

    render() {
        const isGcare = this.props.questionnaire.isGcare;
        const {index, question, data} = this.props;
        const AnswerComponent = answerComponent( question.type );

        return (
            <div className={ 'Question well' }>
                <p>
                    {index ? (index + '.- ') : null}
                    {question.text}
                </p>
                <AnswerComponent
                    data={data}
                    config={question.config}
                    onChange={this.handleAnswerChange}
                    editable={this.props.editable}
                />
                { !isGcare&&(!this.props.editable && questionnaireHelper.isQuestionScorable( question ))?
                    <p className='text-muted small'>Puntaje: { questionnaireHelper.getAnswerScore( { data, question }) }</p>
                    :null}
            </div>
        )
    }
}

function answerComponent(  questionType ){

    const definition = _.find(questionTypes, (def)=>def.type === questionType );

    if( !definition )
        return ()=><span>{questionType}</span>;

    return definition.answerComponent;
}

export default Question;
