import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './_RecipeGallery.scss';
import {TideApiContext} from "../../../../../../api/tideApiConfig";
import {ApiContext} from "../../../../../../api/Api";
import _ from 'lodash';
import {SecurityContext} from "../../../../../../utils/SecurityManager";

const RecipeGallery = ({ recipe, onChange }) => {

    const tideApi = useContext(TideApiContext);
    const security = useContext(SecurityContext);
    const api = useContext(ApiContext);
    const [showingPic, setShowingPic] = useState(null);

    const images = useMemo(()=>{
        return recipe?.gallery||[];
    },[recipe]);

    const nextImage = useCallback(()=>{
        setShowingPic((showingPic)=>{
            if(showingPic >= (images.length -1))
                return 0
            return showingPic+1;
        })
    },[images]);

    const prevImage = useCallback(()=>{
        setShowingPic((showingPic)=>{
            if(showingPic <= 0)
                return images.length -1
            return showingPic-1;
        })
    },[images]);

    const isShowingPic = showingPic!==null;
    useEffect(()=>{
        if(!isShowingPic) return;

        const arrowsHandler = (e)=>{
            if(e.keyCode === 37)//left
                prevImage();
            if(e.keyCode === 39)//right
                nextImage();
        };
        window.document.addEventListener("keydown", arrowsHandler);
        return ()=>window.document.removeEventListener("keydown", arrowsHandler);
    },[nextImage, prevImage, isShowingPic]);

    const handleAddImage = useCallback((e)=>{
        if(!e?.target?.files?.length)
            return;
        tideApi.orozcoRecipes.update({ id: recipe.id, params:{}, files:{gallery:e.target.files[0]} })
            .then(onChange);
    },[tideApi, recipe, onChange]);

    const handleDelete = useCallback((e, id)=>{
        e.stopPropagation();
        console.log(id);
        const remainingIds = _.filter( _.map(recipe.gallery, 'id'), (imageId)=>id!==imageId);
        tideApi.orozcoRecipes.update({ id: recipe.id, params:{gallery: remainingIds} })
            .then(onChange);
    },[recipe, tideApi, onChange]);

    return (
        <div className={"RecipeGallery"}>
            <h3>Galería</h3>
            <ul className='gallery-list'>
                {images.map((image, i)=>
                    <li className='gallery-item' key={i} onClick={()=>setShowingPic(i)}
                        style={{backgroundImage:`url("${api.appFiles.getUrl(image.id)}")`}}>
                        {security.katharsis.canEditRecipes() &&
                        <i className='fa fa-trash red-icon delete-btn' onClick={(e)=>handleDelete(e, image.id)} />}
                    </li>
                )}
                {security.katharsis.canEditRecipes() &&
                <li className='gallery-item add-block'>
                    <i className='fa fa-plus-square' />
                    <input type="file" className='image-input' onChange={handleAddImage}/>
                </li>}
            </ul>
            {showingPic !== null &&
            <div className='image-visor'>
                <div
                    className='big-image'
                    style={{backgroundImage: `url("${api.appFiles.getUrl(images[showingPic].id)}")`}}
                />
                <button className='arrow left' onClick={prevImage}><i className='fa fa-arrow-circle-left'/></button>
                <button className='arrow right' onClick={nextImage}><i className='fa fa-arrow-circle-right'/></button>
                <button className='close-but' onClick={()=>setShowingPic(null)}><i className='fa fa-times-circle'/></button>
            </div>
            }
        </div>
    );
};

export default RecipeGallery;
