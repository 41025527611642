import React, {useState, useEffect, useContext, useCallback} from 'react';
import {Col, Row} from "react-bootstrap";
import AddStockMovement from "./AddStockMovement";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import useTideEntity from "../../../hooks/useTideEntity";
import useBoolean from "../../../hooks/useBoolean";
import PendingMovementsModal from "./PendingMovementsModal";
import {ApiContext} from "../../../api/Api";
import {stockMovementBatchType} from "../../../utils/modelUtils/almazen/stockMovementBatchUtils";
import {useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';


const StockMovements = ({match}) => {

    const [searchingBatch, startSearchingBatch, stopSearchingBatch] = useBoolean();
    const [selectedBatch, setSelectedBatch] = useState(null);

    const {warehouse: warehouseId, movementType} = match.params;
    const [warehouse, loading, notFound] = useTideEntity('warehouses', warehouseId);
    const pendingMovements = useSelector((state) => state.api.warehousePendingMovements) || [];
    const history = useHistory();

    if(movementType!==stockMovementBatchType.OUT && movementType!==stockMovementBatchType.IN && movementType!==stockMovementBatchType.SHRINKAGE)
        history.replace('/');

    const movementString = movementType === stockMovementBatchType.IN ? 'Entrada' : movementType===stockMovementBatchType.OUT?'Salida':'Merma';

    const api = useContext(ApiContext);
    useEffect(() => {
        api.clearProperty('stockMovementBatches');//Clear Redux to prevent a flash of the wrong stockMovementBatches in the Modal
        api.warehouses.getPendingMovements({warehouseId})
    }, [api, movementType, warehouseId]);

    const clearBatch = useCallback(() => {
        setSelectedBatch(null);
        if(movementType!==stockMovementBatchType.SHRINKAGE)
            api.warehouses.getPendingMovements({warehouseId})
    }, [warehouseId, api.warehouses, movementType]);

    const pendingMovementsByType = (movementType) => {
        if (movementType === stockMovementBatchType.IN) {
            return pendingMovements ? pendingMovements.pendingBatchesIn : null;
        } else {
            return pendingMovements ? pendingMovements.pendingBatchesOut : null;
        }
    };

    return (
        <div className="container-fluid main-container StockMovements AddStockMovement">
            <Row>
                <Col xs={12}>
                    <h2>
                        {warehouse ? `${movementString} de ${warehouse.name}` :
                            loading ? 'Cargando...' :
                                notFound ? 'Almacén no encontrado' : ''}

                        {movementType!==stockMovementBatchType.SHRINKAGE && warehouse &&
                        <CustomButton className='pull-right pending-movements-button' onClick={startSearchingBatch}>
                            {pendingMovementsByType(movementType)>0 &&
                                < span className="notification">
                                    {pendingMovementsByType(movementType)}
                                </span>
                            }
                            {movementString}s pendientes
                        </CustomButton>}
                    </h2>

                    <div>
                        {warehouse ?
                            <AddStockMovement
                                currentWarehouse={warehouse}
                                movementType={movementType}
                                stockMovementBatch={selectedBatch}
                                onBatchSaved={clearBatch}
                                cassetteId={
                                    selectedBatch&&
                                    selectedBatch.stockRequest&&
                                    selectedBatch.stockRequest.cassette&&
                                    selectedBatch.stockRequest.cassette.id}
                            />
                            : null}
                    </div>

                </Col>
            </Row>

            {searchingBatch &&
            <PendingMovementsModal
                warehouse={warehouse}
                movementType={movementType}
                onHide={stopSearchingBatch}
                onSelect={setSelectedBatch}
            />
            }
        </div>

    );
};

export default StockMovements;
