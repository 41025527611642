import {USER_DETAIL, HIDE_USER_DETAIL} from './types';

export function showUserDetail( user ){

    return {
        type: USER_DETAIL,
        payload: user
    }
}

export function hideUserDetail(){

    return {
        type: HIDE_USER_DETAIL
    }
}