
export const userFullName = (user)=> user?.employee?
    (user.employee.name+" "+(user.employee.patLastName||'')+" "+(user.employee.matLastName||''))
    :"";

export const userPermissionAdminSGroups = [
    "user_read_id",
    "user_read_employee",
        "employee_read_name",
        "employee_read_pat_last_name",
        "employee_read_mat_last_name",
    "user_read_permission_groups",
        "permission_group_read"
];
