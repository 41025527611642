import _ from 'lodash';

export  const positionPermissionsGroups =  ['position_read_permission_groups', 'permission_group_read', 'permission_read', 'position_read'];

export const operationConstants = {
    ADD_GROUP: 'add',
    REMOVE_GROUP: 'remove'
};

export const preparePositionForServer = (position, group, operation) => {
    if (operation === operationConstants.ADD_GROUP) {
        return {
            permissionGroups: [
                ..._.map(position.permissionGroups, 'id'),
                group.id
            ].filter((value, index, self) => {
                return self.indexOf(value) === index
            }),
            sGroups: positionPermissionsGroups
        };
    }else if(operation === operationConstants.REMOVE_GROUP){
        return {
            permissionGroups: _.remove(_.map(position.permissionGroups, 'id'), (permissionGroupId)=>{
                    return permissionGroupId !== group.id
                }
            ),
            sGroups: positionPermissionsGroups
        };
    }
};

