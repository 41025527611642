import React, {Component} from 'react';
import Button from '../../elements/CustomButton/CustomButton';
import {Jumbotron} from 'react-bootstrap';


class ThankYou extends Component{

    render() {

        return (
            <div className={ 'ThankYou text-center' }>
                <Jumbotron>
                    <h1>Gracias.</h1>
                    <p>
                        ¡Esperamos su próxima visita!
                    </p>
                    <p>
                        <Button bsStyle='primary' onClick={this.props.onRepeat}>Hacer de nuevo</Button>
                    </p>
                </Jumbotron>


            </div>
        )
    }
}

export default ThankYou;