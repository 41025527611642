import YesNoAnswer from './QuestionType/YesNoAnswer';
import YesNoAnswerStats from './QuestionTypeStats/YesNoAnswerStats';
import NoConfig from './QuestionEditor/NoConfig';
import FileQuestionEditor from './QuestionEditor/FileQuestion';
import OptionsAnswerStats from './QuestionTypeStats/OptionsAnswerStats';
import MultipleChoiceRadioAnswer from './QuestionType/MultipleChoiceRadioAnswer';
import MultipleChoiceSelectAnswer from './QuestionType/MultipleChoiceSelectAnswer';
import MultipleChoiceEditor from './QuestionEditor/MultipleChoice';
import TextAnswer from './QuestionType/TextAnswer';
import LongTextAnswer from './QuestionType/LongTextAnswer';
import NumberAnswer from './QuestionType/NumberAnswer';
import LoungeAnswer from "./QuestionType/LoungeAnswer";
import ShiftAnswer from "./QuestionType/ShiftAnswer";
import FileAnswer from "./QuestionType/FileAnswer";
import InitialTextEditor from "./QuestionEditor/InitialTextEditor";
import PercentageAnswer from './QuestionType/PercentageAnswer';
import ScrollableStats from './QuestionTypeStats/ScrollableStats';
import PercentageStats from './QuestionTypeStats/PercentageStats';


export default [
    {
        type: 'yesno',
        title: 'Sí o no',
        answerComponent: YesNoAnswer,
        statComponent: YesNoAnswerStats,
        editor: NoConfig,
    },
    {
        type: 'multiple-choice-radio',
        title: 'Opción multiple (una respuesta)',
        answerComponent: MultipleChoiceRadioAnswer,
        statComponent: OptionsAnswerStats,
        editor: MultipleChoiceEditor
    },
    {
        type: 'multiple-choice-select',
        title: 'Opción multiple (varias respuesta)',
        answerComponent: MultipleChoiceSelectAnswer,
        statComponent: OptionsAnswerStats,
        editor: MultipleChoiceEditor
    },
    {
        type: 'text',
        title: 'Texto',
        answerComponent: TextAnswer,
        statComponent: ScrollableStats,
        editor: NoConfig
    },
    {
        type: 'long-text',
        title: 'Texto largo',
        answerComponent: LongTextAnswer,
        statComponent: ScrollableStats,
        editor: NoConfig
    },
    {
        type: 'number',
        title: 'Número',
        answerComponent: NumberAnswer,
        statComponent: OptionsAnswerStats,
        editor: NoConfig
    },
    {
        type: 'lounge',
        title: 'Sala',
        answerComponent: LoungeAnswer,
        statComponent: OptionsAnswerStats,
        editor: InitialTextEditor
    },
    {
        type: 'shift',
        title: 'Turno',
        answerComponent: ShiftAnswer,
        statComponent: OptionsAnswerStats,
        editor: InitialTextEditor
    },
    {
        type: 'percentage',
        title: 'Porcentaje de avance',
        answerComponent: PercentageAnswer,
        statComponent: PercentageStats,
        editor: NoConfig
    },
    {
        type: 'file',
        title: 'Archivos',
        answerComponent: FileAnswer,
        statComponent: ScrollableStats,
        editor: FileQuestionEditor
    },
];