import React, {useContext, useEffect, useState, useCallback, useMemo} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import DateTime from 'react-datetime'
import {ApiContext} from "../../../api/Api";
import {TideApiContext} from "../../../api/tideApiConfig";
import useInput from '../../../hooks/useInput';
import _ from 'lodash';
import EditableStockMovementsTable from '../components/EditableStockMovementsTable';
import {NotifierContext} from "../../../utils/Notifier";
import {stockMovementsArrayToBatchMovement} from "../../../utils/modelUtils/almazen/warehouseProductsUtility";
import EntitySelect from "../../../components/EntitySelect/EntitySelect";
import {stockMovementBatchStatus} from "../../../utils/modelUtils/almazen/stockMovementBatchUtils";
import {convertSmallestUnitToDisplayUnit} from "../../../utils/modelUtils/almazen/unitsUtility";
import {stockRequestStatus} from "../../../utils/modelUtils/almazen/stockRequestUtils";
import {useSelector} from "react-redux";
import moment from 'moment';
import StockRequestGenerator from "./components/StockRequestGenerator";

const loadingId = '@NewProductRequisitionModal.stockRequests.create';
const selectLabelCreator=(pb)=>`${pb.product.name} - ${pb.brand.name}`;
const additionalFilters={'order[product.name]':'ASC', 'product.isActive':true, 'productBrandUnits.isActive':true};

const ProductRequisitionFormModal = ({onHide, onEdit, stockRequest, cloning} )=>{

    const api = useContext( ApiContext);
    const tideApi = useContext(TideApiContext);
    const notifier = useContext( NotifierContext );
    const warehouses=useSelector(({api})=>api.warehouses||[]);
    const places=useSelector(({api})=>api.places||[]);
    const loadingIds=useSelector(({loadingIds})=>loadingIds);
    const [inventories, setInventories] = useState( [] );
    const [fetchedProductBrands, setFetchedProductBrands] = useState( [] );
    const [overFlowingProductBrand, setOverflowingProductBrand] = useState( [] );

    useEffect( ()=>{
        api.warehouses.get({pagination:false});
        api.places.get({isActive: true});

    }, [api] );

    const commentIn = useInput('');
    const [requestedForDate, setRequestedForDate]=useState(moment());
    const [stockMovements, setStockMovements] = useState([]);
    const clearStockMovements = useCallback(()=>setStockMovements([]), []);
    const toIn = useInput('');
    const fromIn = useInput('', clearStockMovements);

    useEffect(()=>{
        if(!stockRequest) return;

        const outMoves=stockRequest.stockMovementBatches.reduce(//De todos los stockMovementBatches del request vamos a agarrar sus stockMovements en un arreglo
            (acc,smb)=>
                smb.inOrOut==='in'?acc:[...acc, ...smb.movements.map(movement=>{//Tomamos los de salida (los de entrada deben ser los mismos)
                    const {quantity, productBrand}=movement;
                    return {
                        quantity: Math.abs(convertSmallestUnitToDisplayUnit(quantity, productBrand.product)),//Copiamos el valor convertido a displayUnit
                        selectedUnit: productBrand.product.displayUnit?productBrand.product.displayUnit:productBrand.product.smallestUnit,//siempre aparecerá seleccionada la displayUnit
                        productBrand
                    }
                })]
        ,[]);
        setStockMovements(outMoves);
        fromIn.setValue(stockRequest.fromWarehouse.id);
        toIn.setValue(stockRequest.toPlace.id);
        // eslint-disable-next-line
    },[stockRequest]);

    useEffect(()=>{
        setOverflowingProductBrand([]);
        setFetchedProductBrands([]);
    },[toIn.value]);

    useEffect(() => {
        let ids = [];
        stockMovements.forEach((sm) => {
            if (!_.includes(fetchedProductBrands, sm.productBrand.id))
                ids.push(sm.productBrand.id);
        });
        if (ids.length > 0) {
            tideApi.inventories.get({ params: { productBrand: ids, warehouse:toIn.value  }}).then((response) => {
                setInventories([...inventories, ...response]);
                setFetchedProductBrands([...fetchedProductBrands, ...ids]);
            });
        }

    }, [stockMovements, tideApi, fetchedProductBrands, inventories, toIn.value]);


    const onProductBrandAdd = useCallback( ( productBrand )=>{
        if(!productBrand) return;

        //If the product was already on the list
        if( _.find( stockMovements, (sm)=>sm.productBrand.id===productBrand.id ) )
            return;
        const product= productBrand.product;
        const stockMovement = { productBrand, quantity:'1', selectedUnit: product.displayUnit? product.displayUnit: product.smallestUnit };
        setStockMovements( [ ...stockMovements, stockMovement ] );
    }, [ stockMovements ]);

    const fromWarehouseId = fromIn?.value;
    useEffect(()=>{
        api.clearProperty("EntitySelectproductBrands");
    },[api, fromWarehouseId]);

    const createRequisition = useCallback(() =>{

        if( !fromIn.value )
            return notifier.error( 'Debes escoger el almacén de salida del producto.' );

        if( !toIn.value )
            return notifier.error( 'Debes escoger el lugar  de entrega.' );

        if( !stockMovements.length )
            return notifier.error( 'Debes escoger al menos un producto.' );

        const outBatchMovement = stockMovementsArrayToBatchMovement( stockMovements, fromIn.value, 'out', stockMovementBatchStatus.PENDING_APPROVAL );

        const newStockRequest = {
            fromWarehouse: fromIn.value,
            toPlace: toIn.value,
            requestedForDate: requestedForDate.format('YYYY-MM-DDTHH:mm'),
            stockMovementBatches: [ outBatchMovement ],
            autogeneratedIncomeMovements: true, // see gle-backend:src/Entity/StockRequest.php:247 to understand this property
            cassette:{comments:commentIn.value?[{text:commentIn.value}]:[]}
        };

        const apiCall = ()=>
            api.stockRequests.create( newStockRequest, loadingId )
                .then( stockRequest?onEdit:onHide );

        if(stockRequest && !cloning)
            api.stockRequests.edit({id:stockRequest.id, stockRequest:{status:stockRequestStatus.CANCELED}})
                .then(apiCall);
        else
            apiCall();

    },[ api, notifier, fromIn.value, toIn.value, commentIn.value, stockMovements, onHide, stockRequest, onEdit, requestedForDate, cloning ]);

    const toWarehouse = toIn.value && _.find(places, p=>p.id===toIn.value);

    const productBrandFilters = useMemo(()=>({...additionalFilters, "inventories.warehouse": fromIn?.value })
        ,[fromIn]);


    return (
        <Modal
            show={true}
            bsSize='lg'
            className='ProductRequisitionFormModal'
        >
            <ModalHeader closeButton onHide={onHide}><ModalTitle>Nueva requisición de almacén</ModalTitle></ModalHeader>

            <ModalBody>

                {stockRequest && !cloning &&
                    <div className='alert alert-danger'>Al editar la requisición se cancelará la actual y se generará una nueva con los nuevos productos.</div>
                }
                <h5>Solicitar al almacén:</h5>
                <select className='form-control' {...fromIn.bind}>
                    <option value='' disabled>Selecciona un almacén</option>
                    { warehouses.map( ( wh )=>
                        <option key={wh.id} value={wh.id}>{wh.name}</option>
                    ) }
                </select>

                <h5>Lugar de entrega:</h5>
                <select className='form-control' {...toIn.bind}>
                    <option value='' disabled>Selecciona un lugar</option>
                    { places.map( ( pl )=>
                        fromIn.value===pl.id || !pl.canReceiveProducts? null:
                        <option key={pl.id} value={pl.id}>{pl.name}</option>
                    ) }
                </select>

                <h5>Fecha de entrega:</h5>
                <DateTime
                    viewMode={'time'}
                    value={requestedForDate}
                    onChange={setRequestedForDate}
                />

                <StockRequestGenerator
                    warehouseId={ toWarehouse?.type==='warehouse'&& toWarehouse.id }
                    setStockMovements={setStockMovements}
                />

                {/* Only show the product selector when there's a warehouse selected */}
                {!!fromIn.value &&
                <>
                    <h5>Selecciona los productos que requieres:</h5>
                    {overFlowingProductBrand.length > 0 &&
                        <div className='bg-danger text-center'>
                            <p>Estás rebasando la capacidad máxima que tiene el almacén de entrega en los siguientes artículos:</p>
                            <div className='w-100 d-flex flex-column'>
                                {overFlowingProductBrand.filter((inv) => inv.warehouse.id === toIn.value).map((inv) => (
                                    <p key={inv.productBrand.product.id} className='text-danger'>-- {inv.productBrand.product.name} --</p>
                                ))}
                                <br/>
                            </div>
                        </div>
                    }
                    <br/>

                    {!!stockMovements.length &&
                    <EditableStockMovementsTable
                        inventories={inventories}
                        overFlowingProductBrand={overFlowingProductBrand}
                        setOverflowing={setOverflowingProductBrand}
                        stockMovements={stockMovements}
                        onChange={ setStockMovements }
                    />}

                    <EntitySelect
                        entity={'productBrands'}
                        onChange={ onProductBrandAdd }
                        filterBy={'search'}
                        labelCreator={selectLabelCreator}
                        maxResults={100}
                        additionalFilters={productBrandFilters}
                    />
                </>}

                <h5>Comentarios:</h5>
                <textarea  className='form-control' {...commentIn.bind} placeholder='¿Quieres agregar algún detalle a tu requisición?' />

            </ModalBody>

            <ModalFooter>
                <CustomButton bsStyle='danger' onClick={onHide} className='pull-left' disabled={!!loadingIds[loadingId]} >Cancelar</CustomButton>
                <CustomButton bsStyle='success' disabled={!!loadingIds[loadingId] || !!overFlowingProductBrand.length} onClick={ createRequisition } >Crear</CustomButton>
            </ModalFooter>

        </Modal>
    )

};

ProductRequisitionFormModal.displayName="ProductRequisitionFormModal";

export default ProductRequisitionFormModal;
