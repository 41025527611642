import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import CustomButton from "../../elements/CustomButton/CustomButton";

const ConfirmModal=({
        show=true,
        onCancel,
        onConfirm,
        title='Confirmación',
        message='¿Estás seguro que deseas continuar?',
        cancelButtonStyle='danger',
        continueButtonStyle='success',
        cancelButtonText='Cancelar',
        continueButtonText='Continuar',
        disabled=false
    })=>{

    return (
        <Modal show={show} onHide={onCancel}>
            <ModalHeader closeButton>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <p>{message}</p>
            </ModalBody>
            <ModalFooter>
                <CustomButton bsStyle={cancelButtonStyle} onClick={onCancel} className={'pull-left'} disabled={disabled}>{cancelButtonText}</CustomButton>
                <CustomButton bsStyle={continueButtonStyle} onClick={onConfirm} disabled={disabled}>{continueButtonText}</CustomButton>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmModal;
