import React, {useContext} from "react";
import '../../../assets/sass/gle/_NewPasswordConfirmationModal.scss';
import {TideApiContext} from "../../../api/tideApiConfig";
import {ApiContext} from "../../../api/Api";

const NewPasswordConfirmationModal = ({newPassword}) => {
    const api = useContext(ApiContext);
    const tideApi = useContext(TideApiContext);

    const handleContinueButton = () => {
        tideApi.acknowledgeNewPassword.confirmNewPassword({method: "PUT"}).then(() => {
            api.me.get();
        });
    };

    return (
        <div className='NewPasswordConfirmationModal'>
            <div className='content-wrapper text-center'>
                <h2>Confirma tu nueva contraseña</h2>
                <p>Tu nueva contraseña es la siguiente:<br/>
                    <span className='passwordText'>{newPassword}</span><br/>
                    No olvides aprendertela antes de continuar
                </p>
                <button className='btn btn-success' onClick={handleContinueButton}>Continuar</button>
            </div>
        </div>
    )
}

export default NewPasswordConfirmationModal;