import React, {useContext, useEffect, useCallback, useMemo, useState} from 'react';
import {ApiContext} from '../../../api/Api';
import {FormGroup, FormControl, ControlLabel, OverlayTrigger, Tooltip} from 'react-bootstrap';
import CUModal from './CUModal';
import MinuteTimePicker from './MinuteTimePicker';
import {useSelector} from 'react-redux';

const setTextFromEvent = setter => e => setter(e.target.value);

const useDishCategoryState = ({initialValues={}}={}) => {
    const [parentCategory, setParentCategory] = useState(initialValues.parentCategory?initialValues.parentCategory.id:'');
    const [name, setName] = useState(initialValues.name||'');
    const [description, setDescription] = useState(initialValues.description||'');
    const [availableAfter, setAvailableAfter] = useState(initialValues.availableAfter||0);
    const [availableBefore, setAvailableBefore] = useState(initialValues.availableBefore||1439);

    const valueFor = useMemo(()=>({
        parentCategory,
        name,
        description,
        availableAfter,
        availableBefore
    }), [parentCategory, name, description, availableAfter, availableBefore]);
    const onChangeFor = useMemo(()=>({
        name: setTextFromEvent(setName),
        description: setTextFromEvent(setDescription),
        availableAfter: setAvailableAfter,
        availableBefore: setAvailableBefore,
        parentCategory: setTextFromEvent(setParentCategory)
    }), [setParentCategory, setName, setDescription, setAvailableAfter, setAvailableBefore]);

    const propsFor = useCallback(v => ({
        value: valueFor[v],
        onChange: onChangeFor[v]
    }), [valueFor, onChangeFor]);

    return {
        propsFor,
        state: valueFor
    };
};

const validation = state=>{
    let errors = [];
    const {name, availableAfter, availableBefore} = state;
    if(!name){
        errors.push('El nombre de la categoría no puede estar vacío.');
    }
    if(availableAfter===availableBefore){
        errors.push('Intervalo de tiempo inválido.');
    }
    return errors;
};

const CategoryModal = ({onDelete, initialValues=undefined, onHide, onSubmit, submitText, title}) => {
    const api = useContext(ApiContext);
    const {propsFor, state} = useDishCategoryState({initialValues});
    useEffect(()=>{
        api.orozcoProductCategories.get({
            filters: {pagination: false},
            customProp: 'parentOrozcoProductCategories'
        });
    }, [api]);

    const categories = useSelector(({api: {parentOrozcoProductCategories}})=>parentOrozcoProductCategories||[]);
    const parentCategories = useMemo(()=>categories.filter(c=>{
        if (initialValues===undefined){
            return true;
        }
        return c.id!==initialValues.id;
    }), [categories, initialValues]);
    return (
        <CUModal
            onDelete={onDelete}
            initialValues={initialValues}
            onSubmit={onSubmit}
            state={state}
            onHide={onHide}
            title={title}
            submitText={submitText}
            validation={validation}
        >
            <FormGroup>
                <ControlLabel>Categoría padre: </ControlLabel>
                <select {...propsFor('parentCategory')} className='form-control'>
                    <option value={''}>Ninguna</option>
                    {parentCategories.map(pc=>(
                        <option value={pc.id} key={pc.id}>{pc.name}</option>
                    ))}
                </select>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Nombre: </ControlLabel>
                <FormControl type='text' {...propsFor('name')}/>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Descripción: </ControlLabel>
                <FormControl type='text' {...propsFor('description')}/>
            </FormGroup>
            <FormGroup>
                <OverlayTrigger placement='top' overlay={
                                    <Tooltip id='final-category-overlay'>Sólo válido para categorías finales</Tooltip>
                                }>

                    <ControlLabel>Horario de disponibilidad *</ControlLabel>
                </OverlayTrigger>
                <div className='row'>
                    <div className='col-sm-5'>
                        <MinuteTimePicker {...propsFor('availableAfter')}/>
                    </div>
                    <div className='col-sm-2 vertical-align'>
                        al
                    </div>
                    <div className='col-sm-5'>
                        <MinuteTimePicker {...propsFor('availableBefore')}/>
                    </div>
                </div>
            </FormGroup>
        </CUModal>
    );

};


export default CategoryModal;
