import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ApiContext} from "../../../api/Api";
import {SecurityContext} from "../../../utils/SecurityManager";
import {NotifierContext} from "../../../utils/Notifier";
import useBoolean from "../../../hooks/useBoolean";
import Switch from "react-bootstrap-switch";
import {Table} from "react-bootstrap";
import useInput from "../../../hooks/useInput";

const MANAGERS_CAN_REVIEW_JUSTIFICATIONS = 'MANAGERS_CAN_REVIEW_JUSTIFICATIONS';
const CLOCK_RANDOM_MESSAGES = 'CLOCK_RANDOM_MESSAGES';
const CLOCK_PRE_DISCOUNT_MESSAGE = 'CLOCK_PRE_DISCOUNT_MESSAGE';
const ENABLE_HAPINESS_METER_PLUGIN = 'HAPINESS_METER_PLUGIN';

const AlmaConfiguration = () => {
    const [managersCanReviewJustifications, , , toggleManagersCanReviewJustifications, setManagersCanReviewJustifications] = useBoolean();
    const [enabledHapinessMeter, , , toggleEnabledHapinessMeter, setEnabledHapinessMeter] = useBoolean();
    const api = useContext(ApiContext);
    const security = useContext(SecurityContext);
    const notifier = useContext(NotifierContext);
    const clockMessages = useInput([]);
    const clockPreDiscountMessages = useInput('');
    const setPreDiscountMessage = clockPreDiscountMessages.setValue;
    const setMessage = clockMessages.setValue;

    const [ approvalJustificationLimitHours, setApprovalJustificationLimitHours ] = useState();
    const [ requestJustificationLimitHours, setRequestJustificationLimitHours ] = useState();

    useEffect(() => {

        if (security.alma.canEditClockRandomMessages()) {
            api.configurations.getSingle({id: CLOCK_RANDOM_MESSAGES}).then((configuration) => setMessage(configuration.value ? JSON.parse(configuration?.value) : []));
        }
        if (security.alma.canEditManagersCanReviewJustifications()) {
            api.configurations.getSingle({id: MANAGERS_CAN_REVIEW_JUSTIFICATIONS}).then((configuration) => setManagersCanReviewJustifications(configuration.value === "1"));
        }
        if (security.alma.canEditClockPreDiscountMessage()) {
            api.configurations.getSingle({id: CLOCK_PRE_DISCOUNT_MESSAGE}).then((configuration) => setPreDiscountMessage(configuration.value));
        }

        if (security.alma.canEnableHapinessMeter()) {
            api.configurations.getSingle({id: ENABLE_HAPINESS_METER_PLUGIN}).then((configuration) => setEnabledHapinessMeter(configuration.value === "1"));
        }

        if (security.alma.canUpdateJustificationHourLimit()) {
            api.configurations.getSingle({id: 'JUSTIFICATION_APPROVAL_LIMIT_HOURS'}).then((configuration) => setApprovalJustificationLimitHours(configuration.value));
            api.configurations.getSingle({id: 'JUSTIFICATION_REQUEST_LIMIT_HOURS'}).then((configuration) => setRequestJustificationLimitHours(configuration.value));
        }
    }, [api, setManagersCanReviewJustifications, setMessage, setPreDiscountMessage, setEnabledHapinessMeter, security]);

    const onSave = useCallback(() => {

        const configurations = [];
        if (security.alma.canEditClockRandomMessages()) {
            configurations.push( {
                id: CLOCK_RANDOM_MESSAGES,
                value: JSON.stringify(clockMessages.value)
            });
        }
        if (security.alma.canEditManagersCanReviewJustifications()) {
            configurations.push(  {
                id: MANAGERS_CAN_REVIEW_JUSTIFICATIONS,
                value: managersCanReviewJustifications ? "1" : "0"
            });
        }
        if (security.alma.canEditClockPreDiscountMessage()) {
            configurations.push( {
                id: CLOCK_PRE_DISCOUNT_MESSAGE,
                value: clockPreDiscountMessages.value
            });
        }

        if (security.alma.canEnableHapinessMeter()) {
           configurations.push({
               id: ENABLE_HAPINESS_METER_PLUGIN,
               value: enabledHapinessMeter ? "1" : "0"
           });
        }

        if (security.alma.canUpdateJustificationHourLimit()) {
            if( Number(approvalJustificationLimitHours) && Number(requestJustificationLimitHours) ) {
                configurations.push({
                    id: 'JUSTIFICATION_APPROVAL_LIMIT_HOURS',
                    value: approvalJustificationLimitHours
                });
                configurations.push({
                    id: 'JUSTIFICATION_REQUEST_LIMIT_HOURS',
                    value: requestJustificationLimitHours
                });
            }
        }

        api.configurations.batchEdit({
            configurations
        }).then(() => notifier.success('Configuración guardada'));
    },
        [
            api,
            security,
            notifier,
            enabledHapinessMeter,
            clockMessages,
            clockPreDiscountMessages,
            managersCanReviewJustifications,
            approvalJustificationLimitHours,
            requestJustificationLimitHours
        ]
    );

    const handleAddTextbox = () => {
        clockMessages.setValue([...clockMessages.value, ''])
    }

    const onEditClockMessage = idx => e => {
        const newClockMessages = clockMessages.value.map((message, sidx) => {
            if (idx !== sidx) return message;
            return e.target.value;
        });

        clockMessages.setValue(newClockMessages);
    }

    const handleApprovalHourLimitChange = useCallback(e => {
        e.preventDefault();
        const value = e.target.value;

        setApprovalJustificationLimitHours(value);
    }, [setApprovalJustificationLimitHours]);

    const handleRequestHourLimitChange = useCallback(e => {
        e.preventDefault();
        const value = e.target.value;

        setRequestJustificationLimitHours(value);
    }, [setRequestJustificationLimitHours]);

    const onRemoveClockMessage = (message) => {
        const newMessages = [...clockMessages.value];
        newMessages.splice(newMessages.indexOf(message), 1);
        clockMessages.setValue(newMessages);
    }

    return (
        <div className="container-fluid Configuration">
            <div className='configuration-container col-md-8 col-md-push-2'>

                {security.alma.canEditManagersCanReviewJustifications() ?
                    <div className='config-editor row'>
                        <div className='col-md-10 description'>
                            Habilitar o deshabilitar la justificación de solicitudes e incidencias por parte de los
                            gerentes.
                        </div>
                        <div className='col-md-2 value'>
                            <div onClick={toggleManagersCanReviewJustifications} className='mail-notification-toggle'>
                                <Switch value={managersCanReviewJustifications}/>
                            </div>
                        </div>
                    </div> : null}

                {security.alma.canEditClockPreDiscountMessage() ?
                    <div className="config-editor row">
                        <div className='col-md-4 description'>
                            Mensaje para los colaboradores previo al descuento:
                        </div>
                        <div className='col-md-8 value'>
                            <input
                                className="form-control"
                                type="text"
                                {...clockPreDiscountMessages.bind}
                            />
                        </div>
                    </div> : null}

                {security.alma.canEditClockRandomMessages() ?
                    <div className="config-editor row">
                        <div className='col-md-4 description'>
                            Listado de mensajes aleatorios en el reloj:
                        </div>
                        <div className='col-md-8 value'>

                            <Table className={'vertical-responsive-table'}>
                                <tbody>

                                {clockMessages.value.map((message, i) => (
                                    <tr className='product-row' key={i}>
                                        <td data-label='#' className='text-center'>
                                            <i
                                                className='fa fa-trash remove-row'
                                                onClick={() => onRemoveClockMessage(i)}
                                            /> {i + 1}
                                        </td>
                                        <td data-label='Tipo'>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={message}
                                                onChange={onEditClockMessage(i)}/>
                                        </td>
                                    </tr>
                                ))}

                                <tr className='add-product-row text-center'>
                                    <td colSpan="10">
                                        <span className='link add-text'
                                              onClick={handleAddTextbox}>+ Agregar mensaje</span>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>

                        </div>
                    </div> : null}

                {security.alma.canEnableHapinessMeter() ?
                    <div className='config-editor row'>
                        <div className='col-md-10 description'>
                            Habilitar felizometro.
                        </div>
                        <div className='col-md-2 value'>
                            <div onClick={toggleEnabledHapinessMeter} className='mail-notification-toggle'>
                                <Switch value={enabledHapinessMeter}/>
                            </div>
                        </div>
                    </div> : null}

                {security.alma.canUpdateJustificationHourLimit() ?
                    <>
                        <div className='config-editor row'>
                            <div className='col-md-10 description'>
                                Número de horas límite para aprobar una solicitud de justificación.
                            </div>
                            <div className='col-md-2 value'>
                                <div>
                                    <input
                                        className="form-control"
                                        type="number"
                                        onChange={handleApprovalHourLimitChange}
                                        value={approvalJustificationLimitHours}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='config-editor row'>
                            <div className='col-md-10 description'>
                                Número de horas límite para realizar una solicitud de justificación.
                            </div>
                            <div className='col-md-2 value'>
                                <div>
                                    <input
                                        className="form-control"
                                        type="number"
                                        onClick={(e) => e.preventDefault()}
                                        onChange={handleRequestHourLimitChange}
                                        value={requestJustificationLimitHours}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                : null}

                <button className='btn btn-success' onClick={onSave}>
                    Guardar
                </button>
                <div className='clearfix'/>
            </div>
        </div>
    );
};

export default AlmaConfiguration;
