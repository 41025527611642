import React, {Component} from 'react';
import {PanelGroup, Panel} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Card from '../../../../components/Card/Card.jsx';
import {connect} from 'react-redux';
import WeekSchedule from './WeekSchedule';
import Button from '../../../../elements/CustomButton/CustomButton';
import LoaderIcon from '../../../../components/Helper/LoaderIcon';
import ShiftPanel from './ShiftPanel';


const baseSchedule = {
    "checkIn": 420,
    "checkOut": 960,
};
const baseWeekSchedule = {
    monday: Object.assign(baseSchedule),
    tuesday: Object.assign(baseSchedule),
    wednesday: Object.assign(baseSchedule),
    thursday: Object.assign(baseSchedule),
    friday: Object.assign(baseSchedule),
    saturday: Object.assign(baseSchedule),
    sunday: Object.assign(baseSchedule)
};

class Shifts extends Component{

    static LoadingConstants = {
        Deleting: "@Class.Shifts.deleting.",
        Editing: "@Class.Shifts.editing.",
        Creating: "@Class.Shifts.creating"
    };

    static contextTypes = {
        api: PropTypes.object,
        notifier: PropTypes.object
    };

    constructor(props, context){
        super(props);
        context.api.shiftCategories.get();

        this.onShiftChange = this.onShiftChange.bind(this);
        this.newShiftClick = this.newShiftClick.bind(this);
        this.newShiftClickCancel = this.newShiftClickCancel.bind(this);
        this.onNewNameChange = this.onNewNameChange.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);

        this.state = {
            addingNew: false,
            newShift: {
                name: "",
                weekSchedule: baseWeekSchedule,
                type:'M',
                mealMinutes: 30,
                hasMealTime:true,
                canGetExtraMealTime:true,
            }
        };
    }

    onShiftChange( weekSchedule ) {

        this.setState(
            { newShift:{...this.state.newShift, weekSchedule} }
        );
    }
    onNewNameChange( event ){

        this.setState(
            { newShift:{...this.state.newShift, name: event.target.value} }
        );
    }

    onTypeChange( e ){
        this.setState(
            { newShift:{...this.state.newShift, type: e.target.value} }
        );
    }

    onDeletePress( shift ){
        const loadingId = Shifts.LoadingConstants.Deleting + shift;

        if( this.props.loadingIds[loadingId] )
            return;

        this.context.api.shifts.delete( shift, loadingId )
            .then(()=>this.context.api.shiftCategories.get());
    }

    onEdit = ( shift )=>{
        const loadingId = Shifts.LoadingConstants.Editing + shift.id;
        this.context.api.shifts.edit( shift.id, shift, loadingId )
            .then(()=>this.context.api.shiftCategories.get());

    };


    newShiftClick(){
        if( !this.state.addingNew ){
            this.setState({addingNew:true});
            return;
        }

        if( !this.state.newShift.name ){
            this.context.notifier.error('Debes ingresar un nombre para el nuevo turno.');
            return;
        }

        if( !this.state.newShift.category ){
            this.context.notifier.error('Debes seleccionar una categoría para el nuevo turno.');
            return;
        }

        const mealMinutes = parseInt( this.state.newShift.mealMinutes, 10 );
        if( this.state.newShift.hasMealTime && !mealMinutes ){
            this.context.notifier.error('Debes ingresar el número de minutos que tiene para comer.');
            return;
        }

        this.context.api.shifts.create({...this.state.newShift, mealMinutes}, Shifts.LoadingConstants.Creating)
            .then(()=>this.context.api.shiftCategories.get());
        this.setState({addingNew:false});

    }
    newShiftClickCancel(){
        this.setState({addingNew:false});
    }

    addCategory = ()=>{
        if( !this.state.newCategory ){
            this.context.notifier.error('Debes ingresar un nombre para la nueva categoría.');
            return;
        }

        this.context.api.shiftCategories.create(this.state.newCategory);
        this.setState({addingCategory:false, newCategory:''});

    };

    deleteCategory = (catId)=>{
        this.context.api.shiftCategories.delete(catId);
    };

    editCategory = ()=>{
        if( !this.state.editingCategory )
            return;

        if( !this.state.editingCategoryName ){
            this.context.notifier.error('Debes ingresar un nombre para la categoría.');
            return;
        }

        this.context.api.shiftCategories.edit(this.state.editingCategory, this.state.editingCategoryName);
        this.setState({editingCategory:false, editingCategoryName:''});
    };

    render(){

        return(
            <Card
                title="Administración de turnos"
                content={
                    <div className="shift-catalogue">

                        <PanelGroup id="accordion" ref="cat-panels" onClick={() => this.forceUpdate()}>

                        {this.props.shiftCategories.map( sCategory=>
                            <Panel
                                collapsible
                                key={'cat-'+sCategory.id}
                                header={
                                    this.state.editingCategory === sCategory.id?
                                        <span>
                                            <input value={this.state.editingCategoryName}
                                                   onChange={(e)=>this.setState({editingCategoryName:e.target.value})}
                                                   onClick={(e)=>e.stopPropagation()}
                                            />
                                            <i className='fa fa-check green' onClick={this.editCategory}/> &nbsp;
                                            <i className='fa fa-times red' onClick={()=>this.setState({editingCategory:false})}/>
                                        </span>
                                        :
                                        <span>
                                            {sCategory.name}&nbsp;
                                            <i className='fa fa-pencil sky-blue' onClick={(e)=>{
                                                this.setState({editingCategory:sCategory.id, editingCategoryName:sCategory.name});
                                                e.stopPropagation();
                                            }}/>
                                        <b className="caret" />
                                    </span>
                                }
                                eventKey={'cat-'+sCategory.id}
                            >
                            <PanelGroup id="accordion" ref="panels" onClick={() => this.forceUpdate()}>


                                {sCategory.shifts.map( (shift)=>
                                    <ShiftPanel
                                        key={shift.id}
                                        shift={shift}
                                        categoryId={sCategory.id}
                                        categories={this.props.shiftCategories}
                                        deleteLoading={!!this.props.loadingIds[Shifts.LoadingConstants.Deleting + shift.id]}
                                        onDelete={()=>this.onDeletePress( shift.id )}
                                        onEdit={ this.onEdit }
                                    /> )}

                                {sCategory.shifts.length === 0?
                                    <Button bsStyle='danger' onClick={()=>this.deleteCategory(sCategory.id) }>Eliminar categoría</Button>:null}

                            </PanelGroup>
                            </Panel>
                        )}
                            <Panel
                                collapsible
                                header={this.state.addingCategory?
                                    <span>
                                        <input value={this.state.newCategory||''} onChange={(e)=>this.setState({newCategory:e.target.value})} />
                                        <i className='fa fa-check green' onClick={this.addCategory}/> &nbsp;
                                        <i className='fa fa-times red' onClick={()=>this.setState({addingCategory:false})}/>
                                    </span>
                                    :
                                    <span  className='text-primary' onClick={()=>this.setState({addingCategory:true})}>Agregar categoría +</span>
                                }
                            />

                        </PanelGroup>

                        { this.state.addingNew?
                            <Panel

                                header={
                                    <div>
                                        <select onChange={this.onTypeChange} value={this.state.newShift.type}>
                                            <option value='M'>Matutino</option>
                                            <option value='V'>Vespertino</option>
                                            <option value='N'>Nocturno</option>
                                        </select> &nbsp;-&nbsp;
                                        <input className="form-group" placeholder="Nombre del turno" onChange={this.onNewNameChange} value={this.state.newShift.name}/>
                                    </div>
                                }>
                                <select value={this.state.newShift.category || ''} onChange={(e)=>{this.setState({newShift:{ ...this.state.newShift, category:e.target.value}})}}>
                                    <option value='' >Selecciona una categoría</option>
                                    {this.props.shiftCategories.map(sCategory=>
                                        <option value={sCategory.id} key={sCategory.id} >{sCategory.name}</option>
                                    )}
                                </select>
                                <br/><br/>
                                <p>¿Requiere registros de comida? <input
                                    type='checkbox'
                                    checked={this.state.newShift.hasMealTime}
                                    onChange={(e)=>{this.setState({newShift:{ ...this.state.newShift, hasMealTime:e.target.checked}})}}
                                /></p>

                                {this.state.newShift.hasMealTime?
                                <div>
                                    <p>¿Cuánto tiempo de comida tiene? <input
                                        type='text'
                                        value={this.state.newShift.mealMinutes}
                                        onChange={(e)=>{this.setState({newShift:{ ...this.state.newShift, mealMinutes:e.target.value}})}}
                                    /> (en minutos) </p>

                                    <p>¿Puede obtener tiempo de comida extra? <input
                                        type='checkbox'
                                        checked={this.state.newShift.canGetExtraMealTime}
                                        onChange={(e)=>{this.setState({newShift:{ ...this.state.newShift, canGetExtraMealTime:e.target.checked}})}}
                                    /></p>
                                </div>
                                    :null}

                                <WeekSchedule weekSchedule={this.state.newShift.weekSchedule} onChange={ this.onShiftChange } editable/>
                            </Panel>
                            : null}

                        <Button
                            bsStyle="primary"
                            onClick={this.newShiftClick}
                            disabled={!!this.props.loadingIds[Shifts.LoadingConstants.Creating]}
                        >
                            { this.props.loadingIds[Shifts.LoadingConstants.Creating]?
                                <LoaderIcon/>:
                                this.state.addingNew?
                                    "Crear":
                                    "Agregar turno"
                            }
                            </Button> &nbsp;&nbsp;
                        { this.state.addingNew? <Button bsStyle="danger" onClick={this.newShiftClickCancel}>Cancelar</Button>:null}
                    </div>
                }
            />
        );
    }
}

function mapStateToProps( {api: {shifts = [], shiftCategories = [] }, loadingIds } ) {

    return { shifts, loadingIds, shiftCategories };
}

export default connect(mapStateToProps)(Shifts);
