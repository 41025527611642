import React,{useEffect,useContext} from 'react';
import {Modal, Table} from "react-bootstrap";
import Button from '../../../../elements/CustomButton/CustomButton';
import LoaderIcon from "../../../../components/Helper/LoaderIcon";
import _ from "lodash";
import {useSelector} from "react-redux";
import moment from "moment";
import {ApiContext} from "../../../../api/Api";

const IncidenceSummaryModal = ({onHide, employee})=>{

    const incidences=useSelector(({api})=>api.incidences||[]);
    const api=useContext(ApiContext);

    useEffect(()=>{

        //Quincena report
        api.appliedIncidenceReport.get( {
            "groupBy": "type",
            "fromShiftDate": moment().date() <= 15? moment().date(1).format('YYYY-MM-DD') : moment().date(16).format('YYYY-MM-DD'), //Get first date of the quincena
            "toShiftDate": moment().date() <= 15? moment().date(15).format('YYYY-MM-DD') : moment().endOf('month').format('YYYY-MM-DD'),
            "employees": [employee.id],
            "includePositives": false
        }, '@Class.AppliedIncidences.quincenaSummary', 'appliedIncidenceReportQuincenaSummary' );

        //30 days back report
        api.appliedIncidenceReport.get( {
            "groupBy": "type",
            "fromShiftDate": moment().subtract(30, 'days').format('YYYY-MM-DD'),
            "toShiftDate": moment().format('YYYY-MM-DD'),
            "employees": [employee.id],
            "includePositives": false
        }, '@Class.AppliedIncidences.thirtyDaysSummary', 'appliedIncidenceReportThirtyDaysSummary' );

        api.appliedIncidenceReport.get( {
            "groupBy": "type",
            "fromShiftDate": moment().startOf('year').format('YYYY-MM-DD'),
            "toShiftDate": moment().format('YYYY-MM-DD'),
            "employees": [employee.id],
            "includePositives": false
        }, '@Class.AppliedIncidences.ytdSummary', 'appliedIncidenceReportYtdSummary' );

        api.appliedIncidenceReport.get( {
            "groupBy": "type",
            "employees": [employee.id],
            "includePositives": false
        }, '@Class.AppliedIncidences.everSummary', 'appliedIncidenceReportEverSummary' );

    },[api, employee.id]);


    const appliedIncidenceReportQuincenaSummary = useSelector(({api})=>api.appliedIncidenceReportQuincenaSummary)||{result:[]};
    const appliedIncidenceReportThirtyDaysSummary = useSelector(({api})=>api.appliedIncidenceReportThirtyDaysSummary)||{result:[]};
    const appliedIncidenceReportYtdSummary = useSelector(({api})=>api.appliedIncidenceReportYtdSummary)||{result:[]};
    const appliedIncidenceReportEverSummary = useSelector(({api})=>api.appliedIncidenceReportEverSummary)||{result:[]};

    const loadingIds=useSelector(({loadingIds})=>loadingIds);


    return (
        <Modal show={true} bsSize='lg' onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Resumen de incidencias</Modal.Title>
            </Modal.Header>
            <Modal.Body className='applied-incidence-summary'>
                <Table responsive>
                    <thead>
                    <tr>
                        <th>Periodo</th>
                        {incidences.map( inc=>
                            !inc.isPositive?
                                <th key={inc.id}>{inc.name}</th>
                                :null
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Quincena actual</td>
                        {
                            loadingIds['@Class.AppliedIncidences.quincenaSummary']?//If this report has loaded
                                <td><LoaderIcon /></td>:
                                incidences.map( inc=>{//For each incidence
                                    if( inc.isPositive ) return null;
                                    const val = _.find( appliedIncidenceReportQuincenaSummary.result, res=>res.attr === inc.name );// Search the count inside the report
                                    if( val )
                                        return <td key={inc.id + '1' }>{val.count}</td>;//If we find it we render it
                                    return <td key={inc.id + '1' }>0</td>;//Else there was no incidences of this type in this period
                                } )
                        }

                    </tr>
                    <tr>
                        <td>Últimos 30 días</td>
                        {
                            loadingIds['@Class.AppliedIncidences.thirtyDaysSummary']?//If this report has loaded
                                <td><LoaderIcon /></td>:
                                incidences.map( inc=>{//For each incidence
                                    if( inc.isPositive ) return null;
                                    const val = _.find( appliedIncidenceReportThirtyDaysSummary.result, res=>res.attr === inc.name );// Search the count inside the report
                                    if( val )
                                        return <td key={inc.id + '1' }>{val.count}</td>;//If we find it we render it
                                    return <td key={inc.id + '1' }>0</td>;//Else there was no incidences of this type in this period
                                } )
                        }
                    </tr>
                    <tr>
                        <td>Este año</td>
                        {
                            loadingIds['@Class.AppliedIncidences.ytdSummary']?//If this report has loaded
                                <td><LoaderIcon /></td>:
                                incidences.map( inc=>{//For each incidence
                                    if( inc.isPositive ) return null;
                                    const val = _.find( appliedIncidenceReportYtdSummary.result, res=>res.attr === inc.name );// Search the count inside the report
                                    if( val )
                                        return <td key={inc.id + '1' }>{val.count}</td>;//If we find it we render it
                                    return <td key={inc.id + '1' }>0</td>;//Else there was no incidences of this type in this period
                                } )
                        }
                    </tr>
                    <tr>
                        <td>Siempre</td>
                        {
                            loadingIds['@Class.AppliedIncidences.everSummary']?//If this report has loaded
                                <td><LoaderIcon /></td>:
                                incidences.map( inc=>{//For each incidence
                                    if( inc.isPositive ) return null;
                                    const val = _.find( appliedIncidenceReportEverSummary.result, res=>res.attr === inc.name );// Search the count inside the report
                                    if( val )
                                        return <td key={inc.id + '1' }>{val.count}</td>;//If we find it we render it
                                    return <td key={inc.id + '1' }>0</td>;//Else there was no incidences of this type in this period
                                } )
                        }
                    </tr>

                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle='danger' onClick={onHide}>Cerrar</Button>{" "}
            </Modal.Footer>
        </Modal>
    );
};

export default IncidenceSummaryModal;
