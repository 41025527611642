import LocalStorageState from "../utils/LocalStorage";
import { CHANGE_SYSTEM } from '../actions/types';

const initialState = 'alma';

export default function( state = initialState, action ) {

    switch (action.type){
        case CHANGE_SYSTEM:
            let loc = new LocalStorageState();
            loc.store( {system: action.payload});
            return action.payload;
        default:
            return state;
    }
}