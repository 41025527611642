import React from 'react';
import scale1 from '../../assets/img/happiness-scale/angry.png';
import scale2 from '../../assets/img/happiness-scale/uncomfortable.png';
import scale3 from '../../assets/img/happiness-scale/indifferent.png';
import scale4 from '../../assets/img/happiness-scale/amused.png';
import scale5 from '../../assets/img/happiness-scale/happy.png';

const faceForAverage = ( average )=>{

    if( average <= 1 )
        return scale1;
    if( average <= 2 )
        return scale2;
    if( average <= 3 )
        return scale3;
    if( average <= 4 )
        return scale4;
    else
        return scale5;
};

const HappinessFace = ( props )=>{

    const { level, ...rest } = props;
    const img = faceForAverage(level);

    return <img  src={img} alt={'Hapinness face scale ' + level} {...rest} />
};

export default HappinessFace;