import React, {useContext, useEffect, useMemo, useState, useRef} from 'react';
import {ApiContext} from "../../../../api/Api";
import {connect} from "react-redux";
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import _ from 'lodash';
import DateRangePicker from "react-bootstrap-daterangepicker";
import CustomButton from "../../../../elements/CustomButton/CustomButton";


const RotationGraph = ( {staffRotationReport} )=>{


    const api = useContext( ApiContext );

    const [dates, setDates] = useState({from: moment().month(0).date(1), to: moment()});

    const debouncedApi = useRef( _.debounce(api.staffRotationReport.get, 500) );

    useEffect( ()=>{
        debouncedApi.current( {
            groupBy:'month',
            fromDate: dates.from.format('YYYY-MM-DD'),
            toDate: dates.to.format('YYYY-MM-DD')
        } );
    }, [ dates ] );

    const onDateChange = (e, {startDate, endDate}) => {
        setDates({from: moment(startDate), to: moment(endDate)});
    };

    const options = useMemo( ()=>{

        if( !staffRotationReport )
            return {};

        let count = 277;
        // movements be like: { 2018-11: {hires: "25", layoffs: "13"}, 2018-12: {hires: "16", layoffs: "15"}, 2019-1: {hires: "20", layoffs: "24"}, ... }
        const movements = staffRotationReport.result;

        let minVal = count - 20;
        let labels = [];
        //base is a transparent bar that will be placed under the actual color bars to "support" them flying in the graph
        let base = [];
        let lastBase = count;
        let hires = [];
        let layoffs = [];

        _.forEach( movements, ( data, move ) =>{

            const hiresC = Number( data.hires? data.hires : 0 );
            const layoffsC = Number( data.layoffs? data.layoffs : 0 );
            const newBase = lastBase + layoffsC - hiresC;

            //Add the date two time because there will be 2 columns for each date (hires and layoffs)
            labels = labels.concat([move, move]);

            base = base.concat([ lastBase , newBase ]);

            if( (newBase - 20) < minVal )
                minVal = newBase - 20;
            lastBase = newBase;

            hires = hires.concat(['-', hiresC]);
            layoffs = layoffs.concat([layoffsC, '-']);


        });

        labels.reverse();
        base.reverse();
        hires.reverse();
        layoffs.reverse();

        return {
            backgroundColor:'#f5f5f5',

            color:["#28ce81","#c23531"],

            tooltip : {
                trigger: 'axis',
                axisPointer : {
                    type : 'shadow'
                },
                formatter: function (params) {
                    let tar;

                    let total = params[0].value;
                    if (params[1].value !== '-') {
                        tar = params[1];
                        total += tar.value;
                    }
                    else if (params[2].value !== '-') {
                        tar = params[2];
                    }
                    return tar.name + '<br/>' + tar.seriesName + ' : ' + tar.value + '<br/> Total : ' + total;
                }
            },
            legend: {
                data:['Altas','Bajas']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type : 'category',
                splitLine: {show:false},
                data :  labels
            },
            yAxis: {
                type : 'value',

            },
            series: [
                {
                    name: 'Base',
                    type: 'bar',
                    stack: 'ST',
                    itemStyle: {
                        normal: {
                            barBorderColor: 'rgba(0,0,0,0)',
                            color: 'rgba(0,0,0,0)'
                        },
                        emphasis: {
                            barBorderColor: 'rgba(0,0,0,0)',
                            color: 'rgba(0,0,0,0)'
                        }
                    },
                    data: base //[180, 187, 187, 191, 191, 193]
                },
                {
                    name: 'Altas',
                    type: 'bar',
                    stack: 'ST',
                    label: {
                        normal: {
                            show: true,
                            position: 'top'
                        }
                    },
                    data: hires //[10, '-', 5, '-', 4, '-']
                },
                {
                    name: 'Bajas',
                    type: 'bar',
                    stack: 'ST',
                    label: {
                        normal: {
                            show: true,
                            position: 'bottom'
                        }
                    },
                    data: layoffs //['-', 3, '-', 1, '-', 2]
                }
            ]
        };
    }, [staffRotationReport] );

    return (
        <>
            <div>
                <DateRangePicker
                    startDate={dates.from}
                    endDate={dates.to}
                    onApply={onDateChange}
                    containerStyles={{}}
                >

                    <CustomButton
                        bsStyle={'primary'}
                        className='col-xs-12 range-button'
                    >
                        Rango de fechas &nbsp;
                        <strong>{dates.from.format('YYYY-MM-DD')}</strong> al <strong>{dates.to.format('YYYY-MM-DD')}</strong>
                    </CustomButton>

                </DateRangePicker>
            </div>

            <br/><br/>

            <ReactEcharts
                option={options}
            />

            <div className='clearfix'/>
        </>
    );
};


const mapStateToProps = ( {api:{staffRotationReport, employeesMeta}} ) =>({staffRotationReport, employeesMeta});

export default connect(mapStateToProps)(RotationGraph);
