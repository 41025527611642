import React, { useState, useCallback} from 'react';
import {Tab, Nav, NavItem} from "react-bootstrap";

import UserPermissionGroups from "./UserPermissionGroups";
import PositionPermissionGroups from "./PositionPermissionGroups";
import PermissionGroupList from "./PermissionGroupList";

function PermissionsConfiguration() {

    const [selected, setSelected] = useState('users');

    const onSelect = useCallback((event) => {
        setSelected(event);
    }, [setSelected]);

    return (
        <div className='PermissionsConfiguration container-fluid'>
            <Tab.Container id="nav-with-icons" defaultActiveKey="description">
                <div>
                    <div className="nav-container">
                        <Nav bsStyle="tabs" bsClass="nav nav-icons" onSelect={onSelect}>
                            <NavItem eventKey="users">
                                <i className="fa fa-user"/><br/> Permisos por usuario
                            </NavItem>

                            <NavItem eventKey="positions">
                                <i className="fa fa-sitemap"/><br/> Permisos por puesto
                            </NavItem>

                            <NavItem eventKey="permissions">
                                <i className="fa fa-list"/><br/> Lista de permisos
                            </NavItem>

                        </Nav>
                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="description">
                            <p className="main-description"><span>Aquí puedes configurar los grupos de permisos de los usuarios del sistema.</span>
                            </p>
                        </Tab.Pane>
                        <Tab.Pane eventKey="users">
                            { selected === "users"?<UserPermissionGroups/>:null}
                        </Tab.Pane>
                        <Tab.Pane eventKey="positions">
                            { selected === "positions"?<PositionPermissionGroups/>:null}
                        </Tab.Pane>
                        <Tab.Pane eventKey="permissions">
                            { selected === "permissions"?<PermissionGroupList/>:null}
                        </Tab.Pane>

                    </Tab.Content>
                </div>
            </Tab.Container>
        </div>
    )
}


export default PermissionsConfiguration;
