import React, {useContext, useCallback, useState } from 'react';
import {ApiContext} from "../../../api/Api";
import moment from "moment/moment";
import ReactTable from 'react-table';
import {
    defaultProps as ReactTableDefaultProps, tableStateToTideApiOptions,
} from "../../../utils/reactTableUtils";
import {useSelector} from "react-redux";
import Button from '../../../elements/CustomButton/CustomButton';
import {NavLink} from "react-router-dom";
import moneyFormatter from '../../../utils/moneyFormatter';
import ReactTableDataRangeFilter from "../../../components/Helper/ReactTableDateRangeFilter";
import _ from 'lodash';
import {OverlayTrigger} from "react-bootstrap";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import useReduxTableState from "../../../hooks/table/useReduxTableState";
import PurchaseProductProductsModalTooltip
    from "./purchaseRequisitionComponents/tooltips/PurchaseProductProductsTooltip";
import PurchaseProductProvidersTooltip from "./purchaseRequisitionComponents/tooltips/PurchaseProductProvidersTooltip";
import {SecurityContext} from "../../../utils/SecurityManager";
import {purchaseRequisitionListSGroups} from "../../../utils/modelUtils/jazz/purchaseRequisitionUtils";
import {TideApiContext} from '../../../api/tideApiConfig';
import Switch from "react-bootstrap-switch";
import switchTranslations from "../../../utils/switchTranslations";


const tableStructure = [
    {
        Header: 'Número',
        accessor: 'folio',
        id: 'folio'
    },
    {
        Header: 'Solicitante',
        accessor: (req) => employeeFullName(req.requestedBy),
        id: 'requestedBy.name',
    },
    {
        Header: 'Fecha de solicitud',
        accessor: (req) => moment(req.requestedDate).format('DD/MM/YYYY HH:mm'),
        id: 'requestedDate',
        Filter: ReactTableDataRangeFilter
    }, {
        Header: 'Estado',
        accessor: (pr) => trans(pr.status),
        id: 'status'
    },
    {
        Header: 'Productos',
        accessor: (pr) => {

            const purchaseProducts = pr.purchaseProducts;
            return (
                <OverlayTrigger placement="top" overlay={PurchaseProductProductsModalTooltip(purchaseProducts)}>
                    <span>{purchaseProducts[0].productBrand ? purchaseProducts[0].productBrand.product.name : purchaseProducts[0].name}</span>
                </OverlayTrigger>
            );

        },
        id: 'purchaseProducts.productBrand.product.name'
    },
    {
        Header: 'Proveedores',
        accessor: (pr) => {

            const selectedQuote = pr.purchaseProducts[0].selectedPurchaseProductQuote;
            if (selectedQuote)
                return (
                    <OverlayTrigger placement="top" overlay={PurchaseProductProvidersTooltip(pr.purchaseProducts)}>
                        <span>{selectedQuote.provider.name}</span>
                    </OverlayTrigger>
                );
            else {
                return 'Sin cotizar';
            }

        },
        id: 'purchaseProducts.selectedPurchaseProductQuote.provider.name'
    },
    {
        Header: 'Total',
        accessor: (req) => req.total ? moneyFormatter(req.total, 1) : 'Sin cotizar',
        id: 'total',
        filterable: false,
    },
    {
        Header: 'Área',
        accessor: 'jazzArea.name'
    },];

const loadingId = "@PurchaseRequisitionList.get";

const PurchaseRequisitionList = ({history}) => {

    const [filters, setFilters] = useState([]);
    const tableState = useReduxTableState('@PurchaseRequisitionList');
    const api = useContext(ApiContext);
    const security=useContext(SecurityContext);
    const tideApi=useContext(TideApiContext);

    const getMyPendingRequests = tableState.tableProps.genericState;
    const loadData = useCallback((tableState, table, forcePending) => {

        let options = tableStateToTideApiOptions(tableState);

        if (options.params.requestedDate && options.params.requestedDate.startDate)
            options.params['requestedDate[after]'] = options.params.requestedDate.startDate.format('YYYY-MM-DDT[00:00]');

        if (options.params.requestedDate && options.params.requestedDate.endDate)
            options.params['requestedDate[before]'] = options.params.requestedDate.endDate.format('YYYY-MM-DDT[23:59:59]');

        delete options.params.requestedDate;


        if(options.params.status){
            const lowerStatus = options.params.status.toLowerCase();
            if("solicitada".search(lowerStatus)!==-1)
                options.params.status = 'requested';
            else if("cancelada".search(lowerStatus)!==-1)
                options.params.status = 'canceled';
            else if("aprobada".search(lowerStatus)!==-1)
                options.params.status = 'approved';
            else if("cotizada".search(lowerStatus)!==-1)
                options.params.status = 'quoted';
            else if("rechazada".search(lowerStatus)!==-1)
                options.params.status = 'denied';
        }

        setFilters(options.params);

        options={
            params: {
                ...options.params,
                sGroups: purchaseRequisitionListSGroups
            },
            customProp:'@PurchaseRequisitionList',
            loadingId
        };
        const getPending = typeof forcePending!=='undefined'? forcePending : getMyPendingRequests;
        const getMethod = getPending ? 'getMyPendingPurchaseRequisitions' : 'get';
        tideApi.purchaseRequisitions[getMethod](options)
    }, [getMyPendingRequests, tideApi]);

    const handlePendingRequestsChange = useCallback((s, value) => {
        tableState.tableProps.onPageChange(0);
        tableState.tableProps.onGenericStateChange(value);
        loadData(tableState.tableProps, null, value);
    }, [loadData, tableState]);

    const debouncedLoadData = useCallback(_.debounce(loadData, 650),[loadData]);

    const goToDetail = (id) => history.push('/compras/requisitions/' + id);

    const purchaseRequisitions = useSelector(({api})=>api['@PurchaseRequisitionList'])||[];
    const {totalItems, itemsPerPage} = useSelector(({api})=>api['@PurchaseRequisitionListMeta'])||{totalItems: 0,itemsPerPage: 0, sGroups:purchaseRequisitionListSGroups};
    const plural = totalItems > 1 ? "s" : "";
    const loadingIds = useSelector(({loadingIds})=>loadingIds);

    return (
        <div className="container-fluid main-container PurchaseRequisitionList">

            {security.jazz.canRequestPurchases() &&
            <NavLink to='/compras/new-requisition'>
                <Button bsStyle='primary' className='pull-right'>Nueva solicitud</Button>
            </NavLink>}
            <div className="d-flex flex-column">
                <p>Mis solicitudes pendientes por aprobar.</p>
                <Switch value={!!tableState.tableProps.genericState} onChange={handlePendingRequestsChange} {...switchTranslations} />
            </div>

            <div className='clearfix'/>

            <ReactTable
                data={purchaseRequisitions}
                columns={tableStructure}
                pages={Math.ceil(totalItems / itemsPerPage)}
                loading={!!loadingIds[loadingId]}
                onFetchData={debouncedLoadData}
                getTdProps={(state, row) => ({onClick: () => row ? goToDetail(row.original.id) : null})}
                getTrProps={(state, row) => ({className: row && row.original.isUrgent ? 'urgent-row' : ''})}
                {...ReactTableDefaultProps}
                {...tableState.tableProps}
            />
            <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>

            <a
                href={ api.purchaseRequisitions.exportUrl({...filters, pagination:false})}
                rel="noopener noreferrer"
            >
                <Button>Exportar a Excel</Button>
            </a>
        </div>
    );

};


const trans = (string) => {
    const strings = {
        quoted: 'Cotizada',
        requested: 'Solicitada',
        approved: <span className='green'>Aprobada</span>,
        denied: 'Rechazada',
        canceled: <span className='red'>Cancelada</span>,
    };
    return strings[string] || string;
};

export default PurchaseRequisitionList;
