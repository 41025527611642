import React, {Component} from 'react';
import StatsCard from '../../../../components/Card/StatsCard';
import {NavLink} from 'react-router-dom';
import {connect} from "react-redux";
import LoaderIcon from '../../../../components/Helper/LoaderIcon';
import moment from 'moment';


class MAcceptedRequests extends Component {

    getInfoString(){

        if( this.props.incidenceJustifications && this.props.incidenceJustifications.constructor === Array ){

            const today = moment();
            const inDays = moment().add(5,"days");

            return this.props.incidenceJustifications.reduce(
                (acc, cur)=>{
                    const check = moment(cur.date);
                    if( cur.approved && check.isSameOrAfter(today) && check.isSameOrBefore(inDays) )
                        return acc + 1;

                    return acc;
                },
                0
            );

        }
        else{
            return <LoaderIcon/>
        }
    }

    render() {

        return (
            <StatsCard
                bigIcon={<i className="fa fa-user-circle text-danger" />}
                statsText={"Faltas programadas"}
                statsValue={this.getInfoString()}
                statsIcon={<NavLink to={"/alma/requests"}><i className="fa fa-eye" /></NavLink>}
                statsIconText={<NavLink to={"/alma/requests"}>Ver detalles</NavLink>}
            />
        );
    }
}

function mapStateToProps( {api:{MDashIncidenceJustifications:incidenceJustifications}} ){

    return {incidenceJustifications}
}

export default connect(mapStateToProps)(MAcceptedRequests);