import React, {useCallback, useContext, useState} from 'react';
import TideFormModal from "../../../../components/TideFormModal/TideFormModal";
import {golfCartFormFields} from "../../../../utils/modelUtils/ee/golfCartUtils";
import {TideApiContext} from "../../../../api/tideApiConfig";
import {useSelector} from "react-redux";
import CustomCheckbox from "../../../../elements/CustomCheckbox/CustomCheckbox";


const GolfCartFormModal = ({ onHide, toEdit }) => {
    const [checkbox, setCheckbox] = useState(toEdit?toEdit.isActive:true);

    const api = useContext(TideApiContext);

    const loadingId = "@GolfCartFormModal";
    const saveGolfCart = useCallback(( golfCart )=>{
        golfCart = {...golfCart, isActive: checkbox};
        api.golfCarts[toEdit?"update":"create"]({ id:toEdit?.id, params:golfCart, loadingId })
            .then( onHide );
    },[api, onHide, toEdit, checkbox]);

    const loading = useSelector( s=>!!s.loadingIds[loadingId] );

    const handleDelete = useCallback(()=>{
        api.golfCarts.delete({ id:toEdit.id }).then(onHide);
    },[api, onHide, toEdit]);

    return (
        <TideFormModal
            title={(toEdit?"Editar":"Nuevo") + " carrito de golf"}
            initialState={toEdit}
            className={"GolfCartFormModal"}
            onHide={onHide}
            fields={golfCartFormFields}
            onSubmit={saveGolfCart}
            disabled={loading}
        >
            <hr/>
            <div className="text-center">
                <CustomCheckbox isChecked={checkbox} onChange={setCheckbox} label={'Carrito activo'} inline/>
            </div>
            {!!toEdit && <i className="fa fa-trash red-icon" onClick={handleDelete}/>}
        </TideFormModal>
    );
};

export default GolfCartFormModal;
