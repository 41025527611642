import React from 'react';
import ApprovalMatrixConfigurator from "../../../components/Approvals/ApprovalMatrixConfigurator";
import {employeeApprovalRuleTypes} from "../../../utils/modelUtils/alma/employeeApprovalRulesUtils";

const StockRequestApprovalMatrixConfigurator = () => {

    return (
        <div>
            <ApprovalMatrixConfigurator approvalType={employeeApprovalRuleTypes.STOCK_REQUEST}/>
        </div>
    )
};

export default StockRequestApprovalMatrixConfigurator;

