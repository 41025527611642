import React, {useMemo} from 'react';
import useTideTable from "../../../hooks/table/useTideTable";
import ReactTable from "react-table";
import moneyFormatter from "../../../utils/moneyFormatter";
import {productBrandProviderListSGroups} from "../../../utils/modelUtils/almazen/productBrandProvider";


const tableStructure = [
    {
        Header: 'Nombre de producto',
        accessor: 'productBrand.product.name'
    },
    {
        Header: 'Marca',
        accessor: (productBrandProvider) => productBrandProvider.productBrand.brand? productBrandProvider.productBrand.brand.name : 'Sin marca',
        id: 'productBrand.brand.name'
    },
    {
        Header: 'Línea',
        accessor: (productBrandProvider) => productBrandProvider.productBrand.product.line? productBrandProvider.productBrand.product.line.name : 'Sin línea',
        id: 'productBrand.product.line.name'
    },
    {
        Header: 'Proveedor',
        accessor: (productBrandProvider) => productBrandProvider.provider.name,
        id: 'provider.name'
    },
    {
        Header: 'Precio',
        accessor: (productBrandProvider) =>{
            return productBrandProvider.lastPrice>0?moneyFormatter( productBrandProvider.priceInDisplayUnit, 1)+' '+productBrandProvider.productBrand.product.displayUnit.name:'Sin definir'
        },
        filterable:false,
        id: 'lastPrice'
    },
];

const requestOptions={customProp: 'ProductBrandProviderList'};

const ProductBrandProviderList = () => {

    const requestFilters=useMemo(()=>({
        'productBrand.product.isService':false,
        sGroups:productBrandProviderListSGroups
    }),[]);


    const productBrandProviderTable=useTideTable({
        entity:'productBrandProviders',
        columns:tableStructure,
        requestFilters,
        requestOptions
    });

    return (
        <div className="container-fluid main-container">
            <div className='clearfix'/>
            <ReactTable
                {...productBrandProviderTable.tableProps}
            />
        </div>
    );

};

export default ProductBrandProviderList;
