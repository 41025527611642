import React, {useContext} from 'react';
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import defaultMedalImage from "../../../assets/img/tgle-rewards/default-medal-image.svg";
import {ApiContext} from "../../../api/Api";
import defaultEmployeePhoto from "../../../assets/img/faces/face-0.jpg";

const FeedItem = ({fromEmployee, toEmployee, comment, recognition}) => {
    return (
        <div className='FeedItem'>
            <div className='feed-item-container'>
                <FeedHeader employee={toEmployee} recognition={recognition}/>
                <FeedDescription fromEmployee={fromEmployee} toEmployee={toEmployee} recognition={recognition}/>
                {comment && <FeedComment comment={comment}/>}
            </div>
        </div>
    );
};

const FeedComment = ({comment}) => {
    return (
        <>
            <hr/>
            <p className='feed-comment'>{comment}</p>
        </>
    );
};

const FeedDescription = ({fromEmployee, toEmployee, recognition}) => {
    return (
        <p>{employeeFullName(fromEmployee)} ha otorgado a <span className='bold'>{employeeFullName(toEmployee)}</span> la
            medalla
            de <span className='bold'>{recognition.name}</span></p>
    );
};

const FeedHeader = ({employee, recognition}) => {
    const api = useContext(ApiContext);
    const fullName = employeeFullName(employee);
    const employeeImage = employee.photo ?
        api.appFiles.getUrl(employee.photo.id) :
        defaultEmployeePhoto;
    const recognitionImage = recognition.image ?
        api.appFiles.getUrl(recognition.image.id) :
        defaultMedalImage;
    const position = 'Gerente / Contaduría';
    return (
        <div className='feed-header'>
            <div className='employee-image'>
                <img alt='Profile' src={employeeImage}/>
            </div>
            <div className='employee-header'>
                <h4 className='bold'>{fullName}</h4>
                <p>{position}</p>
            </div>
            <div className='medal-image'>
                <img alt='Medal badge' src={recognitionImage}/>
            </div>
        </div>
    );
};

export default FeedItem;
