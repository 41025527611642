import React, {useCallback} from 'react';
import {FormGroup, ControlLabel, Dropdown, MenuItem} from "react-bootstrap";


const PurchaseRequisitionCurrencySelector = ( {purchaseRequisition, onChange } )=>{

    const currency = purchaseRequisition.currency;

    const changeHandler = useCallback(( currency )=>{

        onChange( {...purchaseRequisition, currency } );
    },[purchaseRequisition, onChange]);

    return (
        <FormGroup>
            <ControlLabel>Moneda de la cotización: </ControlLabel>
            <Dropdown id='locations' onSelect={changeHandler}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {currency ? currency : "Seleccionar moneda"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <MenuItem eventKey="MXN" key="MXN">MXN</MenuItem>
                    <MenuItem eventKey="USD" key="USD">USD</MenuItem>
                    <MenuItem eventKey="EUR" key="EUR">EUR</MenuItem>
                </Dropdown.Menu>
            </Dropdown>
        </FormGroup>
    );
};

export default PurchaseRequisitionCurrencySelector;
