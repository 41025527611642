import React, {useContext} from "react";
import moment from "moment/moment";
import AppFileThumb from "./AppFileThumb";
import {ApiContext} from "../../api/Api";


const Comment = ({comment, self, onFileClick, onCommentRemove}) => {
    const date = moment(comment.createdDate);
    const api = useContext(ApiContext);

    return (
        <li className={self ? 'self' : 'other'}>
            <div className="avatar">
                <img src={api.users.avatarUrl(comment.createdBy)} alt="crash"/>
            </div>
            {!comment.isRemoved?
            <div className="msg">
                {self?<div className="remove-message"><i onClick={onCommentRemove} data-commentid={comment.id} className="fa fa-times"/></div>:null}
                {!self ? <p className='name'>
                    <small>{comment.createdBy ? (comment.createdBy.employee ? comment.createdBy.employee.name : comment.createdBy.username) : 'Sin creador'}</small>
                </p> : null}

                {comment.text ?
                    <p>
                        {comment.text}
                    </p> : null
                }


                {comment.appFiles.length > 0 ?
                    <div>
                        {comment.appFiles.map((f) => (
                            <AppFileThumb key={f.id} onClick={onFileClick} appFile={f}/>
                        ))}
                        <div className="clearfix"/>
                    </div>
                    : null
                }
                <p>

                </p>
                <div className="card-footer">
                    <h6>{date.format('HH:MM')}<strong> &nbsp;&nbsp;{date.format('DD/MM/YY')}</strong></h6>
                </div>
            </div>: <div className="msg"><b>Mensaje eliminado</b> <br/><br/></div>}
        </li>
    );

};
export default Comment;

