import React, {Component} from 'react';
import { Modal, ControlLabel, DropdownButton, MenuItem, Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from "../../../../elements/CustomButton/CustomButton";
import {employeeFullname} from '../../../../api/Api';
import moment from "moment";


class NewTicketModal extends Component{

    static contextTypes = {
        api: PropTypes.object,
        notifier: PropTypes.object,
    };

    loadingId = '@Class.NewTicketModal.tickets.create';

    state = {};

    changeStatus = ( status )=>{

        this.setState( {status} );
        this.context.api.tickets.edit( this.props.ticket.id, {status} )
            .catch(()=>{
                this.setState( {status:this.props.ticket.status} );
            })
    };

    render(){

        const ticket = this.props.ticket;

        const status = this.state.status || ticket.status;

        return (
            <Modal
                show={true}
                onHide={this.props.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                        <Table>
                            <tbody>
                            <tr>
                                <td>Tipo:</td><td>{ticket.ticketType.name}</td>
                            </tr>
                            <tr>
                                <td>Fecha límite de resolución:</td><td>{moment(ticket.limitResolutionDate).format( 'DD/MM/YYYY HH:mm' )}</td>
                            </tr>
                            <tr>
                                <td>Prioridad:</td><td>{ticket.ticketType.priority}</td>
                            </tr>
                            <tr>
                                <td>Creado por:</td><td>{ticket.createdBy? employeeFullname(ticket.createdBy.employee) : ''}</td>
                            </tr>
                            <tr>
                                <td>Fecha de creación:</td><td>{moment(ticket.createdDate).format( 'DD/MM/YYYY HH:mm' )}</td>
                            </tr>
                            <tr>
                                <td>Última actualización:</td><td>{ticket.updatedBy? employeeFullname(ticket.updatedBy.employee):''}</td>
                            </tr>
                            <tr>
                                <td>Estado:</td><td>
                                <DropdownButton title={ticketStatusStrings(status)} id='status'>
                                    <MenuItem eventKey='open' onSelect={this.changeStatus}>Abierto</MenuItem>
                                    <MenuItem eventKey='closed' onSelect={this.changeStatus}>Cerrado</MenuItem>
                                    <MenuItem eventKey='canceled' onSelect={this.changeStatus}>Cancelado</MenuItem>
                                </DropdownButton>
                            </td>
                            </tr>
                            </tbody>
                        </Table>

                    <ControlLabel>Descripción</ControlLabel>
                    <div className='well'>
                        {ticket.description}
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle='danger' onClick={this.props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const ticketStatusStrings = ( status )=>{
    switch (status) {
        case 'open':
            return 'Abierto';
        case 'canceled':
            return 'Cancelado';
        case 'closed':
            return 'Cerrado';
        default:
            return status;
    }
};

const mapStateToProps = ({api:{ticketTypes=[]}, loadingIds})=>({ticketTypes, loadingIds});

export default connect(mapStateToProps)(NewTicketModal);