import React, { useState, useCallback, useContext, useMemo, useEffect, useRef } from 'react';
import {Creatable} from "react-select";
import _ from "lodash";
import {ApiContext} from "../../api/Api";
import {useSelector} from 'react-redux';
import pluralize from 'pluralize';
//import useTraceUpdate from "../../../hooks/useTraceUpdate";

/**
 *
 * @param value * The actual select value
 * @param onChange function The change handler, receives the entity object as then only parameter
 * @param entity string The entity to select, in plural as defined in the api
 * @param creatable boolean Whether new entities can be created from the input
 * @param maxResults Number Max number of results to show (It uses the api pagination, it's not enforced in the front end)
 * @param filterBy string Property of the entity to search for coincidences with the input
 * @param entityFromString function For the creatable option, it should create an object from the input string
 * @param labelCreator function It receives the entity and should return the string to show in the options
 * @param createPrefix string The string to show in the option that would create the new entity, prepended to the actual input
 * @param placeholder string The input placeholder
 * @param valueRenderer function Optional component to render the selected value, defaults to just the input string
 * @param optionRenderer function Optional component to render each option of the dropdown
 * @param disabled boolean Disable the input
 * @param createParameter string If creatable, this string will be used as key for the entity returned from entityFromString to send it to the api
 * @param additionalFilters object Filters to add to the request
 * @param filterEntities function A function to remove unwanted entities before they are displayed
 * @param getMethod string Method to call from the api to fetch the entities
 * @param multi boolean If true, several entities can be selected and "value" should be an array
 * @param className string Class to add to the html
 * @param apiCustomProp string The key to save the results in Redux, defaults to 'EntitySelect'+entity
 * @param initialLoad boolean If true, the first page of entities will be loaded without a filter when the component is mount
 * @returns {*}
 * @constructor
 */
const EntitySelect = ({
                          value,
                          onChange,
                          entity,
                          creatable=false,
                          maxResults=10,
                          filterBy='name',
                          entityFromString=defaultEntityFromString,
                          labelCreator= defaultLabelCreator,
                          createPrefix='Crear ',
                          placeholder='Escribe para buscar...',
                          valueRenderer,
                          optionRenderer,
                          disabled=false,
                          createParameter,
                          additionalFilters=defaultAdditionalFilters,
                          filterEntities=defaultFilterEntities,
                          getMethod = 'get',
                          multi=false,
                          className,
                          apiCustomProp,
                          initialLoad,
                      }) => {

    const api = useContext( ApiContext );
    const [inputValue, setInputValue] = useState('');

    const loadingId = '@EntitySelect.'+entity+'.get';
    const customProp = apiCustomProp ||('EntitySelect'+entity);

    const loadEntities = useCallback((filter)=>{
        api[entity][getMethod]({pageSize:maxResults, loadingId, filters:{ [filterBy]: filter, ...additionalFilters}, customProp});
    }, [api, entity, additionalFilters, customProp, filterBy, getMethod, loadingId, maxResults]);

    const handleInputChange=useCallback( _.debounce(( input )=>{
        const trimmedInput = input.trim();
        setInputValue(trimmedInput);
        if( !trimmedInput )
            loadEntities();
        else
            loadEntities(trimmedInput);
    }, 650), [api, entity, loadEntities]);

    const loadedFirstTime=useRef(false);
    useEffect(()=>{
        if(initialLoad && !loadedFirstTime.current) {
            loadedFirstTime.current=true;
            loadEntities();
        }
    },[initialLoad, loadEntities]);


    let options=useSelector(({api})=>api[customProp]||[]);
    const loading=useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    //Creates object to send as value to the Creatable component
    const createValueForSelect = useCallback((entity)=>( { value: entity, label: labelCreator(entity) } ),[labelCreator]);
    //Converts the entity received as value prop to the Creatable component notation
    const selected = useMemo( ()=>{
        if( !multi )
            return value? createValueForSelect(value):null;
        return value && value.map? value.map(createValueForSelect) : [];
    }, [value, createValueForSelect, multi] );

    const onSelectChange = useCallback( ( option )=>{

        if( !option || (option.constructor !== Array && !option.value) )
            return onChange(null);

        if(multi){
            let selected = option.map( o=>o.value );
            if(selected.length && selected[selected.length-1].id==='new'){
                api[entity].create({
                    [createParameter || pluralize.singular(entity)]:entityFromString(selected[selected.length-1].name),
                    loadingId
                })
                    .then((newEntity)=>onChange([...selected, newEntity]));
                selected.splice(-1);
            }
            else
                onChange(selected);
        }
        else if(option.value.id === 'new'){
            api[entity].create({
                [createParameter || pluralize.singular(entity)]:entityFromString(option.value.name),
                loadingId
            })
                .then(onChange);
        }
        else
            return onChange(option.value);

    }, [api, onChange, entity, entityFromString, createParameter, loadingId, multi] );

    //If a filter was sent, use it
    const filteredOptions=useMemo(()=>filterEntities(options),[filterEntities, options]);

    //Convert the options to the Select notation
    let optionsForSelect = useMemo( ()=>filteredOptions.map(createValueForSelect), [filteredOptions, createValueForSelect]);

    //Add the "Create new" option if creatable and there's no exact match (if there's an exact match we don't want to duplicate the info)
    optionsForSelect = useMemo( ()=>{
        const exactMatch=_.find(options, opt=>labelCreator(opt).toUpperCase() === inputValue.toUpperCase() );
        if(creatable && inputValue && !exactMatch )
            return [...optionsForSelect, {value:{id:'new', name:inputValue}, label: createPrefix+inputValue }];
        return optionsForSelect;
    }, [options, creatable, inputValue, createPrefix, optionsForSelect, labelCreator]);

    return <Creatable
        name="entitySelect"
        options={optionsForSelect}
        onInputChange={handleInputChange}
        onChange={ onSelectChange }
        value={ selected }
        isLoading={loading}
        placeholder={placeholder}
        valueRenderer={valueRenderer}
        optionRenderer={optionRenderer}
        disabled={disabled}
        filterOptions={o=>o}
        multi={multi}
        className={className}
    />;
};

export default EntitySelect;


const defaultLabelCreator=e=>e?e.name:'';
const defaultEntityFromString=s=>({name:s});
const defaultAdditionalFilters={};
const defaultFilterEntities=e=>e;
