import React,{useState, useCallback, useContext, useMemo} from 'react';
import CustomButton from "../../../elements/CustomButton/CustomButton";
import useBoolean from "../../../hooks/useBoolean";
import AppointmentFormModal from "./components/AppointmentFormModal";
import useTideTable from "../../../hooks/table/useTideTable";
import ReactTable from "react-table";
import {appointmentListColumns, appointmentListSGroups} from "../../../utils/modelUtils/alma/appointmentUtils";
import {SecurityContext} from "../../../utils/SecurityManager";
import {paths} from "../../../routes/capitalHumano";
import {useSelector} from "react-redux";

const defaultFilters={sGroups:appointmentListSGroups, 'order[fromDate]':'DESC'};
const AppointmentList = ({location})=>{

    const [adding, startAdding, stopAdding]=useBoolean();
    const [listMyAppointments,,,toggleMyAppointments]=useBoolean();

    const security=useContext(SecurityContext);

    const isCH=location.pathname===paths.appointments;
    const me=useSelector(({api})=>api.me);

    const filters=useMemo(()=>({
        ...defaultFilters,
        'employees.id':listMyAppointments?me.employee.id:undefined
    }),[listMyAppointments,me]);

    const table=useTideTable({
        entity:'appointments',
        columns: appointmentListColumns,
        requestFilters: filters
    });

    const [showingDetails, setShowingDetails]=useState(null);
    const stopShowingDetails=useCallback(()=>setShowingDetails(null),[]);

    return (
        <div className='AppointmentList container-fluid'>
            <br/>

            {!isCH && security.alma.canCreateAppointments() &&
            <CustomButton onClick={toggleMyAppointments}>
                {listMyAppointments?'Ver horarios extraordinarios de colaboradores':'Ver mis horarios extraordinarios'}
            </CustomButton>}

            {security.alma.canCreateAppointments() &&
            <CustomButton bsStyle='primary' className='pull-right' onClick={startAdding}>Agregar horario</CustomButton>}

            <div className='clearfix' />

            <ReactTable
                {...table.tableProps}
                getTrProps={(table, row)=>({onClick:()=>setShowingDetails(row.original)})}
                />
            {adding &&
            <AppointmentFormModal
                onHide={stopAdding}
                />}

            {showingDetails &&
            <AppointmentFormModal
                onHide={stopShowingDetails}
                appointment={showingDetails}
                disabled
            />}
        </div>
    )
};

export default AppointmentList;
