import _ from 'lodash';

export const operationConstants = {
    ADD_GROUP: 'add',
    REMOVE_GROUP: 'remove'
};

export const prepareUserForServer = (user, group, operation) => {
    if (operation === operationConstants.ADD_GROUP) {
        return {
            permissionGroups: [
                ..._.map(user.permissionGroups, 'id'),
                group?.id
            ].filter((value, index, self) => {
                return self.indexOf(value) === index
            })
        };
    }else if(operation === operationConstants.REMOVE_GROUP){
        return {
            permissionGroups: _.remove(_.map(user.permissionGroups, 'id'), (permissionGroupId)=>{
                    return permissionGroupId !== group.id
                }
            )
        };
    }
};

