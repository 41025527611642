import React, {useContext} from 'react';
import {ApiContext} from "../../../api/Api";
import defaultPickMedalImage from '../../../assets/img/tgle-rewards/default-pick-medal-image.svg';

const Medal = ({className, id, name, image, points, onMedalClick, disabled}) => {
    const medal = {id, name, points};
    const api = useContext(ApiContext);
    const medalImage = image ? api.appFiles.getUrl(image.id) : defaultPickMedalImage;
    const cssClasses = [
        'medal-item',
        ...className ? [className] : []
    ];
    return (
        <div onClick={() => (!onMedalClick) ? null : onMedalClick(medal)} className={cssClasses.join(' ')}>
            <div className='medal-details'>
                <div className='points-badge'><p>+{points}</p></div>
                <div className='image-container'>
                  <img alt={name}  src={medalImage}/>
                </div>
                <div className='medal-badge'>
                    {name}
                </div>
            </div>
        </div>
    );
};

export default Medal;
