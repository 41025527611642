import {useContext, useMemo} from 'react';
import {searchComponent, useSearch} from "./search";
import searchIcon from '../../../assets/img/tgle-rewards/search-products-icon.svg'
import filterSearchIcon from '../../../assets/img/tgle-rewards/search-products-filter.svg';
import {ApiContext} from "../../../api/Api";

const searchConfigs = [
    {
        label: 'Valor (menor a mayor)',
        filter: {
            'order[pointsCost]': 'ASC'
        }
    },
    {
        label: 'Valor (mayor a menor)',
        filter: {
            'order[pointsCost]': 'DESC'
        }
    },
    {
        label: 'Orden alfabético',
        filter: {
            'order[name]': 'ASC'
        }
    }
];

const SearchProducts = searchComponent({
    className: 'search-products',
    searchIcon,
    filterSearchIcon,
    configTitle: 'Ordenar productos por:',
    searchConfigs
});

const queryToRequestParams = query => ({filters: {name: query}});
const mergeExtraFilters = (filters, query) => ({...query, filters: {...(query.filters || {}), ...filters}});
export const useProductsSearch = config => {
    const extraFilters = useMemo(() => (config ? config.extraFilters : {}) || {}, [config]);
    const api = useContext(ApiContext);
    const request = api.rewards.get;
    const nextPageRequest = api.rewards.getNextPage;
    const {results, searchProps, containerCSSClasses} = useSearch(queryToRequestParams, request, nextPageRequest, mergeExtraFilters, extraFilters, 'rewards');
    return {
        results,
        searchProps,
        containerCSSClasses
    };
};

export default SearchProducts;
