import React, {useContext, useEffect} from 'react';
import useInput from "../../../../hooks/useInput";
import {ApiContext} from "../../../../api/Api";
import {NotifierContext} from "../../../../utils/Notifier";

const EMAIL_TO_SEND_USERS_NEW_PASSWORDS_MONTHLY = 'EMAIL_TO_SEND_USERS_NEW_PASSWORDS_MONTHLY';

const CHConfiguration = () => {
    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);
    const newPasswordsMailReceiver = useInput('');
    const setNewPasswordsMailReceiver = newPasswordsMailReceiver.setValue;

    useEffect(() => {
        api.configurations.getSingle({id: EMAIL_TO_SEND_USERS_NEW_PASSWORDS_MONTHLY}).then((configuration) => setNewPasswordsMailReceiver(configuration.value));
    }, [api, setNewPasswordsMailReceiver]);

    const handleButtonSave = () => {
        api.configurations.batchEdit({configurations: [
                {
                    id: EMAIL_TO_SEND_USERS_NEW_PASSWORDS_MONTHLY,
                    value: newPasswordsMailReceiver.value
                }
            ]}).then(() => notifier.success('Configuración guardada'));
    };

    return (
        <div className="container-fluid Configuration">
            <div className='configuration-container col-md-8 col-md-push-2'>
                <div className='config-editor row'>
                    <div className='col-md-9 description'>
                        Correo que recibe las nuevas contraseñas mensualmente:
                    </div>
                    <div className='col-md-3 value'>
                        <input className="form-control" type="text" {...newPasswordsMailReceiver.bind}/>
                    </div>
                </div>
                <button className='btn btn-success' onClick={handleButtonSave}>
                    Guardar
                </button>
                <div className='clearfix' />
            </div>
        </div>
    )
}

export default CHConfiguration;