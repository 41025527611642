import React from 'react';
import _ from "lodash";
import {convertToSmallestUnit, displayAmount} from "../../../../utils/modelUtils/almazen/unitsUtility";


const AddStockMovementTableRequestedCell = ({stockMovementBatch, movement})=>{

    if(!stockMovementBatch)
        return <td>Sin definir</td>;

    const oldMovement = _.find(stockMovementBatch.movements, mov=>mov.productBrand.id===movement.productBrand.id);
    if(!oldMovement)
        return <td>Sin definir</td>;

    const input = convertToSmallestUnit(movement.quantity||0, movement.selectedUnit, oldMovement.productBrand.product);
    const oldValue = Math.abs(oldMovement.quantity);

    return (
        <td data-label='Solicitado'>
             <span className={
                 input>oldValue?'text-primary':
                     input<oldValue?'text-danger':
                         'text-success'
             }>
                 {displayAmount(oldValue, oldMovement.productBrand.product)}
             </span>
        </td>
    )
};

export default AddStockMovementTableRequestedCell;
