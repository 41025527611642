import React from 'react';
import useTideTable from "../../../../hooks/table/useTideTable";
import {
    orozcoProductRecipeListColumns,
    orozcoProductRecipeListSGroups
} from "../../../../utils/modelUtils/orozco/orozcoProductUtils";
import ReactTable from "react-table";
import {useHistory} from 'react-router-dom';
import {paths} from "../../../../routes/katharsis";
import useReduxTableState from "../../../../hooks/table/useReduxTableState";

const filters = {isAvailable:true, sGroups:orozcoProductRecipeListSGroups, isExtraCharge: false};

const OrozcoProductList = () => {

    const tableState = useReduxTableState('Katharsis.OrozcoProductList');

    const table = useTideTable({
        entity:'orozcoProducts',
        columns: orozcoProductRecipeListColumns,
        requestFilters:filters
    });

    const history = useHistory();

    const createRowProps = (_, {original}={})=>{
        if(!original)
            return {};
        return ({
            onClick: ()=>history.push(
                paths.recipesEditor
                    .replace(':type', 'product')
                    .replace(':id', original?.id)
            )
        });
    }

    return (
        <div className={"OrozcoProductList main-container"}>
            <div className='card'>
                <ReactTable
                    {...table.tableProps}
                    {...tableState.tableProps}
                    getTrProps={createRowProps}
                />
            </div>
        </div>
    );
};

export default OrozcoProductList;
