import React, {useCallback, useContext, useMemo, useState} from 'react';
import './_RecipeIngredientFormModa.scss';
import {
    ingredientTypes, initializeFormFromIngredient,
    prepareIngredientFormForServer
} from "../../../../../../utils/modelUtils/orozco/orozcoRecipeIngredientUtils";
import ClassicModal from "../../../../../../components/Modals/ClassicModal";
import TideEntitySelect from "../../../../../../components/TideEntitySelect/TideEntitySelect";
import useFormState from "../../../../../../hooks/useFormState";
import {productUnitConversionSGroups} from "../../../../../../utils/modelUtils/almazen/productsUtils";
import {getUnitsArray} from "../../../../../../utils/modelUtils/almazen/unitsUtility";
import _ from 'lodash';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {TideApiContext} from "../../../../../../api/tideApiConfig";
import {NotifierContext} from "../../../../../../utils/Notifier";
import {useSelector} from "react-redux";
import {orozcoRecipeUnitConversionSGroups} from "../../../../../../utils/modelUtils/orozco/orozcoRecipeUtils";

const filters={isActive: true};

const RecipeIngredientFormModal = ({onHide, recipe, onSave, ingredient}) => {


    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);
    const [type, setType] = useState(ingredient?.product? ingredientTypes.product : ingredient?.orozcoRecipe? ingredientTypes.recipe : '');
    const {form, handleSimpleChange, handleInputChange} = useFormState(initializeFormFromIngredient(ingredient));

    // ------- handle form changes ---------
    //Filter for selecting the ingredient from the server
    const additionalFilters = useMemo(()=>{
        return {
            ...filters,
            sGroups:type===ingredientTypes.product?
                productUnitConversionSGroups:orozcoRecipeUnitConversionSGroups
        }
    },[type]);

    const mainIngredient = form[ingredientTypes.product] || form[ingredientTypes.recipe];
    const units = (mainIngredient && getUnitsArray(mainIngredient))||[];

    const selectedUnit = _.find(units, u=>`${u.id}`===`${form.unit}`);
    let expectedShrinkage = 0;
    if(form.quantity && form.expectedShrinkage)
        expectedShrinkage=Number(form.quantity)*(Number(form.expectedShrinkage)/100)||0;

    // ----  Save edition or new ingredient ---------
    const loadingId = 'RecipeIngredientFormModal.orozcoRecipes';
    const handleSave = useCallback(()=>{
        try {
            const preparedIngredient = prepareIngredientFormForServer(form, type);

            const orozcoRecipeIngredients = (recipe.orozcoRecipeIngredients||[]).map(i=>i.id);
            orozcoRecipeIngredients.push(preparedIngredient);

            api.orozcoRecipes.update({id: recipe.id, params:{orozcoRecipeIngredients}, loadingId})
                .then(onSave);
        }
        catch (e){
            notifier.error(e.message);
        }
    },[api, recipe, form, type, notifier, onSave]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    // ----- handle deletion ----------
    const handleDelete = ()=>{
        api.orozcoRecipeIngredients.delete({id: ingredient.id, loadingId})
            .then(onSave);
    };

    return (
        <ClassicModal
            className={"RecipeIngredientFormModal"}
            title={'Ingrediente de '+recipe.name}
            onHide={onHide}
            onSave={handleSave}
            disabled={loading}
        >
            <div className='row'>
                <div className='col-xs-12'>
                    <p>¿Es un producto o una sub-receta?</p>
                    <select className='form-control' value={type} onChange={e=>setType(e.target.value)}>
                        <option value='' disabled>Selecciona tipo</option>
                        <option value={ingredientTypes.product}>Producto</option>
                        <option value={ingredientTypes.recipe}>Receta</option>
                    </select>
                </div>
            </div>

            {type &&
            <div>
                <div className='row'>
                    <div className='col-xs-12'>
                        <p>Selecciona {type===ingredientTypes.recipe?'la receta':'el producto'}</p>
                        <TideEntitySelect
                            entity={type+'s'}
                            value={form[type]}
                            onChange={handleSimpleChange(type)}
                            additionalFilters={additionalFilters}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-6'>
                        <p>Cantidad</p>
                        <input className='form-control'
                            value={form.quantity||''}
                            onChange={handleInputChange('quantity')}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <p>Unidad</p>
                        <select className='form-control'
                            value={form.unit||''}
                            onChange={handleInputChange('unit')}
                        >
                            <option value='' disabled=''>Selecciona una unidad</option>
                            {units.map(u=><option value={u.id} key={u.id}>{u.name}</option>)}
                        </select>

                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-6'>
                        <p>
                            Merma (%)
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id={1}>
                                    La merma es considerada encima de la cantidad a utilizar. Por ejemplo, si se configuran 200 gramos de
                                    producto y 50% por cierto de merma, el sistema tomará en cuenta que la receta gasta 300 gramos
                                    de producto del almacén.
                                </Tooltip>}>
                                <span><i className='fa fa-question-circle'/></span>
                            </OverlayTrigger>
                        </p>
                        <input className='form-control'
                            value={form.expectedShrinkage||''}
                            onChange={handleInputChange('expectedShrinkage')}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <p>=</p>
                        <p>{expectedShrinkage} {selectedUnit?.name}</p>
                    </div>
                </div>
                <hr/>
                <div className='row'>
                    <div className='col-sm-6'>
                        <p>Gasto total de {form[type]?.name}</p>
                    </div>
                    <div className='col-sm-6'>
                        <p>{((Number(form.quantity))+expectedShrinkage)||0} {selectedUnit?.name}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-12'>
                        <p className='small muted'>
                            El gasto total equivale a la cantidad utilizada en el platillo más
                            el {form.expectedShrinkage||0}% de merma generada por esa cantidad.
                        </p>
                    </div>
                </div>

                {ingredient.id && <>
                    <hr/>
                    <button className='btn btn-sm btn-danger' onClick={handleDelete} disabled={loading}>Eliminar ingrediente</button>
                </>}

            </div>}

        </ClassicModal>
    );
};

export default RecipeIngredientFormModal;
