import React,{useContext,useState,useCallback} from 'react';
import ClassicModal from "../../../components/Modals/ClassicModal";
import useNumberInput from "../../../hooks/useNumberInput";
import {ApiContext} from "../../../api/Api";
import {NotifierContext} from "../../../utils/Notifier";
import EntitySelect from "../../../components/EntitySelect/EntitySelect";
import {useHistory} from 'react-router-dom';

const NewWarehouseCountModal=({onHide})=>{

    const productsInput=useNumberInput(50);
    const [warehouse, setWarehouse]=useState(null);
    const [countType, setCountType] = useState('fixed');//fixed or random

    const api=useContext(ApiContext);
    const notifier=useContext(NotifierContext);

    const history=useHistory();

    const handleCreateClick=useCallback(()=>{
        if(!productsInput.value)
            return notifier.error('Selecciona el número de productos que quieres.');
        if(!warehouse)
            return notifier.error('Selecciona el almacén donde se realizará el conteo.');

        const warehouseCount =  {
            productsToCount:Number(productsInput.value),
            warehouse:warehouse.id,
            isRandom: countType==='random'
        };

        api.warehouseCounts.create({warehouseCount}).then((warehouseCount)=>{
            history.push('/almazen/warehouse-count/'+warehouseCount.id);
        });

    },[warehouse, productsInput.value, api, notifier, history, countType]);


    return (
        <ClassicModal
            title={'Nuevo conteo de inventario'}
            onHide={onHide}
            confirmText='Crear'
            onSave={handleCreateClick}
        >
            <p>Se creará una liste de productos aleatoria para hacer un conteo.</p>
            <p>¿En qué almacén se realizará el conteo?</p>
            <EntitySelect
                entity={'warehouses'}
                value={warehouse}
                onChange={setWarehouse}
                initialLoad
                maxResults={1000}
            />
            <p>Tipo de conteo</p>
            <select className='form-control' value={countType} onChange={e=>setCountType(e.target.value)}>
                <option value='fixed'>De productos con más movimiento</option>
                <option value='random'>Aleatorio</option>
            </select>

            {countType ==='random' && <>
                <p>¿cuántos productos quieres que tenga la lista?</p>
                <input className='form-control' {...productsInput.bind}/>
            </>}

        </ClassicModal>
    )
};

export default NewWarehouseCountModal;
