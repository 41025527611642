export default {
    ALMA:'alma',
    ALMAZEN:'almazen',
    CAPITAL_HUMANO:'capitalHumano',
    COMPRAS:'compras',
    EXPERIENCIA_ELITE:'ee',
    KATHARSIS:'katharsis',
    REWARDS:'rewards',
    SYSADMIN:'sysadmin',
};
