import aEmployeeRoutes from "./alma/employeeRoutes";
import aPayrollRoutes from "./alma/payrollRoutes";
import aHrRoutes from "./alma/hrRoutes";
import aLegalRoutes from "./alma/legalRoutes";
import aDirectorRoutes from "./alma/directorRoutes";
import aManagerRoutes from "./alma/managerRoutes";
import aRecruiterRoutes from "./alma/recruiterRoutes";
import AppointmentList from "../views/capitalHumano/Appointments/AppointmentList";
import PermissionsConfiguration from "../views/alma/Employees/permissions/PermissionsConfiguration";
import AlmaConfiguration from "../views/alma/admin/almaConfiguration";
import ListEmployeeQualifications from "../views/alma/Employees/core/ListEmployeeQualifications";
import EmployeeHelp from "../views/alma/Help/EmployeeHelp";
import EmployeeXFilesList from "../views/alma/Employees/XFiles/EmployeeXFilesList";
import EmployeeXFiles from "../views/alma/Employees/XFiles/EmployeeXFiles";

export default (securityManager)=>{

    let routes = aEmployeeRoutes;

    if(securityManager?.alma.canSeePayrollRoutes()){
        routes = aPayrollRoutes;
    }
    else {

        switch (securityManager?.role) {
            case 'SUPER_ADMIN':
                routes = aPayrollRoutes;
                break;
            case 'NOMINAS':
                routes = aPayrollRoutes;
                break;
            case 'RECURSOS_HUMANOS':
                routes = aHrRoutes;
                break;
            case 'JURIDICO':
                routes = aLegalRoutes;
                break;
            case 'DIRECTOR':
                routes = aDirectorRoutes;
                break;
            case 'GERENTE':
                routes = aManagerRoutes;
                break;
            case 'RECLUTADOR':
                routes = aRecruiterRoutes;
                break;
            case 'GERENTE_ADMINISTRADOR_EE':
                routes = aManagerRoutes;
                break;
            case 'NOMINAS_ADMINISTRADOR_EE':
                routes = aPayrollRoutes;
                break;
            default:
                break;
        }
    }
    routes = [...routes, {path: '/alma/appointments', name: "Horarios Extraordinarios", icon: "fa fa-clock-o", component: AppointmentList}];

    if(securityManager?.alma.canSeeEmployeeScoreReport())
        routes.push({path: "/alma/score_report", smallName: "Califs. de Colaboradores", name: "Calificaciones de Colaboradores", icon: "fa fa-check", component: ListEmployeeQualifications});

    if(securityManager?.capitalHumano.canSeeXFiles()) {
        routes.push({path: "/alma/xfiles", name: "Expediente digital", icon: "fa fa-files-o", component: EmployeeXFilesList});
        routes.push({path: "/capitalHumano/xfiles/:id", name: "Expedientes de Empleado", component: EmployeeXFiles, hidden: true});
    }

    if(securityManager?.core.canEditPermissions())
        routes = [...routes, {path: "/sysadmin/employees/permission-groups", name: "Grupos de permisos", icon: "fa fa-lock", component: PermissionsConfiguration}];

    if(securityManager?.alma.canEditConfiguration()){
        routes = [...routes, {path: "/alma/configuration", icon: "fa fa-cog", smallName: "Configuración", name:"Configuración de Alma", component: AlmaConfiguration}];
    }

    routes.push({ path: "/alma/help", name: "Ayuda", icon: "pe-7s-info", component: EmployeeHelp });

    return routes;

}
