import {Tooltip} from "react-bootstrap";
import React from "react";
import {getAllQuantityConversions} from '../../../utils/modelUtils/almazen/unitsUtility';

const productUnitsTooltip = (quantity, products) => {

    return (
        <Tooltip id='product-units-tooltip'>
            {getAllQuantityConversions(quantity, products).map((conversion)=>(
                <div key={conversion.id}>{conversion.unit}: {conversion.quantity}</div>
            ))}
        </Tooltip>);
};

export default productUnitsTooltip;
