import sysadminRoutes from '../routes/sysadminRoutes';
import systemsForUser from './systemsForUser';
import _ from 'lodash';
//import Changelog from "../views/sysadmin/Changelog";
import SecurityManager from "./SecurityManager";
import jazz from "../routes/jazz";
import rewards from "../routes/rewards";
import capitalHumano from "../routes/capitalHumano";
import almazen from "../routes/almazen";
import ee from "../routes/ee";
import Systems from "../constants/Systems";
import katharsis from "../routes/katharsis";
import alma from "../routes/alma";


const routesForUser = (user, system)=>{

    const role = user && user.role? user.role.name:"";
    const security = user && new SecurityManager(user);


    if( system === 'sysadmin' )
        if( role === 'SUPER_ADMIN')
            return sysadminRoutes;


    if( system === 'alma' ) {
        return alma(security);
    }
    else if( system === 'ee' ) {
        return ee(security);
    }

    else if( system === 'almazen' ) {
        return almazen(security);
    }

    else if( system === 'compras' )
        return jazz(security);

    else if(system === 'rewards')
        return rewards(security);

    else if( system === 'capitalHumano' )
        return capitalHumano(security);

    else if( system === Systems.KATHARSIS )
        return katharsis(security);

    else if( system === 'all' ) {

        let routes = [];

        _.forEach( systemsForUser( user ), system=>
            routes = [...routes, ...routesForUser( user, system ) ]
        );

        return routes;
    }
    else
        return []
};

export default routesForUser;
