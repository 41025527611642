import React, {useState, useMemo} from 'react';
import ReactTable from 'react-table';
import {fileColumns, fileClassification} from "../../../../utils/xFilesUtils";
import {XFilesContext} from "../../../../utils/xFilesUtils/xFilesReact";
import PreviewXFileModal from "./PreviewXFileModal";
import UploadXFileModal from "./UploadXFileModal";
import {Link} from 'react-router-dom';
import _ from 'lodash/fp';
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";
import Card from "../../../../components/Card/Card";
import useTideTable from "../../../../hooks/table/useTideTable";


const columns = [
    {
        Header: 'Nombre',
        id: 'name',
        Cell: ({original:employee})=>
            <Link to={'/capitalHumano/xfiles/'+employee.id}>{employeeFullName(employee)}</Link>
    },
    ...fileColumns
];

const classificationToFilterMappings = _.flow(
    _.keyBy('key'),
    _.mapKeys(key=>`employeeXFile.${key}`),
    _.mapValues(({key})=>`employeeXFile.${key}[exists]`)
);

const filterMappings = classificationToFilterMappings(fileClassification);

const EmployeeXFilesList= () => {
    const [action, setAction] = useState('list');
    // action: listing | uploading | previewing

    const [employee, setEmployee] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [file, setFile] = useState(null);
    const [isHired, setIsHired] = useState('true');

    const sGroups = useMemo(() => ['employee_simplified', 'employee_x_file_read', 'app_file_read'], []);

    const tableOptions = useMemo(() => {
        return {
            entity: 'employees',
            columns,
            getMethod: 'get2',
            filterMappings
        }
    }, []);

    const requestFilters = useMemo(()=>{
        if(isHired==='')
            return {
                sGroups
            };

        return {
            isHired: isHired,
            sGroups
        }
    }, [isHired, sGroups]);

    let table = useTideTable({...tableOptions, requestFilters});

    const showUpload = (fileType, employee) => () => {
        setAction('uploading');
        setEmployee(employee);
        setFileType(fileType);
    };

    const showPreview = (file, fileType, employee) => () => {
        setAction('previewing');
        setFile(file);
        setEmployee(employee);
        setFileType(fileType);
    };

    const onReloadRequest = ()=>{
        setAction('listing');
        table.reload();
    };

    const context = {
        employee,
        fileType,
        showUpload,
        showPreview,
        file
    };

    const handleIsHiredChange = (hiredValue) => {
        setIsHired(hiredValue);
    }

    const setShowing = () => setAction('showing');
    return (
        <XFilesContext.Provider value={context}>
            <div className="container-fluid main-container EmployeeXFilesList">
                <div className="row">
                    <div className="col-md-4">
                        <select className='form-control border border-info'
                                onChange={(e) => handleIsHiredChange(e.target.value)}
                                value={isHired}
                        >
                            <option value=''>Todos los colaboradores</option>
                            <option value={true}>Colaboradores activos</option>
                            <option value={false}>Colaboradores inactivos</option>
                        </select>
                    </div>
                </div>
                <Card
                    content={(
                        <div>
                            {action === 'uploading' &&
                            <UploadXFileModal onReloadRequest={onReloadRequest} onHide={setShowing}/>
                            }
                            {action === 'previewing' &&
                            <PreviewXFileModal employee={employee} fileType={fileType} file={file} onHide={setShowing}/>
                            }
                            <ReactTable {...table.tableProps} />
                        </div>
                    )}
                />
            </div>
        </XFilesContext.Provider>
    );
};

export default EmployeeXFilesList;
