import React, { useContext } from 'react';
import './_EEReports.scss';
import OrozcoReportCard from "./reports/OrozcoProductGuesReport";
import {SecurityContext} from "../../../utils/SecurityManager";

const EeReports = () => {
    const security=useContext(SecurityContext);

    return (
        <div className={"EEReports"}>
            <h2 className='text-center'>Reportes</h2>
            <div className='card container-fluid'>
                <div className='row'>
                    <div className='col-12 col-sm-6'>
                        {security.ee.canSeePaymentsReports() &&
                        <OrozcoReportCard apiRoute={'/api/orozco_payments/walk_ins/report'} title={'Recolección de Walk In'}/>}
                    </div>
                    <div className='col-12 col-sm-6'>
                        {security.ee.canSeePaymentsReports() &&
                        <OrozcoReportCard apiRoute={'/api/orozco_payments/products_group/report'} title={'DOTCOM CAFË'} productGroup={'DOTCOM_CAFE'}/>}
                    </div>
                </div>
                <br/>
                <div className='row'>
                    <div className='col-12 col-sm-6'>
                        {security.ee.canSeePaymentsReports() &&
                        <OrozcoReportCard apiRoute={'/api/orozco_payments/products_group/report'} title={'Recolección de Ingresos Extra'} productGroup={'INGRESO_EXTRA'}/>}
                    </div>
                    <div className='col-12 col-sm-6'>
                        {security.ee.canSeePaymentsReports() &&
                        <OrozcoReportCard apiRoute={'/api/orozco_payments/products_group/report'} title={'Recolección de Platillo Extra'} productGroup={'PLATILLO_EXTRA'}/>}
                    </div>
                </div>
                <br/>
            </div>
        </div>
    );
};

export default EeReports;