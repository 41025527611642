import { REDIRECT_AFTER_LOGIN } from '../actions/types';

const initialState = null;

export default function( state = initialState, action ) {

    if(action.type === REDIRECT_AFTER_LOGIN)
        return action.payload;

    return state;
}
