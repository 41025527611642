import React from 'react';


export default function PercentageAnswer( props ){

    let answer = { real:'', goal:'' };
    if( props.data && typeof props.data.response === 'object' )
        answer = props.data.response;

    const clickPlusReal = ()=> props.onChange( {response: { real:answer.real === ''? 1:(answer.real +1), goal: !answer.goal? 0 : answer.goal} } );

    const clickMinusReal = ()=> props.onChange( {response: { real:answer.real === ''? 0:(answer.real -1), goal: !answer.goal? 0 : answer.goal} } );

    const clickPlusGoal = ()=> props.onChange( {response: { goal:answer.goal === ''? 1:(answer.goal +1), real: !answer.real? 0 : answer.real} } );

    const clickMinusGoal = ()=> props.onChange( {response: { goal:answer.goal === ''? 0:(answer.goal -1), real: !answer.real? 0 : answer.real} } );

    const onRealNumberChange = (e)=>{

        if( e.target.value === '' )
            return props.onChange( undefined );

        const val = parseInt( e.target.value, 10 );
        if( isNaN( val ) ) return;

        const response = { ...answer };
        response.real = val;

        if( !response.goal ) response.goal = 0;

        props.onChange( {response} );
    };

    const onGoalNumberChange = (e)=>{

        if( e.target.value === '' )
            return props.onChange( undefined );

        const val = parseInt( e.target.value, 10 );
        if( isNaN( val ) ) return;

        const response = { ...answer };
        response.goal = val;

        if( !response.real ) response.real = 0;

        props.onChange( {response} );
    };

    return (
        <div className='answer-component PercentageAnswer'>

            <span className='title'>
            Real:
            </span>

            {props.editable?
                <button className={'left'} onClick={clickMinusReal}>
                    -
                </button>
                :null}

            <input
                type='number'
                value={answer.real}
                onChange={onRealNumberChange}
                disabled={!props.editable}
            />

            {props.editable?
                <button className={'right'} onClick={clickPlusReal}>
                    +
                </button>
                :null}

                <br/>

            <span className='title'>
            Meta:
            </span>

            {props.editable?
                <button className={'left'} onClick={clickMinusGoal}>
                    -
                </button>
                :null}

            <input
                type='number'
                value={answer.goal}
                onChange={onGoalNumberChange}
                disabled={!props.editable}
            />

            {props.editable?
                <button className={'right'} onClick={clickPlusGoal}>
                    +
                </button>
                :null}

                <div className='the-bar' style={{width: (answer.real/answer.goal * 100) + '%' }} />

        </div>
    );

}