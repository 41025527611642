import React, {useContext, useMemo} from 'react';
import CrudPage from "../../Dishes/CrudPage";
import {golfCartListColumns} from "../../../../utils/modelUtils/ee/golfCartUtils";
import GolfCartFormModal from "./GolfCartFormModal";
import {SecurityContext} from "../../../../utils/SecurityManager";

const GolfCartList = () => {

    const securityManager = useContext(SecurityContext);

    const tideTableConfig = useMemo(()=>({
        newTideApi: true,
        entity: "golfCarts",
        columns: golfCartListColumns
    }),[]);

    const canEdit = securityManager.ee.canEditGolfCart();

    return (
        <div className={"GolfCartList"}>
            <CrudPage
                addText="Agregar carrito"
                tideTableConfig={tideTableConfig}
                AddModal={canEdit && GolfCartFormModal}
                EditModal={canEdit && GolfCartFormModal}
            />
        </div>
    );
};

export default GolfCartList;
