import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import CustomButton from "../../elements/CustomButton/CustomButton";

const ClassicModal=({
                        title='',
                        onHide,
                        onSave,
                        children,
                        showConfirm=true,
                        showCancel=true,
                        confirmText='Guardar',
                        confirmStyle='success',
                        cancelText='Cerrar',
                        cancelStyle='danger',
                        disabled=false,
                        ...props
})=>{

    return (
        <Modal show onHide={onHide} {...props}>
            <ModalHeader closeButton>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>

            <ModalBody>{children}</ModalBody>

            {
                (showCancel || showConfirm) &&
                <ModalFooter>
                    {(showCancel) && <CustomButton bsStyle={cancelStyle} className='pull-left' onClick={onHide}     disabled={disabled}>{cancelText}</CustomButton>}
                    {showConfirm && <CustomButton bsStyle={confirmStyle} onClick={onSave} disabled={disabled}>  {confirmText}</CustomButton>}
                </ModalFooter>
            }
        </Modal>
    )
};

export default ClassicModal;
