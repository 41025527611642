
import React from 'react'
import useTideTable from '../../../hooks/table/useTideTable';
import ReactTable from 'react-table';
import {listSGroups, budgetRequestStatusTrans} from '../../../utils/modelUtils/jazz/budgetRequestUtils';
import ReactRequestTableStatusFilter from '../../../components/Helper/ReactRequestTableStatusFilter';
import moment from 'moment';
import {useHistory} from 'react-router-dom';

const params = {sGroups: listSGroups};
const requestOptions={customProp:'budgetRequestsTable'};

export const BudgetRequestList = () => {
    const history = useHistory();

    const budgetRequestColumns = [
        {Header:'ID', id: 'id', accessor: 'id', filterable: false},
        {Header:'Creación', id: 'createdDate', accessor: br=>moment(br.createdDate).format('DD/MM/YYYY HH:mm'), filterable: false},
        {Header:'Estatus', id:'status', accessor: br=>translateStatus(br.status), filterable: true, Filter: ReactRequestTableStatusFilter},
        {Header:'Aprobación 1', accessor:'approvedBy1.employee.fullName', filterable: false},
        {Header:'Aprobación 2', accessor:'approvedBy2.employee.fullName', filterable: false}
    ];

    const table = useTideTable({
        entity:'budgetRequests',
        newTideApi: true,
        columns: budgetRequestColumns,
        requestFilters: params,
        requestOptions
    });

    const handleGotoDetail = (budgetRequest) => {
        history.push('/compras/solicitud-automatica-presupuesto/' + budgetRequest.id);
    };

    const translateStatus = (string) => {
        return budgetRequestStatusTrans[string] || string;
    };

    return (
        <div className="BudgetRequestList container-fluid main-container">
            <div className='col-xs-12'>
                <ReactTable
                    {...table.tableProps}
                    getTdProps={(state, row) => ({onClick: () => row ? handleGotoDetail(row.original) : null})}
                />
            </div>
        </div>
    )
}
