import React, {useContext} from 'react';
import {ApiContext} from '../../../api/Api';
import DishModal from './DishModal';
import {listSGroups as sGroups} from '../../../utils/modelUtils/orozco/orozcoProductUtils';

const EditProductModal = ({onHide, toEdit, lounges, dishCategories}) => {
    const api = useContext(ApiContext);
    const editOrozcoProduct = (state, files=undefined)=>{
        toEdit.orozcoProductModifiers.map((realModifier) => {
            state.orozcoProductModifiers.map((editModifier) => {
                if (realModifier.name.toLowerCase() === editModifier.name.toLowerCase()) {
                    if (!editModifier.id) {
                        editModifier.id = realModifier.id;
                    }
                }
                return null;
            })
            return null;
        })
        api.orozcoProducts.edit({
            id: toEdit.id,
            properties: {...state, sGroups},
            files
        });
    };
    const deleteOrozcoProduct = id=>{
        api.orozcoProducts.delete({id});
    };
    return (
        <DishModal
            onHide={onHide}
            onSubmit={editOrozcoProduct}
            submitText='Editar platillo'
            title='Editar platillo'
            initialValues={toEdit}
            onDelete={deleteOrozcoProduct}
            lounges={lounges}
            dishCategories={dishCategories}
        />
    );
};

export default EditProductModal;
