import React, {useContext, useState } from 'react';
import {Modal, Table} from "react-bootstrap";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import { employeeFullname, ApiContext } from "../../../../api/Api";


const EmployeeProfileModal = ( { employee, onHide } )=>{

    const api = useContext( ApiContext );

    const [loadingState, setLoadingState] = useState( employee.employeeProfile? 'not-loaded':'loaded' );
    const [profile, setProfile] = useState( null );

    if( loadingState === 'not-loaded' ) {
        setLoadingState( 'loading' );
        api.employeeProfiles.getSingle( employee.employeeProfile.id )
            .then(( employeeProfile )=>{
                setProfile( employeeProfile );
                setLoadingState( 'loaded' );
            })
            .catch(()=>{
                setLoadingState( 'loaded' );
            });
    }


    let body;

    if( loadingState === 'loading' || loadingState === 'not-loaded' )
        body = <h4>Cargando...</h4>;

    else if( !profile )
        body = <h4>No se encontró información de este usuario.</h4>;

    else
        body =
            <>
                <h4>Información personal</h4>

                <Table>
                    <tbody>
                    <tr>
                        <td>Estado civil</td>
                        <td>{employee.maritalStatus}</td>
                    </tr>
                    <tr>
                        <td>Número de hijos</td>
                        <td>{profile.childrenNumber}</td>
                    </tr>
                    {profile.childrenNumber && profile.childrensAge?
                        <tr>
                            <td>Edades de los hijos</td>
                            <td>{ profile.childrensAge.join(',') }</td>
                        </tr>
                        :null}
                    <tr>
                        <td>Dirección</td>
                        <td>{employee.address}</td>
                    </tr>
                    <tr>
                        <td>Escolaridad</td>
                        <td>{employee.schoolingLevel}</td>
                    </tr>
                    <tr>
                        <td>Última escuela</td>
                        <td>{profile.lastSchool}</td>
                    </tr>
                    <tr>
                        <td>Pasión en la vida</td>
                        <td>{profile.passion}</td>
                    </tr>
                    <tr>
                        <td>Qué te hace feliz</td>
                        <td>{profile.happinessReason}</td>
                    </tr>
                    <tr>
                        <td>Talento</td>
                        <td>{profile.talent}</td>
                    </tr>
                    <tr>
                        <td>Tipo de comida favorita</td>
                        <td>{profile.favouriteFoodType}</td>
                    </tr>
                    <tr>
                        <td>Platillo favorito</td>
                        <td>{profile.favouriteFood}</td>
                    </tr>
                    <tr>
                        <td>Deporte favorito</td>
                        <td>{profile.favouriteSport}</td>
                    </tr>
                    <tr>
                        <td>Equipo favorito</td>
                        <td>{profile.favouriteTeam}</td>
                    </tr>
                    <tr>
                        <td>Género musical favorito</td>
                        <td>{profile.favouriteMusicType}</td>
                    </tr>
                    <tr>
                        <td>Grupo o artista favorito</td>
                        <td>{profile.favouriteMusicGroup}</td>
                    </tr>
                    <tr>
                        <td>Película favorita</td>
                        <td>{profile.favouriteMovie}</td>
                    </tr>
                    <tr>
                        <td>Actor/Actriz favorito</td>
                        <td>{profile.favouriteActor}</td>
                    </tr>
                    <tr>
                        <td>Qué animal serías</td>
                        <td>{profile.animalWouldBe}</td>
                    </tr>
                    <tr>
                        <td>Tiempo de trayecto al trabajo</td>
                        <td>{profile.dailyTravelMinutes} minutos</td>
                    </tr>
                    <tr>
                        <td>Costo del trayecto</td>
                        <td>{profile.dailyTravelCost}</td>
                    </tr>


                    </tbody>
                </Table>
            </>;

    return (
        <Modal
            show={true}
        >

            <Modal.Header>
                <Modal.Title>Perfil de {employeeFullname( employee )}</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                { body }

            </Modal.Body>

            <Modal.Footer>
                <CustomButton bsStyle='danger' onClick={onHide}>Cerrar</CustomButton>
            </Modal.Footer>

        </Modal>
    );
};

export default EmployeeProfileModal;
