import React, {useMemo, useEffect, useContext} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import DateRangeFilter, {useDateRangeFilter} from '../Filters/DateRangeFilter';
import {dataView} from '../utils';

const loadingId = '@VipNonGrataVisitsReport.report';

const dataAxis = [
    {
        getKey: 'shift',
        getDisplayName: ({shift})=>{
            switch(shift){
            case 'morning': return 'Matutino';
            case 'afternoon': return 'Vespertino';
            case 'night': return 'Nocturno';
            default: return 'Desconocido';
            }
        }
    },
    {
        getKey: 'loungeId',
        getDisplayName: 'loungeName'
    }
];

const prepareDataView = dataView(dataAxis);

const vipNonGrataVisitsHOC = (prop) =>{
    const VipNonGrataVisitsReport = ({defaultDateRange}) => {
        const dateConfig = useMemo(()=>({initial: defaultDateRange}), [defaultDateRange]);
        const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);
        const api = useContext(ApiContext);
        useEffect(()=>{
            api.lounges.vipNonGrataReport({loadingId, params: dateRequest});
        },[api, dateRequest]);
        const report = useSelector(({api})=>api.loungesVipNonGrataReport);
        const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);

        const {axisData, foldDataValues} =
            useMemo(()=>prepareDataView(report, (v)=>v[prop]), [report]);

        const shifts = axisData[0];
        const lounges = axisData[1];

        const getValue = foldDataValues((x,y)=>parseInt(x)+parseInt(y), 0);

        return (
            <div className='VipNonGrataVisitsReport'>
                <DateRangeFilter {...dateFilterProps}/>
                {loading && <h3 className='text-center'>Cargando...</h3>}
                <Table striped>
                    <thead>
                        <tr>
                            <th>
                                Sala
                            </th>
                            {shifts.map(({key, displayName})=><th key={key}>{displayName}</th>)}
                            <th>Total sala</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lounges.map(
                            ({key: loungeId, displayName})=>(
                                <tr key={loungeId}>
                                    <td>{displayName}</td>
                                    {shifts.map(({key: shift})=><td key={shift}>{getValue([shift, loungeId])}</td>)}
                                    <td><label>{getValue([undefined, loungeId])}</label></td>
                                </tr>
                            ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td><label>Total turno</label></td>
                            {shifts.map(({key: shift, value})=><td key={shift}><label>{getValue([shift])}</label></td>)}
                            <td><label>{getValue([])}</label></td>
                        </tr>
                    </tfoot>
                </Table>
            </div>
        );
    };
    return VipNonGrataVisitsReport;
};

export default vipNonGrataVisitsHOC;
