import React, {useCallback} from 'react';


const InputConfigTool = ({loungeMapItem, onChange, field, displayName, type='text'})=>{

    // ---- Config change handler ----
    const handleConfigChange = useCallback( e=>{
        let value = e.target.value;
        if(type === 'number')
            value = Number(value)||0;
        const config =  {...(loungeMapItem.config||{}), [field]:value};
        onChange({...loungeMapItem, config });
    },[loungeMapItem, onChange, type, field]);

    return (<>
        <span className='toolbox-label'>{displayName}:</span>{' '}
        <input className='toolbox-input' type={type} value={(loungeMapItem.config && loungeMapItem.config[field])||''} onChange={handleConfigChange}/> <br/>
    </>);
};

export default InputConfigTool;
