import moment from "moment";
import {displayDateTimeFormat} from "../../timeUtils";
import _ from "lodash";

export const golfCartSGroups = [
    "golf_cart_read",
    "golf_cart_read_golf_cart_schedules",
    "golf_cart_read_operator",
    "employee_simple",
    "golf_cart_schedule_read",
    "golf_cart_schedule_read_available_days",
    "golf_cart_schedule_read",
    "golf_cart_schedule_read_golf_cart_scheduled_trips",
    "golf_cart_scheduled_trip_read",
    "golf_cart_scheduled_trip_read_airport_gates",
    "airport_gate_read",
    "day_of_the_week_read",
    "golf_cart_schedule_read_lounge",
        "place_read_id",
        "place_read_name",
    "golf_cart_scheduled_trip_read_airport_gates",
        "airport_gate_read",
        "airport_gate_read_airport_gate_lounges",
        "airport_gate_lounge_read_eta_minutes",
        "airport_gate_lounge_read_lounge",
    "cassette_read",
    "cassette_read_comments_count"
];

export const golfCartSelectorSGroups = [
    "golf_cart_read",
    "golf_cart_read_operator",
    "employee_simple",
];
export const golfCartSelectorFilters = {
    sGroups: golfCartSelectorSGroups,
    isActive: true
}


export const golfCartListColumns = [
    {
        Header: "Nombre",
        accessor: "name"
    },
    {
        Header: "Capacidad de huéspedes",
        accessor: "maxPassengersNumber"
    },
    {
        Header: "Fecha de creación",
        accessor: ({createdDate}) => createdDate ? moment(createdDate).format(displayDateTimeFormat) : "Sin información",
        id: "createdDate",
        filterable: false
    },
    {
        Header: "Carrito Activo",
        accessor: ({isActive}) => isActive ? "Carrito Activo" : "Carrito Inactivo",
        id: "isActive",
        filterable: false
    },
];

export const getGolfCartScheduleForDate = (golfCart, date) => {
    const dayOfTheWeek = date.weekday();
    let golfCartSchedule = {
        golfCartScheduledTrips: []
    };

    _.each(golfCart.golfCartSchedules, (schedule) => {
        if (schedule.isActive) {
            if (_.find(schedule.availableDays, (availableDay) => availableDay.dayNumber === dayOfTheWeek)) {
                golfCartSchedule.golfCartScheduledTrips = [...golfCartSchedule.golfCartScheduledTrips, ...schedule.golfCartScheduledTrips];
            }
        }
    });

    if (golfCartSchedule.golfCartScheduledTrips.length === 0)
        return null;

    return golfCartSchedule;

}

export const golfCartFormFields = [
    {label: "Nombre", name: "name"},
    {label: "Capacidad de huéspedes", name: "maxPassengersNumber", type: "number"}
];
