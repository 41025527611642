
export const prepareGolfCartSchedulesTripForServer = ( form )=>{
    if( (!form.startTime && !form.endTime) || form.startTime > form.endTime ){
        throw new Error("Configuración de horario inválida");
    }
    if(!form.airportGates?.length){
        throw new Error("El viaje debe tener al menos una puerta");
    }
    return {
        id: form.id,
        startTime:form.startTime,
        endTime: form.endTime,
        airportGates: form.airportGates.map( ag=>ag.id )
    };
}

export const getEmptyGolfCartScheduledTrip = ()=>({
    tempId: Math.random(),
    startTime: 60*12,
    endTime: 60*12+30,
    airportGates: []
});
