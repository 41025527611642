import React, {useState, useContext } from 'react';
import {Modal} from 'react-bootstrap';
import Button from "../../../elements/CustomButton/CustomButton";
import {connect} from 'react-redux';
import {ApiContext} from "../../../api/Api";
import {NotifierContext} from "../../../utils/Notifier";
import useSingleDatePickerState from 'hooks/useSingleDatePickerState';
import { SingleDatePicker } from 'react-dates';


const loadingId = '@EvaluateProviderModal.providerEvaluation.create';

const EvaluateProviderModal = ({onHide, provider, loadingIds}) => {

    const api = useContext( ApiContext );
    const notifier = useContext( NotifierContext );

    const [answers, setAnswers] = useState( {});

    const {props:datePickerProps} = useSingleDatePickerState();

    const getValue = ( name )=>
        typeof answers[name] === 'undefined'?
        '' : answers[name];
    ;

    const onAnswerChange = ( {target} )=>{
        const newAnswers = {...answers, [ target.name ]: target.value === ''? '':Number( target.value )};
        setAnswers( newAnswers);
    };

    const onSend = ()=>{
        if(
            getValue('differentPriceCount') === '' ||
            getValue('lateDeliveryCount') === '' ||
            getValue('incompleteDeliveryCount') === '' ||
            getValue('badQualityCount') === ''
        )
            return notifier.error( 'No has llenado todos los campos' );

        const momentDate = datePickerProps.date.startOf('day').format( 'YYYY-MM-DD  HH:mm:ss' );
        const date = new Date( momentDate );

        api.providerEvaluations.create( {...answers, fromDate: date, toDate: date, provider: provider.id}, loadingId )
            .then( ()=>{
                notifier.success('Evaluación enviada');
                onHide();
            })

    };

    return (
        <Modal
            show={true}
            onHide={onHide}
        >
            <Modal.Header>
                <Modal.Title>Evaluación de proveedor {provider.commercialName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <form autoComplete='off'>
                    <h4>Selecciona el periodo a evaluar</h4>
                    <SingleDatePicker
                        {...datePickerProps}
                        placeholder='Selecciona...'
                    />

                    <h5>1. PRECIO- Del total de entregas, ¿Cuántas no me respeto el precio?</h5>
                    <input className='form-control' type='number' name='differentPriceCount' onChange={onAnswerChange} value={ getValue('differentPriceCount') }/>

                    <h5>2. TIEMPO DE ENTREGA- Del total de entregas, ¿Cuántas no respeto el tiempo de entrega?</h5>
                    <input className='form-control' type='number' name='lateDeliveryCount' onChange={onAnswerChange} value={ getValue('lateDeliveryCount') }/>


                    <h5>3. CANTIDAD- Del total de entregas, ¿Cuántas veces no entrego la cantidad solicitada?</h5>
                    <input className='form-control' type='number' name='incompleteDeliveryCount' onChange={onAnswerChange} value={ getValue('incompleteDeliveryCount') }/>


                    <h5>4. CALIDAD- CONDICIÓN FÍSICA- Del total de entregas, ¿Cuántas veces el material no traía certificado de calidad, esta dañado el producto, o no corresponde con la orden de compra?</h5>
                    <input className='form-control' type='number' name='badQualityCount' onChange={onAnswerChange} value={ getValue('badQualityCount') }/>

                </form>

            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle='danger' disabled={loadingIds[loadingId]} className='pull-left'
                        onClick={onHide}>Cancelar</Button>
                <Button bsStyle='success' disabled={loadingIds[loadingId]} onClick={onSend}>Enviar</Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToMaps = ({ loadingIds}) => ({
    loadingIds
});

export default connect(mapStateToMaps)(EvaluateProviderModal);
