import React, {useContext, useEffect, useState,useCallback, useMemo} from 'react';
import {ApiContext} from "../../../api/Api";
import {useSelector} from "react-redux";
import moment from "moment";
import {Col, Grid, Modal, Row} from "react-bootstrap";
import Card from "../../../components/Card/Card";
import BigCalendar from "react-big-calendar";
import _ from 'lodash';
import CustomButton from "../../../elements/CustomButton/CustomButton";
import {avatarUrl, employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import {UserDetailApiProp, UserDetailLoadingId} from "../../alma/Employees/core/UserDetail";

const EmployeeWorkToolCalendar = () => {
    const api = useContext(ApiContext);

    const [selectedEmployeeWorkTools, setSelectedEmployeeWorkTools] = useState([]);

    const employeeWorkTools = useSelector((state) => state.api.employeeWorkTools) || [];


    const loadEmployeeDetail = useCallback((employeeId) => {
        api.users.get(0, 1, UserDetailLoadingId, {employee: employeeId}, UserDetailApiProp);
    }, [api]);


    useEffect(() => {
        api.employeeWorkTools.get(0, 0, undefined, {pagination: false, 'expirationDate[exists]': true});
    }, [api]);

    const events=useMemo(() => {
        const groupedWorkTools = _.groupBy(employeeWorkTools, (item) => `${item.expirationDate}--${item.workTool.name}`);
        _.forEach(groupedWorkTools, (value, key) => {
            groupedWorkTools[key] = {
                title: value.length + ' ' + value[0].workTool.name,
                from: new Date(moment(value[0].expirationDate).get('year'), moment(value[0].expirationDate).get('month'), moment(value[0].expirationDate).get('date')),
                to: new Date(moment(value[0].expirationDate).get('year'), moment(value[0].expirationDate).get('month'), moment(value[0].expirationDate).get('date')),
                employeeWorkTools: value
            }
        });
        return _.values(groupedWorkTools);
    }, [employeeWorkTools]);

    const clearSelected=useCallback(()=>setSelectedEmployeeWorkTools([]),[]);


    const messages = {
        allDay: 'Todo el día',
        agenda: 'Agenda',
        previous: '<<',
        next: '>>',
        today: 'Hoy',
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        date: 'Fecha',
        time: 'Hora',
        event: 'Evento',
        showMore: total => `+ ${total} más`
    }
    return (
        <div className="reservationsCalendar EmployeeWorkToolCalendar">
            <Grid fluid>
                <Row>
                    <Col md={10} mdOffset={1}>
                        <Card
                            calendar
                            content={
                                <BigCalendar
                                    titleAccessor='title'
                                    startAccessor='from'
                                    endAccessor='to'
                                    messages={messages}
                                    selectable
                                    views={['day', 'week', 'month']}
                                    events={events}
                                    defaultView='month'
                                    onSelectEvent={event => setSelectedEmployeeWorkTools(event.employeeWorkTools)}
                                    scrollToTime={new Date(1990, 1, 1, 6)}
                                    defaultDate={new Date()}
                                />
                            }
                        />
                    </Col>
                </Row>
            </Grid>

            {selectedEmployeeWorkTools.length > 0 ?
                <Modal
                    show={true}
                    onHide={clearSelected}
                >

                    <Modal.Header>
                        <Modal.Title>
                            Vencimiento
                            de {selectedEmployeeWorkTools.length} {selectedEmployeeWorkTools[0].workTool.name} el {moment(selectedEmployeeWorkTools[0].expirationDate).format('DD-MM-YYYY')}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="EmployeeWorkToolCalendarModal">
                        {_.sortBy(selectedEmployeeWorkTools,'employee.name').map((employeeWorkTool) =>
                            <div className='employee-row' key={employeeWorkTool.id}>
                                <div className='face'
                                     style={{backgroundImage: `url('${avatarUrl(employeeWorkTool.employee, api)}')`}}/>
                                <span
                                    onClick={() => loadEmployeeDetail(employeeWorkTool.employee.id)}>{employeeFullName(employeeWorkTool.employee)}</span>
                                {/*<CustomButton bsStyle='success'>Renovar</CustomButton>*/}
                            </div>
                        )}

                    </Modal.Body>

                    <Modal.Footer>
                        <CustomButton bsStyle='danger'
                                      onClick={clearSelected}>Cerrar</CustomButton>
                    </Modal.Footer>

                </Modal> : null
            }

        </div>
    )
};

export default EmployeeWorkToolCalendar;
