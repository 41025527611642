import React, {useEffect, useContext, useState} from 'react';
import Card from '../../../components/Card/Card.jsx';
import {connect} from 'react-redux';
import {ApiContext} from "../../../api/Api";
import MeasurementConversionTemplateForm from "./MeasurementConversionTemplateForm";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import {Row, Col} from "react-bootstrap";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import useBoolean from "../../../hooks/useBoolean";


const MeasurementConversionTemplatesList = ({measurementConversionTemplates}) => {

    const api = useContext(ApiContext);

    const [openTemplate, addNewTemplate, cancelNewTemplate] = useBoolean();
    const [openEditTemplate, setOpenEditTemplate] = useState(null);
    const [editTemplate, setEditTemplate] = useState(null);
    const [deleteTemplate, setDeleteTemplate] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);

    useEffect(() => {
        api.measurementConversionTemplates.get(0, 200);
    }, [api]);

    const editTemplateHandler = (mctInfo) => {
        setEditTemplate(mctInfo);
        setOpenEditTemplate(true);
    };

    const deleteTemplateHandler = (mctInfo) => {
        setDeleteModal(true);
        setDeleteTemplate(mctInfo);
    };

    const cancelDeleteTemplateHandler = () => {
        setDeleteTemplate(null);
        setDeleteModal(false);
    };

    const confirmDeleteTemplateHandler = () => {
        api.measurementConversionTemplates.delete(deleteTemplate.id);
        setDeleteTemplate(null);
    };

    return (
        <>
            <Card
                title="Administración de plantillas de conversión de unidades"
                className='container-fluid MeasurementConversionTemplatesList'
                content={
                    <Row>
                        {measurementConversionTemplates.map(mct =>
                            <Col lg={3} md={4} sm={6} key={mct.id} className='one-template'>
                                <div className='inner-template'>
                                    <h5>{mct.name}</h5>
                                    <hr/>
                                    <p>Unidad más
                                        pequeña: <strong>{mct.smallestUnit ? mct.smallestUnit.name : 'Sin definir'}</strong>
                                    </p>
                                    <p>Unidad para
                                        mostrar: <strong>{mct.displayUnit ? mct.displayUnit.name : 'Sin definir'}</strong>
                                    </p>

                                    {mct.conversionRules && mct.conversionRules.length ?
                                        <>
                                            <hr/>
                                            <h6>Reglas de conversión</h6>
                                            <ul>
                                                {mct.conversionRules.map((cr, i) =>
                                                    <li key={i}> 1 {cr.unit ? cr.unit.name : 'Sin definir'} = {Number(cr.multiplier)} {mct.smallestUnit ? mct.smallestUnit.name : 'Unidad más pequeña'} </li>
                                                )}
                                            </ul>
                                        </>
                                        : null
                                    }
                                    <div className="iconsContainer">
                                        <i className="fa fa-trash" onClick={() => deleteTemplateHandler(mct)}/>
                                        <i className="fa fa-pencil" onClick={() => editTemplateHandler(mct)}/>
                                    </div>
                                </div>
                            </Col>
                        )}

                        <div className='clearfix'/>
                        <hr/>
                        <CustomButton bsStyle='primary' onClick={addNewTemplate}>Agregar</CustomButton>
                    </Row>
                }
            />

            {openTemplate &&
            <MeasurementConversionTemplateForm
                onHide={cancelNewTemplate}
                onClose={cancelNewTemplate}
            />
            }

            {openEditTemplate &&
            <MeasurementConversionTemplateForm
                measurementConversionTemplate={editTemplate}
                onHide={() => setOpenEditTemplate(false)}
            />
            }

            {deleteTemplate &&
            <ConfirmModal
                show={deleteModal}
                message={`¿Estás seguro que deseas borrar el producto ${deleteTemplate ? deleteTemplate.name : ''}?`}
                onCancel={cancelDeleteTemplateHandler}
                onConfirm={confirmDeleteTemplateHandler}
            />
            }
        </>
    );
};

const mapStateToProps = ({
api:
{
    measurementConversionTemplates = []
}
}
)=>(
{
measurementConversionTemplates
}
);

export default connect(mapStateToProps)(MeasurementConversionTemplatesList);
