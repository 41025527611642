import React,{useEffect, useContext, useMemo, useState} from 'react';
import ManWomanStats from './ManWomanStats';
import GenerationStats from './GenerationStats';
import AirlineStats from './AirlineStats';
import DestinationStats from './DestinationStats';
import ExcellenceReport from './ExcellenceReport';
import PurposeStats from './PurposeStats';
import './LoungeAccessMethodCard.scss';
import ReactEcharts from "echarts-for-react";
import DateRangeFilter, {useDateRangeFilter} from '../../../DavinciReports/Filters/DateRangeFilter';
import {ApiContext} from '../../../../../api/Api';
import {useSelector} from 'react-redux';
import {flattenLoungeAccessMethods} from '../../../../../utils/modelUtils/davinci/loungeAccessMethodUtils';
import {getGaugeOption} from '../../utils';
import {percentageString as mPercentageString} from '../../../../../utils/MathUtils';
import _ from 'lodash/fp';

const loadingId = '@LoungeAccessMethodCard.report';

const gcareTypes = [
    ['Alimentos', 'food'],
    ['Bebidas', 'drinks'],
    ['Limpieza', 'cleanliness'],
    ['Recepción', 'reception'],
    ['Staff', 'staff'],
];

const getNpsValues = _.flow(
    _.keyBy('npsCategory'),
    _.mapValues(({count})=>parseInt(count))
);

const loungeAccessMethodGcareStats = accessType => () => {
    const api = useContext(ApiContext);
    const [lam, setLam] = useState('');
    const generationReport = useSelector(({api})=>api.generationReport||[]);
    const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter();
    const [gcareType, setGcareType] = useState(gcareTypes[0][1]);
    useEffect(()=>{
        api.loungeAccessMethods.get({filters: {
            sGroups: ['lounge_access_method_read_lounge_sub_access_methods', 'lounge_access_method_read'],
            'loungeParentAccessMethod[exists]': false,
            accessType,
            pagination: false
        }});
    }, [api]);
    const manWomanKey = useMemo(Math.random, [generationReport]);
    const parentLams = useSelector(({api})=>api.loungeAccessMethods||[]);
    const lams = useMemo(()=>[{id: '', name:'Todos'}].concat(
        _.sortBy(['name'], flattenLoungeAccessMethods(parentLams))), [parentLams]);

    useEffect(()=>{
        api.guests.generationReport({
            loadingId,
            params: {
                ...(lam?{loungeAccessMethods: [lam]}:{}),
                ...dateRequest
            }
        });
        api.answers.gcareNpsReport({params:{
            ...dateRequest,
            ...(lam?{visitLoungeAccessMethods: [lam]}:{})
        }});
    }, [api, dateRequest, lam]);

    const nps = useSelector(
        ({api})=> api.gcareNpsReport?
            getNpsValues(api.gcareNpsReport):null);

    useEffect(()=>{
        setLam(lams.length===0?'':lams[0].id);
    }, [lams]);

    const detractorNps = useMemo(()=>nps?nps.detractor||0:0, [nps]);
    const promotorNps = useMemo(()=>nps?nps.promotor||0:0, [nps]);
    const passiveNps = useMemo(()=>nps?nps.passive||0:0, [nps]);
    const npsTotal = useMemo(()=>detractorNps+promotorNps+passiveNps, [detractorNps, promotorNps, passiveNps]);

    const gaugeOption = useMemo(()=>{
        if(!detractorNps && !passiveNps && !promotorNps){
            return null;
        }

        return getGaugeOption(detractorNps, passiveNps, promotorNps);
    } , [detractorNps, promotorNps, passiveNps]);
    const gaugeKey = useMemo(Math.random, [detractorNps, promotorNps, passiveNps]);

    return (
        <div className="LoungeAccessMethodCard">
            <DateRangeFilter {...dateFilterProps}/>
            <label>Métodos de acceso:</label>
            <select value={lam} onChange={e=>setLam(e.target.value)} className='form-control form-selectors'>
                {lams.map(l=><option key={l.id} value={l.id}>{l.name}</option>)}
            </select>
            <label>Rubros:</label>
            <select value={gcareType} onChange={e=>setGcareType(e.target.value)} className='form-control form-selectors'>
                {gcareTypes.map(([name, key])=>
                                <option value={key} key={key}>{name}</option>)}
            </select>
        <div className='container-fluid'>
            <ExcellenceReport loungeAccessMethod={lam} gcareType={gcareType} dateRequest={dateRequest}/>
            <div className='row'>
                <div className='col-md-5'>
                    <PurposeStats loungeAccessMethod={lam} dateRequest={dateRequest}/>
                </div>
                <div className='col-md-7'>
                    <ManWomanStats key={manWomanKey} generationReport={generationReport}/>
                </div>
            </div>
            <AirlineStats loungeAccessMethod={lam} dateRequest={dateRequest}/>
            <GenerationStats generationReport={generationReport}/>
            <DestinationStats loungeAccessMethod={lam} dateRequest={dateRequest}/>
            <div className='row'>
                <div className='col-md-12'>
                    <h3 className='text-center'>NPS: {mPercentageString(promotorNps/npsTotal)} - {mPercentageString(detractorNps/npsTotal)} = {mPercentageString(promotorNps/npsTotal-detractorNps/npsTotal)}</h3>
                    <br/>
                </div>
            </div>
            {gaugeOption&&<ReactEcharts key={gaugeKey} option={gaugeOption}/>}
        </div>
        </div>
    );
};
export default loungeAccessMethodGcareStats;
