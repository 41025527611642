import React from 'react';
import {Modal, Table} from 'react-bootstrap';
import moment from 'moment';
import Button from "../../../elements/CustomButton/CustomButton";
import ReservationStatusDropdown from './ReservationStatusDropdown';
import {sourceStrings} from "../../../utils/modelUtils/davinci/reservationUtils";


const ReservationDetailModal = ({reservation, onHide, onEdit}) => {

    return (
        <Modal
            show={true}
            onHide={onHide}
        >
            <Modal.Header>
                <Modal.Title>Detalle de reservación</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Table>
                    <tbody>
                    <tr>
                        <td>Nombre</td>
                        <td>{reservation.name ||  (reservation.guest?reservation.guest.name:null)}</td>
                    </tr>
                    <tr>
                        <td>Apellidos</td>
                        <td>{reservation.lastNames || (reservation.guest?reservation.guest.patLastName:null)}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{reservation.email || (reservation.guest?reservation.guest.email:null)}</td>
                    </tr>
                    <tr>
                        <td>Teléfono</td>
                        <td>{reservation.phone || (reservation.guest?reservation.guest.phone:null)}</td>
                    </tr>
                    <tr>
                        <td>Tipo</td>
                        <td>{reservation.accessMethod}</td>
                    </tr>
                    <tr>
                        <td>Fecha</td>
                        <td>{moment(reservation.reservationDate).format('DD/MM/YYYY HH:mm')}</td>
                    </tr>
                    <tr>
                        <td>Personas</td>
                        <td>{reservation.personsNumber}</td>
                    </tr>
                    <tr>
                        <td>Terminal</td>
                        <td>{reservation.terminal}</td>
                    </tr>
                    <tr>
                        <td>Aerolínea</td>
                        <td>{reservation.flight?reservation.flight.airline.name:reservation.airline}</td>
                    </tr>
                    <tr>
                        <td>Vuelo</td>
                        <td>{reservation.flight?reservation.flight.number:reservation.flightNumber}</td>
                    </tr>
                    <tr>
                        <td>Estado</td>
                        <td>
                            <ReservationStatusDropdown reservation={reservation}/>
                        </td>
                    </tr>
                    <tr>
                        <td>Método de reserva</td>
                        <td>{ sourceStrings( reservation.source )}</td>
                    </tr>
                    <tr>
                        <td>Pago en línea</td>
                        <td>{reservation.onlinePaymentMethod?reservation.onlinePaymentMethod:'Sin pago'} </td>
                    </tr>
                    <tr>
                        <td>Servicios agendados</td>
                        <td>
                            {reservation.serviceRequests && reservation.serviceRequests.length>0 ?
                                <Table>
                                    <thead>
                                    <tr>
                                        <th>Tipo de servicio</th>
                                        <th>Fecha</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {reservation.serviceRequests.map((sr)=>(
                                      <tr>
                                          <td>{sr.serviceType.name}</td>
                                          <td>{moment(sr.reservationDate).format('DD/MM/YYYY HH:mm')}</td>
                                      </tr>
                                    ))}
                                    </tbody>
                                </Table> : 'Sin servicios agendados'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>Notas adicionales</td>
                        <td>
                            {reservation.notes ? <pre>{reservation.notes}</pre> : null}
                        </td>
                    </tr>
                    </tbody>
                </Table>

            </Modal.Body>

            <Modal.Footer>
                <Button bsStyle='primary' className='pull-left' onClick={onEdit}>Editar</Button>
                <Button bsStyle='danger' onClick={onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );

};

export default ReservationDetailModal;
