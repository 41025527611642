import React, {useContext} from "react";
import classNames from "classnames";
import {DateRangePicker} from "react-dates";
import useBoolean from "../../../../hooks/useBoolean";
import useDateRangePickerState from "../../../../hooks/useDateRangePickerState";
import {querySerialize} from "../../../../api/Api";
import {TideApiContext} from "../../../../api/tideApiConfig";

const WarehouseMovementsReport = () => {
    const [isOpen,,,toggleOpen] = useBoolean();
    const {props, dateRange} = useDateRangePickerState();
    const api = useContext(TideApiContext);

    let link = "#";
    window.api = api;
    if(dateRange.startDate && dateRange.endDate) {
        const query = querySerialize({
            from: dateRange.startDate.format('YYYY-MM-DD'),
            to:dateRange.endDate.format('YYYY-MM-DD'),
            token: api.token
        });
        link = `${api.host}/api/stock_movements/report?${query}`;
    }

    return (
        <div className={classNames("StockMovementsResume report-block", isOpen && 'open')}>
            <p className='title' onClick={toggleOpen}>Movimientos de Almacén<i className='fa fa-caret-down caret-icon'/></p>
            {isOpen &&
            <div className='body'>
                <hr/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <p className='filter-label'>Rango de fechas:</p>
                            <DateRangePicker {...props}/>
                        </div>
                        <div className='col-md-6'>
                            <p className='filter-label'>Descripción:</p>
                            <p className='description'>Entradas y salidas generadas por los almacenes.</p>
                        </div>
                    </div>
                </div>
                <br/>
                <a href={link} target='_blank' rel='noopener noreferrer'>
                    <button className='btn btn-info'>Descargar</button>
                </a>

            </div>}
        </div>
    )
};

export default WarehouseMovementsReport;