import './DestinationTypesManagement.scss';
import React, {useContext, useEffect, useState} from 'react';
import {isEmpty} from 'lodash';
import { ApiContext } from 'api/Api';
import {useSelector} from 'react-redux';
import ClassicModal from 'components/Modals/ClassicModal';
import DestinationTypeForm from './components/DestinationTypeForm/DestinationTypeForm';
import CustomButton from 'elements/CustomButton/CustomButton';
import {ReactComponent as Edit} from 'assets/icon/edit.svg';
import {ReactComponent as Delete} from 'assets/icon/trash.svg';

const loadingId = "@DestinationTypesManagement.Loader";
const customProp = "@DestinationTypesManagement.Store";

//Modal Actions
const MANAGE_DESTINATION_TYPE = "MANAGE_DESTINATION_TYPE";

const DestinationTypesManagement = () => {
    //Initializers
    const api = useContext(ApiContext);

    //Init State
    const initModal = {action: null, payload: null};

    //State
    const [modal, setModal] = useState(initModal);

    //Retrieving destination types
    useEffect(() => {
        const payload = {customProp, loadingId};
        api.destinationTypes.get(payload);
    }, [api]);

    //Redux Store
    const destinationTypes = useSelector(({api}) => api?.[customProp] || []);
    const loading = useSelector(({loadingIds}) => !!loadingIds[loadingId]);

    //Events
    const onCloseModal = () => setModal(initModal);
    const onManageDestinationType = (payload = null) => setModal({action: MANAGE_DESTINATION_TYPE, payload});
    const onDestinationTypeDelete = async id => {
        try {
            await api.destinationTypes.delete({id, customProp});
        } catch (error) {
            console.error(error);
        }
    }
    const onSaveDestinationType = async payload => {
        const {id, name} = payload;
        try {
            let result;
            if(id)
                result = await api.destinationTypes.update({id, params: {name}, customProp});
            else
                result = await api.destinationTypes.create({params: {name}, customProp});

            if(result) onCloseModal();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <article className="DestinationTypesManagement">
            <header>
                {(!isEmpty(destinationTypes)) &&
                    <CustomButton
                        fill
                        type="button"
                        onClick={onManageDestinationType}
                    >
                        Crear tipo de destino
                    </CustomButton>
                }
            </header>

            {(isEmpty(destinationTypes) && !loading) &&
                <div className="no-data-advice">
                    <p>Aún no has registrado un tipo de destino. Intenta crear uno.</p>
                    <CustomButton
                        fill
                        type="button"
                        onClick={onManageDestinationType}
                    >
                        Crear tipo de destino
                    </CustomButton>
                </div>
            }
            {
                (!isEmpty(destinationTypes)) &&
                <ul>
                    <li id="list-headers">
                        <h6>Nombre</h6>
                        <h6>Acciones</h6>
                    </li>
                    {
                        destinationTypes.map( ({id, name}) =>
                            <li key={id}>
                                <p>{name}</p>
                                <div className="actions">
                                    <button type="button" onClick={() => onManageDestinationType({id, name})}>
                                        <Edit />
                                    </button>
                                    <button type="button" onClick={() => onDestinationTypeDelete(id)}>
                                        <Delete />
                                    </button>
                                </div>
                            </li>
                        )
                    }
                </ul>
            }
            {
                (modal.action === MANAGE_DESTINATION_TYPE) &&
                <ClassicModal
                    title={`${(modal.payload?.id) ? 'Editar' : 'Crear'} tipo de destino.`}
                    onHide={onCloseModal}
                    showConfirm={false}
                    showCancel={false}
                >
                    <DestinationTypeForm
                        destinationType={(modal.payload?.id) ? modal.payload : null}
                        onSubmit={onSaveDestinationType}
                    />
                </ClassicModal>
            }
        </article>
    );
};
export default DestinationTypesManagement;