import React, {useContext, useEffect, useState,} from 'react';
import {Col, Row} from "react-bootstrap";
import {ApiContext} from "../../../api/Api";
import {useSelector} from "react-redux";
import Switch from "react-bootstrap-switch";
import _ from 'lodash';
import PushNotificationsButton from "./PushNotificationsButton";

const loadingId = '@NotificationConfiguration.me.put';
const EMAIL_NOTIFICATION = 'email';
const PUSH_NOTIFICATION = 'push';

const NotificationsConfiguration = () => {

    const api = useContext(ApiContext);
    const [systemsNotifications, setSystemsNotifications] = useState([]);

    const notificationTopics = useSelector((state) => state.api.notificationTopics) || [];
    const me = useSelector((state) => state.api.me) || [];

    const loading = useSelector(({loadingIds}) => loadingIds[loadingId]);


    useEffect(() => {
        api.notificationTopics.getUserCanReceive({});
    }, [api]);


    useEffect(() => {
        setSystemsNotifications(_.toArray(_.groupBy(notificationTopics, 'systemSection')));
    }, [notificationTopics]);


    const toggleNotification  = (notificationTopic, notificationType) => {
        const userNotificationTopics = [];

        let userNotificationTopicExists = false;
        _.each(me.userNotificationTopics, (userNotificationTopic) => {
            const newUserNotificationTopic = {...userNotificationTopic};
            if (newUserNotificationTopic.notificationTopic.id === notificationTopic.id) {
                if(notificationType === EMAIL_NOTIFICATION) {
                    newUserNotificationTopic.isMailEnabled = !newUserNotificationTopic.isMailEnabled;
                }
                else{
                    newUserNotificationTopic.isPushDisabled = !newUserNotificationTopic.isPushDisabled;
                }

                userNotificationTopicExists = true;
            }
            newUserNotificationTopic.notificationTopic = newUserNotificationTopic.notificationTopic.id;
            userNotificationTopics.push(newUserNotificationTopic);
        });

        if(!userNotificationTopicExists){
            userNotificationTopics.push({
                isMailEnabled: notificationType===EMAIL_NOTIFICATION,
                isPushDisabled: notificationType===PUSH_NOTIFICATION,
                notificationTopic: notificationTopic.id
            });
        }

        api.me.edit({userNotificationTopics, loadingId});


    };

    const isPushEnabled = (notificationTopic) => {
        if (me) {
            return !_.find(me.userNotificationTopics, (userNotificationTopic) => (
                (userNotificationTopic.notificationTopic.id === notificationTopic.id) && userNotificationTopic.isPushDisabled)
            )
        }
    };

    const isMailEnabled = (notificationTopic) => {
        if (me) {
            return !! _.find(me.userNotificationTopics, (userNotificationTopic) => (
                (userNotificationTopic.notificationTopic.id === notificationTopic.id) && userNotificationTopic.isMailEnabled)
            );
        }
    };


    return (
        <div className='container-fluid NotificationsConfiguration'>
            <Row>
                <Col md={8} mdPush={2} className='notifications-list-container'>

                    <PushNotificationsButton/>
                    <br/>
                    {me &&
                    <div className="alert alert-primary">Las notificaciones por correo electrónico se envían a {me.email}</div>}


                    {systemsNotifications.map((notificationTopics, index) =>
                        <div key={index} className={'system-notifications ' + (loading ? 'loading' : '')}>
                            <h3>{notificationTopics[0].systemSection.toUpperCase()}</h3>
                            <hr/>
                            <ul className="list">
                                {notificationTopics.map((notificationTopic) =>
                                    <li
                                        key={notificationTopic.id}
                                        className='list-item'
                                    >
                                        <div className='description'>{notificationTopic.description}</div>
                                        <div onClick={() => toggleNotification(notificationTopic, PUSH_NOTIFICATION)} className='push-notification-toggle'>
                                            <Switch
                                                onText={<i className='fa fa-bell'/>}
                                                offText={<i className='fa fa-bell'/>}
                                                value={isPushEnabled(notificationTopic)}/>
                                        </div>
                                        <div onClick={() => toggleNotification(notificationTopic, EMAIL_NOTIFICATION)} className='mail-notification-toggle'>
                                            <Switch
                                                onText={<i className='fa fa-envelope'/>}
                                                offText={<i className='fa fa-envelope'/>}
                                                value={isMailEnabled(notificationTopic)}/>
                                        </div>
                                        <div className='clearfix'></div>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default NotificationsConfiguration;

//