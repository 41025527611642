import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import Button from "../../../../elements/CustomButton/CustomButton";
import PropTypes from 'prop-types';

export default class AddBonusModal extends Component {

    static contextTypes = {notifier:PropTypes.object};

    constructor(props){
        super(props);

        this.state = {
            amount:"",
            comment:"",
            type: this.props.bonuses[0].code
        };

        this.applyBonus = this.applyBonus.bind(this);
        this.typeChange = this.typeChange.bind(this);
        this.amountChange = this.amountChange.bind(this);
        this.amountBlur = this.amountBlur.bind(this);
        this.commentChange = this.commentChange.bind(this);
    }

    amountChange(e){
        this.setState({amount:e.target.value});
    }

    amountBlur(e){
        let am = Number(e.target.value);
        if( isNaN(am) )
            am=0;
        this.setState({amount:am});
    }

    commentChange(e){
        this.setState({comment:e.target.value});
    }

    typeChange(e){
        this.setState({type:e.target.value});
    }

    applyBonus(){

        if( !this.state.amount || this.state.amount <= 0 ) {
            this.context.notifier.error("Es necesario seleccionar una cantidad mayor a cero.");
            return;
        }

        if( !this.state.comment ){
            this.context.notifier.error("Es necesario agregar un comentario");
            return;
        }

        this.props.applyBonus( this.state.amount * 100, this.state.comment, this.state.type );
    }

    render() {

        return (
            <div>

                <Modal
                    show={true}
                    onHide={this.props.onHide}
                >
                    <Modal.Header closeButton >
                        <Modal.Title>Añadir bono</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Se añadirá un bono a {getFullName( this.props.employee )}.</p>
                        <p>Selecciona el tipo de bono:</p>
                        <select className="form-control" onChange={this.typeChange}>
                            {this.props.bonuses.map((bonus)=>{
                                return (<option value={bonus.code} key={bonus.id}>{bonus.name}</option>);
                            })}
                        </select>
                        <br/>
                        <p>Indica el monto del bono:</p>

                        <div className="input-group">
                            <span className="input-group-addon">$</span>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Monto"
                                value={this.state.amount}
                                onChange={this.amountChange}
                                onBlur={this.amountBlur}
                            />
                        </div>
                        <br/>
                        <p>Agrega un comentario al bono:</p>
                        <textarea
                            className="form-control"
                            value={this.state.comment}
                            onChange={this.commentChange} />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="success" onClick={this.applyBonus} >Abonar</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}

const getFullName = (employee)=>(employee.name+" "+employee.patLastName+" "+employee.matLastName);