import React, {useContext,useState, useEffect} from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import CustomButton from "../../elements/CustomButton/CustomButton";
import {UserDetailApiProp, UserDetailLoadingId} from "../alma/Employees/core/UserDetail";
import {employeeFullName} from "../../utils/modelUtils/alma/employeeUtils";
import useTideTable from "../../hooks/table/useTideTable";
import {ApiContext} from "../../api/Api";


const LoginRecordsList = () => {

    const api=useContext(ApiContext);
    const [intervalId, setIntervalId] = useState(0);

    const handleUserDetail = ( user )=>
        api.users.get(0,1,UserDetailLoadingId,{employee:user.employee.id},UserDetailApiProp );


    const tableStructure = [
        {
            Header: 'Username',
            accessor: 'user.username',
        },
        {
            Header: 'Nombre',
            accessor: ( loginRecord )=>loginRecord && loginRecord.user && loginRecord.user.employee &&
                <span onClick={()=>handleUserDetail(loginRecord.user)} className='link' >{employeeFullName(loginRecord.user.employee)}</span>,
            id: 'search'
        },
        {
            Header: 'Fecha',
            accessor: (loginRecord)=>moment(loginRecord.date).format('HH:mm DD/MM/YYYY'),
            id: 'date'
        },
        {
            Header: 'Versión',
            accessor: (loginRecord)=>loginRecord && loginRecord.clientData && loginRecord.clientData.appVersion ,
            id: 'clientData.appVersion'
        },
        {
            Header: 'Cliente',
            accessor: (loginRecord)=>JSON.stringify(loginRecord && loginRecord.clientData && loginRecord.clientData.device ),
            id: 'clientData.device'
        },
        {
            Header: 'Pantalla',
            accessor: (loginRecord)=>JSON.stringify(loginRecord && loginRecord.clientData && loginRecord.clientData.screen ),
            id: 'clientData.screen'
        },
    ];

    const loginTable=useTideTable({
        entity:'loginRecords',
        columns:tableStructure
    });

    const handleAutoRefresh = ()=>{
        setIntervalId( setInterval(()=>loginTable.reload, 6000) );
    };

    useEffect( ()=>()=>clearInterval(intervalId), [intervalId] );


    return (
        <div className="container-fluid main-container">

            { !intervalId?
            <CustomButton bsStyle={'primary'} onClick={handleAutoRefresh}>Auto-cargar</CustomButton>
                :null}
            <div className='clearfix'/>

            <ReactTable {...loginTable.tableProps} />
            <p className="total-count">{loginTable.itemsFoundString}</p>

        </div>
    );

};

export default LoginRecordsList;

