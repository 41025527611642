import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {UserDetailApiProp, UserDetailLoadingId} from "../core/UserDetail";
import Card from "../../../../components/Card/Card";
import Button from "../../../../elements/CustomButton/CustomButton";
import {Modal} from 'react-bootstrap';
import moment from "moment";
import moneyFormatter from "../../../../utils/moneyFormatter";
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";

class EmployeeWorkTools extends Component {

    static contextTypes = {
        api: PropTypes.object,
        notifier:PropTypes.object
    };

    creatingLoadingId = "@Class.EmployeeWorkTools.create";
    getLoadingId = "@Class.EmployeeWorkTools.get";

    constructor(props, context){
        super(props);

        if( props.match.params.id && Number(props.match.params.id) !== Number(props.me.employee.id) ) {
            this.employeeId = props.match.params.id;
            this.isMe = false;
            context.api.employees.getSingle(this.employeeId);
        }
        else {
            this.employeeId = props.me.employee.id;
            this.isMe = true;
        }

        context.api.employeeWorkTools.get(0, 200, this.getLoadingId, {employee: this.employeeId});

        if( canEditWorkTools(this.props.me) ){
            context.api.workTools.get();
        }

        this.showUserDetail = this.showUserDetail.bind(this);

        this.state = {form:{}};
    }

    showUserDetail( ){
        this.context.api.users.get(0,1,UserDetailLoadingId,{employee:this.props.singleEmployee.id},UserDetailApiProp );
    }

    getFormValue = ( name )=>{
        if( this.state.form[name] )
            return this.state.form[name];
        return '';
    };

    changeFormValue = ( e )=>{
        this.setState( { form:{...this.state.form, [e.target.name]:e.target.value} } );
    };

    assignWorkTool = ()=>{

        const workTool = this.state.form.workTool;
        if( !workTool ){
            this.context.notifier.error('Debes seleccionar el equipo de trabajo');
            return;
        }

        if(this.state.editing)
            this.context.api.employeeWorkTools.edit( this.state.editing.id, workTool, this.getFormValue('comments') );
        else
            this.context.api.employeeWorkTools.create( workTool, this.employeeId, this.getFormValue('comments'), this.creatingLoadingId );

        this.setState({adding:false, editing:false, form:{}});
    };

    removeWorkTool = ()=>{

        this.context.api.employeeWorkTools.delete( this.state.deleting.id );
        this.setState( {deleting:false} );
    };

    editEmployeeWorkTool = ( ewt )=>{
        this.setState( {editing: ewt, form: {workTool: ewt.workTool.id, comments: ewt.comments } } );
    };

    returnEmployeeWorkTool = ( ewt )=>{
        this.context.api.employeeWorkTools.edit( ewt.id, undefined, undefined, moment().format() );
    };

    reassignEmployeeWorkTool = ( ewt )=>{
        this.context.api.employeeWorkTools.edit( ewt.id, undefined, undefined, null );
    };

    render() {

        const canEdit = canEditWorkTools(this.props.me);

        return (
            <div className="EmployeeWorkTools">
                <div className='container-fluid main-container'>
                    {!this.isMe && this.props.singleEmployee?
                        <h4 className='name-title'>Equipo asignado a <span className="span-but" onClick={this.showUserDetail}>{employeeFullName(this.props.singleEmployee)}</span></h4>:null
                    }
                    <Card
                        title=" "
                        content={
                            <div>

                                {!this.props.employeeWorkTools.length && !this.props.loadingIds[this.getLoadingId] ?
                                    <p>No se ha asigno equipo de trabajo.</p>
                                    :null}

                                {this.props.employeeWorkTools.map( (ewt)=>
                                    <div className='ewt-item' key={ewt.id}>
                                        <h4>{ewt.workTool.name}</h4>
                                        <p>{ewt.comments}</p>
                                        {ewt.workTool.employeePrice?
                                        <p>Costo de reposición: {moneyFormatter(ewt.workTool.employeePrice, 1)}</p>:null}

                                        {ewt.returnDate?
                                            <div>
                                                <p>Devuelto el {moment(ewt.returnDate).format("dddd, D [de] MMMM [del] YYYY")}</p>
                                                <Button bsStyle='success' onClick={()=>this.reassignEmployeeWorkTool(ewt)}>Volver a asignar</Button>
                                            </div>
                                            :null}

                                        { !ewt.returnDate && canEdit ?
                                            <div>
                                                <Button bsStyle="danger" onClick={()=>this.setState({deleting:ewt})}>Borrar</Button>
                                                <Button bsStyle='primary' onClick={()=>this.editEmployeeWorkTool(ewt)}>Editar</Button>
                                                <Button bsStyle='success' onClick={()=>this.returnEmployeeWorkTool(ewt)}>Recibir de regreso</Button>
                                            </div>
                                            :null
                                        }
                                    </div>
                                )}

                                {canEdit?
                                    <Button
                                        bsStyle='success'
                                        className='assign-btn'
                                        onClick={()=>this.setState({adding:true})}
                                        disabled={this.props.loadingIds[this.creatingLoadingId] }
                                    >
                                        Asignar equipo de trabajo
                                    </Button>
                                    :null}
                            </div>
                        }
                    />

                </div>


                {/* Modals */}

                {this.state.deleting?
                    <Modal show={true} onHide={()=>this.setState({deleting:false})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Borrar equipo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>¿Estás seguro que deseas borrar el equipo de trabajo?</p>
                            <p>Si el colaborador regresó el equipo, debes usar la opción "Recibir de regreso".</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button bsStyle='danger' className='pull-left' onClick={()=>this.setState({deleting:false})}>Cancelar</Button>
                            <Button bsStyle='danger' onClick={this.removeWorkTool}>Sí, borrar</Button>
                        </Modal.Footer>
                    </Modal>
                    :null}

                {this.state.adding || this.state.editing?
                    <Modal show={true} onHide={()=>this.setState({adding:false, editing:false, form:{}})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Asignar equipo de trabajo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Selecciona el equipo:</p>
                            <select className='form-control' name='workTool' onChange={this.changeFormValue} value={this.state.form.workTool} >
                                <option>Selecciona el equipo</option>
                                {this.props.workTools.map((wt)=>
                                    <option key={wt.id} value={wt.id} >{wt.name}</option>
                                )}
                            </select>
                            <br/><br/>
                            <p>Ingresa la descripción o algún comentario (por ejemplo, número de serie):</p>
                            <input className='form-control' name='comments' onChange={this.changeFormValue} value={this.getFormValue('comments')}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button bsStyle='danger' className='pull-left' onClick={()=>this.setState({adding:false, editing:false, form:{}})}>Cancelar</Button>
                            <Button bsStyle='success' onClick={ this.assignWorkTool }>Asignar</Button>
                        </Modal.Footer>
                    </Modal>
                    :null}



            </div>
        );
    }
}

function mapStateToProps( {api:{me = {}, singleEmployee, employeeWorkTools=[], workTools=[]  }, loadingIds=[]} ) {

    return {me, singleEmployee, employeeWorkTools, workTools, loadingIds}
}
export default connect(mapStateToProps)(EmployeeWorkTools);

const canEditWorkTools = ( user )=>{

    if( !user.role )
        return false;

    const roles = [ 'NOMINAS', 'RECURSOS_HUMANOS' ];

    return roles.indexOf( user.role.name ) !== -1;
};
