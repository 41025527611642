import React, {Component} from 'react';
import {Modal, Table} from 'react-bootstrap';
import Button from "../../../../elements/CustomButton/CustomButton";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Datetime from "react-datetime";
import moment from 'moment';
import Select from 'react-select';
import _ from 'lodash';
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";


class PersonnelRequisitionForm extends Component {

    static contextTypes = {api: PropTypes.object, notifier: PropTypes.object};

    constructor( props ){
        super(props);

        this.state = { form:{} };

    }

    getFormValue = ( name )=>{
        return this.state.form[name] || '' ;
    };

    setFormValue = ( e )=>{
        this.setState( {form: {...this.state.form, [e.target.name]:e.target.value } } );
    };

    create = ()=>{
        const required = [ 'layoffRequestDate', 'requestComment'];

        if( !this.state.employee ){
            this.context.notifier.error('No has llenado todos los campos');
            return;
        }

        for( let i=0; i<required.length; i++){
            if( !this.state.form[required[i]] ){
                this.context.notifier.error('No has llenado todos los campos');
                return;
            }
        }

        const req = { ...this.state.form };
        req.employee = this.state.employee.value;
        req.layoffRequestDate = req.layoffRequestDate.format('YYYY-MM-DD');

        this.props.onCreate( req );
    };

    loadEmployees = _.debounce(

        (input)=>{
            if(input === "" ) return;
            this.context.api.employees.get(
                0,
                10,
                this.loadingId,
                {"search":input, "isHired":"true", "sGroups": ['user_search']});
        }

        , 1000, {'leading': true,'trailing': true});

    render() {

        return (
            <Modal
                show={true}
                onHide={this.props.onHide}
            >
                <Modal.Header closeButton >
                    <Modal.Title>Detalle de solicitud</Modal.Title>
                </Modal.Header>

                <Modal.Body className='PersonnelRequisitionsForm'>

                    <Table>
                        <tbody>
                        <tr>
                            <td >Solicitante:</td>
                            <td >{employeeFullName(this.props.me.employee)}</td>
                        </tr>
                        <tr>
                            <td >Fecha de solicitud:</td>
                            <td >{moment().format('DD/MM/YYYY')}</td>
                        </tr>
                        <tr>
                            <td >Persona a dar de baja:</td>
                            <td >
                                <Select
                                    name="employee"
                                    options={this.props.employees.map(
                                        (employee)=>({value:employee.id, label:employee.name+" "+employee.patLastName+" "+employee.matLastName})
                                    )}
                                    onInputChange={(input)=>this.loadEmployees(input)}
                                    onChange={(val)=>this.setState({employee:val})}
                                    value={this.state.employee}
                                    isLoading={!!this.props.loadingIds[this.loadingId]}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td >Fecha de terminación:</td>
                            <td >
                                <Datetime
                                    timeFormat={false}
                                    inputProps={{placeholder: "Seleccionar fecha"}}
                                    closeOnSelect={true}
                                    value={this.getFormValue('layoffRequestDate')}
                                    onChange={(date)=>this.setFormValue({target:{value:date, name:'layoffRequestDate'}})}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </Table>

                    <p>Motivo de baja:</p>
                    <textarea name='requestComment' className='form-control' value={this.getFormValue('requestComment')} onChange={this.setFormValue}/>



                </Modal.Body>

                <Modal.Footer>

                    <Button bsStyle='danger' className='pull-left' onClick={this.props.onHide} >Cerrar</Button>

                    <Button bsStyle='success' onClick={ this.create }>Crear</Button>

                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = ( {api:{ me, employees=[] }, loadingIds = {}} )=>{
    return {me, employees, loadingIds };
};

export default connect( mapStateToProps )(PersonnelRequisitionForm);
