import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {computeAllQuoteValues} from "./purchaseRequisitionUtils";
import moment from "moment";
import ReactTableDataRangeFilter from "../../../components/Helper/ReactTableDateRangeFilter";
import moneyFormatter from "../../moneyFormatter";

/**
 * The value parameter is the new total that should show in the quote, opposed to the actual value saved
 * in the server, which is just the difference from the old value to the new value.
 *
 * type could be either 'unitPrice' or 'quantity'
 *
 * @param adjustment {{value, purchaseProduct, type}}
 * @param purchaseOrder
 */
export const addAdjustmentToPurchaseOrderAndPrepareForServer=( adjustment, purchaseOrder)=>{
    if(!adjustment || !purchaseOrder)
        throw new Error('Missing parameters to add an adjustment to the purchase order');

    const po={...purchaseOrder};
    po.purchaseProducts = po.purchaseProducts.map(pp=>{
        if(pp.id===adjustment.purchaseProduct.id)
            return addAdjustmentToPurchaseProduct(adjustment, pp);
        return pp;
    });

    computePurchaseOrderValues(po);
    return preparePurchaseOrderForServer(po, adjustment.purchaseProduct);
};

const addAdjustmentToPurchaseProduct=(adjustment, purchaseProduct)=>{

    const newPP={...purchaseProduct};
    const adjustmentEntity=newPP.purchaseProductAdjustment||{};

    const originalUnitPrice=getOriginalUnitPrice(purchaseProduct);
    const originalQuantity=getOriginalQuantity(purchaseProduct);

    if(adjustment.type==='unitPrice')
        adjustmentEntity.unitPrice=adjustment.value-originalUnitPrice;
    else if(adjustment.type==='quantity')
        adjustmentEntity.quantity=adjustment.value-originalQuantity;

    newPP.purchaseProductAdjustment=adjustmentEntity;

    if(adjustment.type==='quantity')
        newPP.quantity =adjustment.value;

    newPP.selectedPurchaseProductQuote = {
        id:purchaseProduct.selectedPurchaseProductQuote.id,
        unitPrice: adjustment.type==='unitPrice'? adjustment.value : purchaseProduct.selectedPurchaseProductQuote.unitPrice,
        unitDiscount: purchaseProduct.selectedPurchaseProductQuote.unitDiscount,
        purchaseProductTaxes: purchaseProduct.selectedPurchaseProductQuote.purchaseProductTaxes
    };

    computeAllQuoteValues(newPP, newPP.selectedPurchaseProductQuote, true);
    return newPP;
};

const getOriginalUnitPrice=(purchaseProduct)=>{
    if(purchaseProduct.purchaseProductAdjustment && purchaseProduct.purchaseProductAdjustment.unitPrice)
        return purchaseProduct.selectedPurchaseProductQuote.unitPrice - purchaseProduct.purchaseProductAdjustment.unitPrice;
    else
        return purchaseProduct.selectedPurchaseProductQuote.unitPrice;
};

const getOriginalQuantity=(purchaseProduct)=>{
    if(purchaseProduct.purchaseProductAdjustment && purchaseProduct.purchaseProductAdjustment.quantity)
        return purchaseProduct.quantity - purchaseProduct.purchaseProductAdjustment.quantity;
    else
        return purchaseProduct.quantity;
};

export const computePurchaseOrderValues=(purchaseOrder)=>{
    purchaseOrder.total = purchaseOrder.purchaseProducts.reduce( (acc, pp)=>acc+Number(pp.selectedPurchaseProductQuote.total),0);
    purchaseOrder.subtotal = purchaseOrder.purchaseProducts.reduce( (acc, pp)=>acc+Number(pp.selectedPurchaseProductQuote.subtotal),0);
    purchaseOrder.taxes = purchaseOrder.purchaseProducts.reduce( (acc, pp)=>acc+Number(pp.selectedPurchaseProductQuote.taxesTotal),0);
};

export const preparePurchaseOrderForServer=(purchaseOrder,  modifiedPurchaseProduct)=>{

    return {
        id: purchaseOrder.id,
        purchaseProducts: purchaseOrder.purchaseProducts.map(pp=>{
            if(pp.id!== modifiedPurchaseProduct.id)
                return pp.id;
            return {
                id: pp.id,
                quantity: pp.quantity,
                purchaseProductAdjustment:{
                    id: pp.purchaseProductAdjustment.id?pp.purchaseProductAdjustment.id:null,
                    quantity: pp.purchaseProductAdjustment.quantity?pp.purchaseProductAdjustment.quantity+'':null,
                    unitPrice: pp.purchaseProductAdjustment.unitPrice?pp.purchaseProductAdjustment.unitPrice+'':null,
                },
                selectedPurchaseProductQuote:{
                    ...pp.selectedPurchaseProductQuote,
                    discountTotal: pp.selectedPurchaseProductQuote.discountTotal+'',
                    subtotal: pp.selectedPurchaseProductQuote.subtotal+'',
                    total: pp.selectedPurchaseProductQuote.total+'',
                    taxesTotal: pp.selectedPurchaseProductQuote.taxesTotal+'',
                    subtotalWithDiscount: pp.selectedPurchaseProductQuote.subtotalWithDiscount+'',
                    purchaseProductTaxes: pp.selectedPurchaseProductQuote.purchaseProductTaxes.map( ppTax=>({
                        id:ppTax.id,
                        total: ppTax.total+'',
                        tax:ppTax.tax.id
                    }))
                }
            }
        }),
        subtotal: purchaseOrder.subtotal+'',
        taxes: purchaseOrder.taxes+'',
        total: purchaseOrder.total+'',
    };
};

export const paymentMethods=[
    'bank_transfer',
    'fixed_fund',
    'check',
    'online',
    'prepaid',
];

export const paymentConditions=[
    'full_payment',
    'credit',
    'initial_and_settlement',
];

export const poStrings = {
    //status
    requested: 'Solicitada',
    paying: 'En proceso de pago',
    payed: 'Pagada',
    canceled: 'Cancelada',
    in_clarification: 'En aclaración',

    //paymentMethod
    bank_transfer: 'Transferencia bancaria',
    fixed_fund: 'Fondo fijo',
    check: 'Cheque',
    online: 'Pago electrónico',
    prepaid: 'Fondo revolvente',

    //paymentWay
    full_payment: 'De contado',
    credit: 'Crédito',
    initial_and_settlement: 'Anticipo y finiquito',

    '': 'Sin asignar'
};

export const poTrans = (string)=>{

    return poStrings[string]||string;
};

export const transSearch = (search)=>{

    for(let key in poStrings){
        const translation = poTrans(poStrings[key]).toLocaleLowerCase();
        if(translation.search(search)!==-1)
            return key;
    }
    return search;
};

const requiredCellContent = po=>{
    if(!po.stockRequired){
	return 'NA';
    }
    for(const {ordered, stockRequest} of po.stockRequired){
	if(ordered>stockRequest){ // ordering more than required? what do you think money grows on trees?
	    return 'Exceso en orden de compra';
	}
    }
    return 'OK';
};

const receivedCellContent = po=>{
    if(!po.stockReceived){
	return 'OK';
    }
    for(const {received, requested} of po.stockReceived){
	if(requested>received){
	    return 'Productos faltantes';
	}
    }
    return 'OK';
};


export const POListTableStructure = [
    {
        Header: 'No. de orden',
        accessor: 'folio'
    },
    {
        Header: 'No. de solicitud',
        accessor: 'purchaseRequisition.folio'
    },
    {
        Header: 'Solicitante',
        accessor: 'purchaseRequisition.requestedBy.fullName',
        id: 'search'
    },
    {
        Header: 'Área',
        accessor: 'purchaseRequisition.jazzArea.name'
    },
    {
        Header: 'Proveedor',
        accessor: 'provider.name'
    },
    {
        Header: 'Fecha de creación',
        accessor: (po) => moment(po.createdDate).format('DD/MM/YYYY HH:mm'),
        id: 'createdDate',
        Filter: ReactTableDataRangeFilter
    },
    {
        Header: 'Fecha estimada de entrega',
        accessor: po=>po.expectedDate?moment(po.expectedDate).format('DD/MM/YYYY'):'Sin fecha estimada',
        id: 'expectedDate'
    },
    {
        Header: 'Estado',
        accessor: (po) => poTrans(po.status),
        id: 'status'
    },
    {
        Header: 'Método de pago',
        accessor: (po) => poTrans(po.paymentMethod)||'Sin método de pago',
        id: 'paymentMethod'
    },
    {
        Header: 'No. de transacción',
        accessor: (po) => po.transactionNumber||'Sin transacción',
        id: 'transactionNumber'
    },
    {
	Header: 'Requerido en almacén',
	id: 'stockRequired',
	accessor: po=>po.stockRequired,
	Cell: ({row})=>{
	    const content = requiredCellContent(row);
	    return (
		<OverlayTrigger
		    placement="bottom"
		    overlay={
			<Tooltip id={"stockrequired-"+content.id}>
			    <div>
				{(content==="OK"||content==="NA")?
				 content :(
				     <div>
					 <div>
					    Almacén requiere:
						{row.stockRequired.map(r=>(
						    <div key={r.product.name}>{r.stockRequest} {r.product.displayUnit.name}. {r.product.name}</div>
						))}
					 </div>
					 <div>
					     Se pidieron:
						{row.stockRequired.map(r=>(
						    <div key={r.product.name}>{r.ordered} {r.product.displayUnit.name}. {r.product.name}</div>
						))}
					 </div>
				     </div>

				 )
				}
			    </div>
			</Tooltip>
		    }>
		    <div>{content}</div>
		</OverlayTrigger>
	    );
	}
    },
    {
	Header: 'Recibido en almacén',
	id: 'stockReceived',
	accessor: po=>po.stockReceived,
	Cell: ({row})=>{
	    const content = receivedCellContent(row);
	    return (
		<OverlayTrigger
		    placement="bottom"
		    overlay={
			<Tooltip id={"stockreceived-"+content.id}>
			    <div>
				{(content==="OK"||content==="NA")?
				 content :(
				     <div>
					 <div>
					    Se pidieron:
						{row.stockReceived.map(r=>(
						    <div key={r.product.name}>{r.requested} {r.product.displayUnit.name}. {r.product.name}</div>
						))}
					 </div>
					 <div>
					     Se recibieron:
						{row.stockReceived.map(r=>(
						    <div key={r.product.name}>{r.received} {r.product.displayUnit.name}. {r.product.name}</div>
						))}
					 </div>
				     </div>

				 )
				}
			    </div>
			</Tooltip>
		    }>
		    <div>{content}</div>
		</OverlayTrigger>
	    );
	}
    },
    {
        Header: 'Total',
        accessor: (req) => moneyFormatter(req.total, 1),
        id: 'total',
        filterable: false,
    }
];

export const purchaseOrderDetailSGroups = [
    /* in previous sGroups schema*/
    "purchase_requisition_read",
    'purchase_requisition_read_jazz_area',
    'jazz_area_read',
    'purchase_requisition_read_id',
    "purchase_order_read",
    "purchase_product_read",
    "provider_read",
    "product_simple_read",
    "lounge_read",
    "employee_simple",
    "tax_read",
    "area_read",
    "position_read",
    "mu_read",
    "app_file_read",
    "update_date",
    "creation_date",
    "cassette_read",
    "product_brand_read",
    "purchase_product_adjustment_read",
    "purchase_order_read_purchase_payments",
    "purchase_order_read_purchase_invoices",
    "purchase_invoice_read",
    "purchase_invoice_read_app_files",
    "purchase_payment_read",
    "purchase_payment_read_app_files",
    /****************************/

    'purchase_order_read_expected_date',
    'purchase_order_read_stock_movement_batches',
        'stock_movement_batch_read_movements',
            'stock_movement_read_product_brand',
                'product_brand_read_id',
            'stock_movement_read_quantity',
            'stock_movement_read_status',
    'unit_conversion_rule_read_id',
    'unit_conversion_rule_read_unit',
    'unit_conversion_rule_read_multiplier',
];

export const purchaseOrderListSGroups=[
    'purchase_order_read_id',
    'purchase_order_read_purchase_requisition',
        'purchase_requisition_read_jazz_area',
        'jazz_area_read',
        'purchase_requisition_read_id',
        'purchase_requisition_read_requested_by',
            'employee_read_full_name',
            'employee_read_area',
                'area_read',
    'purchase_order_read_provider',
        'provider_read_id',
        'provider_read_name',
        'provider_read_commercial_name',
    'creation_date',
    'purchase_order_read_status',
    'purchase_order_read_payment_method',
    'purchase_order_read_transaction_number',
    'purchase_order_read_total',
    'purchase_order_read_expected_date',
    'purchase_order_read_stock_received',
    'purchase_order_read_stock_required',
];
