import { flightStatusConverter } from 'utils/modelUtils/ee/flightUtils';
import { departureTimeConverter } from 'utils/modelUtils/ee/flightUtils';

const _noInfoMessage = "Sin información";

export const headerName = {
    airline: 'Aerolínea',
    flightNumber: 'Número de Vuelo',
    destination: 'Destino',
    destinationType: 'Tipo de destino',
    flightType: 'Tipo de vuelo',
    flightHour: 'Hora',
    flightGate: 'Puerta',
    flightStatus: 'Estado',
    terminal: 'Terminal',
};

export const columnsConfig = [
    {
        Header: headerName.airline,
        id: 'airline',
        accessor: ({airline}) => airline?.name || _noInfoMessage
    },
    {
        Header: headerName.flightNumber,
        id: 'flightNumber',
        accessor: ({number}) => number || _noInfoMessage
    },
    {
        Header: headerName.destination,
        id: 'destination',
        accessor: ({destination}) => destination || _noInfoMessage,
        width: 150
    },
    {
        Header: headerName.flightType,
        id: 'flightType',
        accessor: ({flightType}) => {
            if (flightType === 'international')
                return "Internacional";

            if (flightType === 'domestic')
                return "Doméstico";

            return _noInfoMessage;
        },
        width: 200
    },
    {
        Header: headerName.destinationType,
        id: 'destinationType',
        accessor: ({destinationType}) => destinationType?.name || _noInfoMessage,
        width: 200
    },
    {
        Header: "Meta mensual",
        id: 'monthlyGoal',
        accessor: ({monthlyGoal}) => monthlyGoal!==null?monthlyGoal:_noInfoMessage,
        width: 200
    },
    {
        Header: headerName.flightHour,
        id: 'departureTime',
        accessor: ({departureTime}) => departureTime?departureTimeConverter(departureTime): _noInfoMessage
    },
    {
        Header: headerName.flightGate,
        id: 'gate',
        accessor: ({gate}) => gate || _noInfoMessage
    },
    {
        Header: headerName.terminal,
        id: 'terminal',
        accessor: ({terminal}) => terminal || _noInfoMessage
    },
    {
        Header: headerName.flightStatus,
        id: 'status',
        accessor: ({status}) => status?flightStatusConverter(status):_noInfoMessage
    }
];
export const flightTypeOptions = [
    {label: "Llegada", value: false},
    {label: "Salida", value: true}
];

export const sGroups = [
    "flight_read",
    "flight_read_airline",
    "airline_read",
    "flight_read_destination_type",
    "destination_type_read"
];
