import React from 'react';
import moment from 'moment';
import {Modal, ModalHeader, ModalTitle, ModalBody} from 'react-bootstrap';
import useTideEntity from '../../../hooks/useTideEntity2';
import {showSGroups as sGroups} from '../../../utils/modelUtils/davinci/guestUtils';

const requestParams = {sGroups};
const GuestDetailModal = ({onHide, guestId})=>{
    const [guest, loading, notFound] = useTideEntity('guests', guestId, {
	loadingId: '@GuestDetailModal.guest',
	requestParams,
	customProp: 'guestDetail'
    });
    const place = [guest?.city, guest?.country?.spanishName].filter(n=>!!n).join('/');
    return (
	<Modal show onHide={onHide} className="GuestDetailModal">
	    <ModalHeader closeButton>
		<ModalTitle>Detalle del huésped</ModalTitle>
	    </ModalHeader>
	    <ModalBody>
		<div className="container-fluid">
		    {loading?'Cargando...':(notFound?'Huésped no encontrado':guest&&(
			<>
			    <div className="row">
				<div className="col-xs col-md-6">
				    Nombre completo:
				</div>
				<div className="col-xs col-md-6">
				    <b>{guest.fullName}</b>
				</div>
			    </div>
			    <div className="row">
				<div className="col-xs col-md-6">
				    Género:
				</div>
				<div className="col-xs col-md-6">
				    <b>{guest.gender}</b>
				</div>
			    </div>
			    <div className="row">
				<div className="col-xs col-md-6">
				    Fecha de nacimiento:
				</div>
				<div className="col-xs col-md-6">
				    <b>{guest.birthday}</b>
				</div>
			    </div>
			    <div className="row">
				<div className="col-xs col-md-6">
				    Ciudad/País:
				</div>
				<div className="col-xs col-md-6">
				    <b>{place}</b>
				</div>
			    </div>
			    <div className="row">
				<div className="col-xs col-md-6">
				    Teléfono:
				</div>
				<div className="col-xs col-md-6">
				    <b>{guest.phone}</b>
				</div>
			    </div>
			    <div className="row">
				<div className="col-xs col-md-6">
				    Correo:
				</div>
				<div className="col-xs col-md-6">
				    <b>{guest.email}</b>
				</div>
			    </div>
			    <div className="row">
				<div className="col-xs col-md-6">
				    VIP o non grata:
				</div>
				<div className="col-xs col-md-6">
				    <b>{guest.vipSince&&'VIP'}{guest.nonGrataSince&&'Non grata'}{(!guest.vipSince&&!guest.nonGrataSince)&&'Huésped normal'}</b>
				</div>
			    </div>
			    <h4>Visitas</h4>
			    <hr/>
			    <div className="visits">
				{guest.visits.length>0?(
				    guest.visits.map(v=>(
					<div key={v.id} className="row">
					    <div className="col-xs col-md-6">
						{v?.firstCheckInDate?moment(v?.firstCheckInDate).format('DD/MM/YYYY'):''}
					    </div>
					    <div className="col-xs col-md-6">
						{v?.lounge?.name}
					    </div>
					</div>
				    ))
				):'Sin visitas registradas'}
			    </div>
			</>
		    ))}
		</div>
	    </ModalBody>
	</Modal>
    );
};

export default GuestDetailModal;
