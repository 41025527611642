import React from 'react';


export default function ShiftAnswer( props ){

    let answer = '';
    if( props.data && props.data.response )
        answer = props.data.response;

    const onShiftSelect = ( e )=>{
        props.onChange( {response:e.target.value} );
    };

    return (
        <div className='answer-component LoungeAnswer'>
            <select
                className='form-control'
                value={answer}
                onChange={onShiftSelect}
                disabled={!props.editable}
            >
                <option disabled value=''>Selecciona un turno</option>

                <option value='Matutino'>Matutino</option>
                <option value='Vespertino'>Vespertino</option>
                <option value='Nocturno'>Nocturno</option>

            </select>
        </div>
    );

}
