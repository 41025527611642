import React, {useCallback,useEffect, useContext, useState, useMemo} from 'react';
import {ApiContext} from "../../api/Api";
import {useSelector} from "react-redux";
import {Grid, Col, Row} from "react-bootstrap";
import CustomButton from "../../elements/CustomButton/CustomButton";
import _ from 'lodash';
import Select from 'react-select';
import useCallbackCreator from "../../hooks/useCallbackCreator";
import EntitySelect from "../EntitySelect/EntitySelect";

const emptyArr=[];

const UnitBundleCreator = ({unitBundle, onChange,  showTemplates, requiredUnits})=>{

    const api = useContext( ApiContext );

    let [smallestUnit, setSmallestUnit] = useState( null );
    let [displayUnit, setDisplayUnit] = useState( null );
    let [conversionRules, setConversionRules] = useState( [] );

    //If we get this props, we make this component controlled
    if( unitBundle && onChange ){

        smallestUnit = unitBundle.smallestUnit;
        displayUnit = unitBundle.displayUnit;
        conversionRules = unitBundle.conversionRules;

        setSmallestUnit = ( smallestUnit )=>onChange( {...unitBundle, smallestUnit} );
        setDisplayUnit = ( displayUnit )=>onChange( {...unitBundle, displayUnit} );
        setConversionRules = ( conversionRules )=>onChange( {...unitBundle, conversionRules} );

    }

    const measurementConversionTemplates = useSelector(s=>s.api.measurementConversionTemplates)||emptyArr;

    useEffect(() => {
        if ( showTemplates && measurementConversionTemplates.length === 0)
            api.measurementConversionTemplates.get();
    }, [api, measurementConversionTemplates.length, showTemplates]);

    const addRule = ()=>{
        setConversionRules( [ ...conversionRules, emptyRule() ] );
    };

    const setRuleUnit = useCallbackCreator(( index, unit )=>{
        const newRules = [...conversionRules];
        newRules[index] =  { ...newRules[index], unit };
        setConversionRules( newRules );
    },[conversionRules]);

    const setRuleMultiplier = ( multiplier, index )=>{
        const newRules = [...conversionRules];
        newRules[index] =  { ...newRules[index], multiplier };
        setConversionRules( newRules );
    };

    const changeDisplayUnit = ( unit )=>{

        if( !unit ) return setDisplayUnit( null );

        const ruleIndex = _.findIndex( conversionRules, r => r.unit && r.unit.id === unit.id );

        let newConversionRules;

        //If there is no conversion rule for the displayUnit, we add it
        if( ruleIndex === -1 && ( !smallestUnit || smallestUnit.id !== unit.id)) {
            const newRule = emptyRule();
            newRule.unit = unit;
            newConversionRules = [newRule, ...conversionRules];
        }
        //If there was a rule for the conversion unit but was not the first rule, we change the order
        else if( ruleIndex !== 0 ){
            const oldRule = conversionRules[ ruleIndex ];
            const newRules = [ oldRule, ...conversionRules ];
            newRules.splice( ruleIndex+1, 1 );
            newConversionRules = newRules;
        }

        if( newConversionRules )
            onChange( { ...unitBundle, displayUnit: unit, conversionRules: newConversionRules } );
        else
            setDisplayUnit( unit )
    };

    //Let's be sure that we have rules for the required units
    useEffect(()=>{

        if(!requiredUnits || !smallestUnit) return;

        const missing=requiredUnits.reduce( (acc, unit)=>{

            if( unit.id!==smallestUnit.id &&//If it's not the smallest unit
                !_.find(conversionRules,(cr)=>cr.unit && cr.unit.id===unit.id)//And is not already a rule
            )
                acc.push(emptyRule(unit));//We add the rule
            return acc;
        }, []);

        if(missing.length)
            setConversionRules([...conversionRules, ...missing]);

    },[requiredUnits, smallestUnit, conversionRules]);

    const changeSmallestUnit = ( unit )=>{

        if( !unit ) return setSmallestUnit( null );

        const ruleIndex = _.findIndex( conversionRules, r => r.unit && r.unit.id === unit.id );

        let newConversionRules;

        if( ruleIndex !== -1 ){
            const newRules = [ ...conversionRules ];
            newRules.splice( ruleIndex, 1 );
            newConversionRules = newRules;
        }

        if( newConversionRules )
            onChange( { ...unitBundle, smallestUnit: unit, conversionRules: newConversionRules } );
        else
            setSmallestUnit( unit )
    };

    const removeRule = useCallbackCreator((index)=>{
        const newRules = [...conversionRules];
        newRules.splice(index, 1);
        setConversionRules(newRules);
    },[conversionRules]);

    const templatesForSelect = useMemo( ()=>
        measurementConversionTemplates.map(
            mct=>({ label:mct.name, value: mct.id })
        ), [ measurementConversionTemplates ] );

    const selectedTemplate = ( selected )=>{

        const template = _.find( measurementConversionTemplates, mct=>mct.id === selected.value );
        if( !template ) return;

        if( !onChange ) {//For uncontrolled version
            setSmallestUnit(template.smallestUnit);
            setDisplayUnit(template.displayUnit);

            if (template.conversionRules) {
                const newRules = template.conversionRules.map(cr => {
                    const newRule = {...cr, multiplier: Number(cr.multiplier)};
                    delete newRule.id;
                    return newRule;
                });
                setConversionRules(newRules);
            }
        }
        else{//For controlled version
            const bundle = { ...template };
            if( bundle.conversionRules ){
                bundle.conversionRules = bundle.conversionRules.map( cr=>{
                    const newConvRule = {...cr, multiplier: Number(cr.multiplier)};
                    delete newConvRule.id;
                    return newConvRule;
                } )
            }

            onChange( bundle );
        }
    };

    const isRuleEditable=(rule)=>{
        if( !rule.unit )
            return true;
        if(displayUnit && rule.unit.id===displayUnit.id)
            return false;

        return !_.find(requiredUnits, (ru) => ru.id === rule.unit.id);
    };


    //Remove all the units that are already used from an array of units
    const filterUnitsInRules=useCallback((units)=> {
        let unitsInRules = conversionRules.filter(r => r.unit).map(r => r.unit);
        if( smallestUnit )
            unitsInRules=[ ...unitsInRules, smallestUnit ];

        return _.filter(units, optionUnit =>!_.find(unitsInRules, unitInRule => unitInRule.id === optionUnit.id));
    },[conversionRules, smallestUnit]);

    return (
            <Grid fluid className='UnitBundleCreator'>

                {showTemplates && <>
                    <Row className='text-center'>
                        <Select
                            placeholder='Copiar una plantilla de unidades'
                            options={templatesForSelect}
                            onChange={ selectedTemplate }
                        />
                    </Row>
                    <hr/>
                </>}

                <Row>
                    <Col xs={4} className={'f-label'}>Unidad más pequeña</Col>
                    <Col xs={8}>
                        <EntitySelect
                            entity='measurementUnits'
                            value={smallestUnit}
                            onChange={ changeSmallestUnit }
                            maxResults={110}
                            creatable
                        />
                    </Col>
                </Row>
                <hr/>

                <Row>
                    <Col xs={4} className={'f-label'}>Unidad para mostrar</Col>
                    <Col xs={8}>
                        <EntitySelect
                            entity='measurementUnits'
                            value={displayUnit}
                            onChange={changeDisplayUnit}
                            maxResults={110}
                            creatable
                        />
                    </Col>
                </Row>
                <hr/>

                <Row>
                    <h5>Reglas de conversión</h5>
                </Row>

                { conversionRules.map((rule, i)=>
                    <React.Fragment key={i}>
                        <Row className={'text-center'}>
                            <Col xs={3} md={1}>{isRuleEditable(rule)?<i className='fa fa-trash red-icon' onClick={removeRule(i)}/>:null}&nbsp;Un(a)</Col>
                            <Col xs={9} md={4}>
                                {!isRuleEditable(rule) ?// If this is the conversion rule for the displayUnit, it's not selectable
                                    <p className='alert alert-info'>{rule.unit.name}</p>
                                    :
                                    <EntitySelect
                                        entity='measurementUnits'
                                        value={rule.unit}
                                        onChange={setRuleUnit(i)}
                                        creatable
                                        filterEntities={filterUnitsInRules}
                                        maxResults={110}
                                    />
                                }
                            </Col>
                            <Col xs={12} md={1}>equivale a</Col>
                            <Col xs={3} md={2}><input className='form-control' value={rule.multiplier} onChange={ ( {target} )=>setRuleMultiplier( target.value, i )} type='number' /></Col>
                            <Col xs={9} md={4}>{ smallestUnit?
                                <p className='alert alert-info'>{smallestUnit.name}</p> :
                                <p className='alert alert-warning'>Sin unidad mínima</p> }
                            </Col>
                        </Row>
                        <hr/>
                    </React.Fragment>
                )}
                <Row><CustomButton bsStyle='primary' className='col-xs-12' onClick={addRule}>Agregar regla</CustomButton></Row>
            </Grid>
    );

};

const emptyRule = (unit=null)=>({
    multiplier: 0,
    unit,
});

export default UnitBundleCreator;
