import React, {Component} from 'react';
import WeekSchedule from './WeekSchedule';
import Button from '../../../../elements/CustomButton/CustomButton';
import LoaderIcon from '../../../../components/Helper/LoaderIcon';
import { Panel } from 'react-bootstrap';



export default class ShiftPanel extends Component {

    constructor(props) {

        super(props);
        this.state = {
            editing: false,
            newName:props.shift.name,
            hasMealTime:!!props.shift.hasMealTime,
            category: props.categoryId,
            mealMinutes: props.shift.mealMinutes,
            canGetExtraMealTime: props.shift.canGetExtraMealTime
        };

    }

    edit = ( e )=>{
        e.stopPropagation();

        if( this.state.editing  &&
            this.props.onEdit &&
            (
                this.props.shift.name !== this.state.newName ||
                this.props.shift.hasMealTime !== this.state.hasMealTime ||
                this.props.shift.mealMinutes !== this.state.mealMinutes ||
                this.props.shift.canGetExtraMealTime !== this.state.canGetExtraMealTime ||
                this.props.categoryId !== this.state.category
            )
        )
            this.props.onEdit( {
                id: this.props.shift.id,
                name:this.state.newName,
                type: this.props.shift.type,
                hasMealTime:this.state.hasMealTime,
                category: this.state.category,
                mealMinutes: parseInt(this.state.mealMinutes, 10) || 0,
                canGetExtraMealTime: this.state.canGetExtraMealTime
            } );


        this.setState( {editing:!this.state.editing} );



    };

    render(){
        return (
            <Panel
                collapsible
                key={this.props.shift.id}
                header={

                    <div>
                        {typeStrings[ this.props.shift.type ]}&nbsp;
                        -&nbsp;
                        {
                            !this.state.editing?
                                this.state.newName :
                                <input value={this.state.newName} onChange={(e)=>this.setState({newName:e.target.value})} />
                        } &nbsp;
                        <i className={this.state.editing? 'fa fa-check green':'fa fa-pencil sky-blue'} onClick={this.edit}/>
                        <b className="caret" />
                    </div>
                }

                eventKey={this.props.shift.id}>

                {this.state.editing?
                    <select value={this.state.category} onChange={(e)=>this.setState({category:e.target.value})} >
                        {this.props.categories.map(sCategory=>
                            <option value={sCategory.id} key={sCategory.id}>{sCategory.name}</option>
                        )}
                    </select>
                    :null}

                <p>¿Requiere registros de comida? &nbsp;
                    {!this.state.editing ?
                        <strong>{this.state.hasMealTime ? "Sí" : "No"}</strong> :
                        <input
                            type='checkbox'
                            checked={this.state.hasMealTime}
                            onChange={(e) => {
                                this.setState({ hasMealTime: e.target.checked})
                            }}
                        />
                    }
                        </p>

                {this.state.hasMealTime?
                    <div>

                        <p>¿Puede obtener tiempo de comida extra? &nbsp;
                            {!this.state.editing ?
                                <strong>{this.state.canGetExtraMealTime ? "Sí" : "No"}</strong> :
                                <input
                                    type='checkbox'
                                    checked={this.state.canGetExtraMealTime}
                                    onChange={(e) => {
                                        this.setState({ canGetExtraMealTime: e.target.checked})
                                    }}
                                />
                            }
                        </p>
                    </div>
                    :null}

                {this.props.shift.weekSchedule ?
                    <WeekSchedule weekSchedule={this.props.shift.weekSchedule}/>
                    :
                    <p>Sin horario definido</p>
                }
                <Button
                    bsStyle="danger"
                    onClick={this.props.onDelete}
                    className="delete-button"
                    disabled={ this.props.deleteLoading }
                >
                    { this.props.deleteLoading ?
                        <LoaderIcon />:
                        "Eliminar turno"
                    }
                </Button>
            </Panel>
        );
    }

}

const typeStrings = {
    M: 'Matutino',
    V: 'Vespertino',
    N: 'Nocturno',
};
