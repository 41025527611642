import React from 'react';
import {stringForConfig} from "../../../../utils/modelUtils/davinci/loungeAccessMethodUtils";

const AccessMethodAdditionalConfigList=({loungeAccessMethod})=>{

    if(!loungeAccessMethod)
        return null;

    const config = loungeAccessMethod.config||{};
    const entries=Object.entries(config);

    //This check if there is any truthy config value
    const hasAdditionalConfig= entries.length && entries.reduce((acc,[,val])=>{
        if(val.constructor === Array && !val.length)
            return acc;
        return acc||val
    }, false);

    return (
        <ul>
            {!hasAdditionalConfig?
                <li className='no-data'>Sin configuración adicional.</li>:
                entries.map(([key, value])=>
                    value?
                    <li key={key}>{stringForConfig(key, value)}</li>
                        :null)
            }
        </ul>
    )

};

export default AccessMethodAdditionalConfigList;
