import React, {useCallback, useContext, useEffect, useState} from 'react';
import ClassicModal from "../../../../components/Modals/ClassicModal";
import {TideApiContext} from "../../../../api/tideApiConfig";
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import useBoolean from "../../../../hooks/useBoolean";
import useFormState from "../../../../hooks/useFormState";
import {airportGateWithLoungesSGroups} from "../../../../utils/modelUtils/ee/airportGateUtils";
import _ from 'lodash';
import ConfirmModal from "../../../../components/Modals/ConfirmModal";
const emptyArr = [];

const AirportGateLoungesModal = ({ toEdit:airportGate, onHide }) => {

    const api = useContext(TideApiContext);
    const lounges = useSelector( s=>s.api?.lounges )||[];
    const [airportGateWithLounges, setAirportGateWithLounges] = useState();
    const airportGateLounges = airportGateWithLounges?.airportGateLounges || emptyArr;
    const [showingConfirmDelete, setShowingConfirmDelete] = useState();

    //  Load Data
    const reload = useCallback(()=>{
        api.clearProperty("airportGateLounges");
        api.airportGates.get({id:airportGate.id, params:{ sGroups:airportGateWithLoungesSGroups, pagination:false }, customProp:'_' })
            .then(setAirportGateWithLounges)
    },[api, airportGate]);

    useEffect(()=>{
        api.lounges.get({ params:{ isLounge: true, isActive: true,pagination:false, "order[name]":"ASC" } });
        reload();
    },[api, airportGate, reload]);

    // Get the time string for each lounge e.g. "10 minutes"
    const getTimeForLounge = ( lounge )=>{
        for( let i=0; i<airportGateLounges?.length||0; i++ ){
            if( airportGateLounges[i].lounge?.id === lounge.id ){
                return airportGateLounges[i].etaMinutes + " minutos";
            }
        }
        return "Sin información";
    }

    // ----- Edition handling ------
    const [editing, startEditing, stopEditing] = useBoolean();

    const { form, setForm, handleInputChange } = useFormState();
    //Pre fill form with the data
    useEffect(()=>{
        const form = {};
        for( let i=0; i<airportGateLounges.length; i++ ){
            form[ airportGateLounges[i].lounge.id ] = airportGateLounges[i].etaMinutes;
        }
        setForm(form);
    },[setForm, airportGateLounges]);

    // Save to server
    const loadingId = "@AirportGateLoungesModal.save";
    const handleConfirmButton = useCallback(()=>{
        if(!editing){
            return startEditing();
        }
        const newAirportGateLounges = [];
        _.forEach(form, (value, lounge)=>{
            const etaMinutes = Number(value);
            if(!etaMinutes)
                return;
            newAirportGateLounges.push({
                id: _.find(airportGateLounges, agl=>agl.lounge.id === lounge)?.id,
                lounge: lounge,
                etaMinutes
            });
        })

        api.airportGates.update({ id: airportGate.id, params:{
                airportGateLounges:newAirportGateLounges,
                sGroups:airportGateWithLoungesSGroups
            }, loadingId })
            .then( airportGate=>{
                setAirportGateWithLounges(airportGate);
                stopEditing();
            });

    },[api, editing, startEditing, form, airportGate, stopEditing, airportGateLounges]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    const handleDelete = () => {
        api.airportGates.delete({ id: airportGate.id, params:{}, loadingId })
        .then( () =>{
            api.airportGates.get().then(() => {onHide && onHide()});
        });
    };

    const showConfirm = useCallback(() => {
            setShowingConfirmDelete(true);
        }, [setShowingConfirmDelete]);

    const hideConfirm = useCallback(() => {
            setShowingConfirmDelete(false);
        }, [setShowingConfirmDelete]);

    return (
        <ClassicModal
            bsSize='lg'
            className={"AirportGateLoungesModal"}
            onHide={onHide}
            title={`Tiempos a la puerta ${airportGate.gateNumber}`}
            confirmText={editing? "Guardar":"Editar" }
            onSave={handleConfirmButton}
            disabled={loading}
        >

            <Table striped responsive>
                <thead>
                <tr>
                    <th>Sala</th>
                    <th>Tiempo</th>
                </tr>
                </thead>
                <tbody>
                {lounges.map( lounge=>
                    <tr key={lounge.id}>
                        <td>{lounge.name}</td>
                        <td>{ !editing?
                            getTimeForLounge(lounge):
                            <input value={form[lounge.id]||''} onChange={handleInputChange(lounge.id)} />
                        }</td>
                    </tr>
                )}
                </tbody>
            </Table>

            {airportGate &&
                <i className="fa fa-trash red-icon" onClick={showConfirm}/>}

            {showingConfirmDelete &&
                <ConfirmModal
                    show={true}
                    message={`¿Estás seguro que deseas borrar la puerta?, se eliminarán también los viajes que la tengan asignada`}
                    onCancel={hideConfirm}
                    onConfirm={handleDelete}
                />}
        </ClassicModal>
    );
};

export default AirportGateLoungesModal;
