import {useEffect, useRef} from 'react';

export default (callback, inputs)=>{

    const mounted=useRef(false);

    useEffect(()=> {
        if (mounted.current)
            callback();
        else
            mounted.current = true;
        // eslint-disable-next-line
    }, inputs)

};
