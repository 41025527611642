import React, {useState} from 'react';
import ReactTable from 'react-table';
import useTideTable from "../../../hooks/table/useTideTable";
import AddMedalModal from "./AddMedalModal";
import EditMedalModal from "./EditMedalModal";
import {Button} from "react-bootstrap";


const MedalAdmin = () => {
    const [showingAddMedal, setShowingAddMedal] = useState(false);
    const [medalEdited, setMedalEdited] = useState(null);
    const recognitionsTable = useTideTable({
        entity: 'recognitions',
        columns
    });
    const trProps = (state, rowInfo, column, instance)=>{
        return {
            onClick: e => {
                setMedalEdited(rowInfo.original);
            },
            style: {
                cursor: 'pointer'
            }
        };
    };
    return (
        <>
            <div className='Rewards admin'>
                <div className='add-medal-container'>
                    <h2>Creación de medallas</h2>
                    <Button onClick={() => setShowingAddMedal(true)}>Agregar medalla</Button>
                </div>
                <div>
                    <h2>Edición</h2>
                    <ReactTable
                        {...recognitionsTable.tableProps}
                        getTrProps={trProps}
                    />
                </div>
            </div>
            {showingAddMedal &&
            <AddMedalModal onHide={() => setShowingAddMedal(false)}/>
            }
            {!!medalEdited &&
            <EditMedalModal medal={medalEdited} onHide={() => setMedalEdited(null)}/>
            }
        </>
    );
};


const columns = [
    {
        Header: 'Nombre',
        accessor: 'name'
    },
    {
        Header: 'Costo',
        accessor: 'points',
        filterable: false

    },
    /*{
        Header: 'Editar',
        filterable: false,
        sortable: false,
        Cell: EditButton
    },*/
];


export default MedalAdmin;
