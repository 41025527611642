import React, {useContext, useEffect, useMemo, useState} from 'react';
import {ApiContext} from "../../../../api/Api";
import {connect} from "react-redux";
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts/lib/echarts';
import _ from 'lodash';
import moment from 'moment';
import {Table} from "react-bootstrap";


const AgeGraph = ( {employeesAgeReport} )=>{


    const api = useContext( ApiContext );

    const [ view, setView ] = useState( 'graph' );


    const reportExists = !!employeesAgeReport;
    useEffect( ()=>{
        if( !reportExists )
            api.employees.ageReport();
    }, [api, reportExists] );

    const data = useMemo( ()=>{

        if( !employeesAgeReport )
            return null;

        const years = [];
        const counts = [];
        let total = 0;

        const actualYear = moment().year();
        _.each( employeesAgeReport.ageCount, ( count, year )=>{
            years.push( actualYear - year );
            counts.push( count );
            total += Number(count);
        } );

        years.reverse();
        counts.reverse();

        return {years, counts, total };

    }, [ employeesAgeReport ] );


    const ageOptions = useMemo( ()=>{

        if( !data )
            return {};

        return ({
            backgroundColor:'#f7f7f7',

            xAxis: {
                name:'Edad',
                type: 'category',
                data: data.years,
                silent: false
            },
            yAxis: {
                name:'Personas',
                type: 'value'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params)=>{
                    const { name, value } = params[0];
                    const percent = ( Math.round(value/data.total * 10000 ) / 100 ) + ' %';
                    return name + ' años <br/>' + value + ' personas (' + percent + ')';
                }
            },
            series: [{
                data: data.counts,
                type: 'bar',
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#83bff6'},
                                {offset: 0.5, color: '#188df0'},
                                {offset: 1, color: '#188df0'}
                            ]
                        )
                    },
                    emphasis: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#2378f7'},
                                {offset: 0.7, color: '#2378f7'},
                                {offset: 1, color: '#83bff6'}
                            ]
                        )
                    }
                }
            }]
        });
    }, [data] );


    return(

        <>
            <h5>Edades</h5>

            <select value={view} onChange={({target})=>setView(target.value)} className='form-control'>
                <option value='graph'>Gráfica</option>
                <option value='table'>Tabla</option>
            </select>
            <br/>
            {view === 'graph' ?
                <ReactEcharts
                    option={ageOptions}
                    style={{height: "460px"}}
                />
                :
                <Table striped>
                    <thead>
                    <tr>
                        <th>Edad</th>
                        <th>Personas</th>
                        <th>Porcentaje</th>
                    </tr>
                    </thead>
                    <tbody>

                    { data?
                        data.counts.map( (count, index)=>
                            count?
                            <tr key={index}>
                                <td>{data.years[index]} años</td>
                                <td>{count} personas</td>
                                <td>{( Math.round(count/data.total * 10000 ) / 100 ) + ' %'}</td>
                            </tr>
                                :null
                        ):null}

                    </tbody>
                </Table>
            }

        </>
    );

};

const mapStateToProps = ( { api: { employeesAgeReport } } )=>({ employeesAgeReport });

export default connect(mapStateToProps)(AgeGraph);
