import React,{useState, useCallback, useContext} from 'react';
import ClassicModal from "../../../../components/Modals/ClassicModal";
import EntitySelect from "../../../../components/EntitySelect/EntitySelect";
import {ApiContext} from "../../../../api/Api";
import {NotifierContext} from "../../../../utils/Notifier";
import {productBrandName} from "../../../../utils/modelUtils/almazen/productBrandUtils";

const selectFilters={
    isService:false,
    isActive:true
};
const ProductBrandProviderFormModal=({onHide, provider, createAdditionalParameters={}})=>{

    const api=useContext(ApiContext);
    const notifier=useContext(NotifierContext);
    const [product, setProduct]=useState(null);

    const createProductBrandProvider=useCallback(()=>{
        if(!product)
            return notifier.error("Selecciona un producto primero");
        api.productBrandProviders.create({productBrandProvider:{
                provider: provider.id,
                productBrand: product.id,
                ...createAdditionalParameters}});
        onHide();
    },[api,product, provider, onHide, notifier, createAdditionalParameters]);

    return (
        <ClassicModal
            title={'Agregar producto de lista'}
            onHide={onHide}
            onSave={createProductBrandProvider}
        >
            <EntitySelect
            entity={'productBrands'}
            value={product}
            filterBy={'search'}
            labelCreator={productBrandName}
            onChange={setProduct}
            additionalFilters={selectFilters}
            />
        </ClassicModal>
    );
};

export default ProductBrandProviderFormModal;
