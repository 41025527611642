import React, {useContext, useEffect, useState} from 'react';
import {Col, Grid, Row} from "react-bootstrap";
import Card from '../../../components/Card/Card';
import {ApiContext} from "../../../api/Api";
import {connect} from 'react-redux';
import LoaderIcon from '../../../components/Helper/LoaderIcon';
import DemographicsStats from './statistics/DemographicsStats';
import SeniorityStats from './statistics/SeniorityStats';
import AgeStats from './statistics/AgeStats';
import LayoffsStats from './statistics/LayoffsStats';
import AppliedIncidencesStats from './statistics/AppliedIncidencesStats';


const EmployeeStatistics = ({employeesGeneralReport}) => {

    const api = useContext(ApiContext);

    const [activeStats, setActiveStats] = useState('');

    const reportExists = !!employeesGeneralReport;
    useEffect(() => {
        if (!reportExists)
            api.employees.generalReport();
    }, [api, reportExists]);


    return (
        <div className="main-content EmployeeStatistics">
            <Grid fluid>
                <Row>
                    <Col lg={2} md={4} sm={6} xs={12}>
                        <Card
                            title='Total de empleados'
                            content={
                                <div>
                                    {employeesGeneralReport ?
                                        <p className='stat-number'>{employeesGeneralReport.hiredEmployees}</p> :
                                        <LoaderIcon/>
                                    }
                                    <p><span className='link' onClick={() => setActiveStats('demographics')}><i
                                        className='fa fa-bar-chart'/> Ver reporte demográfico</span></p>
                                </div>
                            }
                        />
                    </Col>

                    <Col lg={2} md={4} sm={6} xs={12}>
                        <Card
                            title='Antigüedad promedio'
                            content={
                                <div>
                                    {employeesGeneralReport ?
                                        <p className='stat-number'>{formatYearsFromDays(employeesGeneralReport.averageSeniority)}</p> :
                                        <LoaderIcon/>
                                    }
                                    <p><span className='link' onClick={() => setActiveStats('seniority')}><i
                                        className='fa fa-bar-chart'/> Ver estadíticas</span></p>
                                </div>
                            }
                        />
                    </Col>

                    <Col lg={2} md={4} sm={6} xs={12}>
                        <Card
                            title='Edad promedio'
                            content={
                                <div>
                                    {employeesGeneralReport ?
                                        <p className='stat-number'>{formatYearsFromDays(employeesGeneralReport.averageAge)}</p> :
                                        <LoaderIcon/>
                                    }
                                    <p><span className='link' onClick={() => setActiveStats('age')}><i
                                        className='fa fa-bar-chart'/> Ver estadíticas</span></p>
                                </div>
                            }
                        />
                    </Col>

                    <Col lg={2} md={4} sm={6} xs={12}>
                        <Card
                            title='Bajas este año'
                            content={
                                <div>
                                    {employeesGeneralReport ?
                                        <p className='stat-number'>{employeesGeneralReport.yearLayoffs}</p> :
                                        <LoaderIcon/>
                                    }
                                    <p><span className='link' onClick={() => setActiveStats('layoffs')}><i
                                        className='fa fa-bar-chart'/> Ver estadíticas</span></p>
                                </div>
                            }
                        />
                    </Col>


                    <Col lg={2} md={4} sm={6} xs={12}>
                        <Card
                            title='Incidencias este año'
                            content={
                                <div>

                                    {employeesGeneralReport ?
                                        <p className='stat-number'>{employeesGeneralReport.yearAppliedIncidences}</p> :
                                        <LoaderIcon/>
                                    }
                                    <p><span className='link' onClick={() => setActiveStats('appliedIncidences')}><i
                                        className='fa fa-bar-chart'/> Ver estadíticas</span></p>
                                </div>
                            }
                        />
                    </Col>
                </Row>

                {componentForStats(activeStats)}

                <Row>


                </Row>
            </Grid>
        </div>
    );

};

const mapStateToProps = ({api: {employeesGeneralReport}}) => ({employeesGeneralReport});

export default connect(mapStateToProps)(EmployeeStatistics);


/* Helper functions */

const formatYearsFromDays = (days) => {

    let years = days / 365.25;
    years = Math.round(years * 100) / 100;

    return years + ' años';
};

const componentForStats = (statString) => {

    switch (statString) {

        case 'demographics':
            return <DemographicsStats/>;
        case 'seniority':
            return <SeniorityStats/>;
        case 'age':
            return <AgeStats/>;
        case 'layoffs':
            return <LayoffsStats/>;
        case 'appliedIncidences':
            return <AppliedIncidencesStats/>;
        default:
            return null;

    }

};