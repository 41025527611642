

export const filledQuestionnaireListSGroups = [
    'filled_questionnaire_read',
    'filled_questionnaire_read_questionnaire',
    'questionnaire_read_name',
    'filled_questionnaire_read_filled_by',
        'employee_read_name',
        'employee_read_pat_last_name',
        'employee_read_mat_last_name',
    'filled_questionnaire_read_lounge',
        'lounge_read_id',
        'lounge_read_name',
    'filled_questionnaire_read_visit',
        'visit_read_id',
        'visit_read_lounge',
        'visit_read_created_date_shift',
];

export const filledQuestionnaireFullSGroups = [
    'filled_questionnaire_read',
    'filled_questionnaire_read_questionnaire',
    'questionnaire_read_name',
    'filled_questionnaire_read_filled_by',
        'employee_read_name',
        'employee_read_pat_last_name',
        'employee_read_mat_last_name',
    'filled_questionnaire_read_lounge',
        'lounge_read_id',
        'lounge_read_name',
    'filled_questionnaire_read_visit',
        'visit_read_id',
        'visit_read_lounge',
        'visit_read_created_date_shift',
    'filled_questionnaire_read_answers',
        'answer_read',
        'answer_read_question',
            'question_read',
];
