
import React from 'react'
import classNames from 'classnames';
import {DateRangePicker} from "react-dates";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import useBoolean from "../../../../hooks/useBoolean";

export const ReportBlock = ({reportTitle, urlReportXLS, showDownload, dateRangeProps, setShowModal}) => {
    const [isOpen, , , toggleOpen] = useBoolean();

    return (
        <div className={classNames("report-title", isOpen && 'open')}>
            <p className='title' onClick={toggleOpen}>{reportTitle}<i className='fa fa-caret-down caret-icon'/>
            </p>
            {isOpen &&
            <div className='body'>
                <hr/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <p className='filter-label'>Rango de fechas:</p>
                            <DateRangePicker {...dateRangeProps} />
                        </div>
                    </div>
                </div>
                <br/>
                <div className="EEReports__buttons-container">
                    <CustomButton bsStyle='primary' onClick={()=>setShowModal(true)}>Ver Reporte</CustomButton>
                    
                    {showDownload && 
                        <a href={urlReportXLS}>
                            <CustomButton bsStyle='primary'>Descarga</CustomButton>
                        </a>}
                </div>
            </div>}
        </div>
    )
}
