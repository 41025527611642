import React from 'react';
import ReactEcharts from 'echarts-for-react';


const twoDecimals = ( num )=>Math.round( num*100 )/100;

export default function PercentageStats( props ){

    if( !props.stats.length )
        return <p>Sin información.</p>;

    let { realAvg, goalAvg, count } = props.stats[0];
    let percentage = 0;

    if( goalAvg )
        percentage = realAvg / goalAvg * 100;

    /*
    const options = [];
    const values = [];

    for( let i =0; i<stats.length; i++) {
        options.push( stats[i].answer );
        values.push({
            value: stats[i].count,
            name: stats[i].answer
        });
    }
    */

    const graphOtions = {

        series: [
            {
                name: props.result.question,
                type: 'gauge',
                detail: {
                    formatter:`{value}%\n\n${twoDecimals(realAvg)}/${twoDecimals(goalAvg)}`,
                    textStyle: { fontSize: 14 }
                },
                data: [{value: twoDecimals(percentage), name: props.result.question }],
                radius: '98%',
                axisLine: {
                    lineStyle: {
                        color: [
                            [0.7, '#ff443b'],
                            [0.8, '#ffc522'],
                            [0.95, '#97d400'],
                            [1, '#23da4a']
                        ],
                        shadowColor : '#777',
                        shadowBlur: 10
                    }
                },
            }
        ]
    };

    return (
        <div>
            <ReactEcharts option={graphOtions} />
            <span className='text-muted small'>{count} respuesta{Number(count)!==1?'s':''}</span>
        </div>
    );
}