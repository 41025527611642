import React from 'react';
import logo from '../../assets/img/tgle-ee.png';


const  EE = ()=>{
    return <div style={{ textAlign:'center', paddingTop:'50px' }}>
        <span style={{backgroundColor:'#282828', borderRadius:5, padding:'3%', margin:'1%', display:'inline-block'}}>
            <img alt='logo' src={logo } style={{maxWidth:'80%'}} />
        </span>
    </div>;
};

export default EE;
