import React, {useEffect, useMemo, useContext, useState} from 'react';
import {ApiContext} from '../../../api/Api';
import {xlsCourtesyConfig, courtesySGroups} from '../../../utils/modelUtils/davinci/checkInUtils';
import {xlsReportConfig as reservationsXlsReportConfig, reportSGroups as reservationsReportSGroups} from '../../../utils/modelUtils/davinci/reservationUtils';
import {xlsReCheckInsConfig, reCheckInsSGroups, guestSGroups, xlsGuestConfig, nonGrataSGroups, xlsNonGrataConfig, vipSGroups, xlsVipConfig} from '../../../utils/modelUtils/davinci/visitUtils';
import _ from 'lodash';
import {getWalkInsReportUrl} from "../../../services/modelUtils/checkInUtils";

const formatDate = d=>d.format('YYYY-MM-DD HH:mm:ss');
const dayStart = d=>d.clone().startOf('day');
const dayEnd = d=>d.clone().endOf('day');

const FileBoxes = ({dateRange, loungeAccessMethods, unflattenedLoungeAccessMethods}) => {
    const api = useContext(ApiContext);
    const [accessMethod, setAccessMethod] = useState(loungeAccessMethods[0].id);
    const [parent, setParent] = useState(unflattenedLoungeAccessMethods[0].id);
    const [parentBreakdown, setParentBreakdown] = useState(unflattenedLoungeAccessMethods[0].id);
    const [drinksId, setDrinksId] = useState(null);
    const [coffeeId, setCoffeeId] = useState(null);
    const dateRangeFilters = useMemo(
        ()=>({
            ...(dateRange.startDate?{'createdDate[after]': formatDate(dayStart(dateRange.startDate))}:{}),
            ...(dateRange.endDate?{'createdDate[before]': formatDate(dayEnd(dateRange.endDate))}:{})
        }),
        [dateRange]
    );
    useEffect(()=>{
        api.configurations.getSingle({
            id: 'DRINKS_CATEGORY_ID'
        }).then(v=>setDrinksId(v.value));
        api.configurations.getSingle({
            id: 'COFFEE_CATEGORY_ID'
        }).then(v=>setCoffeeId(v.value));

    }, [api]);

    const fromToFilters = useMemo(
        ()=>({
            ...(dateRange.startDate?{from: formatDate(dayStart(dateRange.startDate))}:{}),
            ...(dateRange.endDate?{to: formatDate(dayEnd(dateRange.endDate))}:{})
        }),
        [dateRange]
    );
    const parentName = useMemo(()=>_.find(unflattenedLoungeAccessMethods, l=>l.id===parent).name, [parent, unflattenedLoungeAccessMethods]);
//    const parentBreakdownName = useMemo(()=>_.find(unflattenedLoungeAccessMethods, l=>l.id===parentBreakdown).name, [parentBreakdown, unflattenedLoungeAccessMethods]);
    const lamName = useMemo(()=>_.find(loungeAccessMethods, l=>l.id===accessMethod).name, [accessMethod, loungeAccessMethods]);
    const breakdownBillingReportURL = useMemo(
        ()=>api.checkIns.exportBreakdownBillingUrl({
            params: {
                'loungeAccessMethod': parentBreakdown,
                ...fromToFilters,
                xlsName: parentName+'_desglose_facturación.xls'
            },
        }),
        [api, parentBreakdown, fromToFilters, parentName]
    );
    const generalBillingReportURL = useMemo(
        ()=>api.checkIns.exportBillingUrl({
            params: {
                'loungeAccessMethod': parent,
                ...fromToFilters,
                xlsName: parentName+'_facturación.xls'
            },
        }),
        [api, parent, fromToFilters, parentName]
    );
    const billingReportURL = useMemo(
        ()=>api.checkIns.exportBillingUrl({
            params: {
                'loungeAccessMethod': accessMethod,
                ...fromToFilters,
                xlsName: lamName+'_facturación.xls'
            },
        }),
        [api, accessMethod, fromToFilters, lamName]
    );
    const walkInsReportURL = getWalkInsReportUrl(api, dateRange);

    const courtesyReportURL = useMemo(
        ()=>api.checkIns.exportUrl({
            filters: {
                'loungeAccessMethod.id': 'loungeAccessMethod-courtesy',
                ...dateRangeFilters,
                sGroups: courtesySGroups,
                pagination: false
            },
            reportConfig: xlsCourtesyConfig
        }),
        [api, dateRangeFilters]
    );
    const vipReportURL = useMemo(
        ()=>api.visits.exportUrl({
            filters: {
                'guest.vipSince[before]': dateRangeFilters['createdDate[before]'],
                ...dateRangeFilters,
                sGroups: vipSGroups,
                pagination: false
            },
            reportConfig: xlsVipConfig
        }),
        [api, dateRangeFilters]
    );
    const nonGrataReportURL = useMemo(
        ()=>api.visits.exportUrl({
            filters: {
                'guest.nonGrataSince[before]': dateRangeFilters['createdDate[before]'],
                ...dateRangeFilters,
                sGroups: nonGrataSGroups,
                pagination: false
            },
            reportConfig: xlsNonGrataConfig
        }),
        [api, dateRangeFilters]
    );
    const reCheckInsReportURL = useMemo(
        ()=>api.visits.exportUrl({
            filters: {
                ...dateRangeFilters,
                sGroups: reCheckInsSGroups,
                pagiantion: false
            },
            reportConfig: xlsReCheckInsConfig,
        }),
        [api, dateRangeFilters]
    );
    const guestsReportURL = useMemo(
        ()=>api.visits.exportUrl({
            filters: {
                ...dateRangeFilters,
                sGroups: guestSGroups,
                pagination: false
            },
            reportConfig: xlsGuestConfig
        }),
        [api, dateRangeFilters]
    );
    const reservationDateFilters = useMemo(
        ()=>(_.mapKeys(dateRangeFilters, (v, k)=>
                       _.replace(k, 'createdDate', 'reservationDate'))),
        [dateRangeFilters]
    );
    const reservationsReportURL = useMemo(
        ()=>api.reservations.exportUrl({
            filters: {
                ...reservationDateFilters,
                sGroups: reservationsReportSGroups,
                pagination: false
            },
            reportConfig: reservationsXlsReportConfig
        }),
        [api, reservationDateFilters]
    );
    const orozcoProductsPopularityURL = useMemo(
        ()=>api.orozcoVisitOrderProducts.popularityURL({
            params: {
                ...fromToFilters,
                xlsName:'Popularidad_de_platillos.xls',
                'categoriesExcept': [drinksId, coffeeId],
                type: 'ALIMENTO'
            }
        }),
        [api, fromToFilters, drinksId, coffeeId]
    );
    const orozcoProductsDrinksPopularityURL = useMemo(
        ()=>api.orozcoVisitOrderProducts.popularityURL({
            params: {
                ...fromToFilters,
                xlsName:'Popularidad_de_bebidas.xls',
                categories: drinksId,
                type: 'BEBIDA'
            }
        }),
        [api, fromToFilters, drinksId]
    );

    const orozcoProductsServicesPopularityURL = useMemo(
        ()=>api.orozcoVisitOrderProducts.popularityURL({
            params: {
                ...fromToFilters,
                xlsName:'Popularidad_de_servicios.xls',
                type: 'SERVICIO'
            }
        }), [api, fromToFilters]);

    const orozcoProductsOthersPopularityURL = useMemo(
        ()=>api.orozcoVisitOrderProducts.popularityURL({
            params: {
                ...fromToFilters,
                xlsName:'Popularidad_de_otros.xls',
                type: 'OTRO'
            }
        }), [api, fromToFilters]);

    const freeOrozcoProductsPopularityURL = useMemo(
        ()=>api.orozcoVisitOrderProducts.popularityURL({
            params: {
                ...fromToFilters,
                canBeFreeMeal: true,
                xlsName:'Platillos_no_cobrados.xls',
                'categoriesExcept': [drinksId, coffeeId]
            }
        }),
        [api, fromToFilters, drinksId, coffeeId]
    );
    const freeOrozcoProductsDrinksPopularityURL = useMemo(
        ()=>api.orozcoVisitOrderProducts.popularityURL({
            params: {
                ...fromToFilters,
                xlsName:'Bebidas_no_cobradas.xls',
                categories: drinksId,
                canBeFreeMeal: true
            }
        }),
        [api, fromToFilters, drinksId]
    );
    const orozcoProductsCoffeePopularityURL = useMemo(
        ()=>api.orozcoVisitOrderProducts.popularityURL({
            params: {
                ...fromToFilters,
                xlsName:'Métodos_de_cafe.xls',
                categories: coffeeId
            }
        }),
        [api, fromToFilters, coffeeId]
    );
    const guestsAttendedURL = useMemo(
        ()=>api.employees.guestsAttendedReportURL({
            params: {
                ...fromToFilters,
                excelReport: true
            }
        }),
        [api, fromToFilters]
    );


    const methodChanged = e=>{
        setAccessMethod(e.target.value);
    };

    return (
        <div>
            <div className='file-box'>
                <h3><i className='fa fa-archive'/>&nbsp;&nbsp;DAVINCI</h3>
                <div className='file-container'>
                    <div className='file-entry'>
                        <h4>Desglose de facturación</h4>
                        <select className='form-control' value={parentBreakdown} onChange={e=>setParentBreakdown(e.target.value)}>
                            {unflattenedLoungeAccessMethods.map(l=><option key={l.id} onClick={e=>e.stopPropagation()} value={l.id}>{l.name}</option>)}
                        </select>
                        <a  href={breakdownBillingReportURL}><h2><i style={{color:'black'}} className='fa fa-money'/></h2></a>
                    </div>
                    <div className='file-entry'>
                        <h4>Facturación por tipo</h4>
                        <select className='form-control' value={parent} onChange={e=>setParent(e.target.value)}>
                            {unflattenedLoungeAccessMethods.map(l=><option key={l.id} onClick={e=>e.stopPropagation()} value={l.id}>{l.name}</option>)}
                        </select>
                        <a  href={generalBillingReportURL}><h2><i style={{color:'black'}} className='fa fa-money'/></h2></a>
                    </div>
                    <div className='file-entry'>
                        <h4>Facturación por subtipo</h4>
                        <select className='form-control' value={accessMethod} onChange={methodChanged}>
                            {loungeAccessMethods.map(l=><option key={l.id} onClick={e=>e.stopPropagation()} value={l.id}>{l.name}</option>)}
                        </select>
                        <a  href={billingReportURL}><h2><i style={{color:'black'}} className='fa fa-money'/></h2></a>
                    </div>
                    <a className='file-entry' href={walkInsReportURL}>
                        <h4>Reporte de Walk Ins</h4>
                        <h2><i className='fa fa-money'/></h2>
                    </a>
                    <a className='file-entry' href={courtesyReportURL}>
                        <h4>Reporte de cortesías</h4>
                        <h2><i className='fa fa-gift'/></h2>
                    </a>
                    <a className='file-entry' href={vipReportURL}>
                        <h4>Reporte de VIP's</h4>
                        <h2><i className='fa fa-user-plus'/></h2>
                    </a>
                    <a className='file-entry' href={nonGrataReportURL}>
                        <h4>Reporte huéspedes non grata</h4>
                        <h2><i className='fa fa-user-times'/></h2>
                    </a>
                    <a className='file-entry' href={reCheckInsReportURL}>
                        <h4>Número de re-check ins</h4>
                        <h2><i className='fa fa-repeat'/></h2>
                    </a>
                    <a className='file-entry' href={guestsReportURL}>
                        <h4>Lista de huéspedes</h4>
                        <h2><i className='fa fa-list'/></h2>
                    </a>
                    <a className='file-entry' href={reservationsReportURL}>
                        <h4>Lista de reservaciones</h4>
                        <h2><i className='fa fa-address-book-o'/></h2>
                    </a>
                </div>
            </div>
            <div className='file-box'>
                <h3><i className='fa fa-archive'/>&nbsp;&nbsp;OROZCO</h3>
                <div className='file-container'>
                    <a className='file-entry' href={orozcoProductsPopularityURL}>
                        <h4>Popularidad de platillos</h4>
                        <h2><i className='fa fa-cutlery'/></h2>
                    </a>
                    <a className='file-entry' href={orozcoProductsDrinksPopularityURL}>
                        <h4>Popularidad de bebidas</h4>
                        <h2><i className='fa fa-coffee'/></h2>
                    </a>
                    <a className='file-entry' href={orozcoProductsServicesPopularityURL}>
                        <h4>Popularidad de Servicios</h4>
                        <h2><i className='fa fa-star'/></h2>
                    </a>
                    <a className='file-entry' href={orozcoProductsOthersPopularityURL}>
                        <h4>Popularidad de Otros</h4>
                        <h2><i className='fa fa-user'/></h2>
                    </a>
                    <a className='file-entry' href={freeOrozcoProductsPopularityURL}>
                        <h4>Platillos no cobrados</h4>
                        <h2><i className='fa fa-usd'/>&nbsp;<i className='fa fa-cutlery'/></h2>
                    </a>
                    <a className='file-entry' href={freeOrozcoProductsDrinksPopularityURL}>
                        <h4>Bebidas no cobradas</h4>
                        <h2><i className='fa fa-usd'/>&nbsp;<i className='fa fa-coffee'/></h2>
                    </a>
                    <a className='file-entry' href={orozcoProductsCoffeePopularityURL}>
                        <h4>Métodos de café</h4>
                        <h2><i className='fa fa-coffee'/></h2>
                    </a>
                    <a className='file-entry' href={guestsAttendedURL}>
                        <h4>Huéspedes por anfitrión</h4>
                        <h2><i className='fa fa-users'/></h2>
                    </a>
                </div>
            </div>
        </div>

    );
};

export default FileBoxes;
