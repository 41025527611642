import moment from "moment";
import {ApiPaths, querySerialize} from "../../../api/Api";

export const getInventoryReportDownloadUrl = (api, filters, name="")=>{
    const reportConfig = {
        name: `Inventario ${name} ${moment().format("YYYY-MM-DD HH_mm")}.xlsx`,
        headers: {
            'productBrand.product.id': 'ID',
            'productBrand.product.name': 'Producto',
            'productBrand.brand.name': 'Marca',
            'productBrand.product.line.name': 'Línea',
            'stockAsDisplayUnit': 'Cantidad',
            'productBrand.product.displayUnit.name': 'Unidad',
            'productBrand.product.displayUnit.id': 'ID Unidad',
            'updatedDate': 'Última actualización'
        }
    };


    const query = querySerialize({...filters,  pagination:false, token: window.localStorage.googlead, reportConfig: JSON.stringify(reportConfig)});
    return `${api.host || api.baseUrl}${ApiPaths.inventories}.xlsx?${query}`;
}

export const getInventoryValueDownloadUrl = (api, warehouse)=>{

    if(!api.host) throw new Error("You should use the TideApi for this");

    const query = querySerialize({token: api.token, warehouse: warehouse?.id});
    return `${api.host}/api/inventories/value-report?${query}`;
}
