import SysadminDash from "../views/sysadmin/SysadminDash";
import PayrollDetail from "../views/alma/Payroll/PayrollDetail";
import EmployeeHistory from "../views/alma/Employees/core/EmployeeHistory";
import EmployeeJustifications from "../views/alma/Employees/payrollManagement/EmployeeJustifications";
import EmployeeIncidenceRequests from "../views/alma/Employees/payrollManagement/EmployeeIncidenceRequests";
import EmployeeIncidences from "../views/alma/Employees/payrollManagement/EmployeeIncidences";
import EmployeeWorkTools from "../views/alma/Employees/workTools/EmployeeWorkTools";
import EmployeePayrolls from "../views/alma/Employees/payrollManagement/EmployeePayrolls";
import ListEmployees from "../views/alma/Employees/core/ListEmployees";
import EmployeeForm from "../views/alma/Employees/core/EmployeeForm";
import ListFiredEmployees from "../views/alma/Employees/core/ListFiredEmployees";
import PersonnelRequisitions from "../views/alma/HumanResources/PersonnelRequisitions";
import Justifications from "../views/alma/Manager/Justifications";
import IncidenceRequests from "../views/alma/Manager/IncidenceRequests";
import PayrollFileHistory from "../views/alma/Payroll/PayrollFileHistory";
import PayrollList from "../views/alma/Payroll/PayrollList";
import EditEmployee from "../views/alma/Employees/core/EditEmployee";
import CataloguesIndex from "../views/alma/Catalogues/CataloguesIndex";
import LoginRecordsList from "../views/sysadmin/LoginRecordsList";
import Changelog from "../views/sysadmin/Changelog";
import QuestionnaireList from "../views/ee/Questionnaires/QuestionnaireList";
import FilledQuestionnaireList from "../views/ee/Questionnaires/FilledQuestionnaireList";
import QuestionnaireEditorList from "../views/ee/Questionnaires/QuestionnaireEditorList";
import TicketList from "../views/ee/Tickets/TicketList";
import HappinessLogReport from "../views/alma/HumanResources/HappinessLogReport";
import ReservationsList from "../views/ee/Reservations/ReservationsList";
import ACReservationsList from "../views/ee/AirConciergeReservations/ACReservationsList";
import QuestionnaireEditor from "../views/ee/Questionnaires/QuestionnaireEditor";
import QuestionnaireStats from "../views/ee/Questionnaires/QuestionnaireStats";
import QuestionnaireView from "../views/ee/Questionnaires/QuestionnaireView";
import NotificationsList from "../views/alma/Employees/NotificationsList";
import NotificationsConfiguration from "../views/alma/Employees/NotificationsConfiguration";
import PermissionsConfiguration from "../views/alma/Employees/permissions/PermissionsConfiguration";


const sysadminRoutes = [

    //Sysadmin
    { path: "/sysadmin", name: "Control", icon: "fa fa-grav", component: SysadminDash },
    { path: "/sysadmin/login-records", name: "Login", icon: "fa fa-child", component: LoginRecordsList },


    //Alma 2.0
    { path: "/sysadmin/payroll/detail/:id", name: "Detalle de nómina", component: PayrollDetail, hidden: true },
    { path: "/sysadmin/employee/history/:id", name: "Historial de asistencia", component: EmployeeHistory, hidden: true },
    { path: "/sysadmin/employee/justifications/:id", name: "Historial de justificaciones", component: EmployeeJustifications, hidden: true },
    { path: "/sysadmin/employee/requests/:id", name: "Historial de solicitudes", component: EmployeeIncidenceRequests, hidden: true },
    { path: "/sysadmin/employee/incidences/:id", name: "Historial de incidencias", component: EmployeeIncidences, hidden: true },
    { path: "/sysadmin/employee/worktools/:id", name: "Equipo de trabajo", component: EmployeeWorkTools, hidden: true },
    { path: "/sysadmin/employee/worktools", name: "Equipo de trabajo", component: EmployeeWorkTools, hidden: true },
    { path: "/sysadmin/employee/payroll/:id", name: "Pagos de colaborador", component: EmployeePayrolls, hidden: true  },

    {path: "/sysadmin/employees/permission-groups", name: "Grupos de permisos", icon: "fa fa-lock", component: PermissionsConfiguration},
    { collapse: true, name: "Colaboradores", state: "openEmployees", icon: "fa fa-users", views: [
            {path: "/sysadmin/employees/list", name: "Lista de colaboradores", mini:"LE", component: ListEmployees},
            {path: "/sysadmin/employees/add", name: "Nuevo colaborador", mini: "NE", component: EmployeeForm},
            {path: "/sysadmin/employees/fired", name: "Colaboradores dados de baja", mini: "ED", component: ListFiredEmployees},
            {path: "/sysadmin/personnel-requisitions", name: "Requisición de personal", mini: "RP", component: PersonnelRequisitions},
        ]
    },
    { path: "/sysadmin/justifying", smallName: "Justificantes", name: "Revisión de justificantes", icon: "fa fa-file-text-o", component: Justifications },
    { path: "/sysadmin/requests", smallName: "Solicitudes", name: "Revisión de solicitudes", icon: "fa fa-comments-o", component: IncidenceRequests },

    { collapse: true, name: "Nóminas", state: "openPayroll", icon: "pe-7s-plugin", views:[
            { path: "/sysadmin/payroll/upload/history", smallName: "Historial de Reloj", name: "Historial de Reloj Checador", mini: "HA", component: PayrollFileHistory },
            { path: "/sysadmin/payroll/list", name: "Lista de nóminas", mini: "N", component: PayrollList },
            { path: "/sysadmin/payroll/detail/actual", name: "Nómina en curso", mini: "NC", component: PayrollDetail, justMenu:true },
        ]
    },
    { path: "/sysadmin/employees/edit", name: "Editar empleado", component: EditEmployee, hidden: true },

    { path: "/sysadmin/catalogues", smallName: "Admon. de catálogos", name: "Administración de catálogos", icon: "fa fa-cogs", component: CataloguesIndex },

    //EE routes
    { path: "/sysadmin/questionnaires", name: "Cuestionarios", icon: "pe-7s-note2", component: QuestionnaireList },
    { path: "/sysadmin/filled-questionnaires", name: "Cuestionarios resueltos", icon: "pe-7s-notebook", component: FilledQuestionnaireList },
    { path: "/sysadmin/questionnaire-editor", name: "Editor de cuestionarios", icon: "pe-7s-pen", component: QuestionnaireEditorList },
    { path: "/sysadmin/tickets", name: "Tickets", icon: "pe-7s-mail-open-file", component: TicketList },
    { path: "/sysadmin/employees/happiness", name: "Felicidad", icon: "fa fa-smile-o", component: HappinessLogReport},
    { path: "/sysadmin/reservations", name: "Reservaciones", icon: "fa fa-book", component: ReservationsList},
    { path: "/sysadmin/air-concierge-reservations", name: "Air Concierge", icon: "pe-7s-rocket", component: ACReservationsList},

    { path: "/sysadmin/questionnaire-editor/:id", name: "Editor de cuestionarios", component: QuestionnaireEditor, hidden: true },

    { path: "/sysadmin/questionnaire-report/:id", name: "Estadísticas del cuestionario", component: QuestionnaireStats, hidden: true },

    { path: "/sysadmin/questionnaires/:id", name: "Cuestionario", component: QuestionnaireView, hidden:true },

    { path: "/sysadmin/changelog", name: "Changelog", icon: "fa fa-file-text-o", component: Changelog },

    { path: "/alma/notifications", name: "Notificaciones", component: NotificationsList, hidden:true, },
    { path: "/alma/notifications-configuration", name: "Configuración de notificaciones", component: NotificationsConfiguration, hidden:true, }

];

export default sysadminRoutes;
