import React from 'react';

const PurchaseRequisitionStatus = ( {purchaseRequisition, className=''} )=>{

    const pr = purchaseRequisition;
    if( !pr || !pr.status)
        return null;

    let msg, type;
    switch ( pr.status ) {
        case 'requested':
            msg = 'En espera de cotización';
            type = 'alert-warning';
            break;
        case 'quoted':
            msg = 'En espera de aprobación';
            type = 'alert-warning';
            break;
        case 'approved':
            msg = 'Requisición aprobada';
            type = 'alert-success';
            break;
        case 'denied':
            msg = 'Requisición denegada';
            type = 'alert-danger';
            break;
        case 'canceled':
            msg = 'Requisición cancelada';
            type = 'alert-danger';
            break;
        default:
            msg = pr.status;
            type = 'alert-primary';
    }

    return <span className={`alert status-alert ${type} ${className}`} style={ {marginTop: 30 } }>{msg}</span>
};

export default PurchaseRequisitionStatus;
