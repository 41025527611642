import React, {useCallback, useContext, useMemo, useEffect} from 'react';
import "./_GolfCartTripForm.scss";
import {Button} from "react-bootstrap";
import TimeSelector from "../../../../../components/TimeSelector/TimeSelector";
import TideEntitySelect from "../../../../../components/TideEntitySelect/TideEntitySelect";
import {TideApiContext} from "../../../../../api/tideApiConfig";
import moment from "moment";
import {NotifierContext} from "../../../../../utils/Notifier";
import _ from "lodash";
import {toString} from "lodash/lang";
import {
    getMinutesAndSecondsElapsedString,
    minutesToString,
    momentToMinutes,
    minutesToMoment
} from "../../../../../utils/TimeConverter";
import GolfCartTripPassengerInput from "./components/GolfCartTripPassengerInput";
import GolfTripTimer from "../GolfTripTimer/GolfTripTimer";
import useCallbackCreator from "../../../../../hooks/useCallbackCreator";
import {selectLoungeFilters} from "../../../../../utils/modelUtils/ee/loungesUtils";
import {SecurityContext} from "../../../../../utils/SecurityManager";
import {golfCartSelectorFilters} from "../../../../../utils/modelUtils/ee/golfCartUtils";
import {golfCartTripSGroups} from "../../../../../utils/modelUtils/ee/golfCartTripUtils";

/*const employeesFilters = {
    showAll: true,
    sGroups: [
        "employee_read_id",
        "employee_read_full_name"
    ],
    "user.permissionGroups.permissions.name": "START_AND_END_CART_TRIPS"
}
*/
const employeesFilters = {
    "permission": "START_AND_END_CART_TRIPS"
}

const GolfCartTripForm = ({
                              onCancel,
                              addingTrip,
                              reloadTrips,
                              reloadAddingTrip,
                              addingTripOnChange,
                              showCartSelector=false
                          }) => {
    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);
    const securityManager = useContext(SecurityContext);
    const isReadonlyUser = securityManager.ee.canSeeGolfCartTripListReadonly();
    const canStartAndEndTrips = securityManager.ee.canStartAndEndTrips();
    const canAdminTrips = securityManager.ee.canAdminTrips();
    const isPersistedTrip = useMemo(() => addingTrip?.scheduledTrip?.scheduledStartDate !== undefined, [addingTrip]);
    const isAssignedToUser =  useMemo(() => addingTrip?.operator?.id===securityManager.me.id, [securityManager, addingTrip]);

    const onSave = useCallback(() => {
        const startTime = !addingTrip?.scheduledTrip ? addingTrip?.startTime : addingTrip.scheduledStartTime;
        const endTime = !addingTrip?.scheduledTrip ? addingTrip?.endTime : addingTrip.scheduledEndTime;
        const scheduledStartDate = minutesToMoment(startTime, addingTrip.date);
        const scheduledEndDate =minutesToMoment(endTime, addingTrip.date);

        if(!isPersistedTrip && moment().isAfter(scheduledStartDate) )
            return notifier.error("No es posible crear viajes en el pasado");

        const config = {
            params: {
                scheduledStartDate: scheduledStartDate.format("YYYY-MM-DD HH:mm"),
                scheduledEndDate: scheduledEndDate.format("YYYY-MM-DD HH:mm"),
                golfCart: addingTrip.golfCart.id,
                airportGates: _.map(addingTrip?.airportGates, "id"),
                operator: addingTrip.operator?.id,
                lounge: addingTrip.lounge?.id,
                golfCartPassengers: addingTrip?.passengers?.map((passenger) => ({
                    visit: passenger?.visit?.id,
                    personsNumber: passenger.personsNumber
                })),
                sGroups: golfCartTripSGroups,
            }
        };

        if (isPersistedTrip) {
            config.id = addingTrip.scheduledTrip.id;
        } else {
            config.params.createdFromScheduledTrip = addingTrip.minutes === undefined;
        }

        api.golfCartTrips[ isPersistedTrip?'update':'create' ](config).then(() => {
            if (onCancel)
                onCancel();
            if (reloadTrips)
                reloadTrips();
            notifier.success("Viaje " + (isPersistedTrip ? "actualizado" : "creado"));
        });

        const cartOperatorId = (addingTrip.operator?.id ? toString(addingTrip.operator?.id) : null);
        const tripData = {operator: cartOperatorId};

        api.golfCarts.update({id: addingTrip?.golfCart?.id, params: tripData})
            .then(() => {
                    if (reloadTrips)
                        reloadTrips();
                }
            )
    }, [api, notifier, reloadTrips, onCancel, addingTrip, isPersistedTrip]);

    const deleteTrip = useCallback(() => {
        api.golfCartTrips.delete({id: addingTrip.scheduledTrip.id}).then(() => {
            notifier.success("Viaje eliminado");
            if (reloadTrips) {
                reloadTrips();
            }
            onCancel();
        });
    }, [api, addingTrip, reloadTrips, notifier, onCancel]);

    const onPersonsNumberChange = useCallback((index, value) => {
        const newPassengers = [...addingTrip?.passengers];
        newPassengers[index].personsNumber = value;
        addingTripOnChange({...addingTrip, passengers: newPassengers});
    }, [addingTrip, addingTripOnChange]);

    const onVisitChange = useCallback((index, value) => {
        const newPassengers = [...addingTrip?.passengers];
        newPassengers[index].visit = value;
        addingTripOnChange({...addingTrip, passengers: newPassengers});
    }, [addingTrip, addingTripOnChange]);

    const addPassenger = useCallback(() => {
        const newPassengers = addingTrip.passengers ? addingTrip.passengers : [];
        newPassengers.push({visit: null, personsNumber: 1})
        addingTripOnChange({...addingTrip, passengers: newPassengers});
    }, [addingTrip, addingTripOnChange]);

    const onPassengerRemove = useCallback((index) => {
        addingTripOnChange(addingTrip => {
            const newPassengers = [...addingTrip.passengers];
            newPassengers.splice(index, 1);
            return {...addingTrip, passengers: newPassengers};
        });
    }, [addingTripOnChange]);

    const changeHandler = useCallbackCreator((property, val) => {
        addingTripOnChange((addingTrip) => ({...addingTrip, [property]: val?.target?.value || val}));
    }, [addingTripOnChange]);


    const onStartTrip = useCallback(() => {
        api.golfCartTrips.update({
            id: addingTrip?.scheduledTrip?.id,
            params: {realStartDate: moment().format("YYYY-MM-DD HH:mm:ss"), sGroups: golfCartTripSGroups}
        })
            .then((trip) => {
                    if (reloadTrips)
                        reloadTrips();
                    if (reloadAddingTrip)
                        reloadAddingTrip(addingTrip?.scheduledTrip?.id, trip);
                }
            )
    }, [api, addingTrip, reloadTrips, reloadAddingTrip]);

    const onEndTrip = useCallback(() => {
        api.golfCartTrips.update({
            id: addingTrip?.scheduledTrip?.id,
            params: {
                realEndDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                endedBy: securityManager.me.id,
                sGroups: golfCartTripSGroups
            }
        })
            .then((trip) => {
                    if ( reloadTrips )
                        reloadTrips();
                    if ( reloadAddingTrip )
                        reloadAddingTrip(addingTrip?.scheduledTrip?.id, trip);
                }
            )
    }, [api, addingTrip, reloadTrips, reloadAddingTrip, securityManager]);

    const employeeLabelCreator = useCallback((e) => {
        return e.fullName;
    }, [])

    const gateLabelCreator = useCallback((ag) => {
        return ag.gateNumber;
    }, [])


    // Retuns the max time for go to all gates

    const currentLounge = addingTrip.lounge;
    const currentAirportGates = addingTrip.airportGates;
    const currentStartTime = addingTrip.startTime;
    const scheduledStartTime = addingTrip.scheduledStartTime;

    useEffect(() => {
        if (currentAirportGates && currentLounge) {
            let maxTime = 0;

            // calculate max time
            for (let i = 0; i <= (currentAirportGates.length - 1); i++) {
                let gateLoungeTime = currentAirportGates[i].airportGateLounges?.filter((gateLounge) => gateLounge.lounge?.id === currentLounge?.id)[0]?.etaMinutes;

                if (maxTime < gateLoungeTime) {
                    maxTime = gateLoungeTime;
                }
            }

            let startTime = currentStartTime;

            if (isPersistedTrip) { // If this trip is in edit mode
                startTime = scheduledStartTime;
            }

            const newEndTime = startTime + maxTime;

            addingTripOnChange((addingTrip) => ({...addingTrip, endTime: newEndTime, scheduledEndTime: newEndTime}));
        }

    }, [currentAirportGates, currentLounge, addingTripOnChange, currentStartTime, scheduledStartTime, isPersistedTrip])

    const handleCartSelection = useCallback((golfCart)=>{
        changeHandler("golfCart")(golfCart);
        if(golfCart.operator){
            changeHandler("operator")(golfCart.operator);
        }
    },[changeHandler]);

    const passengers = useMemo(() => {
        if(addingTrip?.passengers)
            return addingTrip?.passengers;

        if(addingTrip?.scheduledTrip?.golfCartPassengers)
            return addingTrip?.scheduledTrip?.golfCartPassengers;

        if(addingTrip?.golfCartPassengers)
            return addingTrip?.golfCartPassengers;

        return [];
    }, [addingTrip]);

    return (
        <div className={"GolfCartTripForm"}>
            <h5>Viaje para {addingTrip?.golfCart?.name||'carrito'}</h5>
            <span>{addingTrip?.date?.format('DD/MM/YYYY')}</span>
            <hr/>

            {(!addingTrip?.scheduledTrip?.realStartDate && !isReadonlyUser) ?
                <div>
                    {showCartSelector && <>
                        <h5>Carrito:</h5>
                        <TideEntitySelect
                            value={addingTrip?.golfCart}
                            entity={'golfCarts'}
                            onChange={handleCartSelection}
                            placeholder={'Selecciona un carrito...'}
                            additionalFilters={golfCartSelectorFilters}
                            filterLocal
                        />
                        <br/>
                    </>}
                    <div className="row">
                        <div className="col-xs-6">
                            <label className="control-label">Hora de inicio</label>
                            <TimeSelector
                                value={!addingTrip?.scheduledTrip ? addingTrip?.startTime : addingTrip?.scheduledStartTime}
                                onChange={changeHandler(!addingTrip?.scheduledTrip ? 'startTime' : 'scheduledStartTime')}/>
                        </div>
                        <div className="col-xs-6">
                            <label className="control-label">Hora de fin</label><br/>
                            <TimeSelector
                                value={!addingTrip?.scheduledTrip ? addingTrip?.endTime : addingTrip?.scheduledEndTime}
                                onChange={changeHandler(!addingTrip?.scheduledTrip ? 'endTime' : 'scheduledEndTime')}
                                maxValue={1499}
                            />
                        </div>
                    </div>
                    <hr/>
                    <h5>Sala de partida:</h5>
                    <TideEntitySelect
                        value={addingTrip?.lounge}
                        entity={'lounges'}
                        onChange={changeHandler("lounge")}
                        placeholder={'Selecciona una sala...'}
                        additionalFilters={selectLoungeFilters}
                        filterLocal
                    />
                    <hr/>
                    <h5>Puertas a recorrer:</h5>
                    <TideEntitySelect
                        entity={"airportGates"}
                        labelCreator={gateLabelCreator}
                        multi
                        value={addingTrip?.airportGates}
                        additionalFilters={airportGatesFilters}
                        onChange={changeHandler('airportGates')}
                        filterLocal
                        filterBy='gateNumber'
                    />
                    <hr/>
                    <h5>Operador:</h5>
                    <TideEntitySelect
                        labelCreator={employeeLabelCreator}
                        additionalFilters={employeesFilters}
                        entity={"employees"}
                        value={addingTrip?.operator}
                        onChange={changeHandler('operator')}
                        placeholder={'Escribe para buscar operadores...'}
                        getMethod={"getByPermissions"}
                    />
                    <hr/>
                    <h5>Pasajeros:</h5>
                    {passengers && passengers.map((passenger, index) =>
                        <GolfCartTripPassengerInput
                            passenger={passenger}
                            key={index}
                            index={index}
                            onPersonsNumberChange={onPersonsNumberChange}
                            onVisitChange={onVisitChange}
                            onRemove={onPassengerRemove}
                        />)}
                    <button onClick={addPassenger} className={"btn btn-default full-width"}>+ Agregar más huéspedes
                    </button>
                    <hr/>
                </div>
                : addingTrip.scheduledTrip &&
                <div>
                    <div className="row">
                        <div className="col-xs-6">
                            <label className="control-label">Inicio programado</label><br/>
                            {minutesToString(momentToMinutes(moment(addingTrip.scheduledTrip.scheduledStartDate)))}:00
                        </div>
                        <div className="col-xs-6">
                            <label className="control-label">Fin programado</label><br/>
                            {minutesToString(momentToMinutes(moment(addingTrip.scheduledTrip.scheduledEndDate)))}:00
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-xs-6">
                            <label className="control-label">Inicio de viaje</label><br/>
                            {addingTrip?.scheduledTrip?.realStartDate ? moment(addingTrip?.scheduledTrip?.realStartDate).format(' HH:mm:ss') : "Aún no inicia"}
                        </div>
                        <div className="col-xs-6">
                            <label className="control-label">Fin de viaje</label><br/>
                            {addingTrip?.scheduledTrip?.realEndDate ? moment(addingTrip?.scheduledTrip?.realEndDate).format(' HH:mm:ss') : "Aún no termina"}
                        </div>
                    </div>
                    {addingTrip?.scheduledTrip?.realEndDate &&
                    <div className="row">
                        <hr/>
                        <div className="col-xs-12">
                            <label className="control-label">Tiempo de viaje</label><br/>
                            {getMinutesAndSecondsElapsedString(moment(addingTrip?.scheduledTrip?.realStartDate), moment(addingTrip?.scheduledTrip?.realEndDate))}
                        </div>
                    </div>
                    }
                    <h5>Operador:</h5>
                    {addingTrip.operator?.fullName}
                    <hr/>
                    <h5>Pasajeros:</h5>
                    <span>Huéspedes:{' '}
                        {passengers?.map( p=>p?.visit?.guest?.fullName||'Anónimo' ).join(', ')}
                    </span>
                    <br/>
                    <span>
                        Total de pasajeros: 
                        {passengers?.reduce((acc, passenger) => passenger.personsNumber + acc, 0)}
                    </span>
                    <br/>
                    <span>Asientos:{' '}
                        {passengers?.map( p=>p?.visit?.seats?.map(s=>s.name ).join(', ')||'No reg.' ).join(', ')}
                    </span>
                    <br/>
                    <span>Vuelo:{' '}
                        {passengers?.map( p=>p?.visit?.flight?.number||'No reg.' ).join(', ')}
                    </span>
                    < hr/>
                </div>
            }

            {isPersistedTrip &&
            <div>
                <h5>

                </h5>
                {!addingTrip?.scheduledTrip?.realStartDate && (canStartAndEndTrips || canAdminTrips) && (isAssignedToUser || canAdminTrips) &&
                <Button onClick={onStartTrip} bsStyle="info" className="full-width">
                    <i className={"fa fa-play"}/> Iniciar viaje
                </Button>}

                {addingTrip?.scheduledTrip?.realStartDate && !addingTrip?.scheduledTrip?.realEndDate && (canStartAndEndTrips || canAdminTrips) &&
                <div>
                    <Button onClick={onEndTrip} bsStyle="info" className="full-width">
                        <i className={"fa fa-stop"}/> Terminar viaje
                    </Button>
                    <br/>
                    <div className="trip-time">
                        <div className="trip-stat">
                            <span className="trip-time-desc">Hora de inicio</span><br/>
                            <span
                                className={"trip-time-value"}>{moment(addingTrip?.scheduledTrip?.realStartDate).format('HH:mm')}</span>
                        </div>
                        <div className="trip-stat">
                            <span className="trip-time-desc">Tiempo de viaje</span><br/>
                            <span className="trip-time-value"><GolfTripTimer
                                startDate={addingTrip?.scheduledTrip?.realStartDate}/></span>
                        </div>
                    </div>
                </div>}
                <hr/>
                <h5>Puertas a recorrer:</h5>
                {addingTrip?.scheduledTrip.airportGates?.map((ag) => ag.gateNumber).join(", ")}
                <hr/>
            </div>
            }

            <hr/>
            <div>
                {isPersistedTrip && !isReadonlyUser && <Button onClick={deleteTrip} className={"btn btn-danger"}>Cancelar viaje</Button>}
                &nbsp;
                {!addingTrip?.scheduledTrip?.realStartDate && !isReadonlyUser && <Button onClick={onSave}
                                                                      bsStyle="success">{isPersistedTrip ? "Actualizar viaje" : "Crear viaje"}</Button>}
            </div>
        </div>
    );
};

const airportGatesFilters = {
    sGroups: [
        "airport_gate_read",
        "airport_gate_read_airport_gate_lounges",
        "airport_gate_lounge_read_eta_minutes",
        "airport_gate_lounge_read_lounge",
        "place_read_name",
        "place_read_id"
    ]
};

export default GolfCartTripForm;
