import React, {useState, useContext, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CustomButton from "../../../elements/CustomButton/CustomButton.jsx";
import moment from 'moment';
import {Grid, Row, Col, Table} from 'react-bootstrap';
import {ApiContext} from "../../../api/Api.js";
import _ from 'lodash';
import tableDataBuilder, {SEPARATOR} from "../../../utils/tableDataBuilder.js";
import moneyFormatter from "../../../utils/moneyFormatter";


const PurchaseProductsReport = () => {

    const api = useContext(ApiContext);

    const [dates, setDates] = useState({from: moment().month(0).date(1), to: moment()});
    const [reportBy, setReportBy] = useState('sumTotal');
    const [status, setStatus] = useState('approved');
    const [currency, setCurrency] = useState('MXN');

    const [yFilter, setYFilter] = useState({name: 'Meses (Creación de solicitud)', value: 'month'});
    const [xFilters, setXFilters] = useState([
        {name: 'Áreas', value: 'area'}
    ]);

    const purchasesReport = useSelector(({api}) => (api.purchaseProductsReport || []));

    useEffect(() => {
        const options = {groupBy: []};

        if (yFilter)
            options.groupBy.push(yFilter.value);

        _.forEach(xFilters, ({value}) => options.groupBy.push(value));

        options.fromDate = dates.from.format('YYYY-MM-DD');
        options.toDate = dates.to.format('YYYY-MM-DD');
        options.reportBy = reportBy;
        options.currency = currency;
        options.orderBy = 'count';
        options.orderDir = 'DESC';
        if(status)
            options.status = status;

        api.purchaseProducts.getReport({options});

    }, [api, xFilters, yFilter, dates, reportBy, currency, status]);

    const tableData = useMemo(() => {

        return tableDataBuilder(purchasesReport, xFilters, yFilter);

        // eslint-disable-next-line
    }, [purchasesReport]);

    const onDateChange = (e, {startDate, endDate}) => {
        setDates({from: moment(startDate), to: moment(endDate)});
    };

    const onReportByChange = ({target}) => {
        setReportBy(target.value);
    };

    const onCurrencyChange = ({target}) => {
        setCurrency(target.value);
    };

    const onStatusChange = ({target}) => {
        setStatus(target.value);
    };

    const removeYFilter = () => setYFilter(null);

    const addYFilter = ({target}) => setYFilter(availableFilters[target.value]);

    const removeXFilter = ({target}) => {
        const newFilters = [...xFilters];
        newFilters.splice(target.dataset.index, 1);
        setXFilters(newFilters);
    };

    const addXFilter = ({target}) => {
        const newFilters = [...xFilters];
        newFilters.push(availableFilters[target.value]);
        setXFilters(newFilters);
    };

    const xTotals = [];
    const yTotals = [];

    return (
        <div className='LayoffsReport InteractiveReport'>
            <h2 className="text-center">Reporte interactivo de compras</h2>

            <Grid fluid>

                <Row>
                    <div className='col-md-3'>

                        Rango de fechas de creación de solicitud &nbsp;
                        <DateRangePicker
                            startDate={dates.from}
                            endDate={dates.to}
                            onApply={onDateChange}
                            containerStyles={{}}
                        >

                            <CustomButton
                                bsStyle={'primary'}
                                className='col-xs-12 range-button'
                            >
                                <strong>{dates.from.format('YYYY-MM-DD')}</strong> al <strong>{dates.to.format('YYYY-MM-DD')}</strong>
                            </CustomButton>

                        </DateRangePicker>
                    </div>

                    <div className='col-md-3'>
                        Reportar por
                        <select onChange={onReportByChange} value={reportBy} className='form-control'>
                            <option value='sumTotal'>Costo de compra</option>
                            <option value='count'>No. de compras</option>
                            <option value='urgent_count'>No. de requisiciones urgentes</option>
                            <option value='urgent_sumTotal'>Costo de requisiciones urgentes</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        Estado de solicitud
                        <select value={status} onChange={onStatusChange} className='form-control'>
                            <option value=''>Todas</option>
                            <option value='approved'>Aprobadas</option>
                            <option value='quoted'>En espera de aprobación</option>
                            <option value='denied'>No aprobadas</option>
                            <option value='canceled'>Canceladas</option>
                        </select>
                    </div>
                    <div className='col-md-3'>
                        Moneda
                        <select value={currency} onChange={onCurrencyChange} className='form-control'>
                            <option value='MXN'>MXN</option>
                            <option value='USD'>USD</option>
                        </select>
                    </div>

                </Row>

                <Row>
                    <Col md={6} xs={12}>
                        <p className='filter-title'>
                            Selecciona filtros para el eje X
                            <select value='' onChange={addXFilter} className='form-control'>
                                <option value='' disabled>Agrega un filtro</option>
                                {availableFilters.map((filter, index) => {
                                        if ((yFilter && yFilter.value === filter.value) || _.find(xFilters, (fil) => fil.value === filter.value))
                                            return null;
                                        else
                                            return <option value={index} key={filter.value}>{filter.name}</option>;
                                    }
                                )}
                            </select>
                        </p>
                        <ul className='filter-list'>
                            {!xFilters || !xFilters.length ?
                                <li className='filter-item'>Sin filtro</li>
                                :
                                xFilters.map((filter, index) =>
                                    <li className='filter-item' key={filter.value}>{filter.name}
                                        <i className='fa fa-close' onClick={removeXFilter} data-index={index}/>
                                    </li>
                                )
                            }
                        </ul>
                    </Col>

                    <Col md={6} xs={12}>
                        <p className='filter-title'>
                            Selecciona filtros para el eje Y
                            <select value='' onChange={addYFilter} className='form-control'>
                                <option value='' disabled>Agrega un filtro</option>
                                {yFilter ? null : availableFilters.map((filter, index) => {
                                        if (_.find(xFilters, (fil) => fil.value === filter.value))
                                            return null;
                                        else
                                            return <option value={index} key={filter.value}>{filter.name}</option>;
                                    }
                                )}
                            </select>
                        </p>
                        <ul className='filter-list'>
                            {yFilter ?
                                <li className='filter-item'>
                                    {yFilter.name}
                                    <i className='fa fa-close' onClick={removeYFilter}/>
                                </li>
                                :
                                <li className='filter-item'>
                                    Sin filtro
                                </li>
                            }
                        </ul>
                    </Col>

                </Row>

            </Grid>

            <div className='table-container'>
                <Table>

                    <thead>
                    {tableData.headerRows.map((row, i) =>
                        <tr key={i}>
                            {yFilter ?
                                <th>~</th>
                                : null}
                            {row.map((col, j) =>
                                <th colSpan={col.size} key={`${i}-${j}`}>{col.name}</th>
                            )}
                            {xFilters && xFilters.length && (tableData.headerRows.length - 1) === i ?
                                <th>Total</th>
                                : null}
                        </tr>
                    )}
                    </thead>

                    <tbody>

                    {tableData.rowHeaders.map((rHeader, i) =>

                        <tr key={rHeader}>
                            {yFilter ?
                                <th>{rHeader ? rHeader : ('Sin ' + yFilter.name)}</th>
                                :
                                null
                            }

                            {tableData.columnsCodes.map((code, j) => {
                                const path = code.split(SEPARATOR);
                                let entries = tableData.data[path[0]];

                                if (yTotals.length < (j + 1))
                                    yTotals.push(0);

                                if (xTotals.length < (i + 1))
                                    xTotals.push(0);

                                for (let i = 1; i < path.length; i++) {
                                    if (entries && entries.subHeaders && entries.subHeaders[path[i]])
                                        entries = entries.subHeaders[path[i]];
                                    else {
                                        return <td className='empty' key={code}>0</td>;
                                    }
                                }
                                if (entries && entries.count) {

                                    xTotals[i] += Number(entries.count);
                                    yTotals[j] += Number(entries.count);
                                    return <td key={code}>{reportBy.indexOf('count')!==-1?entries.count:moneyFormatter(entries.count, 1)}</td>;
                                }
                                if (entries && entries[rHeader] && entries[rHeader].count) {

                                    xTotals[i] += Number(entries[rHeader].count);
                                    yTotals[j] += Number(entries[rHeader].count);
                                    return <td
                                        key={code}>{reportBy.indexOf('count')!==-1 ? entries[rHeader].count : moneyFormatter(entries[rHeader].count, 1)}</td>;
                                } else
                                    return <td className='empty' key={code}>0</td>;
                            })}

                            {xFilters && xFilters.length ?
                                <td>{reportBy.indexOf('count')!==-1  ? xTotals[i] : moneyFormatter(xTotals[i], 1)}</td>
                                : null}

                        </tr>
                    )}

                    {yFilter ?
                        <tr>
                            <th style={{width: 240}}>Total</th>
                            {yTotals.map((total, i) => <td
                                key={i}>{reportBy.indexOf('count')!==-1  ? total : moneyFormatter(total, 1)}</td>)}

                            {xFilters && xFilters.length ?
                                <td>
                                    {reportBy.indexOf('count')!==-1  ?
                                        <strong> {yTotals.reduce((acc, t) => acc + t, 0) || xTotals.reduce((acc, t) => acc + t, 0)} </strong> :
                                        <strong> {moneyFormatter(yTotals.reduce((acc, t) => acc + t, 0) || xTotals.reduce((acc, t) => acc + t, 0), 1)} </strong>
                                    }
                                </td>
                                : null}
                        </tr>
                        : null}

                    </tbody>

                </Table>
            </div>

        </div>
    );
};

export default PurchaseProductsReport;

const availableFilters = [
    {name: 'Área', value: 'area'},
    {name: 'Meses (Creación de solicitud)', value: 'month'},
    {name: 'Meses (Aprobación de solicitud)', value: 'approvedMonth'},
    {name: 'Puesto', value: 'position'},
    {name: 'Solicitante', value: 'requestedBy'},
    {name: 'Proveedor', value: 'provider'},
    {name: 'Línea', value: 'line'},
    {name: 'Producto de línea', value: 'lineProduct'},
    {name: 'Producto de no línea', value: 'notLineProduct'},
];
