import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LoaderIcon from '../../../components/Helper/LoaderIcon';
import Button from '../../../elements/CustomButton/CustomButton';
import {Panel} from 'react-bootstrap';


class UploadTimeRegister extends Component{

    static contextTypes = { api: PropTypes.object };
    static LoadingConstants = { UploadingFile: "@Class.UploadTimeRegister.uploading" };

    constructor(props, context){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.resetUpload = this.resetUpload.bind(this);
        this.handleClockChange = this.handleClockChange.bind(this);

        this.apiUpload = context.api.assistanceReportUploads;
        context.api.clocks.get();

        this.state = {
            extensionError: false,
            selectedFile: null
        };

    }

    handleChange = ( files )=>{
        if( files.length === 0) {
            this.setState({
                extensionError: false,
                selectedFile: null
            });
            return;
        }

        const extensions = ["xls","xlsx"];
        const selectedExt = files[0].name.split(".").pop().toLocaleLowerCase();

        if( extensions.indexOf( selectedExt) === -1 ){
            this.setState({
                extensionError: true,
                selectedFile: null
            });
        }
        else{
            this.setState({
                extensionError: false,
                selectedFile: files[0]
            });
        }
    };

    handleUpload(){
        if( this.state.extensionError || !this.state.selectedFile || !this.state.clock ) return;

        console.log(this.state.selectedFile);
        this.apiUpload.create( this.state.clock, UploadTimeRegister.LoadingConstants.UploadingFile, this.state.selectedFile );
    }

    resetUpload(){
        this.setState({
            assReport: undefined
        })
    }

    handleClockChange(e){
        this.setState({clock:e.target.value});
    }

    UNSAFE_componentWillReceiveProps( nextProps ){

        if( nextProps.assistanceReportUploads && nextProps.assistanceReportUploads !== this.props.assistanceReportUploads && nextProps.assistanceReportUploads.length )
            this.setState({
                assReport: nextProps.assistanceReportUploads[nextProps.assistanceReportUploads.length-1]
            })
    }

    renderFileChooser(){

        const badFileExtension = <div className="alert alert-danger">Tipo de archivo incorrecto. Los archivos deben de ser de tipo xls.</div>;
        const loading = this.props.loadingIds[UploadTimeRegister.LoadingConstants.UploadingFile];

        return(
        <div className={this.props.fullWidth?"":"col-md-6"}>

            <p className="instructions">Selecciona el reloj checador al que pertence el archivo:</p>
            <select onChange={this.handleClockChange}>
                <option>Selecciona un reloj</option>
                {this.props.clocks.map((clock)=>(
                    <option value={clock.id} key={clock.id}>{clock.name}</option>
                ))}
            </select>

            <br/><br/>
            <p className="instructions">Selecciona el archivo del reloj checador:</p>
            <div>
                {this.state.extensionError? badFileExtension : null}
            </div>
            <input type="file"
                   onChange={ (e) => this.handleChange(e.target.files) }/>
            <br /><br />
            <Button
                type="button"
                className="btn btn-success"
                onClick={this.handleUpload}
                disabled={ loading }
                >
                { loading? <LoaderIcon />:"Subir!" }
            </Button>
        </div>
        )
    }

    renderFileReport(){

        const rep = this.state.assReport.importResult;

        return (
            <div className="col-md-6">
                <div className="report-stats">Total de registros leídeos: <b>{rep.totalRegisters}</b></div>
                <div className="report-stats">Total de nuevos registros: <b>{rep.totalRegisterInserts}</b></div>
                <hr />
                <div className="report-details">
                    <Panel
                        collapsible
                        header={<span>Mensajes adicionales - {rep.info.length}</span>}>
                        {rep.info.map((info, i)=><span key={i}>{info}<br/></span>)}
                    </Panel>
                </div>
                <div className="report-details">
                    <Panel
                        collapsible
                        header={<span>Advertencias - {rep.warnings.length}</span>}>
                        {rep.warnings.map((warning, i)=><span key={i}>{warning}<br/></span>)}
                    </Panel>
                </div>
                <div className="report-details">
                    <Panel
                        collapsible
                        header={<span>Errores - {rep.errors.length}</span>}>
                        {rep.errors.map((error,i)=><span key={i}>{error}<br/></span>)}
                    </Panel>
                </div>
                <br />
                <Button onClick={this.resetUpload}>
                    Subir otro archivo
                </Button>

            </div>
        );
    }

    render = ()=>{
        const loading = this.props.loadingIds[UploadTimeRegister.LoadingConstants.UploadingFile];
        const uploaded = Boolean(this.state.assReport);

        return (
            <div className="UploadTimeRegister container-fluid main-container">
                <div className="row">
                    <div className={"panel" + (this.props.fullWidth?"":(" col-xs-12 " +(uploaded?"col-lg-12":"col-lg-6 col-lg-push-3")))}>
                        <div className="panel-heading"><h4>Subir registro del reloj checador</h4></div>
                        <div className="panel-body">
                            { uploaded? this.renderFileReport() : this.renderFileChooser()}
                            <div className={this.props.fullWidth?"":"col-md-6"}>
                                <span className="sideIcon"><i className={loading? "pe-7s-alarm fa-spin":"pe-7s-alarm"}/></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps( {api:{assistanceReportUploads, clocks},loadingIds} ){

    clocks = clocks||[];

    return {
        clocks,
        assistanceReportUploads,
        loadingIds
    };
}

export default connect(mapStateToProps)(UploadTimeRegister);