import moment from 'moment';

export const flightTimeFilter = {
    currentHour: moment().hour(),
    currentMinute: moment().minute(),
    //Nota: Dejaremos un retraso de 3 minutos de la hora actual para que no siempre cargue información de 'ultimo minuto'
    getCurrentHourToMinutes: () =>
        (flightTimeFilter.currentHour * 60) + (flightTimeFilter.currentMinute - 5),

    getNextHourToMinutes: () => (flightTimeFilter.currentHour * 60) + 60
};

/**
 * Adds a zero if the number have one character
 * @param {*} num
 * @param {*} size
 */
export const timeZeroAdd = function(num, size=2) {
    let stringNumber = String(num);
    while (stringNumber.length < size) {stringNumber = "0" + stringNumber;}
    return stringNumber;
};

/**
 * Returns a human readable version of departureTime property
 * @param {*} minutes
 */
export const departureTimeConverter = minutes => {
    if(!minutes)
        throw new Error('"minutes" param are required');

    let hours = Math.floor(minutes / 60);
    let mins = (minutes % 60);
    let timePrefix = 'AM';

    if(hours >= 12) timePrefix = 'PM';
    if(hours > 12) hours -= 12;

    return `${timeZeroAdd(hours)}:${timeZeroAdd(mins)} ${timePrefix}`;
}

/**
 * Returns a human readable version of status property
 * @param {*} status
 */
export const flightStatusConverter = (status = "Sin información") => {
    const currentStatus = status.toLowerCase();

    switch(currentStatus){
        case 'inair':
            return 'Despegó / Departed';
        case 'delayed':
            return "Demorado / Delayed";
        case 'scheduled':
            return 'A tiempo / On time';
        default:
            return status;
    }
}

export const flightLabelCreator = (flight = {}) => {
        const airlineName= flight?.airline?.name || 'Aerolínea desconocida';
        const destination= flight?.destination || 'Destino desconocido';
        const number= flight?.number || 'Número de vuelo desconocido';

    return `${airlineName} - ${destination} - ${number}`;
};
