import { SAVE_REACT_TABLE_STATE } from '../actions/types';

const initialState = {};

export default function( state = initialState, action ) {

    if(action.type!==SAVE_REACT_TABLE_STATE)
        return state;

    return {...state, [action.payload.tableId]:action.payload.state};
}
