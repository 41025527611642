import { useState } from "react";

const useNumberInput = (initialValue='') => {

    const numberOrNull = ( val )=>{

        const numVal = Number( val );

        if( isNaN( numVal ) )
            return null;
        else
            return numVal;
    };

    const changeValue = ( val )=>{
        setInputValue( val );
        setValue( numberOrNull( val ) )
    };

    const [inputValue, setInputValue] = useState(initialValue);
    const [value, setValue] = useState(numberOrNull(initialValue));

    return {
        value,
        setValue: changeValue,
        reset: () => changeValue(""),
        bind: {
            value: inputValue,
            onChange: event => changeValue(event.target.value),
            type: 'number'
        }
    }
};

export default useNumberInput;
