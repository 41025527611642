import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import moment from 'moment';
import FileViewer from '../../../components/FileViewer/FileViewer';
import _ from 'lodash';
import Button from "../../../elements/CustomButton/CustomButton";
import PropTypes from 'prop-types';
import {UserDetailLoadingId, UserDetailApiProp} from "../Employees/core/UserDetail";
import Rotable from '../../../components/Rotable/Rotable';
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";


export default class IncidenceJustificationDetail extends Component {

    static defaultProps = {canApprove:true};
    static contextTypes = {api:PropTypes.object};

    constructor(props){
        super(props);

        this.state = {
            supervisorComments: props.incidenceJustification.responseComment || "",
            rotations: {}
        };
        this.handleCommentsChange = this.handleCommentsChange.bind(this);
        this.showUserDetail = this.showUserDetail.bind(this);
        this.didApprove = this.didApprove.bind(this);
        this.didReject = this.didReject.bind(this);
        this.didDelete = this.didDelete.bind(this);
    }

    handleCommentsChange( e ){
        this.setState({supervisorComments:e.target.value});
    }

    didApprove(){

        const newIncJus = Object.assign( {},this.props.incidenceJustification );
        newIncJus.responseComment =  this.state.supervisorComments;
        newIncJus.approved = true;
        this.props.onChange(newIncJus);

    }

    didReject(){

        const newIncJus = Object.assign( {},this.props.incidenceJustification );
        newIncJus.responseComment =  this.state.supervisorComments;
        newIncJus.approved = false;
        this.props.onChange(newIncJus);
    }

    didDelete(){
        this.props.onDelete( this.props.incidenceJustification );
    }

    showUserDetail( ){
        this.context.api.users.get(0,1,UserDetailLoadingId,{employee:this.props.incidenceJustification.requestedBy.id},UserDetailApiProp );
    }


    rotateFile( index ){

        const rotations = { ...this.state.rotations };

        if( !rotations[index] ) {
            rotations[index] = Rotable.rotation90;
            this.setState({rotations})
        }
        else if( rotations[index] === Rotable.rotation90 ){
            rotations[index] = Rotable.rotation180;
            this.setState({rotations})
        }
        else if( rotations[index] === Rotable.rotation180 ){
            rotations[index] = Rotable.rotation270;
            this.setState({rotations})
        }
        else if( rotations[index] === Rotable.rotation270 ){
            rotations[index] = Rotable.rotation0;
            this.setState({rotations})
        }

    }

    render() {

        const {incidenceJustification: incJus, urlCreator, onHide} = this.props;

        const plural = incJus.appliedIncidences.length > 1 ? "s" : "";
        const oldIncidence = incJus.appliedIncidences.length?incJus.appliedIncidences[0].incidence:null;

        return (
            <Modal
                show={true}
                bsSize="lg"
                onHide={onHide}
                className="IncidenceJustificationDetail"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Justificante de  <span className="span-but" onClick={this.showUserDetail}>{employeeFullName(incJus.requestedBy)}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {incJus.appliedIncidences.length?
                        <p>
                            {oldIncidence.name}{plural} del {plural ? <span>: <br/></span> : ""}
                            {incJus.appliedIncidences.map((appInc) =>
                                <span key={appInc.id}>{_.startCase(moment(appInc.shiftDate).format("dddd, DD [de] MMMM"))}<br/></span>)}
                        </p>
                        :
                        <p>
                            La incidencia justificada ya no existe. Esto puede deberse a una correción que hace el sistema
                            cuando tiene información nueva para el calculo de incidencias. Por ejemplo, si cargaron un nuevo
                            reporte del reloj checador o cambiaron tu horario o día de descanso.
                        </p>}
                    <p>Tipo de justificante: <b>{incJus.newIncidence.name}</b></p>
                    <p>Creación de solicitud: {moment(incJus.createdDate).format("dddd, DD [de] MMMM [a las] HH:mm")}</p>
                    <p>Comentarios de la solicitud: <br/></p>
                    <pre>{incJus.requestComment ? incJus.requestComment : "Sin comentarios."}</pre>

                    {incJus.files && incJus.files.length && this.props.urlCreator ?

                        incJus.files.map((file,i)=>
                        <div key={file.id}>
                            <Rotable rotation={this.state.rotations[i]}>
                                <FileViewer
                                    name={file.originalName}
                                    src={urlCreator(file)}
                                />
                            </Rotable><br/>
                            <Button onClick={()=>this.rotateFile(i)}>Rotar imagen <i className='fa fa-repeat'/></Button>
                        </div>) :
                        <p>No se adjunto ningún archivo.</p>
                    }
                    <p>Comentarios del supervisor:</p>
                    {this.props.canApprove && (incJus.approved === null || this.state.editingComments)?
                        <textarea
                            className="form-control"
                            placeholder="Agrega tus comentarios"
                            value={this.state.supervisorComments}
                            onChange={this.handleCommentsChange}
                        />:
                        incJus.responseComment?
                            <pre onDoubleClick={()=>this.setState({editingComments: true})} >{incJus.responseComment}</pre>:
                            <pre onDoubleClick={()=>this.setState({editingComments: true})} >Sin comentarios.</pre>
                    }
                    {incJus.reviewedBy?
                        <p>Revisado por {employeeFullName(incJus.reviewedBy)}</p>
                    :null}


                </Modal.Body>

                {this.props.canApprove?

                    <Modal.Footer>
                        { incJus.approved === null || incJus.approved?
                        <Button style={{float: "left"}} bsStyle="danger" onClick={this.didReject}>Rechazar</Button>:null}
                        { incJus.approved === null || !incJus.approved?
                        <Button bsStyle="success" onClick={this.didApprove}>Aprobar</Button>:null}
                    </Modal.Footer>
                    :null}

                {this.props.onDelete && incJus.approved === null?
                    <Modal.Footer>
                        <Button bsStyle="danger" onClick={this.didDelete}>Eliminar</Button>
                    </Modal.Footer>
                    :null}

            </Modal>
        );
    }
}
