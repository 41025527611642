import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Card from '../../../../components/Card/Card';
import ReactEcharts from 'echarts-for-react';
import {connect} from "react-redux";

class LoungeCostGraph extends Component {

    getPayrollLoungesOptions(){

        let lounges = [];
        let data = [];
        let max = 0;
        let min = 999999;

        if( this.props.payrollDetails ){

            for( let i=0; i < this.props.payrollDetails.length ; i++ ){

                const pd = this.props.payrollDetails[i];
                if( pd.salary === 0 || !pd.employee.lounge )
                    continue;
                const lounge = pd.employee.lounge.name;
                const index = lounges.indexOf(lounge);

                if( index === -1 ){
                    lounges.push(lounge);
                    data.push({name:lounge, value:pd.total});
                }
                else{
                    data[index].value += pd.total;
                }
            }

            data.forEach(( val )=>{
                val.value = val.value / 100;
                if( val.value > max )
                    max = val.value;
                if( val.value < min )
                    min = val.value;
            });

            data.sort(function (a, b) { return a.value - b.value; });
        }

        return {
            backgroundColor:'#f5f5f5',

            visualMap: {
                show: false,
                min: min*0.8,
                max: max*1.2,
                inRange: {
                    colorLightness: [0, 1]
                }
            },
            tooltip : {
                trigger: 'item'
            },
            legend: {
                data: lounges
            },
            series : [
                {
                    name:'Costo quincenal:',
                    type:'pie',
                    radius : '55%',
                    center: ['50%', '50%'],
                    data:data,
                    roseType: 'radius',
                    label: {
                        normal: {
                            textStyle: {
                                color: 'rgba(20, 20, 20, 1)'
                            }
                        }
                    },
                    labelLine: {
                        normal: {
                            lineStyle: {
                                color: 'rgba(20, 20, 20, 1)'
                            },
                            smooth: 0.2,
                            length: 10,
                            length2: 20
                        }
                    },
                    itemStyle: {
                        normal: {
                            color: '#c23531',
                            shadowBlur: 100,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },

                    animationType: 'scale',
                    animationEasing: 'elasticOut',
                    animationDelay: function (idx) {
                        return Math.random() * 200;
                    }
                }
            ]
        }
    }

    render() {

        return (
            <Card
                title="Costo por sala"
                category="Última quincena"
                content={
                    <ReactEcharts
                        option={this.getPayrollLoungesOptions()}
                        style={{height:"460px"}}
                    />
                }
                stats={
                    <div>
                        <NavLink to="/alma/payroll/list"><i className="fa fa-clock-o" /> Ir a historial</NavLink>
                    </div>
                }
            />
        );
    }
}

function mapStateToProps( {api:{payrollDetails}} ){

    return {payrollDetails}
}

export default connect(mapStateToProps)(LoungeCostGraph);
