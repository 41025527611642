import {useState} from 'react';
import moment from "moment";

const returnFalse=()=>false;
export default (initialValue=moment())=>{

    const [date, setDate]=useState(initialValue);
    const [focused, setFocused]=useState({focused:false});

    return {
        props:{
            date: date,
            id:"very_uniq_very wow",
            onDateChange:setDate,
            focused:focused.focused,
            onFocusChange:setFocused,
            numberOfMonths:1,
            isOutsideRange:returnFalse
        },
        date
    };
};
