import React, {Component} from 'react';
import {Col, Modal, Nav, NavItem, Row, Tab} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Card from '../../../components/Card/Card';
import ReactTable from 'react-table';
import PersonnelRequisitionModal from './components/PersonnelRequisitionModal';
import PersonnelRequisitionForm from './components/PersonnelRequisitionForm';
import Button from "../../../elements/CustomButton/CustomButton";
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import moment from 'moment';
import EmployeeForm from "../Employees/core/EmployeeForm";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";


export const types = {
    ACTIVE:'ACTIVE',
    PENDING:'PENDING',
    FULFILLED:'FULFILLED',
    DENIED:'DENIED',
};

class PersonnelRequisitions extends Component{

    static contextTypes = { api:PropTypes.object, notifier:PropTypes.object };

    loadingId = "@Class.PersonnelRequisition.get";

    constructor( props, context ){
        super(props);

        const hash = props.location.hash.replace( '#', '' );
        this.state = {
            type : types[ hash ]? types[ hash ] : types.ACTIVE
        };

        //Api calls for the Form
        context.api.positions.get();
        context.api.areas.get();
        context.api.lounges.get();

    }

    loadRequis = ( tableState )=>{

        this.tableState = tableState;

        this.sort = tableState.sorted.reduce( (acc, val)=>{acc[`order[${val.id}]`] = val.desc?"DESC":"ASC"; return acc;}, {} );
        this.filter = tableState.filtered.reduce( (acc, val)=>{acc[val.id] = val.value; return acc;}, {} );

        if (this.state.type === types.ACTIVE) {
            this.filter.isApproved = true;
            this.filter['employee[exists]'] = false;

        } else if (this.state.type === types.DENIED)
            this.filter.isApproved = false;

        else if (this.state.type === types.FULFILLED)
            this.filter['employee[exists]'] = true;

        else if (this.state.type === types.PENDING)
            this.filter['isApproved[exists]'] = false;

        this.context.api.personnelRequisitions.get(tableState.page , tableState.pageSize, this.loadingId, {...this.sort, ...this.filter}, 'personnelRequisitions'+this.state.type );
    };

    getColumnsDefinition = ()=>{
        let columns =
            [
            {
                Header:'Solicitante',
                accessor: ( req )=>employeeFullName( req.requestedBy ),
                id: 'requestedBy.name'
            },
            {
                Header:'Puesto',
                accessor:'position.name'
            },
            {
                Header:'Fecha de creación',
                accessor:( req )=>moment(req.createdDate).format('DD/MM/YYYY'),
                id:'createdDate'
            }
        ];

        if( this.state.type === types.ACTIVE || this.state.type === types.PENDING )
            columns.push({
                Header:'Fecha a cubrir',
                accessor:( req )=>moment(req.fulfillDate).format('DD/MM/YYYY'),
                id:'fulfillDate'
            });

        else if ( this.state.type === types.FULFILLED )
            columns = columns.concat([{
                Header:'Nuevo colaborador',
                accessor:( req )=>req.employee? employeeFullName(req.employee):'',
                id:'employee.name'
            },{
                Header:'Fecha de contratación',
                accessor:( req )=>req.employee? moment(req.employee.hireDate).format('DD/MM/YYYY'):'',
                id:'employee.hireDate'
            }
            ]);

        return columns;
    };

    onCreate = ( requisition )=>{
        this.setState({creating:false});
        const createTo = canApproveRoles.indexOf( this.props.me.role.name ) !== -1? types.ACTIVE : types.PENDING;
        this.context.api.personnelRequisitions.create( requisition, undefined, 'personnelRequisitions'+createTo );
        this.onTypeChange( createTo );
    };

    showRequisitionDetail = ( requisition )=>{
        this.setState( {showDetail: requisition })
    };

    onTypeChange = (type)=>{
        this.setState({type},
            ()=>this.loadRequis( this.tableState ) );
    };

    onReject = ( requisition, rejectionComment )=>{
        this.setState( {showDetail:false} );
        this.context.api.personnelRequisitions.edit( requisition.id, false, rejectionComment, 'personnelRequisitions'+this.state.type )
            .then( ()=>this.loadRequis( this.tableState ) );
    };

    onApprove = ( requisition )=>{
        this.setState( {showDetail:false} );
        this.context.api.personnelRequisitions.edit( requisition.id, true )
            .then( ()=>this.loadRequis( this.tableState ) );
    };

    onDelete = ( requisition )=>{
        this.setState( {showDetail: false });
        this.context.api.personnelRequisitions.delete( requisition.id )
            .then( ()=>this.loadRequis( this.tableState ) );
    };

    showHireEmployeeForm = ( requisition, applicant )=>{

        const user = {
            employee:{
                personnelRequisition: {id:requisition.id},
                position: requisition.position,
                area: requisition.area,
                lounge: requisition.lounge,
                daysOff: requisition.daysOff,
                hireDate: moment().format('YYYY-MM-DD')
            }
        };

        if( applicant ) {

            const {email, id, ...employee} = applicant;
            employee.applicant = {id};
            user.email = email;
            user.employee = {
                ...user.employee,
                ...employee
            };

        }

        this.setState( {showDetail: false, hireEmployee: user, hiringRequisition: requisition } );
    };

    render() {
        const { totalItems, itemsPerPage } = this.props.api['personnelRequisitions'+this.state.type+'Meta'] || { totalItems:0, itemsPerPage:0 } ;
        const personnelRequisitions = this.props.api['personnelRequisitions'+this.state.type] || [];
        const plural = totalItems>1?"s":"";

        return (
            <div className="container-fluid main-container PersonnelRequisitions">

                <Card
                    content={(
                        <div>
                            <Tab.Container id="tabs-with-dropdown" activeKey={this.state.type} onSelect={this.onTypeChange}>
                                <Row className="clearfix">
                                    <Col sm={12}>
                                        <Nav bsStyle="tabs">
                                            <NavItem eventKey={types.ACTIVE}>
                                                <i className="fa fa-user" /> Vacantes activas
                                            </NavItem>
                                            <NavItem eventKey={types.PENDING}>
                                                <i className="fa fa-question-circle-o" /> Solicitudes pendientes
                                            </NavItem>
                                            <NavItem eventKey={types.FULFILLED}>
                                                <i className="fa fa-id-card-o" /> Contrataciones
                                            </NavItem>
                                            <NavItem eventKey={types.DENIED}>
                                                <i className="fa fa-window-close-o" /> Solicitudes rechazadas
                                            </NavItem>
                                        </Nav>
                                    </Col>
                                </Row>
                            </Tab.Container>

                            <ReactTable
                                columns={ this.getColumnsDefinition() }
                                data = { personnelRequisitions }
                                minRows={3}
                                pages= { Math.ceil( totalItems / itemsPerPage ) }
                                manual
                                sortable
                                onFetchData={this.loadRequis}
                                className="-striped -highlight"
                                loading={!!this.props.loadingIds[this.loadingId]}
                                getTdProps={(state, rowInfo)=>({onClick:()=> rowInfo && this.showRequisitionDetail(rowInfo.original)})}
                                {...ReactTableTranslations}

                            />

                            <p className="total-count pull-left">{totalItems} registro{plural} encontrado{plural}</p>
                            <Button bsStyle='primary' className='pull-right new-requisition' onClick={()=>this.setState({creating:true})}>Crear nueva solicitud</Button>
                            <div className='clearfix' />
                        </div>
                    )} />

                {this.state.showDetail?
                <PersonnelRequisitionModal
                    requisition={ this.state.showDetail }
                    onHide={()=>this.setState( {showDetail:false} ) }
                    canApprove={ canApproveRoles.indexOf( this.props.me.role.name ) !== -1 }
                    canHire={ canHireRoles.indexOf( this.props.me.role.name ) !== -1 }
                    onReject={this.onReject}
                    onApprove={this.onApprove}
                    onHire={ this.showHireEmployeeForm }
                    onDelete={ this.onDelete }
                />
                    :null}

                {this.state.creating?
                <PersonnelRequisitionForm
                    onHide={()=>this.setState({creating:false})}
                    onCreate={ this.onCreate }
                    canApprove={ canApproveRoles.indexOf( this.props.me.role.name ) !== -1 }
                />
                    :null}

                {this.state.hireEmployee?
                    <Modal
                        onHide={()=>this.setState({hireEmployee:false})}
                        show={true}
                        bsSize="lg">
                        <Modal.Header closeButton>
                            <Modal.Title>Agregar empleado</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <EmployeeForm
                                isEditing={false}
                                initialValues={ EmployeeForm.userToFormValues(this.state.hireEmployee) }
                                history={this.props.history}
                            />

                        </Modal.Body>

                        <Modal.Footer/>
                    </Modal>:null}

            </div>
        );
    }
}

function mapStateToProps( {api, loadingIds} ){//{api:{ me, personnelRequisitions=[], personnelRequisitionsMeta={totalItems:0,itemsPerPage:0} }, loadingIds} ) {

    return { me:api.me, api, loadingIds };
}

export default connect(mapStateToProps)(PersonnelRequisitions);

const canApproveRoles = [ 'DIRECTOR' ];
const canHireRoles = [ 'RECURSOS_HUMANOS', 'DIRECTOR' ];
