import React from 'react';

export const timeString = m=>(
    (hours, minutes)=>
        `${hours<10?'0'+hours: hours}:${minutes<10?'0'+minutes: minutes}`)(Math.floor(m/60), m%60);

const MinuteTimePicker=({value, onChange})=>{
    const handleChangeButton=toAdd=>()=>onChange(((value+toAdd)%1440+1440)%1440);

    return (
        <div className='TimePicker'>
            <div>
                <button type="button" onClick={handleChangeButton(60)}>+</button>
                <button type="button" onClick={handleChangeButton(1)}>+</button>
            </div>
            <div className='time-display'>
                {value ? timeString(value): '00:00'}
            </div>
            <div>
                <button type="button" onClick={handleChangeButton(-60)}>-</button>
                <button type="button" onClick={handleChangeButton(-1)}>-</button>
            </div>
        </div>
    );
};

export default MinuteTimePicker;
