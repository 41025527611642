import React, {Component} from 'react';
import {FormControl} from "react-bootstrap";
import TagsInput from "react-tagsinput";
import {SortableElement} from "react-sortable-hoc";
import AdvancedQuestionConfigurator from "./AdvancedQuestionConfigurator";


class MultipleChoice extends Component{

    state = { showingConfigurator: false };

    onChange = ( tags )=>{

        const question = {...this.props.question};
        question.config = { ...question.config, options:tags};

        if( !question.config.uid ) question.config.uid = Math.random().toString(10);

        this.props.onChange( question );
    };

    onTitleChange = (e)=>{
        const question = {...this.props.question, text: e.target.value};
        this.props.onChange( question );
    };

    handleRemove = ()=>{
        if( !this.props.onRemove ) return;

        this.props.onRemove( this.props.question );
    };

    toggleConfigurator = ()=>{
        this.setState({showingConfigurator: !this.state.showingConfigurator });
    };


    onConfigChange = ( config )=>{

        const question = {...this.props.question, config};
        this.props.onChange( question, question.config.order );
    };

    render() {

        const options = this.props.question.config && this.props.question.config.options? this.props.question.config.options : [];
        const isInactive = this.props.question.config && this.props.question.config.inactive;

        return (

            <div className={'question-wrapper ' +(isInactive?'inactive-question':'')}>
                <div className='left-handle'>
                    <span>{this.props.question.config.order+1}</span>
                    <span className='thrash-container'>
                        <i onClick={this.handleRemove} className='fa fa-trash'/>
                    </span>
                </div>
                <div className='right-content'>
                    <FormControl
                        type='text'
                        placeholder='Título de la pregunta'
                        value={this.props.question.text}
                        onChange={this.onTitleChange}
                    />
                    <p className='text-muted small question-type-subtitle'>{this.props.definition.title}</p>
                    <TagsInput
                        value={options}
                        onChange={this.onChange}
                        tagProps={{className: 'react-tagsinput-tag tag-fill tag-azure'}}
                        inputProps={{placeholder: 'Agregar respuesta'}}
                    />

                    <p><span className='link small' onClick={this.toggleConfigurator}><i className='fa fa-cogs' /> Configuración avanzada</span></p>
                    {this.state.showingConfigurator?
                        <AdvancedQuestionConfigurator
                            question={this.props.question}
                            questionnaire={this.props.questionnaire}
                            onChange={this.onConfigChange}
                        />
                        :null}

                </div>
            </div>
        );
    }
}

MultipleChoice = SortableElement(MultipleChoice);

MultipleChoice.initialConfig = ( order )=>{
    return {
        options: [],
        uid: Math.random().toString(10),
        required: true,
        order
    }
};

MultipleChoice.validate = (question)=>{

    if( !question.text || !question.text.trim() )
        return {error:true, message:'El título de la pregunta está vacío'};

    if( !question.config || !question.config.options || question.config.options.length < 2 )
        return {error:true, message:'La pregunta debe tener al menos dos posibles respuestas'};

    return {success:true};
};

export default MultipleChoice;
