import React, {useState, useCallback, useContext} from 'react';
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import useInput from '../../../../hooks/useInput';
import {ApiContext} from "../../../../api/Api";
import {connect} from "react-redux";
import {NotifierContext} from "../../../../utils/Notifier";


const typesAvailableFor = [ 'multiple-choice-radio', 'lounge' ];
const loadingId = '@HistoryEdition.answers';

const HistoryEdition = ( {question, loadingIds} )=>{

    const [ modalShown, setModalShown ] = useState(false);
    const [ oldResponse, setOldResponse ] = useState(null);
    const [ oldCount, setOldCount ] = useState(null);
    const [ newCount, setNewCount ] = useState(null);
    const [ step, setStep ] = useState(0);
    const responseIn = useInput('');

    const api = useContext( ApiContext );
    const notifier = useContext( NotifierContext );

    const showModal = useCallback( ()=>setModalShown(true), [] );
    const hideModal = useCallback( ()=>{
        setOldCount( null );
        setOldResponse( null );
        setModalShown( false );
        setStep(0);
    }, [] );

    if( typesAvailableFor.indexOf( question.type ) === -1 )
        return null;

    const countAnswers = ()=>{

        api.answers.countWithQuestionsFilter( { question: question.id, response: responseIn.value }, loadingId )
            .then(( count )=> {
                setOldResponse( responseIn.value );
                setOldCount(count);
                responseIn.setValue('');
                setStep(1);
            });

    };

    const reset = ()=>{
        setOldResponse( '' );
        responseIn.setValue( '' );
        setOldCount(null);
        setStep(0);
    };

    const changeAnswer = ()=>{
        if( !responseIn.value )
            return notifier.error('Ingresa la nueva respuesta');

        api.answers.editWithQuestionsFilter( { oldResponse, newResponse: responseIn.value.trim(), question: question.id }, loadingId )
            .then( ( count )=>{
                setNewCount( count );
                setStep( 2 );
            } );
    };

    return (
        <div className='HistoryEdition'>
            <CustomButton
                className='pull-right'
                bsStyle='danger'
                onClick={showModal}
            >
                Edición histórica de respuestas
            </CustomButton>

            <div className='clearfix'/>

            {modalShown?
                <Modal
                    show={true}
                    onHide={ hideModal }
                >
                    <ModalHeader><ModalTitle>
                        Edición histórica de respuestas
                    </ModalTitle></ModalHeader>
                    <ModalBody>
                        {(()=>{
                            switch( step ){
                                case 0:
                                    return <>
                                        <div className='alert alert-danger'>
                                            <h1 className='text-center'>¡Advertencia!</h1>
                                            <p>
                                                Esta acción podría destruir la información histórica de esta pregunta de forma irreversible.
                                                Prosigue solo si estás seguro de saber utilizar esta funcionalidad.
                                            </p>
                                        </div>

                                        <hr/>
                                        <p>Primero vamos a buscar todas las respuestas que quieres cambiar:</p>
                                        <p className='text-muted small'>Solo vamos a contar, aún no llegas a la parte irreversible</p>
                                        <input {...responseIn.bind} placeholder='Respuesta antigua' className='form-control'/>
                                    </>;

                                case 1:
                                    return <>
                                        { oldCount?
                                            <>
                                                <p>Se encontró {oldCount} veces la respuesta "{oldResponse}" <br/> Indica la respuesta por la que la quieres cambiar:</p>
                                                <input {...responseIn.bind} placeholder='Respuesta nueva' className='form-control'/>
                                            </>
                                            :
                                            <p>Lo sentimos, no encontramos ninguna coincidencia. Recuerda que la búsqueda distingue mayúsculas y minúsculas</p>
                                        }
                                    </>;
                                case 2:
                                    return <p>Se convirtieron {newCount} respuestas de {oldResponse} a {responseIn.value} </p>;

                                default:
                                    return <p>Error</p>
                            }
                        })()}

                    </ModalBody>
                    <ModalFooter>
                        <CustomButton
                            bsStyle='primary'
                            onClick={hideModal}
                            className='pull-left'
                        >
                            {step===2? 'Cerrar':'Cancelar'}
                        </CustomButton>

                        {(()=>{
                            switch( step ) {
                                case 0:
                                    return <CustomButton bsStyle='primary' disabled={!!loadingIds[loadingId]}
                                                         onClick={countAnswers}>Buscar</CustomButton>;
                                case 1:
                                    return <CustomButton bsStyle='primary' disabled={!!loadingIds[loadingId]}
                                                         onClick={oldCount? changeAnswer: reset}>{ oldCount? 'Cambiar respuestas':'Volver a buscar' }</CustomButton>;
                                default:
                                    return null;
                            }})()}

                    </ModalFooter>
                </Modal>
            :null}
        </div>
    );
};


const mapStateToProps = ( { loadingIds } )=>( { loadingIds } );

export default connect(mapStateToProps)(HistoryEdition);
