import React, {useContext, useEffect} from 'react';
import TimeGroupedGcareStat from '../../TimeGroupedGcareStat';
import {ApiContext} from '../../../../../api/Api';
import {useSelector} from 'react-redux';

const ExcellenceReport = ({gcareType, dateRequest, loungeAccessMethod:lam})=>{
    const api = useContext(ApiContext);
    useEffect(()=>{
        api.answers.gcareEvolutionReport({
            params: {
                ...dateRequest,
                ...(lam?{visitLoungeAccessMethods: [lam]}:{})
            }
        });
    }, [api, lam, dateRequest]);
    const report = useSelector(({api})=>api.gcareEvolutionReport||[]);
    return <TimeGroupedGcareStat
               title='Excelencia Operativa'
               report={report}
               gcareType={gcareType}
           />;
};

export default ExcellenceReport;
