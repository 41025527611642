import React from 'react';
import crashImg from '../assets/img/core/bug.png';
import CustomButton from "../elements/CustomButton/CustomButton";
import LoaderIcon from "../components/Helper/LoaderIcon";
import alma from '../assets/img/tgle-alma-dark.png';


const CrashView = ( { sending } )=>{

    return (
        <div className='CrashView'>
            <div className='center-wrapper'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-xs-12 col-md-7'>
                            <img src={alma} alt={'alma'} className='logo-alma' />
                            <h1>Ooops!</h1>
                            <p className='p1'>Sucedió un error en la aplicación.</p>
                            {sending ?
                                <p className='p2'><LoaderIcon/> Recopilando detalles.</p> :
                                <p className='p2'>Los detalles han sido enviados a un programador para su análisis.</p>
                            }
                            <p className='p3'>Regresa al inicio dando click aquí abajo</p>
                            <a href={'/'}><CustomButton>Regresar al inicio</CustomButton></a>
                        </div>
                        <div className='col-xs-12 col-md-5'>
                            <hr className='visible-xs' />
                            <img src={crashImg} alt='crash' className='crash-img'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CrashView;
