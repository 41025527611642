import _ from 'lodash/fp';

export const getGcareQuestionType = (type,report)=>
      _.find(q=>q.questionConfig.gcareType===type, report.result);

const startAngle = 190;
const endAngle = -10;
export const getGaugeOption = (d, ps, pm)=>({
    angleAxis: {
        startAngle,
        min: 0,
        max: 360*(d+ps+pm)/Math.abs(endAngle-startAngle),
        axisLine: {
            show: false
        },
        axisTick: {show: false},
        axisLabel: {show: false},
        splitLine: {show: false}
    },
    radiusAxis: {
        type: 'category',
        data: [''],
        axisLine: {
            show: false
        }
    },
    legend: {
    },
    polar: {
    },
    tooltip: {
    },
    series: [
        {
            type: 'bar',
            data:[{
                value: d,
            }],
            itemStyle: {color: '#990a00'},
            coordinateSystem: 'polar',
            stack: 'x',
            name: 'Detractores',
        },
        {
            type: 'bar',
            data:[{
                value: ps,
            }],
            itemStyle: {color: '#e3c800'},
            coordinateSystem: 'polar',
            stack: 'x',
            name: 'Pasivos',
        },
        {
            type: 'bar',
            data:[{
                value: pm,
            }],
            itemStyle: {color: '#009623'},
            coordinateSystem: 'polar',
            stack: 'x',
            name: 'Promotores',
        }
    ]
});

