import React, {useCallback} from 'react';
import PurchaseProductQuoteRow from "./PurchaseProductQuoteRow";
import PurchaseProductNoQuoteRow from "./PurchaseProductNoQuoteRow";
import PurchaseProductRowSpacer from "./PurchaseProductRowSpacer";


const PurchaseProductRow = ( {productTotal, stockRequestAmount, purchaseRequisition, purchaseProduct, onChange, isOpen, onHoverChange, index, hasDiscount, currency} )=>{

    const handleHoverChange = useCallback(( hovered )=>{
        onHoverChange( hovered? index : -1 );
    }, [ index, onHoverChange ] );

    const handleMakeFirst = useCallback( (quoteIndex)=>{
        const purchaseProductQuotes = [ ...purchaseProduct.purchaseProductQuotes ];
        const temp = purchaseProductQuotes[quoteIndex];
        purchaseProductQuotes[quoteIndex] = purchaseProductQuotes[0];
        purchaseProductQuotes[0] = temp;

        onChange({ ...purchaseProduct, purchaseProductQuotes }, index);
    }, [onChange, purchaseProduct, index] );

    const rows = purchaseProduct.purchaseProductQuotes.map( (purchaseProductQuote, i)=>
        <PurchaseProductQuoteRow
	    productTotal={productTotal}
	    stockRequestAmount={stockRequestAmount}
            purchaseRequisition={purchaseRequisition}
            purchaseProduct={purchaseProduct}
            purchaseProductQuote={purchaseProductQuote}
            ppIndex={ index }
            quoteIndex={ i }
            onHoverChange={ handleHoverChange }
            isOpen={ isOpen }
            hasDiscount={hasDiscount}
            currency={ currency }
            onMakeFirst={ handleMakeFirst }
            key={i}
        />);

    if( !rows.length )
        rows.push( <PurchaseProductNoQuoteRow
            key='no-quote'
            purchaseProduct={purchaseProduct}
            ppIndex={ index }
        /> );
    else if(rows.length>1)
        rows.push( <PurchaseProductRowSpacer
            onHoverChange={ handleHoverChange } isOpen={ isOpen } hasDiscount={hasDiscount} key={'spacer'}
        /> );

    return rows;

};


export default PurchaseProductRow;
