import React, {useCallback, useContext} from 'react';
import './_SingleWarehouseConfig.scss';
import {TideApiContext} from "../../../../../api/tideApiConfig";
import classNames from 'classnames';
import {warehouseConfigurationSGroups} from "../../../../../utils/modelUtils/almazen/warehouseUtils";
import useBoolean from "../../../../../hooks/useBoolean";
import {Link} from "react-router-dom";
import {paths} from "../../../../../routes/almazen";
import WarehousePermissionsTable from "../WarehousePermissionsTable/WarehousePermissionsTable";
import LoungeWarehousesTable from "../LoungeWarehousesTable/LoungeWarehousesTable";
import WarehouseNotifyTable from '../WarehouseNotifyTable/WarehouseNotifyTable';

const SingleWarehouseConfig = ({warehouse:wh}) => {

    const api = useContext(TideApiContext);

    const handleDeactivateWarehouse = useCallback(()=>{
        api.warehouses.update({id:wh.id, params:{isActive: !wh.isActive, sGroups: warehouseConfigurationSGroups }})
    },[api, wh]);

    const [showingPermissions, ,,togglePermissions] = useBoolean();
    const [showingNotifPermissions, ,,toggleNotifPermissions] = useBoolean();
    const [showingLounges, ,,toggleLounges] = useBoolean();

    return (
        <div className={"SingleWarehouseConfig"}>
            <p className={classNames('wh-name', !wh.isActive&&'inactive')}>{wh.name} {!wh.isActive && '(inactivo)'}</p>
            <div className='config'>
                <button className='btn btn-info' onClick={togglePermissions}>{showingPermissions?"Ocultar":"Ver"} permisos de usuarios</button>
                <button className='btn btn-info' onClick={toggleNotifPermissions}>{showingNotifPermissions?"Ocultar":"Ver"} usuarios a notificar</button>
                <button className='btn btn-info' onClick={toggleLounges}>{showingLounges?"Ocultar":"Ver"} salas asignadas</button>
                <Link to={paths.inventory.replace(':id', wh.id)} >
                    <button className='btn btn-info'>Ver inventario</button>
                </Link>
                <Link to={paths.inventoryMovements.replace(':warehouse', wh.id)} >
                    <button className='btn btn-info'>Ver historial de movimientos</button>
                </Link>
                <button className={classNames('btn',wh.isActive?'btn-danger':'btn-info')} onClick={handleDeactivateWarehouse}>
                    {wh.isActive?'Desactivar':'Activar'} almacén
                </button>
            </div>
            {showingPermissions && <WarehousePermissionsTable warehouse={wh}/>}
            {showingNotifPermissions && <WarehouseNotifyTable warehouse={wh}/>}
            {showingLounges && <LoungeWarehousesTable warehouse={wh}/>}
        </div>
    );
};

export default SingleWarehouseConfig;
