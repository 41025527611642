import React,{useContext, useEffect, useMemo} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import {percentageString} from "../../../../utils/MathUtils";
import DateRangeFilter, {useDateRangeFilter} from '../Filters/DateRangeFilter';
import _ from 'lodash/fp';

const getLoungesFromReport = _.flow(
    _.uniqBy('loungeId'),
    _.map(_.pick(['loungeId', 'loungeName']))
);

const getCountCols = (report, lounges)=>{
    return ({airlineId, airlineName})=>{
        const getLoungesEntries = ({loungeId})=>_.head(
            _.filter({airlineId, loungeId}, report)
        )||{loungeId, count: 0};
        return {
            airlineId,
            airlineName,
            loungesCount: _.map(getLoungesEntries, lounges)
        };
    };
};
const getReportRows = (report, lounges)=>{
    const getCount = getCountCols(report, lounges);
    return _.flow(
        _.uniqBy('airlineId'),
        _.map(getCount)
    )(report);
};
const loadingId='@VisitsAirlinesReport.report';
const VisitsAirlinesReport=({defaultDateRange})=>{
    const dateConfig = useMemo(()=>({initial: defaultDateRange}), [defaultDateRange]);
    const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);
    const api=useContext(ApiContext);
    useEffect(()=>{
        api.visits.airlinesReport({
            params: dateRequest,
            loadingId
        });
    },[api, dateRequest]);

    const report = useSelector(({api})=>api.visitsAirlinesReport)||[];
    const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    const lounges = useMemo(()=>getLoungesFromReport(report), [report]);
    const reportRows = useMemo(()=>getReportRows(report, lounges), [report, lounges]);

    console.log(reportRows);

    const total = useMemo(()=>(report||[]).reduce((count, data)=>count+Number(data.count), 0),[report]);

    return (
        <div className='VisitsAirlinesReport'>
            <DateRangeFilter {...dateFilterProps}/>
            {loading && <h3 className='text-center'>Cargando...</h3>}

            <Table striped>
                <thead>
                <tr>
                    <th>Aerolínea</th>
                    {lounges.map(({loungeId, loungeName})=><th key={loungeId}>{loungeName}</th>)}
                </tr>
                </thead>
                <tbody>
                {reportRows.map((data, i)=>
                    <tr key={i}>
                        <td>{data.airlineName}</td>
                        {(data.loungesCount||[]).map(c=><td key={c.loungeId}>{c?<>{c.count}&nbsp;/&nbsp;{percentageString(Number(c.count)/total)}</>:0}</td>)}
                    </tr>
                )}
                </tbody>
            </Table>

        </div>
    );
};

export default VisitsAirlinesReport;
