import React,{useContext, useEffect, useState, useCallback} from 'react';
import {Link, useParams} from 'react-router-dom';
import {ApiContext} from "../../../api/Api";
import useTideEntity from "../../../hooks/useTideEntity";
import {useSelector} from "react-redux";
import {priceListSGroups} from "../../../utils/modelUtils/almazen/productBrandProvider";
import {productBrandName} from "../../../utils/modelUtils/almazen/productBrandUtils";
import noProduct from '../../../assets/img/no-product.png';
import moneyFormatter from "../../../utils/moneyFormatter";
import {convertSmallestUnitToDisplayUnit, convertToSmallestUnit} from "../../../utils/modelUtils/almazen/unitsUtility";
import moment from 'moment';
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import _ from 'lodash';
import {NotifierContext} from "../../../utils/Notifier";
import CalendarPopUp from "../../../components/Calendar/CalendarPopUp";
import Switch from "react-bootstrap-switch";
import useBoolean from "../../../hooks/useBoolean";
import ProductBrandProviderFormModal from "./components/ProductBrandProviderFormModal";

const createSGroups={sGroups:priceListSGroups};
const ProviderPriceTable=()=>{

    const {id}=useParams();
    const api = useContext(ApiContext);
    const notifier=useContext(NotifierContext);

    const [addingProduct,startAddingProduct,stopAddingProduct]=useBoolean();

    const [provider, loading, notFound]=useTideEntity('providers', id);
    const productBrandProviders=useSelector(({api})=>api.productBrandProviders||[]);

    const [editingVal, setEditingVal]=useState({index:null, type:null});

    const startEditing=useCallbackCreator((info)=>{
        const [index, type]=info.split('|');
        setEditingVal({index, type, value:''});
    },[]);

    const handleEditingChange=useCallback(({target})=>{
        setEditingVal({...editingVal, value: target.value})
    },[editingVal]);

    const saveNewPrice=useCallback(()=>{
        const newPrice=Number(editingVal.value);
        if(!newPrice)
            return notifier.error('Precio inválido');

        const productBrandProvider=_.find(productBrandProviders, pbp=>pbp.id===editingVal.index);
        if(!productBrandProvider)
            return notifier.error('Producto no encontrado. Contacta a soporte técnico');
        const price = convertSmallestUnitToDisplayUnit(newPrice, productBrandProvider.productBrand.product);
        const newProductBrandProvider={id:editingVal.index, [editingVal.type]:price+'', sGroups:priceListSGroups};
        api.productBrandProviders.edit({id:editingVal.index, productBrandProvider: newProductBrandProvider})
            .then(()=>notifier.success('Precio actualizado'));
        setEditingVal({index:null, type:null});
    },[notifier, api, editingVal, productBrandProviders]);

    const stopEditing=useCallback(()=>{
        setEditingVal({index:null, type:null});
    },[]);

    const changeExpirationDate=useCallbackCreator((info, date)=>{
        const [id, type]=info.split('|');
        api.productBrandProviders.edit({id, productBrandProvider: {[type]: date.format('YYYY-MM-DD'), sGroups:priceListSGroups}})
            .then(()=>notifier.success('Fecha de expiración actualizada'));
    },[]);

    const onAvailabilityChange=useCallbackCreator((id, s, value)=>{
        api.productBrandProviders.edit({id, productBrandProvider: {isAvailable: value, sGroups:priceListSGroups}})
            .then(()=>notifier.success('Disponibilidad actualizada'));
    },[]);


    useEffect(()=>{
        api.productBrandProviders.get({filters:{pagination:false, provider:id, sGroups:priceListSGroups}});
    },[api, id]);

    return (
        <div className='ProviderPriceTable container-fluid'>
            <h4 className='text-muted title-desc'>Lista de precios</h4>
            {provider&&<h2 className='title'>{provider.commercialName || provider.name} {!!provider.commercialName && <span className='small-name'>{provider.name}</span>}</h2>}
            {loading&&<h3>Cargando...</h3>}
            {notFound&&<h3>Proveedor no encontrado...</h3>}

            <div className='price-list-table container-fluid'>

                {_.chunk(productBrandProviders,2).map((row, i)=>
                    <div className='row' key={i}>
                        {row.map((productBrandProvider) =>
                            <div className='col-xs-12 col-lg-6' key={productBrandProvider.id}>
                                <div className='product-brand-block'>
                                    {/* ----- Product photo ------ */}
                                    <div
                                        className='product-photo'
                                        style={{
                                            backgroundImage: `url("${
                                                productBrandProvider.productBrand.photo ?
                                                    api.appFiles.getUrl(productBrandProvider.productBrand.photo.id) :
                                                    productBrandProvider.productBrand.product.photo ?
                                                        api.appFiles.getUrl(productBrandProvider.productBrand.product.photo.id) :
                                                        noProduct
                                            }")`
                                        }}/>
                                        <Switch
                                            value={productBrandProvider.isAvailable}
                                            onChange={onAvailabilityChange(productBrandProvider.id)}
                                        />
                                    <p className='name'>{productBrandName(productBrandProvider.productBrand)}</p>
                                    <p className='unit'>{
                                        productBrandProvider.productBrand.product.displayUnit ?
                                            productBrandProvider.productBrand.product.displayUnit.name :
                                            productBrandProvider.productBrand.product.smallestUnit.name
                                    }</p>
                                    {/* ----- List Price ------ */}
                                    <div className='prices'>
                                        <div className='price-block'>
                                            <span className='price-type'>Precio de lista</span><br/>
                                            <span className='price'>
                                                {editingVal.index === productBrandProvider.id && editingVal.type === 'listPrice' ?
                                                <div className='edit-price-block'>
                                                    <input className='edit-price-input' value={editingVal.value} onChange={handleEditingChange}/>{' '}
                                                    <button onClick={saveNewPrice}><i className='fa fa-check green'/></button>
                                                    {' '}
                                                    <button onClick={stopEditing}><i className='fa fa-times red'/></button>
                                                </div> :
                                                <span>
                                                    {productBrandProvider.listPrice ?
                                                        moneyFormatter(convertToSmallestUnit(productBrandProvider.listPrice, productBrandProvider.productBrand.product.displayUnit, productBrandProvider.productBrand.product), 1) :
                                                        'NA'} <i className='fa fa-pencil edit-icon'
                                                                 onClick={startEditing(productBrandProvider.id + '|listPrice')}/>
                                                </span>}
                                            </span><br/>
                                            <span className='expiration'>{productBrandProvider.listPriceExpirationDate?
                                                'Expira '+moment(productBrandProvider.listPriceExpirationDate).format('DD/MM/YYYY')
                                                :'Sin expiración'}{' '}
                                                <CalendarPopUp
                                                    value={moment(productBrandProvider.listPriceExpirationDate)}
                                                    onChange={changeExpirationDate(productBrandProvider.id+'|listPriceExpirationDate')}
                                                >
                                                    <i className='fa fa-calendar expiration-button'/>
                                                </CalendarPopUp>
                                            </span>
                                        </div>
                                        {/* ----- List discount ------ */}
                                        <div className='price-block'>
                                            <span className='price-type'>Descuento de lista</span><br/>
                                            <span className='price'>
                                                {editingVal.index === productBrandProvider.id && editingVal.type === 'listDiscount' ?
                                                    <div className='edit-price-block'>
                                                        <input className='edit-price-input' value={editingVal.value} onChange={handleEditingChange}/>{' '}
                                                        <button onClick={saveNewPrice}><i className='fa fa-check green'/></button>
                                                        {' '}
                                                        <button onClick={stopEditing}><i className='fa fa-times red'/></button>
                                                    </div> :
                                                    <span>
                                                    {productBrandProvider.listDiscount ?
                                                        moneyFormatter(convertToSmallestUnit(productBrandProvider.listDiscount, productBrandProvider.productBrand.product.displayUnit, productBrandProvider.productBrand.product), 1) :
                                                        'NA'} <i className='fa fa-pencil edit-icon'
                                                                 onClick={startEditing(productBrandProvider.id + '|listDiscount')}/>
                                                </span>}
                                            </span><br/>
                                            <span className='expiration'>{productBrandProvider.listDiscountExpirationDate?
                                                'Expira '+moment(productBrandProvider.listDiscountExpirationDate).format('DD/MM/YYYY')
                                                :'Sin expiración'}{' '}
                                                <CalendarPopUp
                                                    value={moment(productBrandProvider.listDiscountExpirationDate)}
                                                    onChange={changeExpirationDate(productBrandProvider.id+'|listDiscountExpirationDate')}
                                                >
                                                    <i className='fa fa-calendar expiration-button'/>
                                                </CalendarPopUp>
                                            </span>
                                        </div>
                                        {/* ----- Last price ------ */}
                                        <div className='price-block'>
                                            <Link to={
                                                productBrandProvider.lastPurchaseProduct && productBrandProvider.lastPurchaseProduct.purchaseOrder ?
                                                    '/compras/orders/' + productBrandProvider.lastPurchaseProduct.purchaseOrder.id :
                                                    '#'}>
                                                <span className='price-type'>Última compra</span><br/>
                                                <span className='price'>{productBrandProvider.lastPrice ?
                                                    moneyFormatter(convertToSmallestUnit(productBrandProvider.lastPrice, productBrandProvider.productBrand.product.displayUnit, productBrandProvider.productBrand.product), 1) :
                                                    'NA'}</span><br/>
                                                {productBrandProvider.lastPurchaseProduct ?
                                                    <span
                                                        className='expiration'>Realizada {moment(productBrandProvider.lastPurchaseProduct).format('DD/MM/YYYY')}</span> :
                                                    <span className='expiration'>Sin registro</span>
                                                }
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className='row'>
                    <div className='col-xs-12 col-lg-6'>
                        <div className='product-brand-block add-block' onClick={startAddingProduct}>
                            <p><i className='fa fa-plus-circle'/> Agregar producto</p>
                        </div>
                    </div>
                </div>
            </div>
            {addingProduct&&
                <ProductBrandProviderFormModal
                    provider={provider}
                    onHide={stopAddingProduct}
                    createAdditionalParameters={createSGroups}
                />}
        </div>
    );
};

export default ProviderPriceTable;
