import {useContext, useState, useMemo, useEffect, useCallback} from 'react';
import {useSelector} from "react-redux";
import {ApiContext} from "../api/Api";
import _ from 'lodash';

const obj={};
/**
 * This looks for an entity with the provided id in the redux store. If it doesn't find it
 * it looks for it in the server. If it isn't there either it turns on the notFound flag
 *
 * @param entityName string The entities name in plural as defined in the api
 * @param id string|Number The id of the object to look for in the server
 * @param config {{}} An object with optional keys {loadingId, customProp, filters} to be used in the request
 * @returns {[object|undefined, boolean, boolean]} with this values [entity, loading, notFound];
 */
export default (entityName, id, config = obj) => {
    const api = useContext(ApiContext);
    const apiProp=config.customProp || entityName;
    const entities = useSelector(({api}) => api[apiProp]);

    // We use == to include UID's case
    // eslint-disable-next-line
    const entity = _.find(entities, e => e.id == id);

    const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(false);

    const getMethod = config.getMethod || 'get';

    const requestOptions = config.requestOptions || obj;
    const requestFilters = config.requestFilters || obj;

    const params = useMemo(()=>({
        pageSize: 1,
        customProp: apiProp,
        filters: {
            id,
            ...requestFilters
        },
        loadingId: config.loadingId,
        ...requestOptions
    }),[config.loadingId, id, requestFilters, requestOptions, apiProp]);

    //We cannot use the loadingIds functionality because of a race condition which would make the requests to be triggered
    //twice when the entity was not found
    //const loading = !!useSelector(({loadingIds}) => loadingIds[loadingId]);

    const apiCall = useCallback(() => {
        setLoading(true);
        api[entityName][getMethod](params)
            .then(resp => {
                // eslint-disable-next-line
                if (!resp || !resp.length || !_.find(resp, e => e.id == id))
                    setNotFound(true);
                setLoading(false);
            })
            .catch(() =>{
                setNotFound(true);
                setLoading(false);
            });
    }, [entityName, api, id, getMethod, params]);

    useEffect(() => {
        if (entity || notFound || loading)
            return;
        apiCall();
    }, [apiCall, entity, loading, notFound]);

    return [
        entity,
        loading,
        notFound,
        {
            reload:apiCall
        }];
};
