import React, {useMemo, useRef, useContext, useEffect, useState, useCallback} from 'react';
import EmployeeRow from "../utils/EmployeeRow";
import SearchEmployees, {useEmployeesSearch} from "../utils/SearchEmployees";
import {useHeaderFooterHelper} from "../../../utils/header-footer-helper";
import {ApiContext} from "../../../api/Api";
import {useSelector} from "react-redux";

const GiveAward = ({history: navigate}) => {
    const {
        results: employees,
        searchProps,
        requestNextPage,
        containerCSSClasses,
    } = useEmployeesSearch();
    const me = useSelector(({api: {me}}) => me);
    const {headerTitle, setHeaderTitle} = useHeaderFooterHelper();
    const [menuOpened, setMenuOpened] = useState(null);
    const api = useContext(ApiContext);
    const walletRequest = api.pointsWallets.get;
    const followedEmployees = useSelector(({api: {followedEmployees = []}}) => followedEmployees).map(f => f.id);
    const pointsWallets = useSelector(({api: {pointsWallets = []}})=>pointsWallets);
    const pointsWallet = useMemo(()=>pointsWallets?pointsWallets[0]:null, [pointsWallets]);
    const chooseAwardForEmployee = useCallback(employeeId => {
        navigate.push(`/rewards/gifts/${employeeId}`);
    }, [navigate]);

    const employeesList = useRef(null);

    useEffect(()=>{
        walletRequest();
    }, [walletRequest]);

    useEffect(()=>{
        if(pointsWallet && pointsWallet.availableRecognitionPointsToGive === 0){
            if(!headerTitle){
                setHeaderTitle('Directorio');
            }
        }
    }, [pointsWallet, headerTitle, setHeaderTitle]);

    const scrolled = useCallback(() => {
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const wScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const scrolled = wScroll / height;
        if (scrolled === 1) {
            requestNextPage();
        }
    }, [requestNextPage]);
    useEffect(() => {
        window.addEventListener('scroll', scrolled);
        return () => window.removeEventListener('scroll', scrolled);
    }, [scrolled]);
    useEffect(() => {
        if (!me.employee) {
            return;
        }
        api.employees.followedEmployees({id: me.employee.id});
    }, [api, me.employee]);

    const actions = employee => {
        const followed = followedEmployees.includes(employee.id);
        return [
            [followed?'Dejar de seguir':'Seguir colaborador', () => {
                setMenuOpened(null);
                if (!me.employee) {
                    return;
                }
                const toFollow = followed ?
                    followedEmployees.filter(f => f !== employee.id) :
                    followedEmployees.concat(employee.id);
                api.employees.editFollowedEmployees(me.employee.id, {followedEmployees: toFollow});
                api.employees.followedEmployees({id: me.employee.id});
            }],
        ];
    };

    const pickable = !pointsWallet || pointsWallet.availableRecognitionPointsToGive > 0;

    const eClassName = e => {
        if(!pickable){
            return '';
        }
        return 'clickable';
    };
    return (
        <>
            <div className={'Rewards rewards-give-award ' + containerCSSClasses.join(' ')}>
                {!pickable&&
                 <div className='top-message'>
                     Ya no tienes puntos para otorgar
                 </div>
                }
                <SearchEmployees {...searchProps}/>
                <div ref={employeesList} className='employee-list'>
                    {
                        employees.map(e => <EmployeeRow menuOpened={menuOpened === e.id} key={e.id}
                                                        className={eClassName(e)}
                                                        onClick={()=>{
                                                            if(!pickable){
                                                                return;
                                                            }
                                                            chooseAwardForEmployee(e.id);
                                                        }}
                                                        onMenuClicked={() => setMenuOpened(o => o === e.id ? null : e.id)}
                                                        actions={actions(e)} employee={e}/>)
                    }
                </div>
            </div>
            <div onClick={()=>searchProps.setShowingFiltersMenu(false)} className={'overlay '+containerCSSClasses.join(' ')}/>
            <div id='filter-portal'/>
        </>
    );
};

export default GiveAward;
