import React from 'react';
import Card from '../../../../components/Card/Card';
import AppliedIncidencesReport from './AppliedIncidencesReport';

const AppliedIncidencesStats = ( )=>{

    return(
        <Card
            className='AppliedIncidencesStats'
            title='Reporte de incidencias'
            content={
                <>
                   <AppliedIncidencesReport/>
                </>
            }
        />
    );

};

export default AppliedIncidencesStats;
