import _ from 'lodash';
import moneyFormatter from "../../moneyFormatter";
import {copyPropertyOrNull} from "../../../services/formUtils";

/**
 * Get the config object that the form produces and converts it to the one that goes to the server
 *
 * @param config
 */
export const normalizeConfig=(config={})=>{

    const normalized={...config, prices:[]};

    _.forEach(['MXN','USD','EUR'], currency=> {

        if (config[`has${currency}Price`] && Number(config[`${currency}Price`])) {
            normalized.prices.push({
                amount: Number(config[`${currency}Price`]) * 100,
                currency
            });
            delete normalized[`${currency}Price`];
        }
    });

    if (config['hasFreeMeals'] && Number(config['freeMeals']))
        normalized.freeMeals=Number(config['freeMeals']);

    delete normalized.hasUSDPrice;
    delete normalized.hasEURPrice;
    delete normalized.hasMXNPrice;
    delete normalized.hasFreeMeals;

    // Check that extra fields has content, and each field has name.
    if(normalized.extraFields){
        if(!normalized.extraFields.length)
            delete normalized.extraFields;
        else {
            const fields=[];
            _.forEach(normalized.extraFields, field=>{
                if(field.name.trim())
                    fields.push(field);
            });
            normalized.extraFields=fields;
        }
    }

    return normalized;
};

/**
 * Get the config object from the server and prepare it for the form
 *
 * @param config
 */
export const denormalizeConfig=(config)=>{

    if(!config)
        return {};

    let denormalized={...config};


    if(config.prices && config.prices.length){
        const plainPrices=config.prices.reduce((acc, priceObj)=>{
            acc[`has${priceObj.currency}Price`]=true;
            acc[`${priceObj.currency}Price`]=priceObj.amount/100;
            return acc;
        },{});
        denormalized={...denormalized, ...plainPrices};
    }

    if(config.freeMeals)
        denormalized.hasFreeMeals=true;

    return denormalized;
};

export const prepareLoungeAccessMethodForServer=(rawAccessMethod)=>{
    if(!rawAccessMethod)
        return {error:'Error desconocido. No se encontró método de acceso.'};

    const prepared = {};
    if(!rawAccessMethod.name || !rawAccessMethod.name.trim())
        return {error: 'Debes agregar un nombre al método de acceso'};
    prepared.name=rawAccessMethod.name.trim();

    copyNumberOrZero(rawAccessMethod, prepared, 'accessTime');
    copyNumberOrZero(rawAccessMethod, prepared, 'maxAdults');
    copyNumberOrZero(rawAccessMethod, prepared, 'maxKids');
    copyPropertyOrNull(rawAccessMethod, prepared, 'accessType');
    copyPropertyOrNull(rawAccessMethod, prepared, 'folioPrefix');

    if(rawAccessMethod.loungeSubAccessMethods)
        prepared.loungeSubAccessMethods=_.map(rawAccessMethod.loungeSubAccessMethods, 'id');

    if(rawAccessMethod.id)
        prepared.id=rawAccessMethod.id;

    return prepared;
};

const copyNumberOrZero=(original, receptor, property)=>{
    if(!original || !receptor || !property)
        receptor[property]=0;

    if(!Number(original[property]))
        receptor[property]=0;

    receptor[property]=Number(original[property]);
};

export const stringForConfig=(key, value)=>{
    switch (key) {
        case 'prices':
            return renderAllPricesToString(value);
        case 'mandatoryCompanionName':
            return 'Registro de nombre de acompañantes obligatorio';
        case 'freeMeals':
            return `${value} platillo${value!==1?'s':''} de cortesía por persona`;
        case 'extraFields':
            return `Campos extra: ${_.map(value, 'name').join(', ')}`;
        case 'flights':
            return `${value.length} vuelos disponibles`;
        default:
            return `${key} - ${value}`;
    }
};

export const renderAllPricesToString=(prices)=>{
    if(!prices || !prices.length)
        return 'Sin precio';

    return 'Precios: ' + prices.map(price=>`${moneyFormatter(price.amount,100,price.currency)}`).join(' ');
};


export const accessMethodNameFromParents = (loungeAccessMethod)=>{
    if(loungeAccessMethod.loungeParentAccessMethod)
        return accessMethodNameFromParents(loungeAccessMethod.loungeParentAccessMethod) + ' - ' + loungeAccessMethod.name;
    return loungeAccessMethod && loungeAccessMethod.name;
};

const mapParentLAM = l=>{
    if(l.loungeSubAccessMethods.length===0){
        return l;
    }
    return l.loungeSubAccessMethods
        .map(sl=>({...sl, name: l.name+' - '+sl.name}))
        .map(mapParentLAM);
};

export const flattenLoungeAccessMethods = ls=> _.flatMapDeep(ls, mapParentLAM);
