import React, {Component} from 'react';
import StatsCard from '../../../../components/Card/StatsCard';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import LoaderIcon from '../../../../components/Helper/LoaderIcon';


class MPendingIncidenceRequests extends Component {

    static defaultProps = {future:false};

    getInfoString(){

        if( this.props.incidenceJustifications && this.props.incidenceJustifications.constructor === Array ){

            return this.props.incidenceJustifications.reduce(
                (acc, cur)=>{

                    if( cur.approved === null && cur.isFuture === this.props.future )
                        return acc + 1;

                    return acc;
                },
                0
            );

        }
        else{
            return <LoaderIcon/>
        }
    }

    render() {

        const link = this.props.future? "/alma/requests":"/alma/justifying";

        return (
            <StatsCard
                bigIcon={<i className={"fa "+(this.props.future?"fa-comments-o text-success":"fa-file-text-o text-primary")}/>}
                statsText={this.props.future? "Solicitudes pendientes":"Justificaciones pendientes" }
                statsValue={this.getInfoString()}
                statsIcon={<Link to={link}><i className="fa fa-eye" /></Link>}
                statsIconText={<Link to={link}>Ver detalles</Link>}
            />
        );
    }
}

function mapStateToProps( {api:{MDashIncidenceJustifications:incidenceJustifications}} ){

    return {incidenceJustifications}
}

export default connect(mapStateToProps)(MPendingIncidenceRequests);