import React, {Component} from 'react';
import HappinessOverTimeGraph from './components/HappinessOverTimeGraph';
import HappinessLastWeek from './components/HappinessLastWeek';
import LastHappinessLogs from './components/LastHappinessLogs';
import Card from '../../../components/Card/Card';
import CustomButton from "../../../elements/CustomButton/CustomButton";
import PropTypes from 'prop-types';


class HappinessLogReport extends Component {

    static contextTypes = { api: PropTypes.object };

    render(){

        return(
            <div className='container-fluid HappinessLogReport'>
                <HappinessOverTimeGraph />

                <HappinessLastWeek />

                <div className='row'>

                    <div className='col-xs-12 col-md-6'>
                        <LastHappinessLogs />
                    </div>

                    <div className='col-xs-12 col-md-6'>
                        <Card
                            title={<span>Reporte en Excel</span>}
                            category={""}
                            content={
                            <>
                                <p>El reporte contiene el promedio de felicidad de cada empleado, tomando en cuenta todos los registros que se tienen en el sistema.</p>
                                <a href={this.context.api.happinessLogsReport.excelUrl()} target="_blank" rel="noopener noreferrer" >
                                    <CustomButton bsStyle='primary'>Descargar reporte</CustomButton>
                                </a>
                            </>
                            }
                        />
                    </div>
                </div>

            </div>
        );
    }
}

export default HappinessLogReport;