import React from 'react';
import Switch from "react-bootstrap-switch";


const GroupQuestions = ( props )=>{

    return (
        <div className='GroupQuestions'>
            <Switch
                value={ !!props.question.config.group }
                onChange={ (component,value)=>{ props.onChange( { ...props.question.config, group: value?'grupo':undefined } ) } }
            />&nbsp;
            Agrupar pregunta

            {  typeof props.question.config.group !== 'undefined' ?
                <div className='configuration-box'>
                    <input
                        className='form-control'
                        value={ props.question.config.group || '' }
                        onChange={ ( {target} )=>{ props.onChange( { ...props.question.config, group: target.value } ) } }
                    />
                    <p className='text-muted small'>
                        Las preguntas con el mismo grupo aparecerán bajo un subtítulo al realizar el cuestionario.<br/>
                        Si las preguntas se pueden calificar, podrás obtener un promedio por grupo.
                    </p>
                </div>
                :null}

        </div>
    );
};


export default GroupQuestions;