import React,{useContext, useEffect, useMemo} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import {percentageString} from "../../../../utils/MathUtils";
import DateRangeFilter, {useDateRangeFilter} from '../Filters/DateRangeFilter';
import LoungesFilter, {useLoungesFilter} from '../Filters/LoungesFilter';
import ShiftFilter, {useShiftFilter} from '../Filters/ShiftFilter';
import _ from 'lodash';

const loadingId='@DestinationsReport.report';
const DestinationsReport=({defaultDateRange})=>{
    const dateConfig = useMemo(()=>({initial: defaultDateRange}), [defaultDateRange]);
    const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);
    const {requestParams: loungesRequest, filterProps: loungesFilterProps} = useLoungesFilter();
    const {requestParams: shiftRequest, filterProps: shiftFilterProps} = useShiftFilter();
    const api=useContext(ApiContext);
    const params=useMemo(()=>({
        ...dateRequest,
        ...loungesRequest,
        ...shiftRequest
    }), [dateRequest, loungesRequest, shiftRequest]);
    useEffect(()=>{
        api.flights.visitDestinationsReport({
            loadingId,
            params
        });
    },[api, params]);

    const report = useSelector(({api})=>api.visitDestinationsReport)||[];
    const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    const total = useMemo(()=>(report||[]).reduce((count, data)=>count+Number(data.totalOfVisits), 0),[report]);
    const sorted = useMemo( ()=>_.sortBy(report, (r)=>-r.totalOfVisits),[report] );

    return (
        <div className='VisitsAirlinesReport'>
            <DateRangeFilter {...dateFilterProps}/>
            <LoungesFilter {...loungesFilterProps}/>
            <ShiftFilter {...shiftFilterProps}/>
            {loading && <h3 className='text-center'>Cargando...</h3>}

            <Table striped>
                <thead>
                <tr>
                    <th>Destino</th>
                    <th>Cuenta</th>
                    <th>Porcentaje</th>
                </tr>
                </thead>
                <tbody>
                {sorted.map((data, i)=>
                    <tr key={i}>
                        <td>{data.destination}</td>
                        <td>{data.totalOfVisits}</td>
                        <td>{percentageString(Number(data.totalOfVisits)/total)}</td>
                    </tr>
                )}
                </tbody>
            </Table>

        </div>
    );
};

export default DestinationsReport;
