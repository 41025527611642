import {ReactComponent as MexicoFlag} from "../../../assets/img/flags/mexicoFlag.svg";
import {ReactComponent as UsaFlag} from "../../../assets/img/flags/usaFlag.svg";
import {ReactComponent as EuFlag} from "../../../assets/img/flags/euFlag.svg";
import {ReactComponent as CanFlag} from "../../../assets/img/flags/canFlag.svg";
import _ from 'lodash/fp';
import React from "react";

export const detailTableSGroups = [
    "orozco_cash_closing_read",
    "orozco_cash_closing_read_orozco_cash_closing_details",
        "orozco_cash_closing_detail_read",
        "orozco_cash_closing_detail_read_orozco_payment_method",
            "orozco_payment_method_read",
    "orozco_cash_closing_read_created_by",
         "user_read_employee",
             "employee_read_full_name",

];

export const paymentMethodToIcon = (paymentMethodName) => {
    const icons = {
        'Efectivo': 'fa fa-money',
        'Tarjeta': 'fa fa-credit-card'
    };

    return icons[paymentMethodName] ? icons[paymentMethodName] : 'fa fa-money';
};

export const currencyToIcon = (currency) => {
    const icons = {
        'MXN': <MexicoFlag/>,
        'USD': <UsaFlag/>,
        'EUR': <EuFlag/>,
        'CAN': <CanFlag/>
    };

    return icons[currency] ? icons[currency] : <i className='fa fa-money'/>;
};

export const allDetailsEmpty = (cashRegisterCash)=>{
    for(let detail of cashRegisterCash.details){
        if(detail.closingAmount!==0) return false;
    }
    return true;
};

const accumulateSameClosingDetails = (closingDetails)=>{
    let accumulated = {};
    for(let detail of closingDetails){
        const curr = detail.currency;
        const paymm = detail.orozcoPaymentMethod.id;
        if(!accumulated[curr]){
            accumulated[curr] = {};
        }
        if(!accumulated[curr][paymm]){
            accumulated[curr][paymm] = {};
        }
        if(accumulated[curr][paymm].closingAmount===undefined){
            accumulated[curr][paymm] = _.clone(detail);
        } else {
            accumulated[curr][paymm].closingAmount += detail.closingAmount;
        }
    }
    return _.flatMap(_.values, _.values(accumulated));
};

export const accumulateClosingDetails = _.flow(
    _.map('orozcoCashClosingDetails'),
    _.flatten,
    accumulateSameClosingDetails
);
