import React, {useCallback, useContext, useEffect, useState} from 'react';
import useBoolean from "../../../../hooks/useBoolean";
import './_StockRequestGenerator.scss';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {TideApiContext} from "../../../../api/tideApiConfig";
import _ from 'lodash';
import moment from "moment";
import {NotifierContext} from "../../../../utils/Notifier";
import {convertFromSmallestUnit, getSuggestedUnitToShow} from "../../../../utils/modelUtils/almazen/unitsUtility";
import CustomCheckbox from "../../../../elements/CustomCheckbox/CustomCheckbox";

const StockRequestGenerator = ({warehouseId, setStockMovements}) => {

    const [formOpen, openForm] = useBoolean();

    //Las salas a las que el almacén de entrega seleccionado sirve sus productos
    const [lounges, setLounges] = useState();
    const [selectedLounges, setSelectedLounges] = useState([]);

    const [days, setDays] = useState("7");
    const [extra, setExtra] = useState("0");
    const [guestCount, setGuestCount] = useState(null);

    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);

    /*useEffect(() => {
        api.warehouses.get({id: warehouseId, customProp:'_', params:{sGroups: warehouseForStockRequestFormSGroups}})
            .then(warehouse => {
                if (!warehouse?.loungeWarehouses?.length)
                    return;

                setLounges(_.map(warehouse.loungeWarehouses, 'lounge'));
        });
    }, [warehouseId, api]);*/

    useEffect(() => {
       api.lounges.get({params: {isLounge: true, isActive: true}})
           .then(lounges => {
               setLounges(lounges);
           });
    }, [api]);

    const isLoungeChecked = (id) => {
        return _.some(selectedLounges, {'id': id});
    }

    const toggleLoungeCheckbox = (id, name) => {
        if (!isLoungeChecked(id)) {
            setSelectedLounges(ls =>  [...ls, {'loungeName':name, 'id': id}]);
            return;
        }
        setSelectedLounges(ls => _.filter(ls, l => id !== l.id && name !== l.loungeName));
    };

    const loadGuests = useCallback(_.debounce((selectedLounges, days) => {
        if (!selectedLounges?.length || !Number(days))
            return setGuestCount(0);
        api.guests.numberInPeriod({
            params: {
                lounges: _.map(selectedLounges, 'id'),
                from: moment().subtract(days, "days").format('YYYY-MM-DD 00:00')
            }
        })
            .then(resp => setGuestCount(resp?.total));
    }, 650), [api, selectedLounges]);

    useEffect(() => {
        loadGuests(selectedLounges, days);
    }, [loadGuests, selectedLounges, days]);

    const totalGuests = (guestCount || 0) * (1 + (Number(extra) || 0) / 100);

    const generate = useCallback(() => {
        if (!warehouseId)
            return notifier.error("Selecciona el almacén de entrega");
        api.inventories.forStockRequest({params: {warehouse: warehouseId}, customProp: '_'})
            .then((inventories) => {
                if (!inventories?.length)
                    return notifier.error("no se encontró información de consumo");
                //Filter only inventories with a known consumption rate
                inventories = _.filter(inventories, inv => inv?.productBrand?.product?.consumedAverageByGuestInPeriod);
                const stockMovements = inventories.map(inventory => {
                    const pb = inventory.productBrand;

                    const totalRequired = pb.product.consumedAverageByGuestInPeriod * totalGuests;
                    const selectedUnit = getSuggestedUnitToShow(totalRequired, pb.product);
                    const missing = totalRequired - (inventory.stockAmount < 0 ? 0 : inventory.stockAmount);

                    if (missing < 0) {
                        return null
                    }

                    const quantity = convertFromSmallestUnit(missing, selectedUnit, pb.product);

                    return {
                        productBrand: pb,
                        quantity: Math.ceil(quantity) || 1,
                        selectedUnit
                    };
                });

                setStockMovements(_.compact(stockMovements));
            })
    }, [api, totalGuests, warehouseId, setStockMovements, notifier]);

    if (!warehouseId)
        return null;

    return (
        <div className={"StockRequestGenerator"}>

            {!formOpen ?
                <button onClick={openForm} className='btn btn-primary'>Llenado automático</button> :
                <div className='auto-form'>
                    <h5>Llenado automático</h5>
                    <div className='container-fluid'>
                        <div className='row form-row'>
                            <div className='col-sm-12'>
                                {lounges ? lounges.map(l=><CustomCheckbox
                                        key={l.id}
                                        label={l.name}
                                        inline
                                        isChecked={isLoungeChecked(l.id)}
                                        onChange={() => toggleLoungeCheckbox(l.id, l.name)}
                                    />) : null}
                            </div>
                            <div className='col-sm-4'>
                                <p className='field-name'>Días</p>
                                <input className='form-control' value={days} onChange={e => setDays(e.target.value)}/>
                            </div>
                            <div className='col-sm-4'>
                                <p className='field-name'>
                                    Huéspedes <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id={1}>
                                        Huéspedes que entraron en la sala asignada al almacén seleccionado en los
                                        últimos {days} días.<br/>
                                        {selectedLounges?.map(l => l.loungeName + ', ')}
                                    </Tooltip>}
                                >
                                    <i className='fa fa-question-circle'/>
                                </OverlayTrigger>
                                </p>
                                <p className='guests'>{guestCount || '-'}</p>
                            </div>
                            <div className='col-sm-4'>
                                <p className='field-name'>Porcentaje extra</p>
                                <input className='form-control' value={extra} onChange={e => setExtra(e.target.value)}/>
                            </div>
                        </div>
                        <hr/>
                        <div className='row'>
                            <p>
                                Se generará una solicitud para atender a <b>{totalGuests || '-'} huéspedes</b><br/>
                            </p>
                            <button className='btn btn-primary' onClick={generate}>Calcular</button>
                        </div>
                    </div>
                </div>}
        </div>
    );
}
;

export default StockRequestGenerator;
