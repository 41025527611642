import React, {useContext, useEffect, useState}  from 'react';
import {Modal, Table} from "react-bootstrap";
import Button from '../../../elements/CustomButton/CustomButton';
import {ApiContext} from "../../../api/Api";
import {connect} from "react-redux";
import {NotifierContext} from "../../../utils/Notifier";


const TaxManagerModal = (  {onHide, taxes} )=>{

    const api = useContext( ApiContext );
    const notifier = useContext( NotifierContext );

    const [ addingTax, setAddingTax ] = useState( false );

    useEffect( ()=>{
        if( !taxes.length )
            api.taxes.get( 0,200 );
    }, [api, taxes.length] );

    const deleteTax = ( tax ) => api.taxes.delete( tax.id );

    const createTax = ()=>{
        const name = addingTax.name.trim();
        const multiplier = Number(addingTax.multiplier);

        console.log( 'creating' );

        if( !name )
            return notifier.error('Debes agregar un nombre al impuesto.');

        if( isNaN(  multiplier) )
            return notifier.error('Debes seleccionar un número válido como multiplicador.');

        setAddingTax( false );

        api.taxes.create( { name, multiplier: multiplier+'' } );

    };

    return <Modal
        show={true}
        onHide={onHide}
        className='TaxManagerModal'
    >
        <Modal.Header>
            <Modal.Title>Catálogo de impuestos</Modal.Title>
        </Modal.Header>

        <Modal.Body>

            <Table>
                <thead>
                <tr>
                    <th>Impuesto</th>
                    <th>Multiplicador</th>
                    <th>Acción</th>
                </tr>
                </thead>
                <tbody>

                {taxes.map( (tax)=><tr key={tax.id}>
                    <td>{tax.name}</td>
                    <td>{Number(tax.multiplier)}</td>
                    <td><i className='fa fa-trash' onClick={ ()=>deleteTax( tax ) }/></td>
                </tr> )}

                {addingTax?
                    <tr>
                        <td><input value={ addingTax.name } onChange={ ( {target})=>setAddingTax( {...addingTax, name: target.value} ) }/></td>
                        <td><input type="number" value={ addingTax.multiplier } onChange={ ( {target})=>setAddingTax( {...addingTax, multiplier: target.value} ) }/></td>
                        <td><i className='fa fa-check' onClick={createTax} /></td>
                    </tr>
                    :null}

                </tbody>
            </Table>

        </Modal.Body>

        <Modal.Footer>
            <Button bsStyle='danger' onClick={onHide} className='pull-left'>Cerrar</Button>
            <span className='link' onClick={()=>setAddingTax({ name:'', multiplier:''})} >Nuevo tipo de impuesto</span>
        </Modal.Footer>
    </Modal>
};

const mapStateToProps = ( {api:{taxes=[]}} )=>({taxes});

export default connect(mapStateToProps)(TaxManagerModal);