import React, {useState, useContext, useRef, useEffect, useCallback, useMemo} from 'react';
import {Modal, Table } from 'react-bootstrap';
import Button from "../../../elements/CustomButton/CustomButton";
import {ApiContext} from "../../../api/Api";
import {NotifierContext} from "../../../utils/Notifier";
import UnitBundleCreator from '../../../components/UnitBundleCreator/UnitBundleCreator';
import {extractUnitBundleProperties, emptyUnitBundle} from "../../../utils/modelUtils/almazen/unitsUtility";
import FileViewer from '../../../components/FileViewer/FileViewer';
import noProductImg from '../../../assets/img/no-product.png';
import _ from 'lodash';
import Select, {Creatable} from "react-select";
import {connect} from "react-redux";
import ProductBrandsForm from "./units/ProductBrandsForm";
import {getUsedUnitsFromProductBrands, prepareProductForServer} from "../../../utils/modelUtils/almazen/productsUtils";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";


const loadingId = '@ProductFormModal.productLine/product.create';
const employeesLoadingId = '@ProductFormModal.employees.get';

const ProductFormModal = ({onHide, editProduct, productLines, employees, loadingIds }) => {


    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);

    const [product, setProduct] = useState(editProduct ? editProduct : {
        name: '',
        isService: false,
        approvedBy: null,
        productBrands:[]
    });

    const [employeeInput, setEmployeeInput] = useState('');
    const [ unitBundle, setUnitBundle ] = useState( editProduct? extractUnitBundleProperties(editProduct) : emptyUnitBundle() );
    const [ previewImage, setPreviewImage ] = useState( editProduct && editProduct.photo? api.appFiles.getUrl( editProduct.photo.id ) : noProductImg );

    const [fileInputs, setFileInputs] = useState([]);
    const [files, setFiles] = useState([]);

    const filesRef = useRef([]);
    filesRef.current = files;

    const onFileChange = (e, index) => {
        const newFiles = [...filesRef.current];
        newFiles[index] = {...e.target.files}[0];
        setFiles(newFiles);
    };

    const addFileInput = () => {
        const index = fileInputs.length;
        const newFileInputs = [...fileInputs];
        newFileInputs.push(<input type="file" onChange={(e) => onFileChange(e, index)} key={index}/>);
        setFileInputs(newFileInputs);
    };

    const newPhotoRef = useRef(null);

    const handleProductBrandsChange = useCallback((productBrands)=>{
        setProduct({...product, productBrands});
    },[product]);

    useEffect( ()=>{
        api.productLines.get({page:0,pageSize:200});
    }, [api] );

    const onPropertyChange = ({target}) => {
        const newProduct = {...product, [target.name]: target.value};
        setProduct(newProduct);
    };

    const saveProduct = () => {

        const newProduct = prepareProductForServer({...product, ...unitBundle});

        if(newProduct.error)
            return notifier.error(newProduct.message);

        delete newProduct.photo;

        api.products[editProduct?'edit':'create']({
            id:editProduct? editProduct.id:undefined,
            product:newProduct,
            files:{photo: newPhotoRef.current, approvalEvidenceFiles: files||undefined},
            loadingId
        })
            .then(onHide)

    };

    const selectedFile = ( {target} )=>{

        if( !target.files.length ) {
            newPhotoRef.current = null;
            setPreviewImage( null );
            return;
        }

        const ext = _.last( target.files[0].name.split('.') ).toLowerCase();
        if( ext !== 'jpg' && ext !== 'jpeg' && ext !== 'png' ) {
            newPhotoRef.current = null;
            setPreviewImage(null);
            return notifier.error('Debes seleccionar una imagen de tipo jpg o png');
        }

        newPhotoRef.current = target.files[0];

        var reader = new FileReader();
        reader.onload = (e)=> setPreviewImage( e.target.result );
        reader.readAsDataURL(target.files[0]);

    };

    const searchEmployeesWithName = ( name ) => {
        api.employees.get(
            0,
            10,
            loadingIds[employeesLoadingId],
            {"search":name, "sGroups": ['user_search']})
    };

    const onEmployeeInputChange = (value) =>{
        if( !value || employeeInput === value)
            return;
        setEmployeeInput(value);
        searchEmployeesWithName(value);
    };

    const onEmployeeChange = (selection) =>{
        const newProduct = {...product, approvedBy: selection?selection.value:undefined};
        setProduct(newProduct);
    };

    const onLineSelect = ( selected )=>{

        if( !selected )
            return setProduct( {...product, line:null} );

        const line = _.find( productLines, pl=>pl.id===selected.value );

        if( !line )
            api.productLines.create( { name: selected.label }, loadingId )
                .then( line=>setProduct( { ...product, line } ) );
        else
            setProduct( {...product, line } );
    };

    const approvedByValue = useMemo(()=>employeeToSelectValue(product.approvedBy),[product.approvedBy]);
    const employeesForSelect = useMemo(()=>employees.map(employeeToSelectValue),[employees]);
    const usedUnits = useMemo(()=>getUsedUnitsFromProductBrands(product.productBrands),[product.productBrands]);


    return (
        <Modal
            show={true}
            onHide={onHide}
            className='ProductFormModal'
            bsSize='lg'
        >
            <Modal.Header>
                <Modal.Title>{editProduct ? 'Editar' : 'Nuevo'} producto</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <form autoComplete='off'>
                    <Table className='main-info'>
                        <tbody>
                        <tr>
                            <td>Nombre</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='name'
                                    required={true}
                                    onChange={onPropertyChange}
                                    value={product.name}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Línea</td>
                            <td>
                                <Creatable
                                    placeholder='Selecciona una línea'
                                    options={ productLines.map( pl=>({label:pl.name, value:pl.id}) ) }
                                    value={ product.line? {label:product.line.name, value: product.line.id } : null }
                                    onChange={ onLineSelect }
                                    promptTextCreator={ input =>
                                        ('Crear línea '+input)
                                    }
                                    newOptionCreator={ ({label}) =>
                                        ({value:_.capitalize( label ), label:_.capitalize( label ) })
                                    }
                                    isLoading={ !!loadingIds[ loadingId ] }
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>Aprobado por</td>
                            <td>
                                <Select
                                    name="approvedBy"
                                    options={employeesForSelect}
                                    isLoading={ !!loadingIds[loadingId]}
                                    onInputChange={onEmployeeInputChange}
                                    onChange={onEmployeeChange}
                                    value={approvedByValue}
                                    placeholder={"Selecciona un responsable"}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Archivos de aprobación</td>
                            <td>
                                <span className='link add-text' onClick={addFileInput}>+ Agregar archivo</span>
                                {fileInputs}
                            </td>
                        </tr>
                        <tr>
                            <td>Descripción</td>
                            <td>
                                <textarea
                                    className='form-control'
                                    name='description'
                                    onChange={onPropertyChange}
                                    value={product.description||''}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>

                    <h4>Unidades de medida</h4>
                    <UnitBundleCreator
                        showTemplates={true}
                        unitBundle={ unitBundle }
                        onChange={ setUnitBundle }
                        requiredUnits={usedUnits}
                    />

                    <hr/>

                    <h4>Marcas</h4>

                    <ProductBrandsForm
                        onChange={handleProductBrandsChange}
                        productBrands={product.productBrands}
                    />

                    <div className='text-center'>
                        <FileViewer
                            name={'photo.jpg'}
                            src={previewImage}
                            />
                            <input className='form-control' type='file' onChange={ selectedFile }/>
                    </div>


                </form>


            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle='danger' disabled={!!loadingIds[loadingId]} className='pull-left'
                        onClick={onHide}>Cancelar</Button>
                <Button bsStyle='success' disabled={!!loadingIds[loadingId]} onClick={saveProduct}>Guardar</Button>
            </Modal.Footer>
        </Modal>
    );
};

const employeeToSelectValue=(employee)=>({value:employee, label: employeeFullName(employee)});

const mapStateToProps = ({api:{ productLines=[], employees=[] }, loadingIds})=>({productLines, employees, loadingIds});

export default connect(mapStateToProps)(ProductFormModal);
