import React, { Component } from 'react';
import {minutesToString, momentToMinutes} from "../../../../utils/TimeConverter";
import PropTypes from "prop-types";
import {Grid, Row, Col} from 'react-bootstrap';
import DateTime from 'react-datetime';


export default class DaySchedule extends Component{

    static defaultProps = { editable: false, maxTime: 2160 };
    static propTypes = { day: PropTypes.string.isRequired, schedule: PropTypes.array.isRequired };

    onScheduleChange(index, time){

        if(typeof time !== 'object') return;

        const schedule = [...this.props.schedule];
        schedule[index] = momentToMinutes( time );
        this.props.onChange(schedule);
    }

    render() {

        return (
            <div className="DaySchedule">
                <Grid fluid>
                    <Row>
                        <Col>
                            <span className="shiftDay">{this.props.day}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2} md={3} sm={2}>
                            <span className="shiftDesc">Entrada</span><br/>
                            <DateTime
                                dateFormat={false}
                                value={minutesToString( this.props.schedule[0] )}
                                inputProps={{disabled:!this.props.editable}}
                                onChange={(m)=>this.onScheduleChange(0,m)}
                            />
                        </Col>

                        <Col lg={2} md={3} sm={2}>
                            <span className="shiftDesc">Salida</span><br/>
                            <DateTime
                                dateFormat={false}
                                value={minutesToString( this.props.schedule[1] )}
                                inputProps={{disabled:!this.props.editable}}
                                onChange={(m)=>this.onScheduleChange(3,m)}
                            />
                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}
