import {USER_DETAIL, HIDE_USER_DETAIL} from '../actions/types';

const initialState = null;

export default function ( state = initialState, action ) {

    switch (action.type){
        case USER_DETAIL:
            return action.payload;
        case HIDE_USER_DETAIL:
            return initialState;
        default:
            return state;
    }
}