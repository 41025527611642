import React, {useEffect, useMemo, useContext, useState} from 'react';
import {ApiContext} from "../../../api/Api";
import useTideEntity from "../../../hooks/useTideEntity";
import defaultRewardImage from '../../../assets/img/tgle-rewards/default-reward.png';
import wishedImage from '../../../assets/img/tgle-rewards/store/wished-white.png';
import notWishedImage from '../../../assets/img/tgle-rewards/store/not-wished-white.png';
import {useSelector} from "react-redux";
import _ from 'lodash/fp';
import {useHeaderFooterHelper} from "../../../utils/header-footer-helper";
import Product from '../utils/Product';

const randomEntries = 4;
const addRandomEntryIndex = (max, excludeIndex)=>entries=>{
    let newEntry;
    do{
        newEntry = Math.floor(Math.random()*max);
    }while(_.includes(newEntry, entries) || newEntry===excludeIndex);
    return [newEntry, ...entries];
};

const ProductDescription = ({match: {params: {id: productId}}, history: navigation}) => {
    const api = useContext(ApiContext);
    const wishedRewards = useSelector(({api: {rewardWishes = []}}) => rewardWishes);
    const me = useSelector(({api: {me}}) => me);
    const [showingConfirmation, setShowingConfirmation] = useState(false);
    const [visibleDescription, setVisibleDescription] = useState(true);
    const [product, loading, notFound] = useTideEntity('rewards', productId);
    const pointsWallets = useSelector(({api: {pointsWallets = []}}) => pointsWallets);
    const rewards = useSelector(({api: {rewards}})=>rewards);

    const maxEntries = _.isArray(rewards)?
          Math.min(rewards.length-1, randomEntries):0;
    const excludeIndex = _.findIndex({id: productId}, rewards);
    const otherProducts = useMemo(()=>_.isArray(rewards)?
          [...new Array(maxEntries)]
                                  .reduce(addRandomEntryIndex(rewards.length, excludeIndex), [])
                                  .map(i=>rewards[i]):[]
                                  , [rewards, maxEntries, excludeIndex]);
    const {setFooterClassName, setHeaderClassName, fullResetAction} = useHeaderFooterHelper();
    useEffect(()=>{
        window.scroll(0, 0);
    }, []);
    useEffect(() => {
        api.pointsWallets.get();
        api.employees.wishedRewards();
        api.rewards.get();
    }, [api]);
    useEffect(() => {
        if (showingConfirmation) {
            setFooterClassName('blurred');
            setHeaderClassName('blurred');
        } else {
            fullResetAction();
        }
    }, [showingConfirmation, setHeaderClassName, setFooterClassName, fullResetAction]);
    useEffect(()=>{
        if(showingConfirmation){
            window.scroll(0, 0);
        }
    }, [showingConfirmation]);

    const wishedIds = _.map('id', wishedRewards[0] ? wishedRewards[0].wishedRewards : []);
    if (loading || !product) {
        return null;
    }
    if (notFound) {
        navigation.back();
        return null;
    }
    const pointsWallet = pointsWallets.length > 0 ? pointsWallets[0] : null;
    const productWished = id => _.includes(id, wishedIds);
    const addWishedInfo = p => ({
        ...p,
        wished: productWished(p.id)
    });
    const {name, image, wished, description, pointsCost} = addWishedInfo(product);
    const productImage = image ? api.appFiles.getUrl(image.id) : defaultRewardImage;
    const buyProduct = () => {
        api.rewardExchanges.create(productId);
        fullResetAction();
        navigation.push('/rewards/store');
    };
    const toggleWish = async () => {
        const getToWish = wished ?
            _.filter(pId => pId !== productId) :
            _.concat([productId]);
        await api.employees.editWishedRewards(me.employee.id, {wishedRewards: getToWish(wishedIds)});
        await api.employees.wishedRewards();
    };
    const purchasable = pointsWallet?
          pointsWallet.availableExchangePoints>=pointsCost
          :false;
    return (
        <>
            <div className={'Rewards ProductDescription ' + (showingConfirmation ? 'blurred' : '')}>
                <div className='product-image-container'>
                    <img alt='Product' src={productImage}/>
                </div>
                <div className='product-info'>
                    <div className='product-label'>
                        <div className='product-label-container'>
                            <p>{name.toUpperCase()}</p>
                            <div className='product-label-detail'>
                                <p className='value-text bold'>Valor: {pointsCost}pts.</p>
                                <button onClick={toggleWish}>
                                    {wished ?
                                        <img src={wishedImage} alt='wished'/> :
                                        <img src={notWishedImage} alt=' not wished'/>
                                    }
                                </button>
                            </div>
                        </div>
                        <div className='buy-container'>
                            <button disabled={!purchasable} className={'buy-button '+(purchasable?'':'disabled')} onClick={() => purchasable?setShowingConfirmation(true):null}>
                                {purchasable?'+ Comprar':'Comprar'}
                            </button>
                        </div>
                    </div>
                    <div className='product-description'>
                        <div className='description-header'>
                            <p className='description-header-text'>Descripción</p>
                            <button
                                onClick={() => setVisibleDescription(!visibleDescription)}>{visibleDescription ? '-' : '+'}</button>
                        </div>
                        <hr/>
                        {visibleDescription &&
                        <div className='collapsable-content'>
                            <p>
                                {description}
                            </p>
                        </div>
                        }
                    </div>
                    <div className='product-description'>
                        <p>Otros productos</p>
                        <div className='more-products'>
                            {otherProducts.map(p=><Product navigation={navigation} key={p.id} {...p}/>
                            )}
                        </div>
                    </div>
                </div>

            </div>
            {showingConfirmation &&
            <div className='ProductDescription purchase-confirmation'>
                <button className='close-button' onClick={() => setShowingConfirmation(false)}><i
                    className='fa fa-close'/></button>
                <div className='purchase-content'>
                    <p>¿Estás <span className='bold'>seguro</span> que <span
                        className='bold'>deseas realizar esta compra?</span></p>
                    <div className='purchase-info'>
                        <p className='available'>Puntos disponibles: <span
                            className='value'>{pointsWallet.availableExchangePoints}pts.</span></p>
                        <hr/>
                        <p className='to-exchange'>Puntos restados: <span
                            className='value'>-{pointsCost}pts.</span></p>
                        <hr/>
                        <p className='total'>Total (Puntos disponibles): <span
                            className='value'>{pointsWallet.availableExchangePoints - pointsCost}pts.</span></p>
                        <button className='buy-button' onClick={buyProduct}>Realizar compra</button>
                    </div>
                </div>
            </div>
            }
        </>
    );
};

export default ProductDescription;
