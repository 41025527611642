import React, {createContext, useContext, useMemo} from 'react';
import Notifications from 'react-notification-system-redux';
import socketIOClient from "socket.io-client";
import sailsIOClient from "sails.io.js";
import _ from "lodash";
import {
    ACTION_PREFIX,
    SOCKET_API_URL,
    STATE_ACTION_PUSH,
    STATE_ACTION_SEARCH_N_DELETE,
    STATE_ACTION_SET
} from "./constants_api";
import {APP_LOADING_END, APP_LOADING_START} from "../actions/types";


let io = null;
let configured = false; //classy state

export const configure = (store) => {
    if(configured){
        return;
    }
    io = sailsIOClient(socketIOClient);
    /*
    When instantiated, the socket is auto-connected to the server,
    requests made while it's not connected are queued for when it is.
    */
    io.sails.reconnection = true;
    io.sails.url = SOCKET_API_URL;
    registerListeners(store);
    configured = true;
};


/*
    How the client will handle events sent from the server without being
    result of his request is defined in the following `listeners`
    configuration array.
    For consistency with the way things are done in Api.js, we use the same
    STATE_ACTION_* dispatches on the store if the storeKey is provided.
    Shape of a listener configuration:
    {
        event: String,
        action: STATE_ACTION | fn,
        storeKey: String || fn (Optional)
    }
    If storeKey is not provided, no action will be dispatched on the
    redux store. (Only those concerning loading notifications, etc...)

    storeKey may be a function, to allow the "customProp" level of configuration,
    the function receives the event from the server and must return a string.

    action may also be a function, allowing to update the state as required,
    the action will receive the previous state with that storeKey and the data
    the server sent.

    You can subscribe as many times as needed to the same event with different listeners.
 */
const storeListeners = (storeKey, eventsAndActions) =>
    eventsAndActions.map(([event, action]) => ({
        event,
        action,
        storeKey
    }));

const listeners = [
    ...storeListeners('chats', [
        ['chatOpened', STATE_ACTION_PUSH],
        ['chatClosed', STATE_ACTION_SEARCH_N_DELETE]
    ]),
    ...storeListeners('assignedChats', [
        ['chatAdded', STATE_ACTION_PUSH],
        ['chatRemoved', STATE_ACTION_SEARCH_N_DELETE],
        ['messageReceived', (chats, message) => {
            const i = _.findIndex(chats, c => message.chat === c.id);
            if (i === -1) {
                return chats;
            }
            let chat = _.clone(chats[i]);
            chat.messages = chat.messages.concat(message);
            const updated = Object.assign([], chats, {[i]: chat});
            chats[i].messages = chats[i].messages.concat(message);
            return updated;
        }]
    ]),
];

/*
    How the client will call for socket requests, and handle their responses,
    is defined in the following `requests` configuration array.
    Shape of a request configuration:
 */
const requests = [
    {
        resource: 'chat',
        route: '/chat',
        storeKey: 'chats',
        only: ['find', 'update'],
        actions: [
            {
                method: 'find',
                action: STATE_ACTION_SET
            }
        ],
        creators: [
            {
                name: 'fromUser',
                storeKey: 'assignedChats',
                create: () => ({
                    user: window.localStorage.googlead
                })
            },
        ]
    },
    {
        resource: 'chatMessage',
        route: '/chatMessage',
        only: ['create'],
        creators: [
            {
                name: 'sendFrom',
                create: () => ({
                    sender: window.localStorage.googlead
                })
            }
        ]
    }
];

const urlFor = (route, method, parameters) => {
    if (method === 'update') {
        return route + '/' + parameters.id;
    }
    return route
};

const socketApiCall = store => async (resource, method, requestConfiguration = {}) => {
    const resourceConfiguration = _.find(requests, r => r.resource === resource);
    if (!resourceConfiguration) {
        throw new Error(`There's no request configured with ${resource} as resource.`);
    }

    if (resourceConfiguration.only) {
        if (!resourceConfiguration.only.includes(method)) {
            throw new Error(`${method} not allowed for ${resource} configuration.`);
        }
    }

    const httpMethod = (() => {
        switch (method) {
            case 'find':
                return 'get';
            case 'update':
                return 'patch';
            case 'create':
                return 'post';
            default:
                throw new Error(`${method} is an invalid method.`);
        }
    })();

    const creator = (() => {
        if (!requestConfiguration.creator) {
            return null;
        }
        if (!resourceConfiguration.creators) {
            throw new Error(`There're no configured creators for ${resource}, and you asked for ${requestConfiguration.creator}.`);
        }
        const creator = _.find(resourceConfiguration.creators, c => c.name === requestConfiguration.creator);
        if (!creator) {
            throw new Error(`There's no ${requestConfiguration.creator} creator on the configuration of ${resource}`);
        }
        return creator;
    })();

    const parameters = (() => {
        const perCallParameters = requestConfiguration.parameters;
        const creatorParameters = (() => {
            if (!creator) {
                return {};
            }
            return creator.create(requestConfiguration.parameters || {});
        })();
        return {
            ...creatorParameters,
            ...perCallParameters
        };
    })();

    const storeKey = (() => {
        if (requestConfiguration.storeKey) {
            return requestConfiguration.storeKey;
        }
        if ((creator || {}).storeKey) {
            return creator.storeKey;
        }
        if (resourceConfiguration.storeKey) {
            return resourceConfiguration.storeKey;
        }
        return null;
    })();


    const action = (() => {
        if ((creator || {}).action) {
            return creator.action;
        }
        const actions = resourceConfiguration.actions || [];
        const actionConfiguration = _.find(actions, a => a.method === method);
        if (!actionConfiguration) {
            return null;
        }
        return actionConfiguration.action;
    })();

    const startAction = {
        type: APP_LOADING_START,
        ...(requestConfiguration.loadingId ? {
            payload: {id: requestConfiguration.loadingId}
        } : {})
    };
    const endAction = {
        type: APP_LOADING_END,
        ...(requestConfiguration.loadingId ? {
            payload: {id: requestConfiguration.loadingId}
        } : {})
    };
    const errorAction = Notifications.error({
        title: <i className="fa fa-exclamation-triangle"/>,
        message: <span> Parece que hubo un error</span>,
        autoDismiss: 8
    });

    const url = urlFor(resourceConfiguration.route, method, parameters);
    store.dispatch(startAction);
    try {
        const response = await socketCall(url, parameters, httpMethod);
        if (storeKey && action) {
            store.dispatch({
                ...reduxUpdateAction(storeKey, action, store, response),
                payload: {
                    ...reduxUpdateAction(storeKey, action, store, response).payload,
                    method: httpMethod,
                    params: parameters
                }
            });
        }
    } catch (e) {
        console.log('Error making socket request', e);
        store.dispatch(errorAction);
    } finally {
        store.dispatch(endAction);
    }
};

const socketCall = (...params) =>
    new Promise(executeSocketCall(...params));

const executeSocketCall = (url, params, method) => {
    return (res, rej) => {
        return io.socket[method](
            url,
            params,
            (data, jwr) => {
                if (jwr.statusCode === 200) {
                    return res(data);
                }
                return rej({data, jwr});
            });
    };
};

const reduxUpdateAction = (storeKey, action, store, data) => ({
    type: ACTION_PREFIX + (typeof action === 'function' ?
        STATE_ACTION_SET : action),
    payload: {
        success: true,
        property: storeKey,
        ...(typeof action === 'function' ?
            {data: action(store.getState().api[storeKey], data)} : {data}),
    }
});

const registerListeners = store => {
    for (const {event, action, storeKey} of listeners) {
        io.socket.on(event, message => {
            console.log('answering to ', event);
            console.log('message', message);
            console.log('storeKey', storeKey);
            store.dispatch(reduxUpdateAction(storeKey, action, store, message))
        });
    }
};

export const SocketApiContext = createContext(null);

export const socketApi = store => ({
    apiCall: socketApiCall(store),
    socket: () => io.socket,
    configure: () => configure(store)
});

export const useSocketApi = resource => {
    const configuredApi = useContext(SocketApiContext);
    return useMemo(() => ({
        apiCall: (method, configuration = {}) =>
            configuredApi.apiCall(resource, method, configuration)
    }), [resource, configuredApi]);
};
