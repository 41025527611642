import React, { useState, useContext, useEffect, useMemo } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import moment from 'moment';
import { Grid, Row, Col, Table } from 'react-bootstrap';
import {ApiContext} from "../../../../api/Api";
import _ from 'lodash';
import {connect} from 'react-redux';
import tableDataBuilder, {SEPARATOR} from "../../../../utils/tableDataBuilder";


const LayoffsReport = ( {layoffsReport} )=>{

    const api = useContext( ApiContext );

    const [ dates, setDates ] = useState( { from: moment().month(0).date(1), to: moment() } );

    const [ yFilter, setYFilter ] = useState( {name: 'Meses', value:'month'} );
    const [ xFilters, setXFilters ] = useState( [{name: 'Áreas', value:'area'}, {name:'Sala', value:'lounge'},{name:'Turnos', value:'shift'} ] );

    useEffect( ()=>{
        const options = { groupBy:[] };

        if( yFilter )
            options.groupBy.push( yFilter.value );

        _.forEach( xFilters, ( {value} )=>options.groupBy.push(value) );

        options.fromDate = dates.from.format( 'YYYY-MM-DD' );
        options.toDate = dates.to.format( 'YYYY-MM-DD' );

        api.layoffs.getReport( options );

    }, [api, xFilters, yFilter, dates] );

    const tableData = useMemo( ()=>{

        return tableDataBuilder( layoffsReport, xFilters, yFilter );

        // eslint-disable-next-line
    }, [ layoffsReport ] );

    const onDateChange = ( e, { startDate, endDate } )=>{
        setDates( {from: moment(startDate), to: moment(endDate) } );
    };

    const removeYFilter = ()=>setYFilter(null);

    const addYFilter = ( {target} )=>setYFilter( availableFilters[target.value] );

    const removeXFilter = ( {target} )=>{
        const newFilters = [ ...xFilters ];
        newFilters.splice( target.dataset.index, 1 );
        setXFilters( newFilters );
    };

    const addXFilter = ( {target} )=>{
        const newFilters = [ ...xFilters ];
        newFilters.push( availableFilters[target.value] );
        setXFilters( newFilters );
    };

    const xTotals = [];
    const yTotals = [];

    return (
        <div className='LayoffsReport'>
            <h5>Reporte interactivo de bajas</h5>

            <Grid fluid>

                <Row>
                    <DateRangePicker
                        startDate={dates.from}
                        endDate={dates.to}
                        onApply={onDateChange}
                        containerStyles={{}}
                    >

                        <CustomButton
                            bsStyle={'primary'}
                            className='col-xs-12 range-button'
                        >
                            Rango de fechas &nbsp;
                            <strong>{ dates.from.format('YYYY-MM-DD') }</strong> al <strong>{ dates.to.format('YYYY-MM-DD') }</strong>
                        </CustomButton>

                    </DateRangePicker>
                </Row>

                <Row>
                    <Col md={6} xs={12}>
                        <p className='filter-title'>
                            Selecciona filtros para el eje X
                            <select value='' onChange={addXFilter} className='form-control'>
                                <option value='' disabled>Agrega un filtro</option>
                                { availableFilters.map( (filter, index)=> {
                                        if(  (yFilter && yFilter.value === filter.value) || _.find( xFilters, (fil)=>fil.value === filter.value ) )
                                            return null;
                                        else
                                            return <option value={index} key={filter.value}>{filter.name}</option>;
                                    }
                                ) }
                            </select>
                        </p>
                        <ul className='filter-list'>
                            { !xFilters || !xFilters.length?
                                <li className='filter-item'>Sin filtro</li>
                                :
                                xFilters.map( (filter, index)=>
                                    <li className='filter-item' key={filter.value}>{filter.name}
                                        <i className='fa fa-close' onClick={removeXFilter} data-index={index}/>
                                    </li>
                                )
                            }
                        </ul>
                    </Col>

                    <Col md={6} xs={12}>
                        <p className='filter-title'>
                            Selecciona filtros para el eje Y
                            <select value='' onChange={addYFilter} className='form-control'>
                                <option value='' disabled>Agrega un filtro</option>
                                { yFilter? null : availableFilters.map( (filter, index)=> {
                                    if( _.find( xFilters, (fil)=>fil.value === filter.value ) )
                                        return null;
                                    else
                                        return <option value={index} key={filter.value}>{filter.name}</option>;
                                    }
                                ) }
                            </select>
                        </p>
                        <ul className='filter-list'>
                            {yFilter ?
                                <li className='filter-item'>
                                    {yFilter.name}
                                    <i className='fa fa-close' onClick={removeYFilter}/>
                                </li>
                                :
                                <li className='filter-item'>
                                    Sin filtro
                                </li>
                            }
                        </ul>
                    </Col>

                </Row>

            </Grid>

            <div className='table-container'>
                <Table >

                    <thead>
                    { tableData.headerRows.map( ( row, i )=>
                        <tr key={i}>
                            {yFilter?
                                <th>~</th>
                                :null}
                            { row.map( (col, j)=>
                                <th colSpan={col.size} key={`${i}-${j}`}>{col.name}</th>
                            )}
                            { xFilters && xFilters.length && (tableData.headerRows.length-1) === i?
                                <th>Total</th>
                                :null}
                        </tr>
                    ) }
                    </thead>

                    <tbody>

                    {tableData.rowHeaders.map( (rHeader, i)=>

                        <tr key={rHeader}>
                            {yFilter?
                                <th>{rHeader? rHeader : ('Sin ' + yFilter.name)}</th>
                                :
                                null
                            }

                            { tableData.columnsCodes.map( (code, j)=>{
                                const path = code.split( SEPARATOR );
                                let entries = tableData.data[ path[0] ];

                                if( yTotals.length < (j+1) )
                                    yTotals.push(0);

                                if( xTotals.length < (i+1) )
                                    xTotals.push(0);

                                for( let i=1; i<path.length; i++ ){
                                    if( entries && entries.subHeaders && entries.subHeaders[ path[i] ] )
                                        entries = entries.subHeaders[ path[i] ];
                                    else {
                                        return <td className='empty' key={code}>0</td>;
                                    }
                                }
                                if( entries && entries.count ) {

                                    xTotals[i] += Number(entries.count);
                                    yTotals[j] += Number(entries.count);
                                    return <td key={code}>{entries.count}</td>;
                                }
                                if( entries && entries[ rHeader ] && entries[ rHeader ].count) {

                                    xTotals[i] += Number(entries[rHeader].count);
                                    yTotals[j] += Number(entries[rHeader].count);
                                    return <td key={code}>{entries[rHeader].count}</td>;
                                }
                                else
                                    return <td className='empty' key={code}>0</td>;
                            })}

                            { xFilters && xFilters.length?
                                <td>{xTotals[i]}</td>
                                :null}

                        </tr>

                    )}

                    { yFilter?
                        <tr>
                            <th style={{width:240}}>Total</th>
                            {yTotals.map( (total, i)=><td key={i}>{total}</td> )}

                            { xFilters && xFilters.length?
                                <td><strong> { yTotals.reduce( (acc, t)=>acc+t, 0 ) || xTotals.reduce( (acc, t)=>acc+t, 0 ) } </strong> </td>
                                :null}
                        </tr>
                        :null}

                    </tbody>

                </Table>
            </div>

        </div>
    );
};

const mapStateToProps = ( {api:{layoffsReport}} )=>({layoffsReport});

export default connect(mapStateToProps)(LayoffsReport);

const availableFilters = [
    { name:'Área', value:'area' },
    { name:'Grupo', value:'group' },
    { name:'Meses', value:'month' },
    { name:'Puesto', value:'position' },
    { name:'Sala', value:'lounge' },
    { name:'Turnos', value:'shift' },
];
