import React, {useContext, useEffect, useMemo, useState} from 'react';
import './_PurchaseProductAnalysis.scss';
import ClassicModal from "../../../../../components/Modals/ClassicModal";
import {Link} from "react-router-dom";
import {Table} from "react-bootstrap";
import {TideApiContext} from "../../../../../api/tideApiConfig";
import moneyFormatter from "../../../../../utils/moneyFormatter";
import {convertToSmallestUnit, displayAmount, pluralizeUnitName} from "../../../../../utils/modelUtils/almazen/unitsUtility";
import {stockMovementBatchType} from "../../../../../utils/modelUtils/almazen/stockMovementBatchUtils";
import {paths} from "../../../../../routes/almazen";
import {SecurityContext} from "../../../../../utils/SecurityManager";
import {useSelector} from "react-redux";
import {maxDecimals} from "../../../../../utils/MathUtils";

const PurchaseProductAnalysis = ({onHide, purchaseProduct, quote, stockRequest, purchaseRequisition}) => {

    const security = useContext(SecurityContext);
    const api = useContext(TideApiContext);
    const [lastProductQuantity, setLastProductQuantity] = useState();
    const [lastProductPrice, setLastProductPrice] = useState();
    const pb = purchaseProduct.productBrand;

    // ---- Get related stock request amount from server
    const [stockRequisitionAmount, setStockRequisitionAmount] = useState('');
    useEffect(()=>{
        if(!stockRequest)
            return;
        api.stockMovements.get({params:{
                'batch.stockRequest': stockRequest.id,
                'productBrand': purchaseProduct.productBrand.id,
                'batch.inOrOut': stockMovementBatchType.OUT
            }}).then( movements=>{
            if(!movements?.length)
                return;
            const total = movements.reduce( (total, move)=>total+Math.abs(move.quantity), 0);
            if(total)
                setStockRequisitionAmount( displayAmount(total, pb.product) );
        });
    },[purchaseProduct, api, stockRequest, pb]);
    // ---- END Get related stock request amount from server

    // --- get product consumption --
    const [consumptionPerGuest, setConsumptionPerGuest] = useState();
    useEffect(()=>{
        api.products.get({
            id: pb.product.id,
            params:{sGroups:['product_read_consumed_average_by_guest_in_period']}
        }).then(prod=>setConsumptionPerGuest(prod.consumedAverageByGuestInPeriod));
    },[api, pb]);

    useEffect(()=>{
        api.purchaseProducts.get({
            params:{
                'order[createdDate]': 'DESC',
                'exists[purchaseOrder]' : true,
                'productBrand.product.id' : pb.product.id,
                sGroups: ['purchase_product_read_measurement_unit', 'measurement_unit_read', 'purchase_product_read_quantity', 'purchase_product_read_purchase_product_quotes', 'purchase_product_quote_read_unit_price']
            }
        }).then((response)=> {
            setLastProductQuantity(response[0]);
            setLastProductPrice(response[0].purchaseProductQuotes[0].unitPrice)
        });
    },[api, pb]);
    // --- END get product consumption --
    // ---- Get guests count in last month
    const [guestsInMonth, setGuestsInMonth] = useState();
    useEffect(()=>{
        api.guests.numberInPeriod().then(resp=>setGuestsInMonth(resp?.total));
    },[api]);
    // ---- END Get guest in last month

    // ---- Compute days the product will last ----
    const days = useMemo(()=>{
        if(!guestsInMonth || !consumptionPerGuest)
            return null;
        const guestsPerDay = guestsInMonth/30;
        const guestsServed = purchaseProduct.smallestUnitQuantity/consumptionPerGuest;
        console.log(guestsServed,guestsPerDay, guestsInMonth);
        return guestsServed/guestsPerDay;
    },[guestsInMonth, consumptionPerGuest, purchaseProduct]);

    // --- Get inventory in all warehouses ---
    const customProp="PurchaseProductAnalysis.inventories";
    const inventories = useSelector(s=>s.api[customProp])||[];
    useEffect(() => {
        api.clearProperty(customProp);
        if(security.almazen.canListInventories()){
            api.inventories.get({
                params: {
                    productBrand:purchaseProduct.productBrand.id,
                    pagination:false},
                customProp});
        }
    }, [api, security, purchaseProduct]);
    // --- END Get inventory in all warehouses ---

    // --- Computing last PO price
    const [lastPrice, lastColor] = useMemo(()=>{
        if(!purchaseProduct.previousPurchaseSmallestUnitPrice)
            return [null, ''];
        try {

            const price = convertToSmallestUnit(
                purchaseProduct.previousPurchaseSmallestUnitPrice,
                purchaseProduct.measurementUnit,
                pb.product);
            if(!price)
                return [null, ''];
            let color='';
            if(price<quote.unitPrice)
                color='red';
            else if(price>quote.unitPrice)
                color='green';
            return [moneyFormatter(price, 1), color];
        }catch (e){ console.log(e) }
        return [null, ''];
    },[purchaseProduct, quote, pb]);
    // --- END Computing last PO price

    return (
        <ClassicModal
            onHide={onHide}
            className={"PurchaseProductAnalysis"}
            title={"Análisis de compra"}
            bsSize={'lg'}
            showConfirm={false}
        >
            <h4 className='product-title'>{pb?.product?.name} - {pb?.brand?.name}</h4>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='info-box'>
                            <p className='box-title'>Precio unitario</p>
                            <p className='box-value'>{moneyFormatter(quote.unitPrice, 1)}</p>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='info-box'>
                            <p className='box-title'>Cantidad solicitada</p>
                            <p className='box-value'>{Number(purchaseProduct.quantity)} {pluralizeUnitName(purchaseProduct.quantity, purchaseProduct?.measurementUnit?.name)}</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='info-box'>
                            <p className='box-title'>Precio última compra</p>
                            <p className={'box-value '+lastColor}>
                                {lastPrice||`$ ${ Number(lastProductPrice).toFixed(2)}`||'Sin info'}
                            </p>
                            <p className='box-link'><Link to={`/compras/orders/${purchaseRequisition?.purchaseOrders[0].id}`}>Ir a orden de compra</Link></p>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='info-box'>
                            <p className='box-title'>Cantidad última compra</p>
                            <p className='box-value'>{Number(lastProductQuantity?.quantity)||'Sin info'} {pluralizeUnitName(lastProductQuantity?.quantity, purchaseProduct?.measurementUnit?.name)}</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-12'>
                        <div className='info-box'>
                            <p className='box-title'>Requisición de almacén</p>
                            <p className='box-value'>{stockRequisitionAmount||'Sin info'}</p>
                            {stockRequest &&
                            <p className='box-link'><a href={paths.stockRequestDetail.replace(':id', stockRequest.id)}>
                                Ir a requisición
                            </a></p>}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-12'>
                        <div className='info-box large'>
                            <p className='box-title'>Consumo de {pb.product.name}</p>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <p className='box-value'>{!consumptionPerGuest?'Sin info':
                                            displayAmount(consumptionPerGuest*100, pb.product)
                                        }</p>
                                        <p className='box-link'>por cada 100 huéspedes</p>
                                    </div>
                                    <div className='col-sm-4'>
                                        <p className='box-value'>{(guestsInMonth || '-')+" huéspedes"}</p>
                                        <p className='box-link'>En lo últimos 30 días</p>
                                    </div>
                                    <div className='col-sm-4'>
                                        <p className='box-value'>{days?`${maxDecimals(days, 2)} días`:"Sin info"}</p>
                                        <p className='box-link'>Alcanzará el producto</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xs-12'>
                        <div className='info-box large inventory-box'>
                            <p className='box-title text-center'>Inventarios</p>

                                <Table>
                                    <tbody>
                                    <tr>
                                        <th>Almacén/Sala</th>
                                        <th>Marca</th>
                                        <th>Cantidad</th>
                                    </tr>
                                    {inventories.map((inventory) => (
                                        <tr key={inventory.id}>
                                            <td>{inventory.warehouse.name}</td>
                                            <td>{inventory.productBrand?.brand?.name}</td>
                                            <td>{displayAmount(inventory.stockAmount, pb.product)}</td>
                                        </tr>
                                    ))}

                                    </tbody>
                                </Table>

                        </div>
                    </div>
                </div>

            </div>
        </ClassicModal>
    );
};

export default PurchaseProductAnalysis;
