import React, {useContext, useEffect} from 'react';
import {Modal, Table} from 'react-bootstrap';
import Button from "../../../elements/CustomButton/CustomButton";
import {ApiContext} from "../../../api/Api";
import {connect} from "react-redux";
import moment from 'moment';


const loadingId = '@ProviderEvaluationsModal.providerEvaluations.get';

const ProviderEvaluationsModal = ({provider, onHide, providerEvaluations, loadingIds}) => {

    const api = useContext(ApiContext);

    useEffect( ()=>{
        api.providerEvaluations.get(0, 200, loadingId, {provider: provider.id} )
    }, [provider, api] );

    return (
        <Modal
            show={true}
            onHide={onHide}
            bsSize={'lg'}
        >
            <Modal.Header>
                <Modal.Title>Evaluaciones de {provider.commercialName}</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                {loadingIds[loadingId] ?
                    <h4>Cargando...</h4>
                    :
                    <Table className='text-center' striped>
                        <tbody>
                        <tr>
                            <th>Fecha</th>
                            <th>No se respeto el precio acordado</th>
                            <th>No se respeto el tiempo de entrega</th>
                            <th>No se respeto cantidad solicitada</th>
                            <th>Se entregó producto en mal estado</th>
                        </tr>
                        { providerEvaluations.map( pe=>
                            <tr>
                                <td>{moment(pe.fromDate).format('DD')} {moment(pe.fromDate).format('MMMM')} {moment(pe.fromDate).year()}</td>
                                <td>{pe.differentPriceCount}</td>
                                <td>{pe.lateDeliveryCount}</td>
                                <td>{pe.incompleteDeliveryCount}</td>
                                <td>{pe.badQualityCount}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle='danger' className='pull-left' onClick={onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
};


const mapStateToProps = ({api: { providerEvaluations=[] }, loadingIds }) => ({providerEvaluations, loadingIds});

export default connect(mapStateToProps)(ProviderEvaluationsModal);
