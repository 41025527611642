import {ACTION_CACHE_SAVE, initialCache} from "./constants_api";
import LocalStorageState from "../utils/LocalStorage";
import md5 from 'js-md5';


export const reducer = (state = initialCache, action)=>{


    if( action.type === ACTION_CACHE_SAVE ) {

        const { url, params, data } = action.payload;
        const newCache = { ...state };

        const key = createCacheKey( url, params);
        newCache[key] = data;

        let loc = new LocalStorageState();
        loc.store({apiCache: newCache});

        return newCache;
    }

    return state;
};

export const createCacheKey = ( url, params )=>{

    if( !params )
        params = '';
    return md5( url + JSON.stringify(params) );
};

export const searchCache = ( url, params, cache )=>{

    const key = createCacheKey( url, params);

    if( typeof cache !== 'object' )
        return;

    return cache[key];
};