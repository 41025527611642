import React, {useMemo} from "react";
import ReactTable from "react-table";
import useTideTable from "../../../../hooks/table/useTideTable";
import moneyFormatter from "../../../../utils/moneyFormatter";
import {useParams} from 'react-router-dom';
import moment from "moment";
import useTideEntity2 from "../../../../hooks/useTideEntity2";

const columnsConfig = [
    {
        Header: 'Presupuesto mensual',
        id: 'currentBudget',
        filterable: false,
        accessor: (budget) => budget.currentBudget||budget.currentBudget===0 ? `${moneyFormatter(budget.currentBudget,1)}` : 'Sin definir'
    },
    {
        Header: 'Presupuesto acumulado',
        id: 'accumulatedBudget',
        filterable: false,
        accessor: (budget) => budget.accumulatedBudget||budget.accumulatedBudget===0 ? `${moneyFormatter(budget.accumulatedBudget,1)}` : 'Sin definir'
    },
    {
        Header: 'Presupuesto gastado',
        id: 'spentBudget',
        filterable: false,
        accessor: (budget) => (budget.spentBudget||budget.spentBudget===0) ? `${moneyFormatter(budget.spentBudget,1)}` : 'Calculado a final de mes'
    },
    {
        Header: 'Inicio de presupuesto',
        id:'dateStartBudget',
        filterable: false,
        accessor: (budget) => budget.dateStartBudget ? `${moment(budget.dateStartBudget).format("DD/MM/YYYY")}` : 'Sin definir'
    },
    {
        Header: 'Fin de presupuesto',
        id:'dateEndBudget',
        filterable: false,
        accessor: (budget) => budget.dateEndBudget ? `${moment(budget.dateEndBudget).format("DD/MM/YYYY")}` : 'Sin definir'
    }
];

const JazzAreaHistory = () => {
    const areaId = useParams().id;
    const tableConfig = useMemo(() => {
        return {
            entity:'jazzAreaBudgetLogs',
            columns: columnsConfig,
            newTideApi: true,
            requestFilters: {'jazzArea': areaId}
        }
    }, [areaId]);
    const budgetTable = useTideTable(tableConfig);

    const [area] = useTideEntity2('jazzAreas', areaId);

    return (
        <div className='JazzAreaHistory'>
            <div className="container-fluid main-container">
                <h4 className='text-muted title-desc'>Presupuestos de {area?area.name : "Área"}</h4>
                <ReactTable
                    {...budgetTable.tableProps}
                />
            </div>
        </div>
    )
}

export default JazzAreaHistory;
