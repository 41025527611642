import moment from "moment";

export const prepareHolidayForServer = (holidayName, holidayDate) => {  
    if(!holidayName)
        throw new Error('Debes escribir el nombre del día festivo.');

    if(!holidayDate)
        throw new Error('Debes seleccionar la fecha del día festivo.');
        
    return {
        name: holidayName,
        date: holidayDate
    };
}

export const holidayColumns = [
    {
        Header:'Nombre',
        accessor: 'name',
        filterable: false,
    },
    {
        Header:'Fecha',
        id: 'date',
        accessor: (d) => moment(d.date).format('DD/MM/YYYY'),
        filterable: false,
    }
];

export const holidaysSGroups = [
    'public_holiday_read'
];