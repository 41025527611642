import React, {useContext} from 'react';
import {Table} from 'react-bootstrap';
import {ApiContext} from "../../api/Api";
import CustomButton from "../../elements/CustomButton/CustomButton";
import moment from 'moment';
import {useSelector} from "react-redux";
import {getTimeElapsedString} from "../../utils/TimeConverter";
import {employeeFullName} from "../../utils/modelUtils/alma/employeeUtils";
import pluralize from 'pluralize';


const loadingId = '@ApprovalsTable.edit';

const ApprovalsTable = ( {approvals, onChange, approvalEntityName} )=>{

    if(!approvalEntityName )
        throw new Error('The ApprovalsTable component requires an approvalEntityName.');

    const api= useContext( ApiContext );

    const onApprove = (approvalId) => {
        const entity=pluralize.singular(approvalEntityName);
        api[approvalEntityName].edit({id:approvalId, [entity]:{status: 'approved'}, loadingId})
            .then(onChange);
    };

    const onDeny = (approvalId) => {
        const entity=pluralize.singular(approvalEntityName);
        api[approvalEntityName].edit({id:approvalId,[entity]:{status: 'denied'}, loadingId})
            .then(onChange);
    };

    const me= useSelector(({api})=>api.me);
    const loading=useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    if(!approvals || !approvals.length)
        return <h4 className='text-center'>No hay aprobaciones definidas</h4>;

    return (
            <div>
                <h4 className='text-center'>Aprobaciones</h4>
                <Table responsive>

                    <thead>
                    <tr>
                        <th className='text-center col-xs-1'>#</th>
                        <th>Persona</th>
                        <th className=''>Respuesta</th>
                        <th>Fecha de revisión</th>
                    </tr>
                    </thead>

                    <tbody>

                    {
                        approvals.map((approval, i) =>
                            <tr className='product-row' key={approval.id}>
                                <td className='text-center'> {i + 1}</td>
                                <td>{employeeFullName(approval.employee)}</td>
                                <td>{
                                    approval.employee.id === me.employee?.id &&
                                    approval.status === 'pending' ?
                                    <>
                                        <CustomButton
                                            bsStyle='danger'
                                            onClick={() => onDeny(approval.id)}
                                            disabled={loading}>
                                            Denegar
                                        </CustomButton>&nbsp;
                                        <CustomButton
                                            bsStyle='success'
                                            onClick={() => onApprove(approval.id)}
                                            disabled={loading}>
                                            Aprobar
                                        </CustomButton>
                                    </>
                                    :
                                    (approval.status === 'pending' || approval.status === 'waiting_others_approval' ?
                                        <span
                                            className='alert alert-warning'>Pendiente</span>
                                        : null)}

                                    {approval.status === 'approved' ?
                                        <span
                                            className='alert alert-success'>Aprobado</span>
                                        : null}
                                    {approval.status === 'denied' ?
                                        <span
                                            className='alert alert-danger'>Denegado</span>
                                        : null}

                                </td>
                                <td>
                                    {(approval.status === 'approved') || (approval.status === 'denied') ?
                                        <span>{moment(approval.updatedDate).format("DD/MM/YYYY HH:mm")} - Tiempo de respuesta {getTimeElapsedString( moment( i===0?approval.createdDate:approvals[i-1].updatedDate), moment( approval.updatedDate ) )} </span>
                                        :
                                        <span> - </span>
                                    }
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </div>
    );
};


export default ApprovalsTable;
