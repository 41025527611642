import React, {useCallback, useEffect} from 'react';
import useBoolean from "../../../../../hooks/useBoolean";
import {mapItemsDefinition} from "tgle-core/utils/loungeMapItemUtils";
import _ from 'lodash';
import '../../style/_ElementToolbox.scss';
import InputConfigTool from "./InputConfigTool";


const ElementToolbox = ({loungeMapItem, onChange, onDelete})=>{

    const [isOpen,,, toggleOpen] = useBoolean(true);
    const definition = _.find(mapItemsDefinition(), def=>def.type===loungeMapItem.type);

    // ---- Name change handler -----
    const handleNameChange = useCallback((e)=>{
        onChange({...loungeMapItem, name: e.target.value });
    },[loungeMapItem, onChange]);

    //  -------- Deletion
    const handleDeleteClick = useCallback(()=>onDelete(loungeMapItem), [loungeMapItem, onDelete]);

    useEffect(()=>{
        const delKey = (e)=>{
            const key = e.key || e.keyCode;
            if (key === 'Delete' || key === 46)
                handleDeleteClick()
        };
        window.addEventListener('keyup', delKey);
        return ()=>window.removeEventListener('keyup', delKey);
    },[handleDeleteClick]);

    const ComponentToolbox = definition.toolbox;
    return (
        <div className='ElementToolbox'>
            <h5 onClick={toggleOpen} className='toolbox-title'>{definition.name} <i className={'fa fa-caret-'+(isOpen?'up':'down')}/></h5>

            {isOpen &&
            <div className='toolbox'>
                <span className='toolbox-label'>Nombre:</span> <input className='toolbox-input' value={loungeMapItem.name} onChange={handleNameChange}/> <br/>
                <InputConfigTool loungeMapItem={loungeMapItem} onChange={onChange} field='x' displayName='x' type='number'/>
                <InputConfigTool loungeMapItem={loungeMapItem} onChange={onChange} field='y' displayName='y' type='number'/>

                {definition.toolbox &&
                    <ComponentToolbox
                        loungeMapItem={loungeMapItem}
                        onChange={onChange}
                    />}

                <button className='toolbox-button delete-button' onClick={handleDeleteClick}>Eliminar</button>
            </div>}
        </div>
    );
};

export default ElementToolbox;
