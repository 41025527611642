import React, {useEffect, useState} from 'react';
import moment from "moment";

const GolfTripTimer = ({startDate}) => {
    const [passedHours, setPassedHours] = useState(0);
    const [passedMinutes, setPassedMinutes] = useState(0);
    const [passedSeconds, setPassedSeconds] = useState(0);

    useEffect(
        () => {
            let timerInterval = setInterval(() => {
                    const now = moment();
                    setPassedHours(now.diff(moment(startDate), 'hours'));
                    setPassedMinutes(now.diff(moment(startDate), 'minutes')%60);
                    setPassedSeconds(now.diff(moment(startDate), 'seconds')%60);
                }
                , 1000
            );

            // this will clear Timeout
            // when component unmount like in willComponentUnmount
            // and show will not change to true
            return () => {
                clearInterval(timerInterval);
            };
        },
        // useEffect will run only one time with empty []
        // if you pass a value to array,
        // like this - [data]
        // than clearTimeout will run every time
        // this value changes (useEffect re-run)
        [startDate]
    );
    return (
        <span className={"GolfCartTripForm"}>
            {passedHours.toString().padStart(2,'0')}:{passedMinutes.toString().padStart(2,'0')}:{passedSeconds.toString().padStart(2,'0')}
        </span>
    );
};

export default GolfTripTimer;
