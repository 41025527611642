export default {
    shim: ()=>{
        if( !Number.isNaN ){
            const n = Number;
            Object.defineProperty(n, 'isNaN', {
                value:window.isNaN,
                configurable: true,
                writable: true
            } );
        }

    }
}