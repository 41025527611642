import React, {useCallback, useContext, useState} from 'react';
import {Modal, ModalHeader, ModalTitle, ModalBody} from 'react-bootstrap';
import useTideEntity from '../../../hooks/useTideEntity';
import {guestFullName} from "../../../utils/modelUtils/davinci/guestUtils";
import moment from 'moment';
import {SecurityContext} from "../../../utils/SecurityManager";
import _ from 'lodash';
import useBoolean from "../../../hooks/useBoolean";
import EntitySelect from "../../../components/EntitySelect/EntitySelect";
import {flightLabelCreator} from "../../../utils/modelUtils/ee/flightUtils";
import {TideApiContext} from "../../../api/tideApiConfig";
import {visitDetailSGroups, visitListSGroups} from "../../../utils/modelUtils/davinci/visitUtils";
import moneyFormatter from "../../../utils/moneyFormatter";
import {NotifierContext} from "../../../utils/Notifier";
import {flattenLoungeAccessMethodsTree, getLoungeAccessMethodNameIncludingParents} from "../../../services/modelUtils/loungeAccessMethodsUtils";
import TideEntitySelect from "../../../components/TideEntitySelect/TideEntitySelect";
import CommentsFeed from "../../../components/CommentsFeed/CommentsFeed";
import CancelCheckInModal from "./CancelCheckInModal";

const extraCharge = vop=> vop.orozcoProduct.isExtraCharge ;

const loungeAccessMethodFilters={'order[name]':'ASC', isActive:true, 'loungeParentAccessMethod[exists]':false};
const loungeFilters = {isLounge: true};

const requestFilters = {sGroups: visitDetailSGroups};
const VisitDetailModal = ({onHide, visitId}) =>{
    const [visit,loading, notFound, {reload}] = useTideEntity('visits', visitId, {
        loadingId: '@VisitDetailModal.visit',
        requestFilters,
        customProp: 'visitDetail',
    });

    const allProducts = visit&&visit.orozcoVisitOrder?visit.orozcoVisitOrder.orozcoVisitOrderProducts:[];
    const products = allProducts.filter(x=>!extraCharge(x));
    const extras = allProducts.filter(extraCharge);
    const services = visit?visit.serviceVisits:[];
    const payments = visit&&visit.orozcoVisitOrder?visit.orozcoVisitOrder.orozcoVisitOrderPayments:[];

    const canceled = visit?.checkIns.filter(check=>check.canceledDate);

    const security = useContext(SecurityContext);
    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);
    const [currentCheckIn, setCurrentCheckIn] = useState();
    const [editLounge, setEditLounge] = useState(false);
    const [adultsNumber, setAdultNumbers] = useState();
    const [openEditAdultsNumber, setOpenEditAdultNumbers] = useState(false);
    const [kidsNumber, setKidsNumber] = useState();
    const [openEditKidsNumber, setOpenEditKidsNumbers] = useState(false);
    const [editingFlight,,stopEditingFlight,toggleEditingFlight] = useBoolean();
    const [cancelingCheckIn, setCancelingCheckIn] = useState();
    const closeCancelCheckIn = useCallback(()=>setCancelingCheckIn(null),[]);

    const handleFlightChange = useCallback((flight)=>{
        if(!flight) return;
        api.visits.update({id: visit.id, params:{flight: flight.id, sGroups: visitListSGroups}}).then(()=>{
            reload();
            stopEditingFlight();
        });
    },[visit, api, stopEditingFlight, reload]);

    const handleAccessMethodChange = useCallback((accessMethod)=>{
        if(!accessMethod) return;
        const checkInToEdit = { id: currentCheckIn, loungeAccessMethod: accessMethod.id };
        let oldCheckInsIds = visit.checkIns.map(checkIn => checkIn.id);
        oldCheckInsIds = _.filter( oldCheckInsIds, (checkInId) => checkInId!==checkInToEdit.id );

        api.visits.update({id: visit.id,
            params:{
                checkIns: [
                ...oldCheckInsIds,
                checkInToEdit
            ]
        }}).then(()=>{
            reload();
            setCurrentCheckIn(null);
        }).catch(() => {
            notifier.error("No es posible modificar el método de acceso debido a que tiene pagos relacionados.");
        });
    },[visit, currentCheckIn, api, reload, notifier]);

    const handleLoungeChange = useCallback((lounge) => {
        if (!lounge)
            return;

        api.visits.update({id: visit.id, params:{lounge: lounge.id, sGroups: visitListSGroups}}).then(()=>{
            reload();
            setEditLounge(!editLounge);
        }).catch(() => {
            notifier.error("No fue posible modificar la sala.");
        });

    }, [visit, api, reload, editLounge, setEditLounge, notifier]);

    const handleAdultsNumberChange = useCallback((e) => {
        setAdultNumbers(e.currentTarget.value);
    }, [setAdultNumbers]);

    const handleKidsNumberChange = useCallback((e) => {
        setKidsNumber(e.currentTarget.value);
    }, [setKidsNumber]);

    const handleAdultsSave = useCallback((checkInId) => {
        const checkInToEdit = {id: checkInId, adultsNumber: Number(adultsNumber) === 0 ? 1 : Number(adultsNumber) + 1};
        let oldCheckInsIds = visit.checkIns.map(checkIn => checkIn.id);
        oldCheckInsIds = _.filter( oldCheckInsIds, (checkInId) => checkInId !== checkInToEdit.id);

        api.visits.update({id: visit.id, params: {checkIns: [...oldCheckInsIds, checkInToEdit]}})
            .then(() => {
            reload();
            setOpenEditAdultNumbers(!openEditAdultsNumber);
        }).catch(() => {
            notifier.error("No es posible modificar el número de acompañantes.");
        });

    }, [visit, adultsNumber, api, reload, notifier, openEditAdultsNumber]);

    const handleKidsSave = useCallback((checkInId) => {
        const checkInToEdit = {id: checkInId, kidsNumber: Number(kidsNumber)};
        let oldCheckInsIds = visit.checkIns.map(checkIn => checkIn.id);
        oldCheckInsIds = _.filter(oldCheckInsIds, (checkInId) => checkInId !== checkInToEdit.id );

        api.visits.update({id: visit.id, params:{ checkIns: [...oldCheckInsIds, checkInToEdit]}})
            .then(() => {
            reload();
            setOpenEditKidsNumbers(!openEditKidsNumber);
        }).catch(() => {
            notifier.error("No es posible editar el número de niños.");
        });
    }, [visit, kidsNumber, api, reload, notifier, openEditKidsNumber]);

    const startEditingAccessMethod = useCallback(( checkInId ) => {
        setCurrentCheckIn(checkInId);
    }, []);

    const handleCancelSuccess = useCallback(()=>{
        setCancelingCheckIn(null);
        reload();
    },[reload]);

    return (
        <Modal show onHide={onHide} className='VisitDetailModal' bsSize='lg'>
            <ModalHeader closeButton>
                <ModalTitle>Detalle de la visita</ModalTitle>
            </ModalHeader>
            <ModalBody>
                {notFound && 'Visita no encontrada'}
                {(loading || !visit)?'Cargando información de la visita...':(
                    <div className='container-fluid'>
                        <h4 className='guest'>{guestFullName(visit.guest)}</h4>
                        <div className='row'>
                            <div className='col-xs-12 col-md-6 date'>
                                {moment(visit.createdDate).format('DD/MM/YYYY HH:mm')}<br/>
                                Folio: {visit.folio}<br/>
                            </div>
                            <div className='col-xs-12 col-md-6 lounge'>
                                <i className="fa fa-pencil pointer" onClick={() => setEditLounge(!editLounge)}/>
                                <b>{visit.lounge.name}</b>
                                {editLounge ?
                                    <TideEntitySelect
                                        entity='lounges'
                                        onChange={handleLoungeChange}
                                        filterBy='search'
                                        additionalFilters={loungeFilters}
                                        filterLocal
                                    /> : null}
                            </div>
                            <p className={"col-xs-12"}>Recepcionista: {visit.createdBy?.employee?.fullName || "Sin información"}</p>
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-xs-12 col-md-6'>
                                <p className='text-center'>Anfitriones:</p>
                                <ul>
                                    {visit.employeesAttendingVisit?.length?
                                        visit.employeesAttendingVisit.map((eav,i)=><li key={i}>{eav.employee.fullName}</li>):
                                        'No se asignó anfitrión en sistema.'
                                    }
                                </ul>
                            </div>
                            <div className='col-xs-12 col-md-6 text-center'>
                                <i className='fa fa-plane' /> {visit.flight.number} - {visit.flight.destination} <br/>
                                {security.ee.canEditVisits() &&
                                    <div className='visit-flight-edition'>
                                        {!editingFlight?
                                        <button className='button' onClick={toggleEditingFlight}>Editar</button>:
                                            <EntitySelect
                                                entity='flights'
                                                labelCreator={flightLabelCreator}
                                                onChange={handleFlightChange}
                                                filterBy='search'
                                            />}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xs-12'>
                                <hr/>
                                <h5>Check ins durante la visita</h5>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <th>Método de acceso</th>
                                            <th>Acompañantes</th>
                                            <th>Niños</th>
                                            <th>Folio</th>
                                            {security.ee.canCancelCheckIns()&&<th>Cancelar</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {visit.checkIns.map((checkIn) => (
                                            <tr key={checkIn.id}>
                                                {/* Lounge Access Method */}
                                                <td>{getLoungeAccessMethodNameIncludingParents(checkIn.loungeAccessMethod)}
                                                {security.ee.canEditVisits() &&
                                                    <div className='visit-flight-edition'>
                                                    {currentCheckIn !== checkIn.id ? (
                                                        <i className="fa fa-pencil pointer" onClick={() => startEditingAccessMethod(checkIn.id)}/>
                                                        ) : (
                                                        <div>
                                                            <TideEntitySelect
                                                                entity='loungeAccessMethods'
                                                                onChange={handleAccessMethodChange}
                                                                additionalFilters={loungeAccessMethodFilters}
                                                                apiCustomProp={'SingleCheckInLoungeAccessMethods'}
                                                                filterEntities={flattenLoungeAccessMethodsTree}
                                                                filterLocal
                                                            />
                                                        </div>
                                                        )
                                                    }
                                                    </div>
                                                }</td>
                                                {/* Companions */}
                                                <td>
                                                    {checkIn.adultsNumber-1/*adultsNumber also count the guest*/}
                                                    <i className="fa fa-pencil pointer" onClick={() => setOpenEditAdultNumbers(!openEditAdultsNumber)}/>
                                                    {openEditAdultsNumber ?
                                                        <>
                                                            <br/>
                                                            <input type='number'
                                                                   min='0'
                                                                   max={checkIn.loungeAccessMethod.maxAdults - 1}
                                                                   onChange={handleAdultsNumberChange}/>
                                                            <i className="fa fa-floppy-o pointer" onClick={() => handleAdultsSave(checkIn.id)}/>
                                                        </> : null}
                                                </td>
                                                {/* Kids */}
                                                <td>
                                                    {checkIn.kidsNumber}
                                                    <i className="fa fa-pencil pointer" onClick={() => setOpenEditKidsNumbers(!openEditKidsNumber)}/>
                                                    {openEditKidsNumber ?
                                                        <>
                                                            <br/>
                                                            <input
                                                                type='number'
                                                                min='0'
                                                                max={checkIn.loungeAccessMethod.maxKids}
                                                                onChange={handleKidsNumberChange}/>
                                                            <i className="fa fa-floppy-o pointer" onClick={() => handleKidsSave(checkIn.id)}/>
                                                        </> : null}
                                                </td>

                                                <td>{checkIn.folio}</td>
                                                {security.ee.canCancelCheckIns()&&
                                                <td>
                                                    {checkIn.canceledDate? "Cancelado":
                                                    <button className='button-clear-def' onClick={()=>setCancelingCheckIn(checkIn)}>
                                                        <i className="fa fa-trash" />
                                                    </button>}
                                                </td>}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {!!canceled?.length &&
                            <div className='col-xs-12'>
                                <hr/>
                                <h5>Cancelación:</h5>
                                {canceled.map((checkIn)=>
                                    <p key={checkIn.id}>{moment(checkIn.canceledDate).format('DD/MM/YYYY HH:mm')}:<br/>
                                        {checkIn.cancellationReason}
                                    </p>)}
                            </div>}
                            <div className='col-xs-12'>
                                <hr/>
                                <h5>Productos pedidos</h5>
                                {products.length>0?(
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Cantidad</th>
                                                <th>Producto</th>
                                                <th>Fecha</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products.map(x=>(
                                                <tr key={x.id}>
                                                    <td>{x.quantity}</td>
                                                    <td>{x.orozcoProduct.name}</td>
                                                    <td>{moment(x.createdDate).format("HH:mm DD/MM/YYYY")}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ):'No se pidieron productos en la visita.'}
                            </div>
                            <div className='col-xs-12'>
                                <hr/>
                                <h5>Cargos extra</h5>
                                {extras.length>0?(
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Cantidad</th>
                                                <th>Producto</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {extras.map(x=>(
                                                <tr key={x.id}>
                                                    <td>{x.quantity}</td>
                                                    <td>{x.orozcoProduct.name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ):'No hubo cargos extra en la visita.'}
                            </div>
                            <div className='col-xs-12'>
                                <hr/>
                                <h5>Servicios pedidos</h5>
                                {services.length>0?(
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Servicio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {services.map(x=>(
                                                <tr key={x.id}>
                                                    <td>{x.service.name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ):'No se pidieron servicios en la visita.'}
                            </div>
                            <div className='col-xs-12'>
                                <hr/>
                                <h5>Pagos realizados</h5>
                                {payments.length>0?(
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>Cantidad</th>
                                                <th>Moneda</th>
                                                <th>Método de pago</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payments.map(x=>(
                                                <tr key={x.id}>
                                                    <td>{moneyFormatter(x.amount)}</td>
                                                    <td>{x.paymentCurrency}</td>
                                                    <td>{x.orozcoPaymentMethod.name}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ):'No se pidieron servicios en la visita.'}
                            </div>
                            <div className='col-xs-12'>
                                <hr/>
                                <CommentsFeed cassetteId={visit?.cassette?.id} />
                            </div>

                        </div>
                        {!!cancelingCheckIn &&
                        <CancelCheckInModal
                            visit={visit}
                            onHide={closeCancelCheckIn}
                            checkIn={cancelingCheckIn}
                            onCancelSuccess={handleCancelSuccess}
                        />}
                    </div>
                )}
            </ModalBody>
        </Modal>
    );
};

export default VisitDetailModal;
