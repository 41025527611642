import QuestionnaireList from "../views/ee/Questionnaires/QuestionnaireList";
import QuestionnaireView from "../views/ee/Questionnaires/QuestionnaireView";
import FilledQuestionnaireList from "../views/ee/Questionnaires/FilledQuestionnaireList";
import QuestionnaireStats from "../views/ee/Questionnaires/QuestionnaireStats";
import QuestionnaireEditorList from "../views/ee/Questionnaires/QuestionnaireEditorList";
import QuestionnaireEditor from "../views/ee/Questionnaires/QuestionnaireEditor";
import ReservationsList from "../views/ee/Reservations/ReservationsList";
import FilledQuestionnaireReviewList from "../views/ee/Questionnaires/FilledQuestionnaireReviewList";
import EE from "../views/ee/EE";
import LoungeAccessMethodsList from "../views/ee/davinci/AccessMethods/LoungeAccessMethodsList";
import CheckIns from "../views/ee/Visits/CheckIns";
import VisitsDashboard from "../views/ee/Visits/VisitsDashboard";
import VisitsList from '../views/ee/Visits/VisitsList';
import DishesCategories from "../views/ee/Dishes/DishesCategories";
import DishAdmin from "../views/ee/Dishes/DishAdmin";
//import ServicesAdmin from "../views/ee/ServicesAdmin";
import CashRegistersList from "../views/ee/davinci/CashRegisters/CashRegistersList";
import ActiveVisits from "../views/ee/davinci/ActiveVisits/ActiveVisits";
import DavinciReports from "../views/ee/DavinciReports/DavinciReports";
import DavinciReportsNps from "../views/ee/DavinciReportsNps/DavinciReportsNps";
import LoungeMapEditor from "../components/Maps/LoungeMapEditor/LoungeMapEditor";
import LoungeMapList from "../views/ee/orozco/LoungeMaps/LoungeMapList";
import PaymentsList from "../views/ee/orozco/Payments/PaymentsList";
import PrintsList from "../views/ee/orozco/Prints/PrintsList";
import FlightsManagement from 'views/ee/Flights/FlightsManagement/FlightsManagement';
import DestinationTypesManagement from 'views/ee/Flights/DestinationTypesManagement/DestinationTypesManagement';
import AirlinesManagement from 'views/ee/AirlinesManagement/AirlinesManagement';
import Guests from '../views/ee/Guests/Guests';
import EeReports from '../views/ee/EeReports/EeReports';
import AirportGateList from "../views/ee/GolfCart/AirportGateList/AirportGateList";
import GolfCartList from "../views/ee/GolfCart/GolfCartList/GolfCartList";
import GolfCartScheduleList from "../views/ee/GolfCart/GolfCartSchedules/GolfCartScheduleList";
import GolfCartScheduleDetail
    from "../views/ee/GolfCart/GolfCartSchedules/GolfCartScheduleDetail/GolfCartScheduleDetail";
import GolfCartDayControl from "../views/ee/GolfCart/GolfCartDayControl/GolfCartDayControl";
import GolfCartTripList
    from "../views/ee/GolfCart/GolfCartTrip/GolfCartTripList/GolfCartTripList";
//import HappinessLogReport from "../views/alma/HumanResources/HappinessLogReport";
//import ACReservationsList from "../views/ee/AirConciergeReservations/ACReservationsList";
//import TablesList from "../views/ee/orozco/Tables/TablesList";
//import ServiceRequestsList from "../views/ee/ServicesAdmin/ServiceRequestsList";
import EeReportsGcare from '../views/ee/EeReports/EeReportsGcare';
import {ReceptionistsReport} from "views/ee/Receptionists/ReceptionistsReport";
import { Calendar } from "views/ee/Calendar/Calendar";
import GolfCartDayList from "../views/ee/GolfCart/GolfCartDayList/GolfCartDayList";
import Help from "../views/ee/Help/Help";

export const paths = {
    ee: "/ee",
    questionnaires: "/ee/questionnaires",
    questionnaireView: "/ee/questionnaires/:id",
    questionnaireGCare: "/ee/questionnaires/gcare/:visit",
    questionnaireEditor: "/ee/questionnaire-editor",
    questionnaireEditorSingle: "/ee/questionnaire-editor/:id",
    questionnaireStats: "/ee/questionnaire-report/:id",

    filledQuestionnaires: "/ee/filled-questionnaires",
    filledQuestionnairesReview: "/ee/filled-questionnaire-review",

    reservations: "/ee/reservations",
    airConciergeReservations: "/ee/air-concierge-reservations",

    happinessReport: "/ee/happiness-report",

    loungeAccessMethods: "/ee/lounge-access-methods",

    davinciReports:"/ee/davinci-reports",
    npsReports:"/ee/davinci-nps-reports",

    tables: "/ee/tables",

    checkIns: "/ee/check-ins",
    visitsDashboard: "/ee/visits-dashboard",
    visitsList: "/ee/visits",
    activeVisits: "/ee/active-visits",

    dishesDashboard: "/ee/dishes-dashboard",
    dishesCategories: "/ee/dishes-categories",
    dishAdmin: "/ee/dish-admin",

    servicesAdmin: "/ee/services-admin",
    serviceRequests: "/ee/service-requests",

    loungeMapList: '/ee/lounge-map',
    loungeMapEditor: '/ee/lounge-map/:id',

    cashRegistersList: "/ee/cash-registers",
    paymentsList: "/ee/payments",
    ticketsList: "/ee/tickets",

    airlinesManagement: '/ee/airlines-management',
    flightsManagement: '/ee/flights-management',
    destinationTypesManagement: '/ee/destination-types-management',

    eeReports: '/ee/collect/reports',
    eeReportsGcare: '/ee/gcare/reports',

    airportGateList: '/ee/airport-gates/list',
    golfCartList: '/ee/golf-carts/list',
    golfCartScheduleList: '/ee/golf-cart-schedules',
    golfCartScheduleDetail: '/ee/golf-cart-schedules/:id',
    golfCartScheduleDayControl: '/ee/golf-cart-day-control',
    golfCartScheduleListView: '/ee/golf-cart-day-list-control',
    golfCartTrips: '/ee/golf-cart-trips',
    receptionistsReport: '/ee/receptionists',
    eeHelp: '/ee/help',
};

export default (securityManager) => {
    const routes = [];
    routes.push({path: paths.ee, name: "Experiencia Elite", component: EE, hidden: true, exact: true});

    if (!securityManager) return routes;

    if (securityManager.ee.canSeeCalendar() || securityManager.ee.canEditCalendar()) {
        routes.push({
            path: "/ee/calendar",
            name: "Calendario",
            icon: "fa fa-calendar",
            component: Calendar
        });
    }

    //Questionnaires
    if (securityManager.ee.canCreateQuestionnaires() && securityManager.ee.canReviewQuestionnaires()) {

        routes.push({path: paths.questionnaireView, name: "Cuestionario", component: QuestionnaireView, hidden: true});

        routes.push({path: paths.questionnaireGCare, name: "G-Care", component: QuestionnaireView, hidden: true});
        routes.push({
            path: paths.questionnaireStats,
            name: "Estadísticas del cuestionario",
            component: QuestionnaireStats,
            hidden: true
        });

        const questionnaires = {
            collapse: true, name: "Cuestionarios", state: "openQuestionnaires", icon: "pe-7s-note2", views: [
                {path: paths.questionnaires, name: "Ver cuestionarios", mini: "C", component: QuestionnaireList},
                {
                    path: paths.filledQuestionnaires,
                    name: "Cuestionarios resueltos",
                    mini: "CR",
                    component: FilledQuestionnaireList
                },
            ]
        };

        if (securityManager.ee.canEditQuestionnaires()) {
            questionnaires.views.push({
                path: paths.questionnaireEditor,
                name: "Editor de cuestionarios",
                mini: "EC",
                component: QuestionnaireEditorList
            });
            routes.push({
                path: paths.questionnaireEditorSingle,
                name: "Editor de cuestionarios",
                component: QuestionnaireEditor,
                hidden: true
            })
        }

        if (securityManager.ee.canReviewQuestionnaires())
            questionnaires.views.push({
                path: paths.filledQuestionnairesReview,
                name: "Revisión de cuestionarios",
                mini: "RC",
                component: FilledQuestionnaireReviewList
            });

        routes.push(questionnaires);
    } else {//Every one can fill questionnaires and see what they have filled
        routes.push({path: paths.questionnaireGCare, name: "G-Care", component: QuestionnaireView, hidden: true});
        routes.push({
            path: paths.questionnaires,
            name: "Cuestionarios",
            icon: "pe-7s-note2",
            component: QuestionnaireList
        });
        routes.push({
            path: paths.filledQuestionnaires,
            name: "Cuestionarios resueltos",
            icon: "pe-7s-notebook",
            component: FilledQuestionnaireList
        });
        routes.push({path: paths.questionnaireView, name: "Cuestionario", component: QuestionnaireView, hidden: true});
        routes.push({
            path: paths.questionnaireStats,
            name: "Estadísticas del cuestionario",
            component: QuestionnaireStats,
            hidden: true
        });

    }

    if (securityManager.ee.canSeeReservations())
        routes.push({path: paths.reservations, name: "Reservaciones", icon: "fa fa-book", component: ReservationsList});

    if(securityManager.ee.canSeeGuests()){
	routes.push({
	    path: "/ee/guests",
	    name: "Huéspedes",
	    icon: "fa fa-user",
	    component: Guests
	});
    }

    // if (securityManager.ee.canSeeAirConciergeReservations())
    //     routes.push({
    //         path: paths.airConciergeReservations,
    //         name: "Air Concierge",
    //         icon: "pe-7s-rocket",
    //         component: ACReservationsList
    //     });

    if(securityManager.ee.canManageFlights()){
        const flightsManagement = {
            collapse: true,
            name: "Vuelos",
            state: "openFlightsManagement",
            icon: "fa fa-plane",
            views: [
                {path: paths.flightsManagement, name: "Catálogo de vuelos", mini: "V", component: FlightsManagement},
                {
                    path: paths.destinationTypesManagement,
                    name: "Catálogo de tipos de destino",
                    mini: "TD",
                    component: DestinationTypesManagement
                }
            ]
        };

        routes.push(flightsManagement);
    }

    if(securityManager.ee.canManageAirlineOrigins()){
        routes.push({
            path: paths.airlinesManagement,
            name: "Gestión de Aerolíneas",
            component: AirlinesManagement,
            icon: "pe-7s-rocket"
        });
    }

    // if (securityManager.ee.canSeeHappinessReport())
    //     routes.push({
    //         path: paths.happinessReport,
    //         name: "Felicidad",
    //         icon: "fa fa-smile-o",
    //         component: HappinessLogReport
    //     });

    if (securityManager.ee.canSeeAccessMethods())
        routes.push({
            path: paths.loungeAccessMethods,
            name: "Métodos de acceso",
            icon: "fa fa-credit-card",
            component: LoungeAccessMethodsList
        });

    if( securityManager.ee.canSeeAirportGateList() ||
        securityManager.ee.canSeeGolfCartList() ||
        securityManager.ee.canSeeGolfCartScheduleList() ||
        securityManager.ee.canSeeGolfCartTripList()||
        securityManager.ee.canSeeGolfCartTripListReadonly() ||
        securityManager.ee.canStartAndEndTrips() ||
        securityManager.ee.canAdminTrips()){

        const views = [];

        if( securityManager.ee.canSeeGolfCartDayControl() ||
            securityManager.ee.canSeeGolfCartTripList() ||
            securityManager.ee.canSeeGolfCartTripListReadonly() ||
            securityManager.ee.canStartAndEndTrips() ||
            securityManager.ee.canAdminTrips()){
            views.push({
                path: paths.golfCartScheduleDayControl,
                smallName: "Agenda del día 📅",
                name: "Agenda de carritos",
                mini: "AD",
                component: GolfCartDayControl
            });
            views.push({
                path: paths.golfCartScheduleListView,
                smallName: "Agenda del día 📑",
                name: "Agenda de carritos",
                mini: "AD",
                component: GolfCartDayList
            });
        }
        if( securityManager.ee.canSeeGolfCartScheduleList() ){
            views.push({
                path: paths.golfCartScheduleList,
                name: "Programación de carritos",
                mini: "PC",
                component: GolfCartScheduleList
            });
        }

        if( securityManager.ee.canSeeGolfCartList() ){
            views.push({
                path: paths.golfCartList,
                name: "Lista de carritos",
                mini: "LC",
                component: GolfCartList
            });
        }
        if( securityManager.ee.canSeeAirportGateList() ){
            views.push({
                path: paths.airportGateList,
                name: "Puertas del aeropuerto",
                mini: 'PA',
                component: AirportGateList
            });
        }

        if (securityManager.ee.canSeeGolfCartTripList()) {
            views.push({
                path: paths.golfCartTrips,
                name: "Historial de Viajes",
                mini: 'HV',
                component: GolfCartTripList
            });
        }

        routes.push({collapse: true, name: "Carritos de Golf", state: 'openGolfCarts', icon: "fa fa-car", views});
    }

    if( securityManager.ee.canSeeGolfCartScheduleDetail() ){
        routes.push({
            path: paths.golfCartScheduleDetail,
            name: "Horario de carrito",
            hidden: true,
            component: GolfCartScheduleDetail
        });
    }

    // if (securityManager.ee.canAdminTables())
    //     routes.push({path: paths.tables, name: "Mesas", icon: "fa fa-th", component: TablesList});

    if (securityManager.ee.canSeeCheckins() || securityManager.ee.canSeeVisitsDashboard()|| securityManager.ee.canSeeActiveVisits())
        routes.push({
            collapse: true,
            name: 'Visitas',
            state: 'openVisits',
            icon: 'pe-7s-car',
            views: [

                ...(securityManager.ee.canSeeVisitsDashboard() ?
                    [{name: 'Dashboard', path: paths.visitsDashboard, mini: 'D', component: VisitsDashboard}] : []),
                ...(securityManager.ee.canSeeVisits()?
                    [{name: 'Lista de visitas', path: paths.visitsList, mini: 'L', component: VisitsList}]:[]),
                ...(securityManager.ee.canSeeActiveVisits() ?
                    [{name: 'Huéspedes en sala', path: paths.activeVisits, mini: 'H', component: ActiveVisits}] : []),
                ...(securityManager.ee.canSeeCheckins() ?
                    [{name: 'Check-ins', path: paths.checkIns, mini: 'CI', component: CheckIns}] : []),
            ]

        });

    if (securityManager.ee.canAdminDishes())
        routes.push({
            collapse: true,
            name: 'Platillos',
            state: 'openDishes',
            icon: 'fa fa-cutlery',
            views: [
                /*
                {
                    name: 'Dashboard',
                    path: paths.dishesDashboard,
                    mini: 'D',
                    component: DishesDashboard
                },
                 */
                {
                    name: 'Categorías de platillos',
                    path: paths.dishesCategories,
                    mini: 'C',
                    component: DishesCategories
                },
                {
                    name: 'Platillos',
                    path: paths.dishAdmin,
                    mini: 'P',
                    component: DishAdmin
                }
            ]
        });

    /*if (securityManager.ee.canAdminServices())
        routes.push({
            name: 'Servicios',
            path: paths.servicesAdmin,
            icon: 'fa fa-shower',
            component: ServicesAdmin
        });
     */
/*
    if (securityManager.ee.canListParkingReservations())
        routes.push({
            name: 'Estacionamiento',
            path: paths.serviceRequests,
            icon: 'fa fa-car',
            component: ServiceRequestsList
        });
*/
    if (securityManager.ee.canSeeCashRegisters() || securityManager.ee.canSeeOrozcoPayments()) {
        const views = [
            {name: 'Lista de cajas', path: paths.cashRegistersList, mini: 'L', component: CashRegistersList},
        ];

        if (securityManager.ee.canSeeOrozcoPayments()) {
            views.push({name: 'Lista de pagos', path: paths.paymentsList, mini: 'P', component: PaymentsList});
        }

        if (securityManager.ee.canSeeOrozcoPrints()) {
            views.push({name: 'Lista de tickets', path: paths.ticketsList, mini: 'T', component: PrintsList});
        }

        routes.push({
            collapse: true, name: 'Cajas registradoras', state: 'openCashRegister', icon: 'fa fa-money', views
        });
    }

    if(securityManager.ee.canEditLoungeMaps()) {
        routes.push({name: 'Editor de mapas', path: paths.loungeMapList, icon: 'fa fa-map', component: LoungeMapList});
        routes.push({name: 'Editor de mapas', path: paths.loungeMapEditor, hidden: true, component: LoungeMapEditor});
    }

    if(securityManager.ee.canSeeReceptionistsReports()){
        routes.push({name: 'Recepcionistas', path: paths.receptionistsReport, icon: 'fa fa-user', component: ReceptionistsReport});
    }

    if(securityManager.ee.canSeeDaVinciReports() || securityManager.ee.canSeePaymentsReports() || securityManager.ee.canSeeGCareReports() )
        routes.push({
            collapse:true,
            name: "Reportes",
            state:'openReports',
            icon: "fa fa-area-chart",
            views: [
                ...(securityManager.ee.canSeeDaVinciReports()?
                    [{ name: 'Reportes',  path: paths.davinciReports, mini: 'R',  component: DavinciReports }]:[]),
                ...(securityManager.ee.canSeeDaVinciReports()?
                    [{ name: 'Reportes NPS',  path: paths.npsReports, mini: 'NPS',  component: DavinciReportsNps }]:[]),
                ...( securityManager.ee.canSeePaymentsReports()?
                    [{name: 'Reportes de recolección', path: paths.eeReports, mini:'RDR', component: EeReports, exact: true }]:[]),
                ...( securityManager.ee.canSeeGCareReports()?
                    [{name: 'Reportes de GCare', path: paths.eeReportsGcare, mini:'GC', component: EeReportsGcare, exact: true }]:[])
            ]
        });

    routes.push({ path: paths.eeHelp, name: "Ayuda", icon: "pe-7s-info", component: Help });

    return routes;
};
