import Context from './Context';
import _ from 'lodash';

export default class SecurityManager{

    constructor(me){

        if( !me )
            throw new Error("The Security object requires an user to be initialized");

        this.me = me;
        this.position = me.employee && me.employee.position && me.employee.position.code? me.employee.position.code:'';
        this.role = (me.role && me.role.name) ? me.role.name:'';
        this.permissions = me.permissionsArray||[];
    }

    havePermission($permission){
        if(this.role==='SUPER_ADMIN'){
            return true;
        }
        return _.includes(this.permissions, $permission);
    }

    haveAllPermissions($permissions){
        if(this.role==='SUPER_ADMIN'){
            return true;
        }
        return _.reduce($permissions, (haveAll, permission)=>haveAll&&this.havePermission(permission), true);
    }

    haveAtLeastOnePermission($permissions){
        if(this.role==='SUPER_ADMIN'){
            return true;
        }
        return _.intersection(this.permissions, $permissions).length>0
    }

    isActualUser(user){
        return !!(user && user.id===this.me.id )
    }

    isActualEmployee(employee){
        return !!(employee && this.me.employee && employee.id===this.me.employee.id )
    }

    core = {
        canSeeGuestChat: ()=> false,//this.havePermission('ATTEND_GUEST_CHAT')
        canEditPermissions: ()=>this.havePermission('PERMISSIONS_ADMIN')
    };

    ee = {

        canSeeSystem: ()=>this.haveAtLeastOnePermission(["VISIT_SEE_DASHBOARD", "VISIT_LIST_IN_CLOUD", "OROZCO_CASH_REGISTER_ADMIN"]),
        canSeePaymentsReports: () => this.havePermission('SEE_PAYMENTS_REPORTS'),
        canManageFlights: () => this.havePermission('FLIGHTS_MANAGEMENT'),
        canManageAirlineOrigins: () => this.havePermission('AIRLINES_ORIGIN_MANAGEMENT'),
        canSeeFlightGuestGoalStatistics: () => this.havePermission('CONSULT_FLIGHT_STATISTICS'),
        canSeeGCareStats: ()=>this.havePermission('QUESTIONNAIRE_REPORT_SEE_MAILED'),

	    canSeeGuests: ()=>this.haveAllPermissions(['GUEST_LIST', 'GUEST_SHOW']),

        canCreateQuestionnaires:()=>this.havePermission('QUESTIONNAIRE_CREATE'),
        canEditQuestionnaires:()=>this.havePermission('QUESTIONNAIRE_UPDATE'),
        canReviewQuestionnaires:()=>this.havePermission('FILLED_QUESTIONNAIRE_REVIEW'),

        canSeeHappinessReport:()=>this.havePermission('HAPPINESS_SHOW'),

        canSeeReservations:()=>this.havePermission('RESERVATION_LIST'),
        canSeeAirConciergeReservations:()=>this.havePermission('AIR_CONCIERGE_RESERVATION_LIST'),

        canSeeAccessMethods:()=>this.havePermission('LOUNGE_ACCESS_METHOD_CREATE'),

        canSeeVisitsDashboard: ()=>this.havePermission('VISIT_SEE_DASHBOARD'),
        canSeeActiveVisits: ()=>this.havePermission('DAVINCI_SEE_GUESTS_IN_LOUNGE'),
        canSeeCheckins: ()=>this.havePermission('VISIT_LIST_IN_CLOUD'),
        canAdminServices: ()=>this.haveAllPermissions([
            'SERVICE_LIST',
            'SERVICE_CREATE',
            'SERVICE_UPDATE',
            'SERVICE_DELETE',
        ]),
        canAdminDishes: ()=>{
            return this.haveAllPermissions([
            'OROZCO_PRODUCT_LIST',
            'OROZCO_PRODUCT_CREATE',
            'OROZCO_PRODUCT_UPDATE',
            'OROZCO_PRODUCT_DELETE',
            'OROZCO_PRODUCT_CATEGORY_LIST',
            'OROZCO_PRODUCT_CATEGORY_CREATE',
            'OROZCO_PRODUCT_CATEGORY_UPDATE',
            'OROZCO_PRODUCT_CATEGORY_DELETE',
        ])},

        canSeeCashRegisters: ()=>this.havePermission('OROZCO_CASH_REGISTER_ADMIN'),
        canSeeOrozcoPayments: ()=>this.havePermission('OROZCO_PAYMENT_LIST'),
        canSeeOrozcoPrints: ()=>this.havePermission('OROZCO_PRINT_LIST'),
        canSeeDaVinciReports: ()=>this.havePermission('DAVINCI_SEE_REPORTS'),
        canSeeVisits: ()=>this.havePermission('VISIT_LIST_IN_CLOUD'),
        canEditVisits: ()=>this.havePermission('VISIT_CLOSED_UPDATE'),
        canAdminTables: ()=>{
            return this.haveAllPermissions([
                'TABLE_CREATE',
                'TABLE_LIST'
            ])
        },
        canListParkingReservations: ()=>this.havePermission('PARKING_SERVICE_LIST'),
        canCancelCheckIns: ()=>this.havePermission('CHECKIN_CANCEL'),

        canEditLoungeMaps: ()=>this.haveAllPermissions(['LOUNGE_MAP_UPDATE', 'SEAT_CREATE', 'LOUNGE_MAP_ITEM_CREATE']),

        canChangeAutomaticCashClosingConfiguration: ()=>this.haveAllPermissions(['CONFIGURATION_UPDATE_AUTOMATIC_CASH_REGISTER_CLOSING','CONFIGURATION_SHOW_AUTOMATIC_CASH_REGISTER_CLOSING']),

        canHomologateGuests: () => this.havePermission('HOMOLOGATE_GUESTS'),

        // Golf cart functionality
        canSeeGolfCartList: () => this.havePermission('GOLF_CART_SEE_CATALOGUE'),
        canEditGolfCart: () => this.havePermission('GOLF_CART_CREATE'),

        canSeeAirportGateList: () => this.havePermission('AIRPORT_GATE_SEE_CATALOGUE'),
        canEditAirportGate: () => this.havePermission('AIRPORT_GATE_CREATE'),

        canSeeGolfCartScheduleList: () => this.havePermission('GOLF_CART_SCHEDULE_SEE_CATALOGUE'),
        canSeeGolfCartScheduleDetail: () => this.havePermission('GOLF_CART_SCHEDULE_SHOW'),
        canEditGolfCartSchedule: () => this.haveAllPermissions(['GOLF_CART_SCHEDULE_CREATE', 'GOLF_CART_SCHEDULE_UPDATE']),
        canSeeGolfCartDayControl: () => this.havePermission('GOLF_CART_TRIP_CREATE'),

        canSeeGolfCartTripList: () => this.havePermission('GOLF_CART_TRIP_CATALOGUE'),
        canSeeGolfCartTripShowStatistics: () => this.havePermission('GOLF_CART_TRIP_SHOW_STATISTICS'),
        canEditGolfCartTrip: () => this.haveAllPermissions(['GOLF_CART_TRIP_CREATE', 'GOLF_CART_TRIP_UPDATE']),

        canSeeGolfCartTripListReadonly: () => (this.havePermission('GOLF_CART_TRIP_LIST') && !this.havePermission('GOLF_CART_TRIP_CREATE')),

        canStartAndEndTrips: () => this.havePermission('START_AND_END_CART_TRIPS'),
        canAdminTrips: () => this.havePermission('ADMIN_CART_TRIPS'),
        canSeeGCareReports: ()=>this.havePermission('GCARE_SEE_REPORTS'),

        canSeeReceptionistsReports: ()=>this.havePermission('SEE_RECEPTIONISTS_REPORTS'),
        canSeeCalendar: () => this.havePermission('EVENT_LIST'),
        canEditCalendar: ()=>{
            return this.haveAllPermissions([
                'EVENT_LIST',
                'EVENT_SHOW',
                'EVENT_UPDATE',
                'EVENT_CREATE',
                'EVENT_DELETE'
        ])}
    };

    alma = {
        canSeeXFiles: ()=>this.havePermission('EMPLOYEE_X_FILES_LIST'),
        canCreateAppointments: ()=>this.havePermission('APPOINTMENT_CREATE'),
        canCreateAppointmentsForAll: ()=>this.havePermission('APPOINTMENT_CREATE_FOR_ALL'),
        canCreateRepositoryFiles: ()=>this.havePermission('REPOSITORY_FILE_CREATE'),
        canSeePayrollRoutes: ()=>this.haveAllPermissions(['EMPLOYEE_CREATE_PAYMENT_DATA', 'AREA_CREATE', 'LAYOFF_CREATE', 'TIME_REGISTER_CREATE', 'INCIDENCE_JUSTIFICATION_JUSTIFY']),
        canEditConfiguration: ()=>this.haveAtLeastOnePermission([
            'CONFIGURATION_UPDATE_MANAGERS_CAN_REVIEW_JUSTIFICATIONS',
            'CONFIGURATION_UPDATE_CLOCK_RANDOM_MESSAGES',
            'CONFIGURATION_UPDATE_CLOCK_PRE_DISCOUNT_MESSAGE',
            'CONFIGURATION_UPDATE_JUSTIFICATION_APPROVAL_LIMIT_HOURS',
            'CONFIGURATION_UPDATE_JUSTIFICATION_REQUEST_LIMIT_HOURS',
        ]),
        canSeeEmployeeScoreReport: ()=>this.havePermission('BIWEEKLY_SCORE_GENERATION_REPORT'),
        canEditManagersCanReviewJustifications: ()=>this.havePermission('CONFIGURATION_UPDATE_MANAGERS_CAN_REVIEW_JUSTIFICATIONS'),
        canUpdateLayoffs: ()=>this.havePermission('LAYOFF_UPDATE'),
        canSeeEmployeesWage: ()=>this.role==='NOMINAS' || this.havePermission('EMPLOYEE_SHOW_PAYMENT_DATA'),
        canEditEmployeesWage: ()=>this.role==='NOMINAS' || this.havePermission('EMPLOYEE_SHOW_PAYMENT_DATA'),
        canEditClockRandomMessages: ()=>this.havePermission('CONFIGURATION_UPDATE_CLOCK_RANDOM_MESSAGES'),
        canEditClockPreDiscountMessage: ()=>this.havePermission('CONFIGURATION_UPDATE_CLOCK_PRE_DISCOUNT_MESSAGE'),
        canEnableHapinessMeter: () => this.havePermission('CONFIGURATION_UPDATE_HAPINESS_METER_PLUGIN'),
        canUpdateJustificationHourLimit: () => this.haveAllPermissions(['CONFIGURATION_UPDATE_JUSTIFICATION_APPROVAL_LIMIT_HOURS', 'CONFIGURATION_UPDATE_JUSTIFICATION_REQUEST_LIMIT_HOURS']),
        canApproveJustifications: (isMe)=>{
            if( this.me?.role?.name === 'NOMINAS' )
                return true;
            if(this.me.appConfig.alma.managersCanReviewJustifications)
                return !isMe
            return  false;
        },

        canApproveEmployeeJustification: (employee)=>{
            const me = this.me;

            if((me.role && me.role.name === "NOMINAS"))
                return true;

            if( this.role !== 'GERENTE' && this.havePermission('INCIDENCE_JUSTIFICATION_JUSTIFY') )
                return true;

            if(this.me?.appConfig?.alma?.managersCanReviewJustifications)
                return Boolean((employee.group && me.employee.owningGroup &&  me.employee.owningGroup.id === employee.group.id));

            return false;
        },

        canAdminHolidays: ()=>this.haveAllPermissions(['PUBLIC_HOLIDAY_CREATE', 'PUBLIC_HOLIDAY_UPDATE', 'PUBLIC_HOLIDAY_DELETE', 'PUBLIC_HOLIDAY_SHOW', 'PUBLIC_HOLIDAY_LIST',]),
    };

    almazen = {
        canSeeSystem: ()=>this.haveAtLeastOnePermission(['STOCK_REQUEST_CREATE', 'STOCK_REQUEST_SHOW','WAREHOUSE_CREATE', 'INVENTORY_LIST']),

        canEditWarehouses: ()=>this.havePermission('WAREHOUSE_UPDATE'),
        canDeleteWarehouses: ()=>this.havePermission('WAREHOUSE_DELETE'),
        canEditWarehousePermissions: ()=>this.havePermission('EMPLOYEE_WAREHOUSE_CREATE'),

        canSeeReports: ()=>this.havePermission('WAREHOUSE_REPORTS_SCREEN'),

        canListInventories: ()=>this.havePermission('INVENTORY_LIST'),
        canShowInventoryMode: ()=>this.havePermission('ALLOW_WAREHOUSE_INVENTORY_MODE'),
        canEditInventories: ()=>this.havePermission('STOCK_MOVEMENT_BATCH_CREATE'),

        canCreateOutMovements: ()=>this.havePermission('CREATE_OUT_STOCK_MOVEMENTS'),
        canCreateInMovements: ()=>this.havePermission('CREATE_IN_STOCK_MOVEMENTS'),
        canCreateShrinkages: ()=>this.havePermission('SHRINKAGE_CREATE'),

        canListShrinkages: ()=>this.havePermission('SHRINKAGE_LIST'),

        canDeleteStockMovements: ()=>this.havePermission('STOCK_MOVEMENT_DELETE'),

        canEditProducts:()=>this.havePermission('PRODUCT_UPDATE'),

        canListRequestStock:()=>this.havePermission('STOCK_REQUEST_LIST'),
        canRequestStock:()=>this.havePermission('STOCK_REQUEST_CREATE'),
        canEditStockRequest:(stockRequest)=>this.havePermission('STOCK_REQUEST_UPDATE') && stockRequest && (!stockRequest.createdBy || stockRequest.createdBy.id===this.me.id),
        canCancelStockRequest:(stockRequest)=>this.havePermission('STOCK_REQUEST_UPDATE') && stockRequest && stockRequest.createdBy && stockRequest.createdBy.id===this.me.id,
        canReceiveStockRequests:(stockRequest)=>stockRequest && this.havePermission('STOCK_REQUEST_UPDATE'),


        canListWarehouseCounts: ()=>this.havePermission('WAREHOUSE_COUNT_LIST'),
        canCreateWarehouseCounts: ()=>this.havePermission('WAREHOUSE_COUNT_CREATE'),


        canEditStockRequestApprovalMatrix: ()=>this.havePermission('EMPLOYEE_APPROVAL_RULE_CREATE'),

        canCreateMeasurementUnits: ()=>this.havePermission('UNIT_CONVERSION_RULE_CREATE'),

	    canListPeriodicStockRequest: ()=>this.havePermission('PERIODIC_STOCK_REQUEST_LIST'),
	    canCreatePeriodicStockRequest: ()=>this.havePermission('PERIODIC_STOCK_REQUEST_CREATE'),
        canEditConfigurationOfCreateNewRequest: ()=>this.havePermission('CONFIGURATION_SHOW_CREATE_REQUISITIONS_WHEN_EXISTS_UNCLOSED_REQUISITIONS'),

    };

    jazz = {
        canSeeJazzDashboard: ()=>this.havePermission('PURCHASE_DASHBOARD'),

        canSeeSystem: ()=>this.haveAtLeastOnePermission(['PURCHASE_REQUISITION_LIST', 'PURCHASE_ORDER_LIST']),
        canRequestLineProducts: ()=>this.havePermission('PURCHASE_REQUISITION_CREATE_WITH_LINE_PRODUCT'),
        canQuote: ()=> this.havePermission('PURCHASE_REQUISITION_QUOTE'),

        canRequestPurchases: ()=> this.havePermission('PURCHASE_REQUISITION_CREATE'),

        canListPurchaseRequisitions: ()=>this.havePermission('PURCHASE_REQUISITION_LIST'),
        canShowPurchaseRequisitions: ()=>this.havePermission('PURCHASE_REQUISITION_SHOW'),
        canEditPurchaseRequisitions: ()=>this.havePermission('PURCHASE_REQUISITION_UPDATE'),
        canCreatePurchaseRequisitions: ()=>this.havePermission('PURCHASE_REQUISITION_CREATE'),
        canUnquotePurchaseRequisitions: ()=>this.havePermission('PURCHASE_REQUISITION_QUOTE'),

        canEditPurchaseApprovalMatrix: ()=>this.havePermission('EMPLOYEE_APPROVAL_RULE_CREATE'),

        canEditConfiguration: ()=>this.haveAtLeastOnePermission([
            'CONFIGURATION_UPDATE_MINS_TO_ADD_APPROVAL_TO_SUPERIOR',
            'CONFIGURATION_UPDATE_MINS_TO_AUTO_APPROVE_REQUISITION',
            'CONFIGURATION_UPDATE_URL_TO_EXTERNAL_FORM',
            'CONFIGURATION_SHOW_BUDGET_REQUEST_APPROVAL_1',
            'CONFIGURATION_UPDATE_BUDGET_REQUEST_APPROVAL_1',
            'CONFIGURATION_SHOW_BUDGET_REQUEST_APPROVAL_2',
            'CONFIGURATION_UPDATE_BUDGET_REQUEST_APPROVAL_2',
            'CONFIGURATION_SHOW_BUDGET_REQUEST_MONTHS',
            'CONFIGURATION_UPDATE_BUDGET_REQUEST_MONTHS',
            'CONFIGURATION_SHOW_BUDGET_REQUEST_MULTIPLIER',
            'CONFIGURATION_UPDATE_BUDGET_REQUEST_MULTIPLIER'
        ]),

        canEditMinsToChangeApprovalToSuperior:()=>this.havePermission('CONFIGURATION_UPDATE_MINS_TO_ADD_APPROVAL_TO_SUPERIOR'),
        canEditMinsToAutoApproveRequisitions: ()=>this.havePermission('CONFIGURATION_UPDATE_MINS_TO_AUTO_APPROVE_REQUISITION'),
        canEditOnlyAmountApprovalForLineProducts: ()=>this.havePermission('CONFIGURATION_UPDATE_ONLY_ONE_APPROVAL_FOR_LINE_PRODUCTS'),
        canEditUrlToExternalFormPurchaseRequisitionsLimit: ()=>this.havePermission('CONFIGURATION_UPDATE_URL_TO_EXTERNAL_FORM'),

        canEditRequisition: (purchaseRequisition)=>!!(purchaseRequisition && this.isActualEmployee(purchaseRequisition.requestedBy)),
        canCancelRequisition: (purchaseRequisition)=>!!(this.havePermission('PURCHASE_REQUISITION_CANCEL') || (purchaseRequisition && this.isActualEmployee(purchaseRequisition.requestedBy))),

        canListProducts: ()=> this.havePermission('PRODUCT_SEE_CATALOGUE'),

        canListProviders: ()=> this.havePermission('PROVIDER_LIST'),
        canListProductBrandProviders: ()=> this.havePermission('PRODUCT_BRAND_PROVIDER_SEE_CATALOGUE'),

        canListProviderProductBrands: ()=>this.havePermission('PRODUCT_BRAND_PROVIDER_SEE_CATALOGUE'),

        canListPurchaseOrders: ()=> this.havePermission('PURCHASE_ORDER_LIST'),
        canShowPurchaseOrders: ()=> this.havePermission('PURCHASE_ORDER_SHOW'),
        canAdjustPurchaseOrders: ()=> this.havePermission('PURCHASE_ORDER_ADJUSTMENT'),

        canUpdateOrderPaymentMethod: ()=> this.havePermission('PURCHASE_ORDER_UPDATE_PAYMENT_METHOD'),
        canUpdateOrderStatus: ()=> this.havePermission('PURCHASE_ORDER_UPDATE_STATUS'),
        canMarkPOAsReceived: (purchaseOrder)=>!!(purchaseOrder && purchaseOrder.purchaseRequisition && this.isActualEmployee(purchaseOrder.purchaseRequisition.requestedBy)),
        canShowBudgetRequest: () => this.havePermission('BUDGET_REQUEST_LIST'),
        canApproveBudgetRequest: () => this.haveAllPermissions(['BUDGET_REQUEST_LIST', 'BUDGET_REQUEST_SHOW', 'BUDGET_REQUEST_UPDATE']),
        canConfigureApprovalRequest: () => this.haveAllPermissions([
            'CONFIGURATION_SHOW_BUDGET_REQUEST_APPROVAL_1',
            'CONFIGURATION_UPDATE_BUDGET_REQUEST_APPROVAL_1',
            'CONFIGURATION_SHOW_BUDGET_REQUEST_APPROVAL_2',
            'CONFIGURATION_UPDATE_BUDGET_REQUEST_APPROVAL_2',
            'CONFIGURATION_SHOW_BUDGET_REQUEST_MONTHS',
            'CONFIGURATION_UPDATE_BUDGET_REQUEST_MONTHS',
            'CONFIGURATION_SHOW_BUDGET_REQUEST_MULTIPLIER',
            'CONFIGURATION_UPDATE_BUDGET_REQUEST_MULTIPLIER'
        ])
    };

    capitalHumano = {
        canSeeSystem: ()=>this.role==='RECURSOS_HUMANOS' || this.role==='DIRECTOR' || this.role==='SUPER_ADMIN',

        canSeeUsers: ()=>this.role==='RECURSOS_HUMANOS',

        canSeeXFiles: ()=>this.havePermission('EMPLOYEE_X_FILES_LIST'),

        canSeeHappiness: ()=>this.havePermission('DOWNLOAD_HAPPINESS_LOG_REPORT')||this.role==='RECURSOS_HUMANOS',

        canSeeStatistics: ()=>this.havePermission('USER_LIST')||this.role==='RECURSOS_HUMANOS',
        canSeeEmployeeCalendars: ()=>this.havePermission('USER_LIST')||this.role==='RECURSOS_HUMANOS',

        canSeeStaffTable: ()=>this.havePermission('POSITION_LIST'),

        canSeeWorkTools: ()=>this.havePermission('WORK_TOOL_CREATE'),
    };

    orozco = {
        canPickUpCashRegisterCash: ()=>this.havePermission('OROZCO_CASH_PICK_UP_CREATE'),
        canListCashPickUps: ()=>this.havePermission('OROZCO_CASH_PICK_UP_LIST')
    };

    katharsis = {
        canSeeSystem: ()=>this.haveAtLeastOnePermission(['OROZCO_RECIPE_LIST', 'KATHARSIS_REPORTS_SCREEN']),
        canSeeKatharsisDashboard: ()=>this.havePermission('KATHARSIS_DASHBOARD'),
        canSeeRecipesAdmin: ()=>this.havePermission('OROZCO_RECIPE_ADMIN'),
        canEditRecipes: ()=>this.havePermission('OROZCO_RECIPE_CREATE'),
        canSeeReports: ()=>this.havePermission('KATHARSIS_REPORTS_SCREEN'),
        canApproveRecipes: ()=>this.havePermission('APPROVE_RECIPES')
    };

    rewards = {
        canSeeSystem: ()=>false,//this.havePermission('REWARD_SHOW_SYSTEM'),//||true,
        canAdminRewards: ()=>this.havePermission('REWARD_ADMIN_SYSTEM')//||true
    };


}

export const SecurityContext = Context;
