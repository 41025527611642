import React, {useState, useContext} from 'react';
import {ApiContext} from "../../../api/Api";
import ReactTable from 'react-table';
import useTideTable from "../../../hooks/table/useTideTable";
import AddProductModal from "./AddProductModal";
import EditProductModal from "./EditProductModal";
import optionsFilter from "../../../utils/optionsFilter";
import {Button} from "react-bootstrap";

const ProductAdmin = () => {
    const [showingAddProduct, setShowingAddProduct] = useState(false);
    const rewardsTable = useTideTable({
        entity: 'rewards',
        columns
    });
    return (
        <>
            <div className='Rewards admin'>
                <div className='add-medal-container'>
                    <h2>Nuevos productos</h2>
                    <Button onClick={() => setShowingAddProduct(true)}>Agregar producto</Button>
                </div>
                <div>
                    <h2>Edición</h2>
                    <ReactTable {...rewardsTable.tableProps}/>
                </div>
            </div>
            {showingAddProduct &&
            <AddProductModal onHide={() => setShowingAddProduct(false)}/>
            }
        </>
    );
};


const EditButton = ({original}) => {
    const [showing, setShowing] = useState(false);
    const showEditProduct = () => {
        setShowing(true);
    };

    const onHide = () => {
        setShowing(false);
    };

    return (
        <>
            <Button onClick={showEditProduct} style={{border: 'none'}}>
                {original.name}
            </Button>
            {showing && <EditProductModal product={original} onHide={onHide}/>}
        </>
    );
};

const ToggleActiveButton = ({original}) => {
    const api = useContext(ApiContext);
    const [loading, setLoading] = useState(false);

    const toggleActive = async () => {
        setLoading(true);
        try {
            await api.rewards.edit({
                id: original.id,
                params: {
                    isActive: !original.isActive
                }
            });
        } catch (e) {
        }

        setLoading(false);
    };
    if (loading) {
        return <p>Cargando...</p>;
    }
    return (
        <Button onClick={toggleActive}>
            {original.isActive ? 'Deshabilitar' : 'Habilitar'}
        </Button>
    );
};

const columns = [
    {
        Header: 'Nombre',
        accessor: 'name',
        Cell: EditButton
    },
    {
        Header: 'Precio',
        accessor: 'pointsCost',
        filterable: false
    },
    /*{
        Header: 'Editar',
        filterable: false,
        Cell: EditButton,
        sortable: false
    },*/
    {
        Header: 'Habilitar/Deshabilitar',
        accessor: 'isActive',
        Cell: ToggleActiveButton,
        Filter: optionsFilter([
            {
                value: true,
                text: 'Disponibles'
            },
            {
                value: false,
                text: 'No disponibles'
            }
        ])
    },
];


export default ProductAdmin;
