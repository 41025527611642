
export const validateForm = ( form, fields=[] )=>{

    const errors = [];
    for(let i=0; i<fields.length; i++){
        const field = fields[i];
        const {isRequired, name, label, validation} = field;
        if(isRequired!==false && (typeof form[name] === 'undefined' || form[name] === null) ){
            errors.push( {field: field, message: 'Debes llenar el campo '+(label||name)} );
            continue;
        }
        if(validation){
            const result = validation(form[name], { form, field, fields });
            if(result !== true ){
                errors.push( {field: field, message: result} );
            }
        }
    }

    if(errors.length)
        return {success: false, errors};

    return {success:true};
};
