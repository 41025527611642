import React,{useMemo,useCallback, useRef, useContext, useEffect, useState} from 'react';
import Card from "../../../components/Card/Card";
import useTideTable from "../../../hooks/table/useTideTable";
import ReactTable from "react-table";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import _ from 'lodash';
import {ApiContext} from "../../../api/Api";
import {useSelector} from "react-redux";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Switch from "react-bootstrap-switch";
import moment from 'moment';
import switchTranslations from "../../../utils/switchTranslations";
import useBoolean from "../../../hooks/useBoolean";
import EmployeeWorkToolsAssignerModal from "./components/EmployeeWorkToolsAssignerModal";


const requestFilters={
    sGroups:['employee_simplified','employee_work_tool_read','employee_work_tool','work_tool_read','area_read','lounge_read','position_read','shift_read'],
    isHired:true
};
const requestOptions={customProp:'employeesWithWorkTools'};

const EmployeeWorkToolsManager = ()=>{

    const workTools=useSelector(({api})=>api.workTools||[]);

    const [filteringWorkTool, setFilteringWorkTool]=useState('');
    const [hideMissingWorkTools, setHideMissingWorkTools]=useState(false);
    const [assigningWorkTool, startAssigningWorkTool, stopAssigningWorkTool]=useBoolean();

    const handleHideMissingChange=useCallback((s, val)=>{
        setHideMissingWorkTools(val)
    },[]);

    const handleFilterChange=useCallback(({target})=>{
        const workTool=_.find(workTools, wt=>wt.id===Number(target.value));
        if(workTool)
            setFilteringWorkTool(workTool);
        else
            setFilteringWorkTool('');
    }, [workTools]);

    const filterData=useCallback((data)=>{
        if(!filteringWorkTool || !hideMissingWorkTools) return data;

        return _.filter( data, employee=>!!_.find(employee.employeeWorkTools, ewt=>ewt.workTool.id===filteringWorkTool.id) )
    },[filteringWorkTool, hideMissingWorkTools]);

    const tableColumns=useMemo(()=>{
        if(!filteringWorkTool)
            return columns;
        return [
            ...columns,
            {
                Header:'Expiración',
                id:'employeeWorkTools',
                accessor:(employee)=>{
                    const employeeWorkTool=_.find(employee.employeeWorkTools, ewt=>ewt.workTool.id===filteringWorkTool.id);
                    if(!employeeWorkTool)
                        return 'Sin asignar';
                    if(!employeeWorkTool.expirationDate)
                        return 'No expira';
                    return moment(employeeWorkTool.expirationDate).format('YYYY-MM-DD');
                }
            }
        ]
    },[filteringWorkTool]);

    const employeesTable=useTideTable({
        entity:'employees',
        getMethod:'get2',
        requestFilters,
        columns:tableColumns,
        selectable:true,
        filterData,
        requestOptions
    });

    const {selected, setSelected}=employeesTable;
    const tableRef=useRef(null);

    const selectAll=useCallback(()=>{
        setSelected(
            _.map(tableRef.current.getResolvedState().sortedData, '_original')
        );
    },[setSelected]);

    const unselectAll=useCallback(()=>{
        setSelected([]);
    },[setSelected]);

    const api=useContext(ApiContext);
    useEffect(()=>{
        api.workTools.get();
    },[api]);

    const handleAssignation=useCallback(()=>{
        employeesTable.reload();
        stopAssigningWorkTool();
    },[employeesTable, stopAssigningWorkTool]);


    return (
        <div className='container-fluid EmployeeWorkToolsManager'>
            <Card
                content={
                    <div>
                        <div className='filters-wrapper container-fluid'>
                            <Row>
                                <Col xs={12} md={6} lg={4} className='text-center'>
                                    <p>Mostrar expiración de herramienta de trabajo:</p>
                                    <select
                                        className='form-control'
                                        value={filteringWorkTool && filteringWorkTool.id}
                                        onChange={handleFilterChange}
                                    >
                                        <option value=''>Selecciona una herramienta</option>
                                        {workTools.map(wt=>
                                            <option key={wt.id} value={wt.id}>{wt.name}</option>
                                        )}
                                    </select>
                                </Col>
                                <Col xs={12} md={6} lg={4} className='text-center'>
                                    <p>Ocultar personal sin la herramienta:</p>
                                    <Switch {...switchTranslations} value={hideMissingWorkTools} onChange={handleHideMissingChange}/>
                                </Col>
                                <Col xs={12} md={6} lg={4} className='text-center'>
                                    <CustomButton className='button-left' bsStyle='primary' onClick={selectAll}>Seleccionar todo</CustomButton>
                                    <CustomButton className='button-right' bsStyle='danger' onClick={unselectAll}>Borrar selección</CustomButton>
                                </Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col xs={12} lg={6} >
                                    {(!!selected.length) &&
                                    <CustomButton bsStyle='primary' onClick={startAssigningWorkTool}>Asignar herramientas de trabajo a selección</CustomButton>}
                                </Col>
                                <Col xs={12} lg={6} className='text-right'>
                                    <p>{selected.length} seleccionado{selected.length!==1?'s':''}</p>
                                </Col>
                            </Row>
                        </div>
                        <ReactTable
                            {...employeesTable.tableProps}
                            ref={tableRef}
                        />
                        <p>{employeesTable.itemsFoundString}</p>
                    </div>
                }
            />
            {assigningWorkTool &&
            <EmployeeWorkToolsAssignerModal
                employees={selected}
                onHide={stopAssigningWorkTool}
                onSave={handleAssignation}
            />}
        </div>
    );
};

export default EmployeeWorkToolsManager;


const shiftStrings={
    M:'Matutino',
    V:'Vespertino',
    N:'Nocturno'
};

const columns=[
    {
        Header:'Nombre',
        accessor:'fullName',
        id:'search'
    },
    {
        Header:'Área',
        accessor:'area.name'
    },
    {
        Header:'Puesto',
        accessor:'position.name'
    },
    {
        Header:'Turno',
        accessor:({shift})=>shift?shiftStrings[shift.type]:'',
        id:'shift.type',
    },
    {
        Header:'Asignadas',
        id:'employeeWorkTools.length',
        accessor:employee=>employee.employeeWorkTools.length,
        Cell:({original})=><OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id={1}>
                <ul className='text-left'>
                    {original.employeeWorkTools.map(ewt=><li key={ewt.id}>{ewt.workTool.name}</li>)}
                </ul>
            </Tooltip>}
        >
            <span>{original.employeeWorkTools.length}</span>
        </OverlayTrigger>
    }
];
