import React, {useContext, useEffect, useState, useCallback} from 'react';
import {Button} from "react-bootstrap";
import {ApiContext} from "../../../api/Api";
import {useSelector} from "react-redux";
import urlBase64ToUint8Array from "../../../utils/urlBase64ToUint8Array";
import {NotifierContext} from "../../../utils/Notifier";

const loadingId = '@PushNotificationButton.edit';

const PushNotificationsButton = () => {

    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);

    const [notificationsStatus, setNotificationsStatus] = useState(window.Notification?window.Notification.permission:'');
    const [subscription, setSubscription] = useState(null);
    const [waitingSubscription, setWaitingSubscription] = useState(false);

    const [serviceWorkerRegistered, setServiceWorkerRegistered] = useState(false);

    const loading = useSelector(({loadingIds}) => loadingIds[loadingId]);

    const isSupported = ()=>{
            if (!('serviceWorker' in navigator)) {
                return false;
            }
            if (!('PushManager' in window)) {
                return false;
            }

            return true;
    };

    useEffect(() => {
        if (isSupported()) {
            window.navigator.serviceWorker.getRegistration().then((registration) => {
                if (!registration)
                    return;
                setServiceWorkerRegistered(true);
                registration.pushManager.getSubscription()
                    .then(async (subscription) => {
                        if (subscription) {
                            setSubscription(subscription);
                        }
                    })
            })
        }
    }, []);


    const subscribe = useCallback(() => {
        window.navigator.serviceWorker.getRegistration().then((registration) => {
            if (!registration)
                return;

            registration.pushManager.getSubscription()
                .then(async (subscription) => {
                    // If a subscription was found, return it.
                    if (subscription) {
                        setSubscription(subscription);
                        return subscription;
                    }

                    setWaitingSubscription(true);
                    // Get the server's public key
                    const response = await api.webPush.getVapidPublicKey();
                    const vapidPublicKey = await response.publicKey;

                    // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
                    // urlBase64ToUint8Array() is defined in /tools.js
                    const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

                    // Otherwise, subscribe the user (userVisibleOnly allows to specify that we don't plan to
                    // send notifications that don't have a visible effect for the user).
                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: convertedVapidKey
                    });
                })
                .then((subscription) => {
                    setNotificationsStatus('granted');
                    if (subscription) {
                        api.webPush.subscribe({subscription, loadingId}).then(() => {
                            setSubscription(subscription);
                            setWaitingSubscription(false);
                        }).catch(()=>{
                            subscription.unsubscribe().then((successful) => {setSubscription(null)});
                            setWaitingSubscription(false);
                        });
                    }
                }).catch(function (err) {
                // User canceled or denied explicitly
                if(err){
                    setNotificationsStatus('denied');
                    setWaitingSubscription(false);
                }
            });

        })

    }, [api]);

    const unsubscribe = useCallback(() => {
        window.navigator.serviceWorker.getRegistration().then((registration) => {
            return registration.pushManager.getSubscription();
        })
            .then((pushSubscription) => {
                // Check we have everything we need to unsubscribe
                if (!pushSubscription) {
                    return;
                }
                // You should remove the device details from the server
                // i.e. the  pushSubscription.endpoint
                return pushSubscription.unsubscribe()
                    .then(function (successful) {
                        if (!successful) {
                            return notifier.error('Hubo un problema al desuscribirse');
                        }
                        return pushSubscription;
                    });
            })
            .then((subscription) => {
                if (subscription) {
                    api.webPush.unsubscribe({subscription, loadingId}).then(() => {
                        setSubscription(null);
                    });
                }else{
                    setSubscription(null);
                }
            })
            .catch((err) => {
                return notifier.error('Hubo un problema al desuscribirse');
            });
    }, [api, notifier]);

    return (
        <div>
            {serviceWorkerRegistered && (notificationsStatus === 'default' || notificationsStatus === 'granted') && !subscription ?
                <Button bsStyle='info' onClick={subscribe} disabled={loading || waitingSubscription}>Activar notificaciones
                    push</Button> : null
            }
            {serviceWorkerRegistered && notificationsStatus === 'denied' ?
                <Button bsStyle='warning' disabled={loading}>
                    Instrucciones para activar notificaciones push
                </Button> : null
            }
            {serviceWorkerRegistered && subscription ?
                <Button bsStyle='danger' onClick={unsubscribe} disabled={loading}>
                    Desabilitar notificaciones push
                </Button> : null
            }
            {!serviceWorkerRegistered || notificationsStatus === ''?
                <Button bsStyle='default' disabled={true}>
                    Notificaciones push no soportadas en este navegador y/o dispositivo
                </Button> : null
            }
        </div>

    );
};

export default PushNotificationsButton;

