import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Card from '../../../components/Card/Card.jsx';
import SimpleBlockTable from '../../../components/Table/SimpleBlockTable';
import {connect} from 'react-redux';
import moment from 'moment';


class Clocks extends Component{

    constructor(props, context){
        super(props);
        this.dataApi = context.api.clocks;
    }

    UNSAFE_componentWillMount(){
        this.dataApi.get();
    }

    render(){

        return(
            <Card
                title="Administración de relojes"
                content={
                    <SimpleBlockTable
                        data = {this.props.clocks}
                        accessor = "name"
                        idAccessor = "id"
                        text = {{
                            addModal:"Nuevo reloj:",
                            addPlaceholder:"Nombre",
                            deleteConfirm:"¿Estás seguro que deseas borrar esta reloj?",
                            editModal:"Editar nombre del reloj:"
                        }}
                        extraInfoComponent={ LastConnectionInfo }
                        onAdd = {this.dataApi.create}
                        onDelete = {this.dataApi.delete}
                        onEdit = {this.dataApi.edit}
                    />
                }
            />
        );
    }
}

const LastConnectionInfo = ( props )=>{

    const { serial, trSyncDate, usersSyncDate } = props.data;

    if( !trSyncDate || !usersSyncDate )
        return null;

    return <p>
        Última conexión: { moment( usersSyncDate ).format( 'DD/MM/YYYY HH:mm' ) }<br/>
        Último registro: { moment( trSyncDate ).format( 'DD/MM/YYYY HH:mm' ) }<br/>
        <span className='text-muted small'>No. de serie: {serial}</span>
    </p>;

};

Clocks.contextTypes = { api: PropTypes.object.isRequired };

function mapStateToProps( state ) {
    const { api } = state;
    const clocks = api.clocks||[];

    return { clocks };
}

export default connect(mapStateToProps)(Clocks);