import _ from 'lodash';
import React from 'react';
import { budgetRequestStatus, budgetRequestStatusTrans } from 'utils/modelUtils/jazz/budgetRequestUtils';

const ReactRequestTableStatusFilter = ({ filter, onChange }) => {

  const options = [
    { value: budgetRequestStatus.PENDING, option: budgetRequestStatusTrans[budgetRequestStatus.PENDING] },
    { value: budgetRequestStatus.APPROVED, option: budgetRequestStatusTrans[budgetRequestStatus.APPROVED] },
    { value: budgetRequestStatus.REJECTED, option: budgetRequestStatusTrans[budgetRequestStatus.REJECTED] }
  ];

  return (
    <div className="ReactTableStatusFilter">
      <select className='form-control border border-info' value={filter?.value || ''} onChange={(e) => onChange(e.target.value)}>
          <option value=''>Todas</option>
          { _.map(options, ( option, index ) =>
              <option key={index} value={option.value}>{option.option}</option>
          ) }
      </select>
    </div>
  );
}
 
export default ReactRequestTableStatusFilter;