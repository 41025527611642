import React, {useCallback} from 'react';
import {imageForExtension} from '../../utils/fileUtils';


const FileIcon = ( {width=100, onSelect, onDelete, onEdit, repositoryFile} )=>{


    const handleDelete = useCallback((e)=>{
        e.stopPropagation();
        onDelete( repositoryFile );
    }, [onDelete, repositoryFile]);

    const handleEdit = useCallback((e)=>{
        e.stopPropagation();
        onEdit( repositoryFile );
    }, [onEdit, repositoryFile]);

    const handleSelect = useCallback(()=>{
      onSelect(repositoryFile);
    },[onSelect, repositoryFile]);

    if( !repositoryFile )
        return null;

    const parts = (repositoryFile.name || '').split('.');
    const image = imageForExtension( parts[ parts.length -1 ] );

    return(
        <div className="FileIcon"  style={{width: width + 30}} onClick={handleSelect}>

            {onEdit?
                <i className='fa fa-pencil' onClick={handleEdit} />
                :null
            }
            {onDelete?
                <i className='fa fa-trash' onClick={handleDelete} />
                :null
            }

            {repositoryFile.isFolder ?
                <i className='fa fa-folder' style={{width}} />
                :
                <img style={{width}} src={image}
                     alt={"File " + repositoryFile.name + " icon"}/>
            }
            <p>{repositoryFile.name}</p>
        </div>
    );

};

export default FileIcon;
