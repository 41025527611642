import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import LoaderIcon from '../../../components/Helper/LoaderIcon'
import QuestionStat from "../../../components/Questionnaire/QuestionStat";
import _ from 'lodash';
import Card from "../../../components/Card/Card";
import moment from 'moment';
import QuestionnaireStatsFilters from "./components/QuestionnaireStatsFilters";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import "./QuestionnaireStats.scss";


const initialFilters = {
    fromDate: moment().subtract(1, 'month').format('YYYY-MM-DDTHH:mm'),
    toDate: moment().format('YYYY-MM-DDTHH:mm')
};

class QuestionnaireStats extends  Component{

    static contextTypes = {
        api:PropTypes.object,
        notifier:PropTypes.object,
    };

    state = {failedToFetch:false};

    loadingId = '@Class.QuestionnaireStats.questionnaireReport.get';

    componentDidMount(){
        this.context.api.lounges.get();
        this.loadReport( initialFilters );
    }

    componentDidUpdate = ()=>{

        const idInUrl = this.props.match.params.id;

        //If there's no report in redux or the one stored is different to the one queried and we are not loading it already, then we fetch it
        //This may mean that they changed directly the questionnaire id in the url
        if( (!this.props.questionnaireReport || this.props.questionnaireReport.questionnaire.id+'' !== idInUrl ) && !this.props.loadingIds[this.loadingId] && !this.state.failedToFetch ) {
            this.context.api.questionnaireReport.get({questionnaire: idInUrl}, this.loadingId)
                .then((questionnaireReport)=> {
                    if (!questionnaireReport.questionnaire.id)
                        this.setState({failedToFetch: true});
                })
                .catch(()=> this.setState({failedToFetch:true}) );

        }

    };

    prepareFilters = (filters)=>{

        const preparedFilters={...filters};

        //The filters in this list are expected to be an array of objects with an id property
        //they will be iterated to extract only the id to an array
        const filtersToMapIds = [
            'filledBy',
            'visitCreatedByUsers',
            'visitAttendingBy',
            'loungeAccessMethods',
            'airlines',
            'visitLounges',
            'visitShiftTypes',
            'orozcoProducts'
        ];
        _.forEach( filtersToMapIds, filterName=>{
            if( !preparedFilters[filterName]?.length ){
                delete preparedFilters[filterName];
                return;
            }
            preparedFilters[filterName] = preparedFilters[filterName].map( f=>f.id );
        })

        return preparedFilters;
    };

    loadReport = (filters)=>{
        const idInUrl = this.props.match.params.id;
        const options = {questionnaire: idInUrl};

        const preparedFilters = this.prepareFilters(filters);

        return this.context.api.questionnaireReport.get({...options, ...preparedFilters}, this.loadingId)
            .then( ()=>{
                this.preparedFilters=preparedFilters;
                this.setState( { filters } )
            });
    };

    componentWillUnmount(){
        this.context.api.clearProperty('questionnaireReport');
    }

    render() {
        const fromDate = !this.state.filters ? moment(initialFilters.fromDate).format('YYYY-MM-DD') : moment(this.state.filters.fromDate).format('YYYY-MM-DD');
        const toDate = !this.state.filters ? moment(initialFilters.toDate).format('YYYY-MM-DD') : moment(this.state.filters.toDate).format('YYYY-MM-DD');

        if( this.state.failedToFetch)
            return <div className='text-center'><p className='alert alert-danger'>Hubo un error al cargar la información.</p></div>;

        if( !this.props.questionnaireReport )
            return <div className='text-center'><LoaderIcon /></div>;

        const qr = this.props.questionnaireReport;
        let orderedResults = _.filter( qr.result, res=>res.questionConfig?.inactive!==true )
        orderedResults = _.sortBy(orderedResults, res=>res.questionConfig.order);
        const resultRows = _.chunk(orderedResults, 2);
        const printPant = () => {
            window.print();
        };

        return (
            <div className="container-fluid main-container QuestionnaireStats">

                <h2>{qr.questionnaire.name}</h2>
                <CustomButton bsStyle='info' onClick={printPant} className='filtersContainer'>
                    Imprimir en PDF
                </CustomButton>

                <Card
                    title="Seleccionar filtros"
                    className='filtersContainer'
                    content={
                        <QuestionnaireStatsFilters
                            questionnaireReport={qr}
                            onFilterUse={this.loadReport}
                            loading={!!this.props.loadingIds[this.loadingId]}
                        />
                    }
                />

                <Row className='filtersContainer'>
                {this.state.filters?
                    <Col md={6} sm={12}>
                        <Card
                            title="Filtros activos"
                            content={
                                <div>

                                    <span className='active-filter'>
                                        {this.state.filters.fromDate?formatDate(this.state.filters.fromDate):null} -&nbsp;
                                        {this.state.filters.toDate?formatDate(this.state.filters.toDate):null}
                                    </span>

                                    {!!this.state.filters.isMailed && <span className='active-filter'>Solo por correo</span>}

                                    {!!this.state.filters.filledBy?.length &&
                                        this.state.filters.filledBy.map((employee)=>
                                            <span className='active-filter' key={employee.id}>Creado por: {employeeFullName(employee)}</span>)}

                                    {!!this.state.filters.visitAttendingBy?.length &&
                                        this.state.filters.visitAttendingBy.map((employee)=>
                                            <span className='active-filter' key={employee.id}>Anfitrión: {employeeFullName(employee)}</span>)}

                                    {!!this.state.filters.visitCreatedByUsers?.length &&
                                        this.state.filters.visitCreatedByUsers.map((user)=>
                                            <span className='active-filter' key={user.id}>Recepcionista: {employeeFullName(user.employee)}</span>)}

                                    {!!this.state.filters.loungeAccessMethods?.length &&
                                        this.state.filters.loungeAccessMethods.map((lam)=>
                                            <span className='active-filter' key={lam.id}>Método de acceso: {lam.fullName}</span>)}

                                    {!!this.state.filters.airlines?.length &&
                                        this.state.filters.airlines.map((airline)=>
                                            <span className='active-filter' key={airline.id}>Aerolínea: {airline.name}</span>)}

                                    {!!this.state.filters.visitLounges?.length &&
                                        this.state.filters.visitLounges.map((lounge) =>
                                            <span className='active-filter' key={lounge.id}>Sala: {lounge.name}</span>)}

                                    {!!this.state.filters.visitShiftTypes?.length &&
                                        this.state.filters.visitShiftTypes.map((shift) =>
                                            <span className='active-filter' key={shift.id}>Turno: {shift.label}</span>)}

                                    {!!this.state.filters.orozcoProducts?.length &&
                                        this.state.filters.orozcoProducts.map((receipe) =>
                                            <span className='active-filter' key={receipe.id}>Platillo: {receipe.name}</span>)}

                                    { (()=>{
                                        const filters = [];
                                        for( const filter in  this.state.filters.filterAnswers )
                                            this.state.filters.filterAnswers[filter].map( oneFilter=>
                                                filters.push(
                                                    <span key={oneFilter} className='active-filter' > {oneFilter} </span>
                                                )
                                            );
                                        return filters;
                                    })() }
                                </div>
                            }
                        />
                    </Col>
                :null}
                    {qr?.questionnaire?.isGcare &&
                    <Col md={6} sm={12}>
                        <Card
                            title='Descargar Reporte'
                            content={
                                <a href={this.context.api.gcare.downloadGCareReport({
                                    params: {
                                        from: fromDate,
                                        to: toDate,
                                        token: window.localStorage.googlead
                                    }
                                })} rel="noopener noreferrer">
                                    <CustomButton
                                        bsStyle='success'
                                    >
                                        Descargar Reporte de G Care por colaborador
                                    </CustomButton>
                                </a>
                            }
                        >
                        </Card>
                    </Col>
                    }
                </Row>

                {resultRows.map((results, index)=>
                    <Row key={index}>
                        {results.map((result, index)=>
                            <React.Fragment key={index}>
                            <Col lg={6} xs={12} key={index} className='questionContainer'>
                                <QuestionStat
                                    result={result}
                                    filters={ this.preparedFilters }
                                />
                            </Col>
                            {index % 2 !== 0 &&
                            <div className="pagebreak"> </div>
                            }
                            </React.Fragment>
                        )}
                    </Row>
                )}

            </div>
        )
    }
}

const mapStateToProps = ({ api:{questionnaireReport, lounges=[]}, loadingIds })=> ({questionnaireReport, lounges, loadingIds});

export default connect(mapStateToProps)(QuestionnaireStats);

const formatDate = (date)=>moment(date).format('DD/MM/YYYY');
