import React, {useState} from 'react';
import {Button, ButtonGroup} from "react-bootstrap";

const optionsFilter = options => ({onChange}) => {
    const [checked, setChecked] = useState(undefined);

    const check = value => () => {
        if(value === checked){
            setChecked(undefined);
            onChange(undefined);
            return;
        }
        setChecked(value);
        onChange(value);
    };
    return (
        <ButtonGroup>
            {
                options.map(({value, text})=>(
                    <Button bsStyle={value===checked?'info':'default'} key={value} onClick={check(value)}>{text}</Button>
                ))
            }
        </ButtonGroup>
    );
};

export default optionsFilter;
