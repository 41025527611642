import React, {useContext, useEffect, useState, useCallback} from 'react';
import {Col, ControlLabel, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row} from "react-bootstrap";
import {ApiContext} from "../../../api/Api";
import {useSelector} from "react-redux";
import {avatarUrl, employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import _ from 'lodash';
import Modal from "react-bootstrap/lib/Modal";
import EntitySelect from "../../../components/EntitySelect/EntitySelect";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import {NotifierContext} from "../../../utils/Notifier";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import moneyFormatter from "../../../utils/moneyFormatter";
import useNumberInput from "../../../hooks/useNumberInput";

const PurchaseAmountsApprovalRuleConfigurator = () => {

    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);

    const [selecting, setSelecting] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [deletingRule, setDeletingRule] = useState(null);

    const minInput = useNumberInput();
    const maxInput = useNumberInput();
    const priorityInput = useNumberInput();

    useEffect(() => {
        api.purchaseApprovalRules.get({filters: {pagination: false}});
    }, [api]);

    const purchaseApprovalRules = useSelector(({api}) => api.purchaseApprovalRules || []);

    const amountRows = _.chunk(purchaseApprovalRules, 3);

    const cancelSelecting = useCallback(() => setSelecting(null), []);

    const saveSelected = useCallback(() => {
        if (!selectedEmployee)
            return notifier.error('Selecciona a una persona');

        const newRule = {...selecting.rule};
        if (newRule.approvesTo) newRule.approvesTo = _.map(selecting.rule.approvesTo, 'id');
        if (newRule.employee) newRule.employee = newRule.employee.id;


        if (minInput.value === null || maxInput.value === null)
            return notifier.error('Selecciona un monto válido.');
        newRule.employee = selectedEmployee.id;
        newRule.minAmount = minInput.value + '';
        newRule.maxAmount = maxInput.value + '';
        newRule.priority = priorityInput.value;
        if (selecting.rule)
            api.purchaseApprovalRules.edit({id: newRule.id, purchaseApprovalRule: newRule});
        else
            api.purchaseApprovalRules.create({purchaseApprovalRule: newRule});

        setSelecting(null);
        setSelectedEmployee(null);

    }, [api, notifier, selectedEmployee, selecting, minInput.value, maxInput.value, priorityInput.value]);


    const promptDeleteRule = useCallbackCreator((id) => {
        setDeletingRule(id);
    }, []);

    const cancelDeleting = useCallback(() => setDeletingRule(null), []);

    const deleteRule = useCallback(() => {
        api.purchaseApprovalRules.delete({id: deletingRule});
        setDeletingRule(null);

    }, [api, deletingRule]);

    const editAmountsRule = useCallbackCreator((id) => {
        const rule = _.find(purchaseApprovalRules, rule => rule.id+'' === id+'');
        if (!rule) return notifier.error('Regla no encontrada');

        setSelectedEmployee(rule.employee);
        minInput.setValue(Number(rule.minAmount));
        maxInput.setValue(Number(rule.maxAmount));
        priorityInput.setValue(Number(rule.priority));
        setSelecting({type: 'amounts', rule})

    }, [purchaseApprovalRules, minInput, maxInput]);


    return (
        <div className='container-fluid ApprovalMatrixConfigurator'>

            <Row>
                <Col xs={12}>
                    <h3>Aprobaciones por montos</h3>
                    <hr/>
                </Col>
            </Row>

            {amountRows.map((rules, i) =>
                <Row key={i}>
                    {rules.map((rule) =>
                        <Col xs={12} sm={6} md={4} key={rule.id}>
                            <div className='approval-card'>
                                <div className='header'>
                                    <div className='face'
                                         style={{backgroundImage: `url('${avatarUrl(rule.employee, api)}')`}}/>
                                    {employeeFullName(rule.employee)}

                                    <button className='edit-rule-btn' onClick={editAmountsRule(rule.id)}>
                                        <i className='fa fa-pencil'/>
                                    </button>
                                    <button className='remove-rule-btn'
                                            onClick={promptDeleteRule(rule.id)}>
                                        <i className='fa fa-trash'/>
                                    </button>
                                </div>

                                <div className='body'>
                                    <p>Desde: {moneyFormatter(rule.minAmount, 1)}</p>
                                    <p>Hasta: {moneyFormatter(rule.maxAmount, 1)}</p>
                                    <p className='priority-text'>Prioridad: {rule.priority !== null ? rule.priority : 'Sin definir'}</p>

                                </div>

                            </div>
                        </Col>
                    )}
                </Row>
            )}
            <Row>
                <Col xs={12} sm={6} md={4}>
                    <div className='approval-card add'>
                        <button className='add-card-btn' onClick={() => setSelecting({type: 'amounts'})}>
                            <i className='fa fa-plus-circle'/>
                            Añadir regla
                        </button>
                    </div>
                </Col>
            </Row>


            <Modal show={!!selecting} onHide={cancelSelecting}>
                <ModalHeader closeButton>
                    <ModalTitle>Selecciona una persona</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <p>
                        Selecciona la persona que aprobará por monto
                    </p>

                    <EntitySelect
                        entity='employees'
                        getMethod='get2'
                        filterBy='search'
                        labelCreator={employeeFullName}
                        onChange={setSelectedEmployee}
                        value={selectedEmployee}
                        additionalFilters={employeeFilters}
                    />
                    {selecting ?
                        <div>
                            <hr/>
                            <ControlLabel>Desde:</ControlLabel>
                            <input className='form-control' {...minInput.bind} placeholder='Monto mínimo en pesos'/>
                            <ControlLabel>Hasta:</ControlLabel>
                            <input className='form-control' {...maxInput.bind} placeholder='Monto máximo en pesos'/>
                            <ControlLabel>Prioridad:</ControlLabel>
                            <input className='form-control' {...priorityInput.bind}
                                   placeholder='El más grande entra primero'/>
                            <p className='text-muted'>Si para una compra aplican dos o más reglas de aprobación, se
                                agregarán ordenandas por prioridad de mayor a menor.</p>
                        </div>
                        : null}
                </ModalBody>
                <ModalFooter>
                    <CustomButton bsStyle='danger' className='pull-left'
                                  onClick={cancelSelecting}>Cancelar</CustomButton>
                    <CustomButton bsStyle='success' onClick={saveSelected}>Guardar</CustomButton>
                </ModalFooter>
            </Modal>

            <ConfirmModal
                show={!!deletingRule}
                onCancel={cancelDeleting}
                onConfirm={deleteRule}
                title={'Borrar regla de aprobación'}
            />

        </div>
    )
};

export default PurchaseAmountsApprovalRuleConfigurator;

const employeeFilters = {isHired: true};
