import React, {useCallback} from 'react';
import ProviderSelect from "../../components/ProviderSelect";
import {computeAllQuoteValues} from "../../../../utils/modelUtils/jazz/purchaseRequisitionUtils";
import moneyFormatter from "../../../../utils/moneyFormatter";
import PurchaseProductTaxes from "./PurchaseProductTaxes";

const PurchaseProductQuoteRowEditable = (props )=>{

    const {
        purchaseProduct,
        purchaseProductQuote,
        ppIndex,
        quoteIndex,
        onRemove,
        onChange,
        onHoverChange,
        onPurchaseProductChange,
        isOpen,
        hasDiscount,
        currency,
        onMakeFirst
    } = props;

    const onProviderChange = useCallback( ( provider )=>{
        const newQuote = { ...purchaseProductQuote, provider };
        onChange(newQuote, quoteIndex);
    },[purchaseProductQuote, onChange, quoteIndex] );


    const handleMouseEnter = useCallback( ()=>onHoverChange(true), [onHoverChange] );
    const handleMouseLeave = useCallback( ()=>onHoverChange(false), [onHoverChange] );
    const handleRemoveClick = useCallback( (e)=>{
        e.stopPropagation();
        onRemove(quoteIndex)
    }, [onRemove, quoteIndex] );

    const handleInputChange = useCallback( ( {target:{ name, value }} )=>{
        const newQuote = { ...purchaseProductQuote, [name]:value };
        computeAllQuoteValues( purchaseProduct, newQuote );
        onChange( newQuote, quoteIndex );
    }, [purchaseProductQuote, purchaseProduct, quoteIndex, onChange] );

    const handleTaxesChange = useCallback( ( purchaseProductTaxes )=>{
        const newQuote = { ...purchaseProductQuote, purchaseProductTaxes };
        computeAllQuoteValues( purchaseProduct, newQuote );
        onChange( newQuote, quoteIndex );
    }, [purchaseProductQuote, purchaseProduct, quoteIndex, onChange] );

    const handleClick = useCallback( ()=>{
        onMakeFirst( quoteIndex );
    }, [quoteIndex, onMakeFirst] );

    const handleProductNameChange = ({target})=>{
        onPurchaseProductChange({...purchaseProduct, name:target.value}, ppIndex);
    };

    let classes = quoteIndex !== 0 && !isOpen? 'quotation-row' : '';
    if( quoteIndex === 0 ) classes += ' first';

    const total = Number( purchaseProductQuote.total );
    const selectedTotal  = Number(purchaseProduct.purchaseProductQuotes[0].total);
    if( total && selectedTotal ){
        if( total > selectedTotal  )
            classes += ' up';
        else if( total < selectedTotal )
            classes += ' down';
    }

    const productName = purchaseProduct.productBrand?
        purchaseProduct.productBrand.product.name + ' - ' + purchaseProduct.productBrand.brand.name
        : purchaseProduct.name;

    return (
        <tr
            className={'PurchaseProductQuoteRow product-row ' + classes}
            onMouseEnter={ handleMouseEnter }
            onMouseLeave={ handleMouseLeave }
        >
            <td className='text-center' onClick={handleClick} data-label='#'><div className='outer-div'><div className='inner-div'>
                {quoteIndex !== 0 ?
                    <i className='fa fa-times red-icon' onClick={ handleRemoveClick } data-id={purchaseProduct.id}/>:null}
                {ppIndex + 1}
            </div></div></td>

            <td className='text-center' onClick={handleClick} data-label='Tipo'><div className='outer-div'><div className='inner-div'>
                {!purchaseProduct.isService ? 'Producto':purchaseProduct.isExtraCharge?'Servicio extra':'Servicio'}
            </div></div></td>
            {/* De línea o no */}
            <td className='text-center' onClick={handleClick}  data-label='De línea'><div className='outer-div'><div className='inner-div'>
                {purchaseProduct.product ? 'Si' : 'No'}
            </div></div></td>

            <td className='text-left' onClick={handleClick}  data-label='Nombre'><div className='outer-div'><div className='inner-div'>
                {!purchaseProduct.isExtraCharge || quoteIndex!==0?
                    productName:
                    <input className='form-control' placeholder='Nombre del cargo extra' value={productName} onChange={handleProductNameChange}/>
                }
            </div></div></td>

            <td onClick={handleClick} data-label='Cantidad'><div className='outer-div'><div className='inner-div'>
                {Number(purchaseProduct.quantity)} {purchaseProduct.measurementUnit ? purchaseProduct.measurementUnit.name : null}
            </div></div> </td>

            <td  data-label='Proveedor'><div className='outer-div'><div className='inner-div'>
                <ProviderSelect onChange={onProviderChange} provider={purchaseProductQuote.provider}  creatable />
            </div></div></td>

            <td  data-label='Precio unitario'><div className='outer-div'><div className='inner-div'>
                <input className='form-control' name='unitPrice' onChange={handleInputChange} value={ purchaseProductQuote.unitPrice } />
            </div></div></td>

            <td onClick={handleClick} data-label='Subtotal'><div className='outer-div'><div className='inner-div'>{ moneyFormatter( purchaseProductQuote.subtotal,1,currency)}</div></div></td>

            <td  data-label='Descuento unitario'><div className='outer-div'><div className='inner-div'>
                <input className='form-control' name='unitDiscount' onChange={handleInputChange} value={ purchaseProductQuote.unitDiscount } />
            </div></div></td>

            { hasDiscount? <td onClick={handleClick}  data-label='Descuento total'><div className='outer-div'><div className='inner-div'>{ moneyFormatter( purchaseProductQuote.discountTotal,1,currency)}</div></div></td> :null}

            { hasDiscount? <td onClick={handleClick} data-label='Subtotal con descuento'><div className='outer-div'><div className='inner-div'>{ moneyFormatter( purchaseProductQuote.subtotalWithDiscount,1,currency)}</div></div></td> :null}

            <td  data-label='Impuestos'><div className='outer-div'><div className='inner-div'>
                <PurchaseProductTaxes
                    purchaseProductTaxes={ purchaseProductQuote.purchaseProductTaxes }
                    currency={currency}
                    subtotal={ purchaseProductQuote.subtotalWithDiscount }
                    onChange={ handleTaxesChange }
                    editable
                />
            </div></div></td>

            <td onClick={handleClick} data-label='Total'><div className='outer-div'><div className='inner-div'>{ moneyFormatter( purchaseProductQuote.total,1,currency)}</div></div></td>

        </tr>
    );
};

export default PurchaseProductQuoteRowEditable;
