
import RewardsFeed from "../views/rewards/RewardsFeed";
import GiveAward from "../views/rewards/GiveAward";
import ChooseAwardForEmployee from "../views/rewards/ChooseAwardForEmployee";
import Store from "../views/rewards/Store";
import Activity from "../views/rewards/Activity";
import ProductDescription from "../views/rewards/ProductDescription";
import AwardHistory from "../views/rewards/AwardHistory";
import ProductAdmin from "../views/rewards/ProductAdmin";
import MedalAdmin from "../views/rewards/MedalAdmin";
import RewardDelivery from "../views/rewards/RewardDelivery";
import RewardsStats from "../views/rewards/RewardsStats";
import PointsAssignment from "../views/rewards/PointsAssignment";

import FeedIcon from "../assets/img/tgle-rewards/nav-icons/feed.svg";
import GiftsIcon from "../assets/img/tgle-rewards/nav-icons/gifts.svg";
import StoreIcon from "../assets/img/tgle-rewards/nav-icons/store.svg";
import ActivityIcon from "../assets/img/tgle-rewards/nav-icons/notifications.svg";

export default security => {
    return [
        {
            path: '/rewards/feed',
            name: 'TGLE Rewards',
            component: RewardsFeed,
            img: FeedIcon,
            icon: 'fa fa-trophy',
            onFooter: true
        },
        {
            path: '/rewards/gifts',
            name: 'Elige una persona',
            component: GiveAward,
            img: GiftsIcon,
            icon: 'fa fa-users',
            onFooter: true
        },
        {
            path: '/rewards/gifts/:id',
            name: 'Elige una medalla',
            component: ChooseAwardForEmployee,
            hidden: true,
            from: '/rewards/gifts'
        },
        {
            path: '/rewards/store',
            name: 'Tienda',
            component: Store,
            img: StoreIcon,
            icon: 'fa fa-shopping-bag',
            onFooter: true
        },
        {
            path: '/rewards/product/:id',
            name: 'Tienda',
            component: ProductDescription,
            hidden: true,
            from: '/rewards/store'
        },
        {
            path: '/rewards/notifications',
            name: 'Actividad',
            component: Activity,
            img: ActivityIcon,
            icon: 'fa fa-bell',
            onFooter: true
        },
        {
            path: '/rewards/history',
            name: 'Historial de Medallas',
            component: AwardHistory,
            from: '/rewards/feed',
            hidden: true
        },
        ...(security&&security.rewards.canAdminRewards()?[
            {
                path: '/rewards/product-admin',
                name: 'Administrador de productos',
                component: ProductAdmin,
                icon: 'fa fa-gift'
            },
            {
                path: '/rewards/medal-admin',
                name: 'Administrador de medallas',
                component: MedalAdmin,
                icon: 'fa fa-certificate'
            },
            {
                path: '/rewards/reward-delivery',
                name: 'Entrega de productos',
                component: RewardDelivery,
                icon: 'fa fa-truck'
            },
            {
                path: '/rewards/stats',
                name: 'Estadísticas',
                icon: 'fa fa-line-chart',
                component: RewardsStats
            },
            {
                path: '/rewards/points-assignment',
                name: 'Puntos por puesto',
                icon: 'fa fa-briefcase',
                component: PointsAssignment
            }
        ]:[])
    ];
};
