import React, {Component} from 'react';
import ReactTable from 'react-table';
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import PropTypes from "prop-types";
import moment from 'moment';
import {connect} from 'react-redux';
import _ from 'lodash';
import Swtich from 'react-bootstrap-switch';
import ACReservationDetailModal from './ACReservationDetailModal';
import AddACReservationModal from './AddACReservationModal';
//import Button from "../../../elements/CustomButton/CustomButton";


class ACReservationsList extends Component {

    static contextTypes = {api: PropTypes.object};

    state = {
        showFilters: false,
        showOlder: false,
    };

    loadingId = '@Class.ACReservationsList.airConciergeReservations.get';

    componentDidMount() {

        this.interval = setInterval(() => {
            this.loadReservations(this.tableState);
        }, 300000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    loadReservations = (tableState) => {

        this.tableState = tableState;

        let sort = {};
        if (tableState.sorted.length)
            sort = tableState.sorted.reduce((acc, val) => {
                acc[`order[${val.id}]`] = val.desc ? "DESC" : "ASC";
                return acc;
            }, {});
        else
            sort = {'order[outboundReservationDate]': this.state.showOlder ? 'DESC' : 'ASC'};

        const filter = tableState.filtered.reduce((acc, val) => {
            acc[val.id] = val.value;
            return acc;
        }, {});

        if (!this.state.showOlder)
            filter['reservation_date'] = moment().subtract(2, 'hours').format('YYYY-MM-DD');

        this.context.api.airConciergeReservations.get(tableState.page, tableState.pageSize, this.loadingId, {...sort, ...filter});
    };


    tableStructure = [{
        Header: 'Nombre',
        id: 'search',
        accessor: (reservation) => {

            if (reservation.outboundAirConciergePassengers && reservation.outboundAirConciergePassengers.length)
                return `${reservation.outboundAirConciergePassengers[0].name} ${reservation.outboundAirConciergePassengers[0].lastNames}`

            if (reservation.returnAirConciergePassengers && reservation.returnAirConciergePassengers.length)
                return `${reservation.returnAirConciergePassengers[0].name} ${reservation.returnAirConciergePassengers[0].lastNames}`

            return 'Sin pasajeros';
        },
        filterable: false
    }, {
        Header: 'Fecha de viaje de ida',
        id: 'outboundReservationDate',
        accessor: (reservation) => reservation.outboundReservationDate ? moment(reservation.outboundReservationDate).format('DD/MM/YYYY HH:mm') : 'Sin viaje de ida',
        filterable: false
    },
        {
            Header: 'Fecha de viaje de regreso',
            id: 'returnReservationDate',
            accessor: (reservation) => reservation.returnReservationDate ? moment(reservation.returnReservationDate).format('DD/MM/YYYY HH:mm') : 'Sin viaje de regreso',
            filterable: false
        },
        {
            Header: 'Estado',
            id: 'status',
            accessor: (reservation) => this.transStatus(reservation.status),
            filterable: false
        },{
            Header: 'Cupón',
            accessor: 'discountCoupon',
        },];

    toggleOlder = (c, value) => {

        this.setState({showOlder: value}, () => {
            this.loadReservations(this.tableState);
        })
    };

    toggleFilter = () => {
        this.setState({showFilters: !this.state.showFilters});
    };

    showDetail = (state, rowInfo) => (
        {onClick: () => this.setState({showingDetail: rowInfo.original})}
    );

    hideDetail = () => {
        this.setState({showingDetail: false})
    };

    newReservation = () => {
        this.setState({addingReservation: true})
    };

    hideNewReservation = () => {
        this.setState({addingReservation: false})
    };


    transStatus = (status) => {
        const statuses = {
            requested: 'Solicitada',
            finished: 'Terminada',
            canceled: 'Cancelada'
        };
        if (statuses[status])
            return statuses[status];
        return status;
    };

    render() {

        const {totalItems, itemsPerPage} = this.props.airConciergeReservationsMeta;
        const plural = totalItems > 1 ? "s" : "";


        return (
            <div className="container-fluid main-container ReservationsList">

                {/*
                <Button onClick={this.newReservation} bsStyle='primary' className='col-xs-12'>Agregar
                    reservación</Button>
                */}

                <div className={'col-xs-12 ' + (this.state.showFilters ? 'configuration' : '')}>
                    <h5><span className='link' onClick={this.toggleFilter}><i className='fa fa-cogs'/> Filtros</span>
                    </h5>
                    {this.state.showFilters ?
                        <div className='row'>
                            <div className='col-md-3 filter-wrapper'>
                                <div className='filter-block'>
                                    <Swtich value={this.state.showOlder} onChange={this.toggleOlder}/>&nbsp;
                                    Mostrar reservaciones anteriores
                                </div>
                            </div>
                        </div>
                        : null}
                </div>

                <div className='clearfix'/>

                <ReactTable
                    data={this.props.airConciergeReservations}
                    columns={this.tableStructure}
                    defaultPageSize={10}
                    pages={Math.ceil(totalItems / itemsPerPage)}
                    className="-striped -highlight"
                    loading={!!this.props.loadingIds[this.loadingId]}
                    manual
                    onFetchData={_.debounce(this.loadReservations, 600)}
                    getTdProps={this.showDetail}
                    minRows={3}
                    filterable
                    {...ReactTableTranslations}
                />
                <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>


                {this.state.showingDetail ?
                    <ACReservationDetailModal
                        reservation={this.state.showingDetail}
                        onHide={this.hideDetail}
                    />
                    : null
                }

                {this.state.addingReservation ?
                    <AddACReservationModal
                        onHide={this.hideNewReservation}
                    />
                    : null
                }
            </div>
        );
    }
}

const mapStateToProps = ({
                             api: {
                                 airConciergeReservations = [], airConciergeReservationsMeta = {
                                     totalItems: 0,
                                     itemsPerPage: 0
                                 }
                             }, loadingIds
                         }) => ({airConciergeReservations, airConciergeReservationsMeta, loadingIds});

export default connect(mapStateToProps)(ACReservationsList);
