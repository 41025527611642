import React from 'react';
import './_WarehouseReports.scss';
import InventoryPeriodResume from "./reports/InventoryPeriodResume";
import InventoryExport from "./reports/InventoryExport";
import InventoryValueReport from "./reports/InventoryValueReport";
import WarehouseMovementsReport from "./reports/WarehouseMovementsReport";

const WarehouseReports = () => {

    return (
        <div className={"WarehouseReports"}>
            <h2 className='text-center'>Reportes</h2>
            <div className='card container-fluid'>
                <div className='row report-row'>
                    <div className='col-sm-6'>
                        <InventoryPeriodResume />
                    </div>
                    <div className='col-sm-6'>
                        <InventoryExport />
                    </div>
                </div>
                <div className='row report-row'>
                    <div className='col-sm-6'>
                        <InventoryValueReport />
                    </div>
                    <div className='col-sm-6'>
                        <WarehouseMovementsReport/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WarehouseReports;
