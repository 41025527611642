import React, {useCallback} from 'react';
import {SingleDatePicker} from "react-dates";
import useDatePickerState from "../../../../hooks/useDatePickerState";


const DateField = ({value, onChange, field}) => {

    const {name, label} = field;
    const {props} = useDatePickerState(null);
    const handleChange = useCallback((date)=>{
        onChange && onChange(date, name)
        },[onChange, name]);

    return (
        <div className={"DateField"}>
            <SingleDatePicker
                {...props}
                placeholder={label}
                date={value}
                onDateChange={handleChange}
                {...field.configProps}
            />
        </div>
    );
};

export default React.memo(DateField);
