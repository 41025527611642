import moment from "moment";
import moneyFormatter from "../../moneyFormatter";
import ReactTableDateRangeFilter from "../../../components/Helper/ReactTableDateRangeFilter";

export const listSGroups = [
    //'orozco_payment_read',
    'orozco_payment_read_amount',
    'orozco_payment_read_payment_currency',
    'orozco_payment_read_card_number',
    'orozco_payment_read_orozco_print',
    'orozco_print_read_folio_number',
    'orozco_payment_read_orozco_cash_register',
    'orozco_cash_register_read_lounge',
    'orozco_cash_register_read_name',
    'lounge_read_name',
    'orozco_payment_read_created_by',
    'creation_date',
    'orozco_payment_read_type',
    'user_read_employee',
    'employee_read_full_name',
    'orozco_payment_read_orozco_payment_method',
    'orozco_payment_method_read_name',
    'orozco_payment_read_formatted_amount',
    'orozco_payment_read_formatted_type',
    'orozco_payment_read_formatted_products',
    'orozco_payment_read_guest_name',
    'orozco_payment_read_date_only',
    'orozco_payment_read_time_only',
    'orozco_payment_read_comments'
];

export const orozcoPaymentsListTable = [
    {
        Header: 'Concepto',
        accessor: (p) => {
            if (p.type === 'visit_order' && p.amount < 0) {
                return 'Pago de productos/servicios - (CANCELADO)'
            } else if (p.type === 'check_in' && p.amount < 0) {
                return 'Walk In - (CANCELADO)'
            }

            if (p.type === 'visit_order') {
                return 'Pago de productos/servicios'
            } else
                return 'Walk In'
        },
        id: 'type',
        filterable: false
    },
    {
        Header: 'Folio',
        accessor: 'orozcoPrint.folioNumber',
    },
    {
        Header: 'Realizado por',
        accessor: 'createdBy.employee.fullName',
        id: 'createdBy.employee.name'
    },
    {
        Header: 'Sala',
        accessor: 'orozcoCashRegister.lounge.name'
    },
    {
        Header: 'Caja',
        accessor: 'orozcoCashRegister.name'
    },
    {
        Header: 'Monto',
        accessor: (p) => moneyFormatter(p.amount),
        id: 'amount'
    },
    {
        Header: 'Moneda',
        accessor: 'paymentCurrency'
    },
    {
        Header: 'Método de pago',
        accessor: 'orozcoPaymentMethod.name'
    },
    {
        Header: 'Fecha de pago',
        accessor: (op) => moment(op.createdDate).format('DD/MM/YYYY HH:mm'),
        id: 'createdDate',
        Filter: ReactTableDateRangeFilter
    },
    {
        Header: 'Numero de tarjeta',
        accessor: 'creditCardNumber'
    }

];
