import React, {useMemo, useState, useCallback} from "react";
import ReactTable from "react-table";
import { golfCartDayTripsTable, golfCartTripSGroups } from "../../../../utils/modelUtils/ee/golfCartTripUtils";
import useTideTable from "../../../../hooks/table/useTideTable";
import moment from "moment";
import './_GolfCartDayList.scss'
import {serverDateTimeFormat} from "../../../../utils/timeUtils";
import useBoolean from "../../../../hooks/useBoolean";
import ClassicModal from "../../../../components/Modals/ClassicModal";
import GolfCartTripForm from "../components/GolfCartTripForm/GolfCartTripForm";
import {momentToMinutes} from "../../../../utils/TimeConverter";


const GolfCartDayList = () => {

    const [addingTrip, _startAddingTrip, stopAddingTrip] = useBoolean();
    const startAddingTrip = useCallback(()=>{
        setEditingTrip({startTime:momentToMinutes(moment()), date: moment()});
        _startAddingTrip();
    },[_startAddingTrip]);
    const [editingTrip, setEditingTrip] = useState();

    const requestFilters = useMemo(()=>({
        'scheduledStartDate[after]':moment().subtract(2, 'hours').format(serverDateTimeFormat),
        'scheduledStartDate[before]':moment().add(1,'day').format(serverDateTimeFormat),
        sGroups: golfCartTripSGroups
    }),[]);

    const tripsTable = useTideTable({
        entity: 'golfCartTrips',
        columns: golfCartDayTripsTable,
        newTideApi: true,
        requestFilters
    });

    //Esto requiere una refactorización medio grande.
    //El objeto trip tiene una forma muy extraña que no hace sentido, pero así lo requiere
    //el componente GolfCartTripForm. Se debería rehacer el interior de ese componente para
    //que tenga sentido, pero es mucho trabajo. Hay que considerarlo si hay más cambios en esta
    //sección del código.
    const prepareTripForForm = useCallback((original)=>{

        return {
        ...original,
            scheduledStartTime: momentToMinutes( moment(original.scheduledStartDate) ),
            scheduledEndTime: momentToMinutes( moment(original.scheduledEndDate) ),
            scheduledTrip: original
        };
    },[]);

    const onAddingTripChange = useCallback((id, trip)=>{
        setEditingTrip(prepareTripForForm(trip));
    },[prepareTripForForm]);

    return (
        <div className={"GolfCartTripList main-container container-fluid"}>
            <button className='btn btn-default' onClick={startAddingTrip}>Nuevo viaje</button>
            <ReactTable {...tripsTable.tableProps}
                        getTrProps={(_, data) => ({
                            onClick: () => {
                                if(!data?.original) return;

                                const trip = prepareTripForForm(data.original);
                                setEditingTrip(trip);
                                _startAddingTrip();
                            }
                        })}
            />

            {addingTrip && <ClassicModal onHide={stopAddingTrip} title={'Nuevo viaje'} showConfirm={false}>
                <GolfCartTripForm
                    addingTrip={editingTrip}
                    addingTripOnChange={setEditingTrip}
                    onCancel={stopAddingTrip}
                    reloadAddingTrip={onAddingTripChange}
                    showCartSelector
                />
            </ClassicModal>}

        </div>
    );
}

export default GolfCartDayList;


