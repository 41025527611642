import React, {Component} from 'react';
import {Modal, Table} from 'react-bootstrap';
import Button from "../../../../elements/CustomButton/CustomButton";
import {UserDetailApiProp, UserDetailLoadingId} from "../../Employees/core/UserDetail";
import PropTypes from 'prop-types';
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";


export default class ApplicantModal extends Component {

    static contextTypes = {api: PropTypes.object};

    hire = ()=>{

        if( this.props.didHire )
            this.props.didHire( this.props.applicant );
    };

    employeeDetail = ()=>{
        this.context.api.users.get(0,1,UserDetailLoadingId,{employee:this.props.applicant.employee.id},UserDetailApiProp );
    };

    render() {

        const app = this.props.applicant;

        return (
            <div>

                <Modal
                    show={true}
                    onHide={this.props.onHide}
                >
                    <Modal.Header closeButton >
                        <Modal.Title>Detalle de prospecto</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <Table>
                            <tbody>
                                <tr>
                                    <td >Nombre:</td>
                                    <td >{app.name}</td>
                                </tr>

                                <tr>
                                    <td >Apellido paterno:</td>
                                    <td >{app.patLastName}</td>
                                </tr>

                                <tr>
                                    <td >Apellido materno:</td>
                                    <td >{app.matLastName}</td>
                                </tr>

                                <tr>
                                    <td >Email:</td>
                                    <td >{app.email}</td>
                                </tr>

                                <tr>
                                    <td >Teléfono:</td>
                                    <td >{app.cellPhone}</td>
                                </tr>

                                <tr>
                                    <td >Área:</td>
                                    <td >{app.area?app.area.name:'Sin área'}</td>
                                </tr>

                                <tr>
                                    <td >Puesto:</td>
                                    <td >{app.position?app.position.name:'Sin puesto'}</td>
                                </tr>

                                <tr>
                                    <td >Superior:</td>
                                    <td >{ app.superior?employeeFullName(app.superior):'Sin superior' }</td>
                                </tr>

                            </tbody>
                        </Table>

                    </Modal.Body>

                    <Modal.Footer>
                        { !app.employee?
                        <Button bsStyle="success" onClick={this.hire} >Contratar</Button>
                            :
                        <Button bsStyle="success" onClick={this.employeeDetail} >Ver empleado</Button>
                        }
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}
