import {useState, useCallback} from 'react';
import useCallbackCreator from "./useCallbackCreator";


const useSelectObjectFromArray=(arr, initialSelected=null)=>{

    const [selectedObject, setSelectedObject]=useState(initialSelected);

    const selectObjectAtIndex=useCallbackCreator((index)=>{
        setSelectedObject(arr[index]);
    },[arr]);

    const cancelSelection=useCallback(()=>{
        setSelectedObject(null);
    },[]);

    return [selectedObject, selectObjectAtIndex, cancelSelection, setSelectedObject];
};

export default useSelectObjectFromArray;
