import React from 'react';
import {Link} from "react-router-dom";
import {paths} from "../../../routes/jazz";
import {pluralizeUnitName, unitConversion} from "../almazen/unitsUtility";
import moment from 'moment';
import moneyFormatter from "../../moneyFormatter";
import {maxDecimals} from "../../MathUtils";
import ReactTableDataRangeFilter from "../../../components/Helper/ReactTableDateRangeFilter";


export const purchaseProductListTable=[
    {
        Header:'Marca',
        accessor:'productBrand.brand.name'
    },
    {
        Header: 'Fecha de compra',
        accessor: (pp)=>moment(pp.createdDate).format('DD/MM/YYYY'),
        Filter: ReactTableDataRangeFilter,
        filterable: true,
        id: 'createdDate'
    },
    {
        Header: 'Proveedor',
        accessor: 'purchaseOrder.provider.name'
    },
    {
        Header: 'OC',
        accessor: (pp)=><Link to={paths.singlePurchaseOrder.replace(':id', pp.purchaseOrder.id)}>{pp.purchaseOrder.folio}</Link>,
        id: 'purchaseOrder.id'
    },
    {
        Header: 'Precio',
        accessor: (pp)=>{
            try {
                const price = unitConversion(pp.selectedPurchaseProductQuote.unitPrice, pp.productBrand.product.displayUnit, pp.measurementUnit, pp.productBrand.product);
                return moneyFormatter(price, 1, 'MXN')
            }
            catch (e){
                const price = moneyFormatter(pp?.selectedPurchaseProductQuote?.unitPrice, 1);
                return <span>Unidad eliminada. <br/>({price} {pp?.measurementUnit?.name}) </span>
            }
        },
        id: 'unitPrice'
    },
    {
        Header: 'Cantidad',
        accessor: (pp)=>`${maxDecimals(pp.quantity, 2)} ${pluralizeUnitName(pp.quantity, pp.measurementUnit.name)}`,
        id: 'quantity'
    }
];
export const purchaseProductListSGroups=[
    'purchase_product_read_id',
    'purchase_product_read_quantity',
    'purchase_product_read_created_date',
    'purchase_product_read_purchase_order',
        'purchase_order_read_id',
        'purchase_order_read_provider',
            'provider_read_name',
    'purchase_product_read_measurement_unit',
    'purchase_product_read_product_brand',
        'product_brand_read_product',
            'product_read_smallest_unit',
                'measurement_unit_read',
            'product_read_display_unit',
            'product_read_conversion_rules',
                'uc_rule_read',
        'product_brand_read_brand',
            'brand_read_name',
    'purchase_product_read_selected_purchase_product_quote',
        'purchase_product_quote_read_unit_price'
];

export const purchaseProductsMonthlyReportFilters = {
    sGroups: [
        "purchase_product_read_name",
        "purchase_product_read_purchase_order",
            "purchase_order_read_folio",
            "purchase_order_read_formatted_created_date",
            "purchase_order_read_purchase_requisition",
                "purchase_requisition_read_folio",
            "purchase_order_read_provider",
                "provider_read_name",
        "purchase_product_read_product_brand",
            "product_brand_read_full_name",
            "product_brand_read_product",
                "product_read_line_full_name",
        "purchase_product_read_quantity",
        "purchase_product_read_measurement_unit",
            "measurement_unit_read_name",
        "purchase_product_read_selected_purchase_product_quote",
            "purchase_product_quote_read",
    ],
    pagination: false,
    "exists[purchaseOrder]": true,
    "order[purchaseOrder.createdDate]": "ASC",
    reportConfig: JSON.stringify({
        name: 'Listado de compras mensuales.xlsx',
        headers: {
            'purchaseOrder.formattedCreatedDate': 'Fecha',
            'purchaseOrder.purchaseRequisition.folio': '# Solicitud',
            'purchaseOrder.folio': '# OC',
            'productBrand.fullName': 'Producto de línea',
            'name': 'Producto NO de línea',
            'productBrand.product.lineFullName': 'Línea',
            'purchaseOrder.provider.name': "Proveedor",
            'quantity': `Cantidad`,
            'measurementUnit.name': "Unidad",
            'selectedPurchaseProductQuote.unitPrice': 'Costo unitario',
            'selectedPurchaseProductQuote.discountTotal': 'Descuento',
            'selectedPurchaseProductQuote.taxesTotal': 'Impuestos',
            'selectedPurchaseProductQuote.total': 'Total',
        },
    })
};
