import React, {useState, useMemo, useContext, useCallback} from 'react';
import ReactTable from "react-table";
import {ApiContext} from "../../../api/Api";
import useTideTable from "../../../hooks/table/useTideTable";
import {productLineListColumns, productLineListSGroups} from "../../../utils/modelUtils/almazen/productLineUtils";
import useBoolean from "../../../hooks/useBoolean";
import ProductLineFormModal from "./components/ProductLineFormModal";

const ProductLineList = ()=>{
    const [hideInactive,,,toggleHideInactive]=useBoolean(true);
    const api = useContext(ApiContext)

    const requestFilters=useMemo(()=>({
        sGroups: productLineListSGroups,
        isActive:hideInactive?true:undefined
    }),[hideInactive]);

    const table = useTideTable({
        entity: 'productLines',
        columns: productLineListColumns,
        requestFilters
    });

    const onActivateToggle=useCallback(productLine=>{
        setEditingLine(null);
        api.productLines.edit(productLine.id, {isActive: !productLine.isActive });
    },[api]);

    const [addingLine, startAddingLine, stopAddingLine] = useBoolean();

    const [editingLine, setEditingLine] = useState();

    return(
        <div className='card ProductLineList'>
            <div className='header'>
                <div class="buttons mb-5">
                    <button className='pull-left btn btn-info' onClick={toggleHideInactive}>{hideInactive?'Mostrar':'Ocultar'} inactivos</button>
                    <button className='pull-right btn btn-info' onClick={startAddingLine}>
                        Agregar línea
                    </button>
                </div>
                <h4 className='title text-center'>Administración de líneas de productos</h4>
                <p className='category text-center'>
                    <span className='text-muted'>
                        ¡Advertencia! Modificar el nombre de una línea de producto lo modificará para todos los
                        productos que pertenecen a ella.
                    </span>
                </p>
            </div>
            <div className='content'>
                <ReactTable
                    {...table.tableProps}
                    getTrProps={(_, row)=>
                        ({onClick:()=>setEditingLine(row?.original)})
                    }
                />
            </div>

            {addingLine &&
            <ProductLineFormModal onClose={stopAddingLine}/>}

            {editingLine &&
            <ProductLineFormModal isActive={hideInactive} onActivateToggle={onActivateToggle} onClose={()=>setEditingLine(null)} productLine={editingLine} />}

        </div>
    );
};


export default ProductLineList;
