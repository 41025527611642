import CapitalHumano from "../views/capitalHumano/CapitalHumano";
import EmployeeXFilesList from "../views/alma/Employees/XFiles/EmployeeXFilesList";
import EmployeeXFiles from "../views/alma/Employees/XFiles/EmployeeXFiles";
import HappinessLogReport from "../views/alma/HumanResources/HappinessLogReport";
import EmployeeStatistics from "../views/alma/HumanResources/EmployeeStatistics";
import BirthdayCalendar from "../views/capitalHumano/calendars/BirthdayCalendar";
import AnniversaryCalendar from "../views/capitalHumano/calendars/AnniversaryCalendar";
import EmployeeWorkToolCalendar from "../views/capitalHumano/workTools/EmployeeWorkToolCalendar";
import StaffTable from "../views/alma/HumanResources/StaffTable";
import WorkToolsCatalogue from "../views/capitalHumano/workTools/WorkToolsCatalogue";
import EmployeeWorkToolsExpirationTable from "../views/capitalHumano/workTools/EmployeeWorkToolsExpirationTable.jsx";
import EmployeeWorkToolsManager from "../views/capitalHumano/workTools/EmployeeWorkToolsManager";
import ListEmployees from "../views/alma/Employees/core/ListEmployees";
import EmployeeForm from "../views/alma/Employees/core/EmployeeForm";
import ListFiredEmployees from "../views/alma/Employees/core/ListFiredEmployees";
import PersonnelRequisitions from "../views/alma/HumanResources/PersonnelRequisitions";
import LayoffRequests from "../views/alma/HumanResources/LayoffRequests";
import Applicants from "../views/alma/HumanResources/Applicants";
import AppointmentList from "../views/capitalHumano/Appointments/AppointmentList";
import CHConfiguration from "../views/capitalHumano/Configuration/Components/CHConfiguration";
import Help from "../views/capitalHumano/Help/Help";

export const paths={
    appointments:'/capitalHumano/appointments',
    chHelp:'/capitalHumano/help',
};

export default (securityManager)=> {

    const routes = [];
    routes.push({path: "/capitalHumano/home", name: "Capital Humano", component: CapitalHumano, hidden: true});

    if (!securityManager) return routes;

    if(securityManager.capitalHumano.canSeeUsers())
        routes.push({ collapse: true, name: "Colaboradores", state: "openEmployees", icon: "fa fa-users", views: [
                {path: "/alma/employees/list", name: "Lista de colaboradores", mini:"LE", component: ListEmployees},
                {path: "/alma/employees/add", name: "Nuevo colaborador", mini: "NE", component: EmployeeForm},
                {path: "/alma/employees/fired", name: "Colaboradores dados de baja", mini: "ED", component: ListFiredEmployees},
                {path: "/alma/personnel-requisitions", name: "Requisición de personal", mini: "RP", component: PersonnelRequisitions},
                {path: "/alma/layoff-requests", name: "Requisición de baja", mini: "RB", component: LayoffRequests},
                {path: "/alma/applicants", name: "Personal seleccionado", mini: "PS", component: Applicants},
            ]});

    if(securityManager.capitalHumano.canSeeXFiles()) {
        routes.push({path: "/capitalHumano/xfiles", name: "Expediente digital", icon: "fa fa-files-o", component: EmployeeXFilesList});
        routes.push({path: "/capitalHumano/xfiles/:id", name: "Expedientes de Empleado", component: EmployeeXFiles, hidden: true});
    }

    if(securityManager.capitalHumano.canSeeStatistics())
        routes.push({path: "/capitalHumano/statistics", smallName: "Estadísticas", name: "Estadísticas del personal", icon: "fa fa-bar-chart", component: EmployeeStatistics },);

    if(securityManager.capitalHumano.canSeeEmployeeCalendars())
        routes.push({
            collapse: true, name: "Calendarios", state: "openCalendars", icon: "fa fa-calendar", views: [
                {path: "/capitalHumano/birthday-calendar", name: "Cumpleaños", mini:"C", component: BirthdayCalendar},
                {path: "/capitalHumano/anniversary-calendar", name: "Aniversarios", mini:"A", component: AnniversaryCalendar}
            ]
        });


    if(securityManager.capitalHumano.canSeeStaffTable())
        routes.push({ path: "/capitalHumano/staff-table", name: "Plantilla", icon: "fa fa-user-circle", component: StaffTable });

    if(securityManager.capitalHumano.canSeeWorkTools()){
        routes.push({
            collapse: true, name: "Herramientas de T.", state: "openWorkTools", icon: "fa fa-wrench", views: [
                {path: "/capitalHumano/workTools/manager", smallName: "Administrar", name: "Administrador de herramientas de trabajo", mini:"A", component: EmployeeWorkToolsManager},
                {path: "/capitalHumano/workTools/report", smallName: "Vencimiento", name: "Vencimiento de herramientas de trabajo", mini:"V", component: EmployeeWorkToolsExpirationTable},
                {path: "/capitalHumano/workTools/catalogue", smallName: "Catálogo", name: "Catálogo de herramientas de trabajo", mini:"T", component: WorkToolsCatalogue},
                {path: "/capitalHumano/workTools/calendar", name: "Calendario", mini:"C", component: EmployeeWorkToolCalendar},
            ]
        });
        }

    if(securityManager.capitalHumano.canSeeHappiness())
        routes.push({path: "/capitalHumano/happiness", name: "Felicidad", icon: "fa fa-smile-o", component: HappinessLogReport});

    routes.push({path: paths.appointments, name: "Horarios Extraordinarios", icon: "fa fa-clock-o", component: AppointmentList});
    routes.push({path: "/capitalHumano/configuration", name: "Configuración", icon: "fa fa-cog", component: CHConfiguration});
    routes.push({ path: paths.chHelp, name: "Ayuda", icon: "pe-7s-info", component: Help });

    return routes;
}
