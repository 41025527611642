import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import CustomButton from "../../../elements/CustomButton/CustomButton";


const ConfirmDeleteFileModal = ({onHide, onDelete, repositoryFile})=>{

    return (
        <Modal
            show={true}
            onHide={onHide}
            bsSize='small'
        >
            <ModalHeader closeButton>
                <ModalTitle>
                    Eliminar {repositoryFile.isFolder? "carpeta":"archivo"}
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <p>¿ Estás seguro que deseas borrar
                    {repositoryFile.isFolder?
                        (" la carpeta " + repositoryFile.name + " y todo su contenido"):
                        (" el archivo " + repositoryFile.name )}
                    ?</p>
            </ModalBody>

            <ModalFooter>
                <CustomButton
                    onClick={onHide}
                    bsStyle='danger'
                    className='pull-left'
                >
                    Cancelar
                </CustomButton>
                <CustomButton
                    bsStyle='danger'
                    onClick={onDelete}
                >
                    Borrar
                </CustomButton>
            </ModalFooter>

        </Modal>
    );
};

export default ConfirmDeleteFileModal;
