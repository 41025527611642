import React, {useCallback} from 'react';
import "./_TimeSelector.scss";

const TimeSelector = ({ value, onChange, maxValue=1439 }) => {

    const hours =  !isNaN(Number(value))? Math.floor(Number(value)/60) : '';
    const minutes =  !isNaN(Number(value))? Number(value)%60 : '';

    const handleHoursChange = useCallback((e)=>{
        const val = Number(e.target.value);
        if(isNaN(val))
            return;
        const maxHours = Math.floor(maxValue/60);
        if(val > maxHours || val < 0)
            return;

        onChange( val*60 + ( value%60 || 0 ) );
    },[ value, onChange, maxValue ]);

    const handleMinutesChange = useCallback((e)=>{
        const val = Number(e.target.value);
        if(isNaN(val))
            return;
        const hourMinutes = Math.floor(value/60)*60;
        const maxMinutes = (maxValue - hourMinutes)%60;

        if(val > maxMinutes || val < 0)
            return;

        onChange( (value - value%60 || 0) + val );
    },[ value, onChange, maxValue ]);

    return (
        <div className={"TimeSelector"}>
            <input type="number" className="time-input" value={ hours } onChange={handleHoursChange}/>
            <span className='time-colon'>:</span>
            <input type="number" className="time-input" value={ minutes } onChange={handleMinutesChange}/>
        </div>
    );
};

export default TimeSelector;
