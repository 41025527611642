import React, {useContext, useState, useEffect, useRef, useCallback, useMemo} from 'react';
import './_AddShrinkageMovement.scss';
import {Col, Row, Table} from "react-bootstrap";
import Card from '../../../components/Card/Card.jsx';
import {ApiContext} from "../../../api/Api";
import {connect, useSelector} from "react-redux";
import _ from 'lodash';
import {NotifierContext} from "../../../utils/Notifier";
import {
    convertToSmallestUnit,
    getUnitsArray,
    pluralizeUnitName,
    unitConversion
} from "../../../utils/modelUtils/almazen/unitsUtility";
import EntitySelect from "../../../components/EntitySelect/EntitySelect";
import noProductImg from "../../../assets/img/no-product.png";
import {
    batchLoadingId,
    createNewStockMovementBatch,
    getProductsDifferences,
    movementProductsToStockMovements,
    productGetterFromRecipeObject,
    receiveForcedStockMovementBatch,
    receivePerfectStockMovementBatch,
    receiveStockMovementBatchAndCreatePending,
    stockMovementBatchToStockMovementWithUnit, stockMovementBatchType
} from "../../../utils/modelUtils/almazen/stockMovementBatchUtils";
import PromptStockMovementDifferencesModal from "./PromptStockMovementDifferencesModal";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import CommentsFeed from "../../../components/CommentsFeed/CommentsFeed";
import {orozcoRecipeShrinkageMovementSGroups} from 'utils/modelUtils/orozco/orozcoRecipeUtils.js';
import RadioSelector from 'components/RadioSelector/RadioSelector.jsx';
import {TideApiContext} from 'api/tideApiConfig.js';
import {useHistory, useParams} from 'react-router-dom';

const productLoadingId = '@AddStockMovement.products.get';

const productFilters = {'isActive': true, 'productBrand.product.isActive': true};
const recipeFilters = {sGroups: orozcoRecipeShrinkageMovementSGroups};

const shrinkageTypeOptions = [{label: 'Recetas', value: 'show'}, {label: 'Productos', value: 'hide'}];

const AddShrinkageMovement = ({
                                  productBrandUnits,
                                  movementType = 'shrinkage',
                                  currentWarehouse,
                                  stockMovementBatch,
                                  preventAddingProducts,
                                  cassetteId
                              }) => {
    const api = useContext(ApiContext);
    const tideApi = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);
    const {warehouse} = useParams();
    const history = useHistory();
    const recipeIngredients = useMemo(() => [], []);

    const [productBrandUnit, setProductBrandUnit] = useState(false);
    const [recipeUnit, setRecipeUnit] = useState(false);
    const [movementProducts, setMovementProducts] = useState([]);
    const [movementRecipes, setMovementRecipes] = useState([]);
    const [promptDifferences, setPromptDifferences] = useState(null);
    const [newShrinkageComment, setNewShrinkageComment] = useState('');
    const [entityIsRecipe, setEntityIsRecipe] = useState('hide');

    const sendingBatch = useSelector(({loadingIds}) => !!loadingIds[batchLoadingId]);

    const onBatchSaved = useCallback(() => {
        history.push('/almazen/warehouses');
    }, [history]);

    if (stockMovementBatch)
        movementType = stockMovementBatch.inOrOut;

    const barcodeInputRef = useRef('');

    const addMovementProduct = useCallback((pbu) => {
        const newMovementProducts = [...movementProducts];
        const mpIndex = _.findIndex(newMovementProducts, (mp) => mp.productBrand.id === pbu.productBrand.id);

        if (mpIndex === -1)
            newMovementProducts.push({quantity: 1, selectedUnit: pbu.unit, productBrand: pbu.productBrand});
        else {
            const newMovement = {...newMovementProducts[mpIndex]};
            if (newMovement.selectedUnit.id === pbu.unit.id)
                newMovement.quantity++;
            else
                newMovement.quantity = Number(newMovement.quantity) + unitConversion(1, pbu.unit, newMovement.selectedUnit, pbu.productBrand.product);
            newMovementProducts[mpIndex] = newMovement;
        }
        setMovementProducts(newMovementProducts);
    }, [movementProducts]);


    const addRecipeProduct = useCallback((pbu) => {
        const newMovementRecipes = [...movementRecipes];
        const mpIndex = _.findIndex(newMovementRecipes, (mp) => mp.entity.id === pbu.id);

        if (mpIndex === -1)
            newMovementRecipes.push({quantity: 1, name: pbu.name, entity: pbu, selectedUnit: pbu.smallestUnit});
        else {
            const newMovement = {...newMovementRecipes[mpIndex]};
            newMovement.quantity++;
            newMovementRecipes[mpIndex] = newMovement;
        }
        setMovementRecipes(newMovementRecipes);
    }, [movementRecipes]);

    // ---------------------  Barcode reader management

    const keyUpInterval = useRef(0);
    const onKeyUp = useCallback(({key}) => {

        if (key === 'Shift' || key === 'Ctrl')
            return;

        if (key !== 'Enter') {
            barcodeInputRef.current += key;
            if (keyUpInterval.current)
                clearInterval(keyUpInterval.current);
            keyUpInterval.current = setTimeout(() => {
                barcodeInputRef.current = '';
            }, 500);
            return;
        }

        if (!barcodeInputRef.current)
            return;

        const barcode = barcodeInputRef.current;
        const pbu = _.find(productBrandUnits, (pbu) => pbu.barcode === barcode);

        if (pbu) {
            setProductBrandUnit(pbu);
            addMovementProduct(pbu);
        } else {
            api.productBrandUnits.get({
                page: 0,
                pageSize: 10,
                loadingId: productLoadingId,
                filters: {search: barcode, isService: false}
            }).then((result) => {
                if (result && result[0]) {
                    const pbu = result[0];
                    setProductBrandUnit(pbu);
                    addMovementProduct(pbu);
                } else
                    notifier.error('Producto no encontrado. Código: ' + barcode);
            });
        }
        barcodeInputRef.current = '';
    }, [productBrandUnits, api, addMovementProduct, notifier]);

    useEffect(() => {
        if (preventAddingProducts) return;
        //https://stackoverflow.com/questions/30677134/react-keyboard-events-not-firing Work around
        document.addEventListener("keyup", onKeyUp);
        return () => document.removeEventListener("keyup", onKeyUp);
    }, [onKeyUp, preventAddingProducts]);

    // -------    Set movements from old batch

    useEffect(() => {

        if (!stockMovementBatch) {
            setMovementProducts([]);
            return;
        }
        const movements = stockMovementBatchToStockMovementWithUnit(stockMovementBatch);
        setMovementProducts(movements);

    }, [stockMovementBatch]);

    // ---------------   Api calls ---------

    const finishSendingBatch = useCallback(() => {
        notifier.success('Mermas registradas');
        setMovementProducts([]);
        setProductBrandUnit(null);
        onBatchSaved();
    }, [notifier, onBatchSaved]);

    const sendBatch = useCallback(() => {
                if (!newShrinkageComment)
                    return notifier.error('Tienes que agregar un comentario para guardar.');

                const movementProductsQuantityOnZero = _.filter(movementProducts, (movement) => parseInt(movement.quantity) === 0);
                if (movementProductsQuantityOnZero && movementProductsQuantityOnZero.length !== 0) {
                    const movementProductsWithoutQuantitiesOnZero = _.remove(movementProducts, (movement) => parseInt(movement.quantity) !== 0)
                    return setMovementProducts(movementProductsWithoutQuantitiesOnZero);
                } 

                const stockMovements = movementProductsToStockMovements(movementProducts, movementType);
                const ingredientsToSend = [];

                if (!stockMovementBatch) {

                    const length = recipeIngredients.length;
                    for (let i = 0; i < length; i++) {
                        if (recipeIngredients[i].productBrand) {
                            if (movementType === stockMovementBatchType.SHRINKAGE) {
                                recipeIngredients[i].quantity = (recipeIngredients[i].quantity * -1).toString();
                            }
                            ingredientsToSend.push({...recipeIngredients[i]});
                        }
                    }

                    if (ingredientsToSend.length > 0 || stockMovements.length > 0) {
                        return createNewStockMovementBatch(api, stockMovements, movementType, warehouse, newShrinkageComment, ingredientsToSend)
                            .then(finishSendingBatch);
                    } else {
                        // Show message if ShrinkageMovement was not created
                        return notifier.error('No se pudo crear el movimiento. No hay ingredientes para enviar.');

                        //finishSendingBatch();
                        //console.log(ingredientsToSend)
                    }
                } else {
                    if (ingredientsToSend.length > 0 || stockMovements.length > 0) {
                        const differences = getProductsDifferences(stockMovements, stockMovementBatch.movements);

                        if (!differences.length){
                            return receivePerfectStockMovementBatch(api, stockMovementBatch).then(finishSendingBatch);
                        }

                        setPromptDifferences(differences);
                    } else { return notifier.error('No se pudo crear el movimiento. No hay ingredientes para enviar.'); }

                    setNewShrinkageComment('');
                }

            }
            ,
            [api, notifier, recipeIngredients, movementProducts, movementType, stockMovementBatch, finishSendingBatch, setNewShrinkageComment, newShrinkageComment, warehouse]
        )
    ;

    const fetchInventories = useCallback(() => {
        const productIds = _.map(recipeIngredients, 'productBrand');
        tideApi.inventories.get({params: {warehouse, 'productBrand.product': productIds}}).then((resp) => {
            for (let i = 0; i < recipeIngredients.length; i++) {
                const mockInventory = _.find(resp, inv => inv.productBrand.product.id === recipeIngredients[i].productBrand);
                recipeIngredients[i].productBrand = mockInventory?.productBrand.id;
            }
        }).then(() => sendBatch());
    }, [recipeIngredients, tideApi, warehouse, sendBatch]);

    const sendMovement = useCallback(() => {
        recipeIngredients.splice(0, recipeIngredients.length);
        movementRecipes.forEach((recipe) => {
            const currentRecipeYield = recipe.entity.smallestUnit ? convertToSmallestUnit(recipe.quantity, recipe.selectedUnit, recipe.entity) : recipe.quantity;
            const yieldFactor = currentRecipeYield ? (currentRecipeYield / recipe.entity.quantity) : 1;
            productGetterFromRecipeObject(recipe.entity, recipeIngredients, currentRecipeYield, yieldFactor, tideApi, warehouse)
        });
        fetchInventories();
    }, [fetchInventories, movementRecipes, recipeIngredients, tideApi, warehouse]);

    const closeBatchWithDifferences = useCallback(() => {
        setPromptDifferences(null);
        return receiveForcedStockMovementBatch(api, stockMovementBatch, movementProducts)
            .then(finishSendingBatch);
    }, [api, stockMovementBatch, finishSendingBatch, movementProducts]);

    const closeBatchAndCreatePending = useCallback(() => {
        setPromptDifferences(null);
        return receiveStockMovementBatchAndCreatePending(api, stockMovementBatch, movementProducts, promptDifferences, currentWarehouse, movementType)
            .then(finishSendingBatch);
    }, [api, stockMovementBatch, movementProducts, promptDifferences, currentWarehouse, movementType, finishSendingBatch]);

    // ---------------   Change handlers ---------

    const onProductBrandUnitChange = (productBrandUnit) => {
        setProductBrandUnit(productBrandUnit);
    };

    const onRecipeUnitChange = (recipeUnit) => {
        setRecipeUnit(recipeUnit);
    };

    const handleAddProductButton = useCallback(() => {
        if (productBrandUnit)
            addMovementProduct(productBrandUnit);
    }, [productBrandUnit, addMovementProduct]);

    const handleAddRecipeButton = useCallback(() => {
        if (recipeUnit)
            addRecipeProduct(recipeUnit);
    }, [recipeUnit, addRecipeProduct]);


    const onProductQuantityChange = useCallback((e) => {

        const index = e.target.dataset.index;
        const movementProduct = movementProducts[index];

        const newMovementProducts = [...movementProducts];
        newMovementProducts[index] = {...movementProduct, quantity: e.target.value};

        setMovementProducts(newMovementProducts);

    }, [movementProducts]);

    const onRecipeQuantityChange = useCallback((e) => {

        const index = e.target.dataset.index;
        const movementRecipe = movementRecipes[index];

        const newMovementRecipes = [...movementRecipes];
        newMovementRecipes[index] = {...movementRecipe, quantity: e.target.value};

        setMovementRecipes(newMovementRecipes);

    }, [movementRecipes]);

    const onProductRemove = useCallback((e) => {

        const index = e.currentTarget.dataset.index;
        const newMovementProducts = [...movementProducts];
        newMovementProducts.splice(index, 1);

        setMovementProducts(newMovementProducts);

    }, [movementProducts]);

    const onRecipeRemove = useCallback((e) => {

        const index = e.currentTarget.dataset.index;
        const newMovementRecipes = [...movementRecipes];
        newMovementRecipes.splice(index, 1);

        setMovementRecipes(newMovementRecipes);

    }, [movementRecipes]);

    const onProductUnitChange = useCallback((e) => {

        const index = e.target.dataset.index;
        const movementProduct = movementProducts[index];

        const newMovementProducts = [...movementProducts];
        const selectedId = e.target.value;
        const selectedUnit = _.find(getUnitsArray(movementProduct.productBrand.product), unit => unit.id + '' === selectedId);

        newMovementProducts[index] = {...movementProduct, selectedUnit};
        setMovementProducts(newMovementProducts);

    }, [movementProducts]);

    const onRecipeDefUnitChange = useCallback((e) => {

        const index = e.target.dataset.index;
        const movementRecipe = movementRecipes[index];

        const newMovementRecipes = [...movementRecipes];
        const selectedId = e.target.value;
        const selectedUnit = _.find(getUnitsArray(movementRecipe.entity), unit => unit.id + '' === selectedId);

        newMovementRecipes[index] = {...movementRecipe, selectedUnit};
        setMovementRecipes(newMovementRecipes);

    }, [movementRecipes]);

    const product = productBrandUnit && productBrandUnit.productBrand && productBrandUnit.productBrand.product;

    let productImg = noProductImg;
    if (product && product.photo)
        productImg = api.appFiles.getUrl(product.photo.id);
    else if (productBrandUnit && productBrandUnit.productBrand && productBrandUnit.productBrand.photo)
        productImg = api.appFiles.getUrl(productBrandUnit.productBrand.photo.id);

    //Create title
    const title = 'Nueva merma';

    const handleShrinkageComment = useCallback((e) => {
        setNewShrinkageComment(e.currentTarget.value);
    }, [setNewShrinkageComment]);
    
    return (
        <div className='AddShrinkageMovement'>

            <Row>

                <Col xs={12}>

                    <Card
                        content={
                            <>
                                <Row>

                                    <Col xs={12} md={6}>
                                        <h4>
                                            {title}
                                        </h4><br/>
                                        <>
                                            <p>Agrega un comentario:</p>
                                            <textarea
                                                value={newShrinkageComment}
                                                onChange={handleShrinkageComment}
                                                placeholder='Comentario'
                                                style={{resize: 'none'}}
                                                className='form-control textarea'/>
                                        </>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <button onClick={movementRecipes.length !== 0 ? sendMovement : sendBatch}
                                                disabled={sendingBatch || (movementProducts.length === 0 && movementRecipes.length === 0)}
                                                className={'text-center btn btn-success big-button'}>Guardar
                                        </button>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    {!preventAddingProducts &&
                                    <Col xs={12} md={5}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginBottom: '10px'
                                        }}>
                                            <RadioSelector
                                                options={shrinkageTypeOptions}
                                                onChange={setEntityIsRecipe}
                                                value={entityIsRecipe}
                                            />
                                        </div>
                                        <div className='text-center'>
                                            <div>
                                                <div>
                                                    {entityIsRecipe === 'show' ?
                                                        <EntitySelect
                                                            entity='orozcoRecipes'
                                                            onChange={onRecipeUnitChange}
                                                            apiCustomProp={'orozcoRecipesUnits'}
                                                            placeholder="Escribe el nombre de la receta"
                                                            additionalFilters={recipeFilters}

                                                        />
                                                        :
                                                        <EntitySelect
                                                            entity='productBrandUnits'
                                                            filterBy='search'
                                                            labelCreator={pbu => `${pbu.productBrand.product.name} - ${pbu.productBrand.brand.name} - ${pbu.unit.name}`}
                                                            onChange={onProductBrandUnitChange}
                                                            apiCustomProp={'productBrandUnits'}
                                                            placeholder="Escribe el nombre del producto o escanea un código de barras"
                                                            additionalFilters={productFilters}

                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <Row>
                                                <Col xs={12}>

                                                    <div className='container-fluid product-detail'>
                                                        <Row>
                                                            {entityIsRecipe === 'hide' &&
                                                            <Col xs={12} sm={product ? 6 : 12}>
                                                                <div className='text-center'>
                                                                    <img
                                                                        src={productImg}
                                                                        alt='Producto'
                                                                        className='img-responsive product-img'
                                                                    />
                                                                </div>
                                                            </Col>
                                                            }
                                                            <Col xs={12} sm={6}>
                                                                {entityIsRecipe === 'show' ?
                                                                    <div>
                                                                        <h4 className='text-center'>{recipeUnit && recipeUnit.name}</h4>
                                                                        {recipeUnit &&
                                                                        <p className="prod-description">{recipeUnit.type || 'Sin descripción'}</p>}
                                                                        {recipeUnit &&
                                                                        <CustomButton bsStyle='success'
                                                                                      onClick={handleAddRecipeButton}>Agregar
                                                                            receta</CustomButton>}
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <h4 className='text-center'>{product && product.name}</h4>
                                                                        <h6 className='text-center text-muted'>{product && product.line && ('Línea: ' + product.line.name)}</h6>
                                                                        {product &&
                                                                        <p className="prod-description">{product.description || 'Sin descripción'}</p>}
                                                                        {product && <CustomButton bsStyle='success'
                                                                                                  onClick={handleAddProductButton}>Agregar
                                                                            producto</CustomButton>}
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    }

                                    <Col xs={12} md={preventAddingProducts ? 12 : 7}>
                                        {entityIsRecipe === 'show' ? <>
                                                {movementRecipes.length === 0 ?
                                                    <>
                                                        <h4 className='text-center'>Selecciona una receta para agregarla
                                                            al
                                                            movimiento</h4>
                                                    </>
                                                    :
                                                    <Table className='vertical-responsive-table'>
                                                        <thead>
                                                        <tr>
                                                            <th className='text-center'>Borrar</th>
                                                            <th>Nombre</th>
                                                            <th className='quantity-col'>Cantidad</th>
                                                            <th>Unidad</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {movementRecipes.map((recipe, index) => (
                                                            <tr key={index}>
                                                                <td className='text-center'>
                                                                    <button onClick={onRecipeRemove} data-index={index}
                                                                            className='remove-product-icon'><i
                                                                        className='fa fa-times'/></button>
                                                                </td>
                                                                <td data-label='Prod.'>{recipe.name}</td>
                                                                <td data-label='Cant.' className='quantity-col'>
                                                                    <input value={recipe.quantity} data-index={index}
                                                                           onChange={onRecipeQuantityChange}
                                                                           type='number'
                                                                           className='form-control'/>
                                                                </td>
                                                                <td data-label='Unidad'>
                                                                    <select className='form-control' data-index={index}
                                                                            value={recipe.selectedUnit?.id}
                                                                            onChange={onRecipeDefUnitChange}>
                                                                        {getUnitsArray(recipe.entity).length > 0 ?
                                                                            getUnitsArray(recipe.entity).map(unit =>
                                                                                <option key={unit.id}
                                                                                        value={unit.id}>{pluralizeUnitName(recipe.quantity, unit.name)}</option>
                                                                            ) :
                                                                            <option key="no"
                                                                                    value="no">Sin unidad</option>
                                                                        }

                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        ))}

                                                        </tbody>
                                                    </Table>
                                                }
                                            </>
                                            :
                                            <>
                                                {movementProducts.length === 0 ?
                                                    <>
                                                        <h4 className='text-center'>Selecciona un producto para
                                                            agregarlo al movimiento</h4>
                                                    </>
                                                    :
                                                    <Table className='vertical-responsive-table'>
                                                        <thead>
                                                        <tr>
                                                            <th className='text-center'>Borrar</th>
                                                            <th>Nombre</th>
                                                            {stockMovementBatch &&
                                                            <th>Solicitado</th>}
                                                            <th className='quantity-col'>Cantidad</th>
                                                            <th>Unidad</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {movementProducts.map((movement, index) => (
                                                            <tr key={index}>
                                                                <td className='text-center'>
                                                                    <button onClick={onProductRemove}
                                                                            data-index={index}
                                                                            className='remove-product-icon'><i
                                                                        className='fa fa-times'/></button>
                                                                </td>
                                                                <td data-label='Prod.'>{movement.productBrand.product.name} - {movement.productBrand.brand.name}</td>
                                                                <td data-label='Cant.' className='quantity-col'>
                                                                    <input value={movement.quantity}
                                                                           data-index={index}
                                                                           onChange={onProductQuantityChange}
                                                                           type='number' className='form-control'/>
                                                                </td>
                                                                <td data-label='Unidad'>
                                                                    <select className='form-control'
                                                                            data-index={index}
                                                                            value={movement.selectedUnit.id}
                                                                            onChange={onProductUnitChange}>
                                                                        {getUnitsArray(movement.productBrand.product).map(unit =>
                                                                            <option key={unit.id}
                                                                                    value={unit.id}>{pluralizeUnitName(movement.quantity, unit.name)}</option>
                                                                        )}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        ))}

                                                        </tbody>
                                                    </Table>
                                                }
                                            </>
                                        }
                                    </Col>

                                </Row>
                            </>
                        }
                    >
                    </Card>
                </Col>
            </Row>

            {cassetteId &&
            <CommentsFeed
                cassetteId={cassetteId}
            />}

            {promptDifferences &&
            <PromptStockMovementDifferencesModal
                onHide={() => setPromptDifferences(null)}
                differences={promptDifferences}
                stockMovementBatch={stockMovementBatch}
                onForceClose={closeBatchWithDifferences}
                onCloseWithPending={closeBatchAndCreatePending}

            />
            }

        </div>


    );
};

const mapStateToProps = ({api: {productBrandUnits = []}}) => ({productBrandUnits});

export default connect(mapStateToProps)(AddShrinkageMovement);


