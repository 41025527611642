import React, {useMemo} from 'react';
import {useDropzone} from "react-dropzone";

const dropzoneStyle = {
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: '#eeeeee',
    backgroundColor: '#fafafa',
    padding: '30px',
    color: '#bdbdbd',
    outline: 'none',
    borderRadius: 2,
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'grab'
};

const DropFile = ({onDrop, text, textStyle})=>{
        const {
        getRootProps,
        getInputProps,
        isDragAccept,
        isDragActive,
        isDragReject
    } = useDropzone({onDrop});
            const style = useMemo(() => ({
        ...dropzoneStyle,
        ...(isDragReject ? {borderColor: '#ff1744'} : {}),
        ...(isDragAccept ? {borderColor: '#00e676'} : {}),
        ...(isDragActive ? {borderColor: '#2196f3'} : {}),
    }), [isDragReject, isDragActive, isDragAccept]);
    return (
        <div {...getRootProps({style})}>
            <input {...getInputProps()}/>
            {text&&<p style={textStyle?textStyle:{}}>{text}</p>}
        </div>
    )
};

export default DropFile;
