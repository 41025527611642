import React, {useMemo, useCallback, useState, useContext} from 'react';
import Card from '../../../components/Card/Card.jsx';
import ReactTable from 'react-table';
import Button from '../../../elements/CustomButton/CustomButton';
import useTideTable from "../../../hooks/table/useTideTable";
import moment from 'moment';
import {Modal, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import './_Holidays.scss';
import useInput from '../../../hooks/useInput';
import { prepareHolidayForServer, holidayColumns, holidaysSGroups } from '../../../utils/modelUtils/alma/holidaysUtils.js';
import {NotifierContext} from "../../../utils/Notifier";
import {TideApiContext} from "../../../api/tideApiConfig";
import {useSelector} from "react-redux";
import ConfirmModal from 'components/Modals/ConfirmModal.jsx';

const loadingId = 'Holidays.loading';

const Holidays = () => {
    const notifier = useContext(NotifierContext);
    const api = useContext(TideApiContext);

    const tableOptions = useMemo(()=>({
        entity:'publicHolidays',
        newTideApi: true,
        columns: holidayColumns,
        requestFilters: {
            sGroups: holidaysSGroups,
            'order[date]':'DESC'
        }
    }), []);

    const holidaysTable=useTideTable(tableOptions);
    const holidayName = useInput('');
    const [showHolidayModal, setShowHolidayModal] = useState();
    const [holidayDate, setHolidayDate] = useState(moment());
    const [holidayId, setHolidayId] = useState();
    const [removingHoliday, setRemovingHoliday] = useState();

    const handleChangeDate = useCallback((e, dateRangePicker) => {
        if (!holidayDate.isSame(dateRangePicker.startDate))
            setHolidayDate(dateRangePicker.startDate);
    }, [holidayDate]);

    const clearModal = useCallback(() => {
        setHolidayId(null);
        setHolidayDate(moment()); // clear date
        holidayName.setValue(''); // clear name
        setRemovingHoliday(false);
    }, [holidayName]);

    const showNewHolidayModal = useCallback(() => {
        setShowHolidayModal(true);
        clearModal();
    }, [clearModal]);

    const hideNewHolidayModal = useCallback(() => {
        setShowHolidayModal(false);
        clearModal();
    }, [clearModal]);

    const saveHoliday = useCallback(() => {
        let holiday;

        try {
            holiday = prepareHolidayForServer(holidayName.value, holidayDate);
        } catch (e) {
            return notifier.error(e.message);
        }
        api.publicHolidays.create({params: holiday, loadingId}).then(() => {
            notifier.success('El día festivo ha sido creado');
            hideNewHolidayModal();
        });
    }, [api, notifier, holidayName, holidayDate, hideNewHolidayModal]);

    const updateHoliday = useCallback(() => {
        let holiday;

        try {
            holiday = prepareHolidayForServer(holidayName.value, holidayDate);
        } catch (e) {
            return notifier.error(e.message);
        }
        api.publicHolidays.update({id: holidayId, params: holiday, loadingId}).then(() => {
            notifier.success('El día festivo ha sido actualizado');
            hideNewHolidayModal();
        });
    }, [api, notifier, holidayName, holidayDate, holidayId, hideNewHolidayModal]);

    const deleteHoliday = useCallback(() => {
        if(!holidayId)
            return notifier.error('No se ha seleccionado ningún día festivo');

        api.publicHolidays.delete({id: holidayId, loadingId}).then(()=>{
            notifier.success('El día festivo ha sido eliminado');
            hideNewHolidayModal();
        });
    }, [holidayId, api, hideNewHolidayModal, notifier]);

    const loading = useSelector(s=>!!s.loadingIds[loadingId]);

    return (
        <div className='Holidays'>
            <Card
                title="Administración de días festivos"
                content={
                    <div>
                        <ReactTable
                            {...holidaysTable.tableProps}
                            getTdProps={(state, rowInfo)=>({onClick:()=>{
                                const holiday = Object.assign({},rowInfo.original);

                                setHolidayId(holiday.id);
                                setShowHolidayModal(true); // show modal
                                setHolidayDate(moment(holiday.date)); // set date
                                holidayName.setValue(holiday.name); // set name
                            }})}
                        />

                        <Button
                            onClick={showNewHolidayModal}
                            bsStyle="primary"
                            className="btn-mt-1"
                        >
                            Crear nuevo día festivo
                        </Button>
                    </div>
                }
            />

            <Modal
                onHide={()=>setShowHolidayModal(false)}
                show={showHolidayModal} >
                <Modal.Header closeButton>
                    <Modal.Title>{!holidayId ? 'Nuevo' : 'Editar'} día festivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <FormGroup>
                        <ControlLabel>
                            Nombre del día festivo:
                        </ControlLabel>
                        <FormControl
                            type="text"
                            placeholder="Nombre del día festivo"
                            {...holidayName.bind}
                        />
                    </FormGroup>

                    <FormGroup>
                        <ControlLabel className="label--w100">
                            Fecha del día festivo:
                        </ControlLabel>
                        <DateRangePicker
                            singleDatePicker
                            startDate={holidayDate}
                            onApply={handleChangeDate}
                        >
                            <Button className="select-date-button">
                                <i className="fa fa-calendar"/>
                                {holidayDate?.format("dddd, D MMMM YYYY")}
                                <i className="fa fa-caret-down"/>
                            </Button>
                        </DateRangePicker>
                    </FormGroup>

                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="primary" onClick={holidayId ? updateHoliday : saveHoliday} disabled={loading}>Guardar</Button>
                    {holidayId &&
                        <Button bsStyle="danger" onClick={()=>setRemovingHoliday(true)} disabled={loading}>Eliminar</Button>}
                    <Button bsStyle="warning" onClick={hideNewHolidayModal}>Cerrar</Button>
                </Modal.Footer>
            </Modal>

            {removingHoliday &&
                <ConfirmModal
                    title='Eliminar día festivo'
                    message={'¿Estás seguro que deseas eliminar el día festivo?'}
                    onConfirm={deleteHoliday}
                    onCancel={() => setRemovingHoliday(null)}
                />}
        </div>
    );
};

export default Holidays;
