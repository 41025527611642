import React, {Component} from 'react';
import Card from '../../../../components/Card/Card';
import {Table} from 'react-bootstrap';
import {connect} from "react-redux";
import {UserDetailApiProp, UserDetailLoadingId} from '../../Employees/core/UserDetail';
import PropTypes from "prop-types";


class MEmployeeIncidenceRanking extends Component {

    static defaultProps = {
        order:"DESC",
        count: 5
    };

    static contextTypes = { api:PropTypes.object };

    getInfoArray(){

        const info = this.props.appliedIncidenceReportMDash;

        if( info ){
            const array = [...info.result];
            if( this.props.order === "DESC" )
                return array.splice(0,6);
            else
                return array.splice(-6).reverse();
        }
        else{
            return [];
        }
    }

    showEmployeeDetail( employee ){
        this.context.api.users.get(0,1,UserDetailLoadingId,{employee},UserDetailApiProp );
    }

    render() {

        return (
            <Card
                title={`Empleados con ${this.props.order === "DESC"? "más":"menos"} incidencias`}
                category="Últimos 30 días"
                content={
                    <Table >
                        <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Incidencias</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.getInfoArray().map((val, i)=>
                            <tr key={i}>
                                <td><span className='pointer' onClick={ ()=>this.showEmployeeDetail(val.employeeId) }>{val.attr}</span></td>
                                <td>{val.count}</td>
                            </tr>
                        )}
                        </tbody>
                    </Table>
                }

            />
        );
    }
}

function mapStateToProps( {api:{appliedIncidenceReportMDash}} ){

    return {appliedIncidenceReportMDash}
}

export default connect(mapStateToProps)(MEmployeeIncidenceRanking);
