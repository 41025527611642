import React,{useState, useContext} from 'react';
import {ApiContext} from "../../../api/Api";
import {NotifierContext} from "../../../utils/Notifier";


const ReservationStatusDropdown = ( {reservation } )=>{


    const api = useContext( ApiContext );
    const notifier = useContext(NotifierContext);
    const [newStatus, setNewStatus] = useState(null);


    const onStatusChange = ( status )=>{

        if( status === reservation.status )
            return;

        if( status === 'completed' && !reservation.lounge )
            return notifier.error('Selecciona una sala primero');

        setNewStatus( status );
        api.reservations.edit( reservation.id, { status } )
            .catch( ()=>{
                setNewStatus(null);
            } )
    };

    return (
        <select
            onChange={({target})=>onStatusChange(target.value)}
            onClick={ (e)=>e.stopPropagation() }
            value={newStatus? newStatus : reservation.status}
            className='form-control'>
                <option value='requested'>Solicitada</option>
                <option value='canceled'>Cancelada</option>
                <option value='completed'>Completada</option>
        </select>
    );

};

export default ReservationStatusDropdown;