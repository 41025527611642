
export const priceListSGroups=[
    'product_brand_provider_read',
    'product_brand_provider_read_product_brand',
        'product_brand_photo',
            'app_file_read',
        'product_brand_read_brand',
            'brand_read',
        'product_brand_read_product',
            'product_read_id',
            'product_read_name',
            'product_read_photo',
            'product_read_smallest_unit',
            'product_read_display_unit',
                'measurement_unit_read',
                    'unit_conversion_rule_read',
            'product_read_conversion_rules',
    'product_brand_provider_read_last_purchase_product',
        'purchase_product_read_id',
        'purchase_product_read_created_date',
        'purchase_product_read_purchase_order',
            'purchase_order_read_id'
];

export const productBrandProviderListSGroups=[
    'product_brand_provider_read',
    'product_brand_provider_read_provider',
        'provider_read',
    'product_brand_provider_read_product_brand',
        'product_brand_read_brand',
            'brand_read',
        'product_brand_read_product',
            'product_read_id',
            'product_read_name',
            'product_read_line',
                'product_line_read',
            'product_read_display_unit',
                'measurement_unit_read',
];
