import React from 'react';
import ReactEcharts from "echarts-for-react";
import _ from 'lodash';

const generations = ['silent', 'bb', 'x', 'millenials', 'z', 'other', 'unspecified'];
const genders = [{key: 'M', value: 'men'}, {key: 'F', value: 'women'}, {key:null, value: 'unspecified'}];

const generationStats = report => generation => {
    const buildEntry = (genderKey, count)=>({generation, [genderKey]: count});
    const genderInReport = gender => _.find(report, {gender});
    const builder = (v, k)=>v?buildEntry(k, v[generation]):buildEntry(k, 0);
    const genderEntries = _.map(genders, ({key, value})=>builder(genderInReport(key), value));
    const total = _.sumBy(report, e=>parseInt(e[generation]));
    return _.merge(...[...genderEntries, {generation, total}]);
};

const reportify = report=>
      _.keyBy(_.map(generations, generationStats(report)), 'generation');


const getOption = gs=>({
    title: {
        text: 'Generaciones',
    },
    color:['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622'],
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01]
    },
    yAxis: {
        type: 'category',
        data: ['Sin fecha de nacimiento','Otra generación','Generación del Silencio', 'Generación Baby Boomers', 'Generación X', 'Generación Millenial', 'Generación Z']
    },
    series: [
        {
            name: '',
            type: 'bar',
            data: [
                {
                    value: gs.unspecified.total,
                    itemStyle: {color: '#003D5B'}
                },
                {
                    value: gs.other.total,
                    itemStyle:{color:'#30638E'}
                },
                {
                    value: gs.silent.total,
                    itemStyle: {color: '#EDAE49'}
                },
                {
                    value: gs.bb.total,
                    itemStyle:{color:'#EE6352'}
                },
                {
                    value: gs.x.total,
                    itemStyle: {color: '#00798C'}
                },
                {
                    value: gs.millenials.total,
                    itemStyle:{color:'#102542'}
                },
                {
                    value: gs.z.total,
                    itemStyle:{color:'#06A77D'}
                }
            ]
        }
    ]
});

const GenerationStats = ({generationReport}) => {
    const gs = reportify(generationReport);
    const option = getOption(gs);
    return (
        <ReactEcharts option={option}/>
    );
};

export default GenerationStats;
