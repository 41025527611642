import React, {Component} from 'react';
import {Grid, Row, Col} from 'react-bootstrap';
import MPendingIncidenceRequests from "./Widgets/MPendingIncidenceRequests";
import MEmployeeIncidenceRanking from "./Widgets/MEmployeeIncidenceRanking";
import MAcceptedRequests from "./Widgets/MAcceptedRequests";
import MComplianceIndex from "./Widgets/MComplianceIndex";
import PropTypes from "prop-types";
import moment from 'moment';
import EmployeeDash from './EmployeeDash';

export default class ManagerDashboard extends Component {

    static contextTypes = {api: PropTypes.object };

    constructor(props, context){
        super(props);

        context.api.appliedIncidenceReport.get({ groupBy: "type", fromShiftDate: "2018-02-01", types: ["F", "R", "I", "RP"], includePositives : false }); //For MComplianceIndex
        context.api.employees.get(0,1);// For MComplianceIndex
        context.api.incidenceJustifications.get(undefined,undefined,undefined,undefined,'MDashIncidenceJustifications'); // For MAcceptedRequests, MPendingIncidenceRequests
        context.api.appliedIncidenceReport.get(
            { groupBy : "employee", fromShiftDate: moment().subtract(30,"days").format("YYYY-MM-DD"), includePositives: false },
            undefined,
            "appliedIncidenceReportMDash"); //For MEmployeeIncidenceRanking

    }


    render() {

        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col lg={3} sm={6}>
                            <MAcceptedRequests />
                        </Col>
                        <Col lg={3} sm={6}>
                            <MPendingIncidenceRequests/>
                        </Col>
                        <Col lg={3} sm={6}>
                            <MPendingIncidenceRequests
                                future
                            />
                        </Col>
                        <Col lg={3} sm={6}>
                            <MComplianceIndex

                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <MEmployeeIncidenceRanking

                            />
                        </Col>
                        <Col lg={6}>
                            <MEmployeeIncidenceRanking
                                order="ASC"
                            />
                        </Col>
                    </Row>

                    <hr/>
                    <h4>Mi información</h4>

                </Grid>
                <EmployeeDash />
            </div>
        );
    }
}