import React, {useContext, useEffect, useMemo} from 'react';
import {ApiContext} from "../../../../api/Api";
import {connect} from "react-redux";
import ReactEcharts from 'echarts-for-react';


const GenderGraph = ( {employeesGenderReport} )=>{


    const api = useContext( ApiContext );


    const graphExists = !!employeesGenderReport;
    useEffect( ()=>{
        if( !graphExists )
            api.employees.genderReport();
    }, [ api, graphExists] );

    const genderOptions = useMemo( ()=>{

        if( !employeesGenderReport )
            return {};

        const homCount = employeesGenderReport.genderCount.M;
        const mujCount = employeesGenderReport.genderCount.F;
        const total = Number( homCount ) + Number( mujCount );

        return ({
            backgroundColor:'#f7f7f7',

            legend: {
                left: 'left',
                orient: 'vertical',
                data: ['Hombres','Mujeres'],
                formatter: ( opt )=> opt + ' - ' + ( opt==='Hombres'? `${homCount} (${Math.round(homCount/total*10000)/100} %)`: `${mujCount} (${Math.round(mujCount/total*10000)/100} %)` )
            },
            series : [
                {
                    type:'pie',
                    radius : ['50%', '70%'],
                    center: ['50%', '50%'],
                    data:[
                        {name:'Hombres', value: homCount,itemStyle:{color:"#2f52b8"}},
                        {name:'Mujeres', value: mujCount,itemStyle:{color:"#d88bd8"}},
                    ],
                    label: false,
                    itemStyle: {
                        normal: {
                            shadowBlur: 100,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },

                }
            ]
        });
    }, [employeesGenderReport] );


    return(
        <>
            <h5>Género</h5>
            <ReactEcharts
                option={ genderOptions }
                style={{height:"460px"}}
            />
        </>
    );

};

const mapStateToProps = ( { api: { employeesGenderReport } } )=>({ employeesGenderReport });

export default connect(mapStateToProps)(GenderGraph);
