import React from 'react';
import Switch from "react-bootstrap-switch";


const RequiredQuestion = ( props )=>{

    return (
        <div className='RequiredQuestion'>
            <Switch
                value={ !!props.question.config.required }
                onChange={ (component,value)=>{ props.onChange( { ...props.question.config, required: value } ) } }
            />&nbsp;
            Pregunta obligatoria
        </div>
    );
};


export default RequiredQuestion;