import React, {useState, useContext} from 'react';
import {ApiContext} from "../../../api/Api";
import {Modal, ModalHeader, ModalBody, ModalTitle, FormGroup, FormControl, ControlLabel, Button} from "react-bootstrap";
import DropFile from "../utils/DropFile";

const AddProductModal = ({onHide}) => {
    const [name, setName] = useState('');
    const [pointsCost, setPointsCost] = useState(1);
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const api = useContext(ApiContext);
    const onDrop = (files) => {
        if (files[0]) {
            setImage(files[0]);
        }
    };

    const saveProduct = () => {
        const params = {name, pointsCost, description}
        api.rewards.create({
            params,
            files: {
                image
            }
        });
        onHide();
    };
    return (
        <Modal className='RewardsModal' show onHide={onHide}>
                <ModalHeader closeButton>
                    <ModalTitle>Agregar producto</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <ControlLabel>Nombre: </ControlLabel>
                        <FormControl type="text" value={name} onChange={e => setName(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Costo en puntos: </ControlLabel>
                        <FormControl min={1} type="number" value={pointsCost}
                                     onChange={e => setPointsCost(parseInt(e.target.value))}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Descripción</ControlLabel>
                        <FormControl componentClass="textarea" value={description}
                                     onChange={e => setDescription(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Imagen:</ControlLabel>
                        <DropFile onDrop={onDrop}
                                  text={image ? image.name : 'Da click aquí o arrastra la imagen'}
                                  textStyle={image ? {color: '#000000'} : {}}
                        />
                    </FormGroup>
                    <Button bsStyle='success' onClick={saveProduct}>
                        Guardar nuevo producto
                    </Button>
                </ModalBody>
        </Modal>
    );
};

export default AddProductModal;
