import React,{useState,useCallback,useContext} from 'react';
import ClassicModal from "../../../../components/Modals/ClassicModal";
import EntitySelect from "../../../../components/EntitySelect/EntitySelect";
import useInput from "../../../../hooks/useInput";
import {ApiContext} from "../../../../api/Api";
import {NotifierContext} from "../../../../utils/Notifier";
import {useSelector} from "react-redux";


const loadingId='@CashRegisterFormModal';
const CashRegisterFormModal = ({onHide, cashRegister})=>{

    const nameIn=useInput(cashRegister? cashRegister.name:'');
    const [lounge, setLounge]=useState(cashRegister? cashRegister.lounge:null);

    const api=useContext(ApiContext);
    const notifier=useContext(NotifierContext);

    const handleSave=useCallback(()=>{
        if(!nameIn.value.trim())
            return notifier.error('Debes agregar un nombre.');
        if(!lounge)
            return notifier.error('Selecciona una sala');

        const orozcoCashRegister={id:cashRegister && cashRegister.id, name: nameIn.value, lounge: lounge.id};

        api.orozcoCashRegisters[cashRegister?'edit':'create']({id: cashRegister && cashRegister.id, orozcoCashRegister})
            .then(()=>{
                notifier.success('Caja registradora guardada');
                onHide();
            });
    },[nameIn.value, notifier, lounge, api, onHide, cashRegister]);

    const loading=useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    return (
        <ClassicModal
            title={'Caja registradora'}
            onHide={onHide}
            onSave={handleSave}
            disabled={loading}
        >
            <p>Nombre:</p>
            <input className='form-control' {...nameIn.bind}/>
            <p>Sala:</p>
            <EntitySelect
                entity={'lounges'}
                getMethod={'get2'}
                additionalFilters={loungeFilters}
                value={lounge}
                onChange={setLounge}
                initialLoad
            />
        </ClassicModal>
    );
};

export default CashRegisterFormModal;

const loungeFilters={isLounge: true};
