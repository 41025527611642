import React, {Component} from 'react';
import {Panel, Button, Row} from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

//Requires data array as prop
export default class SimpleBlockTable extends Component {

    constructor(props) {
        super(props);

        this.addModal = this.addModal.bind(this);
        this.deleteModal = this.deleteModal.bind(this);
        this.state = {alert: null};

        this.text = {
            addRow: "Agregar",
            addPlaceholder: "",
            ...this.props.text
        };
    }

    addModal() {
        this.setState({
            alert: <SweetAlert
                input
                showCancel
                cancelBtnText="Cancelar"
                confirmBtnText="Agregar"
                placeholder={this.text.addPlaceholder}
                style={{display: "block"}}
                title={this.text.addModal}
                onConfirm={(e) => this.confirmAdd(e)}
                onCancel={() => this.hideModal()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                required
            />
        });
    }

    hideModal() {
        this.setState({alert: null});
    }

    confirmAdd(e) {
        if (this.props.onAdd)
            this.props.onAdd(e);
        this.setState({alert: null});
    }

    deleteModal(id) {
        this.setState({
            alert: <SweetAlert
                showCancel
                cancelBtnText="Cancelar"
                confirmBtnText="Eliminar"
                style={{display: "block"}}
                title={this.text.deleteConfirm}
                onConfirm={() => this.confirmDelete(id)}
                onCancel={() => this.hideModal()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                required
            />
        });
    }

    confirmDelete(id) {
        if (this.props.onDelete) this.props.onDelete(id);
        this.setState({alert: null});
    }

    editModal(id, actual) {
        this.setState({
            alert: <SweetAlert
                input
                showCancel
                cancelBtnText="Cancelar"
                confirmBtnText="Editar"
                style={{display: "block"}}
                title={this.text.editModal}
                onConfirm={(e) => this.confirmEdit(id, e)}
                onCancel={() => this.hideModal()}
                defaultValue={actual}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                required
            />
        });
    }

    confirmEdit(id, name) {
        if (this.props.onEdit) this.props.onEdit(id, name);
        this.setState({alert: null});
    }

    onBlockClick = ({currentTarget}) => {
        if (!this.props.onBlockClick)
            return;
        const index = currentTarget.dataset.index;
        this.props.onBlockClick(this.props.data[index]);
    };

    render() {

        const accessor = this.props.accessor;
        const idAccessor = this.props.idAccessor;

        const blocks = this.props.data.map((data, index) => {

            const id = idAccessor ? data[idAccessor] : data;
            const name = accessor ? data[accessor] : data;
            return (
                <div onClick={this.onBlockClick} className="col-md-4 col-lg-4" key={id} data-index={index}>
                    <Panel className="single-name-table">
                        <h3>{name}</h3>

                        {this.props.extraInfoComponent ?
                            <this.props.extraInfoComponent
                                data={data}
                                {...this.props.extraInfoComponentProps}
                            />
                            : null
                        }

                        <i className="fa fa-trash" onClick={(e) => {e.stopPropagation();this.deleteModal(id)}}/>
                        <i className="fa fa-pencil" onClick={(e) => {e.stopPropagation();this.editModal(id, name)}}/>
                    </Panel>
                </div>
            );

        });

        const chunkBlocks = _.chunk(blocks, 3);

        return (
            <div>
                {this.state.alert}

                {chunkBlocks.map((blocks, i) => (
                    <Row key={i}>
                        {blocks}
                    </Row>
                ))}

                <div className="clearfix"/>
                <Button bsStyle="primary" onClick={this.addModal}>Agregar</Button>
            </div>
        );
    }
}