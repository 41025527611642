import {copyPropertyIdOrThrow} from "../../../services/formUtils";
import {convertFromSmallestUnit, convertToSmallestUnit, getSuggestedUnitToShow, getUnitsArray} from "../almazen/unitsUtility";
import _ from "lodash";

export const ingredientTypes = {
    product:'product',
    recipe:'orozcoRecipe',
}

export const initializeFormFromIngredient = (ingredient)=>{
    if(!ingredient || ! ingredient.quantity)
        return {};
    const form = {...ingredient};
    const mainIngredient = ingredient.product || ingredient.orozcoRecipe;
    if(!mainIngredient)
        return {};
    form.expectedShrinkage = (ingredient.expectedShrinkage*100)||0;
    const unit = getSuggestedUnitToShow(form.quantity, mainIngredient)
    form.quantity = convertFromSmallestUnit(form.quantity, unit, mainIngredient);
    form.unit = unit.id;
    return form;
};

export const prepareIngredientFormForServer = (form, type)=>{
    if(!form || !type)
        throw new Error('Por favor selecciona el tipo de ingrediente');

    const ingredient = { [ingredientTypes.product]:null, [ingredientTypes.recipe]:null };
    copyPropertyIdOrThrow(form, ingredient, type, 'Por favor selecciona el ingrediente');
    // Conversion de unidades
    const mainIngredient = form[type];
    const units = (mainIngredient && getUnitsArray(mainIngredient))||[];
    const selectedUnit = _.find(units, u=>`${u.id}`===`${form.unit}`);
    if(!selectedUnit)
        throw new Error('Por favor selecciona una unidad');

    if(!form.quantity || !Number(form.quantity))
        throw new Error('Por favor ingresa la cantidad del ingrediente a utilizar');

    ingredient.quantity = convertToSmallestUnit(form.quantity, selectedUnit, mainIngredient)+'';
    ingredient.expectedShrinkage = (Number(form.expectedShrinkage)/100||0)+'';

    if(form.id)
        ingredient.id=form.id;

    return ingredient;
};
