import React, {useContext} from 'react';
import useBoolean from "../../../../hooks/useBoolean";
import classNames from 'classnames';
import {DateRangePicker} from "react-dates";
import useDateRangePickerState from "../../../../hooks/useDateRangePickerState";
import {NotifierContext} from "../../../../utils/Notifier";
import {TideApiContext} from "../../../../api/tideApiConfig";
import {querySerialize} from "../../../../api/Api";
import moment from 'moment';

const OrozcoProductGuestsReport = ({title, productGroup, apiRoute}) => {

    const notifier = useContext(NotifierContext);
    const api = useContext(TideApiContext);
    const [isOpen, , , toggleOpen] = useBoolean();
    const {props, dateRange} = useDateRangePickerState({
	startDate: moment().subtract(1, 'month'),
	endDate: moment()
    });

    const valid = dateRange.startDate&&dateRange.endDate;
    const query = valid&&querySerialize({
	from: dateRange.startDate.format('YYYY-MM-DD'),
	to: dateRange.endDate.format('YYYY-MM-DD'),
	token: api.token,
    productGroup
    });

    const handleClick = (e)=>{
        if(!valid){
            e.preventDefault();
            notifier.error('Llena todos los filtros');
        }
    };
    const reportUrl = valid?`${api.host}${apiRoute}?${query}`:'#';

    return (
        <div className={classNames("report-block", isOpen && 'open')}>
            <p className='title' onClick={toggleOpen}>{title}<i className='fa fa-caret-down caret-icon'/>
            </p>
            {isOpen &&
            <div className='body'>
                <hr/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12'>
                            <p className='filter-label'>Rango de fechas:</p>
                            <DateRangePicker {...props} />
                        </div>
                    </div>
                </div>
                <br/>
                <a onClick={handleClick} href={reportUrl} rel='noopener noreferrer'>
                    <button className='btn btn-info'>Descargar</button>
                </a>
            </div>}
        </div>
    );
}
 
export default OrozcoProductGuestsReport;