import React from "react";
import moneyFormatter from "../../../../utils/moneyFormatter";


const PurchaseRequisitionTotalRow = ({ purchaseRequisition, hasDiscount} )=>{

    if( !purchaseRequisition )
        return null;
    const pr = purchaseRequisition;
    return (

        <tr className='product-row PurchaseRequisitionTotalRow'>
            <td colSpan={7} className='hidden-xs'/>
            <td  data-label='Subtotal'> {moneyFormatter( pr.subtotal, 1, pr.currency )} </td>
            <td className='hidden-xs'/>{/* Unit discount */}
            { hasDiscount? <td  data-label='Total de descuento'> {moneyFormatter( pr.discountTotal, 1, pr.currency )} </td> : null}
            { hasDiscount? <td  data-label='Subtotal con descuento'> {moneyFormatter( pr.subtotalWithDiscount, 1, pr.currency )} </td> : null}
            <td  data-label='Total de impuestos'> {moneyFormatter( pr.taxesTotal, 1, pr.currency )} </td>
            <td  data-label='Total'><strong> {moneyFormatter( pr.total, 1, pr.currency )} </strong></td>
        </tr>

    );
};

export default React.memo( PurchaseRequisitionTotalRow );
