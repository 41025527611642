
export const loungeMinimumSGroups = [
    'place_read_id',
    'place_read_name',
];

export const selectLoungeFilters = { isActive: true, isLounge: true, sGroups: loungeMinimumSGroups};

export const loungeOccupancySGroups = [
    'place_read_id',
    'place_read_name',
    'lounge_read_lounge_map',
    'lounge_map_read_total_seats',
    'lounge_map_read_occupied_seats'
];
