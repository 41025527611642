import React from 'react';
import Switch from "react-bootstrap-switch";

const filterableTypes=['yesno','multiple-choice-radio'];

const FilterableQuestion = ( props )=>{

    if( filterableTypes.indexOf(props.question.type) === -1 )
        return null;

    return (
        <div className='FilterableQuestion'>
            <Switch
                value={ !!props.question.config.filterable }
                onChange={ (component,value)=>{ props.onChange( { ...props.question.config, filterable: value } ) } }
            />&nbsp;
            Agregar filtro con esta pregunta
        </div>
    );
};


export default FilterableQuestion;
