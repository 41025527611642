import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Card from '../../../components/Card/Card.jsx';
import SimpleBlockTable from '../../../components/Table/SimpleBlockTable';
import {connect} from 'react-redux';
import Switch from 'react-bootstrap-switch';
import _ from 'lodash';


const canEditRoles = [
    'RECURSOS_HUMANOS',
    'NOMINAS',
    'NOMINAS_ADMINISTRADOR_EE',
    'SUPER_ADMIN'
];

class Positions extends Component{

    static contextTypes = {
        api: PropTypes.object.isRequired,
        notifier: PropTypes.object
    };

    constructor(props, context){
        super(props);
        this.positionsApi = context.api.positions;
        this.state = { showInactive: false };
    }

    UNSAFE_componentWillMount(){
        this.positionsApi.get( { isActive: 1 } );
    }

    extraInfoProps = {
        onChange: ( id, property, val )=>{

            if( property === 'isActive' ){
                const pos = _.find( this.props.positions, (pos)=>pos.id === id );

                if( !pos )
                    return this.context.notifier.error("Hay un error editando esta posición, contacta a soporte técnico.");

                if( pos.occupiedVacancies )
                    return this.context.notifier.error( "No puedes desactivar un puesto con empleados activos." );

            }
            this.positionsApi.edit( id, { [property]:val } );
        },
        canEdit: canEditRoles.indexOf( this.props.me && this.props.me.role.name ) !== -1
    };

    onShowInactiveChange = ( val )=>{

        this.setState( { showInactive: val } );

        const filter = {};
        if( !val )
            filter.isActive = 1;
        this.positionsApi.get( filter );
    };

    render(){

        return(
            <Card
                title="Administración de puestos"
                content={
                    <>
                        <div>Mostrar puestos inactivos: <Switch
                            { ...switchTexts }
                            value={ this.state.showInactive }
                            onChange={( el, value )=>this.onShowInactiveChange( value )} /></div>

                        <SimpleBlockTable
                            data = {this.props.positions}
                            accessor = "name"
                            idAccessor = "id"
                            text = {{
                                addModal:"Nuevo puesto:",
                                addPlaceholder:"Nombre",
                                deleteConfirm:"¿Estás seguro que deseas borrar este puesto?",
                                editModal:"Editar nombre del puesto:"
                            }}
                            onAdd = {( name )=>this.positionsApi.create( {name} )}
                            onDelete = {this.positionsApi.delete}
                            onEdit = {(id, name)=>this.positionsApi.edit( id, {name} )}

                            extraInfoComponent={positionDetail}
                            extraInfoComponentProps={this.extraInfoProps}

                        />
                    </>
                }
            />
        );
    }
}

const mapStateToProps = ( {api: { positions=[], me} } ) => ({positions, me});

export default connect(mapStateToProps)(Positions);

const switchTexts = { onText:'Sí', offText: 'No' };

const positionDetail = ( { data, onChange, canEdit } )=>{

    return(
        <div>
            { canEdit?
                <>
                    <div>Puede despedir: <Switch
                        { ...switchTexts }
                        defaultValue={ data.canFire }
                        onChange={( el, value )=>onChange( data.id, 'canFire', value)} /></div>

                    <div>Puesto activo: <Switch
                        { ...switchTexts }
                        value={ data.isActive }
                        onChange={( el, value )=>onChange( data.id, 'isActive', value)} /></div>
                </>
                :null}
            <p>Contratados: {data.occupiedVacancies}</p>
        </div>
    );
};
