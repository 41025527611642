import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from 'react-bootstrap';
import moment from "moment";
import './_GolfCartTripModalData.scss'
import {getNumberOfPassengers} from "../../../../../utils/modelUtils/ee/golfCartTripUtils";
import {getMinutesAndSecondsElapsedString} from "../../../../../utils/TimeConverter";

const GolfCartTripModalData = ({showModal, tripData, onClose}) => {
    return (
        <Modal
            className='GolfCartTripModalData'
            show={showModal}
            onHide={onClose}
            bsSize="large"
        >
            <ModalHeader closeButton>
                <ModalTitle>Información del Viaje</ModalTitle>
            </ModalHeader>
            <ModalBody>
                <div className="row text-center">
                    <div className="col-md-4">
                        <h4 className='data-title'><b>Carrito</b></h4>
                        <p>{tripData?.golfCart?.name}</p>
                    </div>
                    <div className="col-md-4">
                        <h4 className='data-title'><b>Operador</b></h4>
                        <p>{tripData?.operator?.fullName}</p>
                    </div>
                    <div className="col-md-4">
                        <h4 className='data-title'><b>Sala</b></h4>
                        <p>{tripData?.lounge?.name || "Sin definir"}</p>
                    </div>
                </div>
                <table className='table table-striped'>
                    <thead>
                    <tr>
                        <th>Inicio programado de viaje</th>
                        <th>Fin programado de viaje</th>
                        <th>Inicio real de viaje</th>
                        <th>Fin real de viaje</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{moment(tripData?.scheduledStartDate).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{moment(tripData?.scheduledEndDate).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{moment(tripData?.realStartDate).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{moment(tripData?.realEndDate).format('DD/MM/YYYY HH:mm')}</td>
                    </tr>
                    </tbody>
                </table>
                <div className="row">
                    <div className="col-md-6">
                        <h4 className='data-title'><b>Huéspedes</b></h4>
                        <ul className='list-data'>
                            {tripData.golfCartPassengers.map((passenger) => {
                                const companions = passenger?.personsNumber > 1 ? ' - (' + (passenger?.personsNumber - 1) + ' Acompañantes) ' : '';
                                const visitCompanions = passenger?.visit?.guest?.fullName + companions;
                                return (<li key={passenger.id}>
                                    <p className='list-item-data'>
                                        {!passenger?.visit ? 'Anónimo' : visitCompanions}
                                    </p>
                                </li>)
                            })}
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <h4 className='data-title'><b>Número de pasajeros total</b></h4>
                        <p>{getNumberOfPassengers(tripData?.golfCartPassengers)}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <h4 className='data-title'><b>Puertas recorridas</b></h4>
                        <ul className='list-data'>
                            {tripData.airportGates.map((gate) =>
                                <li key={gate.id}>
                                    <p className='list-item-data'>{gate.gateNumber}</p>
                                </li>)}
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <h4 className='data-title'><b>Tiempo total de viaje</b></h4>
                        <p>{!tripData.realEndDate ? 'Aún no concluye el viaje' :
                            getMinutesAndSecondsElapsedString(moment(tripData?.realStartDate), moment(tripData?.realEndDate))}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {tripData.realEndDate ? moment(tripData?.realEndDate).isSameOrBefore(tripData?.scheduledEndDate) ?
                            null : <h5 style={{color: 'red'}}>** El viaje finalizó después del tiempo estimado **</h5> : null}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-danger" onClick={onClose}>Cerrar</button>
            </ModalFooter>
        </Modal>
    );
};


export default GolfCartTripModalData;
