import React, {useContext, useEffect, useMemo} from 'react';
import {ApiContext} from '../../../../../api/Api';
import ReactEcharts from "echarts-for-react";
import {useSelector} from 'react-redux';
import _ from 'lodash/fp';

const getOption = (stats)=>({
    title:{
        text:'Aerolineas'
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    },
    xAxis: {
        type: 'category',
        data: _.map('airlineName', stats),
        boundaryGroup: true,
        axisTick: {
            alignWithLabel: true,
        },
        axisLabel: {
            interval: 0
        }
    },
    yAxis: {
        type: 'value'
    },
    series: [{
        data: _.map('total', stats),
        type: 'bar',
    }]
});

const getAirlineTotal = (report, {airlineId})=>{
    return _.flow(
        _.filter({airlineId}),
        _.sumBy(r=>parseInt(r.count))
    )(report);
};

const getAirlineStats = report=>{
    return _.flow(
        _.uniqBy('airlineId'),
        _.map(r=>({
            ...r,
            total: getAirlineTotal(report, r)
        }))
    )(report);
};

const loadingId = '@AirlineStats.report';
const AirlineStats = ({loungeAccessMethod: lam, dateRequest}) => {
    const api = useContext(ApiContext);
    const report = useSelector(({api})=>api.visitsAirlinesReport)||[];
    const option = useMemo(
        ()=>getOption(getAirlineStats(report)),
        [report]
    );

    useEffect(()=>{
        api.visits.airlinesReport({
            params: {
                ...dateRequest,
                ...(lam?{loungeAccessMethods: [lam]}:{}),
            },
            loadingId
        });
    },[api, dateRequest, lam]);

    return (
        <ReactEcharts option={option}/>
    );
};

export default AirlineStats;
