import React, {useMemo} from 'react';
import {Button} from "react-bootstrap";
import ReactTable from "react-table";
import useTideTable from "../../../../hooks/table/useTideTable";
import {
    golfCartScheduleColumns,
    golfCartScheduleListSGroups
} from "../../../../utils/modelUtils/ee/golfCartScheduleUtils";
import {Link} from "react-router-dom";
import {paths} from "../../../../routes/ee";

const requestFilters = {sGroups: golfCartScheduleListSGroups};
const GolfCartScheduleList = ({history}) => {

    const tideTableConfig = useMemo(()=>({
        newTideApi:true,
        entity: "golfCartSchedules",
        columns: golfCartScheduleColumns,
        requestFilters
    }),[]);

    const { tableProps } = useTideTable(tideTableConfig);
    const trProps = ( _, rowInfo) => ({
        onClick: () =>{
            if(rowInfo?.original){
                history.push(paths.golfCartScheduleDetail.replace(":id", rowInfo.original.id ))
            }
        }
    });

    return (
        <div className={"GolfCartScheduleList main-container container-fluid"}>

            <Link to={paths.golfCartScheduleDetail.replace(":id", "new")}><Button>Crear horario</Button></Link>

            <ReactTable
                {...tableProps}
                getTrProps={trProps}
            />

        </div>
    );
};

export default GolfCartScheduleList;
