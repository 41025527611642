import React, {Component} from 'react';
import {Grid, Row, Col} from 'react-bootstrap';
import PayrollDetailGraph from "./Widgets/CPayrollDetailGraph";
import LoungeCostGraph from "./Widgets/CLoungeCostGraph";
import EmployeeRotationGraph from "./Widgets/CEmployeeRotationGraph";
import AvergageWageAndEmployeesGraph from "./Widgets/CAvergageWageAndEmployeesGraph";
import MComplianceIndex from "./Widgets/MComplianceIndex";
import PEmployeeCount from "./Widgets/PEmployeeCount";
import PPayrollTotal from "./Widgets/PPayrollTotal";
import PAverageWage from "./Widgets/PAverageWage";
import MEmployeeIncidenceRanking from "./Widgets/MEmployeeIncidenceRanking";
import PropTypes from 'prop-types';
import moment from "moment/moment";


export default class CapiDashboard extends Component {

    static contextTypes = {api: PropTypes.object };

    constructor(props, context){
        super(props);

        context.api.payrollDetailsRealTime.get();// For PPayrollTotal, PAverageWage
        context.api.appliedIncidenceReport.get({ groupBy: "type", fromShiftDate: "2018-02-01", types: ["F", "R", "I", "RP"], includePositives : false }); //For MComplianceIndex
        context.api.employees.get(0,1, undefined, {isHired:true});// For MComplianceIndex, PEmployeeCount, EmployeeRotationGraph
        context.api.appliedIncidenceReport.get(
            { groupBy : "employee", fromShiftDate: moment().subtract(30,"days").format("YYYY-MM-DD"), includePositives: false },
            undefined,
            "appliedIncidenceReportMDash"); //For MEmployeeIncidenceRanking
        context.api.staffRotationReport.get( { groupBy : "month", fromDate: moment().subtract(5,'months').startOf('month').format("YYYY-MM-DD"), toDate: moment().endOf('month').format("YYYY-MM-DD") } );//For EmployeeRotationGraph
        context.api.payrolls.get(0,6,undefined, {"order[createdDate]":"DESC"});// For CPayrollDetailGraph

        const period = moment().date() < 16 ? 1:2;
        context.api.payrollDetailsReport.get( { groupBy : 'period', fromPeriod: moment().subtract(4,'months').format(`YYYY-M[.${period}]`), toPeriod: moment().format(`YYYY-M[.${period}]`) } ); //For AvergageWageAndEmployeesGraph


    }

    render() {

        return (
            <div className="main-content Dashboard">
                <Grid fluid>

                    <Row>
                        <Col lg={3} sm={6}>
                            <PPayrollTotal/>
                        </Col>

                        <Col lg={3} sm={6}>
                            <PAverageWage/>
                        </Col>

                        <Col lg={3} sm={6}>
                            <MComplianceIndex />
                        </Col>

                        <Col lg={3} sm={6}>
                            <PEmployeeCount />
                        </Col>

                    </Row>
                    <Row>
                        <Col md={6}>
                            <PayrollDetailGraph/>
                        </Col>

                        <Col md={6}>
                            <LoungeCostGraph/>
                        </Col>
                    </Row>

                    <Row>

                        <Col md={6}>
                            <AvergageWageAndEmployeesGraph/>
                        </Col>

                        <Col md={6}>
                            <EmployeeRotationGraph/>
                        </Col>

                    </Row>

                    <Row>
                        <Col lg={6}>
                            <MEmployeeIncidenceRanking

                            />
                        </Col>
                        <Col lg={6}>
                            <MEmployeeIncidenceRanking
                                order="ASC"
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}