import React, {useCallback, useContext, useEffect, useState} from 'react';
import './_RecipeEditor.scss';
import {useParams, useHistory} from 'react-router-dom';
import {TideApiContext} from "../../../../api/tideApiConfig";
import {orozcoProductRecipeEditorSGroups} from "../../../../utils/modelUtils/orozco/orozcoProductUtils";
import {orozcoRecipeEditorSGroups} from "../../../../utils/modelUtils/orozco/orozcoRecipeUtils";
import {paths} from "../../../../routes/katharsis";
import RecipeIngredientsTable from "./components/RecipeIngredientsTable/RecipeIngredientsTable";
import RecipeHeader from "./components/RecipeHeader/RecipeHeader";
import RecipeFooter from "./components/RecipeFooter/RecipeFooter";
import RecipeModifiers from "./components/RecipeModifiers/RecipeModifiers";
import usePageTitle from "../../../../hooks/usePageTitle";
import useBoolean from "../../../../hooks/useBoolean";


const RecipeEditor = ({ recipeId, forceType }) => {

    const api = useContext(TideApiContext);
    const {type:typeInUrl, id:urlId} = useParams();
    const history = useHistory();

    const id = recipeId||urlId;
    const type = forceType||typeInUrl;

    const [orozcoProduct, setOrozcoProduct] = useState();
    let [recipe, setRecipe] = useState();


    usePageTitle(`Receta - ${orozcoProduct?.name || recipe?.name}`);

    // Load data from server
    const loadData = useCallback(()=>{
        if(type === 'product')
            api.orozcoProducts.get({id, params:{sGroups:orozcoProductRecipeEditorSGroups}, customProp:'_'})
                .then(setOrozcoProduct);
                //.then()
        else if(id !=='new' )
            api.orozcoRecipes.get({id, params:{sGroups:orozcoRecipeEditorSGroups}, customProp:'_'})
                .then(setRecipe);

    },[api, type, id]);

    useEffect(()=>{
        loadData();
    },[api, loadData]);

    // Reload if something changed
    const handleChange = useCallback((recipe)=>{
        if(id==='new')
            history.replace(paths.recipesEditor.replace(':type', 'recipe').replace(':id', recipe.id));
        else
            loadData();
    },[history, loadData, id]);

    if(orozcoProduct?.orozcoRecipe)
        recipe = orozcoProduct.orozcoRecipe;

    // For printing the page
    const [showingPrices,,, togglePrices] = useBoolean(true);
    const printPage = useCallback(()=>{
        window.print();
    },[]);

    return (
        <div className={"RecipeEditor"}>
            <div className='container-fluid recipe-container'>
                <RecipeHeader recipe={recipe}
                              orozcoProduct={orozcoProduct}
                              onChange={handleChange}
                              showImage={type!=='modifier'}
                              showPrices={showingPrices}
                />

                <RecipeIngredientsTable recipe={recipe} orozcoProduct={orozcoProduct} onChange={handleChange} showPrices={showingPrices}/>

                <RecipeFooter recipe={recipe} onChange={handleChange}/>
            </div>

            <RecipeModifiers orozcoProduct={orozcoProduct} onChange={handleChange} />

            <button className='btn btn-primary bottom-btn' onClick={printPage}>Imprimir</button>
            <button className='btn btn-primary bottom-btn' onClick={togglePrices}>{showingPrices?'Ocultar precios':'Mostrar precios'}</button>

        </div>
    );
};

export default RecipeEditor;
