import Pages from '../containers/Pages/Pages.jsx';
import Dash from '../containers/Dash/Dash.jsx';

const appRoutes = [
    { path: "/pages/login-page", name: "Pages", component: Pages },
    { path: "/login", name: "Pages", component: Pages },
    { path: "/logout", name: "Pages", component: Pages },
    { path: "/", name: "Home", component: Dash }
];

export default appRoutes;
