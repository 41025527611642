import moment from 'moment';


export const prepareWarehouseCountForServer=(warehouseCount, realCounts)=>{

    const prepared={id: warehouseCount.id};
    if(warehouseCount.completedDate)
        prepared.completedDate=moment(warehouseCount.completedDate).format('YYYY-MM-DD');

    if(!warehouseCount.completedBy)
        return {error: 'Debes seleccionar al responsable del conteo'};

    if(!warehouseCount.receivedBy)
        return {error: 'Debes seleccionar a quién recibirá el conteo'};

    prepared.completedBy=warehouseCount.completedBy.id;
    prepared.receivedBy=warehouseCount.receivedBy.id;
    prepared.shiftType=warehouseCount.shiftType;

    prepared.warehouseCountProductBrands=[];
    for(let i=0; i<warehouseCount.warehouseCountProductBrands.length;i++){
        const wCPB=warehouseCount.warehouseCountProductBrands[i];

        if(isNaN( Number(realCounts[i].value) ) || realCounts[i].value==='')
            return {error:'Ingresa un valor válido en el artículo '+(i+1)};

        prepared.warehouseCountProductBrands.push({
            id: wCPB.id,
            realCount: realCounts[i].valueInSmallestUnit
        });
    }

    return prepared;

};

export const createEmptyRealCounts=(warehouseCount)=>{

    return warehouseCount.warehouseCountProductBrands.map((wCPB)=>({
        value:'',
        valueInSmallestUnit:0,
        unit: wCPB.productBrand.product.displayUnit?
            wCPB.productBrand.product.displayUnit.id:
            wCPB.productBrand.product.smallestUnit.id
        }));
};

export const computeReliability=(real, theoretical)=>{

    if(Number(theoretical)===Number(real))
        return 100;

    const reliability=((theoretical- Math.abs(theoretical-real))/theoretical)*100;

    return reliability<0?0:reliability;
};

export const detailSerializationGroups=[
    'warehouse_count_read',
    'warehouse_count_read_warehouse_count_product_brand',
    'product_brand_read',
    'product_simple_read',
    'uc_rule_read',
    'mu_read',
    'product_line_read',
    'product_brand_unit_read',
    'warehouse_read',
    'app_file_read',
    'cassette_read',
    'user_minimal',
    'employee_simple',
];
