import React, {useState, useContext, useEffect} from 'react'
import {Button} from "react-bootstrap";
import moneyFormatter from "../../../utils/moneyFormatter";
import CommentsFeed from "../../../components/CommentsFeed/CommentsFeed";
import {SecurityContext} from "../../../utils/SecurityManager";
import {TideApiContext} from "../../../api/tideApiConfig";
import {NotifierContext} from "../../../utils/Notifier";
import {useParams} from 'react-router-dom';
import {listSGroupsDetails, budgetDetailToForm} from '../../../utils/modelUtils/jazz/budgetRequestUtils'; // budgetRequestStatusTrans
import useFormState from "../../../hooks/useFormState";
import {useHistory} from 'react-router-dom';

const BUDGET_REQUEST_APPROVAL_1 = 'BUDGET_REQUEST_APPROVAL_1';
const BUDGET_REQUEST_APPROVAL_2 = 'BUDGET_REQUEST_APPROVAL_2';

export const BudgetRequestDetail = () => {
    const history = useHistory();

    const security = useContext(SecurityContext);
    const tideApi = useContext( TideApiContext);
    const notifier = useContext(NotifierContext);
    const {id}=useParams();
    const [approval1, setApproval1] = useState();
    const [approval2, setApproval2] = useState();
    const {form, setForm, handleInputChange} = useFormState();
    const [budgetRequest, setBudgetRequest] = useState();

    const loadDetails = () => {
        tideApi.budgetRequests.get({ params: {sGroups: listSGroupsDetails}, id: id}).then((resp) => {
            setBudgetRequest(resp);
            setForm(budgetDetailToForm(resp.budgetRequestDetails));
        });
    }

    useEffect(() => {
        tideApi.budgetRequests.get({ params: {sGroups: listSGroupsDetails}, id: id}).then((resp) => {
            setBudgetRequest(resp);
            setForm(budgetDetailToForm(resp.budgetRequestDetails));
        });

        tideApi.configurations.get({id: BUDGET_REQUEST_APPROVAL_1}).then(conf => setApproval1(conf));
        tideApi.configurations.get({id: BUDGET_REQUEST_APPROVAL_2}).then(conf => setApproval2(conf));
    }, [tideApi, id, setForm]);

    // const handleReject = () => {
    //     let budgetParams = null;
    //
    //     if(canSetApprove1())
    //         budgetParams = {approvedBy1: security.me.id};
    //
    //     if(canSetApprove2())
    //         budgetParams = {approvedBy2: security.me.id};
    //
    //     tideApi.budgetRequests.update({
    //         id: budgetRequest.id,
    //         params: {
    //             ...budgetParams,
    //             status: 'rejected'
    //         }
    //     }).then(refreshTableAndNotifyRejected);
    // };

    const handleApprove = async () => {
        let budgetParams = null;

        if(canSetApprove1())
            budgetParams = {approvedBy1: security.me.id};

        if(canSetApprove2())
            budgetParams = {approvedBy2: security.me.id};

        for ( let i=0; i < budgetRequest.budgetRequestDetails.length; i++ ) {
            const detail = budgetRequest.budgetRequestDetails[i];
            const detailParams = {
                budget: form[detail.id]
            };

            await tideApi.budgetRequestDetails.update({
                id: detail.id,
                params: {
                    ...detailParams,
                }
            });
        }

        await tideApi.budgetRequests.update({
            id: budgetRequest.id,
            params: {
                ...budgetParams
            }
        });

        refreshTableAndNotify();
    };

    const handleDisapprove = () => {
        tideApi.budgetRequests.update({
            id: budgetRequest.id,
            params: {approvedBy1: null}
        });

        loadDetails();
        notifier.success('Se quitó correctamente la aprobación de la solicitud');
    }

    // const refreshTableAndNotifyRejected = () => {
    //     onClose(true);
    //     notifier.success('La solicitud ha sido rechazada correctamente');
    // }

    const refreshTableAndNotify = () => {
        //onClose(true);
        loadDetails();
        notifier.success('La solicitud ha sido actualizada correctamente');
    }

    const canSetApprove1 = () => {
        if(budgetRequest?.status!=='pending' || budgetRequest?.approvedBy1)
           return false;

        //first approval
        if(((!budgetRequest.approvedBy1 && !budgetRequest.approvedBy2) && (security.me.employee.id===Number(approval1?.value) ))) // || security.me.role.name==='SUPER_ADMIN'
           return true;

           return false;
    };

    const canSetApprove2 = () => {
        if(budgetRequest?.status!=='pending' || budgetRequest?.approvedBy2)
           return false;

        //second approval
        if(((budgetRequest.approvedBy1 && !budgetRequest.approvedBy2) && (security.me.employee.id===Number(approval2?.value)))) // || security.me.role.name==='SUPER_ADMIN'
           return true;

        return false;
    };

    const canDisapprove1 = () => {
        if(budgetRequest?.status!=='pending')
           return false;

        //first approval
        if(((budgetRequest.approvedBy1 && !budgetRequest.approvedBy2) && (security.me.employee.id===Number(approval1?.value))))
           return true;

           return false;
    }

    const onClose = () => {
        history.push('/compras/listado-solicitudes-automaticas-presupuesto');
    }

    const getBudgetRow = (detail) => {
        return (
            <tr key={detail.id}>
                <td>{detail.jazzArea.name}</td>
                <td>{moneyFormatter(detail.calculatedBudgetLastMonth, 1)}</td>
                <td>{moneyFormatter(detail.calculatedBudgetBeforePrevious, 1)}</td>
                <td>{moneyFormatter(detail.realBudgetLastMonth, 1)}</td>
                <td>{moneyFormatter(detail.realBudgetBeforePrevious, 1)}</td>
                <td><b>{moneyFormatter(detail.budget, 1)}</b></td>
                <td>
                    <input
                        onChange={handleInputChange([detail.id])}
                        value={form[detail.id] || ''}
                        disabled={(!canSetApprove1() && !canSetApprove2())}
                    />
                </td>
            </tr>
        );
    }

    return (
        <div className="BudgetRequestList container-fluid main-container">
            <div className="row" style={{marginBottom: '15px'}}>
                <div className='col-md-6 p-3'>
                    Total de presupuesto usado el mes anterior: <b>{moneyFormatter(budgetRequest?.realBudgetLastMonth, 1)}</b>
                </div>
                <div className='col-md-6 p-3'>
                    Total de presupuesto usado el mes anterior: <b>{moneyFormatter(budgetRequest?.realBudgetBeforePrevious, 1)}</b>
                </div>
            </div>

            <div className='col-xs-12'>
                <table className='table table-responsive'>
                    <tbody>
                        <tr>
                            <th>Área</th>
                            <th>P. Calculado Mes anterior</th>
                            <th>P. Calculado Mes antes de anterior</th>
                            <th>P. Gastado Mes anterior</th>
                            <th>P. Gastado Mes antes de anterior</th>
                            <th>P. Calculado</th>
                            <th>Presupuesto</th>
                        </tr>

                        {budgetRequest && budgetRequest.budgetRequestDetails.map(detail=>getBudgetRow(detail))}
                    </tbody>
                </table>

                {budgetRequest?.cassette?.id &&
                    <CommentsFeed
                        cassetteId={budgetRequest.cassette.id}
                        height={'100px'}
                    />}
            </div>
            <div className='col-xs-12 text-right'>
                <Button bsStyle='danger'  className='pull-left' onClick={onClose}>Regresar</Button>

                {(canDisapprove1()) &&
                    <>
                        <Button bsStyle='danger' onClick={handleDisapprove}>Quitar aprobación</Button>
                        &nbsp;
                    </>}

                {(canSetApprove1() || canSetApprove2()) &&
                    <Button bsStyle='primary'  onClick={handleApprove}>Aprobar</Button>}
            </div>
        </div>
    )
}
