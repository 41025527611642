import React, {useMemo, useState, useEffect, useContext} from 'react';
import {ApiContext} from "../../../api/Api";
import {connect} from 'react-redux';
import _ from "lodash/fp";
import SearchProducts, {useProductsSearch} from "../utils/SearchProducts";
import Product from "../utils/Product";
import ScrollBoxes from "./ScrollBoxes";

const states = [
    {
        title: 'Productos comprados',
        getNumber: ({boughtRewardsCount}) => boughtRewardsCount,
        filter: {employeeExchangedRewards: true}
    },
    {
        title: 'Catálogo de productos',
        headerTitle: 'Puntos disponibles',
        getNumber: ({availableExchangePoints}) => availableExchangePoints
    },
    {
        title: 'Productos guardados',
        getNumber: ({wished}) => wished,
        filter: {employeeWishedRewards: true}
    }
];

const Store = ({me, pointsWallets = [], wishedRewards = [], boughtRewardsMeta = {}, history: navigation}) => {
    const [selectedPage, setSelectedPage] = useState(1);
    const api = useContext(ApiContext);
    useEffect(() => {
        api.pointsWallets.get();
        api.employees.wishedRewards();
        api.rewards.get({pageSize: 0, customProp: 'boughtRewards', filters: {employeeExchangedRewards: true}});
    }, [api]);
    const selectedStatus = states[selectedPage];
    const extraFilters = useMemo(() => selectedStatus.filter || {}, [selectedStatus]);
    const {containerCSSClasses, searchProps, results: rewards} = useProductsSearch({
        extraFilters
    });
    const boughtRewardsCount = boughtRewardsMeta.totalItems || 0;

    const pointsWallet = pointsWallets.length > 0 ? pointsWallets[0] : null;
    const wishedIds = _.flow(
        _.filter('isActive'),
        _.map('id')
    )(wishedRewards[0] ? wishedRewards[0].wishedRewards : []);
    const productWished = id => _.includes(id, wishedIds);
    const addWishedInfo = p => ({
        ...p,
        wished: productWished(p.id)
    });
    const getAvailableProducts = _.flow(
        _.filter('isActive'),
        _.map(addWishedInfo)
    );
    const availableProducts = getAvailableProducts(rewards);
    const toggleWish = p => async () => {
        if(!me.employee){
            return;
        }
        const getToWish = p.wished ?
            _.filter(pId => pId !== p.id) :
            _.concat([p.id]);
        await api.employees.editWishedRewards(me.employee.id, {wishedRewards: getToWish(wishedIds)});
        await api.employees.wishedRewards();
    };
    return (
        <>
            <div className={'Rewards store '+containerCSSClasses.join(' ')}>
                <div className='main-container'>
                    <SearchProducts {...searchProps}/>
                    <ScrollBoxes info={pointsWallet ? {
                        ...pointsWallet,
                        boughtRewardsCount,
                        wished: wishedIds.length
                    } : {}}
                                 picked={selectedPage}
                                 setPicked={setSelectedPage}
                                 elements={states} visible={3}/>
                    <div className='page-indicator-row'>
                        <div className='page-indicator'>
                            {states.map((v, k) => <div
                                className={'indicator-dot ' + (k === selectedPage ? 'selected' : '')} key={k}/>)
                            }
                        </div>
                    </div>
                    <div className='products'>
                        <div className='handle-container'>
                            <div className='handle'/>
                        </div>
                        <div className='store-content'>
                            <h4>{selectedStatus.title}</h4>
                            <div className='products-list'>
                                {availableProducts.length===0&&'No hay productos'}
                                {availableProducts.map(p => (
                                    <Product navigation={navigation} key={p.id} toggleWish={toggleWish(p)} {...p}/>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div onClick={()=>searchProps.setShowingFiltersMenu(false)} className={'overlay '+containerCSSClasses.join(' ')}/>
            <div id='filter-portal'/>
        </>
    );
};



const mapStateToProps = ({api: {me, pointsWallets, rewards, rewardWishes: wishedRewards, boughtRewardsMeta}}) => ({
    me,
    pointsWallets,
    wishedRewards,
    boughtRewardsMeta
});

export default connect(mapStateToProps)(Store);
