import _ from 'lodash';
import moment from "moment";
import {minutesToMoment, minutesToString, momentToMinutes} from "../../TimeConverter";
import {employeeFullName} from "./employeeUtils";

export const moveCheckOutHourToNextDay=(checkInTime, checkOutTime)=>{
    if(!checkInTime || !checkOutTime || typeof checkInTime !=='object' || typeof checkOutTime !== 'object')
        return false;

    return checkInTime.hour() > checkOutTime.hour() ||
        ( checkInTime.hour() === checkOutTime.hour() && checkInTime.minute() > checkOutTime.minute());
};

export const emptyAppointment = ()=>({
    checkIn:moment().hour(7).minute(0),
    checkOut:moment().hour(15).minute(0),
    fromDate:moment().add(1,'day'),
    toDate:moment().add(1,'day'),
    hasMealTime:true,
    mealMinutes:'30',
    daysOff:[]
});

export const appointmentToForm=(appointment)=>{
    return {
        ...appointment,
        employees: appointment.employees.map(e=>({employee:e})),
        fromDate: appointment.fromDate?moment(appointment.fromDate):'',
        toDate: appointment.toDate?moment(appointment.toDate):'',
        checkIn: appointment.schedule && appointment.schedule.checkIn && minutesToMoment(appointment.schedule.checkIn),
        checkOut: appointment.schedule && appointment.schedule.checkOut && minutesToMoment(appointment.schedule.checkOut),
    };
};

export const prepareAppointmentForServer = (appointmentForm)=>{
    if(!appointmentForm)
        throw new Error('Error desconocido creando un horario extraordinario. Contacta a soporte');
    if(!appointmentForm.employees || !appointmentForm.employees.length)
        throw new Error('Debes seleccionar al menos un colaborador.');
    if(appointmentForm.hasMealTime && !Number(appointmentForm.mealMinutes))
        throw new Error('El tiempo de comida es inválido');
    if(!appointmentForm.reason || !appointmentForm.reason.trim())
        throw new Error('Debes escribir la razón del cambio de horario');
    if(!appointmentForm.checkIn || ! appointmentForm.checkOut|| typeof appointmentForm.checkIn !=='object' || typeof appointmentForm.checkOut !== 'object')
        throw new Error('Ingresa el horario de entrada y salida');
    if(!appointmentForm.fromDate || ! appointmentForm.toDate)
        throw new Error('Ingresa el rango de fechas para aplicar el horario');
    if(appointmentForm.fromDate.isAfter(appointmentForm.toDate))
        throw new Error('La fecha de inicio debe ser anterior a la fecha de fin');

    return {
        employees: _.map(appointmentForm.employees, (u)=>u.employee.id),
        reason: appointmentForm.reason.trim(),
        hasMealTime: !!appointmentForm.hasMealTime,
        mealMinutes: Number(appointmentForm.mealMinutes),
        fromDate: appointmentForm.fromDate.format('YYYY-MM-DDTHH:mm'),
        toDate: appointmentForm.toDate.format('YYYY-MM-DDTHH:mm'),
        schedule: {checkIn: momentToMinutes(appointmentForm.checkIn), checkOut: momentToMinutes(appointmentForm.checkOut)},
        daysOff: (appointmentForm.daysOff||[]).map(d=>moment(d).format('YYYY-MM-DD')),
        daysWithoutChecks: (appointmentForm.daysWithoutChecks||[]).map(d=>moment(d).format('YYYY-MM-DD')),
    };
};

export const appointmentListSGroups=[
    'appointment_read',
    'appointment_read_created_by',
        'user_read_id',
        'user_read_employee',
    'appointment_read_employees',
        'employee_read_id',
        'employee_read_name',
        'employee_read_pat_last_name',
        'employee_read_mat_last_name',
    'appointment_read_schedule',
        'schedule_read',
];

export const appointmentListColumns=[
    {
        Header:'Creado por',
        accessor:a=>employeeFullName(a.createdBy&&a.createdBy.employee),
        id:'createdBy.employee.name'
    },
    {
        Header:'Colaboradores',
        accessor:a=>((a&&a.employees)||[]).map(employeeFullName).join(', '),
        id:'employees.name'
    },
    {
        Header:'Motivo',
        accessor:'reason'
    },
    {
        Header:'Fecha',
        accessor:(a)=>a.fromDate && moment(a.fromDate).format('DD/MM/YYYY'),
        id:'fromDate',
        filterable: false
    },
    {
        Header:'Horario',
        accessor:(a)=>`${minutesToString(a.schedule.checkIn)} - ${minutesToString(a.schedule.checkOut)}`,
        id:'schedule.checkIn',
        filterable: false
    }
];
