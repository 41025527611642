import './AirlinesManagement.scss';
import React, {useContext, useEffect, useState} from 'react';
import ReactTable from 'react-table';
import {ApiContext} from 'api/Api';
import {useSelector} from 'react-redux';
import {columnsConfig} from './utils/table.config';
import {ReactComponent as Edit} from '../../../assets/icon/edit.svg';
import ClassicModal from 'components/Modals/ClassicModal';
import ReactTableTranslation from 'utils/ReactTableTranslation';
import AirlineForm from './components/AirlineForm/AirlineForm';
import useDebounce from 'hooks/useDebounce';

const MODAL_ACTION_MANAGE_AIRLINE = "MODAL_ACTION_MANAGE_AIRLINE";
const customProp = "@AirlinesManagement.Store";
const sGroups = [
    'airline_read',
    'airline_read_airline_origin',
    'country_read_id',
    'country_read_spanish_name'
];
const AirlinesManagement = () => {
    //Initializers:
    const api = useContext(ApiContext);

    //Init States
    const initModal = {action: null, payload: null};

    //State
    const [modal, setModal] = useState(initModal);
    const [searchTerm, setSearchTerm] = useState("");

    //Helpers
    const searchTermDebounced = useDebounce(searchTerm, 1000);

    //Retrieving airlines:
    useEffect(() => {
        const params = {pagination: false, sGroups, 'order[name]' : 'ASC', name: searchTermDebounced};
        const payload = {params, customProp};
        api.airlines.get(payload);
    }, [api, searchTermDebounced]);

    //Redux Store:
    const airlines = useSelector( ({api}) => api?.[customProp] || [] );

    //State Setters
    const setModalValues = (action, payload = null) => setModal({action, payload});
    const onCloseModal = () => setModal(initModal);


    //Methods
    const saveAirlineChanges = async payload => {
        payload.params.sGroups = sGroups;
        payload.customProp = customProp;
        try {
            const result = await api.airlines.update(payload);
            if(result)
                onCloseModal();
        } catch (error) {
            console.error(error);
        }
    }

    const onSearch = ({target: {value}}) => setSearchTerm(value);

    //Table config with actions:
    const columnsWithActions = [
        {
            Header: 'Acciones',
            id: 'actions',
            width: 100,
            accessor: airline => (
                <button
                    type="button"
                    className="edit-action"
                    onClick={() => setModalValues(MODAL_ACTION_MANAGE_AIRLINE, airline)}
                >
                    <Edit />
                </button>
            )
        },
        ...columnsConfig
    ];

    return(
        <div className="AirlinesManagement">
            <input
                className="form-control search"
                type="text"
                value={searchTerm}
                placeholder="Buscar aerolínea"
                onChange={onSearch}
            />
            <ReactTable
                data={airlines}
                columns={columnsWithActions}
                defaultPageSize={15}
                {...ReactTableTranslation}
            />
            {
                (modal?.action === MODAL_ACTION_MANAGE_AIRLINE) &&
                <ClassicModal
                    title="Editar vuelo"
                    showConfirm={false}
                    showCancel={false}
                    onHide={onCloseModal}
                >
                    <AirlineForm airline={modal?.payload} onSubmit={saveAirlineChanges}/>
                </ClassicModal>
            }
        </div>
    );
};
export default AirlinesManagement;
