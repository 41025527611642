import React, {useCallback, useEffect, useState, useContext, useMemo} from 'react';
import {Table} from "react-bootstrap";
import PurchaseRequisitionTableHeader from "./PurchaseRequisitionTableHeader";
import PurchaseProductRowEditable from "./PurchaseProductRowEditable";
import PurchaseRequisitionCurrencySelector from "./PurchaseRequisitionCurrencySelector";
import {
    basePurchaseProduct,
    computePurchaseRequisitionTotals,
    initializePurchaseRequisitionForQuoting, loadPurchaseRequisitionDraft, prepareQuoteForServer,
    purchaseRequisitionHasDiscount,
    purchaseRequisitionListSGroups,
} from "../../../../utils/modelUtils/jazz/purchaseRequisitionUtils";
import Switch from "react-bootstrap-switch";
import {connect} from "react-redux";
import {ApiContext} from "../../../../api/Api";
import PurchaseRequisitionTotalRow from "./PurchaseRequisitionTotalRow";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import {NotifierContext} from "../../../../utils/Notifier";
import ExpectedDateSelector from './ExpectedDateSelector';
import {NavLink} from "react-router-dom";
import {SecurityContext} from "../../../../utils/SecurityManager";


const PurchaseRequisitionQuoter  = ( { purchaseRequisition, taxes } )=>{

    const api = useContext( ApiContext );
    const notifier = useContext( NotifierContext );

    const [ modifiedPurchaseR, setModifiedPurchaseR ] = useState( null );
    const [hoveredRow, setHoveredRow] = useState( -1 );
    const [ forceOpen, setForceOpen ] = useState( true );

    useEffect(() => {

        const initializer = ( taxes )=>{
            const modifiedPurchaseRequisition = initializePurchaseRequisitionForQuoting( purchaseRequisition, taxes );
            computePurchaseRequisitionTotals( modifiedPurchaseRequisition );
            setModifiedPurchaseR( modifiedPurchaseRequisition );
        }

        if( !taxes )
            api.taxes.get().then( initializer );
        else
            initializer(taxes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseRequisition, api]);

    const onPurchaseProductChange = useCallback(( purchaseProduct, index )=>{

        const purchaseProducts = [ ...modifiedPurchaseR.purchaseProducts ];
        purchaseProducts[index] = purchaseProduct;
        const newRequisition = { ...modifiedPurchaseR, purchaseProducts };
        computePurchaseRequisitionTotals( newRequisition );

        setModifiedPurchaseR( newRequisition );

    }, [modifiedPurchaseR ]);

    const onExtraChargeChange  = useCallback(( purchaseProduct, index )=>{

        const extraCharges = [ ...modifiedPurchaseR.extraCharges ];
        extraCharges[index] = purchaseProduct;
        const newRequisition = { ...modifiedPurchaseR, extraCharges };
        computePurchaseRequisitionTotals( newRequisition );

        setModifiedPurchaseR( newRequisition );

    }, [modifiedPurchaseR ]);

    const pr = modifiedPurchaseR || purchaseRequisition;

    const toggleOpen = useCallback( ()=>setForceOpen(!forceOpen), [ forceOpen ] );
    const handleHoverChange = ( index )=> !forceOpen? setHoveredRow(index):null;

    const hasDiscount = purchaseRequisitionHasDiscount( pr );

    const sendQuotation = useCallback( () => {

        const preparedPR = prepareQuoteForServer( modifiedPurchaseR );
        if( preparedPR.error )
            return notifier.error('Hay un error ' + preparedPR.message );
        preparedPR['sGroups'] = purchaseRequisitionListSGroups;
        api.purchaseRequisitions.edit(modifiedPurchaseR.id, preparedPR)
            .then(()=>{
                const drafts = JSON.parse(window.localStorage.purchaseProductDrafts || "{}");
                if (drafts[purchaseRequisition.id]) {
                    delete drafts[purchaseRequisition.id];
                    window.localStorage.purchaseProductDrafts = JSON.stringify(drafts);
                }

            })
    }, [api, notifier, purchaseRequisition, modifiedPurchaseR]);

    const saveDraft = useCallback( ()=>{
        const drafts = JSON.parse( window.localStorage.purchaseProductDrafts || "{}" );
        drafts[pr.id] = pr;
        window.localStorage.purchaseProductDrafts = JSON.stringify(drafts);
        notifier.success("Borrador guardado");
    },[notifier, pr]);

    const loadDraft = useCallback(()=>{
        const drafts = JSON.parse(window.localStorage.purchaseProductDrafts || "{}");
        if( drafts[pr.id] ) {
            setModifiedPurchaseR(loadPurchaseRequisitionDraft( modifiedPurchaseR, drafts[pr.id]) );
            notifier.success("Borrador cargado correctamente");
        }
    },[pr, modifiedPurchaseR, notifier]);

    const hasDraft = useMemo(()=>{
        const drafts = JSON.parse(window.localStorage.purchaseProductDrafts || "{}");
        return !!drafts[pr.id];
    },[pr]);

    const addExtraCharge = useCallback(()=>{
        const newCharge = basePurchaseProduct({isInStock:false}, true, taxes);
        setModifiedPurchaseR({...pr, extraCharges: [...pr.extraCharges, newCharge]});
    },[pr, taxes]);

    const updatePR = field => newDate => {
        setModifiedPurchaseR(pr=>({
            ...pr,
            [field]: newDate
        }));
    };

    const security = useContext(SecurityContext);

    if( !pr || !pr.purchaseProducts )
        return null;

    return (
        <div className='PurchaseRequisitionQuoter'>

            <div className='row'>
                <div className='col-md-6'>
                    <ExpectedDateSelector
                        expectedDate={pr.expectedDate}
                        onChange={updatePR('expectedDate')}
                    />
                    <PurchaseRequisitionCurrencySelector
                        purchaseRequisition={pr}
                        onChange={ setModifiedPurchaseR }
                    />
                </div>

                <div className='col-md-6'>
                    <div className='pull-right force-open-box hidden-xs' onClick={toggleOpen}>
                        { forceOpen? 'Ocultar':'Mostrar' } todas las cotizaciones &nbsp;
                        <Switch value={forceOpen} />
                    </div>
                </div>
            </div>

            <div className='scrollable-table-container'>
                <Table className='text-center vertical-responsive-table'>

                    <PurchaseRequisitionTableHeader hasDiscount={hasDiscount} />
                    <tbody>

                    { pr.purchaseProducts.map( (purchaseProduct, i)=>
                        <PurchaseProductRowEditable
                            purchaseProduct={purchaseProduct}
                            onChange={ onPurchaseProductChange }
                            onHoverChange={ handleHoverChange }
                            isOpen={ forceOpen || hoveredRow === i }
                            hasDiscount={ hasDiscount }
                            currency={ pr.currency }
                            index={ i }
                            key={ i }
                        />
                    ) }

                    { (pr.extraCharges||[]).map( (purchaseProduct, i)=>
                        <PurchaseProductRowEditable
                            purchaseProduct={purchaseProduct}
                            onChange={ onExtraChargeChange }
                            onHoverChange={ handleHoverChange }
                            isOpen={ forceOpen || hoveredRow === i }
                            hasDiscount={ hasDiscount }
                            currency={ pr.currency }
                            index={ i }
                            key={ i }
                        />
                    ) }

                    <tr><td colSpan={ hasDiscount? 13:11} ><CustomButton bsStyle='danger' onClick={addExtraCharge}>Agregar cargo extra</CustomButton></td></tr>

                    <PurchaseRequisitionTotalRow purchaseRequisition={pr} hasDiscount={ hasDiscount } />

                    </tbody>
                </Table>
            </div>

            <hr/>

            <CustomButton bsStyle='success' className='bottom-actions' onClick={sendQuotation}>Enviar cotización</CustomButton>

            { pr.status === 'requested' && security.jazz.canEditRequisition(pr) &&
            <NavLink to={'/compras/requisitions/'+ purchaseRequisition.id +'/edit'}><CustomButton bsStyle='primary' className='bottom-btn'> Editar requisición</CustomButton></NavLink>}

            {hasDraft?
            <CustomButton bsStyle='warning' className='bottom-actions pull-right' onClick={loadDraft}>Cargar borrador</CustomButton>
                :null}
            <CustomButton bsStyle='warning' className='bottom-actions pull-right' onClick={saveDraft} >Guardar borrador</CustomButton>
            <div className='clearfix'/>

        </div>
    );
};

const mapStateToProps = ({api:{taxes}})=>({taxes});

export default connect(mapStateToProps)(PurchaseRequisitionQuoter);
