import React, {useCallback, useContext, useEffect, useState, useRef, useMemo} from 'react';
import {ApiContext} from "../../../api/Api";
import ReactTable from 'react-table';
import {connect} from "react-redux";
import moment from 'moment/moment';
import {displayAmount} from "../../../utils/modelUtils/almazen/unitsUtility";
import InventoryDetailModal from "./components/InventoryDetailModal";
import {defaultProps as reactTableDefaultProps, filtersToObject} from "../../../utils/reactTableUtils";
import _ from 'lodash';
import CustomButton from "../../../elements/CustomButton/CustomButton";
import {NavLink} from "react-router-dom";
import useTideEntity from "../../../hooks/useTideEntity";
import {productBrandName, getProductBrandPhotoUrl} from "../../../utils/modelUtils/almazen/productBrandUtils";
import {SecurityContext} from "../../../utils/SecurityManager";
import {getInventoryReportDownloadUrl} from "../../../utils/modelUtils/almazen/inventoryUtils";
import {Button} from "react-bootstrap";

const loadingId = "@InventoryList.get";
const InventoryList = ({inventories, match, inventoriesMeta, loadingIds}) => {

    const {totalItems, itemsPerPage} = inventoriesMeta;
    const plural = totalItems > 1 ? "s" : "";
    const id = match.params.id;

    const [showDisabled, setShowDisabled] = useState(true);
    const [filters, setFilters] = useState([]);

    const api = useContext(ApiContext);
    const security = useContext(SecurityContext);
    const [warehouse, loading, notFound] = useTideEntity('warehouses', id);

    const [inventoryDetailId, setInventoryDetailId] = useState(null);
    const inventoryDetail = useMemo(()=>
        inventories &&
        _.find(inventories, inv=>inv.id===inventoryDetailId), [inventories, inventoryDetailId]);

    //Clean data before fetching ( Prevents blinking of wrong data in table )
    useEffect(() => {
        api.clearProperty('inventories');
    }, [api]);

    const tableStateRef = useRef(null);

    const showInactiveProducts = () => {
        reload();
    };

    const loadData = useCallback(_.debounce(tableState => {

        if (showDisabled === true) {
            setShowDisabled(null);
        } else {
            setShowDisabled(true);
        }

        tableStateRef.current = tableState;

        const filters = filtersToObject(tableState.filtered, tableState.sorted);

        //Default order
        if(!tableState?.sorted?.length)
            filters['order[productBrand.product.name]']='ASC';

        filters.warehouse = id;
        filters['productBrand.product.isActive'] = showDisabled;
        setFilters(filters);
        api.inventories.get({page: tableState.page, pageSize: tableState.pageSize, loadingId, filters});
    }, 650), [api, id, showDisabled]);

    const reload = useCallback(() => {
        loadData(tableStateRef.current);
    }, [loadData]);


    return (
        <div className="container-fluid main-container InventoryList">

            <div className='clearfix'/>
            <Button className='pull-right' onClick={showInactiveProducts}>
                {showDisabled ? "Ocultar inactivos" : "Mostrar inactivos"}
            </Button>
            <h2>
                {warehouse ? `Inventario de ${warehouse.name}` : loading ? 'Cargando...' : notFound ? 'No se encontró el almacén' : ''}
            </h2>

            <ReactTable
                data={inventories}
                columns={tableStructure(api)}
                pages={Math.ceil(totalItems / itemsPerPage)}
                loading={!!loadingIds[loadingId]}
                onFetchData={loadData}
                getTrProps={(t, row) => ({onClick: () => setInventoryDetailId(row?.original?.id)})}
                {...reactTableDefaultProps}
                filterable={false}
            />
            <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>


            {security.almazen.canEditWarehouses() || security.almazen.canShowInventoryMode()?
                <NavLink to={'/almazen/inventory-mode/' + id}><CustomButton>Entrar a modo de carga de
                    inventario</CustomButton></NavLink> : null
            }

            <a
                className='pull-right'
                href={getInventoryReportDownloadUrl(api, filters, warehouse?.name)}
                rel="noopener noreferrer"
            >
                <CustomButton>Exportar a Excel</CustomButton>
            </a>

            {inventoryDetail ?
                <InventoryDetailModal
                    inventory={inventoryDetail}
                    onHide={() => setInventoryDetailId(null)}
                    onChange={reload}
                /> : null
            }

        </div>
    );

};

const getStockAmountClass = (inventory) => {

    if (Number(inventory.stockAmount) <= Number(inventory.minStockAmount))
        return 'red';
    if (Number(inventory.stockAmount) <= (Number(inventory.maxStockAmount)*0.3))
        return 'orange';
    return 'green';
};

const mapStateToProps = ({
                             api: {
                                 inventories = [], inventoriesMeta = {
                                     totalItems: 0,
                                     itemsPerPage: 0
                                 }
                             }, loadingIds
                         }) => ({inventories, inventoriesMeta, loadingIds});

export default connect(mapStateToProps)(InventoryList);

const tableStructure = (api) => [
    {
        Header: 'Foto',
        accessor: (inventory) =>
            <div className='list-photo-container'>
                <img alt='Foto' src={getProductBrandPhotoUrl(inventory.productBrand, api)}/>
            </div>
        ,
        id: 'employee.fulfillmentScore',
        filterable: false,
        width: 70
    },
    {
        Header: 'Producto',
        accessor: inventory => productBrandName(inventory.productBrand),
        id: 'productBrand.product.name',
        filterable: true
    },
    {
        Header: 'Linea',
        accessor: inventory => (inventory.productBrand.product.line ? inventory.productBrand.product.line.name : 'Sin línea'),
        id: 'productBrand.product.line.name',
        filterable: true
    },
    {
        Header: 'Cantidad',
        accessor: (inventory) =>
            <span className={getStockAmountClass(inventory)}>
            {displayAmount(inventory.stockAmount, inventory.productBrand.product)}
        </span>,
        id: 'stockAmount'
    },
    {
        Header: 'Mínimo',
        accessor: (inventory) => displayAmount(inventory.minStockAmount, inventory.productBrand.product)||'Sin definir',
        id: 'minStockAmount'
    },
    {
        Header: 'Punto de re-orden',
        accessor: (inventory) => displayAmount(inventory.preorderPoint, inventory.productBrand.product)||'Sin definir',
        id: 'preorderPoint'
    },
    {
        Header: 'Máximo',
        accessor: (inventory) => displayAmount(inventory.maxStockAmount, inventory.productBrand.product)||'Sin definir',
        id: 'maxStockAmount'
    },
    {
        Header: 'Última actualización',
        id: 'updatedDate',
        accessor: (inventory) => inventory.updatedDate ? moment(inventory.updatedDate).format("DD/MM/YYYY HH:mm") : 'Sin fecha de actualizaicón'
    },
    {
        Header: 'En conteo',
        id: 'showInWarehouseCount',
        accessor: (inventory) => inventory.showInWarehouseCount ? 'Si':'No'
    }
];
