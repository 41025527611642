import _ from 'lodash';

export const getSelectableColumns = ({availableColumns, securityManager} )=>{
    return _.filter( availableColumns, (column)=>
        !column.availabilityCheck || column.availabilityCheck({securityManager}));
};

export const getDefaultColumns = ({availableColumns, securityManager } )=>{
    return _.filter( getSelectableColumns({availableColumns, securityManager}),
            col=>col.isDefault );
};

export const getColumnId = column=>column.id || column.accessor;
