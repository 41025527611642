import React, {useState, useEffect, useContext} from 'react';
import useInput from '../../../hooks/useInput';
import Button from "../../../elements/CustomButton/CustomButton";
import {connect} from "react-redux";
import {ApiContext} from "../../../api/Api";
import {NotifierContext} from "../../../utils/Notifier";
import {Creatable as Select} from 'react-select';


const loadingId = '@ProfileCompletionModal.employees.edit';

const ProfileCompletionModal = ( {me, loadingIds} )=>{


    useEffect( ()=>{
        document.body.classList.add('with-modal');
        return ()=>{
            document.body.classList.remove('with-modal');
        }
    }, [] );


    const maritalStatusIn = useInput('');
    const otherMaritalStatusIn = useInput('');

    const addressIn = useInput('');
    const schoolingLevelIn = useInput('');
    const lastSchoolIn = useInput('');
    const passionIn = useInput('');
    const happinessReasonIn = useInput('');
    const talentIn = useInput('');
    const [favouriteFoodType, setFavouriteFoodType] = useState([]);

    const favouriteFoodIn = useInput('');
    const [favouriteSport, setFavouriteSport] = useState([]);

    const [favouriteTeam, setFavouriteTeam] = useState([]);

    const [favouriteMusicType, setFavouriteMusicType] = useState([]);

    const favouriteMusicGroupIn = useInput('');
    const [favouriteMovieType, setFavouriteMovieType ]= useState([]);

    const favouriteMovieIn = useInput('');
    const favouriteActorIn = useInput('');
    const animalWouldBeIn = useInput('');
    const dailyTravelHoursIn = useInput('');
    const dailyTravelMinutesIn = useInput('');
    const dailyTravelCostIn = useInput('');

    const [ childrensAge, setChildrensAge ] = useState([]);

    const changeChildrensAge = ( e )=>{
        const child = e.target.dataset.index;
        const newChildrensAge = [ ...childrensAge ];
        newChildrensAge[ child ] = e.target.value;
        setChildrensAge( newChildrensAge );
    };

    const childrenNumberIn = useInput('', (e)=>{
        const count = Number(e.target.value);
        const ages = [];
        for( let i=0; i<count; i++)
            ages.push( childrensAge[i]? childrensAge[i]:'' );
        setChildrensAge( ages ) ;
    });

    const api = useContext( ApiContext );
    const notifier = useContext( NotifierContext );

    const submitForm = (e)=>{

        e.preventDefault();

        const dailyTravelCost = Number( dailyTravelCostIn.value );

        if( isNaN(dailyTravelCost) )
            return notifier.error('Escribe un número en el costo de trayecto ( sin letras ni símbolos )');

        if( !favouriteFoodType.length )
            return notifier.error('No has respondido cuál es tu tipo de comida favorito');

        if( !favouriteSport.length )
            return notifier.error('No has respondido cuál es tu deporte favorito');

        if( !favouriteTeam.length )
            return notifier.error('No has respondido cuál es tu equipo favorito');

        if( !favouriteMovieType.length )
            return notifier.error('No has respondido cuál es tu género de películas favorito');

        if( !favouriteMusicType.length )
            return notifier.error('No has respondido cuál es tu género musical favorito');


        const employee = {
            maritalStatus: maritalStatusIn.value === 'otro'? otherMaritalStatusIn.value : maritalStatusIn.value ,
            address: addressIn.value,
            schoolingLevel: schoolingLevelIn.value,

            employeeProfile:{
                childrenNumber: Number( childrenNumberIn.value ),
                childrensAge,
                lastSchool: lastSchoolIn.value,
                passion: passionIn.value,
                happinessReason: happinessReasonIn.value,
                talent: talentIn.value,
                favouriteFoodType: favouriteFoodType.map(ob=>ob.value).join(',') ,

                favouriteFood: favouriteFoodIn.value,
                favouriteSport: favouriteSport.map(ob=>ob.value).join(',') ,

                favouriteTeam: favouriteTeam.map(ob=>ob.value).join(',') ,
                favouriteMovieType: favouriteMovieType.map(ob=>ob.value).join(',') ,

                favouriteMovie: favouriteMovieIn.value,
                favouriteActor: favouriteActorIn.value,
                favouriteMusicType: favouriteMusicType.map(ob=>ob.value).join(',') ,

                favouriteMusicGroup: favouriteMusicGroupIn.value,
                animalWouldBe: animalWouldBeIn.value,
                dailyTravelMinutes: Number(dailyTravelHoursIn.value)*60 + Number(dailyTravelMinutesIn.value),
                dailyTravelCost,
            }
        };

        api.employees.editProfile( me.employee.id, employee, loadingId )
            .then( ()=>{
                api.me.get()
            } );

    };


    return (
        <div className='ProfileCompletionModal'>
            <div className='content-wrapper'>
                <h2 className='text-center'>Queremos saber más de ti</h2>
                <p>Por favor, para continuar usando ALMA 2.0, completa la información de tu perfil. Será algo rápido.</p>

                <form onSubmit={submitForm}>
                    <h4>Información personal</h4>
                    <p>¿Cuál es tu estado civil?</p>
                    <select className='form-control' {...maritalStatusIn.bind} required >
                        <option value='' disabled>Selecciona una opción</option>
                        <option value='Soltero'>Solter{me.employee.sex === 'M'? 'o':'a'}</option>
                        <option value='Casado'>Casad{me.employee.sex === 'M'? 'o':'a'}</option>
                        <option value='otro'>Otro</option>
                    </select>
                    {maritalStatusIn.value === 'otro'?
                        <input {...otherMaritalStatusIn.bind} className='form-control' placeholder="Escribe tu estado civil" required />
                        :null}


                    <p>¿Cuántos hijos tienes?</p>
                    <select className='form-control' {...childrenNumberIn.bind} required >
                        {(function(){
                            const arr=[];
                            for( let i=0; i<11; i++)
                                arr.push( <option value={i} key={i}>{i} {i===10?' o más':''}</option> );
                            return arr;
                        })()}
                    </select>
                    {
                        childrenNumberIn.value?
                            <>
                                <p>¿Cuáles son sus edades?</p>
                                {(function(){
                                    const arr=[];
                                    for( let i=0; i<childrenNumberIn.value; i++)
                                        arr.push( <input value={childrensAge[i]} type='number'  data-index={i} key={i} className='form-control' placeholder={'Edad de tu hijo '+(i+1)} onChange={changeChildrensAge} required /> );
                                    return arr;
                                })()}
                            </>:null
                    }
                    <p>¿Cuál es tu dirección?</p>
                    <input {...addressIn.bind} className='form-control' />

                    <p>¿Cuál es tu nivel de escolaridad máximo?</p>
                    <select className='form-control' {...schoolingLevelIn.bind} required >
                        <option value='' disabled>Selecciona una opción</option>
                        <option value='Ninguno'>Ninguno</option>
                        <option value='Primaria'>Primaria</option>
                        <option value='Secundaria'>Secundaria</option>
                        <option value='Preparatoria'>Preparatoria</option>
                        <option value='Licenciatura'>Licenciatura</option>
                        <option value='Maestría'>Maestría</option>
                        <option value='Doctorado'>Doctorado</option>
                    </select>

                    <p>¿Cuál es el nombre de tu última escuela?</p>
                    <input {...lastSchoolIn.bind} className='form-control'  required />

                    <h4>Preguntas generales</h4>

                    <p>¿Cuál es tu pasión en la vida?</p>
                    <input {...passionIn.bind} className='form-control'  required />

                    <p>¿Qué te hace feliz?</p>
                    <input {...happinessReasonIn.bind} className='form-control'  required />


                    <p>¿Tienes algún talento especial?</p>
                    <input {...talentIn.bind} className='form-control'  required />

                    <p>¿Cuáles son tus tipos de comida favoritos?</p>
                    <Select
                        multi
                        onChange={ setFavouriteFoodType }
                        value={ favouriteFoodType }
                        promptTextCreator={(val)=>'Seleccionar tipo de comida '+val}
                        options={[
                        {value:'Alemana', label:'Alemana'},
                        {value:'Americana', label:'Americana'},
                        {value:'Argentina', label:'Argentina'},
                        {value:'Brasileña', label:'Brasileña'},
                        {value:'China', label:'China'},
                        {value:'Cubana', label:'Cubana'},
                        {value:'Española', label:'Española'},
                        {value:'Francesa', label:'Francesa'},
                        {value:'India', label:'India'},
                        {value:'Italiana', label:'Italiana'},
                        {value:'Japonesa', label:'Japonesa'},
                        {value:'Koreana', label:'Koreana'},
                        {value:'Libanesa', label:'Libanesa'},
                        {value:'Mexicana', label:'Mexicana'},
                        {value:'Peruana', label:'Peruana'},
                        {value:'Tailandesa', label:'Tailandesa'},
                        {value:'Vietnamita', label:'Vietnamita'},
                    ]}/>

                    <p>¿Cuáles son tus platillos favoritos?</p>
                    <input {...favouriteFoodIn.bind} className='form-control'  required />

                    <p>¿Cuáles son tus deportes favoritos?</p>
                    <Select
                        multi
                        onChange={ setFavouriteSport }
                        value={ favouriteSport }
                        promptTextCreator={(val)=>'Seleccionar deporte '+val}
                        options={[
                            {value:'Americano', label:'Americano'},
                            {value:'Baloncesto', label:'Baloncesto'},
                            {value:'Béisbol', label:'Béisbol'},
                            {value:'Ciclismo', label:'Ciclismo'},
                            {value:'Esports', label:'Esports'},
                            {value:'Excursionismo', label:'Excursionismo'},
                            {value:'Golf', label:'Golf'},
                            {value:'Natación', label:'Natación'},
                            {value:'Rugby', label:'Rugby'},
                            {value:'Senderismo', label:'Senderismo'},
                            {value:'Tenis', label:'Tenis'},
                            {value:'Voleibol', label:'Voleibol'},
                            {value:'Motociclismo', label:'Motociclismo'},
                            {value:'Fútbol', label:'Fútbol'},
                        ]}/>

                    <p>¿Cuáles son tus equipos favoritos?</p>
                    <Select
                        multi
                        onChange={ setFavouriteTeam }
                        value={ favouriteTeam }
                        promptTextCreator={(val)=>'Seleccionar equipo '+val}
                        options={[
                            {value:'Atlas', label:'Atlas'},
                            {value:'América', label:'América'},
                            {value:'Atlético de Madrid', label:'Atlético de Madrid'},
                            {value:'Barcelona', label:'Barcelona'},
                            {value:'Chelsea', label:'Chelsea'},
                            {value:'Chivas', label:'Chivas'},
                            {value:'Cruz Azul', label:'Cruz Azul'},
                            {value:'Juventus', label:'Juventus'},
                            {value:'Liverpool', label:'Liverpool'},
                            {value:'Manchester United', label:'Manchester United'},
                            {value:'Monterrey', label:'Monterrey'},
                            {value:'Necaxa', label:'Necaxa'},
                            {value:'Pachuca', label:'Pachuca'},
                            {value:'Pumas', label:'Pumas'},
                            {value:'Santos', label:'Santos'},
                            {value:'Sevilla', label:'Sevilla'},
                            {value:'Tigres', label:'Tigres'},
                            {value:'Toluca', label:'Toluca'},
                            {value:'Real Madrid', label:'Real Madrid'},
                        ]}/>

                    <p>¿Cuáles son tus géneros de música favoritos?</p>
                    <Select
                        multi
                        onChange={ setFavouriteMusicType }
                        value={ favouriteMusicType }
                        promptTextCreator={(val)=>'Seleccionar género de música '+val}
                        options={[
                            {value:'Ballenato', label:'Ballenato'},
                            {value:'Blues', label:'Blues'},
                            {value:'Corrido', label:'Corrido'},
                            {value:'Country', label:'Country'},
                            {value:'Cumbia', label:'Cumbia'},
                            {value:'Disco', label:'Disco'},
                            {value:'Electrónica', label:'Electrónica'},
                            {value:'Flamenco', label:'Flamenco'},
                            {value:'Folk', label:'Folk'},
                            {value:'Funk', label:'Funk'},
                            {value:'Gospel', label:'Gospel'},
                            {value:'Heavy Metal', label:'Heavy Metal'},
                            {value:'Hip Hop', label:'Hip Hop'},
                            {value:'Indie', label:'Indie'},
                            {value:'Jazz', label:'Jazz'},
                            {value:'Merengue', label:'Merengue'},
                            {value:'Pop', label:'Pop'},
                            {value:'Punk', label:'Punk'},
                            {value:'Ranchera', label:'Ranchera'},
                            {value:'Rap', label:'Rap'},
                            {value:'Reggae', label:'Reggae'},
                            {value:'Reggaeton', label:'Reggaeton'},
                            {value:'Rhythm and Blues', label:'Rhythm and Blues'},
                            {value:'Rock', label:'Rock'},
                            {value:'Rock and Roll', label:'Rock and Roll'},
                            {value:'Rumba', label:'Rumba'},
                            {value:'Salsa', label:'Salsa'},
                            {value:'Samba', label:'Samba'},
                            {value:'Son', label:'Son'},
                            {value:'Soul', label:'Soul'},
                            {value:'Tango', label:'Tango'},
                        ]}/>


                    <p>¿Cuáles son tus grupos o artistas musicales favoritos?</p>
                    <input {...favouriteMusicGroupIn.bind} className='form-control'  required />

                    <p>¿Cuáles son tus géneros de películas favoritos?</p>
                    <Select
                        multi
                        onChange={ setFavouriteMovieType}
                        value={ favouriteMovieType }
                        promptTextCreator={(val)=>'Seleccionar género de películas '+val}
                        options={[
                            {value:'Acción', label:'Acción'},
                            {value:'Animación', label:'Animación'},
                            {value:'Bélico', label:'Bélico'},
                            {value:'Ciencia ficción', label:'Ciencia ficción'},
                            {value:'Comedia', label:'Comedia'},
                            {value:'Culto', label:'Culto'},
                            {value:'Documental', label:'Documental'},
                            {value:'Drama', label:'Drama'},
                            {value:'Fantasia', label:'Fantasia'},
                            {value:'Gore', label:'Gore'},
                            {value:'Histórico', label:'Histórico'},
                            {value:'Melodrama', label:'Melodrama'},
                            {value:'Misterio', label:'Misterio'},
                            {value:'Musical', label:'Musical'},
                            {value:'Policiaco', label:'Policiaco'},
                            {value:'Romance', label:'Romance'},
                            {value:'Suspenso', label:'Suspenso'},
                            {value:'Terror', label:'Terror'},
                            {value:'Thriller', label:'Thriller'},
                            {value:'Underground', label:'Underground'},
                        ]}/>

                    <p>¿Cuáles son tus película favoritas?</p>
                    <input {...favouriteMovieIn.bind} className='form-control'  required />

                    <p>¿Cuáles son tus actores o actrices favoritos?</p>
                    <input {...favouriteActorIn.bind} className='form-control'  required />

                    <p>Si fueras un animal ¿Qué animal serías?</p>
                    <input {...animalWouldBeIn.bind} className='form-control'  required />

                    <p>¿Cuánto tiempo tardas en llegar de tu casa al trabajo? </p>
                    <select {...dailyTravelHoursIn.bind} className='form-control'  required >
                        <option value="" disabled>Horas</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                    </select>

                    <select {...dailyTravelMinutesIn.bind} className='form-control'  required >
                        <option value="" disabled>Minutos</option>
                        {(()=>{
                            const arr = [];
                            for( let i=0; i<60; i++ )
                                arr.push( <option value={i} key={i}>{i}</option> );
                            return arr;
                        })()}
                    </select>

                    <p>¿Cuál es tu gasto diario aproximado de pasajes?</p>
                    <input {...dailyTravelCostIn.bind} className='form-control' required />

                    <Button bsStyle='success' className='send-but' type='submit' disabled={ loadingIds[loadingId] }>Enviar</Button>
                    <div className='clearfix' />
                </form>

            </div>
        </div>
    );

};

const mapStateToProps = ( {api:{me}, loadingIds} )=>({me, loadingIds});

export default connect(mapStateToProps)(ProfileCompletionModal);