import React, {useState, useMemo, useContext, useEffect} from 'react';
import moment from 'moment';
import {loungeOccupancySGroups} from '../../../utils/modelUtils/ee/loungesUtils';
import {flattenLoungeAccessMethods} from '../../../utils/modelUtils/davinci/loungeAccessMethodUtils';
import {ApiContext} from '../../../api/Api';
import useDateRangePickerState from "../../../hooks/useDateRangePickerState";
import {useSelector} from 'react-redux';
import {DateRangePicker} from "react-dates";
import LoungeCurrentOcupancy from './LoungeCurrentOcupancy';
import LoungeStatsTable from './LoungeStatsTable';
import AccessMethodStatsTable from './AccessMethodStatsTable';
import EntriesStatsTable from './EntriesStatsTable';
import CustomCheckbox from '../../../elements/CustomCheckbox/CustomCheckbox';
import FileBoxes from './FileBoxes';
import _ from 'lodash/fp';

const checkInsToStats = checkIns => ({
    entries: _.sumBy(c=>parseInt(c.adults), checkIns)||0,
    loungeAccessMethod: checkIns[0]?{
        id: checkIns[0].loungeAccessMethodId,
        name: checkIns[0].loungeAccessMethodName
    } :{},
    lounge: checkIns[0]?{
        id: checkIns[0].loungeId,
        name: checkIns[0].loungeName
    }:{},
    loungeParentAccessMethod: checkIns[0]?checkIns[0].loungeParentAccessMethodId:null
});

const statsGroupedBy = prop => _.flow(
    _.groupBy(prop),
    _.mapValues(checkInsToStats),
    _.values
);

const accessMethodStatsFrom = statsGroupedBy('loungeAccessMethodId');
const loungeStatsFrom = statsGroupedBy('loungeId');
const parentAccessMethodStatsFrom = statsGroupedBy(
    s=>s.loungeParentAccessMethodId===null?
        s.loungeAccessMethodId:
        s.loungeParentAccessMethodId
);

const removeWithoutLoungeMap = l => {
    if(!l.loungeMap){
        console.error(`Lounge ${l.name} with id ${l.id} is marked as lounge but has no loungeMap`);
        return false;
    }
    return true;
};

const initialDateFilters = {
    startDate: moment().date(1),
    endDate: moment()
};

const formatDate = d=>d.format('YYYY-MM-DD HH:mm:ss');
const dayStart = d=>d.clone().startOf('day');
const dayEnd = d=>d.clone().endOf('day');
const dateRangeFilters = ({startDate, endDate})=>({
    ...(startDate?{from: formatDate(dayStart(startDate))}:{}),
    ...(endDate?{to: formatDate(dayEnd(endDate))}:{})
});

const VisitsDashboard = () => {
    const { props:dateRangeProps, dateRange}=useDateRangePickerState(initialDateFilters);
    const [statsConfig, setStatsConfig] = useState(['turn', 'lounge', 'loungeAccessMethod']);
    const api = useContext(ApiContext);
    const allLounges = useSelector(
        ({api: {lounges=[]}})=>lounges);
    const lounges = useMemo(
        ()=>allLounges.filter(removeWithoutLoungeMap),
        [allLounges]
    );
    const unflattenedLoungeAccessMethods = useSelector(
        ({api: {loungeAccessMethods=[]}})=>loungeAccessMethods
    );
    const loungeAccessMethods = useMemo(()=>flattenLoungeAccessMethods(unflattenedLoungeAccessMethods), [unflattenedLoungeAccessMethods]);
    useEffect(()=>{
        api.lounges.get2({
            filters: {
                isLounge: true,
                sGroups: loungeOccupancySGroups
            }
        });
        api.loungeAccessMethods.get({
            filters: {
                pagination: false,
                'loungeParentAccessMethod[exists]': false
            }
        });
    }, [api]);

    const downloadTableURL = useMemo(
        ()=>api.checkIns.exportBillingUrl({
            params: {
                ...dateRangeFilters(dateRange),
            },
        }),
        [api, dateRange]
    );

    const downloadCanceledTableURL = useMemo(
        ()=>api.checkIns.exportBillingUrl({
            params: {
                ...dateRangeFilters(dateRange),
                canceled: true,
                xlsName: 'Cancelaciones.xls'
            },
        }),
        [api, dateRange]
    );

    const checkInStats = useSelector(({api: {checkInStats = []}})=>checkInStats);

    const loungeStats = loungeStatsFrom(checkInStats);

    const addFullAccessMethodName = v=>({
        ...v,
        loungeAccessMethod: {
            ...v.loungeAccessMethod,
            name: (_.find({id: v.loungeAccessMethod.id}, loungeAccessMethods)||{}).name
        }
    });

    const addParentName = v => ({
        ...v,
        loungeAccessMethod: {
            ...v.loungeAccessMethod,
            name: v.loungeParentAccessMethod?(_.find({id: v.loungeParentAccessMethod}, unflattenedLoungeAccessMethods)||{}).name:
            v.loungeAccessMethod.name
        }
    });

    const parentAccessMethodStats = _.map(addParentName, parentAccessMethodStatsFrom(checkInStats));
    const accessMethodsStats = _.map(addFullAccessMethodName, accessMethodStatsFrom(checkInStats));

    const remove = field => () => setStatsConfig(_.filter(x=>x!==field, statsConfig));
    const add = field => () => setStatsConfig([...statsConfig, field]);
    const isChecked = field=>_.includes(field, statsConfig);
    const checked = field=>isChecked(field)?
          remove(field):add(field);
    return (
        <div className='VisitsDashboard container-fluid main-container'>
            <h3 className='text-center'>Ocupación actual</h3>
            <div className='row'>
                {lounges.map((l, k)=><LoungeCurrentOcupancy
                                         key={l.id}
                                         lounge={l}
                                         nLounge={k}
                                         totalLounges={lounges.length}
                                     />)}
            </div>
            <div className='date-stats-container'>
                <div>
                    <h3 className='text-center'>Rango de fechas para estadísticas</h3>
                    <div className='date-picker-container'>
                        <DateRangePicker {...dateRangeProps}/>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <a href={downloadTableURL}>
                                <div className='excel-download'>
                                    <div className='text'>
                                        <h3>
                                            <i className='fa fa-table'/>
                                            &nbsp;&nbsp;
                                            Descargar check-ins en Excel
                                        </h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className='col-md-6'>
                            <a href={downloadCanceledTableURL}>
                                <div className='excel-download'>
                                    <div className='text'>
                                        <h3>
                                            <i className='fa fa-table'/>
                                            &nbsp;&nbsp;
                                            Descargar cancelaciones en Excel
                                        </h3>
                                    </div>
                                </div>
                            </a>
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
            {(loungeAccessMethods.length>0&&unflattenedLoungeAccessMethods.length>0)&&
                                <FileBoxes
                            unflattenedLoungeAccessMethods={unflattenedLoungeAccessMethods}
                            loungeAccessMethods={loungeAccessMethods}
                            dateRange={dateRange}
                                />
                        }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3>Entradas</h3>
                            <CustomCheckbox isChecked={isChecked('lounge')} label='Sala' onChange={checked('lounge')} inline/>
                            <CustomCheckbox isChecked={isChecked('loungeAccessMethod')} label='Método de acceso' onChange={checked('loungeAccessMethod')} inline/>
                            <CustomCheckbox isChecked={isChecked('turn')} label='Turno' onChange={checked('turn')} inline/>
                            <EntriesStatsTable statsConfig={statsConfig} lounges={lounges} loungeAccessMethods={loungeAccessMethods} dateRange={dateRange}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6'>
                            <LoungeStatsTable data={loungeStats}/>
                            <AccessMethodStatsTable title={'Entradas por método de acceso globales'} data={parentAccessMethodStats}/>
                        </div>
                        <div className='col-md-6'>
                            <AccessMethodStatsTable data={accessMethodsStats}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VisitsDashboard;
