import React, {Component} from 'react';
import ReactTableTranslations from "../../../utils/ReactTableTranslation";
import ReactTable from 'react-table';
import moment from "moment/moment";
import PropTypes from "prop-types";
import {employeeFullname} from "../../../api/Api";
import {connect} from "react-redux";
import _ from 'lodash';
import Button from '../../../elements/CustomButton/CustomButton';
import {Modal} from 'react-bootstrap';
import IncidenceJustificationDetail from "./IncidenceJustificationDetail";
import IncidenceSummaryModal from "./components/IncidenceSummaryModal";
import {getFulfillmentPoints} from "../../../utils/modelUtils/alma/incidenceUtils";
import EmployeeFulfillmentPointsGraph from "./components/EmployeeFulfillmentPointsGraph";
import withSecurityManager from "../../../utils/SecurityManager/withSecurityManager";


class AppliedIncidences extends Component {

    static contextTypes = {
        api:PropTypes.object,
        notifier:PropTypes.object
    };

    static defaultProps = {
        showNames: true,
        paginated: true,
        records: 100//If not paginated show this number of records

    };

    loadingId = "@Class.AppliedIncidences.get";
    deleteLoadingId = "@Class.AppliedIncidences.deleteIncidenceJustification";
    loadingJustifyByChange = "@Class.AppliedIncidences.justifyByChange";
    loadingCreatingJustificationId = "@Class.AppliedIncidences.creatingJustification";
    loadingEditId= "@Class.AppliedIncidences.edit";


    constructor(props, context){
        super(props);
        this.setTableStructure();
        this.appIncApi = context.api.appliedIncidences;
        this.configApi = context.api.configurations;

        this.loadRequestJustificationLimitHours();


        this.loadIncidences = _.debounce( this.loadIncidences.bind(this), 700, {'leading': true,'trailing': true} );
        this.handleIncidenceJustifyComments = this.handleIncidenceJustifyComments.bind(this);
        this.deleteIncidenceJustification = this.deleteIncidenceJustification.bind(this);
        this.changeJustificationIncidence = this.changeJustificationIncidence.bind(this);
        this.handleIncidenceJustifyFile = this.handleIncidenceJustifyFile.bind(this);
        this.handleIncidenceJustifyNew = this.handleIncidenceJustifyNew.bind(this);
        this.handleIncidenceJustify = this.handleIncidenceJustify.bind(this);
        this.onJustificationsChange = this.onJustificationsChange.bind(this);
        this.isExpired = this.isExpired.bind(this);

        this.state = {
            justifying: false,
            toJustify: [],
            toJustifyIndexes: [],
            incidenceJustifyFiles:[],
            fileInputs:[],
            showFulfillmentHistory: false
        };

        context.api.incidences.get();
    }

    loadRequestJustificationLimitHours(){
        this.configApi.getSingle({id: 'JUSTIFICATION_REQUEST_LIMIT_HOURS'}).then( config => {
            this.requestJustificationLimitHours = config.value;
        });
    }

    loadIncidences( tableState ){

        const sort = tableState.sorted.reduce( (acc, val)=>{acc[`order[${val.id}]`] = val.desc?"DESC":"ASC"; return acc;}, {} );
        const filter = tableState.filtered.reduce( (acc, val)=>{acc[val.id] = val.value; return acc;}, {} );

        filter.isFuture = this.props.isFuture;

        if(!tableState.sorted.length)
            filter['order[shiftDate]'] = 'DESC';
        filter['incidence.isPositive'] = 0;

        if( this.props.dates ) {
            filter["date[after]"] = this.props.dates.from.format("YYYY/MM/DD");
            filter["date[before]"] = this.props.dates.to.format("YYYY/MM/DD");
        }

        if( this.props.employee )
            filter.employee = this.props.employee.id;

        this.appIncApi.get(tableState.page , tableState.pageSize, this.loadingId, {...sort, ...filter});
        this.tableState = tableState;
    }


    handleIncidenceJustifyComments( e ){
        this.setState({incidenceJustifyComments: e.target.value});
    }

    handleIncidenceJustifyFile = (e, index)=>{
        const incidenceJustifyFiles = [ ...this.state.incidenceJustifyFiles ];
        incidenceJustifyFiles[index] = e.target.files[0];
        this.setState({incidenceJustifyFiles})
    };

    addFileInput = ()=>{
        const fileInputs = [...this.state.fileInputs];
        const index = fileInputs.length;
        fileInputs.push(<input type="file" onChange={(e)=>this.handleIncidenceJustifyFile(e, index)} key={index}/>);
        this.setState({fileInputs});
    };

    handleIncidenceJustifyNew( e ){
        this.setState({incidenceJustifyNew: e.target.value});
    }
    handleIncidenceJustify(){

        if( ! this.state.incidenceJustifyComments ) {
            this.context.notifier.error("Debes escribir algún comentario antes de enviar la solicitud.");
            return;
        }
        if( ! this.state.incidenceJustifyNew ) {
            this.context.notifier.error("Debes elegir la opción que mejor justifique tu incidencia.");
            return;
        }

        const files = this.state.incidenceJustifyFiles;
        const incidences = this.state.toJustify.map( (inc)=>inc.id );

        if( this.isMe ){
            this.context.api.incidenceJustifications.create(undefined, undefined, this.state.incidenceJustifyNew,incidences, this.state.incidenceJustifyComments, files, this.loadingCreatingJustificationId);
        }
        else{
            this.context.api.appliedIncidences.singleEdit( incidences[0], { incidence:this.state.incidenceJustifyNew, comment:this.state.incidenceJustifyComments, approvedBy:this.props.me.id  }, this.loadingEditId );
        }

        this.setState({justifying:false, toJustify:[], toJustifyIndexes:[], incidenceJustifyNew:"", incidenceJustifyComments:""});
    }

    changeJustificationIncidence( newAppInc ){
        this.context.api.incidenceJustifications.edit( newAppInc.id, newAppInc.responseComment, newAppInc.approved, this.loadingJustifyByChange );
        this.setState({showJustificationDetail:null});

    }

    onJustificationsChange(){
        this.loadIncidences(this.tableState);
    }

    deleteIncidenceJustification( incJus ){

        this.context.api.incidenceJustifications.delete(incJus.id, this.deleteLoadingId);
        this.setState({showJustificationDetail:null});

    }

    UNSAFE_componentWillReceiveProps(nextProps){

        //An incidence was justified so we reload incidences
        if( (this.props.loadingIds[this.loadingCreatingJustificationId] && !nextProps.loadingIds[this.loadingCreatingJustificationId] ) ||
            (this.props.loadingIds[this.deleteLoadingId] && !nextProps.loadingIds[this.deleteLoadingId] ) ||
            (this.props.loadingIds[this.loadingJustifyByChange] && !nextProps.loadingIds[this.loadingJustifyByChange] ) ||
            (this.props.loadingIds[this.loadingEditId] && !nextProps.loadingIds[this.loadingEditId] )
        ){

            this.onJustificationsChange();
            this.setState({toJustify:[]});
        }

    }

    isExpired( inc ){
        if ( !this.requestJustificationLimitHours ) return false;
        if ( this.requestJustificationLimitHours === '0' ) return false;

        return moment(inc.shiftDate).add( this.requestJustificationLimitHours, 'hours' ).isBefore( moment() );
    }


    setTableStructure() {

        let tableStructure = [];

        if( this.props.showNames )
            tableStructure.push({
                Header: 'Nombre',
                accessor: (appInc)=>employeeFullname(appInc.employee),
                id: 'employee.name',
                filterable: true
            });

        this.tableStructure = tableStructure.concat([{
            Header: 'Tipo',
            accessor: (appInc) => appInc.incidence.name,
            id: 'incidence.name',
            filterable: true
        },
            {
                Header:'Puntos',
                accessor: (appInc)=>getFulfillmentPoints(appInc.incidence),
                id:'fulfilmentPoints',
                sortable: false
            },
            {
                Header: 'Fecha',
                accessor: (appInc) => moment(appInc.shiftDate).format("DD/MM/YYYY"),
                id: 'shiftDate'
            },
            {
                Header: 'Estado',
                accessor: (appInc) => {
                    if(this.isExpired(appInc))
                        return "Expirada";
                    else if( !appInc.justification )
                        return "Sin justificar";
                    else if(appInc.justification.approved === true)
                        return "Aprobada";
                    else if(appInc.justification.approved === false)
                        return "Rechazada";
                    else
                        return "En espera..."
                },
                id: 'justification.approved'
            },
            {
                Header: 'Seleccionar',
                sortable: false,
                Cell: (row) =>{
                    const appInc = row.original;
                    const globalIndex = `${row.page}.${row.index}`;

                    if( this.isMe){
                        return <input
                            type="checkbox"

                            disabled={ (()=>{ return ((appInc.justification && appInc.justification.approved !== false ) || (this.state.toJustify.length && this.state.toJustify[0].incidence.code !== appInc.incidence.code))})() }
                            checked={ (()=>{ return this.state.toJustifyIndexes.indexOf(globalIndex)!==-1})()}

                            onChange={(val)=> {

                                if( val.target.checked ) {
                                    this.setState({toJustify: [appInc, ...this.state.toJustify], toJustifyIndexes:[globalIndex, ...this.state.toJustifyIndexes] });
                                    appInc.selected = true;
                                }
                                else {
                                    appInc.selected = false;
                                    let index =  this.state.toJustifyIndexes.indexOf(globalIndex);
                                    if (index !== -1) {
                                        let newArray = [...this.state.toJustify];
                                        let newArrayI = [...this.state.toJustifyIndexes];
                                        newArray.splice(index, 1);
                                        newArrayI.splice(index, 1);
                                        this.setState({toJustify:newArray, toJustifyIndexes:newArrayI});
                                    }
                                }

                            }}
                        />}

                    else{
                        // if(  this.props.securityManager.alma.canApproveEmployeeJustification(appInc.employee) ){
                        //     return <span
                        //         className='link'
                        //         onClick={(e)=>{
                        //             if( !appInc.justification) {
                        //                 e.preventDefault();
                        //
                        //                 this.setState({
                        //                     justifying:true,
                        //                     toJustify:[row.original]
                        //                 });
                        //
                        //             }
                        //         }}>
                        //     {appInc.justification? "Revisar":"Justificar"}
                        // </span>
                        // }
                        // else{
                            return "Sin selección";
                        //}
                    }
                },
                id: 'selected'
            }
        ]);

    }

    showSummary = ()=>{
        this.setState({showSummary:true});
    };

    toggleFulfillmentHistory=()=>{
        this.setState({showFulfillmentHistory:!this.state.showFulfillmentHistory})
    };

    render() {

        this.isMe = this.props.employee.id === this.props.me.employee.id;

        const { totalItems, itemsPerPage } = this.props.listMeta;
        const plural = totalItems !== 1?"s":"";
        const justification = this.state.showJustificationDetail;
        const canApprove = Boolean( justification && this.props.securityManager.alma.canApproveEmployeeJustification( justification.requestedBy ) );

        return (
            <div className="container-fluid main-container">
                {this.state.showFulfillmentHistory&&
                <EmployeeFulfillmentPointsGraph employee={this.props.employee}/>}
                <Button bsStyle='primary'  onClick={this.toggleFulfillmentHistory}>{this.state.showFulfillmentHistory?'Ocultar':'Ver'} historial de cumplimiento</Button>
                <Button bsStyle='primary' className='pull-right'  onClick={this.showSummary}>Ver resumen</Button>
                <br/><br/>

                {this.isMe && this.state.toJustify.length?
                    <Button onClick={()=>this.setState({justifying:true})} className="col-xs-12">Justificar</Button>
                    :null
                }

                <ReactTable
                    data={this.props.list}
                    columns = {this.tableStructure}
                    defaultPageSize= {this.props.paginated?10:this.props.records}
                    pages= { Math.ceil( totalItems / itemsPerPage ) }
                    className="col-xs-12 -striped -highlight"
                    loading={!!this.props.loadingIds[this.loadingId]}
                    manual
                    showPagination={this.props.paginated}
                    onFetchData={this.loadIncidences}
                    minRows={3}
                    getTdProps={(state, rowInfo)=>(
                        {onClick:(e)=>{
                                if(rowInfo && rowInfo.original.justification) {
                                    e.preventDefault();
                                    rowInfo.original.justification.appliedIncidences = [rowInfo.original];
                                    this.setState({showJustificationDetail: rowInfo.original.justification});
                                }
                            }
                        }
                    )}
                    {...ReactTableTranslations}
                />
                <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>

                {this.state.justifying && this.state.toJustify.length ?
                    <Modal show={true} onHide={() => this.setState({justifying: false,fileInputs:[]})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Justificar { this.state.toJustify[0].incidence.name }{this.state.toJustify.length > 1? "s":""} </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <p>{ this.state.toJustify[0].incidence.name }{this.state.toJustify.length > 1? "s":""} del:<br/>
                                {this.state.toJustify.map((appInc)=> <span key={appInc.id}>{moment(appInc.shiftDate).format("DD/MM/YYYY")}<br/></span> )}
                            </p>
                            <br/>
                            <p>Selecciona una opción que justifique la incidencia</p>
                            <select  onChange={this.handleIncidenceJustifyNew} value={this.state.handleIncidenceJustifyNew}>
                                <option>Selecciona una opción</option>
                                {this.props.incidences.map((inc)=> {
                                    if( this.state.toJustify[0].incidence.code === "F" && inc.justifyAbsence )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "R" && inc.justifyRetardment )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "RIN" && inc.justifyIncompleteChecks )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "CHOT" && inc.justifyEarlyCheckout )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "MACO" && inc.justifyMoreMealTime )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "MECO" && inc.justifyLessMealTime )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "MHT" && inc.justifyLessWorkHours )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "UNREG" && inc.justifyOneCheck )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "MHT5" && inc.justifyLess5WorkHours )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    return null;
                                })}
                            </select>
                            <br/><br/>
                            <p>Escribe porqué crees que se debería justificar esta incidencia.</p>
                            <textarea className={"form-control"} value={this.state.incidenceJustifyComments} onChange={this.handleIncidenceJustifyComments} />
                            <br/>
                            <p>¿Tienes algún archivo que lo corrobore?</p>
                            {this.state.fileInputs}
                            <br />
                            <Button bsStyle='primary' onClick={this.addFileInput}>Agregar archivo</Button>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.setState({justifying: false,fileInputs:[]})}>Cancelar</Button>{" "}
                            <Button bsStyle="primary" onClick={this.handleIncidenceJustify}>Enviar</Button>
                        </Modal.Footer>
                    </Modal>
                    :null}

                {justification?
                    <div>
                        <IncidenceJustificationDetail
                            incidenceJustification={justification}
                            urlCreator={this.context.api.createFileUrl}
                            onHide={()=>this.setState({showJustificationDetail:null})}
                            onChange={this.changeJustificationIncidence}
                            canApprove={canApprove}
                            onDelete = {this.props.employee && this.isMe ? this.deleteIncidenceJustification:undefined}
                        />
                    </div>:null}

                {this.state.showSummary &&
                <IncidenceSummaryModal
                onHide={()=>this.setState({showSummary:false})}
                employee={this.props.employee}
                />}

            </div>
        );
    }
}

function mapStateToProps( {api:{
    me,
    appliedIncidences=[],
    appliedIncidencesMeta={totalItems:0,itemsPerPage:0},
    incidences=[],
}, loadingIds } ) {

    return {
        me,
        list:appliedIncidences,
        listMeta:appliedIncidencesMeta,
        incidences,
        loadingIds }
}


export default connect(mapStateToProps )(withSecurityManager(AppliedIncidences));
