import React, {useContext, useEffect, useMemo} from 'react';
import {Col, Grid, Row, Table} from "react-bootstrap";
import Card from '../../../components/Card/Card';
import {ApiContext} from "../../../api/Api";
import {useSelector} from "react-redux";
import _ from "lodash";
import moneyFormatter from "../../../utils/moneyFormatter";
import moment from 'moment';


const EmployeeWorkToolsExpirationTable = () => {

    const api = useContext(ApiContext);

    const employeeWorkToolsMonthlyReport = useSelector((state) => state.api.employeeWorkToolsMonthlyReport) || [];

    useEffect(() => {
        api.employeeWorkTools.getMonthlyReport({});
    }, [api]);

    const groupedEmployeeWorkToolsReport=useMemo(()=>{
        const groupedReports = _.groupBy(employeeWorkToolsMonthlyReport, (item) => `${item.year}--${item.month}`);
        return _.values(groupedReports);
    },[employeeWorkToolsMonthlyReport]);

    const getTotalMonthExpense = (monthReport)=>{
        const totalExpense  = _.sumBy(monthReport, function (ewtReport) {
            return parseFloat(ewtReport.expense)||0;
        });
        return totalExpense;
    };
    return (
        <div className="main-content EmployeeStatistics">
            <Grid fluid>
                <Row>
                    {!groupedEmployeeWorkToolsReport || !groupedEmployeeWorkToolsReport.length?
                        <h3 className='text-center text-muted'>No hay información disponible</h3>
                    :null}
                    {groupedEmployeeWorkToolsReport.map((monthReport, key) => (
                        <Col lg={12} md={12} sm={12} xs={12} key={key}>
                            <Card
                                title={_.capitalize(moment.months(monthReport[0].month-1))+' del '+ monthReport[0].year  }
                                content={
                                    <div>
                                        <Table >
                                            <thead>
                                            <tr>
                                                <th>Herramienta</th>
                                                <th>Cantidad de herramientas que vencen</th>
                                                <th>Costo unitario</th>
                                                <th>Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {monthReport.map((employeeWorkToolReport, key) => (
                                                <tr key={key}>
                                                    <td>{employeeWorkToolReport.workToolName} </td>
                                                    <td>{employeeWorkToolReport.totalCount}</td>
                                                    <td>{moneyFormatter(employeeWorkToolReport.workToolProviderPrice||0, 1)}</td>
                                                    <td>{moneyFormatter(employeeWorkToolReport.expense||0,1)}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan={3} />
                                                <td >Total: {moneyFormatter(getTotalMonthExpense(monthReport), 1)} </td>
                                            </tr>
                                            </tbody>
                                        </Table>

                                    </div>
                                }
                            />
                        </Col>
                    ))}

                </Row>
            </Grid>
        </div>
    );

};

export default EmployeeWorkToolsExpirationTable;
