import React, {Component} from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTableTranslations from '../../../utils/ReactTableTranslation';
import {connect} from 'react-redux';
import {Col, Row, Modal, SplitButton, MenuItem} from 'react-bootstrap';
import Button from "../../../elements/CustomButton/CustomButton";
import UploadTimeRegister from './UploadTimeRegister';
import PayrollFileModal from './components/PayrollFileModal';
import IncidenceGenerationModal from './components/IncidenceGenerationModal';
import enumerateDates from '../../../utils/enumerateDates';


class PayrollFileHistory extends Component{

    static contextTypes = { api:PropTypes.object };

    loadingId = "@Class.PayrollFileHistory.get";
    genLoadingId = "@Class.PayrollFileHistory.getGen";
    generatingLoadingId = "@Class.PayrollFileHistory.generatingIncidences";

    constructor( props, context ){
        super(props);
        this.state = {
            generationDate: this.getLatestDateForGeneration()
        };

        this.incidenceGenerationDelete = this.incidenceGenerationDelete.bind(this);
        this.assistanceReportDelete = this.assistanceReportDelete.bind(this);
        this.generateIncidences = this.generateIncidences.bind(this);
        this.loadGenData = this.loadGenData.bind(this);
        this.loadData = this.loadData.bind(this);

        this.dataApi = context.api.assistanceReportUploads;
        this.genApi = context.api.incidenceGenerations;
    }

    loadData( tableState ){

        const sort = tableState.sorted.reduce( (acc, val)=>{acc[`order[${val.id}]`] = val.desc?"DESC":"ASC"; return acc;}, {} );
        const filter = tableState.filtered.reduce( (acc, val)=>{acc[val.id] = val.value; return acc;}, {} );

        this.dataApi.get(tableState.page , tableState.pageSize, this.loadingId, {...sort, ...filter});
    }

    loadGenData( tableState ){

        const sort = tableState.sorted.reduce( (acc, val)=>{acc[`order[${val.id}]`] = val.desc?"DESC":"ASC"; return acc;}, {} );
        const filter = tableState.filtered.reduce( (acc, val)=>{acc[val.id] = val.value; return acc;}, {} );

        this.genApi.get(tableState.page , tableState.pageSize, this.genLoadingId, {...sort, ...filter});
    }

    generateIncidences(){
        this.genApi.create(this.state.generationDate.format('YYYY-MM-DD'), this.generatingLoadingId)
    }

    getAvailableDatesForGeneration(){

        const date = moment().date();

        if( date <= 15 ){
            const periodStart = moment().subtract(1,'month').date(26);
            const periodEnd = date > 10? moment().date(10) : moment().subtract(1,'day');
            return enumerateDates( periodStart, periodEnd )
        }

        const periodStart = moment().date(11);
        const periodEnd = date > 25? moment().date(25) : moment().subtract(1,'day');
        return enumerateDates( periodStart, periodEnd )
    }

    getLatestDateForGeneration(){

        const date = moment().date();

        if( date <= 15 ) {
            if( date > 10 )
                return moment().date(10);
            else
                return moment().subtract(1,'day');
        }
        else{
            if( date > 25 )
                return moment().date(25);
            else
                return moment().subtract(1,'day');
        }
    }

    filesTableStructure = [{
        Header: 'Reloj',
        accessor: 'clock.name'
    }, {
        Header: 'Archivo',
        accessor: 'file.originalName'
    }, {
        Header: 'Periodo',
        accessor: (assRep)=>
            <div>
                <span> {moment(assRep.fromDate).format("DD/MM/YYYY")} </span><br/>
                <span> {moment(assRep.toDate).format("DD/MM/YYYY")} </span>
            </div>,
        id: 'fromDate'
    }];

    generationsTableStructure = [{

        Header: 'Fecha de generación',
        accessor: (gen)=> moment(gen.createdDate).format("DD/MM/YYYY"),
        id: 'createdDate'
    }, {
        Header: 'Periodo',
        accessor: (gen)=>
            <div>
                <span> {moment(gen.fromDate).format("DD/MM/YYYY")} </span><br/>
                <span> {moment(gen.toDate).format("DD/MM/YYYY")} </span>
            </div>,
        id: 'fromDate'
    }];

    assistanceReportDelete = ()=>{

        const assRep = this.state.showFileDetail;
        this.setState({showFileDetail:undefined});

        this.context.api.assistanceReportUploads.delete(assRep.id);
    };

    incidenceGenerationDelete= ()=>{

        const incGen = this.state.showIncGenDetail;
        this.setState({showIncGenDetail:undefined});

        this.context.api.incidenceGenerations.delete(incGen.id);
    };

    render(){

        const { totalItems, itemsPerPage } = this.props.listMeta;
        const { totalItems:genTotalItems, itemsPerPage:genItemsPerPage } = this.props.genListMeta;
        const plural = totalItems===1?"s":"";
        const genPlural = genTotalItems===1?"s":"";

        return(
            <div className="container-fluid main-container PayrollFileHistory">
                <Row>
                    <Col sm={6}>

                        <h4>Archivos del reloj checador</h4>
                        <Button bsStyle="primary" onClick={()=>this.setState({uploadingFile:true})}>
                            Subir archivo
                        </Button>

                        <ReactTable
                            data={this.props.list}
                            columns = {this.filesTableStructure}
                            defaultPageSize= {10}
                            pages= { Math.ceil( totalItems / itemsPerPage ) }
                            className="-striped -highlight"
                            loading={!!this.props.loadingIds[this.loadingId]}
                            manual
                            sortable={false}
                            onFetchData={this.loadData}
                            minRows={3}
                            getTdProps={ (state, rowInfo)=>({onClick:()=>this.setState({showFileDetail:rowInfo.original})}) }
                            {...ReactTableTranslations}
                        />
                        <p className="total-count">{totalItems} registro{plural} encontrado{plural}</p>
                    </Col>

                    <Col sm={6}>

                        <h4>Generación de incidencias</h4>
                        <SplitButton
                            bsStyle='danger'
                            title={ 'Generar incidencias al ' + this.state.generationDate.format('DD/MM/YYYY') }
                            id={`split-button-basic`}
                            onClick={this.generateIncidences}
                            onSelect={(e)=>this.setState( {generationDate:moment(e)} )}
                        >
                            {this.getAvailableDatesForGeneration().map((date, i)=>
                                <MenuItem eventKey={date.format('YYYY-MM-DD')} key={i}>{date.format('DD/MM/YYYY')}</MenuItem>
                            )}

                        </SplitButton>

                        <ReactTable
                            data={this.props.genList}
                            columns={this.generationsTableStructure}
                            defaultPageSize= {10}
                            pages= { Math.ceil( genTotalItems / genItemsPerPage ) }
                            className="-striped -highlight"
                            loading={this.props.loadingIds[this.genLoadingId]}
                            manual
                            sortable={false}
                            onFetchData={this.loadGenData}
                            minRows={3}
                            getTdProps={ (state, rowInfo)=>({onClick:()=>this.setState({showIncGenDetail:rowInfo.original})}) }
                            {...ReactTableTranslations}
                        />
                        <p className="total-count">{genTotalItems} registro{genPlural} encontrado{genPlural}</p>
                    </Col>
                </Row>

                {this.state.uploadingFile?
                    <Modal
                        show={true}
                        onHide={()=>this.setState({uploadingFile:false})}
                        bsSize="lg"
                    >
                        <Modal.Header closeButton >
                            <Modal.Title>
                                Nuevos registros
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <UploadTimeRegister
                                fullWidth={true}
                            />
                        </Modal.Body>
                    </Modal>:null
                }

                {this.state.showFileDetail?
                    <PayrollFileModal
                        assistanceReport={this.state.showFileDetail}
                        onHide={()=>this.setState({showFileDetail:undefined})}
                        urlCreator = {this.context.api.createFileUrl}
                        onAssistanceReportDelete = {this.assistanceReportDelete}
                    />:null
                }

                {this.state.showIncGenDetail?
                    <IncidenceGenerationModal
                        incidenceGeneration={this.state.showIncGenDetail}
                        onHide={()=>this.setState({showIncGenDetail:undefined})}
                        onIncidenceGenerationDelete={this.incidenceGenerationDelete}
                    />:null
                }

            </div>
        )}
}


function mapStateToProps( {api:{
    assistanceReportUploads=[],
    assistanceReportUploadsMeta={totalItems:0,itemsPerPage:0},
    incidenceGenerations=[],
    incidenceGenerationsMeta={totalItems:0,itemsPerPage:0},
}, loadingIds } ) {

    return {
        list:assistanceReportUploads, listMeta:assistanceReportUploadsMeta,
        genList: incidenceGenerations, genListMeta: incidenceGenerationsMeta,
        loadingIds }
}

export default connect(mapStateToProps )(PayrollFileHistory);