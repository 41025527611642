import PurchaseRequisitionProcess from "../views/compras/requisitions/PurchaseRequisitionProcess";
import PurchaseRequisitionForm from "../views/compras/requisitions/PurchaseRequisitionForm";
import PurchaseRequisitionList from "../views/compras/requisitions/PurchaseRequisitionList";
import PurchaseOrderDetail from "../views/compras/orders/PurchaseOrderDetail";
import PurchaseOrderList from "../views/compras/orders/PurchaseOrderList";
import ProviderList from "../views/compras/providers/ProviderList";
import ProductBrandProviderList from "../views/compras/productBrandProviders/ProductBrandProviderList";
import ProductList from "../views/almazen/products/ProductList";
import ServiceList from "../views/almazen/services/ServiceList";
import Compras from "../views/compras/Compras";
import PurchaseApprovalMatrixConfigurator from "../views/compras/admin/PurchaseApprovalMatrixConfigurator";
import JazzDashboard from "../views/compras/admin/JazzDashboard";
import ProviderPriceTable from "../views/compras/providers/ProviderPriceList";
import JazzConfiguration from "../views/compras/admin/JazzConfiguration";
import ProductReportView from "../views/almazen/products/ProductReportView";
import JazzAreaCataloguesList from "../views/compras/JazzAreaCatalogues/JazzAreaCataloguesList";
import JazzReports from "../views/compras/JazzReports/JazzReports";
import JazzAreaHistory from "../views/compras/JazzAreaCatalogues/components/JazzAreaHistory";
import { BudgetRequestList } from "../views/compras/BudgetRequestList/BudgetRequestList";
import { BudgetRequestDetail } from "../views/compras/BudgetRequestList/BudgetRequestDetail";
import Help from "../views/compras/Help/Help";

export const paths={
    productReport:"/compras/products/report/:id",
    singlePurchaseOrder: "/compras/orders/:id",
    jazzHelp: "/compras/help",
};

export default (securityManager)=>{

    const routes=[];
    routes.push({ path: "/compras/compras", name: "Compras", icon: "pe-7s-note2", component: Compras, hidden: true });

    if(!securityManager) return routes;

    if(securityManager.jazz.canSeeJazzDashboard()){
        routes.push({ path: "/compras/dashboard", name: "Dashboard de compras", component: JazzDashboard, icon: 'fa fa-tachometer' });
	routes.push({ path: "/compras/reports", name: "Reportes", component: JazzReports, icon: 'fa fa-area-chart' });
    }

    if(securityManager.jazz.canEditPurchaseRequisitions())
        routes.push({ path: "/compras/requisitions/:id/edit", name: "Solicitud de compra", component: PurchaseRequisitionForm, hidden: true });
    if(securityManager.jazz.canShowPurchaseRequisitions())
        routes.push({ path: "/compras/requisitions/:id", name: "Solicitud de compra", component: PurchaseRequisitionProcess, hidden: true });
    if(securityManager.jazz.canListPurchaseRequisitions())
        routes.push({ path: "/compras/requisitions", name: "Solicitudes de compra", icon: "pe-7s-note2", component: PurchaseRequisitionList });
        routes.push({ path: "/compras/area_catalogues", name: "Catálogo de áreas", icon: "fa fa-pie-chart", component: JazzAreaCataloguesList });
        routes.push({ path: "/compras/area_catalogues/history/:id", name: "Historial de Presupuesto", component: JazzAreaHistory, hidden: true });

    if(securityManager.jazz.canShowPurchaseOrders())
        routes.push({ path: paths.singlePurchaseOrder, name: "Orden de compra", component: PurchaseOrderDetail, hidden: true });

    if(securityManager.jazz.canListPurchaseOrders())
        routes.push({ path: "/compras/orders", name: "Órdenes de compra", icon: "fa fa-shopping-cart", component: PurchaseOrderList });

    if(securityManager.jazz.canListProviders())
        routes.push({ path: "/compras/providers", name: "Proveedores", icon: "fa fa-truck", component: ProviderList });

    if(securityManager.jazz.canListProductBrandProviders())
        routes.push({ path: "/compras/providers/list/:id", name: "Lista de precios", component: ProviderPriceTable, hidden: true });

    if(securityManager.jazz.canListProviderProductBrands())
        routes.push({ path: "/compras/product-brand-providers", name: "Productos de proveedores", icon: "fa fa-shopping-basket", component: ProductBrandProviderList });

    if(securityManager.jazz.canListProducts()) {
        routes.push({path: paths.productReport, name: "Reporte de producto", hidden: true, component: ProductReportView});
        routes.push({path: "/compras/products", name: "Productos", icon: "fa fa-cubes", component: ProductList});
        routes.push({path: "/compras/services", name: "Servicios", icon: "fa fa-suitcase", component: ServiceList});
    }
    if(securityManager.jazz.canCreatePurchaseRequisitions())
        routes.push({ path: "/compras/new-requisition", name: "Nueva requisición", component: PurchaseRequisitionForm, hidden: true });

    if(securityManager.jazz.canEditPurchaseApprovalMatrix())
        routes.push({ path: "/compras/approval-matrix", icon: "fa fa-chain", smallName: "Aprobaciones", name:"Matriz de aprobaciones", component: PurchaseApprovalMatrixConfigurator });

    if(securityManager.jazz.canShowBudgetRequest() || securityManager.jazz.canApproveBudgetRequest())
        routes.push({ path: '/compras/listado-solicitudes-automaticas-presupuesto', icon: "fa fa-money", smallName: "Aprobación de presupuestos", name: "Listado de presupuesto", component: BudgetRequestList, hidden: false });

    if(securityManager.jazz.canShowBudgetRequest() || securityManager.jazz.canApproveBudgetRequest())
        routes.push({ path: '/compras/solicitud-automatica-presupuesto/:id', icon: "fa fa-money", smallName: "Solicitud de presupuesto", name: "Solicitud de presupuesto", component: BudgetRequestDetail, hidden: true });


    if(securityManager.jazz.canEditConfiguration())
        routes.push({path: "/compras/configuration", icon: "fa fa-cog", smallName: "Configuración", name:"Configuración de Jazz", component: JazzConfiguration});

    routes.push({ path: paths.jazzHelp, name: "Ayuda", icon: "pe-7s-info", component: Help });

    return routes;
};
