import React from 'react';
import useTideTable from "../../../../hooks/table/useTideTable";
import {activeVisitsSGroups, activeVisitsTableColumns} from "../../../../utils/modelUtils/davinci/visitUtils";
import ReactTable from "react-table";

const reqFilters = {
    sGroups: activeVisitsSGroups,
    'order[createdDate]':'DESC',
    'activePersons[gt]':0
};

const ActiveVisits=()=>{

    const visitsTable=useTideTable({
        entity:'visits',
        columns: activeVisitsTableColumns,
        requestFilters: reqFilters
    });

    return (
        <div className='ActiveVisits'>
            <ReactTable
                {...visitsTable.tableProps}
            />
        </div>
    );
};

export default ActiveVisits;
