import React, {useContext, useEffect, useState, useMemo} from 'react';
import './OperationalExcellence.scss';
import DateRangeFilter, {useDateRangeFilter} from "../../../DavinciReports/Filters/DateRangeFilter";
import CustomCheckbox from '../../../../../elements/CustomCheckbox/CustomCheckbox';
import {useSelector} from 'react-redux';
import {getGcareQuestionType} from '../../utils';
import _ from 'lodash/fp';
import {ApiContext} from '../../../../../api/Api';
import TimeGroupedGcareStat from '../../TimeGroupedGcareStat';

const gcareTypes = [
    ['Alimentos', 'food'],
    ['Bebidas', 'drinks'],
    ['Limpieza', 'cleanliness'],
    ['Recepción', 'reception'],
    ['Staff', 'staff'],
];

const OperationalExcellence = ({defaultDateRange}) => {
    const [shift, setShift] = useState(null);
    const [lounge, setLounge] = useState(null);
    const [airline, setAirline] = useState(null);
    const [loungeAccessMethod, setLoungeAccessMethod] = useState(null);
    const [gcareType, setGcareType] = useState(gcareTypes[0][1]);

    const api = useContext(ApiContext);

    const questionnaireReport = useSelector(({api})=>api.questionnaireReport||{});
    const shiftQuestion = getGcareQuestionType('shift', questionnaireReport);
    const loungeQuestion = getGcareQuestionType('lounge', questionnaireReport);
    const loungeAccessMethodQuestion = getGcareQuestionType('loungeAccessMethod', questionnaireReport);
    const airlineQuestion = getGcareQuestionType('airline', questionnaireReport);
    const shifts = _.map('answer', shiftQuestion?shiftQuestion.stats:[]);
    const lounges = _.map('answer', loungeQuestion?loungeQuestion.stats:[]);
    const loungeAccessMethods = _.map('answer', loungeAccessMethodQuestion?loungeAccessMethodQuestion.stats:[]);
    const airlines = _.map('answer', airlineQuestion?airlineQuestion.stats:[]);

    const dateConfig = useMemo(()=>({initial: defaultDateRange}), [defaultDateRange]);
    const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);

    useEffect(()=>{
        api.answers.gcareEvolutionReport({ params: {
            ...dateRequest,
            ...(shift?{shifts: [shift]}:{}),
            ...(lounge?{lounges: [lounge]}:{}),
            ...(loungeAccessMethod?{loungeAccessMethods: [loungeAccessMethod]}:{}),
            ...(airline?{airlines: [airline]}:{}),
        }});
    }, [api, dateRequest, shift, lounge, loungeAccessMethod, airline]);
    const report = useSelector(({api})=>api.gcareEvolutionReport||[]);

    useEffect(()=>{
        api.questionnaireReport.get({
            isGcare: true
        });
    }, [api]);

    const title = (_.find(f=>f[1]===gcareType, gcareTypes)||[''])[0];
    return (
        <div className="HappinessReport">
            <DateRangeFilter {...dateFilterProps}/>
            <div className='row'>
                <div className='col-md-3'>
                    <label>Salas: </label>
                </div>
                <div className='col-md-8 pull-right'>
                    {lounges.map(v=>
                                <CustomCheckbox key={v} label={v} inline isChecked={v===lounge} onChange={()=>lounge===v?setLounge(null):setLounge(v)}/>)}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-3'>
                    <label>Turno: </label>
                </div>
                <div className='col-md-8 pull-right'>
                    {shifts.map(v=>
                                <CustomCheckbox key={v} label={v} inline isChecked={v===shift} onChange={()=>shift===v?setShift(null):setShift(v)}/>)}
                </div>
            </div>
            <label>Métodos de acceso:</label>
            <select onChange={e=>e.target.value==='Todos'?setLoungeAccessMethod(null):setLoungeAccessMethod(e.target.value)} className='form-control form-selectors'>
                {loungeAccessMethods.map(lam=><option value={lam} key={lam}>{lam}</option>)}
                <option>Todos</option>
            </select>

            <label>Aerolínea:</label>
            <select onChange={e=>e.target.value==='Todas'?setAirline(null):setAirline(e.target.value)} className='form-control form-selectors'>
                {airlines.map(a=><option key={a} value={a}>{a}</option>)}
                <option>Todas</option>
            </select>
            <label>Rubro:</label>
            <select value={gcareType} onChange={e=>setGcareType(e.target.value)} className='form-control form-selectors'>
                {gcareTypes.map(([name, key])=>
                                <option value={key} key={key}>{name}</option>)}
            </select>

            <TimeGroupedGcareStat title={title} gcareType={gcareType} report={report}/>

        </div>
    );
};
export default OperationalExcellence;
