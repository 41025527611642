import React, {useCallback, useContext} from 'react';
import './style/_LoungeMapEditorTools.scss';
import StageTools from "./ToolBoxes/StageTools";
import ElementsPicker from "./ToolBoxes/ElementsPicker";
import ElementOrder from "./ToolBoxes/ElementOrder";
import {loungeMapEditorSGroups, prepareLoungeMapForServer} from "tgle-core/utils/loungeMapUtils";
import {TideApiContext} from "../../../api/tideApiConfig";
import ElementToolbox from "./ToolBoxes/elementToolboxes/ElementToolbox";
import _ from 'lodash';

const LoungeMapEditorTools = ({
                                  loungeMap,
                                  onLoungeMapChange,
                                  viewPort,
                                  onViewPortChange,
                                  activeTool,
                                  onActiveToolChange,
                                  selectedItem,
                                  onItemSelect,
})=>{

    const api = useContext(TideApiContext);
    // ------------  Save data ----------
    const saveData = useCallback(()=>{
        if(!loungeMap)
            return;
        const prepared = prepareLoungeMapForServer(loungeMap);
        api.loungeMaps.update({id: loungeMap.id, params:{...prepared, sGroups: loungeMapEditorSGroups} });

    },[api, loungeMap]);

    // ---- Handle single element edition ----
    const handleSelectedItemChange = useCallback((loungeMapItem)=>{
        const itemIndex= _.findIndex(loungeMap.loungeMapItems, item=>item.id === loungeMapItem.id);
        if(itemIndex === -1)
            return;

        const loungeMapItems = [...loungeMap.loungeMapItems];
        loungeMapItems[itemIndex] = loungeMapItem;

        onLoungeMapChange({...loungeMap, loungeMapItems});
    },[loungeMap, onLoungeMapChange]);

    const handleSelectedItemDelete = useCallback((loungeMapItem)=>{
        const itemIndex= _.findIndex(loungeMap.loungeMapItems, item=>item.id === loungeMapItem.id);
        if(itemIndex === -1)
            return;

        const loungeMapItems = [...loungeMap.loungeMapItems];
        loungeMapItems.splice(itemIndex, 1);

        onLoungeMapChange({...loungeMap, loungeMapItems});
    },[loungeMap, onLoungeMapChange]);

    return (
        <div className='LoungeMapEditorTools'>
            <h3 className='title'>Herramientas</h3>
            <button className='toolbox-button full-width save-btn' disabled={!loungeMap} onClick={saveData}>Guardar cambios</button>

            {selectedItem && <ElementToolbox
                loungeMapItem={selectedItem}
                onChange={handleSelectedItemChange}
                onDelete={handleSelectedItemDelete}
            />}
            <StageTools
                loungeMap={loungeMap}
                onLoungeMapChange={onLoungeMapChange}
                viewPort={viewPort}
                onViewPortChange={onViewPortChange}
            />
            <ElementsPicker
                activeTool={activeTool}
                onActiveToolChange={onActiveToolChange}
            />
            <ElementOrder
                loungeMap={loungeMap}
                onLoungeMapChange={onLoungeMapChange}
                selectedItem={selectedItem}
                onItemSelect={onItemSelect}
            />
        </div>
    );
};

export default LoungeMapEditorTools;
