import React, {Component} from 'react';
import {ControlLabel, Modal, Table } from 'react-bootstrap';
import moment from 'moment';
import Button from "../../../elements/CustomButton/CustomButton";
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';


class AddACReservationModal extends Component{

    static contextTypes = {
        api: PropTypes.object,
        notifier: PropTypes.object
    };

    state = { form:{
            name:'',
            lastNames:'',
            personsNumber:1,
            terminal: 'T1',
            reservationDate: moment().add(5, 'hours'),
            pickupDate: moment().minute(0).hour(9),
            departureDate: moment().minute(0).hour(10),
        }};

    getFormValue = ( name )=>{
        if( this.state.form[name] )
            return this.state.form[name];
        return '';
    };

    changeFormValue = ( e )=>{
        this.setState( { form:{...this.state.form, [e.target.name]:e.target.value} } );
    };

    createReservation = ()=>{

        const reservation = { ...this.state.form };
        reservation.personsNumber = Number(reservation.personsNumber);

        reservation.name = reservation.name.trim();
        reservation.lastNames = reservation.lastNames.trim();

        if( !reservation.name || !reservation.lastNames || !reservation.accessMethod || !reservation.source )
            return this.context.notifier.error('No has llenado todos los campos');

        this.setState( {creating: true} );

        this.context.api.reservations.create( reservation )
            .then(()=>{
                this.props.onHide();
            })
            .catch( ()=>{
                this.setState({creating:false});
            } )
    };

    render(){

        return (
            <Modal
                show={true}
                onHide={this.props.onHide}
            >
                <Modal.Header>
                    <Modal.Title>Detalle de reservación</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <form autoComplete='off'>
                    <Table>
                        <tbody>
                        <tr>
                            <td>Nombre</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='name'
                                    value={this.getFormValue('name')}
                                    onChange={this.changeFormValue}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Apellidos</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='lastNames'
                                    value={this.getFormValue('lastNames')}
                                    onChange={this.changeFormValue}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='email'
                                    value={this.getFormValue('email')}
                                    onChange={this.changeFormValue}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Teléfono</td>
                            <td>
                                <input
                                    className='form-control'
                                    name='phone'
                                    value={this.getFormValue('phone')}
                                    onChange={this.changeFormValue}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Fecha</td>
                            <td>
                                <Datetime
                                    value={this.getFormValue('reservationDate')}
                                    onChange={(date)=>this.changeFormValue({target:{name:'reservationDate', value:date}})}
                                    isValidDate={(current)=> moment().isSameOrBefore(current, 'day') }
                                    timeFormat={false}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Hora de recogida</td>
                            <td>
                                <Datetime
                                    value={this.getFormValue('pickupDate')}
                                    onChange={(date)=>this.changeFormValue({target:{name:'pickupDate', value:date}})}
                                    dateFormat={false}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Hora de partida del helicóptero</td>
                            <td>
                                <Datetime
                                    value={this.getFormValue('departureDate')}
                                    onChange={(date)=>this.changeFormValue({target:{name:'departureDate', value:date}})}
                                    dateFormat={false}
                                    timeConstraints={{hours:{min:7, max:18}, minutes:{step:30} }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Personas</td>
                            <td>
                                <select
                                    className='form-control'
                                    name='personsNumber'
                                    value={this.getFormValue('personsNumber')}
                                    onChange={this.changeFormValue}
                                >
                                    <option value='1'>1</option>
                                    <option value='2'>2</option>
                                    <option value='3'>3</option>
                                    <option value='4'>4</option>
                                </select>
                            </td>
                        </tr>
                        { (()=> {
                            const weights = [];
                            for (let i = 0; i < this.getFormValue('personsNumber'); i++)
                                weights.push(
                                    <tr key={i}>
                                        <td>Peso persona {i + 1}</td>
                                        <td>
                                            <input
                                                className='form-control'
                                                name={'personsWeights[' + i + ']'}
                                                value={this.getFormValue('personsWeights[' + i + ']')}
                                                onChange={this.changeFormValue}
                                                placeholder='Peso en kg'
                                            />
                                        </td>
                                    </tr>
                                );
                            return weights;
                        })()}
                        <tr>
                            <td>Terminal</td>
                            <td>
                                <select
                                    className='form-control'
                                    name='terminal'
                                    value={this.getFormValue('terminal')}
                                    onChange={this.changeFormValue}
                                >
                                    <option value='T1'>Terminal 1</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Método de reserva</td>
                            <td>
                                <select
                                    className='form-control'
                                    name='source'
                                    value={this.getFormValue('source')}
                                    onChange={this.changeFormValue}
                                >
                                    <option disabled value="">Selecciona una opción</option>
                                    <option value='phone'>Teléfono</option>
                                    <option value='facebook'>Facebook</option>
                                    <option value='whatsapp'>Whatsapp</option>
                                    <option value='personal'>En persona</option>
                                    <option value='other'>Otro</option>
                                </select>
                            </td>
                        </tr>
                        </tbody>
                    </Table>

                    <ControlLabel>Notas</ControlLabel>
                    <textarea
                        className='form-control'
                        name='notes'
                        value={this.getFormValue('notes')}
                        onChange={this.changeFormValue}
                    />

                    </form>

                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle='danger' disabled={this.state.creating} className='pull-left' onClick={this.props.onHide}>Cancelar</Button>
                    <Button bsStyle='success' disabled={this.state.creating} onClick={this.createReservation}>{this.state.creating? 'Creando...':'Crear'}</Button>
                </Modal.Footer>
            </Modal>
        );

    }

}

export default AddACReservationModal;
