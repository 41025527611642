import React from 'react';
import _ from "lodash/fp";
import {useHeaderFooterHelper} from "../utils/header-footer-helper";

const matchesLocation = location => ({path}) => {
    if(!path){
        return false;
    }
    const regexDefinition = path.replace(/:\w+/g, '\\w+');
    const r = new RegExp(regexDefinition);
    return location.match(r) !== null;
};

export default ({location, systemRoutes: routes, history: navigate}) => {
    const {footerClassName} = useHeaderFooterHelper();
    const rs = _.filter(r => r.onFooter, routes);
    const route = _.find(matchesLocation(location), routes);
    const routeMenuPath = (() => {
        if (!route) {
            return '';
        }
        if (!route.hidden) {
            return route.path;
        }
        if (!route.from) {
            return '';
        }
        return route.from;
    })();
    const selected = _.findIndex({path: routeMenuPath}, rs);
    return (

        <div className='visible-sm visible-xs footer-container'>
            <div className={'footer-menu ' + (footerClassName || '')}>
                {rs.map((r, k) => (
                    <button className={k === selected ? 'selected footer-button' : 'footer-button'} key={k}
                    onClick={() => {navigate.push(r.path); window.scroll(0, 0)}}>
                        <img alt={r.name} src={r.img}/>
                    </button>
                ))
                }
            </div>
        </div>
    );
};
