import React, {useContext, useEffect, useState, useMemo, useCallback} from 'react';
import ReactDOM from 'react-dom';
import {useSelector} from 'react-redux';
import _ from 'lodash';
import {ApiContext} from "../../../api/Api";
import {useHeaderFooterHelper} from "../../../utils/header-footer-helper";

export const searchComponent = ({className = 'search', searchIcon, filterSearchIcon, configTitle, searchConfigs}) =>
    ({showingFiltersMenu, setShowingFiltersMenu, setContainerCSSClasses, query, onQueryChange, filter, setFilter, onFilterOpened, onFilterClosed}) => {
        const toggleFiltersMenu = () => setShowingFiltersMenu(!showingFiltersMenu);
        const {setFooterClassName, setHeaderClassName, fullResetAction} = useHeaderFooterHelper();

        const closeFilter = useCallback(() => {
            fullResetAction();
            setContainerCSSClasses([]);
            if (onFilterClosed) {
                onFilterClosed();
            }
        }, [fullResetAction, setContainerCSSClasses, onFilterClosed]);

        useEffect(() => {
            if (showingFiltersMenu) {
                setFooterClassName('blurred');
                setHeaderClassName('blurred');
                setContainerCSSClasses(['blurred']);
                if (onFilterOpened) {
                    onFilterOpened();
                }
                return closeFilter;
            }
            else{
                closeFilter();
            }
        }, [onFilterOpened, showingFiltersMenu, closeFilter, setFooterClassName, setHeaderClassName, setContainerCSSClasses]);
        return (
            <>
                <div className={className}>
                    <img className='search-icon' src={searchIcon} alt='Search'/>
                    <input value={query} onChange={evt => onQueryChange(evt.target.value)} type='text'
                           className='search-box'/>
                    <div className='filter-button'>
                        <button onClick={toggleFiltersMenu}>
                            <img src={filterSearchIcon} alt='Filter search'/>
                        </button>
                    </div>
                </div>
                {showingFiltersMenu &&
                <FilterMenuPortal onClose={() => setShowingFiltersMenu(false)} searchConfigs={searchConfigs}
                                  configTitle={configTitle} filter={filter} setFilter={setFilter}/>}
            </>
        );
    };

const FilterMenuPortal = props => {
    return ReactDOM.createPortal(
        <FilterMenu {...props}/>,
        document.getElementById('filter-portal')
    );
};

export const FilterMenu = ({onClose, searchConfigs, configTitle, filter, setFilter}) => {
    const pickFilter = toFilter=>{
        if(_.isEqual(toFilter, filter)){
            setFilter(null);
            onClose();
            return;
        }
        setFilter(toFilter);
        onClose();
    };
    const pickedIndex = _.findIndex(searchConfigs, c=>_.isEqual(c.filter, filter));
    return (
        <div className='Search Filter'>
            <div className='content'>
                <div className='handle-container'>
                    <div className='handle'/>
                </div>
                <p className='filter-title'>{configTitle}</p>

                <div className='options-container'>
                    {searchConfigs && (
                        searchConfigs.map((config, k) => (
                            <div onClick={()=>pickFilter(config.filter)} className={'filter-option '+(pickedIndex===k?'picked':'')}
                                key={k}>
                                {config.label}
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
};

const SEARCH_DEBOUNCE_MS = 20;
export const useSearch = (queryToRequestParams, request, nextPageRequest, mergeExtraFilters, extraFilters, resource) => {
    const [containerCSSClasses, setContainerCSSClasses] = useState([]);
    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState(null);
    const [showingFiltersMenu, setShowingFiltersMenu] = useState(false);
    const api = useContext(ApiContext);
    const {results, metaResults} = useSelector(({api: {[resource]: results, [resource + 'Meta']: metaResults}}) => ({
        results,
        metaResults
    }));
    const configuredFilters = useMemo(
        () => mergeExtraFilters(
            filter || {},
            queryToRequestParams(query),
        ), [mergeExtraFilters, filter, queryToRequestParams, query]
    );
    const withExtraFilters = useMemo(
        () => mergeExtraFilters(
            extraFilters || {},
            configuredFilters
        ), [configuredFilters, extraFilters, mergeExtraFilters]
    );

    useEffect(() => {
        api.clearProperty(resource);
    }, [withExtraFilters, api, resource]);

    useEffect(() => {
        (async () => {
            await request(withExtraFilters);
        })();
    }, [withExtraFilters, request]);
    const onQueryChange = _.debounce(setQuery, SEARCH_DEBOUNCE_MS);
    const searchProps = {
        query,
        onQueryChange,
        filter,
        setFilter,
        setContainerCSSClasses,
        setShowingFiltersMenu,
        showingFiltersMenu
    };
    const requestNextPage = useCallback(async () => {
        if (!metaResults) {
            return;
        }
        await nextPageRequest({...withExtraFilters, page: metaResults.currentPage + 1});
    }, [withExtraFilters, metaResults, nextPageRequest]);
    return {
        results,
        searchProps,
        requestNextPage,
        containerCSSClasses,
    };
};
