//Created by Mark Ehrlich 13.01.2017
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import ReactTable from 'react-table';
import {useSelector} from 'react-redux';
import {Modal} from 'react-bootstrap';
import UserDetail, {UserDetailLoadingId} from './UserDetail';
import EmployeeForm from './EmployeeForm';
import Button from "../../../../elements/CustomButton/CustomButton";
import SettlementDetailModal from "../../HumanResources/components/SettlementDetailModal";
import {availableEmployeeColumns, getUserExportUrl} from "../../../../utils/modelUtils/alma/employeeUtils";
import FireHireUserModal from "./components/FireHireUserModal/FireHireUserModal";
import AssistanceReportExporterModal from "./components/AssistanceReportExporterModal/AssistanceReportExporterModal";
import {ApiContext} from "../../../../api/Api";
import useBoolean from "../../../../hooks/useBoolean";
import useTideTable from "../../../../hooks/table/useTideTable";
import {tableStateToApiOptions} from "../../../../utils/reactTableUtils";
import {SecurityContext} from "../../../../utils/SecurityManager";
import TableConfigurator from "../../../../components/TableConfigurator/TableConfigurator";
import {getDefaultColumns} from "../../../../components/TableConfigurator/tableConfiguratorUtils";
import _ from 'lodash';
import {TideApiContext} from "../../../../api/tideApiConfig";

const ListEmployees = ({ areHired=true })=>{

    const api = useContext(ApiContext);
    const tideApi = useContext(TideApiContext);
    const securityManager = useContext(SecurityContext);
    const me = useSelector( s=>s.api.me );
    const [showingTableConfigurator, showTableConfigurator, hideTableConfigurator] = useBoolean();

    const tableStateRef = useRef({sort: {}, filter: {}});

    useEffect(()=>{
        api.clearProperty( "users" );
    }, [api]);

    const [userDetail, setUserDetail] = useState();
    const showUserDetail = ( user )=>{
        if(!user) return;
        api.users.get(0,1,UserDetailLoadingId,{employee:user.employee.id}, 'userDetail', areHired ).then((res) =>{
            setUserDetail( res[0] );
        });
    }
    const [userEditing, setUserEditing] = useState();
    const handleUserEdited = ()=>{
        if(!userEditing) return;
        showUserDetail(userEditing);
    }

    const [userFiring, setUserFiring] = useState();
    const fireEmployee = useCallback(( user )=>{
        setUserFiring(user);
        setUserDetail(null);
    }, []);

    const [userHiring, setUserHiring] = useState();
    const hireEmployee = useCallback(( user )=>{
        setUserHiring(user);
        setUserDetail(null);
    }, []);

    const stopHiringAndFiring = useCallback(()=>{
        setUserHiring(null);
        setUserFiring(null);
    }, []);

    const [settlementDetailUser, setSettlementDetailUser] = useState();
    const settlementDetail = useCallback(( user, event )=>{
        event.stopPropagation();
        if( user.employee.layoffs.length === 0 )
            return;
        setSettlementDetailUser(user);
    },[]);

    const tableContext = useMemo(()=>({api, areHired, settlementDetail}),[api, areHired, settlementDetail]);
    const availableColumns = useMemo(()=>availableEmployeeColumns({tableContext}), [tableContext]);
    const [activeColumns, setActiveColumns] = useState( ()=>getDefaultColumns({availableColumns, securityManager}) );

    const createApiOptions = useCallback((...args)=>{

        const result = tableStateToApiOptions(...args);
        if( result.params['order[username]'] ){
            result.params['stringNumberOrder[username]'] = result.params['order[username]'];
            delete result.params['order[username]'];
        }
        const tableState = args[0];
        if( !tableState?.sorted?.length && !areHired )
            result.params['order[employee.layoffDate]'] = 'DESC';
        return result;
    },[areHired]);

    const sGroups = useMemo(()=>{
        return _.union( ...activeColumns.map( col=>col.sGroups ), ['user_read_id', 'employee_read_id'] );
    },[activeColumns]);

    const requestFilters = useMemo(()=>{
        return {
            'employee[exists]':true,
            isActive:!!areHired,
            sGroups
        }
    }, [areHired, sGroups]);

    const table = useTideTable({
        entity: "users",
        columns: activeColumns,
        newTideApi: true,
        requestFilters,
        createApiOptions
    });

    const [exportingAssistance, setExportingAssistance, stopExportingAssistance] = useBoolean();

    const { totalItems } = useSelector(s=>s.api.usersMeta)||{};

    const exportUrl = getUserExportUrl(tideApi, activeColumns, {
        ...tableStateRef.current.sort,
        ...tableStateRef.current.filter,
        isActive: areHired,
        'employee[exists]':true,
        sGroups,
    })

    return(
        <div className="container-fluid main-container ListEmployees">

            {showingTableConfigurator?
                <TableConfigurator
                    onClose={hideTableConfigurator}
                    availableColumns={availableColumns}
                    activeColumns={activeColumns}
                    onActiveColumnsChange={setActiveColumns}
                />:
                <Button className='pull-right' onClick={showTableConfigurator}>Mostrar opciones avanzadas</Button>}
            <div className='clearfix'/>

            <ReactTable
                {...table.tableProps}
                getTdProps={(state, rowInfo)=>({onClick:()=> {
                        showUserDetail(rowInfo?.original);
                    }})}
            />

            <p className="total-count">{totalItems} registro{totalItems>1?"s":""} encontrado{totalItems>1?"s":""}</p>

            <a rel="noopener noreferrer" href={exportUrl}>
                <Button>Exportar a Excel</Button>
            </a>

            <Button className="pull-right btn-primary" onClick={setExportingAssistance} > Exportar reportes de asistencia</Button>


            {/*  ---Modals---  */}

            {/*  ---User detail modal---  */}

            { userDetail &&
                <Modal
                    onHide={()=>setUserDetail(null)}
                    show={true}
                    bsSize="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Detalle de empleado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UserDetail
                            user={userDetail}
                            editEmployee = {canEditRoles.indexOf(me.role.name) !== -1? setUserEditing: undefined }
                            onFire = {canFireRoles.indexOf(me.role.name) !== -1? fireEmployee: undefined }
                            onHire = {canFireRoles.indexOf(me.role.name) !== -1? hireEmployee: undefined }
                            canSeeWage={ securityManager.alma.canEditEmployeesWage() }
                        />
                    </Modal.Body>

                    <Modal.Footer/>
                </Modal>
            }

            {/*  ---Modal for editing an employee---  */}


            {userEditing &&
                <Modal
                    onHide={()=>setUserEditing(null)}
                    show={true}
                    bsSize="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Editar empleado</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <EmployeeForm
                            isEditing={true}
                            initialValues={EmployeeForm.userToFormValues(userEditing) }
                            canSeeWage={ securityManager.alma.canEditEmployeesWage() }
                            onEdit={handleUserEdited}
                        />

                    </Modal.Body>

                    <Modal.Footer/>
                </Modal>
            }


            {/*  ---Modal for firing an employee or re-hiring a fired employee---  */}

            { (userFiring || userHiring) &&
            <FireHireUserModal
                userFiring={userFiring}
                userHiring={userHiring}
                onHide={stopHiringAndFiring}
            />  }


            {/*  ---Export assistance report to PDF using actual filters in table---  */}
            { exportingAssistance &&
            <AssistanceReportExporterModal
                onHide={stopExportingAssistance}
                filter={tableStateRef.current.filter}
                sort={tableStateRef.current.sort}
                areHired={areHired}
            />}

            {settlementDetailUser &&
                <SettlementDetailModal
                    onHide={()=>setSettlementDetailUser(null)}
                    user={settlementDetailUser}
                />}

            {/*  ---End Modals---  */}

        </div>
    )
}

export default ListEmployees;

const canEditRoles = [
    "RECURSOS_HUMANOS",
    "NOMINAS",
    "NOMINAS_ADMINISTRADOR_EE",
    "SUPER_ADMIN",
];

const canFireRoles = [
    "RECURSOS_HUMANOS",
    "NOMINAS",
    "NOMINAS_ADMINISTRADOR_EE",
    "SUPER_ADMIN",
];
