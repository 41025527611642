import React, {Component} from 'react';
import StatsCard from '../../../../components/Card/StatsCard';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import LoaderIcon from '../../../../components/Helper/LoaderIcon';

//Requires api.employees.get(0,1); (for the meta)

class PEmployeeCount extends Component {

    getInfoString(){

        if( this.props.employeesMeta ){
            return this.props.employeesMeta.totalItems;
        }
        else{
            return <LoaderIcon/>
        }

    }

    render() {

        return (
            <StatsCard
                bigIcon={<i className={"fa fa-users pink"}/>}
                statsText={ "Número de empleados" }
                statsValue={ this.getInfoString() }
                statsIcon={<Link to={"/alma/employees/list"}><i className="fa fa-eye" /></Link>}
                statsIconText={<Link to={"/alma/employees/list"}>Ir a lista</Link>}
            />
        );
    }
}

function mapStateToProps( {api:{employeesMeta}} ){

    return {employeesMeta}
}

export default connect(mapStateToProps)(PEmployeeCount);
