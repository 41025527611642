import './FlightsManagement.scss';
import React, {useContext, useState} from 'react';
import {ApiContext} from 'api/Api';
import ClassicModal from 'components/Modals/ClassicModal';
import FlightForm from './components/FlightForm/FlightForm';
import {ReactComponent as Edit} from 'assets/icon/edit.svg';
import FlightsTable from '../components/FlightsTable/FlightsTable';
import { sGroups } from '../components/FlightsTable/utils/table.config';

const customProp = "@FlightsManagement.Store";
const MANAGE_FLIGHT = "MANAGE_FLIGHT";
const FlightsManagement = () => {
    //Initializers
    const api = useContext(ApiContext);

    //Init states:
    const initModal = {action: null, payload: null};

    //State
    const [modal, setModal] = useState(initModal);

    //Events
    const onCloseModal = () => setModal(initModal);
    const onManageFlight = (flight = {}) => () => setModal({action: MANAGE_FLIGHT, payload: flight});

    //Methods
    const handleFormSubmit = async payload => {
        let result;
        //Adding sGroups in our payload:
        payload.params.sGroups = sGroups;
        try {
            if(!payload.params.monthlyGoal)
                payload.params.monthlyGoal = null;
            if(payload?.id)
                result = await api.flights.update({...payload, customProp});
            else
                result = await api.flights.create({...payload, customProp});

            if(result)
                onCloseModal();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="FlightsManagement">
            <FlightsTable
                customProp={customProp}
                unshiftColumns={{
                    Header: "Acciones",
                    id: "actions",
                    accessor: flight => (
                        <button className="edit-action" type="button" onClick={onManageFlight(flight)}>
                            <Edit />
                        </button>
                    ),
                    width: 100
                }}
            />
            {
                (modal.action === MANAGE_FLIGHT) &&
                <ClassicModal
                    title={`${(modal?.payload) ? "Editar" : "Crear"} vuelo.`}
                    onHide={onCloseModal}
                    showConfirm={false}
                    showCancel={false}
                >
                    <FlightForm flight={(modal?.payload?.id) ? modal.payload : null} onSubmit={handleFormSubmit}/>
                </ClassicModal>
            }
        </div>
    );
};
export default FlightsManagement;