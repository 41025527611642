import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import './_GolfCartDayControl.scss'
import moment from "moment";
import GolfCartScheduleControlPanel from "../components/GolfCartScheduleControlPanel/GolfCartScheduleControlPanel";
import GolfCartSchedule from "../../../../components/GolfCartSchedule/GolfCartSchedule";
import {TideApiContext} from "../../../../api/tideApiConfig";
import {getGolfCartScheduleForDate, golfCartSGroups} from "../../../../utils/modelUtils/ee/golfCartUtils";
import {golfCartTripSGroups} from "../../../../utils/modelUtils/ee/golfCartTripUtils";
import _ from "lodash";
//import {SecurityContext} from "../../../../utils/SecurityManager";
import {selectLoungeFilters} from "../../../../utils/modelUtils/ee/loungesUtils";

const GolfCartDayControl = () => {
    const api = useContext(TideApiContext);
    const [date, setDate] = useState(() => moment());
    const nextDate = useCallback(() => setDate(date => moment(date).add(1, 'day')), []);
    const prevDate = useCallback(() => setDate(date => moment(date).subtract(1, 'day')), []);
    const [activeGolfCarts, setActiveGolfCarts] = useState([]);
    const [currentDateTrips, setCurrentDateTrips] = useState([]);
    const [hourHeight, setHourHeight] = useState(400);
    const [addingTrip, setAddingTrip] = useState();
    const [showingCassetteId, setShowingCassetteId] = useState(null);
    //const securityManager = useContext(SecurityContext);
    //const isReadonlyUser = securityManager.ee.canSeeGolfCartTripListReadonly();
    const [lounges, setLounges] = useState([]);

    const reload = useCallback(() => {
        api.golfCarts.get({params: {sGroups: golfCartSGroups, pagination:false, isActive: true}})
            .then(golfCarts => {
                setActiveGolfCarts(golfCarts);
            });
    }, [api]);

    const loadLounges = useCallback(() => {
        api.lounges.get({params: selectLoungeFilters})
            .then(lounges => {
                setLounges(lounges);
            });
    }, [api]);

    const currentDateSchedules = useMemo(() => {
        const currentDateSchedules = {};
        activeGolfCarts.map((activeGolfCart) => {
            const currentDateGolfSchedule = getGolfCartScheduleForDate(activeGolfCart, date);
            if (currentDateGolfSchedule) {
                currentDateSchedules[activeGolfCart.id] = currentDateGolfSchedule;
            }
            return null;
        })
        return currentDateSchedules;
    }, [activeGolfCarts, date]);

    const reloadTrips = useCallback(() => {
        reload();
        setCurrentDateTrips([]);
        api.golfCartTrips.get({
            params: {
                sGroups: golfCartTripSGroups,
                'scheduledStartDate[after]': date.format('YYYY-MM-DDT[00:00]'),
                'scheduledStartDate[before]': date.format('YYYY-MM-DDT[23:59]'),
                'pagination':false
            }
        }).then((trips) => {
            //if(isReadonlyUser)
            //    trips = trips.filter((trip) => trip.operator?.id===securityManager.me.id);

            setCurrentDateTrips(_.groupBy(trips, "golfCart.id"));
        });
    }, [date, setCurrentDateTrips, api, reload])

    useEffect(() => {
        //Actualizar adding trip si no es nuevo
        reload();
        reloadTrips();
        loadLounges();
    }, [reload, reloadTrips, loadLounges]);

    useEffect(() => {
        reloadTrips();
    }, [date, reloadTrips]);

    const addingTripWithDate = useMemo(() => {
        if (addingTrip) {
            return {...addingTrip, date}
        }
    }, [addingTrip, date])

    const reloadAddingTrip = useCallback((addingTripId) => {
        api.golfCartTrips.get({
            id: addingTripId,
            params: {sGroups: golfCartTripSGroups, pagination:false},
        }).then((trip) => setAddingTrip({scheduledTrip: trip, golfCart: trip.golfCart}));
    }, [api, setAddingTrip]);

    const onCancel = useCallback(() => {
        setShowingCassetteId(null);
        setAddingTrip(null);
    }, [setAddingTrip, setShowingCassetteId]);

    return (
        <div className={"GolfCartDayControl"}>
            <div className='date-bar'>
                <button onClick={prevDate} className="change-date-but"><i className="fa fa-arrow-left"/></button>
                <button onClick={() => setHourHeight(h => h <= 50 ? 50 : (h - 10))} className="change-date-but"><i
                    className="fa fa-search-minus"/></button>
                {date.format("DD/MM/YYYY")}
                <button onClick={() => setHourHeight(h => h >= 600 ? 600 : (h + 10))} className="change-date-but"><i
                    className="fa fa-search-plus"/></button>
                <button onClick={nextDate} className="change-date-but"><i className="fa fa-arrow-right"/></button>
            </div>
            <div className="work-space">
                <GolfCartScheduleControlPanel addingTrip={addingTripWithDate}
                                              reloadTrips={reloadTrips}
                                              reloadAddingTrip={reloadAddingTrip}
                                              onCancel={onCancel}
                                              addingTripOnChange={setAddingTrip}
                                              date={date}
                                              showingCassetteId={showingCassetteId}
                                              onCommentAdded={reloadTrips}
                />
                {activeGolfCarts.map((golfCart) =>
                    <GolfCartSchedule golfCart={golfCart}
                                      isSelected={golfCart.id === addingTripWithDate?.golfCart?.id}
                                      onScheduleSquareClick={setAddingTrip}
                                      addingTrip={addingTrip}
                                      key={golfCart.id}
                                      golfCartSchedule={currentDateSchedules[golfCart.id]}
                                      golfCartTrips={currentDateTrips[golfCart.id]}
                                      hourHeight={hourHeight}
                                      onCommentsButtonClick={setShowingCassetteId}
                                      lounges={lounges}
                    />
                )}
            </div>
        </div>
    );
};

export default GolfCartDayControl;


