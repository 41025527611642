import React, {useEffect, useMemo, useCallback, useContext, useState} from 'react';
import {useSelector} from 'react-redux';
import {ApiContext} from '../../../../api/Api';
import CustomCheckbox from '../../../../elements/CustomCheckbox/CustomCheckbox';
import _ from 'lodash/fp';

export const useLoungesFilter = () => {
    const [selectedLounges, setSelectedLounges] = useState(undefined);
    
    const isChecked = useCallback(id=>_.includes(id, selectedLounges),
                                  [selectedLounges]);

    const toggle = useCallback(id=>{
        if(!isChecked(id)){
            setSelectedLounges(ls=>ls?[...ls, id]:[id]);
            return;
        }
        setSelectedLounges(ls=>_.filter(l=>id!==l, ls));
    }, [isChecked]);

    const filterProps = useMemo(()=>({
        selectedLounges,
        toggle,
        isChecked
    }), [selectedLounges, isChecked, toggle]);
    
    const requestParams = useMemo(()=>({lounges: selectedLounges}), [selectedLounges]);

    return {
        requestParams,
        filterProps
    };
};

const LoungesFilter = ({selectedLounges, toggle, isChecked, label}) => {
    const api=useContext(ApiContext);
    useEffect(()=>{
        api.lounges.get2({filters: {isLounge: true}});
    }, [api]);
    const lounges = useSelector(({api: {lounges=[]}})=>lounges);
    return (
        <div className='row'>
            <div className='col-md-3'>
                <label>{label||'En salas:'}</label>
            </div>
            <div className='col-md-8 pull-right'>
                {lounges.map(l=><CustomCheckbox
                                    key={l.id}
                                    label={l.name}
                                    inline
                                    isChecked={isChecked(l.id)}
                                    onChange={()=>toggle(l.id)}
                                />)}
            </div>
        </div>
    );
};

export default LoungesFilter;
