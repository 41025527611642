import React, {useContext} from 'react';
import {ApiContext} from '../../../api/Api';
import DishModal from './DishModal';
import {listSGroups as sGroups} from '../../../utils/modelUtils/orozco/orozcoProductUtils';

const AddDishModal = ({onHide, lounges, dishCategories}) => {
    const api = useContext(ApiContext);
    const addDish = (properties, files=undefined) => {
        api.orozcoProducts.create({
            properties: {...properties, sGroups},
            files
        });
    };
    return (
        <DishModal
            onHide={onHide}
            onSubmit={addDish}
            submitText='Agregar platillo'
            title='Agregar platillo'
            lounges={lounges}
            dishCategories={dishCategories}
        />
    );
};
export default AddDishModal;
