import React from 'react';
import ApprovalMatrixConfigurator from "../../../components/Approvals/ApprovalMatrixConfigurator";
import PurchaseAmountsApprovalRuleConfigurator from "./PurchaseAmountsApprovalRuleConfigurator";
import {employeeApprovalRuleTypes} from "../../../utils/modelUtils/alma/employeeApprovalRulesUtils";

const PurchaseApprovalMatrixConfigurator = () => {

    return (
        <div>
            <ApprovalMatrixConfigurator approvalType={employeeApprovalRuleTypes.PURCHASE}/>
            <PurchaseAmountsApprovalRuleConfigurator/>
        </div>
    )
};

export default PurchaseApprovalMatrixConfigurator;

