import React, {useState} from 'react';
import {positionReducedPermissionsSGroups} from "../../../../utils/modelUtils/alma/positionUtils"; // exclude permissions in position
import EntitySelect from "../../../../components/EntitySelect/EntitySelect";
import Switch from "react-bootstrap-switch";

const positionsFilters = { isActive: true, sGroups: positionReducedPermissionsSGroups};

const PositionSelector = ( props )=>{
    const initialState = props.question.config?.position;
    const [showPositions, setShowPositions] = useState(!!initialState);
    const togglePositions = () => {
        const newShowPositions = !showPositions;

        if(!newShowPositions)
            props.onChange( { ...props.question.config, position: null } )

        setShowPositions(!showPositions);
    }

    return (
        <div className='FilterableQuestion'>
            <Switch
                value={ showPositions }
                onChange={ togglePositions }
            />

            Afecta calificación de GCare para puestos específicos

            {showPositions &&
                <EntitySelect
                    entity={"positions"}
                    value={ props.question.config?.position }
                    onChange={ (position) => props.onChange( { ...props.question.config, position: position } ) }
                    placeholder="Ver permisos de puesto"
                    additionalFilters={positionsFilters}
                    getMethod={"get2"}
                    multi={true}
                    style={{marginLeft: '10px'}}
                />}
        </div>
    );
};

export default PositionSelector;
