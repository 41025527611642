import React, {useCallback, useState} from 'react';
import {FormControl} from 'react-bootstrap';
import CustomCheckbox from '../../../elements/CustomCheckbox/CustomCheckbox';
import _ from 'lodash';

const priceCurrencies = [
    {
        label: 'Precio en dólares',
        code: 'USD'
    },
    {
        label: 'Precio en euros',
        code: 'EUR'
    },
    {
        label: 'Precio en pesos',
        code: 'MXN'
    }
];

const PricesCheckboxes = ({value, onChange}) => {
    const [visible, setVisible] = useState(value.map(v=>v.currency));
    const hide = code => () => {
        onChange(code)(null);
        setVisible(vs=>_.filter(vs, v=>v!==code));
    };
    const show = code => () => {
        let value = 80;
        if(code === 'USD'){
            value = 5;
        }
        else if( code === 'EUR'){
            value = 3;
        }
        onChange(code)({target: {value}});
        setVisible(vs=>[...vs, code]);
    };
    const isChecked = code=>!!_.find(visible, v=>v===code);
    const toggleVisibility = code =>
          isChecked(code)? hide(code): show(code);

    const amountForCurrency = useCallback(
        currency=>(
            (v=>v===''?v:v/100)((_.find(value, {currency})||{}).amount)
        ),
        [value]);

    return (
        <div>
            {priceCurrencies.map(c=>(
                <div key={c.code} className='row'>
                    <div className='col-md-4'>
                        <CustomCheckbox
                            onChange={toggleVisibility(c.code)}
                            isChecked={isChecked(c.code)}
                            label={c.label}/>
                    </div>
                    <div className='col-md-6'>
                        {isChecked(c.code)&&
                         <div className='input-group'>
                             <div className='input-group-addon'>$</div>
                             <FormControl
                                 type='number'
                                 value={amountForCurrency(c.code)}
                                 onChange={onChange(c.code)}
                             />
                         </div>
                        }
                    </div>
                </div>
            ))}
        </div>

    );
};

export default PricesCheckboxes;
