import React, {useCallback} from 'react';
import PurchaseProductQuoteRowEditable from "./PurchaseProductQuoteRowEditable";
import PurchaseProductAddRowQuote from "./PurchaseProductAddRowQuote";
import {emptyPurchaseProductQuote, getBaseTaxes} from "../../../../utils/modelUtils/jazz/purchaseRequisitionUtils";
import {connect} from "react-redux";


const PurchaseProductRowEditable = ( { purchaseProduct, onChange, isOpen, onHoverChange, index, hasDiscount, currency, taxes} )=>{

    const addNewQuote = useCallback(()=>{
        onChange( {
            ...purchaseProduct,
            purchaseProductQuotes:[
                ...purchaseProduct.purchaseProductQuotes,
                emptyPurchaseProductQuote( getBaseTaxes( taxes ) )
            ]
        }, index )
    }, [ purchaseProduct, onChange, index, taxes ]);

    const handleQuoteChange = useCallback( ( purchaseProductQuote, quoteIndex )=>{

        const purchaseProductQuotes = [ ...purchaseProduct.purchaseProductQuotes ];
        purchaseProductQuotes[ quoteIndex ] = purchaseProductQuote;
        onChange({ ...purchaseProduct, purchaseProductQuotes }, index);
    }, [ purchaseProduct, onChange, index ] );

    const handleQuoteRemove = useCallback( ( quoteIndex )=>{

        const purchaseProductQuotes = [ ...purchaseProduct.purchaseProductQuotes ];
        purchaseProductQuotes.splice(quoteIndex, 1);
        onChange({ ...purchaseProduct, purchaseProductQuotes }, index);
    }, [ purchaseProduct, onChange, index ] );

    const handleHoverChange = useCallback(( hovered )=>{
        onHoverChange( hovered? index : -1 );
    }, [ index, onHoverChange ] );

    const handleMakeFirst = useCallback( (quoteIndex)=>{
        const purchaseProductQuotes = [ ...purchaseProduct.purchaseProductQuotes ];
        const temp = purchaseProductQuotes[quoteIndex];
        purchaseProductQuotes[quoteIndex] = purchaseProductQuotes[0];
        purchaseProductQuotes[0] = temp;

        onChange({ ...purchaseProduct, purchaseProductQuotes }, index);
    }, [onChange, purchaseProduct, index] );

    const rows = purchaseProduct.purchaseProductQuotes.map( (purchaseProductQuote, i)=>
            <PurchaseProductQuoteRowEditable
                purchaseProduct={purchaseProduct}
                purchaseProductQuote={purchaseProductQuote}
                ppIndex={ index }
                quoteIndex={ i }
                onChange={ handleQuoteChange }
                onRemove={ handleQuoteRemove }
                onHoverChange={ handleHoverChange }
                onPurchaseProductChange={onChange}
                isOpen={ isOpen }
                hasDiscount={hasDiscount}
                currency={ currency }
                onMakeFirst={ handleMakeFirst }
                key={i}
            />
        );

    rows.push(
        <PurchaseProductAddRowQuote onAdd={ addNewQuote } onHoverChange={ handleHoverChange } isOpen={ isOpen } hasDiscount={hasDiscount} key={'add'}/>
    );
    return rows;
};

const mapStateToProps = ({api:{taxes}})=>({taxes});

export default connect(mapStateToProps)(PurchaseProductRowEditable);
