export function getCoords(elem, corner) { // cross browser version
    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top  = Math.round(box.top +  scrollTop - clientTop);
    const left = Math.round(box.left + scrollLeft - clientLeft);

    if(corner==='tl')
        return {top, left};
    else if(corner==='tr')
        return {top, left: left+elem.offsetWidth};
    else if(corner==='bl')
        return {top: top+elem.offsetHeight, left};
    else if(corner==='br')
        return {top: top+elem.offsetHeight, left: left+elem.offsetWidth};

    return {top, left};
}