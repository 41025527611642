import React, {useContext, useState} from 'react';
import ReactTable from 'react-table';
import useTideTable from '../../../hooks/table/useTideTable';
import moment from "moment";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import optionsFilter from "../../../utils/optionsFilter";
import {Button} from "react-bootstrap";
import {ApiContext} from "../../../api/Api";

const DeliveryStatus = ({original: exchange}) => {
    const [loading, setLoading] = useState(false);
    const api = useContext(ApiContext);
    const {id, status} = exchange;
    const markAsDelivered = async () => {
        setLoading(true);
        try {
            await api.rewardExchanges.edit({id, params: {status: 'delivered'}});
        } catch (e) {
        }
        setLoading(false);
    };
    if (status === 'pending' && !loading) {
        return (
            <Button onClick={markAsDelivered}>
                Marcar como entregado
            </Button>
        );
    }
    if (status === 'delivered') {
        return (
            <p>Entregado</p>
        );
    }
    return null;
};

const columns = [
    {
        Header: 'Status',
        accessor: 'status',
        Filter: optionsFilter([
            {
                value: 'pending',
                text: 'Por entregar'
            },
            {
                value: 'delivered',
                text: 'Entregados'
            }
        ]),
        Cell: DeliveryStatus
    },
    {
        Header: 'Para',
        id: 'employee.name',
        accessor: e => employeeFullName(e.employee)
    },
    {
        Header: 'Producto',
        id: 'reward.name',
        accessor: e => e.reward.name
    },
    {
        Header: 'Comprado el',
        id: 'exchangeDate',
        filterable: false,
        accessor: e => moment(e.exchangeDate).format('LL')
    },
];

const RewardDelivery = () => {
    const rewardDeliveryTable = useTideTable({
        entity: 'rewardExchanges',
        columns
    });
    return (
        <div className='Rewards admin'>
            <h2>Entrega de productos</h2>
            <ReactTable {...rewardDeliveryTable.tableProps}/>
        </div>
    );
};

export default RewardDelivery;
