import React, {useState,useCallback,useContext} from 'react';
import ReactTable from 'react-table';
import Button from '../../../elements/CustomButton/CustomButton';
import ServiceFormModal from './ServiceFormModal';
import ServiceDetailModal from "./ServiceDetailModal";
import useTideTable from "../../../hooks/table/useTideTable";
import useBoolean from "../../../hooks/useBoolean";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import {ApiContext} from "../../../api/Api";

const tableStructure = [{
    Header: 'Nombre',
    accessor: 'name'
}
];

const requestFilters={isService:true};


const ServiceList = () => {

    const [addingService, addService, hideAddServiceModal] = useBoolean(false);
    const [showingDetail, setShowingDetail] = useState(null);
    const [editingService, setEditingService]=useState(null);
    const stopEditing=useCallback(()=>setEditingService(null),[]);
    const [deletingService, setDeletingService]=useState(null);
    const stopDeleting=useCallback(()=>setDeletingService(null),[]);

    // ----- detail ........
    const hideDetailModal = () => {
        setShowingDetail(null);
    };

    const serviceTable=useTideTable({
        entity:'products',
        columns:tableStructure,
        requestFilters
    });

    const api=useContext(ApiContext);

    // .------- deleting ----------
    const promptDeleteService=useCallback((service)=>{
        hideDetailModal();
        setDeletingService(service);
    },[]);

    const deleteService=useCallback(()=>{
        stopDeleting();
        api.products.delete({id:deletingService.id});
    },[api, deletingService, stopDeleting]);

    // .------- editing ----------
    const startEditingService=useCallback((service)=>{
        hideDetailModal();
        setEditingService(service);
    },[]);

    return (
        <div className="container-fluid main-container">

            <Button bsStyle='primary' className='pull-right' onClick={addService}>Nuevo servicio</Button>
            <div className='clearfix'/>

            <ReactTable
                {...serviceTable.tableProps}
                getTdProps={(state, row) => ({onClick: () => row ? setShowingDetail(row.original) : null})}
            />
            <p className="total-count">{serviceTable.itemsFoundString}</p>

            {addingService &&
                <ServiceFormModal onHide={hideAddServiceModal}/>}

            {editingService &&
                <ServiceFormModal
                    onHide={stopEditing}
                    service={editingService}
                />}

            {showingDetail &&
                <ServiceDetailModal
                service={showingDetail}
                onHide={hideDetailModal}
                onEdit={startEditingService}
                onDelete={promptDeleteService}
                />}

            {deletingService&&
                <ConfirmModal
                    onCancel={stopDeleting}
                    onConfirm={deleteService}
                    title={'Eliminar servicio'}
                    message={"¿Estás seguro que deseas borrar este servicio?"}
            />}
        </div>
    );

};

export default ServiceList;
