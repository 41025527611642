import React from 'react';

const PurchaseProductNoQuoteRow = (props )=>{

    const {
        purchaseProduct,
        ppIndex,
    } = props;

    return (
        <tr
            className={'PurchaseProductQuoteRow product-row first'}
        >
            <td className='text-center' data-label='#' ><div className='outer-div'><div className='inner-div'>
                {ppIndex + 1}
            </div></div></td>

            <td className='text-center' data-label='Tipo'><div className='outer-div'><div className='inner-div'>
                {purchaseProduct.isService ? 'Servicio' : 'Producto'}
            </div></div></td>
            {/* De línea o no */}
            <td className='text-center' data-label='De línea'><div className='outer-div'><div className='inner-div'>
                {purchaseProduct.product ? 'Si' : 'No'}
            </div></div></td>

            <td className='text-left' data-label='Nombre'><div className='outer-div'><div className='inner-div'>
                {purchaseProduct.productBrand ?
                    ( purchaseProduct.productBrand.product.name + ' - ' + purchaseProduct.productBrand.brand.name)
                    : purchaseProduct.name}
            </div></div></td>

            <td data-label='Cantidad'><div className='outer-div'><div className='inner-div'>
                {Number(purchaseProduct.quantity)} {purchaseProduct.measurementUnit ? purchaseProduct.measurementUnit.name : null}
            </div></div> </td>

            <td  data-label='Proveedor'><div className='outer-div'><div className='inner-div'>
                Sin cotización
            </div></div></td>

            <td data-label='Precio unitario'><div className='outer-div'><div className='inner-div'>
                Sin cotización
            </div></div></td>

            <td data-label='Subtotal'><div className='outer-div'><div className='inner-div'>
                Sin cotización
            </div></div></td>

            <td data-label='Descuento unitario'><div className='outer-div'><div className='inner-div'>
                Sin cotización
            </div></div></td>

            <td data-label='Impuestos'><div className='outer-div'><div className='inner-div'>
                Sin cotización
            </div></div></td>

            <td data-label='Total'><div className='outer-div'><div className='inner-div'>
                Sin cotización
            </div></div></td>

        </tr>
    );
};

export default React.memo(PurchaseProductNoQuoteRow);
