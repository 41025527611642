import React, {useContext, useState} from 'react';
import {DropdownButton, MenuItem, Modal} from "react-bootstrap";
import moment from "moment";
import Button from "../../../../../../elements/CustomButton/CustomButton";
import {ApiContext} from "../../../../../../api/Api";

const AssistanceReportExporterModal = ({onHide, totalItems, filter, sort, areHired}) => {

    const api = useContext(ApiContext);
    const [journalYear, setJournalYear] = useState( moment().year() );
    const [journalMonth, setJournalMonth] = useState( moment().month() );

    return (
        <Modal
            onHide={onHide}
            show={true}>
            <Modal.Header closeButton>
                <Modal.Title>Reporte de asistencia</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p>Se va a exportar un archivo PDF con el reporte de asistencia de cada colaborador en una página.</p>
                <p>Los reportes a exportar serán los de las personas mostradas en la tabla debajo de esta ventana.
                    Puedes usar los filtros de esa misma tabla para exportar solo los reportes de algunas personas.</p>
                <p>Se exportará un total de <strong>{totalItems} reporte{totalItems!==1?'s':''}</strong>.</p>

                <p>Selecciona el periodo a exportar:</p>
                <DropdownButton
                    bsStyle='primary'
                    title={ journalYear }
                    id='journal-year-selector'>
                    {(()=>{
                        const years = [];
                        for( let i=moment().year(); i>=2018; i--)
                            years.push( <MenuItem key={i} onClick={()=>setJournalYear(i)}>{i}</MenuItem> );
                        return years;
                    })()}
                </DropdownButton>
                &nbsp;&nbsp;&nbsp;
                <DropdownButton
                    bsStyle='primary'
                    title={ moment().set('month',journalMonth).format('MMMM') }
                    id='journal-month-selector'>
                    {(()=>{
                        const months = [];
                        for( let i=0; i<12; i++)
                            months.push( <MenuItem key={i} onClick={()=>setJournalMonth(i)}>{moment().set('month',i).format('MMMM')}</MenuItem> );
                        return months;
                    })()}
                </DropdownButton>


            </Modal.Body>

            <Modal.Footer>
                <Button
                    bsStyle={'danger'}
                    style={{float:'left'}}
                    onClick={onHide}
                >
                    Cancelar
                </Button>
                <a href={ api.journal.exportUrl( journalYear, journalMonth, { ...filter, ...sort, isActive: areHired } ) }
                   target='_blank'
                   rel="noopener noreferrer"
                >
                    <Button bsStyle={'success'}>Exportar</Button>
                </a>

            </ Modal.Footer>
        </Modal>
    );
};

export default AssistanceReportExporterModal;
