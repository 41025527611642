import React, {useContext, useEffect, useMemo, useState} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Row, Table} from "react-bootstrap";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Button from "../../../../elements/CustomButton/CustomButton";

const sGroups = [
    "flight_read",
    "flight_read_airline",
    "airline_read",
    "flight_read_destination_type",
    "destination_type_read",
    "flight_read_month_flights",
    "flight_read_goal_percentage",
];

const GuestsFlightsGoalReport = () => {
    const api = useContext(ApiContext);
    const [dates, setDates] = useState({from: moment().date(1), to: moment()});
    const onDateChange = (e, {startDate, endDate}) => {
        setDates({from: moment(startDate), to: moment(endDate)});
    };

    const filters = useMemo(() => {
        const countFlightsFrom = dates.from.format('YYYY-MM-DD');
        const countFlightsTo = dates.to.format('YYYY-MM-DD');
        return {sGroups, countFlightsFrom, countFlightsTo, 'exists[monthlyGoal]': true, pagination: false}
    }, [dates])

    useEffect(() => {
        api.flights.get({params: filters});
    }, [api, dates, filters]);


    const flights = useSelector(({api}) => api['flights'] || []);

    return (
        <div className='GuestsFlightsGoalReport'>

            <Row>
                <DateRangePicker
                    startDate={dates.from}
                    endDate={dates.to}
                    onApply={onDateChange}
                    containerStyles={{}}
                >

                    <CustomButton
                        bsStyle={'primary'}
                        className='col-xs-12 range-button'
                    >
                        Rango de fechas &nbsp;
                        <strong>{dates.from.format('YYYY-MM-DD')}</strong> al <strong>{dates.to.format('YYYY-MM-DD')}</strong>
                    </CustomButton>

                </DateRangePicker>
                <br/>

                <div className={'export-button'}>
                    <a
                        href={api.flights.exportGoalsReportUrl(filters)}
                        rel="noopener noreferrer"
                    >
                        <Button>Exportar a Excel</Button>
                    </a>
                </div>
            </Row>
            {flights.length !== 0 ?
                <Table striped>
                    <thead>
                    <tr>
                        <th>Vuelo</th>
                        <th>Aerolínea</th>
                        <th>Meta mensual</th>
                        <th>No. de pasajeros</th>
                        <th>Porcentaje de meta</th>
                    </tr>
                    </thead>
                    <tbody>
                    {flights.map((flight) => (
                        <tr key={flight.id}>
                            <td>{flight.number}</td>
                            <td>{flight.airline.name}</td>
                            <td>{flight.monthlyGoal}</td>
                            <td>{flight.monthFlights}</td>
                            <td>{flight.goalPercentage || 0}%</td>
                        </tr>
                    ))}
                    </tbody>
                </Table> : <h3>No se han encontrado vuelos con meta mensual configurada</h3>
            }
        </div>
    );
};

export default GuestsFlightsGoalReport;
