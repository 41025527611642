import React, {Component} from 'react';
import {Alert, Col, Grid, Modal, Row, Table} from 'react-bootstrap';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import StatsCard from '../../../components/Card/StatsCard';
import moneyFormatter from '../../../utils/moneyFormatter'
import LoaderIcon from '../../../components/Helper/LoaderIcon';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {AbsenceCodes} from '../../../api/Api';
import _ from 'lodash';
import PayrollDetailExplanation from '../Payroll/components/PayrollDetailExplanation';
import Button from '../../../elements/CustomButton/CustomButton';
import Checkbox from '../../../elements/CustomCheckbox/CustomCheckbox';
import Justifications from '../Manager/Justifications';
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
import classNames from 'classnames';
import { GCareScoreWidget } from './Widgets/GCareScoreWidget';

class EmployeeDash extends Component{

    static contextTypes = {api:PropTypes.object, notifier: PropTypes.object};

    loadingPayrollId = "@Class.EmployeeDashboard.payroll";
    loadingIncidencesId = "@Class.EmployeeDashboard.incidences";
    loadingCreatingJustificationId = "@Class.EmployeeDashboard.creatingJustification";

    constructor(props){
        super(props);

        this.state = {
            payrollObtained : false,
            payrollRealTimeObtained: false,
            payrollClosed : true,// This means that the payroll has been calculated and archived, thus we got it from the actual entity and not the "realTime" endpoint which calculate it at the moment
            payrollDetailOpen: false,
            incidencesOpen: false,
            justifying: false,
            toJustify: [],
            toJustifyIndexes: [],
            incidenceJustifyFiles:[],
            fileInputs:[],
            captureTime: null
        };

        this.handleIncidenceJustifyComments = this.handleIncidenceJustifyComments.bind(this);
        this.handleIncidenceJustifyFile = this.handleIncidenceJustifyFile.bind(this);
        this.handleIncidenceJustifyNew = this.handleIncidenceJustifyNew.bind(this);
        this.handleIncidenceJustify = this.handleIncidenceJustify.bind(this);
        this.onJustificationsChange = this.onJustificationsChange.bind(this);


    }

    UNSAFE_componentWillMount(){

        this.getPayroll();
        this.context.api.incidences.get();
        this.context.api.me.haveExtraMealTime();
        this.getCaptureTime();
    }

    getPayroll(){

        if( this.props.loadingIds[this.loadingPayrollId])
            return;

        if( this.state.payrollClosed ){
            this.context.api.payrollDetails.getEmployee( this.props.me.employee.id, true, this.loadingPayrollId);
        }
        else{
            this.context.api.payrollDetailsRealTime.getSingle( this.props.me.employee.id, this.loadingPayrollId);
        }

    }

    getCaptureTime(){
        this.context.api.receptionistsCheckinReport.get({'userId': this.props.me.employee.id})
                    .then(employees => this.setState({
                        captureTime: (employees[0] ? Number(employees[0]?.avg_capture_time) : null)
                    }));
    }

    getIncidences( fromDate, toDate){
        this.context.api.appliedIncidences.get(0,200, this.loadingIncidencesId,{
            employee:this.props.me.employee.id,
            "shiftDate[after]":fromDate.format("YYYY-MM-DD"),
            "shiftDate[before]":toDate.format("YYYY-MM-DD"),
            "incidence.isPositive":false
        }, 'EDashAppliedIncidences' )
    };

    UNSAFE_componentWillReceiveProps( nextProps ){

        if( !this.state.payrollObtained && //If payroll fetch had not been handled
            this.props.loadingIds[this.loadingPayrollId] && //And the payroll fetch was in progress
            !nextProps.loadingIds[this.loadingPayrollId] && //But now the payroll fetch is no more in progress (which means it finished loading)
            nextProps.employeePayrollDetails ){ //And we make sure the property is set

            if( nextProps.employeePayrollDetails.length === 0 ) {//We haven't got a PayrollDetail entity for the current period, this means it haven't been calculated yet
                this.context.api.payrollDetailsRealTime.getSingle(this.props.me.employee.id, this.loadingPayrollId);
                this.setState({
                    payrollObtained: true,
                    payrollClosed: false
                });
            }
            else{//We've got a PayrollDetail entity for the current period
                this.setState({
                    payrollObtained: true,
                    payrollClosed: true
                });
                const payrollDetail = nextProps.employeePayrollDetails[0];

                const fromDate = moment(payrollDetail.payroll.lagStartDate);
                const toDate = moment(payrollDetail.payroll.lagEndDate);

                this.loadDashboardBetweenDates(fromDate, toDate);
            }
        }
        else if( this.state.payrollObtained && //If the payroll fetch has already been done (the one for the closed payroll)
            !this.state.payrollClosed && // But the fetch for the closed payroll was empty (which means we should fetch the real time payroll)
            !this.state.payrollRealTimeObtained && //And the real time payroll has not been fetched yet
            this.props.loadingIds[this.loadingPayrollId] && //And the payroll fetch was in progress
            !nextProps.loadingIds[this.loadingPayrollId] && //But now the payroll fetch is no more in progress (which means it finished loading)
            nextProps.singlePayrollDetail ){

            this.setState({
                payrollRealTimeObtained: true
            });
            const payrollDetail = nextProps.singlePayrollDetail;

            const fromDate = moment(payrollDetail.payroll.lagStartDate);
            const toDate = moment(payrollDetail.payroll.lagEndDate);

            this.loadDashboardBetweenDates(fromDate, toDate);
        }

        //An incidence was justified so we reload incidences
        if( this.props.loadingIds[this.loadingCreatingJustificationId] && !nextProps.loadingIds[this.loadingCreatingJustificationId] ){

            this.onJustificationsChange();
            this.setState({toJustify:[]});
        }
    }

    onJustificationsChange(){
        this.loadDashboardBetweenDates( this.state.dates.from, this.state.dates.to);
    }


    loadDashboardBetweenDates( fromDate, toDate ){

        this.getIncidences(fromDate, toDate);
        this.context.api.incidenceJustifications.get(undefined, undefined, undefined, {requestedBy:this.props.me.employee.id, "date[after]":fromDate.format("YYYY/MM/DD"), "date[before]":toDate.format("YYYY/MM/DD")}, 'EDashIncidenceJustifications');

        this.setState(
            {dates:
                    {
                        from:fromDate,
                        to:toDate
                    }}
        );

    }

    getAssistanceString( payrollDetail ){

        if( !payrollDetail )
            return "0/0";

        const startDate = moment(payrollDetail.payroll.lagStartDate);
        const endLagDate = moment(payrollDetail.payroll.lagEndDate);
        let endDate = moment();
        if( endLagDate.isBefore(endDate))
            endDate = endLagDate;

        const totalDays = endDate.diff( startDate, "days")-1;
        const absences = payrollDetail.appliedDiscounts.reduce( (acc, disc)=>{

            if( disc.appliedIncidences.length ){//Grouped appliedIncideneces are supposed to be always of the same type
                const incCode = disc.appliedIncidences[0].incidence.code;
                if( _.includes( AbsenceCodes, incCode ) )
                    acc+= disc.appliedIncidences.length;
            }


            return acc;
        }, 0);

        return `${totalDays-absences}/${totalDays}`;
    }

    getIncidencesString(){

        if( !this.props.appliedIncidences )
            return "0/0";

        const appIncs = this.props.appliedIncidences;
        const total = appIncs.length;

        const justified = appIncs.reduce( (acc, appInc)=>{

            if( appInc.incidence.justifyAbsence || appInc.incidence.justifyRetardment )
                acc++;
            return acc;
        },0);

        return `${justified}/${total}`;
    }

    handleIncidenceJustifyComments( e ){
        this.setState({incidenceJustifyComments: e.target.value});
    }


    handleIncidenceJustifyFile = (e, index)=>{
        const incidenceJustifyFiles = [ ...this.state.incidenceJustifyFiles ];
        incidenceJustifyFiles[index] = e.target.files[0];
        this.setState({incidenceJustifyFiles})
    };

    addFileInput = ()=>{
        const fileInputs = [...this.state.fileInputs];
        const index = fileInputs.length;
        fileInputs.push(<input type="file" onChange={(e)=>this.handleIncidenceJustifyFile(e, index)} key={index}/>);
        this.setState({fileInputs});
    };

    handleIncidenceJustifyNew( e ){
        this.setState({incidenceJustifyNew: e.target.value});
    }
    handleIncidenceJustify(){

        if( ! this.state.incidenceJustifyComments ) {
            this.context.notifier.error("Debes escribir algún comentario antes de enviar la solicitud.");
            return;
        }
        if( ! this.state.incidenceJustifyNew ) {
            this.context.notifier.error("Debes elegir la opción que mejor justifique tu incidencia..");
            return;
        }

        const files = this.state.incidenceJustifyFiles;
        const incidences = this.state.toJustify.map( (inc)=>inc.id );

        this.context.api.incidenceJustifications.create(undefined, undefined, this.state.incidenceJustifyNew,incidences, this.state.incidenceJustifyComments, files, this.loadingCreatingJustificationId);
        this.setState({justifying:false});
    }

    canHaveBonus(){

        return !!_.find( this.props.me.employee.employeeBonuses, (emBon)=> emBon.bonus.code === 'P' )
    }


    render(){

        const isReceptionist = ['RECEPCIONISTA_A', 'RECEPCIONISTA_B'].includes(this.props.me.employee?.position?.code);

        let payroll;
        if( this.state.payrollRealTimeObtained )
            payroll = this.props.singlePayrollDetail;
        else if( this.props.employeePayrollDetails )
            payroll = this.props.employeePayrollDetails[0];

        const incidenceString = this.getIncidencesString();

        let bonusAlert = "";

        if( this.props.appliedIncidences && this.canHaveBonus() )
            bonusAlert = incidenceString !== "0/0"?
                <Alert bsStyle="danger"> <strong>Oh no!</strong> Has perdido tu bono de puntualidad.</Alert>:
                <Alert bsStyle="success"> <strong>Sigue así!</strong> Tu bono de puntualidad te espera.</Alert>;

        // let extraMinutesAlert = null;
        //
        // if( this.props.haveExtraMealTime )
        //    extraMinutesAlert = <Alert bsStyle="success">¡Felicidades! Gracias a que llegaste temprano, hoy tienes 15 minutos extra en tu horario de comida. Disfrútalos.</Alert>;

        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col xs={12}>
                            { bonusAlert }
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className="fa fa-address-card text-warning" />}
                                statsText="Asistencias"
                                statsValue={this.getAssistanceString(payroll)}
                                statsIcon={<Link to={"/alma/employee/history"}><i className="fa fa-eye" /></Link>}
                                statsIconText={<Link to={"/alma/employee/history"}>Ver detalles</Link>}
                            />
                        </Col>
                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className="fa fa-exclamation-triangle text-danger" />}
                                statsText="Incidencias"
                                statsValue={this.getIncidencesString()}
                                statsIcon={<span className='link' onClick={()=>this.setState({incidencesOpen:true})}><i className="fa fa-eye" /></span>}
                                statsIconText={<span className='link'  onClick={()=>this.setState({incidencesOpen:true})}>Ver detalles</span>}
                            />
                        </Col>
                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className="fa fa-life-ring text-info" />}
                                statsText="Justificaciones"
                                statsValue={this.props.incidenceJustifications? this.props.incidenceJustifications.length:0}
                                statsIcon={<span className='link'  onClick={()=>this.setState({justificationsOpen:true})}><i className="fa fa-eye" /></span>}
                                statsIconText={<span className='link'  onClick={()=>this.setState({justificationsOpen:true})}>Ver detalles</span>}
                            />
                        </Col>
                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className="fa fa-dollar text-success" />}
                                statsText="Descuentos"
                                statsValue={payroll? moneyFormatter(payroll.totalDiscount): <LoaderIcon/>  }
                            />
                        </Col>
                    </Row>
                    
                    <Row>
                        {isReceptionist &&
                            <Col lg={3} sm={6}>
                                <StatsCard
                                    bigIcon={<i className="fa fa-clock-o text-success" />}
                                    statsText="Tiempo promedio de captura de Checkins"
                                    statsValue={this.state.captureTime ? this.state.captureTime.toFixed(2) : 0  }
                                />
                            </Col>}
                        
                        <Col lg={3} sm={6}>
                            <GCareScoreWidget employee={this.props.me.employee} />
                        </Col>

                        <Col lg={3} sm={6}>
                            <StatsCard
                                bigIcon={<i className={classNames("fa fa-star", (this.props.me.employee?.fulfillmentScore>=0 ? 'text-success' : 'text-danger' ))} />}
                                statsText="Calificación de cumplimiento"
                                statsValue={this.props.me.employee?.fulfillmentScore}
                            />
                        </Col>
                    </Row>
                </Grid>

                {this.state.payrollDetailOpen?
                    <Modal show={this.state.payrollDetailOpen} bsSize="large" onHide={() => this.setState({payrollDetailOpen: false})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Detalle de {employeeFullName(this.props.me.employee)}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PayrollDetailExplanation
                                payrollDetail={payroll}
                            />
                        </Modal.Body>
                    </Modal>
                    :null}

                {this.props.appliedIncidences && this.state.incidencesOpen?
                    <Modal show={true} onHide={() => this.setState({incidencesOpen: false, toJustify:[]})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Detalle de incidencias</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h3>Incidencias</h3>
                            <Table>
                                <thead>
                                <tr>
                                    <th>Tipo</th>
                                    <th>Fecha</th>
                                    <th>Estado</th>
                                    <th>Seleccionar</th>
                                </tr>
                                </thead>
                                <tbody>

                                {this.props.appliedIncidences.map((appInc, i)=>(
                                    <tr key={appInc.id}>
                                        <td>{appInc.incidence.name} </td>
                                        <td>
                                            {moment(appInc.shiftDate).format("DD/MM/YYYY")}
                                        </td>
                                        <td>
                                            {(()=>{
                                                if( !appInc.justification )
                                                    return "Sin justificar";
                                                else if(appInc.justification.approved === true)
                                                    return "Aprobada";
                                                else if(appInc.justification.approved === false)
                                                    return "Rechazada";
                                                return "En espera..."
                                            })()}

                                        </td>
                                        <td>
                                            <Checkbox
                                                number={i}
                                                input={
                                                    {
                                                        disabled: (appInc.justification && appInc.justification.approved !== false ) || (this.state.toJustify.length && this.state.toJustify[0].incidence.code !== appInc.incidence.code),
                                                        checked:this.state.toJustify.indexOf(appInc)!==-1
                                                    }}
                                                onChange={(val)=> {
                                                    if(val)
                                                        this.setState({toJustify: [appInc, ...this.state.toJustify]});
                                                    else {
                                                        let index =  this.state.toJustify.indexOf(appInc);
                                                        if (index > -1) {
                                                            let newArray = [...this.state.toJustify];
                                                            newArray.splice(index, 1);
                                                            this.setState({toJustify:newArray});
                                                        }
                                                    }

                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </Table>
                            <Modal.Footer>
                                {this.state.toJustify.length?
                                    <Button onClick={()=>this.setState({justifying:true})}>Justificar</Button>
                                    :null
                                }
                            </Modal.Footer>
                        </Modal.Body>
                    </Modal>
                    :null}

                {this.state.justifying && this.state.toJustify.length ?
                    <Modal show={true} onHide={() => this.setState({justifying: false,fileInputs:[]})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Justificar { this.state.toJustify[0].incidence.name }{this.state.toJustify.length > 1? "s":""} </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <p>{ this.state.toJustify[0].incidence.name }{this.state.toJustify.length > 1? "s":""} del:<br/>
                                    {this.state.toJustify.map((appInc)=> <span key={appInc.id}>{moment(appInc.shiftDate).format("DD/MM/YYYY")}<br/></span> )}
                            </p>
                            <br/>
                            <p>Selecciona una opción que justifique tu incidencia</p>
                            <select  onChange={this.handleIncidenceJustifyNew} value={this.state.handleIncidenceJustifyNew}>
                                <option value=''>Selecciona una opción</option>
                                {this.props.incidences.map((inc)=> {
                                    if( this.state.toJustify[0].incidence.code === "F" && inc.justifyAbsence )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "R" && inc.justifyRetardment )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "MACO" && inc.justifyMoreMealTime )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "MECO" && inc.justifyLessMealTime )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "MHT" && inc.justifyLessWorkHours )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "CHOT" && inc.justifyEarlyCheckout )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "RIN" && inc.justifyIncompleteChecks )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "UNREG" && inc.justifyOneCheck )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( this.state.toJustify[0].incidence.code === "MHT5" && inc.justifyLess5WorkHours )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    else if( inc.code === 'RCHNF' )
                                        return <option value={inc.id} key={inc.id}>{inc.name}</option>;
                                    return null;
                                })}
                            </select>
                            <br/><br/>
                            <p>Escribe porqué crees que se debería justificar esta incidencia.</p>
                            <textarea className={"form-control"} value={this.state.incidenceJustifyComments} onChange={this.handleIncidenceJustifyComments} />
                            <br/>
                            <p>¿Tienes algún archivo que lo corrobore?</p>
                            {this.state.fileInputs}
                            <br />
                            <Button bsStyle='primary' onClick={this.addFileInput}>Agregar archivo</Button>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.setState({justifying: false,fileInputs:[]})}>Cancelar</Button>{" "}
                            <Button bsStyle="primary" onClick={this.handleIncidenceJustify}>Enviar</Button>
                        </Modal.Footer>
                    </Modal>
                    :null}

                {this.state.justificationsOpen ?
                    <Modal show={true} onHide={() => this.setState({justificationsOpen: false})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Justificaciones</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Justifications
                                showNames={false}
                                paginated={false}
                                canApprove={false}
                                canDelete={true}
                                employee={this.props.me.employee}
                                dates = {this.state.dates }
                                onDelete = {this.onJustificationsChange}
                                isFuture={null}
                            />

                        </Modal.Body>

                    </Modal>
                    :null}
            </div>
        );
    }
}

function mapStateToProps({api:{singlePayrollDetail, employeePayrollDetails, EDashAppliedIncidences:appliedIncidences, me, incidences=[], EDashIncidenceJustifications:incidenceJustifications, haveExtraMealTime}, loadingIds}){

    return {
        singlePayrollDetail,
        employeePayrollDetails,
        appliedIncidences,
        loadingIds,
        me,
        incidences,
        incidenceJustifications,
        haveExtraMealTime
    };
}
export default connect(mapStateToProps)(EmployeeDash);
