import {copyPropertyIdOrNull, copyPropertyOrNull, copyPropertyOrThrow} from "../../../services/formUtils";
import {productUnitConversionSGroups} from "../almazen/productsUtils";
import {convertToSmallestUnit, testBasicConversionRules, unitBundleToIds} from "../almazen/unitsUtility";
import moneyFormatter from "../../moneyFormatter";
import moment from "moment";

export const prepareRecipeHeaderFormForServer = (form)=>{
    let prepared = {};

    // Es sub receta
    if(!form.orozcoProduct){
        if(!form.quantity || !Number(form.quantity)){
            throw new Error('Debes agregar un número válido en el rendimiento.');
        }
        testBasicConversionRules(form);
        prepared = unitBundleToIds(form);
        prepared.quantity = convertToSmallestUnit(form.quantity, form.displayUnit, form);
    }

    copyPropertyOrThrow(form, prepared, 'name', 'Debes agregar un nombre para la receta.');
    copyPropertyIdOrNull(form, prepared, 'orozcoProduct');
    copyPropertyOrNull(form, prepared, 'classification');

    if(!form.elaborationMinutes || !Number(form.elaborationMinutes))
        throw new Error('Debes agregar un número válido en el tiempo de elaboración.');

    prepared.elaborationMinutes = Math.round(Number(form.elaborationMinutes));
    prepared.approvedBy = form.approvedBy?.id;
    
    return prepared;
};

export const orozcoRecipeListColumns = [
    {
        Header:'Nombre',
        accessor:'name',
    },
    {
        Header:'Tipo',
        accessor:(recipe)=>recipe.orozcoProduct?'Platillo':'Sub receta',
        id:'orozcoProduct.name',
        filterable: false
    },
    {
        Header:'Precio',
        id:'estimatedCost',
        filterable: false,
        accessor:(recipe)=>moneyFormatter(recipe.estimatedCost, 1)
    },
    {
        Header:'Aprobada por',
        id:'approvedBy',
        filterable: false,
        accessor:(recipe)=> (recipe.approvedBy?.employee ? recipe.approvedBy?.employee?.name : '-')
    },
    {
        Header:'Fecha de aprobación',
        id:'approvedDate',
        filterable: false,
        accessor:(recipe)=>(recipe.approvedBy?.employee ? moment(recipe?.approvedDate).format('DD/MM/YYYY HH:mm') : 'Pendiente')
    }
];

export const emptyRecipeForModifier = (orozcoProduct, modifier)=>({
    name: `Modificador - ${orozcoProduct.name} - ${modifier.name}`,
    quantity: 1,
    classification: "Modificador",
    elaborationMinutes: 0,
    orozcoProductModifier: modifier.id
});

export const orozcoRecipeListSGroups = [
    "orozco_recipe_read",
    "orozco_recipe_read_orozco_product",
        "orozco_product_read_id",
    "orozco_recipe_read_estimated_cost",
    'user_read',
    'employee_read_name'
];

export const orozcoRecipeEditorSGroups = [
    "orozco_recipe_read",
    "orozco_recipe_read_gallery",
    "orozco_recipe_read_smallest_unit",
    "orozco_recipe_read_display_unit",
    "orozco_recipe_read_conversion_rules",
    "orozco_recipe_read_estimated_cost",
    "orozco_recipe_read_orozco_recipe_ingredients",
        "orozco_recipe_ingredient_read",
        "orozco_recipe_read_orozco_recipe_ingredients",
            "orozco_recipe_ingredient_read_recipe",
            "orozco_recipe_ingredient_read_product",
    ...productUnitConversionSGroups,
    "product_read_weighted_cost_average",
    "product_read_last_cost_average",
    'user_read',
    'employee_read_name'
];

export const orozcoRecipeUnitConversionSGroups = [
    'orozco_recipe_read_id',
    'orozco_recipe_read_name',
    'orozco_recipe_read_smallest_unit',
    'orozco_recipe_read_display_unit',
    'orozco_recipe_read_conversion_rules',
    'measurement_unit_read',
    'unit_conversion_rule_read_id',
    'unit_conversion_rule_read_unit',
    'unit_conversion_rule_read_multiplier'
];

export const orozcoRecipeShrinkageMovementSGroups = [
    'orozco_recipe_read',
    'orozco_recipe_read_id',
    'orozco_recipe_read_name',
    'orozco_recipe_read_smallest_unit',
    'orozco_recipe_read_display_unit',
        'measurement_unit_read',
    'orozco_recipe_read_conversion_rules',
        'unit_conversion_rule_read',
    'orozco_recipe_read_orozco_recipe_ingredients',
        "orozco_recipe_ingredient_read",
        "orozco_recipe_ingredient_read_quantity",
        'orozco_recipe_ingredient_read_product',
        'orozco_recipe_ingredient_read_recipe',
            'product_read',
            'product_brand_unit_read',
            'product_brand_read'
];
