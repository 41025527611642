import React, {useState, useContext} from 'react';
import useBoolean from "../../../../hooks/useBoolean";
import classNames from 'classnames';
import {DateRangePicker} from "react-dates";
import useDateRangePickerState from "../../../../hooks/useDateRangePickerState";
import TideEntitySelect from "../../../../components/TideEntitySelect/TideEntitySelect";
import {NotifierContext} from "../../../../utils/Notifier";
import {TideApiContext} from "../../../../api/tideApiConfig";
import {querySerialize} from "../../../../api/Api";
import moment from 'moment';

const InventoryInputReport = () => {

    const notifier = useContext(NotifierContext);
    const api = useContext(TideApiContext);
    const [warehouse, setWarehouse] = useState();
    const [isOpen, , , toggleOpen] = useBoolean();
    const {props, dateRange} = useDateRangePickerState({
	startDate: moment().subtract(1, 'month'),
	endDate: moment()
    });

    const valid = warehouse&&dateRange.startDate&&dateRange.endDate;
    const query = valid&&querySerialize({
	warehouse: warehouse.id,
	from: dateRange.startDate.format('YYYY-MM-DD'),
	to: dateRange.endDate.format('YYYY-MM-DD'),
	token: api.token
    });

    const handleClick = (e)=>{
        if(!valid){
            e.preventDefault();
            notifier.error('Llena todos los filtros');
        }
    };
    const reportUrl = valid?`${api.host}/api/inventories/input-report?${query}`:'#';

    return (
        <div className={classNames("report-block", isOpen && 'open')}>
            <p className='title' onClick={toggleOpen}>Reporte de inventario por almacén<i className='fa fa-caret-down caret-icon'/>
            </p>
            {isOpen &&
            <div className='body'>
                <hr/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <p className='filter-label'>Almacén:</p>
                            <TideEntitySelect
                                entity='warehouses'
                                value={warehouse}
                                onChange={setWarehouse}
                                filterLocal
                            />
                            <p className='filter-label'>Rango de fechas:</p>
                            <DateRangePicker {...props} />
                        </div>
                        <div className='col-md-6'>
                            <p className='filter-label'>Descripción:</p>
                            <p className='description'>Nos permite conocer el inventario físico y monetario de insumos
                                en un almacén en el final del periodo<br/>
                                Incluyendo inventario al inicio y final del periodo y la suma de todas las entradas y todas las salidas de cada producto
                                en el periodo.</p>
                        </div>
                    </div>
                </div>
                <br/>
                <a onClick={handleClick} href={reportUrl} rel='noopener noreferrer'>
                    <button className='btn btn-info'>Descargar</button>
                </a>
            </div>}
        </div>
    );
}

export default InventoryInputReport;
