import _ from 'lodash';

export const flattenLoungeAccessMethodsTree=(loungeAccessMethods)=>{

    if(!loungeAccessMethods || !loungeAccessMethods.length)
        return [];

    const flat=[];
    _.forEach(loungeAccessMethods, lam=>{
        if(!lam.isActive){
            return;
        }
        if(lam.loungeSubAccessMethods && lam.loungeSubAccessMethods.length){
            const children = flattenLoungeAccessMethodsTree(lam.loungeSubAccessMethods);
            _.forEach(children, childLam=>{
                flat.push({...childLam, name:lam.name + ' - ' + childLam.name});
            });
        }
        else
            flat.push({...lam});
    });

    return flat;
};

export const getLoungeAccessMethodNameIncludingParents=(loungeAccessMethod)=>
    loungeAccessMethod.loungeParentAccessMethod?
        getLoungeAccessMethodNameIncludingParents(loungeAccessMethod.loungeParentAccessMethod)+ ' - ' +loungeAccessMethod.name:
        loungeAccessMethod.name;

export const getAvailableCurrencies = (loungeAccessMethod)=>{

    if(!loungeAccessMethod || !loungeAccessMethod.config || !loungeAccessMethod.config.prices || !loungeAccessMethod.config.prices.length)
        return [];

    return loungeAccessMethod.config.prices.reduce(
        (acc, price)=>
            price && price.amount?[...acc, price.currency]:acc
        , []);
};
