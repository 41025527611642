/*
 Todos los colaboradores inician con 100 puntos, a estos se les restan puntos por cada incidencia
                    encontrada en los últimos 30 días.<br/>
                    Los puntos se restan de la siguiente manera:<br/>
                    Más tiempo de comida: -3<br/>
                    Registros incompletos: -3<br/>
                    Retardo a briefing: -3<br/>
                    Salida temprana: -5<br/>
                    Menos de 8 horas trabajadas: -5<br/>
                    Retardo: -5<br/>
                    Falta: -10<br/>
                    Suspensión: -20<br/><br/>
 */

export const getFulfillmentPoints=(incidence)=>{

    if(!incidence || !incidence.code) return 0;

    switch (incidence.code) {
        case 'F':
            return -10;
        case 'R':
            return -5;
        case 'MACO':
            return -3;
        case 'RIN':
            return -3;
        case 'RB':
            return -3;
        case 'CHOT':
            return -5;
        case 'MHT':
            return -5;
        default:
            return 0;
    }

};
