import React,{useContext, useEffect} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import moneyFormatter from '../../../../utils/moneyFormatter';

const loadingId = '@OrozcoProductAverageRealCostReport.report';

const OrozcoProductAverageRealCostReport = () => {
    const api = useContext(ApiContext);
    useEffect(()=>{
        api.orozcoProducts.averageRealCostReport({loadingId});
    }, [api]);
    const report = useSelector(({api})=>api.averageRealCostReport||[]);

    return (
        <div className='OrozcoProductAverageRealCostReport'>
            <Table striped>
                <thead>
                    <tr>
                        <th>Categoría</th>
                        <th>Costo real promedio</th>
                    </tr>
                </thead>
                <tbody>
                    {report.map(({orozcoProductCategoryId, orozcoProductCategoryName, averageRealCost})=>(
                        <tr key={orozcoProductCategoryId}>
                            <td>{orozcoProductCategoryName||'No asignados'}</td>
                            <td>{moneyFormatter(averageRealCost)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default OrozcoProductAverageRealCostReport;
