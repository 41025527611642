import React from 'react';
import {Modal} from 'react-bootstrap';
import FileViewer from '../FileViewer/FileViewer';
import CustomButton from "../../elements/CustomButton/CustomButton";

export default function ( {onHide, name='', src, downloadLink, ...props} ) {

    return (
        <Modal
            bsSize="lg"
            show={true}
            onHide={onHide}
            {...props}
            className="ModalIframe"
        >

            <Modal.Header closeButton>
                <Modal.Title>{name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <FileViewer
                    name={name}
                    src={src}
                />
                {downloadLink&&
                <a href={downloadLink}><CustomButton className='pull-right' bsStyle='primary'>Descargar</CustomButton></a>}
                    <div className='clearfix'/>
            </Modal.Body>
        </Modal>
       );
}