import React, {useContext} from 'react';
import defaultPicture from '../../../assets/img/faces/face-0.jpg';
import {ApiContext} from "../../../api/Api";
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";

const EmployeeRow = ({menuOpened, onMenuClicked, employee, actions = [], onClick, className}) => {
    const api = useContext(ApiContext);
    const photo = (() => {
        if (!employee.photo) {
            return defaultPicture;
        }
        return api.appFiles.getUrl(employee.photo.id);
    })();
    const rowCSSClasses = [
        'employee-row',
        ...className ? [className] : []
    ];
    return (
        <div className={rowCSSClasses.join(' ')} onClick={() => onClick && onClick(employee)}>
            <div className='picture-container'>
                <div className='picture' style={{backgroundImage: `url(${photo})`}}/>
            </div>
            <div className='data-container'>
                <p className='employee-name'>{employeeFullName(employee)}</p>
                <p>{employee.position ? employee.position.name : ''}</p>
                <p>{employee.area ? employee.area.name : ''}</p>
            </div>
            <div className='actions'>
                {actions.length > 0 &&
                <>
                    <button onClick={e=>{onMenuClicked();e.stopPropagation();}}>
                        <div className='dot'/>
                        <div className='dot'/>
                        <div className='dot'/>
                    </button>
                    {menuOpened &&
                    <div className='actions-menu'>
                        {actions.map(([label, action]) => (
                            <button key={label} onClick={e => {e.stopPropagation();action(employee);}} className='action-button'>
                                {label}
                            </button>
                        ))}
                    </div>
                    }
                </>
                }
            </div>
        </div>
    );
};

export default EmployeeRow;
