import React from 'react';
import ProductReport from "./ProductReport";

const ProductReportView = ({match})=>{

    return (
        <div className={'ProductReportView container-fluid'}>
            <ProductReport productId={match.params.id} />
        </div>
    )
};

export default ProductReportView;
