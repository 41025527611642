import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {Grid, Row, Col } from 'react-bootstrap';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import Card from '../../../components/Card/Card.jsx';
import _ from 'lodash'
import {UserDetailApiProp, UserDetailLoadingId} from '../../alma/Employees/core/UserDetail';
import {employeeFullName} from "../../../utils/modelUtils/alma/employeeUtils";
moment.locale('es');


BigCalendar.setLocalizer( BigCalendar.momentLocalizer(moment));

class EmployeeCalendar extends Component{

    static contextTypes = {api:PropTypes.object,notifier: PropTypes.object};

    constructor(props ){
        super(props);
        this.loadingId = '@Class.Calendar';
        this.state = { currentDate:moment() };
        this.loadBirthdayEvents = _.debounce(this.loadBirthdayEvents.bind(this),700);
    }

    componentDidMount() {
        this.loadBirthdayEvents();
    }

    loadBirthdayEvents = () => {
        let filters = {};
        filters['sGroups[]'] = 'employee_calendar';
        filters['itemsPerPage'] = 500;
        filters['isHired'] = true;
        this.context.api.employees.get(0,500,this.loadingId,filters)
            .then(()=>{
                let events = this.props.employees;
                for (let event in events){
                    events[event].title =employeeFullName( events[event] ) ;
                    events[event].from = new Date(moment(this.state.currentDate).get('year'),moment (events[event][this.props.accessor]).get('month'),moment (events[event][this.props.accessor]).get('date'));
                    events[event].to = new Date(moment(this.state.currentDate).get('year'),moment (events[event][this.props.accessor]).get('month'),moment (events[event][this.props.accessor]).get('date'));
                }
                this.setState({...this.state,events:events})
            })
    };

    getUser = (event) => {
        const employeeId = event.id;
        this.context.api.users.get(0,1,UserDetailLoadingId,{employee:employeeId},UserDetailApiProp );
    };

    onNavigate=(date)=>{
        const newDate = moment(date);
        if(moment(date).get('year')!== moment(this.state.currentDate).get('year')) {
            let events = this.state.events;
            for (let event in events){
                events[event].from = new Date(moment(newDate).get('year'),moment (events[event][this.props.accessor]).get('month'),moment (events[event][this.props.accessor]).get('date'));
                events[event].to = new Date(moment(newDate).get('year'),moment (events[event][this.props.accessor]).get('month'),moment (events[event][this.props.accessor]).get('date'));
            }
            this.setState({...this.state,currentDate:newDate,events:events});
        }
        else this.setState({...this.state,currentDate:newDate});
    };

    render(){
        const messages = { allDay: 'Todo el día',agenda: 'Agenda', previous: '<<', next: '>>',
            today: 'Hoy', month: 'Mes', week: 'Semana', day: 'Día', date: 'Fecha', time: 'Hora', event: 'Evento', showMore: total => `+ ${total} más`}
        return (
            <div className="reservationsCalendar">
                <Grid fluid>
                    <Row>
                        <Col md={10} mdOffset={1}>
                            <Card
                                calendar
                                content={
                                    <BigCalendar
                                        titleAccessor='title'
                                        startAccessor='from'
                                        endAccessor='to'
                                        messages={messages}
                                        selectable
                                        views={[ 'day','week','month']}
                                        events={this.state.events||[]}
                                        defaultView='month'
                                        scrollToTime={new Date(1990, 1, 1, 6)}
                                        defaultDate={new Date()}
                                        onSelectEvent={event => this.getUser(event)}
                                        onNavigate={this.onNavigate}
                                    />
                                }
                            />
                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}

const mapStateToProps = ({api:{me, employees=[]}})=>({me,employees});

export default connect(mapStateToProps)(EmployeeCalendar );
