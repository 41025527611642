import React, {Component} from 'react';
import {FormControl} from "react-bootstrap";
import {SortableElement} from 'react-sortable-hoc';
import AdvancedQuestionConfigurator from './AdvancedQuestionConfigurator';

class NoConfig extends Component{

    state = { showingConfigurator: false };

    onTitleChange = (e)=>{
        const question = {...this.props.question, text: e.target.value};

        if( !question.config )
            question.config = { uid:Math.random().toString(10) };
        else if( !question.config.uid )
            question.config.uid = Math.random().toString(10);

        this.props.onChange( question, question.config.order );
    };

    onConfigChange = ( config )=>{

        const question = {...this.props.question, config};
        this.props.onChange( question, question.config.order );
    };

    handleRemove = ()=>{
        if( !this.props.onRemove ) return;

        this.props.onRemove( this.props.question );
    };

    toggleConfigurator = ()=>{
        this.setState({showingConfigurator: !this.state.showingConfigurator });
    };

    render() {

        const isInactive = this.props.question.config && this.props.question.config.inactive;

        return (

            <div className={'question-wrapper ' +(isInactive?'inactive-question':'')}>
                <div className='left-handle'>
                    <span>{this.props.question.config.order + 1}</span>
                    <span className='thrash-container'>
                        <i onClick={this.handleRemove} className='fa fa-trash'/>
                    </span>
                </div>
                <div className='right-content'>
                    <FormControl
                        type='text'
                        placeholder='Título de la pregunta'
                        value={this.props.question.text}
                        onChange={this.onTitleChange}
                    />
                    <p className='text-muted small question-type-subtitle'>{this.props.definition.title}</p>

                    <p><span className='link small' onClick={this.toggleConfigurator}><i className='fa fa-cogs' /> Configuración avanzada</span></p>
                    {this.state.showingConfigurator?
                        <AdvancedQuestionConfigurator
                            question={this.props.question}
                            questionnaire={this.props.questionnaire}
                            onChange={this.onConfigChange}
                        />
                        :null}
                </div>
            </div>
        );
    }
}

NoConfig = SortableElement(NoConfig);

NoConfig.initialConfig = ( order )=>{
    return {
        uid: Math.random().toString(10),
        required: true,
        order
    };
};

NoConfig.validate = (question)=>{

    if( !question.text || !question.text.trim() )
        return {error:true, message:'El título de la pregunta está vacío'};

    return {success:true};
};

export default NoConfig;
