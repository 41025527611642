import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Card from '../../../components/Card/Card.jsx';
import {connect} from 'react-redux';
import ReactTable from 'react-table';
import ReactTableTranslations from '../../../utils/ReactTableTranslation';
import Button from '../../../elements/CustomButton/CustomButton';
import {Modal, FormGroup, FormControl, ControlLabel} from 'react-bootstrap';
import Select from "react-select";


class Groups extends Component{

    constructor(props, context){
        super(props);
        this.dataApi = context.api.groups;

        this.state = {editGroup:undefined};

        this.handleEmployeeInputChange = this.handleEmployeeInputChange.bind(this);
        this.handleEmployeeChange = this.handleEmployeeChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleGroupSave = this.handleGroupSave.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
    }

    componentDidMount(){
        this.dataApi.get();
    }


    tableStructure = [{
        Header: 'Nombre',
        accessor: 'name'
    }, {
        Header: 'Responsable',
        accessor: ( group )=>( group.employeeOwner? (group.employeeOwner.name+" "+group.employeeOwner.patLastName+" "+group.employeeOwner.matLastName):""),
        id: 'responsable'
    }];

    employeeLoadingId = "@Class.GroupsCatalogue.getEmployee";
    handleEmployeeInputChange( value ){


        if( !value || this.state.employeeInput === value)
            return;
        this.setState( {employeeInput:value} );
        this.searchEmployeesWithName(value);
    }

    searchEmployeesWithName( name ){
        this.context.api.employees.get(
            0,
            10,
            this.employeeLoadingId,
            {"search":name, isHired: true, "sGroups": ['user_search']})
    }

    handleEmployeeChange( selection ){

        const group = Object.assign({},this.state.editGroup);
        group.employeeOwner = selection;

        this.setState({editGroup:group});
    }

    handleNameChange( e ){
        const group = Object.assign({},this.state.editGroup);
        group.name = e.target.value;
        this.setState({editGroup:group});

    }

    handleGroupSave(){

        if( this.state.creating )
            this.dataApi.create(this.state.editGroup);

        else
            this.dataApi.edit(this.state.editGroup.id, this.state.editGroup);


        this.setState({editGroup:undefined});
    }

    deleteGroup(){
        this.dataApi.delete(this.state.editGroup.id);
        this.setState({editGroup:undefined});
    }

    render(){

        return(
            <div>
                <Card
                    title="Administración de grupos"
                    content={
                        <div>
                            <ReactTable
                                data={this.props.groups}
                                columns = {this.tableStructure}
                                minRows = {3}
                                showPagination={false}
                                manual
                                getTdProps={(state, rowInfo)=>({onClick:()=>{
                                        const group = Object.assign({},rowInfo.original);
                                        if( group.employeeOwner )
                                            group.employeeOwner = group.employeeOwner.id;
                                        this.setState( {editGroup:group, creating:false} );

                                        if(rowInfo.original.employeeOwner)
                                            this.searchEmployeesWithName(rowInfo.original.employeeOwner.name);

                                    }})}
                                {...ReactTableTranslations}
                            />
                            <Button
                                onClick={()=>this.setState({editGroup:{name:"", employeeOwner:""}, creating:true})}
                                bsStyle="primary" >
                                Crear nuevo grupo
                            </Button>
                        </div>
                    }
                />

                {!this.state.editGroup?null:
                    <Modal
                        onHide={()=>this.setState({editGroup:undefined})}
                        show={true} >
                        <Modal.Header closeButton>
                            <Modal.Title>{this.state.creating? "Nuevo":"Editar"} grupo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <FormGroup>
                                <ControlLabel>
                                    Nombre del grupo:
                                </ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder="Nombre del grupo"
                                    value={this.state.editGroup.name}
                                    onChange={this.handleNameChange}

                                />
                            </FormGroup>

                            <FormGroup>
                                <ControlLabel>Responsable</ControlLabel>
                                    <Select
                                        name="employeeOwner"
                                        options={this.props.employees.map(
                                            (employee)=>({value:employee.id, label:employee.name+" "+employee.patLastName+" "+employee.matLastName})
                                        )}
                                        isLoading={!!this.props.loadingIds[this.employeeLoadingId]}
                                        onInputChange={this.handleEmployeeInputChange}
                                        onChange={this.handleEmployeeChange}
                                        value={this.state.editGroup.employeeOwner}
                                        placeholder={"Selecciona un responsable"}
                                        simpleValue

                                    />
                            </FormGroup>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button bsStyle="primary" onClick={this.handleGroupSave}>Guardar</Button>
                            {this.state.creating? null:
                            <Button bsStyle="danger" onClick={this.deleteGroup}>Eliminar</Button>}
                            <Button bsStyle="warning" onClick={()=>this.setState({editGroup:undefined})}>Cerrar</Button>
                        </Modal.Footer>
                    </Modal>
                }
            </div>
        );
    }
}

Groups.contextTypes = { api: PropTypes.object.isRequired };

function mapStateToProps( {api:{groups,employees}, loadingIds} ) {

    groups = groups||[];
    employees = employees||[];

    return { groups, employees, loadingIds };
}

export default connect(mapStateToProps)(Groups);
