import React, {useState, useContext, useEffect} from 'react';
import {connect} from 'react-redux';
import FeedItem from "./FeedItem";
import {ApiContext} from "../../../api/Api";
import {Link} from 'react-router-dom';

const RewardsFeed = ({recognitionMovements = []}) => {
    const api = useContext(ApiContext);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        api.recognitionMovements.get();
    }, [api]);
    const refresh = async () => {
        setLoading(true);
        try {
            await api.recognitionMovements.get();
        } catch (e) {
        }
        setLoading(false);
    };
    const feedItems = recognitionMovements;
    return (
        <div className='Rewards RewardsFeed'>
            <ul className='feed-top-menu'>
                <li>
                    <Link to='/rewards/history'>
                        Ver tu historial de medallas
                    </Link>
                </li>
            </ul>
            <div className='refresh-feed-container'>
                {!loading ?
                <button className='refresh-feed-button' onClick={refresh}>
                    <p>Nuevas Publicaciones</p>
                </button>:
                    <p>Cargando...</p>
                }
            </div>
            {
                feedItems.map((f, k) => <FeedItem key={k} {...f}/>)
            }
        </div>
    );
};

const mapStateToProps = ({api: {recognitionMovements}}) => ({recognitionMovements});

export default connect(mapStateToProps)(RewardsFeed);
