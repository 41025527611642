import React, {useContext, useEffect, useState, useCallback} from 'react';
import Card from '../../../components/Card/Card.jsx';
import {connect} from 'react-redux';
import {ApiContext} from "../../../api/Api";
import {Col, Panel, Row} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import useCallbackCreator from "../../../hooks/useCallbackCreator";
import {NotifierContext} from "../../../utils/Notifier";
import _ from 'lodash';
import {SecurityContext} from "../../../utils/SecurityManager";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import useBoolean from "../../../hooks/useBoolean";

const loadingId = "@WarehousesList.get";
const WarehousesList = ({warehouses, history}) => {

    const api = useContext(ApiContext);
    const notifier = useContext(NotifierContext);
    const security = useContext(SecurityContext);

    useEffect(() => {
        api.warehouses.get({loadingId, filters: {isActive: true}} );
    }, [api.warehouses]);

    const [editing, setEditing] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const [adding, startAdding, stopAdding] = useBoolean();

    const stopEditing = useCallback(() => setEditing(null), []);
    const stopDeleting = useCallback(() => setDeleting(null), []);

    const editCallback = useCallbackCreator((warehouseId) => {
        const warehouse = _.find(warehouses, w => w.id+'' === warehouseId+'');
        if (!warehouse) return notifier.error('No se encontró el almacén. Revísalo con el soporte técnico');
        setEditing(warehouse);
    }, [warehouses]);

    const deleteCallback = useCallbackCreator((warehouseId) => {
        const warehouse = _.find(warehouses, w => w.id+'' === warehouseId+'');
        if (!warehouse) return notifier.error('No se encontró el almacén. Revísalo con el soporte técnico');
        setDeleting(warehouse);
    }, [warehouses]);

    const editWarehouse = useCallback((name) => {
        setEditing(null);
        api.warehouses.edit({id: editing.id, warehouse: {name}});
    }, [api, editing]);

    const deleteWarehouse = useCallback(() => {
        setDeleting(null);
        api.warehouses.delete({id: deleting.id});
    }, [api, deleting]);

    const createWarehouse = useCallback((name) => {
        stopAdding();
        api.warehouses.create({warehouse: {name}});
    }, [api, stopAdding]);

    const goToInventory = useCallbackCreator((id) => history.push('/almazen/inventory/' + id), []);
    const goToInventoryMovements = useCallbackCreator((id) => history.push('/almazen/inventory-movements-history/' + id), []);
    const goToMovement = useCallbackCreator((idType) => {
        const [id, type] = idType.split('|');
        history.push(`/almazen/movements/${id}/${type}`);
    }, []);

    const goToShrinkage = useCallbackCreator((id) => {
        history.push(`/almazen/movements/${id}/shrinkage`);
    }, []);

    return (
        <Card
            title="Almacenes"
            className='WarehousesList'
            content={
                <div className='container-fluid'>
                    <Row>
                        <Col xs={12}>
                            {security.almazen.canEditWarehouses() ?
                                <CustomButton bsStyle='primary' className='pull-right' onClick={startAdding}>Nuevo
                                    almacén</CustomButton> : null
                            }
                        </Col>
                    </Row>
                    <Row>
                        {warehouses.map(({id, name}) =>
                            <Col xs={12} md={4} key={id}>
                                <Panel className="warehouse-panel">
                                    <h3>{name}</h3>


                                    {security.almazen.canListInventories() || security.almazen.canShowInventoryMode()?
                                        <>
                                            <button className='action-icon' onClick={goToInventory(id)}>
                                                <i className='fa fa-cubes'/><br/>
                                                <span>Inventario</span>
                                            </button>

                                            <button className='action-icon' onClick={goToInventoryMovements(id)}>
                                                <i className='fa fa-arrows-h'/><br/>
                                                <span>Historial</span>
                                            </button>
                                        </> : null}


                                    {security.almazen.canCreateInMovements() ?

                                        <button className='action-icon' onClick={goToMovement(`${id}|in`)}>
                                            <i className='fa fa-sign-in'/><br/>
                                            <span>Entrada</span>
                                        </button> : null}

                                    {security.almazen.canCreateOutMovements() ?

                                        <button className='action-icon' onClick={goToMovement(`${id}|out`)}>
                                            <i className='fa fa-sign-out'/><br/>
                                            <span>Salida</span>
                                        </button> : null}

                                    {security.almazen.canCreateShrinkages() &&
                                        <button className='action-icon' onClick={goToShrinkage(id)}>
                                            <i className='fa fa-trash-o'/><br/>
                                            <span>Mermas</span>
                                        </button>}

                                    {security.almazen.canEditWarehouses() ?
                                        <button className='edit-icon' onClick={editCallback(id)}>
                                            <i className='fa fa-pencil'/><br/>
                                        </button>
                                        : null}
                                    {security.almazen.canDeleteWarehouses() ?
                                        <button className='delete-icon' onClick={deleteCallback(id)}>
                                            <i className='fa fa-trash'/><br/>
                                        </button>
                                        : null}
                                </Panel>
                            </Col>
                        )}
                    </Row>
                    {adding ?
                        <SweetAlert
                            input
                            showCancel
                            cancelBtnText="Cancelar"
                            confirmBtnText="Agregar"
                            style={{display: 'block'}}
                            title={'Nuevo almacén'}
                            placeholder={'Nombre...'}
                            onConfirm={createWarehouse}
                            onCancel={stopAdding}
                            confirmBtnBsStyle="info"
                            cancelBtnBsStyle="danger"
                            required
                        />
                        : null}
                    {editing ?
                        <SweetAlert
                            input
                            showCancel
                            cancelBtnText="Cancelar"
                            confirmBtnText="Editar"
                            style={{display: 'block'}}
                            title={'Editar almacén'}
                            onConfirm={editWarehouse}
                            onCancel={stopEditing}
                            defaultValue={editing.name}
                            confirmBtnBsStyle="info"
                            cancelBtnBsStyle="danger"
                            required
                        />
                        : null}
                    {deleting ?
                        <SweetAlert
                            showCancel
                            cancelBtnText="Cancelar"
                            confirmBtnText="Eliminar"
                            style={{display: 'block'}}
                            title={'Eliminar el almacén ' + deleting.name}
                            onConfirm={deleteWarehouse}
                            onCancel={stopDeleting}
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="danger"
                            required
                        />
                        : null}
                </div>
            }
        />
    );
};

const mapStateToProps = ({api: {warehouses = []}}) => ({warehouses});

export default connect(mapStateToProps)(WarehousesList);
