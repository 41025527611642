import React, {useContext, useState, useMemo, useEffect} from 'react';
import Card from '../../../../components/Card/Card';
import {ApiContext} from "../../../../api/Api";
import {connect} from "react-redux";
import ReactEcharts from 'echarts-for-react';
import moment from "moment";
import _ from "lodash";
import echarts from "echarts/lib/echarts";


const defaultInterval = 3;
const monthsToShow = moment().diff( moment("2011-12-31"), 'months' );//On 2019-05-05 this is equal to 88, and are the months from now the the time the first employee was hired

const SeniortyStats = ( {employeesSeniorityReport} )=>{

    const api = useContext( ApiContext );
    const [ interval, setInterval ] = useState(defaultInterval);

    useEffect( ()=>{

        const intervals = [];

        for( let i=0; i<=monthsToShow; i+= interval ) {
            intervals.push([i, i + interval - 1]);
        }

        api.employees.seniorityReport( JSON.stringify( intervals ) );
    }, [interval, api] );

    const options = useMemo( ()=>{

        if( !employeesSeniorityReport )
            return {};

        const intervals = [];
        const counts = [];

        let total = 0;
        _.each( employeesSeniorityReport.seniority, ( count, inter )=>{
            const interParts = inter.split('_');
            intervals.push(
                interval === 1?
                    interParts[1]:
                    interParts[1] + ' - ' + interParts[2]
            );
            total += Number(count);
            counts.push( count );
        } );

        return ({
            backgroundColor:'#f7f7f7',

            xAxis: {
                name:'Meses',
                type: 'category',
                data: intervals,
            },
            yAxis: {
                name:'Personas',
                type: 'value'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params)=>{
                    const { name, value } = params[0];
                    const percent = ( Math.round(value/total * 10000 ) / 100 ) + ' %';
                    return name + ' meses <br/>' + value + ' personas (' + percent + ')';
                }
            },
            series: [{
                data: counts,
                type: 'bar',
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#83bff6'},
                                {offset: 0.5, color: '#188df0'},
                                {offset: 1, color: '#188df0'}
                            ]
                        )
                    },
                    emphasis: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#2378f7'},
                                {offset: 0.7, color: '#2378f7'},
                                {offset: 1, color: '#83bff6'}
                            ]
                        )
                    }
                }
            }]
        });
        // eslint-disable-next-line
    }, [employeesSeniorityReport] );

    return(
        <Card
            title='Reporte de antigüedad'
            content={
                <>
                    <select value={interval} onChange={ ({target})=>setInterval(Number(target.value)) } className='form-control'>
                        <option value={1}>Cada mes</option>
                        <option value={3}>Cada 3 meses</option>
                        <option value={4}>Cada 4 meses</option>
                        <option value={6}>Cada 6 meses</option>
                        <option value={12}>Cada 12 meses</option>
                    </select>
                    <ReactEcharts
                        option={ options }
                        style={{height:"480px"}}
                    />

                </>
            }
        />
    );


};

const mapStateToProps = ( { api: { employeesSeniorityReport } } )=>({ employeesSeniorityReport });

export default connect(mapStateToProps)(SeniortyStats);
