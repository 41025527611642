import React, {useCallback, useContext, useState } from 'react';
import './_WarehousePermissionsTable.scss';
import EntitySelect from "../../../../../components/EntitySelect/EntitySelect";
import {employeeFullName} from "../../../../../utils/modelUtils/alma/employeeUtils";
import moment from "moment";
import {warehouseConfigurationSGroups} from "../../../../../utils/modelUtils/almazen/warehouseUtils";
import useCallbackCreator from "../../../../../hooks/useCallbackCreator";
import _ from "lodash";
import {TideApiContext} from "../../../../../api/tideApiConfig";
import {NotifierContext} from "../../../../../utils/Notifier";

const filters = {isHired: true};
const WarehousePermissionsTable = ({warehouse: wh}) => {

    const api = useContext(TideApiContext);
    const notifier = useContext(NotifierContext);

    const [addingUser, setAddingUser] = useState();
    const handleAddUser = useCallback(()=>{
        if(!addingUser)
            return;

        const employeesIds = wh.employeeWarehouses.map( e=>e.employee.id );
        if(employeesIds.indexOf(addingUser.id) !== -1){
            notifier.info("Este usuario ya tiene permiso de ver el almacén");
            return;
        }
        const permissions = wh.employeeWarehouses.map( e=>e.id );
        permissions.push({employee:addingUser.id});
        api.warehouses.update({id:wh.id, params:{ employeeWarehouses: permissions, sGroups: warehouseConfigurationSGroups}});
        setAddingUser(null);
    },[api, wh, notifier, addingUser]);

    const handleRemoveUserPermission = useCallbackCreator((id)=>{
            const permissions = wh.employeeWarehouses.map( e=>e.id );
            _.pull(permissions, id);
            api.warehouses.update({id:wh.id, params:{ employeeWarehouses: permissions, sGroups: warehouseConfigurationSGroups}});
    },[wh]);


    return (
        <div className={"WarehousePermissionsTable"}>
            <div className='container-fluid p-header'>
                <div className='row'>
                    <div className='col-sm-6'>
                            <p className='perm-title'>Usuarios con permiso</p>
                    </div>
                    <div className='col-sm-6'>
                        <div className='select-cont'>
                            <EntitySelect
                                className='new-employee-select'
                                placeholder={'Agregar usuario a almacén'}
                                entity='employees'
                                getMethod='get2'
                                labelCreator={employeeFullName}
                                value={addingUser}
                                onChange={setAddingUser}
                                additionalFilters={filters}
                            />
                            <button
                                className='btn btn-info'
                                onClick={handleAddUser}
                                disabled={!addingUser}>
                                Agregar usuario
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <table className='table table-striped'>
                <thead>
                <tr>
                    <th>Nombre</th>
                    <th>Asignado por</th>
                    <th>Desde</th>
                    <th>Acción</th>
                </tr>
                </thead>
                <tbody>
                {wh.employeeWarehouses?.map(employeeWarehouse=>
                    <tr key={employeeWarehouse.id}>
                        <td>{employeeFullName(employeeWarehouse.employee)}</td>
                        <td>{employeeFullName(employeeWarehouse.createdBy.employee)}</td>
                        <td>{moment(employeeWarehouse.createdDate).format('DD/MM/YYYY')}</td>
                        <td><i className='fa fa-trash red-icon' onClick={handleRemoveUserPermission(employeeWarehouse.id)}/></td>
                    </tr>)}
                </tbody>
            </table>
            {!wh.employeeWarehouses?.length &&
            <p className='empty-msg'>Aún no se asigna ningún usuario a este almacén. Solo los administradores podrán verlo.</p>}
        </div>
    );
};

export default WarehousePermissionsTable;
