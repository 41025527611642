import React, {useContext, useState, useEffect} from 'react';
import {Table} from 'react-bootstrap';
import {ApiContext} from "../../../../api/Api";
import {TideApiContext} from "../../../../api/tideApiConfig";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import {purchaseRequisitionProcessSGroups} from "../../../../utils/modelUtils/jazz/purchaseRequisitionUtils";
import moment from 'moment';
import {connect} from "react-redux";
import {getTimeElapsedString} from "../../../../utils/TimeConverter";
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";
import _ from 'lodash';

const loadingId = '@PurchaseRequisitionApprovals.purchaseRequisitionApprovals.edit';

const PurchaseRequisitionApprovals = ( {purchaseRequisition, me, loadingIds} )=>{

    const pr = purchaseRequisition;
    const api = useContext( ApiContext );
    const tideApi = useContext( TideApiContext );
    const [budgetExceeded, setBudgetExceeded] = useState(false);
    const [jazzArea, setJazzArea] = useState();

    const onApprove = (approvalId) => {
        api.purchaseRequisitionApprovals.edit(approvalId, {status: 'approved', sGroups:purchaseRequisitionProcessSGroups}, loadingId)
            .then(() => api.purchaseRequisitions.get(0, 10, loadingId, {id:pr.id, sGroups:purchaseRequisitionProcessSGroups}));
    };

    const onDeny = (approvalId) => {
        api.purchaseRequisitionApprovals.edit(approvalId, {status: 'denied', sGroups:purchaseRequisitionProcessSGroups}, loadingId)
            .then(() => api.purchaseRequisitions.get(0, 10, loadingId, {id:pr.id, sGroups:purchaseRequisitionProcessSGroups}));
    };

    const canApprove = (approval)=>{
        const meIsCoApprover = _.find(approval.coApprovers, (coApprover) => ( coApprover.id === me.id));

        return ( (approval?.employee?.id === me?.employee?.id) || meIsCoApprover) && pr.status === 'quoted' && approval.status === 'pending';
    };

    useEffect(() => {
        if (purchaseRequisition.jazzArea) {
            tideApi.jazzAreas.get({id: purchaseRequisition.jazzArea.id+'', params: { sGroups: ["jazz_area_read_monthly_used_budget", "jazz_area_read"] }}).then((response) => {
                setJazzArea(response);
            })
        }
    }, [tideApi, purchaseRequisition]);

    useEffect(() => {
        if (jazzArea) {
            if (purchaseRequisition.total > (Number(jazzArea.budget) - (Number(jazzArea.monthlyUsedBudget)))) {
                setBudgetExceeded(true);
            } else {
                setBudgetExceeded(false);
            }
        }
    }, [jazzArea, purchaseRequisition]);

    return (
        pr.status !== 'requested' ?
            <div className='PurchaseRequisitionApprovals'>
                <h4 className='text-center'>Aprobaciones</h4>
                { budgetExceeded === true &&
                    <div className='bg-danger text-center' style={{padding: '1em 0', marginTop: '1em'}}>
                        <h5 className='text-uppercase' style={{margin: '0.5em 0'}}><strong>El presupuesto del área "{jazzArea?.name}" esté siendo excedido.</strong></h5>
                        <h6 className='m-0'>Cálculo hecho a partir del precio cotizado de cada producto.</h6>
                    </div>
                }
                <Table responsive>

                    <thead>
                    <tr>
                        <th className='text-center col-xs-1'>#</th>
                        <th>Persona</th>
                        <th className=''>Respuesta</th>
                        <th>Fecha de revisión</th>
                    </tr>
                    </thead>

                    <tbody>

                    {
                        pr.purchaseRequisitionApprovals.map((approval, i) =>
                            <tr className='product-row' key={approval.id}>
                                <td className='text-center'> {i + 1}</td>
                                <td>
                                    {employeeFullName(approval.employee)}
                                    {approval.status === 'pending' && approval.coApprovers.map((coApprover)=>(
                                        <div className='coApprover'>
                                            {coApprover.fullName}
                                        </div>
                                        )

                                    )}
                                </td>
                                <td>{canApprove(approval)?
                                    <>
                                        <CustomButton
                                            bsStyle='danger'
                                            onClick={() => onDeny(approval.id)}
                                            disabled={!!loadingIds[loadingId]}>
                                            Denegar
                                        </CustomButton>&nbsp;
                                        <CustomButton
                                            bsStyle='success'
                                            onClick={() => onApprove(approval.id)}
                                            disabled={!!loadingIds[loadingId]}>
                                            Aprobar
                                        </CustomButton>
                                    </>
                                    :
                                    (approval.status === 'pending' ?
                                        <span
                                            className='alert alert-warning'>Pendiente</span>
                                        : null)}

                                    {approval.status === 'waiting_others_approval' ?
                                        <span
                                            className='alert alert-warning'>Pendiente</span>
                                        : null}

                                    {approval.status === 'approved' ?
                                        <span
                                            className='alert alert-success'>{approval.isAutoApproved?'Aprobado por el sistema':'Aprobado'}</span>
                                        : null}
                                    {approval.status === 'denied' ?
                                        <span
                                            className='alert alert-danger'>Denegado</span>
                                        : null}

                                </td>
                                <td>
                                    {(approval.status === 'approved') || (approval.status === 'denied') ?
                                        <span>{moment(approval.updatedDate).format("DD/MM/YYYY HH:mm")} - Tiempo de respuesta {getTimeElapsedString( moment( i===0?approval.createdDate:pr.purchaseRequisitionApprovals[i-1].updatedDate), moment( approval.updatedDate ) )} </span>
                                        :
                                        <span> - </span>
                                    }
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
                <hr/>
            </div> : null
    );
};

const mapStateToProps = ({api:{me}, loadingIds})=>({me, loadingIds});

export default connect(mapStateToProps)( PurchaseRequisitionApprovals );
