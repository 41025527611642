import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import CustomButton from "../../../../elements/CustomButton/CustomButton";
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import moment from "moment";
import _ from 'lodash';
import moneyFormatter from "../../../../utils/moneyFormatter";
import useTideTable from "../../../../hooks/table/useTideTable";
import ReactTable from "react-table";
import {
    detailTableSGroups as closingSGroups,
    paymentMethodToIcon,
    currencyToIcon
} from '../../../../utils/modelUtils/orozco/orozcoCashClosingUtils';
import {
    detailTableSGroups as pickUpSGroups,
    xlsReportConfig,
    xlsReportSGroups
} from '../../../../utils/modelUtils/orozco/orozcoCashPickUpUtils';
import useBoolean from '../../../../hooks/useBoolean';
import {SecurityContext} from '../../../../utils/SecurityManager';
import {listSGroups} from '../../../../utils/modelUtils/orozco/orozcoPaymentUtils';

const loadingId = '@CashRegisterDetailModal.cashPickUp';

const CashRegisterDetailModal = ({cashRegister, onHide}) => {
    const security = useContext(SecurityContext);
    const api = useContext(ApiContext);
    const [pickingUp, startPickUp, stopPickUp] = useBoolean();

    const currentCash = useSelector(({api}) => api.orozcoCashRegisterCash || null);
    const currentToPickUp = useSelector(({api})=>api.orozcoCashRegisterToPickUp || []);
    const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);

    const closingRequestFilters = useMemo(() => ({
        orozcoCashRegister: cashRegister ? cashRegister.id : null,
        sGroups: closingSGroups,
        'orozcoCashPickUp[exists]': false,
    }), [cashRegister]);

    const cashClosingTable = useTideTable({
        entity: 'orozcoCashClosings',
        columns: closingTableStructure,
        requestFilters: closingRequestFilters
    });

    const pickUpCash = useCallback(()=>{
        const pickUp = {
            orozcoCashRegister: cashRegister.id
        };
        api.orozcoCashPickUps.create({
            params: {...pickUp, sGroups: pickUpSGroups},
            loadingId
        }).then(()=>{
            stopPickUp();
            api.orozcoCashRegisters.getCashToPickUp(cashRegister);
        });
    }, [api, cashRegister, stopPickUp]);

    const closingDetails = useMemo(()=>currentToPickUp.map((p)=>({
        ...p,
        paymentMethod: {
            id: p.orozcoPaymentMethod,
            name: p.orozcoPaymentMethodName,
        },
        totalAmount: parseInt(p.totalAmount),
        closingAmount: parseInt(p.totalAmount)
    })), [currentToPickUp]);
    const closingDetailsByPaymentMethod = useMemo(()=> _.values(_.groupBy(closingDetails, 'paymentMethod.id'))
        , [closingDetails]);


    useEffect(() => {
        api.orozcoCashRegisters.getCurrentCash(cashRegister);
        api.orozcoCashRegisters.getCashToPickUp(cashRegister);
    }, [api, cashRegister]);

    const cashByPaymentMethod = useMemo(() => {
        if (!currentCash)
            return;
        return _.map(
            _.values(_.groupBy(currentCash.details, 'paymentMethod.name')),
            (vs)=>_.map(vs, (v)=>({...v,totalAmount: v.closingAmount, orozcoPaymentMethodName: v.paymentMethod.name}))
        );
    }, [currentCash]);

    const currentToPickUpByPaymentMethod = useMemo(() => {
        if (!currentToPickUp)
            return;

        return _.values(_.groupBy(currentToPickUp, 'orozcoPaymentMethodName'));
    }, [currentToPickUp]);

    /*
    const pickupCashByPaymentMethod = useMemo(() => {
        if (!currentCash)
            return;
        return _.values(_.groupBy(currentCash.details, 'paymentMethod.name'));
    }, [currentCash]);
    */



    return (
        <>
            {pickingUp?
                <Modal className='CashRegisterDetailModal' bsSize='lg' show onHide={stopPickUp}>
                    <ModalHeader closeButton>Recolección en caja {cashRegister.name}</ModalHeader>
                    <ModalBody>
                        <h3>Recolección en caja {cashRegister.name}</h3>
                        <strong>Recolecta el dinero y cuéntalo muy bien. Esta acción no puede deshacerse.</strong>
                        <button disabled={loading} onClick={pickUpCash} className='btn btn-success pull-right'>Recolectar</button>
                        <button onClick={stopPickUp} className='btn btn-danger pull-right'>Cancelar</button>
                        <hr/>
                        <h3>Totales</h3>
                        {closingDetailsByPaymentMethod.length>0?
                            closingDetailsByPaymentMethod.map(ds=><ListClosingAmounts key={ds[0].paymentMethod.id} details={ds}/>):
                            <h3>No hay cierres por recolectar</h3>
                        }
                        <div className='closings-history'>
                            <h3>Cierres por recolectar</h3>
                            <ReactTable {...cashClosingTable.tableProps} minRows={10}/>
                        </div>
                    </ModalBody>
                </Modal>
                :
                <Modal bsSize='lg' show onHide={onHide} className="CashRegisterDetailModal">
                    <ModalHeader closeButton>
                        <ModalTitle>
                            Caja {cashRegister.name}
                        </ModalTitle>
                    </ModalHeader>

                    <ModalBody>
                        {currentCash && cashByPaymentMethod && cashByPaymentMethod.length > 0 ? (
                            <div>
                                <h3>Dinero actual
                                    <small>
                                        <strong>(del {moment(currentCash.startDate).format('DD-MM-YYYY HH:mm')} al {moment(currentCash.endDate).format('DD-MM-YYYY HH:mm')})</strong></small>
                                </h3>
                                <hr/>
                                {cashByPaymentMethod.map((details) => <ListClosingAmounts key={details[0].paymentMethod.id} details={details}/>)}
                            </div>) :(
                            <div>
                                <h3>La caja no cuenta con dinero</h3>
                            </div>
                        )}


                        <div className='closings-history'>
                            <div className='header'>
                                <h3>Cierres pendientes de recolección</h3>
                                {currentToPickUp && currentToPickUpByPaymentMethod && currentToPickUpByPaymentMethod.length > 0 ?(
                                    <div>
                                        <h3>Dinero actual por recojer</h3>
                                        <hr/>
                                        {currentToPickUpByPaymentMethod.map((details) => <ListClosingAmounts key={details[0].orozcoPaymentMethodName} details={details}/>)}
                                    </div>
                                ):(
                                    <div>
                                        <h3>La caja no cuenta con dinero de cierres</h3>
                                    </div>
                                )}
                                {(cashClosingTable.tableProps.data.length>0 && security.orozco.canPickUpCashRegisterCash())&&<button onClick={startPickUp} className='btn btn-primary'>Recolectar Pagos Pendientes</button>}
                                <a href={api.orozcoPayments.exportUrl({sGroups: listSGroups, 'orozcoCashRegister.name': cashRegister.name, 'exists[orozcoCashClosingDetail]': true, 'exists[orozcoCashClosingDetail.orozcoCashPickUpDetail]': false, pagination: false})}
                                   rel="noopener noreferrer"
                                   className='btn btn-success pull-right'>
                                    Exportar Pagos Pendientes de rec.
                                </a>
                                <div className='clearfix'></div>
                            </div>
                            <ReactTable
                                {...cashClosingTable.tableProps}
                                minRows={10}
                            />
                        </div>
                        {security.orozco.canListCashPickUps() && <ListPickUps cashRegister={cashRegister} />}
                    </ModalBody>

                    < ModalFooter>
                        < CustomButton bsStyle='danger' className='pull-left' onClick={onHide}>Cerrar</CustomButton>
                    </ModalFooter>
                </Modal>
            }
        </>
    );
};

const ListPickUps = ({cashRegister})=> {
    const api = useContext(ApiContext);
    const pickUpRequestFilters = useMemo(() => ({
        orozcoCashRegister: cashRegister ? cashRegister.id : null,
        sGroups: pickUpSGroups
    }), [cashRegister]);

    const cashPickUpTable = useTideTable({
        entity: 'orozcoCashPickUps',
        columns: pickUpTableStructure,
        requestFilters: pickUpRequestFilters
    });

    const exportPickUpsURL = useMemo(
        () => api.orozcoCashPickUps.exportUrl({
            params: {
                orozcoCashRegister: cashRegister ? cashRegister.id : null,
                sGroups: xlsReportSGroups
            },
            reportConfig: xlsReportConfig({cashRegister})
        }),
        [api, cashRegister]
    );

    return (
        <>
            <hr/>
            <div className='closings-history'>
                <div className='header'>
                    <h3>Recolecciones de caja</h3>
                </div>
                <ReactTable
                    {...cashPickUpTable.tableProps}
                    minRows={10}
                />
            </div>
            <a href={exportPickUpsURL} style={{marginTop: 10 + 'px'}} className='btn btn-success'>Exportar a Excel</a>
        </>
    );
};

const ListClosingAmounts = ({details}) => (
    <div className='current-money'>
        <h5 className={'payment-method'}>
            <i className={paymentMethodToIcon(details[0].orozcoPaymentMethodName)}/> {details[0].orozcoPaymentMethodName}
        </h5>
        <div className={"currencies row"}>
            {details.map((detail) => (
                <div key={detail.orozcoPaymentMethodName + detail.currency}
                     className="currency-detail col-md-4">
                    {currencyToIcon(detail.currency)}
                    <span className="total">{moneyFormatter(detail.totalAmount, 100)}</span>
                </div>
            ))}
        </div>
        <hr/>
    </div>
);


const closingTableStructure = [
    {
        Header: 'Cerrado por',
        id: 'createdBy',
        accessor: (cashClosing) => cashClosing.createdBy ? (cashClosing.createdBy.employee?cashClosing.createdBy.employee.fullName : ""):'',
        filterable: true
    },
    {
        Header: 'Fecha de inicio',
        id: 'startDate',
        accessor: (cashClosing) => moment(cashClosing.startDate).format('DD/MM/YYYY HH:mm'),
        filterable: false
    },
    {
        Header: 'Fecha de cierre',
        id: 'endDate',
        accessor: (cashClosing) => moment(cashClosing.endDate).format('DD/MM/YYYY HH:mm'),
        filterable: false
    },
    {
        Header: 'Turno',
        accessor: 'shift',
        filterable: true
    },
    {
        Header: 'Total Huéspedes',
        accessor: 'checkInsCount',
        filterable: false
    },
    {
        Header: 'Total personas atendidas',
        accessor: 'visitOccupancy',
        filterable: false
    },
    {
        Header: 'Cantidad',
        id: 'money',
        accessor: (cashClosing) => (<div>
                    {cashClosing.orozcoCashClosingDetails.map((detail) => (
                        <div key={detail.id}
                             className="table-currency-detail">
                            <i className={paymentMethodToIcon(detail.orozcoPaymentMethod.name)}/>
                            {currencyToIcon(detail.currency)}
                            <span className="total">{moneyFormatter(detail.closingAmount, 100)}</span>
                        </div>
                    ))}
                </div>
            )

        ,
        filterable: false
    }
];

const pickUpTableStructure = [
    {
        Header: 'Recolectado por',
        id: 'createdBy.employee.name',
        accessor: (cashPickUp) => cashPickUp.createdBy ? cashPickUp.createdBy.employee.fullName : ""
    },
    {
        Header: 'Fecha de recolección',
        id: 'createdDate',
        accessor: x => moment(x.createdDate).format('DD/MM/YYYY HH:mm'),
        filterable: false
    },
    {
        Header: 'Total Huéspedes',
        accessor: 'checkInsCount',
        filterable: false
    },
    {
        Header: 'Total personas atendidas',
        accessor: 'visitOccupancy',
        filterable: false
    },
    {
        Header: 'Cantidad',
        id: 'money',
        accessor: (cashPickUp) => (
            <div>
                {cashPickUp.orozcoCashPickUpDetails.map((detail) => (
                    <div key={detail.id}
                         className="table-currency-detail">
                        <i className={paymentMethodToIcon(detail.orozcoPaymentMethod.name)}/>
                        {currencyToIcon(detail.currency)}
                        <span className="total">{moneyFormatter(detail.totalAmount, 100)}</span>
                    </div>
                ))}
            </div>
        )
        ,
        filterable: false
    }
];

export default CashRegisterDetailModal;
