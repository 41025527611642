import React, {useContext} from 'react';
import useBoolean from "../../../../hooks/useBoolean";
import classNames from 'classnames';
import {TideApiContext} from "../../../../api/tideApiConfig";
import {querySerialize} from "../../../../api/Api";

const OrozcoProductTotalCostReport = () => {

    const api = useContext(TideApiContext);
    const [isOpen, , , toggleOpen] = useBoolean();

    const query = querySerialize({
	token: api.token,
    });

    const reportUrl = `${api.host}/api/inventories/line-cost-report?${query}`;

    return (
        <div className={classNames("report-block", isOpen && 'open')}>
            <p className='title' onClick={toggleOpen}>Costo por categoría
            <i className='fa fa-caret-down caret-icon'/>
            </p>
            {isOpen &&
            <div className='body'>
                <hr/>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <p className='filter-label'>Descripción:</p>
                            <p className='description'>Lista todas las categorías de Jazz registradas, el gasto hasta
                                en el mes al día de hoy de cada una, el máximo de gasto registrado para cada una y el porcentaje utilizado.</p>
                        </div>
                    </div>
                </div>
                <br/>
                <a href={reportUrl} rel='noopener noreferrer'>
                    <button className='btn btn-info'>Descargar</button>
                </a>
            </div>}
        </div>
    );
}

export default OrozcoProductTotalCostReport;
