import React, {useContext, useMemo, useState, useCallback} from "react";
import ReactTable from "react-table";
import {golfCartTripsTableConfig,
    golfCartTripSGroups,
    golfCartTripSGroupsReport,
    reportConfigDownload} from "../../../../../utils/modelUtils/ee/golfCartTripUtils";
import useTideTable from "../../../../../hooks/table/useTideTable";
import GolfCartTripModalData from "../GolfCartTripModalData/GolfCartTripModalData";
import {TideApiContext} from "../../../../../api/tideApiConfig";
import moment from "moment";
import {SecurityContext} from "../../../../../utils/SecurityManager";
import './_GolfCartTripList.scss'

const GolfCartTripList = () => {
    const tideApi = useContext(TideApiContext);
    const [showModal, setShowModal] = useState(false);
    const [tripData, setTripData] = useState(null);
    const security = useContext(SecurityContext);
    const [showFilters, setShowFilters] = useState();
    const [filterValue, setFilterValue] = useState();

    const requestFilters = useMemo(() => {
        let filters = {};

        switch (filterValue) {
            case 'pending':
                filters = {
                    sGroups: golfCartTripSGroups,
                    'exists[realStartDate]': false,
                    'exists[realEndDate]': false
                };
                break;

            case 'in_progress':
                filters = {
                    sGroups: golfCartTripSGroups,
                    'exists[realStartDate]': true,
                    'exists[realEndDate]': false
                };
                break;
    
            case 'ended':
                filters = {
                    sGroups: golfCartTripSGroups,
                    'exists[realStartDate]': true,
                    'exists[realEndDate]': true
                };
                break;

            default:
                filters = {sGroups: golfCartTripSGroups};
                break;
        }

        return filters;
    }, [filterValue]);

    const guestsTable = useTideTable({
        entity: 'golfCartTrips',
        columns: golfCartTripsTableConfig,
        newTideApi: true,
        requestFilters: requestFilters
    });

    const downloadReport = useMemo(
        () => tideApi.golfCartTrips.downloadTripsReport({
            params: {token: window.localStorage.googlead, sGroups: golfCartTripSGroupsReport, reportConfig: JSON.stringify(reportConfigDownload)}
        }), [tideApi]);

    const getTrProps = (state, rowInfo) => ({
        onClick: () => {
            setShowModal(true);
            setTripData(rowInfo.original);
        },
        style: rowInfo?.original?.realEndDate ? moment(rowInfo?.original?.realEndDate).isSameOrBefore(rowInfo?.original?.scheduledEndDate, 'minute') ?
            {cursor: 'pointer', backgroundColor: 'none'} :
            {cursor: 'pointer', backgroundColor: '#ec9292'}
            : {cursor: 'pointer', backgroundColor: 'none'}
    });

    const handleCloseModal = () => {
        setShowModal(false);
        setTripData(null);
    };

    const changeTripsFilter = useCallback((filterStatusValue) => {
        setFilterValue(filterStatusValue);
        },[],
    );

    return (
        <div className={"GolfCartTripList main-container container-fluid"}>
            <div className="row">
                <div className={'col-xs-12 ' + (showFilters ? 'configuration' : '')}>
                    <h5><span className='link' onClick={()=>setShowFilters(!showFilters)}><i className='fa fa-cogs'/> Filtros</span>
                    </h5>
                    {showFilters ?
                        <div className='row'>
                            <div className='col-md-4 filter-wrapper'>
                                <div className='filter-block'>
                                    <select onChange={(e)=>changeTripsFilter( e.target?.value )} className='form-control form-selectors'>
                                        <option>Todos</option>
                                        <option value="pending">Pendientes</option>
                                        <option value="in_progress">En proceso</option>
                                        <option value="ended">Terminados</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </div>

            <ReactTable
                {...guestsTable.tableProps}
                getTrProps={getTrProps}
            />
            {security.ee.canSeeGolfCartTripShowStatistics() &&
            <div className='pull-right'>
                <a className='btn btn-default' href={downloadReport} rel='noopener noreferrer'>
                    Exportar a Excel
                </a>
            </div>
            }
            {tripData && <GolfCartTripModalData showModal={showModal} tripData={tripData} onClose={handleCloseModal}/>}
        </div>
    );
}

export default GolfCartTripList;
