import Dashboard from '../../views/alma/Dashboard/EmployeeDash';

import EmployeeHistory from "../../views/alma/Employees/core/EmployeeHistory";
import EmployeeJustifications from "../../views/alma/Employees/payrollManagement/EmployeeJustifications";
import EmployeeIncidenceRequests from '../../views/alma/Employees/payrollManagement/EmployeeIncidenceRequests';
import EmployeeIncidences from "../../views/alma/Employees/payrollManagement/EmployeeIncidences";
//import EmployeePayrolls from "../../views/alma/Employees/payrollManagement/EmployeePayrolls";
import EmployeeWorkTools from "../../views/alma/Employees/workTools/EmployeeWorkTools";
import FileRepository from "../../views/alma/FileRepository/FileRepository";
import NotificationsList from "../../views/alma/Employees/NotificationsList";
import NotificationsConfiguration from "../../views/alma/Employees/NotificationsConfiguration";
import Alma from "../../views/alma/Alma";

const dashRoutes = [

    { path: "/alma", name: "ALMA 2.0", component: Alma, hidden: true },

    { path: "/alma/dashboard", name: "Dashboard", icon: "pe-7s-graph", component: Dashboard },
    { path: "/alma/file-repository/:folder?/:openFile?",smallName: "DID TGLE", name: "DID TGLE", icon: "fa fa-archive", component: FileRepository, exact: false},

    { path: "/alma/employee/history", name: "Mis asistencias", icon: "fa fa-clock-o", component: EmployeeHistory, exact: false },
    //{ path: "/alma/employee/payroll", name: "Mis pagos", icon: "fa fa-money", component: EmployeePayrolls, exact: false },
    { path: "/alma/employee/justifications", name: "Mis Justificantes", icon: "fa fa-file-text-o", component: EmployeeJustifications, exact: false },
    { path: "/alma/employee/requests", name: "Mis Solicitudes", icon: "fa fa-comments-o", component: EmployeeIncidenceRequests, exact: false },
    { path: "/alma/employee/incidences", name: "Mis Incidencias", icon: "fa fa-exclamation-circle", component: EmployeeIncidences, exact: false },

    { path: "/alma/employee/worktools/:id", name: "Equipo de trabajo", component: EmployeeWorkTools, hidden: true },
    { path: "/alma/employee/worktools", name: "Equipo de trabajo", component: EmployeeWorkTools, hidden: true },

    { path: "/alma/notifications", name: "Notificaciones", component: NotificationsList, hidden:true, },
    { path: "/alma/notifications-configuration", name: "Configuración de notificaciones", component: NotificationsConfiguration, hidden:true, },


];
export default dashRoutes;
