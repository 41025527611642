import React, {useState, useContext, useEffect, useMemo} from 'react';
import './Nps.scss';
import DateRangeFilter, {useDateRangeFilter} from "../../../DavinciReports/Filters/DateRangeFilter";
import ReactEcharts from "echarts-for-react";
import {ReactComponent as HappyFace} from "../../../../../assets/img/happyFace.svg";
import {ReactComponent as RegularFace} from "../../../../../assets/img/regularFace.svg";
import {ReactComponent as SadFace} from "../../../../../assets/img/sadFace.svg";
import CustomCheckbox from '../../../../../elements/CustomCheckbox/CustomCheckbox';
import {ApiContext} from '../../../../../api/Api';
import {useSelector} from 'react-redux';
import {getGcareQuestionType, getGaugeOption} from '../../utils';
import {percentageString as mPercentageString} from '../../../../../utils/MathUtils';
import _ from 'lodash/fp';

const normalized = total=>v=>total===0?0:v/total;
const percentage = total=>v=>100*normalized(total)(v);
const percentageString = total=>v=>percentage(total)(v).toFixed(2);

const getNpsTimeOption = (npsInRange)=>({
    xAxis: {
        type: 'category',
        data: npsInRange.map(({date})=>date)
    },
    yAxis: {
        type: 'value'
    },
    series: [{
        data: npsInRange.map(({averageNps})=>averageNps),
        type: 'line'
    }]
});

const getNpsValues = _.flow(
    _.keyBy('npsCategory'),
    _.mapValues(({count})=>parseInt(count))
);

const Nps = ({defaultDateRange}) => {
    const dateConfig = useMemo(()=>({initial: defaultDateRange}), [defaultDateRange]);
    const [shift, setShift] = useState(null);
    const [lounge, setLounge] = useState(null);
    const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);
    const api = useContext(ApiContext);


    useEffect(()=>{
        api.answers.gcareNpsReport({params: {
            ...dateRequest,
            ...(shift?{shifts: [shift]}:{}),
            ...(lounge?{lounges: [lounge]}:{}),
        }});
        api.answers.gcareNpsAverageInRange({params: {
            ...dateRequest,
            ...(shift?{shifts: [shift]}:{}),
            ...(lounge?{lounges: [lounge]}:{}),
        }});
    }, [api, dateRequest, shift, lounge]);

    useEffect(()=>{
        api.questionnaireReport.get({
            isGcare: true
        });
    }, [api]);

    const nps = useSelector(
        ({api})=> api.gcareNpsReport?
            getNpsValues(api.gcareNpsReport):null);
    const npsInRange = useSelector(({api})=>api.gcareNpsAverageInRange||[]);
    const questionnaireReport = useSelector(({api})=>api.questionnaireReport||{});
    const shiftQuestion = getGcareQuestionType('shift', questionnaireReport);
    const loungeQuestion = getGcareQuestionType('lounge', questionnaireReport);
    const shifts = _.map('answer', shiftQuestion?shiftQuestion.stats:[]);
    const lounges = _.map('answer', loungeQuestion?loungeQuestion.stats:[]);

    const npsTimeOption = useMemo(()=>getNpsTimeOption(npsInRange),[npsInRange]);

    const detractorNps = useMemo(()=>nps?nps.detractor||0:0, [nps]);
    const promotorNps = useMemo(()=>nps?nps.promotor||0:0, [nps]);
    const passiveNps = useMemo(()=>nps?nps.passive||0:0, [nps]);

    const gaugeOption = useMemo(()=>{
        if(!detractorNps && !passiveNps && !promotorNps){
            return null;
        }

        return getGaugeOption(detractorNps, passiveNps, promotorNps);
    } , [detractorNps, promotorNps, passiveNps]);
    const gaugeKey = useMemo(Math.random, [detractorNps, promotorNps, passiveNps]);
    const npsTotal = _.sum(_.values(nps));

    const npsPercentageString = useMemo(()=>percentageString(npsTotal), [npsTotal]);

    return (
        <div className="Nps">
            <DateRangeFilter {...dateFilterProps}/>
            <div className='row'>
                <div className='col-md-3'>
                    <label>Salas: </label>
                </div>
                <div className='col-md-8 pull-right'>
                    {lounges.map(v=>
                                <CustomCheckbox key={v} label={v} inline isChecked={v===lounge} onChange={()=>lounge===v?setLounge(null):setLounge(v)}/>)}
                </div>
            </div>
            <div className='row'>
                <div className='col-md-3'>
                    <label>Turno: </label>
                </div>
                <div className='col-md-8 pull-right'>
                    {shifts.map(v=>
                                <CustomCheckbox key={v} label={v} inline isChecked={v===shift} onChange={()=>shift===v?setShift(null):setShift(v)}/>)}
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='row-gutter row '>
                        <div className='col-md-6 '>
                            <div className='face sad'>
                                <SadFace/>
                            </div>
                        </div>
                        <div className='detractors col-md-6'>
                            <p>Detractores<span> {npsPercentageString(
                                detractorNps
                            )}%</span></p>

                            <p>Escala<span> 0 - 6</span></p>
                        </div>
                    </div>
                    <div className='row-gutter row'>
                        <div className='col-md-6'>
                            <div className='face regular'>
                                <RegularFace/>
                            </div>
                        </div>
                            <div className='pasive col-md-6'>
                                <p>Pasivos<span> {npsPercentageString(
                                    passiveNps
                                )}%</span></p>
                                <p>Escala<span> 7 - 8</span></p>
                            </div>
                    </div>
                    <div className='row-gutter row'>
                        <div className='col-md-6'>
                            <div className='face happy'>
                                <HappyFace/>
                            </div>
                        </div>
                            <div className='promoter col-md-6'>
                                <p>Promotores<span> {npsPercentageString(
                                    promotorNps
                                )}%</span></p>
                                <p>Escala<span> 9 - 10</span></p>
                            </div>

                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3 className='text-center'>NPS: {mPercentageString(promotorNps/npsTotal)} - {mPercentageString(detractorNps/npsTotal)} = {mPercentageString(promotorNps/npsTotal-detractorNps/npsTotal)}</h3>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
            {gaugeOption&&<ReactEcharts key={gaugeKey} option={gaugeOption}/>}
            {npsInRange.length>1&&<ReactEcharts option={npsTimeOption}/>}
        </div>
    );
};
export default Nps;
