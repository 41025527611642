import React, {useContext} from 'react';
import {ApiContext} from '../../../api/Api';
import CategoryModal from './CategoryModal';

const addOrozcoProductCategory = api => properties => {
    api.orozcoProductCategories.create({
        properties: {
            ...properties,
            parentCategory: properties.parentCategory || undefined
        }
    });
};

const AddCategoryModal = ({onHide}) => {
    const api = useContext(ApiContext);
    return (
        <CategoryModal
            onHide={onHide}
            onSubmit={addOrozcoProductCategory(api)}
            submitText='Agregar categoría'
            title='Agregar categoría'
        />
    );
};

export default AddCategoryModal;
