import React, {useState,useRef,useCallback,useEffect} from 'react';
import classNames from 'classnames';

const CustomCheckbox =({isChecked, onChange, label, inline, input={}})=>{

    const [checked, setChecked]=useState(isChecked);
    const number=useRef(Math.random());

    const handleClick=useCallback(()=>{
        if(onChange)
            onChange(!checked);
        setChecked(!checked);
    },[checked, onChange]);

    useEffect(()=>{
        setChecked(isChecked);
    },[isChecked]);

    const classes = classNames('checkbox', inline && "checkbox-inline");

    return (
        <div className={classes}>
            <input id={number.current} type="checkbox" onChange={handleClick} checked={typeof isChecked==='undefined'?checked:isChecked} {...input}/>
            <label htmlFor={number.current}>{label}</label>
        </div>
    );
};

export default CustomCheckbox;
