import React, {useState, useContext} from 'react';
import {Modal, ModalTitle, ModalBody, ModalHeader, FormControl, FormGroup, ControlLabel, Button} from "react-bootstrap";
import {ApiContext} from "../../../api/Api";
import DropFile from "../utils/DropFile";

const EditProductModal = ({product, onHide}) => {
    const {name, image, pointsCost, description} = product;
    const [editedName, setEditedName] = useState(name);
    const [editedImage, setEditedImage] = useState(null);
    const [editedPointsCost, setEditedPointsCost] = useState(pointsCost);
    const [editedDescription, setEditedDescription] = useState(description);
    const api = useContext(ApiContext);

    const onDrop = (files) => {
        if (files[0]) {
            setEditedImage(files[0]);
        }
    };
    const editProduct = () => {
        api.rewards.edit({
            id: product.id,
            params: {
                name: editedName,
                pointsCost: editedPointsCost,
                description: editedDescription
            },
            ...(editedImage ? {files: {image: editedImage}} : {})
        });
        onHide();
    };
    return (
        <Modal onHide={onHide} show>
            <div className='RewardsModal'>
                <ModalHeader closeButton>
                    <ModalTitle>Edición de {name}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <ControlLabel>Nombre: </ControlLabel>
                        <FormControl type='text' value={editedName} onChange={e => setEditedName(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Costo en puntos: </ControlLabel>
                        <FormControl min={1} type="number" value={editedPointsCost}
                                     onChange={e => setEditedPointsCost(parseInt(e.target.value))}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Descripción: </ControlLabel>
                        <FormControl componentClass="textarea" value={editedDescription}
                                     onChange={e => setEditedDescription(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>Imagen:</ControlLabel>
                        {image ? <img src={api.appFiles.getUrl(image.id)} alt='Imagen a editar'/> :
                            <p>No hay imagen para este producto</p>}
                        <DropFile onDrop={onDrop}
                                  text={editedImage ? editedImage.name : 'Da click aquí o arrastra la imagen'}
                                  textStyle={image ? {color: '#000000'} : {}}
                        />
                    </FormGroup>
                    <Button bsStyle='success' onClick={editProduct}>
                        Guardar edición de producto
                    </Button>
                </ModalBody>
            </div>
        </Modal>
    );
};
export default EditProductModal;
