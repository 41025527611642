import React, {useCallback} from 'react';
import "./_DayOfTheWeekSelector.scss";
import classNames from 'classnames';
import {dayOfTheWeekNotation, dayOfTheWeekNotationConvert} from "../../utils/timeUtils";
import _ from 'lodash';

/**
 * Monday is 0 - Sunday is 6
 * @param values
 * @param onChange
 * @param notation dayOfTheWeekNotation from timeUtils
 * @returns {JSX.Element}
 * @constructor
 */
const DayOfTheWeekSelector = ({ values, onChange, notation= dayOfTheWeekNotation.MONDAY_ZERO }) => {

    const isDayActive = ( day )=>{
        const mondayZeroValues = _.map(values||[], val=>dayOfTheWeekNotationConvert( notation, dayOfTheWeekNotation.MONDAY_ZERO, val ) );
        return mondayZeroValues.indexOf( day ) !== -1;
    }

    const onDaySelect = useCallback((e)=>{
        const day = Number(e.target.dataset.day);
        const value = dayOfTheWeekNotationConvert( dayOfTheWeekNotation.MONDAY_ZERO, notation, day );
        const numValues = values?.map(val=>Number(val))||[];
        const index = numValues.indexOf( value );
        if(index === -1 ){
            onChange( [...values, value] );
        }
        else{
            const newValues = [...values];
            newValues.splice(index, 1);
            onChange(newValues);
        }

    },[onChange, values, notation]);

    return (
        <div className={"DayOfTheWeekSelector"}>
            <button data-day="0" className={classNames("day-but", isDayActive(0) && "active")} onClick={onDaySelect}>L</button>
            <button data-day="1" className={classNames("day-but", isDayActive(1) && "active")} onClick={onDaySelect}>M</button>
            <button data-day="2" className={classNames("day-but", isDayActive(2) && "active")} onClick={onDaySelect}>M</button>
            <button data-day="3" className={classNames("day-but", isDayActive(3) && "active")} onClick={onDaySelect}>J</button>
            <button data-day="4" className={classNames("day-but", isDayActive(4) && "active")} onClick={onDaySelect}>V</button>
            <button data-day="5" className={classNames("day-but", isDayActive(5) && "active")} onClick={onDaySelect}>S</button>
            <button data-day="6" className={classNames("day-but", isDayActive(6) && "active")} onClick={onDaySelect}>D</button>
        </div>
    );
};

export default DayOfTheWeekSelector;
