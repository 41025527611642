
import React, { useContext, useState, useCallback, useMemo } from 'react';
import {SecurityContext} from "../../../utils/SecurityManager";
import useDateRangePickerState from "../../../hooks/useDateRangePickerState";
import moment from "moment";
import ClassicModal from "../../../components/Modals/ClassicModal";
import { EeGcareByHourReport } from './reports/EeGcareByHourReport';
import {ApiContext} from "../../../api/Api";
import { ReportBlock } from './components/ReportBlock';
import './components/ReportsConatiner.scss';

const EeReportsGcare = () => {
    const security=useContext(SecurityContext);
    const [showModal, setShowModal]=useState(null);
    const hideReport=useCallback(()=>setShowModal(null),[]);
    const api = useContext(ApiContext);

    const {props: dateRangeProps, dateRange} = useDateRangePickerState({
        startDate: moment().startOf('month').startOf('day'),
        endDate: moment().add(1, 'day').endOf('day')
    });

    const excelGcareByHourURL = useMemo(
        ()=>api.gcareByHourReport.gcareByHourReportURL({
            params:{
                from: dateRange?.startDate?.format('YYYY-MM-DD'),
                to: dateRange?.endDate?.format('YYYY-MM-DD'),
                excelReport: true
            }
        }),
        [api, dateRange]
    );

    return (
        <div className={"EeReportsGcare ReportsConatiner"}>
            <h2 className='text-center'>Reportes de encuesta GCare</h2>

            <div className='card container-fluid'>
                <div className='row'>
                    <div className="col-12 col-sm-6">
                        {security.ee.canSeeGCareReports() &&
                            <ReportBlock 
                                reportTitle="Encuesta GCare por hora" 
                                urlReportXLS={excelGcareByHourURL}
                                showDownload={true}
                                modalProps={{
                                    onHide: {hideReport},
                                    title: "Hola mundo",
                                    showConfirm: false,
                                    bsSize: 'lg'
                                }}
                                dateRangeProps={dateRangeProps}
                                setShowModal={setShowModal}
                            >
                                <EeGcareByHourReport dateRange={dateRange} />
                            </ReportBlock>}
                    </div>
                </div>
                <br/>
            </div>

            {showModal && security.ee.canSeeGCareReports() &&
                <ClassicModal
                    onHide={hideReport}
                    title="Encuesta de Gcare por hora"
                    showConfirm={false}
                    bsSize='lg'
                >
                    <EeGcareByHourReport dateRange={dateRange} />
                </ClassicModal>}
        </div>
    );
};

export default EeReportsGcare;