import React, {useContext, useEffect, useMemo, useState} from 'react';
import {ApiContext} from "../../../../api/Api";
import {connect} from "react-redux";
import ReactEcharts from 'echarts-for-react';
import _ from 'lodash';


const GenerationsGraph = ( {employeesGenerationsReport} )=>{


    const api = useContext( ApiContext );

    const [ onlyHired, setOnlyHired ] = useState( 'true' );


    useEffect( ()=>{
        api.employees.generationsReport( onlyHired );
    }, [ api, onlyHired ] );

    const options = useMemo( ()=>{

        if( !employeesGenerationsReport )
            return {};

        const data = {};

        _.forEach( employeesGenerationsReport.generationsReport , report=>{

            data[ report.sex ] = { labels:[], counts:[] };
            _.forEach(report, (count, generation)=>{

                if( generation === 'sex' ) return;
                data[report.sex].labels.push(generation);
                data[report.sex].counts.push(count);
            } )

        }  );

        return ({
            backgroundColor:'#f7f7f7',

            tooltip : {
                trigger: 'axis',
                axisPointer : {
                    type : 'shadow'
                }
            },
            legend: {
                data: ['Hombres', 'Mujeres' ]
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis:  {
                type: 'value'
            },
            yAxis: {
                type: 'category',
                data: data['M']? data['M'].labels: (data['F']? data['F'].labels: [])
            },
            series: [
                {
                    name: 'Hombres',
                    type: 'bar',
                    stack: 'main',
                    label: {
                        normal: {
                            show: true,
                            position: 'insideRight'
                        }
                    },
                    data: data['M']? data['M'].counts : [],
                    itemStyle: {
                        normal: {
                            color: "#2f52b8"
                        },
                    }
                },
                {
                    name: 'Mujeres',
                    type: 'bar',
                    stack: 'main',
                    label: {
                        normal: {
                            show: true,
                            position: 'insideRight'
                        }
                    },
                    data: data['F']? data['F'].counts : [],
                    itemStyle: {
                        normal: {
                            color: "#d88bd8"
                        },
                    }
                },
            ]
        });
    }, [employeesGenerationsReport] );


    return(
        <>
            <h5>Generaciones</h5>

            <select value={onlyHired} onChange={({target})=>setOnlyHired(target.value)} className='form-control'>
                <option value='true'>Empleados actualmente contratados</option>
                <option value='null'>Histórico ( incluir empleados despedidos )</option>
            </select>
            <br/>
            <ReactEcharts
                option={ options }
                style={{height:"460px"}}
            />
            <p><br/>
                <strong>1997 - 2010</strong> Generación Z<br/>
                <strong>1981 - 1996</strong> Millenials<br/>
                <strong>1965 - 1980</strong> Generación X<br/>
                <strong>1946 - 1964</strong> Baby Boomers<br/>
                <strong>1930 - 1945</strong> Silent Generation<br/>
            </p>
        </>
    );

};

const mapStateToProps = ( { api: { employeesGenerationsReport } } )=>({ employeesGenerationsReport });

export default connect(mapStateToProps)(GenerationsGraph);
