import moment from "moment";
import {displayAmount} from "./unitsUtility";
import ReactTableDateRangeFilter from "../../../components/Helper/ReactTableDateRangeFilter";

export const shrinkageTypes = {
    SURPRISE:'surprise',
    EXPECTED:'expected'
};

export const shrinkageTypeStrings = {
    [shrinkageTypes.SURPRISE]:"Manual",
    [shrinkageTypes.EXPECTED]:'Automática'
};

export const shrinkageListSGroups = [
    "shrinkage_read_type",
    "shrinkage_read_stock_movement",
        "stock_movement_read_quantity",
        "stock_movement_read_product_brand",
            "product_brand_read_product",
                "product_read_name",
                "product_read_id",
                'product_read_name',
                'product_read_smallest_unit',
                'product_read_display_unit',
                'measurement_unit_read_name',
            "product_brand_read_brand",
                "brand_read_name",
        "stock_movement_read_batch",
            "stock_movement_batch_read_warehouse",
                "place_read_name",
    "shrinkage_read_created_by",
        "user_read_employee",
            "employee_read_name",
            "employee_read_full_name",
];

export const shrinkageListColumns = [
    {
        Header:"Producto - Marca",
        accessor: shrinkage=>`${shrinkage?.stockMovement?.productBrand?.product?.name} - ${shrinkage?.stockMovement?.productBrand?.brand?.name}`,
        id:'stockMovement.productBrand.product.name'
    },
    {
        Header:"Cantidad",
        accessor: shrinkage=>displayAmount(shrinkage?.stockMovement?.quantity, shrinkage?.stockMovement?.productBrand?.product),
        id:'stockMovement.quantity',
        filterable: false,
    },
    {
        Header:"Tipo",
        accessor: shrinkage=>shrinkageTypeStrings[shrinkage.type]||'Sin información',
        id: "type",
        filterable: false,
    },
    {
        Header:"Almacén",
        accessor: 'stockMovement.batch.warehouse.name',
    },
    {
        Header:"Fecha",
        accessor: shrinkage=>moment(shrinkage.createdDate).format('DD/MM/YYYY HH:mm'),
        id:'createdDate',
        Filter: ReactTableDateRangeFilter
    },
    {
        Header:"Creado por",
        accessor: "createdBy.employee.fullName",
        id: "createdBy.employee.name",
    },

];
