import RewardsFooter from './RewardsFooter';

const footersForUser = user=>{
    return [
        {
            path: '/rewards',
            component: RewardsFooter
        }
    ];
};

export default footersForUser;
