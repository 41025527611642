import _ from "lodash";
import moment from 'moment';


export const translations =
{
    previousText: 'Anterior',
    nextText: 'Siguiente',
    loadingText: 'Cargando...',
    noDataText: 'Tabla vacía',
    pageText: 'Página',
    ofText: 'de',
    rowsText: 'filas',
};

export const defaultProps = {
    ...translations,
    minRows:3,
    className: "-striped -highlight",
    manual: true,
    filterable: true
};

export const filtersToObject = ( filters = [], sorts = [] )=>{

    const sort = sorts.reduce((acc, val) => {
        acc[`order[${val.id}]`] = val.desc ? "DESC" : "ASC";
        return acc;
    }, {});

    const filter = filters.reduce((acc, val) => {
        acc[val.id] = val.value;
        return acc;
    }, {});

    return { ...sort , ...filter };

};

export const tableStateToApiOptions=(tableState, addOptions={}, config={}, requestFilters = {}, forNewApi=false)=>{

    const filters = tableState? filtersToObject(tableState.filtered, tableState.sorted) : {};
    let options;

    if(!forNewApi) {
        options = tableState ? {filters, page: tableState.page, pageSize: tableState.pageSize} : {};
    }
    else{
        options = {filters:{ ...filters, page: tableState?.page+1, itemsPerPage: tableState?.pageSize}};
    }

    if(addOptions.filters)
        options.filters = {...options.filters, ...addOptions.filters};

    if(config.filterMappings){
        _.forEach(config.filterMappings, (newName, originalName)=>{
            if(typeof filters[originalName] !== 'undefined' ){
                options.filters[newName] = options.filters[originalName];
                delete options.filters[originalName];
            }
        });
    }
    if(config.convertFilterToSearch && options.filters && options.filters[config.convertFilterToSearch] ){
        options.filters.search=options.filters[config.convertFilterToSearch];
        delete options.filters[config.convertFilterToSearch];
    }

    const merged =  {...addOptions, ...options};
    const preParsedFilters = {
        ...requestFilters,
        ...merged.filters,
    };
    const mergedFilters = _.reduce(
        _.keys(preParsedFilters),
        (filter, key)=>{
            const value = preParsedFilters[key];
            let newProps = {};
            if(value!==null && typeof value === 'object'){
                if(value.startDate instanceof moment){
                    newProps = {
                        [key+'[after]']: value.startDate.format(config.dateFormat||'YYYY-MM-DD HH:mm:ss')
                    };
                }
                if(value.endDate instanceof moment){
                    newProps = {
                        ...newProps,
                        [key+'[before]']: value.endDate.format(config.dateFormat||'YYYY-MM-DD HH:mm:ss')
                    };
                }
            }
            if(_.keys(newProps).length===0){
                newProps = {[key]: value};
            }
            return {
                ...filter,
                ...newProps
            };
        },
        {}
    );
    return {
        ...merged,
        [forNewApi?"params":"filters"]: mergedFilters
    };
};

export const tableStateToTideApiOptions=(tableState, addOptions={}, config={})=>{

    const filters = tableState? filtersToObject(tableState.filtered, tableState.sorted) : {};
    const options = tableState? {params:{...filters, page:tableState.page+1, itemsPerPage:tableState.pageSize}}: {};
    if(addOptions.filters)
        options.params = {...options.params, ...addOptions.filters};

    if(config.filterMappings){
        _.forEach(config.filterMappings, (newName, originalName)=>{
            if(typeof filters[originalName] !== 'undefined' ){
                options.params[newName] = options.params[originalName];
                delete options.params[originalName];
            }
        });
    }
    if(config.convertFilterToSearch && options.params && options.params[config.convertFilterToSearch] ){
        options.params.search=options.params[config.convertFilterToSearch];
        delete options.params[config.convertFilterToSearch];
    }

    return  {...addOptions, ...options};
};

