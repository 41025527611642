import React,{useState, useCallback} from 'react';
import Card from '../../../components/Card/Card.jsx';
import ReactTable from "react-table";
import moneyFormatter from "../../../utils/moneyFormatter";
import WorkToolFormModal from "./components/WorkToolFormModal";
import CustomButton from "../../../elements/CustomButton/CustomButton";
import useBoolean from "../../../hooks/useBoolean";
import useTideTable from "../../../hooks/table/useTideTable";

const WorkToolsCatalogue =()=>{

    const [editing, setEditing]=useState(null);
    const closeEditing=useCallback(()=>setEditing(null),[]);
    const [creating, startCreating, stopCreating]=useBoolean();


    const workToolsTable=useTideTable({
        entity: 'workTools',
        columns
    });

    return (
        <div>
        <Card
            className='WorkToolsCatalogue'
            content={
                <div>
                    <CustomButton bsStyle='primary' onClick={startCreating}>Nueva herramienta</CustomButton>
                    <ReactTable
                        {...workToolsTable.tableProps}
                        getTrProps={(a, rowInfo)=>({onClick:()=>rowInfo? setEditing(rowInfo.original):undefined})}
                        />
                </div>
            }
        />
            {(editing || creating)&&
            <WorkToolFormModal
                workTool={editing}
                onHide={editing?closeEditing:stopCreating}
            />
            }
        </div>
    );
};

export default WorkToolsCatalogue;

const columns=[
    {
        Header:'Nombre',
        accessor: 'name'
    },
    {
        Header:'Precio proveedor',
        id: 'providerPrice',
        accessor: (workTool)=>moneyFormatter( workTool.providerPrice,1),
        filterable: false
    },
    {
        Header:'Precio colaborador',
        id: 'employeePrice',
        accessor: (workTool)=>moneyFormatter( workTool.employeePrice,1),
        filterable: false
    },
    {
        Header:'Duración',
        id: 'lifeDays',
        accessor: (workTool)=>workTool.lifeDays?`${workTool.lifeDays} días`:'Sin definir',
        filterable: false
    },
];

