import React, {Component} from 'react';
import { Modal, Table, Row, Col} from 'react-bootstrap';
import Button from "../../../../elements/CustomButton/CustomButton";
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-select/dist/react-select.min.css';
import Select from "react-select";
import {connect} from 'react-redux';
import _ from 'lodash';
import {employeeFullName} from "../../../../utils/modelUtils/alma/employeeUtils";


class PersonnelRequisitionModal extends Component {

    static contextTypes = {api: PropTypes.object};

    state = {};

    loadApplicants = (query)=>{
        this.context.api.applicants.get(0, 10, undefined, {search:query, 'employee[exists]':false} );
    };

    handleApplicantChange = ( applicant )=>{

    };

    render() {

        const req = this.props.requisition;

        return (
            <div>
                <Modal
                    show={true}
                    onHide={this.props.onHide}
                >
                    <Modal.Header closeButton >
                        <Modal.Title>Detalle de solicitud</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <Table>
                            <tbody>
                            <tr>
                                <td >Solicitante:</td>
                                <td >{employeeFullName(req.requestedBy)}</td>
                            </tr>
                            <tr>
                                <td >Fecha de solicitud:</td>
                                <td >{moment(req.createdDate).format('DD/MM/YYYY')}</td>
                            </tr>
                            </tbody>
                        </Table>

                        <h5>Datos generales</h5>

                        <Table>
                            <tbody>
                            <tr>
                                <td >Área:</td>
                                <td >{req.area? req.area.name:null }</td>
                            </tr>

                            <tr>
                                <td >Sala:</td>
                                <td >{req.lounge? req.lounge.name:null}</td>
                            </tr>

                            <tr>
                                <td >Puesto:</td>
                                <td >{req.position? req.position.name:null}</td>
                            </tr>

                            <tr>
                                <td >Fecha para cubrir:</td>
                                <td >{moment(req.fulfillDate).format('DD/MM/YYYY')}</td>
                            </tr>

                            <tr>
                                <td >Día de descanso:</td>
                                <td >{ req.daysOff? req.daysOff.map((day)=>daysNumbers[day]).join(','):null }</td>
                            </tr>

                            </tbody>
                        </Table>
                        <p>Comentarios de la solicitud:</p>
                        <span>
                            {req.requestComment}
                        </span>

                        <br/><br/>
                        {req.isApproved === false?
                            <div className='alert alert-danger'>
                                <strong>Motivo del rechazo:</strong><br/>
                                { req.rejectionComment? req.rejectionComment: 'Sin comentarios' }
                            </div>:null
                        }

                    </Modal.Body>

                    <Modal.Footer>

                        <Button bsStyle='danger' className='pull-left' onClick={this.props.onHide} >Cerrar</Button>

                        {/* Buttons for pending requisitions */}
                        {req.isApproved === null && this.props.canApprove?
                        <span>
                            <Button bsStyle='danger' onClick={()=>this.setState({rejecting:true})}>Rechazar</Button>
                            <Button bsStyle='success' onClick={ ()=>this.props.onApprove( req ) }>Aceptar</Button>
                        </span>:null}&nbsp;

                        {/* I can delete requisition that I own and haven't been approved */}
                        {!req.employee && <Button bsStyle='danger' onClick={()=>this.props.onDelete(req)}>Borrar</Button>}

                        {req.isApproved === true && !req.employee && this.props.canHire?
                        <Button bsStyle='success' onClick={()=>this.setState({creatingUser:'confirm'})}>Contratar</Button>
                            :null}


                    </Modal.Footer>
                </Modal>


                {/* Reject requisition confirmation Modal */}

                <Modal
                    onHide={()=>this.setState({rejecting:false})}
                    show={this.state.rejecting}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Motivo de rechazo
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Escribe el motivo por el que rechazas esta solicitud:</p>
                        <textarea
                            className='form-control'
                            value={this.state.rejectionComment}
                            onChange={(e)=>this.setState({rejectionComment:e.target.value})}
                        />
                    </Modal.Body>
                    <Modal.Footer>

                        <Button bsStyle='danger' className='pull-left' onClick={()=>this.setState({rejecting:false})} >Cancelar</Button>

                        <Button bsStyle='danger' onClick={ ()=>this.props.onReject( req, this.state.rejectionComment) }>Rechazar</Button>
                    </Modal.Footer>
                </Modal>


                {/* Hire from requisition confirmation Modal */}

                {this.state.creatingUser === 'confirm'?
                <Modal
                    onHide={()=>this.setState({creatingUser:false})}
                    show={ true }>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Añadir nuevo empleado
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>¿Quieres crear un empleado desde cero o pre-llenar la forma con un aspirante seleccionado?</p>
                        <Row>
                            <Col sm={6}>
                                <Select
                                    name="applicant"
                                    options={this.props.applicants.map(
                                        (applicant)=>({value:applicant.id, label:
                                                applicant.name+" "+
                                                applicant.patLastName+" "+
                                                applicant.matLastName+" - "+
                                                (applicant.position? applicant.position.name:'')
                                        })
                                    )}
                                    onInputChange={(input)=>this.loadApplicants(input)}
                                    onChange={(value)=>this.setState({selectedApplicant:value})}
                                    value={this.state.selectedApplicant}
                                    placeholder='Buscar aspirante'

                                />
                                <br/>
                                <Button
                                    bsStyle='success'
                                    disabled={!this.state.selectedApplicant}
                                    onClick={()=>this.props.onHire( req, _.find( this.props.applicants, (app)=>app.id === this.state.selectedApplicant.value ) ) }
                                >Pre-llenar aspirante</Button>
                            </Col>
                            <Col sm={6}>
                                <br/><br/><br/>
                                <Button bsStyle='success' className='pull-right' onClick={()=>this.props.onHire(req)} >Crear desde cero</Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button bsStyle='danger' className='pull-left' onClick={()=>this.setState({creatingUser:false})} >Cancelar</Button>

                    </Modal.Footer>
                </Modal>
                    :null}

                {/* Hire user, New User Form */}

            </div>
        );
    }
}


function mapStateToProps( {api:{ me, applicants=[] } } ) {

    return { me, applicants }
}

export default connect(mapStateToProps)(PersonnelRequisitionModal);


const daysNumbers = ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"];
