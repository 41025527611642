import React ,{useCallback} from 'react';
import {Modal, Table} from 'react-bootstrap';
import Button from "../../../elements/CustomButton/CustomButton";


const ServiceDetailModal = ({service, onHide, onDelete, onEdit}) => {

    const deleteClick=useCallback(()=>onDelete(service),[service, onDelete]);
    const editClick=useCallback(()=>onEdit(service),[service, onEdit]);

    return (
        <Modal
            show={true}
            onHide={onHide}
        >
            <Modal.Header>
                <Modal.Title>Detalles del servicio</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                    <Table>
                        <tbody>
                        <tr>
                            <td>Nombre</td>
                            <td>
                                {service.name}
                            </td>
                        </tr>
                        </tbody>
                    </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle='danger'  className='pull-left' onClick={onHide}>Cerrar</Button>
                <Button bsStyle='danger'  onClick={deleteClick}>Borrar</Button>
                <Button bsStyle='primary'  onClick={editClick}>Editar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ServiceDetailModal;
