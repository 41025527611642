import React,{useState, useContext} from 'react';
import {ApiContext} from "../../../api/Api";
import {connect} from "react-redux";


const ReservationLoungeDropdown = ( {reservation, lounges} )=>{


    const api = useContext( ApiContext );
    const [newLounge, setNewLounge] = useState('');

    const onLoungeChange = ( lounge )=>{

        if( reservation.lounge && reservation.lounge.id === lounge )
            return;

        setNewLounge( lounge );
        api.reservations.edit( reservation.id, { lounge } )
            .catch( ()=>{
                setNewLounge('');
            } )
    };

    return (
        <select
            onChange={({target})=>onLoungeChange(target.value)}
            onClick={ (e)=>e.stopPropagation() }
            value={newLounge? newLounge : (reservation.lounge? reservation.lounge.id : '')}
            className='form-control'>
            <option value={''} disabled >Sala...</option>
            {lounges.map(lounge=><option value={lounge.id} key={lounge.id}>{lounge.name}</option>)}
        </select>
    );
};

const mapStateToProps = ({api:{reservationDropdownLounges=[]},loadingIds})=>({lounges:reservationDropdownLounges, loadingIds});


export default connect(mapStateToProps)(ReservationLoungeDropdown);
