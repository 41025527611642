import Katharsis from "../views/katharsis/Katharsis";
import OrozcoProductList from "../views/katharsis/Recipes/OrozcoProductList/OrozcoProductList";
import RecipeEditor from "../views/katharsis/Recipes/RecipeEditor/RecipeEditor";
import OrozcoRecipeList from "../views/katharsis/Recipes/OrozcoRecipeList/OrozcoRecipeList";
import KatharsisReports from "../views/katharsis/KatharsisReports/KatharsisReports";
import KatharsisDashboard from "../views/katharsis/KatharsisDashboard/KatharsisDashboard";
import Help from "../views/katharsis/Help/Help";


export const paths = {
    home: '/katharsis/home',
    dashboard: '/katharsis/dashboard',
    recipesProducts: '/katharsis/products',
    recipesEditor: '/katharsis/editor/:type/:id',
    recipesList: '/katharsis/recipes',
    reports: '/katharsis/reports',
    katharsisHelp: '/katharsis/help',
};

export default (securityManager)=> {
    const routes = [];
    routes.push({path: paths.home, name: "Katharsis", component: Katharsis, hidden: true});

    if(securityManager?.katharsis.canSeeKatharsisDashboard()){
        routes.push({ name: "Dashboard", icon: "fa fa-area-chart", path: paths.dashboard, component: KatharsisDashboard});
    }
    if(securityManager?.katharsis.canSeeRecipesAdmin()){
        routes.push({ name: "Platillos", icon: "fa fa-cutlery", path: paths.recipesProducts, component: OrozcoProductList});
        routes.push({ name: "Todas las recetas", icon: "fa fa-clipboard", path: paths.recipesList, component: OrozcoRecipeList});
        routes.push({ name: "Creador de recetas", path: paths.recipesEditor, component: RecipeEditor, hidden: true});
    }
    if(securityManager?.katharsis.canSeeReports()){
        routes.push({name:'Reportes', path:paths.reports, icon: 'fa fa-area-chart', component:KatharsisReports});
    }

    routes.push({ path: paths.katharsisHelp, name: "Ayuda", icon: "pe-7s-info", component: Help });

    return routes;
}
