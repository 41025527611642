
import dataReducer from './reducer_data';
import {ACTION_PREFIX, initialState, ACTION_LOG} from "./constants_api";
import LocalStorageState from "../utils/LocalStorage";
import {LOGIN_STATE} from "../constants/LogIn";
import {CLEAR_STATE} from "../actions/types";

export default function( state=initialState, action){

    if( action.type === CLEAR_STATE) {
        console.log('clearing api');
        return initialState;
    }

    if( action.type.substr(0,ACTION_PREFIX.length) !== ACTION_PREFIX )
        return state;

    const apiAction = action.type.substr(ACTION_PREFIX.length);

    switch( apiAction ){

        case ACTION_LOG:
            if( action.payload === LOGIN_STATE.LOGGED_IN || action.payload === LOGIN_STATE.NOT_LOGGED ) {
                let loc = new LocalStorageState();
                loc.store({api: {loggedIn: action.payload}});
            }
            return {...state, loggedIn: action.payload};

        default:
            return dataReducer(state, action);
    }
}