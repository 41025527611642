import React, {useContext, useCallback} from 'react';
import {DropdownButton, MenuItem} from "react-bootstrap";
import {SecurityContext} from "../../../utils/SecurityManager";
import {ApiContext} from "../../../api/Api";
import {connect} from 'react-redux';
import {poTrans} from "../../../utils/modelUtils/jazz/purchaseOrderUtils";

const loadingId='@POStatusSelector.purchaseOrder.edit';

const POStatusSelector = ({purchaseOrder, loadingIds, className,customProp='purchaseOrders', requestParameters={}})=>{

    const security = useContext(SecurityContext);
    const api = useContext(ApiContext);
    const po = purchaseOrder;


    const onChangeStatus = useCallback((newStatus) => {
        const newOrder = { status: newStatus, ...requestParameters};

        if(newStatus!==po.status)
            api.purchaseOrders.edit({id: po.id, purchaseOrder: newOrder, loadingId, customProp});

    }, [api, po, customProp, requestParameters]);

    return (
        <div className={className}>
            {security.jazz.canUpdateOrderStatus() ?
                <DropdownButton
                    id='status'
                    title={loadingIds[loadingId] ? 'Cambiando...' : poTrans(po.status)}
                    bsStyle={styleForStatus[po.status]}
                    className='btn-fill'
                >
                    <MenuItem eventKey='requested' onSelect={onChangeStatus}>{poTrans('requested')}</MenuItem>
                    <MenuItem eventKey='paying' onSelect={onChangeStatus}>{poTrans('paying')}</MenuItem>
                    <MenuItem eventKey='payed' onSelect={onChangeStatus}>{poTrans('payed')}</MenuItem>
                    <MenuItem eventKey='canceled' onSelect={onChangeStatus}>{poTrans('canceled')}</MenuItem>
                    <MenuItem eventKey='in_clarification' onSelect={onChangeStatus}>{poTrans('in_clarification')}</MenuItem>
                </DropdownButton>
                :
                <span className={'alert alert-'+(styleForStatus[po.status]||'primary')}>{poTrans(po.status)}</span>
            }
        </div>
    );
};

const styleForStatus = {
    requested: 'warning',
    canceled: 'danger',
    paying: 'primary',
    payed: 'success',
    in_clarification: 'danger',
};

const mapStateToProps = ({loadingIds})=>({loadingIds});

export default connect(mapStateToProps)(POStatusSelector);
