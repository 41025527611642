import React, {Component} from 'react';
import { Modal, ControlLabel, DropdownButton, MenuItem, Table} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Button from "../../../../elements/CustomButton/CustomButton";


class NewTicketModal extends Component{

    static contextTypes = {
        api: PropTypes.object,
        notifier: PropTypes.object,
    };

    loadingId = '@Class.NewTicketModal.tickets.create';

    state = { description:'' };

    selectTicketType = ( index )=>{

        const ticketType = this.props.ticketTypes[ index ];

        this.setState( { ticketType });
    };

    onFormChange = (e)=>{

        this.setState( {[e.target.name]:e.target.value} );
    };

    onCreate = ()=>{

        if( !this.state.ticketType )
            return this.context.notifier.error('Debes seleccionar un tipo de ticket');
        if( !this.state.description )
            return this.context.notifier.error('Debes escribir la descripción del ticket');

        this.context.api.tickets.create({
            ticketType: this.state.ticketType.id,
            description: this.state.description
        }, this.loadingId)
            .then(()=>{
                this.props.onHide()
            })
    };

    render(){

        const ttype = this.state.ticketType;

        return (
            <Modal
                show={true}
                onHide={this.props.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <ControlLabel>Tipo de ticket</ControlLabel><br/>
                    <DropdownButton
                        id='ticket-type'
                        title={ ttype? ttype.name : 'Selecciona el tipo'}
                        onChange={this.selectTicketType}
                    >
                        {this.props.ticketTypes.map( (ttype, index)=>
                            <MenuItem eventKey={index} key={ttype.id} onSelect={this.selectTicketType} >{ttype.name}</MenuItem>
                        )}
                    </DropdownButton>

                    <br/><br/>

                    {ttype?
                    <Table>
                        <tbody>
                        <tr>
                            <td>Tiempo límite de resolución:</td><td>{ttype.resolutionHours} horas</td>
                        </tr>
                        <tr>
                            <td>Prioridad:</td><td>{ttype.priority}</td>
                        </tr>
                        </tbody>
                    </Table>
                    :null}

                    <ControlLabel>Descripción</ControlLabel>
                    <textarea
                        className='form-control'
                        name='description'
                        onChange={this.onFormChange}
                        value={this.state.description}
                    />

                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle='danger' onClick={this.props.onHide}>Cancelar</Button>
                    <Button bsStyle='success' onClick={this.onCreate} disabled={this.props.loadingIds[ this.loadingId ]}>Crear</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = ({api:{ticketTypes=[]}, loadingIds})=>({ticketTypes, loadingIds});

export default connect(mapStateToProps)(NewTicketModal);