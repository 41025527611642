import React from 'react';
import {Tooltip} from "react-bootstrap";


const PurchaseProductProductsModalTooltip = (purchaseProducts) => {

    const products = [];

    purchaseProducts.forEach((pp) => {
        const productName = pp.productBrand?pp.productBrand.product.name:pp.name;
        if (!products.includes(productName))
            products.push(productName);
    });

    return (
        <Tooltip id='purchase-requisition-products-tooltip'>
            <ul>
                {products.map((p, i) => (
                    <li key={i}>{p}</li>
                ))}
            </ul>
        </Tooltip>);
};

export default PurchaseProductProductsModalTooltip;
