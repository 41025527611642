import React,{useState, useContext, useEffect, useMemo} from 'react';
import {ApiContext} from "../../../../api/Api";
import {useSelector} from "react-redux";
import {Table} from "react-bootstrap";
import DateRangeFilter, {useDateRangeFilter} from '../Filters/DateRangeFilter';
import CustomCheckbox from '../../../../elements/CustomCheckbox/CustomCheckbox';
import {dataView} from '../utils';

const loadingId = '@LoungeAccessMethodStatsReport.report';

const dataAxis = [
    {
        getKey: 'loungeAccessMethodId',
        getDisplayName: 'loungeAccessMethodName'
    },
    {
        getKey: 'shift',
        getDisplayName: ({shift})=>{
            switch(shift){
            case 'morning': return 'Matutino';
            case 'afternoon': return 'Vespertino';
            case 'night': return 'Nocturno';
            default: return 'Desconocido';
            }
        }
    },
    {
        getKey: 'loungeId',
        getDisplayName: 'loungeName'
    }
];
const prepareDataView = dataView(dataAxis);

const LoungeAccessMethodStatsReport = ({defaultDateRange}) => {
    const dateConfig = useMemo(()=>({initial: defaultDateRange}), [defaultDateRange]);
    const {requestParams: dateRequest, filterProps: dateFilterProps} = useDateRangeFilter(dateConfig);
    const [chosenMethod, setChosenMethod] = useState(null);
    const [showing, setShowing] = useState('guestsNumber');
    const params = useMemo(()=>({...dateRequest}), [dateRequest]);
    const api=useContext(ApiContext);
    useEffect(()=>{
        api.lounges.accessMethodReport({loadingId, params});
    },[api, params]);
    const loading = useSelector(({loadingIds})=>!!loadingIds[loadingId]);
    const report  = useSelector(({api})=>api.loungesAccessMethodReport);

    const {axisData, foldDataValues} = useMemo(
        ()=> prepareDataView(report, v=>v[showing]||0),
        [showing, report]);
    const accessMethods = useMemo(()=>axisData[0], [axisData]);
    const shifts = axisData[1];
    const lounges = axisData[2];
    const getValue = foldDataValues((x,y)=>parseInt(x)+parseInt(y), 0);

    useEffect(()=>{
        if(accessMethods.length>0){
            setChosenMethod(accessMethods[0].key);
        }
        else {
            setChosenMethod(null);
        }
    }, [accessMethods]);
    return (
        <div className='LoungeAccessMethodStatsReport'>
            <DateRangeFilter {...dateFilterProps}/>
            {loading && <h3 className='text-center'>Cargando...</h3>}
            <label>Método de acceso:</label>
            <select className='form-control' value={chosenMethod} onChange={e=>setChosenMethod(e.target.value)}>
                {accessMethods.map(({key, displayName})=><option value={key}>{displayName}</option>)}
            </select>
            <div className='row'>
                <div className='col-md-3'>
                    <label>Mostrar: </label>
                </div>
                <div className='col-md-8 align-right'>
                    <CustomCheckbox onChange={()=>setShowing('personsNumber')} isChecked={showing==='personsNumber'} inline label='Total de personas atendidas'/>
                    <CustomCheckbox onChange={()=>setShowing('guestsNumber')} isChecked={showing==='guestsNumber'} inline label='Total de huéspedes'/>
                </div>
            </div>
            {chosenMethod &&
             <Table>
                 <thead>
                     <tr>
                         <th>Turno</th>
                         {lounges.map(({key, displayName})=><th key={key}>{displayName}</th>)}
                         <th>Total turno</th>
                     </tr>
                 </thead>
                 <tbody>
                     {shifts.map(({key: shift, displayName})=>(
                         <tr key={shift}>
                             <td><strong>{displayName}</strong></td>
                             {lounges.map(({key: loungeId})=><td>{getValue([chosenMethod, shift, loungeId])}</td>)}
                             <td><strong>{getValue([chosenMethod, shift, undefined])}</strong></td>
                         </tr>
                     ))}
                     <tr>
                         <td><strong>Total sala</strong></td>
                         {lounges.map(({key})=><td><strong>{getValue([chosenMethod, undefined, key])}</strong></td>)}
                         <td><strong>{getValue([chosenMethod])}</strong></td>
                     </tr>
                 </tbody>
             </Table>
            }
        </div>
    );
};

export default LoungeAccessMethodStatsReport;
