import React, {useMemo, useState, useCallback, useEffect} from 'react';
import {Col, Row} from "react-bootstrap";
import ReactTable from "react-table";
import useTideEntity from "../../../hooks/useTideEntity";
import {productBrandName} from "../../../utils/modelUtils/almazen/productBrandUtils";
import {displayAmount} from "../../../utils/modelUtils/almazen/unitsUtility";
import _ from 'lodash';
import InventoryModeFormModal from "./components/InventoryModeFormModal";
import useBoolean from "../../../hooks/useBoolean";
import useTideTable from "../../../hooks/table/useTideTable";


const InventoryMode = ({match})=>{

    const warehouseId = match.params.id;

    const [warehouse, loading, notFound] = useTideEntity('warehouses', match.params.id);

    const [searchInput, setSearchInput] = useState('');
    const [search, setSearch] = useState('');

    const [inventoryHidden,,,toggleInventoryHidden]=useBoolean();
    const [catalogueHidden,,,toggleCatalogueHidden]=useBoolean();

    const applySearch=useCallback(_.debounce(setSearch, 650),[]);

    const clearSearch=useCallback(()=>{
        setSearchInput('');
        setSearch('');
    },[]);

    const searchChangeHandler=useCallback(({target})=>{
        setSearchInput(target.value);
        applySearch(target.value);
    },[applySearch]);

    const inventoryTableOptions=useMemo(()=>(
        {
            entity:'inventories',
            columns: inventoryColumns,
            requestOptions:{filters:{warehouse:warehouseId, search, 'order[productBrand.product.name]':'ASC'}}
        }
    ),[warehouseId, search]);

    const inventoryTable = useTideTable(inventoryTableOptions);

    const catalogueTableOptions=useMemo(()=>(
        {
            entity:'productBrands',
            columns: catalogueColumns,
            convertFilterToSearch: 'product.name',
            requestOptions:{filters:{warehouse:warehouseId, search, 'order[product.name]':'ASC'}}
        }
    ),[warehouseId, search]);
    const catalogueTable = useTideTable(catalogueTableOptions);

    const reloadTables=useCallback(()=>{
        catalogueTable.reload();
        inventoryTable.reload();
        // eslint-disable-next-line
    },[catalogueTable.reload, inventoryTable.reload]);

    useEffect(()=>reloadTables(), [reloadTables]);

    const [editingProductBrand, setEditingProductBrand] = useState(null);
    const cancelEditing = useCallback(()=>setEditingProductBrand(null),[]);

    const catalogueTrPropsGetter=useCallback((table, row)=>{
        return {onClick: row && row.original? ()=>setEditingProductBrand(row.original): undefined};
    },[]);

    const inventoryTrPropsGetter=useCallback((table, row)=>{
        return {onClick: row && row.original? ()=>setEditingProductBrand(row.original.productBrand): undefined};
    },[]);

    return (
        <div className='container-fluid InventoryMode'>
            {loading && <h2>Cargando...</h2>}
            {notFound && <h2>Almacén no encontrado</h2>}
            {warehouse && <h2>Almacén - {warehouse.name}</h2>}
            <Row>
                <Col xs={12} className='search-wrapper'>
                    <input
                        className='form-control'
                        placeholder='Buscar producto...'
                        value={searchInput}
                        onChange={searchChangeHandler}
                    />
                    {search &&
                    <button className='clear-search' onClick={clearSearch}><i className='fa fa-times'/></button>}
                </Col>
            </Row>
            <Row>

                <Col xs={12} sm={6}>
                    <div className='card'>
                        <div className="header">
                            <h4 className="title">
                                Inventario actual
                                <button className='hide-button' onClick={toggleInventoryHidden}><i className={'fa fa-caret-'+(inventoryHidden?'down':'up')}/></button>
                                <div className='clearfix'/>
                            </h4>
                        </div>
                        <div className={'panel-body hide-panel '+(inventoryHidden?'is-hidden':'')}>
                            <ReactTable
                                {...inventoryTable.tableProps}
                                getTrProps={inventoryTrPropsGetter}
                                minRows={10}
                            />
                        </div>
                    </div>
                </Col>

                <Col xs={12} sm={6}>
                    <div className='card'>
                        <div className="header">
                            <h4 className="title">
                                Catálogo de productos
                                <button className='hide-button' onClick={toggleCatalogueHidden}><i className={'fa fa-caret-'+(catalogueHidden?'down':'up')}/></button>
                                <div className='clearfix'/>
                            </h4>
                        </div>
                        <div className={'panel-body hide-panel '+(catalogueHidden?'is-hidden':'')}>
                            <ReactTable
                                {...catalogueTable.tableProps}
                                getTrProps={catalogueTrPropsGetter}
                                minRows={10}
                            />
                        </div>
                    </div>
                </Col>

            </Row>

            {editingProductBrand &&
                <InventoryModeFormModal
                    productBrand={editingProductBrand}
                    warehouse={warehouse}
                    onHide={cancelEditing}
                    onChange={reloadTables}
                    />
            }

        </div>
    );
};

export default InventoryMode;

const catalogueColumns=[
    {
        Header: 'Producto - Marca',
        accessor: productBrandName,
        id: 'product.name',
        filterable: false
    },
    {
        Header: 'Línea',
        accessor: 'product.line.name',
        filterable: false
    },
];

const inventoryColumns=[
    {
        Header: 'Producto - Marca',
        accessor: inventory=>productBrandName(inventory.productBrand),
        id: 'productBrand.product.name',
        filterable: false
    },
    {
        Header: 'Línea',
        accessor: 'productBrand.product.line.name',
        filterable: false
    },
    {
        Header: 'Existencia',
        accessor: inventory=>displayAmount( inventory.stockAmount, inventory.productBrand.product ),
        id: 'stockAmount',
        filterable: false
    },
];
