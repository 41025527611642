import _ from 'lodash';
import plural from 'pluralize-es';


export const emptyUnitBundle = () => ({
    smallestUnit: null,
    displayUnit: null,
    conversionRules: []
});

export const extractUnitBundleProperties = (unitBundleInterface) => ({
    smallestUnit: unitBundleInterface.smallestUnit,
    displayUnit: unitBundleInterface.displayUnit,
    conversionRules: unitBundleInterface.conversionRules || []
});

export const testBasicConversionRules = (unitBundle)=>{
    if(!unitBundle)
        throw new Error('Error de configuración de unidades');
    if(!unitBundle.smallestUnit)
        throw new Error('Selecciona la unidad mínima');
    if(!unitBundle.displayUnit)
        throw new Error('Selecciona la unidad para mostrar');
    if(unitBundle.smallestUnit.id === unitBundle.displayUnit.id)
        return true;
    for(let i=0; i<unitBundle.conversionRules.length; i++){
        const rule = unitBundle.conversionRules[i];
        if(rule.multiplier && rule.unit.id === unitBundle.displayUnit.id)
            return true;
    }
    throw new Error('Debes agregar la regla de conversión entre la unidad mínima y la unidad para mostrar.');
}

export const unitBundleToIds = (unitBundle) => {

    const justIds = {...unitBundle};
    justIds.smallestUnit = unitBundle.smallestUnit.id;

    if (unitBundle.displayUnit)
        justIds.displayUnit = unitBundle.displayUnit.id;

    if (unitBundle.conversionRules && unitBundle.conversionRules.length) {
        justIds.conversionRules = [];
        for (let i = 0; i < unitBundle.conversionRules.length; i++) {

            const cr = unitBundle.conversionRules[i];
            if (!cr.unit || !Number(cr.multiplier))
                continue;

            justIds.conversionRules.push({...cr, multiplier: cr.multiplier + '', unit: cr.unit.id});
        }
    }

    return justIds;
};

export const getUnitsArray = (unitBundle) => {

    const units = [];
    if (unitBundle.smallestUnit)
        units.push(unitBundle.smallestUnit);

    if (unitBundle.conversionRules && unitBundle.conversionRules.length) {
        for (let i = 0; i < unitBundle.conversionRules.length; i++)
            if (unitBundle.conversionRules[i].unit)
                units.push(unitBundle.conversionRules[i].unit);

    }

    return units;
};


export const smallestUnitConversion = (quantity, unit, unitBundle, toOrFrom) => {
    
    if (quantity === 0)
        return 0;

    if (!quantity || !unit || !unitBundle || !unitBundle.smallestUnit)
        throw new Error('Missing parameters to make a conversion');

    if (unit.id === unitBundle.smallestUnit.id)
        return Number(quantity);

    const rule = _.find(unitBundle.conversionRules, (cr) => cr.unit.id === unit.id);
    if (!rule)
        throw new Error("Tried to convert from unit " + unit.name + " but there's no conversion rule defined");

    if (toOrFrom === 'to')
        return maxDigits(Number(quantity) * Number(rule.multiplier));
    else
        return maxDigits(Number(quantity) / Number(rule.multiplier));
};

export const getAllQuantityConversions = (quantity, unitBundle) => {
    const conversions = [];
    if (unitBundle.conversionRules && unitBundle.conversionRules.length) {
        for (let i = 0; i < unitBundle.conversionRules.length; i++) {
            conversions.push({
                id:unitBundle.conversionRules[i].id,
                unit: unitBundle.conversionRules[i].unit.name,
                quantity: convertFromSmallestUnit( quantity, unitBundle.conversionRules[i].unit, unitBundle )
            })
        }
    }else{ conversions.push({
        id: unitBundle.id,
        unit: unitBundle.smallestUnit.name,
        quantity: Number(quantity)
    })
    }
    return conversions;
};

export const convertToSmallestUnit = (quantity, unit, unitBundle) =>
    smallestUnitConversion(quantity, unit, unitBundle, 'to');

export const convertFromSmallestUnit = (quantity, toUnit, unitBundle) =>
    smallestUnitConversion(quantity, toUnit, unitBundle, 'from');

export const unitConversion = (quantity, fromUnit, toUnit, unitBundle) => {
    const inSmallest = convertToSmallestUnit(quantity, fromUnit, unitBundle);
    return convertFromSmallestUnit(inSmallest, toUnit, unitBundle);
};

export const convertSmallestUnitToDisplayUnit = (quantity, unitBundle) =>{
    if(unitBundle.displayUnit)
        return convertFromSmallestUnit(quantity, unitBundle.displayUnit, unitBundle);
    return quantity;
};

export const getSuggestedUnitToShow=(quantity, unitBundle)=>{
    if(!unitBundle)
        throw new Error('Can\'t get suggested unit to show of undefined');

    const displayUnitRule=_.find(unitBundle.conversionRules, rule=>rule.unit.id===unitBundle.displayUnit.id);
    if(unitBundle.displayUnit && displayUnitRule && Math.abs(quantity)>Number(displayUnitRule.multiplier))
        return unitBundle.displayUnit;

    const units= _.sortBy(getUnitsArray(unitBundle), 'multiplier').reverse();
    for(const unit of units){
        const rule=_.find(unitBundle.conversionRules, rule=>rule.unit.id===unit.id);
        if(rule && Number(rule.multiplier) <= Math.abs(quantity))
            return unit;
    }

    return unitBundle.smallestUnit;
};

export const displayAmount = (quantity, unitBundle) => {
    //Si no está bien definido el unitBundle no hacemos nada
    if (!unitBundle || !unitBundle.smallestUnit || !unitBundle.displayUnit)
        return Number(quantity);
    //Si el displayUnit es el smallestUnit, (ya viene en la unidad favorita) la utilizamos
    if (unitBundle.smallestUnit.id === unitBundle.displayUnit.id)
        return Number(quantity) + " " + pluralizeUnitName(quantity, unitBundle.smallestUnit.name);

    //Obtenemos la regla de la mejor unidad para mostrar
    const unit = getSuggestedUnitToShow(quantity, unitBundle);
    const rule = _.find(unitBundle.conversionRules, (cr) => cr.unit.id === unit.id);

    if (!rule)
        return maxDigits(Number(quantity),2) + " " + pluralizeUnitName(quantity, unitBundle.smallestUnit.name);

    const newQuantity=maxDigits(Number(quantity) / rule.multiplier, 2);
    return  newQuantity+ " " + pluralizeUnitName(newQuantity, unit.name);
};

export const pluralizeUnitName=(amount, name)=>{
    if(Number(amount)===1 || !name)
        return name;

    const parts=name.toLowerCase().split(' ');
    const firstWord = parts[0];
    const lastLetter = firstWord[firstWord.length - 1];
    if (lastLetter >= '0' && lastLetter <= '9')//if last letter is a number, we don't change it
        return name;

    parts[0]=plural(parts[0]);
    return parts.join(' ').toUpperCase();
};

const maxDigits = (num, digits=12) => {
    const multiple = Math.pow(10, digits);
    return Math.round(num * multiple) / multiple;
};
